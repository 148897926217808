import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BroadcastMenu } from "src/app/gui/header/BroadcastMenu";
import { CallsMenu } from "src/app/gui/header/CallsMenu";
import { LatestSpeaker } from "src/app/gui/header/LatestSpeaker";
import { MuteGroupMenu } from "src/app/gui/header/MuteGroupMenu";
import { NO_MICROPHONE_HEIGHT } from "src/app/gui/header/NoMicrophone";
import { NotificationMenu } from "src/app/gui/header/NotificationMenu";
import { PanelMenu } from "src/app/gui/header/PanelMenu";
import { Separator } from "src/app/gui/header/Separator";
import { StatusMenu } from "src/app/gui/header/StatusMenu";
import { TicketMenu } from "src/app/gui/header/TicketMenu";
import { UserMenu } from "src/app/gui/header/UserMenu";
import { connect } from "src/app/state/connect";

export const HEADER_HEIGHT = 60;

const Logo = (): JSX.Element => (
  <Layout alignCenter alignMiddle padding={20} row>
    <img alt="logo" src={`${process.env.PUBLIC_URL}/config/text_logo.png`} />
  </Layout>
);

const Version = ({ version }: { version: string }): JSX.Element => (
  <Layout alignMiddle row>
    <Text color="#aaa" size={14}>
      {version}
    </Text>
  </Layout>
);

export const Header = connect((_, state) => {
  const { version } = state;
  const showMuteGroups =
    (state.online!.sessions.muteGroups?.list.length ?? 0) > 0 &&
    state.settings.muteGroups.showInMenu.value;
  const showTicketMenu = state.settings.queues.showTicketMenu.value;
  const { showNoMicrophoneInput } = state.online!.audio;
  const { statusAvailable } = state.online!.status;
  const { useQueues } = state.online!.queueManagement;
  const { hasCallsOrMonitoringSessions, lastActiveSession } =
    state.online!.sessions;
  const { showCallsMenu, showLatestSpeaker } =
    state.settings.notificationSounds;

  return (
    <Layout
      absolute
      background="linear-gradient(to bottom, #545869, #414450)"
      className="gt_header"
      height={HEADER_HEIGHT}
      left={0}
      right={0}
      row
      top={showNoMicrophoneInput ? NO_MICROPHONE_HEIGHT : 0}
    >
      <Logo />
      <Version version={version} />
      <Layout grow row />
      {showLatestSpeaker.value && lastActiveSession && <Separator />}
      {showLatestSpeaker.value && lastActiveSession && (
        <LatestSpeaker session={lastActiveSession} />
      )}
      {showCallsMenu.value && hasCallsOrMonitoringSessions && <Separator />}
      {showCallsMenu.value && hasCallsOrMonitoringSessions && <CallsMenu />}
      {showTicketMenu && useQueues && <Separator />}
      {showTicketMenu && useQueues && <TicketMenu />}
      <Separator />
      <GtTooltip id="add_panel" title="Add Panel">
        <PanelMenu />
      </GtTooltip>
      <GtTooltip id="broadcast_mode" title="Broadcast Mode">
        <BroadcastMenu />
      </GtTooltip>
      {statusAvailable && (
        <GtTooltip id="status" title="Status">
          <StatusMenu />
        </GtTooltip>
      )}
      {showMuteGroups && (
        <GtTooltip id="mute_group" title="Mute Group">
          <MuteGroupMenu />
        </GtTooltip>
      )}
      <GtTooltip id="notifications" title="Notifications">
        <NotificationMenu />
      </GtTooltip>
      <Separator />
      <GtTooltip id="user_menu" title="User Menu">
        <UserMenu />
      </GtTooltip>
    </Layout>
  );
});
