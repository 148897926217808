import { Token } from "src/lib/modules/Token";
import localStorage from "mobx-localstorage";

const APPDATA_TOKENS = "gt2.account.tokens";
export class Tokens {
  public static get list(): Token[] {
    const tokenString = localStorage.getItem(APPDATA_TOKENS);
    return tokenString ? tokenString.split("|").map(Token.deserialize) : [];
  }
  public static add(token: Token): void {
    const newList = Tokens.list.map((t) =>
      t.realm === token.realm ? token : t
    );
    if (!newList.includes(token)) {
      newList.push(token);
    }
    localStorage.setItem(
      APPDATA_TOKENS,
      newList.map((t) => t.serialize()).join("|")
    );
  }
  public static removeAll(): void {
    localStorage.removeItem(APPDATA_TOKENS);
  }
}
