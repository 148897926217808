import { Layout } from "src/app/gui/components/Layout";
import { NumberSetting } from "src/app/gui/settings/NumberSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const TimeFilter = connect<{
  panel: Panel;
}>(({ panel }) => {
  const { ticketDelaySetting } = panel.queuePanelData!;
  return (
    <Layout column>
      <Layout alignMiddle minHeight={50} row>
        {ticketDelaySetting && (
          <NumberSetting
            label="Show tickets after delay (seconds)"
            setting={ticketDelaySetting}
          />
        )}
      </Layout>
    </Layout>
  );
});
