/**
 * Call responses
 * @namespace
 */
export enum CallStatus {
  /** Accepted. The call was answered and the session may begin. */
  Accepted = 1,
  /** Busy. */
  Busy = 2,
  /** Callee not found. */
  CalleeNotFound = 5,
  /** Callee not online. */
  CalleeNotOnline = 4,
  /** Cancelled. */
  Cancelled = 7,
  /** Declined. */
  Declined = 3,
  /** General failure. */
  GeneralFailure = 6,
  /** No answer. */
  NoAnswer = 8,
  /** Unsupported */
  Unsupported = 9,
}
