import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { InitiateCall } from "src/lib/types/InitiateCall";
import type { TerminateCall } from "src/lib/types/TerminateCall";

const log = Logger.getLogger("ThirdPartyCallControlModule");

/**
 * Returned from <code>setupThirdPartyCallControlModule</code> of
 * <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class ThirdPartyCallControlModule {
  private readonly onInitiate: (initiateCall: InitiateCall) => void;
  private readonly onTerminate: (terminateCall: TerminateCall) => void;
  private constructor({
    onInitiate,
    onTerminate,
  }: {
    onInitiate: (initiateCall: InitiateCall) => void;
    onTerminate: (terminateCall: TerminateCall) => void;
  }) {
    /**
     * Callback on third party initiate call request.
     * @member {function(InitiateCall)}
     */
    this.onInitiate = onInitiate;
    /**
     * Callback on third party terminate call request.
     * @member {function(TerminateCall)}
     */
    this.onTerminate = onTerminate;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onInitiate: (initiateCall: InitiateCall) => void;
      onTerminate: (terminateCall: TerminateCall) => void;
    }
  ): Promise<ThirdPartyCallControlModule> {
    await requestManager.send({
      thirdPartyCallControl: { setupRequest: {} },
    });
    log.debug("thirdPartyCallControl module setup.");
    return new ThirdPartyCallControlModule(options);
  }
  public onRequest(
    message: proto.IThirdPartyCallControlAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.initiateCall) {
      this.onInitiateCall(message.initiateCall, respond);
    } else if (message.terminateCall) {
      this.onTerminateCall(message.terminateCall, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  private onInitiateCall(
    message: proto.IThirdPartyCallControlInitiateCall,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onInitiate) {
      this.onInitiate({
        callName: message.callName,
        callableReferences: message.callableReferences ?? undefined,
        referenceId: message.referenceId,
      });
    }
  }
  private onTerminateCall(
    message: proto.IThirdPartyCallControlTerminateCall,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onTerminate) {
      this.onTerminate(message);
    }
  }
}
