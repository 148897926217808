/**
 * Specific GroupTalk errors from this API are of type GroupTalkAPIError.
 * A temporary error indicates that a new attempt could very well succeed
 * while a final error indicates that the problem would persist if retried.
 * @namespace
 */
export class GroupTalkAPIError extends Error {
  /**
   * Error description.
   * @member {string}
   */
  public message: string;
  /**
   * Indicates whether error is temporary.
   * @member {boolean}
   */
  public temporary: boolean;
  public constructor(message: string, temporary: boolean) {
    super(message);
    this.message = message;
    this.temporary = temporary;
  }
  public toString(): string {
    return this.message;
  }
}
