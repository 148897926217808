import { Button } from "src/app/model/input/Button";
import type { ButtonMapper } from "src/app/model/input/ButtonMapper";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";

export class HA11Handler implements DeviceHandler {
  private state = 1;
  public constructor(
    private readonly buttonMapper: ButtonMapper,
    private readonly id: string
  ) {}
  public onData(data: ArrayBuffer): void {
    const newState = new Uint8Array(data)[1];
    if ((this.state & (1 << 0)) !== 0 && (newState & (1 << 0)) === 0) {
      this.buttonMapper.onButtonDown(this.id, Button.PRIMARY);
    }
    if ((this.state & (1 << 0)) === 0 && (newState & (1 << 0)) !== 0) {
      this.buttonMapper.onButtonUp(this.id, Button.PRIMARY);
    }
    this.state = newState;
  }
}
