import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import type { BooleanSetting as Setting } from "src/app/model/settings/BooleanSetting";

export const BooleanSetting = connect<{
  label: string;
  setting: Setting;
}>(({ label, setting }) => (
  <Layout
    alignLeft
    alignMiddle
    className={setting.configurable ? "gt_hoverable" : undefined}
    gap={20}
    row
  >
    <GtCheckbox
      checked={setting.value}
      disabled={!setting.configurable}
      onChange={() => setting.toggle()}
    />
    <Layout
      onClick={() => {
        if (setting.configurable) {
          setting.toggle();
        }
      }}
      alignMiddle
      column
      style={{ cursor: setting.configurable ? "pointer" : "default" }}
    >
      <Text color={setting.configurable ? "#000" : "#0006"}>{label}</Text>
    </Layout>
  </Layout>
));
