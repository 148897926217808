import { observableClass } from "src/app/state/observableClass";
import { action } from "mobx";
import type { State } from "src/app/model/State";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { CallsignModule } from "src/lib/modules/CallsignModule";
import type { CallsignInfo } from "src/lib/types/CallsignInfo";
import type { UserDisplayInfo } from "src/lib/types/UserDisplayInfo";

export class CallsignHandler {
  public callsign?: string;
  public callsignLabel?: string;
  private callsignModule?: CallsignModule;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public getCallsign(
    options: { entityId?: string; userUuid?: string } = {}
  ): Promise<CallsignInfo | null> {
    return this.callsignModule
      ? this.callsignModule.getCallsign(options)
      : Promise.resolve(null);
  }
  public async setup(
    authenticatedModule: AuthenticatedModule,
    options: {
      onCallsignChanged: (userDiplayInfo: UserDisplayInfo) => void;
    }
  ): Promise<void> {
    const module = await authenticatedModule.setupCallsignModule({
      onCallsignChanged: action((userDisplayInfo) => {
        if (options.onCallsignChanged) {
          options.onCallsignChanged(userDisplayInfo);
        }
        this.callsign = userDisplayInfo.callsign;
      }),
    });
    this.callsignModule = module;
    this.callsign = this.callsignModule.callsign;
    this.callsignLabel = this.callsignModule.callsignLabel;
  }
  public updateCallsign(
    options: {
      callsign?: string;
      callsignLabel?: string;
      entityId?: string;
      onUpdated?: (newCallsign: string | undefined) => void;
      userUuid?: string;
    } = {}
  ): void {
    const { callsign, callsignLabel, entityId, onUpdated, userUuid } = options;
    if (this.callsignModule && callsignLabel) {
      const dialog = this.state.dialogs.show({
        actions: [
          {
            label: "Cancel",
            onSelect: () => {
              dialog.close();
            },
          },
          {
            label: "Update",
            onSelect: (result) => {
              void (async () => {
                const newCallsign = result.textField!.value;
                await this.callsignModule?.updateCallsign({
                  callsign: newCallsign,
                  entityId,
                  userUuid,
                });
                dialog.close();
                if (onUpdated) {
                  onUpdated(newCallsign);
                }
              })();
            },
          },
        ],
        textField: {
          hint: callsignLabel,
          type: "search",
          value: callsign || "",
        },
        title: `Set ${callsignLabel}`,
      });
    }
  }
}
