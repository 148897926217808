import { Text } from "src/app/gui/components/Text";
import { MessageIcon, DetachIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import type { Channel } from "src/app/model/channels/Channel";
import type { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { ContactListEntry } from "src/app/model/contacts/ContactListEntry";
import type { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import type { Panel } from "src/app/model/panels/Panel";
import type { Presence } from "src/app/model/presence/Presence";
import type { Ticket } from "src/app/model/queues/Ticket";
import type { RoomParticipant } from "src/app/model/video/RoomParticipant";

export const MessageButton = connect<{
  entity:
    | Channel
    | ContactDetails
    | ContactListEntry
    | Presence
    | RoomParticipant
    | Ticket;
  isDummy?: boolean;
  panel: Panel;
  panelTab?: ChannelTabSetting | ContactTabSetting;
  showMessages: boolean;
}>(({ entity, isDummy, panel, panelTab, showMessages }) => {
  const unreadCount = entity.unreadCount ?? 0;
  return (
    <IconButton
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        if (!isDummy) {
          entity.openOrFocusPanel(
            panel,
            panelTab as ChannelTabSetting & ContactTabSetting
          );
        }
      }}
      style={{
        background: unreadCount > 0 && showMessages ? "#ed2f2f73" : "#00000010",
        borderRadius: 4,
        padding: 0,
        paddingLeft: 4,
        paddingRight: 4,
        width: 45,
      }}
      aria-label="Open as panel"
    >
      {showMessages && unreadCount > 0 && <MessageIcon />}
      {(unreadCount === 0 || !showMessages) && <DetachIcon />}
      {unreadCount > 0 && showMessages && <Text size={16}>{unreadCount}</Text>}
    </IconButton>
  );
});
