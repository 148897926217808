import {
  MarkerDirectionEmergencyIcon,
  MarkerDirectionDefaultIcon,
  MarkerEmergencyIcon,
  MarkerDefaultIcon,
  MarkerOfflineIcon,
  MarkerDirectionOfflineIcon,
} from "src/app/images";
import L from "leaflet";

const nonSelectedScale = 0.7;

const createIcon = (
  url: any
): {
  nonSelected: L.Icon<L.IconOptions>;
  selected: L.Icon<L.IconOptions>;
} => {
  const selectedIcon = L.icon({
    iconAnchor: [10, 10],
    iconSize: [20, 20],
    iconUrl: url,
  });
  const nonSelectedIcon = L.icon({
    iconAnchor: [10 * nonSelectedScale, 10 * nonSelectedScale],
    iconSize: [20 * nonSelectedScale, 20 * nonSelectedScale],
    iconUrl: url,
  });
  return {
    nonSelected: nonSelectedIcon,
    selected: selectedIcon,
  };
};

const createDirectionIcon = (
  url: any
): {
  nonSelected: L.Icon<L.IconOptions>;
  selected: L.Icon<L.IconOptions>;
} => {
  const selectedIcon = L.icon({
    iconAnchor: [10, 38],
    iconSize: [20, 53],
    iconUrl: url,
  });
  const nonSelectedIcon = L.icon({
    iconAnchor: [10 * nonSelectedScale, 38 * nonSelectedScale],
    iconSize: [20 * nonSelectedScale, 53 * nonSelectedScale],
    iconUrl: url,
  });
  return {
    nonSelected: nonSelectedIcon,
    selected: selectedIcon,
  };
};
export const leafletIcons = {
  default: createIcon(MarkerDefaultIcon),
  directionDefault: createDirectionIcon(MarkerDirectionDefaultIcon),
  directionEmergency: createDirectionIcon(MarkerDirectionEmergencyIcon),
  directionOffline: createDirectionIcon(MarkerDirectionOfflineIcon),
  emergency: createIcon(MarkerEmergencyIcon),
  offline: createIcon(MarkerOfflineIcon),
};
