/**
 * Simple helper class for promises that will be resolved in some other place.
 */
export class WrappedPromise<T> {
  public promise: Promise<T>;
  private rejecter?: (value: PromiseLike<T> | T) => void;
  private resolver?: (value: PromiseLike<T> | T) => void;
  public constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolver = resolve;
      this.rejecter = reject;
    });
  }
  public reject(error: any): void {
    this.rejecter?.(error);
  }
  public resolve(value: T): void {
    this.resolver?.(value);
  }
}
