import { ConnectionClosedLocally } from "src/lib/errors/ConnectionClosedLocally";
import { InvalidLoginOrPassword } from "src/lib/errors/InvalidLoginOrPassword";
import { Unauthorized } from "src/lib/errors/Unauthorized";
import { UpgradeNeeded } from "src/lib/errors/UpgradeNeeded";
import { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import { KeepAliveModule } from "src/lib/modules/KeepAliveModule";
import { PartialToken } from "src/lib/modules/PartialToken";
import { Token } from "src/lib/modules/Token";
import { proto } from "src/lib/protobuf/proto";
import { appendBuffer } from "src/util/appendBuffer";
import { Logger } from "src/util/Logger";
import md5 from "js-md5";
import { stringToUtf8ByteArray } from "utf8-string-bytes";
import type { RequestManager } from "src/lib/RequestManager";
import type { CancelListener } from "src/lib/types/CancelListener";
import type { Capabilities } from "src/lib/types/Capabilities";

const log = Logger.getLogger("CapabilitiesModule");
/**
 * Returned from <code>requestCapabilities</code> of <code>{@link ConnectedModule}</code>.
 * @namespace
 */
export class CapabilitiesModule {
  public authenticatedModule?: AuthenticatedModule;
  public capabilities: Capabilities;
  /**
   * Id used when referring to this connection through side channels.
   * @member {string}
   */
  public connectionId: string;
  /**
   * Device id.
   * @member {string}
   */
  public deviceId: string;
  /**
   * User agent.
   * @member {string}
   */
  public userAgent: string;
  private keepAliveModule?: KeepAliveModule;
  private password?: string;
  private tokens?: Token[];
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      capabilities: proto.ICapabilitiesResponse;
      deviceId: string;
    }
  ) {
    const { capabilities } = options;
    /**
     * Capabilities of the server.
     * @member {Capabilities}
     */
    this.capabilities = {
      audio: capabilities.supportsAudioAPIv1 ?? false,
      authenticate: capabilities.supportsAuthenticateAPIv1 ?? false,
      callsign: capabilities.supportsCallsignAPIv2 ?? false,
      channel: capabilities.supportsChannelAPIv1 ?? false,
      customAction: capabilities.supportsCustomActionAPIv1 ?? false,
      detach: capabilities.supportsDetachAPIv1 ?? false,
      emergency: capabilities.supportsEmergencyAPIv1 ?? false,
      goOffline: capabilities.supportsGoOfflineAPIv1 ?? false,
      httpRequest: capabilities.supportsHTTPRequestAPIv1 ?? false,
      keepAlive: capabilities.supportsKeepAliveAPIv1 ?? false,
      locationEnquiry: capabilities.supportsLocationEnquiryAPIv1 ?? false,
      locationReport: capabilities.supportsLocationReportAPIv1 ?? false,
      phoneBook: capabilities.supportsPhoneBookAPIv1 ?? false,
      presence: capabilities.supportsPresenceAPIv1 ?? false,
      queueManagement: capabilities.supportsQueueManagementAPIv1 ?? false,
      receiveCall: capabilities.supportsReceiveCallAPIv1 ?? false,
      session: capabilities.supportsSessionAPIv1 ?? false,
      setupCall: capabilities.supportsSetupCallAPIv1 ?? false,
      sms: capabilities.supportsSMSAPIv1 ?? false,
      status: capabilities.supportsStatusAPIv1 ?? false,
      talkburstReception: capabilities.supportsTalkburstReceptionAPIv1 ?? false,
      talkburstTransmission:
        capabilities.supportsTalkburstTransmissionAPIv1 ?? false,
      thirdPartyCallControl:
        capabilities.supportsThirdPartyCallControlAPIv1 ?? false,
      udp: capabilities.supportsUDPAPIv1 ?? false,
      webapp: capabilities.supportsWebappAPIv1 ?? false,
    };
    this.userAgent = capabilities.userAgent;
    this.deviceId = options.deviceId;
    this.connectionId = capabilities.connectionId;
  }
  public static async setup(
    requestManager: RequestManager,
    options: { deviceId?: string; userAgent?: string } = {}
  ): Promise<CapabilitiesModule> {
    const { deviceId = "web", userAgent = "" } = options;
    if (!userAgent) {
      throw new Error("User agent not specified");
    }
    try {
      const capabilities = (await requestManager.send({
        capabilities: { deviceId, userAgent: userAgent.substring(0, 195) },
      })) as proto.CapabilitiesResponse;
      log.debug(
        `capabilties module setup with deviceId ${deviceId}`,
        capabilities
      );
      return new CapabilitiesModule(requestManager, {
        capabilities,
        deviceId,
      });
    } catch (error: any) {
      requestManager.stop(error);
      throw error;
    }
  }
  /**
   * Start authentication using a specific token. If authentication fails,
   * the connection will be closed with an error.
   * @param {Token|Array<Token>} token The token associated with this device to use when
   * authenticating or an array of tokens with different realms.
   * @param {boolean} [options.purchaseUsage=false] Authorize purchase of usage based
   * licenses if needed to go online.
   * @returns {Promise<AuthenticatedModule>} Module that can be used when authenticated.
   */
  public async authenticate(
    token: Token | Token[],
    options: {
      purchaseUsage?: boolean;
    }
  ): Promise<AuthenticatedModule> {
    if (this.capabilities.authenticate) {
      if (
        !token ||
        (!(token instanceof Token) &&
          (!Array.isArray(token) || token.length === 0))
      ) {
        const error = new Unauthorized();
        this.requestManager.stop(error);
        throw error;
      }
      this.tokens = Array.isArray(token) ? token : [token];
      try {
        this.authenticatedModule = await AuthenticatedModule.setup(
          this.requestManager,
          this,
          options
        );
        return this.authenticatedModule;
      } catch (error: any) {
        this.requestManager.stop(error);
        throw error;
      }
    } else {
      throw new UpgradeNeeded();
    }
  }
  /**
   * Start authentication using a specific jwt. If authentication fails,
   * the connection will be closed with an error.
   * @param {String} jwt The jwt associated with this device to use when
   * authenticating.
   * @param {boolean} [options.purchaseUsage=false] Authorize purchase of usage based
   * licenses if needed to go online.
   * @returns {Promise<AuthenticatedModule>} Module that can be used when authenticated.
   */
  public async authenticateWithJWT(
    jwt: string,
    options: {
      purchaseUsage?: boolean;
    } = {}
  ): Promise<AuthenticatedModule> {
    if (this.capabilities.authenticate) {
      try {
        this.authenticatedModule = await AuthenticatedModule.setup(
          this.requestManager,
          this,
          { ...options, jwt }
        );
        return this.authenticatedModule;
      } catch (error: any) {
        this.requestManager.stop(error);
        throw error;
      }
    } else {
      throw new UpgradeNeeded();
    }
  }
  /**
   * Deauthenticate a specific token. If deauthentication fails,
   * the connection will be closed and an exception thrown.
   * @param {Token|Array<Token>} token The token associated with this device to use when
   * deauthenticating or an array of tokens with different realms.
   * @param {boolean} allDevices If true will deauthenticate all devices of the user associated
   * with the token.
   * @returns {Promise} Promise which will resolve when deauthenticated.
   */
  public async deauthenticate(
    token: Token | Token[],
    allDevices = false
  ): Promise<void> {
    if (
      !token ||
      (!(token instanceof Token) &&
        (!Array.isArray(token) || token.length === 0))
    ) {
      const error = new Unauthorized();
      this.requestManager.stop(error);
      throw error;
    }
    this.tokens = Array.isArray(token) ? token : [token];
    try {
      await this.requestManager.send({
        authenticate: { deauthenticateRequest: { allDevices } },
      });
      log.debug("Deauthenticated successful.");
    } catch (error: any) {
      this.requestManager.stop(error);
      throw error;
    }
  }
  /**
   * Go offline in an orderly fashion.
   * @returns {Promise} Promise which will resolve when offline.
   */
  public async goOffline(): Promise<void> {
    if (this.capabilities.goOffline) {
      log.debug("GoOffline request");
      try {
        await this.requestManager.send({ goOffline: {} });
      } catch (error: any) {
        log.debug(`GoOffline response: ${error}`);
      }
    } else {
      log.debug("GoOffline request not supported. Terminating connection...");
    }
    this.requestManager.stop(new ConnectionClosedLocally());
  }
  public onDisconnect(): void {
    if (this.keepAliveModule) {
      this.keepAliveModule.onDisconnect();
    }
    if (this.authenticatedModule) {
      this.authenticatedModule.onDisconnect();
    }
  }
  public onRequest(
    message: proto.IServerMessage,
    respond: (code: proto.ResponseCode) => void
  ): CancelListener {
    if (message.keepAlivePing && this.keepAliveModule) {
      this.keepAliveModule.onKeepAlivePing(message, respond);
    } else if (
      message.authenticate &&
      message.authenticate.passwordChallengeRequest
    ) {
      this.onPasswordChallengeRequest(
        message.authenticate.passwordChallengeRequest,
        respond
      );
    } else if (
      message.authenticate &&
      message.authenticate.tokenChallengeRequest
    ) {
      this.onTokenChallengeRequest(
        message.authenticate.tokenChallengeRequest,
        respond
      );
    } else if (this.authenticatedModule) {
      return this.authenticatedModule.onRequest(message, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
    return () => {};
  }
  /**
   * Two factor authentication with email. An email will be sent with a code
   * that needs to be supplied to the returned <code>{@link PartialToken}</code> to convert
   * it to a valid <code>{@link Token}</code>.
   * @param {string} email The configured email to which the verification code will be sent.
   * @returns {Promise<PartialToken>}
   */
  public async requestTokenViaEmail(email: string): Promise<PartialToken> {
    const response = await this.requestToken(
      proto.CreateTokenRequest.AuthenticationMethod.EMAIL,
      email
    );
    return new PartialToken(response.tokenId, response.token, response.realm);
  }
  /**
   * Authentication via username/email and password.
   * @param {string} usernameOrEmail
   * @param {string} password
   * @returns {Promise} Promise of a <code>{@link Token}</code> which can be saved and
   * used to connect multiple times.
   */
  public async requestTokenViaPassword(
    usernameOrEmail: string,
    password: string
  ): Promise<Token> {
    if (
      !usernameOrEmail ||
      usernameOrEmail.length === 0 ||
      !password ||
      password.length === 0
    ) {
      this.requestManager.stop(new InvalidLoginOrPassword());
      throw new InvalidLoginOrPassword();
    }
    this.password = password;
    const response = await this.requestToken(
      proto.CreateTokenRequest.AuthenticationMethod.USERNAME,
      usernameOrEmail
    );
    return new Token(response.tokenId, response.token, response.realm);
  }
  /**
   * Two factor authentication with sms. A sms will be sent with a code
   * that needs to be supplied to the returned <code>{@link PartialToken}</code> to convert
   * it to a valid <code>{@link Token}</code>.
   * @param {string} sms The configured phone number to which the verification code will be sent.
   * @returns {Promise<PartialToken>}
   */
  public async requestTokenViaSMS(sms: string): Promise<PartialToken> {
    const response = await this.requestToken(
      proto.CreateTokenRequest.AuthenticationMethod.PHONENUMBER,
      sms
    );
    return new PartialToken(response.tokenId, response.token, response.realm);
  }
  /**
   * Start the keep alive module. The server will send keep alive requests regularly to keep
   * the connection open. If no messages are received within the negotiated time, the connection
   * is assumed broken and will be closed.
   * @param {number} options.suggestedInterval Suggested interval in seconds. Must be an integer.
   * @returns {Promise<integer>} Promise of the negotiated keep alive interval in seconds.
   */
  public async setupKeepAlive(
    options: {
      suggestedInterval?: number;
    } = {}
  ): Promise<number> {
    if (this.capabilities.keepAlive) {
      this.keepAliveModule = await KeepAliveModule.setup(
        this.requestManager,
        options
      );
      return this.keepAliveModule.interval;
    }
    throw new UpgradeNeeded();
  }
  private onPasswordChallengeRequest(
    message: proto.IPasswordChallengeRequest,
    respond: (
      code: proto.ResponseCode,
      response?: proto.IPasswordChallengeResponse
    ) => void
  ): void {
    const { prefix, suffix } = message;
    if (this.password) {
      const passwordBuffer = new Uint8Array(
        stringToUtf8ByteArray(this.password)
      );
      this.password = undefined;
      const combinedBuffer = appendBuffer(prefix, passwordBuffer);
      const prefixAndPassword = new Uint8Array(md5.arrayBuffer(combinedBuffer));
      const hash = new Uint8Array(
        md5.arrayBuffer(appendBuffer(prefixAndPassword, suffix))
      );
      respond(proto.ResponseCode.OK, { hashResponse: hash });
    } else {
      respond(proto.ResponseCode.NOT_FOUND);
    }
  }
  private onTokenChallengeRequest(
    message: proto.ITokenChallengeRequest,
    respond: (
      code: proto.ResponseCode,
      response: proto.ITokenChallengeResponse
    ) => void
  ): void {
    if (!this.tokens) {
      log.warn("No token in onTokenChallengeRequest");
    } else {
      const { acceptedRealms, nonce } = message;
      const token = acceptedRealms
        ? this.tokens.find((t) => acceptedRealms.includes(t.realm))
        : undefined;
      if (token) {
        const secretBuffer = new Uint8Array(
          stringToUtf8ByteArray(token.secret)
        );
        const hashedSecretArrayBuffer = appendBuffer(secretBuffer, nonce);
        const hashedSecret = new Uint8Array(
          md5.arrayBuffer(hashedSecretArrayBuffer)
        );
        respond(proto.ResponseCode.OK, {
          hashedSecret,
          realm: token.realm,
          tokenId: token.id,
        });
      } else {
        log.warn("Found no acceptedRealm");
        this.requestManager.stop(new Unauthorized());
      }
    }
  }
  private async requestToken(
    method: proto.CreateTokenRequest.AuthenticationMethod,
    data: string
  ): Promise<proto.ICreateTokenResponse> {
    if (this.capabilities.authenticate) {
      try {
        return (await this.requestManager.send({
          authenticate: { createTokenRequest: { data, method } },
        })) as proto.ICreateTokenResponse;
      } catch (error: any) {
        this.requestManager.stop(error);
        throw error;
      }
    } else {
      throw new UpgradeNeeded();
    }
  }
}
