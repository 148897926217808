import { GtTextField } from "src/app/gui/components/GtTextField";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import { Slider } from "@mui/material";
import { action } from "mobx";
import type { NumberParameterSetting } from "src/app/model/settings/NumberParameterSetting";
import type { NumberSetting as Setting } from "src/app/model/settings/NumberSetting";

export const NumberSetting = connect<{
  label: string;
  onUpdated?: (value: number | string) => void;
  setting: NumberParameterSetting | Setting;
  slider?: boolean;
}>(({ label, onUpdated, setting, slider }) => (
  <Layout alignLeft alignMiddle gap={20} row>
    <Layout alignMiddle column width={130}>
      <Text>{label}</Text>
    </Layout>
    <Layout padding={8} width={100}>
      {(slider &&
        setting.maxValue !== undefined &&
        setting.minValue !== undefined && (
          <Slider
            onChange={(_, value) => {
              setting.setValue(value.toString());
            }}
            onChangeCommitted={action(() => {
              setting.validate();
              if (onUpdated) {
                onUpdated(setting.value);
              }
            })}
            disabled={!setting.configurable}
            max={setting.maxValue}
            min={setting.minValue}
            step={(setting.maxValue - setting.minValue) / 100}
            value={Number.parseFloat(setting.unvalidated)}
          />
        )) || (
        <GtTextField
          onValidate={() => {
            setting.validate();
            if (onUpdated) {
              onUpdated(setting.value);
            }
          }}
          disabled={!setting.configurable}
          fullWidth={false}
          onValueChanged={(value) => setting.setValue(value)}
          style={{ textAlign: "center" }}
          value={setting.unvalidated}
        />
      )}
    </Layout>
  </Layout>
));
