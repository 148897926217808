import { observableClass } from "src/app/state/observableClass";
import { AudioManager } from "src/audio/html5/AudioManager";
import { Logger } from "src/util/Logger";
import { action, autorun } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { State } from "src/app/model/State";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { IncomingTalkburst } from "src/lib/modules/IncomingTalkburst";
import type { OutgoingTalkburst } from "src/lib/modules/OutgoingTalkburst";
import type { CodecSettings } from "src/lib/types/CodecSettings";

const log = Logger.getLogger("Audio");

export class Audio {
  public showNoMicrophoneInput = false;
  private audioManager?: AudioManager;
  private disposer?: IReactionDisposer;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public closeDown(): void {
    this.audioManager?.release();
    this.audioManager = undefined;
    this.disposer?.();
    this.disposer = undefined;
  }
  public replayTalkburst({
    onFinished,
    talkburst,
  }: {
    onFinished: () => void;
    talkburst: IncomingTalkburst;
  }): void {
    this.audioManager?.replayTalkburst({
      onFinished,
      talkburst,
    });
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    let codecSettings: CodecSettings;
    const disposer = autorun(() => {
      codecSettings = {
        opus: {
          bandwidth: this.state.settings.codecSettings.opus.bandwidth.selected
            .id as number,
          bitrate: this.state.settings.codecSettings.opus.bitrate.value,
          expectedPacketLoss:
            this.state.settings.codecSettings.opus.expectedPacketLoss.value,
          forwardErrorCorrection:
            this.state.settings.codecSettings.opus.forwardErrorCorrection.value,
          maxFrameSize:
            this.state.settings.codecSettings.opus.maxFrameSize.value,
          variableBitrate:
            this.state.settings.codecSettings.opus.variableBitrate.value,
        },
      };
      log.debug(`Codec settings are ${JSON.stringify(codecSettings)}`);
    });
    const audioManager = await AudioManager.setup(authenticatedModule, {
      codecSettings: () => codecSettings,
      muted: action((talkburst) => {
        const mutegroup =
          (this.state.online &&
            this.state.online?.sessions.muteGroups?.selected) ??
          undefined;
        return !!(
          talkburst.muteGroupId &&
          mutegroup !== undefined &&
          talkburst.muteGroupId === mutegroup.id
        );
      }),
      onReportRecordingStatus: action((hasRecording: boolean) => {
        if (this.state.settings.soundInput.inputWarning.value) {
          if (!hasRecording) {
            if (this.state.online) {
              this.showNoMicrophoneInput = true;
            }
            this.state.flashMessage.warn({ message: "No microphone input" });
          } else {
            if (this.state.online) {
              this.showNoMicrophoneInput = false;
            }
            this.state.flashMessage.info({
              message: "Microphone input detected",
            });
          }
        } else {
          this.showNoMicrophoneInput = false;
        }
      }),
      onTalkburstStart: action((talkburst: IncomingTalkburst) => {
        if (talkburst.session && this.state.online) {
          const session = this.state.online.sessions.list.find(
            (s) => s.sessionId === talkburst.session!.sessionId
          );
          if (session) {
            this.state.online.sessions.lastActiveSession = session;
            session.setLastIncomingTalkburst(talkburst);
          }
        }
      }),
      onTalkburstStop: action((talkburst) => {
        if (talkburst.session && this.state.online) {
          const session = this.state.online.sessions.list.find(
            (s) => s.sessionId === talkburst.session!.sessionId
          );
          if (
            session &&
            session.incomingTalkburst &&
            session.incomingTalkburst.talkburstId === talkburst.talkburstId
          ) {
            session.incomingTalkburst = null;
          }
        }
      }),
      volume: (libSession) => {
        const session =
          this.state.online &&
          this.state.online.sessions.list.find(
            (s) => s.sessionId === libSession.sessionId
          );
        return action(() => (session ? session.actualVolume : 0));
      },
    });
    this.audioManager = audioManager;
    this.disposer = disposer;
  }
  public startTransmission({
    delay,
    muteGroupId,
    onInterrupt,
    onStopped,
    sessionId,
  }: {
    delay: number;
    muteGroupId?: string;
    onInterrupt: () => void;
    onStopped: () => void;
    sessionId: number;
  }): Promise<OutgoingTalkburst | undefined> {
    if (this.audioManager) {
      return this.audioManager.startTransmission({
        delay,
        muteGroupId,
        onInterrupt,
        onStopped,
        sessionId,
      });
    }
    return Promise.resolve(undefined);
  }
}
