export enum PresenceType {
  /** Dialed in user. */
  Dialed = 4,
  /** Dispatcher user. */
  Dispatcher = 3,
  /** Patched group. */
  Group = 6,
  /** Mobile user. */
  Mobile = 2,
  /** Bridged in radio. */
  Radio = 5,
  /** Unknown type. */
  Unknown = 1,
}
