export enum TemplateId {
  channel = "channel",
  channels = "channels",
  contact = "contact",
  contacts = "contacts",
  emergencyTicket = "emergencyTicket",
  location = "location",
  opentickets = "opentickets",
  patch = "patch",
  /**
   * Deprecated
   */
  pickedtickets = "pickedtickets",
  priorityTicket = "priorityTicket",
  sessions = "sessions",
  ticket = "ticket",
}
