import { observableClass } from "src/app/state/observableClass";
import { NotFound } from "src/lib/errors/NotFound";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { StatusModule } from "src/lib/modules/StatusModule";
import type { Status } from "src/lib/types/Status";

const log = Logger.getLogger("status");

export class StatusHandler {
  public currentStatus?: Status;
  private availableStatusesList?: Status[];
  private statusModule?: StatusModule;
  public constructor() {
    observableClass(this);
  }
  public get availableStatuses(): Status[] {
    return this.availableStatusesList || [];
  }
  public get statusAvailable(): boolean {
    return !!(this.statusModule && this.statusModule.statusAvailable);
  }
  public async lookupAvailableStatuses(
    options: {
      entityId?: string;
      userUuid?: string;
    } = {}
  ): Promise<Status[] | undefined> {
    try {
      const statuses = await this.statusModule?.availableStatuses(
        options.userUuid,
        options.entityId
      );
      log.debug("Available statuses", statuses);
      return statuses;
    } catch (error) {
      if (error instanceof NotFound) {
        // No status available for this
      }
    }
    return [];
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    const module = await authenticatedModule.setupStatusModule({
      onStatusChange: action((status) => {
        log.debug("Status changed", status);
        this.currentStatus = status;
      }),
    });
    this.statusModule = module;
    this.currentStatus = this.statusModule.currentStatus;
    const statuses = await this.lookupAvailableStatuses();
    this.availableStatusesList = statuses;
  }
  public updateStatus(
    options: { entityId?: string; statusUuid?: string; userUuid?: string } = {}
  ): void {
    if (this.statusModule) {
      void this.statusModule.updateStatus(options);
    }
  }
}
