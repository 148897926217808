import { observableClass } from "src/app/state/observableClass";

export class Tooltip {
  public id: null | string;
  public constructor() {
    this.id = null;
    observableClass(this);
  }
  public hide(id?: string): void {
    if (!id || this.id === id) {
      this.id = null;
    }
  }
  public show(id: string): void {
    this.id = id;
  }
}
