import { Server } from "src/app/model/appData/Server";
import { UserData } from "src/app/model/appData/UserData";
import { loginHints } from "src/app/model/loginHints";
import { observableClass } from "src/app/state/observableClass";
import { GeneralFailure } from "src/lib/errors/GeneralFailure";
import { MissingRefreshToken } from "src/lib/errors/MissingRefreshToken";
import { AuthenticationModule } from "src/nextgen/AuthenticationModule";
import type { State } from "src/app/model/State";

export class Login {
  public authenticationModule?: AuthenticationModule;
  public error: null | string = null;
  public loading = true;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public async init(
    options: { removeQueryParams?: boolean; showServer?: boolean } = {}
  ): Promise<void> {
    const { removeQueryParams = false, showServer = true } = options;
    if (removeQueryParams) {
      Login.removeQueryParams();
    }
    const authenticationModule = new AuthenticationModule(
      Server.authParams,
      loginHints()
    );
    this.authenticationModule = authenticationModule;
    window.addEventListener("storage", (event) => {
      if (event.key === "logout") {
        authenticationModule.logoutFromExternal();
      }
    });
    try {
      const isLogoutRequest = authenticationModule.isLogoutRequest();
      const hasAccessTokenFromCode =
        authenticationModule.fetchAccessTokenByCode();
      if (isLogoutRequest) {
        this.loading = false;
        // We have written to local storage, and other instances of the dispatcher will
        // logout as a consequence. There's really nothing else to do here
      } else if (
        !Server.showServerSelect ||
        hasAccessTokenFromCode ||
        !showServer
      ) {
        this.loading = true;
        this.error = null;
        await authenticationModule.login();
        if (authenticationModule.idToken === undefined) {
          throw new GeneralFailure();
        }
        const { name, orgName, orgUuid } = authenticationModule.idToken!;
        this.state.userData.setDisplayName(name);
        this.state.userData.setOrganization(orgName);
        this.state.userData.setOrganizationUuid(orgUuid);
        Login.makeDeviceId();
        this.loading = false;
      } else {
        // In this case user need to manually select a server before doing anything
        this.loading = false;
      }
    } catch (err: any) {
      if (!(err instanceof MissingRefreshToken)) {
        this.error = err.message;
        this.loading = false;
      }
    }
  }
  private static makeDeviceId(): void {
    if (!UserData.deviceId) {
      UserData.setRandomDeviceId();
    }
  }
  private static removeQueryParams(): void {
    window.history.pushState({}, document.title, window.location.pathname);
  }
}
