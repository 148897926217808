import { GtError } from "src/app/gui/components/GtError";
import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { Layout } from "src/app/gui/components/Layout";
import { GroupSelectionRow } from "src/app/gui/settings/GroupSelectionRow";
import { GroupSelectionSetting } from "src/app/model/settings/GroupSelectionSetting";
import { connect } from "src/app/state/connect";
import { useEffect } from "react";

export const GroupSelection = connect<{
  id: string;
}>(({ id }, state) => {
  const { closeDown, filterSetting, groups, setup } =
    state.settings.groupSelectionSettings;
  const { channels, error, loadMore, loading } = groups;
  let noElementsMessage = "No groups available.";
  if (filterSetting.value.length > 0) {
    noElementsMessage = "No groups matching filter";
  }
  useEffect(() => {
    setup();
    return () => {
      closeDown();
    };
  });
  return (
    <Layout column>
      <Layout grow row />
      {error && <GtError message={error} />}
      <Layout alignTop column grow marginTop={10} minHeight={150}>
        <GtInfiniteRenderer
          elements={channels.map((channel, index) => (
            <GroupSelectionRow
              onClick={() =>
                GroupSelectionSetting.select(id, channel.channelUuid)
              }
              checked={GroupSelectionSetting.selected(id, channel.channelUuid)}
              description={channel.description}
              evenRow={index % 2 === 1}
              key={channel.channelUuid}
              name={channel.name}
              type={channel.joined ? "joined_group" : "joinable_group"}
            />
          ))}
          loadMore={loadMore}
          loading={loading}
          noElements={noElementsMessage}
        />
      </Layout>
    </Layout>
  );
});
