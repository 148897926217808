import { action, toJS } from "mobx";

export const createState = <T extends { new (): any }>(
  StateState: T
): InstanceType<T> => {
  const state = action(() => new StateState())();
  Object.assign(window, {
    // Convenience to have access to store in console
    get R() {
      return state;
    },
    get action() {
      return (f: any) => action(f)();
    },
    state() {
      return toJS(state);
    },
  });
  return state;
};
