// eslint-disable-next-line no-restricted-imports
import packageJson from "../../../package.json";
import { Server } from "src/app/model/appData/Server";
import { UserData } from "src/app/model/appData/UserData";
import { SoundService } from "src/app/model/audio/SoundService";
import { Connecting } from "src/app/model/Connecting";
import { ContactManagement } from "src/app/model/contacts/ContactManagement";
import { Dialogs } from "src/app/model/dialogs/Dialogs";
import { FlashMessage } from "src/app/model/flashMessage/FlashMessage";
import { Login } from "src/app/model/Login";
import { Menu } from "src/app/model/Menu";
import { Offline } from "src/app/model/Offline";
import { Layout } from "src/app/model/panels/Layout";
import { Panels } from "src/app/model/panels/Panels";
import { Settings } from "src/app/model/settings/Settings";
import { Tooltip } from "src/app/model/tooltip/Tooltip";
import { observableClass } from "src/app/state/observableClass";
import { GraphQLModule } from "src/nextgen/GraphQLModule";
import type { Online } from "src/app/model/Online";

export class State {
  public readonly connecting: Connecting;
  public readonly contactManagement: ContactManagement;
  public readonly dialogs: Dialogs;
  public readonly flashMessage: FlashMessage;
  public readonly graphqlModule: GraphQLModule;
  public readonly layout: Layout;
  public readonly login: Login;
  public readonly menu: Menu;
  public readonly offline: Offline;
  public readonly panels: Panels;
  public readonly title: string;
  public readonly tooltip: Tooltip;
  public readonly userAgent: string;
  public readonly userData: UserData;
  public readonly version: string;
  public online: Online | null;
  public settings: Settings;
  public constructor() {
    this.settings = new Settings(this);
    this.flashMessage = new FlashMessage();
    this.login = new Login(this);
    this.offline = new Offline(this);
    this.connecting = new Connecting(this);
    this.panels = new Panels(this);
    this.layout = new Layout(this);
    this.menu = new Menu(this);
    this.tooltip = new Tooltip();
    this.title = window.gtConfig.title;
    this.version = `v${packageJson.version}`;
    this.userAgent = `GroupTalk Dispatcher/${packageJson.version} (${window.navigator.userAgent})`;
    this.userData = new UserData();
    this.dialogs = new Dialogs();
    this.contactManagement = new ContactManagement(this);
    this.online = null;
    SoundService.preloadSounds();
    void this.login.init();
    this.graphqlModule = new GraphQLModule(
      this.login.authenticationModule!,
      Server.graphQLApi
    );
    observableClass(this);
  }
}
