import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Not acceptable here.
 */
export class NotAcceptableHere extends GroupTalkAPIError {
  public constructor() {
    super("Not acceptable here.", true);
  }
}
