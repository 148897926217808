import { Session } from "src/lib/modules/Session";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Codec } from "src/lib/types/Codec";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { MuteGroup } from "src/lib/types/MuteGroup";
import type { SessionMonitoringStatus } from "src/lib/types/SessionMonitoringStatus";
import type { SessionStopped } from "src/lib/types/SessionStopped";

const log = Logger.getLogger("SessionModule");

/**
 * Returned from <code>setupSessionModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class SessionModule {
  /**
   * List of mute groups
   * @member {Array<MuteGroup>}
   */
  public muteGroups?: MuteGroup[];
  /**
   * Callback when monitoring status changes
   * @member {function(SessionMonitoringStatus)}
   */
  public onMonitored?: (status: SessionMonitoringStatus) => void;
  /**
   * Callback when a session starts.
   * @member {function(Session)}
   */
  public onStarted?: (session: Session) => void;
  /**
   * Callback when a session stops.
   * @member {function(SessionStopped)}
   */
  public onStopped?: (sessionStopped: SessionStopped) => void;
  public sessions: Record<number, Session> = {};
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      muteGroups?: MuteGroup[];
      onMonitored?: (status: SessionMonitoringStatus) => void;
      onStarted?: (session: Session) => void;
      onStopped?: (sessionStopped: SessionStopped) => void;
    }
  ) {
    this.onStarted = options.onStarted;
    this.onStopped = options.onStopped;
    this.onMonitored = options.onMonitored;
    this.muteGroups = options.muteGroups;
    this.sessions = {};
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      maxCalls?: number;
      maxSessions?: number;
      onMonitored?: (status: SessionMonitoringStatus) => void;
      onStarted?: (session: Session) => void;
      onStopped?: (sessionStopped: SessionStopped) => void;
    } = {}
  ): Promise<SessionModule> {
    const {
      maxCalls = 32,
      maxSessions = 32,
      onMonitored,
      onStarted,
      onStopped,
    } = options;
    const response = (await requestManager.send({
      session: {
        setupRequest: {
          acceptedCodecs: [Codec.iLBC, Codec.Opus],
          maxCalls,
          maxSessions,
          supportsMonitoring: onMonitored != null,
          supportsTwilioFullDuplex: true,
        },
      },
    })) as proto.ISessionModuleSetupResponse;
    log.debug("session module setup.", response);
    const { muteGroups } = response;
    return new SessionModule(requestManager, {
      muteGroups: muteGroups != null ? muteGroups : undefined,
      onMonitored,
      onStarted,
      onStopped,
    });
  }
  public onRequest(
    message: proto.ISessionAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.sessionStarted) {
      this.onSessionStarted(message.sessionStarted, respond);
    } else if (message.sessionStopped) {
      this.onSessionStopped(message.sessionStopped, respond);
    } else if (message.sessionAcceptedCodecUpdated) {
      this.onSessionAcceptedCodecUpdated(
        message.sessionAcceptedCodecUpdated,
        respond
      );
    } else if (message.sessionMonitoringStatus) {
      this.onSessionMonitoringStatus(message.sessionMonitoringStatus, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  private onSessionAcceptedCodecUpdated(
    message: proto.ISessionAcceptedCodecUpdated,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    const { acceptedCodecs, sessionId } = message;
    const session = this.sessions[longToNumber(sessionId)];
    if (session != null) {
      session.acceptedCodecs = acceptedCodecs ?? [];
    } else {
      log.warn("Cannot find session on codec updated event.");
    }
  }
  private onSessionMonitoringStatus(
    message: proto.ISessionMonitoringStatus,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onMonitored) {
      const { monitored } = message;
      const sessionId = longToNumber(message.sessionId);
      const session = this.sessions[sessionId];
      if (session != null) {
        this.onMonitored({ monitored, sessionId });
      } else {
        log.warn("Cannot find session on monitoring status event.");
      }
    }
  }
  private onSessionStarted(
    message: proto.ISessionStarted,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    const session = new Session(this.requestManager, message);
    this.sessions[session.sessionId] = session;
    if (this.onStarted) {
      this.onStarted(session);
    }
  }
  private onSessionStopped(
    message: proto.ISessionStopped,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    const last = message.last ?? undefined;
    const sessionId = longToNumber(message.sessionId);
    delete this.sessions[sessionId];
    if (this.onStopped) {
      this.onStopped({ last, sessionId });
    }
  }
}
