import { proto } from "src/lib/protobuf/proto";
import { CallStatus } from "src/lib/types/CallStatus";
import type { CallReferenceNodeId } from "src/lib/types/CallReferenceNodeId";
import type { PhoneBookEntryNodeId } from "src/lib/types/PhoneBookEntryNodeId";

/**
 * Obtained from <code>onIncomingCall</code> callback of <code>{@link ReceiveCallModule}</code>.
 * @namespace
 */
export class IncomingCall {
  /**
   * Corresponds to callRef in the session that will be started.
   * @member {string}
   */
  public callRef?: CallReferenceNodeId;
  /**
   * Empty if client is only callee.
   * @member {Array<string>}
   */
  public callees?: string[];
  /**
   * Name of caller.
   * @member {string}
   */
  public caller: string;
  /**
   * May be <code>null</code>. Otherwise used to call back.
   * @member {string}
   */
  public callerPhonebookEntryId?: PhoneBookEntryNodeId;
  /**
   * Persistant reference for callback to caller and all callees.
   * @member {string}
   */
  public everyoneCallableReference?: string;
  /**
   * True if incoming call is a full duplex call
   * @member {boolean}
   */
  public isFullDuplex: boolean;
  public onCancel?: () => void;
  private readonly responder: (code: proto.ResponseCode) => void;
  public constructor(
    callStart: proto.IReceiveCallStart,
    responder: (code: proto.ResponseCode) => void
  ) {
    this.responder = responder;
    this.caller = callStart.caller;
    this.callees = callStart.callees || [];
    this.callerPhonebookEntryId = callStart.callerPhoneBookEntryId ?? undefined;
    this.everyoneCallableReference =
      callStart.everyoneCallableReference ?? undefined;
    this.callRef = callStart.callRef ?? undefined;
    this.isFullDuplex = callStart.fullDuplex ?? false;
  }
  /**
   * Respond to the incoming call.
   * @param {CallStatus} callStatus
   */
  public respond(callStatus: CallStatus): void {
    switch (callStatus) {
      case CallStatus.Accepted:
        this.responder(proto.ResponseCode.OK);
        break;
      case CallStatus.Busy:
        this.responder(proto.ResponseCode.BUSY_HERE);
        break;
      case CallStatus.Declined:
        this.responder(proto.ResponseCode.DECLINED);
        break;
      case CallStatus.CalleeNotOnline:
        this.responder(proto.ResponseCode.NOT_ONLINE);
        break;
      case CallStatus.CalleeNotFound:
        this.responder(proto.ResponseCode.NOT_FOUND);
        break;
      case CallStatus.Cancelled:
        this.responder(proto.ResponseCode.REQUEST_TERMINATED);
        break;
      case CallStatus.NoAnswer:
        this.responder(proto.ResponseCode.TEMPORARILY_UNAVAILABLE);
        break;
      default:
        this.responder(proto.ResponseCode.GENERAL_FAILURE);
        break;
    }
  }
  /**
   * Used to notify server that we are currently ringing. This should be called
   * every 6 seconds or so corresponding to actual interval of ringing sounds.
   */
  public ringing(): void {
    this.responder(proto.ResponseCode.RINGING);
  }
  /**
   * Add a cancel listener
   * @param {function()} onCancel Callback handling canceled calls.
   */
  public setOnCancelListener(onCancel: () => void): void {
    this.onCancel = onCancel;
  }
}
