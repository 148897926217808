import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { LockIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import React from "react";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const LockGroupMenu = connect<{
  channel: Channel;
  children: React.ReactElement;
  panel: Panel;
}>(({ channel, children, panel }) => (
  <GtMenu
    items={[
      <GtMenuItem
        onSelect={() => {
          channel.updateLocked(!channel.locked);
        }}
        icon={<LockIcon />}
        key="lock"
        label={channel.locked ? "Unlock group" : "Lock group"}
      />,
    ]}
    base={Layout}
    id={`lock_group_${panel.id}_${channel.channelUuid}`}
  >
    {children}
  </GtMenu>
));
