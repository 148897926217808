import { gql } from "src/app/graphql";
import { ContactListEntry } from "src/app/model/contacts/ContactListEntry";
import { EventType } from "src/app/model/events/types/EventType";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { Throttled } from "src/util/Throttled";
import { action, toJS } from "mobx";
import type { ContactFilter } from "src/app/model/contacts/ContactFilter";
import type { DetailedEvent } from "src/app/model/events/types/DetailedEvent";
import type { PartialEvent } from "src/app/model/events/types/PartialEvent";
import type { State } from "src/app/model/State";
import type { PageInfo } from "src/app/types/PageInfo";
import type { ClientUser } from "src/nextgen/types/ClientUser";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { MessageChannelUuid } from "src/nextgen/types/MessageChannelUuid";

const log = Logger.getLogger("Contacts");

const CONTACTS_PER_FETCH = 50;

export class Contacts {
  public readonly contacts: ContactListEntry[] = [];
  public error?: string;
  public loading = true;
  private readonly refreshThrottle = new Throttled(10000);
  private filter?: ContactFilter;
  private offset?: number;
  private searchTimer?: NodeJS.Timeout;
  private totalItems?: number;
  private unsubscribeToUpdates?: () => void;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public closeDown(): void {
    // This object is preservered and re-used after going offline so we need to reset it properly.
    this.unsubscribeToUpdates?.();
    this.unsubscribeToUpdates = undefined;
    this.refreshThrottle.clear();
    this.contacts.length = 0;
    this.error = undefined;
    this.filter = undefined;
    this.loading = true;
    this.offset = undefined;
    this.searchTimer = undefined;
    this.totalItems = undefined;
  }
  /**
   * Checks if a contact with the given contactId has been fetched
   */
  public hasContact(contactId: ClientUserUuid): boolean {
    return this.contacts.some((c) => c.id === contactId);
  }
  /**
   * Checks if a contact with a private message channel with the given groupId has been fetched
   */
  public hasPrivateMessageChannel(groupId: MessageChannelUuid): boolean {
    return this.contacts.some((c) => c.privateMessageChannelId === groupId);
  }
  public loadMore(): void {
    if (!this.loading) {
      void this.fetchContacts();
    }
  }
  public refresh(): void {
    this.error = undefined;
    this.loading = true;
    void this.fetchContacts({ reset: true });
  }
  public setFilter(filter: ContactFilter): void {
    if (!this.state.online) {
      return;
    }
    let debounce = false;
    if (this.filter && this.filter.name && filter.name !== this.filter.name) {
      debounce = true;
    }
    if (
      this.filter &&
      filter.name === this.filter.name &&
      filter.online === this.filter.online &&
      filter.userIds === this.filter.userIds &&
      (filter.userIds == null ||
        (filter.userIds.length === this.filter.userIds!.length &&
          filter.userIds.every(
            (value, index) => value === this.filter!.userIds![index]
          ))) &&
      filter.organizationIds === this.filter.organizationIds &&
      (filter.organizationIds == null ||
        (filter.organizationIds.length ===
          this.filter.organizationIds!.length &&
          filter.organizationIds.every(
            (value, index) => value === this.filter!.organizationIds![index]
          )))
    ) {
      return;
    }

    log.debug("set filter", filter);
    this.filter = filter;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.error = undefined;
    this.loading = true;
    if (debounce) {
      this.searchTimer = setTimeout(
        action(() => {
          this.searchTimer = undefined;
          this.refresh();
        }),
        500
      );
    } else {
      this.refresh();
    }
  }
  private get hasMore(): boolean {
    return (
      this.totalItems === undefined ||
      this.offset === undefined ||
      this.offset < this.totalItems
    );
  }
  private async fetchContacts({ reset = false } = {}): Promise<void> {
    if (reset) {
      this.offset = undefined;
    }
    if (!this.hasMore || this.error || !this.filter) {
      return;
    }
    try {
      log.debug("fetching contacts with filter", this.filter);
      this.loading = true;
      const clientContacts = await this.queryClientContacts();
      this.error = undefined;
      this.loading = false;
      if (clientContacts) {
        log.debug("Fetched contacts", clientContacts.items);
        this.totalItems = clientContacts.pageInfo.totalItems;
        const { limit } = clientContacts.pageInfo;
        this.offset = (this.offset ?? 0) + limit;
        if (reset) {
          this.contacts.length = 0;
        }
        this.contacts.push(
          ...clientContacts.items
            .filter(
              // Due to timing issues it is not completely certain that contact is online
              // even is filtered on online contacts.
              (contact) =>
                !this.filter?.online || contact.onlineStatus.state === "ONLINE"
            )
            .map(
              (contact) =>
                new ContactListEntry(this.state, {
                  description: contact.title,
                  id: contact.id,
                  name: contact.displayName,
                  online: contact.onlineStatus.state === "ONLINE",
                  organization: contact.organization,
                  privateMessageChannelId:
                    contact.privateMessageChannel &&
                    contact.privateMessageChannel.id
                      ? contact.privateMessageChannel.id
                      : undefined,
                  status: contact.currentStatus,
                  unreadCount:
                    contact.privateMessageChannel &&
                    contact.privateMessageChannel.unreadCount
                      ? contact.privateMessageChannel.unreadCount
                      : undefined,
                })
            )
        );
        this.subscribeToUpdates();
      } else {
        this.contacts.length = 0;
      }
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to fetch contacts";
    }
  }
  private async queryClientContacts(): Promise<{
    items: ClientUser[];
    pageInfo: PageInfo;
  }> {
    if (this.offset === undefined) {
      this.offset = 0;
    }
    if (this.filter!.userIds != null && this.filter!.userIds.length === 0) {
      // Empty contact filter should always return empty array
      return {
        items: [],
        pageInfo: { limit: 0, offset: 0, totalItems: 0 },
      };
    }
    if (
      this.filter!.organizationIds != null &&
      this.filter!.organizationIds.length === 0
    ) {
      // Empty organization filter should always return empty array
      return {
        items: [],
        pageInfo: { limit: 0, offset: 0, totalItems: 0 },
      };
    }
    const { clientUsers } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUsers(
          $pagination: OffsetLimitInput!
          $filter: ClientUsersFilterInput
        ) {
          clientUsers(pagination: $pagination, filter: $filter) {
            items {
              ... on ClientUser {
                id
                entityId
                displayName
                title
                organization {
                  id
                  name
                }
                onlineStatus {
                  state
                }
                currentStatus {
                  id
                  name
                  backgroundColor
                  foregroundColor
                }
                privateMessageChannel {
                  id
                  unreadCount
                }
              }
            }
            pageInfo {
              totalItems
              offset
              limit
            }
          }
        }
      `),
      variables: {
        filter: {
          name: this.filter!.name,
          online: this.filter!.online ? [{ state: "ONLINE" }] : undefined,
          organizationIds: this.filter!.organizationIds,
          userIds: toJS(this.filter!.userIds) || [],
        },
        pagination: {
          limit: CONTACTS_PER_FETCH,
          offset: this.offset,
        },
      },
    });
    return clientUsers;
  }
  private subscribeToUpdates(): void {
    if (this.unsubscribeToUpdates === undefined) {
      this.unsubscribeToUpdates = this.state.online?.eventManager.subscribe({
        onDetailedEvent: action((msg: DetailedEvent) => {
          const contact = this.contacts.find(
            (c) => c.id.toUpperCase() === msg.payload.id.toUpperCase()
          );
          if (contact !== undefined) {
            if (msg.type === EventType.OnlineUpdated) {
              contact.online = msg.payload.online;
              if (this.filter?.online && !contact.online) {
                // If contact went offline, simply remove it from the online list.
                // If contact went online and we already had it for some reason - do nothing.
                this.contacts.splice(this.contacts.indexOf(contact), 1);
                if (this.offset) {
                  this.offset -= 1;
                }
              }
            } else if (msg.type === EventType.UserDetailsUpdated) {
              contact.name = msg.payload.displayName;
              contact.description = msg.payload.title ?? undefined;
            } else if (msg.type === EventType.StatusUpdated) {
              contact.status = msg.payload.currentStatus;
            }
          } else if (
            msg.type === EventType.OnlineUpdated &&
            this.filter?.online &&
            msg.payload.online
          ) {
            // If a new user came online, refresh the online list possibly after a delay.
            this.refreshThrottle.throttled(() => {
              this.refresh();
            });
          }
        }),
        onEvent: (msg: PartialEvent) => {
          return (
            (msg.type === EventType.OnlineUpdated && this.filter?.online) ||
            this.contacts.some(
              (c) => c.id.toUpperCase() === msg.payload.id?.toUpperCase()
            )
          );
        },
        types: [
          EventType.UserDetailsUpdated,
          EventType.OnlineUpdated,
          EventType.StatusUpdated,
        ],
      });
    }
  }
}
