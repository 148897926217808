import { Layout } from "src/app/gui/components/Layout";
import { Preview } from "src/app/gui/panels/customizing/Preview";
import { Columns } from "src/app/gui/panels/tickets/customizing/Columns";
import { GroupFilter } from "src/app/gui/panels/tickets/customizing/GroupFilter";
import { QueueFilter } from "src/app/gui/panels/tickets/customizing/QueueFilter";
import { TimeFilter } from "src/app/gui/panels/tickets/customizing/TimeFilter";
import { Tickets } from "src/app/gui/panels/tickets/Tickets";
import { connect } from "src/app/state/connect";
import { Tabs, Tab } from "@mui/material";
import type { Panel } from "src/app/model/panels/Panel";

export const Customizing = connect<{
  panel: Panel;
}>(({ panel }) => (
  <Layout column>
    <Layout background="#657589">
      <Tabs
        onChange={(_, newValue) => {
          panel.setTab(newValue);
        }}
        value={panel.tab}
      >
        <Tab id="0" label="Queue Filter" />
        <Tab id="1" label="Group Filter" />
        <Tab id="2" label="Time Filter" />
        <Tab id="3" label="Columns" />
      </Tabs>
    </Layout>
    <Layout
      background="rgba(0,0,0,0.1)"
      padding={20}
      style={{ overflow: "hidden" }}
    >
      {panel.tab === 0 && <QueueFilter panel={panel} />}
      {panel.tab === 1 && <GroupFilter panel={panel} />}
      {panel.tab === 2 && <TimeFilter panel={panel} />}
      {panel.tab === 3 && <Columns panel={panel} />}
    </Layout>
    <Preview>
      <Tickets panel={panel} />
    </Preview>
  </Layout>
));
