import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";

const DEVICE_ID = "gt2.account.deviceid";

export class UserData {
  public displayName: null | string = null;
  public organization: null | string = null;
  public organizationUuid: null | string = null;
  public constructor() {
    observableClass(this);
  }
  public static get deviceId(): string {
    return localStorage.getItem(DEVICE_ID);
  }
  public get hasUserData(): boolean {
    return this.displayName !== null;
  }
  public static setRandomDeviceId(): void {
    // Generate a random 8 character deviceid and store it in localStorage
    const deviceId = Math.random().toString(36).substring(2, 10);
    localStorage.setItem(DEVICE_ID, deviceId);
  }
  public removeAll(): void {
    this.displayName = null;
    this.organization = null;
    this.organizationUuid = null;
  }
  public setDisplayName(displayName: null | string): void {
    this.displayName = displayName;
  }
  public setOrganization(organization: null | string): void {
    this.organization = organization;
  }
  public setOrganizationUuid(organizationUuid: null | string): void {
    this.organizationUuid = organizationUuid;
  }
}
