import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ContactMenu } from "src/app/gui/panels/contacts/ContactMenu";
import { StatusBadge } from "src/app/gui/panels/contacts/StatusBadge";
import { FavoriteButton } from "src/app/gui/shared/FavoriteButton";
import { MessageButton } from "src/app/gui/shared/MessageButton";
import { ContactIcon, OnlineIcon } from "src/app/images";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { connect } from "src/app/state/connect";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { ContactListEntry } from "src/app/model/contacts/ContactListEntry";
import type { Panel } from "src/app/model/panels/Panel";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";

export const ContactRow = connect<{
  contact: ContactDetails | ContactListEntry;
  contactDetails: ContactDetails;
  evenRow?: boolean;
  fetchContactDetails: (id: ClientUserUuid) => Promise<void>;
  isDummy?: boolean;
  panel: Panel;
  showDescription: boolean;
  showFavorites: boolean;
  showMessages: boolean;
  showOrganization: boolean;
}>(
  ({
    contact,
    contactDetails,
    evenRow,
    fetchContactDetails,
    isDummy,
    panel,
    showDescription,
    showFavorites,
    showMessages,
    showOrganization,
  }) => {
    const bg = evenRow ? "#7d91aa" : "#7789a2";
    const { description, name } = contact;

    return (
      <ContactMenu
        contactDetails={contactDetails}
        fetchContactDetails={() => fetchContactDetails(contact.id)}
        menuId={contact.id}
        panel={panel}
      >
        <Layout
          style={{
            cursor: "pointer",
          }}
          alignMiddle
          background={bg}
          margin={0}
          minHeight={36}
          padding={2}
          row
        >
          <Layout column marginLeft={5} marginRight={5} width={20}>
            {contact.online && (
              <OnlineIcon
                style={{
                  background: "#86b96982",
                  borderRadius: 5,
                  fill: "rgb(0 38 0)",
                }}
              />
            )}
            {!contact.online && <ContactIcon />}
          </Layout>
          <Layout column marginLeft={6}>
            <Layout row>
              {contact.owner && showOrganization && (
                <Layout column marginRight={5}>
                  <Text
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    color="#ffbf00"
                    size={14}
                  >
                    {`${contact.owner}: `}
                  </Text>
                </Layout>
              )}
              <Text
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="#000"
                nowrap
                size={14}
              >
                {name}
              </Text>
            </Layout>
            {showDescription && (
              <Text
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="#474747"
                nowrap
                size={10}
              >
                {description}
              </Text>
            )}
          </Layout>
          <Layout column grow />
          {contact.status && (
            <Layout column marginLeft={4} marginRight={10}>
              <StatusBadge
                defaultBackground={false}
                statusOption={contact.status}
              />
            </Layout>
          )}
          <GtTooltip
            id={`${panel.id}_${contact.id}_open_in_new_panel`}
            title="Open in new panel"
          >
            <Layout column marginLeft={4} marginRight={4}>
              <MessageButton
                entity={contact}
                isDummy={isDummy}
                panel={panel}
                panelTab={ContactTabSetting.Actions}
                showMessages={showMessages}
              />
            </Layout>
          </GtTooltip>
          {showFavorites && (
            <GtTooltip
              title={`${
                contact.isFavorite(panel) ? "Remove" : "Mark"
              } contact as favorite`}
              id={`${panel.id}_${contact.id}_favorite`}
            >
              <FavoriteButton entity={contact} panel={panel} />
            </GtTooltip>
          )}
        </Layout>
      </ContactMenu>
    );
  }
);
