import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Presence } from "src/app/gui/panels/channels/detailedGroup/Presence";
import { connect } from "src/app/state/connect";
import { PresenceType } from "src/lib/types/PresenceType";
import type { Panel } from "src/app/model/panels/Panel";
import type { Presence as PresenceModel } from "src/app/model/presence/Presence";
import type { Session } from "src/app/model/sessions/Session";

const MaxNumberOfPresenceToDisplay = 50; // the maximum number of presence to display in the GUI

const NoUsersInSession = (
  <Layout alignCenter alignMiddle grow row>
    <Text color="#9e9eb3" size={20} weight={500}>
      No users in session
    </Text>
  </Layout>
);

const List = (
  {
    moreElements,
    presence,
  }: { moreElements: number; presence: PresenceModel[] },
  panel: Panel
): JSX.Element => (
  <Layout padding={5} row style={{ overflowY: "auto" }} wrap>
    {presence.map((p) => (
      <Presence key={p.presenceId} panel={panel} presence={p} />
    ))}
    {moreElements > 0 && (
      <Layout alignBottom paddingLeft={5} row style={{ cursor: "pointer" }}>
        <Text color="#9e9eb3" size={14} weight={500}>
          ... {moreElements} more
        </Text>
      </Layout>
    )}
  </Layout>
);

export const PresenceList = connect<{
  panel: Panel;
  session: Session;
  showDispatcherPresence?: boolean;
}>(({ panel, session, showDispatcherPresence }) => {
  const presence = showDispatcherPresence
    ? session.presence
    : session.presence.filter((t) => t.type !== PresenceType.Dispatcher);
  if (presence.length === 0) {
    return NoUsersInSession;
  }
  const p = presence.slice(0, MaxNumberOfPresenceToDisplay);
  return List(
    {
      moreElements:
        presence.length > MaxNumberOfPresenceToDisplay
          ? presence.length - MaxNumberOfPresenceToDisplay
          : 0,
      presence: p,
    },
    panel
  );
});
