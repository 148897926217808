import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";
import type { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import type { QueueSettings } from "src/app/model/settings/QueueSettings";

export interface QueueState {
  newUnpickedEmergencyEntryEvent?(): void;
  newUnpickedPrioEntryEvent?(): void;
  newUnpickedQueueEntryEvent?(): void;
  onEnter?(): void;
  onExit?(): void;
  subscriptionEvent?(flag: boolean): void;
}
export abstract class QueueState {
  public alertService: QueueAlertService;
  public alertTimer?: () => void;
  public name: string;
  public pendingSubscriptionsCount = 0;
  public settingsUnsubscribe?: () => void;
  public constructor(alertService: QueueAlertService, name: string) {
    this.alertService = alertService;
    this.name = name;
  }
  public get settings(): {
    emergency: QueueSettings;
    normal: QueueSettings;
    prio: QueueSettings;
    showTicketMenu: BooleanSetting;
  } {
    return this.alertService.state.settings.queues;
  }
  public subscription(flag: boolean): void {
    if (this.subscriptionEvent !== undefined) {
      this.subscriptionEvent(flag);
    }
  }
}
