import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { connect } from "src/app/state/connect";

export const NotificationSounds = connect((_, state) => {
  const {
    callStartSounds,
    callStopSounds,
    notificationSounds,
    showCallsMenu,
    showLatestSpeaker,
    startSounds,
    stopSounds,
  } = state.settings.notificationSounds;
  return (
    <Layout column width={400}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          Notification Sounds
        </Text>
      </Layout>
      <BooleanSetting
        label="Enable notification sounds"
        setting={notificationSounds}
      />
      <BooleanSetting
        label="Play sound when sending starts"
        setting={startSounds}
      />
      <BooleanSetting
        label="Play sound when sending stops"
        setting={stopSounds}
      />
      <BooleanSetting
        label="Play sound when call starts"
        setting={callStartSounds}
      />
      <BooleanSetting
        label="Play sound when call stops"
        setting={callStopSounds}
      />
      <BooleanSetting
        label="Show ongoing calls in header"
        setting={showCallsMenu}
      />
      <BooleanSetting
        label="Show latest speaker in header"
        setting={showLatestSpeaker}
      />
    </Layout>
  );
});
