import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * The application has encountered an unknown error.
 */
export class UnknownError extends GroupTalkAPIError {
  public constructor() {
    super("The application has encountered an unknown error.", true);
  }
}
