import { SoundService } from "src/app/model/audio/SoundService";
import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { DynamicListSetting } from "src/app/model/settings/DynamicListSetting";
import { ListSetting } from "src/app/model/settings/ListSetting";
import { NumberSetting } from "src/app/model/settings/NumberSetting";
import { observableClass } from "src/app/state/observableClass";

export class QueueSettings {
  public readonly alertSound: ListSetting;
  public readonly alertDevice: DynamicListSetting;
  public readonly alertVolume: NumberSetting;
  public readonly autoPickNextTicket: BooleanSetting;
  public readonly closeTicketsAndCallSimultaneously: BooleanSetting;
  public readonly id: string;
  public readonly joinGroupWhenPicking: BooleanSetting;
  public readonly playSound: ListSetting;
  public readonly reminderIntervalInSeconds: NumberSetting;
  public readonly startMonitoringWhenPicking: BooleanSetting | undefined;
  public readonly startPrivateCallWhenPicking: BooleanSetting;
  public constructor(key: string, defaultAlert: string) {
    this.id = key;
    this.reminderIntervalInSeconds = new NumberSetting({
      defaultValue: 300,
      key: `gt2.settings.queues.${key}.reminderIntervalInSeconds`,
      minValue: 0,
    });
    this.playSound = new ListSetting({
      defaultId: "available",
      key: `gt2.settings.queues.${key}.playSound`,
      list: [
        { id: "available", name: "When Available" },
        { id: "always", name: "Always" },
        { id: "never", name: "Never" },
      ],
    });
    this.alertSound = new ListSetting({
      defaultId: defaultAlert,
      key: `gt2.settings.queues.${key}.alertSound`,
      list: [
        { id: "alert-0", name: "Sound 1" },
        { id: "alert-1", name: "Sound 2" },
        { id: "alert-2", name: "Sound 3" },
        { id: "alert-3", name: "Sound 4" },
        { id: "alert-4", name: "Sound 5" },
        { id: "alert-5", name: "Sound 6" },
        { id: "alert-6", name: "Sound 7" },
        { id: "alert-7", name: "Sound 8" },
        { id: "alert-8", name: "Sound 9" },
        { id: "alert-9", name: "Sound 10" },
        { id: "alert-10", name: "Sound 11" },
        { id: "alert-11", name: "Sound 12" },
        { id: "alert-12", name: "Sound 13" },
      ],
    });
    const defaultAudioDevice = { id: "default", name: "Default" };
    this.alertDevice = new DynamicListSetting({
      defaultEntry: defaultAudioDevice,
      key: `gt2.settings.queues.${key}.alertDevice`,
      listProvider: async () => {
        const available = await SoundService.audioOutputMediaDevices();
        const outputDevices = available.map((d) => ({
          id: d.deviceId,
          name: d.label,
        }));
        return outputDevices.length > 0 ? outputDevices : [defaultAudioDevice];
      },
    });
    this.alertVolume = new NumberSetting({
      defaultValue: 0.8,
      key: `gt2.settings.queues.${key}.alertVolume`,
      maxValue: 1.0,
      minValue: 0.05,
    });
    this.autoPickNextTicket = new BooleanSetting({
      defaultValue: false,
      key: `gt2.settings.queues.${key}.autoPickNextTicket`,
    });
    this.startMonitoringWhenPicking =
      key === "emergency"
        ? new BooleanSetting({
            defaultValue: false,
            key: `gt2.settings.queues.${key}.startMonitoringWhenPicking`,
          })
        : undefined;
    this.startPrivateCallWhenPicking = new BooleanSetting({
      defaultValue: false,
      key: `gt2.settings.queues.${key}.startPrivateCallWhenPicking`,
    });
    this.joinGroupWhenPicking = new BooleanSetting({
      defaultValue: false,
      key: `gt2.settings.queues.${key}.joinGroupWhenPicking`,
    });
    this.closeTicketsAndCallSimultaneously = new BooleanSetting({
      defaultValue: false,
      key: `gt2.settings.queues.${key}.closeTicketsAndCallSimultaneously`,
    });
    observableClass(this);
  }
}
