import { GtActionButton } from "src/app/gui/components/GtActionButton";
import { GtError } from "src/app/gui/components/GtError";
import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { GtPanelTab } from "src/app/gui/components/GtPanelTab";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { GroupRow } from "src/app/gui/panels/patch/GroupRow";
import { SearchSetting } from "src/app/gui/settings/SearchSetting";
import { EditIcon, CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { useState } from "react";
import type { Panel } from "src/app/model/panels/Panel";
import type { Organization } from "src/nextgen/types/Organization";

export const Patch = connect<{
  panel: Panel;
}>(({ panel }, { userData }) => {
  const { availableChannels, edit, editing, patch, searchSetting } =
    panel.patchPanelData!;
  const {
    channels: aChannels,
    loadMore: aLoadMore,
    loading: aLoading,
  } = availableChannels;

  const noIncludedElementsMessage = "No groups in patch.";
  const noAvailableElementsMessage = "No available groups.";

  const [selectedIncluded, setSelectedIncluded] = useState<null | string>(null);
  const [selectedAvailable, setSelectedAvailable] = useState<null | string>(
    null
  );

  if (!patch) {
    return (
      <Layout column>
        <GtError message="Loading patch" severity="info" />
      </Layout>
    );
  }

  const {
    addGroupToPatch,
    created,
    error,
    groups,
    patchId,
    removeGroupFromPatch,
  } = patch;
  const getOwner = (organization?: Organization): null | string => {
    const ourUuid = userData.organizationUuid;
    return organization &&
      organization.id &&
      organization.id.toLowerCase() !== ourUuid?.toLowerCase()
      ? organization.name
      : null;
  };
  return (
    <Layout column grow>
      <Layout alignMiddle height={55} row>
        <Layout column>
          <Text bold size={13}>
            Patch created
          </Text>
          <Layout alignMiddle row>
            <Text size={13}>{new Date(created!).toLocaleString()}</Text>
          </Layout>
        </Layout>
        <Layout grow row />
        {editing && <SearchSetting label="Search" setting={searchSetting} />}
        <Layout column width={10} />
        <GtPanelTab
          onClick={() => {
            edit(!editing);
          }}
          Icon={editing ? CheckIcon : EditIcon}
          disabled={false}
          id={`edit_${patchId}`}
          positiveOnly
          selected={false}
          tooltip="Edit members"
        />
      </Layout>
      {error && (
        <Layout row>
          <Layout column grow>
            <GtError message={error} severity="warning" />
          </Layout>
        </Layout>
      )}
      <Layout row>
        <Layout column grow>
          <Text bold size={13}>
            Members:
          </Text>
        </Layout>
        <Layout column width={120} />
        <Layout column grow>
          {editing && (
            <Text bold size={13}>
              Available:
            </Text>
          )}
        </Layout>
      </Layout>
      <Layout grow row>
        <Layout alignTop column grow>
          <GtInfiniteRenderer
            elements={groups!.map((groupLink, index) => (
              <GroupRow
                onClick={() => {
                  setSelectedIncluded(groupLink.group.id);
                  setSelectedAvailable(null);
                }}
                evenRow={index % 2 === 1}
                key={groupLink.group.id}
                name={groupLink.group.name}
                owner={getOwner(groupLink.group.organization) ?? undefined}
                selected={groupLink.group.id === selectedIncluded}
                tooltipId={`${patchId}-${groupLink.group.id}`}
              />
            ))}
            loadMore={() => {}}
            noElements={noIncludedElementsMessage}
          />
        </Layout>
        {editing && (
          <Layout
            alignCenter
            alignTop
            background="#0002"
            column
            paddingTop={20}
            width={120}
          >
            <GtActionButton
              action={{
                disabled: selectedAvailable === null,
                id: "pick",
                name: "Add",
                onClick: () => {
                  void addGroupToPatch(selectedAvailable!);
                  setSelectedAvailable(null);
                },
                render: () => <Text>{"<--- Add"}</Text>,
                show: true,
              }}
              style={{ width: "82px" }}
            />
            <Layout column height={20} />
            <GtActionButton
              action={{
                disabled: selectedIncluded === null,
                id: "unpick",
                name: "Remove",
                onClick: () => {
                  void removeGroupFromPatch(selectedIncluded!);
                  setSelectedIncluded(null);
                },
                render: () => <Text>{"Remove --->"}</Text>,
                show: true,
              }}
              style={{ width: "82px" }}
            />
          </Layout>
        )}
        {editing && (
          <Layout alignTop column grow>
            <GtInfiniteRenderer
              elements={aChannels.map((channel, index) => (
                <GroupRow
                  onClick={() => {
                    setSelectedIncluded(null);
                    setSelectedAvailable(channel.channelUuid);
                  }}
                  description={channel.description}
                  disabled={!!(channel.isFullDuplex || channel.patch)}
                  evenRow={index % 2 === 1}
                  fullDuplex={channel.isFullDuplex}
                  key={channel.channelUuid}
                  name={channel.name}
                  owner={channel.owner}
                  patched={channel.hasPatch}
                  selected={channel.channelUuid === selectedAvailable}
                  tooltipId={`${patchId}-${channel.channelUuid}`}
                />
              ))}
              loadMore={aLoadMore}
              loading={aLoading}
              noElements={noAvailableElementsMessage}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
});
