import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * A GroupTalk license is required to go online.
 */
export class PaymentRequired extends GroupTalkAPIError {
  public constructor() {
    super("A GroupTalk license is required to go online.", false);
  }
}
