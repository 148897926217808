import { connect } from "src/app/state/connect";
import { useEffect, useRef } from "react";
import { Marker } from "react-leaflet";
import type { MarkerProps } from "react-leaflet";

/**
 * RotatedMarker extends from Marker and supports reliable updating of the rotation angle of the
 * marker
 *
 * Apparently the only reliable way to make sure that the arrow is rotated correctly is to
 * call setRotationAngle() from useEffect(). One would think that the leaflet library and
 * Leaflet Rotated Marker extension should handle this better, but it is the way it is.
 *
 * See stackoverflow answer that inspired this solution: https://stackoverflow.com/a/66344372
 */
export const RotatedMarker = connect<MarkerProps>(
  ({ children, ...props }, _state, ref) => {
    const markerRef = useRef<L.Marker<any> | null>(null);
    const { rotationAngle } = props;

    useEffect(() => {
      if (markerRef.current !== null && rotationAngle !== undefined) {
        markerRef.current.setRotationAngle(rotationAngle);
      }
    }, [rotationAngle]);

    return (
      <Marker
        ref={(node) => {
          markerRef.current = node;
          // apparently ref can both be objects with a current property and functions
          // see https://stackoverflow.com/a/62238917
          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            const r = ref;
            r.current = node;
          }
        }}
        {...props}
      >
        {children}
      </Marker>
    );
  }
);
