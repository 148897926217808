import { gql } from "src/app/graphql";
import { ChannelsHandler } from "src/app/model/channels/ChannelsHandler";
import { Patch } from "src/app/model/patches/Patch";
import { observableClass } from "src/app/state/observableClass";
import { PanelType } from "src/app/types/PanelType";
import { ChannelMediaType } from "src/lib/types/ChannelMediaType";
import { runInAction } from "mobx";
import type { LockState } from "src/app/graphql/graphql";
import type { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";
import type { Room } from "src/app/model/video/Room";
import type { NodeEntityId } from "src/lib/types/NodeEntityId";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { ClientGroup } from "src/nextgen/types/ClientGroup";

export class Channel {
  public readonly channelUuid: ChannelUuid;
  public readonly description?: string;
  public readonly entityId?: NodeEntityId;
  public readonly hasMessaging: boolean;
  public readonly name: string;
  public readonly owner?: string;
  public lockStatus?: LockState;
  public patch: Patch | null;
  public pttSelection?: {
    scanned: boolean;
    selected: boolean;
  };
  public unreadCount: number;
  private readonly mediaType: ChannelMediaType;
  public constructor(private readonly state: State, clientGroup: ClientGroup) {
    this.channelUuid = clientGroup.id;
    this.entityId = clientGroup.entityId ?? undefined;
    this.name = clientGroup.displayName;
    this.description = clientGroup.description ?? undefined;
    const ourUuid = this.state.userData.organizationUuid;
    this.owner =
      clientGroup.organization &&
      clientGroup.organization.id &&
      clientGroup.organization.id.toLowerCase() !== ourUuid!.toLowerCase()
        ? clientGroup.organization.name
        : undefined;
    this.unreadCount = clientGroup.messageChannel
      ? clientGroup.messageChannel.unreadCount
      : 0;
    this.hasMessaging = !!clientGroup.messageChannel;
    this.pttSelection = clientGroup.pttSelection;
    this.lockStatus = clientGroup.lock;
    this.patch = clientGroup.patch ? new Patch(state, clientGroup.patch) : null;
    this.mediaType =
      clientGroup.type === "TWILIO_FULL_DUPLEX"
        ? ChannelMediaType.TwilioFullDuplex
        : ChannelMediaType.GroupTalkPtt;
    observableClass(this);
  }
  public get hasLock(): boolean {
    return this.lockStatus !== "DISABLED";
  }
  public get hasMobileUsers(): boolean {
    return (this.session && this.session.mobilePresence.length > 0) ?? false;
  }
  public get hasPatch(): boolean {
    return this.patch !== null;
  }
  public get isFavorite() {
    return (panel: Panel): boolean =>
      (panel.type === PanelType.channels &&
        panel.channelsPanelData?.includeGroup(this.channelUuid)) ??
      false;
  }
  public get isFullDuplex(): boolean {
    return this.mediaType === ChannelMediaType.TwilioFullDuplex;
  }
  public get joined(): boolean {
    return !!this.pttSelection?.selected;
  }
  public get locked(): boolean {
    return this.lockStatus === "LOCKED";
  }
  public get patchColor(): null | string {
    return this.patch?.patchColor ?? null;
  }
  public get room(): Room | null {
    return this.isFullDuplex
      ? this.state.online?.rooms.roomWithChannelUuid(this.channelUuid) ?? null
      : null;
  }
  public get scanned(): boolean {
    return !!this.pttSelection?.scanned;
  }
  public get session(): Session | undefined {
    return this.state.online?.sessions.list.find(
      (session) => session.channelUuid === this.channelUuid
    );
  }
  public async fetchPatchInfo(): Promise<void> {
    const { clientGroup } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientGroupPatchInfoFields(
          $id: ID!
        ) {
          clientGroup(id: $id) {
            id
            patch {
              id
              created
              owner {
                name
              }
              groups {
                group {
                  id
                  name
                }
              }
            }
          }
        }
      `),
      variables: {
        id: this.channelUuid,
      },
    });
    if (clientGroup?.patch) {
      runInAction(() => {
        this.patch?.updateWithInfo(clientGroup.patch!);
      });
    }
  }
  public openOrFocusPanel(panel: Panel, tab: ChannelTabSetting): void {
    this.state.online?.channels.openOrFocusChannelInPanel({
      id: this.channelUuid,
      name: this.name!,
      panel,
      tab,
    });
  }
  public setUnreadCount(count: number): void {
    this.unreadCount = count;
  }
  public toggleFavorite(panel: Panel): void {
    if (panel.channelsPanelData) {
      panel.channelsPanelData.toggleIncludeGroup(this.channelUuid);
    }
  }
  public updateJoined(joined: boolean): void {
    if (this.entityId) {
      ChannelsHandler.setSavedChannelMute(this.channelUuid, false);
      ChannelsHandler.setSavedChannelSolo(this.channelUuid, false);
      void this.state.online?.channels.updateJoined(this.entityId, joined);
    }
  }
  public updateLocked(locked: boolean): void {
    void this.state.graphqlModule.mutationDataOrThrow({
      mutation: locked
        ? gql(`
        mutation lockGroup(
          $groupId: ID!
        ) {
          lockGroup(input: {
            groupId: $groupId
          }) {
              error {
                  __typename
                  ... on AuthorizationError {
                      message
                  }
                  ... on NotFoundError {
                      message
                  }
              }
          }
        }
      `)
        : gql(`
        mutation unlockGroup(
          $groupId: ID!
        ) {
          unlockGroup(input: {
            groupId: $groupId
          }) {
              error {
                  __typename
                  ... on AuthorizationError {
                      message
                  }
                  ... on NotFoundError {
                      message
                  }
              }
          }
        }
      `),
      variables: {
        groupId: this.channelUuid,
      },
    });
  }
  public updateScanned(scanned: boolean): void {
    if (this.entityId) {
      void this.state.online?.channels.updateScanned(this.entityId, scanned);
    }
  }
}
