import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { Status } from "src/lib/types/Status";

const log = Logger.getLogger("StatusModule");

/**
 * Returned from <code>setupStatusModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class StatusModule {
  /**
   * The user's current status.
   * @member {Status}
   */
  public currentStatus?: Status;
  /**
   * Callback handling status change.
   * @member {function(Status)}
   */
  public onStatusChange: (status?: Status) => void;
  /**
   * True if there is at least one status that can be set.
   * @member {boolean}
   */
  public statusAvailable: boolean;
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      currentStatus?: Status;
      onStatusChange: (status?: Status) => void;
      statusAvailable: boolean;
    }
  ) {
    this.requestManager = requestManager;
    this.statusAvailable = options.statusAvailable;
    this.currentStatus = options.currentStatus;
    this.onStatusChange = options.onStatusChange;
  }
  public static fromProtoStatus(status: proto.IStatus): Status {
    return {
      backgroundColor: StatusModule.validateColor(status.bgColor ?? null),
      foregroundColor: StatusModule.validateColor(status.fgColor ?? null),
      id: status.uuid,
      name: status.name,
    };
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onStatusChange: (status?: Status) => void;
    }
  ): Promise<StatusModule> {
    const response = (await requestManager.send({
      status: { setupRequest: {} },
    })) as proto.StatusModuleSetupResponse;
    log.debug("status module setup.", response);
    return new StatusModule(requestManager, {
      ...options,
      currentStatus: response.currentStatus
        ? StatusModule.fromProtoStatus(response.currentStatus)
        : undefined,
      statusAvailable: response.statusAvailable,
    });
  }
  /**
   * Returns a list of available statuses.
   * @param {string} userUuid If set, list status for another user. <code>(optional)</code>
   * @param {string} entitiyId If set, list status for another entity. <code>(optional)</code>
   * @returns {Promise<Array<Status>>} Resolves with a list of statuses.
   */
  public async availableStatuses(
    userUuid?: string,
    entityId?: string
  ): Promise<Status[]> {
    const response = (await this.requestManager.send({
      status: { listAvailableRequest: { entityId, userUuid } },
    })) as proto.IStatusModuleListAvailableResponse;
    return response.statuses
      ? response.statuses.map(StatusModule.fromProtoStatus)
      : [];
  }
  public onRequest(
    message: proto.IStatusAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.statusChangedRequest) {
      this.onReceiveStatusChanged(message.statusChangedRequest, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Update a user's status which will be propagated through the system.
   * The status is persisted even after logging out.
   * @param {string} statusUuid The new status.
   * @param {string} userUuid If set, updates status for another user. <code>(optional)</code>
   * @param {string} entitiyId If set, updates status for another entity. <code>(optional)</code>
   * @returns {Promise} Resolves when the status has been updated.
   */
  public async updateStatus({
    entityId,
    statusUuid,
    userUuid,
  }: {
    entityId?: string;
    statusUuid?: string;
    userUuid?: string;
  } = {}): Promise<void> {
    await this.requestManager.send({
      status: { setCurrentRequest: { entityId, statusUuid, userUuid } },
    });
  }
  private static validateColor(color: null | string): null | string {
    if (color && color.length >= 4 && color.length <= 9 && color[0] === "#") {
      return color;
    }
    return null;
  }
  private onReceiveStatusChanged(
    statusChangedRequest: proto.IStatusChangedRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    this.currentStatus = statusChangedRequest.newStatus
      ? StatusModule.fromProtoStatus(statusChangedRequest.newStatus)
      : undefined;
    if (this.onStatusChange) {
      this.onStatusChange(this.currentStatus);
    } else {
      log.warn("no status changed callback available");
    }
  }
}
