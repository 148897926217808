import { observableClass } from "src/app/state/observableClass";
import { ConnectionClosedLocally } from "src/lib/errors/ConnectionClosedLocally";
import type { State } from "src/app/model/State";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

export class Connecting {
  public isConnecting = false;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public endWithError(error: GroupTalkAPIError): void {
    this.isConnecting = false;
    this.updateError(error);
  }
  public setFinished(): void {
    this.isConnecting = false;
    this.state.login.error = null;
  }
  public start(): void {
    this.isConnecting = true;
  }
  public updateError(error: GroupTalkAPIError): void {
    if (error instanceof ConnectionClosedLocally) {
      this.state.login.error = "";
    } else {
      this.state.login.error = error.message;
    }
  }
}
