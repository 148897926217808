import { KeepaliveTimeout } from "src/lib/errors/KeepaliveTimeout";
import { proto } from "src/lib/protobuf/proto";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("KeepAliveModule");

export class KeepAliveModule {
  public readonly interval: number;
  private timer?: () => void;
  private constructor(
    private readonly requestManager: RequestManager,
    interval: number
  ) {
    this.interval = interval;
    this.restartTimer();
  }
  public static async setup(
    requestManager: RequestManager,
    options: { suggestedInterval?: number } = {}
  ): Promise<KeepAliveModule> {
    try {
      const { suggestedInterval = 120 } = options;
      const response = (await requestManager.send({
        keepAliveSetup: { suggestedInterval },
      })) as proto.IKeepAliveAPIv1SetupResponse;
      const interval = response.keepAliveInterval;
      log.debug(`KeepAlive interval established to ${interval} seconds.`);
      return new KeepAliveModule(requestManager, interval * 1000);
    } catch (error: any) {
      requestManager.stop(error);
      throw error;
    }
  }
  public onDisconnect(): void {
    this.timer?.();
  }
  public onKeepAlivePing(
    _: proto.IKeepAliveAPIv1PingRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    log.debug("Keep Alive Ping");
    respond(proto.ResponseCode.OK);
    this.restartTimer();
  }
  public restartTimer(): void {
    this.timer?.();
    this.timer = BackgroundTimer.setTimeout(() => {
      this.requestManager.stop(new KeepaliveTimeout());
    }, this.interval);
  }
}
