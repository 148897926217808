import { ActionType } from "src/lib/types/ActionType";
import type { CustomAction } from "src/lib/modules/CustomAction";
import type { RequestManager } from "src/lib/RequestManager";

/**
 * An action which can be performed. Typically a queue.
 * @namespace
 */
export class CustomPerformAction implements CustomAction {
  public constructor(
    public readonly requestManager: RequestManager,
    public readonly caption: string,
    public readonly type: ActionType,
    public readonly id: number
  ) {}
  /**
   * Performs the action. If it is a queue action, it will put the user in the queue etc.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async perform(): Promise<void> {
    await this.requestManager.send({
      customAction: { performRequest: { id: this.id } },
    });
  }
  public queues(): CustomAction[] {
    return this.type === ActionType.Queue ? [this] : [];
  }
}
