import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Customizing as CustomizingChannel } from "src/app/gui/panels/channel/customizing/Customizing";
import { Customizing as CustomizingChannels } from "src/app/gui/panels/channels/customizing/Customizing";
import { Customizing as CustomizingContacts } from "src/app/gui/panels/contacts/customizing/Customizing";
import { Customizing as CustomizingTickets } from "src/app/gui/panels/tickets/customizing/Customizing";
import { connect } from "src/app/state/connect";
import { Button } from "@mui/material";
import type { Panel } from "src/app/model/panels/Panel";

export const Customizing = connect<{
  panel: Panel;
}>(({ panel }) => (
  <Layout column padding={20} style={{ boxSizing: "border-box" }}>
    <Layout alignMiddle row spaceBetween>
      <Text bold>CUSTOMIZE</Text>
      {panel.isCustom && <Text italic>(custom)</Text>}
      <Layout grow row />
      <Button onClick={() => panel.reset()} variant="text">
        Reset
      </Button>
    </Layout>
    <Layout column marginBottom={10} />
    {panel.type === "tickets" && <CustomizingTickets panel={panel} />}
    {panel.type === "contacts" && <CustomizingContacts panel={panel} />}
    {panel.type === "channels" && <CustomizingChannels panel={panel} />}
    {panel.type === "channel" && <CustomizingChannel panel={panel} />}
  </Layout>
));
