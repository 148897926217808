import { Logger } from "src/util/Logger";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloError,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import type { NormalizedCacheObject } from "@apollo/client/cache/inmemory/types";
import type {
  OperationVariables,
  ApolloQueryResult,
} from "@apollo/client/core";
import type {
  QueryOptions,
  MutationOptions,
} from "@apollo/client/core/watchQueryOptions";
import type { ApolloLink, FetchResult } from "@apollo/client/link/core";
import type { AuthenticationModule } from "src/nextgen/AuthenticationModule";

const log = Logger.getLogger("GraphQLModule");

export class GraphQLModule {
  public readonly graphQLClient: ApolloClient<NormalizedCacheObject>;
  private readonly authenticatedHttpLink: ApolloLink;
  private readonly httpLink: ApolloLink;
  public constructor(
    /**
     * The authentication module to use for authenticating
     * @member {AuthenticationModule}
     */
    private readonly authenticationModule: AuthenticationModule,
    /**
     * GraphQL API URL
     * @member {string}
     */
    private readonly graphQLApiURL: string
  ) {
    log.debug(`Creating ApolloClient for ${graphQLApiURL}`);

    // TODO: add RetryLink: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
    // TODO: add onError handling

    this.httpLink = createHttpLink({
      uri: this.graphQLApiURL,
    });

    this.authenticatedHttpLink = setContext(async (_, { headers }) => {
      const jwt = await this.authenticationModule.login();

      return {
        headers: {
          ...headers,
          authorization: jwt ? `Bearer ${jwt}` : "",
        },
      };
    });

    this.graphQLClient = new ApolloClient({
      cache: new InMemoryCache(),
      link: this.authenticatedHttpLink.concat(this.httpLink),
    });
  }
  public mutation<T>({
    mutation,
    variables,
  }: MutationOptions): Promise<FetchResult<T>> {
    return this.graphQLClient.mutate<T>({
      mutation,
      variables,
    });
  }
  public async mutationDataOrThrow<T>(
    options: MutationOptions<T, OperationVariables, Record<string, any>>
  ): Promise<T> {
    const { mutation, variables } = options;
    const res = await this.graphQLClient.mutate<T>({
      mutation,
      variables,
    });
    if (res.errors) {
      log.warn("Error in mutation", res.errors);
      throw new ApolloError({ graphQLErrors: res.errors });
    }
    return res.data!;
  }
  /**
   *  errorPolicy:
   *    none (default) - any error results in no data, so never partial data
   *    ignore         - remove all traces of errors, proceed as if all went well
   *    all            - returns partial data/errors
   *  fetchPolicy (https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies):
   *    cache-first (default) - cache first call, then serve that (if all data available)
   *    network-only          - always get data from network, but store in cache
   *    cache-only            - always get data from cache, error if not all data available
   *    no-cache              - always get data from network, do not store in cache
   *    standby               - like cache-first, but without updated queries
   *    cache-and-network     - query cache AND network for fast response and updated cache
   */
  public query<T>(
    options: QueryOptions<OperationVariables, T>
  ): Promise<ApolloQueryResult<T>> {
    const {
      errorPolicy = "none",
      fetchPolicy = "cache-first",
      query,
      variables,
    } = options;
    return this.graphQLClient.query<T>({
      errorPolicy,
      fetchPolicy,
      query,
      variables,
    });
  }
  public async queryDataOrThrow<T>(
    options: QueryOptions<OperationVariables, T>
  ): Promise<T> {
    const { fetchPolicy = "cache-first", query, variables } = options;
    const res = await this.graphQLClient.query<T>({
      errorPolicy: "none",
      fetchPolicy,
      query,
      variables,
    });
    if (res.error) {
      log.warn("Error in query", res.error);
      throw new ApolloError(res.error);
    }
    return res.data;
  }
}
