import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Unauthorized.
 */
export class Unauthorized extends GroupTalkAPIError {
  public constructor() {
    super("Unauthorized.", false);
  }
}
