import { observableClass } from "src/app/state/observableClass";
import { action } from "mobx";
import type { Dialog } from "src/app/model/dialogs/Dialog";
import type { DialogHandle } from "src/app/model/dialogs/DialogHandle";
import type { TextFieldProps } from "src/app/model/dialogs/TextFieldProps";

export class Dialogs {
  public readonly list: Record<number, Dialog> = {};
  private id = 0;
  public constructor() {
    observableClass(this);
  }
  public show(options: {
    actions?: {
      label: string;
      onSelect?: (options: { textField?: TextFieldProps }) => void;
    }[];
    forceRespond?: boolean;
    text?: string;
    textField?: TextFieldProps;
    title: string;
  }): DialogHandle {
    const {
      actions = [{ label: "OK" }],
      forceRespond = false,
      text,
      textField,
      title,
    } = options;
    const { id } = this;
    this.id += 1;
    const dialog: Dialog = {
      actions,
      forceRespond,
      id,
      onClose: action(() => {
        this.list[id].open = false;
        delete this.list[id];
      }),
      open: true,
      setTextFieldValue: action((value) => {
        const t = this.list[id]?.textField;
        if (t !== undefined) {
          t.value = value;
        }
      }),
      text,
      textField,
      title,
    };
    this.list[id] = dialog;
    return {
      close: action(() => {
        if (this.list[id]) {
          this.list[id].open = false;
        }
      }),
      update: action((newStuff) => {
        if (this.list[id]) {
          this.list[id] = { ...dialog, ...newStuff };
        }
      }),
    };
  }
}
