import { NotFound } from "src/lib/errors/NotFound";
import { AudioModule } from "src/lib/modules/AudioModule";
import { IncomingTalkburst } from "src/lib/modules/IncomingTalkburst";
import { OutgoingTalkburst } from "src/lib/modules/OutgoingTalkburst";
import { TalkburstReceptionModule } from "src/lib/modules/TalkburstReceptionModule";
import { TalkburstTransmissionModule } from "src/lib/modules/TalkburstTransmissionModule";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Codec } from "src/lib/types/Codec";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { CodecSettings } from "src/lib/types/CodecSettings";
import type { ReceptionStatistics } from "src/lib/types/ReceptionStatistics";
import type { TalkburstReceptionStart } from "src/lib/types/TalkburstReceptionStart";
import type { TalkburstReceptionStop } from "src/lib/types/TalkburstReceptionStop";

const log = Logger.getLogger("TalkburstModule");

/**
 * Returned from <code>setupTalkburstModule</code> of
 * <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class TalkburstModule {
  private readonly onTalkburstStart?: (talkburst: IncomingTalkburst) => void;
  private readonly onTalkburstStop?: (talkburst: IncomingTalkburst) => void;
  private audioModule?: AudioModule;
  private incomingTalkbursts: Record<any, any> = {};
  private talkburstReceptionModule?: TalkburstReceptionModule;
  private talkburstTransmissionModule?: TalkburstTransmissionModule;
  private constructor(
    private readonly requestManager: RequestManager,
    {
      onTalkburstStart,
      onTalkburstStop,
    }: {
      onTalkburstStart?: (talkburst: IncomingTalkburst) => void;
      onTalkburstStop?: (talkburst: IncomingTalkburst) => void;
    } = {}
  ) {
    this.requestManager = requestManager;
    /**
     * Callback when receiving incoming talkburst.
     * @member {function(IncomingTalkburst)}
     */
    this.onTalkburstStart = onTalkburstStart;
    /**
     * Callback when incoming talkburst is stopped.
     * @member {function(IncomingTalkburst)}
     */
    this.onTalkburstStop = onTalkburstStop;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onTalkburstStart?: (talkburst: IncomingTalkburst) => void;
      onTalkburstStop?: (talkburst: IncomingTalkburst) => void;
    }
  ): Promise<TalkburstModule> {
    const talkburstModule = new TalkburstModule(requestManager, options);
    talkburstModule.audioModule = await AudioModule.setup(requestManager, {
      onAudio: talkburstModule.onAudio.bind(talkburstModule),
    });
    talkburstModule.talkburstTransmissionModule =
      await TalkburstTransmissionModule.setup(requestManager);
    talkburstModule.talkburstReceptionModule =
      await TalkburstReceptionModule.setup(requestManager, {
        onTalkburstStart:
          talkburstModule.onTalkburstStartWrapped.bind(talkburstModule),
        onTalkburstStop:
          talkburstModule.onTalkburstStopWrapped.bind(talkburstModule),
      });
    log.debug("talkburst module setup finished.");
    return talkburstModule;
  }
  public onRequest(
    message: proto.IServerMessage,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.audio) {
      this.audioModule?.onRequest(message.audio);
    } else if (message.talkburstReception) {
      this.talkburstReceptionModule?.onRequest(
        message.talkburstReception,
        respond
      );
    } else if (message.talkburstTransmission) {
      this.talkburstTransmissionModule?.onRequest(
        message.talkburstTransmission,
        respond
      );
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Start a new transmission.
   * @param {Number} sessionId Which session to start transmission in.
   * @param {string} muteGroupId Hint to users with the same muteGroupId to mute this
   * talkburst to avoid feedback loops.
   * @param {object} codecSettings Codec settings to use for this talkburst
   * @param {function()} onInterrupt Callback on interrupt which can happen for example if a client
   * with higher priority send simultanously.
   * @param {function(): Promise<OutgoingTalkburst>} onStopped Callback when talkburst is stopped (for any reason).
   * @returns {Promise<OutgoingTalkburst>}
   */
  public async startTransmission(
    sessionId: number,
    muteGroupId: string | undefined,
    codecSettings: CodecSettings,
    onInterrupt: () => void,
    onStopped: () => Promise<void>
  ): Promise<OutgoingTalkburst | undefined> {
    let outgoingTalkburst: OutgoingTalkburst | undefined;
    const sessionModule =
      this.requestManager.connectedModule?.capabilitiesModule
        ?.authenticatedModule?.sessionModule;
    if (!sessionModule || !sessionModule.sessions[sessionId]) {
      throw new NotFound();
    }
    const { acceptedCodecs } = sessionModule.sessions[sessionId];
    const codec = acceptedCodecs.includes(Codec.Opus) ? Codec.Opus : Codec.iLBC;

    const talkburstTransmission =
      await this.talkburstTransmissionModule?.startTransmission(
        sessionId,
        muteGroupId,
        onInterrupt,
        async () => {
          if (onStopped) {
            await onStopped();
          }
          if (outgoingTalkburst) {
            outgoingTalkburst.onStopped();
          }
        }
      );
    if (talkburstTransmission) {
      outgoingTalkburst = new OutgoingTalkburst({
        audioModule: this.audioModule!,
        codec,
        codecSettings,
        talkburstTransmission,
      });
    }
    return outgoingTalkburst;
  }
  private onAudio(audioData: proto.IAudioData): void {
    const id = longToNumber(audioData.talkburstId);
    const { audio, codec, seqNo } = audioData;
    if (!this.incomingTalkbursts[id]) {
      this.incomingTalkbursts[id] = new IncomingTalkburst();
    }
    this.incomingTalkbursts[id].onAudio({ audio, codec, seqNo });
  }
  private onTalkburstStartWrapped(
    talkburstReceptionStart: TalkburstReceptionStart
  ): void {
    const id = talkburstReceptionStart.talkburstId;
    if (!this.incomingTalkbursts[id]) {
      this.incomingTalkbursts[id] = new IncomingTalkburst();
    }
    const sessionModule =
      this.requestManager.connectedModule?.capabilitiesModule
        ?.authenticatedModule?.sessionModule;
    if (sessionModule !== undefined) {
      const session = sessionModule.sessions[talkburstReceptionStart.sessionId];
      this.incomingTalkbursts[id].setParameters(
        talkburstReceptionStart,
        session
      );
      if (this.onTalkburstStart) {
        this.onTalkburstStart(this.incomingTalkbursts[id]);
      }
    }
  }
  private onTalkburstStopWrapped(
    talkburstReceptionStop: TalkburstReceptionStop
  ): Promise<ReceptionStatistics> | null {
    const id = talkburstReceptionStop.talkburstId;
    if (this.incomingTalkbursts[id]) {
      return new Promise((resolve) => {
        this.incomingTalkbursts[id].setLastSentSeqNo(
          talkburstReceptionStop.lastSentSeqNo,
          (receptionStatistics: ReceptionStatistics) => {
            if (this.onTalkburstStop) {
              this.onTalkburstStop(this.incomingTalkbursts[id]);
            }
            delete this.incomingTalkbursts[id];
            resolve(receptionStatistics);
          }
        );
      });
    }
    log.warn(`talkburst stop with unknown talkburstId: ${id}`);
    return null;
  }
}
