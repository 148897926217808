import { BusyHere } from "src/lib/errors/BusyHere";
import { Declined } from "src/lib/errors/Declined";
import { NotFound } from "src/lib/errors/NotFound";
import { NotOnline } from "src/lib/errors/NotOnline";
import { RequestTerminated } from "src/lib/errors/RequestTerminated";
import { TemporarilyUnavailable } from "src/lib/errors/TemporarilyUnavailable";
import { UnsupportedByPeer } from "src/lib/errors/UnsupportedByPeer";
import { CallStatus } from "src/lib/types/CallStatus";
import { Logger } from "src/util/Logger";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";
import type { CancelListener } from "src/lib/types/CancelListener";
import type { IClientResponse } from "src/lib/types/IClientResponse";
import type { SetupCallResponse } from "src/lib/types/SetupCallResponse";

const log = Logger.getLogger("SetupCallModule");
/**
 * Returned from <code>setupSetupCallModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class SetupCallModule {
  private constructor(private readonly requestManager: RequestManager) {}
  public static setup(
    requestManager: RequestManager
  ): Promise<SetupCallModule> {
    log.debug("setupCall module setup.");
    // No setup request required for setupCall module.
    return Promise.resolve(new SetupCallModule(requestManager));
  }
  /**
   * Start a new call to one or more entities.
   * @param {boolean} options.autoAccept <code>true</code> if call should be auto-accepted by
   * callee(s).
   * @param {string} [options.callableReferences] The entities to call.
   * @param {boolean} options.fullDuplex <code>true</code> if call should be full duplex.
   * @param {function(SetupCallResponse)} options.onResult Callback on final call response. If
   * accepted, a new session will be started.
   * @param {function()} options.onRinging Callback on temporary ringing response.
   * @param {Number} options.sessionId Set if this is an invite to an existing session.
   * @returns {function()} Abort function to cancel call. This will result in a
   * <code>{@link CallStatus}.Cancelled</code>.
   */
  public setupCall(options: {
    autoAccept: boolean;
    callableReferences: string[];
    fullDuplex?: boolean;
    onResult: (response: SetupCallResponse) => void;
    onRinging?: (responseCode: number) => void;
    sessionId?: number;
  }): CancelListener {
    const {
      autoAccept,
      callableReferences,
      fullDuplex,
      onResult,
      onRinging,
      sessionId,
    } = options;
    return this.requestManager.sendRequest(
      {
        setupCall: {
          initiateRequest: {
            autoAccept,
            callableReferences,
            fullDuplex,
            sessionId,
          },
        },
      },
      {
        onFinalResponse: SetupCallModule.responseToStatus(onResult),
        onTemporaryResponse: onRinging,
      }
    );
  }
  /**
   * Setup a monitoring call.
   * @param {string} entityId The entity to monitor.
   * @returns {Promise<SetupCallResponse>} Promise of the result of the call setup.
   */
  public startMonitoring(entityId: string): Promise<SetupCallResponse> {
    return new Promise((resolve) => {
      this.requestManager.sendRequest(
        {
          setupCall: { monitoringRequest: { entityId } },
        },
        {
          onFinalResponse: SetupCallModule.responseToStatus(resolve),
        }
      );
    });
  }
  private static responseToStatus(
    callback: (response: SetupCallResponse) => void
  ) {
    return (
      error: GroupTalkAPIError | null,
      response: IClientResponse | undefined
    ) => {
      if (!error) {
        callback({
          callRef: (response as proto.SetupCallInitiateResponse).callRef,
          callStatus: CallStatus.Accepted,
        });
      } else if (error instanceof BusyHere) {
        callback({ callStatus: CallStatus.Busy });
      } else if (error instanceof Declined) {
        callback({ callStatus: CallStatus.Declined });
      } else if (error instanceof NotOnline) {
        callback({ callStatus: CallStatus.CalleeNotOnline });
      } else if (error instanceof NotFound) {
        callback({ callStatus: CallStatus.CalleeNotFound });
      } else if (error instanceof RequestTerminated) {
        callback({ callStatus: CallStatus.Cancelled });
      } else if (error instanceof TemporarilyUnavailable) {
        callback({ callStatus: CallStatus.NoAnswer });
      } else if (error instanceof UnsupportedByPeer) {
        callback({ callStatus: CallStatus.Unsupported });
      } else {
        callback({ callStatus: CallStatus.GeneralFailure });
      }
    };
  }
}
