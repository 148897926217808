import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import { useEffect, useRef } from "react";
import type { MediaTrack } from "twilio-video/tsdef/MediaTrack";

export const MediaTrackComponent = connect<{
  invisible: boolean;
  mirrored: boolean;
  track: MediaTrack;
}>(({ invisible, mirrored, track }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (track && divRef.current) {
      // Attach the audio/video track to the DOM element
      if (divRef.current.children.length === 0) {
        divRef.current.appendChild(track.attach());
      }
    }

    return () => {
      // When the component unmounts, make sure to detach the track
      if (track) {
        track.detach();
      }
    };
  }, [track]);

  return invisible ? (
    <div ref={divRef}></div>
  ) : (
    <Layout
      className={mirrored ? "mirrored-video-container" : "video-container"}
      ref={divRef}
      width="100%"
    />
  );
});
