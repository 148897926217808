import { NetworkError } from "src/lib/errors/NetworkError";
import { WrappedPromise } from "src/util/WrappedPromise";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

export class WebSocketConnection {
  private readonly binaryType: BinaryType;
  private readonly onDisconnect: (error: NetworkError) => void;
  private readonly onMessage: (message: any) => void;
  private readonly protocol?: string | string[];
  private readonly server: string;
  private closed = false;
  private websocket?: WebSocket;
  private websocketPromise?: WrappedPromise<void>;
  public constructor(options: {
    binaryType?: BinaryType;
    onDisconnect: (disconnectError: GroupTalkAPIError) => void;
    onMessage: (data: any) => void;
    protocol?: string | string[];
    server: string;
  }) {
    const { binaryType = "arraybuffer" } = options;
    this.server = options.server;
    this.onMessage = options.onMessage;
    this.onDisconnect = options.onDisconnect;
    this.protocol = options.protocol;
    this.binaryType = binaryType;
  }
  public sendMessage(
    message: ArrayBufferLike | ArrayBufferView | Blob | string
  ): void {
    this.websocket?.send(message);
  }
  public start(): Promise<void> {
    this.websocketPromise = new WrappedPromise<void>();
    this.websocket = new window.WebSocket(this.server, this.protocol);
    this.websocket.binaryType = this.binaryType;
    this.websocket.onopen = () => this.websocketPromise?.resolve();
    this.websocket.onclose = () => this.close();
    this.websocket.onmessage = (message) => this.onMessage(message.data);
    return this.websocketPromise.promise;
  }
  public stop(): void {
    if (!this.closed && this.websocket) {
      this.websocket.close();
      // Don't wait for connection to actually close. Let it close in background.
      this.close();
    }
  }
  private close(): void {
    if (!this.closed) {
      this.closed = true;
      const error = new NetworkError(); // TODO: more finegrained error
      this.onDisconnect(error);
      this.websocketPromise?.reject(error);
      this.websocketPromise = undefined;
    }
  }
}
