import { SoundService } from "src/app/model/audio/SoundService";
import { BackgroundTimer } from "src/util/BackgroundTimer";

export class Ringer {
  private ringing = true;
  public constructor() {
    this.ringing = true;
    BackgroundTimer.setTimeout(() => {
      this.ringRunnable();
    }, 800);
  }
  public stopRinging(): void {
    this.ringing = false;
    SoundService.stopSound("ringback");
  }
  private ringRunnable(): void {
    if (this.ringing) {
      SoundService.playSound("ringback");
      BackgroundTimer.setTimeout(() => {
        this.ringRunnable();
      }, 5000);
    }
  }
}
