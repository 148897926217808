import { GtError } from "src/app/gui/components/GtError";
import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const Progress = ({ loading }: { loading: boolean }): JSX.Element => (
  <Layout
    alignCenter
    alignMiddle
    column
    height={loading ? 80 : 0}
    minHeight={loading ? 80 : 0}
  >
    {loading && <CircularProgress size={30} />}
  </Layout>
);

export const GtInfiniteRenderer = connect<{
  elements: React.ReactNode[];
  loadMore?: (elements?: number) => void;
  loading?: boolean;
  noElements?: string;
  onEnterTop?: () => void;
  onLeaveTop?: () => void;
  reverse?: true;
}>(
  (
    {
      elements,
      loadMore,
      loading,
      noElements,
      onEnterTop,
      onLeaveTop,
      reverse,
    },
    _,
    ref
  ) => {
    const outerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const [onTop, setOnTop] = useState(false);

    const checkLoad = (): void => {
      if (innerRef.current && outerRef.current) {
        const div = innerRef.current;
        const { scrollTop } = div;
        const { offsetHeight } = outerRef.current;
        const heightOutside =
          div.scrollHeight + (reverse ? 1 : -1) * scrollTop - offsetHeight;
        if (heightOutside < offsetHeight) {
          if (elements.length > 0) {
            const averageHeightPerElement = div.scrollHeight / elements.length;
            const elementsInOneScreen = Math.ceil(
              offsetHeight / averageHeightPerElement
            );
            if (loadMore) {
              loadMore(elementsInOneScreen);
            }
          } else if (loadMore) {
            loadMore();
          }
        }
        if (Math.abs(scrollTop) < 10 && !onTop) {
          setOnTop(true);
          if (onEnterTop) {
            onEnterTop();
          }
        } else if (Math.abs(scrollTop) >= 10 && onTop) {
          setOnTop(false);
          if (onLeaveTop) {
            onLeaveTop();
          }
        }
      }
    };

    useEffect(checkLoad, [
      innerRef,
      outerRef,
      elements,
      loadMore,
      onTop,
      setOnTop,
      onEnterTop,
      onLeaveTop,
      reverse,
    ]);

    useEffect(() => {
      window.addEventListener("resize", checkLoad);
      return () => {
        window.removeEventListener("resize", checkLoad);
      };
    });

    return (
      <Layout grow height="100%" ref={outerRef} relative width="100%">
        <Layout
          absolute
          alignBottom
          alignLeft
          bottom={0}
          column
          left={0}
          right={0}
          top={0}
        >
          <Layout
            style={{
              overflowY: elements.length === 0 ? "hidden" : "auto",
            }}
            className="custom-scroll"
            column
            height="100%"
            onScroll={checkLoad}
            onWheel={checkLoad}
            ref={innerRef}
            reverse={reverse ?? undefined}
            width="100%"
          >
            <div ref={ref} />
            {elements.length > 0 && elements}
            {noElements && !loading && elements.length === 0 && (
              <GtError message={noElements} severity="info" />
            )}
            <Progress loading={!!loading} />
          </Layout>
        </Layout>
      </Layout>
    );
  }
);
