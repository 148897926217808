import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { GroupIcon, ConferenceGroupIcon, PatchIcon } from "src/app/images";
import { connect } from "src/app/state/connect";

export const GroupRow = connect<{
  description?: string;
  disabled?: boolean;
  evenRow?: boolean;
  fullDuplex?: boolean;
  name: string;
  onClick: () => void;
  owner?: string;
  patched?: boolean;
  selected?: boolean;
  tooltipId: string;
}>(
  ({
    description,
    disabled,
    evenRow,
    fullDuplex,
    name,
    onClick,
    owner,
    patched,
    selected,
    tooltipId,
  }) => {
    let bg;
    if (selected) {
      bg = "#5479b1";
    } else {
      bg = evenRow ? "#7d91aa" : "#7789a2";
    }
    const Icon = fullDuplex ? ConferenceGroupIcon : GroupIcon;
    return (
      <Layout
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        alignMiddle
        background={bg}
        margin={0}
        padding={2}
        row
        style={{ cursor: disabled ? "auto" : "pointer" }}
      >
        {!disabled && <Icon style={{ fill: "#333", marginRight: 10 }} />}
        {disabled && <Icon style={{ fill: "#00000045", marginRight: 10 }} />}
        <Layout alignMiddle column height={30}>
          <Layout row>
            {owner && (
              <Layout column marginRight={5}>
                <Text
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  color="#ffbf00"
                  nowrap
                  size={14}
                >
                  {`${owner}: `}
                </Text>
              </Layout>
            )}
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              color={disabled ? "#555" : "#000"}
              nowrap
              size={14}
            >
              {name}
            </Text>
          </Layout>
          {description && (
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              color="#474747"
              nowrap
              size={10}
            >
              {description}
            </Text>
          )}
        </Layout>
        <Layout grow />
        {patched && (
          <GtTooltip
            id={`patched_tooltip_${tooltipId}`}
            title="Group is already in another patch"
          >
            <PatchIcon style={{ fill: "#00000045", marginRight: 10 }} />
          </GtTooltip>
        )}
      </Layout>
    );
  }
);
