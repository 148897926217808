import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { PresenceTag } from "src/app/gui/panels/video/PresenceTag";
import { connect } from "src/app/state/connect";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";
import type { RoomParticipant } from "src/app/model/video/RoomParticipant";

const NoUsersInSession = (
  <Layout alignCenter alignMiddle grow row>
    <Text color="#9e9eb3" size={20} weight={500}>
      No users in session
    </Text>
  </Layout>
);

const PresenceList = (
  presence: RoomParticipant[],
  panel: Panel
): JSX.Element => (
  <Layout padding={5} row style={{ overflowY: "auto" }} wrap>
    {presence.map((p) => (
      <PresenceTag key={p.id} panel={panel} presence={p} />
    ))}
  </Layout>
);

export const RoomPresenceTags = connect<{
  channel: Channel;
  panel: Panel;
}>(({ channel, panel }) =>
  Object.keys(channel.room!.presence).length === 0
    ? NoUsersInSession
    : PresenceList(Object.values(channel.room!.presence), panel)
);
