import { createState } from "src/app/state/createState";
import { StateContext } from "src/app/state/StateContext";

export const Provider = ({
  children,
  model,
}: {
  children: any;
  model: any;
}): any => (
  <StateContext.Provider value={createState(model)}>
    {children}
  </StateContext.Provider>
);
