import { Server } from "src/app/model/appData/Server";
import { Tokens } from "src/app/model/appData/Tokens";
import { UserData } from "src/app/model/appData/UserData";
import { Online } from "src/app/model/Online";
import { observableClass } from "src/app/state/observableClass";
import { CompanyInvalid } from "src/lib/errors/CompanyInvalid";
import { ConnectionClosedLocally } from "src/lib/errors/ConnectionClosedLocally";
import { InvalidLoginOrPassword } from "src/lib/errors/InvalidLoginOrPassword";
import { PaymentRequired } from "src/lib/errors/PaymentRequired";
import { TerminationByRequest } from "src/lib/errors/TerminationByRequest";
import { TokenExpired } from "src/lib/errors/TokenExpired";
import { Unauthorized } from "src/lib/errors/Unauthorized";
import { GroupTalkAPI } from "src/lib/GroupTalkAPI";
import { Logger } from "src/util/Logger";
import type { State } from "src/app/model/State";
import type { ConnectedModule } from "src/lib/modules/ConnectedModule";

const log = Logger.getLogger("offline");

export class Offline {
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public get hasToken(): boolean {
    return this.state.userData.hasUserData;
  }
  public deauthenticate(): void {
    Tokens.removeAll();
    this.state.userData.removeAll();
    void this.state.login.authenticationModule?.logout();
  }
  public async goOnline(purchaseUsage: boolean): Promise<void> {
    const { connecting, login } = this.state;
    login.error = null;
    connecting.start();
    const server = Server.selectedServer.WEBSOCKET_URL;
    try {
      Server.updateNext();
      await GroupTalkAPI.keepConnected({
        onConnect: (connectedModule) => {
          void this.onConnected(connectedModule, purchaseUsage);
        },
        onDisconnect: (error) => {
          log.debug(`Disconnected: ${error}`);
          if (
            error instanceof Unauthorized ||
            error instanceof InvalidLoginOrPassword ||
            error instanceof TokenExpired ||
            error instanceof CompanyInvalid ||
            error instanceof TerminationByRequest
          ) {
            this.state.userData.removeAll();
            Tokens.removeAll();
          }
          if (this.state.online) {
            this.state.online.closeDown();
          }
          this.state.online = null;
          if (error.temporary === undefined || error.temporary) {
            connecting.start();
            connecting.updateError(error);
          } else if (error instanceof PaymentRequired) {
            this.state.dialogs.show({
              actions: [
                {
                  label: "Cancel",
                  onSelect: () => connecting.endWithError(error),
                },
                {
                  label: "Purchase",
                  onSelect: () => {
                    void this.goOnline(true);
                  },
                },
              ],
              forceRespond: true,
              text: "A GroupTalk license for one day is required to go online. Do you want to purchase one?",
              title: `Purchase license?`,
            });
          } else {
            connecting.endWithError(error);
          }
        },
        server,
      });
    } catch (error: any) {
      if (!(error instanceof ConnectionClosedLocally)) {
        log.error(error);
      } else {
        log.debug("Connection closed locally (normal)");
      }
    }
  }
  private async onConnected(
    connectedModule: ConnectedModule,
    purchaseUsage: boolean
  ): Promise<void> {
    const { connecting, userAgent } = this.state;
    try {
      const capabilitiesModule = await connectedModule.requestCapabilities({
        deviceId: UserData.deviceId,
        userAgent,
      });
      await capabilitiesModule.setupKeepAlive();
      const online = new Online(this.state);
      await online.setup(capabilitiesModule, {
        purchaseUsage,
      });
      this.state.online = online;
      setTimeout(() => {
        // Update tickets
        this.state.online?.queueManagement.updateTicketPanels();
        // Possibly autopick a ticket at this time
        this.state.online?.queueManagement.pickNextTicket();
        // Possibly open call session
        this.state.online?.sessions.openOrFocusCallSessions();
        // Open rooms
        this.state.online?.sessions.openRooms();
        // Manage patches
        this.state.online?.patches.startManagingPatches();
      }, 1);
      connecting.setFinished();
    } catch (error: any) {
      connectedModule.disconnect(error);
    }
  }
}
