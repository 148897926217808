import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ContactMenu } from "src/app/gui/panels/contacts/ContactMenu";
import { PresenceIcon } from "src/app/gui/shared/PresenceIcon";
import { MicMutedIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { ButtonBase } from "@mui/material";
import type { Panel } from "src/app/model/panels/Panel";
import type { RoomParticipant } from "src/app/model/video/RoomParticipant";

const transistionStyle = { transition: "all .4s" };
const badgeBackground = (isSpeaking: boolean): string => {
  if (isSpeaking) {
    return "#33aa33";
  }
  return "#d0d4d8";
};
const boxShadow = (isLatestSpeaker: boolean): string => {
  if (isLatestSpeaker) {
    return "0px 0px 0px 5px #579868";
  }
  return "1px 2px 2px 1px rgba(0,0,0,.1)";
};
export const PresenceTag = connect<{
  description?: null | string;
  mini?: boolean;
  panel: Panel;
  presence: RoomParticipant;
}>(({ description = null, mini = false, panel, presence }) => {
  const {
    audioEnabled,
    contactDetails,
    fetchContactDetails,
    isSpeaking,
    name,
    title,
    type,
  } = presence;
  const nameColor = isSpeaking ? "#fff" : "#222";
  const titleColor = isSpeaking ? "#fff" : "#6d707b";

  const iconStyle = {
    ...transistionStyle,
    fill: isSpeaking ? "#fff" : "#333",
    height: isSpeaking && !mini ? 24 : 16,
    width: isSpeaking && !mini ? 24 : 16,
  };
  let height = 28;
  if (mini) {
    height = 20;
  } else if (isSpeaking) {
    height = 42;
  }
  return (
    <ContactMenu
      contactDetails={contactDetails}
      fetchContactDetails={fetchContactDetails}
      menuId={presence.presenceId}
      panel={panel}
    >
      <ButtonBase>
        <Layout
          style={{
            ...transistionStyle,
            border: "1px solid #333",
            borderRadius: isSpeaking && !mini ? "6px" : "3px",
            boxShadow: boxShadow(isSpeaking && !mini),
            opacity: audioEnabled ? 1.0 : 0.5,
            textAlign: "left",
          }}
          alignMiddle
          background={badgeBackground(isSpeaking)}
          height={height}
          margin={2}
          padding={mini ? "3px 3px" : "5px 8px"}
          row
        >
          <Layout column>
            <Layout alignMiddle gap={5} row>
              <PresenceIcon presenceType={type} style={iconStyle} />
              <Text
                style={{
                  maxWidth: mini ? 100 : undefined,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  ...transistionStyle,
                }}
                color={nameColor}
                nowrap
                size={isSpeaking && !mini ? 18 : 12}
                weight={400}
              >
                {name}
              </Text>
            </Layout>
            {!mini && (
              <Text
                color={titleColor}
                size={isSpeaking && !mini ? 15 : 10}
                style={{ ...transistionStyle }}
              >
                {description || title}
              </Text>
            )}
          </Layout>
          {!audioEnabled && (
            <Layout column marginLeft={10}>
              <MicMutedIcon style={{ fill: "rgb(0 38 0)" }} />
            </Layout>
          )}
        </Layout>
      </ButtonBase>
    </ContactMenu>
  );
});
