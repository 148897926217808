import { Channels } from "src/app/model/channels/Channels";
import { Organizations } from "src/app/model/organizations/Organizations";
import { NumberParameterSetting } from "src/app/model/settings/NumberParameterSetting";
import { StringSetting } from "src/app/model/settings/StringSetting";
import { observableClass } from "src/app/state/observableClass";
import { reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { ChannelNodeId } from "src/lib/types/ChannelNodeId";
import type { Organization } from "src/nextgen/types/Organization";
import type { OrganizationUuid } from "src/nextgen/types/OrganizationUuid";

type Parameters = {
  defaultPickedByOther: boolean;
  defaultUnpicked: boolean;
  groupFilter: Record<ChannelNodeId, boolean>;
  hiddenColumns: Record<string, boolean>;
  includeActiveGroups: boolean;
  includeAllGroups: boolean;
  includeUnknownOrNoGroup: boolean;
  organizationFilter: OrganizationUuid | null;
  organizationFilterName: null | string;
  queueFilter: Record<
    string,
    {
      pickedByOther: boolean;
      unpicked: boolean;
    }
  >;
  ticketDelay: number;
};

export class QueuePanelData {
  public readonly channels: Channels;
  public readonly defaultParameters = {
    defaultPickedByOther: false,
    defaultUnpicked: false,
    groupFilter: {},
    hiddenColumns: {},
    includeActiveGroups: false,
    includeAllGroups: true,
    includeUnknownOrNoGroup: true,
    organizationFilter: null,
    organizationFilterName: null,
    queueFilter: {},
    ticketDelay: 0,
  };
  public readonly filterSetting: StringSetting;
  public readonly organizations: Organizations;
  public ticketDelaySetting?: NumberParameterSetting;
  private disposeChannels?: IReactionDisposer;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    this.filterSetting = new StringSetting({
      defaultValue: "",
      key: `gt2.filter.${id}`,
    });
    this.channels = new Channels(state, "QueueCustomization");
    this.organizations = new Organizations(state, "group");
    observableClass(this);
  }
  public get defaultPickedByOther(): boolean {
    return !!this.parameters.defaultPickedByOther;
  }
  public get defaultUnpicked(): boolean {
    return !!this.parameters.defaultUnpicked;
  }
  public get includeActiveGroupSetting(): boolean {
    return !!this.parameters.includeActiveGroups;
  }
  public get includeActiveGroups(): boolean {
    return this.includeAllGroups || this.includeActiveGroupSetting;
  }
  public get includeAllGroups(): boolean {
    return !!this.parameters.includeAllGroups;
  }
  public get includeGroup() {
    return (channelId: ChannelNodeId) =>
      this.includeAllGroups || this.includeGroupSetting(channelId);
  }
  public get includeGroupSetting() {
    return (channelId: ChannelNodeId) =>
      !!this.parameters.groupFilter[channelId];
  }
  public get includeUnknownOrNoGroup(): boolean {
    return !!this.parameters.includeUnknownOrNoGroup;
  }
  public get mayToggleDefaultPickedByOther(): boolean {
    return this.panel.mayConfigure(["defaultPickedByOther"]);
  }
  public get mayToggleDefaultUnpicked(): boolean {
    return this.panel.mayConfigure(["defaultUnpicked"]);
  }
  public get mayToggleIncludeActiveGroups(): boolean {
    return this.panel.mayConfigure(["includeActiveGroups"]);
  }
  public get mayToggleIncludeAllGroups(): boolean {
    return this.panel.mayConfigure(["includeAllGroups"]);
  }
  public get mayToggleIncludeUnknownOrNoGroup(): boolean {
    return this.panel.mayConfigure(["includeUnknownOrNoGroup"]);
  }
  public get mayToggleShowColumn() {
    return (column: string) =>
      this.panel.mayConfigure(["hiddenColumns", column]);
  }
  public get numberIncludedGroups(): number {
    return Object.keys(this.parameters.groupFilter).length;
  }
  public get organizationFilter(): OrganizationUuid | null {
    return this.parameters.organizationFilter;
  }
  public get organizationFilterName(): null | string {
    return this.parameters.organizationFilterName;
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get pickedByOther() {
    return (queueEntityId: string): boolean =>
      this.defaultPickedByOther ||
      !!(
        this.parameters.queueFilter[queueEntityId] &&
        this.parameters.queueFilter[queueEntityId].pickedByOther
      );
  }
  public get showColumn() {
    return (column: string): boolean => !this.parameters.hiddenColumns[column];
  }
  public get ticketDelay(): number {
    return this.parameters.ticketDelay;
  }
  public get unpicked() {
    return (queueEntityId: string): boolean =>
      this.defaultUnpicked ||
      !!(
        this.parameters.queueFilter[queueEntityId] &&
        this.parameters.queueFilter[queueEntityId].unpicked
      );
  }
  public onCreate(): void {
    this.ticketDelaySetting = new NumberParameterSetting({
      configurable: () => this.panel.mayConfigure(["ticketDelay"]),
      getValue: () => {
        return this.parameters.ticketDelay;
      },
      minValue: 0,
      setValue: (newValue: number) => {
        this.panel.setData(["ticketDelay"], newValue);
      },
    });
  }
  public postCustomize(): void {
    this.disposeChannels?.();
    this.disposeChannels = undefined;
  }
  public preCustomize(): void {
    this.disposeChannels = reaction(
      () => ({
        name: this.filterSetting.value ? `%${this.filterSetting.value}%` : null,
        organizationIds: this.organizationFilter
          ? [this.organizationFilter]
          : null,
      }),
      (filter) => {
        this.channels.setFilter(filter);
      },
      { fireImmediately: true }
    );
    void this.organizations.fetchOrganizations();
  }
  public reset(): void {
    // This is needed to reset temporary value
    this.ticketDelaySetting?.setValue(`${this.defaultParameters.ticketDelay}`);
  }
  public setOrganization(organization: Organization | null): void {
    this.panel.setData(
      ["organizationFilter"],
      organization ? organization.id : null
    );
    this.panel.setData(
      ["organizationFilterName"],
      organization ? organization.name : null
    );
  }
  public toggleDefaultPickedByOther(): void {
    this.panel.setData(["defaultPickedByOther"], !this.defaultPickedByOther);
  }
  public toggleDefaultUnpicked(): void {
    this.panel.setData(["defaultUnpicked"], !this.defaultUnpicked);
  }
  public toggleIncludeActiveGroups(): void {
    this.panel.setData(["includeActiveGroups"], !this.includeActiveGroups);
  }
  public toggleIncludeAllGroups(): void {
    this.panel.setData(["includeAllGroups"], !this.includeAllGroups);
  }
  public toggleIncludeGroup(channelId: ChannelNodeId): void {
    this.panel.setData(
      ["groupFilter", channelId],
      !this.includeGroupSetting(channelId)
    );
  }
  public toggleIncludeUnknownOrNoGroup(): void {
    this.panel.setData(
      ["includeUnknownOrNoGroup"],
      !this.includeUnknownOrNoGroup
    );
  }
  public togglePickedByOther(queueEntityId: string): void {
    this.panel.setData(
      ["queueFilter", queueEntityId, "pickedByOther"],
      !this.pickedByOther(queueEntityId)
    );
  }
  public toggleShowColumn(column: string): void {
    this.panel.setData(["hiddenColumns", column], this.showColumn(column));
  }
  public toggleUnpicked(queueEntityId: string): void {
    this.panel.setData(
      ["queueFilter", queueEntityId, "unpicked"],
      !this.unpicked(queueEntityId)
    );
  }
}
