import { CreateGtTable } from "src/app/gui/components/CreateGtTable";
import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import type { GtTableRow } from "src/app/gui/components/GtTableRow";
import type { Panel } from "src/app/model/panels/Panel";

export const Settings = connect<{
  panel: Panel;
}>((props) => {
  const { panel } = props;
  const {
    mayToggleShowLatestSpeaker,
    mayToggleShowPttButton,
    mayToggleShowReplayButton,
    mayToggleShowVolumeControls,
    showLatestSpeaker,
    showPttButton,
    showReplayButton,
    showVolumeControls,
    toggleShowLatestSpeaker,
    toggleShowPttButton,
    toggleShowReplayButton,
    toggleShowVolumeControls,
  } = panel.channelPanelData!;
  const Table = CreateGtTable<GtTableRow>();
  return (
    <Layout column gap={5}>
      <Text>Set appearance for this panel:</Text>
      <Table
        columns={[
          {
            id: "value",
            render: ({ checked, disabled, label }) => (
              <Layout alignLeft alignMiddle row>
                <GtCheckbox checked={checked} disabled={disabled} />
                <Text>{label}</Text>
              </Layout>
            ),
          },
        ]}
        rows={[
          {
            checked: showPttButton,
            disabled: !mayToggleShowPttButton,
            id: "showPttButton",
            label: "Show ptt button",
            onClickRow: () => toggleShowPttButton(),
          },
          {
            checked: showReplayButton,
            disabled: !mayToggleShowReplayButton,
            id: "showReplayButton",
            label: "Show replay button",
            onClickRow: () => toggleShowReplayButton(),
          },
          {
            checked: showLatestSpeaker,
            disabled: !mayToggleShowLatestSpeaker,
            id: "showLatestSpeaker",
            label: "Show latest speaker",
            onClickRow: () => toggleShowLatestSpeaker(),
          },
          {
            checked: showVolumeControls,
            disabled: !mayToggleShowVolumeControls,
            id: "showVolumeControls",
            label: "Show volume controls",
            onClickRow: () => toggleShowVolumeControls(),
          },
        ]}
        getRowKey={(row) => row.id}
        onClickRow={(row) => row.onClickRow()}
      />
    </Layout>
  );
});
