import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { FullDuplexIcon, PrivateCallIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { SessionMediaType } from "src/lib/types/SessionMediaType";

export const CallsMenu = connect((_, state) => {
  const { callSessions, openOrFocusCallSessions } = state.online!.sessions;
  const bg = "#4f8e66";
  const label = `${callSessions.length} ongoing call${
    callSessions.length > 1 ? "s" : ""
  }`;

  const showFullDuplexIcon = callSessions.some(
    (session) => session.mediaType === SessionMediaType.TwilioFullDuplex
  );

  const items: JSX.Element[] = [];
  callSessions.forEach((session) => {
    if (items.length === 10) {
      return; /* Show max ten calls */
    }
    const UseIcon =
      session.mediaType === SessionMediaType.TwilioFullDuplex
        ? FullDuplexIcon
        : PrivateCallIcon;
    items.push(
      <GtMenuItem
        onSelect={() => {
          openOrFocusCallSessions(session.sessionId);
        }}
        icon={<UseIcon style={{ fill: "#666" }} />}
        key={`call-${session.sessionId}`}
        label={<Text color="#444">{`${session.name}`}</Text>}
      />
    );
  });

  return (
    <Layout row>
      <GtMenu
        id="calls"
        items={items}
        onOpen={() => Promise.resolve(items.length > 0)}
      >
        <Layout
          style={{
            borderRadius: 5,
            width: "100%",
          }}
          alignCenter
          alignMiddle
          background={bg}
          grow
          margin={8}
          padding="5px 10px 5px 10px"
          row
        >
          {showFullDuplexIcon && (
            <FullDuplexIcon style={{ fill: "#fff", marginRight: 5 }} />
          )}
          {!showFullDuplexIcon && (
            <PrivateCallIcon style={{ fill: "#fff", marginRight: 5 }} />
          )}
          <Text center color="#fff">
            {label}
          </Text>
        </Layout>
      </GtMenu>
    </Layout>
  );
});
