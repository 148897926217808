import { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { action } from "mobx";
import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";

export class UnpickedQueueState extends QueueState {
  public constructor(
    alertService: QueueAlertService,
    private newUnpickedQueueEntry: boolean
  ) {
    super(alertService, "UnpickedQueueState");
    this.pendingSubscriptionsCount = 0;
  }
  public newUnpickedEmergencyEntryEvent(): void {
    this.alertService.playEmergencyAlert();
  }
  public newUnpickedPrioEntryEvent(): void {
    this.alertService.playPrioAlert();
  }
  public newUnpickedQueueEntryEvent(): void {
    if (
      this.newUnpickedQueueEntry ||
      this.settings.normal.playSound.selected.id === "always"
    ) {
      this.alertService.playQueueAlert();
      this.remindAboutQueue();
      this.newUnpickedQueueEntry = false;
    }
  }
  public onEnter(): void {
    this.remindAboutQueue();
    this.settingsUnsubscribe =
      this.settings.normal.reminderIntervalInSeconds.subscribeOnChanges(() => {
        this.remindAboutQueue();
      });
  }
  public onExit(): void {
    if (this.alertTimer !== undefined) {
      this.alertTimer();
      this.alertTimer = undefined;
    }
    this.settingsUnsubscribe?.();
  }
  public remindAboutQueue(): void {
    if (this.alertTimer !== undefined) {
      this.alertTimer();
      this.alertTimer = undefined;
    }
    if (this.settings.normal.reminderIntervalInSeconds.value) {
      this.alertTimer = BackgroundTimer.setTimeout(
        action(() => {
          if (this.settings.normal.reminderIntervalInSeconds.value) {
            this.alertService.playQueueAlert();
            this.remindAboutQueue();
          }
        }),
        this.settings.normal.reminderIntervalInSeconds.value * 1000
      );
    }
  }
}
