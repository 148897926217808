import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { GroupSelection } from "src/app/gui/settings/GroupSelection";
import { ListSetting } from "src/app/gui/settings/ListSetting";
import { SearchSetting } from "src/app/gui/settings/SearchSetting";
import { UsbIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { UsbDevice } from "src/app/model/input/UsbDevice";

export const USBDevice = connect<{
  device: UsbDevice;
}>(({ device }, state) => {
  const { buttons, deviceName } = device;
  const { filterSetting } = state.settings.groupSelectionSettings;

  return (
    <Layout
      background="#c0c7d04d"
      column
      gap={10}
      marginBottom={10}
      padding={10}
      style={{ border: "1px solid black", borderRadius: 5 }}
    >
      <Layout alignMiddle gap={10} row>
        <UsbIcon />
        <Text>{deviceName}</Text>
      </Layout>
      <Layout alignMiddle minHeight={50} row>
        <SearchSetting
          alwaysExpanded
          label="Filter Group Lists"
          setting={filterSetting}
        />
      </Layout>

      {buttons.map((button) => (
        <Layout
          background={button.pressed ? "#94bda391" : "#4a4a4a21"}
          column
          key={button.id}
          margin={4}
          paddingLeft={12}
          style={{ borderRadius: 4 }}
        >
          <ListSetting
            id={button.id}
            label={button.label}
            none={false}
            setting={button.setting}
          />
          {button.showGroup && <GroupSelection id={button.fixedGroupId} />}
          {button.showTicketPanel && (
            <ListSetting
              id={button.ticketPanelId}
              label=""
              setting={button.ticketPanel}
            />
          )}
        </Layout>
      ))}
    </Layout>
  );
});
