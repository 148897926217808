import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";

export class SetSetting {
  private readonly key: string;
  private values: string[];
  public constructor({ key }: { key: string }) {
    this.key = key;
    this.values = (localStorage.getItem(key) || "").split("|");
    observableClass(this);
  }
  public get contains() {
    return (element: string): boolean =>
      !!this.values.find((e) => e === element);
  }
  public add(element: string): void {
    if (!this.contains(element)) {
      this.values.push(element);
      this.save();
    }
  }
  public clear(): void {
    this.values = [];
    this.save();
  }
  public remove(element: string): void {
    this.values = this.values.filter((e) => e !== element);
    this.save();
  }
  public save(): void {
    localStorage.setItem(this.key, this.valueString);
  }
  private get valueString(): string {
    return this.values.join("|");
  }
}
