import { Layout } from "src/app/gui/components/Layout";
import { AccuracyRing } from "src/app/gui/panels/location/AccuracyRing";
import { ContextMenu } from "src/app/gui/panels/location/ContextMenu";
import { DeviceDot } from "src/app/gui/panels/location/DeviceDot";
import { connect } from "src/app/state/connect";
import L from "leaflet";
import { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  ScaleControl,
  useMap,
  useMapEvents,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import type { LeafletMouseEvent } from "leaflet";
import type { LocationData } from "src/app/model/location/LocationData";

const { BaseLayer } = LayersControl;
const zoomLevel = { initial: 15, max: 19, min: 0 };

const MapInner = connect<{
  fullView?: boolean;
  locationData: LocationData;
}>(({ fullView, locationData }, state) => {
  const {
    contactDetails,
    deselect,
    followingUser,
    globalTime,
    lookupSelectedContact,
    panel,
    selectUserId,
    selectedUserLocation,
    setClickLocation,
    setStoredLayer,
    storedLayer,
    subscribe,
    updateBoundsIfNeeded,
    userLocations,
  } = locationData;
  const { open } = state.menu;
  const locationPanelId = `location_${panel.id}`;

  const map = useMap();

  const updateBounds = (): void => {
    updateBoundsIfNeeded(map.getBounds());
  };
  const onContextMenu = (e: LeafletMouseEvent): void => {
    setClickLocation({ location: e.latlng, zoom: map.getZoom() });
    const event = e.originalEvent;
    event.preventDefault();
    const params = {
      id: locationPanelId,
      left: event.clientX - 2,
      top: event.clientY - 4,
    };
    if (selectedUserLocation) {
      void (async () => {
        await lookupSelectedContact();
        open(params);
      })();
    } else {
      open(params);
    }
  };

  useEffect(() => {
    map.invalidateSize();
  });

  useEffect(() => {
    void subscribe({
      onSubscribed: (initialArray, storedBounds, resetViewOnLogin) => {
        if (storedBounds && !resetViewOnLogin) {
          map.fitBounds(storedBounds);
        } else if (initialArray.length > 0) {
          map.fitBounds(L.latLngBounds(initialArray), {
            maxZoom: zoomLevel.initial,
            padding: L.point(50, 50),
          });
        } else {
          map.setView([40, 0], 1);
        }
        updateBoundsIfNeeded(map.getBounds());
      },
    });
  }, [subscribe, updateBoundsIfNeeded]);

  useMapEvents({
    baselayerchange: (e) => {
      setStoredLayer(e.name);
    },
    click: () => {
      deselect();
    },
    contextmenu: onContextMenu,
    moveend: () => {
      updateBounds();
    },
    resize: () => {
      updateBounds();
    },
    viewreset: () => {
      updateBounds();
    },
  });
  return (
    <Layout>
      <LayersControl position="topright">
        <BaseLayer
          checked={storedLayer == null || storedLayer === "Standard"}
          name="Standard"
        >
          <TileLayer
            maxZoom={zoomLevel.max}
            minZoom={zoomLevel.min}
            tileSize={512}
            url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${window.gtConfig.mapBoxToken}`}
            zoomOffset={-1}
          />
        </BaseLayer>
        <BaseLayer checked={storedLayer === "Satellite"} name="Satellite">
          <TileLayer
            maxZoom={zoomLevel.max}
            minZoom={zoomLevel.min}
            url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${window.gtConfig.mapBoxToken}`}
          />
        </BaseLayer>
        <BaseLayer checked={storedLayer === "Alternative"} name="Alternative">
          <TileLayer
            attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            maxZoom={zoomLevel.max}
            minZoom={zoomLevel.min}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
      </LayersControl>
      <ScaleControl />
      <MarkerClusterGroup spiderfyDistanceMultiplier={3}>
        {userLocations
          .filter((userLocation) => selectedUserLocation !== userLocation)
          .map((userLocation) => (
            <DeviceDot
              onContextMenu={(event: LeafletMouseEvent) => {
                onContextMenu(event);
              }}
              onSelect={() => {
                selectUserId(userLocation.userId);
              }}
              deviceEntry={userLocation}
              following={followingUser === userLocation}
              key={userLocation.userId}
              selected={false}
            />
          ))}
      </MarkerClusterGroup>
      {selectedUserLocation &&
        selectedUserLocation.latestLocation?.accuracy && (
          <AccuracyRing location={selectedUserLocation.latestLocation} />
        )}
      {selectedUserLocation && (
        <DeviceDot
          onSelect={() => {
            selectUserId(selectedUserLocation.userId);
          }}
          deviceEntry={selectedUserLocation}
          following={followingUser === selectedUserLocation}
          globalTime={globalTime}
          onContextMenu={onContextMenu}
          selected
        />
      )}
      <ContextMenu
        contactDetails={contactDetails}
        fullView={fullView}
        id={locationPanelId}
        locationData={locationData}
        panel={panel}
      />
    </Layout>
  );
});

export const Map = connect<{
  fullView?: boolean;
  locationData: LocationData;
}>(({ fullView, locationData }) => (
  <MapContainer
    maxBounds={[
      [-90, -1800],
      [90, 1800],
    ]}
    style={{ flexGrow: 1 }}
  >
    <MapInner fullView={fullView} locationData={locationData} />
  </MapContainer>
));
