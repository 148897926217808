import localStorage from "mobx-localstorage";

const SELECTED_SERVER = "gt2.account.server";
const NEXT_SERVER_INDEX = "gt2.account.nextserver";

type ServerConfig = {
  API_SERVER_URL?: string;
  GRAPHQL_API_URL?: string;
  LOGIN_SERVER_URL?: string;
  NOTIFICATION_WEBSOCKET_URL?: string;
  OAUTH2_AUTH_URL?: string;
  OAUTH2_LOGOUT_URL?: string;
  OAUTH2_TOKEN_URL?: string;
  SUBSCRIPTION_WEBSOCKET_URL?: string;
  WEBSOCKET_URL: string;
};

export class Server {
  public static get apiServer(): string {
    return Server.selectedServer != null && Server.selectedServer.API_SERVER_URL
      ? Server.selectedServer.API_SERVER_URL
      : window.location.origin.replace("dispatcher", "api");
  }
  public static get authParams(): {
    audience: string;
    authorizeUrl: string;
    logoutUrl: string;
    redirectUrl: string;
    scope: string;
    tokenUrl: string;
  } {
    return {
      // TODO: settle on appropriate URLs for each server app here.s
      audience: "",
      authorizeUrl:
        Server.selectedServer.OAUTH2_AUTH_URL ||
        `${Server.loginServer}/oauth2/auth`,
      logoutUrl:
        Server.selectedServer.OAUTH2_LOGOUT_URL ||
        `${Server.loginServer}/oauth2/sessions/logout`,
      redirectUrl: window.location.origin,
      scope: "openid offline_access messaging dispatch",
      tokenUrl:
        Server.selectedServer.OAUTH2_TOKEN_URL ||
        `${Server.loginServer}/oauth2/token`,
    };
  }
  public static get graphQLApi(): string {
    return Server.selectedServer != null &&
      Server.selectedServer.GRAPHQL_API_URL
      ? Server.selectedServer.GRAPHQL_API_URL
      : Server.apiServer.replace("api", "graphql");
  }
  public static get list(): ServerConfig[] {
    if (!window.gtConfig.servers || window.gtConfig.servers.length === 0) {
      const host = window.location.host.replace("dispatcher", "dispatcher-ws");
      const secure = window.location.protocol.startsWith("https");
      return [
        {
          WEBSOCKET_URL: `${(secure ? "wss://" : "ws://") + host}/clientv2`,
        },
      ];
    }
    return window.gtConfig.servers;
  }
  public static get loginServer(): string {
    return Server.selectedServer != null &&
      Server.selectedServer.LOGIN_SERVER_URL
      ? Server.selectedServer.LOGIN_SERVER_URL
      : Server.apiServer.replace("api", "openid");
  }
  public static get notificationWebsocket(): string {
    return Server.selectedServer != null &&
      Server.selectedServer.NOTIFICATION_WEBSOCKET_URL
      ? Server.selectedServer.NOTIFICATION_WEBSOCKET_URL
      : `${Server.apiServer
          .replace("api", "notification-service")
          .replace("https", "wss")}/v1/notifications`;
  }
  public static get selectedConfig(): ServerConfig {
    const server = localStorage.getItem(SELECTED_SERVER);
    // Check that server actually is one of the ones in the config file
    const selected = Server.list.find((s) => server === s.WEBSOCKET_URL);
    return selected || Server.list[0];
  }
  public static get selectedServer(): ServerConfig {
    if (this.selectedConfig instanceof Array) {
      const nextServer = Server.getNextServer(this.selectedConfig.length);
      return this.selectedConfig[nextServer];
    }
    return this.selectedConfig;
  }
  public static get showServerSelect(): boolean {
    return Server.list.length > 1;
  }
  public static get subscriptionWebsocket(): string {
    return Server.selectedServer != null &&
      Server.selectedServer.SUBSCRIPTION_WEBSOCKET_URL
      ? Server.selectedServer.SUBSCRIPTION_WEBSOCKET_URL
      : `${Server.apiServer
          .replace("api", "subscription-service")
          .replace("https", "wss")}/v1/subscriptions`;
  }
  public static select(server: string | string[]): void {
    localStorage.setItem(
      SELECTED_SERVER,
      server instanceof Array ? server.join() : server
    );
  }
  public static updateNext(): void {
    const server = localStorage.getItem(SELECTED_SERVER);
    for (let i = 0; i < Server.list.length; i += 1) {
      const confServer = Server.list[i];
      if (confServer instanceof Array && server === confServer.join()) {
        const nextServer = Server.getNextServer(confServer.length);
        localStorage.setItem(
          NEXT_SERVER_INDEX,
          (nextServer + 1) % confServer.length
        );
      }
    }
  }
  private static getNextServer(serverCount: number): number {
    let nextServer = parseInt(localStorage.getItem(NEXT_SERVER_INDEX), 10);
    if (
      Number.isNaN(nextServer) ||
      nextServer >= serverCount ||
      nextServer < 0
    ) {
      nextServer = Math.floor(Math.random() * serverCount);
    }
    return nextServer;
  }
}
