import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import {
  MicIcon,
  MicMutedIcon,
  IncomingIcon,
  MuteGroupIcon,
  TimeIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";
import { Button } from "@mui/material";
import type { Channel } from "src/app/model/channels/Channel";
import type { Session } from "src/app/model/sessions/Session";

export const PTTButton = connect<{
  channel?: Channel;
  mini?: boolean;
  session: Session;
  showLatestSpeaker?: boolean;
  showOrganization?: boolean;
}>(
  ({
    channel,
    mini,
    session,
    showLatestSpeaker = true,
    showOrganization = true,
  }) => {
    let buttonColor = session.incomingTalkburst ? "#33aa33" : "#585868";
    if (session.outgoingTalkburst) {
      buttonColor = "#ff4444";
    }

    const muted = session.mutedIncomingTalkburst;
    const disabled =
      session.actualMute ||
      muted ||
      (session.broadcasting && !session.selectedForBroadcasting) ||
      session.txDenied;

    let PttIcon = disabled ? MicMutedIcon : MicIcon;
    if (session.incomingTalkburst) {
      PttIcon = IncomingIcon;
    }
    if (muted) {
      PttIcon = MuteGroupIcon;
    }
    const fillColor =
      session.incomingTalkburst || session.outgoingTalkburst
        ? "#fff"
        : "#e6e6e6";

    let down = false;
    const onPttUp = (event: Event): void => {
      if (down) {
        event.preventDefault();
        down = false;
        document.removeEventListener("touchend", onPttUp);
        document.removeEventListener("mouseup", onPttUp);
        session.pttWithPossibleBroadcastUp();
      }
    };
    const onPttDown = (event: Event): void => {
      if (!down) {
        event.preventDefault();
        down = true;
        document.addEventListener("touchend", onPttUp);
        document.addEventListener("mouseup", onPttUp);
        session.pttWithPossibleBroadcastDown();
      }
    };
    return (
      <Layout row>
        <Button
          style={{
            background: buttonColor,
            border: "1px solid black",
            flexGrow: 1,
            justifyContent: mini ? "center" : "left",
            opacity: disabled ? 0.5 : 1.0,
            overflow: "hidden",
            padding: mini ? 0 : undefined,
            textOverflow: "ellipsis",
            textTransform: "none",
            transition: "all .4s",
            whiteSpace: "nowrap",
          }}
          disableRipple
          disabled={disabled}
          onMouseDown={(event) => onPttDown(event.nativeEvent)}
          onTouchStart={(event) => onPttDown(event.nativeEvent)}
          variant="contained"
        >
          <Layout alignCenter alignMiddle row>
            <PttIcon height={32} style={{ fill: fillColor }} width={32} />
            {!mini && (
              <Layout alignLeft column marginLeft={5}>
                <Text
                  color={fillColor}
                  size={session.latestSpeakerName ? 12 : undefined}
                  style={{ lineHeight: "1.2em", textTransform: "none" }}
                >
                  {session.name}
                </Text>
                {muted && (
                  <Text color={fillColor} style={{ textTransform: "none" }}>
                    MUTED (Same mute group)
                  </Text>
                )}
                {channel && channel.owner && showOrganization && (
                  <Text
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color="#ffbf00"
                    nowrap
                    size={12}
                  >
                    {channel.owner}
                  </Text>
                )}
                {!muted && session.latestSpeakerName && showLatestSpeaker && (
                  <Layout alignLeft alignMiddle row>
                    <TimeIcon
                      style={{
                        fill: "#ccc",
                        height: 12,
                        marginRight: 4,
                        width: 12,
                      }}
                    />
                    <Text
                      color="#ccc"
                      size={10}
                      style={{ textTransform: "none" }}
                    >
                      {session.latestSpeakerName}
                    </Text>
                  </Layout>
                )}
              </Layout>
            )}
          </Layout>
        </Button>
      </Layout>
    );
  }
);
