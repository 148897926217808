import { Logger } from "twilio-video";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("TalkburstModule");

/**
 * A single talkburst transmission.
 */
export class TalkburstTransmission {
  public onInterrupt: () => void;
  public onStopped?: () => Promise<void>;
  public talkburstId: number;
  private stopped = false;
  public constructor(
    private readonly requestManager: RequestManager,
    options: {
      onInterrupt: () => void;
      onStopped: () => Promise<void>;
      talkburstId: number;
    }
  ) {
    this.requestManager = requestManager;
    /**
     * Unique identifier of talkburst.
     * @member {Number}
     */
    this.talkburstId = options.talkburstId;
    /**
     * Callback on interrupt which can happen for example if a client
     * with higher priority send simultanously.
     * @member {function()}
     */
    this.onInterrupt = options.onInterrupt;
    this.onStopped = options.onStopped;
  }
  /**
   * Stop this transmission.
   * @param {Number} lastSentSeqNo Last sent sequence number.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async stopTransmission(lastSentSeqNo: number): Promise<void> {
    if (!this.stopped) {
      this.stopped = true;
      log.debug(`Stopping transmission with lastSentSeqNo ${lastSentSeqNo}`);
      try {
        await this.requestManager.send({
          talkburstTransmission: {
            stopRequest: {
              lastSentSeqNo,
              talkburstId: this.talkburstId,
            },
          },
        });
      } catch (error: any) {
        log.warn(error);
      }
    } else {
      log.warn("Talkburst is already stopped.");
    }
  }
}
