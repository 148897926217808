import { Channels } from "src/app/model/channels/Channels";
import { Constants } from "src/app/model/Constants";
import { StringSetting } from "src/app/model/settings/StringSetting";
import { observableClass } from "src/app/state/observableClass";
import { reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { Panel } from "src/app/model/panels/Panel";
import type { Patch } from "src/app/model/patches/Patch";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { PanelData } from "src/app/types/PanelData";
import type { TabColor } from "src/app/types/TabColor";

type Parameters = {
  patchId: null | string;
  patchName: null | string;
};

export class PatchPanelData implements PanelData {
  public readonly availableChannels: Channels;
  public readonly searchSetting: StringSetting;
  public defaultParameters: Parameters = {
    patchId: null,
    patchName: null,
  };
  public editing = false;
  public patch: Patch | null = null;
  private disposeChannels?: IReactionDisposer;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    this.availableChannels = new Channels(state, "PatchChannel");
    this.searchSetting = new StringSetting({
      defaultValue: "",
      key: `gt2.filter.${id}`,
    });
    observableClass(this);
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get tabColor(): TabColor | undefined {
    return this.patch !== null && this.patch.patchColor !== null
      ? {
          background: this.patch.patchColor,
          textColor: "#444",
        }
      : undefined;
  }
  public edit(edit: boolean): void {
    this.editing = edit;
    if (this.editing) {
      this.availableChannels.refresh();
    }
  }
  public onClosing(performClose: () => void): void {
    if (!this.patch) {
      performClose();
      return;
    }
    const tabset = this.state.layout.tabsetIdContainingPanel(this.panel.id);
    localStorage.setItem(Constants.LAST_CLOSED_PATCH_TAB, tabset!);
    void this.patch.breakPatch({
      number: this.parameters.patchName ?? undefined,
      withConfirmation: this.patch.groups!.length > 0,
    });
  }
  public onCreate(): void {
    this.disposeChannels = reaction(
      () => ({
        name: this.searchSetting.value ? `%${this.searchSetting.value}%` : null,
        notInPatchId: this.patch ? this.patch.patchId : null,
      }),
      (filter) => {
        this.availableChannels.setFilter(filter);
      },

      { fireImmediately: true }
    );
  }
  public onDelete(): void {
    this.editing = false;
    if (this.disposeChannels) {
      this.disposeChannels();
    }
  }
  public setPatch(patch: Patch): void {
    this.patch = patch;
    if (this.editing) {
      this.availableChannels.refresh();
    }
  }
}
