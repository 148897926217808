/**
 * Efficient tracking of sequences of numbers.
 * E.g. the numbers 1,2,3,5,6,9,13,14,15,16 would be stored as 1,3, 5,6, 9,9, 13,16.
 * <p/>
 * The SequenceTracker always keep the ranges in an ascending order, merging wherever possible.
 * @private
 */
export class SequenceTracker {
  private readonly list: number[] = [];
  /**
   * Add a sequence number to tracker.
   *
   * @param seqNo the number to add
   * @return true if the number was added, false if already present
   */
  public add(seqNo: number): boolean {
    const listSize = this.list.length;
    if (listSize === 0) {
      // No ranges, create new and be done
      this.list.push(seqNo);
      this.list.push(seqNo);
      return true;
    }
    // Check for addition last in list, common cast
    // Important, otherwise algorithm below might fail with indexOutOfBounds
    const last = this.list[listSize - 1];
    if (seqNo === last + 1) {
      // Yes, we can just add to from
      this.list[listSize - 1] = seqNo;
      return true;
    }
    if (seqNo > last + 1) {
      // Add new from last
      this.list.push(seqNo);
      this.list.push(seqNo);
      return true;
    }

    // Ok, let's process list and see where we would put this. Determine if that index is
    // between segments or in segment and create new sequence, merge or modify accordingly.
    for (let i = 0; i < listSize; i += 2) {
      const i1 = this.list[i];
      if (i1 < seqNo) {
        const i2 = this.list[i + 1];
        if (i2 < seqNo - 1) {
          // Not related to this sequence
        } else if (i2 >= seqNo) {
          // Already in list
          return false;
        }
        // Ok, i2 == seqNo - 1, we may join with segment.
        // Note that i+2 must be less than size, because otherwise
        // we would have ended up in first case
        else if (this.list[i + 2] === seqNo + 1) {
          // Ok, seqNo is the missing piece: (A,X-1,X+1,B -> A,B)
          this.list.splice(i + 2, 1);
          this.list.splice(i + 1, 1);
          return true;
        } else {
          // Ok, just update sequence: (A,X-1 -> A,X)
          this.list[i + 1] = seqNo;
          return true;
        }
      } else if (i1 > seqNo + 1) {
        // Before this sequence
        this.list.splice(i, 0, seqNo);
        this.list.splice(i, 0, seqNo);
        return true;
      } else if (i1 === seqNo + 1) {
        // Modify sequence
        this.list[i] = seqNo;
        return true;
      } else {
        // i1 == seqNo
        // Already in list
        return false;
      }
    }
    // We should never get here!
    return false;
  }
  public toString(): string {
    let s = "";
    for (let i = 0; i < this.list.length; i += 1) {
      if (i > 0 && i % 2 === 0) {
        s += ",";
      } else if (i % 2 === 1) {
        s += "-";
      }
      s += this.list[i];
    }
    return s;
  }
}
