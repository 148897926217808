import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Too many requests
 */
export class TooManyRequests extends GroupTalkAPIError {
  public constructor() {
    super("Too many requests.", true);
  }
}
