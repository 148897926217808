import { Layout } from "src/app/gui/components/Layout";
import { Settings } from "src/app/gui/panels/channels/customizing/Settings";
import { connect } from "src/app/state/connect";
import { AppBar, Tabs, Tab } from "@mui/material";
import type { Panel } from "src/app/model/panels/Panel";

export const Customizing = connect<{
  panel: Panel;
}>(({ panel }) => (
  <Layout column grow>
    <AppBar color="primary" position="static">
      <Tabs
        onChange={(_, newValue) => {
          panel.setTab(newValue);
        }}
        value={panel.tab}
      >
        <Tab id="0" label="Settings" />
      </Tabs>
    </AppBar>
    <Layout
      background="rgba(0,0,0,0.1)"
      column
      grow
      padding={20}
      style={{ overflow: "hidden" }}
    >
      {panel.tab === 0 && <Settings panel={panel} />}
    </Layout>
  </Layout>
));
