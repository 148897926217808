import { observableClass } from "src/app/state/observableClass";
import type { State } from "src/app/model/State";

export class Menu {
  public element: Element | null = null;
  public id: null | string = null;
  public left: null | number = null;
  public top: null | number = null;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public close(): void {
    this.element = null;
    this.id = null;
  }
  public open(options: {
    element?: Element;
    id: string;
    left?: null | number;
    top?: null | number;
  }): void {
    const { element = null, id, left = null, top = null } = options;
    this.element = element;
    this.id = id;
    this.left = left;
    this.top = top;
    // Close any tooltip when menu opens
    this.state.tooltip.hide();
  }
}
