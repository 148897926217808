import { GtError } from "src/app/gui/components/GtError";
import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { GroupFilterRow } from "src/app/gui/panels/tickets/customizing/GroupFilterRow";
import { SearchSetting } from "src/app/gui/settings/SearchSetting";
import { OrganizationFilter } from "src/app/gui/shared/OrganizationFilter";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const GroupFilter = connect<{
  panel: Panel;
}>(({ panel }) => {
  const {
    filterSetting,
    includeActiveGroupSetting,
    includeAllGroups,
    includeGroupSetting,
    includeUnknownOrNoGroup,
    mayToggleIncludeActiveGroups,
    mayToggleIncludeAllGroups,
    mayToggleIncludeUnknownOrNoGroup,
    numberIncludedGroups,
    toggleIncludeActiveGroups,
    toggleIncludeAllGroups,
    toggleIncludeGroup,
    toggleIncludeUnknownOrNoGroup,
  } = panel.queuePanelData!;
  const { channels, error, loadMore, loading } = panel.queuePanelData!.channels;
  let noElementsMessage = "No groups available.";
  if (filterSetting.value.length > 0) {
    noElementsMessage = "No groups matching filter";
  }
  return (
    <Layout column>
      <Layout alignMiddle minHeight={50} row>
        <Text>Always show tickets from:</Text>
      </Layout>
      <Layout grow row />
      {error && <GtError message={error} />}
      <GroupFilterRow
        checked={includeUnknownOrNoGroup}
        description="Tickets from users with unknown or no group"
        disabled={!mayToggleIncludeUnknownOrNoGroup}
        evenRow
        name="Unknown or no group"
        onClick={() => toggleIncludeUnknownOrNoGroup()}
      />
      <GroupFilterRow
        checked={includeAllGroups}
        description="Tickets from users in all group"
        disabled={!mayToggleIncludeAllGroups}
        name="All groups"
        onClick={() => toggleIncludeAllGroups()}
      />
      <GroupFilterRow
        checked={includeActiveGroupSetting}
        description="Tickets from users in joined groups"
        disabled={includeAllGroups || !mayToggleIncludeActiveGroups}
        evenRow
        name="Joined groups"
        onClick={() => toggleIncludeActiveGroups()}
      />
      <Layout alignTop column grow height={410} marginTop={10}>
        <Layout alignMiddle minHeight={50} row>
          <Text>
            {`In addition always show tickets from the following groups: (${numberIncludedGroups} selected)`}
          </Text>
          <Layout column grow />
          <SearchSetting label="Search" setting={filterSetting} />
          <OrganizationFilter panelData={panel.queuePanelData!} />
        </Layout>
        <GtInfiniteRenderer
          elements={channels.map((channel, index) => (
            <GroupFilterRow
              checked={
                channel.entityId ? includeGroupSetting(channel.entityId) : false
              }
              onClick={
                channel.entityId
                  ? () => toggleIncludeGroup(channel.entityId!)
                  : undefined
              }
              description={channel.description}
              disabled={includeAllGroups}
              evenRow={index % 2 === 1}
              key={channel.channelUuid}
              name={channel.name}
              type={channel.joined ? "joined_group" : "joinable_group"}
            />
          ))}
          loadMore={loadMore}
          loading={loading}
          noElements={noElementsMessage}
        />
      </Layout>
    </Layout>
  );
});
