var Module = { print: function print(x) {}, printErr: function printErr(x) {} };
var Module;
if (!Module) Module = (typeof Module !== "undefined" ? Module : null) || {};
let moduleOverrides = {};
for (var key in Module) {
  if (Module.hasOwnProperty(key)) {
    moduleOverrides[key] = Module[key];
  }
}
let ENVIRONMENT_IS_NODE =
  typeof process === "object" && typeof require === "function";
let ENVIRONMENT_IS_WEB = typeof window === "object";
let ENVIRONMENT_IS_WORKER = typeof importScripts === "function";
let ENVIRONMENT_IS_SHELL =
  !ENVIRONMENT_IS_WEB && !ENVIRONMENT_IS_NODE && !ENVIRONMENT_IS_WORKER;
if (ENVIRONMENT_IS_NODE) {
  if (!Module.print)
    Module.print = function print(x) {
      process.stdout.write(`${x}\n`);
    };
  if (!Module.printErr)
    Module.printErr = function printErr(x) {
      process.stderr.write(`${x}\n`);
    };
  let nodeFS = require("fs");
  let nodePath = require("path");
  Module.read = function read(filename, binary) {
    filename = nodePath.normalize(filename);
    let ret = nodeFS.readFileSync(filename);
    if (!ret && filename != nodePath.resolve(filename)) {
      filename = path.join(__dirname, "..", "src", filename);
      ret = nodeFS.readFileSync(filename);
    }
    if (ret && !binary) ret = ret.toString();
    return ret;
  };
  Module.readBinary = function readBinary(filename) {
    return Module.read(filename, true);
  };
  Module.load = function load(f) {
    globalEval(read(f));
  };
  if (!Module.thisProgram) {
    if (process.argv.length > 1) {
      Module.thisProgram = process.argv[1].replace(/\\/g, "/");
    } else {
      Module.thisProgram = "unknown-program";
    }
  }
  Module.arguments = process.argv.slice(2);
  if (typeof module !== "undefined") {
    module.exports = Module;
  }
  process.on("uncaughtException", (ex) => {
    if (!(ex instanceof ExitStatus)) {
      throw ex;
    }
  });
} else if (ENVIRONMENT_IS_SHELL) {
  if (!Module.print) Module.print = print;
  if (typeof printErr != "undefined") Module.printErr = printErr;
  if (typeof read !== "undefined") {
    Module.read = read;
  } else {
    Module.read = function read() {
      throw "no read() available (jsc?)";
    };
  }
  Module.readBinary = function readBinary(f) {
    if (typeof readbuffer === "function") {
      return new Uint8Array(readbuffer(f));
    }
    let data = read(f, "binary");
    assert(typeof data === "object");
    return data;
  };
  if (typeof scriptArgs !== "undefined") {
    Module.arguments = scriptArgs;
  } else if (typeof arguments !== "undefined") {
    Module.arguments = arguments;
  }
} else if (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) {
  Module.read = function read(url) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, false);
    xhr.send(null);
    return xhr.responseText;
  };
  if (typeof arguments !== "undefined") {
    Module.arguments = arguments;
  }
  if (typeof console !== "undefined") {
    if (!Module.print)
      Module.print = function print(x) {
        console.log(x);
      };
    if (!Module.printErr)
      Module.printErr = function printErr(x) {
        console.log(x);
      };
  } else {
    let TRY_USE_DUMP = false;
    if (!Module.print)
      Module.print =
        TRY_USE_DUMP && typeof dump !== "undefined"
          ? function (x) {
              dump(x);
            }
          : function (x) {};
  }
  if (ENVIRONMENT_IS_WORKER) {
    Module.load = importScripts;
  }
  if (typeof Module.setWindowTitle === "undefined") {
    Module.setWindowTitle = function (title) {
      document.title = title;
    };
  }
} else {
  throw "Unknown runtime environment. Where are we?";
}
function globalEval(x) {
  eval.call(null, x);
}
if (!Module.load && Module.read) {
  Module.load = function load(f) {
    globalEval(Module.read(f));
  };
}
if (!Module.print) {
  Module.print = function () {};
}
if (!Module.printErr) {
  Module.printErr = Module.print;
}
if (!Module.arguments) {
  Module.arguments = [];
}
if (!Module.thisProgram) {
  Module.thisProgram = "./this.program";
}
Module.print = Module.print;
Module.printErr = Module.printErr;
Module.preRun = [];
Module.postRun = [];
for (var key in moduleOverrides) {
  if (moduleOverrides.hasOwnProperty(key)) {
    Module[key] = moduleOverrides[key];
  }
}
var Runtime = {
  setTempRet0(value) {
    tempRet0 = value;
  },
  getTempRet0() {
    return tempRet0;
  },
  stackSave() {
    return STACKTOP;
  },
  stackRestore(stackTop) {
    STACKTOP = stackTop;
  },
  getNativeTypeSize(type) {
    switch (type) {
      case "i1":
      case "i8":
        return 1;
      case "i16":
        return 2;
      case "i32":
        return 4;
      case "i64":
        return 8;
      case "float":
        return 4;
      case "double":
        return 8;
      default: {
        if (type[type.length - 1] === "*") {
          return Runtime.QUANTUM_SIZE;
        } else if (type[0] === "i") {
          var bits = parseInt(type.substr(1));
          assert(bits % 8 === 0);
          return bits / 8;
        } else {
          return 0;
        }
      }
    }
  },
  getNativeFieldSize(type) {
    return Math.max(Runtime.getNativeTypeSize(type), Runtime.QUANTUM_SIZE);
  },
  STACK_ALIGN: 16,
  getAlignSize(type, size, vararg) {
    if (!vararg && (type == "i64" || type == "double")) return 8;
    if (!type) return Math.min(size, 8);
    return Math.min(
      size || (type ? Runtime.getNativeFieldSize(type) : 0),
      Runtime.QUANTUM_SIZE
    );
  },
  dynCall(sig, ptr, args) {
    if (args && args.length) {
      if (!args.splice) args = Array.prototype.slice.call(args);
      args.splice(0, 0, ptr);
      return Module["dynCall_" + sig].apply(null, args);
    } else {
      return Module["dynCall_" + sig].call(null, ptr);
    }
  },
  functionPointers: [],
  addFunction(func) {
    for (var i = 0; i < Runtime.functionPointers.length; i++) {
      if (!Runtime.functionPointers[i]) {
        Runtime.functionPointers[i] = func;
        return 2 * (1 + i);
      }
    }
    throw "Finished up all reserved function pointers. Use a higher value for RESERVED_FUNCTION_POINTERS.";
  },
  removeFunction(index) {
    Runtime.functionPointers[(index - 2) / 2] = null;
  },
  getAsmConst(code, numArgs) {
    if (!Runtime.asmConstCache) Runtime.asmConstCache = {};
    var func = Runtime.asmConstCache[code];
    if (func) return func;
    var args = [];
    for (var i = 0; i < numArgs; i++) {
      args.push(String.fromCharCode(36) + i);
    }
    var source = Pointer_stringify(code);
    if (source[0] === '"') {
      if (source.indexOf('"', 1) === source.length - 1) {
        source = source.substr(1, source.length - 2);
      } else {
        abort(
          "invalid EM_ASM input |" +
            source +
            "|. Please use EM_ASM(..code..) (no quotes) or EM_ASM({ ..code($0).. }, input) (to input values)"
        );
      }
    }
    try {
      var evalled = eval(
        "(function(Module, FS) { return function(" +
          args.join(",") +
          "){ " +
          source +
          " } })"
      )(Module, typeof FS !== "undefined" ? FS : null);
    } catch (e) {
      Module.printErr(
        "error in executing inline EM_ASM code: " +
          e +
          " on: \n\n" +
          source +
          "\n\nwith args |" +
          args +
          "| (make sure to use the right one out of EM_ASM, EM_ASM_ARGS, etc.)"
      );
      throw e;
    }
    return (Runtime.asmConstCache[code] = evalled);
  },
  warnOnce(text) {
    if (!Runtime.warnOnce.shown) Runtime.warnOnce.shown = {};
    if (!Runtime.warnOnce.shown[text]) {
      Runtime.warnOnce.shown[text] = 1;
      Module.printErr(text);
    }
  },
  funcWrappers: {},
  getFuncWrapper(func, sig) {
    assert(sig);
    if (!Runtime.funcWrappers[sig]) {
      Runtime.funcWrappers[sig] = {};
    }
    var sigCache = Runtime.funcWrappers[sig];
    if (!sigCache[func]) {
      sigCache[func] = function dynCall_wrapper() {
        return Runtime.dynCall(sig, func, arguments);
      };
    }
    return sigCache[func];
  },
  getCompilerSetting(name) {
    throw "You must build with -s RETAIN_COMPILER_SETTINGS=1 for Runtime.getCompilerSetting or emscripten_get_compiler_setting to work";
  },
  stackAlloc(size) {
    var ret = STACKTOP;
    STACKTOP = (STACKTOP + size) | 0;
    STACKTOP = (STACKTOP + 15) & -16;
    return ret;
  },
  staticAlloc(size) {
    var ret = STATICTOP;
    STATICTOP = (STATICTOP + size) | 0;
    STATICTOP = (STATICTOP + 15) & -16;
    return ret;
  },
  dynamicAlloc(size) {
    var ret = DYNAMICTOP;
    DYNAMICTOP = (DYNAMICTOP + size) | 0;
    DYNAMICTOP = (DYNAMICTOP + 15) & -16;
    if (DYNAMICTOP >= TOTAL_MEMORY) {
      var success = enlargeMemory();
      if (!success) return 0;
    }
    return ret;
  },
  alignMemory(size, quantum) {
    var ret = (size =
      Math.ceil(size / (quantum ? quantum : 16)) * (quantum ? quantum : 16));
    return ret;
  },
  makeBigInt(low, high, unsigned) {
    var ret = unsigned
      ? +(low >>> 0) + +(high >>> 0) * +4294967296
      : +(low >>> 0) + +(high | 0) * +4294967296;
    return ret;
  },
  GLOBAL_BASE: 8,
  QUANTUM_SIZE: 4,
  __dummy__: 0,
};
Module.Runtime = Runtime;
let __THREW__ = 0;
let ABORT = false;
let EXITSTATUS = 0;
let undef = 0;
let tempValue;
var tempInt;
var tempBigInt;
var tempInt2;
var tempBigInt2;
var tempPair;
var tempBigIntI;
var tempBigIntR;
var tempBigIntS;
var tempBigIntP;
var tempBigIntD;
var tempDouble;
var tempFloat;
let tempI64;
var tempI64b;
let tempRet0;
var tempRet1;
var tempRet2;
var tempRet3;
var tempRet4;
var tempRet5;
var tempRet6;
var tempRet7;
var tempRet8;
var tempRet9;
function assert(condition, text) {
  if (!condition) {
    abort("Assertion failed: " + text);
  }
}
let globalScope = this;
function getCFunc(ident) {
  let func = Module["_" + ident];
  if (!func) {
    try {
      func = eval("_" + ident);
    } catch (e) {}
  }
  assert(
    func,
    "Cannot call unknown function " +
      ident +
      " (perhaps LLVM optimizations or closure removed it?)"
  );
  return func;
}
let cwrap;
var ccall;
(function () {
  let JSfuncs = {
    stackSave: function () {
      Runtime.stackSave();
    },
    stackRestore: function () {
      Runtime.stackRestore();
    },
    arrayToC: function (arr) {
      let ret = Runtime.stackAlloc(arr.length);
      writeArrayToMemory(arr, ret);
      return ret;
    },
    stringToC: function (str) {
      let ret = 0;
      if (str !== null && str !== undefined && str !== 0) {
        ret = Runtime.stackAlloc((str.length << 2) + 1);
        writeStringToMemory(str, ret);
      }
      return ret;
    },
  };
  let toC = { string: JSfuncs.stringToC, array: JSfuncs.arrayToC };
  ccall = function ccallFunc(ident, returnType, argTypes, args) {
    let func = getCFunc(ident);
    let cArgs = [];
    let stack = 0;
    if (args) {
      for (let i = 0; i < args.length; i++) {
        let converter = toC[argTypes[i]];
        if (converter) {
          if (stack === 0) stack = Runtime.stackSave();
          cArgs[i] = converter(args[i]);
        } else {
          cArgs[i] = args[i];
        }
      }
    }
    let ret = func.apply(null, cArgs);
    if (returnType === "string") ret = Pointer_stringify(ret);
    if (stack !== 0) Runtime.stackRestore(stack);
    return ret;
  };
  let sourceRegex =
    /^function\s*[A-Za-z0-9]*\(([^)]*)\)\s*{\s*([^*]*?)[\s;]*(?:return\s*(.*?)[;\s]*)?}$/;
  function parseJSFunc(jsfunc) {
    let parsed = jsfunc.toString().match(sourceRegex).slice(1);
    return { arguments: parsed[0], body: parsed[1], returnValue: parsed[2] };
  }
  let JSsource = {};
  for (let fun in JSfuncs) {
    if (JSfuncs.hasOwnProperty(fun)) {
      JSsource[fun] = parseJSFunc(JSfuncs[fun]);
    }
  }
  cwrap = function cwrap(ident, returnType, argTypes) {
    argTypes = argTypes || [];
    let cfunc = getCFunc(ident);
    let numericArgs = argTypes.every((type) => {
      return type === "number";
    });
    let numericRet = returnType !== "string";
    if (numericRet && numericArgs) {
      return cfunc;
    }
    let argNames = argTypes.map((x, i) => {
      return "$" + i;
    });
    let funcstr = "(function(" + argNames.join(",") + ") {";
    let nargs = argTypes.length;
    if (!numericArgs) {
      funcstr += "var stack = " + JSsource.stackSave.body + ";";
      for (let i = 0; i < nargs; i++) {
        let arg = argNames[i];
        var type = argTypes[i];
        if (type === "number") continue;
        let convertCode = JSsource[`${type}ToC`];
        funcstr += "var " + convertCode.arguments + " = " + arg + ";";
        funcstr += `${convertCode.body};`;
        funcstr += `${arg}=${convertCode.returnValue};`;
      }
    }
    let cfuncname = parseJSFunc(() => {
      return cfunc;
    }).returnValue;
    funcstr += "var ret = " + cfuncname + "(" + argNames.join(",") + ");";
    if (!numericRet) {
      let strgfy = parseJSFunc(() => {
        return Pointer_stringify;
      }).returnValue;
      funcstr += "ret = " + strgfy + "(ret);";
    }
    if (!numericArgs) {
      funcstr += `${JSsource["stackRestore"].body.replace("()", "(stack)")};`;
    }
    funcstr += "return ret})";
    return eval(funcstr);
  };
})();
Module.cwrap = cwrap;
Module.ccall = ccall;
function setValue(ptr, value, type, noSafe) {
  type = type || "i8";
  if (type.charAt(type.length - 1) === "*") type = "i32";
  switch (type) {
    case "i1":
      HEAP8[ptr >> 0] = value;
      break;
    case "i8":
      HEAP8[ptr >> 0] = value;
      break;
    case "i16":
      HEAP16[ptr >> 1] = value;
      break;
    case "i32":
      HEAP32[ptr >> 2] = value;
      break;
    case "i64":
      (tempI64 = [
        value >>> 0,
        ((tempDouble = value),
        +Math_abs(tempDouble) >= +1
          ? tempDouble > +0
            ? (Math_min(+Math_floor(tempDouble / +4294967296), +4294967295) |
                0) >>>
              0
            : ~~+Math_ceil(
                (tempDouble - +(~~tempDouble >>> 0)) / +4294967296
              ) >>> 0
          : 0),
      ]),
        (HEAP32[ptr >> 2] = tempI64[0]),
        (HEAP32[(ptr + 4) >> 2] = tempI64[1]);
      break;
    case "float":
      HEAPF32[ptr >> 2] = value;
      break;
    case "double":
      HEAPF64[ptr >> 3] = value;
      break;
    default:
      abort("invalid type for setValue: " + type);
  }
}
Module.setValue = setValue;
function getValue(ptr, type, noSafe) {
  type = type || "i8";
  if (type.charAt(type.length - 1) === "*") type = "i32";
  switch (type) {
    case "i1":
      return HEAP8[ptr >> 0];
    case "i8":
      return HEAP8[ptr >> 0];
    case "i16":
      return HEAP16[ptr >> 1];
    case "i32":
      return HEAP32[ptr >> 2];
    case "i64":
      return HEAP32[ptr >> 2];
    case "float":
      return HEAPF32[ptr >> 2];
    case "double":
      return HEAPF64[ptr >> 3];
    default:
      abort("invalid type for setValue: " + type);
  }
  return null;
}
Module.getValue = getValue;
let ALLOC_NORMAL = 0;
let ALLOC_STACK = 1;
let ALLOC_STATIC = 2;
let ALLOC_DYNAMIC = 3;
let ALLOC_NONE = 4;
Module.ALLOC_NORMAL = ALLOC_NORMAL;
Module.ALLOC_STACK = ALLOC_STACK;
Module.ALLOC_STATIC = ALLOC_STATIC;
Module.ALLOC_DYNAMIC = ALLOC_DYNAMIC;
Module.ALLOC_NONE = ALLOC_NONE;
function allocate(slab, types, allocator, ptr) {
  let zeroinit;
  var size;
  if (typeof slab === "number") {
    zeroinit = true;
    size = slab;
  } else {
    zeroinit = false;
    size = slab.length;
  }
  let singleType = typeof types === "string" ? types : null;
  let ret;
  if (allocator == ALLOC_NONE) {
    ret = ptr;
  } else {
    ret = [
      _malloc,
      Runtime.stackAlloc,
      Runtime.staticAlloc,
      Runtime.dynamicAlloc,
    ][allocator === undefined ? ALLOC_STATIC : allocator](
      Math.max(size, singleType ? 1 : types.length)
    );
  }
  if (zeroinit) {
    var ptr = ret;
    var stop;
    assert((ret & 3) == 0);
    stop = ret + (size & ~3);
    for (; ptr < stop; ptr += 4) {
      HEAP32[ptr >> 2] = 0;
    }
    stop = ret + size;
    while (ptr < stop) {
      HEAP8[ptr++ >> 0] = 0;
    }
    return ret;
  }
  if (singleType === "i8") {
    if (slab.subarray || slab.slice) {
      HEAPU8.set(slab, ret);
    } else {
      HEAPU8.set(new Uint8Array(slab), ret);
    }
    return ret;
  }
  let i = 0;
  var type;
  var typeSize;
  var previousType;
  while (i < size) {
    let curr = slab[i];
    if (typeof curr === "function") {
      curr = Runtime.getFunctionIndex(curr);
    }
    type = singleType || types[i];
    if (type === 0) {
      i++;
      continue;
    }
    if (type == "i64") type = "i32";
    setValue(ret + i, curr, type);
    if (previousType !== type) {
      typeSize = Runtime.getNativeTypeSize(type);
      previousType = type;
    }
    i += typeSize;
  }
  return ret;
}
Module.allocate = allocate;
function Pointer_stringify(ptr, length) {
  if (length === 0 || !ptr) return "";
  let hasUtf = 0;
  let t;
  let i = 0;
  while (1) {
    t = HEAPU8[(ptr + i) >> 0];
    hasUtf |= t;
    if (t == 0 && !length) break;
    i++;
    if (length && i == length) break;
  }
  if (!length) length = i;
  let ret = "";
  if (hasUtf < 128) {
    let MAX_CHUNK = 1024;
    let curr;
    while (length > 0) {
      curr = String.fromCharCode.apply(
        String,
        HEAPU8.subarray(ptr, ptr + Math.min(length, MAX_CHUNK))
      );
      ret = ret ? ret + curr : curr;
      ptr += MAX_CHUNK;
      length -= MAX_CHUNK;
    }
    return ret;
  }
  return Module.UTF8ToString(ptr);
}
Module.Pointer_stringify = Pointer_stringify;
function AsciiToString(ptr) {
  let str = "";
  while (1) {
    let ch = HEAP8[ptr++ >> 0];
    if (!ch) return str;
    str += String.fromCharCode(ch);
  }
}
Module.AsciiToString = AsciiToString;
function stringToAscii(str, outPtr) {
  return writeAsciiToMemory(str, outPtr, false);
}
Module.stringToAscii = stringToAscii;
function UTF8ArrayToString(u8Array, idx) {
  let u0;
  var u1;
  var u2;
  var u3;
  var u4;
  var u5;
  let str = "";
  while (1) {
    u0 = u8Array[idx++];
    if (!u0) return str;
    if (!(u0 & 128)) {
      str += String.fromCharCode(u0);
      continue;
    }
    u1 = u8Array[idx++] & 63;
    if ((u0 & 224) == 192) {
      str += String.fromCharCode(((u0 & 31) << 6) | u1);
      continue;
    }
    u2 = u8Array[idx++] & 63;
    if ((u0 & 240) == 224) {
      u0 = ((u0 & 15) << 12) | (u1 << 6) | u2;
    } else {
      u3 = u8Array[idx++] & 63;
      if ((u0 & 248) == 240) {
        u0 = ((u0 & 7) << 18) | (u1 << 12) | (u2 << 6) | u3;
      } else {
        u4 = u8Array[idx++] & 63;
        if ((u0 & 252) == 248) {
          u0 = ((u0 & 3) << 24) | (u1 << 18) | (u2 << 12) | (u3 << 6) | u4;
        } else {
          u5 = u8Array[idx++] & 63;
          u0 =
            ((u0 & 1) << 30) |
            (u1 << 24) |
            (u2 << 18) |
            (u3 << 12) |
            (u4 << 6) |
            u5;
        }
      }
    }
    if (u0 < 65536) {
      str += String.fromCharCode(u0);
    } else {
      let ch = u0 - 65536;
      str += String.fromCharCode(55296 | (ch >> 10), 56320 | (ch & 1023));
    }
  }
}
Module.UTF8ArrayToString = UTF8ArrayToString;
function UTF8ToString(ptr) {
  return UTF8ArrayToString(HEAPU8, ptr);
}
Module.UTF8ToString = UTF8ToString;
function stringToUTF8Array(str, outU8Array, outIdx, maxBytesToWrite) {
  if (!(maxBytesToWrite > 0)) return 0;
  let startIdx = outIdx;
  let endIdx = outIdx + maxBytesToWrite - 1;
  for (let i = 0; i < str.length; ++i) {
    let u = str.charCodeAt(i);
    if (u >= 55296 && u <= 57343)
      u = (65536 + ((u & 1023) << 10)) | (str.charCodeAt(++i) & 1023);
    if (u <= 127) {
      if (outIdx >= endIdx) break;
      outU8Array[outIdx++] = u;
    } else if (u <= 2047) {
      if (outIdx + 1 >= endIdx) break;
      outU8Array[outIdx++] = 192 | (u >> 6);
      outU8Array[outIdx++] = 128 | (u & 63);
    } else if (u <= 65535) {
      if (outIdx + 2 >= endIdx) break;
      outU8Array[outIdx++] = 224 | (u >> 12);
      outU8Array[outIdx++] = 128 | ((u >> 6) & 63);
      outU8Array[outIdx++] = 128 | (u & 63);
    } else if (u <= 2097151) {
      if (outIdx + 3 >= endIdx) break;
      outU8Array[outIdx++] = 240 | (u >> 18);
      outU8Array[outIdx++] = 128 | ((u >> 12) & 63);
      outU8Array[outIdx++] = 128 | ((u >> 6) & 63);
      outU8Array[outIdx++] = 128 | (u & 63);
    } else if (u <= 67108863) {
      if (outIdx + 4 >= endIdx) break;
      outU8Array[outIdx++] = 248 | (u >> 24);
      outU8Array[outIdx++] = 128 | ((u >> 18) & 63);
      outU8Array[outIdx++] = 128 | ((u >> 12) & 63);
      outU8Array[outIdx++] = 128 | ((u >> 6) & 63);
      outU8Array[outIdx++] = 128 | (u & 63);
    } else {
      if (outIdx + 5 >= endIdx) break;
      outU8Array[outIdx++] = 252 | (u >> 30);
      outU8Array[outIdx++] = 128 | ((u >> 24) & 63);
      outU8Array[outIdx++] = 128 | ((u >> 18) & 63);
      outU8Array[outIdx++] = 128 | ((u >> 12) & 63);
      outU8Array[outIdx++] = 128 | ((u >> 6) & 63);
      outU8Array[outIdx++] = 128 | (u & 63);
    }
  }
  outU8Array[outIdx] = 0;
  return outIdx - startIdx;
}
Module.stringToUTF8Array = stringToUTF8Array;
function stringToUTF8(str, outPtr, maxBytesToWrite) {
  return stringToUTF8Array(str, HEAPU8, outPtr, maxBytesToWrite);
}
Module.stringToUTF8 = stringToUTF8;
function lengthBytesUTF8(str) {
  let len = 0;
  for (let i = 0; i < str.length; ++i) {
    let u = str.charCodeAt(i);
    if (u >= 55296 && u <= 57343)
      u = (65536 + ((u & 1023) << 10)) | (str.charCodeAt(++i) & 1023);
    if (u <= 127) {
      ++len;
    } else if (u <= 2047) {
      len += 2;
    } else if (u <= 65535) {
      len += 3;
    } else if (u <= 2097151) {
      len += 4;
    } else if (u <= 67108863) {
      len += 5;
    } else {
      len += 6;
    }
  }
  return len;
}
Module.lengthBytesUTF8 = lengthBytesUTF8;
function UTF16ToString(ptr) {
  let i = 0;
  let str = "";
  while (1) {
    let codeUnit = HEAP16[(ptr + i * 2) >> 1];
    if (codeUnit == 0) return str;
    ++i;
    str += String.fromCharCode(codeUnit);
  }
}
Module.UTF16ToString = UTF16ToString;
function stringToUTF16(str, outPtr, maxBytesToWrite) {
  if (maxBytesToWrite === undefined) {
    maxBytesToWrite = 2147483647;
  }
  if (maxBytesToWrite < 2) return 0;
  maxBytesToWrite -= 2;
  let startPtr = outPtr;
  let numCharsToWrite =
    maxBytesToWrite < str.length * 2 ? maxBytesToWrite / 2 : str.length;
  for (let i = 0; i < numCharsToWrite; ++i) {
    let codeUnit = str.charCodeAt(i);
    HEAP16[outPtr >> 1] = codeUnit;
    outPtr += 2;
  }
  HEAP16[outPtr >> 1] = 0;
  return outPtr - startPtr;
}
Module.stringToUTF16 = stringToUTF16;
function lengthBytesUTF16(str) {
  return str.length * 2;
}
Module.lengthBytesUTF16 = lengthBytesUTF16;
function UTF32ToString(ptr) {
  let i = 0;
  let str = "";
  while (1) {
    let utf32 = HEAP32[(ptr + i * 4) >> 2];
    if (utf32 == 0) return str;
    ++i;
    if (utf32 >= 65536) {
      let ch = utf32 - 65536;
      str += String.fromCharCode(55296 | (ch >> 10), 56320 | (ch & 1023));
    } else {
      str += String.fromCharCode(utf32);
    }
  }
}
Module.UTF32ToString = UTF32ToString;
function stringToUTF32(str, outPtr, maxBytesToWrite) {
  if (maxBytesToWrite === undefined) {
    maxBytesToWrite = 2147483647;
  }
  if (maxBytesToWrite < 4) return 0;
  let startPtr = outPtr;
  let endPtr = startPtr + maxBytesToWrite - 4;
  for (let i = 0; i < str.length; ++i) {
    let codeUnit = str.charCodeAt(i);
    if (codeUnit >= 55296 && codeUnit <= 57343) {
      let trailSurrogate = str.charCodeAt(++i);
      codeUnit = (65536 + ((codeUnit & 1023) << 10)) | (trailSurrogate & 1023);
    }
    HEAP32[outPtr >> 2] = codeUnit;
    outPtr += 4;
    if (outPtr + 4 > endPtr) break;
  }
  HEAP32[outPtr >> 2] = 0;
  return outPtr - startPtr;
}
Module.stringToUTF32 = stringToUTF32;
function lengthBytesUTF32(str) {
  let len = 0;
  for (let i = 0; i < str.length; ++i) {
    let codeUnit = str.charCodeAt(i);
    if (codeUnit >= 55296 && codeUnit <= 57343) ++i;
    len += 4;
  }
  return len;
}
Module.lengthBytesUTF32 = lengthBytesUTF32;
function demangle(func) {
  let hasLibcxxabi = !!Module.___cxa_demangle;
  if (hasLibcxxabi) {
    try {
      var buf = _malloc(func.length);
      writeStringToMemory(func.substr(1), buf);
      var status = _malloc(4);
      var ret = Module.___cxa_demangle(buf, 0, 0, status);
      if (getValue(status, "i32") === 0 && ret) {
        return Pointer_stringify(ret);
      }
    } catch (e) {
    } finally {
      if (buf) _free(buf);
      if (status) _free(status);
      if (ret) _free(ret);
    }
  }
  let i = 3;
  let basicTypes = {
    v: "void",
    b: "bool",
    c: "char",
    s: "short",
    i: "int",
    l: "long",
    f: "float",
    d: "double",
    w: "wchar_t",
    a: "signed char",
    h: "unsigned char",
    t: "unsigned short",
    j: "unsigned int",
    m: "unsigned long",
    x: "long long",
    y: "unsigned long long",
    z: "...",
  };
  let subs = [];
  let first = true;
  function dump(x) {
    if (x) Module.print(x);
    Module.print(func);
    let pre = "";
    for (let a = 0; a < i; a++) pre += " ";
    Module.print(`${pre}^`);
  }
  function parseNested() {
    i++;
    if (func[i] === "K") i++;
    let parts = [];
    while (func[i] !== "E") {
      if (func[i] === "S") {
        i++;
        let next = func.indexOf("_", i);
        let num = func.substring(i, next) || 0;
        parts.push(subs[num] || "?");
        i = next + 1;
        continue;
      }
      if (func[i] === "C") {
        parts.push(parts[parts.length - 1]);
        i += 2;
        continue;
      }
      let size = parseInt(func.substr(i));
      let pre = size.toString().length;
      if (!size || !pre) {
        i--;
        break;
      }
      let curr = func.substr(i + pre, size);
      parts.push(curr);
      subs.push(curr);
      i += pre + size;
    }
    i++;
    return parts;
  }
  function parse(rawList, limit, allowVoid) {
    limit = limit || Infinity;
    let ret = "";
    var list = [];
    function flushList() {
      return "(" + list.join(", ") + ")";
    }
    let name;
    if (func[i] === "N") {
      name = parseNested().join("::");
      limit--;
      if (limit === 0) return rawList ? [name] : name;
    } else {
      if (func[i] === "K" || (first && func[i] === "L")) i++;
      var size = parseInt(func.substr(i));
      if (size) {
        let pre = size.toString().length;
        name = func.substr(i + pre, size);
        i += pre + size;
      }
    }
    first = false;
    if (func[i] === "I") {
      i++;
      let iList = parse(true);
      let iRet = parse(true, 1, true);
      ret += `${iRet[0]} ${name}<${iList.join(", ")}>`;
    } else {
      ret = name;
    }
    paramLoop: while (i < func.length && limit-- > 0) {
      let c = func[i++];
      if (c in basicTypes) {
        list.push(basicTypes[c]);
      } else {
        switch (c) {
          case "P":
            list.push(`${parse(true, 1, true)[0]}*`);
            break;
          case "R":
            list.push(`${parse(true, 1, true)[0]}&`);
            break;
          case "L": {
            i++;
            let end = func.indexOf("E", i);
            var size = end - i;
            list.push(func.substr(i, size));
            i += size + 2;
            break;
          }
          case "A": {
            var size = parseInt(func.substr(i));
            i += size.toString().length;
            if (func[i] !== "_") throw "?";
            i++;
            list.push(`${parse(true, 1, true)[0]} [${size}]`);
            break;
          }
          case "E":
            break paramLoop;
          default:
            ret += "?" + c;
            break paramLoop;
        }
      }
    }
    if (!allowVoid && list.length === 1 && list[0] === "void") list = [];
    if (rawList) {
      if (ret) {
        list.push(`${ret}?`);
      }
      return list;
    } else {
      return ret + flushList();
    }
  }
  let parsed = func;
  try {
    if (func == "Object._main" || func == "_main") {
      return "main()";
    }
    if (typeof func === "number") func = Pointer_stringify(func);
    if (func[0] !== "_") return func;
    if (func[1] !== "_") return func;
    if (func[2] !== "Z") return func;
    switch (func[3]) {
      case "n":
        return "operator new()";
      case "d":
        return "operator delete()";
    }
    parsed = parse();
  } catch (e) {
    parsed += "?";
  }
  if (parsed.indexOf("?") >= 0 && !hasLibcxxabi) {
    Runtime.warnOnce(
      "warning: a problem occurred in builtin C++ name demangling; build with  -s DEMANGLE_SUPPORT=1  to link in libcxxabi demangling"
    );
  }
  return parsed;
}
function demangleAll(text) {
  return text.replace(/__Z[\w\d_]+/g, (x) => {
    var y = demangle(x);
    return x === y ? x : x + " [" + y + "]";
  });
}
function jsStackTrace() {
  let err = new Error();
  if (!err.stack) {
    try {
      throw new Error(0);
    } catch (e) {
      err = e;
    }
    if (!err.stack) {
      return "(no stack trace available)";
    }
  }
  return err.stack.toString();
}
function stackTrace() {
  return demangleAll(jsStackTrace());
}
Module.stackTrace = stackTrace;
let PAGE_SIZE = 4096;
function alignMemoryPage(x) {
  if (x % 4096 > 0) {
    x += 4096 - (x % 4096);
  }
  return x;
}
let HEAP;
let HEAP8;
var HEAPU8;
var HEAP16;
var HEAPU16;
var HEAP32;
var HEAPU32;
var HEAPF32;
var HEAPF64;
var STATIC_BASE = 0;
var STATICTOP = 0;
var staticSealed = false;
var STACK_BASE = 0;
var STACKTOP = 0;
var STACK_MAX = 0;
var DYNAMIC_BASE = 0;
var DYNAMICTOP = 0;
function enlargeMemory() {
  abort(
    "Cannot enlarge memory arrays. Either (1) compile with -s TOTAL_MEMORY=X with X higher than the current value " +
      TOTAL_MEMORY +
      ", (2) compile with ALLOW_MEMORY_GROWTH which adjusts the size at runtime but prevents some optimizations, or (3) set Module.TOTAL_MEMORY before the program runs."
  );
}
let TOTAL_STACK = Module.TOTAL_STACK || 5242880;
var TOTAL_MEMORY = Module.TOTAL_MEMORY || 16777216;
let totalMemory = 64 * 1024;
while (totalMemory < TOTAL_MEMORY || totalMemory < 2 * TOTAL_STACK) {
  if (totalMemory < 16 * 1024 * 1024) {
    totalMemory *= 2;
  } else {
    totalMemory += 16 * 1024 * 1024;
  }
}
if (totalMemory !== TOTAL_MEMORY) {
  Module.printErr(
    "increasing TOTAL_MEMORY to " +
      totalMemory +
      " to be compliant with the asm.js spec (and given that TOTAL_STACK=" +
      TOTAL_STACK +
      ")"
  );
  TOTAL_MEMORY = totalMemory;
}
assert(
  typeof Int32Array !== "undefined" &&
    typeof Float64Array !== "undefined" &&
    !!new Int32Array(1).subarray &&
    !!new Int32Array(1).set,
  "JS engine does not provide full typed array support"
);
let buffer = new ArrayBuffer(TOTAL_MEMORY);
HEAP8 = new Int8Array(buffer);
HEAP16 = new Int16Array(buffer);
HEAP32 = new Int32Array(buffer);
HEAPU8 = new Uint8Array(buffer);
HEAPU16 = new Uint16Array(buffer);
HEAPU32 = new Uint32Array(buffer);
HEAPF32 = new Float32Array(buffer);
HEAPF64 = new Float64Array(buffer);
HEAP32[0] = 255;
assert(
  HEAPU8[0] === 255 && HEAPU8[3] === 0,
  "Typed arrays 2 must be run on a little-endian system"
);
Module.HEAP = HEAP;
Module.buffer = buffer;
Module.HEAP8 = HEAP8;
Module.HEAP16 = HEAP16;
Module.HEAP32 = HEAP32;
Module.HEAPU8 = HEAPU8;
Module.HEAPU16 = HEAPU16;
Module.HEAPU32 = HEAPU32;
Module.HEAPF32 = HEAPF32;
Module.HEAPF64 = HEAPF64;
function callRuntimeCallbacks(callbacks) {
  while (callbacks.length > 0) {
    let callback = callbacks.shift();
    if (typeof callback == "function") {
      callback();
      continue;
    }
    let { func } = callback;
    if (typeof func === "number") {
      if (callback.arg === undefined) {
        Runtime.dynCall("v", func);
      } else {
        Runtime.dynCall("vi", func, [callback.arg]);
      }
    } else {
      func(callback.arg === undefined ? null : callback.arg);
    }
  }
}
let __ATPRERUN__ = [];
let __ATINIT__ = [];
let __ATMAIN__ = [];
let __ATEXIT__ = [];
let __ATPOSTRUN__ = [];
let runtimeInitialized = false;
let runtimeExited = false;
function preRun() {
  if (Module.preRun) {
    if (typeof Module.preRun === "function") Module.preRun = [Module.preRun];
    while (Module.preRun.length) {
      addOnPreRun(Module.preRun.shift());
    }
  }
  callRuntimeCallbacks(__ATPRERUN__);
}
function ensureInitRuntime() {
  if (runtimeInitialized) return;
  runtimeInitialized = true;
  callRuntimeCallbacks(__ATINIT__);
}
function preMain() {
  callRuntimeCallbacks(__ATMAIN__);
}
function exitRuntime() {
  callRuntimeCallbacks(__ATEXIT__);
  runtimeExited = true;
}
function postRun() {
  if (Module.postRun) {
    if (typeof Module.postRun === "function") Module.postRun = [Module.postRun];
    while (Module.postRun.length) {
      addOnPostRun(Module.postRun.shift());
    }
  }
  callRuntimeCallbacks(__ATPOSTRUN__);
}
function addOnPreRun(cb) {
  __ATPRERUN__.unshift(cb);
}
Module.addOnPreRun = Module.addOnPreRun = addOnPreRun;
function addOnInit(cb) {
  __ATINIT__.unshift(cb);
}
Module.addOnInit = Module.addOnInit = addOnInit;
function addOnPreMain(cb) {
  __ATMAIN__.unshift(cb);
}
Module.addOnPreMain = Module.addOnPreMain = addOnPreMain;
function addOnExit(cb) {
  __ATEXIT__.unshift(cb);
}
Module.addOnExit = Module.addOnExit = addOnExit;
function addOnPostRun(cb) {
  __ATPOSTRUN__.unshift(cb);
}
Module.addOnPostRun = Module.addOnPostRun = addOnPostRun;
function intArrayFromString(stringy, dontAddNull, length) {
  let len = length > 0 ? length : lengthBytesUTF8(stringy) + 1;
  let u8array = new Array(len);
  let numBytesWritten = stringToUTF8Array(stringy, u8array, 0, u8array.length);
  if (dontAddNull) u8array.length = numBytesWritten;
  return u8array;
}
Module.intArrayFromString = intArrayFromString;
function intArrayToString(array) {
  let ret = [];
  for (let i = 0; i < array.length; i++) {
    let chr = array[i];
    if (chr > 255) {
      chr &= 255;
    }
    ret.push(String.fromCharCode(chr));
  }
  return ret.join("");
}
Module.intArrayToString = intArrayToString;
function writeStringToMemory(string, buffer, dontAddNull) {
  let array = intArrayFromString(string, dontAddNull);
  let i = 0;
  while (i < array.length) {
    let chr = array[i];
    HEAP8[(buffer + i) >> 0] = chr;
    i += 1;
  }
}
Module.writeStringToMemory = writeStringToMemory;
function writeArrayToMemory(array, buffer) {
  for (let i = 0; i < array.length; i++) {
    HEAP8[buffer++ >> 0] = array[i];
  }
}
Module.writeArrayToMemory = writeArrayToMemory;
function writeAsciiToMemory(str, buffer, dontAddNull) {
  for (let i = 0; i < str.length; ++i) {
    HEAP8[buffer++ >> 0] = str.charCodeAt(i);
  }
  if (!dontAddNull) HEAP8[buffer >> 0] = 0;
}
Module.writeAsciiToMemory = writeAsciiToMemory;
function unSign(value, bits, ignore) {
  if (value >= 0) {
    return value;
  }
  return bits <= 32
    ? 2 * Math.abs(1 << (bits - 1)) + value
    : Math.pow(2, bits) + value;
}
function reSign(value, bits, ignore) {
  if (value <= 0) {
    return value;
  }
  let half = bits <= 32 ? Math.abs(1 << (bits - 1)) : Math.pow(2, bits - 1);
  if (value >= half && (bits <= 32 || value > half)) {
    value = -2 * half + value;
  }
  return value;
}
if (!Math.imul || Math.imul(4294967295, 5) !== -5)
  Math.imul = function imul(a, b) {
    let ah = a >>> 16;
    let al = a & 65535;
    let bh = b >>> 16;
    let bl = b & 65535;
    return (al * bl + ((ah * bl + al * bh) << 16)) | 0;
  };
Math.imul = Math.imul;
if (!Math.clz32)
  Math.clz32 = function (x) {
    x >>>= 0;
    for (let i = 0; i < 32; i++) {
      if (x & (1 << (31 - i))) return i;
    }
    return 32;
  };
Math.clz32 = Math.clz32;
var Math_abs = Math.abs;
let Math_cos = Math.cos;
let Math_sin = Math.sin;
let Math_tan = Math.tan;
let Math_acos = Math.acos;
let Math_asin = Math.asin;
let Math_atan = Math.atan;
let Math_atan2 = Math.atan2;
let Math_exp = Math.exp;
let Math_log = Math.log;
let Math_sqrt = Math.sqrt;
var Math_ceil = Math.ceil;
var Math_floor = Math.floor;
let Math_pow = Math.pow;
let Math_imul = Math.imul;
let Math_fround = Math.fround;
var Math_min = Math.min;
let Math_clz32 = Math.clz32;
let runDependencies = 0;
let runDependencyWatcher = null;
let dependenciesFulfilled = null;
function addRunDependency(id) {
  runDependencies++;
  if (Module.monitorRunDependencies) {
    Module.monitorRunDependencies(runDependencies);
  }
}
Module.addRunDependency = addRunDependency;
function removeRunDependency(id) {
  runDependencies--;
  if (Module.monitorRunDependencies) {
    Module.monitorRunDependencies(runDependencies);
  }
  if (runDependencies == 0) {
    if (runDependencyWatcher !== null) {
      clearInterval(runDependencyWatcher);
      runDependencyWatcher = null;
    }
    if (dependenciesFulfilled) {
      let callback = dependenciesFulfilled;
      dependenciesFulfilled = null;
      callback();
    }
  }
}
Module.removeRunDependency = removeRunDependency;
Module.preloadedImages = {};
Module.preloadedAudios = {};
let memoryInitializer = null;
STATIC_BASE = 8;
STATICTOP = STATIC_BASE + 9888;
__ATINIT__.push();
allocate(
  [
    205, 204, 76, 63, 102, 102, 102, 63, 0, 0, 128, 63, 102, 102, 102, 63, 205,
    204, 76, 63, 0, 0, 0, 0, 171, 170, 42, 62, 171, 170, 170, 62, 0, 0, 0, 63,
    171, 170, 42, 63, 85, 85, 85, 63, 0, 0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    2, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1,
    0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 2,
    0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 4, 0, 0, 0, 2, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 3, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    6, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7,
    0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 2, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 1, 0, 0, 0, 2, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2,
    0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 1, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 4, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 204, 127, 136,
    189, 0, 0, 0, 62, 243, 31, 162, 62, 17, 0, 212, 62, 243, 31, 162, 62, 0, 0,
    0, 62, 204, 127, 136, 189, 0, 0, 0, 0, 2, 18, 110, 192, 255, 91, 11, 192, 1,
    164, 144, 191, 243, 143, 158, 190, 4, 112, 227, 62, 1, 52, 170, 63, 255,
    235, 27, 64, 1, 248, 126, 64, 201, 2, 128, 63, 77, 45, 137, 63, 119, 248,
    145, 63, 167, 122, 154, 63, 229, 122, 163, 63, 13, 254, 172, 63, 237, 245,
    182, 63, 211, 23, 192, 63, 153, 214, 200, 63, 97, 222, 209, 63, 77, 129,
    218, 63, 53, 9, 228, 63, 77, 159, 235, 63, 254, 101, 243, 63, 251, 116, 250,
    63, 152, 110, 0, 64, 227, 140, 3, 64, 16, 148, 6, 64, 242, 35, 9, 64, 249,
    191, 11, 64, 23, 243, 13, 64, 151, 117, 16, 64, 99, 237, 18, 64, 85, 79, 21,
    64, 194, 161, 23, 64, 88, 199, 25, 64, 90, 216, 27, 64, 130, 253, 29, 64,
    155, 55, 32, 64, 161, 131, 34, 64, 29, 171, 36, 64, 233, 184, 38, 64, 166,
    185, 40, 64, 230, 207, 42, 64, 208, 184, 44, 64, 151, 169, 46, 64, 85, 160,
    48, 64, 164, 140, 50, 64, 61, 101, 52, 64, 216, 70, 54, 64, 120, 40, 56, 64,
    162, 10, 58, 64, 40, 242, 59, 64, 128, 240, 61, 64, 226, 1, 64, 64, 175, 34,
    66, 64, 41, 63, 68, 64, 185, 171, 70, 64, 82, 14, 73, 64, 120, 182, 75, 64,
    216, 68, 78, 64, 219, 252, 80, 64, 127, 189, 83, 64, 226, 115, 86, 64, 48,
    76, 89, 64, 184, 86, 92, 64, 73, 105, 95, 64, 141, 184, 98, 64, 252, 168,
    102, 64, 144, 160, 106, 64, 114, 108, 111, 64, 254, 42, 117, 64, 207, 20,
    124, 64, 167, 65, 131, 64, 58, 0, 0, 0, 58, 0, 0, 0, 58, 0, 0, 0, 108, 0, 0,
    0, 44, 0, 0, 0, 44, 0, 0, 0, 108, 0, 0, 0, 108, 0, 0, 0, 108, 0, 0, 0, 108,
    0, 0, 0, 108, 0, 0, 0, 108, 0, 0, 0, 108, 0, 0, 0, 108, 0, 0, 0, 108, 0, 0,
    0, 0, 0, 0, 0, 147, 0, 0, 0, 147, 0, 0, 0, 147, 0, 0, 0, 147, 0, 0, 0, 84,
    0, 12, 189, 38, 0, 223, 61, 244, 191, 60, 190, 250, 95, 78, 63, 2, 160, 54,
    63, 2, 128, 19, 190, 225, 127, 171, 61, 147, 255, 9, 189, 0, 0, 128, 191,
    254, 243, 40, 191, 253, 247, 168, 190, 0, 0, 0, 0, 0, 0, 128, 62, 0, 0, 0,
    63, 0, 0, 64, 63, 0, 0, 128, 63, 2, 102, 134, 191, 249, 103, 102, 191, 0, 0,
    64, 191, 7, 152, 25, 191, 249, 103, 230, 190, 7, 152, 153, 190, 228, 159,
    25, 190, 0, 0, 0, 0, 228, 159, 25, 62, 7, 152, 153, 62, 249, 103, 230, 62,
    7, 152, 25, 63, 0, 0, 64, 63, 249, 103, 102, 63, 2, 102, 134, 63, 254, 153,
    153, 63, 111, 128, 25, 61, 228, 159, 153, 61, 28, 96, 230, 61, 228, 159, 25,
    62, 0, 0, 64, 62, 28, 96, 102, 62, 249, 103, 134, 62, 7, 152, 153, 62, 242,
    207, 172, 62, 0, 0, 192, 62, 14, 48, 211, 62, 249, 103, 230, 62, 7, 152,
    249, 62, 249, 103, 6, 63, 0, 0, 16, 63, 7, 152, 25, 63, 253, 51, 35, 63, 3,
    204, 44, 63, 249, 103, 54, 63, 0, 0, 64, 63, 7, 152, 73, 63, 253, 51, 83,
    63, 3, 204, 92, 63, 249, 103, 102, 63, 0, 0, 112, 63, 7, 152, 121, 63, 254,
    153, 129, 63, 2, 102, 134, 63, 253, 51, 139, 63, 0, 0, 144, 63, 3, 204, 148,
    63, 254, 153, 153, 63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 128, 63, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 128, 60, 216, 127, 157, 189, 253,
    159, 147, 62, 8, 176, 92, 63, 214, 255, 217, 189, 92, 0, 154, 60, 0, 0, 128,
    188, 193, 0, 194, 60, 57, 128, 254, 189, 8, 0, 26, 63, 8, 0, 26, 63, 57,
    128, 254, 189, 193, 0, 194, 60, 193, 0, 194, 188, 92, 0, 154, 60, 214, 255,
    217, 189, 8, 176, 92, 63, 253, 159, 147, 62, 216, 127, 157, 189, 0, 0, 128,
    60, 92, 0, 154, 188, 0, 0, 32, 66, 0, 0, 240, 66, 0, 0, 72, 67, 0, 0, 140,
    67, 0, 0, 180, 67, 0, 0, 220, 67, 0, 0, 2, 68, 0, 0, 22, 68, 3, 0, 0, 0, 3,
    0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 64, 0, 0, 0, 128, 0, 0, 0, 128, 0, 0, 0, 0,
    0, 0, 0, 247, 63, 144, 62, 7, 64, 228, 62, 255, 207, 41, 63, 249, 103, 118,
    63, 0, 60, 160, 63, 0, 60, 196, 63, 1, 224, 236, 63, 254, 209, 8, 64, 255,
    207, 30, 64, 1, 192, 49, 64, 0, 0, 0, 63, 0, 0, 128, 63, 171, 170, 42, 63,
    171, 170, 170, 62, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 64, 63, 0, 0, 0, 63, 0, 0,
    128, 62, 0, 0, 0, 0, 176, 227, 63, 57, 12, 230, 47, 58, 16, 4, 200, 58, 235,
    254, 49, 59, 122, 0, 139, 59, 16, 4, 200, 59, 235, 0, 8, 60, 23, 129, 49,
    60, 90, 126, 96, 60, 141, 128, 138, 60, 117, 63, 167, 60, 1, 191, 198, 60,
    50, 255, 232, 60, 131, 223, 6, 61, 190, 159, 26, 61, 203, 159, 47, 61, 168,
    223, 69, 61, 99, 96, 93, 61, 226, 31, 118, 61, 31, 16, 136, 61, 54, 176,
    149, 61, 244, 223, 163, 61, 224, 159, 178, 61, 59, 0, 194, 61, 61, 240, 209,
    61, 45, 96, 226, 61, 196, 95, 243, 61, 7, 120, 2, 62, 225, 127, 11, 62, 243,
    199, 20, 62, 253, 79, 30, 62, 252, 23, 40, 62, 22, 24, 50, 62, 5, 80, 60,
    62, 235, 199, 70, 62, 13, 112, 81, 62, 5, 80, 92, 62, 246, 95, 103, 62, 224,
    159, 114, 62, 6, 16, 126, 62, 241, 215, 132, 62, 14, 188, 138, 62, 6, 184,
    144, 62, 252, 195, 150, 62, 240, 223, 156, 62, 241, 15, 163, 62, 240, 79,
    169, 62, 14, 160, 175, 62, 248, 251, 181, 62, 1, 104, 188, 62, 10, 220, 194,
    62, 255, 91, 201, 62, 4, 232, 207, 62, 247, 119, 214, 62, 10, 16, 221, 62,
    251, 175, 227, 62, 252, 83, 234, 62, 13, 252, 240, 62, 13, 168, 247, 62, 13,
    84, 254, 62, 6, 128, 2, 63, 6, 214, 5, 63, 6, 44, 9, 63, 7, 126, 12, 63, 7,
    208, 15, 63, 8, 30, 19, 63, 249, 103, 22, 63, 3, 176, 25, 63, 6, 242, 28,
    63, 249, 47, 32, 63, 6, 104, 35, 63, 250, 153, 38, 63, 0, 196, 41, 63, 255,
    231, 44, 63, 255, 3, 48, 63, 1, 24, 51, 63, 3, 36, 54, 63, 7, 36, 57, 63,
    252, 27, 60, 63, 3, 8, 63, 63, 3, 234, 65, 63, 253, 193, 68, 63, 0, 140, 71,
    63, 6, 74, 74, 63, 252, 251, 76, 63, 6, 158, 79, 63, 1, 52, 82, 63, 6, 188,
    84, 63, 5, 54, 87, 63, 254, 157, 89, 63, 1, 248, 91, 63, 254, 67, 94, 63,
    254, 123, 96, 63, 248, 165, 98, 63, 253, 189, 100, 63, 252, 195, 102, 63, 6,
    184, 104, 63, 250, 153, 106, 63, 249, 105, 108, 63, 250, 37, 110, 63, 7,
    208, 111, 63, 6, 102, 113, 63, 8, 232, 114, 63, 252, 85, 116, 63, 3, 176,
    117, 63, 253, 245, 118, 63, 250, 39, 120, 63, 3, 68, 121, 63, 6, 74, 122,
    63, 251, 59, 123, 63, 252, 23, 124, 63, 248, 221, 124, 63, 8, 144, 125, 63,
    250, 41, 126, 63, 255, 175, 126, 63, 8, 30, 127, 63, 3, 120, 127, 63, 2,
    186, 127, 63, 251, 229, 127, 63, 248, 253, 127, 63, 248, 253, 127, 63, 251,
    229, 127, 63, 2, 186, 127, 63, 3, 120, 127, 63, 8, 30, 127, 63, 255, 175,
    126, 63, 250, 41, 126, 63, 8, 144, 125, 63, 248, 221, 124, 63, 252, 23, 124,
    63, 251, 59, 123, 63, 6, 74, 122, 63, 3, 68, 121, 63, 250, 39, 120, 63, 253,
    245, 118, 63, 3, 176, 117, 63, 252, 85, 116, 63, 8, 232, 114, 63, 6, 102,
    113, 63, 7, 208, 111, 63, 250, 37, 110, 63, 249, 105, 108, 63, 250, 153,
    106, 63, 6, 184, 104, 63, 252, 195, 102, 63, 253, 189, 100, 63, 248, 165,
    98, 63, 254, 123, 96, 63, 254, 67, 94, 63, 1, 248, 91, 63, 254, 157, 89, 63,
    5, 54, 87, 63, 6, 188, 84, 63, 1, 52, 82, 63, 6, 158, 79, 63, 252, 251, 76,
    63, 6, 74, 74, 63, 0, 140, 71, 63, 253, 193, 68, 63, 3, 234, 65, 63, 3, 8,
    63, 63, 252, 27, 60, 63, 7, 36, 57, 63, 3, 36, 54, 63, 1, 24, 51, 63, 255,
    3, 48, 63, 255, 231, 44, 63, 0, 196, 41, 63, 250, 153, 38, 63, 6, 104, 35,
    63, 249, 47, 32, 63, 6, 242, 28, 63, 3, 176, 25, 63, 249, 103, 22, 63, 8,
    30, 19, 63, 7, 208, 15, 63, 7, 126, 12, 63, 6, 44, 9, 63, 6, 214, 5, 63, 6,
    128, 2, 63, 13, 84, 254, 62, 13, 168, 247, 62, 13, 252, 240, 62, 252, 83,
    234, 62, 251, 175, 227, 62, 10, 16, 221, 62, 247, 119, 214, 62, 4, 232, 207,
    62, 255, 91, 201, 62, 10, 220, 194, 62, 1, 104, 188, 62, 248, 251, 181, 62,
    14, 160, 175, 62, 240, 79, 169, 62, 241, 15, 163, 62, 240, 223, 156, 62,
    252, 195, 150, 62, 6, 184, 144, 62, 14, 188, 138, 62, 241, 215, 132, 62, 6,
    16, 126, 62, 224, 159, 114, 62, 246, 95, 103, 62, 5, 80, 92, 62, 13, 112,
    81, 62, 235, 199, 70, 62, 5, 80, 60, 62, 22, 24, 50, 62, 252, 23, 40, 62,
    253, 79, 30, 62, 243, 199, 20, 62, 225, 127, 11, 62, 7, 120, 2, 62, 196, 95,
    243, 61, 45, 96, 226, 61, 61, 240, 209, 61, 59, 0, 194, 61, 224, 159, 178,
    61, 244, 223, 163, 61, 54, 176, 149, 61, 31, 16, 136, 61, 226, 31, 118, 61,
    99, 96, 93, 61, 168, 223, 69, 61, 203, 159, 47, 61, 190, 159, 26, 61, 131,
    223, 6, 61, 50, 255, 232, 60, 1, 191, 198, 60, 117, 63, 167, 60, 141, 128,
    138, 60, 90, 126, 96, 60, 23, 129, 49, 60, 235, 0, 8, 60, 16, 4, 200, 59,
    122, 0, 139, 59, 235, 254, 49, 59, 16, 4, 200, 58, 12, 230, 47, 58, 176,
    227, 63, 57, 64, 218, 127, 56, 48, 101, 96, 57, 184, 31, 240, 57, 180, 1,
    88, 58, 200, 8, 168, 58, 42, 254, 239, 58, 71, 1, 34, 59, 75, 2, 84, 59, 17,
    1, 135, 59, 176, 0, 166, 59, 185, 255, 200, 59, 130, 2, 239, 59, 84, 0, 12,
    60, 27, 127, 34, 60, 195, 127, 58, 60, 25, 254, 83, 60, 80, 254, 110, 60,
    30, 255, 133, 60, 3, 64, 149, 60, 192, 63, 165, 60, 109, 0, 182, 60, 241,
    127, 199, 60, 80, 255, 217, 60, 158, 63, 237, 60, 237, 127, 0, 61, 248, 223,
    10, 61, 123, 160, 21, 61, 223, 223, 32, 61, 70, 96, 44, 61, 24, 64, 56, 61,
    99, 128, 68, 61, 142, 63, 81, 61, 188, 63, 94, 61, 98, 160, 107, 61, 116,
    96, 121, 61, 249, 191, 131, 61, 243, 255, 138, 61, 42, 112, 146, 61, 23, 16,
    154, 61, 255, 207, 161, 61, 221, 207, 169, 61, 61, 240, 177, 61, 205, 63,
    186, 61, 222, 175, 194, 61, 229, 95, 203, 61, 232, 47, 212, 61, 230, 31,
    221, 61, 33, 64, 230, 61, 17, 144, 239, 61, 252, 255, 248, 61, 18, 80, 1,
    62, 0, 56, 6, 62, 15, 40, 11, 62, 25, 56, 16, 62, 1, 80, 21, 62, 6, 128, 26,
    62, 9, 192, 31, 62, 231, 23, 37, 62, 6, 128, 42, 62, 224, 247, 47, 62, 250,
    127, 53, 62, 17, 24, 59, 62, 4, 200, 64, 62, 23, 128, 70, 62, 5, 80, 76, 62,
    19, 40, 82, 62, 252, 23, 88, 62, 6, 16, 94, 62, 235, 31, 100, 62, 239, 55,
    106, 62, 242, 95, 112, 62, 242, 151, 118, 62, 18, 216, 124, 62, 247, 147,
    129, 62, 5, 196, 132, 62, 1, 248, 135, 62, 253, 51, 139, 62, 247, 119, 142,
    62, 1, 192, 145, 62, 250, 11, 149, 62, 242, 95, 152, 62, 250, 183, 155, 62,
    1, 24, 159, 62, 7, 120, 162, 62, 13, 224, 165, 62, 1, 76, 169, 62, 6, 188,
    172, 62, 9, 52, 176, 62, 12, 172, 179, 62, 254, 39, 183, 62, 17, 172, 186,
    62, 1, 48, 190, 62, 2, 184, 193, 62, 242, 67, 197, 62, 3, 208, 200, 62, 3,
    96, 204, 62, 241, 243, 207, 62, 240, 139, 211, 62, 16, 36, 215, 62, 253,
    191, 218, 62, 12, 92, 222, 62, 9, 252, 225, 62, 6, 156, 229, 62, 4, 60, 233,
    62, 240, 223, 236, 62, 253, 131, 240, 62, 11, 40, 244, 62, 247, 203, 247,
    62, 4, 112, 251, 62, 1, 24, 255, 62, 7, 94, 1, 63, 253, 47, 3, 63, 251, 3,
    5, 63, 2, 214, 6, 63, 248, 167, 8, 63, 255, 121, 10, 63, 253, 73, 12, 63,
    252, 25, 14, 63, 251, 233, 15, 63, 250, 185, 17, 63, 1, 136, 19, 63, 0, 84,
    21, 63, 255, 31, 23, 63, 7, 234, 24, 63, 255, 179, 26, 63, 254, 123, 28, 63,
    7, 66, 30, 63, 8, 6, 32, 63, 8, 202, 33, 63, 0, 140, 35, 63, 249, 73, 37,
    63, 3, 8, 39, 63, 5, 196, 40, 63, 254, 125, 42, 63, 248, 51, 44, 63, 3, 234,
    45, 63, 254, 155, 47, 63, 1, 76, 49, 63, 253, 249, 50, 63, 1, 166, 52, 63,
    5, 78, 54, 63, 250, 241, 55, 63, 255, 149, 57, 63, 253, 51, 59, 63, 3, 208,
    60, 63, 1, 106, 62, 63, 0, 0, 64, 63, 255, 145, 65, 63, 8, 34, 67, 63, 0,
    174, 68, 63, 248, 53, 70, 63, 2, 186, 71, 63, 4, 60, 73, 63, 254, 183, 74,
    63, 1, 50, 76, 63, 252, 165, 77, 63, 1, 24, 79, 63, 253, 131, 80, 63, 3,
    238, 81, 63, 0, 82, 83, 63, 255, 177, 84, 63, 254, 13, 86, 63, 254, 101, 87,
    63, 6, 184, 88, 63, 255, 5, 90, 63, 248, 79, 91, 63, 251, 147, 92, 63, 254,
    211, 93, 63, 2, 16, 95, 63, 254, 69, 96, 63, 251, 119, 97, 63, 1, 164, 98,
    63, 0, 202, 99, 63, 255, 235, 100, 63, 7, 8, 102, 63, 255, 31, 103, 63, 1,
    50, 104, 63, 251, 61, 105, 63, 254, 67, 106, 63, 2, 70, 107, 63, 255, 65,
    108, 63, 4, 56, 109, 63, 250, 41, 110, 63, 1, 20, 111, 63, 248, 249, 111,
    63, 2, 216, 112, 63, 251, 177, 113, 63, 253, 133, 114, 63, 0, 82, 115, 63,
    5, 26, 116, 63, 1, 220, 116, 63, 7, 152, 117, 63, 253, 75, 118, 63, 5, 252,
    118, 63, 5, 166, 119, 63, 6, 72, 120, 63, 0, 228, 120, 63, 3, 122, 121, 63,
    255, 9, 122, 63, 3, 148, 122, 63, 1, 24, 123, 63, 255, 147, 123, 63, 7, 10,
    124, 63, 7, 122, 124, 63, 0, 228, 124, 63, 2, 72, 125, 63, 5, 164, 125, 63,
    1, 250, 125, 63, 6, 74, 126, 63, 251, 145, 126, 63, 250, 211, 126, 63, 2,
    16, 127, 63, 2, 70, 127, 63, 4, 116, 127, 63, 254, 155, 127, 63, 249, 187,
    127, 63, 6, 216, 127, 63, 3, 236, 127, 63, 1, 248, 127, 63, 248, 253, 127,
    63, 248, 253, 127, 63, 5, 54, 127, 63, 249, 217, 124, 63, 7, 238, 120, 63,
    3, 120, 115, 63, 253, 131, 108, 63, 5, 26, 100, 63, 6, 70, 90, 63, 0, 28,
    79, 63, 248, 169, 66, 63, 251, 3, 53, 63, 7, 66, 38, 63, 255, 121, 22, 63,
    248, 193, 5, 63, 239, 111, 232, 62, 242, 239, 195, 62, 0, 56, 158, 62, 224,
    15, 111, 62, 249, 47, 32, 62, 41, 176, 160, 61, 71, 3, 128, 63, 65, 183,
    127, 63, 156, 221, 126, 63, 96, 116, 125, 63, 11, 126, 123, 63, 210, 253,
    120, 63, 10, 248, 117, 63, 172, 113, 114, 63, 143, 112, 110, 63, 47, 251,
    105, 63, 194, 24, 101, 63, 0, 0, 0, 0, 33, 32, 31, 62, 244, 223, 139, 62, 4,
    0, 231, 62, 2, 240, 199, 62, 251, 231, 37, 63, 254, 67, 128, 63, 15, 96,
    225, 62, 4, 56, 49, 63, 248, 167, 116, 63, 2, 240, 175, 62, 0, 112, 36, 63,
    254, 39, 137, 63, 243, 255, 162, 62, 251, 175, 251, 62, 252, 167, 43, 63,
    240, 191, 69, 62, 247, 63, 192, 62, 0, 200, 57, 63, 9, 112, 186, 62, 0, 168,
    2, 63, 249, 159, 40, 63, 244, 79, 152, 62, 2, 16, 7, 63, 251, 175, 87, 63,
    10, 160, 104, 62, 7, 96, 187, 62, 250, 39, 16, 63, 248, 223, 122, 62, 16,
    32, 203, 62, 7, 240, 34, 63, 27, 128, 45, 62, 3, 176, 153, 62, 6, 48, 5, 63,
    249, 47, 160, 62, 15, 208, 237, 62, 248, 167, 36, 63, 246, 127, 126, 62, 12,
    32, 220, 62, 6, 72, 32, 63, 252, 223, 113, 62, 0, 144, 251, 62, 6, 48, 81,
    63, 2, 128, 171, 62, 255, 7, 32, 63, 1, 80, 101, 63, 14, 160, 175, 62, 5,
    192, 4, 63, 249, 215, 50, 63, 9, 224, 190, 62, 255, 231, 40, 63, 7, 8, 114,
    63, 8, 32, 53, 62, 245, 15, 162, 62, 2, 216, 20, 63, 6, 48, 213, 62, 7, 64,
    32, 63, 4, 32, 78, 63, 12, 64, 155, 62, 7, 152, 17, 63, 255, 63, 106, 63,
    237, 127, 80, 62, 242, 239, 179, 62, 4, 144, 22, 63, 229, 127, 98, 62, 0, 0,
    192, 62, 254, 95, 29, 63, 244, 191, 76, 62, 247, 143, 165, 62, 249, 191,
    243, 62, 248, 255, 153, 62, 13, 224, 221, 62, 8, 32, 17, 63, 237, 159, 103,
    62, 3, 64, 181, 62, 250, 239, 1, 63, 6, 160, 153, 62, 5, 24, 2, 63, 1, 48,
    54, 63, 9, 192, 159, 62, 7, 64, 252, 62, 4, 112, 67, 63, 254, 239, 168, 62,
    6, 128, 10, 63, 250, 183, 75, 63, 246, 95, 191, 62, 252, 199, 26, 63, 0,
    200, 109, 63, 237, 159, 87, 62, 244, 111, 231, 62, 0, 88, 65, 63, 250, 207,
    138, 62, 13, 80, 242, 62, 7, 152, 41, 63, 241, 47, 146, 62, 12, 176, 223,
    62, 249, 103, 34, 63, 249, 159, 124, 62, 4, 200, 16, 63, 4, 232, 91, 63, 6,
    128, 138, 62, 0, 0, 208, 62, 2, 184, 13, 63, 249, 15, 185, 62, 252, 23, 20,
    63, 4, 88, 80, 63, 244, 111, 175, 62, 253, 47, 247, 62, 8, 88, 31, 63, 255,
    63, 174, 62, 248, 167, 12, 63, 255, 63, 62, 63, 226, 63, 77, 62, 10, 48,
    172, 62, 7, 120, 10, 63, 3, 208, 208, 62, 249, 15, 37, 63, 7, 40, 101, 63,
    9, 224, 150, 62, 1, 224, 232, 62, 5, 248, 50, 63, 240, 191, 69, 62, 242, 95,
    176, 62, 248, 167, 36, 63, 6, 48, 141, 62, 244, 111, 215, 62, 2, 72, 25, 63,
    250, 207, 194, 62, 7, 208, 27, 63, 0, 112, 92, 63, 235, 31, 100, 62, 241,
    47, 218, 62, 6, 48, 45, 63, 10, 160, 208, 62, 251, 63, 51, 63, 3, 204, 134,
    63, 14, 160, 95, 62, 247, 31, 193, 62, 2, 128, 43, 63, 242, 95, 160, 62, 15,
    128, 232, 62, 254, 215, 25, 63, 252, 223, 177, 62, 253, 79, 18, 63, 8, 200,
    95, 63, 249, 191, 115, 62, 17, 144, 207, 62, 253, 191, 58, 63, 3, 64, 101,
    62, 4, 112, 211, 62, 248, 111, 82, 63, 11, 96, 202, 62, 251, 7, 21, 63, 248,
    111, 62, 63, 13, 224, 125, 62, 10, 160, 248, 62, 255, 119, 56, 63, 2, 160,
    106, 62, 255, 63, 254, 62, 3, 96, 104, 63, 6, 48, 133, 62, 8, 32, 17, 63,
    253, 159, 129, 63, 244, 191, 172, 62, 252, 143, 28, 63, 249, 103, 122, 63,
    247, 31, 137, 62, 6, 48, 229, 62, 254, 239, 68, 63, 244, 79, 128, 62, 4,
    112, 195, 62, 251, 175, 7, 63, 10, 48, 220, 62, 5, 24, 78, 63, 1, 104, 156,
    63, 244, 223, 195, 62, 250, 71, 11, 63, 253, 159, 51, 63, 3, 96, 196, 62,
    254, 215, 53, 63, 1, 24, 147, 63, 246, 239, 138, 62, 4, 112, 7, 63, 5, 136,
    70, 63, 244, 191, 124, 62, 10, 128, 201, 62, 255, 175, 22, 63, 10, 160, 136,
    62, 251, 31, 216, 62, 3, 64, 45, 63, 241, 127, 159, 62, 248, 167, 20, 63,
    255, 175, 86, 63, 2, 100, 170, 63, 254, 211, 201, 63, 0, 200, 227, 63, 254,
    123, 148, 63, 4, 116, 179, 63, 252, 83, 226, 63, 254, 183, 148, 63, 252, 55,
    195, 63, 0, 144, 219, 63, 248, 111, 66, 63, 249, 247, 105, 63, 0, 88, 143,
    63, 7, 120, 114, 63, 1, 136, 143, 63, 2, 40, 164, 63, 3, 236, 129, 63, 1,
    76, 199, 63, 3, 8, 231, 63, 3, 36, 150, 63, 3, 120, 179, 63, 0, 168, 216,
    63, 253, 51, 139, 63, 255, 179, 188, 63, 254, 43, 213, 63, 254, 71, 102, 63,
    3, 120, 141, 63, 0, 0, 180, 63, 4, 0, 147, 63, 254, 11, 190, 63, 3, 236,
    229, 63, 8, 32, 73, 63, 1, 220, 154, 63, 0, 172, 200, 63, 2, 40, 94, 63, 1,
    80, 149, 63, 3, 176, 185, 63, 250, 39, 108, 63, 253, 107, 157, 63, 252, 223,
    181, 63, 4, 144, 74, 63, 0, 200, 143, 63, 1, 108, 180, 63, 249, 247, 73, 63,
    248, 79, 119, 63, 0, 144, 171, 63, 252, 139, 134, 63, 255, 235, 162, 63, 3,
    64, 199, 63, 251, 231, 93, 63, 253, 47, 151, 63, 253, 247, 196, 63, 251, 31,
    104, 63, 255, 207, 175, 63, 255, 207, 205, 63, 0, 84, 159, 63, 1, 108, 202,
    63, 1, 136, 247, 63, 249, 247, 105, 63, 254, 43, 171, 63, 1, 20, 197, 63, 0,
    56, 46, 63, 8, 144, 117, 63, 2, 212, 212, 63, 255, 31, 99, 63, 0, 28, 183,
    63, 254, 127, 230, 63, 6, 160, 105, 63, 2, 128, 183, 63, 0, 140, 215, 63, 6,
    72, 92, 63, 2, 184, 135, 63, 254, 71, 186, 63, 0, 168, 128, 63, 4, 200, 176,
    63, 254, 123, 218, 63, 252, 255, 76, 63, 3, 148, 174, 63, 4, 32, 234, 63,
    250, 39, 72, 63, 3, 236, 143, 63, 252, 195, 192, 63, 255, 119, 128, 63, 4,
    88, 188, 63, 253, 163, 215, 63, 252, 55, 123, 63, 253, 159, 167, 63, 0, 28,
    207, 63, 2, 72, 157, 63, 254, 243, 198, 63, 2, 212, 224, 63, 0, 0, 60, 63,
    1, 80, 101, 63, 1, 224, 156, 63, 2, 128, 87, 63, 255, 31, 135, 63, 4, 232,
    159, 63, 0, 144, 107, 63, 2, 72, 143, 63, 0, 60, 190, 63, 253, 247, 100, 63,
    4, 0, 197, 63, 252, 27, 234, 63, 253, 79, 164, 63, 0, 140, 197, 63, 253,
    219, 237, 63, 249, 15, 45, 63, 248, 223, 110, 63, 2, 212, 190, 63, 255, 63,
    82, 63, 254, 243, 190, 63, 255, 179, 222, 63, 4, 88, 108, 63, 253, 131, 146,
    63, 253, 79, 202, 63, 0, 84, 135, 63, 0, 60, 172, 63, 254, 99, 209, 63, 2,
    128, 99, 63, 255, 151, 137, 63, 253, 51, 173, 63, 0, 88, 113, 63, 4, 4, 153,
    63, 255, 179, 192, 63, 4, 4, 191, 63, 1, 224, 228, 63, 1, 138, 2, 64, 4, 0,
    137, 63, 253, 51, 209, 63, 1, 52, 238, 63, 1, 108, 160, 63, 3, 148, 190, 63,
    255, 207, 215, 63, 5, 168, 37, 63, 6, 48, 93, 63, 252, 111, 179, 63, 252,
    223, 93, 63, 2, 160, 160, 63, 255, 7, 186, 63, 1, 48, 136, 63, 2, 44, 196,
    63, 254, 155, 221, 63, 3, 124, 149, 63, 253, 191, 194, 63, 254, 39, 241, 63,
    5, 192, 116, 63, 254, 67, 192, 63, 252, 111, 223, 63, 1, 192, 109, 63, 254,
    239, 164, 63, 254, 99, 189, 63, 3, 68, 139, 63, 253, 187, 176, 63, 2, 16,
    201, 63, 251, 175, 103, 63, 4, 232, 151, 63, 254, 155, 175, 63, 253, 103,
    135, 63, 4, 228, 181, 63, 3, 8, 207, 63, 253, 219, 141, 63, 0, 0, 168, 63,
    1, 48, 192, 63, 253, 191, 122, 63, 254, 95, 181, 63, 4, 232, 207, 63, 0, 84,
    163, 63, 2, 156, 212, 63, 2, 130, 0, 64, 0, 56, 126, 63, 255, 147, 149, 63,
    0, 116, 170, 63, 252, 55, 71, 63, 251, 231, 113, 63, 1, 52, 158, 63, 1, 164,
    156, 63, 0, 228, 190, 63, 1, 104, 232, 63, 254, 71, 90, 63, 1, 220, 172, 63,
    0, 116, 220, 63, 3, 64, 145, 63, 3, 236, 203, 63, 4, 200, 228, 63, 3, 148,
    134, 63, 3, 124, 171, 63, 1, 248, 195, 63, 2, 184, 77, 63, 254, 183, 162,
    63, 3, 120, 211, 63, 251, 119, 65, 63, 3, 96, 146, 63, 254, 215, 209, 63, 4,
    112, 51, 63, 251, 87, 86, 63, 255, 175, 144, 63, 0, 88, 113, 63, 0, 84, 153,
    63, 2, 188, 213, 63, 254, 71, 126, 63, 1, 108, 176, 63, 253, 131, 200, 63,
    249, 15, 77, 63, 1, 52, 140, 63, 254, 67, 166, 63, 252, 111, 61, 63, 0, 56,
    132, 63, 0, 28, 177, 63, 254, 127, 88, 63, 3, 64, 137, 63, 254, 155, 197,
    63, 249, 103, 106, 63, 3, 64, 137, 63, 253, 187, 156, 63, 3, 208, 130, 63,
    254, 243, 156, 63, 254, 155, 189, 63, 1, 248, 127, 63, 4, 32, 154, 63, 254,
    39, 199, 63, 255, 231, 56, 63, 249, 247, 105, 63, 0, 140, 171, 63, 3, 96,
    44, 63, 8, 0, 86, 63, 252, 55, 161, 63, 248, 23, 85, 63, 0, 172, 154, 63, 0,
    116, 178, 63, 253, 79, 118, 63, 1, 192, 201, 63, 2, 216, 244, 63, 0, 88,
    149, 63, 3, 120, 175, 63, 3, 68, 199, 63, 255, 63, 114, 63, 2, 188, 145, 63,
    3, 64, 179, 63, 254, 123, 132, 63, 2, 240, 155, 63, 3, 120, 177, 63, 1, 104,
    178, 63, 252, 223, 219, 63, 255, 3, 0, 64, 252, 255, 120, 63, 253, 103, 161,
    63, 255, 91, 225, 63, 254, 155, 131, 63, 255, 235, 164, 63, 255, 7, 190, 63,
    7, 8, 70, 63, 3, 8, 161, 63, 1, 220, 224, 63, 254, 71, 138, 63, 1, 252, 169,
    63, 2, 16, 223, 63, 7, 240, 82, 63, 3, 180, 185, 63, 3, 120, 245, 63, 4,
    232, 91, 63, 4, 32, 130, 63, 4, 144, 152, 63, 254, 15, 88, 63, 4, 200, 144,
    63, 4, 144, 210, 63, 254, 71, 152, 63, 2, 244, 217, 63, 254, 123, 242, 63,
    3, 96, 172, 63, 3, 176, 225, 63, 0, 58, 4, 64, 4, 0, 123, 63, 254, 123, 160,
    63, 253, 135, 184, 63, 2, 72, 171, 63, 2, 44, 210, 63, 254, 99, 247, 63, 3,
    148, 156, 63, 253, 75, 182, 63, 1, 48, 208, 63, 255, 7, 68, 63, 253, 135,
    128, 63, 4, 32, 202, 63, 3, 124, 133, 63, 0, 116, 162, 63, 1, 224, 210, 63,
    0, 0, 120, 63, 253, 247, 160, 63, 255, 35, 199, 63, 1, 136, 83, 63, 253, 79,
    126, 63, 252, 55, 163, 63, 7, 8, 62, 63, 248, 79, 115, 63, 0, 28, 183, 63,
    254, 243, 134, 63, 253, 75, 184, 63, 1, 252, 233, 63, 0, 88, 137, 63, 2,
    128, 161, 63, 4, 144, 184, 63, 0, 0, 92, 63, 0, 168, 132, 63, 253, 75, 168,
    63, 1, 80, 101, 63, 1, 52, 162, 63, 2, 132, 205, 63, 1, 48, 78, 63, 6, 128,
    118, 63, 0, 56, 146, 63, 251, 175, 75, 63, 255, 179, 128, 63, 1, 248, 187,
    63, 3, 124, 141, 63, 2, 44, 168, 63, 1, 220, 202, 63, 250, 207, 74, 63, 2,
    100, 156, 63, 254, 155, 187, 63, 254, 211, 149, 63, 253, 219, 187, 63, 4, 0,
    213, 63, 3, 152, 86, 63, 254, 155, 147, 63, 255, 203, 171, 63, 7, 208, 119,
    63, 254, 95, 153, 63, 253, 19, 178, 63, 255, 3, 160, 63, 255, 207, 207, 63,
    253, 107, 233, 63, 1, 48, 54, 63, 3, 120, 135, 63, 0, 140, 193, 63, 0, 56,
    154, 63, 2, 44, 186, 63, 2, 156, 222, 63, 255, 151, 145, 63, 1, 252, 181,
    63, 254, 95, 207, 63, 7, 8, 82, 63, 1, 80, 169, 63, 255, 3, 202, 63, 255,
    119, 76, 63, 1, 164, 128, 63, 254, 95, 155, 63, 2, 16, 123, 63, 255, 151,
    169, 63, 252, 167, 193, 63, 3, 96, 142, 63, 2, 44, 184, 63, 2, 40, 222, 63,
    0, 88, 145, 63, 252, 167, 173, 63, 254, 95, 209, 63, 253, 247, 140, 63, 255,
    151, 177, 63, 252, 223, 215, 63, 8, 144, 89, 63, 4, 116, 163, 63, 4, 0, 195,
    63, 3, 96, 176, 63, 252, 83, 208, 63, 254, 215, 237, 63, 5, 136, 98, 63, 0,
    56, 140, 63, 3, 204, 164, 63, 1, 24, 165, 63, 253, 191, 192, 63, 2, 216,
    224, 63, 2, 72, 81, 63, 254, 43, 177, 63, 3, 68, 211, 63, 254, 43, 185, 63,
    252, 223, 217, 63, 2, 72, 247, 63, 249, 247, 85, 63, 254, 243, 130, 63, 0,
    84, 177, 63, 0, 168, 106, 63, 255, 203, 145, 63, 255, 235, 170, 63, 8, 232,
    122, 63, 254, 71, 150, 63, 253, 19, 186, 63, 252, 83, 218, 63, 2, 216, 9,
    64, 254, 125, 25, 64, 255, 147, 47, 64, 255, 7, 230, 63, 1, 20, 1, 64, 1,
    134, 28, 64, 255, 235, 44, 64, 252, 195, 254, 63, 255, 5, 14, 64, 0, 228,
    36, 64, 2, 16, 52, 64, 254, 183, 236, 63, 0, 56, 12, 64, 255, 35, 39, 64, 0,
    114, 53, 64, 0, 56, 212, 63, 255, 91, 237, 63, 2, 46, 10, 64, 255, 121, 46,
    64, 255, 7, 184, 63, 255, 231, 242, 63, 1, 52, 16, 64, 2, 242, 41, 64, 2,
    214, 1, 64, 254, 211, 15, 64, 254, 181, 34, 64, 254, 13, 56, 64, 252, 83,
    222, 63, 255, 7, 246, 63, 0, 230, 11, 64, 255, 147, 47, 64, 252, 195, 194,
    63, 3, 124, 239, 63, 254, 183, 33, 64, 255, 93, 50, 64, 3, 180, 235, 63,
    255, 61, 3, 64, 0, 172, 29, 64, 0, 228, 54, 64, 1, 136, 203, 63, 1, 78, 4,
    64, 2, 100, 27, 64, 2, 216, 50, 64, 255, 151, 223, 63, 0, 172, 3, 64, 254,
    125, 20, 64, 255, 63, 51, 64, 255, 7, 222, 63, 2, 100, 248, 63, 255, 149,
    19, 64, 0, 228, 52, 64, 1, 164, 228, 63, 1, 22, 13, 64, 2, 72, 29, 64, 0,
    230, 50, 64, 0, 56, 238, 63, 255, 233, 10, 64, 1, 136, 26, 64, 1, 224, 48,
    64, 1, 24, 0, 64, 255, 175, 19, 64, 254, 95, 35, 64, 1, 246, 51, 64, 1, 104,
    228, 63, 2, 242, 7, 64, 1, 108, 24, 64, 1, 80, 41, 64, 0, 196, 241, 63, 255,
    177, 8, 64, 0, 202, 26, 64, 0, 30, 55, 64, 254, 69, 19, 64, 0, 0, 34, 64, 2,
    102, 46, 64, 0, 112, 58, 64, 4, 60, 235, 63, 2, 72, 3, 64, 0, 196, 16, 64,
    1, 104, 35, 64, 254, 211, 0, 64, 254, 39, 14, 64, 2, 44, 28, 64, 2, 102, 43,
    64, 252, 251, 182, 63, 253, 219, 237, 63, 254, 67, 37, 64, 255, 31, 54, 64,
    2, 184, 207, 63, 255, 231, 242, 63, 2, 130, 23, 64, 1, 192, 39, 64, 0, 112,
    202, 63, 254, 239, 250, 63, 255, 3, 33, 64, 255, 33, 47, 64, 3, 176, 181,
    63, 0, 140, 247, 63, 255, 65, 25, 64, 0, 202, 47, 64, 1, 104, 220, 63, 255,
    207, 3, 64, 0, 254, 26, 64, 255, 65, 49, 64, 2, 156, 244, 63, 255, 31, 14,
    64, 2, 130, 29, 64, 1, 104, 47, 64, 2, 100, 202, 63, 1, 48, 238, 63, 1, 22,
    33, 64, 1, 250, 55, 64, 253, 103, 239, 63, 0, 82, 6, 64, 0, 172, 27, 64,
    255, 3, 42, 64, 254, 209, 3, 64, 255, 231, 17, 64, 254, 183, 31, 64, 0, 174,
    46, 64, 1, 104, 232, 63, 2, 158, 11, 64, 0, 230, 28, 64, 0, 142, 43, 64, 0,
    56, 180, 63, 4, 88, 226, 63, 2, 244, 21, 64, 1, 194, 42, 64, 255, 203, 10,
    64, 255, 65, 25, 64, 255, 179, 38, 64, 1, 20, 53, 64, 252, 111, 209, 63, 3,
    208, 238, 63, 2, 16, 21, 64, 1, 192, 52, 64, 254, 127, 240, 63, 254, 237, 8,
    64, 0, 28, 24, 64, 2, 130, 43, 64, 1, 248, 225, 63, 2, 100, 252, 63, 2, 130,
    12, 64, 0, 144, 37, 64, 0, 140, 10, 64, 1, 76, 26, 64, 0, 30, 43, 64, 255,
    205, 57, 64, 254, 153, 4, 64, 1, 48, 21, 64, 0, 86, 41, 64, 254, 13, 58, 64,
    0, 170, 1, 64, 254, 239, 19, 64, 2, 18, 38, 64, 2, 74, 55, 64, 3, 120, 249,
    63, 255, 145, 11, 64, 2, 244, 32, 64, 2, 16, 51, 64, 1, 224, 240, 63, 254,
    95, 8, 64, 254, 153, 30, 64, 255, 119, 51, 64, 255, 61, 15, 64, 1, 250, 28,
    64, 0, 200, 41, 64, 255, 145, 55, 64, 254, 239, 242, 63, 2, 130, 6, 64, 0,
    142, 33, 64, 0, 84, 56, 64, 253, 159, 237, 63, 0, 198, 4, 64, 2, 160, 34,
    64, 0, 172, 50, 64, 3, 8, 217, 63, 1, 190, 12, 64, 2, 102, 32, 64, 1, 136,
    47, 64, 1, 108, 206, 63, 254, 67, 240, 63, 1, 222, 27, 64, 255, 231, 46, 64,
    3, 208, 200, 63, 0, 172, 228, 63, 0, 116, 12, 64, 1, 48, 49, 64, 255, 7,
    250, 63, 1, 136, 10, 64, 254, 41, 31, 64, 254, 239, 55, 64, 3, 180, 177, 63,
    2, 216, 220, 63, 254, 155, 24, 64, 2, 102, 49, 64, 254, 97, 7, 64, 2, 158,
    21, 64, 2, 242, 37, 64, 1, 76, 55, 64, 2, 240, 243, 63, 255, 31, 7, 64, 254,
    67, 23, 64, 2, 132, 50, 64, 253, 247, 240, 63, 255, 63, 21, 64, 2, 74, 38,
    64, 1, 248, 52, 64, 2, 128, 215, 63, 0, 174, 5, 64, 254, 41, 23, 64, 2, 244,
    38, 64, 252, 223, 239, 63, 0, 168, 11, 64, 1, 80, 34, 64, 1, 22, 49, 64,
    252, 83, 238, 63, 2, 130, 4, 64, 254, 209, 19, 64, 2, 214, 44, 64, 0, 112,
    220, 63, 1, 224, 6, 64, 0, 140, 30, 64, 254, 155, 53, 64, 1, 164, 246, 63,
    254, 209, 11, 64, 0, 84, 28, 64, 254, 123, 42, 64, 254, 237, 7, 64, 255,
    149, 21, 64, 2, 102, 35, 64, 1, 194, 49, 64, 2, 72, 203, 63, 0, 172, 244,
    63, 2, 158, 13, 64, 255, 207, 34, 64, 0, 116, 3, 64, 2, 188, 23, 64, 255,
    147, 45, 64, 254, 39, 60, 64, 255, 119, 13, 64, 255, 65, 33, 64, 1, 78, 49,
    64, 255, 89, 61, 64, 0, 196, 12, 64, 254, 97, 30, 64, 1, 248, 45, 64, 1,
    224, 58, 64, 0, 228, 250, 63, 0, 58, 11, 64, 255, 233, 38, 64, 0, 226, 56,
    64, 253, 19, 250, 63, 1, 218, 11, 64, 1, 104, 27, 64, 255, 207, 51, 64, 253,
    191, 206, 63, 0, 86, 2, 64, 0, 230, 36, 64, 1, 106, 53, 64, 2, 128, 207, 63,
    255, 205, 1, 64, 255, 149, 27, 64, 1, 138, 42, 64, 255, 151, 233, 63, 0, 30,
    17, 64, 254, 237, 32, 64, 1, 222, 47, 64, 3, 68, 255, 63, 0, 170, 14, 64, 1,
    106, 30, 64, 254, 95, 53, 64, 1, 136, 223, 63, 2, 186, 0, 64, 1, 166, 37,
    64, 255, 235, 52, 64, 254, 11, 200, 63, 255, 179, 8, 64, 1, 48, 30, 64, 1,
    252, 43, 64, 253, 219, 223, 63, 0, 88, 5, 64, 2, 128, 29, 64, 255, 235, 43,
    64, 3, 8, 225, 63, 1, 106, 8, 64, 1, 190, 40, 64, 2, 16, 57, 64, 0, 144, 4,
    64, 255, 35, 19, 64, 255, 63, 35, 64, 0, 30, 52, 64, 3, 120, 235, 63, 255,
    9, 6, 64, 0, 198, 31, 64, 0, 86, 46, 64, 255, 235, 240, 63, 1, 166, 5, 64,
    1, 194, 40, 64, 1, 20, 59, 64, 2, 156, 250, 63, 255, 205, 9, 64, 254, 153,
    24, 64, 254, 95, 39, 64, 1, 24, 255, 63, 255, 121, 22, 64, 0, 226, 41, 64,
    2, 242, 56, 64, 254, 209, 0, 64, 255, 203, 16, 64, 1, 52, 41, 64, 255, 203,
    57, 64, 1, 164, 1, 64, 1, 78, 16, 64, 1, 138, 32, 64, 2, 46, 50, 64, 0, 58,
    20, 64, 254, 183, 37, 64, 254, 211, 50, 64, 0, 172, 61, 64, 1, 224, 222, 63,
    0, 228, 17, 64, 255, 5, 37, 64, 255, 61, 52, 64, 1, 52, 236, 63, 255, 145,
    3, 64, 1, 78, 22, 64, 254, 155, 52, 64, 3, 8, 215, 63, 3, 152, 246, 63, 1,
    252, 31, 64, 254, 123, 53, 64, 254, 123, 220, 63, 253, 51, 249, 63, 255,
    179, 28, 64, 1, 164, 46, 64, 0, 56, 234, 63, 0, 196, 2, 64, 255, 37, 37, 64,
    1, 136, 55, 64, 2, 156, 214, 63, 255, 149, 4, 64, 1, 164, 20, 64, 0, 28, 45,
    64, 1, 80, 229, 63, 254, 67, 7, 64, 2, 16, 30, 64, 2, 46, 50, 64, 2, 16,
    253, 63, 0, 196, 13, 64, 2, 214, 31, 64, 1, 134, 46, 64, 1, 108, 230, 63,
    254, 211, 6, 64, 0, 230, 22, 64, 2, 74, 47, 64, 2, 244, 233, 63, 0, 228, 10,
    64, 0, 168, 33, 64, 0, 142, 54, 64, 0, 140, 245, 63, 2, 128, 8, 64, 255, 65,
    31, 64, 254, 153, 46, 64, 0, 88, 245, 63, 1, 104, 14, 64, 2, 186, 34, 64, 0,
    228, 54, 64, 252, 111, 225, 63, 254, 239, 252, 63, 255, 121, 32, 64, 254,
    155, 56, 64, 0, 112, 3, 64, 2, 132, 23, 64, 255, 233, 38, 64, 254, 155, 53,
    64, 1, 52, 204, 63, 2, 16, 0, 64, 0, 168, 19, 64, 0, 2, 37, 64, 0, 60, 188,
    63, 0, 0, 2, 64, 254, 13, 24, 64, 0, 114, 41, 64, 3, 148, 230, 63, 1, 50, 8,
    64, 255, 149, 25, 64, 0, 170, 52, 64, 3, 68, 237, 63, 254, 67, 4, 64, 255,
    119, 27, 64, 1, 24, 48, 64, 1, 104, 250, 63, 255, 121, 10, 64, 254, 67, 25,
    64, 0, 254, 46, 64, 0, 88, 251, 63, 1, 166, 17, 64, 255, 117, 37, 64, 254,
    95, 55, 64, 255, 151, 241, 63, 1, 192, 6, 64, 0, 60, 21, 64, 255, 151, 37,
    64, 255, 175, 7, 64, 254, 95, 28, 64, 1, 248, 46, 64, 255, 63, 60, 64, 3,
    208, 192, 63, 2, 44, 226, 63, 1, 162, 25, 64, 2, 132, 54, 64, 2, 16, 213,
    63, 255, 147, 253, 63, 254, 11, 24, 64, 0, 144, 49, 64, 255, 63, 220, 63, 1,
    76, 253, 63, 254, 13, 25, 64, 255, 5, 41, 64, 252, 223, 189, 63, 253, 75,
    232, 63, 255, 205, 27, 64, 0, 58, 46, 64, 0, 112, 226, 63, 1, 190, 8, 64, 1,
    20, 36, 64, 0, 144, 49, 64, 0, 200, 231, 63, 254, 39, 3, 64, 254, 237, 23,
    64, 254, 69, 39, 64, 255, 231, 228, 63, 254, 97, 0, 64, 1, 252, 22, 64, 2,
    72, 46, 64, 254, 127, 228, 63, 255, 31, 255, 63, 255, 149, 25, 64, 2, 72,
    53, 64, 4, 0, 217, 63, 255, 119, 1, 64, 0, 114, 33, 64, 255, 175, 47, 64,
    252, 195, 202, 63, 255, 151, 251, 63, 1, 50, 19, 64, 255, 203, 45, 64, 252,
    139, 242, 63, 0, 254, 15, 64, 1, 250, 41, 64, 0, 116, 57, 64, 0, 60, 220,
    63, 4, 116, 255, 63, 254, 41, 19, 64, 1, 166, 35, 64, 2, 72, 207, 63, 255,
    33, 11, 64, 1, 192, 36, 64, 1, 110, 50, 64, 2, 12, 241, 63, 0, 28, 14, 64,
    255, 91, 30, 64, 255, 205, 52, 64, 1, 76, 185, 63, 0, 226, 2, 64, 0, 142,
    35, 64, 0, 172, 47, 64, 4, 88, 192, 63, 255, 5, 10, 64, 255, 207, 41, 64, 2,
    46, 54, 64, 1, 20, 219, 63, 2, 216, 248, 63, 0, 28, 18, 64, 0, 228, 43, 64,
    1, 248, 191, 63, 2, 12, 239, 63, 254, 211, 21, 64, 254, 11, 37, 64, 255, 91,
    245, 63, 1, 166, 8, 64, 255, 93, 36, 64, 1, 222, 52, 64, 1, 192, 191, 63, 3,
    8, 219, 63, 2, 74, 14, 64, 0, 84, 48, 64, 2, 132, 231, 63, 3, 176, 255, 63,
    1, 106, 16, 64, 0, 140, 48, 64, 0, 170, 5, 64, 0, 198, 25, 64, 254, 181, 42,
    64, 1, 250, 56, 64, 0, 196, 197, 63, 1, 220, 232, 63, 1, 190, 20, 64, 255,
    89, 44, 64, 4, 228, 229, 63, 255, 205, 0, 64, 255, 93, 32, 64, 0, 60, 50,
    64, 0, 172, 228, 63, 0, 172, 2, 64, 255, 149, 18, 64, 1, 166, 41, 64, 252,
    23, 248, 63, 255, 235, 16, 64, 1, 220, 33, 64, 2, 244, 50, 64, 169, 19, 208,
    59, 169, 19, 80, 59, 169, 19, 208, 58, 169, 19, 80, 58, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  "i8",
  ALLOC_NONE,
  Runtime.GLOBAL_BASE
);
let tempDoublePtr = Runtime.alignMemory(allocate(12, "i8", ALLOC_STATIC), 8);
assert(tempDoublePtr % 8 == 0);
function copyTempFloat(ptr) {
  HEAP8[tempDoublePtr] = HEAP8[ptr];
  HEAP8[tempDoublePtr + 1] = HEAP8[ptr + 1];
  HEAP8[tempDoublePtr + 2] = HEAP8[ptr + 2];
  HEAP8[tempDoublePtr + 3] = HEAP8[ptr + 3];
}
function copyTempDouble(ptr) {
  HEAP8[tempDoublePtr] = HEAP8[ptr];
  HEAP8[tempDoublePtr + 1] = HEAP8[ptr + 1];
  HEAP8[tempDoublePtr + 2] = HEAP8[ptr + 2];
  HEAP8[tempDoublePtr + 3] = HEAP8[ptr + 3];
  HEAP8[tempDoublePtr + 4] = HEAP8[ptr + 4];
  HEAP8[tempDoublePtr + 5] = HEAP8[ptr + 5];
  HEAP8[tempDoublePtr + 6] = HEAP8[ptr + 6];
  HEAP8[tempDoublePtr + 7] = HEAP8[ptr + 7];
}
Module._memset = _memset;
let ___errno_state = 0;
function ___setErrNo(value) {
  HEAP32[___errno_state >> 2] = value;
  return value;
}
let ERRNO_CODES = {
  EPERM: 1,
  ENOENT: 2,
  ESRCH: 3,
  EINTR: 4,
  EIO: 5,
  ENXIO: 6,
  E2BIG: 7,
  ENOEXEC: 8,
  EBADF: 9,
  ECHILD: 10,
  EAGAIN: 11,
  EWOULDBLOCK: 11,
  ENOMEM: 12,
  EACCES: 13,
  EFAULT: 14,
  ENOTBLK: 15,
  EBUSY: 16,
  EEXIST: 17,
  EXDEV: 18,
  ENODEV: 19,
  ENOTDIR: 20,
  EISDIR: 21,
  EINVAL: 22,
  ENFILE: 23,
  EMFILE: 24,
  ENOTTY: 25,
  ETXTBSY: 26,
  EFBIG: 27,
  ENOSPC: 28,
  ESPIPE: 29,
  EROFS: 30,
  EMLINK: 31,
  EPIPE: 32,
  EDOM: 33,
  ERANGE: 34,
  ENOMSG: 42,
  EIDRM: 43,
  ECHRNG: 44,
  EL2NSYNC: 45,
  EL3HLT: 46,
  EL3RST: 47,
  ELNRNG: 48,
  EUNATCH: 49,
  ENOCSI: 50,
  EL2HLT: 51,
  EDEADLK: 35,
  ENOLCK: 37,
  EBADE: 52,
  EBADR: 53,
  EXFULL: 54,
  ENOANO: 55,
  EBADRQC: 56,
  EBADSLT: 57,
  EDEADLOCK: 35,
  EBFONT: 59,
  ENOSTR: 60,
  ENODATA: 61,
  ETIME: 62,
  ENOSR: 63,
  ENONET: 64,
  ENOPKG: 65,
  EREMOTE: 66,
  ENOLINK: 67,
  EADV: 68,
  ESRMNT: 69,
  ECOMM: 70,
  EPROTO: 71,
  EMULTIHOP: 72,
  EDOTDOT: 73,
  EBADMSG: 74,
  ENOTUNIQ: 76,
  EBADFD: 77,
  EREMCHG: 78,
  ELIBACC: 79,
  ELIBBAD: 80,
  ELIBSCN: 81,
  ELIBMAX: 82,
  ELIBEXEC: 83,
  ENOSYS: 38,
  ENOTEMPTY: 39,
  ENAMETOOLONG: 36,
  ELOOP: 40,
  EOPNOTSUPP: 95,
  EPFNOSUPPORT: 96,
  ECONNRESET: 104,
  ENOBUFS: 105,
  EAFNOSUPPORT: 97,
  EPROTOTYPE: 91,
  ENOTSOCK: 88,
  ENOPROTOOPT: 92,
  ESHUTDOWN: 108,
  ECONNREFUSED: 111,
  EADDRINUSE: 98,
  ECONNABORTED: 103,
  ENETUNREACH: 101,
  ENETDOWN: 100,
  ETIMEDOUT: 110,
  EHOSTDOWN: 112,
  EHOSTUNREACH: 113,
  EINPROGRESS: 115,
  EALREADY: 114,
  EDESTADDRREQ: 89,
  EMSGSIZE: 90,
  EPROTONOSUPPORT: 93,
  ESOCKTNOSUPPORT: 94,
  EADDRNOTAVAIL: 99,
  ENETRESET: 102,
  EISCONN: 106,
  ENOTCONN: 107,
  ETOOMANYREFS: 109,
  EUSERS: 87,
  EDQUOT: 122,
  ESTALE: 116,
  ENOTSUP: 95,
  ENOMEDIUM: 123,
  EILSEQ: 84,
  EOVERFLOW: 75,
  ECANCELED: 125,
  ENOTRECOVERABLE: 131,
  EOWNERDEAD: 130,
  ESTRPIPE: 86,
};
function _sysconf(name) {
  switch (name) {
    case 30:
      return PAGE_SIZE;
    case 132:
    case 133:
    case 12:
    case 137:
    case 138:
    case 15:
    case 235:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 149:
    case 13:
    case 10:
    case 236:
    case 153:
    case 9:
    case 21:
    case 22:
    case 159:
    case 154:
    case 14:
    case 77:
    case 78:
    case 139:
    case 80:
    case 81:
    case 79:
    case 82:
    case 68:
    case 67:
    case 164:
    case 11:
    case 29:
    case 47:
    case 48:
    case 95:
    case 52:
    case 51:
    case 46:
      return 200809;
    case 27:
    case 246:
    case 127:
    case 128:
    case 23:
    case 24:
    case 160:
    case 161:
    case 181:
    case 182:
    case 242:
    case 183:
    case 184:
    case 243:
    case 244:
    case 245:
    case 165:
    case 178:
    case 179:
    case 49:
    case 50:
    case 168:
    case 169:
    case 175:
    case 170:
    case 171:
    case 172:
    case 97:
    case 76:
    case 32:
    case 173:
    case 35:
      return -1;
    case 176:
    case 177:
    case 7:
    case 155:
    case 8:
    case 157:
    case 125:
    case 126:
    case 92:
    case 93:
    case 129:
    case 130:
    case 131:
    case 94:
    case 91:
      return 1;
    case 74:
    case 60:
    case 69:
    case 70:
    case 4:
      return 1024;
    case 31:
    case 42:
    case 72:
      return 32;
    case 87:
    case 26:
    case 33:
      return 2147483647;
    case 34:
    case 1:
      return 47839;
    case 38:
    case 36:
      return 99;
    case 43:
    case 37:
      return 2048;
    case 0:
      return 2097152;
    case 3:
      return 65536;
    case 28:
      return 32768;
    case 44:
      return 32767;
    case 75:
      return 16384;
    case 39:
      return 1e3;
    case 89:
      return 700;
    case 71:
      return 256;
    case 40:
      return 255;
    case 2:
      return 100;
    case 180:
      return 64;
    case 25:
      return 20;
    case 5:
      return 16;
    case 6:
      return 6;
    case 73:
      return 4;
    case 84: {
      if (typeof navigator === "object")
        return navigator.hardwareConcurrency || 1;
      return 1;
    }
  }
  ___setErrNo(ERRNO_CODES.EINVAL);
  return -1;
}
let _BDtoIHigh = true;
let _BDtoILow = true;
function _abort() {
  Module.abort();
}
Module._strlen = _strlen;
function _emscripten_memcpy_big(dest, src, num) {
  HEAPU8.set(HEAPU8.subarray(src, src + num), dest);
  return dest;
}
Module._memcpy = _memcpy;
let _cos = Math_cos;
let _llvm_pow_f64 = Math_pow;
let _fabs = Math_abs;
Module._memmove = _memmove;
function ___errno_location() {
  return ___errno_state;
}
let _BItoD = true;
let _sqrt = Math_sqrt;
function _sbrk(bytes) {
  let self = _sbrk;
  if (!self.called) {
    DYNAMICTOP = alignMemoryPage(DYNAMICTOP);
    self.called = true;
    assert(Runtime.dynamicAlloc);
    self.alloc = Runtime.dynamicAlloc;
    Runtime.dynamicAlloc = function () {
      abort("cannot dynamically allocate, sbrk now has control");
    };
  }
  let ret = DYNAMICTOP;
  if (bytes != 0) {
    let success = self.alloc(bytes);
    if (!success) return -1 >>> 0;
  }
  return ret;
}
function _time(ptr) {
  let ret = (Date.now() / 1e3) | 0;
  if (ptr) {
    HEAP32[ptr >> 2] = ret;
  }
  return ret;
}
function __exit(status) {
  Module.exit(status);
}
function _exit(status) {
  __exit(status);
}
___errno_state = Runtime.staticAlloc(4);
HEAP32[___errno_state >> 2] = 0;
STACK_BASE = STACKTOP = Runtime.alignMemory(STATICTOP);
staticSealed = true;
STACK_MAX = STACK_BASE + TOTAL_STACK;
DYNAMIC_BASE = DYNAMICTOP = Runtime.alignMemory(STACK_MAX);
assert(DYNAMIC_BASE < TOTAL_MEMORY, "TOTAL_MEMORY not big enough for stack");
Module.asmGlobalArg = {
  Math: Math,
  Int8Array: Int8Array,
  Int16Array: Int16Array,
  Int32Array: Int32Array,
  Uint8Array: Uint8Array,
  Uint16Array: Uint16Array,
  Uint32Array: Uint32Array,
  Float32Array: Float32Array,
  Float64Array: Float64Array,
  NaN: NaN,
  Infinity: Infinity,
};
Module.asmLibraryArg = {
  abort: abort,
  assert: assert,
  _fabs: _fabs,
  _llvm_pow_f64: _llvm_pow_f64,
  _cos: _cos,
  _sysconf: _sysconf,
  _abort: _abort,
  ___setErrNo: ___setErrNo,
  _sbrk: _sbrk,
  _time: _time,
  _emscripten_memcpy_big: _emscripten_memcpy_big,
  _sqrt: _sqrt,
  _exit: _exit,
  __exit: __exit,
  ___errno_location: ___errno_location,
  STACKTOP: STACKTOP,
  STACK_MAX: STACK_MAX,
  tempDoublePtr: tempDoublePtr,
  ABORT: ABORT,
}; // EMSCRIPTEN_START_ASM
let asm = (function (global, env, buffer) {
  "use asm";
  let a = new global.Int8Array(buffer);
  let b = new global.Int16Array(buffer);
  let c = new global.Int32Array(buffer);
  let d = new global.Uint8Array(buffer);
  let e = new global.Uint16Array(buffer);
  let f = new global.Uint32Array(buffer);
  let g = new global.Float32Array(buffer);
  let h = new global.Float64Array(buffer);
  let i = env.STACKTOP | 0;
  let j = env.STACK_MAX | 0;
  let k = env.tempDoublePtr | 0;
  let l = env.ABORT | 0;
  let m = 0;
  let n = 0;
  let o = 0;
  let p = 0;
  let q = global.NaN;
  var r = global.Infinity;
  let s = 0;
  var t = 0;
  var u = 0;
  var v = 0;
  var w = 0.0;
  var x = 0;
  var y = 0;
  var z = 0;
  var A = 0.0;
  let B = 0;
  let C = 0;
  let D = 0;
  let E = 0;
  let F = 0;
  let G = 0;
  let H = 0;
  let I = 0;
  let J = 0;
  let K = 0;
  let L = global.Math.floor;
  let M = global.Math.abs;
  let N = global.Math.sqrt;
  let O = global.Math.pow;
  let P = global.Math.cos;
  let Q = global.Math.sin;
  let R = global.Math.tan;
  let S = global.Math.acos;
  let T = global.Math.asin;
  let U = global.Math.atan;
  let V = global.Math.atan2;
  let W = global.Math.exp;
  let X = global.Math.log;
  let Y = global.Math.ceil;
  let Z = global.Math.imul;
  let _ = global.Math.min;
  let $ = global.Math.clz32;
  let aa = env.abort;
  let ba = env.assert;
  let ca = env._fabs;
  let da = env._llvm_pow_f64;
  let ea = env._cos;
  let fa = env._sysconf;
  let ga = env._abort;
  let ha = env.___setErrNo;
  let ia = env._sbrk;
  let ja = env._time;
  let ka = env._emscripten_memcpy_big;
  let la = env._sqrt;
  let ma = env._exit;
  let na = env.__exit;
  let oa = env.___errno_location;
  let pa = 0.0;
  // EMSCRIPTEN_START_FUNCS
  function qa(a) {
    a |= 0;
    let b = 0;
    b = i;
    i = (i + a) | 0;
    i = (i + 15) & -16;
    return b | 0;
  }
  function ra() {
    return i | 0;
  }
  function sa(a) {
    a |= 0;
    i = a;
  }
  function ta(a, b) {
    a |= 0;
    b |= 0;
    if (!m) {
      m = a;
      n = b;
    }
  }
  function ua(b) {
    b |= 0;
    a[k >> 0] = a[b >> 0];
    a[(k + 1) >> 0] = a[(b + 1) >> 0];
    a[(k + 2) >> 0] = a[(b + 2) >> 0];
    a[(k + 3) >> 0] = a[(b + 3) >> 0];
  }
  function va(b) {
    b |= 0;
    a[k >> 0] = a[b >> 0];
    a[(k + 1) >> 0] = a[(b + 1) >> 0];
    a[(k + 2) >> 0] = a[(b + 2) >> 0];
    a[(k + 3) >> 0] = a[(b + 3) >> 0];
    a[(k + 4) >> 0] = a[(b + 4) >> 0];
    a[(k + 5) >> 0] = a[(b + 5) >> 0];
    a[(k + 6) >> 0] = a[(b + 6) >> 0];
    a[(k + 7) >> 0] = a[(b + 7) >> 0];
  }
  function wa(a) {
    a |= 0;
    B = a;
  }
  function xa() {
    return B | 0;
  }
  function ya(a, b) {
    a |= 0;
    b |= 0;
    let d = 0;
    var e = 0;
    var f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    var n = 0;
    var o = 0;
    o = i;
    i = (i + 80) | 0;
    d = (o + 48) | 0;
    e = (o + 36) | 0;
    k = (o + 4) | 0;
    h = (o + 8) | 0;
    f = (o + 56) | 0;
    n = o;
    m = (o + 32) | 0;
    j = (o + 44) | 0;
    l = (o + 40) | 0;
    c[d >> 2] = a;
    c[e >> 2] = b;
    c[(h + 0) >> 2] = 0;
    c[(h + 4) >> 2] = 0;
    c[(h + 8) >> 2] = 0;
    c[(h + 12) >> 2] = 0;
    c[(h + 16) >> 2] = 0;
    c[(h + 20) >> 2] = 0;
    c[(f + 0) >> 2] = 0;
    c[(f + 4) >> 2] = 0;
    c[(f + 8) >> 2] = 0;
    c[(f + 12) >> 2] = 0;
    c[(f + 16) >> 2] = 0;
    c[(f + 20) >> 2] = 0;
    c[m >> 2] = 0;
    c[n >> 2] = c[e >> 2];
    c[j >> 2] = 0;
    while (1) {
      if ((c[j >> 2] | 0) >= 5) break;
      b = (h + (c[m >> 2] << 2)) | 0;
      g[b >> 2] =
        +g[b >> 2] +
        +g[(32 + (c[j >> 2] << 2)) >> 2] *
          +g[c[n >> 2] >> 2] *
          +g[c[n >> 2] >> 2];
      c[n >> 2] = (c[n >> 2] | 0) + 4;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    c[j >> 2] = 5;
    while (1) {
      if ((c[j >> 2] | 0) >= 40) break;
      b = (h + (c[m >> 2] << 2)) | 0;
      g[b >> 2] = +g[b >> 2] + +g[c[n >> 2] >> 2] * +g[c[n >> 2] >> 2];
      c[n >> 2] = (c[n >> 2] | 0) + 4;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    c[m >> 2] = 1;
    while (1) {
      if ((c[m >> 2] | 0) >= (((c[((c[d >> 2] | 0) + 8) >> 2] | 0) - 1) | 0))
        break;
      c[n >> 2] = (c[e >> 2] | 0) + (((c[m >> 2] | 0) * 40) << 2);
      c[j >> 2] = 0;
      while (1) {
        if ((c[j >> 2] | 0) >= 5) break;
        b = (h + (c[m >> 2] << 2)) | 0;
        g[b >> 2] =
          +g[b >> 2] +
          +g[(32 + (c[j >> 2] << 2)) >> 2] *
            +g[c[n >> 2] >> 2] *
            +g[c[n >> 2] >> 2];
        b = (f + (c[m >> 2] << 2)) | 0;
        g[b >> 2] = +g[b >> 2] + +g[c[n >> 2] >> 2] * +g[c[n >> 2] >> 2];
        c[n >> 2] = (c[n >> 2] | 0) + 4;
        c[j >> 2] = (c[j >> 2] | 0) + 1;
      }
      c[j >> 2] = 5;
      while (1) {
        if ((c[j >> 2] | 0) >= 35) break;
        b = (h + (c[m >> 2] << 2)) | 0;
        g[b >> 2] = +g[b >> 2] + +g[c[n >> 2] >> 2] * +g[c[n >> 2] >> 2];
        b = (f + (c[m >> 2] << 2)) | 0;
        g[b >> 2] = +g[b >> 2] + +g[c[n >> 2] >> 2] * +g[c[n >> 2] >> 2];
        c[n >> 2] = (c[n >> 2] | 0) + 4;
        c[j >> 2] = (c[j >> 2] | 0) + 1;
      }
      c[j >> 2] = 35;
      while (1) {
        if ((c[j >> 2] | 0) >= 40) break;
        b = (h + (c[m >> 2] << 2)) | 0;
        g[b >> 2] = +g[b >> 2] + +g[c[n >> 2] >> 2] * +g[c[n >> 2] >> 2];
        b = (f + (c[m >> 2] << 2)) | 0;
        g[b >> 2] =
          +g[b >> 2] +
          +g[(32 + ((40 - (c[j >> 2] | 0) - 1) << 2)) >> 2] *
            +g[c[n >> 2] >> 2] *
            +g[c[n >> 2] >> 2];
        c[n >> 2] = (c[n >> 2] | 0) + 4;
        c[j >> 2] = (c[j >> 2] | 0) + 1;
      }
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    c[m >> 2] = (c[((c[d >> 2] | 0) + 8) >> 2] | 0) - 1;
    c[n >> 2] = (c[e >> 2] | 0) + (((c[m >> 2] | 0) * 40) << 2);
    c[j >> 2] = 0;
    while (1) {
      if ((c[j >> 2] | 0) >= 35) break;
      b = (f + (c[m >> 2] << 2)) | 0;
      g[b >> 2] = +g[b >> 2] + +g[c[n >> 2] >> 2] * +g[c[n >> 2] >> 2];
      c[n >> 2] = (c[n >> 2] | 0) + 4;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    c[j >> 2] = 35;
    while (1) {
      if ((c[j >> 2] | 0) >= 40) break;
      b = (f + (c[m >> 2] << 2)) | 0;
      g[b >> 2] =
        +g[b >> 2] +
        +g[(32 + ((40 - (c[j >> 2] | 0) - 1) << 2)) >> 2] *
          +g[c[n >> 2] >> 2] *
          +g[c[n >> 2] >> 2];
      c[n >> 2] = (c[n >> 2] | 0) + 4;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    if ((c[c[d >> 2] >> 2] | 0) == 20) c[j >> 2] = 1;
    else c[j >> 2] = 0;
    g[k >> 2] =
      (+g[h >> 2] + +g[(f + 4) >> 2]) * +g[(8 + (c[j >> 2] << 2)) >> 2];
    c[l >> 2] = 1;
    c[m >> 2] = 2;
    while (1) {
      if ((c[m >> 2] | 0) >= (c[((c[d >> 2] | 0) + 8) >> 2] | 0)) break;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
      if (
        (+g[(h + (((c[m >> 2] | 0) - 1) << 2)) >> 2] +
          +g[(f + (c[m >> 2] << 2)) >> 2]) *
          +g[(8 + (c[j >> 2] << 2)) >> 2] >
        +g[k >> 2]
      ) {
        g[k >> 2] =
          (+g[(h + (((c[m >> 2] | 0) - 1) << 2)) >> 2] +
            +g[(f + (c[m >> 2] << 2)) >> 2]) *
          +g[(8 + (c[j >> 2] << 2)) >> 2];
        c[l >> 2] = c[m >> 2];
      }
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    i = o;
    return c[l >> 2] | 0;
  }
  function za(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = +e;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    h = i;
    i = (i + 64) | 0;
    k = (h + 56) | 0;
    o = (h + 52) | 0;
    n = (h + 48) | 0;
    m = (h + 44) | 0;
    l = (h + 40) | 0;
    j = h;
    c[k >> 2] = a;
    c[o >> 2] = b;
    c[n >> 2] = d;
    g[m >> 2] = e;
    c[l >> 2] = f;
    hb(j, c[o >> 2] | 0, c[n >> 2] | 0, +g[m >> 2], c[l >> 2] | 0);
    Bb(c[k >> 2] | 0, j);
    i = h;
  }
  function Aa(a, b, d) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    var e = 0,
      f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0;
    n = i;
    i = (i + 32) | 0;
    e = (n + 8) | 0;
    f = (n + 16) | 0;
    h = (n + 20) | 0;
    k = (n + 24) | 0;
    l = (n + 4) | 0;
    m = n;
    j = (n + 12) | 0;
    c[e >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[m >> 2] = 0;
    c[j >> 2] = 0;
    c[k >> 2] = 0;
    while (1) {
      if ((c[k >> 2] | 0) >= 3) break;
      c[l >> 2] = 0;
      while (1) {
        if ((c[l >> 2] | 0) >= (c[(2936 + (c[k >> 2] << 2)) >> 2] | 0)) break;
        a =
          ((c[j >> 2] | 0) +
            (Z(
              c[((c[f >> 2] | 0) + (c[k >> 2] << 2)) >> 2] | 0,
              c[(2936 + (c[k >> 2] << 2)) >> 2] | 0
            ) |
              0)) |
          0;
        g[((c[e >> 2] | 0) + (((c[m >> 2] | 0) + (c[l >> 2] | 0)) << 2)) >> 2] =
          +g[(5016 + ((a + (c[l >> 2] | 0)) << 2)) >> 2];
        c[l >> 2] = (c[l >> 2] | 0) + 1;
      }
      c[m >> 2] = (c[m >> 2] | 0) + (c[(2936 + (c[k >> 2] << 2)) >> 2] | 0);
      a =
        Z(
          c[(2952 + (c[k >> 2] << 2)) >> 2] | 0,
          c[(2936 + (c[k >> 2] << 2)) >> 2] | 0
        ) | 0;
      c[j >> 2] = (c[j >> 2] | 0) + a;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    a: do
      if ((c[h >> 2] | 0) > 1) {
        c[m >> 2] = 0;
        c[j >> 2] = 0;
        c[k >> 2] = 0;
        while (1) {
          if ((c[k >> 2] | 0) >= 3) break a;
          c[l >> 2] = 0;
          while (1) {
            if ((c[l >> 2] | 0) >= (c[(2936 + (c[k >> 2] << 2)) >> 2] | 0))
              break;
            a =
              ((c[j >> 2] | 0) +
                (Z(
                  c[((c[f >> 2] | 0) + ((3 + (c[k >> 2] | 0)) << 2)) >> 2] | 0,
                  c[(2936 + (c[k >> 2] << 2)) >> 2] | 0
                ) |
                  0)) |
              0;
            g[
              ((c[e >> 2] | 0) +
                ((10 + (c[m >> 2] | 0) + (c[l >> 2] | 0)) << 2)) >>
                2
            ] = +g[(5016 + ((a + (c[l >> 2] | 0)) << 2)) >> 2];
            c[l >> 2] = (c[l >> 2] | 0) + 1;
          }
          c[m >> 2] = (c[m >> 2] | 0) + (c[(2936 + (c[k >> 2] << 2)) >> 2] | 0);
          a =
            Z(
              c[(2952 + (c[k >> 2] << 2)) >> 2] | 0,
              c[(2936 + (c[k >> 2] << 2)) >> 2] | 0
            ) | 0;
          c[j >> 2] = (c[j >> 2] | 0) + a;
          c[k >> 2] = (c[k >> 2] | 0) + 1;
        }
      }
    while (0);
    i = n;
  }
  function Ba(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0;
    s = i;
    i = (i + 80) | 0;
    h = s;
    j = (s + 16) | 0;
    o = (s + 68) | 0;
    p = (s + 4) | 0;
    q = (s + 72) | 0;
    k = (s + 76) | 0;
    n = (s + 12) | 0;
    m = (s + 64) | 0;
    l = (s + 20) | 0;
    r = (s + 8) | 0;
    c[h >> 2] = a;
    c[j >> 2] = b;
    c[o >> 2] = d;
    c[p >> 2] = e;
    c[q >> 2] = f;
    c[r >> 2] = (c[o >> 2] | 0) + (c[p >> 2] << 2);
    c[m >> 2] = (c[p >> 2] | 0) + 1;
    a: do
      if ((c[c[q >> 2] >> 2] | 0) == 30) {
        za(l, ((c[q >> 2] | 0) + 76) | 0, c[o >> 2] | 0, 0.5, c[p >> 2] | 0);
        Nb(c[h >> 2] | 0, l | 0, (c[m >> 2] << 2) | 0) | 0;
        ib(c[j >> 2] | 0, l, 0.4221999943256378, c[m >> 2] | 0);
        c[n >> 2] = c[m >> 2];
        c[k >> 2] = 1;
        while (1) {
          if ((c[k >> 2] | 0) >= 6) break a;
          za(
            l,
            c[o >> 2] | 0,
            c[r >> 2] | 0,
            +g[(3008 + (c[k >> 2] << 2)) >> 2],
            c[p >> 2] | 0
          );
          Nb(
            ((c[h >> 2] | 0) + (c[n >> 2] << 2)) | 0,
            l | 0,
            (c[m >> 2] << 2) | 0
          ) | 0;
          ib(
            ((c[j >> 2] | 0) + (c[n >> 2] << 2)) | 0,
            l,
            0.4221999943256378,
            c[m >> 2] | 0
          );
          c[n >> 2] = (c[n >> 2] | 0) + (c[m >> 2] | 0);
          c[k >> 2] = (c[k >> 2] | 0) + 1;
        }
      } else {
        c[n >> 2] = 0;
        c[k >> 2] = 0;
        while (1) {
          if ((c[k >> 2] | 0) >= (c[((c[q >> 2] | 0) + 8) >> 2] | 0)) break a;
          za(
            l,
            ((c[q >> 2] | 0) + 76) | 0,
            c[o >> 2] | 0,
            +g[(3032 + (c[k >> 2] << 2)) >> 2],
            c[p >> 2] | 0
          );
          Nb(
            ((c[h >> 2] | 0) + (c[n >> 2] << 2)) | 0,
            l | 0,
            (c[m >> 2] << 2) | 0
          ) | 0;
          ib(
            ((c[j >> 2] | 0) + (c[n >> 2] << 2)) | 0,
            l,
            0.4221999943256378,
            c[m >> 2] | 0
          );
          c[n >> 2] = (c[n >> 2] | 0) + (c[m >> 2] | 0);
          c[k >> 2] = (c[k >> 2] | 0) + 1;
        }
      }
    while (0);
    h = ((c[q >> 2] | 0) + 76) | 0;
    if ((c[c[q >> 2] >> 2] | 0) == 30)
      Nb(h | 0, c[r >> 2] | 0, (c[p >> 2] << 2) | 0) | 0;
    else Nb(h | 0, c[o >> 2] | 0, (c[p >> 2] << 2) | 0) | 0;
    i = s;
  }
  function Ca(a, b, d) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    var e = 0,
      f = 0,
      g = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    n = i;
    i = (i + 1120) | 0;
    e = (n + 1104) | 0;
    o = (n + 1012) | 0;
    f = (n + 960) | 0;
    h = (n + 964) | 0;
    g = (n + 1108) | 0;
    m = n;
    j = (n + 968) | 0;
    k = (n + 1060) | 0;
    l = (n + 1016) | 0;
    c[e >> 2] = a;
    c[o >> 2] = b;
    c[f >> 2] = d;
    c[g >> 2] = 300 - (c[((c[f >> 2] | 0) + 4) >> 2] | 0);
    Nb(
      ((c[f >> 2] | 0) + 156 + (c[g >> 2] << 2)) | 0,
      c[o >> 2] | 0,
      (c[((c[f >> 2] | 0) + 4) >> 2] << 2) | 0
    ) | 0;
    c[h >> 2] = 0;
    while (1) {
      if ((c[h >> 2] | 0) >= (c[((c[f >> 2] | 0) + 24) >> 2] | 0)) break;
      c[g >> 2] = 60;
      b = ((c[f >> 2] | 0) + 156) | 0;
      if ((c[h >> 2] | 0) < (((c[((c[f >> 2] | 0) + 24) >> 2] | 0) - 1) | 0))
        fb(m, 3048, b, 240);
      else fb(m, 4008, (b + (c[g >> 2] << 2)) | 0, 240);
      eb(l, m, 240, 10);
      fb(l, l, 4968, 11);
      gb(j, m, l, 10);
      ib(k, j, 0.9024999737739563, 11);
      Ab(((c[e >> 2] | 0) + (((c[h >> 2] | 0) * 10) << 2)) | 0, k);
      c[h >> 2] = (c[h >> 2] | 0) + 1;
    }
    c[g >> 2] = 300 - (c[((c[f >> 2] | 0) + 4) >> 2] | 0);
    Ob(
      ((c[f >> 2] | 0) + 156) | 0,
      ((c[f >> 2] | 0) + 1356 + ((0 - (c[g >> 2] | 0)) << 2)) | 0,
      (c[g >> 2] << 2) | 0
    ) | 0;
    i = n;
  }
  function Da(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = +e;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    h = i;
    i = (i + 64) | 0;
    k = (h + 56) | 0;
    o = (h + 52) | 0;
    n = (h + 48) | 0;
    m = (h + 44) | 0;
    l = (h + 40) | 0;
    j = h;
    c[k >> 2] = a;
    c[o >> 2] = b;
    c[n >> 2] = d;
    g[m >> 2] = e;
    c[l >> 2] = f;
    hb(j, c[o >> 2] | 0, c[n >> 2] | 0, +g[m >> 2], c[l >> 2] | 0);
    Bb(c[k >> 2] | 0, j);
    i = h;
  }
  function Ea(a, b, d, e, f, h, j, k) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    var l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0;
    z = i;
    i = (i + 96) | 0;
    l = (z + 64) | 0;
    m = (z + 60) | 0;
    s = (z + 84) | 0;
    t = (z + 76) | 0;
    n = (z + 72) | 0;
    u = (z + 80) | 0;
    v = (z + 88) | 0;
    w = (z + 92) | 0;
    o = (z + 8) | 0;
    r = (z + 4) | 0;
    q = (z + 68) | 0;
    p = (z + 12) | 0;
    x = z;
    y = (z + 56) | 0;
    c[l >> 2] = a;
    c[m >> 2] = b;
    c[s >> 2] = d;
    c[t >> 2] = e;
    c[n >> 2] = f;
    c[u >> 2] = h;
    c[v >> 2] = j;
    c[w >> 2] = k;
    c[x >> 2] = (c[s >> 2] | 0) + (c[v >> 2] << 2);
    c[y >> 2] = (c[t >> 2] | 0) + (c[v >> 2] << 2);
    c[q >> 2] = (c[v >> 2] | 0) + 1;
    a: do
      if ((c[c[w >> 2] >> 2] | 0) == 30) {
        Da(p, c[u >> 2] | 0, c[t >> 2] | 0, 0.5, c[v >> 2] | 0);
        Nb(c[l >> 2] | 0, p | 0, (c[q >> 2] << 2) | 0) | 0;
        Da(p, c[n >> 2] | 0, c[s >> 2] | 0, 0.5, c[v >> 2] | 0);
        ib(c[m >> 2] | 0, p, 0.4221999943256378, c[q >> 2] | 0);
        c[r >> 2] = c[q >> 2];
        c[o >> 2] = 1;
        while (1) {
          if ((c[o >> 2] | 0) >= (c[((c[w >> 2] | 0) + 8) >> 2] | 0)) break a;
          Da(
            p,
            c[t >> 2] | 0,
            c[y >> 2] | 0,
            +g[(3008 + (c[o >> 2] << 2)) >> 2],
            c[v >> 2] | 0
          );
          Nb(
            ((c[l >> 2] | 0) + (c[r >> 2] << 2)) | 0,
            p | 0,
            (c[q >> 2] << 2) | 0
          ) | 0;
          Da(
            p,
            c[s >> 2] | 0,
            c[x >> 2] | 0,
            +g[(3008 + (c[o >> 2] << 2)) >> 2],
            c[v >> 2] | 0
          );
          ib(
            ((c[m >> 2] | 0) + (c[r >> 2] << 2)) | 0,
            p,
            0.4221999943256378,
            c[q >> 2] | 0
          );
          c[r >> 2] = (c[r >> 2] | 0) + (c[q >> 2] | 0);
          c[o >> 2] = (c[o >> 2] | 0) + 1;
        }
      } else {
        c[r >> 2] = 0;
        c[o >> 2] = 0;
        while (1) {
          if ((c[o >> 2] | 0) >= (c[((c[w >> 2] | 0) + 8) >> 2] | 0)) break a;
          Da(
            p,
            c[u >> 2] | 0,
            c[t >> 2] | 0,
            +g[(3032 + (c[o >> 2] << 2)) >> 2],
            c[v >> 2] | 0
          );
          Nb(
            ((c[l >> 2] | 0) + (c[r >> 2] << 2)) | 0,
            p | 0,
            (c[q >> 2] << 2) | 0
          ) | 0;
          Da(
            p,
            c[n >> 2] | 0,
            c[s >> 2] | 0,
            +g[(3032 + (c[o >> 2] << 2)) >> 2],
            c[v >> 2] | 0
          );
          ib(
            ((c[m >> 2] | 0) + (c[r >> 2] << 2)) | 0,
            p,
            0.4221999943256378,
            c[q >> 2] | 0
          );
          c[r >> 2] = (c[r >> 2] | 0) + (c[q >> 2] | 0);
          c[o >> 2] = (c[o >> 2] | 0) + 1;
        }
      }
    while (0);
    l = c[n >> 2] | 0;
    if ((c[c[w >> 2] >> 2] | 0) == 30) {
      Nb(l | 0, c[x >> 2] | 0, (c[v >> 2] << 2) | 0) | 0;
      Nb(c[u >> 2] | 0, c[y >> 2] | 0, (c[v >> 2] << 2) | 0) | 0;
    } else {
      Nb(l | 0, c[s >> 2] | 0, (c[v >> 2] << 2) | 0) | 0;
      Nb(c[u >> 2] | 0, c[t >> 2] | 0, (c[v >> 2] << 2) | 0) | 0;
    }
    i = z;
  }
  function Fa(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      g = 0,
      h = 0,
      j = 0,
      k = 0;
    j = i;
    i = (i + 16) | 0;
    f = (j + 12) | 0;
    g = (j + 8) | 0;
    h = (j + 4) | 0;
    k = j;
    c[f >> 2] = a;
    c[g >> 2] = b;
    c[h >> 2] = d;
    c[k >> 2] = e;
    kb(c[f >> 2] | 0, c[g >> 2] | 0, c[h >> 2] | 0, 5016, 3, 2936, 2952);
    if ((c[k >> 2] | 0) == 2)
      kb(
        ((c[f >> 2] | 0) + 40) | 0,
        ((c[g >> 2] | 0) + 12) | 0,
        ((c[h >> 2] | 0) + 40) | 0,
        5016,
        3,
        2936,
        2952
      );
    i = j;
  }
  function Ga(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var g = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0;
    g = i;
    i = (i + 192) | 0;
    m = (g + 80) | 0;
    l = (g + 168) | 0;
    o = (g + 84) | 0;
    p = (g + 172) | 0;
    h = (g + 180) | 0;
    k = g;
    j = (g + 88) | 0;
    n = (g + 176) | 0;
    c[m >> 2] = a;
    c[l >> 2] = b;
    c[o >> 2] = d;
    c[p >> 2] = e;
    c[h >> 2] = f;
    c[n >> 2] = 0;
    Ca(k, c[p >> 2] | 0, c[h >> 2] | 0);
    Fa(j, c[o >> 2] | 0, k, c[((c[h >> 2] | 0) + 24) >> 2] | 0);
    c[n >> 2] = mb(j, 10, c[((c[h >> 2] | 0) + 24) >> 2] | 0) | 0;
    Ea(
      c[m >> 2] | 0,
      c[l >> 2] | 0,
      k,
      j,
      ((c[h >> 2] | 0) + 76) | 0,
      ((c[h >> 2] | 0) + 116) | 0,
      10,
      c[h >> 2] | 0
    );
    i = g;
  }
  function Ha(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0;
    u = i;
    i = (i + 1456) | 0;
    v = (u + 760) | 0;
    h = (u + 752) | 0;
    j = (u + 60) | 0;
    k = (u + 56) | 0;
    l = (u + 748) | 0;
    p = (u + 756) | 0;
    s = (u + 768) | 0;
    r = (u + 48) | 0;
    q = (u + 4) | 0;
    n = (u + 64) | 0;
    m = (u + 52) | 0;
    o = (u + 744) | 0;
    t = u;
    c[v >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    c[k >> 2] = e;
    c[l >> 2] = f;
    g[p >> 2] = +g[(2200 + (c[v >> 2] << 2)) >> 2];
    g[p >> 2] = +O(10.0, +(+g[p >> 2])) / 4.5;
    b = (s + 0) | 0;
    a = (b + 40) | 0;
    do {
      c[b >> 2] = 0;
      b = (b + 4) | 0;
    } while ((b | 0) < (a | 0));
    b = (n + 0) | 0;
    a = (b + 40) | 0;
    do {
      c[b >> 2] = 0;
      b = (b + 4) | 0;
    } while ((b | 0) < (a | 0));
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= 10) break;
      g[(q + (c[o >> 2] << 2)) >> 2] =
        +g[((c[j >> 2] | 0) + ((10 - (c[o >> 2] | 0)) << 2)) >> 2];
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    g[(q + 40) >> 2] = +g[c[j >> 2] >> 2];
    c[r >> 2] = s + 40;
    c[m >> 2] = n + 40;
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= (c[l >> 2] | 0)) break;
      c[t >> 2] = (c[l >> 2] | 0) - 1 - (c[o >> 2] | 0);
      g[((c[r >> 2] | 0) + (c[o >> 2] << 2)) >> 2] =
        +g[p >> 2] *
        +g[(2168 + (c[((c[h >> 2] | 0) + (c[t >> 2] << 2)) >> 2] << 2)) >> 2];
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    Lb(((c[r >> 2] | 0) + (c[l >> 2] << 2)) | 0, 0, (c[l >> 2] << 2) | 0) | 0;
    $a(c[r >> 2] | 0, q, c[j >> 2] | 0, c[l >> 2] << 1, 10, c[m >> 2] | 0);
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= (c[l >> 2] | 0)) break;
      g[((c[k >> 2] | 0) + (c[o >> 2] << 2)) >> 2] =
        +g[
          ((c[m >> 2] | 0) + (((c[l >> 2] | 0) - 1 - (c[o >> 2] | 0)) << 2)) >>
            2
        ] +
        +g[
          ((c[m >> 2] | 0) + (((c[l >> 2] << 1) - 1 - (c[o >> 2] | 0)) << 2)) >>
            2
        ];
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    i = u;
  }
  function Ia(a, b, d, e, f, h, j) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    var k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0;
    x = i;
    i = (i + 336) | 0;
    l = (x + 316) | 0;
    m = (x + 308) | 0;
    n = (x + 292) | 0;
    o = (x + 288) | 0;
    p = (x + 304) | 0;
    q = (x + 312) | 0;
    r = (x + 320) | 0;
    u = (x + 280) | 0;
    k = (x + 8) | 0;
    v = (x + 296) | 0;
    w = (x + 284) | 0;
    t = (x + 300) | 0;
    s = x;
    c[l >> 2] = a;
    c[m >> 2] = b;
    c[n >> 2] = d;
    c[o >> 2] = e;
    c[p >> 2] = f;
    c[q >> 2] = h;
    c[r >> 2] = j;
    a = (k + 0) | 0;
    b = (a + 40) | 0;
    do {
      c[a >> 2] = 0;
      a = (a + 4) | 0;
    } while ((a | 0) < (b | 0));
    c[u >> 2] = k + 40;
    b = c[m >> 2] | 0;
    a = c[o >> 2] | 0;
    if (c[r >> 2] | 0) Za(b, a, 40, 10);
    else Za(b, a, ((c[((c[l >> 2] | 0) + 28) >> 2] | 0) - 40) | 0, 10);
    c[t >> 2] = 0;
    while (1) {
      if ((c[t >> 2] | 0) >= (c[q >> 2] | 0)) break;
      if (!(((c[r >> 2] | 0) != 0) & ((c[t >> 2] | 0) == 40))) {
        if (
          (c[r >> 2] | 0) == 0
            ? (c[t >> 2] | 0) ==
              (((c[((c[l >> 2] | 0) + 28) >> 2] | 0) - 40) | 0)
            : 0
        ) {
          c[n >> 2] = (c[n >> 2] | 0) + 44;
          c[o >> 2] = (c[o >> 2] | 0) + 44;
          Za(
            ((c[m >> 2] | 0) + (c[t >> 2] << 2)) | 0,
            c[o >> 2] | 0,
            ((c[q >> 2] | 0) - (c[t >> 2] | 0)) | 0,
            10
          );
        }
      } else {
        c[n >> 2] = (c[n >> 2] | 0) + 44;
        c[o >> 2] = (c[o >> 2] | 0) + 44;
        Za(
          ((c[m >> 2] | 0) + (c[t >> 2] << 2)) | 0,
          c[o >> 2] | 0,
          ((c[q >> 2] | 0) - (c[t >> 2] | 0)) | 0,
          10
        );
      }
      g[((c[u >> 2] | 0) + (c[t >> 2] << 2)) >> 2] = 0.0;
      Za(((c[u >> 2] | 0) + (c[t >> 2] << 2)) | 0, c[o >> 2] | 0, 1, 10);
      g[v >> 2] =
        +g[((c[m >> 2] | 0) + (c[t >> 2] << 2)) >> 2] -
        +g[((c[u >> 2] | 0) + (c[t >> 2] << 2)) >> 2];
      lb(w, s, +g[v >> 2], 2168, 8);
      c[((c[p >> 2] | 0) + (c[t >> 2] << 2)) >> 2] = c[s >> 2];
      g[((c[u >> 2] | 0) + (c[t >> 2] << 2)) >> 2] =
        +g[(2168 + (c[((c[p >> 2] | 0) + (c[t >> 2] << 2)) >> 2] << 2)) >> 2];
      Za(((c[u >> 2] | 0) + (c[t >> 2] << 2)) | 0, c[o >> 2] | 0, 1, 10);
      c[t >> 2] = (c[t >> 2] | 0) + 1;
    }
    i = x;
  }
  function Ja(a, b, d, e, f, h, j, k) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    var l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0;
    D = i;
    i = (i + 1120) | 0;
    l = (D + 572) | 0;
    m = (D + 564) | 0;
    n = (D + 1084) | 0;
    o = (D + 1100) | 0;
    p = (D + 552) | 0;
    q = (D + 1080) | 0;
    r = (D + 1088) | 0;
    s = (D + 1096) | 0;
    t = (D + 1104) | 0;
    x = (D + 1108) | 0;
    C = (D + 48) | 0;
    B = (D + 44) | 0;
    y = D;
    v = (D + 576) | 0;
    u = (D + 556) | 0;
    w = (D + 560) | 0;
    z = (D + 1092) | 0;
    A = (D + 568) | 0;
    c[l >> 2] = a;
    c[m >> 2] = b;
    c[n >> 2] = d;
    c[o >> 2] = e;
    c[p >> 2] = f;
    c[q >> 2] = h;
    c[r >> 2] = j;
    c[s >> 2] = k;
    f = (C + 0) | 0;
    a = (f + 40) | 0;
    do {
      c[f >> 2] = 0;
      f = (f + 4) | 0;
    } while ((f | 0) < (a | 0));
    f = (v + 0) | 0;
    a = (f + 40) | 0;
    do {
      c[f >> 2] = 0;
      f = (f + 4) | 0;
    } while ((f | 0) < (a | 0));
    c[w >> 2] = 0;
    while (1) {
      if ((c[w >> 2] | 0) >= 10) break;
      g[(y + (c[w >> 2] << 2)) >> 2] =
        +g[((c[n >> 2] | 0) + ((10 - (c[w >> 2] | 0)) << 2)) >> 2];
      c[w >> 2] = (c[w >> 2] | 0) + 1;
    }
    g[(y + 40) >> 2] = +g[c[n >> 2] >> 2];
    c[B >> 2] = C + 40;
    c[u >> 2] = v + 40;
    Nb(c[B >> 2] | 0, c[m >> 2] | 0, (c[r >> 2] << 2) | 0) | 0;
    Lb(((c[B >> 2] | 0) + (c[r >> 2] << 2)) | 0, 0, (c[r >> 2] << 2) | 0) | 0;
    $a(c[B >> 2] | 0, y, c[n >> 2] | 0, c[r >> 2] << 1, 10, c[u >> 2] | 0);
    c[w >> 2] = 0;
    while (1) {
      if ((c[w >> 2] | 0) >= (c[r >> 2] | 0)) break;
      C = ((c[u >> 2] | 0) + (c[w >> 2] << 2)) | 0;
      g[C >> 2] =
        +g[C >> 2] +
        +g[((c[u >> 2] | 0) + (((c[w >> 2] | 0) + (c[r >> 2] | 0)) << 2)) >> 2];
      c[w >> 2] = (c[w >> 2] | 0) + 1;
    }
    g[x >> 2] = +g[c[u >> 2] >> 2];
    c[w >> 2] = 1;
    while (1) {
      if ((c[w >> 2] | 0) >= (c[r >> 2] | 0)) break;
      if (
        +g[((c[u >> 2] | 0) + (c[w >> 2] << 2)) >> 2] *
          +g[((c[u >> 2] | 0) + (c[w >> 2] << 2)) >> 2] >
        +g[x >> 2] * +g[x >> 2]
      )
        g[x >> 2] = +g[((c[u >> 2] | 0) + (c[w >> 2] << 2)) >> 2];
      c[w >> 2] = (c[w >> 2] | 0) + 1;
    }
    g[x >> 2] = +M(+(+g[x >> 2]));
    if (+g[x >> 2] < 10.0) g[x >> 2] = 10.0;
    g[x >> 2] = +Jb(+g[x >> 2]);
    lb(t, c[p >> 2] | 0, +g[x >> 2], 2200, 64);
    g[x >> 2] = +g[(2200 + (c[c[p >> 2] >> 2] << 2)) >> 2];
    g[z >> 2] = +O(10.0, +(+g[x >> 2]));
    g[A >> 2] = 4.5 / +g[z >> 2];
    c[w >> 2] = 0;
    while (1) {
      if ((c[w >> 2] | 0) >= (c[r >> 2] | 0)) break;
      C = ((c[u >> 2] | 0) + (c[w >> 2] << 2)) | 0;
      g[C >> 2] = +g[C >> 2] * +g[A >> 2];
      c[w >> 2] = (c[w >> 2] | 0) + 1;
    }
    Ia(
      c[l >> 2] | 0,
      c[u >> 2] | 0,
      c[n >> 2] | 0,
      c[o >> 2] | 0,
      c[q >> 2] | 0,
      c[r >> 2] | 0,
      c[s >> 2] | 0
    );
    i = D;
  }
  function Ka(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0.0;
    s = i;
    i = (i + 48) | 0;
    q = (s + 36) | 0;
    j = (s + 16) | 0;
    r = s;
    t = (s + 28) | 0;
    h = (s + 32) | 0;
    k = (s + 40) | 0;
    l = (s + 12) | 0;
    p = (s + 24) | 0;
    n = (s + 20) | 0;
    o = (s + 8) | 0;
    m = (s + 4) | 0;
    c[q >> 2] = a;
    c[j >> 2] = b;
    c[r >> 2] = d;
    c[t >> 2] = e;
    c[h >> 2] = f;
    c[p >> 2] = c[t >> 2];
    c[k >> 2] = 0;
    while (1) {
      if ((c[k >> 2] | 0) >= 10) break;
      c[n >> 2] = (c[q >> 2] | 0) + (c[k >> 2] << 2);
      c[o >> 2] = (c[h >> 2] | 0) + 36;
      c[m >> 2] = c[j >> 2];
      g[c[p >> 2] >> 2] = 0.0;
      c[l >> 2] = 0;
      while (1) {
        if ((c[l >> 2] | 0) > (c[k >> 2] | 0)) break;
        f = c[m >> 2] | 0;
        c[m >> 2] = f + 4;
        u = +g[f >> 2];
        f = c[n >> 2] | 0;
        c[n >> 2] = f + -4;
        t = c[p >> 2] | 0;
        g[t >> 2] = +g[t >> 2] + u * +g[f >> 2];
        c[l >> 2] = (c[l >> 2] | 0) + 1;
      }
      c[l >> 2] = (c[k >> 2] | 0) + 1;
      while (1) {
        if ((c[l >> 2] | 0) >= 11) break;
        f = c[m >> 2] | 0;
        c[m >> 2] = f + 4;
        u = +g[f >> 2];
        f = c[o >> 2] | 0;
        c[o >> 2] = f + -4;
        t = c[p >> 2] | 0;
        g[t >> 2] = +g[t >> 2] + u * +g[f >> 2];
        c[l >> 2] = (c[l >> 2] | 0) + 1;
      }
      c[p >> 2] = (c[p >> 2] | 0) + 4;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    c[k >> 2] = 10;
    while (1) {
      if ((c[k >> 2] | 0) >= (c[r >> 2] | 0)) break;
      c[n >> 2] = (c[q >> 2] | 0) + (c[k >> 2] << 2);
      c[m >> 2] = c[j >> 2];
      g[c[p >> 2] >> 2] = 0.0;
      c[l >> 2] = 0;
      while (1) {
        if ((c[l >> 2] | 0) >= 11) break;
        o = c[m >> 2] | 0;
        c[m >> 2] = o + 4;
        u = +g[o >> 2];
        o = c[n >> 2] | 0;
        c[n >> 2] = o + -4;
        t = c[p >> 2] | 0;
        g[t >> 2] = +g[t >> 2] + u * +g[o >> 2];
        c[l >> 2] = (c[l >> 2] | 0) + 1;
      }
      c[p >> 2] = (c[p >> 2] | 0) + 4;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    j = ((c[h >> 2] | 0) + 0) | 0;
    a = ((c[q >> 2] | 0) + (((c[r >> 2] | 0) - 10) << 2) + 0) | 0;
    h = (j + 40) | 0;
    do {
      c[j >> 2] = c[a >> 2];
      j = (j + 4) | 0;
      a = (a + 4) | 0;
    } while ((j | 0) < (h | 0));
    i = s;
  }
  function La(a, b, d) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    var e = 0,
      f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0.0;
    n = i;
    i = (i + 656) | 0;
    o = (n + 644) | 0;
    p = (n + 16) | 0;
    e = (n + 4) | 0;
    f = (n + 8) | 0;
    h = (n + 648) | 0;
    k = n;
    l = (n + 12) | 0;
    m = (n + 24) | 0;
    j = (n + 20) | 0;
    c[o >> 2] = a;
    c[p >> 2] = b;
    c[e >> 2] = d;
    c[(m + 0) >> 2] = 0;
    c[(m + 4) >> 2] = 0;
    c[(m + 8) >> 2] = 0;
    Nb((m + 12) | 0, c[p >> 2] | 0, (c[e >> 2] << 2) | 0) | 0;
    b = (m + (((c[e >> 2] | 0) + 4 - 1) << 2)) | 0;
    c[(b + 0) >> 2] = 0;
    c[(b + 4) >> 2] = 0;
    c[(b + 8) >> 2] = 0;
    c[(b + 12) >> 2] = 0;
    c[(b + 16) >> 2] = 0;
    c[j >> 2] = c[o >> 2];
    Lb(c[j >> 2] | 0, 0, (c[e >> 2] << 2) | 0) | 0;
    c[h >> 2] = 0;
    while (1) {
      if ((c[h >> 2] | 0) >= (c[e >> 2] | 0)) break;
      c[k >> 2] = m + (c[h >> 2] << 2);
      c[l >> 2] = 2564;
      c[f >> 2] = 0;
      while (1) {
        if ((c[f >> 2] | 0) >= 8) break;
        o = c[k >> 2] | 0;
        c[k >> 2] = o + 4;
        q = +g[o >> 2];
        o = c[l >> 2] | 0;
        c[l >> 2] = o + -4;
        p = c[j >> 2] | 0;
        g[p >> 2] = +g[p >> 2] + q * +g[o >> 2];
        c[f >> 2] = (c[f >> 2] | 0) + 1;
      }
      c[j >> 2] = (c[j >> 2] | 0) + 4;
      c[h >> 2] = (c[h >> 2] | 0) + 1;
    }
    i = n;
  }
  function Ma(a, b, d, e, f, h, j, k, l, m, n) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    l = l | 0;
    m = m | 0;
    n = n | 0;
    var o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0,
      I = 0,
      J = 0,
      K = 0,
      L = 0,
      N = 0,
      O = 0,
      P = 0.0;
    O = i;
    i = (i + 112) | 0;
    o = (O + 92) | 0;
    p = (O + 84) | 0;
    r = (O + 40) | 0;
    s = (O + 44) | 0;
    t = O;
    u = (O + 16) | 0;
    v = (O + 20) | 0;
    w = (O + 24) | 0;
    x = (O + 28) | 0;
    y = (O + 72) | 0;
    q = (O + 80) | 0;
    C = (O + 88) | 0;
    D = (O + 96) | 0;
    E = (O + 48) | 0;
    L = (O + 52) | 0;
    H = (O + 56) | 0;
    K = (O + 60) | 0;
    J = (O + 8) | 0;
    I = (O + 64) | 0;
    A = (O + 12) | 0;
    z = (O + 32) | 0;
    N = (O + 68) | 0;
    F = (O + 36) | 0;
    G = (O + 76) | 0;
    B = (O + 4) | 0;
    c[o >> 2] = a;
    c[p >> 2] = b;
    c[r >> 2] = d;
    c[s >> 2] = e;
    c[t >> 2] = f;
    c[u >> 2] = h;
    c[v >> 2] = j;
    c[w >> 2] = k;
    c[x >> 2] = l;
    c[y >> 2] = m;
    c[q >> 2] = n;
    g[G >> 2] = 0.0;
    c[H >> 2] = (c[u >> 2] | 0) + ((0 - (c[o >> 2] | 0)) << 2) + 4;
    c[E >> 2] = 0;
    while (1) {
      if ((c[E >> 2] | 0) >= (((c[o >> 2] | 0) - 5) | 0)) break;
      g[G >> 2] = +g[G >> 2] + +g[c[H >> 2] >> 2] * +g[c[H >> 2] >> 2];
      c[H >> 2] = (c[H >> 2] | 0) + 4;
      c[E >> 2] = (c[E >> 2] | 0) + 1;
    }
    c[I >> 2] = (c[u >> 2] | 0) + ((0 - (c[o >> 2] | 0)) << 2);
    c[C >> 2] = c[o >> 2];
    while (1) {
      if ((c[C >> 2] | 0) > (c[p >> 2] | 0)) break;
      c[L >> 2] = (c[s >> 2] | 0) + (c[C >> 2] | 0) - 20;
      c[D >> 2] = (c[C >> 2] | 0) - 4;
      g[G >> 2] = +g[G >> 2] + +g[c[I >> 2] >> 2] * +g[c[I >> 2] >> 2];
      c[I >> 2] = (c[I >> 2] | 0) + -4;
      g[((c[y >> 2] | 0) + (c[L >> 2] << 2)) >> 2] = +g[G >> 2];
      g[A >> 2] = 0.0;
      c[H >> 2] = (c[u >> 2] | 0) + ((0 - (c[C >> 2] | 0)) << 2);
      c[E >> 2] = 0;
      while (1) {
        if ((c[E >> 2] | 0) >= (c[D >> 2] | 0)) break;
        P = +g[((c[t >> 2] | 0) + (c[E >> 2] << 2)) >> 2];
        f = c[H >> 2] | 0;
        c[H >> 2] = f + 4;
        g[A >> 2] = +g[A >> 2] + P * +g[f >> 2];
        c[E >> 2] = (c[E >> 2] | 0) + 1;
      }
      g[z >> 2] = 0.20000000298023224;
      c[K >> 2] = (c[u >> 2] | 0) + -16;
      c[J >> 2] = (c[u >> 2] | 0) + ((0 - (c[C >> 2] | 0)) << 2) + -16;
      c[E >> 2] = c[D >> 2];
      while (1) {
        if ((c[E >> 2] | 0) >= (c[C >> 2] | 0)) break;
        g[N >> 2] =
          (1.0 - +g[z >> 2]) * +g[c[K >> 2] >> 2] +
          +g[z >> 2] * +g[c[J >> 2] >> 2];
        c[K >> 2] = (c[K >> 2] | 0) + 4;
        c[J >> 2] = (c[J >> 2] | 0) + 4;
        f = ((c[y >> 2] | 0) + (c[L >> 2] << 2)) | 0;
        g[f >> 2] = +g[f >> 2] + +g[N >> 2] * +g[N >> 2];
        g[A >> 2] =
          +g[A >> 2] +
          +g[((c[t >> 2] | 0) + (c[E >> 2] << 2)) >> 2] * +g[N >> 2];
        g[z >> 2] = +g[z >> 2] + 0.20000000298023224;
        c[E >> 2] = (c[E >> 2] | 0) + 1;
      }
      c[H >> 2] = (c[u >> 2] | 0) + ((0 - (c[C >> 2] | 0)) << 2);
      c[E >> 2] = c[C >> 2];
      while (1) {
        if ((c[E >> 2] | 0) >= 40) break;
        f = ((c[y >> 2] | 0) + (c[L >> 2] << 2)) | 0;
        g[f >> 2] = +g[f >> 2] + +g[c[H >> 2] >> 2] * +g[c[H >> 2] >> 2];
        P = +g[((c[t >> 2] | 0) + (c[E >> 2] << 2)) >> 2];
        f = c[H >> 2] | 0;
        c[H >> 2] = f + 4;
        g[A >> 2] = +g[A >> 2] + P * +g[f >> 2];
        c[E >> 2] = (c[E >> 2] | 0) + 1;
      }
      o = c[L >> 2] | 0;
      if (+g[((c[y >> 2] | 0) + (c[L >> 2] << 2)) >> 2] > 0.0)
        g[((c[q >> 2] | 0) + (c[L >> 2] << 2)) >> 2] =
          1.0 / (+g[((c[y >> 2] | 0) + (o << 2)) >> 2] + 2.220446049250313e-16);
      else g[((c[q >> 2] | 0) + (o << 2)) >> 2] = 0.0;
      if (
        !((c[r >> 2] | 0) == 0
          ? ((g[F >> 2] = -1.0e7), !(+g[A >> 2] > 0.0))
          : 0)
      )
        g[F >> 2] =
          +g[A >> 2] *
          +g[A >> 2] *
          +g[((c[q >> 2] | 0) + (c[L >> 2] << 2)) >> 2];
      g[B >> 2] = +g[A >> 2] * +g[((c[q >> 2] | 0) + (c[L >> 2] << 2)) >> 2];
      if (
        +g[F >> 2] > +g[c[v >> 2] >> 2]
          ? +M(+(+g[B >> 2])) < 1.2999999523162842
          : 0
      ) {
        c[c[w >> 2] >> 2] = c[L >> 2];
        g[c[v >> 2] >> 2] = +g[F >> 2];
        g[c[x >> 2] >> 2] = +g[B >> 2];
      }
      c[C >> 2] = (c[C >> 2] | 0) + 1;
    }
    i = O;
  }
  function Na(a, b, d) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    var e = 0,
      f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0;
    q = i;
    i = (i + 48) | 0;
    e = (q + 36) | 0;
    f = (q + 16) | 0;
    h = q;
    r = (q + 28) | 0;
    l = (q + 32) | 0;
    m = (q + 40) | 0;
    o = (q + 12) | 0;
    n = (q + 24) | 0;
    j = (q + 20) | 0;
    k = (q + 8) | 0;
    p = (q + 4) | 0;
    c[e >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[r >> 2] = (c[e >> 2] | 0) - 5;
    c[m >> 2] = (c[f >> 2] | 0) + ((0 - (c[e >> 2] | 0)) << 2);
    Nb(c[h >> 2] | 0, c[m >> 2] | 0, (c[e >> 2] << 2) | 0) | 0;
    g[k >> 2] = 0.20000000298023224;
    g[j >> 2] = 0.0;
    c[o >> 2] = (c[f >> 2] | 0) + -20;
    c[n >> 2] = (c[f >> 2] | 0) + ((0 - (c[e >> 2] | 0)) << 2) + -20;
    c[l >> 2] = c[r >> 2];
    while (1) {
      if ((c[l >> 2] | 0) >= (c[e >> 2] | 0)) break;
      g[p >> 2] =
        (1.0 - +g[j >> 2]) * +g[c[o >> 2] >> 2] +
        +g[j >> 2] * +g[c[n >> 2] >> 2];
      c[o >> 2] = (c[o >> 2] | 0) + 4;
      c[n >> 2] = (c[n >> 2] | 0) + 4;
      g[((c[h >> 2] | 0) + (c[l >> 2] << 2)) >> 2] = +g[p >> 2];
      g[j >> 2] = +g[j >> 2] + +g[k >> 2];
      c[l >> 2] = (c[l >> 2] | 0) + 1;
    }
    c[m >> 2] = (c[f >> 2] | 0) + ((0 - (c[e >> 2] | 0)) << 2);
    Nb(
      ((c[h >> 2] | 0) + (c[e >> 2] << 2)) | 0,
      c[m >> 2] | 0,
      ((40 - (c[e >> 2] | 0)) << 2) | 0
    ) | 0;
    i = q;
  }
  function Oa(a, b, d, e, f, h, j) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    var k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0.0,
      x = 0.0;
    v = i;
    i = (i + 48) | 0;
    k = (v + 36) | 0;
    l = (v + 16) | 0;
    m = v;
    n = (v + 28) | 0;
    o = (v + 32) | 0;
    p = (v + 40) | 0;
    q = (v + 12) | 0;
    u = (v + 24) | 0;
    r = (v + 20) | 0;
    s = (v + 8) | 0;
    t = (v + 4) | 0;
    c[k >> 2] = a;
    c[l >> 2] = b;
    c[m >> 2] = d;
    c[n >> 2] = e;
    c[o >> 2] = f;
    c[p >> 2] = h;
    c[q >> 2] = j;
    if ((((c[p >> 2] | 0) - (c[q >> 2] | 0) - (c[o >> 2] | 0)) | 0) < 0)
      c[q >> 2] = (c[p >> 2] | 0) - (c[o >> 2] | 0);
    g[r >> 2] = 0.0;
    g[s >> 2] = 0.0;
    g[t >> 2] = 0.0;
    c[u >> 2] = 0;
    while (1) {
      if ((c[u >> 2] | 0) >= (c[q >> 2] | 0)) break;
      g[r >> 2] =
        +g[r >> 2] +
        +g[
          ((c[n >> 2] | 0) +
            (((c[p >> 2] | 0) - (c[q >> 2] | 0) + (c[u >> 2] | 0)) << 2)) >>
            2
        ] *
          +g[
            ((c[n >> 2] | 0) +
              (((c[p >> 2] | 0) -
                (c[q >> 2] | 0) +
                (c[u >> 2] | 0) -
                (c[o >> 2] | 0)) <<
                2)) >>
              2
          ];
      g[s >> 2] =
        +g[s >> 2] +
        +g[
          ((c[n >> 2] | 0) +
            (((c[p >> 2] | 0) -
              (c[q >> 2] | 0) +
              (c[u >> 2] | 0) -
              (c[o >> 2] | 0)) <<
              2)) >>
            2
        ] *
          +g[
            ((c[n >> 2] | 0) +
              (((c[p >> 2] | 0) -
                (c[q >> 2] | 0) +
                (c[u >> 2] | 0) -
                (c[o >> 2] | 0)) <<
                2)) >>
              2
          ];
      g[t >> 2] =
        +g[t >> 2] +
        +g[
          ((c[n >> 2] | 0) +
            (((c[p >> 2] | 0) - (c[q >> 2] | 0) + (c[u >> 2] | 0)) << 2)) >>
            2
        ] *
          +g[
            ((c[n >> 2] | 0) +
              (((c[p >> 2] | 0) - (c[q >> 2] | 0) + (c[u >> 2] | 0)) << 2)) >>
              2
          ];
      c[u >> 2] = (c[u >> 2] | 0) + 1;
    }
    if (+g[s >> 2] > 0.0) {
      g[c[k >> 2] >> 2] = (+g[r >> 2] * +g[r >> 2]) / +g[s >> 2];
      x = +M(+(+g[r >> 2] / +g[s >> 2]));
      g[c[l >> 2] >> 2] = x;
      x = +M(+(+g[r >> 2]));
      w = +N(+(+g[s >> 2]));
      w = x / (w * +N(+(+g[t >> 2])));
      g[c[m >> 2] >> 2] = w;
    } else {
      g[c[k >> 2] >> 2] = 0.0;
      g[c[l >> 2] >> 2] = 0.0;
      g[c[m >> 2] >> 2] = 0.0;
    }
    i = v;
  }
  function Pa(a, b, d, e, f, h, j) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    var k = 0,
      l = 0,
      m = 0.0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0,
      I = 0;
    I = i;
    i = (i + 1056) | 0;
    C = (I + 1040) | 0;
    D = I;
    E = (I + 4) | 0;
    l = (I + 996) | 0;
    s = (I + 1032) | 0;
    k = (I + 16) | 0;
    F = (I + 20) | 0;
    G = (I + 24) | 0;
    y = (I + 1020) | 0;
    u = (I + 1028) | 0;
    p = (I + 1036) | 0;
    A = (I + 1044) | 0;
    o = (I + 1e3) | 0;
    q = (I + 1004) | 0;
    r = (I + 1008) | 0;
    H = (I + 8) | 0;
    v = (I + 1012) | 0;
    w = (I + 28) | 0;
    B = (I + 1016) | 0;
    n = (I + 12) | 0;
    z = (I + 32) | 0;
    x = (I + 1024) | 0;
    t = (I + 992) | 0;
    c[C >> 2] = a;
    c[D >> 2] = b;
    c[E >> 2] = d;
    c[l >> 2] = e;
    c[s >> 2] = f;
    c[k >> 2] = h;
    c[F >> 2] = j;
    c[G >> 2] = 20;
    if ((c[E >> 2] | 0) == 1) {
      s = ((c[F >> 2] | 0) + 124) | 0;
      c[s >> 2] = (c[s >> 2] | 0) + 1;
      a: do
        if ((c[((c[F >> 2] | 0) + 128) >> 2] | 0) != 1) {
          c[G >> 2] = (c[k >> 2] | 0) - 3;
          Oa(
            p,
            u,
            H,
            ((c[F >> 2] | 0) + 180) | 0,
            c[G >> 2] | 0,
            c[((c[F >> 2] | 0) + 4) >> 2] | 0,
            60
          );
          c[v >> 2] = (c[k >> 2] | 0) - 2;
          while (1) {
            if ((c[v >> 2] | 0) > (((c[k >> 2] | 0) + 3) | 0)) break a;
            Oa(
              q,
              o,
              r,
              ((c[F >> 2] | 0) + 180) | 0,
              c[v >> 2] | 0,
              c[((c[F >> 2] | 0) + 4) >> 2] | 0,
              60
            );
            if (+g[q >> 2] > +g[p >> 2]) {
              g[p >> 2] = +g[q >> 2];
              g[u >> 2] = +g[o >> 2];
              c[G >> 2] = c[v >> 2];
              g[H >> 2] = +g[r >> 2];
            }
            c[v >> 2] = (c[v >> 2] | 0) + 1;
          }
        } else {
          c[G >> 2] = c[((c[F >> 2] | 0) + 120) >> 2];
          g[H >> 2] = +g[((c[F >> 2] | 0) + 1140) >> 2];
        }
      while (0);
      g[A >> 2] = 1.0;
      do
        if (
          (Z(
            c[((c[F >> 2] | 0) + 124) >> 2] | 0,
            c[((c[F >> 2] | 0) + 4) >> 2] | 0
          ) |
            0) <=
          320
        ) {
          if (
            (Z(
              c[((c[F >> 2] | 0) + 124) >> 2] | 0,
              c[((c[F >> 2] | 0) + 4) >> 2] | 0
            ) |
              0) >
            640
          ) {
            g[A >> 2] = 0.699999988079071;
            break;
          }
          if (
            (Z(
              c[((c[F >> 2] | 0) + 124) >> 2] | 0,
              c[((c[F >> 2] | 0) + 4) >> 2] | 0
            ) |
              0) >
            960
          ) {
            g[A >> 2] = 0.5;
            break;
          }
          if (
            (Z(
              c[((c[F >> 2] | 0) + 124) >> 2] | 0,
              c[((c[F >> 2] | 0) + 4) >> 2] | 0
            ) |
              0) >
            1280
          )
            g[A >> 2] = 0.0;
        } else g[A >> 2] = 0.8999999761581421;
      while (0);
      g[n >> 2] = +N(+(+g[H >> 2]));
      do
        if (!(+g[n >> 2] > 0.699999988079071))
          if (+g[n >> 2] > 0.4000000059604645) {
            g[x >> 2] = (+g[n >> 2] - 0.4000000059604645) / 0.29999998211860657;
            break;
          } else {
            g[x >> 2] = 0.0;
            break;
          }
        else g[x >> 2] = 1.0;
      while (0);
      c[B >> 2] = c[G >> 2];
      if ((c[G >> 2] | 0) < 80) c[B >> 2] = c[G >> 2] << 1;
      g[t >> 2] = 0.0;
      c[v >> 2] = 0;
      while (1) {
        if ((c[v >> 2] | 0) >= (c[((c[F >> 2] | 0) + 4) >> 2] | 0)) break;
        c[((c[F >> 2] | 0) + 1144) >> 2] =
          ((((c[((c[F >> 2] | 0) + 1144) >> 2] | 0) * 69069) | 0) + 1) &
          2147483647;
        c[y >> 2] = 50 + ((c[((c[F >> 2] | 0) + 1144) >> 2] | 0) % 70 | 0);
        c[w >> 2] = (c[v >> 2] | 0) - (c[y >> 2] | 0);
        if ((c[w >> 2] | 0) < 0)
          g[(z + (c[v >> 2] << 2)) >> 2] =
            +g[
              ((c[F >> 2] | 0) +
                180 +
                (((c[((c[F >> 2] | 0) + 4) >> 2] | 0) + (c[w >> 2] | 0)) <<
                  2)) >>
                2
            ];
        else g[(z + (c[v >> 2] << 2)) >> 2] = +g[(z + (c[w >> 2] << 2)) >> 2];
        c[w >> 2] = (c[v >> 2] | 0) - (c[B >> 2] | 0);
        if ((c[w >> 2] | 0) < 0)
          g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] =
            +g[
              ((c[F >> 2] | 0) +
                180 +
                (((c[((c[F >> 2] | 0) + 4) >> 2] | 0) + (c[w >> 2] | 0)) <<
                  2)) >>
                2
            ];
        else
          g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] =
            +g[((c[C >> 2] | 0) + (c[w >> 2] << 2)) >> 2];
        do
          if ((c[v >> 2] | 0) >= 80) {
            m = +g[A >> 2];
            if ((c[v >> 2] | 0) < 160) {
              g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] =
                0.949999988079071 *
                m *
                (+g[x >> 2] * +g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] +
                  (1.0 - +g[x >> 2]) * +g[(z + (c[v >> 2] << 2)) >> 2]);
              break;
            } else {
              g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] =
                0.8999999761581421 *
                m *
                (+g[x >> 2] * +g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] +
                  (1.0 - +g[x >> 2]) * +g[(z + (c[v >> 2] << 2)) >> 2]);
              break;
            }
          } else
            g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] =
              +g[A >> 2] *
              (+g[x >> 2] * +g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] +
                (1.0 - +g[x >> 2]) * +g[(z + (c[v >> 2] << 2)) >> 2]);
        while (0);
        g[t >> 2] =
          +g[t >> 2] +
          +g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] *
            +g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2];
        c[v >> 2] = (c[v >> 2] | 0) + 1;
      }
      b: do
        if (+N(+(+g[t >> 2] / +(c[((c[F >> 2] | 0) + 4) >> 2] | 0))) < 30.0) {
          g[u >> 2] = 0.0;
          c[v >> 2] = 0;
          while (1) {
            if ((c[v >> 2] | 0) >= (c[((c[F >> 2] | 0) + 4) >> 2] | 0)) break b;
            g[((c[C >> 2] | 0) + (c[v >> 2] << 2)) >> 2] =
              +g[(z + (c[v >> 2] << 2)) >> 2];
            c[v >> 2] = (c[v >> 2] | 0) + 1;
          }
        }
      while (0);
      l = ((c[D >> 2] | 0) + 0) | 0;
      b = ((c[F >> 2] | 0) + 136) | 0;
      k = (l + 44) | 0;
      do {
        c[l >> 2] = c[b >> 2];
        l = (l + 4) | 0;
        b = (b + 4) | 0;
      } while ((l | 0) < (k | 0));
    } else {
      Nb(
        c[C >> 2] | 0,
        c[l >> 2] | 0,
        (c[((c[F >> 2] | 0) + 4) >> 2] << 2) | 0
      ) | 0;
      l = ((c[D >> 2] | 0) + 0) | 0;
      b = ((c[s >> 2] | 0) + 0) | 0;
      k = (l + 44) | 0;
      do {
        c[l >> 2] = c[b >> 2];
        l = (l + 4) | 0;
        b = (b + 4) | 0;
      } while ((l | 0) < (k | 0));
      c[((c[F >> 2] | 0) + 124) >> 2] = 0;
    }
    if (c[E >> 2] | 0) {
      c[((c[F >> 2] | 0) + 120) >> 2] = c[G >> 2];
      g[((c[F >> 2] | 0) + 1140) >> 2] = +g[H >> 2];
    }
    c[((c[F >> 2] | 0) + 128) >> 2] = c[E >> 2];
    l = ((c[F >> 2] | 0) + 136) | 0;
    b = ((c[D >> 2] | 0) + 0) | 0;
    k = (l + 44) | 0;
    do {
      c[l >> 2] = c[b >> 2];
      l = (l + 4) | 0;
      b = (b + 4) | 0;
    } while ((l | 0) < (k | 0));
    Nb(
      ((c[F >> 2] | 0) + 180) | 0,
      c[C >> 2] | 0,
      (c[((c[F >> 2] | 0) + 4) >> 2] << 2) | 0
    ) | 0;
    i = I;
  }
  function Qa(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = +d;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    o = i;
    i = (i + 32) | 0;
    f = (o + 8) | 0;
    h = (o + 16) | 0;
    j = (o + 20) | 0;
    k = (o + 24) | 0;
    n = (o + 4) | 0;
    l = o;
    m = (o + 12) | 0;
    c[f >> 2] = a;
    c[h >> 2] = b;
    g[j >> 2] = d;
    c[k >> 2] = e;
    g[m >> 2] = +g[c[h >> 2] >> 2] - +g[j >> 2];
    g[l >> 2] = +g[m >> 2] * +g[m >> 2];
    c[c[f >> 2] >> 2] = 0;
    c[n >> 2] = 1;
    while (1) {
      if ((c[n >> 2] | 0) >= (c[k >> 2] | 0)) break;
      g[m >> 2] = +g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] - +g[j >> 2];
      g[m >> 2] = +g[m >> 2] * +g[m >> 2];
      if (+g[m >> 2] < +g[l >> 2]) {
        g[l >> 2] = +g[m >> 2];
        c[c[f >> 2] >> 2] = c[n >> 2];
      }
      c[n >> 2] = (c[n >> 2] | 0) + 1;
    }
    i = o;
  }
  function Ra(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0;
    p = i;
    i = (i + 32) | 0;
    h = (p + 8) | 0;
    j = (p + 16) | 0;
    k = (p + 20) | 0;
    l = (p + 24) | 0;
    m = (p + 4) | 0;
    n = p;
    o = (p + 12) | 0;
    c[h >> 2] = a;
    c[j >> 2] = b;
    c[k >> 2] = d;
    c[l >> 2] = e;
    c[m >> 2] = f;
    c[n >> 2] = 0;
    while (1) {
      if ((c[n >> 2] | 0) > (((c[k >> 2] | 0) - (c[m >> 2] | 0)) | 0)) break;
      g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] = 0.0;
      c[o >> 2] = 0;
      while (1) {
        a = c[n >> 2] | 0;
        if ((c[o >> 2] | 0) >= (c[m >> 2] | 0)) break;
        e = ((c[h >> 2] | 0) + (c[n >> 2] << 2)) | 0;
        g[e >> 2] =
          +g[e >> 2] +
          +g[((c[j >> 2] | 0) + ((a + (c[o >> 2] | 0)) << 2)) >> 2] *
            +g[((c[l >> 2] | 0) + (c[o >> 2] << 2)) >> 2];
        c[o >> 2] = (c[o >> 2] | 0) + 1;
      }
      c[n >> 2] = a + 1;
    }
    i = p;
  }
  function Sa(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0.0;
    v = i;
    i = (i + 80) | 0;
    f = (v + 36) | 0;
    h = (v + 32) | 0;
    j = (v + 56) | 0;
    k = (v + 48) | 0;
    t = (v + 44) | 0;
    s = (v + 52) | 0;
    n = (v + 60) | 0;
    o = (v + 64) | 0;
    p = (v + 20) | 0;
    u = (v + 16) | 0;
    l = (v + 40) | 0;
    m = (v + 24) | 0;
    q = v;
    r = (v + 28) | 0;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    c[k >> 2] = e;
    c[l >> 2] = (c[k >> 2] << 1) + 1;
    a: do
      if ((c[l >> 2] | 0) > (c[j >> 2] | 0)) {
        c[m >> 2] = ((c[j >> 2] | 0) / 2) | 0;
        c[o >> 2] = 0;
        while (1) {
          if ((c[o >> 2] | 0) >= 4) break;
          a = (2792 + ((Z(c[o >> 2] | 0, c[l >> 2] | 0) | 0) << 2)) | 0;
          c[(q + (c[o >> 2] << 2)) >> 2] =
            a + (c[k >> 2] << 2) + ((0 - (c[m >> 2] | 0)) << 2);
          c[o >> 2] = (c[o >> 2] | 0) + 1;
        }
        c[k >> 2] = c[m >> 2];
        c[l >> 2] = (c[k >> 2] << 1) + 1;
      } else {
        c[o >> 2] = 0;
        while (1) {
          if ((c[o >> 2] | 0) >= 4) break a;
          a = (2792 + ((Z(c[o >> 2] | 0, c[l >> 2] | 0) | 0) << 2)) | 0;
          c[(q + (c[o >> 2] << 2)) >> 2] = a;
          c[o >> 2] = (c[o >> 2] | 0) + 1;
        }
      }
    while (0);
    c[t >> 2] = c[f >> 2];
    c[n >> 2] = c[k >> 2];
    while (1) {
      if ((c[n >> 2] | 0) >= (c[l >> 2] | 0)) break;
      c[o >> 2] = 0;
      while (1) {
        if ((c[o >> 2] | 0) >= 4) break;
        g[c[t >> 2] >> 2] = 0.0;
        c[r >> 2] = c[(q + (c[o >> 2] << 2)) >> 2];
        c[s >> 2] = (c[h >> 2] | 0) + (c[n >> 2] << 2);
        c[p >> 2] = 0;
        while (1) {
          if ((c[p >> 2] | 0) > (c[n >> 2] | 0)) break;
          b = c[s >> 2] | 0;
          c[s >> 2] = b + -4;
          w = +g[b >> 2];
          b = c[r >> 2] | 0;
          c[r >> 2] = b + 4;
          a = c[t >> 2] | 0;
          g[a >> 2] = +g[a >> 2] + w * +g[b >> 2];
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[t >> 2] = (c[t >> 2] | 0) + 4;
        c[o >> 2] = (c[o >> 2] | 0) + 1;
      }
      c[n >> 2] = (c[n >> 2] | 0) + 1;
    }
    c[n >> 2] = c[l >> 2];
    while (1) {
      if ((c[n >> 2] | 0) >= (c[j >> 2] | 0)) break;
      c[o >> 2] = 0;
      while (1) {
        if ((c[o >> 2] | 0) >= 4) break;
        g[c[t >> 2] >> 2] = 0.0;
        c[r >> 2] = c[(q + (c[o >> 2] << 2)) >> 2];
        c[s >> 2] = (c[h >> 2] | 0) + (c[n >> 2] << 2);
        c[p >> 2] = 0;
        while (1) {
          if ((c[p >> 2] | 0) >= (c[l >> 2] | 0)) break;
          b = c[s >> 2] | 0;
          c[s >> 2] = b + -4;
          w = +g[b >> 2];
          b = c[r >> 2] | 0;
          c[r >> 2] = b + 4;
          a = c[t >> 2] | 0;
          g[a >> 2] = +g[a >> 2] + w * +g[b >> 2];
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[t >> 2] = (c[t >> 2] | 0) + 4;
        c[o >> 2] = (c[o >> 2] | 0) + 1;
      }
      c[n >> 2] = (c[n >> 2] | 0) + 1;
    }
    c[u >> 2] = 1;
    while (1) {
      if ((c[u >> 2] | 0) > (c[k >> 2] | 0)) break;
      c[o >> 2] = 0;
      while (1) {
        if ((c[o >> 2] | 0) >= 4) break;
        g[c[t >> 2] >> 2] = 0.0;
        c[r >> 2] = (c[(q + (c[o >> 2] << 2)) >> 2] | 0) + (c[u >> 2] << 2);
        c[s >> 2] = (c[h >> 2] | 0) + (c[j >> 2] << 2) + -4;
        c[p >> 2] = 0;
        while (1) {
          if ((c[p >> 2] | 0) >= (((c[l >> 2] | 0) - (c[u >> 2] | 0)) | 0))
            break;
          b = c[s >> 2] | 0;
          c[s >> 2] = b + -4;
          w = +g[b >> 2];
          b = c[r >> 2] | 0;
          c[r >> 2] = b + 4;
          a = c[t >> 2] | 0;
          g[a >> 2] = +g[a >> 2] + w * +g[b >> 2];
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[t >> 2] = (c[t >> 2] | 0) + 4;
        c[o >> 2] = (c[o >> 2] | 0) + 1;
      }
      c[u >> 2] = (c[u >> 2] | 0) + 1;
    }
    i = v;
  }
  function Ta(a, b, d, e, f, h, j) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = +h;
    j = +j;
    var k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0;
    C = i;
    i = (i + 528) | 0;
    v = (C + 512) | 0;
    k = (C + 468) | 0;
    l = (C + 24) | 0;
    w = (C + 456) | 0;
    m = (C + 504) | 0;
    E = (C + 16) | 0;
    D = (C + 476) | 0;
    t = (C + 500) | 0;
    s = (C + 508) | 0;
    p = (C + 516) | 0;
    z = (C + 460) | 0;
    A = (C + 464) | 0;
    q = (C + 8) | 0;
    u = C;
    x = (C + 4) | 0;
    y = (C + 472) | 0;
    B = (C + 32) | 0;
    n = (C + 480) | 0;
    r = (C + 12) | 0;
    o = (C + 376) | 0;
    c[v >> 2] = a;
    c[k >> 2] = b;
    c[l >> 2] = d;
    c[w >> 2] = e;
    c[m >> 2] = f;
    g[E >> 2] = h;
    g[(C + 20) >> 2] = j;
    c[D >> 2] = ~~(+g[E >> 2] - 0.5);
    b = ((c[D >> 2] | 0) - 2) | 0;
    c[t >> 2] = b;
    c[t >> 2] = (c[t >> 2] | 0) < 0 ? 0 : b;
    c[s >> 2] = (c[D >> 2] | 0) + 2;
    if ((((c[s >> 2] | 0) + 80) | 0) >= (c[w >> 2] | 0))
      c[s >> 2] = (c[w >> 2] | 0) - 80 - 1;
    c[p >> 2] = (c[s >> 2] | 0) - (c[t >> 2] | 0) + 1;
    Ra(
      n,
      ((c[l >> 2] | 0) + (c[t >> 2] << 2)) | 0,
      ((c[p >> 2] | 0) + 80 - 1) | 0,
      ((c[l >> 2] | 0) + (c[m >> 2] << 2)) | 0,
      80
    );
    Sa(o, n, c[p >> 2] | 0, 3);
    c[z >> 2] = 0;
    g[r >> 2] = +g[o >> 2];
    c[q >> 2] = 1;
    while (1) {
      if ((c[q >> 2] | 0) >= ((c[p >> 2] << 2) | 0)) break;
      if (+g[(o + (c[q >> 2] << 2)) >> 2] > +g[r >> 2]) {
        c[z >> 2] = c[q >> 2];
        g[r >> 2] = +g[(o + (c[q >> 2] << 2)) >> 2];
      }
      c[q >> 2] = (c[q >> 2] | 0) + 1;
    }
    g[c[k >> 2] >> 2] = +(c[t >> 2] | 0) + +(c[z >> 2] | 0) / 4.0 + 1.0;
    c[A >> 2] = ((c[z >> 2] | 0) / 4) | 0;
    if ((c[z >> 2] | 0) > ((c[A >> 2] << 2) | 0))
      c[A >> 2] = (c[A >> 2] | 0) + 1;
    c[u >> 2] = (c[t >> 2] | 0) + (c[A >> 2] | 0) - 3;
    do
      if ((c[u >> 2] | 0) >= 0) {
        c[x >> 2] = (c[u >> 2] | 0) + 86;
        k = ((c[l >> 2] | 0) + (c[u >> 2] << 2)) | 0;
        if ((c[x >> 2] | 0) > (c[w >> 2] | 0)) {
          Nb(
            B | 0,
            k | 0,
            ((86 - ((c[x >> 2] | 0) - (c[w >> 2] | 0))) << 2) | 0
          ) | 0;
          Lb(
            (B + ((86 - ((c[x >> 2] | 0) - (c[w >> 2] | 0))) << 2)) | 0,
            0,
            (((c[x >> 2] | 0) - (c[w >> 2] | 0)) << 2) | 0
          ) | 0;
          break;
        } else {
          Nb(B | 0, k | 0, 344) | 0;
          break;
        }
      } else {
        Lb(B | 0, 0, ((0 - (c[u >> 2] | 0)) << 2) | 0) | 0;
        Nb(
          (B + ((0 - (c[u >> 2] | 0)) << 2)) | 0,
          c[l >> 2] | 0,
          ((86 + (c[u >> 2] | 0)) << 2) | 0
        ) | 0;
      }
    while (0);
    c[y >> 2] = (c[A >> 2] << 2) - (c[z >> 2] | 0);
    Ra(c[v >> 2] | 0, B, 86, (2792 + (((c[y >> 2] | 0) * 7) << 2)) | 0, 7);
    i = C;
  }
  function Ua(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = +e;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0;
    z = i;
    i = (i + 1056) | 0;
    f = (z + 992) | 0;
    h = (z + 984) | 0;
    j = (z + 1004) | 0;
    k = (z + 1044) | 0;
    r = (z + 12) | 0;
    s = (z + 1e3) | 0;
    v = (z + 1008) | 0;
    w = (z + 1040) | 0;
    x = (z + 1048) | 0;
    l = (z + 1052) | 0;
    m = (z + 8) | 0;
    n = (z + 4) | 0;
    t = z;
    p = (z + 996) | 0;
    q = (z + 16) | 0;
    u = (z + 24) | 0;
    y = (z + 1012) | 0;
    o = (z + 988) | 0;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    g[k >> 2] = e;
    c[r >> 2] = 1;
    while (1) {
      if ((c[r >> 2] | 0) > (((c[j >> 2] << 1) + 1) | 0)) break;
      e =
        (1.0 -
          +P(
            +(
              (+(c[r >> 2] | 0) * 6.2831854820251465) /
              +(((c[j >> 2] << 1) + 2) | 0)
            )
          )) *
        0.5;
      g[(y + (((c[r >> 2] | 0) - 1) << 2)) >> 2] = e;
      c[r >> 2] = (c[r >> 2] | 0) + 1;
    }
    g[(y + (c[j >> 2] << 2)) >> 2] = 0.0;
    c[r >> 2] = 0;
    while (1) {
      if ((c[r >> 2] | 0) >= 80) break;
      g[(u + (c[r >> 2] << 2)) >> 2] =
        +g[((c[h >> 2] | 0) + (c[r >> 2] << 2)) >> 2] * +g[y >> 2];
      c[r >> 2] = (c[r >> 2] | 0) + 1;
    }
    c[s >> 2] = 1;
    while (1) {
      if ((c[s >> 2] | 0) >= (c[j >> 2] | 0)) break;
      c[t >> 2] = (c[h >> 2] | 0) + (((c[s >> 2] | 0) * 80) << 2);
      c[r >> 2] = 0;
      while (1) {
        if ((c[r >> 2] | 0) >= 80) break;
        b = (u + (c[r >> 2] << 2)) | 0;
        g[b >> 2] =
          +g[b >> 2] +
          +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2] *
            +g[(y + (c[s >> 2] << 2)) >> 2];
        c[r >> 2] = (c[r >> 2] | 0) + 1;
      }
      c[s >> 2] = (c[s >> 2] | 0) + 1;
    }
    c[s >> 2] = (c[j >> 2] | 0) + 1;
    while (1) {
      if ((c[s >> 2] | 0) > ((c[j >> 2] << 1) | 0)) break;
      c[t >> 2] = (c[h >> 2] | 0) + (((c[s >> 2] | 0) * 80) << 2);
      c[r >> 2] = 0;
      while (1) {
        if ((c[r >> 2] | 0) >= 80) break;
        b = (u + (c[r >> 2] << 2)) | 0;
        g[b >> 2] =
          +g[b >> 2] +
          +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2] *
            +g[(y + (c[s >> 2] << 2)) >> 2];
        c[r >> 2] = (c[r >> 2] | 0) + 1;
      }
      c[s >> 2] = (c[s >> 2] | 0) + 1;
    }
    g[x >> 2] = 0.0;
    g[w >> 2] = 0.0;
    g[v >> 2] = 0.0;
    c[t >> 2] = (c[h >> 2] | 0) + (((c[j >> 2] | 0) * 80) << 2);
    c[r >> 2] = 0;
    while (1) {
      if ((c[r >> 2] | 0) >= 80) break;
      g[v >> 2] =
        +g[v >> 2] +
        +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2] *
          +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2];
      g[x >> 2] =
        +g[x >> 2] +
        +g[(u + (c[r >> 2] << 2)) >> 2] * +g[(u + (c[r >> 2] << 2)) >> 2];
      g[w >> 2] =
        +g[w >> 2] +
        +g[(u + (c[r >> 2] << 2)) >> 2] *
          +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2];
      c[r >> 2] = (c[r >> 2] | 0) + 1;
    }
    if (+M(+(+g[x >> 2])) < 1.0) g[x >> 2] = 1.0;
    g[n >> 2] = +N(+(+g[v >> 2] / +g[x >> 2]));
    g[q >> 2] = 0.0;
    c[t >> 2] = (c[h >> 2] | 0) + (((c[j >> 2] | 0) * 80) << 2);
    c[r >> 2] = 0;
    while (1) {
      if ((c[r >> 2] | 0) >= 80) break;
      g[((c[f >> 2] | 0) + (c[r >> 2] << 2)) >> 2] =
        +g[n >> 2] * +g[(u + (c[r >> 2] << 2)) >> 2];
      g[p >> 2] =
        +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2] -
        +g[((c[f >> 2] | 0) + (c[r >> 2] << 2)) >> 2];
      g[q >> 2] = +g[q >> 2] + +g[p >> 2] * +g[p >> 2];
      c[r >> 2] = (c[r >> 2] | 0) + 1;
    }
    a: do
      if (+g[q >> 2] > +g[k >> 2] * +g[v >> 2]) {
        if (+g[v >> 2] < 1.0) g[v >> 2] = 1.0;
        g[o >> 2] =
          (+g[x >> 2] * +g[v >> 2] - +g[w >> 2] * +g[w >> 2]) /
          (+g[v >> 2] * +g[v >> 2]);
        if (+g[o >> 2] > 0.0001) {
          g[l >> 2] = +N(
            +((+g[k >> 2] - (+g[k >> 2] * +g[k >> 2]) / 4.0) / +g[o >> 2])
          );
          g[m >> 2] =
            -+g[k >> 2] / 2.0 - (+g[l >> 2] * +g[w >> 2]) / +g[v >> 2];
          g[m >> 2] = +g[m >> 2] + 1.0;
        } else {
          g[l >> 2] = 0.0;
          g[m >> 2] = 1.0;
        }
        c[t >> 2] = (c[h >> 2] | 0) + (((c[j >> 2] | 0) * 80) << 2);
        c[r >> 2] = 0;
        while (1) {
          if ((c[r >> 2] | 0) >= 80) break a;
          g[((c[f >> 2] | 0) + (c[r >> 2] << 2)) >> 2] =
            +g[l >> 2] * +g[(u + (c[r >> 2] << 2)) >> 2] +
            +g[m >> 2] * +g[((c[t >> 2] | 0) + (c[r >> 2] << 2)) >> 2];
          c[r >> 2] = (c[r >> 2] | 0) + 1;
        }
      }
    while (0);
    i = z;
  }
  function Va(a, b, d, e, f, h, j, k) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    var l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0;
    z = i;
    i = (i + 192) | 0;
    n = (z + 176) | 0;
    o = (z + 168) | 0;
    p = (z + 124) | 0;
    q = (z + 120) | 0;
    r = (z + 36) | 0;
    l = (z + 164) | 0;
    s = (z + 172) | 0;
    t = (z + 180) | 0;
    m = (z + 128) | 0;
    A = (z + 32) | 0;
    y = (z + 28) | 0;
    u = (z + 132) | 0;
    v = z;
    w = (z + 40) | 0;
    x = (z + 160) | 0;
    c[n >> 2] = a;
    c[o >> 2] = b;
    c[p >> 2] = d;
    c[q >> 2] = e;
    c[r >> 2] = f;
    c[l >> 2] = h;
    c[s >> 2] = j;
    c[t >> 2] = k;
    c[A >> 2] = (c[q >> 2] | 0) + 80 - 1;
    Qa(
      (v + (c[t >> 2] << 2)) | 0,
      c[l >> 2] | 0,
      +(((c[q >> 2] | 0) + (c[A >> 2] | 0)) | 0) * 0.5,
      c[s >> 2] | 0
    );
    g[(u + (c[t >> 2] << 2)) >> 2] = +(c[q >> 2] | 0);
    c[x >> 2] = (c[n >> 2] | 0) + (((c[t >> 2] | 0) * 80) << 2);
    Nb(c[x >> 2] | 0, ((c[o >> 2] | 0) + (c[q >> 2] << 2)) | 0, 320) | 0;
    c[y >> 2] = (c[t >> 2] | 0) - 1;
    while (1) {
      if ((c[y >> 2] | 0) < 0) break;
      g[(u + (c[y >> 2] << 2)) >> 2] =
        +g[(u + (((c[y >> 2] | 0) + 1) << 2)) >> 2] -
        +g[
          ((c[r >> 2] | 0) +
            (c[(v + (((c[y >> 2] | 0) + 1) << 2)) >> 2] << 2)) >>
            2
        ];
      Qa(
        (v + (c[y >> 2] << 2)) | 0,
        c[l >> 2] | 0,
        +g[(u + (c[y >> 2] << 2)) >> 2] +
          40.0 -
          +g[
            ((c[r >> 2] | 0) +
              (c[(v + (((c[y >> 2] | 0) + 1) << 2)) >> 2] << 2)) >>
              2
          ],
        c[s >> 2] | 0
      );
      e = ((c[n >> 2] | 0) + (((c[y >> 2] | 0) * 80) << 2)) | 0;
      if (+g[(u + (c[y >> 2] << 2)) >> 2] - 2.0 >= 0.0)
        Ta(
          e,
          (u + (c[y >> 2] << 2)) | 0,
          c[o >> 2] | 0,
          c[p >> 2] | 0,
          c[q >> 2] | 0,
          +g[(u + (c[y >> 2] << 2)) >> 2],
          +g[
            ((c[r >> 2] | 0) +
              (c[(v + (((c[y >> 2] | 0) + 1) << 2)) >> 2] << 2)) >>
              2
          ]
        );
      else {
        c[x >> 2] = e;
        Lb(c[x >> 2] | 0, 0, 320) | 0;
      }
      c[y >> 2] = (c[y >> 2] | 0) + -1;
    }
    c[m >> 2] = 0;
    while (1) {
      if ((c[m >> 2] | 0) >= (c[s >> 2] | 0)) break;
      g[(w + (c[m >> 2] << 2)) >> 2] =
        +g[((c[l >> 2] | 0) + (c[m >> 2] << 2)) >> 2] -
        +g[((c[r >> 2] | 0) + (c[m >> 2] << 2)) >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    c[y >> 2] = (c[t >> 2] | 0) + 1;
    while (1) {
      if ((c[y >> 2] | 0) > ((c[t >> 2] << 1) | 0)) break;
      Qa(
        (v + (c[y >> 2] << 2)) | 0,
        w,
        +g[(u + (((c[y >> 2] | 0) - 1) << 2)) >> 2] + 40.0,
        c[s >> 2] | 0
      );
      g[(u + (c[y >> 2] << 2)) >> 2] =
        +g[(u + (((c[y >> 2] | 0) - 1) << 2)) >> 2] +
        +g[((c[r >> 2] | 0) + (c[(v + (c[y >> 2] << 2)) >> 2] << 2)) >> 2];
      k = c[n >> 2] | 0;
      e = c[y >> 2] | 0;
      if (+g[(u + (c[y >> 2] << 2)) >> 2] + 80.0 + 2.0 < +(c[p >> 2] | 0))
        Ta(
          (k + ((e * 80) << 2)) | 0,
          (u + (c[y >> 2] << 2)) | 0,
          c[o >> 2] | 0,
          c[p >> 2] | 0,
          c[q >> 2] | 0,
          +g[(u + (c[y >> 2] << 2)) >> 2],
          +g[((c[r >> 2] | 0) + (c[(v + (c[y >> 2] << 2)) >> 2] << 2)) >> 2]
        );
      else {
        c[x >> 2] = k + ((e * 80) << 2);
        Lb(c[x >> 2] | 0, 0, 320) | 0;
      }
      c[y >> 2] = (c[y >> 2] | 0) + 1;
    }
    i = z;
  }
  function Wa(a, b, d, e, f, h, j, k) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = +f;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    var l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0;
    l = i;
    i = (i + 2288) | 0;
    o = (l + 2268) | 0;
    u = (l + 16) | 0;
    t = (l + 4) | 0;
    s = (l + 8) | 0;
    m = (l + 2272) | 0;
    r = l;
    q = (l + 12) | 0;
    p = (l + 2264) | 0;
    n = (l + 24) | 0;
    c[o >> 2] = a;
    c[u >> 2] = b;
    c[t >> 2] = d;
    c[s >> 2] = e;
    g[m >> 2] = f;
    c[r >> 2] = h;
    c[q >> 2] = j;
    c[p >> 2] = k;
    Va(
      n,
      c[u >> 2] | 0,
      c[t >> 2] | 0,
      c[s >> 2] | 0,
      c[r >> 2] | 0,
      c[q >> 2] | 0,
      c[p >> 2] | 0,
      3
    );
    Ua(c[o >> 2] | 0, n, 3, +g[m >> 2]);
    i = l;
  }
  function Xa(a, b, d) {
    a |= 0;
    b |= 0;
    d |= 0;
    let e = 0;
    var f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    var n = 0;
    n = i;
    i = (i + 32) | 0;
    e = (n + 8) | 0;
    f = (n + 16) | 0;
    h = (n + 20) | 0;
    j = (n + 24) | 0;
    m = (n + 4) | 0;
    k = n;
    l = (n + 12) | 0;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    g[k >> 2] = 0.0;
    g[l >> 2] = 0.0;
    c[m >> 2] = 0;
    while (1) {
      if ((c[m >> 2] | 0) >= (c[j >> 2] | 0)) break;
      g[k >> 2] =
        +g[k >> 2] +
        +g[((c[f >> 2] | 0) + (c[m >> 2] << 2)) >> 2] *
          +g[((c[h >> 2] | 0) + (c[m >> 2] << 2)) >> 2];
      g[l >> 2] =
        +g[l >> 2] +
        +g[((c[h >> 2] | 0) + (c[m >> 2] << 2)) >> 2] *
          +g[((c[h >> 2] | 0) + (c[m >> 2] << 2)) >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    if (+g[k >> 2] > 0.0) g[e >> 2] = (+g[k >> 2] * +g[k >> 2]) / +g[l >> 2];
    else g[e >> 2] = 0.0;
    i = n;
    return +(+g[e >> 2]);
  }
  function Ya(a, b, d) {
    a |= 0;
    b |= 0;
    d |= 0;
    let e = 0;
    var f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    var n = 0;
    var o = 0;
    var p = 0;
    var q = 0;
    var r = 0;
    var s = 0;
    var t = 0;
    var u = 0;
    var v = 0;
    var w = 0;
    var x = 0;
    var y = 0;
    var z = 0;
    var A = 0;
    var B = 0;
    var C = 0;
    var D = 0;
    var E = 0;
    var F = 0;
    var G = 0;
    var H = 0.0;
    G = i;
    i = (i + 1168) | 0;
    e = (G + 384) | 0;
    f = (G + 380) | 0;
    h = (G + 1140) | 0;
    m = (G + 1148) | 0;
    p = (G + 376) | 0;
    t = (G + 352) | 0;
    z = (G + 356) | 0;
    A = (G + 360) | 0;
    u = (G + 364) | 0;
    s = (G + 1128) | 0;
    y = (G + 1136) | 0;
    k = (G + 1144) | 0;
    C = (G + 1152) | 0;
    q = (G + 1156) | 0;
    r = (G + 388) | 0;
    w = (G + 392) | 0;
    n = (G + 396) | 0;
    o = (G + 400) | 0;
    E = (G + 8) | 0;
    B = (G + 328) | 0;
    l = (G + 408) | 0;
    v = (G + 368) | 0;
    F = (G + 372) | 0;
    D = (G + 1132) | 0;
    x = G;
    j = (G + 4) | 0;
    c[e >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[A >> 2] = 0;
    c[v >> 2] = 360;
    c[m >> 2] = (c[h >> 2] | 0) + 1432;
    c[p >> 2] = (c[h >> 2] | 0) + 3992;
    Ob(
      c[m >> 2] | 0,
      ((c[m >> 2] | 0) + (c[((c[h >> 2] | 0) + 4) >> 2] << 2)) | 0,
      ((640 - (c[((c[h >> 2] | 0) + 4) >> 2] | 0)) << 2) | 0
    ) | 0;
    Nb(
      ((c[m >> 2] | 0) + ((640 - (c[((c[h >> 2] | 0) + 4) >> 2] | 0)) << 2)) |
        0,
      c[f >> 2] | 0,
      (c[((c[h >> 2] | 0) + 4) >> 2] << 2) | 0
    ) | 0;
    if ((c[c[h >> 2] >> 2] | 0) == 30) c[D >> 2] = 80;
    else c[D >> 2] = 40;
    c[y >> 2] = 0;
    c[y >> 2] = (c[c[h >> 2] >> 2] | 0) == 20 ? 1 : 0;
    c[s >> 2] = 3 - (c[y >> 2] | 0);
    Ob(
      c[p >> 2] | 0,
      ((c[p >> 2] | 0) + (c[s >> 2] << 2)) | 0,
      ((8 - (c[s >> 2] | 0)) << 2) | 0
    ) | 0;
    a =
      ((c[m >> 2] | 0) + ((((5 + (c[y >> 2] | 0)) | 0) * 80) << 2) + -504) | 0;
    c[(B + 0) >> 2] = c[(a + 0) >> 2];
    c[(B + 4) >> 2] = c[(a + 4) >> 2];
    c[(B + 8) >> 2] = c[(a + 8) >> 2];
    c[(B + 12) >> 2] = c[(a + 12) >> 2];
    c[(B + 16) >> 2] = c[(a + 16) >> 2];
    c[(B + 20) >> 2] = c[(a + 20) >> 2];
    ab(
      ((c[m >> 2] | 0) + ((((5 + (c[y >> 2] | 0)) | 0) * 80) << 2) + -480) | 0,
      2136,
      ((c[v >> 2] | 0) - (((c[y >> 2] | 0) * 80) | 0)) | 0,
      B,
      l
    );
    c[t >> 2] = 0;
    while (1) {
      if ((c[t >> 2] | 0) >= ((3 - (c[y >> 2] | 0)) | 0)) break;
      c[A >> 2] = 10;
      g[C >> 2] = +Xa(
        (l + 240 + (((c[t >> 2] | 0) * 40) << 2)) | 0,
        (l +
          240 +
          (((c[t >> 2] | 0) * 40) << 2) +
          ((0 - (c[A >> 2] | 0)) << 2)) |
          0,
        40
      );
      c[u >> 2] = 11;
      while (1) {
        if ((c[u >> 2] | 0) >= 60) break;
        g[k >> 2] = +Xa(
          (l + 240 + (((c[t >> 2] | 0) * 40) << 2)) | 0,
          (l +
            240 +
            (((c[t >> 2] | 0) * 40) << 2) +
            ((0 - (c[u >> 2] | 0)) << 2)) |
            0,
          40
        );
        if (+g[k >> 2] > +g[C >> 2]) {
          g[C >> 2] = +g[k >> 2];
          c[A >> 2] = c[u >> 2];
        }
        c[u >> 2] = (c[u >> 2] | 0) + 1;
      }
      g[
        ((c[p >> 2] | 0) + (((c[t >> 2] | 0) + 5 + (c[y >> 2] | 0)) << 2)) >> 2
      ] = +(c[A >> 2] | 0) * 2.0;
      c[t >> 2] = (c[t >> 2] | 0) + 1;
    }
    a: do
      if ((c[((c[h >> 2] | 0) + 132) >> 2] | 0) == 1) {
        c[x >> 2] = ~~+g[((c[p >> 2] | 0) + ((5 + (c[y >> 2] | 0)) << 2)) >> 2];
        c[A >> 2] = (c[x >> 2] | 0) - 1;
        g[C >> 2] = +Xa(
          c[f >> 2] | 0,
          ((c[f >> 2] | 0) + (c[A >> 2] << 2)) | 0,
          c[D >> 2] | 0
        );
        c[u >> 2] = c[x >> 2];
        while (1) {
          if ((c[u >> 2] | 0) > (((c[x >> 2] | 0) + 1) | 0)) break;
          g[k >> 2] = +Xa(
            c[f >> 2] | 0,
            ((c[f >> 2] | 0) + (c[u >> 2] << 2)) | 0,
            c[D >> 2] | 0
          );
          if (+g[k >> 2] > +g[C >> 2]) {
            g[C >> 2] = +g[k >> 2];
            c[A >> 2] = c[u >> 2];
          }
          c[u >> 2] = (c[u >> 2] | 0) + 1;
        }
        g[((c[p >> 2] | 0) + ((5 + (c[y >> 2] | 0) - 1) << 2)) >> 2] = +(
          c[A >> 2] | 0
        );
        c[w >> 2] = (c[f >> 2] | 0) + (((c[A >> 2] | 0) - 1) << 2);
        c[n >> 2] = E + (((c[D >> 2] | 0) - 1) << 2);
        if ((c[A >> 2] | 0) > (c[D >> 2] | 0)) c[F >> 2] = c[D >> 2];
        else c[F >> 2] = c[A >> 2];
        c[z >> 2] = c[F >> 2];
        while (1) {
          if ((c[z >> 2] | 0) <= 0) break;
          F = c[w >> 2] | 0;
          c[w >> 2] = F + -4;
          H = +g[F >> 2];
          F = c[n >> 2] | 0;
          c[n >> 2] = F + -4;
          g[F >> 2] = H;
          c[z >> 2] = (c[z >> 2] | 0) + -1;
        }
        c[o >> 2] =
          (c[m >> 2] | 0) + ((639 - (c[((c[h >> 2] | 0) + 4) >> 2] | 0)) << 2);
        c[z >> 2] = (c[D >> 2] | 0) - 1 - (c[A >> 2] | 0);
        while (1) {
          if ((c[z >> 2] | 0) < 0) break;
          F = c[o >> 2] | 0;
          c[o >> 2] = F + -4;
          H = +g[F >> 2];
          F = c[n >> 2] | 0;
          c[n >> 2] = F + -4;
          g[F >> 2] = H;
          c[z >> 2] = (c[z >> 2] | 0) + -1;
        }
        g[r >> 2] = 0.0;
        g[q >> 2] = 0.0;
        c[s >> 2] = 0;
        while (1) {
          if ((c[s >> 2] | 0) >= (c[D >> 2] | 0)) break;
          g[r >> 2] =
            +g[r >> 2] +
            +g[
              ((c[m >> 2] | 0) +
                ((639 -
                  (c[((c[h >> 2] | 0) + 4) >> 2] | 0) -
                  (c[s >> 2] | 0)) <<
                  2)) >>
                2
            ] *
              +g[
                ((c[m >> 2] | 0) +
                  ((639 -
                    (c[((c[h >> 2] | 0) + 4) >> 2] | 0) -
                    (c[s >> 2] | 0)) <<
                    2)) >>
                  2
              ];
          g[q >> 2] =
            +g[q >> 2] +
            +g[(E + (c[s >> 2] << 2)) >> 2] * +g[(E + (c[s >> 2] << 2)) >> 2];
          c[s >> 2] = (c[s >> 2] | 0) + 1;
        }
        g[q >> 2] = +N(+(+g[q >> 2] / +(c[D >> 2] | 0)));
        g[r >> 2] = +N(+(+g[r >> 2] / +(c[D >> 2] | 0)));
        b: do
          if (+g[q >> 2] > +g[r >> 2] * 2.0 ? +g[q >> 2] > 0.0 : 0) {
            c[s >> 2] = 0;
            while (1) {
              if ((c[s >> 2] | 0) >= (((c[D >> 2] | 0) - 10) | 0)) break;
              F = (E + (c[s >> 2] << 2)) | 0;
              g[F >> 2] = +g[F >> 2] * ((+g[r >> 2] * 2.0) / +g[q >> 2]);
              c[s >> 2] = (c[s >> 2] | 0) + 1;
            }
            c[s >> 2] = (c[D >> 2] | 0) - 10;
            while (1) {
              if ((c[s >> 2] | 0) >= (c[D >> 2] | 0)) break b;
              F = (E + (c[s >> 2] << 2)) | 0;
              g[F >> 2] =
                +g[F >> 2] *
                ((+(((c[s >> 2] | 0) - (c[D >> 2] | 0) + 10) | 0) *
                  (1.0 - (+g[r >> 2] * 2.0) / +g[q >> 2])) /
                  10.0 +
                  (+g[r >> 2] * 2.0) / +g[q >> 2]);
              c[s >> 2] = (c[s >> 2] | 0) + 1;
            }
          }
        while (0);
        c[n >> 2] =
          (c[m >> 2] | 0) + ((639 - (c[((c[h >> 2] | 0) + 4) >> 2] | 0)) << 2);
        c[s >> 2] = 0;
        while (1) {
          if ((c[s >> 2] | 0) >= (c[D >> 2] | 0)) break a;
          g[q >> 2] =
            +(((c[s >> 2] | 0) + 1) | 0) / +(((c[D >> 2] | 0) + 1) | 0);
          F = c[n >> 2] | 0;
          g[F >> 2] = +g[F >> 2] * +g[q >> 2];
          F = c[n >> 2] | 0;
          g[F >> 2] =
            +g[F >> 2] +
            (1.0 - +g[q >> 2]) *
              +g[(E + (((c[D >> 2] | 0) - 1 - (c[s >> 2] | 0)) << 2)) >> 2];
          c[n >> 2] = (c[n >> 2] | 0) + -4;
          c[s >> 2] = (c[s >> 2] | 0) + 1;
        }
      }
    while (0);
    c: do
      if ((c[c[h >> 2] >> 2] | 0) != 20) {
        if ((c[c[h >> 2] >> 2] | 0) == 30) {
          c[t >> 2] = 0;
          while (1) {
            if ((c[t >> 2] | 0) >= 3) break c;
            Wa(
              ((c[e >> 2] | 0) + (((c[t >> 2] | 0) * 80) << 2)) | 0,
              c[m >> 2] | 0,
              640,
              (((4 + (c[t >> 2] | 0)) | 0) * 80) | 0,
              0.05000000074505806,
              c[p >> 2] | 0,
              2904,
              8
            );
            c[t >> 2] = (c[t >> 2] | 0) + 1;
          }
        }
      } else {
        c[t >> 2] = 0;
        while (1) {
          if ((c[t >> 2] | 0) >= 2) break c;
          Wa(
            ((c[e >> 2] | 0) + (((c[t >> 2] | 0) * 80) << 2)) | 0,
            c[m >> 2] | 0,
            640,
            (((((5 + (c[t >> 2] | 0)) | 0) * 80) | 0) + 40) | 0,
            0.05000000074505806,
            c[p >> 2] | 0,
            2904,
            8
          );
          c[t >> 2] = (c[t >> 2] | 0) + 1;
        }
      }
    while (0);
    F = c[A >> 2] << 1;
    c[j >> 2] = 1;
    i = G;
    return F | 0;
  }
  function Za(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0;
    n = i;
    i = (i + 32) | 0;
    f = (n + 20) | 0;
    h = (n + 16) | 0;
    j = (n + 12) | 0;
    k = (n + 8) | 0;
    m = (n + 4) | 0;
    l = n;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    c[k >> 2] = e;
    c[m >> 2] = 0;
    while (1) {
      if ((c[m >> 2] | 0) >= (c[j >> 2] | 0)) break;
      c[l >> 2] = 1;
      while (1) {
        if ((c[l >> 2] | 0) > (c[k >> 2] | 0)) break;
        e = c[f >> 2] | 0;
        g[e >> 2] =
          +g[e >> 2] -
          +g[((c[h >> 2] | 0) + (c[l >> 2] << 2)) >> 2] *
            +g[((c[f >> 2] | 0) + ((0 - (c[l >> 2] | 0)) << 2)) >> 2];
        c[l >> 2] = (c[l >> 2] | 0) + 1;
      }
      c[f >> 2] = (c[f >> 2] | 0) + 4;
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    i = n;
  }
  function _a(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0;
    p = i;
    i = (i + 32) | 0;
    h = (p + 8) | 0;
    j = (p + 16) | 0;
    k = (p + 20) | 0;
    l = (p + 24) | 0;
    m = (p + 4) | 0;
    o = p;
    n = (p + 12) | 0;
    c[h >> 2] = a;
    c[j >> 2] = b;
    c[k >> 2] = d;
    c[l >> 2] = e;
    c[m >> 2] = f;
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= (c[k >> 2] | 0)) break;
      g[c[m >> 2] >> 2] = +g[c[j >> 2] >> 2] * +g[c[h >> 2] >> 2];
      c[n >> 2] = 1;
      while (1) {
        if ((c[n >> 2] | 0) > (c[l >> 2] | 0)) break;
        e = c[m >> 2] | 0;
        g[e >> 2] =
          +g[e >> 2] +
          +g[((c[j >> 2] | 0) + (c[n >> 2] << 2)) >> 2] *
            +g[((c[h >> 2] | 0) + ((0 - (c[n >> 2] | 0)) << 2)) >> 2];
        c[n >> 2] = (c[n >> 2] | 0) + 1;
      }
      c[m >> 2] = (c[m >> 2] | 0) + 4;
      c[h >> 2] = (c[h >> 2] | 0) + 4;
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    i = p;
  }
  function $a(a, b, d, e, f, g) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    g = g | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    h = i;
    i = (i + 32) | 0;
    o = (h + 20) | 0;
    n = (h + 16) | 0;
    l = (h + 12) | 0;
    k = (h + 8) | 0;
    j = (h + 4) | 0;
    m = h;
    c[o >> 2] = a;
    c[n >> 2] = b;
    c[l >> 2] = d;
    c[k >> 2] = e;
    c[j >> 2] = f;
    c[m >> 2] = g;
    _a(
      c[o >> 2] | 0,
      c[n >> 2] | 0,
      c[k >> 2] | 0,
      c[j >> 2] | 0,
      c[m >> 2] | 0
    );
    Za(c[m >> 2] | 0, c[l >> 2] | 0, c[k >> 2] | 0, c[j >> 2] | 0);
    i = h;
  }
  function ab(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0.0;
    u = i;
    i = (i + 64) | 0;
    h = (u + 44) | 0;
    j = (u + 36) | 0;
    k = (u + 20) | 0;
    l = (u + 16) | 0;
    v = (u + 32) | 0;
    r = (u + 40) | 0;
    o = (u + 48) | 0;
    m = (u + 8) | 0;
    n = (u + 4) | 0;
    s = (u + 24) | 0;
    p = (u + 12) | 0;
    q = (u + 28) | 0;
    t = u;
    c[h >> 2] = a;
    c[j >> 2] = b;
    c[k >> 2] = d;
    c[l >> 2] = e;
    c[v >> 2] = f;
    c[o >> 2] = c[v >> 2];
    c[p >> 2] = 3;
    while (1) {
      if ((c[p >> 2] | 0) >= (c[k >> 2] | 0)) break;
      c[m >> 2] = c[j >> 2];
      c[n >> 2] = (c[h >> 2] | 0) + (c[p >> 2] << 2);
      c[s >> 2] = (c[l >> 2] | 0) + 20;
      g[r >> 2] = 0.0;
      if ((c[p >> 2] | 0) < 7) b = ((c[p >> 2] | 0) + 1) | 0;
      else b = 7;
      c[t >> 2] = b;
      c[q >> 2] = 0;
      while (1) {
        if ((c[q >> 2] | 0) >= (c[t >> 2] | 0)) break;
        v = c[m >> 2] | 0;
        c[m >> 2] = v + 4;
        w = +g[v >> 2];
        v = c[n >> 2] | 0;
        c[n >> 2] = v + -4;
        g[r >> 2] = +g[r >> 2] + w * +g[v >> 2];
        c[q >> 2] = (c[q >> 2] | 0) + 1;
      }
      c[q >> 2] = (c[p >> 2] | 0) + 1;
      while (1) {
        if ((c[q >> 2] | 0) >= 7) break;
        v = c[m >> 2] | 0;
        c[m >> 2] = v + 4;
        w = +g[v >> 2];
        v = c[s >> 2] | 0;
        c[s >> 2] = v + -4;
        g[r >> 2] = +g[r >> 2] + w * +g[v >> 2];
        c[q >> 2] = (c[q >> 2] | 0) + 1;
      }
      w = +g[r >> 2];
      v = c[o >> 2] | 0;
      c[o >> 2] = v + 4;
      g[v >> 2] = w;
      c[p >> 2] = (c[p >> 2] | 0) + 2;
    }
    c[p >> 2] = (c[k >> 2] | 0) + 2;
    while (1) {
      if ((c[p >> 2] | 0) >= (((c[k >> 2] | 0) + 3) | 0)) break;
      g[r >> 2] = 0.0;
      a: do
        if ((c[p >> 2] | 0) < (c[k >> 2] | 0)) {
          c[m >> 2] = c[j >> 2];
          c[n >> 2] = (c[h >> 2] | 0) + (c[p >> 2] << 2);
          c[q >> 2] = 0;
          while (1) {
            if ((c[q >> 2] | 0) >= 7) break a;
            v = c[m >> 2] | 0;
            c[m >> 2] = v + 4;
            w = +g[v >> 2];
            v = c[o >> 2] | 0;
            c[o >> 2] = v + -4;
            g[r >> 2] = +g[r >> 2] + w * +g[v >> 2];
            c[q >> 2] = (c[q >> 2] | 0) + 1;
          }
        } else {
          c[m >> 2] =
            (c[j >> 2] | 0) + (((c[p >> 2] | 0) - (c[k >> 2] | 0)) << 2);
          c[n >> 2] = (c[h >> 2] | 0) + (((c[k >> 2] | 0) - 1) << 2);
          c[q >> 2] = 0;
          while (1) {
            if (
              (c[q >> 2] | 0) >=
              ((7 - ((c[p >> 2] | 0) - (c[k >> 2] | 0))) | 0)
            )
              break a;
            v = c[m >> 2] | 0;
            c[m >> 2] = v + 4;
            w = +g[v >> 2];
            v = c[n >> 2] | 0;
            c[n >> 2] = v + -4;
            g[r >> 2] = +g[r >> 2] + w * +g[v >> 2];
            c[q >> 2] = (c[q >> 2] | 0) + 1;
          }
        }
      while (0);
      w = +g[r >> 2];
      v = c[o >> 2] | 0;
      c[o >> 2] = v + 4;
      g[v >> 2] = w;
      c[p >> 2] = (c[p >> 2] | 0) + 2;
    }
    i = u;
  }
  function bb(a, b, d, e) {
    a = +a;
    b = +b;
    d |= 0;
    e |= 0;
    let f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    var n = 0;
    var o = 0;
    var p = 0;
    var q = 0;
    var r = 0;
    q = i;
    i = (i + 48) | 0;
    f = q;
    r = (q + 16) | 0;
    h = (q + 28) | 0;
    j = (q + 4) | 0;
    l = (q + 32) | 0;
    p = (q + 36) | 0;
    n = (q + 12) | 0;
    m = (q + 24) | 0;
    k = (q + 20) | 0;
    o = (q + 8) | 0;
    g[f >> 2] = a;
    g[r >> 2] = b;
    c[h >> 2] = d;
    c[j >> 2] = e;
    g[o >> 2] = +g[r >> 2];
    if (+g[o >> 2] < 0.1) g[o >> 2] = 0.10000000149011612;
    do
      if ((c[h >> 2] | 0) != 8)
        if ((c[h >> 2] | 0) == 16) {
          c[k >> 2] = 2600;
          break;
        } else {
          c[k >> 2] = 2664;
          break;
        }
      else c[k >> 2] = 2568;
    while (0);
    g[n >> 2] = 1.0e7;
    c[p >> 2] = 0;
    c[l >> 2] = 0;
    while (1) {
      if ((c[l >> 2] | 0) >= (c[h >> 2] | 0)) break;
      g[m >> 2] =
        (+g[f >> 2] -
          +g[o >> 2] * +g[((c[k >> 2] | 0) + (c[l >> 2] << 2)) >> 2]) *
        (+g[f >> 2] -
          +g[o >> 2] * +g[((c[k >> 2] | 0) + (c[l >> 2] << 2)) >> 2]);
      if (+g[m >> 2] < +g[n >> 2]) {
        c[p >> 2] = c[l >> 2];
        g[n >> 2] = +g[m >> 2];
      }
      c[l >> 2] = (c[l >> 2] | 0) + 1;
    }
    c[c[j >> 2] >> 2] = c[p >> 2];
    i = q;
    return +(+g[o >> 2] * +g[((c[k >> 2] | 0) + (c[p >> 2] << 2)) >> 2]);
  }
  function cb(a, b, d) {
    a |= 0;
    b = +b;
    d |= 0;
    let e = 0;
    var f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    k = i;
    i = (i + 32) | 0;
    e = (k + 16) | 0;
    f = (k + 12) | 0;
    l = (k + 8) | 0;
    h = (k + 4) | 0;
    j = k;
    c[f >> 2] = a;
    g[l >> 2] = b;
    c[h >> 2] = d;
    g[j >> 2] = +M(+(+g[l >> 2]));
    if (+g[j >> 2] < 0.1) g[j >> 2] = 0.10000000149011612;
    do
      if ((c[h >> 2] | 0) != 8) {
        if ((c[h >> 2] | 0) == 16) {
          g[e >> 2] = +g[j >> 2] * +g[(2600 + (c[f >> 2] << 2)) >> 2];
          break;
        }
        if ((c[h >> 2] | 0) == 32) {
          g[e >> 2] = +g[j >> 2] * +g[(2664 + (c[f >> 2] << 2)) >> 2];
          break;
        } else {
          g[e >> 2] = 0.0;
          break;
        }
      } else g[e >> 2] = +g[j >> 2] * +g[(2568 + (c[f >> 2] << 2)) >> 2];
    while (0);
    i = k;
    return +(+g[e >> 2]);
  }
  function db(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0.0;
    G = i;
    i = (i + 1312) | 0;
    h = (G + 664) | 0;
    j = (G + 660) | 0;
    k = (G + 4) | 0;
    l = (G + 1288) | 0;
    m = G;
    t = (G + 636) | 0;
    u = (G + 640) | 0;
    w = (G + 644) | 0;
    v = (G + 684) | 0;
    D = (G + 692) | 0;
    F = (G + 700) | 0;
    p = (G + 1292) | 0;
    s = (G + 1296) | 0;
    r = (G + 668) | 0;
    n = (G + 672) | 0;
    o = (G + 676) | 0;
    E = (G + 8) | 0;
    x = (G + 680) | 0;
    z = (G + 648) | 0;
    A = (G + 632) | 0;
    y = (G + 652) | 0;
    C = (G + 688) | 0;
    B = (G + 656) | 0;
    q = (G + 696) | 0;
    c[h >> 2] = a;
    c[j >> 2] = b;
    c[k >> 2] = d;
    c[l >> 2] = e;
    c[m >> 2] = f;
    c[p >> 2] = (c[l >> 2] | 0) - (c[m >> 2] | 0) + 1;
    if ((c[m >> 2] | 0) == 40)
      c[p >> 2] = (c[p >> 2] | 0) + (((c[m >> 2] | 0) / 2) | 0);
    f = c[k >> 2] | 0;
    do
      if ((c[k >> 2] | 0) < (((c[l >> 2] | 0) - (c[m >> 2] | 0) + 1) | 0)) {
        c[u >> 2] = f + (c[m >> 2] | 0);
        Nb(
          c[h >> 2] | 0,
          ((c[j >> 2] | 0) + (c[l >> 2] << 2) + ((0 - (c[u >> 2] | 0)) << 2)) |
            0,
          (c[m >> 2] << 2) | 0
        ) | 0;
      } else {
        a = c[k >> 2] | 0;
        if ((f | 0) < (c[p >> 2] | 0)) {
          c[u >> 2] =
            ((a - ((c[l >> 2] | 0) - (c[m >> 2] | 0) + 1)) << 1) +
            (c[m >> 2] | 0);
          c[r >> 2] = ((c[u >> 2] | 0) / 2) | 0;
          c[s >> 2] = (c[r >> 2] | 0) - 5;
          Nb(
            c[h >> 2] | 0,
            ((c[j >> 2] | 0) +
              (c[l >> 2] << 2) +
              ((0 - (((c[u >> 2] | 0) / 2) | 0)) << 2)) |
              0,
            (c[s >> 2] << 2) | 0
          ) | 0;
          g[o >> 2] = 0.20000000298023224;
          g[n >> 2] = 0.0;
          c[t >> 2] = c[s >> 2];
          while (1) {
            if ((c[t >> 2] | 0) >= (c[r >> 2] | 0)) break;
            g[((c[h >> 2] | 0) + (c[t >> 2] << 2)) >> 2] =
              (1.0 - +g[n >> 2]) *
                +g[
                  ((c[j >> 2] | 0) +
                    (((c[l >> 2] | 0) -
                      (((c[u >> 2] | 0) / 2) | 0) +
                      (c[t >> 2] | 0)) <<
                      2)) >>
                    2
                ] +
              +g[n >> 2] *
                +g[
                  ((c[j >> 2] | 0) +
                    (((c[l >> 2] | 0) - (c[u >> 2] | 0) + (c[t >> 2] | 0)) <<
                      2)) >>
                    2
                ];
            g[n >> 2] = +g[n >> 2] + +g[o >> 2];
            c[t >> 2] = (c[t >> 2] | 0) + 1;
          }
          Nb(
            ((c[h >> 2] | 0) + (c[r >> 2] << 2)) | 0,
            ((c[j >> 2] | 0) +
              (c[l >> 2] << 2) +
              ((0 - (c[u >> 2] | 0)) << 2) +
              (c[r >> 2] << 2)) |
              0,
            (((c[m >> 2] | 0) - (c[r >> 2] | 0)) << 2) | 0
          ) | 0;
          break;
        }
        if (
          ((a - (c[p >> 2] | 0)) | 0) <
          (((c[l >> 2] | 0) - (c[m >> 2] | 0) + 1) | 0)
        ) {
          c[(E + 0) >> 2] = 0;
          c[(E + 4) >> 2] = 0;
          c[(E + 8) >> 2] = 0;
          c[(E + 12) >> 2] = 0;
          Nb((E + 16) | 0, c[j >> 2] | 0, (c[l >> 2] << 2) | 0) | 0;
          F = (E + (((c[l >> 2] | 0) + 4) << 2)) | 0;
          c[(F + 0) >> 2] = 0;
          c[(F + 4) >> 2] = 0;
          c[(F + 8) >> 2] = 0;
          c[(F + 12) >> 2] = 0;
          c[(F + 16) >> 2] = 0;
          c[u >> 2] = (c[k >> 2] | 0) - (c[p >> 2] | 0) + (c[m >> 2] | 0);
          c[D >> 2] = (c[l >> 2] | 0) - (c[u >> 2] | 0);
          c[v >> 2] = (c[D >> 2] | 0) + 1 - 4;
          c[x >> 2] = c[h >> 2];
          Lb(c[x >> 2] | 0, 0, (c[m >> 2] << 2) | 0) | 0;
          c[w >> 2] = 0;
          while (1) {
            if ((c[w >> 2] | 0) >= (c[m >> 2] | 0)) break;
            c[z >> 2] = E + (((c[v >> 2] | 0) + (c[w >> 2] | 0) + 4) << 2);
            c[A >> 2] = 2564;
            c[t >> 2] = 0;
            while (1) {
              if ((c[t >> 2] | 0) >= 8) break;
              D = c[z >> 2] | 0;
              c[z >> 2] = D + 4;
              H = +g[D >> 2];
              D = c[A >> 2] | 0;
              c[A >> 2] = D + -4;
              F = c[x >> 2] | 0;
              g[F >> 2] = +g[F >> 2] + H * +g[D >> 2];
              c[t >> 2] = (c[t >> 2] | 0) + 1;
            }
            c[x >> 2] = (c[x >> 2] | 0) + 4;
            c[w >> 2] = (c[w >> 2] | 0) + 1;
          }
          break;
        }
        c[(E + 0) >> 2] = 0;
        c[(E + 4) >> 2] = 0;
        c[(E + 8) >> 2] = 0;
        c[(E + 12) >> 2] = 0;
        Nb((E + 16) | 0, c[j >> 2] | 0, (c[l >> 2] << 2) | 0) | 0;
        A = (E + (((c[l >> 2] | 0) + 4) << 2)) | 0;
        c[(A + 0) >> 2] = 0;
        c[(A + 4) >> 2] = 0;
        c[(A + 8) >> 2] = 0;
        c[(A + 12) >> 2] = 0;
        c[(A + 16) >> 2] = 0;
        c[u >> 2] =
          (((c[k >> 2] | 0) -
            (c[p >> 2] | 0) -
            ((c[l >> 2] | 0) - (c[m >> 2] | 0) + 1)) <<
            1) +
          (c[m >> 2] | 0);
        c[D >> 2] = (c[l >> 2] | 0) - (c[u >> 2] | 0);
        c[v >> 2] = (c[D >> 2] | 0) + 1 - 4;
        c[y >> 2] = F + (c[D >> 2] << 2);
        Lb(c[y >> 2] | 0, 0, (c[u >> 2] << 2) | 0) | 0;
        c[q >> 2] = 0;
        while (1) {
          if ((c[q >> 2] | 0) >= (c[u >> 2] | 0)) break;
          c[C >> 2] = E + (((c[v >> 2] | 0) + (c[q >> 2] | 0) + 4) << 2);
          c[B >> 2] = 2564;
          c[t >> 2] = 0;
          while (1) {
            if ((c[t >> 2] | 0) >= 8) break;
            A = c[C >> 2] | 0;
            c[C >> 2] = A + 4;
            H = +g[A >> 2];
            A = c[B >> 2] | 0;
            c[B >> 2] = A + -4;
            D = c[y >> 2] | 0;
            g[D >> 2] = +g[D >> 2] + H * +g[A >> 2];
            c[t >> 2] = (c[t >> 2] | 0) + 1;
          }
          c[y >> 2] = (c[y >> 2] | 0) + 4;
          c[q >> 2] = (c[q >> 2] | 0) + 1;
        }
        c[r >> 2] = ((c[u >> 2] | 0) / 2) | 0;
        c[s >> 2] = (c[r >> 2] | 0) - 5;
        Nb(
          c[h >> 2] | 0,
          (F + (c[l >> 2] << 2) + ((0 - (((c[u >> 2] | 0) / 2) | 0)) << 2)) | 0,
          (c[s >> 2] << 2) | 0
        ) | 0;
        g[o >> 2] = 0.20000000298023224;
        g[n >> 2] = 0.0;
        c[t >> 2] = c[s >> 2];
        while (1) {
          if ((c[t >> 2] | 0) >= (c[r >> 2] | 0)) break;
          g[((c[h >> 2] | 0) + (c[t >> 2] << 2)) >> 2] =
            (1.0 - +g[n >> 2]) *
              +g[
                (F +
                  (((c[l >> 2] | 0) -
                    (((c[u >> 2] | 0) / 2) | 0) +
                    (c[t >> 2] | 0)) <<
                    2)) >>
                  2
              ] +
            +g[n >> 2] *
              +g[
                (F +
                  (((c[l >> 2] | 0) - (c[u >> 2] | 0) + (c[t >> 2] | 0)) <<
                    2)) >>
                  2
              ];
          g[n >> 2] = +g[n >> 2] + +g[o >> 2];
          c[t >> 2] = (c[t >> 2] | 0) + 1;
        }
        Nb(
          ((c[h >> 2] | 0) + (c[r >> 2] << 2)) | 0,
          (F +
            (c[l >> 2] << 2) +
            ((0 - (c[u >> 2] | 0)) << 2) +
            (c[r >> 2] << 2)) |
            0,
          (((c[m >> 2] | 0) - (c[r >> 2] | 0)) << 2) | 0
        ) | 0;
      }
    while (0);
    i = G;
  }
  function eb(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    o = i;
    i = (i + 32) | 0;
    f = (o + 8) | 0;
    h = (o + 16) | 0;
    j = (o + 20) | 0;
    k = (o + 24) | 0;
    l = (o + 4) | 0;
    m = o;
    n = (o + 12) | 0;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    c[k >> 2] = e;
    c[l >> 2] = 0;
    while (1) {
      if ((c[l >> 2] | 0) > (c[k >> 2] | 0)) break;
      g[n >> 2] = 0.0;
      c[m >> 2] = 0;
      while (1) {
        if ((c[m >> 2] | 0) >= (((c[j >> 2] | 0) - (c[l >> 2] | 0)) | 0)) break;
        g[n >> 2] =
          +g[n >> 2] +
          +g[((c[h >> 2] | 0) + (c[m >> 2] << 2)) >> 2] *
            +g[
              ((c[h >> 2] | 0) + (((c[m >> 2] | 0) + (c[l >> 2] | 0)) << 2)) >>
                2
            ];
        c[m >> 2] = (c[m >> 2] | 0) + 1;
      }
      g[((c[f >> 2] | 0) + (c[l >> 2] << 2)) >> 2] = +g[n >> 2];
      c[l >> 2] = (c[l >> 2] | 0) + 1;
    }
    i = o;
  }
  function fb(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0;
    m = i;
    i = (i + 32) | 0;
    f = (m + 16) | 0;
    h = (m + 12) | 0;
    j = (m + 8) | 0;
    k = (m + 4) | 0;
    l = m;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    c[k >> 2] = e;
    c[l >> 2] = 0;
    while (1) {
      if ((c[l >> 2] | 0) >= (c[k >> 2] | 0)) break;
      g[((c[f >> 2] | 0) + (c[l >> 2] << 2)) >> 2] =
        +g[((c[h >> 2] | 0) + (c[l >> 2] << 2)) >> 2] *
        +g[((c[j >> 2] | 0) + (c[l >> 2] << 2)) >> 2];
      c[l >> 2] = (c[l >> 2] | 0) + 1;
    }
    i = m;
  }
  function gb(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0.0;
    q = i;
    i = (i + 48) | 0;
    f = (q + 28) | 0;
    h = (q + 16) | 0;
    j = (q + 4) | 0;
    k = (q + 8) | 0;
    p = (q + 32) | 0;
    l = q;
    n = (q + 12) | 0;
    o = (q + 24) | 0;
    m = (q + 20) | 0;
    c[f >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = d;
    c[k >> 2] = e;
    g[c[f >> 2] >> 2] = 1.0;
    a: do
      if (+g[c[j >> 2] >> 2] < 2.220446049250313e-16) {
        c[m >> 2] = 0;
        while (1) {
          if ((c[m >> 2] | 0) >= (c[k >> 2] | 0)) break a;
          g[((c[h >> 2] | 0) + (c[m >> 2] << 2)) >> 2] = 0.0;
          g[((c[f >> 2] | 0) + (((c[m >> 2] | 0) + 1) << 2)) >> 2] = 0.0;
          c[m >> 2] = (c[m >> 2] | 0) + 1;
        }
      } else {
        r = -+g[((c[j >> 2] | 0) + 4) >> 2] / +g[c[j >> 2] >> 2];
        g[c[h >> 2] >> 2] = r;
        g[((c[f >> 2] | 0) + 4) >> 2] = r;
        g[l >> 2] =
          +g[c[j >> 2] >> 2] +
          +g[((c[j >> 2] | 0) + 4) >> 2] * +g[c[h >> 2] >> 2];
        c[n >> 2] = 1;
        while (1) {
          if ((c[n >> 2] | 0) >= (c[k >> 2] | 0)) break a;
          g[p >> 2] = +g[((c[j >> 2] | 0) + (((c[n >> 2] | 0) + 1) << 2)) >> 2];
          c[m >> 2] = 0;
          while (1) {
            if ((c[m >> 2] | 0) >= (c[n >> 2] | 0)) break;
            g[p >> 2] =
              +g[p >> 2] +
              +g[((c[f >> 2] | 0) + (((c[m >> 2] | 0) + 1) << 2)) >> 2] *
                +g[
                  ((c[j >> 2] | 0) +
                    (((c[n >> 2] | 0) - (c[m >> 2] | 0)) << 2)) >>
                    2
                ];
            c[m >> 2] = (c[m >> 2] | 0) + 1;
          }
          g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] =
            -+g[p >> 2] / +g[l >> 2];
          g[l >> 2] =
            +g[l >> 2] +
            +g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] * +g[p >> 2];
          c[o >> 2] = ((c[n >> 2] | 0) + 1) >> 1;
          c[m >> 2] = 0;
          while (1) {
            if ((c[m >> 2] | 0) >= (c[o >> 2] | 0)) break;
            g[p >> 2] =
              +g[((c[f >> 2] | 0) + (((c[m >> 2] | 0) + 1) << 2)) >> 2] +
              +g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] *
                +g[
                  ((c[f >> 2] | 0) +
                    (((c[n >> 2] | 0) - (c[m >> 2] | 0)) << 2)) >>
                    2
                ];
            d =
              ((c[f >> 2] | 0) + (((c[n >> 2] | 0) - (c[m >> 2] | 0)) << 2)) |
              0;
            g[d >> 2] =
              +g[d >> 2] +
              +g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] *
                +g[((c[f >> 2] | 0) + (((c[m >> 2] | 0) + 1) << 2)) >> 2];
            g[((c[f >> 2] | 0) + (((c[m >> 2] | 0) + 1) << 2)) >> 2] =
              +g[p >> 2];
            c[m >> 2] = (c[m >> 2] | 0) + 1;
          }
          g[((c[f >> 2] | 0) + (((c[n >> 2] | 0) + 1) << 2)) >> 2] =
            +g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2];
          c[n >> 2] = (c[n >> 2] | 0) + 1;
        }
      }
    while (0);
    i = q;
  }
  function hb(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = +e;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0;
    p = i;
    i = (i + 32) | 0;
    h = (p + 8) | 0;
    j = (p + 16) | 0;
    k = (p + 20) | 0;
    l = (p + 24) | 0;
    m = (p + 4) | 0;
    n = p;
    o = (p + 12) | 0;
    c[h >> 2] = a;
    c[j >> 2] = b;
    c[k >> 2] = d;
    g[l >> 2] = e;
    c[m >> 2] = f;
    g[o >> 2] = 1.0 - +g[l >> 2];
    c[n >> 2] = 0;
    while (1) {
      if ((c[n >> 2] | 0) >= (c[m >> 2] | 0)) break;
      g[((c[h >> 2] | 0) + (c[n >> 2] << 2)) >> 2] =
        +g[l >> 2] * +g[((c[j >> 2] | 0) + (c[n >> 2] << 2)) >> 2] +
        +g[o >> 2] * +g[((c[k >> 2] | 0) + (c[n >> 2] << 2)) >> 2];
      c[n >> 2] = (c[n >> 2] | 0) + 1;
    }
    i = p;
  }
  function ib(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = +d;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0;
    n = i;
    i = (i + 32) | 0;
    f = (n + 20) | 0;
    h = (n + 16) | 0;
    j = (n + 12) | 0;
    k = (n + 8) | 0;
    m = (n + 4) | 0;
    l = n;
    c[f >> 2] = a;
    c[h >> 2] = b;
    g[j >> 2] = d;
    c[k >> 2] = e;
    g[l >> 2] = +g[j >> 2];
    g[c[f >> 2] >> 2] = +g[c[h >> 2] >> 2];
    c[m >> 2] = 1;
    while (1) {
      if ((c[m >> 2] | 0) >= (c[k >> 2] | 0)) break;
      g[((c[f >> 2] | 0) + (c[m >> 2] << 2)) >> 2] =
        +g[l >> 2] * +g[((c[h >> 2] | 0) + (c[m >> 2] << 2)) >> 2];
      g[l >> 2] = +g[l >> 2] * +g[j >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    i = n;
  }
  function jb(a, b, d, e, f, h) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    var j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0;
    w = i;
    i = (i + 64) | 0;
    q = (w + 44) | 0;
    r = (w + 36) | 0;
    s = (w + 20) | 0;
    j = (w + 16) | 0;
    k = (w + 32) | 0;
    t = (w + 40) | 0;
    u = (w + 48) | 0;
    m = (w + 8) | 0;
    o = (w + 4) | 0;
    v = (w + 24) | 0;
    l = (w + 12) | 0;
    p = (w + 28) | 0;
    n = w;
    c[q >> 2] = a;
    c[r >> 2] = b;
    c[s >> 2] = d;
    c[j >> 2] = e;
    c[k >> 2] = f;
    c[t >> 2] = h;
    c[o >> 2] = 0;
    g[n >> 2] = 9999999933815812510711506.0e12;
    c[v >> 2] = 0;
    c[m >> 2] = 0;
    while (1) {
      if ((c[m >> 2] | 0) >= (c[k >> 2] | 0)) break;
      g[l >> 2] =
        +g[c[j >> 2] >> 2] - +g[((c[s >> 2] | 0) + (c[o >> 2] << 2)) >> 2];
      g[l >> 2] = +g[l >> 2] * +g[l >> 2];
      c[u >> 2] = 1;
      while (1) {
        if ((c[u >> 2] | 0) >= (c[t >> 2] | 0)) break;
        g[p >> 2] =
          +g[((c[j >> 2] | 0) + (c[u >> 2] << 2)) >> 2] -
          +g[
            ((c[s >> 2] | 0) + (((c[o >> 2] | 0) + (c[u >> 2] | 0)) << 2)) >> 2
          ];
        g[l >> 2] = +g[l >> 2] + +g[p >> 2] * +g[p >> 2];
        c[u >> 2] = (c[u >> 2] | 0) + 1;
      }
      if (+g[l >> 2] < +g[n >> 2]) {
        g[n >> 2] = +g[l >> 2];
        c[v >> 2] = c[m >> 2];
      }
      c[o >> 2] = (c[o >> 2] | 0) + (c[t >> 2] | 0);
      c[m >> 2] = (c[m >> 2] | 0) + 1;
    }
    c[u >> 2] = 0;
    while (1) {
      j = c[v >> 2] | 0;
      if ((c[u >> 2] | 0) >= (c[t >> 2] | 0)) break;
      p = Z(j, c[t >> 2] | 0) | 0;
      g[((c[q >> 2] | 0) + (c[u >> 2] << 2)) >> 2] =
        +g[((c[s >> 2] | 0) + ((p + (c[u >> 2] | 0)) << 2)) >> 2];
      c[u >> 2] = (c[u >> 2] | 0) + 1;
    }
    c[c[r >> 2] >> 2] = j;
    i = w;
  }
  function kb(a, b, d, e, f, g, h) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    g = g | 0;
    h = h | 0;
    var j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0;
    t = i;
    i = (i + 48) | 0;
    j = t;
    k = (t + 16) | 0;
    l = (t + 28) | 0;
    m = (t + 4) | 0;
    n = (t + 32) | 0;
    o = (t + 36) | 0;
    p = (t + 12) | 0;
    r = (t + 24) | 0;
    q = (t + 20) | 0;
    s = (t + 8) | 0;
    c[j >> 2] = a;
    c[k >> 2] = b;
    c[l >> 2] = d;
    c[m >> 2] = e;
    c[n >> 2] = f;
    c[o >> 2] = g;
    c[p >> 2] = h;
    c[r >> 2] = 0;
    c[q >> 2] = 0;
    c[s >> 2] = 0;
    while (1) {
      if ((c[s >> 2] | 0) >= (c[n >> 2] | 0)) break;
      jb(
        ((c[j >> 2] | 0) + (c[q >> 2] << 2)) | 0,
        ((c[k >> 2] | 0) + (c[s >> 2] << 2)) | 0,
        ((c[m >> 2] | 0) + (c[r >> 2] << 2)) | 0,
        ((c[l >> 2] | 0) + (c[q >> 2] << 2)) | 0,
        c[((c[p >> 2] | 0) + (c[s >> 2] << 2)) >> 2] | 0,
        c[((c[o >> 2] | 0) + (c[s >> 2] << 2)) >> 2] | 0
      );
      c[q >> 2] =
        (c[q >> 2] | 0) + (c[((c[o >> 2] | 0) + (c[s >> 2] << 2)) >> 2] | 0);
      a =
        Z(
          c[((c[o >> 2] | 0) + (c[s >> 2] << 2)) >> 2] | 0,
          c[((c[p >> 2] | 0) + (c[s >> 2] << 2)) >> 2] | 0
        ) | 0;
      c[r >> 2] = (c[r >> 2] | 0) + a;
      c[s >> 2] = (c[s >> 2] | 0) + 1;
    }
    i = t;
  }
  function lb(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = +d;
    e = e | 0;
    f = f | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    o = i;
    i = (i + 32) | 0;
    j = (o + 20) | 0;
    k = (o + 16) | 0;
    l = (o + 12) | 0;
    m = (o + 8) | 0;
    h = (o + 4) | 0;
    n = o;
    c[j >> 2] = a;
    c[k >> 2] = b;
    g[l >> 2] = d;
    c[m >> 2] = e;
    c[h >> 2] = f;
    do
      if (!(+g[l >> 2] <= +g[c[m >> 2] >> 2])) {
        c[n >> 2] = 0;
        while (1) {
          if (!(+g[l >> 2] > +g[((c[m >> 2] | 0) + (c[n >> 2] << 2)) >> 2]))
            break;
          if ((c[n >> 2] | 0) >= (((c[h >> 2] | 0) - 1) | 0)) break;
          c[n >> 2] = (c[n >> 2] | 0) + 1;
        }
        h = c[n >> 2] | 0;
        if (
          +g[l >> 2] >
          (+g[((c[m >> 2] | 0) + (c[n >> 2] << 2)) >> 2] +
            +g[((c[m >> 2] | 0) + (((c[n >> 2] | 0) - 1) << 2)) >> 2]) /
            2.0
        ) {
          c[c[k >> 2] >> 2] = h;
          g[c[j >> 2] >> 2] = +g[((c[m >> 2] | 0) + (c[n >> 2] << 2)) >> 2];
          break;
        } else {
          c[c[k >> 2] >> 2] = h - 1;
          g[c[j >> 2] >> 2] =
            +g[((c[m >> 2] | 0) + (((c[n >> 2] | 0) - 1) << 2)) >> 2];
          break;
        }
      } else {
        c[c[k >> 2] >> 2] = 0;
        g[c[j >> 2] >> 2] = +g[c[m >> 2] >> 2];
      }
    while (0);
    i = o;
  }
  function mb(a, b, d) {
    a |= 0;
    b |= 0;
    d |= 0;
    let e = 0;
    var f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    var n = 0;
    var o = 0;
    var p = 0;
    var q = 0;
    q = i;
    i = (i + 48) | 0;
    e = q;
    f = (q + 16) | 0;
    h = (q + 28) | 0;
    l = (q + 4) | 0;
    n = (q + 32) | 0;
    m = (q + 36) | 0;
    j = (q + 12) | 0;
    k = (q + 24) | 0;
    o = (q + 20) | 0;
    p = (q + 8) | 0;
    c[e >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[j >> 2] = 2;
    c[k >> 2] = 0;
    c[n >> 2] = 0;
    while (1) {
      if ((c[n >> 2] | 0) >= (c[j >> 2] | 0)) break;
      c[m >> 2] = 0;
      while (1) {
        if ((c[m >> 2] | 0) >= (c[h >> 2] | 0)) break;
        c[l >> 2] = 0;
        while (1) {
          d = c[m >> 2] | 0;
          if ((c[l >> 2] | 0) >= (((c[f >> 2] | 0) - 1) | 0)) break;
          a = Z(d, c[f >> 2] | 0) | 0;
          c[o >> 2] = a + (c[l >> 2] | 0);
          if (
            +g[((c[e >> 2] | 0) + (((c[o >> 2] | 0) + 1) << 2)) >> 2] -
              +g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] <
            0.039000000804662704
          ) {
            d = c[o >> 2] | 0;
            if (
              +g[((c[e >> 2] | 0) + (((c[o >> 2] | 0) + 1) << 2)) >> 2] <
              +g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2]
            ) {
              g[p >> 2] = +g[((c[e >> 2] | 0) + ((d + 1) << 2)) >> 2];
              g[((c[e >> 2] | 0) + (((c[o >> 2] | 0) + 1) << 2)) >> 2] =
                +g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] +
                0.019500000402331352;
              g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] =
                +g[((c[e >> 2] | 0) + (((c[o >> 2] | 0) + 1) << 2)) >> 2] -
                0.019500000402331352;
            } else {
              a = ((c[e >> 2] | 0) + (d << 2)) | 0;
              g[a >> 2] = +g[a >> 2] - 0.019500000402331352;
              a = ((c[e >> 2] | 0) + (((c[o >> 2] | 0) + 1) << 2)) | 0;
              g[a >> 2] = +g[a >> 2] + 0.019500000402331352;
            }
            c[k >> 2] = 1;
          }
          if (
            +g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] < 0.009999999776482582
          ) {
            g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] = 0.009999999776482582;
            c[k >> 2] = 1;
          }
          if (
            +g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] > 3.140000104904175
          ) {
            g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] = 3.140000104904175;
            c[k >> 2] = 1;
          }
          c[l >> 2] = (c[l >> 2] | 0) + 1;
        }
        c[m >> 2] = d + 1;
      }
      c[n >> 2] = (c[n >> 2] | 0) + 1;
    }
    i = q;
    return c[k >> 2] | 0;
  }
  function nb(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    n = i;
    i = (i + 32) | 0;
    o = (n + 8) | 0;
    f = (n + 16) | 0;
    h = (n + 20) | 0;
    j = (n + 24) | 0;
    k = (n + 4) | 0;
    l = n;
    m = (n + 12) | 0;
    c[o >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[j >> 2] = e;
    c[l >> 2] = c[o >> 2];
    c[m >> 2] = c[h >> 2];
    c[k >> 2] = 0;
    while (1) {
      if ((c[k >> 2] | 0) >= (c[f >> 2] | 0)) break;
      g[c[m >> 2] >> 2] = +g[c[l >> 2] >> 2] * 0.9272743463516235;
      o = c[m >> 2] | 0;
      g[o >> 2] = +g[o >> 2] + +g[c[j >> 2] >> 2] * -1.8544940948486328;
      o = c[m >> 2] | 0;
      g[o >> 2] =
        +g[o >> 2] + +g[((c[j >> 2] | 0) + 4) >> 2] * 0.9272743463516235;
      g[((c[j >> 2] | 0) + 4) >> 2] = +g[c[j >> 2] >> 2];
      g[c[j >> 2] >> 2] = +g[c[l >> 2] >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 4;
      c[l >> 2] = (c[l >> 2] | 0) + 4;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    c[m >> 2] = c[h >> 2];
    c[k >> 2] = 0;
    while (1) {
      if ((c[k >> 2] | 0) >= (c[f >> 2] | 0)) break;
      o = c[m >> 2] | 0;
      g[o >> 2] =
        +g[o >> 2] - +g[((c[j >> 2] | 0) + 8) >> 2] * -1.9059464931488037;
      o = c[m >> 2] | 0;
      g[o >> 2] =
        +g[o >> 2] - +g[((c[j >> 2] | 0) + 12) >> 2] * 0.9114024043083191;
      g[((c[j >> 2] | 0) + 12) >> 2] = +g[((c[j >> 2] | 0) + 8) >> 2];
      g[((c[j >> 2] | 0) + 8) >> 2] = +g[c[m >> 2] >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 4;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    i = n;
  }
  function ob(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0;
    n = i;
    i = (i + 32) | 0;
    o = (n + 8) | 0;
    f = (n + 16) | 0;
    h = (n + 20) | 0;
    j = (n + 24) | 0;
    k = (n + 4) | 0;
    l = n;
    m = (n + 12) | 0;
    c[o >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[j >> 2] = e;
    c[l >> 2] = c[o >> 2];
    c[m >> 2] = c[h >> 2];
    c[k >> 2] = 0;
    while (1) {
      if ((c[k >> 2] | 0) >= (c[f >> 2] | 0)) break;
      g[c[m >> 2] >> 2] = +g[c[l >> 2] >> 2] * 0.939805805683136;
      o = c[m >> 2] | 0;
      g[o >> 2] = +g[o >> 2] + +g[c[j >> 2] >> 2] * -1.8795833587646484;
      o = c[m >> 2] | 0;
      g[o >> 2] =
        +g[o >> 2] + +g[((c[j >> 2] | 0) + 4) >> 2] * 0.939805805683136;
      g[((c[j >> 2] | 0) + 4) >> 2] = +g[c[j >> 2] >> 2];
      g[c[j >> 2] >> 2] = +g[c[l >> 2] >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 4;
      c[l >> 2] = (c[l >> 2] | 0) + 4;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    c[m >> 2] = c[h >> 2];
    c[k >> 2] = 0;
    while (1) {
      if ((c[k >> 2] | 0) >= (c[f >> 2] | 0)) break;
      o = c[m >> 2] | 0;
      g[o >> 2] =
        +g[o >> 2] - +g[((c[j >> 2] | 0) + 8) >> 2] * -1.9330735206604004;
      o = c[m >> 2] | 0;
      g[o >> 2] =
        +g[o >> 2] - +g[((c[j >> 2] | 0) + 12) >> 2] * 0.9358919858932495;
      g[((c[j >> 2] | 0) + 12) >> 2] = +g[((c[j >> 2] | 0) + 8) >> 2];
      g[((c[j >> 2] | 0) + 8) >> 2] = +g[c[m >> 2] >> 2];
      c[m >> 2] = (c[m >> 2] | 0) + 4;
      c[k >> 2] = (c[k >> 2] | 0) + 1;
    }
    i = n;
  }
  function pb(a) {
    a = a | 0;
    var b = 0,
      d = 0,
      e = 0,
      f = 0;
    f = i;
    i = (i + 16) | 0;
    b = (f + 4) | 0;
    d = f;
    c[b >> 2] = a;
    c[d >> 2] = 1;
    while (1) {
      if ((c[d >> 2] | 0) >= 3) break;
      if (
        (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) >= 108
          ? (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) < 172
          : 0
      ) {
        a = ((c[b >> 2] | 0) + (c[d >> 2] << 2)) | 0;
        c[a >> 2] = (c[a >> 2] | 0) - 64;
      } else e = 6;
      if (
        (e | 0) == 6
          ? ((e = 0), (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) >= 236)
          : 0
      ) {
        a = ((c[b >> 2] | 0) + (c[d >> 2] << 2)) | 0;
        c[a >> 2] = (c[a >> 2] | 0) - 128;
      }
      c[d >> 2] = (c[d >> 2] | 0) + 1;
    }
    i = f;
  }
  function qb(a) {
    a = a | 0;
    var b = 0,
      d = 0,
      e = 0,
      f = 0;
    f = i;
    i = (i + 16) | 0;
    b = (f + 4) | 0;
    d = f;
    c[b >> 2] = a;
    c[d >> 2] = 1;
    while (1) {
      if ((c[d >> 2] | 0) >= 3) break;
      if (
        (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) >= 44
          ? (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) < 108
          : 0
      ) {
        a = ((c[b >> 2] | 0) + (c[d >> 2] << 2)) | 0;
        c[a >> 2] = (c[a >> 2] | 0) + 64;
      } else e = 6;
      if (
        (
          (e | 0) == 6
            ? ((e = 0),
              (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) >= 108)
            : 0
        )
          ? (c[((c[b >> 2] | 0) + (c[d >> 2] << 2)) >> 2] | 0) < 128
          : 0
      ) {
        a = ((c[b >> 2] | 0) + (c[d >> 2] << 2)) | 0;
        c[a >> 2] = (c[a >> 2] | 0) + 128;
      }
      c[d >> 2] = (c[d >> 2] | 0) + 1;
    }
    i = f;
  }
  function rb(a, b, d, e, f, h, j) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    var k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0;
    v = i;
    i = (i + 224) | 0;
    l = (v + 204) | 0;
    m = (v + 184) | 0;
    k = v;
    n = (v + 196) | 0;
    o = (v + 200) | 0;
    p = (v + 208) | 0;
    q = (v + 180) | 0;
    t = (v + 192) | 0;
    u = (v + 188) | 0;
    s = (v + 168) | 0;
    r = (v + 8) | 0;
    c[l >> 2] = a;
    c[m >> 2] = b;
    c[k >> 2] = d;
    c[n >> 2] = e;
    c[o >> 2] = f;
    c[p >> 2] = h;
    c[q >> 2] = j;
    g[s >> 2] = +cb(c[c[k >> 2] >> 2] | 0, 1.0, 32);
    if ((c[q >> 2] | 0) > 1)
      g[(s + 4) >> 2] = +cb(
        c[((c[k >> 2] | 0) + 4) >> 2] | 0,
        +M(+(+g[s >> 2])),
        16
      );
    if ((c[q >> 2] | 0) > 2)
      g[(s + 8) >> 2] = +cb(
        c[((c[k >> 2] | 0) + 8) >> 2] | 0,
        +M(+(+g[(s + 4) >> 2])),
        8
      );
    db(r, c[n >> 2] | 0, c[c[m >> 2] >> 2] | 0, c[o >> 2] | 0, c[p >> 2] | 0);
    c[t >> 2] = 0;
    while (1) {
      if ((c[t >> 2] | 0) >= (c[p >> 2] | 0)) break;
      g[((c[l >> 2] | 0) + (c[t >> 2] << 2)) >> 2] =
        +g[s >> 2] * +g[(r + (c[t >> 2] << 2)) >> 2];
      c[t >> 2] = (c[t >> 2] | 0) + 1;
    }
    a: do
      if ((c[q >> 2] | 0) > 1) {
        c[u >> 2] = 1;
        while (1) {
          if ((c[u >> 2] | 0) >= (c[q >> 2] | 0)) break a;
          db(
            r,
            c[n >> 2] | 0,
            c[((c[m >> 2] | 0) + (c[u >> 2] << 2)) >> 2] | 0,
            c[o >> 2] | 0,
            c[p >> 2] | 0
          );
          c[t >> 2] = 0;
          while (1) {
            k = c[u >> 2] | 0;
            if ((c[t >> 2] | 0) >= (c[p >> 2] | 0)) break;
            h = ((c[l >> 2] | 0) + (c[t >> 2] << 2)) | 0;
            g[h >> 2] =
              +g[h >> 2] +
              +g[(s + (k << 2)) >> 2] * +g[(r + (c[t >> 2] << 2)) >> 2];
            c[t >> 2] = (c[t >> 2] | 0) + 1;
          }
          c[u >> 2] = k + 1;
        }
      }
    while (0);
    i = v;
  }
  function sb(a, b, d, e, f, h, j, k, l, m, n) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    l = l | 0;
    m = m | 0;
    n = n | 0;
    var o = 0.0,
      p = 0.0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0,
      I = 0,
      J = 0,
      K = 0,
      L = 0,
      N = 0,
      O = 0,
      P = 0,
      Q = 0,
      R = 0,
      S = 0,
      T = 0,
      U = 0,
      V = 0,
      W = 0,
      X = 0,
      Y = 0,
      _ = 0,
      $ = 0,
      aa = 0,
      ba = 0,
      ca = 0,
      da = 0,
      ea = 0,
      fa = 0,
      ga = 0,
      ha = 0,
      ia = 0,
      ja = 0,
      ka = 0;
    ka = i;
    i = (i + 4112) | 0;
    u = (ka + 828) | 0;
    v = (ka + 824) | 0;
    x = (ka + 820) | 0;
    r = (ka + 4084) | 0;
    s = (ka + 4092) | 0;
    y = (ka + 4076) | 0;
    z = (ka + 20) | 0;
    A = (ka + 24) | 0;
    t = (ka + 28) | 0;
    q = (ka + 32) | 0;
    w = (ka + 36) | 0;
    T = (ka + 40) | 0;
    W = (ka + 44) | 0;
    U = (ka + 48) | 0;
    ha = (ka + 52) | 0;
    E = (ka + 56) | 0;
    ea = (ka + 60) | 0;
    I = (ka + 64) | 0;
    X = (ka + 3908) | 0;
    R = (ka + 4072) | 0;
    Y = (ka + 4080) | 0;
    J = (ka + 4088) | 0;
    Q = (ka + 4096) | 0;
    S = (ka + 4100) | 0;
    ia = (ka + 832) | 0;
    C = (ka + 992) | 0;
    fa = (ka + 996) | 0;
    L = (ka + 1e3) | 0;
    D = (ka + 1004) | 0;
    ga = (ka + 1008) | 0;
    N = (ka + 1012) | 0;
    F = (ka + 1016) | 0;
    V = (ka + 1848) | 0;
    O = (ka + 2872) | 0;
    $ = (ka + 3896) | 0;
    ba = (ka + 3900) | 0;
    ca = (ka + 16) | 0;
    aa = (ka + 12) | 0;
    da = (ka + 3904) | 0;
    G = (ka + 68) | 0;
    ja = ka;
    H = (ka + 8) | 0;
    K = (ka + 656) | 0;
    B = (ka + 3912) | 0;
    P = (ka + 4) | 0;
    _ = (ka + 816) | 0;
    c[u >> 2] = a;
    c[v >> 2] = b;
    c[x >> 2] = d;
    c[r >> 2] = e;
    c[s >> 2] = f;
    c[y >> 2] = h;
    c[z >> 2] = j;
    c[A >> 2] = k;
    c[t >> 2] = l;
    c[q >> 2] = m;
    c[w >> 2] = n;
    c[ga >> 2] = 0;
    c[N >> 2] = 0;
    c[ba >> 2] = 0;
    c[ca >> 2] = 0;
    c[aa >> 2] = 0;
    Lb(K | 0, 0, 160) | 0;
    c[D >> 2] = (c[y >> 2] | 0) - (c[z >> 2] | 0) + 1;
    if ((c[z >> 2] | 0) == 40)
      c[D >> 2] =
        (c[y >> 2] | 0) - (c[z >> 2] | 0) + 1 + (((c[z >> 2] | 0) / 2) | 0);
    a = (F + 0) | 0;
    n = ((c[q >> 2] | 0) + 0) | 0;
    d = (a + 40) | 0;
    do {
      c[a >> 2] = c[n >> 2];
      a = (a + 4) | 0;
      n = (n + 4) | 0;
    } while ((a | 0) < (d | 0));
    Nb((F + 40) | 0, c[s >> 2] | 0, (c[y >> 2] << 2) | 0) | 0;
    Nb((F + 40 + (c[y >> 2] << 2)) | 0, c[r >> 2] | 0, (c[z >> 2] << 2) | 0) |
      0;
    Za(
      (F + 40) | 0,
      c[t >> 2] | 0,
      ((c[y >> 2] | 0) + (c[z >> 2] | 0)) | 0,
      10
    );
    Nb(ia | 0, (F + 40 + (c[y >> 2] << 2)) | 0, (c[z >> 2] << 2) | 0) | 0;
    g[ja >> 2] = 0.0;
    c[T >> 2] = 0;
    while (1) {
      if ((c[T >> 2] | 0) >= (c[z >> 2] | 0)) break;
      g[ja >> 2] =
        +g[ja >> 2] +
        +g[(ia + (c[T >> 2] << 2)) >> 2] * +g[(ia + (c[T >> 2] << 2)) >> 2];
      c[T >> 2] = (c[T >> 2] | 0) + 1;
    }
    La(G, (F + 40) | 0, c[y >> 2] | 0);
    c[ha >> 2] = 0;
    while (1) {
      if ((c[ha >> 2] | 0) >= (c[A >> 2] | 0)) break;
      c[ea >> 2] =
        c[(2456 + (((c[w >> 2] | 0) * 12) | 0) + (c[ha >> 2] << 2)) >> 2];
      g[X >> 2] = -1.0e7;
      g[R >> 2] = 0.0;
      c[E >> 2] = 0;
      g[J >> 2] = 0.0;
      c[$ >> 2] = F + 40 + (c[y >> 2] << 2) + ((0 - (c[z >> 2] | 0)) << 2);
      c[W >> 2] = 0;
      while (1) {
        if ((c[W >> 2] | 0) >= (c[z >> 2] | 0)) break;
        p = +g[(ia + (c[W >> 2] << 2)) >> 2];
        t = c[$ >> 2] | 0;
        c[$ >> 2] = t + 4;
        g[J >> 2] = +g[J >> 2] + p * +g[t >> 2];
        c[W >> 2] = (c[W >> 2] | 0) + 1;
      }
      if (!(c[ha >> 2] | 0)) {
        c[aa >> 2] = O;
        c[ba >> 2] =
          F + 40 + (c[y >> 2] << 2) + ((0 - (c[z >> 2] | 0)) << 2) + -4;
        c[ca >> 2] = F + 40 + (c[y >> 2] << 2) + -4;
        g[c[aa >> 2] >> 2] = 0.0;
        c[$ >> 2] = F + 40 + (c[y >> 2] << 2) + ((0 - (c[z >> 2] | 0)) << 2);
        c[W >> 2] = 0;
        while (1) {
          if ((c[W >> 2] | 0) >= (c[z >> 2] | 0)) break;
          t = c[$ >> 2] | 0;
          c[$ >> 2] = t + 4;
          g[da >> 2] = +g[t >> 2];
          t = c[aa >> 2] | 0;
          g[t >> 2] = +g[t >> 2] + +g[da >> 2] * +g[da >> 2];
          c[W >> 2] = (c[W >> 2] | 0) + 1;
        }
        if (+g[c[aa >> 2] >> 2] > 0.0)
          g[V >> 2] = 1.0 / (+g[c[aa >> 2] >> 2] + 2.220446049250313e-16);
        else g[V >> 2] = 0.0;
        c[aa >> 2] = (c[aa >> 2] | 0) + 4;
        g[Y >> 2] = -1.0e7;
        if (+g[J >> 2] > 0.0) g[Y >> 2] = +g[J >> 2] * +g[J >> 2] * +g[V >> 2];
      } else g[Y >> 2] = +g[J >> 2] * +g[J >> 2] * +g[V >> 2];
      g[Q >> 2] = +g[J >> 2] * +g[V >> 2];
      if (
        +g[Y >> 2] > +g[X >> 2] ? +M(+(+g[Q >> 2])) < 1.2999999523162842 : 0
      ) {
        c[E >> 2] = 0;
        g[X >> 2] = +g[Y >> 2];
        g[R >> 2] = +g[Q >> 2];
      }
      c[U >> 2] = 1;
      while (1) {
        if ((c[U >> 2] | 0) >= (c[ea >> 2] | 0)) break;
        g[J >> 2] = 0.0;
        c[$ >> 2] =
          F +
          40 +
          (c[y >> 2] << 2) +
          ((0 - (c[z >> 2] | 0)) << 2) +
          ((0 - (c[U >> 2] | 0)) << 2);
        c[W >> 2] = 0;
        while (1) {
          if ((c[W >> 2] | 0) >= (c[z >> 2] | 0)) break;
          p = +g[(ia + (c[W >> 2] << 2)) >> 2];
          t = c[$ >> 2] | 0;
          c[$ >> 2] = t + 4;
          g[J >> 2] = +g[J >> 2] + p * +g[t >> 2];
          c[W >> 2] = (c[W >> 2] | 0) + 1;
        }
        if (!(c[ha >> 2] | 0)) {
          p =
            +g[(O + (((c[U >> 2] | 0) - 1) << 2)) >> 2] +
            +g[c[ba >> 2] >> 2] * +g[c[ba >> 2] >> 2] -
            +g[c[ca >> 2] >> 2] * +g[c[ca >> 2] >> 2];
          a = c[aa >> 2] | 0;
          c[aa >> 2] = a + 4;
          g[a >> 2] = p;
          c[ca >> 2] = (c[ca >> 2] | 0) + -4;
          c[ba >> 2] = (c[ba >> 2] | 0) + -4;
          a = c[U >> 2] | 0;
          if (+g[(O + (c[U >> 2] << 2)) >> 2] > 0.0)
            g[(V + (c[U >> 2] << 2)) >> 2] =
              1.0 / (+g[(O + (a << 2)) >> 2] + 2.220446049250313e-16);
          else g[(V + (a << 2)) >> 2] = 0.0;
          g[Y >> 2] = -1.0e7;
          if (+g[J >> 2] > 0.0)
            g[Y >> 2] =
              +g[J >> 2] * +g[J >> 2] * +g[(V + (c[U >> 2] << 2)) >> 2];
        } else
          g[Y >> 2] = +g[J >> 2] * +g[J >> 2] * +g[(V + (c[U >> 2] << 2)) >> 2];
        g[Q >> 2] = +g[J >> 2] * +g[(V + (c[U >> 2] << 2)) >> 2];
        if (
          +g[Y >> 2] > +g[X >> 2] ? +M(+(+g[Q >> 2])) < 1.2999999523162842 : 0
        ) {
          c[E >> 2] = c[U >> 2];
          g[X >> 2] = +g[Y >> 2];
          g[R >> 2] = +g[Q >> 2];
        }
        c[U >> 2] = (c[U >> 2] | 0) + 1;
      }
      if ((c[z >> 2] | 0) == 40)
        Ma(
          20,
          39,
          c[ha >> 2] | 0,
          ((c[D >> 2] | 0) - (((c[z >> 2] | 0) / 2) | 0)) | 0,
          ia,
          (F + 40 + (c[y >> 2] << 2)) | 0,
          X,
          E,
          R,
          O,
          V
        );
      c[C >> 2] = c[E >> 2];
      c[ga >> 2] = 0;
      c[N >> 2] = 0;
      c[fa >> 2] = (c[C >> 2] | 0) - 17;
      c[L >> 2] = (c[fa >> 2] | 0) + 34;
      a = (c[fa >> 2] | 0) < 0;
      do
        if ((c[z >> 2] | 0) == 40) {
          if (a) {
            c[ga >> 2] = 40 + (c[fa >> 2] | 0);
            c[N >> 2] = 39;
            c[fa >> 2] = 0;
            break;
          }
          if ((c[C >> 2] | 0) < (((c[D >> 2] | 0) - 20) | 0)) {
            if ((c[L >> 2] | 0) <= (c[ea >> 2] | 0)) break;
            c[fa >> 2] =
              (c[fa >> 2] | 0) - ((c[L >> 2] | 0) - (c[ea >> 2] | 0));
            c[L >> 2] = c[ea >> 2];
            break;
          }
          if ((c[fa >> 2] | 0) >= (((c[D >> 2] | 0) - 20) | 0)) {
            c[ga >> 2] = 20 + (c[fa >> 2] | 0) - ((c[D >> 2] | 0) - 20);
            c[N >> 2] = 39;
            c[fa >> 2] = 0;
            c[L >> 2] = 34 - ((c[N >> 2] | 0) - (c[ga >> 2] | 0) + 1);
            break;
          }
          c[ga >> 2] = 20;
          c[fa >> 2] = 0;
          c[L >> 2] = 0;
          c[N >> 2] = 53;
          if ((c[N >> 2] | 0) > 39) {
            c[L >> 2] = (c[N >> 2] | 0) - 39;
            c[N >> 2] = 39;
          }
        } else {
          if (a) {
            c[L >> 2] = (c[L >> 2] | 0) - (c[fa >> 2] | 0);
            c[fa >> 2] = 0;
          }
          if ((c[L >> 2] | 0) > (c[ea >> 2] | 0)) {
            c[fa >> 2] =
              (c[fa >> 2] | 0) - ((c[L >> 2] | 0) - (c[ea >> 2] | 0));
            c[L >> 2] = c[ea >> 2];
          }
        }
      while (0);
      c[I >> 2] = c[fa >> 2];
      c[fa >> 2] = (c[fa >> 2] | 0) + (c[D >> 2] | 0);
      c[L >> 2] = (c[L >> 2] | 0) + (c[D >> 2] | 0);
      a: do
        if (!(c[ha >> 2] | 0)) {
          c[aa >> 2] = O + (c[D >> 2] << 2);
          g[c[aa >> 2] >> 2] = 0.0;
          c[$ >> 2] = G + (c[y >> 2] << 2) + ((0 - (c[z >> 2] | 0)) << 2);
          c[W >> 2] = 0;
          while (1) {
            if ((c[W >> 2] | 0) >= (c[z >> 2] | 0)) break;
            t = c[$ >> 2] | 0;
            c[$ >> 2] = t + 4;
            g[da >> 2] = +g[t >> 2];
            t = c[aa >> 2] | 0;
            g[t >> 2] = +g[t >> 2] + +g[da >> 2] * +g[da >> 2];
            c[W >> 2] = (c[W >> 2] | 0) + 1;
          }
          c[ba >> 2] = G + (c[y >> 2] << 2) + -4 + ((0 - (c[z >> 2] | 0)) << 2);
          c[ca >> 2] = G + (c[y >> 2] << 2) + -4;
          c[W >> 2] = 0;
          while (1) {
            if ((c[W >> 2] | 0) >= (((c[ea >> 2] | 0) - 1) | 0)) break a;
            g[((c[aa >> 2] | 0) + 4) >> 2] =
              +g[c[aa >> 2] >> 2] +
              +g[c[ba >> 2] >> 2] * +g[c[ba >> 2] >> 2] -
              +g[c[ca >> 2] >> 2] * +g[c[ca >> 2] >> 2];
            c[ca >> 2] = (c[ca >> 2] | 0) + -4;
            c[ba >> 2] = (c[ba >> 2] | 0) + -4;
            c[aa >> 2] = (c[aa >> 2] | 0) + 4;
            c[W >> 2] = (c[W >> 2] | 0) + 1;
          }
        }
      while (0);
      c[U >> 2] = c[fa >> 2];
      while (1) {
        if ((c[U >> 2] | 0) >= (c[L >> 2] | 0)) break;
        g[J >> 2] = 0.0;
        s = (G + (c[y >> 2] << 2)) | 0;
        t = c[I >> 2] | 0;
        c[I >> 2] = t + 1;
        c[$ >> 2] = s + ((0 - t) << 2) + ((0 - (c[z >> 2] | 0)) << 2);
        c[W >> 2] = 0;
        while (1) {
          if ((c[W >> 2] | 0) >= (c[z >> 2] | 0)) break;
          p = +g[(ia + (c[W >> 2] << 2)) >> 2];
          t = c[$ >> 2] | 0;
          c[$ >> 2] = t + 4;
          g[J >> 2] = +g[J >> 2] + p * +g[t >> 2];
          c[W >> 2] = (c[W >> 2] | 0) + 1;
        }
        a = c[U >> 2] | 0;
        if (+g[(O + (c[U >> 2] << 2)) >> 2] > 0.0)
          g[(V + (c[U >> 2] << 2)) >> 2] =
            1.0 / (+g[(O + (a << 2)) >> 2] + 2.220446049250313e-16);
        else g[(V + (a << 2)) >> 2] = 0.0;
        if (
          !((c[ha >> 2] | 0) == 0
            ? ((g[Y >> 2] = -1.0e7), !(+g[J >> 2] > 0.0))
            : 0)
        )
          g[Y >> 2] = +g[J >> 2] * +g[J >> 2] * +g[(V + (c[U >> 2] << 2)) >> 2];
        g[Q >> 2] = +g[J >> 2] * +g[(V + (c[U >> 2] << 2)) >> 2];
        if (
          +g[Y >> 2] > +g[X >> 2] ? +M(+(+g[Q >> 2])) < 1.2999999523162842 : 0
        ) {
          c[E >> 2] = c[U >> 2];
          g[X >> 2] = +g[Y >> 2];
          g[R >> 2] = +g[Q >> 2];
        }
        c[U >> 2] = (c[U >> 2] | 0) + 1;
      }
      if (((c[z >> 2] | 0) == 40) & ((c[ga >> 2] | 0) != 0))
        Ma(
          c[ga >> 2] | 0,
          c[N >> 2] | 0,
          c[ha >> 2] | 0,
          ((c[D >> 2] << 1) - 20) | 0,
          ia,
          (G + (c[y >> 2] << 2)) | 0,
          X,
          E,
          R,
          O,
          V
        );
      c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] = c[E >> 2];
      do
        if (c[ha >> 2] | 0) {
          o = +g[R >> 2];
          p = +M(+(+g[(S + (((c[ha >> 2] | 0) - 1) << 2)) >> 2]));
          a = ((c[x >> 2] | 0) + (c[ha >> 2] << 2)) | 0;
          if ((c[ha >> 2] | 0) == 1) {
            g[R >> 2] = +bb(o, p, 16, a);
            break;
          } else {
            g[R >> 2] = +bb(o, p, 8, a);
            break;
          }
        } else {
          if (+g[R >> 2] < 0.0) g[R >> 2] = 0.0;
          if (+g[R >> 2] > 1.2999999523162842) g[R >> 2] = 1.2999999523162842;
          g[R >> 2] = +bb(
            +g[R >> 2],
            1.0,
            32,
            ((c[x >> 2] | 0) + (c[ha >> 2] << 2)) | 0
          );
        }
      while (0);
      a = (c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0) < (c[D >> 2] | 0);
      do
        if (
          (c[z >> 2] | 0) ==
          ((80 - (c[((c[u >> 2] | 0) + 28) >> 2] | 0)) | 0)
        )
          if (a) {
            c[$ >> 2] =
              F +
              40 +
              (c[y >> 2] << 2) +
              ((0 - (c[z >> 2] | 0)) << 2) +
              ((0 - (c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0)) << 2);
            break;
          } else {
            c[$ >> 2] =
              G +
              (c[y >> 2] << 2) +
              ((0 - (c[z >> 2] | 0)) << 2) +
              ((0 - (c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0)) << 2) +
              (c[D >> 2] << 2);
            break;
          }
        else {
          d = c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0;
          n = c[D >> 2] | 0;
          if (a)
            if ((d | 0) < ((n - 20) | 0)) {
              c[$ >> 2] =
                F +
                40 +
                (c[y >> 2] << 2) +
                ((0 - (c[z >> 2] | 0)) << 2) +
                ((0 - (c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0)) <<
                  2);
              break;
            } else {
              Na(
                ((c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0) -
                  (c[D >> 2] | 0) +
                  40) |
                  0,
                (F + 40 + (c[y >> 2] << 2)) | 0,
                B
              );
              c[$ >> 2] = B;
              break;
            }
          else {
            c[P >> 2] = ((d | 0) / (n | 0)) | 0;
            c[_ >> 2] =
              (c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0) -
              (Z(c[P >> 2] | 0, c[D >> 2] | 0) | 0);
            if ((c[_ >> 2] | 0) < (((c[D >> 2] | 0) - 20) | 0)) {
              t = (G + ((Z(c[P >> 2] | 0, c[y >> 2] | 0) | 0) << 2)) | 0;
              c[$ >> 2] =
                t +
                ((0 - (c[z >> 2] | 0)) << 2) +
                ((0 - (c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0)) <<
                  2) +
                ((Z(c[P >> 2] | 0, c[D >> 2] | 0) | 0) << 2);
              break;
            } else {
              t =
                ((c[((c[v >> 2] | 0) + (c[ha >> 2] << 2)) >> 2] | 0) -
                  (Z(((c[P >> 2] | 0) + 1) | 0, c[D >> 2] | 0) | 0) +
                  40) |
                0;
              Na(t, (G + ((Z(c[P >> 2] | 0, c[y >> 2] | 0) | 0) << 2)) | 0, B);
              c[$ >> 2] = B;
              break;
            }
          }
        }
      while (0);
      c[W >> 2] = 0;
      while (1) {
        o = +g[R >> 2];
        if ((c[W >> 2] | 0) >= (c[z >> 2] | 0)) break;
        s = (K + (c[W >> 2] << 2)) | 0;
        g[s >> 2] = +g[s >> 2] + o * +g[c[$ >> 2] >> 2];
        p = +g[R >> 2];
        s = c[$ >> 2] | 0;
        c[$ >> 2] = s + 4;
        t = (ia + (c[W >> 2] << 2)) | 0;
        g[t >> 2] = +g[t >> 2] - p * +g[s >> 2];
        c[W >> 2] = (c[W >> 2] | 0) + 1;
      }
      g[(S + (c[ha >> 2] << 2)) >> 2] = o;
      c[ha >> 2] = (c[ha >> 2] | 0) + 1;
    }
    g[H >> 2] = 0.0;
    c[T >> 2] = 0;
    while (1) {
      if ((c[T >> 2] | 0) >= (c[z >> 2] | 0)) break;
      g[H >> 2] =
        +g[H >> 2] +
        +g[(K + (c[T >> 2] << 2)) >> 2] * +g[(K + (c[T >> 2] << 2)) >> 2];
      c[T >> 2] = (c[T >> 2] | 0) + 1;
    }
    c[W >> 2] = c[c[x >> 2] >> 2];
    c[T >> 2] = c[c[x >> 2] >> 2];
    while (1) {
      if ((c[T >> 2] | 0) >= 32) break;
      g[Q >> 2] =
        +g[H >> 2] *
        +g[(2664 + (c[T >> 2] << 2)) >> 2] *
        +g[(2664 + (c[T >> 2] << 2)) >> 2];
      if (
        +g[Q >> 2] < +g[ja >> 2] * +g[S >> 2] * +g[S >> 2]
          ? +g[(2664 + (c[W >> 2] << 2)) >> 2] < +g[S >> 2] * 2.0
          : 0
      )
        c[W >> 2] = c[T >> 2];
      c[T >> 2] = (c[T >> 2] | 0) + 1;
    }
    c[c[x >> 2] >> 2] = c[W >> 2];
    i = ka;
  }
  function tb(a, b, d) {
    a |= 0;
    b |= 0;
    d |= 0;
    let e = 0;
    var f = 0;
    var h = 0;
    var j = 0;
    var k = 0;
    k = i;
    i = (i + 16) | 0;
    f = (k + 12) | 0;
    e = (k + 8) | 0;
    h = (k + 4) | 0;
    j = k;
    c[f >> 2] = a;
    c[e >> 2] = b;
    c[h >> 2] = d;
    c[c[f >> 2] >> 2] = c[e >> 2];
    do
      if ((c[e >> 2] | 0) != 30)
        if ((c[e >> 2] | 0) == 20) {
          c[((c[f >> 2] | 0) + 4) >> 2] = 160;
          c[((c[f >> 2] | 0) + 8) >> 2] = 4;
          c[((c[f >> 2] | 0) + 12) >> 2] = 2;
          c[((c[f >> 2] | 0) + 24) >> 2] = 1;
          c[((c[f >> 2] | 0) + 16) >> 2] = 38;
          c[((c[f >> 2] | 0) + 20) >> 2] = 19;
          c[((c[f >> 2] | 0) + 28) >> 2] = 57;
          c[((c[f >> 2] | 0) + 32) >> 2] = 56;
          break;
        } else ma(2);
      else {
        c[((c[f >> 2] | 0) + 4) >> 2] = 240;
        c[((c[f >> 2] | 0) + 8) >> 2] = 6;
        c[((c[f >> 2] | 0) + 12) >> 2] = 4;
        c[((c[f >> 2] | 0) + 24) >> 2] = 2;
        c[((c[f >> 2] | 0) + 16) >> 2] = 50;
        c[((c[f >> 2] | 0) + 20) >> 2] = 25;
        c[((c[f >> 2] | 0) + 28) >> 2] = 58;
        c[((c[f >> 2] | 0) + 32) >> 2] = 1096;
      }
    while (0);
    b = ((c[f >> 2] | 0) + 36) | 0;
    e = (b + 40) | 0;
    do {
      c[b >> 2] = 0;
      b = (b + 4) | 0;
    } while ((b | 0) < (e | 0));
    b = ((c[f >> 2] | 0) + 76) | 0;
    a = 2968 | 0;
    e = (b + 40) | 0;
    do {
      c[b >> 2] = c[a >> 2];
      b = (b + 4) | 0;
      a = (a + 4) | 0;
    } while ((b | 0) < (e | 0));
    Lb(((c[f >> 2] | 0) + 1148) | 0, 0, 264) | 0;
    c[j >> 2] = 0;
    while (1) {
      if ((c[j >> 2] | 0) >= 6) break;
      g[((c[f >> 2] | 0) + 1148 + (((c[j >> 2] | 0) * 11) << 2)) >> 2] = 1.0;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    c[((c[f >> 2] | 0) + 116) >> 2] = 20;
    c[((c[f >> 2] | 0) + 120) >> 2] = 120;
    g[((c[f >> 2] | 0) + 1140) >> 2] = 0.0;
    c[((c[f >> 2] | 0) + 124) >> 2] = 0;
    c[((c[f >> 2] | 0) + 128) >> 2] = 0;
    g[((c[f >> 2] | 0) + 136) >> 2] = 1.0;
    b = ((c[f >> 2] | 0) + 140) | 0;
    e = (b + 40) | 0;
    do {
      c[b >> 2] = 0;
      b = (b + 4) | 0;
    } while ((b | 0) < (e | 0));
    Lb(((c[f >> 2] | 0) + 180) | 0, 0, 960) | 0;
    c[((c[f >> 2] | 0) + 1144) >> 2] = 777;
    d = ((c[f >> 2] | 0) + 1412) | 0;
    c[(d + 0) >> 2] = 0;
    c[(d + 4) >> 2] = 0;
    c[(d + 8) >> 2] = 0;
    c[(d + 12) >> 2] = 0;
    c[((c[f >> 2] | 0) + 1428) >> 2] = c[h >> 2];
    Lb(((c[f >> 2] | 0) + 1432) | 0, 0, 2560) | 0;
    c[j >> 2] = 0;
    while (1) {
      if ((c[j >> 2] | 0) >= 8) break;
      g[((c[f >> 2] | 0) + 3992 + (c[j >> 2] << 2)) >> 2] = 40.0;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    c[((c[f >> 2] | 0) + 132) >> 2] = 0;
    i = k;
    return (c[((c[f >> 2] | 0) + 4) >> 2] & 65535) | 0;
  }
  function ub(a, b, d, e, f, h, j, k, l, m, n) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    h = h | 0;
    j = j | 0;
    k = k | 0;
    l = l | 0;
    m = m | 0;
    n = n | 0;
    var o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0,
      I = 0,
      J = 0,
      K = 0;
    K = i;
    i = (i + 1632) | 0;
    o = (K + 40) | 0;
    p = (K + 636) | 0;
    r = (K + 4) | 0;
    s = (K + 660) | 0;
    t = (K + 36) | 0;
    u = (K + 16) | 0;
    v = (K + 20) | 0;
    w = (K + 640) | 0;
    x = (K + 648) | 0;
    y = (K + 656) | 0;
    q = (K + 664) | 0;
    G = (K + 672) | 0;
    E = (K + 44) | 0;
    D = (K + 632) | 0;
    F = (K + 8) | 0;
    A = K;
    z = (K + 24) | 0;
    C = (K + 28) | 0;
    B = (K + 644) | 0;
    H = (K + 12) | 0;
    I = (K + 32) | 0;
    J = (K + 652) | 0;
    c[o >> 2] = a;
    c[p >> 2] = b;
    c[r >> 2] = d;
    c[s >> 2] = e;
    c[t >> 2] = f;
    c[u >> 2] = h;
    c[v >> 2] = j;
    c[w >> 2] = k;
    c[x >> 2] = l;
    c[y >> 2] = m;
    c[q >> 2] = n;
    c[B >> 2] = 80 - (c[((c[o >> 2] | 0) + 28) >> 2] | 0);
    j = ((((c[r >> 2] | 0) - 1) | 0) * 40) | 0;
    if ((c[q >> 2] | 0) == 1) c[H >> 2] = j;
    else c[H >> 2] = j + (c[B >> 2] | 0);
    Ha(
      c[s >> 2] | 0,
      c[t >> 2] | 0,
      ((c[u >> 2] | 0) + (((((c[r >> 2] | 0) - 1) | 0) * 11) << 2)) | 0,
      ((c[p >> 2] | 0) + (c[H >> 2] << 2)) | 0,
      c[((c[o >> 2] | 0) + 28) >> 2] | 0
    );
    a: do
      if (c[q >> 2] | 0) {
        Lb(E | 0, 0, ((147 - (c[((c[o >> 2] | 0) + 28) >> 2] | 0)) << 2) | 0) |
          0;
        Nb(
          (E + 588 + ((0 - (c[((c[o >> 2] | 0) + 28) >> 2] | 0)) << 2)) | 0,
          ((c[p >> 2] | 0) + (c[H >> 2] << 2)) | 0,
          (c[((c[o >> 2] | 0) + 28) >> 2] << 2) | 0
        ) | 0;
        rb(
          ((c[p >> 2] | 0) +
            (((c[H >> 2] | 0) + (c[((c[o >> 2] | 0) + 28) >> 2] | 0)) << 2)) |
            0,
          c[x >> 2] | 0,
          c[y >> 2] | 0,
          (E + 588 + ((0 - 85) << 2)) | 0,
          85,
          c[B >> 2] | 0,
          3
        );
      } else {
        c[D >> 2] = 0;
        while (1) {
          if ((c[D >> 2] | 0) >= (c[B >> 2] | 0)) break;
          g[(G + (c[D >> 2] << 2)) >> 2] =
            +g[
              ((c[p >> 2] | 0) +
                (((((((c[r >> 2] | 0) + 1) | 0) * 40) | 0) -
                  1 -
                  ((c[D >> 2] | 0) + (c[((c[o >> 2] | 0) + 28) >> 2] | 0))) <<
                  2)) >>
                2
            ];
          c[D >> 2] = (c[D >> 2] | 0) + 1;
        }
        c[F >> 2] = c[((c[o >> 2] | 0) + 28) >> 2];
        c[D >> 2] = 0;
        while (1) {
          if ((c[D >> 2] | 0) >= (c[F >> 2] | 0)) break;
          g[(E + ((146 - (c[D >> 2] | 0)) << 2)) >> 2] =
            +g[
              ((c[p >> 2] | 0) + (((c[H >> 2] | 0) + (c[D >> 2] | 0)) << 2)) >>
                2
            ];
          c[D >> 2] = (c[D >> 2] | 0) + 1;
        }
        Lb(E | 0, 0, ((147 - (c[D >> 2] | 0)) << 2) | 0) | 0;
        rb(
          G,
          c[x >> 2] | 0,
          c[y >> 2] | 0,
          (E + 588 + ((0 - 85) << 2)) | 0,
          85,
          c[B >> 2] | 0,
          3
        );
        c[D >> 2] = 0;
        while (1) {
          if ((c[D >> 2] | 0) >= (c[B >> 2] | 0)) break a;
          g[
            ((c[p >> 2] | 0) +
              (((c[H >> 2] | 0) - 1 - (c[D >> 2] | 0)) << 2)) >>
              2
          ] = +g[(G + (c[D >> 2] << 2)) >> 2];
          c[D >> 2] = (c[D >> 2] | 0) + 1;
        }
      }
    while (0);
    c[I >> 2] = 0;
    c[A >> 2] = (c[((c[o >> 2] | 0) + 8) >> 2] | 0) - (c[r >> 2] | 0) - 1;
    b: do
      if ((c[A >> 2] | 0) > 0) {
        Lb(E | 0, 0, 268) | 0;
        Nb(
          (E + 588 + -320) | 0,
          ((c[p >> 2] | 0) + (((((c[r >> 2] | 0) - 1) | 0) * 40) << 2)) | 0,
          320
        ) | 0;
        c[J >> 2] = 0;
        while (1) {
          if ((c[J >> 2] | 0) >= (c[A >> 2] | 0)) break b;
          rb(
            ((c[p >> 2] | 0) +
              (((((c[r >> 2] | 0) + 1 + (c[J >> 2] | 0)) | 0) * 40) << 2)) |
              0,
            ((c[v >> 2] | 0) + (((c[I >> 2] | 0) * 3) << 2)) | 0,
            ((c[w >> 2] | 0) + (((c[I >> 2] | 0) * 3) << 2)) | 0,
            (E + 588 + ((0 - (c[(2520 + (c[I >> 2] << 2)) >> 2] | 0)) << 2)) |
              0,
            c[(2520 + (c[I >> 2] << 2)) >> 2] | 0,
            40,
            3
          );
          Nb(E | 0, (E + 160) | 0, 428) | 0;
          Nb(
            (E + 588 + -160) | 0,
            ((c[p >> 2] | 0) +
              (((((c[r >> 2] | 0) + 1 + (c[J >> 2] | 0)) | 0) * 40) << 2)) |
              0,
            160
          ) | 0;
          c[I >> 2] = (c[I >> 2] | 0) + 1;
          c[J >> 2] = (c[J >> 2] | 0) + 1;
        }
      }
    while (0);
    c[z >> 2] = (c[r >> 2] | 0) - 1;
    c: do
      if ((c[z >> 2] | 0) > 0) {
        H =
          ((((c[((c[o >> 2] | 0) + 8) >> 2] | 0) + 1 - (c[r >> 2] | 0)) | 0) *
            40) |
          0;
        c[F >> 2] = H;
        c[F >> 2] = (c[F >> 2] | 0) > 147 ? 147 : H;
        c[D >> 2] = 0;
        while (1) {
          if ((c[D >> 2] | 0) >= (c[F >> 2] | 0)) break;
          g[(E + ((146 - (c[D >> 2] | 0)) << 2)) >> 2] =
            +g[
              ((c[p >> 2] | 0) +
                (((((((c[r >> 2] | 0) - 1) | 0) * 40) | 0) + (c[D >> 2] | 0)) <<
                  2)) >>
                2
            ];
          c[D >> 2] = (c[D >> 2] | 0) + 1;
        }
        Lb(E | 0, 0, ((147 - (c[D >> 2] | 0)) << 2) | 0) | 0;
        c[J >> 2] = 0;
        while (1) {
          if ((c[J >> 2] | 0) >= (c[z >> 2] | 0)) break;
          rb(
            (G + (((c[J >> 2] | 0) * 40) << 2)) | 0,
            ((c[v >> 2] | 0) + (((c[I >> 2] | 0) * 3) << 2)) | 0,
            ((c[w >> 2] | 0) + (((c[I >> 2] | 0) * 3) << 2)) | 0,
            (E + 588 + ((0 - (c[(2520 + (c[I >> 2] << 2)) >> 2] | 0)) << 2)) |
              0,
            c[(2520 + (c[I >> 2] << 2)) >> 2] | 0,
            40,
            3
          );
          Nb(E | 0, (E + 160) | 0, 428) | 0;
          Nb(
            (E + 588 + -160) | 0,
            (G + (((c[J >> 2] | 0) * 40) << 2)) | 0,
            160
          ) | 0;
          c[I >> 2] = (c[I >> 2] | 0) + 1;
          c[J >> 2] = (c[J >> 2] | 0) + 1;
        }
        c[C >> 2] = 0;
        while (1) {
          if ((c[C >> 2] | 0) >= (((c[z >> 2] | 0) * 40) | 0)) break c;
          g[
            ((c[p >> 2] | 0) +
              (((((c[z >> 2] | 0) * 40) | 0) - (c[C >> 2] | 0) - 1) << 2)) >>
              2
          ] = +g[(G + (c[C >> 2] << 2)) >> 2];
          c[C >> 2] = (c[C >> 2] | 0) + 1;
        }
      }
    while (0);
    i = K;
  }
  function vb(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0,
      I = 0,
      J = 0,
      K = 0,
      L = 0,
      M = 0,
      N = 0,
      O = 0,
      P = 0,
      Q = 0;
    Q = i;
    i = (i + 5104) | 0;
    z = (Q + 5092) | 0;
    f = (Q + 4112) | 0;
    A = (Q + 36) | 0;
    B = (Q + 2464) | 0;
    F = (Q + 2736) | 0;
    t = (Q + 88) | 0;
    D = (Q + 168) | 0;
    C = (Q + 1128) | 0;
    P = (Q + 1176) | 0;
    L = (Q + 2136) | 0;
    p = (Q + 2180) | 0;
    H = (Q + 2184) | 0;
    N = (Q + 2188) | 0;
    n = (Q + 4108) | 0;
    v = (Q + 4116) | 0;
    r = (Q + 4124) | 0;
    x = (Q + 5088) | 0;
    J = (Q + 5096) | 0;
    I = (Q + 3696) | 0;
    E = (Q + 3700) | 0;
    K = (Q + 3704) | 0;
    o = (Q + 3712) | 0;
    j = (Q + 4032) | 0;
    m = (Q + 4040) | 0;
    l = (Q + 4088) | 0;
    h = (Q + 40) | 0;
    k = (Q + 24) | 0;
    s = Q;
    w = (Q + 4100) | 0;
    q = (Q + 2192) | 0;
    u = (Q + 4104) | 0;
    y = (Q + 2200) | 0;
    M = (Q + 4120) | 0;
    O = (Q + 2472) | 0;
    G = (Q + 4128) | 0;
    c[z >> 2] = a;
    c[f >> 2] = b;
    c[A >> 2] = d;
    c[B >> 2] = e;
    if ((c[B >> 2] | 0) > 0) {
      c[u >> 2] = c[f >> 2];
      c[v >> 2] = 0;
      c[p >> 2] = 0;
      while (1) {
        if ((c[p >> 2] | 0) >= 6) break;
        c[(s + (c[p >> 2] << 2)) >> 2] = 0;
        c[p >> 2] = (c[p >> 2] | 0) + 1;
      }
      c[N >> 2] = 0;
      c[w >> 2] = 0;
      c[n >> 2] = 0;
      c[p >> 2] = 0;
      while (1) {
        if ((c[p >> 2] | 0) >= (c[((c[A >> 2] | 0) + 28) >> 2] | 0)) break;
        c[(o + (c[p >> 2] << 2)) >> 2] = 0;
        c[p >> 2] = (c[p >> 2] | 0) + 1;
      }
      c[p >> 2] = 0;
      while (1) {
        if ((c[p >> 2] | 0) >= 3) break;
        c[(k + (c[p >> 2] << 2)) >> 2] = 0;
        c[p >> 2] = (c[p >> 2] | 0) + 1;
      }
      c[p >> 2] = 0;
      while (1) {
        if ((c[p >> 2] | 0) >= 3) break;
        c[(l + (c[p >> 2] << 2)) >> 2] = 0;
        c[p >> 2] = (c[p >> 2] | 0) + 1;
      }
      c[H >> 2] = 0;
      while (1) {
        if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 12) >> 2] | 0)) break;
        c[p >> 2] = 0;
        while (1) {
          f = c[H >> 2] | 0;
          if ((c[p >> 2] | 0) >= 3) break;
          c[(h + ((((f * 3) | 0) + (c[p >> 2] | 0)) << 2)) >> 2] = 0;
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[H >> 2] = f + 1;
      }
      c[H >> 2] = 0;
      while (1) {
        if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 12) >> 2] | 0)) break;
        c[p >> 2] = 0;
        while (1) {
          f = c[H >> 2] | 0;
          if ((c[p >> 2] | 0) >= 3) break;
          c[(m + ((((f * 3) | 0) + (c[p >> 2] | 0)) << 2)) >> 2] = 0;
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[H >> 2] = f + 1;
      }
      c[x >> 2] = 0;
      while (1) {
        if ((c[x >> 2] | 0) >= 3) break;
        c[p >> 2] = 0;
        while (1) {
          f = c[x >> 2] | 0;
          if (
            (c[p >> 2] | 0) >=
            (((c[((c[A >> 2] | 0) + 24) >> 2] | 0) * 3) | 0)
          )
            break;
          Fb(
            u,
            r,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                (((c[p >> 2] | 0) * 20) | 0) +
                (f << 2)) >>
                2
            ] | 0,
            v
          );
          Db(
            (s + (c[p >> 2] << 2)) | 0,
            c[r >> 2] | 0,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                (((c[p >> 2] | 0) * 20) | 0) +
                (c[x >> 2] << 2)) >>
                2
            ] | 0
          );
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        Fb(
          u,
          r,
          c[((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 120 + (f << 2)) >> 2] | 0,
          v
        );
        Db(
          N,
          c[r >> 2] | 0,
          c[
            ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 120 + (c[x >> 2] << 2)) >> 2
          ] | 0
        );
        Fb(
          u,
          r,
          c[
            ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 140 + (c[x >> 2] << 2)) >> 2
          ] | 0,
          v
        );
        Db(
          w,
          c[r >> 2] | 0,
          c[
            ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 140 + (c[x >> 2] << 2)) >> 2
          ] | 0
        );
        Fb(
          u,
          r,
          c[
            ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 160 + (c[x >> 2] << 2)) >> 2
          ] | 0,
          v
        );
        Db(
          n,
          c[r >> 2] | 0,
          c[
            ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 160 + (c[x >> 2] << 2)) >> 2
          ] | 0
        );
        c[p >> 2] = 0;
        while (1) {
          if ((c[p >> 2] | 0) >= (c[((c[A >> 2] | 0) + 28) >> 2] | 0)) break;
          Fb(
            u,
            r,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 180 + (c[x >> 2] << 2)) >>
                2
            ] | 0,
            v
          );
          Db(
            (o + (c[p >> 2] << 2)) | 0,
            c[r >> 2] | 0,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) + 180 + (c[x >> 2] << 2)) >>
                2
            ] | 0
          );
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[p >> 2] = 0;
        while (1) {
          if ((c[p >> 2] | 0) >= 3) break;
          Fb(
            u,
            r,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                200 +
                (((c[p >> 2] | 0) * 20) | 0) +
                (c[x >> 2] << 2)) >>
                2
            ] | 0,
            v
          );
          Db(
            (k + (c[p >> 2] << 2)) | 0,
            c[r >> 2] | 0,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                200 +
                (((c[p >> 2] | 0) * 20) | 0) +
                (c[x >> 2] << 2)) >>
                2
            ] | 0
          );
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[p >> 2] = 0;
        while (1) {
          if ((c[p >> 2] | 0) >= 3) break;
          Fb(
            u,
            r,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                260 +
                (((c[p >> 2] | 0) * 20) | 0) +
                (c[x >> 2] << 2)) >>
                2
            ] | 0,
            v
          );
          Db(
            (l + (c[p >> 2] << 2)) | 0,
            c[r >> 2] | 0,
            c[
              ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                260 +
                (((c[p >> 2] | 0) * 20) | 0) +
                (c[x >> 2] << 2)) >>
                2
            ] | 0
          );
          c[p >> 2] = (c[p >> 2] | 0) + 1;
        }
        c[H >> 2] = 0;
        while (1) {
          if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 12) >> 2] | 0)) break;
          c[p >> 2] = 0;
          while (1) {
            if ((c[p >> 2] | 0) >= 3) break;
            Fb(
              u,
              r,
              c[
                ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                  320 +
                  (((c[H >> 2] | 0) * 60) | 0) +
                  (((c[p >> 2] | 0) * 20) | 0) +
                  (c[x >> 2] << 2)) >>
                  2
              ] | 0,
              v
            );
            Db(
              (h + (((c[H >> 2] | 0) * 3) << 2) + (c[p >> 2] << 2)) | 0,
              c[r >> 2] | 0,
              c[
                ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                  320 +
                  (((c[H >> 2] | 0) * 60) | 0) +
                  (((c[p >> 2] | 0) * 20) | 0) +
                  (c[x >> 2] << 2)) >>
                  2
              ] | 0
            );
            c[p >> 2] = (c[p >> 2] | 0) + 1;
          }
          c[H >> 2] = (c[H >> 2] | 0) + 1;
        }
        c[H >> 2] = 0;
        while (1) {
          if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 12) >> 2] | 0)) break;
          c[p >> 2] = 0;
          while (1) {
            if ((c[p >> 2] | 0) >= 3) break;
            Fb(
              u,
              r,
              c[
                ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                  680 +
                  (((c[H >> 2] | 0) * 60) | 0) +
                  (((c[p >> 2] | 0) * 20) | 0) +
                  (c[x >> 2] << 2)) >>
                  2
              ] | 0,
              v
            );
            Db(
              (m + (((c[H >> 2] | 0) * 3) << 2) + (c[p >> 2] << 2)) | 0,
              c[r >> 2] | 0,
              c[
                ((c[((c[A >> 2] | 0) + 32) >> 2] | 0) +
                  680 +
                  (((c[H >> 2] | 0) * 60) | 0) +
                  (((c[p >> 2] | 0) * 20) | 0) +
                  (c[x >> 2] << 2)) >>
                  2
              ] | 0
            );
            c[p >> 2] = (c[p >> 2] | 0) + 1;
          }
          c[H >> 2] = (c[H >> 2] | 0) + 1;
        }
        c[x >> 2] = (c[x >> 2] | 0) + 1;
      }
      Fb(u, q, 1, v);
      if ((c[N >> 2] | 0) < 1) c[B >> 2] = 0;
      if ((c[N >> 2] | 0) > 3 ? (c[c[A >> 2] >> 2] | 0) == 20 : 0)
        c[B >> 2] = 0;
      if ((c[N >> 2] | 0) > 5 ? (c[c[A >> 2] >> 2] | 0) == 30 : 0)
        c[B >> 2] = 0;
      if ((c[q >> 2] | 0) == 1) c[B >> 2] = 0;
      if ((c[B >> 2] | 0) == 1) {
        qb(h);
        Aa(t, s, c[((c[A >> 2] | 0) + 24) >> 2] | 0);
        c[j >> 2] = mb(t, 10, c[((c[A >> 2] | 0) + 24) >> 2] | 0) | 0;
        Ba(O, y, t, 10, c[A >> 2] | 0);
        ub(
          c[A >> 2] | 0,
          G,
          c[N >> 2] | 0,
          c[n >> 2] | 0,
          o,
          O,
          h,
          m,
          k,
          l,
          c[w >> 2] | 0
        );
        Pa(
          D,
          C,
          0,
          G,
          (O + (((((c[((c[A >> 2] | 0) + 8) >> 2] | 0) - 1) | 0) * 11) << 2)) |
            0,
          c[((c[A >> 2] | 0) + 116) >> 2] | 0,
          c[A >> 2] | 0
        );
        Nb(G | 0, D | 0, (c[((c[A >> 2] | 0) + 4) >> 2] << 2) | 0) | 0;
      }
    }
    a: do
      if (!(c[B >> 2] | 0)) {
        Lb(P | 0, 0, 960) | 0;
        g[L >> 2] = 1.0;
        b = (L + 4) | 0;
        f = (b + 40) | 0;
        do {
          c[b >> 2] = 0;
          b = (b + 4) | 0;
        } while ((b | 0) < (f | 0));
        c[N >> 2] = 0;
        Pa(D, C, 1, P, L, c[((c[A >> 2] | 0) + 116) >> 2] | 0, c[A >> 2] | 0);
        Nb(G | 0, D | 0, (c[((c[A >> 2] | 0) + 4) >> 2] << 2) | 0) | 0;
        c[M >> 2] = 11;
        c[H >> 2] = 0;
        while (1) {
          if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 8) >> 2] | 0)) break a;
          P = (O + ((Z(c[H >> 2] | 0, c[M >> 2] | 0) | 0) << 2)) | 0;
          Nb(P | 0, C | 0, (c[M >> 2] << 2) | 0) | 0;
          c[H >> 2] = (c[H >> 2] | 0) + 1;
        }
      }
    while (0);
    b: do
      if ((c[((c[A >> 2] | 0) + 1428) >> 2] | 0) == 1) {
        P = Ya(F, G, c[A >> 2] | 0) | 0;
        c[((c[A >> 2] | 0) + 116) >> 2] = P;
        if ((c[c[A >> 2] >> 2] | 0) == 20) {
          c[H >> 2] = 0;
          Gb(
            (F + (((c[H >> 2] | 0) * 40) << 2)) | 0,
            ((c[A >> 2] | 0) +
              1148 +
              (((((c[H >> 2] | 0) + (c[((c[A >> 2] | 0) + 8) >> 2] | 0) - 1) |
                0) *
                11) <<
                2)) |
              0,
            40,
            ((c[A >> 2] | 0) + 36) | 0
          );
          c[H >> 2] = 1;
          while (1) {
            if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 8) >> 2] | 0)) break b;
            Gb(
              (F + (((c[H >> 2] | 0) * 40) << 2)) | 0,
              (O + (((((c[H >> 2] | 0) - 1) | 0) * 11) << 2)) | 0,
              40,
              ((c[A >> 2] | 0) + 36) | 0
            );
            c[H >> 2] = (c[H >> 2] | 0) + 1;
          }
        }
        if ((c[c[A >> 2] >> 2] | 0) == 30) {
          c[H >> 2] = 0;
          while (1) {
            if ((c[H >> 2] | 0) >= 2) break;
            Gb(
              (F + (((c[H >> 2] | 0) * 40) << 2)) | 0,
              ((c[A >> 2] | 0) +
                1148 +
                (((((c[H >> 2] | 0) + (c[((c[A >> 2] | 0) + 8) >> 2] | 0) - 2) |
                  0) *
                  11) <<
                  2)) |
                0,
              40,
              ((c[A >> 2] | 0) + 36) | 0
            );
            c[H >> 2] = (c[H >> 2] | 0) + 1;
          }
          c[H >> 2] = 2;
          while (1) {
            if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 8) >> 2] | 0)) break b;
            Gb(
              (F + (((c[H >> 2] | 0) * 40) << 2)) | 0,
              (O + (((((c[H >> 2] | 0) - 2) | 0) * 11) << 2)) | 0,
              40,
              ((c[A >> 2] | 0) + 36) | 0
            );
            c[H >> 2] = (c[H >> 2] | 0) + 1;
          }
        }
      } else {
        c[J >> 2] = 20;
        g[K >> 2] = +Xa(
          (G + 640) | 0,
          (G + ((160 - (c[J >> 2] | 0)) << 2)) | 0,
          80
        );
        c[I >> 2] = 21;
        while (1) {
          if ((c[I >> 2] | 0) >= 120) break;
          g[E >> 2] = +Xa(
            (G + 640) | 0,
            (G + ((160 - (c[I >> 2] | 0)) << 2)) | 0,
            80
          );
          if (+g[E >> 2] > +g[K >> 2]) {
            g[K >> 2] = +g[E >> 2];
            c[J >> 2] = c[I >> 2];
          }
          c[I >> 2] = (c[I >> 2] | 0) + 1;
        }
        c[((c[A >> 2] | 0) + 116) >> 2] = c[J >> 2];
        Nb(F | 0, G | 0, (c[((c[A >> 2] | 0) + 4) >> 2] << 2) | 0) | 0;
        c[H >> 2] = 0;
        while (1) {
          if ((c[H >> 2] | 0) >= (c[((c[A >> 2] | 0) + 8) >> 2] | 0)) break b;
          Gb(
            (F + (((c[H >> 2] | 0) * 40) << 2)) | 0,
            (O + (((c[H >> 2] | 0) * 11) << 2)) | 0,
            40,
            ((c[A >> 2] | 0) + 36) | 0
          );
          c[H >> 2] = (c[H >> 2] | 0) + 1;
        }
      }
    while (0);
    ob(
      F,
      c[((c[A >> 2] | 0) + 4) >> 2] | 0,
      c[z >> 2] | 0,
      ((c[A >> 2] | 0) + 1412) | 0
    );
    Nb(
      ((c[A >> 2] | 0) + 1148) | 0,
      O | 0,
      (((c[((c[A >> 2] | 0) + 8) >> 2] | 0) * 11) << 2) | 0
    ) | 0;
    c[((c[A >> 2] | 0) + 132) >> 2] = 0;
    if (!(c[B >> 2] | 0)) c[((c[A >> 2] | 0) + 132) >> 2] = 1;
    i = Q;
  }
  function wb() {
    return 1372;
  }
  function xb() {
    return 4024;
  }
  function yb(a, b) {
    a |= 0;
    b |= 0;
    let d = 0;
    var e = 0;
    var f = 0;
    f = i;
    i = (i + 16) | 0;
    e = (f + 4) | 0;
    d = f;
    c[e >> 2] = a;
    c[d >> 2] = b;
    c[c[e >> 2] >> 2] = c[d >> 2];
    do
      if ((c[d >> 2] | 0) != 30)
        if ((c[d >> 2] | 0) == 20) {
          c[((c[e >> 2] | 0) + 4) >> 2] = 160;
          c[((c[e >> 2] | 0) + 8) >> 2] = 4;
          c[((c[e >> 2] | 0) + 12) >> 2] = 2;
          c[((c[e >> 2] | 0) + 24) >> 2] = 1;
          c[((c[e >> 2] | 0) + 16) >> 2] = 38;
          c[((c[e >> 2] | 0) + 20) >> 2] = 19;
          c[((c[e >> 2] | 0) + 28) >> 2] = 57;
          c[((c[e >> 2] | 0) + 32) >> 2] = 56;
          break;
        } else ma(2);
      else {
        c[((c[e >> 2] | 0) + 4) >> 2] = 240;
        c[((c[e >> 2] | 0) + 8) >> 2] = 6;
        c[((c[e >> 2] | 0) + 12) >> 2] = 4;
        c[((c[e >> 2] | 0) + 24) >> 2] = 2;
        c[((c[e >> 2] | 0) + 16) >> 2] = 50;
        c[((c[e >> 2] | 0) + 20) >> 2] = 25;
        c[((c[e >> 2] | 0) + 28) >> 2] = 58;
        c[((c[e >> 2] | 0) + 32) >> 2] = 1096;
      }
    while (0);
    b = ((c[e >> 2] | 0) + 36) | 0;
    d = (b + 40) | 0;
    do {
      c[b >> 2] = 0;
      b = (b + 4) | 0;
    } while ((b | 0) < (d | 0));
    b = ((c[e >> 2] | 0) + 76) | 0;
    a = 2968 | 0;
    d = (b + 40) | 0;
    do {
      c[b >> 2] = c[a >> 2];
      b = (b + 4) | 0;
      a = (a + 4) | 0;
    } while ((b | 0) < (d | 0));
    b = ((c[e >> 2] | 0) + 116) | 0;
    a = 2968 | 0;
    d = (b + 40) | 0;
    do {
      c[b >> 2] = c[a >> 2];
      b = (b + 4) | 0;
      a = (a + 4) | 0;
    } while ((b | 0) < (d | 0));
    Lb(((c[e >> 2] | 0) + 156) | 0, 0, 1200) | 0;
    b = ((c[e >> 2] | 0) + 1356) | 0;
    c[(b + 0) >> 2] = 0;
    c[(b + 4) >> 2] = 0;
    c[(b + 8) >> 2] = 0;
    c[(b + 12) >> 2] = 0;
    i = f;
    return (c[((c[e >> 2] | 0) + 16) >> 2] & 65535) | 0;
  }
  function zb(a, b, d) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    var e = 0,
      f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0,
      E = 0,
      F = 0,
      G = 0,
      H = 0,
      I = 0,
      J = 0,
      K = 0,
      L = 0,
      M = 0,
      N = 0,
      O = 0,
      P = 0,
      Q = 0,
      R = 0,
      S = 0;
    R = i;
    i = (i + 6544) | 0;
    l = (R + 4360) | 0;
    S = (R + 3128) | 0;
    m = (R + 4) | 0;
    e = (R + 2168) | 0;
    G = (R + 5512) | 0;
    I = (R + 3400) | 0;
    J = (R + 280) | 0;
    x = (R + 284) | 0;
    y = (R + 288) | 0;
    H = (R + 608) | 0;
    B = (R + 1568) | 0;
    D = (R + 2156) | 0;
    z = (R + 2160) | 0;
    C = (R + 2164) | 0;
    o = (R + 4480) | 0;
    n = (R + 4488) | 0;
    w = (R + 4536) | 0;
    F = (R + 5504) | 0;
    v = (R + 6472) | 0;
    t = (R + 6520) | 0;
    p = (R + 4368) | 0;
    s = (R + 4416) | 0;
    A = (R + 4432) | 0;
    E = (R + 4456) | 0;
    r = (R + 4460) | 0;
    K = (R + 4464) | 0;
    L = (R + 4468) | 0;
    f = (R + 4472) | 0;
    h = (R + 12) | 0;
    j = R;
    O = (R + 8) | 0;
    u = (R + 4476) | 0;
    M = (R + 4484) | 0;
    N = (R + 3132) | 0;
    P = (R + 4496) | 0;
    k = (R + 16) | 0;
    Q = (R + 3136) | 0;
    q = (R + 4544) | 0;
    c[l >> 2] = a;
    c[S >> 2] = b;
    c[m >> 2] = d;
    nb(
      c[S >> 2] | 0,
      c[((c[m >> 2] | 0) + 4) >> 2] | 0,
      e,
      ((c[m >> 2] | 0) + 1356) | 0
    );
    Ga(k, Q, A, e, c[m >> 2] | 0);
    c[D >> 2] = 0;
    while (1) {
      if ((c[D >> 2] | 0) >= (c[((c[m >> 2] | 0) + 8) >> 2] | 0)) break;
      Ka(
        (e + (((c[D >> 2] | 0) * 40) << 2)) | 0,
        (k + (((c[D >> 2] | 0) * 11) << 2)) | 0,
        40,
        (G + (((c[D >> 2] | 0) * 40) << 2)) | 0,
        ((c[m >> 2] | 0) + 36) | 0
      );
      c[D >> 2] = (c[D >> 2] | 0) + 1;
    }
    c[J >> 2] = ya(c[m >> 2] | 0, G) | 0;
    c[r >> 2] = 80 - (c[((c[m >> 2] | 0) + 28) >> 2] | 0);
    g[f >> 2] = 0.0;
    c[j >> 2] = (((c[J >> 2] | 0) - 1) | 0) * 40;
    c[w >> 2] = 0;
    while (1) {
      if ((c[w >> 2] | 0) >= (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) break;
      g[f >> 2] =
        +g[f >> 2] +
        +g[(G + (((c[j >> 2] | 0) + (c[w >> 2] | 0)) << 2)) >> 2] *
          +g[(G + (((c[j >> 2] | 0) + (c[w >> 2] | 0)) << 2)) >> 2];
      c[w >> 2] = (c[w >> 2] | 0) + 1;
    }
    g[h >> 2] = 0.0;
    c[j >> 2] = (((((c[J >> 2] | 0) - 1) | 0) * 40) | 0) + (c[r >> 2] | 0);
    c[w >> 2] = 0;
    while (1) {
      if ((c[w >> 2] | 0) >= (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) break;
      g[h >> 2] =
        +g[h >> 2] +
        +g[(G + (((c[j >> 2] | 0) + (c[w >> 2] | 0)) << 2)) >> 2] *
          +g[(G + (((c[j >> 2] | 0) + (c[w >> 2] | 0)) << 2)) >> 2];
      c[w >> 2] = (c[w >> 2] | 0) + 1;
    }
    if (+g[f >> 2] > +g[h >> 2]) {
      c[L >> 2] = 1;
      c[K >> 2] = (((c[J >> 2] | 0) - 1) | 0) * 40;
    } else {
      c[L >> 2] = 0;
      c[K >> 2] = (((((c[J >> 2] | 0) - 1) | 0) * 40) | 0) + (c[r >> 2] | 0);
    }
    Ja(
      c[m >> 2] | 0,
      (G + (c[K >> 2] << 2)) | 0,
      (k + (((((c[J >> 2] | 0) - 1) | 0) * 11) << 2)) | 0,
      (Q + (((((c[J >> 2] | 0) - 1) | 0) * 11) << 2)) | 0,
      x,
      y,
      c[((c[m >> 2] | 0) + 28) >> 2] | 0,
      c[L >> 2] | 0
    );
    Ha(
      c[x >> 2] | 0,
      y,
      (k + (((((c[J >> 2] | 0) - 1) | 0) * 11) << 2)) | 0,
      (q + (c[K >> 2] << 2)) | 0,
      c[((c[m >> 2] | 0) + 28) >> 2] | 0
    );
    a: do
      if (c[L >> 2] | 0) {
        Lb(B | 0, 0, ((147 - (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) << 2) | 0) |
          0;
        Nb(
          (B + 588 + ((0 - (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) << 2)) | 0,
          (q + (c[K >> 2] << 2)) | 0,
          (c[((c[m >> 2] | 0) + 28) >> 2] << 2) | 0
        ) | 0;
        a = (P + 0) | 0;
        b = (a + 40) | 0;
        do {
          c[a >> 2] = 0;
          a = (a + 4) | 0;
        } while ((a | 0) < (b | 0));
        sb(
          c[m >> 2] | 0,
          s,
          t,
          (G +
            (((c[K >> 2] | 0) + (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) << 2)) |
            0,
          (B + 588 + ((0 - 85) << 2)) | 0,
          85,
          c[r >> 2] | 0,
          3,
          (Q + (((c[J >> 2] | 0) * 11) << 2)) | 0,
          P,
          0
        );
        rb(
          (q +
            (((c[K >> 2] | 0) + (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) << 2)) |
            0,
          s,
          t,
          (B + 588 + ((0 - 85) << 2)) | 0,
          85,
          c[r >> 2] | 0,
          3
        );
      } else {
        c[z >> 2] = 0;
        while (1) {
          if ((c[z >> 2] | 0) >= (c[r >> 2] | 0)) break;
          g[(I + (c[z >> 2] << 2)) >> 2] =
            +g[
              (G +
                (((((((c[J >> 2] | 0) + 1) | 0) * 40) | 0) -
                  1 -
                  ((c[z >> 2] | 0) + (c[((c[m >> 2] | 0) + 28) >> 2] | 0))) <<
                  2)) >>
                2
            ];
          c[z >> 2] = (c[z >> 2] | 0) + 1;
        }
        c[C >> 2] = c[((c[m >> 2] | 0) + 28) >> 2];
        c[z >> 2] = 0;
        while (1) {
          if ((c[z >> 2] | 0) >= (c[C >> 2] | 0)) break;
          g[(B + ((146 - (c[z >> 2] | 0)) << 2)) >> 2] =
            +g[(q + (((c[K >> 2] | 0) + (c[z >> 2] | 0)) << 2)) >> 2];
          c[z >> 2] = (c[z >> 2] | 0) + 1;
        }
        Lb(B | 0, 0, ((147 - (c[z >> 2] | 0)) << 2) | 0) | 0;
        a = (P + 0) | 0;
        b = (a + 40) | 0;
        do {
          c[a >> 2] = 0;
          a = (a + 4) | 0;
        } while ((a | 0) < (b | 0));
        sb(
          c[m >> 2] | 0,
          s,
          t,
          I,
          (B + 588 + ((0 - 85) << 2)) | 0,
          85,
          c[r >> 2] | 0,
          3,
          (Q + (((((c[J >> 2] | 0) - 1) | 0) * 11) << 2)) | 0,
          P,
          0
        );
        rb(H, s, t, (B + 588 + ((0 - 85) << 2)) | 0, 85, c[r >> 2] | 0, 3);
        c[z >> 2] = 0;
        while (1) {
          if ((c[z >> 2] | 0) >= (c[r >> 2] | 0)) break a;
          g[(q + (((c[K >> 2] | 0) - 1 - (c[z >> 2] | 0)) << 2)) >> 2] =
            +g[(H + (c[z >> 2] << 2)) >> 2];
          c[z >> 2] = (c[z >> 2] | 0) + 1;
        }
      }
    while (0);
    c[M >> 2] = 0;
    c[o >> 2] = (c[((c[m >> 2] | 0) + 8) >> 2] | 0) - (c[J >> 2] | 0) - 1;
    b: do
      if ((c[o >> 2] | 0) > 0) {
        Lb(B | 0, 0, 268) | 0;
        Nb(
          (B + 588 + -320) | 0,
          (q + (((((c[J >> 2] | 0) - 1) | 0) * 40) << 2)) | 0,
          320
        ) | 0;
        a = (P + 0) | 0;
        b = (a + 40) | 0;
        do {
          c[a >> 2] = 0;
          a = (a + 4) | 0;
        } while ((a | 0) < (b | 0));
        c[N >> 2] = 0;
        while (1) {
          if ((c[N >> 2] | 0) >= (c[o >> 2] | 0)) break b;
          sb(
            c[m >> 2] | 0,
            (p + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (v + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (G + (((((c[J >> 2] | 0) + 1 + (c[N >> 2] | 0)) | 0) * 40) << 2)) |
              0,
            (B + 588 + ((0 - (c[(2520 + (c[M >> 2] << 2)) >> 2] | 0)) << 2)) |
              0,
            c[(2520 + (c[M >> 2] << 2)) >> 2] | 0,
            40,
            3,
            (Q + (((((c[J >> 2] | 0) + 1 + (c[N >> 2] | 0)) | 0) * 11) << 2)) |
              0,
            P,
            ((c[M >> 2] | 0) + 1) | 0
          );
          rb(
            (q + (((((c[J >> 2] | 0) + 1 + (c[N >> 2] | 0)) | 0) * 40) << 2)) |
              0,
            (p + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (v + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (B + 588 + ((0 - (c[(2520 + (c[M >> 2] << 2)) >> 2] | 0)) << 2)) |
              0,
            c[(2520 + (c[M >> 2] << 2)) >> 2] | 0,
            40,
            3
          );
          Nb(B | 0, (B + 160) | 0, 428) | 0;
          Nb(
            (B + 588 + -160) | 0,
            (q + (((((c[J >> 2] | 0) + 1 + (c[N >> 2] | 0)) | 0) * 40) << 2)) |
              0,
            160
          ) | 0;
          a = (P + 0) | 0;
          b = (a + 40) | 0;
          do {
            c[a >> 2] = 0;
            a = (a + 4) | 0;
          } while ((a | 0) < (b | 0));
          c[M >> 2] = (c[M >> 2] | 0) + 1;
          c[N >> 2] = (c[N >> 2] | 0) + 1;
        }
      }
    while (0);
    c[n >> 2] = (c[J >> 2] | 0) - 1;
    c: do
      if ((c[n >> 2] | 0) > 0) {
        c[D >> 2] = 0;
        while (1) {
          if ((c[D >> 2] | 0) >= (c[n >> 2] | 0)) break;
          c[z >> 2] = 0;
          while (1) {
            if ((c[z >> 2] | 0) >= 40) break;
            g[
              (I + (((((c[D >> 2] | 0) * 40) | 0) + (c[z >> 2] | 0)) << 2)) >> 2
            ] =
              +g[
                (G +
                  (((((((c[J >> 2] | 0) - 1) | 0) * 40) | 0) -
                    1 -
                    (((c[D >> 2] | 0) * 40) | 0) -
                    (c[z >> 2] | 0)) <<
                    2)) >>
                  2
              ];
            g[
              (H + (((((c[D >> 2] | 0) * 40) | 0) + (c[z >> 2] | 0)) << 2)) >> 2
            ] =
              +g[
                (q +
                  (((((((c[J >> 2] | 0) - 1) | 0) * 40) | 0) -
                    1 -
                    (((c[D >> 2] | 0) * 40) | 0) -
                    (c[z >> 2] | 0)) <<
                    2)) >>
                  2
              ];
            c[z >> 2] = (c[z >> 2] | 0) + 1;
          }
          c[D >> 2] = (c[D >> 2] | 0) + 1;
        }
        S =
          ((((c[((c[m >> 2] | 0) + 8) >> 2] | 0) + 1 - (c[J >> 2] | 0)) | 0) *
            40) |
          0;
        c[C >> 2] = S;
        c[C >> 2] = (c[C >> 2] | 0) > 147 ? 147 : S;
        c[z >> 2] = 0;
        while (1) {
          if ((c[z >> 2] | 0) >= (c[C >> 2] | 0)) break;
          g[(B + ((146 - (c[z >> 2] | 0)) << 2)) >> 2] =
            +g[
              (q +
                (((((((c[J >> 2] | 0) - 1) | 0) * 40) | 0) + (c[z >> 2] | 0)) <<
                  2)) >>
                2
            ];
          c[z >> 2] = (c[z >> 2] | 0) + 1;
        }
        Lb(B | 0, 0, ((147 - (c[z >> 2] | 0)) << 2) | 0) | 0;
        a = (P + 0) | 0;
        b = (a + 40) | 0;
        do {
          c[a >> 2] = 0;
          a = (a + 4) | 0;
        } while ((a | 0) < (b | 0));
        c[N >> 2] = 0;
        while (1) {
          if ((c[N >> 2] | 0) >= (c[n >> 2] | 0)) break;
          sb(
            c[m >> 2] | 0,
            (p + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (v + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (I + (((c[N >> 2] | 0) * 40) << 2)) | 0,
            (B + 588 + ((0 - (c[(2520 + (c[M >> 2] << 2)) >> 2] | 0)) << 2)) |
              0,
            c[(2520 + (c[M >> 2] << 2)) >> 2] | 0,
            40,
            3,
            (Q + (((((c[J >> 2] | 0) - 2 - (c[N >> 2] | 0)) | 0) * 11) << 2)) |
              0,
            P,
            ((c[M >> 2] | 0) + 1) | 0
          );
          rb(
            (H + (((c[N >> 2] | 0) * 40) << 2)) | 0,
            (p + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (v + (((c[M >> 2] | 0) * 3) << 2)) | 0,
            (B + 588 + ((0 - (c[(2520 + (c[M >> 2] << 2)) >> 2] | 0)) << 2)) |
              0,
            c[(2520 + (c[M >> 2] << 2)) >> 2] | 0,
            40,
            3
          );
          Nb(B | 0, (B + 160) | 0, 428) | 0;
          Nb(
            (B + 588 + -160) | 0,
            (H + (((c[N >> 2] | 0) * 40) << 2)) | 0,
            160
          ) | 0;
          a = (P + 0) | 0;
          b = (a + 40) | 0;
          do {
            c[a >> 2] = 0;
            a = (a + 4) | 0;
          } while ((a | 0) < (b | 0));
          c[M >> 2] = (c[M >> 2] | 0) + 1;
          c[N >> 2] = (c[N >> 2] | 0) + 1;
        }
        c[w >> 2] = 0;
        while (1) {
          if ((c[w >> 2] | 0) >= (((c[n >> 2] | 0) * 40) | 0)) break c;
          g[
            (q + (((((c[n >> 2] | 0) * 40) | 0) - (c[w >> 2] | 0) - 1) << 2)) >>
              2
          ] = +g[(H + (c[w >> 2] << 2)) >> 2];
          c[w >> 2] = (c[w >> 2] | 0) + 1;
        }
      }
    while (0);
    pb(p);
    c[E >> 2] = c[l >> 2];
    c[F >> 2] = 0;
    c[O >> 2] = 0;
    while (1) {
      if ((c[O >> 2] | 0) >= 3) break;
      c[z >> 2] = 0;
      while (1) {
        if ((c[z >> 2] | 0) >= (((c[((c[m >> 2] | 0) + 24) >> 2] | 0) * 3) | 0))
          break;
        Cb(
          (A + (c[z >> 2] << 2)) | 0,
          u,
          (A + (c[z >> 2] << 2)) | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] | 0,
          ((c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] |
            0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (((c[O >> 2] | 0) + 1) << 2)) >>
                2
            ] |
              0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (((c[O >> 2] | 0) + 2) << 2)) >>
                2
            ] |
              0)) |
            0
        );
        Eb(
          E,
          c[u >> 2] | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] | 0,
          F
        );
        c[z >> 2] = (c[z >> 2] | 0) + 1;
      }
      Cb(
        J,
        u,
        J,
        c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 120 + (c[O >> 2] << 2)) >> 2
        ] | 0,
        ((c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 120 + (c[O >> 2] << 2)) >> 2
        ] |
          0) +
          (c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              120 +
              (((c[O >> 2] | 0) + 1) << 2)) >>
              2
          ] |
            0) +
          (c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              120 +
              (((c[O >> 2] | 0) + 2) << 2)) >>
              2
          ] |
            0)) |
          0
      );
      Eb(
        E,
        c[u >> 2] | 0,
        c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 120 + (c[O >> 2] << 2)) >> 2
        ] | 0,
        F
      );
      Cb(
        L,
        u,
        L,
        c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 140 + (c[O >> 2] << 2)) >> 2
        ] | 0,
        ((c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 140 + (c[O >> 2] << 2)) >> 2
        ] |
          0) +
          (c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              140 +
              (((c[O >> 2] | 0) + 1) << 2)) >>
              2
          ] |
            0) +
          (c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              140 +
              (((c[O >> 2] | 0) + 2) << 2)) >>
              2
          ] |
            0)) |
          0
      );
      Eb(
        E,
        c[u >> 2] | 0,
        c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 140 + (c[O >> 2] << 2)) >> 2
        ] | 0,
        F
      );
      Cb(
        x,
        u,
        x,
        c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 160 + (c[O >> 2] << 2)) >> 2
        ] | 0,
        ((c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 160 + (c[O >> 2] << 2)) >> 2
        ] |
          0) +
          (c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              160 +
              (((c[O >> 2] | 0) + 1) << 2)) >>
              2
          ] |
            0) +
          (c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              160 +
              (((c[O >> 2] | 0) + 2) << 2)) >>
              2
          ] |
            0)) |
          0
      );
      Eb(
        E,
        c[u >> 2] | 0,
        c[
          ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 160 + (c[O >> 2] << 2)) >> 2
        ] | 0,
        F
      );
      c[z >> 2] = 0;
      while (1) {
        if ((c[z >> 2] | 0) >= (c[((c[m >> 2] | 0) + 28) >> 2] | 0)) break;
        Cb(
          (y + (c[z >> 2] << 2)) | 0,
          u,
          (y + (c[z >> 2] << 2)) | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 180 + (c[O >> 2] << 2)) >> 2
          ] | 0,
          ((c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 180 + (c[O >> 2] << 2)) >> 2
          ] |
            0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                180 +
                (((c[O >> 2] | 0) + 1) << 2)) >>
                2
            ] |
              0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                180 +
                (((c[O >> 2] | 0) + 2) << 2)) >>
                2
            ] |
              0)) |
            0
        );
        Eb(
          E,
          c[u >> 2] | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) + 180 + (c[O >> 2] << 2)) >> 2
          ] | 0,
          F
        );
        c[z >> 2] = (c[z >> 2] | 0) + 1;
      }
      c[z >> 2] = 0;
      while (1) {
        if ((c[z >> 2] | 0) >= 3) break;
        Cb(
          (s + (c[z >> 2] << 2)) | 0,
          u,
          (s + (c[z >> 2] << 2)) | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              200 +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] | 0,
          ((c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              200 +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] |
            0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                200 +
                (((c[z >> 2] | 0) * 20) | 0) +
                (((c[O >> 2] | 0) + 1) << 2)) >>
                2
            ] |
              0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                200 +
                (((c[z >> 2] | 0) * 20) | 0) +
                (((c[O >> 2] | 0) + 2) << 2)) >>
                2
            ] |
              0)) |
            0
        );
        Eb(
          E,
          c[u >> 2] | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              200 +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] | 0,
          F
        );
        c[z >> 2] = (c[z >> 2] | 0) + 1;
      }
      c[z >> 2] = 0;
      while (1) {
        if ((c[z >> 2] | 0) >= 3) break;
        Cb(
          (t + (c[z >> 2] << 2)) | 0,
          u,
          (t + (c[z >> 2] << 2)) | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              260 +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] | 0,
          ((c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              260 +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] |
            0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                260 +
                (((c[z >> 2] | 0) * 20) | 0) +
                (((c[O >> 2] | 0) + 1) << 2)) >>
                2
            ] |
              0) +
            (c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                260 +
                (((c[z >> 2] | 0) * 20) | 0) +
                (((c[O >> 2] | 0) + 2) << 2)) >>
                2
            ] |
              0)) |
            0
        );
        Eb(
          E,
          c[u >> 2] | 0,
          c[
            ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
              260 +
              (((c[z >> 2] | 0) * 20) | 0) +
              (c[O >> 2] << 2)) >>
              2
          ] | 0,
          F
        );
        c[z >> 2] = (c[z >> 2] | 0) + 1;
      }
      c[w >> 2] = 0;
      while (1) {
        if ((c[w >> 2] | 0) >= (c[((c[m >> 2] | 0) + 12) >> 2] | 0)) break;
        c[z >> 2] = 0;
        while (1) {
          if ((c[z >> 2] | 0) >= 3) break;
          Cb(
            (p + (((c[w >> 2] | 0) * 3) << 2) + (c[z >> 2] << 2)) | 0,
            u,
            (p + (((c[w >> 2] | 0) * 3) << 2) + (c[z >> 2] << 2)) | 0,
            c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                320 +
                (((c[w >> 2] | 0) * 60) | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (c[O >> 2] << 2)) >>
                2
            ] | 0,
            ((c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                320 +
                (((c[w >> 2] | 0) * 60) | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (c[O >> 2] << 2)) >>
                2
            ] |
              0) +
              (c[
                ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                  320 +
                  (((c[w >> 2] | 0) * 60) | 0) +
                  (((c[z >> 2] | 0) * 20) | 0) +
                  (((c[O >> 2] | 0) + 1) << 2)) >>
                  2
              ] |
                0) +
              (c[
                ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                  320 +
                  (((c[w >> 2] | 0) * 60) | 0) +
                  (((c[z >> 2] | 0) * 20) | 0) +
                  (((c[O >> 2] | 0) + 2) << 2)) >>
                  2
              ] |
                0)) |
              0
          );
          Eb(
            E,
            c[u >> 2] | 0,
            c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                320 +
                (((c[w >> 2] | 0) * 60) | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (c[O >> 2] << 2)) >>
                2
            ] | 0,
            F
          );
          c[z >> 2] = (c[z >> 2] | 0) + 1;
        }
        c[w >> 2] = (c[w >> 2] | 0) + 1;
      }
      c[w >> 2] = 0;
      while (1) {
        if ((c[w >> 2] | 0) >= (c[((c[m >> 2] | 0) + 12) >> 2] | 0)) break;
        c[z >> 2] = 0;
        while (1) {
          if ((c[z >> 2] | 0) >= 3) break;
          Cb(
            (v + (((c[w >> 2] | 0) * 3) << 2) + (c[z >> 2] << 2)) | 0,
            u,
            (v + (((c[w >> 2] | 0) * 3) << 2) + (c[z >> 2] << 2)) | 0,
            c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                680 +
                (((c[w >> 2] | 0) * 60) | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (c[O >> 2] << 2)) >>
                2
            ] | 0,
            ((c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                680 +
                (((c[w >> 2] | 0) * 60) | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (c[O >> 2] << 2)) >>
                2
            ] |
              0) +
              (c[
                ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                  680 +
                  (((c[w >> 2] | 0) * 60) | 0) +
                  (((c[z >> 2] | 0) * 20) | 0) +
                  (((c[O >> 2] | 0) + 1) << 2)) >>
                  2
              ] |
                0) +
              (c[
                ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                  680 +
                  (((c[w >> 2] | 0) * 60) | 0) +
                  (((c[z >> 2] | 0) * 20) | 0) +
                  (((c[O >> 2] | 0) + 2) << 2)) >>
                  2
              ] |
                0)) |
              0
          );
          Eb(
            E,
            c[u >> 2] | 0,
            c[
              ((c[((c[m >> 2] | 0) + 32) >> 2] | 0) +
                680 +
                (((c[w >> 2] | 0) * 60) | 0) +
                (((c[z >> 2] | 0) * 20) | 0) +
                (c[O >> 2] << 2)) >>
                2
            ] | 0,
            F
          );
          c[z >> 2] = (c[z >> 2] | 0) + 1;
        }
        c[w >> 2] = (c[w >> 2] | 0) + 1;
      }
      c[O >> 2] = (c[O >> 2] | 0) + 1;
    }
    Eb(E, 0, 1, F);
    i = R;
  }
  function Ab(a, b) {
    a = a | 0;
    b = b | 0;
    var d = 0.0,
      e = 0,
      f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0,
      v = 0,
      w = 0,
      x = 0,
      y = 0,
      z = 0,
      A = 0,
      B = 0,
      C = 0,
      D = 0;
    D = i;
    i = (i + 176) | 0;
    j = (D + 164) | 0;
    e = D;
    C = (D + 8) | 0;
    A = (D + 88) | 0;
    B = (D + 156) | 0;
    r = (D + 32) | 0;
    f = (D + 36) | 0;
    h = (D + 56) | 0;
    x = (D + 112) | 0;
    z = (D + 136) | 0;
    u = (D + 160) | 0;
    v = (D + 168) | 0;
    s = (D + 92) | 0;
    y = (D + 96) | 0;
    w = (D + 100) | 0;
    t = (D + 24) | 0;
    q = (D + 104) | 0;
    k = (D + 76) | 0;
    l = (D + 108) | 0;
    m = (D + 28) | 0;
    n = (D + 80) | 0;
    o = (D + 132) | 0;
    p = (D + 84) | 0;
    c[j >> 2] = a;
    c[e >> 2] = b;
    c[(C + 0) >> 2] = c[2342];
    c[(C + 4) >> 2] = c[2343];
    c[(C + 8) >> 2] = c[2344];
    c[(C + 12) >> 2] = c[2345];
    c[q >> 2] = 0;
    while (1) {
      if ((c[q >> 2] | 0) >= 5) break;
      g[(f + (c[q >> 2] << 2)) >> 2] =
        (+g[((c[e >> 2] | 0) + (((c[q >> 2] | 0) + 1) << 2)) >> 2] +
          +g[((c[e >> 2] | 0) + ((10 - (c[q >> 2] | 0)) << 2)) >> 2]) *
        -1.0;
      g[(h + (c[q >> 2] << 2)) >> 2] =
        +g[((c[e >> 2] | 0) + ((10 - (c[q >> 2] | 0)) << 2)) >> 2] -
        +g[((c[e >> 2] | 0) + (((c[q >> 2] | 0) + 1) << 2)) >> 2];
      c[q >> 2] = (c[q >> 2] | 0) + 1;
    }
    g[x >> 2] = -1.0 - +g[f >> 2];
    g[(x + 4) >> 2] = -+g[x >> 2] - +g[(f + 4) >> 2];
    g[(x + 8) >> 2] = -+g[(x + 4) >> 2] - +g[(f + 8) >> 2];
    g[(x + 12) >> 2] = -+g[(x + 8) >> 2] - +g[(f + 12) >> 2];
    g[(x + 16) >> 2] = -+g[(x + 12) >> 2] - +g[(f + 16) >> 2];
    g[(x + 16) >> 2] = +g[(x + 16) >> 2] / 2.0;
    g[z >> 2] = 1.0 - +g[h >> 2];
    g[(z + 4) >> 2] = +g[z >> 2] - +g[(h + 4) >> 2];
    g[(z + 8) >> 2] = +g[(z + 4) >> 2] - +g[(h + 8) >> 2];
    g[(z + 12) >> 2] = +g[(z + 8) >> 2] - +g[(h + 12) >> 2];
    g[(z + 16) >> 2] = +g[(z + 12) >> 2] - +g[(h + 16) >> 2];
    g[(z + 16) >> 2] = +g[(z + 16) >> 2] / 2.0;
    g[w >> 2] = 0.0;
    g[t >> 2] = 0.0;
    g[u >> 2] = 9999999933815812510711506.0e12;
    g[v >> 2] = 9999999933815812510711506.0e12;
    c[r >> 2] = 0;
    while (1) {
      if ((c[r >> 2] | 0) >= 10) break;
      if (!(c[r >> 2] & 1)) {
        c[y >> 2] = x;
        c[s >> 2] = u;
      } else {
        c[y >> 2] = z;
        c[s >> 2] = v;
      }
      c[B >> 2] = 0;
      g[A >> 2] = +g[(C + (c[B >> 2] << 2)) >> 2];
      while (1) {
        if ((c[B >> 2] | 0) >= 4) break;
        g[k >> 2] = +P(+(+g[w >> 2] * 6.2831854820251465));
        g[l >> 2] = +g[k >> 2] * 2.0 + +g[c[y >> 2] >> 2];
        g[m >> 2] =
          +g[k >> 2] * 2.0 * +g[l >> 2] - 1.0 + +g[((c[y >> 2] | 0) + 4) >> 2];
        g[n >> 2] =
          +g[k >> 2] * 2.0 * +g[m >> 2] -
          +g[l >> 2] +
          +g[((c[y >> 2] | 0) + 8) >> 2];
        g[o >> 2] =
          +g[k >> 2] * 2.0 * +g[n >> 2] -
          +g[m >> 2] +
          +g[((c[y >> 2] | 0) + 12) >> 2];
        g[p >> 2] =
          +g[k >> 2] * +g[o >> 2] -
          +g[n >> 2] +
          +g[((c[y >> 2] | 0) + 16) >> 2];
        if (
          !(+g[p >> 2] * +g[c[s >> 2] >> 2] <= 0.0) ? !(+g[w >> 2] >= 0.5) : 0
        ) {
          g[c[s >> 2] >> 2] = +g[p >> 2];
          g[w >> 2] = +g[w >> 2] + +g[A >> 2];
          continue;
        }
        if ((c[B >> 2] | 0) != 3) {
          if (!(c[B >> 2] | 0)) g[t >> 2] = +g[w >> 2];
          c[B >> 2] = (c[B >> 2] | 0) + 1;
          g[w >> 2] = +g[w >> 2] - +g[(C + (c[B >> 2] << 2)) >> 2];
          g[A >> 2] = +g[(C + (c[B >> 2] << 2)) >> 2];
          continue;
        }
        d = +M(+(+g[p >> 2]));
        h = d >= +M(+(+g[c[s >> 2] >> 2]));
        d = +g[w >> 2];
        if (h) g[((c[j >> 2] | 0) + (c[r >> 2] << 2)) >> 2] = d - +g[A >> 2];
        else g[((c[j >> 2] | 0) + (c[r >> 2] << 2)) >> 2] = d;
        e = c[s >> 2] | 0;
        if (+g[c[s >> 2] >> 2] >= 0.0)
          g[e >> 2] = -9999999933815812510711506.0e12;
        else g[e >> 2] = 9999999933815812510711506.0e12;
        g[w >> 2] = +g[t >> 2];
        c[B >> 2] = 0;
        c[B >> 2] = 4;
      }
      c[r >> 2] = (c[r >> 2] | 0) + 1;
    }
    c[q >> 2] = 0;
    while (1) {
      if ((c[q >> 2] | 0) >= 10) break;
      g[((c[j >> 2] | 0) + (c[q >> 2] << 2)) >> 2] =
        +g[((c[j >> 2] | 0) + (c[q >> 2] << 2)) >> 2] * 6.2831854820251465;
      c[q >> 2] = (c[q >> 2] | 0) + 1;
    }
    i = D;
  }
  function Bb(a, b) {
    a = a | 0;
    b = b | 0;
    var d = 0,
      e = 0,
      f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0.0;
    t = i;
    i = (i + 192) | 0;
    d = (t + 164) | 0;
    e = (t + 140) | 0;
    o = (t + 92) | 0;
    p = (t + 88) | 0;
    n = (t + 136) | 0;
    q = (t + 144) | 0;
    r = (t + 168) | 0;
    f = (t + 40) | 0;
    h = (t + 20) | 0;
    j = (t + 96) | 0;
    k = (t + 64) | 0;
    l = (t + 116) | 0;
    m = t;
    c[d >> 2] = a;
    c[e >> 2] = b;
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= 10) break;
      g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] =
        +g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] * 0.15915493667125702;
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    if (
      !(!(+g[c[e >> 2] >> 2] <= 0.0)
        ? !(+g[((c[e >> 2] | 0) + 36) >> 2] >= 0.5)
        : 0)
    )
      s = 6;
    a: do
      if ((s | 0) == 6) {
        if (+g[c[e >> 2] >> 2] <= 0.0) g[c[e >> 2] >> 2] = 0.02199999988079071;
        if (+g[((c[e >> 2] | 0) + 36) >> 2] >= 0.5)
          g[((c[e >> 2] | 0) + 36) >> 2] = 0.49900001287460327;
        g[n >> 2] =
          (+g[((c[e >> 2] | 0) + 36) >> 2] - +g[c[e >> 2] >> 2]) / 9.0;
        c[o >> 2] = 1;
        while (1) {
          if ((c[o >> 2] | 0) >= 10) break a;
          g[((c[e >> 2] | 0) + (c[o >> 2] << 2)) >> 2] =
            +g[((c[e >> 2] | 0) + (((c[o >> 2] | 0) - 1) << 2)) >> 2] +
            +g[n >> 2];
          c[o >> 2] = (c[o >> 2] | 0) + 1;
        }
      }
    while (0);
    c[(h + 0) >> 2] = 0;
    c[(h + 4) >> 2] = 0;
    c[(h + 8) >> 2] = 0;
    c[(h + 12) >> 2] = 0;
    c[(h + 16) >> 2] = 0;
    c[(j + 0) >> 2] = 0;
    c[(j + 4) >> 2] = 0;
    c[(j + 8) >> 2] = 0;
    c[(j + 12) >> 2] = 0;
    c[(j + 16) >> 2] = 0;
    c[(l + 0) >> 2] = 0;
    c[(l + 4) >> 2] = 0;
    c[(l + 8) >> 2] = 0;
    c[(l + 12) >> 2] = 0;
    c[(l + 16) >> 2] = 0;
    c[(m + 0) >> 2] = 0;
    c[(m + 4) >> 2] = 0;
    c[(m + 8) >> 2] = 0;
    c[(m + 12) >> 2] = 0;
    c[(m + 16) >> 2] = 0;
    c[(f + 0) >> 2] = 0;
    c[(f + 4) >> 2] = 0;
    c[(f + 8) >> 2] = 0;
    c[(f + 12) >> 2] = 0;
    c[(f + 16) >> 2] = 0;
    c[(f + 20) >> 2] = 0;
    c[(k + 0) >> 2] = 0;
    c[(k + 4) >> 2] = 0;
    c[(k + 8) >> 2] = 0;
    c[(k + 12) >> 2] = 0;
    c[(k + 16) >> 2] = 0;
    c[(k + 20) >> 2] = 0;
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= 5) break;
      u = +P(
        +(
          +g[((c[e >> 2] | 0) + ((c[o >> 2] << 1) << 2)) >> 2] *
          6.2831854820251465
        )
      );
      g[(q + (c[o >> 2] << 2)) >> 2] = u;
      u = +P(
        +(
          +g[((c[e >> 2] | 0) + (((c[o >> 2] << 1) + 1) << 2)) >> 2] *
          6.2831854820251465
        )
      );
      g[(r + (c[o >> 2] << 2)) >> 2] = u;
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    g[f >> 2] = 0.25;
    g[k >> 2] = 0.25;
    c[o >> 2] = 0;
    while (1) {
      if ((c[o >> 2] | 0) >= 5) break;
      g[(f + (((c[o >> 2] | 0) + 1) << 2)) >> 2] =
        +g[(f + (c[o >> 2] << 2)) >> 2] -
        +g[(q + (c[o >> 2] << 2)) >> 2] *
          2.0 *
          +g[(h + (c[o >> 2] << 2)) >> 2] +
        +g[(j + (c[o >> 2] << 2)) >> 2];
      g[(k + (((c[o >> 2] | 0) + 1) << 2)) >> 2] =
        +g[(k + (c[o >> 2] << 2)) >> 2] -
        +g[(r + (c[o >> 2] << 2)) >> 2] *
          2.0 *
          +g[(l + (c[o >> 2] << 2)) >> 2] +
        +g[(m + (c[o >> 2] << 2)) >> 2];
      g[(j + (c[o >> 2] << 2)) >> 2] = +g[(h + (c[o >> 2] << 2)) >> 2];
      g[(h + (c[o >> 2] << 2)) >> 2] = +g[(f + (c[o >> 2] << 2)) >> 2];
      g[(m + (c[o >> 2] << 2)) >> 2] = +g[(l + (c[o >> 2] << 2)) >> 2];
      g[(l + (c[o >> 2] << 2)) >> 2] = +g[(k + (c[o >> 2] << 2)) >> 2];
      c[o >> 2] = (c[o >> 2] | 0) + 1;
    }
    c[p >> 2] = 0;
    while (1) {
      if ((c[p >> 2] | 0) >= 10) break;
      if (!(c[p >> 2] | 0)) {
        g[f >> 2] = 0.25;
        g[k >> 2] = -0.25;
      } else {
        g[k >> 2] = 0.0;
        g[f >> 2] = 0.0;
      }
      c[o >> 2] = 0;
      while (1) {
        if ((c[o >> 2] | 0) >= 5) break;
        g[(f + (((c[o >> 2] | 0) + 1) << 2)) >> 2] =
          +g[(f + (c[o >> 2] << 2)) >> 2] -
          +g[(q + (c[o >> 2] << 2)) >> 2] *
            2.0 *
            +g[(h + (c[o >> 2] << 2)) >> 2] +
          +g[(j + (c[o >> 2] << 2)) >> 2];
        g[(k + (((c[o >> 2] | 0) + 1) << 2)) >> 2] =
          +g[(k + (c[o >> 2] << 2)) >> 2] -
          +g[(r + (c[o >> 2] << 2)) >> 2] *
            2.0 *
            +g[(l + (c[o >> 2] << 2)) >> 2] +
          +g[(m + (c[o >> 2] << 2)) >> 2];
        g[(j + (c[o >> 2] << 2)) >> 2] = +g[(h + (c[o >> 2] << 2)) >> 2];
        g[(h + (c[o >> 2] << 2)) >> 2] = +g[(f + (c[o >> 2] << 2)) >> 2];
        g[(m + (c[o >> 2] << 2)) >> 2] = +g[(l + (c[o >> 2] << 2)) >> 2];
        g[(l + (c[o >> 2] << 2)) >> 2] = +g[(k + (c[o >> 2] << 2)) >> 2];
        c[o >> 2] = (c[o >> 2] | 0) + 1;
      }
      g[((c[d >> 2] | 0) + (((c[p >> 2] | 0) + 1) << 2)) >> 2] =
        (+g[(f + 20) >> 2] + +g[(k + 20) >> 2]) * 2.0;
      c[p >> 2] = (c[p >> 2] | 0) + 1;
    }
    g[c[d >> 2] >> 2] = 1.0;
    i = t;
  }
  function Cb(a, b, d, e, f) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    f = f | 0;
    var g = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0;
    g = i;
    i = (i + 32) | 0;
    l = (g + 20) | 0;
    k = (g + 16) | 0;
    h = (g + 12) | 0;
    m = (g + 8) | 0;
    n = (g + 4) | 0;
    j = g;
    c[l >> 2] = a;
    c[k >> 2] = b;
    c[h >> 2] = d;
    c[m >> 2] = e;
    c[n >> 2] = f;
    c[j >> 2] = (c[n >> 2] | 0) - (c[m >> 2] | 0);
    c[c[k >> 2] >> 2] = c[c[l >> 2] >> 2] >> c[j >> 2];
    c[c[h >> 2] >> 2] =
      (c[c[l >> 2] >> 2] | 0) - (c[c[k >> 2] >> 2] << c[j >> 2]);
    i = g;
  }
  function Db(a, b, d) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    var e = 0,
      f = 0,
      g = 0,
      h = 0;
    e = i;
    i = (i + 16) | 0;
    g = (e + 8) | 0;
    f = (e + 4) | 0;
    h = e;
    c[g >> 2] = a;
    c[f >> 2] = b;
    c[h >> 2] = d;
    c[c[g >> 2] >> 2] = c[c[g >> 2] >> 2] << c[h >> 2];
    b = c[g >> 2] | 0;
    c[b >> 2] = (c[b >> 2] | 0) + (c[f >> 2] | 0);
    i = e;
  }
  function Eb(b, e, f, g) {
    b = b | 0;
    e = e | 0;
    f = f | 0;
    g = g | 0;
    var h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0;
    n = i;
    i = (i + 32) | 0;
    h = (n + 16) | 0;
    j = (n + 12) | 0;
    k = (n + 8) | 0;
    l = (n + 4) | 0;
    m = n;
    c[h >> 2] = b;
    c[j >> 2] = e;
    c[k >> 2] = f;
    c[l >> 2] = g;
    if (!(c[c[l >> 2] >> 2] | 0)) a[c[c[h >> 2] >> 2] >> 0] = 0;
    while (1) {
      if ((c[k >> 2] | 0) <= 0) break;
      if ((c[c[l >> 2] >> 2] | 0) == 8) {
        c[c[l >> 2] >> 2] = 0;
        g = c[h >> 2] | 0;
        c[g >> 2] = (c[g >> 2] | 0) + 1;
        a[c[c[h >> 2] >> 2] >> 0] = 0;
      }
      c[m >> 2] = 8 - (c[c[l >> 2] >> 2] | 0);
      f = c[j >> 2] | 0;
      if ((c[k >> 2] | 0) <= (c[m >> 2] | 0)) {
        g = c[c[h >> 2] >> 2] | 0;
        a[g >> 0] =
          d[g >> 0] | 0 | ((f << ((c[m >> 2] | 0) - (c[k >> 2] | 0))) & 255);
        g = c[l >> 2] | 0;
        c[g >> 2] = (c[g >> 2] | 0) + (c[k >> 2] | 0);
        c[k >> 2] = 0;
        continue;
      } else {
        g = c[c[h >> 2] >> 2] | 0;
        a[g >> 0] =
          d[g >> 0] | 0 | ((f >> ((c[k >> 2] | 0) - (c[m >> 2] | 0))) & 255);
        c[c[l >> 2] >> 2] = 8;
        c[j >> 2] =
          (c[j >> 2] | 0) -
          ((c[j >> 2] >> ((c[k >> 2] | 0) - (c[m >> 2] | 0))) <<
            ((c[k >> 2] | 0) - (c[m >> 2] | 0)));
        c[k >> 2] = (c[k >> 2] | 0) - (c[m >> 2] | 0);
        continue;
      }
    }
    i = n;
  }
  function Fb(a, b, e, f) {
    a = a | 0;
    b = b | 0;
    e = e | 0;
    f = f | 0;
    var g = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0;
    m = i;
    i = (i + 32) | 0;
    g = (m + 16) | 0;
    h = (m + 12) | 0;
    j = (m + 8) | 0;
    k = (m + 4) | 0;
    l = m;
    c[g >> 2] = a;
    c[h >> 2] = b;
    c[j >> 2] = e;
    c[k >> 2] = f;
    c[c[h >> 2] >> 2] = 0;
    while (1) {
      if ((c[j >> 2] | 0) <= 0) break;
      if ((c[c[k >> 2] >> 2] | 0) == 8) {
        c[c[k >> 2] >> 2] = 0;
        f = c[g >> 2] | 0;
        c[f >> 2] = (c[f >> 2] | 0) + 1;
      }
      c[l >> 2] = 8 - (c[c[k >> 2] >> 2] | 0);
      if ((c[l >> 2] | 0) >= (c[j >> 2] | 0)) {
        f = c[h >> 2] | 0;
        c[f >> 2] =
          (c[f >> 2] | 0) +
          ((((d[c[c[g >> 2] >> 2] >> 0] | 0) << c[c[k >> 2] >> 2]) & 255) >>
            (8 - (c[j >> 2] | 0)));
        f = c[k >> 2] | 0;
        c[f >> 2] = (c[f >> 2] | 0) + (c[j >> 2] | 0);
        c[j >> 2] = 0;
        continue;
      }
      a = ((d[c[c[g >> 2] >> 2] >> 0] | 0) << c[c[k >> 2] >> 2]) & 255;
      e = c[j >> 2] | 0;
      if (((8 - (c[j >> 2] | 0)) | 0) > 0) {
        f = c[h >> 2] | 0;
        c[f >> 2] = (c[f >> 2] | 0) + (a >> (8 - e));
        c[c[k >> 2] >> 2] = 8;
      } else {
        f = c[h >> 2] | 0;
        c[f >> 2] = (c[f >> 2] | 0) + (a << (e - 8));
        c[c[k >> 2] >> 2] = 8;
      }
      c[j >> 2] = (c[j >> 2] | 0) - (c[l >> 2] | 0);
    }
    i = m;
  }
  function Gb(a, b, d, e) {
    a = a | 0;
    b = b | 0;
    d = d | 0;
    e = e | 0;
    var f = 0,
      h = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0.0;
    r = i;
    i = (i + 48) | 0;
    p = r;
    h = (r + 16) | 0;
    q = (r + 28) | 0;
    f = (r + 4) | 0;
    j = (r + 32) | 0;
    k = (r + 36) | 0;
    o = (r + 12) | 0;
    m = (r + 24) | 0;
    l = (r + 20) | 0;
    n = (r + 8) | 0;
    c[p >> 2] = a;
    c[h >> 2] = b;
    c[q >> 2] = d;
    c[f >> 2] = e;
    c[o >> 2] = c[p >> 2];
    c[j >> 2] = 0;
    while (1) {
      if ((c[j >> 2] | 0) >= 10) break;
      c[m >> 2] = (c[p >> 2] | 0) + (((c[j >> 2] | 0) - 1) << 2);
      c[l >> 2] = (c[h >> 2] | 0) + 4;
      c[n >> 2] = (c[f >> 2] | 0) + 36;
      c[k >> 2] = 1;
      while (1) {
        if ((c[k >> 2] | 0) > (c[j >> 2] | 0)) break;
        d = c[l >> 2] | 0;
        c[l >> 2] = d + 4;
        s = +g[d >> 2];
        d = c[m >> 2] | 0;
        c[m >> 2] = d + -4;
        e = c[o >> 2] | 0;
        g[e >> 2] = +g[e >> 2] - s * +g[d >> 2];
        c[k >> 2] = (c[k >> 2] | 0) + 1;
      }
      c[k >> 2] = (c[j >> 2] | 0) + 1;
      while (1) {
        if ((c[k >> 2] | 0) >= 11) break;
        d = c[l >> 2] | 0;
        c[l >> 2] = d + 4;
        s = +g[d >> 2];
        d = c[n >> 2] | 0;
        c[n >> 2] = d + -4;
        e = c[o >> 2] | 0;
        g[e >> 2] = +g[e >> 2] - s * +g[d >> 2];
        c[k >> 2] = (c[k >> 2] | 0) + 1;
      }
      c[o >> 2] = (c[o >> 2] | 0) + 4;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    c[j >> 2] = 10;
    while (1) {
      if ((c[j >> 2] | 0) >= (c[q >> 2] | 0)) break;
      c[m >> 2] = (c[p >> 2] | 0) + (((c[j >> 2] | 0) - 1) << 2);
      c[l >> 2] = (c[h >> 2] | 0) + 4;
      c[k >> 2] = 1;
      while (1) {
        if ((c[k >> 2] | 0) >= 11) break;
        e = c[l >> 2] | 0;
        c[l >> 2] = e + 4;
        s = +g[e >> 2];
        e = c[m >> 2] | 0;
        c[m >> 2] = e + -4;
        n = c[o >> 2] | 0;
        g[n >> 2] = +g[n >> 2] - s * +g[e >> 2];
        c[k >> 2] = (c[k >> 2] | 0) + 1;
      }
      c[o >> 2] = (c[o >> 2] | 0) + 4;
      c[j >> 2] = (c[j >> 2] | 0) + 1;
    }
    h = ((c[f >> 2] | 0) + 0) | 0;
    a = ((c[p >> 2] | 0) + (((c[q >> 2] | 0) - 10) << 2) + 0) | 0;
    f = (h + 40) | 0;
    do {
      c[h >> 2] = c[a >> 2];
      h = (h + 4) | 0;
      a = (a + 4) | 0;
    } while ((h | 0) < (f | 0));
    i = r;
  }
  function Hb(a) {
    a |= 0;
    let b = 0;
    var d = 0;
    var e = 0;
    var f = 0;
    var g = 0;
    var h = 0;
    var i = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    var n = 0;
    var o = 0;
    var p = 0;
    var q = 0;
    var r = 0;
    var s = 0;
    var t = 0;
    var u = 0;
    var v = 0;
    var w = 0;
    var x = 0;
    var y = 0;
    var z = 0;
    var A = 0;
    var B = 0;
    var C = 0;
    var D = 0;
    var E = 0;
    var F = 0;
    var G = 0;
    var H = 0;
    var I = 0;
    var J = 0;
    var K = 0;
    var L = 0;
    var M = 0;
    var N = 0;
    var O = 0;
    var P = 0;
    var Q = 0;
    do
      if (a >>> 0 < 245) {
        if (a >>> 0 < 11) p = 16;
        else p = (a + 11) & -8;
        a = p >>> 3;
        l = c[2346] | 0;
        i = l >>> a;
        if (i & 3) {
          e = (((i & 1) ^ 1) + a) | 0;
          f = e << 1;
          b = (9424 + (f << 2)) | 0;
          f = (9424 + ((f + 2) << 2)) | 0;
          g = c[f >> 2] | 0;
          h = (g + 8) | 0;
          i = c[h >> 2] | 0;
          do
            if ((b | 0) != (i | 0)) {
              if (i >>> 0 < (c[2350] | 0) >>> 0) ga();
              d = (i + 12) | 0;
              if ((c[d >> 2] | 0) == (g | 0)) {
                c[d >> 2] = b;
                c[f >> 2] = i;
                break;
              } else ga();
            } else c[2346] = l & ~(1 << e);
          while (0);
          Q = e << 3;
          c[(g + 4) >> 2] = Q | 3;
          Q = (g + (Q | 4)) | 0;
          c[Q >> 2] = c[Q >> 2] | 1;
          Q = h;
          return Q | 0;
        }
        k = c[2348] | 0;
        if (p >>> 0 > k >>> 0) {
          if (i) {
            f = 2 << a;
            f = (i << a) & (f | (0 - f));
            f = ((f & (0 - f)) + -1) | 0;
            a = (f >>> 12) & 16;
            f >>>= a;
            e = (f >>> 5) & 8;
            f >>>= e;
            d = (f >>> 2) & 4;
            f >>>= d;
            g = (f >>> 1) & 2;
            f >>>= g;
            h = (f >>> 1) & 1;
            h = ((e | a | d | g | h) + (f >>> h)) | 0;
            f = h << 1;
            g = (9424 + (f << 2)) | 0;
            f = (9424 + ((f + 2) << 2)) | 0;
            d = c[f >> 2] | 0;
            a = (d + 8) | 0;
            e = c[a >> 2] | 0;
            do
              if ((g | 0) != (e | 0)) {
                if (e >>> 0 < (c[2350] | 0) >>> 0) ga();
                i = (e + 12) | 0;
                if ((c[i >> 2] | 0) == (d | 0)) {
                  c[i >> 2] = g;
                  c[f >> 2] = e;
                  m = c[2348] | 0;
                  break;
                } else ga();
              } else {
                c[2346] = l & ~(1 << h);
                m = k;
              }
            while (0);
            Q = h << 3;
            b = (Q - p) | 0;
            c[(d + 4) >> 2] = p | 3;
            j = (d + p) | 0;
            c[(d + (p | 4)) >> 2] = b | 1;
            c[(d + Q) >> 2] = b;
            if (m) {
              e = c[2351] | 0;
              g = m >>> 3;
              i = g << 1;
              f = (9424 + (i << 2)) | 0;
              h = c[2346] | 0;
              g = 1 << g;
              if (h & g) {
                h = (9424 + ((i + 2) << 2)) | 0;
                i = c[h >> 2] | 0;
                if (i >>> 0 < (c[2350] | 0) >>> 0) ga();
                else {
                  n = h;
                  o = i;
                }
              } else {
                c[2346] = h | g;
                n = (9424 + ((i + 2) << 2)) | 0;
                o = f;
              }
              c[n >> 2] = e;
              c[(o + 12) >> 2] = e;
              c[(e + 8) >> 2] = o;
              c[(e + 12) >> 2] = f;
            }
            c[2348] = b;
            c[2351] = j;
            Q = a;
            return Q | 0;
          }
          a = c[2347] | 0;
          if (a) {
            h = ((a & (0 - a)) + -1) | 0;
            P = (h >>> 12) & 16;
            h >>>= P;
            O = (h >>> 5) & 8;
            h >>>= O;
            Q = (h >>> 2) & 4;
            h >>>= Q;
            i = (h >>> 1) & 2;
            h >>>= i;
            g = (h >>> 1) & 1;
            g = c[(9688 + (((O | P | Q | i | g) + (h >>> g)) << 2)) >> 2] | 0;
            h = ((c[(g + 4) >> 2] & -8) - p) | 0;
            i = g;
            while (1) {
              d = c[(i + 16) >> 2] | 0;
              if (!d) {
                d = c[(i + 20) >> 2] | 0;
                if (!d) {
                  l = h;
                  k = g;
                  break;
                }
              }
              i = ((c[(d + 4) >> 2] & -8) - p) | 0;
              Q = i >>> 0 < h >>> 0;
              h = Q ? i : h;
              i = d;
              g = Q ? d : g;
            }
            a = c[2350] | 0;
            if (k >>> 0 < a >>> 0) ga();
            b = (k + p) | 0;
            if (k >>> 0 >= b >>> 0) ga();
            j = c[(k + 24) >> 2] | 0;
            g = c[(k + 12) >> 2] | 0;
            do
              if ((g | 0) == (k | 0)) {
                h = (k + 20) | 0;
                i = c[h >> 2] | 0;
                if (!i) {
                  h = (k + 16) | 0;
                  i = c[h >> 2] | 0;
                  if (!i) {
                    e = 0;
                    break;
                  }
                }
                while (1) {
                  g = (i + 20) | 0;
                  f = c[g >> 2] | 0;
                  if (f) {
                    i = f;
                    h = g;
                    continue;
                  }
                  g = (i + 16) | 0;
                  f = c[g >> 2] | 0;
                  if (!f) break;
                  else {
                    i = f;
                    h = g;
                  }
                }
                if (h >>> 0 < a >>> 0) ga();
                else {
                  c[h >> 2] = 0;
                  e = i;
                  break;
                }
              } else {
                f = c[(k + 8) >> 2] | 0;
                if (f >>> 0 < a >>> 0) ga();
                i = (f + 12) | 0;
                if ((c[i >> 2] | 0) != (k | 0)) ga();
                h = (g + 8) | 0;
                if ((c[h >> 2] | 0) == (k | 0)) {
                  c[i >> 2] = g;
                  c[h >> 2] = f;
                  e = g;
                  break;
                } else ga();
              }
            while (0);
            do
              if (j) {
                i = c[(k + 28) >> 2] | 0;
                h = (9688 + (i << 2)) | 0;
                if ((k | 0) == (c[h >> 2] | 0)) {
                  c[h >> 2] = e;
                  if (!e) {
                    c[2347] &= ~(1 << i);
                    break;
                  }
                } else {
                  if (j >>> 0 < (c[2350] | 0) >>> 0) ga();
                  i = (j + 16) | 0;
                  if ((c[i >> 2] | 0) == (k | 0)) c[i >> 2] = e;
                  else c[(j + 20) >> 2] = e;
                  if (!e) break;
                }
                h = c[2350] | 0;
                if (e >>> 0 < h >>> 0) ga();
                c[(e + 24) >> 2] = j;
                i = c[(k + 16) >> 2] | 0;
                do
                  if (i)
                    if (i >>> 0 < h >>> 0) ga();
                    else {
                      c[(e + 16) >> 2] = i;
                      c[(i + 24) >> 2] = e;
                      break;
                    }
                while (0);
                i = c[(k + 20) >> 2] | 0;
                if (i)
                  if (i >>> 0 < (c[2350] | 0) >>> 0) ga();
                  else {
                    c[(e + 20) >> 2] = i;
                    c[(i + 24) >> 2] = e;
                    break;
                  }
              }
            while (0);
            if (l >>> 0 < 16) {
              Q = (l + p) | 0;
              c[(k + 4) >> 2] = Q | 3;
              Q = (k + (Q + 4)) | 0;
              c[Q >> 2] = c[Q >> 2] | 1;
            } else {
              c[(k + 4) >> 2] = p | 3;
              c[(k + (p | 4)) >> 2] = l | 1;
              c[(k + (l + p)) >> 2] = l;
              d = c[2348] | 0;
              if (d) {
                e = c[2351] | 0;
                g = d >>> 3;
                i = g << 1;
                f = (9424 + (i << 2)) | 0;
                h = c[2346] | 0;
                g = 1 << g;
                if (h & g) {
                  i = (9424 + ((i + 2) << 2)) | 0;
                  h = c[i >> 2] | 0;
                  if (h >>> 0 < (c[2350] | 0) >>> 0) ga();
                  else {
                    q = i;
                    r = h;
                  }
                } else {
                  c[2346] = h | g;
                  q = (9424 + ((i + 2) << 2)) | 0;
                  r = f;
                }
                c[q >> 2] = e;
                c[(r + 12) >> 2] = e;
                c[(e + 8) >> 2] = r;
                c[(e + 12) >> 2] = f;
              }
              c[2348] = l;
              c[2351] = b;
            }
            Q = (k + 8) | 0;
            return Q | 0;
          } else r = p;
        } else r = p;
      } else if (a >>> 0 <= 4294967231) {
        a = (a + 11) | 0;
        r = a & -8;
        b = c[2347] | 0;
        if (b) {
          h = (0 - r) | 0;
          a >>>= 8;
          if (a)
            if (r >>> 0 > 16777215) d = 31;
            else {
              p = (((a + 1048320) | 0) >>> 16) & 8;
              q = a << p;
              o = (((q + 520192) | 0) >>> 16) & 4;
              q <<= o;
              d = (((q + 245760) | 0) >>> 16) & 2;
              d = (14 - (o | p | d) + ((q << d) >>> 15)) | 0;
              d = ((r >>> ((d + 7) | 0)) & 1) | (d << 1);
            }
          else d = 0;
          f = c[(9688 + (d << 2)) >> 2] | 0;
          a: do
            if (!f) {
              a = 0;
              i = 0;
            } else {
              if ((d | 0) == 31) i = 0;
              else i = (25 - (d >>> 1)) | 0;
              l = h;
              a = 0;
              k = r << i;
              i = 0;
              while (1) {
                e = c[(f + 4) >> 2] & -8;
                h = (e - r) | 0;
                if (h >>> 0 < l >>> 0)
                  if ((e | 0) == (r | 0)) {
                    a = f;
                    i = f;
                    break a;
                  } else i = f;
                else h = l;
                q = c[(f + 20) >> 2] | 0;
                f = c[(f + ((k >>> 31) << 2) + 16) >> 2] | 0;
                a = ((q | 0) == 0) | ((q | 0) == (f | 0)) ? a : q;
                if (!f) break;
                else {
                  l = h;
                  k <<= 1;
                }
              }
            }
          while (0);
          if (((a | 0) == 0) & ((i | 0) == 0)) {
            a = 2 << d;
            a = b & (a | (0 - a));
            if (!a) break;
            q = ((a & (0 - a)) + -1) | 0;
            n = (q >>> 12) & 16;
            q >>>= n;
            m = (q >>> 5) & 8;
            q >>>= m;
            o = (q >>> 2) & 4;
            q >>>= o;
            p = (q >>> 1) & 2;
            q >>>= p;
            a = (q >>> 1) & 1;
            a = c[(9688 + (((m | n | o | p | a) + (q >>> a)) << 2)) >> 2] | 0;
          }
          if (!a) {
            o = h;
            p = i;
          } else
            while (1) {
              q = ((c[(a + 4) >> 2] & -8) - r) | 0;
              f = q >>> 0 < h >>> 0;
              h = f ? q : h;
              i = f ? a : i;
              f = c[(a + 16) >> 2] | 0;
              if (f) {
                a = f;
                continue;
              }
              a = c[(a + 20) >> 2] | 0;
              if (!a) {
                o = h;
                p = i;
                break;
              }
            }
          if ((p | 0) != 0 ? o >>> 0 < (((c[2348] | 0) - r) | 0) >>> 0 : 0) {
            a = c[2350] | 0;
            if (p >>> 0 < a >>> 0) ga();
            m = (p + r) | 0;
            if (p >>> 0 >= m >>> 0) ga();
            j = c[(p + 24) >> 2] | 0;
            g = c[(p + 12) >> 2] | 0;
            do
              if ((g | 0) == (p | 0)) {
                h = (p + 20) | 0;
                i = c[h >> 2] | 0;
                if (!i) {
                  h = (p + 16) | 0;
                  i = c[h >> 2] | 0;
                  if (!i) {
                    t = 0;
                    break;
                  }
                }
                while (1) {
                  g = (i + 20) | 0;
                  f = c[g >> 2] | 0;
                  if (f) {
                    i = f;
                    h = g;
                    continue;
                  }
                  g = (i + 16) | 0;
                  f = c[g >> 2] | 0;
                  if (!f) break;
                  else {
                    i = f;
                    h = g;
                  }
                }
                if (h >>> 0 < a >>> 0) ga();
                else {
                  c[h >> 2] = 0;
                  t = i;
                  break;
                }
              } else {
                f = c[(p + 8) >> 2] | 0;
                if (f >>> 0 < a >>> 0) ga();
                i = (f + 12) | 0;
                if ((c[i >> 2] | 0) != (p | 0)) ga();
                h = (g + 8) | 0;
                if ((c[h >> 2] | 0) == (p | 0)) {
                  c[i >> 2] = g;
                  c[h >> 2] = f;
                  t = g;
                  break;
                } else ga();
              }
            while (0);
            do
              if (j) {
                i = c[(p + 28) >> 2] | 0;
                h = (9688 + (i << 2)) | 0;
                if ((p | 0) == (c[h >> 2] | 0)) {
                  c[h >> 2] = t;
                  if (!t) {
                    c[2347] &= ~(1 << i);
                    break;
                  }
                } else {
                  if (j >>> 0 < (c[2350] | 0) >>> 0) ga();
                  i = (j + 16) | 0;
                  if ((c[i >> 2] | 0) == (p | 0)) c[i >> 2] = t;
                  else c[(j + 20) >> 2] = t;
                  if (!t) break;
                }
                h = c[2350] | 0;
                if (t >>> 0 < h >>> 0) ga();
                c[(t + 24) >> 2] = j;
                i = c[(p + 16) >> 2] | 0;
                do
                  if (i)
                    if (i >>> 0 < h >>> 0) ga();
                    else {
                      c[(t + 16) >> 2] = i;
                      c[(i + 24) >> 2] = t;
                      break;
                    }
                while (0);
                i = c[(p + 20) >> 2] | 0;
                if (i)
                  if (i >>> 0 < (c[2350] | 0) >>> 0) ga();
                  else {
                    c[(t + 20) >> 2] = i;
                    c[(i + 24) >> 2] = t;
                    break;
                  }
              }
            while (0);
            b: do
              if (o >>> 0 >= 16) {
                c[(p + 4) >> 2] = r | 3;
                c[(p + (r | 4)) >> 2] = o | 1;
                c[(p + (o + r)) >> 2] = o;
                i = o >>> 3;
                if (o >>> 0 < 256) {
                  h = i << 1;
                  f = (9424 + (h << 2)) | 0;
                  g = c[2346] | 0;
                  i = 1 << i;
                  do
                    if (!(g & i)) {
                      c[2346] = g | i;
                      u = (9424 + ((h + 2) << 2)) | 0;
                      v = f;
                    } else {
                      i = (9424 + ((h + 2) << 2)) | 0;
                      h = c[i >> 2] | 0;
                      if (h >>> 0 >= (c[2350] | 0) >>> 0) {
                        u = i;
                        v = h;
                        break;
                      }
                      ga();
                    }
                  while (0);
                  c[u >> 2] = m;
                  c[(v + 12) >> 2] = m;
                  c[(p + (r + 8)) >> 2] = v;
                  c[(p + (r + 12)) >> 2] = f;
                  break;
                }
                d = o >>> 8;
                if (d)
                  if (o >>> 0 > 16777215) f = 31;
                  else {
                    P = (((d + 1048320) | 0) >>> 16) & 8;
                    Q = d << P;
                    O = (((Q + 520192) | 0) >>> 16) & 4;
                    Q <<= O;
                    f = (((Q + 245760) | 0) >>> 16) & 2;
                    f = (14 - (O | P | f) + ((Q << f) >>> 15)) | 0;
                    f = ((o >>> ((f + 7) | 0)) & 1) | (f << 1);
                  }
                else f = 0;
                i = (9688 + (f << 2)) | 0;
                c[(p + (r + 28)) >> 2] = f;
                c[(p + (r + 20)) >> 2] = 0;
                c[(p + (r + 16)) >> 2] = 0;
                h = c[2347] | 0;
                g = 1 << f;
                if (!(h & g)) {
                  c[2347] = h | g;
                  c[i >> 2] = m;
                  c[(p + (r + 24)) >> 2] = i;
                  c[(p + (r + 12)) >> 2] = m;
                  c[(p + (r + 8)) >> 2] = m;
                  break;
                }
                d = c[i >> 2] | 0;
                if ((f | 0) == 31) b = 0;
                else b = (25 - (f >>> 1)) | 0;
                c: do
                  if (((c[(d + 4) >> 2] & -8) | 0) != (o | 0)) {
                    h = o << b;
                    while (1) {
                      b = (d + ((h >>> 31) << 2) + 16) | 0;
                      i = c[b >> 2] | 0;
                      if (!i) break;
                      if (((c[(i + 4) >> 2] & -8) | 0) == (o | 0)) {
                        B = i;
                        break c;
                      } else {
                        h <<= 1;
                        d = i;
                      }
                    }
                    if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
                    else {
                      c[b >> 2] = m;
                      c[(p + (r + 24)) >> 2] = d;
                      c[(p + (r + 12)) >> 2] = m;
                      c[(p + (r + 8)) >> 2] = m;
                      break b;
                    }
                  } else B = d;
                while (0);
                d = (B + 8) | 0;
                b = c[d >> 2] | 0;
                Q = c[2350] | 0;
                if ((B >>> 0 >= Q >>> 0) & (b >>> 0 >= Q >>> 0)) {
                  c[(b + 12) >> 2] = m;
                  c[d >> 2] = m;
                  c[(p + (r + 8)) >> 2] = b;
                  c[(p + (r + 12)) >> 2] = B;
                  c[(p + (r + 24)) >> 2] = 0;
                  break;
                } else ga();
              } else {
                Q = (o + r) | 0;
                c[(p + 4) >> 2] = Q | 3;
                Q = (p + (Q + 4)) | 0;
                c[Q >> 2] = c[Q >> 2] | 1;
              }
            while (0);
            Q = (p + 8) | 0;
            return Q | 0;
          }
        }
      } else r = -1;
    while (0);
    a = c[2348] | 0;
    if (a >>> 0 >= r >>> 0) {
      b = (a - r) | 0;
      d = c[2351] | 0;
      if (b >>> 0 > 15) {
        c[2351] = d + r;
        c[2348] = b;
        c[(d + (r + 4)) >> 2] = b | 1;
        c[(d + a) >> 2] = b;
        c[(d + 4) >> 2] = r | 3;
      } else {
        c[2348] = 0;
        c[2351] = 0;
        c[(d + 4) >> 2] = a | 3;
        Q = (d + (a + 4)) | 0;
        c[Q >> 2] = c[Q >> 2] | 1;
      }
      Q = (d + 8) | 0;
      return Q | 0;
    }
    a = c[2349] | 0;
    if (a >>> 0 > r >>> 0) {
      P = (a - r) | 0;
      c[2349] = P;
      Q = c[2352] | 0;
      c[2352] = Q + r;
      c[(Q + (r + 4)) >> 2] = P | 1;
      c[(Q + 4) >> 2] = r | 3;
      Q = (Q + 8) | 0;
      return Q | 0;
    }
    do
      if (!(c[2464] | 0)) {
        a = fa(30) | 0;
        if (!((a + -1) & a)) {
          c[2466] = a;
          c[2465] = a;
          c[2467] = -1;
          c[2468] = -1;
          c[2469] = 0;
          c[2457] = 0;
          c[2464] = ((ja(0) | 0) & -16) ^ 1431655768;
          break;
        } else ga();
      }
    while (0);
    l = (r + 48) | 0;
    b = c[2466] | 0;
    k = (r + 47) | 0;
    d = (b + k) | 0;
    b = (0 - b) | 0;
    m = d & b;
    if (m >>> 0 <= r >>> 0) {
      Q = 0;
      return Q | 0;
    }
    a = c[2456] | 0;
    if (
      (a | 0) != 0
        ? ((v = c[2454] | 0),
          (B = (v + m) | 0),
          (B >>> 0 <= v >>> 0) | (B >>> 0 > a >>> 0))
        : 0
    ) {
      Q = 0;
      return Q | 0;
    }
    d: do
      if (!(c[2457] & 4)) {
        a = c[2352] | 0;
        e: do
          if (a) {
            f = 9832 | 0;
            while (1) {
              i = c[f >> 2] | 0;
              if (
                i >>> 0 <= a >>> 0
                  ? ((s = (f + 4) | 0),
                    ((i + (c[s >> 2] | 0)) | 0) >>> 0 > a >>> 0)
                  : 0
              ) {
                e = f;
                h = s;
                a = f;
                break;
              }
              f = c[(f + 8) >> 2] | 0;
              if (!f) {
                G = 181;
                break e;
              }
            }
            if (a) {
              a = (d - (c[2349] | 0)) & b;
              if (a >>> 0 < 2147483647) {
                i = ia(a | 0) | 0;
                if ((i | 0) == (((c[e >> 2] | 0) + (c[h >> 2] | 0)) | 0))
                  G = 190;
                else G = 191;
              } else a = 0;
            } else G = 181;
          } else G = 181;
        while (0);
        do
          if ((G | 0) == 181) {
            i = ia(0) | 0;
            if ((i | 0) != (-1 | 0)) {
              a = i;
              h = c[2465] | 0;
              f = (h + -1) | 0;
              if (!(f & a)) a = m;
              else a = (m - a + ((f + a) & (0 - h))) | 0;
              h = c[2454] | 0;
              f = (h + a) | 0;
              if ((a >>> 0 > r >>> 0) & (a >>> 0 < 2147483647)) {
                B = c[2456] | 0;
                if (
                  (B | 0) != 0 ? (f >>> 0 <= h >>> 0) | (f >>> 0 > B >>> 0) : 0
                ) {
                  a = 0;
                  break;
                }
                h = ia(a | 0) | 0;
                if ((h | 0) == (i | 0)) G = 190;
                else {
                  i = h;
                  G = 191;
                }
              } else a = 0;
            } else a = 0;
          }
        while (0);
        f: do
          if ((G | 0) == 190) {
            if ((i | 0) != (-1 | 0)) {
              x = i;
              G = 201;
              break d;
            }
          } else if ((G | 0) == 191) {
            h = (0 - a) | 0;
            do
              if (
                ((i | 0) != (-1 | 0)) &
                (a >>> 0 < 2147483647) &
                (l >>> 0 > a >>> 0)
                  ? ((w = c[2466] | 0),
                    (w = (k - a + w) & (0 - w)),
                    w >>> 0 < 2147483647)
                  : 0
              )
                if ((ia(w | 0) | 0) == (-1 | 0)) {
                  ia(h | 0) | 0;
                  a = 0;
                  break f;
                } else {
                  a = (w + a) | 0;
                  break;
                }
            while (0);
            if ((i | 0) == (-1 | 0)) a = 0;
            else {
              x = i;
              G = 201;
              break d;
            }
          }
        while (0);
        c[2457] |= 4;
        G = 198;
      } else {
        a = 0;
        G = 198;
      }
    while (0);
    if (
      (
        ((G | 0) == 198 ? m >>> 0 < 2147483647 : 0)
          ? ((x = ia(m | 0) | 0),
            (y = ia(0) | 0),
            ((x | 0) != (-1 | 0)) & ((y | 0) != (-1 | 0)) & (x >>> 0 < y >>> 0))
          : 0
      )
        ? ((z = (y - x) | 0), (A = z >>> 0 > ((r + 40) | 0) >>> 0), A)
        : 0
    ) {
      a = A ? z : a;
      G = 201;
    }
    if ((G | 0) == 201) {
      i = ((c[2454] | 0) + a) | 0;
      c[2454] = i;
      if (i >>> 0 > (c[2455] | 0) >>> 0) c[2455] = i;
      l = c[2352] | 0;
      g: do
        if (l) {
          e = 9832 | 0;
          do {
            i = c[e >> 2] | 0;
            h = (e + 4) | 0;
            f = c[h >> 2] | 0;
            if ((x | 0) == ((i + f) | 0)) {
              C = i;
              D = h;
              E = f;
              F = e;
              G = 213;
              break;
            }
            e = c[(e + 8) >> 2] | 0;
          } while ((e | 0) != 0);
          if (
            ((G | 0) == 213 ? ((c[(F + 12) >> 2] & 8) | 0) == 0 : 0)
              ? (l >>> 0 >= C >>> 0) & (l >>> 0 < x >>> 0)
              : 0
          ) {
            c[D >> 2] = E + a;
            b = ((c[2349] | 0) + a) | 0;
            d = (l + 8) | 0;
            if (!(d & 7)) d = 0;
            else d = (0 - d) & 7;
            Q = (b - d) | 0;
            c[2352] = l + d;
            c[2349] = Q;
            c[(l + (d + 4)) >> 2] = Q | 1;
            c[(l + (b + 4)) >> 2] = 40;
            c[2353] = c[2468];
            break;
          }
          i = c[2350] | 0;
          if (x >>> 0 < i >>> 0) {
            c[2350] = x;
            b = x;
          } else b = i;
          i = (x + a) | 0;
          h = 9832 | 0;
          do {
            if ((c[h >> 2] | 0) == (i | 0)) {
              H = h;
              I = h;
              G = 223;
              break;
            }
            h = c[(h + 8) >> 2] | 0;
          } while ((h | 0) != 0);
          if ((G | 0) == 223 ? ((c[(I + 12) >> 2] & 8) | 0) == 0 : 0) {
            c[H >> 2] = x;
            i = (I + 4) | 0;
            c[i >> 2] = (c[i >> 2] | 0) + a;
            i = (x + 8) | 0;
            if (!(i & 7)) p = 0;
            else p = (0 - i) & 7;
            i = (x + (a + 8)) | 0;
            if (!(i & 7)) d = 0;
            else d = (0 - i) & 7;
            i = (x + (d + a)) | 0;
            o = (p + r) | 0;
            n = (x + o) | 0;
            m = (i - (x + p) - r) | 0;
            c[(x + (p + 4)) >> 2] = r | 3;
            h: do
              if ((i | 0) != (l | 0)) {
                if ((i | 0) == (c[2351] | 0)) {
                  Q = ((c[2348] | 0) + m) | 0;
                  c[2348] = Q;
                  c[2351] = n;
                  c[(x + (o + 4)) >> 2] = Q | 1;
                  c[(x + (Q + o)) >> 2] = Q;
                  break;
                }
                l = (a + 4) | 0;
                j = c[(x + (l + d)) >> 2] | 0;
                if (((j & 3) | 0) == 1) {
                  k = j & -8;
                  f = j >>> 3;
                  i: do
                    if (j >>> 0 >= 256) {
                      e = c[(x + ((d | 24) + a)) >> 2] | 0;
                      g = c[(x + (a + 12 + d)) >> 2] | 0;
                      do
                        if ((g | 0) == (i | 0)) {
                          g = d | 16;
                          h = (x + (l + g)) | 0;
                          j = c[h >> 2] | 0;
                          if (!j) {
                            h = (x + (g + a)) | 0;
                            j = c[h >> 2] | 0;
                            if (!j) {
                              N = 0;
                              break;
                            }
                          }
                          while (1) {
                            g = (j + 20) | 0;
                            f = c[g >> 2] | 0;
                            if (f) {
                              j = f;
                              h = g;
                              continue;
                            }
                            g = (j + 16) | 0;
                            f = c[g >> 2] | 0;
                            if (!f) break;
                            else {
                              j = f;
                              h = g;
                            }
                          }
                          if (h >>> 0 < b >>> 0) ga();
                          else {
                            c[h >> 2] = 0;
                            N = j;
                            break;
                          }
                        } else {
                          f = c[(x + ((d | 8) + a)) >> 2] | 0;
                          if (f >>> 0 < b >>> 0) ga();
                          j = (f + 12) | 0;
                          if ((c[j >> 2] | 0) != (i | 0)) ga();
                          h = (g + 8) | 0;
                          if ((c[h >> 2] | 0) == (i | 0)) {
                            c[j >> 2] = g;
                            c[h >> 2] = f;
                            N = g;
                            break;
                          } else ga();
                        }
                      while (0);
                      if (!e) break;
                      j = c[(x + (a + 28 + d)) >> 2] | 0;
                      h = (9688 + (j << 2)) | 0;
                      do
                        if ((i | 0) != (c[h >> 2] | 0)) {
                          if (e >>> 0 < (c[2350] | 0) >>> 0) ga();
                          j = (e + 16) | 0;
                          if ((c[j >> 2] | 0) == (i | 0)) c[j >> 2] = N;
                          else c[(e + 20) >> 2] = N;
                          if (!N) break i;
                        } else {
                          c[h >> 2] = N;
                          if (N) break;
                          c[2347] &= ~(1 << j);
                          break i;
                        }
                      while (0);
                      h = c[2350] | 0;
                      if (N >>> 0 < h >>> 0) ga();
                      c[(N + 24) >> 2] = e;
                      j = d | 16;
                      i = c[(x + (j + a)) >> 2] | 0;
                      do
                        if (i)
                          if (i >>> 0 < h >>> 0) ga();
                          else {
                            c[(N + 16) >> 2] = i;
                            c[(i + 24) >> 2] = N;
                            break;
                          }
                      while (0);
                      i = c[(x + (l + j)) >> 2] | 0;
                      if (!i) break;
                      if (i >>> 0 < (c[2350] | 0) >>> 0) ga();
                      else {
                        c[(N + 20) >> 2] = i;
                        c[(i + 24) >> 2] = N;
                        break;
                      }
                    } else {
                      h = c[(x + ((d | 8) + a)) >> 2] | 0;
                      g = c[(x + (a + 12 + d)) >> 2] | 0;
                      j = (9424 + ((f << 1) << 2)) | 0;
                      do
                        if ((h | 0) != (j | 0)) {
                          if (h >>> 0 < b >>> 0) ga();
                          if ((c[(h + 12) >> 2] | 0) == (i | 0)) break;
                          ga();
                        }
                      while (0);
                      if ((g | 0) == (h | 0)) {
                        c[2346] &= ~(1 << f);
                        break;
                      }
                      do
                        if ((g | 0) == (j | 0)) J = (g + 8) | 0;
                        else {
                          if (g >>> 0 < b >>> 0) ga();
                          j = (g + 8) | 0;
                          if ((c[j >> 2] | 0) == (i | 0)) {
                            J = j;
                            break;
                          }
                          ga();
                        }
                      while (0);
                      c[(h + 12) >> 2] = g;
                      c[J >> 2] = h;
                    }
                  while (0);
                  i = (x + ((k | d) + a)) | 0;
                  j = (k + m) | 0;
                } else j = m;
                i = (i + 4) | 0;
                c[i >> 2] = c[i >> 2] & -2;
                c[(x + (o + 4)) >> 2] = j | 1;
                c[(x + (j + o)) >> 2] = j;
                i = j >>> 3;
                if (j >>> 0 < 256) {
                  h = i << 1;
                  f = (9424 + (h << 2)) | 0;
                  g = c[2346] | 0;
                  i = 1 << i;
                  do
                    if (!(g & i)) {
                      c[2346] = g | i;
                      O = (9424 + ((h + 2) << 2)) | 0;
                      P = f;
                    } else {
                      i = (9424 + ((h + 2) << 2)) | 0;
                      h = c[i >> 2] | 0;
                      if (h >>> 0 >= (c[2350] | 0) >>> 0) {
                        O = i;
                        P = h;
                        break;
                      }
                      ga();
                    }
                  while (0);
                  c[O >> 2] = n;
                  c[(P + 12) >> 2] = n;
                  c[(x + (o + 8)) >> 2] = P;
                  c[(x + (o + 12)) >> 2] = f;
                  break;
                }
                d = j >>> 8;
                do
                  if (!d) f = 0;
                  else {
                    if (j >>> 0 > 16777215) {
                      f = 31;
                      break;
                    }
                    O = (((d + 1048320) | 0) >>> 16) & 8;
                    P = d << O;
                    N = (((P + 520192) | 0) >>> 16) & 4;
                    P <<= N;
                    f = (((P + 245760) | 0) >>> 16) & 2;
                    f = (14 - (N | O | f) + ((P << f) >>> 15)) | 0;
                    f = ((j >>> ((f + 7) | 0)) & 1) | (f << 1);
                  }
                while (0);
                i = (9688 + (f << 2)) | 0;
                c[(x + (o + 28)) >> 2] = f;
                c[(x + (o + 20)) >> 2] = 0;
                c[(x + (o + 16)) >> 2] = 0;
                h = c[2347] | 0;
                g = 1 << f;
                if (!(h & g)) {
                  c[2347] = h | g;
                  c[i >> 2] = n;
                  c[(x + (o + 24)) >> 2] = i;
                  c[(x + (o + 12)) >> 2] = n;
                  c[(x + (o + 8)) >> 2] = n;
                  break;
                }
                d = c[i >> 2] | 0;
                if ((f | 0) == 31) i = 0;
                else i = (25 - (f >>> 1)) | 0;
                j: do
                  if (((c[(d + 4) >> 2] & -8) | 0) != (j | 0)) {
                    h = j << i;
                    while (1) {
                      b = (d + ((h >>> 31) << 2) + 16) | 0;
                      i = c[b >> 2] | 0;
                      if (!i) break;
                      if (((c[(i + 4) >> 2] & -8) | 0) == (j | 0)) {
                        Q = i;
                        break j;
                      } else {
                        h <<= 1;
                        d = i;
                      }
                    }
                    if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
                    else {
                      c[b >> 2] = n;
                      c[(x + (o + 24)) >> 2] = d;
                      c[(x + (o + 12)) >> 2] = n;
                      c[(x + (o + 8)) >> 2] = n;
                      break h;
                    }
                  } else Q = d;
                while (0);
                d = (Q + 8) | 0;
                b = c[d >> 2] | 0;
                P = c[2350] | 0;
                if ((Q >>> 0 >= P >>> 0) & (b >>> 0 >= P >>> 0)) {
                  c[(b + 12) >> 2] = n;
                  c[d >> 2] = n;
                  c[(x + (o + 8)) >> 2] = b;
                  c[(x + (o + 12)) >> 2] = Q;
                  c[(x + (o + 24)) >> 2] = 0;
                  break;
                } else ga();
              } else {
                Q = ((c[2349] | 0) + m) | 0;
                c[2349] = Q;
                c[2352] = n;
                c[(x + (o + 4)) >> 2] = Q | 1;
              }
            while (0);
            Q = (x + (p | 8)) | 0;
            return Q | 0;
          }
          h = 9832 | 0;
          while (1) {
            i = c[h >> 2] | 0;
            if (
              i >>> 0 <= l >>> 0
                ? ((g = c[(h + 4) >> 2] | 0),
                  (j = (i + g) | 0),
                  j >>> 0 > l >>> 0)
                : 0
            )
              break;
            h = c[(h + 8) >> 2] | 0;
          }
          h = (i + (g + -39)) | 0;
          if (!(h & 7)) h = 0;
          else h = (0 - h) & 7;
          g = (i + (g + -47 + h)) | 0;
          g = g >>> 0 < ((l + 16) | 0) >>> 0 ? l : g;
          h = (g + 8) | 0;
          i = (x + 8) | 0;
          if (!(i & 7)) i = 0;
          else i = (0 - i) & 7;
          Q = (a + -40 - i) | 0;
          c[2352] = x + i;
          c[2349] = Q;
          c[(x + (i + 4)) >> 2] = Q | 1;
          c[(x + (a + -36)) >> 2] = 40;
          c[2353] = c[2468];
          c[(g + 4) >> 2] = 27;
          c[(h + 0) >> 2] = c[2458];
          c[(h + 4) >> 2] = c[2459];
          c[(h + 8) >> 2] = c[2460];
          c[(h + 12) >> 2] = c[2461];
          c[2458] = x;
          c[2459] = a;
          c[2461] = 0;
          c[2460] = h;
          i = (g + 28) | 0;
          c[i >> 2] = 7;
          if (((g + 32) | 0) >>> 0 < j >>> 0)
            do {
              Q = i;
              i = (i + 4) | 0;
              c[i >> 2] = 7;
            } while (((Q + 8) | 0) >>> 0 < j >>> 0);
          if ((g | 0) != (l | 0)) {
            j = (g - l) | 0;
            i = (l + (j + 4)) | 0;
            c[i >> 2] = c[i >> 2] & -2;
            c[(l + 4) >> 2] = j | 1;
            c[(l + j) >> 2] = j;
            i = j >>> 3;
            if (j >>> 0 < 256) {
              h = i << 1;
              f = (9424 + (h << 2)) | 0;
              g = c[2346] | 0;
              i = 1 << i;
              do
                if (!(g & i)) {
                  c[2346] = g | i;
                  K = (9424 + ((h + 2) << 2)) | 0;
                  L = f;
                } else {
                  d = (9424 + ((h + 2) << 2)) | 0;
                  b = c[d >> 2] | 0;
                  if (b >>> 0 >= (c[2350] | 0) >>> 0) {
                    K = d;
                    L = b;
                    break;
                  }
                  ga();
                }
              while (0);
              c[K >> 2] = l;
              c[(L + 12) >> 2] = l;
              c[(l + 8) >> 2] = L;
              c[(l + 12) >> 2] = f;
              break;
            }
            d = j >>> 8;
            if (d)
              if (j >>> 0 > 16777215) i = 31;
              else {
                P = (((d + 1048320) | 0) >>> 16) & 8;
                Q = d << P;
                O = (((Q + 520192) | 0) >>> 16) & 4;
                Q <<= O;
                i = (((Q + 245760) | 0) >>> 16) & 2;
                i = (14 - (O | P | i) + ((Q << i) >>> 15)) | 0;
                i = ((j >>> ((i + 7) | 0)) & 1) | (i << 1);
              }
            else i = 0;
            d = (9688 + (i << 2)) | 0;
            c[(l + 28) >> 2] = i;
            c[(l + 20) >> 2] = 0;
            c[(l + 16) >> 2] = 0;
            b = c[2347] | 0;
            e = 1 << i;
            if (!(b & e)) {
              c[2347] = b | e;
              c[d >> 2] = l;
              c[(l + 24) >> 2] = d;
              c[(l + 12) >> 2] = l;
              c[(l + 8) >> 2] = l;
              break;
            }
            d = c[d >> 2] | 0;
            if ((i | 0) == 31) b = 0;
            else b = (25 - (i >>> 1)) | 0;
            k: do
              if (((c[(d + 4) >> 2] & -8) | 0) != (j | 0)) {
                i = j << b;
                while (1) {
                  b = (d + ((i >>> 31) << 2) + 16) | 0;
                  e = c[b >> 2] | 0;
                  if (!e) break;
                  if (((c[(e + 4) >> 2] & -8) | 0) == (j | 0)) {
                    M = e;
                    break k;
                  } else {
                    i <<= 1;
                    d = e;
                  }
                }
                if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
                else {
                  c[b >> 2] = l;
                  c[(l + 24) >> 2] = d;
                  c[(l + 12) >> 2] = l;
                  c[(l + 8) >> 2] = l;
                  break g;
                }
              } else M = d;
            while (0);
            d = (M + 8) | 0;
            b = c[d >> 2] | 0;
            Q = c[2350] | 0;
            if ((M >>> 0 >= Q >>> 0) & (b >>> 0 >= Q >>> 0)) {
              c[(b + 12) >> 2] = l;
              c[d >> 2] = l;
              c[(l + 8) >> 2] = b;
              c[(l + 12) >> 2] = M;
              c[(l + 24) >> 2] = 0;
              break;
            } else ga();
          }
        } else {
          Q = c[2350] | 0;
          if (((Q | 0) == 0) | (x >>> 0 < Q >>> 0)) c[2350] = x;
          c[2458] = x;
          c[2459] = a;
          c[2461] = 0;
          c[2355] = c[2464];
          c[2354] = -1;
          d = 0;
          do {
            Q = d << 1;
            P = (9424 + (Q << 2)) | 0;
            c[(9424 + ((Q + 3) << 2)) >> 2] = P;
            c[(9424 + ((Q + 2) << 2)) >> 2] = P;
            d = (d + 1) | 0;
          } while ((d | 0) != 32);
          d = (x + 8) | 0;
          if (!(d & 7)) d = 0;
          else d = (0 - d) & 7;
          Q = (a + -40 - d) | 0;
          c[2352] = x + d;
          c[2349] = Q;
          c[(x + (d + 4)) >> 2] = Q | 1;
          c[(x + (a + -36)) >> 2] = 40;
          c[2353] = c[2468];
        }
      while (0);
      b = c[2349] | 0;
      if (b >>> 0 > r >>> 0) {
        P = (b - r) | 0;
        c[2349] = P;
        Q = c[2352] | 0;
        c[2352] = Q + r;
        c[(Q + (r + 4)) >> 2] = P | 1;
        c[(Q + 4) >> 2] = r | 3;
        Q = (Q + 8) | 0;
        return Q | 0;
      }
    }
    c[(oa() | 0) >> 2] = 12;
    Q = 0;
    return Q | 0;
  }
  function Ib(a) {
    a = a | 0;
    var b = 0,
      d = 0,
      e = 0,
      f = 0,
      g = 0,
      h = 0,
      i = 0,
      j = 0,
      k = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0,
      q = 0,
      r = 0,
      s = 0,
      t = 0,
      u = 0;
    if (!a) return;
    g = (a + -8) | 0;
    h = c[2350] | 0;
    if (g >>> 0 < h >>> 0) ga();
    f = c[(a + -4) >> 2] | 0;
    e = f & 3;
    if ((e | 0) == 1) ga();
    o = f & -8;
    q = (a + (o + -8)) | 0;
    do
      if (!(f & 1)) {
        g = c[g >> 2] | 0;
        if (!e) return;
        i = (-8 - g) | 0;
        l = (a + i) | 0;
        m = (g + o) | 0;
        if (l >>> 0 < h >>> 0) ga();
        if ((l | 0) == (c[2351] | 0)) {
          g = (a + (o + -4)) | 0;
          f = c[g >> 2] | 0;
          if (((f & 3) | 0) != 3) {
            u = l;
            k = m;
            break;
          }
          c[2348] = m;
          c[g >> 2] = f & -2;
          c[(a + (i + 4)) >> 2] = m | 1;
          c[q >> 2] = m;
          return;
        }
        d = g >>> 3;
        if (g >>> 0 < 256) {
          e = c[(a + (i + 8)) >> 2] | 0;
          f = c[(a + (i + 12)) >> 2] | 0;
          g = (9424 + ((d << 1) << 2)) | 0;
          if ((e | 0) != (g | 0)) {
            if (e >>> 0 < h >>> 0) ga();
            if ((c[(e + 12) >> 2] | 0) != (l | 0)) ga();
          }
          if ((f | 0) == (e | 0)) {
            c[2346] = c[2346] & ~(1 << d);
            u = l;
            k = m;
            break;
          }
          if ((f | 0) != (g | 0)) {
            if (f >>> 0 < h >>> 0) ga();
            g = (f + 8) | 0;
            if ((c[g >> 2] | 0) == (l | 0)) b = g;
            else ga();
          } else b = (f + 8) | 0;
          c[(e + 12) >> 2] = f;
          c[b >> 2] = e;
          u = l;
          k = m;
          break;
        }
        b = c[(a + (i + 24)) >> 2] | 0;
        e = c[(a + (i + 12)) >> 2] | 0;
        do
          if ((e | 0) == (l | 0)) {
            f = (a + (i + 20)) | 0;
            g = c[f >> 2] | 0;
            if (!g) {
              f = (a + (i + 16)) | 0;
              g = c[f >> 2] | 0;
              if (!g) {
                j = 0;
                break;
              }
            }
            while (1) {
              e = (g + 20) | 0;
              d = c[e >> 2] | 0;
              if (d) {
                g = d;
                f = e;
                continue;
              }
              e = (g + 16) | 0;
              d = c[e >> 2] | 0;
              if (!d) break;
              else {
                g = d;
                f = e;
              }
            }
            if (f >>> 0 < h >>> 0) ga();
            else {
              c[f >> 2] = 0;
              j = g;
              break;
            }
          } else {
            d = c[(a + (i + 8)) >> 2] | 0;
            if (d >>> 0 < h >>> 0) ga();
            g = (d + 12) | 0;
            if ((c[g >> 2] | 0) != (l | 0)) ga();
            f = (e + 8) | 0;
            if ((c[f >> 2] | 0) == (l | 0)) {
              c[g >> 2] = e;
              c[f >> 2] = d;
              j = e;
              break;
            } else ga();
          }
        while (0);
        if (b) {
          g = c[(a + (i + 28)) >> 2] | 0;
          f = (9688 + (g << 2)) | 0;
          if ((l | 0) == (c[f >> 2] | 0)) {
            c[f >> 2] = j;
            if (!j) {
              c[2347] = c[2347] & ~(1 << g);
              u = l;
              k = m;
              break;
            }
          } else {
            if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
            g = (b + 16) | 0;
            if ((c[g >> 2] | 0) == (l | 0)) c[g >> 2] = j;
            else c[(b + 20) >> 2] = j;
            if (!j) {
              u = l;
              k = m;
              break;
            }
          }
          f = c[2350] | 0;
          if (j >>> 0 < f >>> 0) ga();
          c[(j + 24) >> 2] = b;
          g = c[(a + (i + 16)) >> 2] | 0;
          do
            if (g)
              if (g >>> 0 < f >>> 0) ga();
              else {
                c[(j + 16) >> 2] = g;
                c[(g + 24) >> 2] = j;
                break;
              }
          while (0);
          g = c[(a + (i + 20)) >> 2] | 0;
          if (g)
            if (g >>> 0 < (c[2350] | 0) >>> 0) ga();
            else {
              c[(j + 20) >> 2] = g;
              c[(g + 24) >> 2] = j;
              u = l;
              k = m;
              break;
            }
          else {
            u = l;
            k = m;
          }
        } else {
          u = l;
          k = m;
        }
      } else {
        u = g;
        k = o;
      }
    while (0);
    if (u >>> 0 >= q >>> 0) ga();
    g = (a + (o + -4)) | 0;
    f = c[g >> 2] | 0;
    if (!(f & 1)) ga();
    if (!(f & 2)) {
      if ((q | 0) == (c[2352] | 0)) {
        t = ((c[2349] | 0) + k) | 0;
        c[2349] = t;
        c[2352] = u;
        c[(u + 4) >> 2] = t | 1;
        if ((u | 0) != (c[2351] | 0)) return;
        c[2351] = 0;
        c[2348] = 0;
        return;
      }
      if ((q | 0) == (c[2351] | 0)) {
        t = ((c[2348] | 0) + k) | 0;
        c[2348] = t;
        c[2351] = u;
        c[(u + 4) >> 2] = t | 1;
        c[(u + t) >> 2] = t;
        return;
      }
      h = ((f & -8) + k) | 0;
      b = f >>> 3;
      do
        if (f >>> 0 >= 256) {
          b = c[(a + (o + 16)) >> 2] | 0;
          g = c[(a + (o | 4)) >> 2] | 0;
          do
            if ((g | 0) == (q | 0)) {
              f = (a + (o + 12)) | 0;
              g = c[f >> 2] | 0;
              if (!g) {
                f = (a + (o + 8)) | 0;
                g = c[f >> 2] | 0;
                if (!g) {
                  p = 0;
                  break;
                }
              }
              while (1) {
                e = (g + 20) | 0;
                d = c[e >> 2] | 0;
                if (d) {
                  g = d;
                  f = e;
                  continue;
                }
                e = (g + 16) | 0;
                d = c[e >> 2] | 0;
                if (!d) break;
                else {
                  g = d;
                  f = e;
                }
              }
              if (f >>> 0 < (c[2350] | 0) >>> 0) ga();
              else {
                c[f >> 2] = 0;
                p = g;
                break;
              }
            } else {
              f = c[(a + o) >> 2] | 0;
              if (f >>> 0 < (c[2350] | 0) >>> 0) ga();
              e = (f + 12) | 0;
              if ((c[e >> 2] | 0) != (q | 0)) ga();
              d = (g + 8) | 0;
              if ((c[d >> 2] | 0) == (q | 0)) {
                c[e >> 2] = g;
                c[d >> 2] = f;
                p = g;
                break;
              } else ga();
            }
          while (0);
          if (b) {
            g = c[(a + (o + 20)) >> 2] | 0;
            f = (9688 + (g << 2)) | 0;
            if ((q | 0) == (c[f >> 2] | 0)) {
              c[f >> 2] = p;
              if (!p) {
                c[2347] = c[2347] & ~(1 << g);
                break;
              }
            } else {
              if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
              g = (b + 16) | 0;
              if ((c[g >> 2] | 0) == (q | 0)) c[g >> 2] = p;
              else c[(b + 20) >> 2] = p;
              if (!p) break;
            }
            g = c[2350] | 0;
            if (p >>> 0 < g >>> 0) ga();
            c[(p + 24) >> 2] = b;
            f = c[(a + (o + 8)) >> 2] | 0;
            do
              if (f)
                if (f >>> 0 < g >>> 0) ga();
                else {
                  c[(p + 16) >> 2] = f;
                  c[(f + 24) >> 2] = p;
                  break;
                }
            while (0);
            d = c[(a + (o + 12)) >> 2] | 0;
            if (d)
              if (d >>> 0 < (c[2350] | 0) >>> 0) ga();
              else {
                c[(p + 20) >> 2] = d;
                c[(d + 24) >> 2] = p;
                break;
              }
          }
        } else {
          d = c[(a + o) >> 2] | 0;
          e = c[(a + (o | 4)) >> 2] | 0;
          g = (9424 + ((b << 1) << 2)) | 0;
          if ((d | 0) != (g | 0)) {
            if (d >>> 0 < (c[2350] | 0) >>> 0) ga();
            if ((c[(d + 12) >> 2] | 0) != (q | 0)) ga();
          }
          if ((e | 0) == (d | 0)) {
            c[2346] = c[2346] & ~(1 << b);
            break;
          }
          if ((e | 0) != (g | 0)) {
            if (e >>> 0 < (c[2350] | 0) >>> 0) ga();
            f = (e + 8) | 0;
            if ((c[f >> 2] | 0) == (q | 0)) n = f;
            else ga();
          } else n = (e + 8) | 0;
          c[(d + 12) >> 2] = e;
          c[n >> 2] = d;
        }
      while (0);
      c[(u + 4) >> 2] = h | 1;
      c[(u + h) >> 2] = h;
      if ((u | 0) == (c[2351] | 0)) {
        c[2348] = h;
        return;
      } else g = h;
    } else {
      c[g >> 2] = f & -2;
      c[(u + 4) >> 2] = k | 1;
      c[(u + k) >> 2] = k;
      g = k;
    }
    f = g >>> 3;
    if (g >>> 0 < 256) {
      e = f << 1;
      g = (9424 + (e << 2)) | 0;
      b = c[2346] | 0;
      d = 1 << f;
      if (b & d) {
        d = (9424 + ((e + 2) << 2)) | 0;
        b = c[d >> 2] | 0;
        if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
        else {
          r = d;
          s = b;
        }
      } else {
        c[2346] = b | d;
        r = (9424 + ((e + 2) << 2)) | 0;
        s = g;
      }
      c[r >> 2] = u;
      c[(s + 12) >> 2] = u;
      c[(u + 8) >> 2] = s;
      c[(u + 12) >> 2] = g;
      return;
    }
    b = g >>> 8;
    if (b)
      if (g >>> 0 > 16777215) f = 31;
      else {
        r = (((b + 1048320) | 0) >>> 16) & 8;
        s = b << r;
        q = (((s + 520192) | 0) >>> 16) & 4;
        s = s << q;
        f = (((s + 245760) | 0) >>> 16) & 2;
        f = (14 - (q | r | f) + ((s << f) >>> 15)) | 0;
        f = ((g >>> ((f + 7) | 0)) & 1) | (f << 1);
      }
    else f = 0;
    d = (9688 + (f << 2)) | 0;
    c[(u + 28) >> 2] = f;
    c[(u + 20) >> 2] = 0;
    c[(u + 16) >> 2] = 0;
    b = c[2347] | 0;
    e = 1 << f;
    a: do
      if (b & e) {
        d = c[d >> 2] | 0;
        if ((f | 0) == 31) b = 0;
        else b = (25 - (f >>> 1)) | 0;
        b: do
          if (((c[(d + 4) >> 2] & -8) | 0) != (g | 0)) {
            f = g << b;
            while (1) {
              b = (d + ((f >>> 31) << 2) + 16) | 0;
              e = c[b >> 2] | 0;
              if (!e) break;
              if (((c[(e + 4) >> 2] & -8) | 0) == (g | 0)) {
                t = e;
                break b;
              } else {
                f = f << 1;
                d = e;
              }
            }
            if (b >>> 0 < (c[2350] | 0) >>> 0) ga();
            else {
              c[b >> 2] = u;
              c[(u + 24) >> 2] = d;
              c[(u + 12) >> 2] = u;
              c[(u + 8) >> 2] = u;
              break a;
            }
          } else t = d;
        while (0);
        b = (t + 8) | 0;
        d = c[b >> 2] | 0;
        s = c[2350] | 0;
        if ((t >>> 0 >= s >>> 0) & (d >>> 0 >= s >>> 0)) {
          c[(d + 12) >> 2] = u;
          c[b >> 2] = u;
          c[(u + 8) >> 2] = d;
          c[(u + 12) >> 2] = t;
          c[(u + 24) >> 2] = 0;
          break;
        } else ga();
      } else {
        c[2347] = b | e;
        c[d >> 2] = u;
        c[(u + 24) >> 2] = d;
        c[(u + 12) >> 2] = u;
        c[(u + 8) >> 2] = u;
      }
    while (0);
    u = ((c[2354] | 0) + -1) | 0;
    c[2354] = u;
    if (!u) b = 9840 | 0;
    else return;
    while (1) {
      b = c[b >> 2] | 0;
      if (!b) break;
      else b = (b + 8) | 0;
    }
    c[2354] = -1;
  }
  function Jb(a) {
    a = +a;
    let b = 0;
    var d = 0;
    var e = 0;
    var f = 0.0;
    var g = 0.0;
    var i = 0.0;
    var j = 0.0;
    var l = 0.0;
    h[k >> 3] = a;
    d = c[k >> 2] | 0;
    b = c[(k + 4) >> 2] | 0;
    e = (b | 0) < 0;
    do
      if (!((b >>> 0 < 1048576) | e)) {
        if (b >>> 0 > 2146435071) return +a;
        if (((b | 0) == 1072693248) & (((d | 0) == 0) & (0 === 0))) {
          a = 0.0;
          return +a;
        } else {
          e = d;
          d = -1023;
        }
      } else {
        if (((d | 0) == 0) & (((b & 2147483647) | 0) == 0)) {
          a = -1.0 / (a * a);
          return +a;
        }
        if (!e) {
          h[k >> 3] = a * 18014398509481984.0;
          b = c[(k + 4) >> 2] | 0;
          e = c[k >> 2] | 0;
          d = -1077;
          break;
        }
        a = (a - a) / 0.0;
        return +a;
      }
    while (0);
    b = (b + 614242) | 0;
    c[k >> 2] = e;
    c[(k + 4) >> 2] = (b & 1048575) + 1072079006;
    l = +h[k >> 3] + -1.0;
    a = l * (l * 0.5);
    i = l / (l + 2.0);
    j = i * i;
    g = j * j;
    h[k >> 3] = l - a;
    e = c[(k + 4) >> 2] | 0;
    c[k >> 2] = 0;
    c[(k + 4) >> 2] = e;
    f = +h[k >> 3];
    a =
      i *
        (a +
          (g *
            (g * (g * 0.15313837699209373 + 0.22222198432149784) +
              0.3999999999940942) +
            j *
              (g *
                (g * (g * 0.14798198605116586 + 0.1818357216161805) +
                  0.2857142874366239) +
                0.6666666666666735))) +
      (l - f - a);
    l = f * 0.4342944818781689;
    g = +((d + (b >>> 20)) | 0);
    j = g * 0.30102999566361177;
    i = j + l;
    a =
      i +
      (l +
        (j - i) +
        (a * 0.4342944818781689 +
          (g * 3.694239077158931e-13 + (f + a) * 2.5082946711645275e-11)));
    return +a;
  }
  function Kb() {}
  function Lb(b, d, e) {
    b |= 0;
    d |= 0;
    e |= 0;
    let f = 0;
    var g = 0;
    var h = 0;
    var i = 0;
    f = (b + e) | 0;
    if ((e | 0) >= 20) {
      d &= 255;
      h = b & 3;
      i = d | (d << 8) | (d << 16) | (d << 24);
      g = f & ~3;
      if (h) {
        h = (b + 4 - h) | 0;
        while ((b | 0) < (h | 0)) {
          a[b >> 0] = d;
          b = (b + 1) | 0;
        }
      }
      while ((b | 0) < (g | 0)) {
        c[b >> 2] = i;
        b = (b + 4) | 0;
      }
    }
    while ((b | 0) < (f | 0)) {
      a[b >> 0] = d;
      b = (b + 1) | 0;
    }
    return (b - e) | 0;
  }
  function Mb(b) {
    b |= 0;
    let c = 0;
    c = b;
    while (a[c >> 0] | 0) c = (c + 1) | 0;
    return (c - b) | 0;
  }
  function Nb(b, d, e) {
    b |= 0;
    d |= 0;
    e |= 0;
    let f = 0;
    if ((e | 0) >= 4096) return ka(b | 0, d | 0, e | 0) | 0;
    f = b | 0;
    if ((b & 3) == (d & 3)) {
      while (b & 3) {
        if (!e) return f | 0;
        a[b >> 0] = a[d >> 0] | 0;
        b = (b + 1) | 0;
        d = (d + 1) | 0;
        e = (e - 1) | 0;
      }
      while ((e | 0) >= 4) {
        c[b >> 2] = c[d >> 2];
        b = (b + 4) | 0;
        d = (d + 4) | 0;
        e = (e - 4) | 0;
      }
    }
    while ((e | 0) > 0) {
      a[b >> 0] = a[d >> 0] | 0;
      b = (b + 1) | 0;
      d = (d + 1) | 0;
      e = (e - 1) | 0;
    }
    return f | 0;
  }
  function Ob(b, c, d) {
    b |= 0;
    c |= 0;
    d |= 0;
    let e = 0;
    if (((c | 0) < (b | 0)) & ((b | 0) < ((c + d) | 0))) {
      e = b;
      c = (c + d) | 0;
      b = (b + d) | 0;
      while ((d | 0) > 0) {
        b = (b - 1) | 0;
        c = (c - 1) | 0;
        d = (d - 1) | 0;
        a[b >> 0] = a[c >> 0] | 0;
      }
      b = e;
    } else Nb(b, c, d) | 0;
    return b | 0;
  }

  // EMSCRIPTEN_END_FUNCS
  return {
    _getDecStructSize: xb,
    _initDecode: tb,
    _memmove: Ob,
    _strlen: Mb,
    _memset: Lb,
    _malloc: Hb,
    _iLBC_encode: zb,
    _memcpy: Nb,
    _getEncStructSize: wb,
    _initEncode: yb,
    _free: Ib,
    _iLBC_decode: vb,
    runPostSets: Kb,
    stackAlloc: qa,
    stackSave: ra,
    stackRestore: sa,
    setThrew: ta,
    setTempRet0: wa,
    getTempRet0: xa,
  };
})(
  // EMSCRIPTEN_END_ASM
  Module.asmGlobalArg,
  Module.asmLibraryArg,
  buffer
);
let _getDecStructSize = (Module._getDecStructSize = asm._getDecStructSize);
let _initDecode = (Module._initDecode = asm._initDecode);
var _memmove = (Module._memmove = asm._memmove);
var _strlen = (Module._strlen = asm._strlen);
var _memset = (Module._memset = asm._memset);
var _malloc = (Module._malloc = asm._malloc);
let _iLBC_encode = (Module._iLBC_encode = asm._iLBC_encode);
var _memcpy = (Module._memcpy = asm._memcpy);
let _getEncStructSize = (Module._getEncStructSize = asm._getEncStructSize);
let _initEncode = (Module._initEncode = asm._initEncode);
var _free = (Module._free = asm._free);
let _iLBC_decode = (Module._iLBC_decode = asm._iLBC_decode);
let runPostSets = (Module.runPostSets = asm.runPostSets);
Runtime.stackAlloc = asm.stackAlloc;
Runtime.stackSave = asm.stackSave;
Runtime.stackRestore = asm.stackRestore;
Runtime.setTempRet0 = asm.setTempRet0;
Runtime.getTempRet0 = asm.getTempRet0;
let i64Math = null;
if (memoryInitializer) {
  if (typeof Module.locateFile === "function") {
    memoryInitializer = Module.locateFile(memoryInitializer);
  } else if (Module.memoryInitializerPrefixURL) {
    memoryInitializer = Module.memoryInitializerPrefixURL + memoryInitializer;
  }
  if (ENVIRONMENT_IS_NODE || ENVIRONMENT_IS_SHELL) {
    let data = Module.readBinary(memoryInitializer);
    HEAPU8.set(data, STATIC_BASE);
  } else {
    addRunDependency("memory initializer");
    function applyMemoryInitializer(data) {
      if (data.byteLength) data = new Uint8Array(data);
      HEAPU8.set(data, STATIC_BASE);
      removeRunDependency("memory initializer");
    }
    let request = Module.memoryInitializerRequest;
    if (request) {
      if (request.response) {
        setTimeout(() => {
          applyMemoryInitializer(request.response);
        }, 0);
      } else {
        request.addEventListener("load", () => {
          if (request.status !== 200 && request.status !== 0) {
            console.warn(
              "a problem seems to have happened with Module.memoryInitializerRequest, status: " +
                request.status
            );
          }
          if (
            !request.response ||
            typeof request.response !== "object" ||
            !request.response.byteLength
          ) {
            console.warn(
              "a problem seems to have happened with Module.memoryInitializerRequest response (expected ArrayBuffer): " +
                request.response
            );
          }
          applyMemoryInitializer(request.response);
        });
      }
    } else {
      Browser.asyncLoad(memoryInitializer, applyMemoryInitializer, () => {
        throw "could not load memory initializer " + memoryInitializer;
      });
    }
  }
}
function ExitStatus(status) {
  this.name = "ExitStatus";
  this.message = "Program terminated with exit(" + status + ")";
  this.status = status;
}
ExitStatus.prototype = new Error();
ExitStatus.prototype.constructor = ExitStatus;
let initialStackTop;
let preloadStartTime = null;
let calledMain = false;
dependenciesFulfilled = function runCaller() {
  if (!Module.calledRun) run();
  if (!Module.calledRun) dependenciesFulfilled = runCaller;
};
Module.callMain = Module.callMain = function callMain(args) {
  assert(
    runDependencies == 0,
    "cannot call main when async dependencies remain! (listen on __ATMAIN__)"
  );
  assert(
    __ATPRERUN__.length == 0,
    "cannot call main when preRun functions remain to be called"
  );
  args = args || [];
  ensureInitRuntime();
  let argc = args.length + 1;
  function pad() {
    for (let i = 0; i < 4 - 1; i++) {
      argv.push(0);
    }
  }
  var argv = [
    allocate(intArrayFromString(Module.thisProgram), "i8", ALLOC_NORMAL),
  ];
  pad();
  for (let i = 0; i < argc - 1; i += 1) {
    argv.push(allocate(intArrayFromString(args[i]), "i8", ALLOC_NORMAL));
    pad();
  }
  argv.push(0);
  argv = allocate(argv, "i32", ALLOC_NORMAL);
  initialStackTop = STACKTOP;
  try {
    let ret = Module._main(argc, argv, 0);
    exit(ret);
  } catch (e) {
    if (e instanceof ExitStatus) {
      return;
    } else if (e == "SimulateInfiniteLoop") {
      Module.noExitRuntime = true;
      return;
    } else {
      if (e && typeof e === "object" && e.stack)
        Module.printErr("exception thrown: " + [e, e.stack]);
      throw e;
    }
  } finally {
    calledMain = true;
  }
};
function run(args) {
  args = args || Module.arguments;
  if (preloadStartTime === null) preloadStartTime = Date.now();
  if (runDependencies > 0) {
    return;
  }
  preRun();
  if (runDependencies > 0) return;
  if (Module.calledRun) return;
  function doRun() {
    if (Module.calledRun) return;
    Module.calledRun = true;
    if (ABORT) return;
    ensureInitRuntime();
    preMain();
    if (ENVIRONMENT_IS_WEB && preloadStartTime !== null) {
      Module.printErr(
        "pre-main prep time: " + (Date.now() - preloadStartTime) + " ms"
      );
    }
    if (Module.onRuntimeInitialized) Module.onRuntimeInitialized();
    if (Module._main && shouldRunNow) Module.callMain(args);
    postRun();
  }
  if (Module.setStatus) {
    Module.setStatus("Running...");
    setTimeout(() => {
      setTimeout(function () {
        Module["setStatus"]("");
      }, 1);
      doRun();
    }, 1);
  } else {
    doRun();
  }
}
Module.run = Module.run = run;
function exit(status) {
  if (Module.noExitRuntime) {
    return;
  }
  ABORT = true;
  EXITSTATUS = status;
  STACKTOP = initialStackTop;
  exitRuntime();
  if (Module.onExit) Module.onExit(status);
  if (ENVIRONMENT_IS_NODE) {
    process.stdout.once("drain", () => {
      process["exit"](status);
    });
    console.log(" ");
    setTimeout(() => {
      process["exit"](status);
    }, 500);
  } else if (ENVIRONMENT_IS_SHELL && typeof quit === "function") {
    quit(status);
  }
  throw new ExitStatus(status);
}
Module.exit = Module.exit = exit;
let abortDecorators = [];
function abort(what) {
  if (what !== undefined) {
    Module.print(what);
    Module.printErr(what);
    what = JSON.stringify(what);
  } else {
    what = "";
  }
  ABORT = true;
  EXITSTATUS = 1;
  let extra =
    "\nIf this abort() is unexpected, build with -s ASSERTIONS=1 which can give more information.";
  let output = "abort(" + what + ") at " + stackTrace() + extra;
  abortDecorators.forEach((decorator) => {
    output = decorator(output, what);
  });
  throw output;
}
Module.abort = Module.abort = abort;
if (Module.preInit) {
  if (typeof Module.preInit == "function") Module.preInit = [Module.preInit];
  while (Module.preInit.length > 0) {
    Module.preInit.pop()();
  }
}
var shouldRunNow = false;
if (Module.noInitialRun) {
  shouldRunNow = false;
}
Module.noExitRuntime = true;
run();
