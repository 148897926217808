import { GtError } from "src/app/gui/components/GtError";
import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { PTTButton } from "src/app/gui/panels/channels/detailedGroup/PTTButton";
import { VolumeControls } from "src/app/gui/panels/channels/detailedGroup/VolumeControls";
import { GroupRow } from "src/app/gui/panels/channels/GroupRow";
import { EndCallButton } from "src/app/gui/panels/contact/EndCallButton";
import { WaitingForCall } from "src/app/gui/panels/contact/WaitingForCall";
import { ContactRow } from "src/app/gui/panels/contacts/ContactRow";
import { Map } from "src/app/gui/panels/location/Map";
import { FullDuplexSession } from "src/app/gui/panels/video/FullDuplexSession";
import { MessageButton } from "src/app/gui/shared/MessageButton";
import {
  AlarmIcon,
  CloseTicketIcon,
  MonitorIcon,
  PriorityTicketIcon,
  PutbackIcon,
  TicketIcon,
} from "src/app/images/index";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

const BOX_STYLE = {
  background: "#0001",
  border: "1px solid #0003",
  borderRadius: 4,
};
const TicketButton = ({
  icon,
  label,
  onClick,
}: {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}): JSX.Element => (
  <Layout
    style={{
      borderRadius: 8,
      cursor: "pointer",
    }}
    alignCenter
    alignMiddle
    background="#616577"
    gap={5}
    height={22}
    onClick={onClick}
    padding={8}
    row
  >
    {icon}
    <Text color="#fff" size={14}>
      {label}
    </Text>
  </Layout>
);

export const Ticket = connect<{
  panel: Panel;
}>(({ panel }) => {
  const {
    closeTicket,
    contact,
    group,
    hasFullDuplexSession,
    locationData,
    ticket,
  } = panel.ticketPanelData!;
  if (!ticket) {
    return (
      <Layout column>
        <GtError message="Unable to open ticket" severity="info" />
      </Layout>
    );
  }
  const { isEmergency, isHighPriority } = ticket;
  let color = null;
  if (ticket.isEmergency) {
    color = "#d13e3e";
  } else if (ticket.isHighPriority) {
    color = "#c9aa70";
  } else {
    color = "#00002018";
  }

  return (
    <Layout
      className="custom-scroll"
      column
      gap={10}
      grow
      style={{ overflowY: "auto" }}
    >
      <Layout
        alignMiddle
        background={color}
        padding={5}
        row
        style={{ borderRadius: 3 }}
      >
        <Layout alignMiddle row>
          {isEmergency && <AlarmIcon style={{ fill: "#000" }} />}
          {!isEmergency && isHighPriority && (
            <PriorityTicketIcon style={{ fill: "#4e4e4e" }} />
          )}
          {!isEmergency && !isHighPriority && (
            <TicketIcon style={{ fill: "#1d1d1d" }} />
          )}
        </Layout>
        <Layout column marginLeft={5}>
          <Text>{ticket.queue.name}</Text>
          {ticket.queue.description && (
            <Text size={10}>{ticket.queue.description}</Text>
          )}
        </Layout>
        <Layout column grow />
        <Layout gap={5} row>
          <Layout column>
            <TicketButton
              icon={<PutbackIcon style={{ fill: "#fff" }} />}
              label="Put back"
              onClick={() => ticket.unpick()}
            />
          </Layout>
          <Layout column>
            <TicketButton
              icon={<CloseTicketIcon style={{ fill: "#fff" }} />}
              label={`Close ${ticket.isEmergency ? "Alarm" : "Ticket"}`}
              onClick={() => closeTicket()}
            />
          </Layout>
        </Layout>
      </Layout>
      <Layout alignRight column marginRight={4}>
        <Text size={10}>{`Time in queue: ${ticket.opened}`}</Text>
      </Layout>
      {contact && (
        <Layout column padding={5} style={BOX_STYLE}>
          <Text size={13} weight={500}>
            User:
          </Text>
          <ContactRow
            fetchContactDetails={
              () => Promise.resolve() /* Data already available */
            }
            contact={contact}
            contactDetails={contact}
            evenRow={false}
            panel={panel}
            showDescription
            showFavorites={false}
            showMessages
            showOrganization={true}
          />
          {ticket.monitorable && ticket.monitoringSession && (
            <Layout column width={300}>
              <Layout row>
                <PTTButton
                  channel={group}
                  session={ticket.monitoringSession}
                  showLatestSpeaker={false}
                />
                <EndCallButton session={ticket.monitoringSession} />
              </Layout>
              <Layout column>
                <VolumeControls session={ticket.monitoringSession} />
              </Layout>
            </Layout>
          )}
          {ticket.monitorable && (
            <Layout column width={200}>
              {!ticket.monitoringSession && (
                <TicketButton
                  icon={<MonitorIcon style={{ fill: "#fff" }} />}
                  label="Start Listen In"
                  onClick={() => ticket.monitor()}
                />
              )}
            </Layout>
          )}
          {contact && contact.session && (
            <Layout column width={hasFullDuplexSession ? undefined : 300}>
              <Layout grow row>
                {hasFullDuplexSession &&
                  contact.fullDuplexRoom!.presenceCount > 0 && (
                    <Layout alignCenter column grow>
                      <FullDuplexSession contact={contact!} panel={panel} />
                    </Layout>
                  )}
                {((hasFullDuplexSession &&
                  contact.fullDuplexRoom!.presenceCount === 0) ||
                  (!hasFullDuplexSession &&
                    contact.session.presence.length === 0)) && (
                  <WaitingForCall contact={contact} />
                )}
                {!hasFullDuplexSession &&
                  contact.session.presence.length > 0 && (
                    <PTTButton
                      channel={group}
                      session={contact.session}
                      showLatestSpeaker={false}
                    />
                  )}
                {!hasFullDuplexSession && (
                  <EndCallButton session={contact.session} />
                )}
              </Layout>
              {!hasFullDuplexSession && (
                <Layout column>
                  <VolumeControls session={contact.session} />
                </Layout>
              )}
            </Layout>
          )}
        </Layout>
      )}
      {group && (
        <Layout column padding={5} style={BOX_STYLE}>
          <Text size={13} weight={500}>
            Group:
          </Text>
          <GroupRow
            channel={group}
            evenRow={false}
            panel={panel}
            showDescription
            showDispatcherPresence
            showFavoriteButton={false}
            showGroupSymbol
            showLatestSpeaker
            showLockbutton={group.hasLock}
            showMessages
            showOrganization
            showPatchIcon={group.hasPatch}
            showPowerbutton
            showPowerbuttonWithMenu={false}
            showPresence
            showPttButton
            showReplayButton
            showSpaces={false}
            showVolumeControls
          />
        </Layout>
      )}
      {locationData && (
        <Layout column grow padding={5} relative style={BOX_STYLE}>
          <Text size={13} weight={500}>
            Location:
          </Text>
          <Layout column grow marginTop={20} padding={10}>
            <Map locationData={locationData} />
          </Layout>
          <Layout absolute column right={10} style={{ zIndex: 1 }} top={10}>
            <GtTooltip
              id={`${panel.id}_${ticket.queueEntryEntityId}_open_in_new_panel`}
              title="Open in new panel"
            >
              <Layout column marginLeft={4} marginRight={4}>
                <MessageButton
                  entity={ticket}
                  panel={panel}
                  showMessages={false}
                />
              </Layout>
            </GtTooltip>
          </Layout>
        </Layout>
      )}
    </Layout>
  );
});
