import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";

export class ListSetting {
  public readonly list: { id: number | string; name: string }[];
  private readonly defaultId: string;
  private readonly key: string;
  public constructor({
    defaultId,
    key,
    list,
  }: {
    defaultId?: number | string;
    key: string;
    list?: { id: number | string; name: string }[];
  }) {
    this.list = list || [];
    this.key = key;
    this.defaultId =
      window.gtConfig.defaultSettings &&
      window.gtConfig.defaultSettings[key] !== undefined
        ? window.gtConfig.defaultSettings[key]
        : defaultId;

    observableClass(this);
  }
  public get configurable(): boolean {
    return window.gtConfig.configurableSettings &&
      window.gtConfig.configurableSettings[this.key] !== undefined
      ? window.gtConfig.configurableSettings[this.key]
      : true;
  }
  public get selected(): { id: number | string; name: string } {
    return this.list.find(
      (entry) => entry.id === (localStorage.getItem(this.key) || this.defaultId)
    )!;
  }
  public deselect(): void {
    localStorage.removeItem(this.key);
  }
  public select(entry: { id: number | string; name: string }): void {
    if (entry.id !== this.defaultId) {
      localStorage.setItem(this.key, entry.id);
    } else {
      localStorage.removeItem(this.key);
    }
  }
}
