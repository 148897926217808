import { SoundService } from "src/app/model/audio/SoundService";
import { observableClass } from "src/app/state/observableClass";
import { CallStatus } from "src/lib/types/CallStatus";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { Logger } from "src/util/Logger";
import type { DialogHandle } from "src/app/model/dialogs/DialogHandle";
import type { State } from "src/app/model/State";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { IncomingCall } from "src/lib/modules/IncomingCall";

const log = Logger.getLogger("incomingcall");
const MAX_RING_COUNT = 10;

export class IncomingCallHandler {
  private readonly incomingCalls: IncomingCall[] = [];
  private closed = false;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public closeDown(): void {
    this.closed = true;
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    await authenticatedModule.setupReceiveCallModule({
      onIncomingCall: (incomingCall) => {
        log.debug("Incoming call", incomingCall);
        this.incomingCalls.push(incomingCall);
        this.showDialog(incomingCall);
      },
    });
  }
  private showDialog(incomingCall: IncomingCall): void {
    const { callees } = incomingCall;
    let multipleCallees = "";
    if (callees && callees.length > 0) {
      multipleCallees = `${"\nTo: "}${callees.join(", ")}`;
    }

    let dialog: DialogHandle;
    let ringing = true;
    const answer = (callStatus?: CallStatus): void => {
      ringing = false;
      SoundService.stopSound("ringing");
      SoundService.stopSound("ringing2");
      if (callStatus) {
        incomingCall.respond(callStatus);
      }
      dialog.close();
    };

    dialog = this.state.dialogs.show({
      actions: [
        {
          label: "Decline",
          onSelect: () => {
            answer(CallStatus.Declined);
          },
        },
        {
          label: "Accept",
          onSelect: () => {
            answer(CallStatus.Accepted);
          },
        },
      ],
      forceRespond: true,
      text: `From ${incomingCall.caller + multipleCallees}`,
      title: "Incoming call",
    });
    let ringCount = 0;
    const ringRunnable = (): void => {
      if (ringing && !this.closed) {
        if (ringCount < MAX_RING_COUNT) {
          ringCount += 1;
          incomingCall.ringing();
          const sound = incomingCall.isFullDuplex ? "ringing-2" : "ringing";
          SoundService.playSound(sound);
          BackgroundTimer.setTimeout(ringRunnable, 6000);
        } else {
          answer(CallStatus.NoAnswer);
        }
      }
    };
    ringRunnable();
    incomingCall.setOnCancelListener(() => {
      answer();
    });
  }
}
