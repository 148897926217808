import { gql } from "src/app/graphql";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { OrganizationOffsetPagination } from "src/app/graphql/graphql";
import type { State } from "src/app/model/State";
import type { Organization } from "src/nextgen/types/Organization";

const log = Logger.getLogger("Organizations");

const ORGANIZATIONS_PER_FETCH = 50;

export class Organizations {
  public organizations: Organization[] = [];
  private error: null | string = null;
  private loading = true;
  private offset?: number;
  private totalItems?: number;
  public constructor(
    private readonly state: State,
    private readonly type: "group" | "user"
  ) {
    observableClass(this);
  }
  public async fetchOrganizations(): Promise<void> {
    if (!this.hasMore || this.error) {
      return;
    }
    try {
      log.debug("fetching organizations");
      this.loading = true;
      const clientGroupOrganizations = await (this.type === "group"
        ? this.queryClientGroupOrganizations()
        : this.queryClientUserOrganizations());
      this.error = null;
      this.loading = false;
      if (clientGroupOrganizations) {
        log.debug("Fetched organizations", clientGroupOrganizations.items);
        this.totalItems = clientGroupOrganizations.pageInfo.totalItems;
        const { limit } = clientGroupOrganizations.pageInfo;
        this.offset = (this.offset ?? 0) + limit;
        this.organizations = clientGroupOrganizations.items.map(
          (organization) => ({
            id: organization.id,
            name: organization.name,
          })
        );
      } else {
        this.organizations.length = 0;
      }
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to fetch organizations";
    }
  }
  public loadMore(): void {
    if (!this.loading) {
      void this.fetchOrganizations();
    }
  }
  private get hasMore(): boolean {
    return (
      this.totalItems === undefined ||
      this.offset === undefined ||
      this.offset < this.totalItems
    );
  }
  private async queryClientGroupOrganizations(): Promise<OrganizationOffsetPagination> {
    if (this.offset === undefined) {
      this.offset = 0;
    }
    const { clientGroupOrganizations } =
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "no-cache",
        query: gql(`
          query clientGroupOrganizations(
            $pagination: OffsetLimitInput!
          ) {
            clientGroupOrganizations(pagination: $pagination) {
              items {
                id
                name
              }
              pageInfo {
                totalItems
                offset
                limit
              }
            }
          }
        `),
        variables: {
          pagination: {
            limit: ORGANIZATIONS_PER_FETCH,
            offset: this.offset,
          },
        },
      });
    return clientGroupOrganizations;
  }
  private async queryClientUserOrganizations(): Promise<OrganizationOffsetPagination> {
    if (this.offset === undefined) {
      this.offset = 0;
    }
    const { clientUserOrganizations } =
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "no-cache",
        query: gql(`
          query clientUserOrganizations($pagination: OffsetLimitInput!) {
            clientUserOrganizations(pagination: $pagination) {
              items {
                id
                name
              }
              pageInfo {
                totalItems
                offset
                limit
              }
            }
          }
        `),
        variables: {
          pagination: {
            limit: ORGANIZATIONS_PER_FETCH,
            offset: this.offset,
          },
        },
      });
    return clientUserOrganizations;
  }
}
