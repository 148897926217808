import { FavoriteIcon, FavoriteFilledIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import type { Channel } from "src/app/model/channels/Channel";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { ContactListEntry } from "src/app/model/contacts/ContactListEntry";
import type { Panel } from "src/app/model/panels/Panel";

export const FavoriteButton = connect<{
  entity: Channel | ContactDetails | ContactListEntry;
  panel: Panel;
}>(({ entity, panel }) => {
  if (entity.isFavorite(panel)) {
    return (
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          entity.toggleFavorite(panel);
        }}
        aria-label="Remove as Favorite"
        style={{ padding: 4 }}
      >
        <FavoriteFilledIcon
          style={{
            background: "#86b96982",
            borderRadius: 5,
            fill: "rgb(0 38 0)",
          }}
        />
      </IconButton>
    );
  }
  return (
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        entity.toggleFavorite(panel);
      }}
      aria-label="Mark as Favorite"
      style={{ padding: 4 }}
    >
      <FavoriteIcon />
    </IconButton>
  );
});
