import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import { MenuItem } from "@mui/material";

const IconRow = ({
  icon,
  label,
}: {
  icon?: React.ReactNode;
  label: React.ReactNode;
}): JSX.Element => (
  <Layout alignMiddle gap={15} grow row>
    {icon != null ? icon : <Layout height={24} minWidth={24} />}
    {label}
  </Layout>
);

export const GtMenuItem = connect<{
  disabled?: boolean;
  icon?: React.ReactNode;
  label: React.ReactNode;
  onSelect?: () => void;
}>(({ disabled, icon, label, onSelect }, state) => {
  const { close } = state.menu;
  return onSelect ? (
    <MenuItem
      onClick={(event) => {
        event.stopPropagation();
        close();
        if (onSelect) {
          onSelect();
        }
      }}
      disabled={disabled}
    >
      <IconRow icon={icon} label={label} />
    </MenuItem>
  ) : (
    <IconRow icon={icon} label={label} />
  );
});
