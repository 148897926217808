import { QueueEntry } from "src/lib/modules/QueueEntry";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { Logger } from "twilio-video";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("QueueManagementModule");

/**
 * Obtained from <code>{@link QueueModule}.availableQueues</code>.
 * @namespace
 */
export class Queue {
  /**
   * Queue description.
   * @member {string}
   */
  public description?: string;
  /**
   * <code>true</code> if queue is used for emergency; otherwise, <code>false</code>.
   * @member {boolean}
   */
  public emergency: boolean;
  /**
   * Human readable name of queue.
   * @member {string}
   */
  public name: string;
  public onQueueEntries?: (queueEntries: QueueEntry[]) => void;
  /**
   * The owner of the queue; only non-<code>null</code> if this queue is shared by some other
   * organization.
   * @member {string}
   */
  public owner?: string;
  /**
   * Priority of queue (higher means higher prio).
   * @member {Number}
   */
  public priority: number;
  /**
   * Unique entity id for this queue.
   * @member {string}
   */
  public queueEntityId: string;
  public subscriptionId?: number;
  public constructor(
    private readonly requestManager: RequestManager,
    queueInfo: proto.IQueueInfo
  ) {
    this.requestManager = requestManager;
    this.queueEntityId = queueInfo.queueEntityId;
    this.name = queueInfo.name;
    this.description = queueInfo.info ?? undefined;
    this.owner = queueInfo.owner ?? undefined;
    this.emergency = queueInfo.emergency;
    this.priority = queueInfo.priority;
  }
  /**
   * Subscribe to queue entries. Note that only one subscription to every queue may be active.
   * @param {function(Array<QueueEntry>)} onQueueEntries Callback when queue entries are
   * updated or added.
   * @returns {Promise<function():Promise>} Resolves with an unsubscribe function when subscribed.
   * The <code>onQueueEntries</code> callback will be called with initial entries before this
   * promise resolves.
   */
  public async subscribe(
    onQueueEntries: (queueEntries: QueueEntry[]) => void
  ): Promise<() => Promise<void>> {
    const response = (await this.requestManager.send({
      queueManagement: {
        subscribeRequest: { queueEntityId: this.queueEntityId },
      },
    })) as proto.IQueueManagementSubscribeResponse;
    const { currentEntries, subscriptionId } = response;
    log.debug(`Queue entries for ${this.queueEntityId}:`, currentEntries);
    this.onQueueEntries = onQueueEntries;
    this.subscriptionId = longToNumber(subscriptionId);
    this.onQueueEntries(
      currentEntries
        ? currentEntries.map(
            (entry) => new QueueEntry(this.requestManager, this, entry)
          )
        : []
    );
    return async () => {
      if (this.subscriptionId === longToNumber(subscriptionId)) {
        this.onQueueEntries = undefined;
        this.subscriptionId = undefined;
        await this.requestManager.send({
          queueManagement: { unsubscribeRequest: { subscriptionId } },
        });
      }
    };
  }
}
