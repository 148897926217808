import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Connecting } from "src/app/gui/offline/Connecting";
import { OrganizationIcon, UserIcon, ExitIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { Button } from "@mui/material";

export const Offline = connect((_, state) => {
  const { deauthenticate, goOnline } = state.offline;
  const { displayName, organization } = state.userData;
  const { isConnecting } = state.connecting;
  return (
    <form
      onSubmit={(event) => {
        void goOnline(false);
        event.preventDefault();
      }}
      noValidate
    >
      <Layout column>
        <Connecting header="Logged in as">
          <Layout background="#e4e4e4" column gap={10} padding={10}>
            <Layout gap={20} row width={300}>
              <UserIcon />
              <Text>{displayName}</Text>
            </Layout>
            <Layout gap={20} row width={300}>
              <OrganizationIcon />
              <Text>{organization}</Text>
            </Layout>
          </Layout>
          <Layout column marginBottom={40} marginTop={30}>
            <Button
              color="primary"
              disableRipple
              size="large"
              type="submit"
              variant="contained"
            >
              Go online
            </Button>
          </Layout>
        </Connecting>
        <Layout column marginTop={10}>
          <Button
            onClick={() => {
              deauthenticate();
            }}
            disabled={isConnecting}
            type="button"
          >
            <Layout gap={10} row>
              <ExitIcon
                style={{ fill: isConnecting ? "rgba(0, 0, 0, 0.26)" : "#000" }}
              />
              <Text>Logout / Change user</Text>
            </Layout>
          </Button>
        </Layout>
      </Layout>
    </form>
  );
});
