import { Layout } from "src/app/gui/components/Layout";
import { Header } from "src/app/gui/header/Header";
import { NoMicrophone } from "src/app/gui/header/NoMicrophone";
import { Main } from "src/app/gui/Main";
import { TwilioAudioTracks } from "src/app/gui/panels/video/TwilioAudioTracks";
import { Settings } from "src/app/gui/settings/Settings";
import { connect } from "src/app/state/connect";

export const Online = connect((_, state) => (
  <Layout
    onKeyDown={(e) => {
      if (e.key === "Escape" && state.settings.showing) {
        state.settings.hide();
      }
    }}
    column
    tabIndex={-1}
    width="100%"
  >
    <Header />
    {state.online?.audio.showNoMicrophoneInput && <NoMicrophone />}
    {!state.settings.showing && <Main />}
    {state.settings.showing && <Settings />}
    <TwilioAudioTracks />
  </Layout>
));
