import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { DispatchIcon, GroupIcon, UnpatchIcon } from "src/app/images/index";
import { connect } from "src/app/state/connect";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const BreakPatchMenu = connect<{
  channel: Channel;
  children: React.ReactNode;
  panel: Panel;
}>(({ channel, children, panel }) => {
  const patch = channel.patch;
  const menuItems = [];
  if (patch && patch.created) {
    const date = new Date(patch.created).toLocaleString();
    menuItems.push(
      <Layout column key="createdby" marginBottom={20} paddingLeft={15}>
        <Text size={13}>Created by</Text>
        <Layout alignMiddle row>
          <DispatchIcon />
          <Layout column width={5} />
          <Text>{patch.owner}</Text>
        </Layout>
        <Text size={12}>{date}</Text>
      </Layout>
    );
    const members = (patch.groups ?? []).map((g) => (
      <Layout alignMiddle key={g.group.id} row>
        <GroupIcon />
        <Layout column width={5} />
        <Text>{g.group.name}</Text>
      </Layout>
    ));
    menuItems.push(
      <Layout
        column
        key="members"
        marginBottom={20}
        paddingLeft={15}
        paddingRight={15}
      >
        <Text size={13}>Members</Text>
        {members}
      </Layout>
    );
    menuItems.push(
      <GtMenuItem
        onSelect={() => {
          void patch.breakPatch({ withConfirmation: true });
        }}
        icon={<UnpatchIcon />}
        key="break"
        label="Break Patch"
      />
    );
  }
  return (
    <GtMenu
      onOpen={async () => {
        await channel.fetchPatchInfo();
        return true;
      }}
      base={Layout}
      id={`patch_menu_${panel.id}_${channel.channelUuid}`}
      items={menuItems}
    >
      {children}
    </GtMenu>
  );
});
