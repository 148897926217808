import { defaultPanelLayout } from "src/app/model/panels/defaultPanelLayout";
import { observableClass } from "src/app/state/observableClass";
import { removeKeyRecursivly } from "src/util/removeKeyRecursivly";
import * as FlexLayout from "flexlayout-react";
import { createAtom } from "mobx";
import localStorage from "mobx-localstorage";
import type { IAtom } from "mobx";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { TabsetId } from "src/app/types/TabsetId";

const LAYOUT = "gt2.layout";

export class Layout {
  private readonly flexLayoutModelAtom: IAtom;
  /**
   * This variable is intentionally not observed to prevent
   * model to be rebuilt every change.
   */
  private cachedLayout = localStorage.getItem(LAYOUT);
  public constructor(private readonly state: State) {
    this.flexLayoutModelAtom = createAtom("FlexLayoutModel");
    observableClass(this, { cachedLayout: false });
  }
  public get flexLayoutModel(): FlexLayout.Model {
    return FlexLayout.Model.fromJson({
      global: {
        borderBarSize: 0,
        tabEnableRename: true,
        tabSetTabStripHeight: 24,
      },
      layout: this.cachedLayout || defaultPanelLayout.layout,
    });
  }
  public get nameOfPanel() {
    return (id: ComponentId): string => {
      const nodes = this.nodesWithId(id);
      return nodes.length > 0 ? nodes[0].getName() : "?";
    };
  }
  public get rectOfPanel(): (
    panelId: ComponentId
  ) => FlexLayout.Rect | undefined {
    return (panelId: ComponentId): FlexLayout.Rect | undefined => {
      const nodes = this.nodesWithId(panelId);
      return nodes.length > 0 ? nodes[0].getRect() : undefined;
    };
  }
  public get tabsetIdContainingPanel() {
    return (id: ComponentId): TabsetId | undefined => {
      const node = this.tabsetNodeContainingPanel(id);
      return node !== undefined ? node.getId() : undefined;
    };
  }
  public get topLeftComponent(): FlexLayout.TabSetNode {
    this.flexLayoutModelAtom.reportObserved();
    let component = this.flexLayoutModel.getRoot() as FlexLayout.Node;
    while (component.getType() === "row") {
      [component] = component.getChildren();
    }
    return component as FlexLayout.TabSetNode;
  }
  public get visible() {
    return (id: ComponentId): boolean => {
      const node = this.tabsetNodeContainingPanel(id);
      if (node) {
        const selected = node.getSelectedNode() as FlexLayout.TabNode;
        return selected.getComponent() === id;
      }
      return false;
    };
  }
  public closePanel(id: ComponentId): void {
    const nodes = this.nodesWithId(id);
    if (nodes.length > 0) {
      for (const node of nodes) {
        this.flexLayoutModel.doAction(
          FlexLayout.Actions.deleteTab(node.getId())
        );
        const component = node.getComponent();
        if (component) {
          this.state.panels.onDelete(component);
        }
        this.updateLayout(this.flexLayoutModel);
      }
    }
  }
  public createPanel(
    id: ComponentId,
    name: string,
    panel?: Panel,
    panelId: TabsetId | null = null
  ): void {
    let usePanelId = null;
    if (panelId !== null && this.tabsetExists(panelId)) {
      usePanelId = panelId;
    }
    if (usePanelId == null && panel) {
      usePanelId = this.tabsetIdContainingPanel(panel.id);
    }
    if (usePanelId == null) {
      usePanelId = this.topLeftComponent.getId();
    }
    const action = FlexLayout.Actions.addNode(
      {
        className: `gt-panel-${id}`,
        component: id,
        name,
        type: "tab",
      },
      usePanelId,
      FlexLayout.DockLocation.CENTER,
      -1
    );
    this.flexLayoutModel.doAction(action);
    this.updateLayout(this.flexLayoutModel);
  }
  public maximizeToggle(tabsetNodeId: TabsetId): void {
    this.flexLayoutModel.doAction(
      FlexLayout.Actions.maximizeToggle(tabsetNodeId)
    );
  }
  public reportFlexLayoutModelChanged(): void {
    this.flexLayoutModelAtom.reportChanged();
  }
  public reset(): void {
    localStorage.removeItem(LAYOUT);
    this.cachedLayout = undefined;
    this.updateLayout(this.flexLayoutModel);
  }
  public selectPanel(panelId: ComponentId): void {
    const nodes = this.nodesWithId(panelId);
    if (nodes.length > 0) {
      this.flexLayoutModel.doAction(
        FlexLayout.Actions.selectTab(nodes[0].getId())
      );
    }
  }
  public updateLayout(model: FlexLayout.Model): void {
    const { layout } = model.toJson();
    const filteredLayout = removeKeyRecursivly(layout, "maximized");
    localStorage.setItem(LAYOUT, filteredLayout);
    this.flexLayoutModelAtom.reportChanged();
    this.cachedLayout = localStorage.getItem(LAYOUT);
  }
  public updateName(id: ComponentId, name: string): void {
    const nodes = this.nodesWithId(id);
    for (const node of nodes) {
      if (node !== undefined && node.getName() !== name) {
        node
          .getModel()
          .doAction(FlexLayout.Actions.renameTab(node.getId(), name));
      }
    }
  }
  private get nodesWithId() {
    return (id: ComponentId): FlexLayout.TabNode[] => {
      const nodes: FlexLayout.TabNode[] = [];
      this.flexLayoutModelAtom.reportObserved();
      this.flexLayoutModel.visitNodes((node) => {
        if (node instanceof FlexLayout.TabNode) {
          if (node.getComponent() === id) {
            nodes.push(node);
          }
        }
      });
      return nodes;
    };
  }
  private get tabsetExists() {
    return (id: TabsetId) => !!this.flexLayoutModel.getNodeById(id);
  }
  private get tabsetNodeContainingPanel(): (
    id: ComponentId
  ) => FlexLayout.TabSetNode | undefined {
    this.flexLayoutModelAtom.reportObserved();
    return (id: ComponentId): FlexLayout.TabSetNode | undefined => {
      const tabNodes = this.nodesWithId(id);
      if (tabNodes.length === 0) {
        return undefined;
      }
      return tabNodes[0].getParent() as FlexLayout.TabSetNode | undefined;
    };
  }
}
