import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Protocol decoding error.
 */
export class DecodingError extends GroupTalkAPIError {
  public constructor() {
    super("Protocol decoding error.", true);
  }
}
