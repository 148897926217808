import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MenuSeparator } from "src/app/gui/header/MenuSeparator";
import {
  UserIcon,
  SettingsIcon,
  ExitIcon,
  CallsignIcon,
  LogoutIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";

export const UserMenu = connect((_, state) => {
  const { goOffline, goOfflineAndLogout } = state.online!;
  const { settings } = state;
  const name = state.userData.displayName;
  const { callsign, callsignLabel, updateCallsign } = state.online!.callsign;

  return (
    <GtMenu
      items={[
        callsignLabel && (
          <GtMenuItem
            label={
              callsign
                ? `Set ${callsignLabel} (${callsign})`
                : `Set ${callsignLabel}`
            }
            onSelect={() => {
              updateCallsign({ callsign, callsignLabel });
            }}
            icon={<CallsignIcon />}
            key="callsign"
          />
        ),
        <GtMenuItem
          onSelect={() => {
            settings.show();
          }}
          icon={<SettingsIcon />}
          key="settings"
          label="Settings"
        />,
        <GtMenuItem
          onSelect={() => {
            goOffline();
          }}
          icon={<ExitIcon />}
          key="goOffline"
          label="Go Offline"
        />,
        <MenuSeparator key="separator" />,
        <GtMenuItem
          onSelect={() => {
            goOfflineAndLogout();
          }}
          icon={<LogoutIcon />}
          key="logout"
          label="Log Out"
        />,
      ]}
      id="user"
    >
      <Layout alignCenter alignMiddle gap={10} padding={10} row>
        <UserIcon />
        <Text color="#aaa" uppercase>
          {name}
        </Text>
      </Layout>
    </GtMenu>
  );
});
