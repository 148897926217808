import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import { Tooltip } from "@mui/material";

export const GtTooltip = connect<{
  children: React.ReactNode;
  id: string;
  title: string;
}>(({ children, id, title }, { tooltip }) => {
  const { hide, show } = tooltip;
  return (
    <Tooltip aria-label={title} open={id === tooltip.id} title={title}>
      <Layout
        onMouseEnter={() => {
          show(id);
        }}
        onMouseLeave={() => {
          hide(id);
        }}
        alignCenter
        alignMiddle
        column
        height="100%"
      >
        {children}
      </Layout>
    </Tooltip>
  );
});
