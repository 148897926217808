import { GtMenu } from "src/app/gui/components/GtMenu";
import { Layout } from "src/app/gui/components/Layout";
import { ActionRowContent } from "src/app/gui/panels/contact/ActionRowContent";
import { connect } from "src/app/state/connect";
import type { Action } from "src/app/types/Action";

export const ActionRow = connect<{
  action: Action;
  evenRow: boolean;
}>(({ action, evenRow }) => {
  if (action.menuItems && !action.disabled) {
    return (
      <GtMenu
        base={Layout}
        id={action.key}
        items={action.menuItems}
        onOpen={action.onOpen}
      >
        <ActionRowContent action={action} evenRow={evenRow} />
      </GtMenu>
    );
  }
  return (
    <ActionRowContent
      action={action}
      disabled={action.disabled}
      evenRow={evenRow}
    />
  );
});
