import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { NotificationRenderer } from "src/app/gui/shared/NotificationRenderer";
import { ErrorIcon, NotificationsIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { Notification } from "src/app/model/notifications/Notification";

export const NotificationMenu = connect((_, state) => {
  if (!state.online!.notificationMenuData) {
    return <></>;
  }
  const {
    active,
    error,
    fetchNotifications,
    noOfUnread,
    notifications,
    resetUnreadCount,
  } = state.online!.notificationMenuData;

  const mapNotifications = (n: Notification): JSX.Element => {
    if (n.message) {
      return (
        <GtMenuItem
          icon={NotificationRenderer.createNotificationIcon(n)}
          key={n.id}
          label={NotificationRenderer.createNotificationBody(n)}
          onSelect={() => n.onSelect()}
        />
      );
    }
    return <></>;
  };

  const metaItems = [];
  if (error) {
    metaItems.push(
      <GtMenuItem
        label={
          <Layout alignCenter alignMiddle column grow padding={2} width="100%">
            <Text color="#533" size={14}>
              {error}
            </Text>
          </Layout>
        }
        icon={<ErrorIcon />}
        key="error"
      />
    );
  }
  if (notifications.length === 0) {
    metaItems.push(
      <GtMenuItem
        label={
          <Layout alignCenter alignMiddle column grow padding={2} width="100%">
            <Text color="#555" size={14}>
              No notifications
            </Text>
          </Layout>
        }
        key="no_notifications"
        // icon={<LoadingIcon />}
        onSelect={() => {}}
      />
    );
  }

  const handleOpen = async (): Promise<boolean> => {
    await fetchNotifications();
    void resetUnreadCount();
    return true;
  };
  return (
    <Layout grow row>
      <GtMenu
        id="notifications"
        items={notifications.map(mapNotifications).concat(metaItems)}
        onOpen={handleOpen}
      >
        <Layout relative row>
          {active && noOfUnread > 0 && (
            <Layout
              style={{
                background: "#a33",
                borderRadius: "20%",
                height: 14,
                lineHeight: 1,
                padding: 3,
              }}
              absolute
              right={-4}
              top={-10}
            >
              <Text color="#aaa" size={14}>
                {noOfUnread}
              </Text>
            </Layout>
          )}
          <NotificationsIcon />
        </Layout>
      </GtMenu>
    </Layout>
  );
});
