import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { runInAction } from "mobx";
import localStorage from "mobx-localstorage";

const log = Logger.getLogger("SoundService");

export type DynamicListEntry = {
  id: number | string;
  name: string;
};

/**
 * Dynamic list saves the latest selected entry and only uses the dynamically provided list
 * when selecting a new entry. This can be used for settings that should be kept even
 * if the selected item in some cases might not be in list, like audio devices.
 */
export class DynamicListSetting {
  public readonly list: DynamicListEntry[];
  private readonly listProvider: () => Promise<DynamicListEntry[]>;
  private readonly defaultEntry: DynamicListEntry;
  private readonly key: string;
  public constructor({
    defaultEntry,
    key,
    listProvider,
  }: {
    defaultEntry: DynamicListEntry;
    key: string;
    listProvider: () => Promise<DynamicListEntry[]>;
  }) {
    this.list = [];
    this.listProvider = listProvider;
    this.key = key;
    if (
      window.gtConfig.defaultSettings &&
      window.gtConfig.defaultSettings[key] !== undefined
    ) {
      const s = DynamicListSetting.parseSetting(
        window.gtConfig.defaultSettings[key]
      );
      if (s) {
        this.defaultEntry = defaultEntry;
      } else {
        log.warn(
          `Unable to parse default settings for key ${key}: ${window.gtConfig.defaultSettings[key]}`
        );
        this.defaultEntry = defaultEntry;
      }
    } else {
      this.defaultEntry = defaultEntry;
    }

    observableClass(this);
  }
  public async refresh(): Promise<void> {
    const entries = await this.listProvider();
    runInAction(() => {
      this.list.length = 0;
      entries.forEach((e) => this.list.push(e));
    });
  }
  public get configurable(): boolean {
    return window.gtConfig.configurableSettings &&
      window.gtConfig.configurableSettings[this.key] !== undefined
      ? window.gtConfig.configurableSettings[this.key]
      : true;
  }
  public get selected(): DynamicListEntry {
    return this.configurable
      ? DynamicListSetting.parseSetting(localStorage.getItem(this.key)) ||
          this.defaultEntry
      : this.defaultEntry;
  }
  public deselect(): void {
    localStorage.removeItem(this.key);
  }
  public select(entry: DynamicListEntry): void {
    if (entry.id !== this.defaultEntry.id) {
      localStorage.setItem(this.key, JSON.stringify(entry));
    } else {
      localStorage.removeItem(this.key);
    }
  }

  private static parseSetting(setting: null | string): DynamicListEntry | null {
    if (!setting) {
      return null;
    }
    try {
      const entry = JSON.parse(setting);
      if (entry && entry.id && entry.name) {
        return entry;
      }
    } catch (err) {
      log.warn(`Unable to parse settings: ${setting}`, err);
    }
    return null;
  }
}
