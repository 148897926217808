import { BackgroundTimer } from "src/util/BackgroundTimer";

export class Throttled {
  private cancelTimeout?: () => void;
  private latestCall: number | undefined;
  public constructor(
    private readonly timeoutInMs: number,
    private readonly scheduleNewIfThrottled = true
  ) {}
  public clear(): void {
    this.latestCall = undefined;
    this.cancelTimeout?.();
    this.cancelTimeout = undefined;
  }
  public throttled(callback: () => void): void {
    const now = new Date().getTime();
    const timeLeftOfThrottle =
      this.latestCall === undefined
        ? 0
        : this.latestCall + this.timeoutInMs - now;
    if (timeLeftOfThrottle <= 0) {
      this.latestCall = now;
      callback();
    } else if (this.scheduleNewIfThrottled) {
      this.cancelTimeout?.();
      this.cancelTimeout = BackgroundTimer.setTimeout(() => {
        this.latestCall = new Date().getTime();
        callback();
      }, timeLeftOfThrottle);
    }
  }
}
