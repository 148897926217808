import { gql } from "src/app/graphql";
import { SoundService } from "src/app/model/audio/SoundService";
import { EventType } from "src/app/model/events/types/EventType";
import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { RoomParticipant } from "src/app/model/video/RoomParticipant";
import { observableClass } from "src/app/state/observableClass";
import { BackingOffTimer } from "src/nextgen/BackingOffTimer";
import { Logger } from "src/util/Logger";
import { ApolloError } from "@apollo/client/core";
import { action, runInAction } from "mobx";
import { connect, createLocalVideoTrack } from "twilio-video";
import type { DetailedEvent } from "src/app/model/events/types/DetailedEvent";
import type { EventUserDetailsUpdated } from "src/app/model/events/types/EventUserDetailsUpdated";
import type { PartialEvent } from "src/app/model/events/types/PartialEvent";
import type { State } from "src/app/model/State";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { FullDuplexUuid } from "src/nextgen/types/FullDuplexUuid";
import type { ParticipantInfo } from "src/nextgen/types/ParticipantInfo";
import type {
  LocalAudioTrack,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteParticipant,
  RemoteVideoTrack,
  TwilioError,
  Room as TwilioRoom,
} from "twilio-video";

const log = Logger.getLogger("rooms");
const PARTICIPANTS_PER_FETCH = 50;

export class Room {
  public readonly channelUuid?: ChannelUuid;
  public readonly peerUserUuid?: ClientUserUuid;
  public dominantSpeaker?: RemoteParticipant;
  public loading = true;
  /**
   * The current state of local camera.
   */
  public localCameraIsOn = false;
  /**
   * Indicates if a local video operation is in progress.
   * This is used to indicate that an async operation against the Twilio api to enable
   * a local video stream is in progress, and until this operation returns the camera
   * button should be disabled.
   */
  public twilioLocalVideoOperationInProgress = false;
  /**
   * Keyed by Twilio SID
   */
  public presence: Record<string, RoomParticipant> = {};
  /**
   * The current state. (Toggles false/true when clicking on PTT button)
   */
  public roomMuted = true;
  public twilioLocalVideoTrack?: LocalVideoTrack;
  private readonly connectTimer: BackingOffTimer<void>;
  private readonly fullDuplexUuid: FullDuplexUuid;
  /**
   * The permanent state from the user. (Icon toggled or not.)
   */
  private readonly localCameraSetting: BooleanSetting;
  /**
   * The permanent state from the user. (Icon toggled or not.)
   */
  private readonly mutedSetting: BooleanSetting;
  private accessToken?: string;
  private connectRegion?: string;
  private isShowingErrorDialog = false;
  private room: TwilioRoom | null = null;
  private roomPossiblyExist = true;
  private stopped = false;
  private unsubscribeToUpdates?: () => void;
  public constructor(
    private readonly state: State,
    options: {
      channelUuid?: ChannelUuid;
      fullDuplexUuid: FullDuplexUuid;
      peerUserUuid?: ClientUserUuid;
    }
  ) {
    this.channelUuid = options.channelUuid;
    this.fullDuplexUuid = options.fullDuplexUuid;
    this.peerUserUuid = options.peerUserUuid;
    this.mutedSetting = new BooleanSetting({
      defaultValue: options.channelUuid !== undefined,
      key: `gt2.room-mute_${this.fullDuplexUuid}`,
    });
    this.localCameraSetting = new BooleanSetting({
      defaultValue: false,
      key: `gt2.room-camera_${this.fullDuplexUuid}`,
    });
    this.dominantSpeaker = undefined;
    this.connectTimer = new BackingOffTimer<void>(() => this.connect());
    observableClass(this);
  }
  /**
   * The preferred video size standardized across GroupTalk.
   * 16:9 ratio is what we can expect clients to render their video boxes and as such should be
   * the preferred aspect ratio that client record their video if possible. (Or padding will be added.)
   */
  public static get videoSize(): { height: number; width: number } {
    return {
      height: 480,
      width: 854,
    };
  }
  public get localAudioMuted(): boolean {
    return this.mutedSetting.value;
  }
  public get localCameraSettingOn(): boolean {
    return this.localCameraSetting.value;
  }
  public get peerAudioMuted(): boolean {
    return Object.values(this.presence).some(
      (presence) => !presence.audioEnabled
    );
  }
  public get peerCameraIsOn(): boolean {
    return Object.values(this.presence).some(
      (presence) => presence.videoEnabled
    );
  }
  public get presenceCount(): number {
    return Object.values(this.presence).length;
  }
  public get twilioPeerVideoTrack(): RemoteVideoTrack | undefined {
    const roomParticipantWithVideo = Object.values(this.presence).find(
      (presence) => presence.twilioPeerVideoTrack
    );
    return roomParticipantWithVideo?.twilioPeerVideoTrack;
  }
  public muteRoom(forced = false): void {
    if (this.room && (!this.roomMuted || forced)) {
      log.debug(`Mute room ${this.fullDuplexUuid}`);
      this.roomMuted = true;
      if (!forced && this.state.settings.notificationSounds.stopSounds.value) {
        SoundService.playSound("send-stop");
      }
      setTimeout(
        () => {
          this.room?.localParticipant.audioTracks.forEach((publication) => {
            publication.track.disable();
          });
        },
        forced ? 0 : 200
      );
    }
  }
  public setCamera(videoOn: boolean): void {
    this.localCameraSetting.setValue(videoOn);
    log.debug(`Set camera of room ${this.fullDuplexUuid} to ${videoOn}`);
    this.updateCamera(false);
  }
  // This "locally" sets the configuration if use full duplex or push-to-talk.
  public setMute(mute: boolean): void {
    this.mutedSetting.setValue(mute);
    log.debug(`Set mute of room ${this.fullDuplexUuid} to ${mute}`);
    this.updateMute(false);
  }
  public async setup(): Promise<void> {
    if (this.stopped) {
      return;
    }
    try {
      await this.connectTimer.start();
    } catch (err) {
      this.handleError(err);
    }
  }
  public stop(): void {
    log.debug(`Disconnecting from room ${this.fullDuplexUuid}`);
    this.stopped = true;
    this.turnLocalCameraOff(true);
    this.unsubscribeToUpdates?.();
    this.unsubscribeToUpdates = undefined;
    if (this.room) {
      this.room.disconnect();
    }
  }
  public turnLocalCameraOff(forced = false): void {
    if (
      this.room &&
      !this.twilioLocalVideoOperationInProgress &&
      (this.localCameraIsOn || forced)
    ) {
      log.debug(`Turn local camera OFF in room ${this.fullDuplexUuid}`);
      if (this.twilioLocalVideoTrack) {
        try {
          this.twilioLocalVideoTrack.stop();
          this.room?.localParticipant.unpublishTrack(
            this.twilioLocalVideoTrack
          );
          this.localCameraIsOn = false;
          this.twilioLocalVideoTrack = undefined;
        } catch (error) {
          log.warn("Failed to unpublish video track", error);
          this.state.flashMessage.warn({ message: "Failed to disable video" });
        }
      }
    }
  }
  public turnLocalCameraOn(forced = false): void {
    if (
      this.room &&
      !this.twilioLocalVideoOperationInProgress &&
      (!this.localCameraIsOn || forced)
    ) {
      log.debug(`Turn local camera ON in room ${this.fullDuplexUuid}`);
      this.localCameraIsOn = true;

      if (!this.twilioLocalVideoTrack) {
        this.twilioLocalVideoOperationInProgress = true;
        void (async () => {
          try {
            this.twilioLocalVideoTrack = await createLocalVideoTrack({
              aspectRatio: {
                ideal: Room.videoSize.width / Room.videoSize.height,
              },
              frameRate: { ideal: 24 },
              height: { ideal: Room.videoSize.height },
              width: { ideal: Room.videoSize.width },
            });
            (await this.room?.localParticipant.publishTrack(
              this.twilioLocalVideoTrack
            )) as LocalVideoTrackPublication;
            this.twilioLocalVideoTrack.enable();
          } catch (error) {
            log.warn("Failed to enable local video", error);
            this.state.flashMessage.warn({ message: "Failed to enable video" });
          }
          this.twilioLocalVideoOperationInProgress = false;
        })();
      } else {
        this.twilioLocalVideoTrack.enable();
      }
    }
  }
  public unmuteRoom(forced = false): void {
    if (this.room && (this.roomMuted || forced)) {
      log.debug(`Unmute room ${this.fullDuplexUuid}`);
      this.roomMuted = false;

      if (this.state.settings.notificationSounds.startSounds.value) {
        SoundService.playSound("send-start");
      }
      const recordDelay =
        this.state.settings.soundInput.latencyCompensation.value || 0;

      setTimeout(() => {
        this.room?.localParticipant.audioTracks.forEach((publication) => {
          log.debug("Publication", publication);
          publication.track.enable();
        });
      }, recordDelay);
    }
  }
  private get isPrivateCall(): boolean {
    return this.peerUserUuid != null;
  }
  private clearPartipants(): void {
    this.presence = {};
  }
  private async connect(): Promise<void> {
    this.loading = true;
    if (this.accessToken === undefined) {
      await this.fetchAccessToken();
    }
    if (!this.roomPossiblyExist) {
      await this.createRoom();
    }
    log.debug("Setup room", this.fullDuplexUuid);
    log.debug("Using region", this.connectRegion);
    const room = await connect(this.accessToken!, {
      /*
       * As we don't provide an array of tracks to use when joining the room the SDK will
       * acquiring a LocalAudioTrack and LocalVideoTrack automatically for us. This has
       * the benefit that the SDK will also handle the clean-up.
       *
       * See:
       *  - https://sdk.twilio.com/js/video/releases/2.7.0/docs/module-twilio-video.html#.connect__anchor
       *  - https://sdk.twilio.com/js/video/releases/2.7.0/docs/global.html#ConnectOptions
       *  - https://github.com/twilio/video-quickstart-js/issues/48#issuecomment-531412443
       */
      audio: true,
      dominantSpeaker: true,
      name: this.fullDuplexUuid,
      region: this.connectRegion,
      // video: this.isPrivateCall ? { width: 640 } : false,
    });

    /*
     * If an automatically created video track is available, fetch it.
     *
     * For some reason even though the SDK states that a LocalVideoTrack will be created
     * automatically, it does not seem to be created automatically every time as verified
     * by using console.log-statements. However, it was probably created automatically
     * the time when https://gitlab.com/grouptalk/clients/dispatcher-web/-/issues/12 was
     * noticed. Hopefully, saving the video track if it was created automatically will
     * solve the problem noticed in issue #12.
     */
    if (room.localParticipant.videoTracks.size > 0) {
      this.twilioLocalVideoTrack = Array.from(
        room.localParticipant.videoTracks.values()
      )[0].track;
    }

    this.initRoom(room);
  }
  private async createRoom(): Promise<void> {
    const { createTwilioRoom } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation createTwilioRoom($input: CreateTwilioRoomInput!) {
            createTwilioRoom(input: $input) {
              error {
                __typename
                ... on AuthorizationError {
                  message
                }
                ... on TwilioRoomAlreadyExistsError {
                  message
                }
                ... on TwilioRoomDisabledError {
                  message
                  id
                }
              }
            }
          }
        `),
        variables: {
          input: {
            groupId: this.channelUuid,
          },
        },
      });
    if (createTwilioRoom.error !== null) {
      throw new Error(createTwilioRoom.error.message!);
    } else {
      log.debug(
        `Successfully created twilio room for channel ${this.fullDuplexUuid}`
      );
    }
  }
  private async fetchAccessToken(): Promise<void> {
    const { createTwilioRoomToken } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation createTwilioRoomToken(
            $input: CreateTwilioRoomTokenInput!
          ) {
            createTwilioRoomToken(input: $input) {
              error {
                __typename
                ... on AuthorizationError {
                  message
                }
                ... on NotEnoughCreditsError {
                  message
                }
                ... on TwilioRoomDisabledError {
                  message
                  id
                }
              }
              token
              connectOptions {
                region
              }
            }
          }
        `),
        variables: {
          input: {
            groupId: this.isPrivateCall ? null : this.fullDuplexUuid,
            privateCallId: this.isPrivateCall ? this.fullDuplexUuid : null,
          },
        },
      });
    if (createTwilioRoomToken.error !== null) {
      throw new Error(createTwilioRoomToken.error.message!);
    } else {
      log.debug(
        `Successfully fetched accessToken to room ${this.fullDuplexUuid}`
      );
      runInAction(() => {
        this.accessToken = createTwilioRoomToken.token ?? undefined;
        this.connectRegion =
          createTwilioRoomToken.connectOptions?.region ?? undefined;
      });
    }
  }
  private handleConnectionError(err: ApolloError): void {
    void this.leaveChannelOrEndCall();
    this.showError(
      "Error connecting to room",
      `We're sorry, we're having trouble connecting to the room. Please check that you have a stable internet connection and try again. If you are behind a firewall, make sure that it allows media traffic. If the problem persists, please contact our support team for assistance. (${err.name})`
    );
  }
  private handleMediaConnectionError(): void {
    /*
     * Error TwilioError 53405: Media connection failed or Media activity ceased
     *
     * This error is expected when the room closes due to the remote participant
     * disconnecting unexpectedly and not reconnecting during the grace-period.
     */
    void this.leaveChannelOrEndCall();
    this.showError(
      "Disconnected from room",
      "We're sorry, we're having trouble connecting to the room. It is possible that the room was closed due to the other participant disconnecting. If the problem persists, please contact our support team for assistance."
    );
  }
  private handleError(err: any): void {
    log.error(err);
    if (err instanceof ApolloError) {
      this.handleConnectionError(err);
      return;
    }
    if (
      err.name &&
      [
        "NotAllowedError",
        "NotFoundError",
        "NotReadableError",
        "OverconstrainedError",
        "TypeError",
      ].includes(err.name)
    ) {
      // Media errors
      // https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#media-errors
      this.handleMediaError(err);
    } else if (err.code) {
      if (err.code === 53000 /* 'SignalingConnectionError' */) {
        this.handleConnectionError(err);
      } else if (err.code === 53405 /* 'MediaConnectionError' */) {
        this.handleMediaConnectionError();
      } else if (err.code === 20104 /* 'AccessTokenExpiredError' */) {
        // Invalid access token. Reset and retry
        this.accessToken = undefined;
      } else if (
        [
          53103, /* 'RoomCreateFailedError' */ 53106 /* 'RoomNotFoundError' */,
        ].includes(err.code)
      ) {
        // Room not found / Unable to create room
        if (this.isPrivateCall) {
          void this.leaveChannelOrEndCall();
        } else {
          this.roomPossiblyExist = false;
        }
      } else if (err.code === 53216 || err.code === 53205) {
        // Name possibly TVIErrorParticipantSessionLengthExceededError
        /* Error descriptions found here: https://www.twilio.com/docs/api/errors/53216 */
        // Maximum participant length exceeded. This should typically not happen
        // if configured correctly, but if it happens, leave group/end call.
        // 53205 = Participant disconnected because of duplicate identity
        void this.leaveChannelOrEndCall();
        return;
      } else {
        // Unhanlded error. Retry.
        log.warn(`Unhandled error ${err.code}`);
      }
    }
    // Retry, with backing off timer
    void this.setup();
  }
  private handleMediaError(err: any): void {
    this.showError(
      "Error getting microphone",
      `We were unable to access your microphone. Please check that your browser recognizes your microphone and that you have granted permission to use it. You may need to adjust your browser settings and refresh the page to try again. (${err.name})`
    );
  }
  private initRoom(room: TwilioRoom): void {
    log.debug(`Successfully joined a Room: ${room}`);
    room.on("participantConnected", this.onParticipantJoined);
    room.on("participantDisconnected", this.onParticipantDisconnected);
    room.on("dominantSpeakerChanged", this.onDominantSpeaker);
    room.on("disconnected", this.onDisconnect);
    room.on("reconnected", () => {
      void this.onConnectedOrReconnected(room);
    });
    room.on("reconnecting", this.onReconnecting);
    void this.onConnectedOrReconnected(room);
  }
  private async leaveChannelOrEndCall(): Promise<void> {
    this.stopped = true; // so this.setup() does not trigger a re-connect
    if (this.channelUuid) {
      const channel = await this.state.online?.channels.channelWithUuid(
        this.channelUuid
      );
      channel?.updateJoined(false);
    } else if (this.peerUserUuid) {
      const session = this.state.online?.sessions.sessionWithPeerUserUuid(
        this.peerUserUuid
      );
      await session?.endCall();
    }
  }
  private async onConnectedOrReconnected(room: TwilioRoom): Promise<void> {
    this.room = room;
    log.debug(
      `Connected to the Room as LocalParticipant "${this.room.localParticipant.identity}"`
    );

    const ids = Array.from(room.participants.values()).map(
      (participant) => participant.identity
    );

    try {
      const groupParticipants =
        ids.length > 0 ? await this.queryParticipantsInfos(ids) : { items: [] };
      log.debug("Participant infos", groupParticipants);
      room.participants.forEach((participant) => {
        log.debug(
          `Participant "${participant.identity}" is connected to the Room`
        );

        const participantInfo = groupParticipants.items.find(
          (info) => info.id === participant.identity
        );
        this.setupParticipant(participant, participantInfo);
      });
      this.updateMute(true);
      if (this.isPrivateCall) {
        this.updateCamera(true);
      }
      this.subscribeToUpdates();
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      this.handleError(error);
    }
  }
  private onDisconnect(room: TwilioRoom, error: TwilioError): void {
    // Detach the local media elements
    log.debug(`Disconnected from room: ${room}. Error ${error}`);
    room.localParticipant.tracks.forEach((publication) => {
      const attachedElements = (publication.track as LocalAudioTrack).detach();
      attachedElements.forEach((element) => element.remove());
    });
    this.clearPartipants();
    if (error) {
      this.handleError(error);
    }
  }
  private onDominantSpeaker(participant: RemoteParticipant): void {
    this.dominantSpeaker = participant;
    log.debug("The new dominant speaker in the Room is:", participant);
  }
  private onParticipantDisconnected(participant: RemoteParticipant): void {
    log.debug(`A remote Participant disconnected: ${participant}`);
    // Remove audio div?
    delete this.presence[participant.sid];
  }
  private onParticipantJoined(participant: RemoteParticipant): void {
    log.debug(`A remote Participant connected: ${participant}`);
    this.setupParticipant(participant);
  }
  private onReconnecting(): void {
    this.loading = true;
    this.clearPartipants();
  }
  private async queryParticipantsInfos(ids: string[]): Promise<{
    items: ParticipantInfo[];
  }> {
    const { groupParticipants } =
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "no-cache",
        query: gql(`
          query groupParticipants(
            $pagination: OffsetLimitInput!
            $filter: GroupParticipantsFilterInput!
          ) {
            groupParticipants(pagination: $pagination, filter: $filter) {
              items {
                ... on Participant {
                  id
                  name
                  title
                }
                ... on UserParticipant {
                  id
                  name
                  title
                  client
                  userId
                }
              }
            }
          }
        `),
        variables: {
          filter: {
            participantIds: ids,
          },
          pagination: {
            limit: PARTICIPANTS_PER_FETCH,
            offset: 0,
          },
        },
      });
    return groupParticipants;
  }
  private setupParticipant(
    participant: RemoteParticipant,
    participantInfo?: ParticipantInfo
  ): void {
    const roomParticipant = new RoomParticipant(this.state, this, participant);
    this.presence[roomParticipant.sid] = roomParticipant;
    void roomParticipant.setup(participantInfo);
  }
  private showError(title: string, text: string): void {
    if (!this.isShowingErrorDialog) {
      this.isShowingErrorDialog = true;
      this.state.dialogs.show({
        actions: [
          {
            label: "Ok",
            onSelect: () => {
              this.isShowingErrorDialog = false;
            },
          },
        ],
        forceRespond: true,
        text,
        title,
      });
    }
  }
  private subscribeToUpdates(): void {
    if (this.unsubscribeToUpdates === undefined) {
      this.unsubscribeToUpdates = this.state.online?.eventManager.subscribe({
        onDetailedEvent: action((msg: DetailedEvent) => {
          for (const participant of Object.values(this.presence).filter(
            (c) => c.userUuid?.toUpperCase() === msg.payload.id.toUpperCase()
          )) {
            const payload = msg.payload as EventUserDetailsUpdated;
            participant.updateParticipantInfo({
              name: payload.displayName,
              title: payload.title ?? undefined,
            });
          }
        }),
        onEvent: (msg: PartialEvent) => {
          return Object.values(this.presence).some(
            (c) => c.userUuid?.toUpperCase() === msg.payload.id?.toUpperCase()
          );
        },
        types: [EventType.UserDetailsUpdated],
      });
    }
  }
  private updateCamera(forced: boolean): void {
    if (this.localCameraSettingOn) {
      this.turnLocalCameraOn(forced);
    } else {
      this.turnLocalCameraOff(forced);
    }
  }
  private updateMute(forced: boolean): void {
    if (this.localAudioMuted) {
      this.muteRoom(forced);
    } else {
      this.unmuteRoom(forced);
    }
  }
}
