import { Layout } from "src/app/gui/components/Layout";
import { WaitingForCall } from "src/app/gui/panels/contact/WaitingForCall";
import {
  EndCallIcon,
  MessageIcon,
  MicIcon,
  MicMutedIcon,
} from "src/app/images";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { connect } from "src/app/state/connect";
import {
  Fullscreen,
  FullscreenExit,
  Videocam,
  VideocamOff,
} from "@mui/icons-material";
import { CircularProgress, Fab } from "@mui/material";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { Panel } from "src/app/model/panels/Panel";

export const FullDuplexFabs = connect<{
  contact: ContactDetails;
  onClickFullScreen: () => void;
  panel: Panel;
}>(({ contact, onClickFullScreen, panel }) => {
  const session = contact.session;
  const { setContactTab } = panel.contactPanelData!;
  const room = session?.room;
  if (room) {
    const {
      localAudioMuted: muted,
      localCameraSettingOn,
      roomMuted,
      setCamera,
      twilioLocalVideoOperationInProgress,
    } = room;
    const buttonColor = roomMuted ? "#ff4444" : "#585868";
    const fillColor = roomMuted ? "#fff" : "#e6e6e6";

    return (
      <Layout alignCenter alignMiddle gap={10} row>
        <Fab
          onClick={() => {
            room!.setMute(!muted);
          }}
          style={{
            background: buttonColor,
            color: fillColor,
          }}
          aria-label="ptt"
        >
          {muted ? <MicMutedIcon /> : <MicIcon />}
        </Fab>
        <Fab
          aria-label={
            localCameraSettingOn ? "turn-off-camera" : "turn-on-camera"
          }
          onClick={() => {
            setCamera(!localCameraSettingOn);
          }}
          style={{
            background: localCameraSettingOn ? "#585868" : "#ff4444",
            color: localCameraSettingOn ? "#e6e6e6" : "#fff",
          }}
          disabled={twilioLocalVideoOperationInProgress}
        >
          {localCameraSettingOn ? <Videocam /> : <VideocamOff />}
          {twilioLocalVideoOperationInProgress && (
            <CircularProgress
              sx={{
                color: "#e6e6e6",
                left: -6,
                position: "absolute",
                top: -6,
                zIndex: 1,
              }}
              size={68}
            />
          )}
        </Fab>
        <Fab
          onClick={() => {
            setContactTab(ContactTabSetting.Messages);
          }}
          style={{
            background: "#585868",
            color: "#e6e6e6",
          }}
          aria-label="message"
        >
          <MessageIcon />
        </Fab>
        <Fab
          onClick={() => {
            onClickFullScreen();
          }}
          style={{
            background: "#585868",
            color: "#e6e6e6",
          }}
          aria-label="full-screen"
        >
          {document.fullscreenElement !== null ? (
            <FullscreenExit />
          ) : (
            <Fullscreen />
          )}
        </Fab>
        <Fab
          onClick={() => {
            void session.endCall();
          }}
          style={{
            background: "#aa3333",
            color: "#fff",
          }}
          aria-label="end-call"
        >
          <EndCallIcon />
        </Fab>
      </Layout>
    );
  }
  return <WaitingForCall contact={contact} />;
});
