import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MessageGroup } from "src/app/gui/panels/messages/MessageGroup";
import { connect } from "src/app/state/connect";
import type { Message } from "src/app/model/messages/Message";

export const DayGroup = connect<{
  sameDayGroup: Message[][];
}>(({ sameDayGroup }) => (
  <Layout column>
    <Layout alignCenter background="#293f4929" margin={16} padding={8} row>
      <Text bold size={12}>
        {sameDayGroup[0][0].readableDay}
      </Text>
    </Layout>
    {sameDayGroup.map((messageGroup) => (
      <MessageGroup key={messageGroup[0].id} messageGroup={messageGroup} />
    ))}
  </Layout>
));
