import { BackgroundTimer } from "src/util/BackgroundTimer";
import { WrappedPromise } from "src/util/WrappedPromise";

export const delay = (
  ms: number
): Promise<void> & {
  cancel(): void;
} => {
  const wrappedPromise = new WrappedPromise<void>();
  const delayTimer = BackgroundTimer.setTimeout(() => {
    wrappedPromise.resolve();
  }, ms);
  return Object.assign(wrappedPromise.promise, {
    cancel() {
      delayTimer();
    },
  });
};
