import { GtError } from "src/app/gui/components/GtError";
import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { GtTextField } from "src/app/gui/components/GtTextField";
import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { DayGroup } from "src/app/gui/panels/messages/DayGroup";
import {
  LockIcon,
  LockOpenIcon,
  SendMessageIcon,
  NotificationsIcon,
  NotificationsOffIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";
import { Fab, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import type { MessageChannel } from "src/app/model/messages/MessageChannel";
import type { Panel } from "src/app/model/panels/Panel";

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const Messages = connect<{
  channelLocked?: boolean;
  messageChannel: MessageChannel;
  panel: Panel;
}>(({ channelLocked, messageChannel, panel }) => {
  const {
    currentMessage,
    error,
    loadMore,
    loading,
    locked,
    mayLock,
    mayRead,
    mayWrite,
    name,
    notificationsEnabled,
    onTop,
    postMessage,
    sameDayMessagesGroupsReversed,
    scroll,
    setCurrentMessage,
    setOnTop,
    setScroll,
    toggleLocked,
    toggleNotifications,
    type,
  } = messageChannel;
  const { id } = panel;
  const [textFieldEmpty, setTextFieldEmpty] = useState(true);
  const infiniteRenderer = useRef<HTMLDivElement>(null);
  const previousMessages = usePrevious(sameDayMessagesGroupsReversed);
  const scrollToTop = (): void => {
    if (infiniteRenderer.current) {
      infiniteRenderer.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };
  useEffect(() => {
    if (
      scroll ||
      (onTop && previousMessages !== sameDayMessagesGroupsReversed)
    ) {
      scrollToTop();
      setScroll(false);
    }
  }, [
    onTop,
    scroll,
    setScroll,
    previousMessages,
    sameDayMessagesGroupsReversed,
  ]);

  let textInInputField;
  if (type === "group") {
    if (channelLocked) {
      textInInputField = "Group is locked.";
    } else if (!mayWrite) {
      textInInputField = "No permission to write in group";
    } else {
      textInInputField = `Message ${name}`;
    }
  } else {
    textInInputField = mayWrite
      ? "Send private message"
      : "No permission to write in channel.";
  }
  const lockedExtraMessage = mayLock
    ? "Channel is locked. Others cannot write."
    : "Channel is locked.";

  return (
    <Layout column height="100%">
      <Fab
        onClick={() => {
          void toggleNotifications();
        }}
        style={{
          background: "#6f7e91",
          position: "absolute",
          right: 12,
          zIndex: 1000,
        }}
        aria-label="notification"
        color="default"
      >
        {notificationsEnabled && (
          <GtTooltip
            id={`disable_notifications_${id}`}
            title="Disable notifications"
          >
            <NotificationsIcon style={{ color: "rgb(27, 28, 30)" }} />
          </GtTooltip>
        )}
        {!notificationsEnabled && (
          <GtTooltip
            id={`enable_notifications_${id}`}
            title="Enable notifications"
          >
            <NotificationsOffIcon style={{ color: "rgb(27, 28, 30)" }} />
          </GtTooltip>
        )}
      </Fab>

      {type === "private" && mayLock && (
        <Fab
          onClick={() => {
            void toggleLocked();
          }}
          style={{
            background: "#6f7e91",
            marginTop: 68,
            position: "absolute",
            right: 12,
            zIndex: 1000,
          }}
          aria-label="locked"
          color="default"
        >
          {locked && (
            <GtTooltip id={`unlock_channel_${id}`} title="Unlock channel">
              <LockIcon style={{ color: "rgb(27, 28, 30)" }} />
            </GtTooltip>
          )}
          {!locked && (
            <GtTooltip id={`lock_channel_${id}`} title="Lock channel">
              <LockOpenIcon style={{ color: "rgb(27, 28, 30)" }} />
            </GtTooltip>
          )}
        </Fab>
      )}

      <Layout column grow>
        <GtInfiniteRenderer
          elements={sameDayMessagesGroupsReversed.map((sameDayGroup) => (
            <DayGroup key={sameDayGroup[0][0].id} sameDayGroup={sameDayGroup} />
          ))}
          onEnterTop={() => {
            setOnTop(true);
          }}
          onLeaveTop={() => {
            setOnTop(false);
          }}
          loadMore={loadMore}
          loading={loading}
          ref={infiniteRenderer}
          reverse
        />
        {!mayRead && !loading && !error && (
          <GtError message="No permission to read channel" severity="info" />
        )}
      </Layout>

      {locked && (
        <Layout
          alignCenter
          alignMiddle
          background="#3200001a"
          column
          marginTop={12}
          padding={2}
          style={{ borderRadius: 5 }}
        >
          <Layout alignMiddle row>
            <Text color="#444" weight={500}>
              {lockedExtraMessage}
            </Text>
          </Layout>
        </Layout>
      )}

      <Layout column>
        {error && <GtError message={error} />}
        <Layout row>
          {type === "group" && (
            <GtTextField
              onKeyDown={(ev) => {
                if (ev.key === "Enter" && !(ev.shiftKey || ev.altKey)) {
                  ev.preventDefault();
                  void postMessage();
                  scrollToTop();
                }
              }}
              onValueChanged={(value) => {
                setCurrentMessage(value);
                setTextFieldEmpty(!value && value.length === 0);
              }}
              autoFocus
              disabled={!mayWrite || channelLocked}
              label={<Text>{textInInputField}</Text>}
              multiline
              rows={2}
              value={currentMessage}
            />
          )}
          {type === "private" && (
            <GtTextField
              onKeyDown={(ev) => {
                if (ev.key === "Enter" && !(ev.shiftKey || ev.altKey)) {
                  ev.preventDefault();
                  void postMessage();
                  scrollToTop();
                }
              }}
              onValueChanged={(value) => {
                setCurrentMessage(value);
                setTextFieldEmpty(!value && value.length === 0);
              }}
              autoFocus
              disabled={!mayWrite}
              label={<Text>{textInInputField}</Text>}
              multiline
              rows={2}
              value={currentMessage}
            />
          )}
          <Layout alignCenter alignMiddle column>
            <GtTooltip id={`send_${id}`} title="Send">
              <span>
                <IconButton
                  onClick={() => {
                    void postMessage();
                  }}
                  aria-label="send"
                  disabled={!(mayWrite && !textFieldEmpty)}
                  style={{ backgroundColor: "transparent" }}
                >
                  <SendMessageIcon />
                </IconButton>
              </span>
            </GtTooltip>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
});
