import { gql } from "src/app/graphql";
import { Constants } from "src/app/model/Constants";
import { Notification } from "src/app/model/notifications/Notification";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { NotificationFieldsFragment } from "src/app/graphql/graphql";
import type { State } from "src/app/model/State";
import type { SubscriptionModule } from "src/nextgen/SubscriptionModule";

const log = Logger.getLogger("NotificationMenuData");

export class MenuData {
  public active = false;
  /**
   * possible error message of last operation
   */
  public error?: string;
  public noOfUnread = 0;
  public notifications: Notification[] = [];
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public async fetchNotifications(): Promise<void> {
    try {
      const items = (
        await this.state.graphqlModule.queryDataOrThrow({
          fetchPolicy: "network-only",
          query: gql(`
            query notificationsLatestByThread(
              $profile: String!
            ) {
              notificationsLatestByThread(profile: $profile) {
                items {
                  ... on Notification {
                    ...NotificationFields
                  }
                }
                cursor {
                  next
                  previous
                }
              }
            }
          `),
          variables: { profile: Constants.PROFILE },
        })
      ).notificationsLatestByThread.items as NotificationFieldsFragment[];
      this.error = undefined;
      this.notifications = items // remove messaging notifications without data
        .filter(
          (n) =>
            n.type !== "messaging.message" ||
            (n.type === "messaging.message" && n.data)
        )
        // create notification of remaining items
        .map((n) => new Notification(this.state, n));
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to fetch notifications";
    }
  }
  public async resetUnreadCount(): Promise<void> {
    try {
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation notificationsResetUnreadCount($profile: String!) {
            notificationsResetUnreadCount(profile: $profile)
          }
        `),
        variables: { profile: Constants.PROFILE },
      });
      this.error = undefined;
      this.noOfUnread = 0;
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to reset notification unread count";
    }
  }
  public setup(subscriptionModule: SubscriptionModule): void {
    log.debug("Setting up NotificationMenuData");
    // subscribe to new notifications
    subscriptionModule.subscribe({
      onActive: () => {
        log.debug("Notification subscription is active");
        this.active = true;
        void this.fetchUnreadCount();
      },
      onEvent: (eventJson) => {
        log.debug(
          `New notification (subscription event): ${JSON.stringify(eventJson)}`
        );
        void this.fetchUnreadCount();
      },
      onInactive: () => {
        log.debug("Notification subscription is inactive");
        this.active = false;
      },
      onSetupSubscription: async (webSocketId) => {
        // TODO: handle subscribe errors
        log.debug(
          `Subscribing to new notifications for websocket ${webSocketId}`
        );
        const data = await this.state.graphqlModule.mutationDataOrThrow({
          mutation: gql(`
            mutation subscribeNotifications(
              $subscription: NotificationSubscriptionInput!
            ) {
              subscribeNotifications(subscription: $subscription)
            }
          `),
          variables: {
            subscription: {
              profile: Constants.PROFILE,
              webSocketId,
            },
          },
        });
        return data.subscribeNotifications;
      },
      onTearDownSubscription: (subscriptionId) => {
        log.debug(
          `Unsubscribing from new notifications with subscription id ${subscriptionId}`
        );
        try {
          void this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
              mutation unsubscribeNotifications($subscriptionId: ID!) {
                unsubscribeNotifications(subscriptionId: $subscriptionId)
              }
            `),
            variables: { subscriptionId },
          });
        } catch (e) {
          log.warn(`Unable to unsubscribe from new notifications: ${e}`);
        }
      },
    });
  }
  private async fetchUnreadCount(): Promise<void> {
    try {
      const data = await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "network-only",
        query: gql(`
          query notificationsUnreadCount($profile: String!) {
            notificationsUnreadCount(profile: $profile)
          }
        `),
        variables: { profile: Constants.PROFILE },
      });
      this.error = undefined;
      this.noOfUnread = data.notificationsUnreadCount;
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to fetch notification unread count";
    }
  }
}
