import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { Status } from "src/lib/types/Status";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { MessageChannelUuid } from "src/nextgen/types/MessageChannelUuid";
import type { Organization } from "src/nextgen/types/Organization";

export class ContactListEntry {
  public readonly id: ClientUserUuid;
  public description?: string;
  public name: string;
  public online: boolean;
  public owner: null | string;
  public privateMessageChannelId?: MessageChannelUuid;
  public status?: Status | null;
  public unreadCount?: number;
  public constructor(
    private readonly state: State,
    entry: {
      description?: null | string;
      id: ClientUserUuid;
      name: string;
      online: boolean;
      organization?: Organization;
      privateMessageChannelId?: MessageChannelUuid;
      status?: Status | null;
      unreadCount?: number;
    }
  ) {
    this.id = entry.id;
    this.name = entry.name;
    this.description = entry.description ?? undefined;
    const ourUuid = this.state.userData.organizationUuid;
    this.owner =
      entry.organization &&
      entry.organization.id &&
      entry.organization.id.toLowerCase() !== ourUuid?.toLowerCase()
        ? entry.organization.name
        : null;
    this.online = entry.online;
    this.status = entry.status;
    this.privateMessageChannelId = entry.privateMessageChannelId;
    this.unreadCount = entry.unreadCount;
    observableClass(this);
  }
  public get isFavorite() {
    return (panel: Panel) =>
      panel.contactsPanelData &&
      panel.contactsPanelData.includeContact(this.id);
  }
  public openOrFocusPanel(panel: Panel, tab: ContactTabSetting): void {
    const existingPanel = this.state.panels.find(
      (p) =>
        p.isContactPanel && p.contactPanelData!.contact?.contactUuid === this.id
    );
    if (existingPanel) {
      existingPanel.select();
      if (tab) {
        existingPanel.contactPanelData!.setContactTab(tab);
        if (tab === ContactTabSetting.Messages) {
          if (existingPanel.contactPanelData!.messageChannel) {
            existingPanel.contactPanelData!.messageChannel.setScroll(true);
          }
        }
      }
      existingPanel.flash();
    } else {
      const newTab = tab || ContactTabSetting.Actions;
      this.state.panels.add({
        customData: {
          contactId: this.id,
          contactTab: newTab,
        },
        name: this.name,
        panel,
        templateId: TemplateId.contact,
      });
    }
  }
  public toggleFavorite(panel: Panel): void {
    if (panel.contactsPanelData) {
      panel.contactsPanelData.toggleIncludeContact(this.id);
    }
  }
}
