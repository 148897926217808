import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { OrganizationIcon, CheckIcon } from "src/app/images/index";
import { connect } from "src/app/state/connect";
import type { ChannelsPanelData } from "src/app/model/channels/ChannelsPanelData";
import type { ContactsPanelData } from "src/app/model/contacts/ContactsPanelData";
import type { QueuePanelData } from "src/app/model/queues/QueuePanelData";

export const OrganizationFilter = connect<{
  panelData: ChannelsPanelData | ContactsPanelData | QueuePanelData;
}>(({ panelData }) => {
  const {
    organizationFilter,
    organizationFilterName,
    organizations,
    panel,
    setOrganization,
  } = panelData;

  if (organizations.organizations.length < 2) {
    return <></>;
  }

  return (
    <GtMenu
      items={[
        <GtMenuItem
          onSelect={() => {
            setOrganization(null);
          }}
          icon={!organizationFilter ? <CheckIcon /> : null}
          key="all"
          label="All organizations"
        />,
        <Layout
          key="separator"
          marginBottom={5}
          marginTop={5}
          style={{ borderTop: "1px solid #00000022" }}
        />,
        ...organizations.organizations.map((organization) => (
          <GtMenuItem
            icon={
              organizationFilter && organizationFilter === organization.id ? (
                <CheckIcon />
              ) : null
            }
            onSelect={() => {
              setOrganization(organization);
            }}
            key={organization.id}
            label={organization.name}
          />
        )),
      ]}
      base={Layout}
      id={`organization_filter_${panel.id}`}
    >
      <Layout
        style={{
          borderRadius: 2,
          cursor: "pointer",
        }}
        alignMiddle
        background="#00000008"
        gap={10}
        padding={8}
        row
      >
        <OrganizationIcon />
        {organizationFilter && <Text size={14}>{organizationFilterName}</Text>}
        {!organizationFilter && <Text size={14}>All organizations</Text>}
      </Layout>
    </GtMenu>
  );
});
