import { GtError } from "src/app/gui/components/GtError";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ActionRow } from "src/app/gui/panels/contact/ActionRow";
import { StatusBadge } from "src/app/gui/panels/contacts/StatusBadge";
import { StatusMenuItems } from "src/app/gui/panels/contacts/StatusMenuItems";
import {
  PrivateCallIcon,
  MessageIcon,
  LocationIcon,
  CallsignIcon,
  ContactPhoneIcon,
  ContactMailIcon,
  StatusIcon,
  FullDuplexIcon,
} from "src/app/images";
import { ContactManagement } from "src/app/model/contacts/ContactManagement";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { connect } from "src/app/state/connect";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { Panel } from "src/app/model/panels/Panel";
import type { Action } from "src/app/types/Action";

export const Actions = connect<{
  contact: ContactDetails;
  panel: Panel;
}>(({ contact, panel }) => {
  const {
    autoAnswerCall,
    call,
    callPermission,
    callable,
    callsign,
    callsignLabel,
    email,
    fullDuplexCall,
    fullDuplexCallable,
    locatable,
    phoneNumbers,
    statusList,
    updateCallsign,
  } = contact;
  const { hasMessaging, mayWriteMessage, setContactTab } =
    panel.contactPanelData!;
  const actions: Action[] = [];
  if (statusList && statusList.length > 0) {
    actions.push({
      badge: (
        <Layout row>
          {contact.status && <StatusBadge statusOption={contact.status} />}
          {!contact.status && (
            <Text color="#444" italic size={14}>
              No status
            </Text>
          )}
        </Layout>
      ),
      icon: <StatusIcon />,
      key: `status.${panel.id}`,
      label: "Status: ",
      menuItems: [<StatusMenuItems entity={contact} key="single" />],
    });
  }
  if (callPermission) {
    actions.push(
      {
        disabled: !callable || !!contact.session,
        icon: <PrivateCallIcon />,
        key: "privateCall",
        label: "Make PTT call",
        onSelect: () => {
          void call();
        },
      },
      {
        disabled: !callable || !!contact.session,
        icon: <PrivateCallIcon />,
        key: "privateAutoCall",
        label: "Make auto-answer PTT call",
        onSelect: () => {
          void autoAnswerCall();
        },
      },
      {
        disabled: !fullDuplexCallable || !!contact.session,
        icon: <FullDuplexIcon />,
        key: "fullduplexCall",
        label: "Make full-duplex call",
        onSelect: () => {
          void fullDuplexCall();
        },
      }
    );
  }
  if (hasMessaging) {
    if (mayWriteMessage) {
      actions.push({
        icon: <MessageIcon />,
        key: "privateMessage",
        label: "Send private message",
        onSelect: () => {
          setContactTab(ContactTabSetting.Messages);
        },
      });
    } else {
      actions.push({
        icon: <MessageIcon />,
        key: "privateMessage",
        label: "Show private messages",
        onSelect: () => {
          setContactTab(ContactTabSetting.Messages);
        },
      });
    }
  }
  if (locatable) {
    actions.push({
      icon: <LocationIcon />,
      key: "showLocation",
      label: "Show location",
      onSelect: () => {
        setContactTab(ContactTabSetting.Locations);
      },
    });
  }
  if (callsignLabel != null) {
    actions.push({
      icon: <CallsignIcon />,
      key: "callsign",
      label: `Set ${callsignLabel}`,
      onSelect: () => {
        updateCallsign();
      },
      subLabel: callsign,
    });
  }
  if (phoneNumbers) {
    phoneNumbers.forEach((phoneNumber) => {
      actions.push({
        icon: <ContactPhoneIcon />,
        key: `call_${phoneNumber}`,
        label: phoneNumber,
        onSelect: () => {
          ContactManagement.callPhoneNumber(phoneNumber);
        },
      });
    });
  }
  if (email) {
    actions.push({
      icon: <ContactMailIcon />,
      key: `email_${email}`,
      label: "Email",
      onSelect: () => {
        ContactManagement.sendEmail(email);
      },
      subLabel: email,
    });
  }
  return (
    <Layout alignTop column grow>
      {actions.length === 0 && (
        <GtError message="No actions available" severity="info" />
      )}
      {actions.length > 0 && (
        <Layout column>
          {actions.map((action, index) => (
            <ActionRow
              action={action}
              evenRow={index % 2 === 1}
              key={action.key}
            />
          ))}
        </Layout>
      )}
    </Layout>
  );
});
