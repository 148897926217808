import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * User client(s) does not support this action.
 */
export class UnsupportedByPeer extends GroupTalkAPIError {
  public constructor() {
    super("User client(s) does not support this action.", false);
  }
}
