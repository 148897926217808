import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import { ButtonBase } from "@mui/material";
import type { Action } from "src/app/types/Action";

export const ActionRowContent = connect<{
  action: Action;
  disabled?: boolean;
  evenRow: boolean;
}>(({ action, disabled = false, evenRow }) => {
  const bg = evenRow ? "#7d91aa" : "#7789a2";
  const { badge, icon, label, onSelect, subLabel } = action;
  return (
    <Layout
      onClick={() => {
        if (!disabled) {
          onSelect?.();
        }
      }}
      style={{
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.3 : 1.0,
      }}
      alignMiddle
      background={bg}
      element={disabled ? Layout : ButtonBase}
      margin={0}
      minHeight={36}
      row
      width="100%"
    >
      <Layout column marginLeft={10}>
        {icon}
      </Layout>
      <Layout alignLeft column marginLeft={10}>
        <Text
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color="#000"
          nowrap
          size={14}
        >
          {label}
        </Text>
        {subLabel && (
          <Text
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color="#474747"
            nowrap
            size={10}
          >
            {subLabel}
          </Text>
        )}
      </Layout>
      {badge && (
        <Layout column marginLeft={10}>
          {badge}
        </Layout>
      )}
      <Layout column grow />
    </Layout>
  );
});
