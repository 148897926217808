import { UsbDevice } from "src/app/model/input/UsbDevice";
import { UsbDevices } from "src/app/model/input/UsbDevices";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import type { State } from "src/app/model/State";

const log = Logger.getLogger("usb");

export class Usb {
  public devices: Record<string, UsbDevice> = {};
  private port: chrome.runtime.Port | null;
  public constructor(private readonly state: State) {
    this.port = null;
    this.handleConnectedDevice = this.handleConnectedDevice.bind(this);
    this.handleDisconnectedDevice = this.handleDisconnectedDevice.bind(this);
    observableClass(this);
  }
  public static get hidSupported(): boolean {
    return navigator.hid !== undefined;
  }
  public get connected(): boolean {
    return Object.keys(this.devices).length > 0;
  }
  public async closeDown(): Promise<void> {
    if (!Usb.hidSupported) {
      return;
    }
    navigator.hid.removeEventListener("connect", this.handleConnectedDevice);
    navigator.hid.removeEventListener(
      "disconnect",
      this.handleDisconnectedDevice
    );
    try {
      for (const device of Object.values(this.devices)) {
        await device.close();
        log.debug("Device closed", device);
      }
    } catch (error) {
      log.error("Could not close devices", error);
    }
    this.devices = {};
  }
  public connectToDevice(device: HIDDevice): void {
    const identifier = Usb.uniqueIdentifier(device);
    device
      .open()
      .then(() => {
        const recognizedDevice = UsbDevice.recognizedDevice(device);
        if (recognizedDevice) {
          log.debug(`Opened device: ${device.productName}`, device);
          this.devices[identifier] = new UsbDevice(
            this.state,
            device,
            identifier
          );
          localStorage.setItem(
            "gt2.usb.deviceCount",
            `${Object.keys(this.devices).length}`
          );
        }
      })
      .catch((err) => {
        log.warn("Unable to open device", err);
      });
  }
  public connectToNewButton(): void {
    navigator.hid
      .requestDevice({ filters: UsbDevices })
      .then((devices) => {
        if (devices.length === 0) return;
        this.connectToDevice(devices[0]);
      })
      .catch((err) => {
        log.warn("Unable to request device", err);
      });
  }
  public init(): void {
    if (!Usb.hidSupported) {
      return;
    }
    void this.connectToDevices();
    navigator.hid.addEventListener("connect", this.handleConnectedDevice);
    navigator.hid.addEventListener("disconnect", this.handleDisconnectedDevice);

    if (localStorage.getItem("gt2.usb.preventConnectToUSBHelper")) {
      return;
    }
    if (!Usb.supported) {
      return;
    }
    try {
      this.port = window.chrome.runtime.connect(
        window.gtConfig.usbHelperAppId!
      );
    } catch (error) {
      log.error("Could not connect to USB extension", error);
    }
    this.port?.onMessage.addListener(
      action((msg) => {
        const cmdArray = msg.split(".");
        if (cmdArray.length < 2 || cmdArray[0] !== "gtevent") {
          log.warn("Unknown USB message", msg);
          return;
        }
        const cmd = `${cmdArray[0]}.${cmdArray[1]}`;
        if (cmd === "gtevent.connectedToUSBHelper") {
          log.debug("Connected to HID. Show dialog...");
          if (this.port) {
            this.port.disconnect();
          }
          this.connectToNewButton();
          localStorage.setItem("gt2.usb.preventConnectToUSBHelper", "true");
        }
      })
    );
  }
  private static get supported(): boolean {
    return !!(
      window.chrome &&
      window.chrome.runtime &&
      window.gtConfig.usbHelperAvailable &&
      window.gtConfig.usbHelperAppId
    );
  }
  private static get uniqueIdentifier() {
    return (device: HIDDevice): string =>
      `${device.vendorId}.${device.productId}`;
  }
  private async connectToDevices(): Promise<void> {
    const previouslyConnectedDevicesCount = Number.parseInt(
      localStorage.getItem("gt2.usb.deviceCount") || "0",
      10
    );
    localStorage.removeItem("gt2.usb.deviceCount");
    const devices = await navigator.hid.getDevices();
    devices.forEach((d) => {
      log.debug(`Available device: ${d.productName}`);
      const identifier = Usb.uniqueIdentifier(d);
      if (UsbDevice.recognizedDevice(d)) {
        if (!this.devices[identifier]) {
          this.connectToDevice(d);
        } else {
          log.warn("Identical device found. Ignoring.");
        }
      }
    });
    if (Object.keys(devices).length < previouslyConnectedDevicesCount) {
      this.connectToNewButton();
    }
  }
  private handleConnectedDevice(e: HIDConnectionEvent): void {
    log.debug(`Device connected: ${e.device.productName}`);
    this.connectToDevice(e.device);
  }
  private handleDisconnectedDevice(e: HIDConnectionEvent): void {
    const { productName } = e.device;
    const identifier = Usb.uniqueIdentifier(e.device);
    log.debug(`Device disconnected: ${productName}`, e);
    if (this.devices[identifier]) {
      log.debug(`Disconnected to USB device: ${identifier}`);
      this.state.flashMessage.info({
        message: `Disconnected to USB device ${this.devices[identifier].deviceName}`,
      });
      delete this.devices[identifier];
    }
  }
}
