import { CreateGtTable } from "src/app/gui/components/CreateGtTable";
import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ContactRow } from "src/app/gui/panels/contacts/ContactRow";
import { Preview } from "src/app/gui/panels/customizing/Preview";
import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { ContactListEntry } from "src/app/model/contacts/ContactListEntry";
import { connect } from "src/app/state/connect";
import type { GtTableRow } from "src/app/gui/components/GtTableRow";
import type { Panel } from "src/app/model/panels/Panel";

const GtTable = CreateGtTable<GtTableRow>();

export const Settings = connect<{
  panel: Panel;
}>((props, state) => {
  const { panel } = props;
  const {
    mayToggleShowDescription,
    mayToggleShowMessages,
    mayToggleShowOrganization,
    showDescription,
    showMessages,
    showOrganization,
    toggleShowDescription,
    toggleShowMessages,
    toggleShowOrganization,
  } = panel.contactsPanelData!;
  const dummyContact = new ContactListEntry(state, {
    description: "Guard 1",
    id: "dummy",
    name: "Braxton Barnet",
    online: true,
    organization: { id: "dummy", name: "Pretty Good Security Inc." },
    unreadCount: 8,
  });
  const dummyContactDetails = new ContactDetails(state, {
    callPermission: false,
    entityId: null,
    fullDuplexPermission: false,
    id: "dummy",
    locatable: false,
    messagePermission: false,
    name: "Braxton Barnet",
    online: true,
    organization: { id: "dummy", name: "Pretty Good Security Inc." },
    status: null,
  });
  return (
    <Layout column gap={5}>
      <Text>Set appearance for contacts in this panel:</Text>
      <GtTable
        columns={[
          {
            id: "value",
            render: ({ checked, disabled, label }) => (
              <Layout alignLeft alignMiddle row>
                <GtCheckbox checked={checked} disabled={disabled} />
                <Text>{label}</Text>
              </Layout>
            ),
          },
        ]}
        rows={[
          {
            checked: showMessages,
            disabled: !mayToggleShowMessages,
            id: "showMessages",
            label: "Show unread messages",
            onClickRow: () => toggleShowMessages(),
          },
          {
            checked: showDescription,
            disabled: !mayToggleShowDescription,
            id: "showDescription",
            label: "Show description",
            onClickRow: () => toggleShowDescription(),
          },
          {
            checked: showOrganization,
            disabled: !mayToggleShowOrganization,
            id: "showOrganization",
            label: "Show organization",
            onClickRow: () => toggleShowOrganization(),
          },
        ]}
        getRowKey={(row) => row.id}
        onClickRow={(row) => row.onClickRow()}
      />
      <Preview>
        <ContactRow
          contact={dummyContact}
          contactDetails={dummyContactDetails}
          fetchContactDetails={() => Promise.resolve()}
          isDummy
          panel={panel}
          showDescription={showDescription}
          showFavorites={true}
          showMessages={showMessages}
          showOrganization={showOrganization}
        />
      </Preview>
    </Layout>
  );
});
