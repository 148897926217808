import { UpgradeNeeded } from "src/lib/errors/UpgradeNeeded";
import { AudioModule } from "src/lib/modules/AudioModule";
import { CallsignModule } from "src/lib/modules/CallsignModule";
import { ChannelModule } from "src/lib/modules/ChannelModule";
import { CustomActionModule } from "src/lib/modules/CustomActionModule";
import { EmergencyModule } from "src/lib/modules/EmergencyModule";
import { HttpRequestModule } from "src/lib/modules/HttpRequestModule";
import { LocationEnquiryModule } from "src/lib/modules/LocationEnquiryModule";
import { LocationReportModule } from "src/lib/modules/LocationReportModule";
// import { PhonebookModule } from "src/lib/modules/PhonebookModule";
import { PresenceModule } from "src/lib/modules/PresenceModule";
import { QueueManagementModule } from "src/lib/modules/QueueManagementModule";
import { ReceiveCallModule } from "src/lib/modules/ReceiveCallModule";
import { SessionModule } from "src/lib/modules/SessionModule";
import { SetupCallModule } from "src/lib/modules/SetupCallModule";
import { SmsModule } from "src/lib/modules/SmsModule";
import { StatusModule } from "src/lib/modules/StatusModule";
import { TalkburstModule } from "src/lib/modules/TalkburstModule";
import { TalkburstReceptionModule } from "src/lib/modules/TalkburstReceptionModule";
import { TalkburstTransmissionModule } from "src/lib/modules/TalkburstTransmissionModule";
import { ThirdPartyCallControlModule } from "src/lib/modules/ThirdPartyCallControlModule";
import { UdpModule } from "src/lib/modules/UdpModule";
import { WebappModule } from "src/lib/modules/WebappModule";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { Alarm } from "src/lib/modules/Alarm";
import type { CapabilitiesModule } from "src/lib/modules/CapabilitiesModule";
import type { IncomingCall } from "src/lib/modules/IncomingCall";
import type { IncomingTalkburst } from "src/lib/modules/IncomingTalkburst";
import type { Session } from "src/lib/modules/Session";
import type { RequestManager } from "src/lib/RequestManager";
import type { AccountInfo } from "src/lib/types/AccountInfo";
import type { CancelListener } from "src/lib/types/CancelListener";
import type { EncryptionParameters } from "src/lib/types/EncryptionParameters";
import type { HttpRequest } from "src/lib/types/HttpRequest";
import type { InitiateCall } from "src/lib/types/InitiateCall";
import type { Location } from "src/lib/types/Location";
import type { LocationReportHints } from "src/lib/types/LocationReportHints";
import type { LocationUpdateHints } from "src/lib/types/LocationUpdateHints";
import type { ReceptionStatistics } from "src/lib/types/ReceptionStatistics";
import type { SessionMonitoringStatus } from "src/lib/types/SessionMonitoringStatus";
import type { SessionStopped } from "src/lib/types/SessionStopped";
import type { Status } from "src/lib/types/Status";
import type { TalkburstReceptionStart } from "src/lib/types/TalkburstReceptionStart";
import type { TalkburstReceptionStop } from "src/lib/types/TalkburstReceptionStop";
import type { TerminateCall } from "src/lib/types/TerminateCall";
import type { UserDisplayInfo } from "src/lib/types/UserDisplayInfo";

const log = Logger.getLogger("AuthenticatedModule");

/**
 * Returned from <code>authenticate</code> of <code>{@link CapabilitiesModule}</code>.
 * @namespace
 */
export class AuthenticatedModule {
  /**
   * Information of a GroupTalk account.
   * @member {AccountInfo}
   */
  public readonly accountInfo: AccountInfo;
  public sessionModule?: SessionModule;
  private readonly capabilitiesModule: CapabilitiesModule;
  private readonly requestManager: RequestManager;
  private audioModule?: AudioModule;
  private callsignModule?: CallsignModule;
  private channelModule?: ChannelModule;
  private customActionModule?: CustomActionModule;
  private emergencyModule?: EmergencyModule;
  private httpRequestModule?: HttpRequestModule;
  private locationEnquiryModule?: LocationEnquiryModule;
  private locationReportModule?: LocationReportModule;
  // private phonebookModule?: PhonebookModule;
  private presenceModule?: PresenceModule;
  private queueManagementModule?: QueueManagementModule;
  private receiveCallModule?: ReceiveCallModule;
  private setupCallModule?: SetupCallModule;
  private smsModule?: SmsModule;
  private statusModule?: StatusModule;
  private talkburstModule?: TalkburstModule;
  private talkburstReceptionModule?: TalkburstReceptionModule;
  private talkburstTransmissionModule?: TalkburstTransmissionModule;
  private thirdPartyCallControlModule?: ThirdPartyCallControlModule;
  private udpModule?: UdpModule;
  private webappModule?: WebappModule;
  private constructor(options: {
    accountInfo: AccountInfo;
    capabilitiesModule: CapabilitiesModule;
    requestManager: RequestManager;
  }) {
    this.requestManager = options.requestManager;
    this.capabilitiesModule = options.capabilitiesModule;
    this.accountInfo = options.accountInfo;
  }
  public static async setup(
    requestManager: RequestManager,
    capabilitiesModule: CapabilitiesModule,
    options: { jwt?: string; purchaseUsage?: boolean } = {}
  ): Promise<AuthenticatedModule> {
    const { jwt = undefined, purchaseUsage = false } = options;
    try {
      const response = (await requestManager.send({
        authenticate: { authenticateRequest: { jwt, purchaseUsage } },
      })) as proto.IAuthenticateResponse;
      return new AuthenticatedModule({
        accountInfo: AuthenticatedModule.fromAuthenticateResponse(response),
        capabilitiesModule,
        requestManager,
      });
    } catch (error: any) {
      requestManager.stop(error);
      throw error;
    }
  }
  public onDisconnect(): void {
    if (this.locationEnquiryModule) {
      this.locationEnquiryModule.onDisconnect();
    }
  }
  public onRequest(
    message: proto.IServerMessage,
    respond: (code: proto.ResponseCode) => void
  ): CancelListener {
    if (
      (message.audio ||
        message.talkburstReception ||
        message.talkburstTransmission) &&
      this.talkburstModule
    ) {
      this.talkburstModule.onRequest(message, respond);
    } else if (message.callsignV2 && this.callsignModule) {
      this.callsignModule.onRequest(message.callsignV2, respond);
    } else if (message.channel && this.channelModule) {
      this.channelModule.onRequest(message.channel, respond);
    } else if (message.emergency && this.emergencyModule) {
      this.emergencyModule.onRequest(message.emergency, respond);
    } else if (message.httpRequest && this.httpRequestModule) {
      this.httpRequestModule.onRequest(message.httpRequest, respond);
    } else if (message.locationEnquiry && this.locationEnquiryModule) {
      this.locationEnquiryModule.onRequest(message.locationEnquiry, respond);
    } else if (message.locationReport && this.locationReportModule) {
      this.locationReportModule.onRequest(message.locationReport, respond);
    } /* else if (message.phoneBook && this.phonebookModule) {
      this.phonebookModule.onRequest(message.phoneBook, respond);
    } */ else if (message.presence && this.presenceModule) {
      this.presenceModule.onRequest(message.presence, respond);
    } else if (message.queueManagement && this.queueManagementModule) {
      this.queueManagementModule.onRequest(message.queueManagement, respond);
    } else if (message.receiveCall && this.receiveCallModule) {
      return this.receiveCallModule.onRequest(message.receiveCall, respond);
    } else if (message.session && this.sessionModule) {
      this.sessionModule.onRequest(message.session, respond);
    } else if (message.status && this.statusModule) {
      this.statusModule.onRequest(message.status, respond);
    } else if (
      message.thirdPartyCallControl &&
      this.thirdPartyCallControlModule
    ) {
      this.thirdPartyCallControlModule.onRequest(
        message.thirdPartyCallControl,
        respond
      );
    } else if (message.udp && this.udpModule) {
      this.udpModule.onRequest(message.udp, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
    return () => {};
  }
  /**
   * Setup the audio module to send and receive audio over TCP instead of UDP.
   * @private
   * @param {function(AudioData)} options.onAudio Callback on incoming audio.
   * @returns {Promise<AudioModule>}
   */
  public async setupAudioModule(options: {
    onAudio?: (audio: proto.IAudioData) => void;
  }): Promise<AudioModule> {
    if (this.capabilitiesModule.capabilities.audio) {
      this.audioModule = await AudioModule.setup(this.requestManager, options);
      return this.audioModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the callsign module for changing callsign.
   * @param {function(UserDisplayInfo)} [options.onCallsignChanged] Callback when callsign changes.
   * @returns {Promise<CallsignModule>}
   */
  public async setupCallsignModule(options: {
    callsign?: string;
    callsignLabel?: string;
    onCallsignChanged?: (info: UserDisplayInfo) => void;
  }): Promise<CallsignModule> {
    if (this.capabilitiesModule.capabilities.callsign) {
      this.callsignModule = await CallsignModule.setup(
        this.requestManager,
        options
      );
      return this.callsignModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the channel module for controlling channel selection.
   * @param {number} [options.requestedMaxJoined=100] Requested max number of joined channels.
   * Must be an integer.
   * @param {number} [options.requestedMaxScanned=0] Requested max number of scanned channels.
   * Must be an integer.
   * @param {number} [options.requestedMaxAutoJoined=100] Requested max number of auto joined
   * channels. Must be an integer.
   * @param {boolean} [options.resetToDefault=false] True to reset channel selection to default,
   * if any default has been configured.
   * @returns {Promise<ChannelModule>}
   */
  public async setupChannelModule(
    options: {
      requestedMaxAutoJoined?: number;
      requestedMaxJoined?: number;
      requestedMaxScanned?: number;
      resetToDefault?: boolean;
    } = {}
  ): Promise<ChannelModule> {
    if (this.capabilitiesModule.capabilities.channel) {
      this.channelModule = await ChannelModule.setup(
        this.requestManager,
        options
      );
      return this.channelModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the custom action module for performing custom actions such as putting this client in a
   * queue.
   * @returns {Promise<CustomActionModule>}
   */
  public async setupCustomActionModule(): Promise<CustomActionModule> {
    if (this.capabilitiesModule.capabilities.customAction) {
      this.customActionModule = await CustomActionModule.setup(
        this.requestManager
      );
      return this.customActionModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the Emergency module to send panic alarms.
   * @param {function(Alarm)} options.onAlarmRaised Callback when alarm is active on server.
   * @param {function(Alarm)} options.onAlarmCleared Callback when alarm has
   * been cleared from server.
   * @returns {Promise<EmergencyModule>}
   */
  public async setupEmergencyModule(options: {
    emergencyDialupNumber?: string;
    emergencySms?: string;
    emergencySmsReceivers?: string[];
    onAlarmCleared?: (alarm: Alarm) => void;
    onAlarmRaised?: (alarm: Alarm) => void;
  }): Promise<EmergencyModule> {
    if (this.capabilitiesModule.capabilities.emergency) {
      this.emergencyModule = await EmergencyModule.setup(
        this.requestManager,
        options
      );
      return this.emergencyModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the HTTP request module.
   * @param {function(HttpRequest):Promise<Number>} options.onHttpRequest Callback when server
   * want client to make a http request. The callback should return a promise of the response
   * code aquired from the request.
   * @returns {Promise<HttpRequestModule>}
   */
  public async setupHttpRequestModule(options: {
    onHttpRequest?: (httpRequest: HttpRequest) => Promise<number>;
  }): Promise<HttpRequestModule> {
    if (this.capabilitiesModule.capabilities.httpRequest) {
      this.httpRequestModule = await HttpRequestModule.setup(
        this.requestManager,
        options
      );
      return this.httpRequestModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the location enquiry management module for receiving location information.
   * @returns {Promise<LocationEnquiryModule>}
   */
  public async setupLocationEnquiryModule(): Promise<LocationEnquiryModule> {
    if (this.capabilitiesModule.capabilities.locationEnquiry) {
      this.locationEnquiryModule = await LocationEnquiryModule.setup(
        this.requestManager
      );
      return this.locationEnquiryModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the location report module for reporting this client's location to the server on request.
   * @param {function(LocationReportHints)} options.onStartReoprt Callback when server wants to
   * start knowing your location. Client should report his location with an interval of
   * hintInterval milliseconds or when the distance has exceeded hintDistance.
   * @param {function()} options.onStopReport Callback when server no longer wants reports of
   * our location.
   * @param {function(LocationUpdateHints):Promise<Location>} options.onUpdate Callback when
   * server wants a single location update. Client should report his location as soon as possible
   * but must comply to all supplied parameters.
   * @returns {Promise<LocationReportModule>}
   */
  public async setupLocationReportModule(options: {
    onStartReport: (reportHints: LocationReportHints) => void;
    onStopReport: () => void;
    onUpdate: (updateHints: LocationUpdateHints) => Promise<Location>;
  }): Promise<LocationReportModule> {
    if (this.capabilitiesModule.capabilities.locationReport) {
      this.locationReportModule = await LocationReportModule.setup(
        this.requestManager,
        options
      );
      return this.locationReportModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the phone book module for querying the phone book.
   * @returns {Promise<PhonebookModule>}
   */
  /* public async setupPhonebookModule(): Promise<PhonebookModule> {
    if (this.capabilitiesModule.capabilities.phoneBook) {
      this.phonebookModule = await PhonebookModule.setup(this.requestManager);
      return this.phonebookModule;
    }
    throw new UpgradeNeeded();
  } */
  /**
   * Setup the presence module for tracking presence in sessions.
   * @returns {Promise<PresenceModule>}
   */
  public async setupPresenceModule(): Promise<PresenceModule> {
    if (this.capabilitiesModule.capabilities.presence) {
      this.presenceModule = await PresenceModule.setup(this.requestManager);
      return this.presenceModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the queue management module for managing queues.
   * @returns {Promise<QueueManagementModule>}
   */
  public async setupQueueManagementModule(): Promise<QueueManagementModule> {
    if (this.capabilitiesModule.capabilities.queueManagement) {
      this.queueManagementModule = await QueueManagementModule.setup(
        this.requestManager
      );
      return this.queueManagementModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the receive module for receiving incoming calls.
   * @param {function(IncomingCall)} options.onIncomingCall Callback handling
   * incoming calls.
   * @returns {Promise<ReceiveCallModule>}
   */
  public async setupReceiveCallModule(options: {
    onIncomingCall: (incomingCall: IncomingCall) => void;
  }): Promise<ReceiveCallModule> {
    if (this.capabilitiesModule.capabilities.receiveCall) {
      this.receiveCallModule = await ReceiveCallModule.setup(
        this.requestManager,
        options
      );
      return this.receiveCallModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the session module to be able to participate in PTT conversations.
   * @param {number} [options.maxSessions=32] Max number of concurrent sessions.
   * Must be an integer.
   * @param {number} [options.maxCalls=32] Max number of concurrent calls.
   * Must be an integer.
   * @param {function(Session)} options.onStarted Callback when a session starts.
   * @param {function(SessionStopped)} options.onStopped Callback when a session stops.
   * @param {function(SessionMonitoringStatus)} options.onMonitored Callback when monitoring status
   * changes, set to <code>null</code> if monitoring should not be supported.
   * @returns {Promise<SessionModule>}
   */
  public async setupSessionModule(options: {
    maxCalls?: number;
    maxSessions?: number;
    onMonitored?: (status: SessionMonitoringStatus) => void;
    onStarted?: (session: Session) => void;
    onStopped?: (sessionStopped: SessionStopped) => void;
  }): Promise<SessionModule> {
    if (this.capabilitiesModule.capabilities.session) {
      this.sessionModule = await SessionModule.setup(
        this.requestManager,
        options
      );
      return this.sessionModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the call module for setting up new outgoing calls.
   * @returns {Promise<SetupCallModule>}
   */
  public async setupSetupCallModule(): Promise<SetupCallModule> {
    if (this.capabilitiesModule.capabilities.setupCall) {
      this.setupCallModule = await SetupCallModule.setup(this.requestManager);
      return this.setupCallModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the SMS module to send SMS from the server to a single receiver.
   * @returns {Promise<SMSModule>}
   */
  public async setupSmsModule(): Promise<SmsModule> {
    if (this.capabilitiesModule.capabilities.sms) {
      this.smsModule = await SmsModule.setup(this.requestManager);
      return this.smsModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the status module for changing status.
   * @returns {Promise<StatusModule>}
   */
  public async setupStatusModule(options: {
    onStatusChange: (status?: Status) => void;
  }): Promise<StatusModule> {
    if (this.capabilitiesModule.capabilities.status) {
      this.statusModule = await StatusModule.setup(
        this.requestManager,
        options
      );
      return this.statusModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the talkburst module for transmitting and receiving PTT talkbursts.
   * @param {function(IncomingTalkburst)} options.onTalkburstStart Callback when receiving
   * incoming talkburst
   * @param {function(IncomingTalkburst)} options.onTalkburstStop Callback when talkburst stops
   * @returns {Promise<TalkburstModule>}
   */
  public async setupTalkburstModule(options: {
    onTalkburstStart?: (talkburst: IncomingTalkburst) => void;
    onTalkburstStop?: (talkburst: IncomingTalkburst) => void;
  }): Promise<TalkburstModule> {
    const { audio, talkburstReception, talkburstTransmission } =
      this.capabilitiesModule.capabilities;
    if (audio && talkburstTransmission && talkburstReception) {
      this.talkburstModule = await TalkburstModule.setup(
        this.requestManager,
        options
      );
      return this.talkburstModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * @private
   * Setup the talkburst reception module for receiving PTT talkbursts.
   * @param {function(TalkburstReceptionStart)} options.onTalkburstStart Callback when receiving
   * incoming talkburst
   * @param {function(TalkburstReceptionStop):Promise<ReceptionStatistics>} options.onTalkburstStop
   * Callback when talkburst is stopped. Should return reception statistics if
   * <code>{@link TalkburstReceptionModule}.returnReceptionStatistics</code> is <code>true</code>.
   * @returns {Promise<TalkburstReceptionModule>}
   */
  public async setupTalkburstReceptionModule(options: {
    onTalkburstStart?: (receptionStart: TalkburstReceptionStart) => void;
    onTalkburstStop?: (
      receptionStop: TalkburstReceptionStop
    ) => Promise<ReceptionStatistics> | null;
  }): Promise<TalkburstReceptionModule> {
    if (this.capabilitiesModule.capabilities.talkburstReception) {
      this.talkburstReceptionModule = await TalkburstReceptionModule.setup(
        this.requestManager,
        options
      );
      return this.talkburstReceptionModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * @private
   * Setup the talkburst transmission module for sending PTT talkbursts.
   * @returns {Promise<TalkburstTransmissionModule>}
   */
  public async setupTalkburstTransmissionModule(): Promise<TalkburstTransmissionModule> {
    if (this.capabilitiesModule.capabilities.talkburstTransmission) {
      this.talkburstTransmissionModule =
        await TalkburstTransmissionModule.setup(this.requestManager);
      return this.talkburstTransmissionModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the Third party call control.
   * @param {function(InitiateCall)} options.onInitiate Callback on initiate call request.
   * @param {function(TerminateCall)} options.onTerminate Callback on terminate call request.
   * @returns {Promise<ThirdPartyCallControlModule>}
   */
  public async setupThirdPartyCallControlModule(options: {
    onInitiate: (initiateCall: InitiateCall) => void;
    onTerminate: (terminateCall: TerminateCall) => void;
  }): Promise<ThirdPartyCallControlModule> {
    if (this.capabilitiesModule.capabilities.thirdPartyCallControl) {
      this.thirdPartyCallControlModule =
        await ThirdPartyCallControlModule.setup(this.requestManager, options);
      return this.thirdPartyCallControlModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the UDP module to use UDP for audio communication.
   * @private
   * @param {EncryptionParameters} options.EncryptionParameters Parameters that server uses when
   * encrypting data to client.
   * @param {function(integer)} options.onStartKeepOpen Callback on start keep open. Will supply
   * the interval between sequential keep open packets in milliseconds as the first argument.
   * @param {function()} options.onStopKeepOpen Callback on stop keep open.
   * @returns {Promise<SetupUdpModule>}
   */
  public async setupUdpModule(options: {
    encryptionParameters: EncryptionParameters;
    onStartKeepOpen: (interval: number) => void;
    onStopKeepOpen: () => void;
  }): Promise<UdpModule> {
    if (this.capabilitiesModule.capabilities.udp) {
      this.udpModule = await UdpModule.setup(this.requestManager, options);
      return this.udpModule;
    }
    throw new UpgradeNeeded();
  }
  /**
   * Setup the web app module for custom web apps.
   * @returns {Promise<WebappModule>}
   */
  public async setupWebappModule(): Promise<WebappModule> {
    if (this.capabilitiesModule.capabilities.webapp) {
      this.webappModule = await WebappModule.setup(this.requestManager);
      return this.webappModule;
    }
    throw new UpgradeNeeded();
  }
  private static fromAuthenticateResponse(
    authenticateResponse: proto.IAuthenticateResponse
  ): AccountInfo {
    return {
      accountId: authenticateResponse.accountId ?? undefined,
      canBeLocated: authenticateResponse.canBeLocated ?? false,
      canEnterQueues: authenticateResponse.canEnterQueues ?? false,
      canLocateOthers: authenticateResponse.canLocateOthers ?? false,
      canMakeCalls: authenticateResponse.canMakeCalls ?? false,
      canManageAlarms: authenticateResponse.canManageAlarms ?? false,
      canManageQueues: authenticateResponse.canManageQueues ?? false,
      canReceiveCalls: authenticateResponse.canReceiveCalls ?? false,
      canTriggerAlarm: authenticateResponse.canTriggerAlarm ?? false,
      displayName: authenticateResponse.displayName ?? undefined,
      domain: authenticateResponse.domain ?? undefined,
      email: authenticateResponse.email ?? undefined,
      hasContacts: authenticateResponse.hasContacts ?? false,
      loginId: authenticateResponse.loginId ?? undefined,
      organization: authenticateResponse.organization ?? undefined,
      phoneNumbers: authenticateResponse.phoneNumbers ?? undefined,
      title: authenticateResponse.title ?? undefined,
    };
  }
}
