import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Request terminated.
 */
export class RequestTerminated extends GroupTalkAPIError {
  public constructor() {
    super("Request terminated.", true);
  }
}
