import { Queue } from "src/lib/modules/Queue";
import { QueueEntry } from "src/lib/modules/QueueEntry";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("QueueManagementModule");

/**
 * Returned from <code>setupQueueManagementModule</code> of
 * <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class QueueManagementModule {
  /**
   * List of available queues.
   * @member {Array<Queue>}
   */
  public availableQueues: Queue[];
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      availableQueues: Queue[];
    }
  ) {
    this.availableQueues = options.availableQueues;
  }
  public static async setup(
    requestManager: RequestManager
  ): Promise<QueueManagementModule> {
    const response = (await requestManager.send({
      queueManagement: { setupRequest: {} },
    })) as proto.QueueManagementModuleSetupResponse;
    log.debug("QueueManagement module setup.", response);

    return new QueueManagementModule(requestManager, {
      availableQueues: response.availableQueues
        ? response.availableQueues.map(
            (queue) => new Queue(requestManager, queue)
          )
        : [],
    });
  }
  public onRequest(
    message: proto.IQueueManagementAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.delta) {
      this.onQueueDelta(message.delta, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  private onQueueDelta(
    message: proto.IQueueManagementDelta,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    const queue = this.availableQueues.find(
      (q) => q.subscriptionId === longToNumber(message.subscriptionId)
    );
    if (queue && queue.onQueueEntries) {
      log.debug("QueueDelta:", message);
      if (message.updatedEntries) {
        queue.onQueueEntries(
          message.updatedEntries.map(
            (entry) => new QueueEntry(this.requestManager, queue, entry)
          )
        );
      }
    } else {
      log.warn("QueueEntry delta received without subscriber.");
    }
  }
}
