import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Keep alive request was not received in time from server.
 */
export class KeepaliveTimeout extends GroupTalkAPIError {
  public constructor() {
    super("Keep alive timeout.", true);
  }
}
