import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { observableClass } from "src/app/state/observableClass";

export class PrivateCallSettings {
  public readonly monitoringCallsInSolo: BooleanSetting;
  public readonly privateCallsInSolo: BooleanSetting;
  public constructor() {
    this.privateCallsInSolo = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.muteInPrivate",
    });
    this.monitoringCallsInSolo = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.muteInMonitoring",
    });
    observableClass(this);
  }
}
