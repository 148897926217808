export enum SessionType {
  /** Session is started due to an auto-joined channel. */
  AutoJoin = 4,
  /** Session is started due to a call. */
  Call = 3,
  /** Session is started due to a joined channel. */
  Group = 1,
  /** Session is started due to us listening to a monitored user. */
  MonitoringListener = 6,
  /** Session is started due to us being monitored. */
  MonitoringSender = 5,
  /** Session is started due to a scanned channel. */
  Scan = 2,
}
