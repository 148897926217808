import OpusCodecEncoder from "src/audio/codec/es5/opusCodecEncoder";
import { Logger } from "src/util/Logger";
import type { OpusSettings } from "src/audio/codec/OpusSettings";

const log = Logger.getLogger("OpusEncoder");

const PACKET_TIME_IN_MS = 20;
const SAMPLING_RATE = 16000;
const APPLICATION_VOIP = 2048;
const NUM_CHANNELS = 1;

export class OpusEncoder {
  private readonly buf: Float32Array;
  private readonly bufPtr: number;
  private readonly frameSize: number;
  private readonly handle: typeof OpusCodecEncoder;
  private readonly out: Uint8Array;
  private readonly outPtr: number;
  private readonly paramMem: number[];
  private bufPos = 0;
  public constructor(opusSettings: OpusSettings) {
    log.debug(
      `Creating opus encoder with params ${JSON.stringify(opusSettings)}`
    );
    const err = OpusCodecEncoder._malloc(4);
    const app = APPLICATION_VOIP;
    const frameDuration = PACKET_TIME_IN_MS;
    if ([2.5, 5, 10, 20, 40, 60].indexOf(frameDuration) < 0) {
      throw new Error("Invalid frame duration");
    }
    this.frameSize = (SAMPLING_RATE * frameDuration) / 1e3;
    this.handle = OpusCodecEncoder._opus_encoder_create(
      SAMPLING_RATE,
      NUM_CHANNELS,
      app,
      err
    );
    if (OpusCodecEncoder.getValue(err, "i32") !== 0) {
      throw new Error(
        `Unable to create encoder (${OpusCodecEncoder.getValue(err, "i32")})`
      );
    }
    const params: Record<string, number> = {
      4002: opusSettings.bitrate >= 500000 ? -1 : opusSettings.bitrate,
      // NB = 1101, MB = 1102, WB = 1103
      4004: opusSettings.bandwidth, // OPUS_SET_MAX_BANDWIDTH_REQUEST
      4006: opusSettings.variableBitrate ? 1 : 0, // OPUS_SET_VBR_REQUEST
      4010: 10, // OPUS_SET_COMPLEXITY_REQUEST
      4012: opusSettings.forwardErrorCorrection ? 1 : 0, // OPUS_SET_INBAND_FEC_REQUEST
      4014: opusSettings.expectedPacketLoss, // OPUS_SET_PACKET_LOSS_PERC_REQUEST
      4024: 3001, // OPUS_SET_SIGNAL_REQUEST = OPUS_SIGNAL_VOICE
    };

    this.paramMem = [];
    Object.keys(params).forEach((key) => {
      const mem: number = OpusCodecEncoder._malloc(4);
      OpusCodecEncoder.setValue(mem, params[key], "i32");
      const ret = OpusCodecEncoder._opus_encoder_ctl(this.handle, key, mem);
      if (ret < 0) {
        log.warn(`Error setting ${key} (${ret})`);
      }
      this.paramMem.push(mem);
    });

    const bufSize = 4 * this.frameSize * NUM_CHANNELS;
    this.bufPtr = OpusCodecEncoder._malloc(bufSize);
    this.buf = OpusCodecEncoder.HEAPF32.subarray(
      this.bufPtr / 4,
      (this.bufPtr + bufSize) / 4
    );
    const outSize = opusSettings.maxFrameSize;
    this.outPtr = OpusCodecEncoder._malloc(outSize);
    this.out = OpusCodecEncoder.HEAPU8.subarray(
      this.outPtr,
      this.outPtr + outSize
    );
  }
  public encode(
    buffer: Float32Array,
    callback: (audio: Uint8Array) => void
  ): void {
    const len = buffer.length;
    let samples = new Float32Array(len);
    for (let cop = 0; cop < len; cop += 1) {
      let s = buffer[cop];
      if (s < -32768) {
        s = -32768;
      }
      if (s > 32767) {
        s = 32767;
      }
      samples[cop] = s / (s < 0 ? 32768 : 32767);
    }

    while (samples && samples.length > 0) {
      const size = Math.min(samples.length, this.buf.length - this.bufPos);
      this.buf.set(samples.subarray(0, size), this.bufPos);
      this.bufPos += size;
      samples =
        size < samples.length ? samples.subarray(size) : new Float32Array(0);
      if (this.bufPos === this.buf.length) {
        this.bufPos = 0;
        const ret = OpusCodecEncoder._opus_encode_float(
          this.handle,
          this.bufPtr,
          this.frameSize,
          this.outPtr,
          this.out.byteLength
        );
        if (ret < 0) {
          log.warn(`Error while encoding (${ret})`);
          return;
        }
        callback(new Uint8Array(this.out.subarray(0, ret)));
      }
    }
  }
  public release(): void {
    log.debug("Releasing Opus Encoder");
    OpusCodecEncoder._opus_encoder_destroy(this.handle);
    OpusCodecEncoder._free(this.bufPtr);
    OpusCodecEncoder._free(this.outPtr);
    this.paramMem.forEach((mem) => {
      OpusCodecEncoder._free(mem);
    });
  }
}
