/* eslint-disable import/order */
import { Logger } from "src/util/Logger";
import type { Encoder } from "src/audio/codec/Encoder";
import type { Resampler } from "src/audio/resampler/Resampler";
import { createResampler } from "src/audio/resampler/createResampler";

const IlbcCodec = require("./es5/ilbcCodec");

const log = Logger.getLogger("IlbcEncoder");
const ILBC_ENCODED_FRAMES = 38;

export class IlbcEncoder implements Encoder {
  private readonly downSampler: Resampler;
  private readonly encodedRecordBuffer: Float32Array;
  private readonly ilbcEncoderInst: typeof IlbcCodec;
  private readonly inputHeap: Float32Array;
  private readonly outputHeap: Uint8Array;
  public constructor() {
    log.debug("Creating iLBC encoder");
    const size = IlbcCodec.ccall("getEncStructSize", "number", [], []);
    this.ilbcEncoderInst = IlbcCodec._malloc(size);
    const mode = 20;
    const inputLength = 160;
    const inputPtr = IlbcCodec._malloc(inputLength * 4);
    this.inputHeap = new Float32Array(
      IlbcCodec.HEAPF32.buffer,
      inputPtr,
      inputLength
    );
    const outputBytes = 38;
    const outputPtr = IlbcCodec._malloc(outputBytes);
    this.outputHeap = new Uint8Array(
      IlbcCodec.HEAPU8.buffer,
      outputPtr,
      outputBytes
    );
    this.downSampler = createResampler({ fromHz: 16000, toHz: 8000 });
    this.encodedRecordBuffer = new Float32Array(ILBC_ENCODED_FRAMES);
    IlbcCodec.ccall(
      "initEncode",
      "number",
      ["number", "number"],
      [this.ilbcEncoderInst, mode]
    );
  }
  public encode(
    buffer: Float32Array,
    callback: (audio: Uint8Array) => void
  ): void {
    // ILBC: downsample from 16 kHz to 8 kHz
    const downsampledBuffer = this.downSampler.resample(buffer);
    this.inputHeap.set(downsampledBuffer);
    IlbcCodec.ccall(
      "iLBC_encode",
      null,
      ["number", "number", "number"],
      [
        this.outputHeap.byteOffset,
        this.inputHeap.byteOffset,
        this.ilbcEncoderInst,
      ]
    );
    this.encodedRecordBuffer.set(this.outputHeap);
    // Send back encoded ilbc
    callback(new Uint8Array(this.encodedRecordBuffer));
  }
  public release(): void {
    log.debug("Releasing iLBC encoder");
    IlbcCodec._free(this.inputHeap.byteOffset);
    IlbcCodec._free(this.outputHeap.byteOffset);
    IlbcCodec._free(this.ilbcEncoderInst);
  }
}
