/* eslint-disable no-dupe-class-members */
import { gql } from "src/app/graphql";
import { Channels, CHANNELS_PER_FETCH } from "src/app/model/channels/Channels";
import { ChannelsTabSetting } from "src/app/model/channels/ChannelsTabSetting";
import { Constants } from "src/app/model/Constants";
import { Organizations } from "src/app/model/organizations/Organizations";
import { Patch } from "src/app/model/patches/Patch";
import { StringSetting } from "src/app/model/settings/StringSetting";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { action, reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { Organization } from "src/app/graphql/graphql";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { PanelData } from "src/app/types/PanelData";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { OrganizationUuid } from "src/nextgen/types/OrganizationUuid";

const log = Logger.getLogger("ChannelsPanelsData");

type Parameters = {
  customFilter: Record<ChannelUuid, boolean>;
  detailedView: boolean;
  groupFilterSetting: ChannelsTabSetting;
  organizationFilter: OrganizationUuid | null;
  organizationFilterName: null | string;
  showDescription: boolean;
  showDispatcherPresence: boolean;
  showGroupSymbol: boolean;
  showLatestSpeaker: boolean;
  showMessages: boolean;
  showOrganization: boolean;
  showPresence: boolean;
  showPttButton: boolean;
  showReplayButton: boolean;
  showVolumeControls: boolean;
};

export class ChannelsPanelData implements PanelData {
  public readonly channels: Channels;
  public readonly defaultParameters: Parameters = {
    customFilter: {},
    detailedView: true,
    groupFilterSetting: ChannelsTabSetting.Joined,
    organizationFilter: null,
    organizationFilterName: null,
    showDescription: false,
    showDispatcherPresence: true,
    showGroupSymbol: true,
    showLatestSpeaker: true,
    showMessages: true,
    showOrganization: true,
    showPresence: true,
    showPttButton: true,
    showReplayButton: true,
    showVolumeControls: true,
  };
  public readonly filterSetting: StringSetting;
  public readonly organizations: Organizations;
  private disposeChannels?: IReactionDisposer;
  private groupSelectionUnsubscriber?: () => void;
  private messageUnsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    this.id = id;
    this.channels = new Channels(state); // TODO: Possibly save each tab
    this.organizations = new Organizations(state, "group");
    this.filterSetting = new StringSetting({
      defaultValue: "",
      key: `gt2.filter.${id}`,
    });
    observableClass(this);
  }
  public get detailedView(): boolean {
    return !!this.parameters.detailedView;
  }
  public get groupFilter(): ChannelsTabSetting {
    return this.parameters.groupFilterSetting;
  }
  public get includeGroup() {
    return (channelUuid: ChannelUuid): boolean =>
      !!this.parameters.customFilter[channelUuid];
  }
  public get isShowingJoined(): boolean {
    return (
      this.groupFilter === ChannelsTabSetting.Joined ||
      this.groupFilter === ChannelsTabSetting.Mobile
    );
  }
  public get mayToggleDetailedView(): boolean {
    return this.panel.mayConfigure(["detailedView"]);
  }
  public get mayToggleShowDescription(): boolean {
    return this.panel.mayConfigure(["showDescription"]);
  }
  public get mayToggleShowDispatcherPresence(): boolean {
    return this.panel.mayConfigure([`showDispatcherPresence`]);
  }
  public get mayToggleShowGroupSymbol(): boolean {
    return this.panel.mayConfigure(["showGroupSymbol"]);
  }
  public get mayToggleShowLatestSpeaker(): boolean {
    return this.panel.mayConfigure(["showLatestSpeaker"]);
  }
  public get mayToggleShowMessages(): boolean {
    return this.panel.mayConfigure(["showMessages"]);
  }
  public get mayToggleShowOrganization(): boolean {
    return this.panel.mayConfigure(["showOrganization"]);
  }
  public get mayToggleShowPresence(): boolean {
    return this.panel.mayConfigure(["showPresence"]);
  }
  public get mayToggleShowPttButton(): boolean {
    return this.panel.mayConfigure(["showPttButton"]);
  }
  public get mayToggleShowReplayButton(): boolean {
    return this.panel.mayConfigure(["showReplayButton"]);
  }
  public get mayToggleShowVolumeControls(): boolean {
    return this.panel.mayConfigure(["showVolumeControls"]);
  }
  public get organizationFilter(): OrganizationUuid | null {
    return this.parameters.organizationFilter;
  }
  public get organizationFilterName(): null | string {
    return this.parameters.organizationFilterName;
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get showDescription(): boolean {
    return !!this.parameters.showDescription;
  }
  public get showDispatcherPresence(): boolean {
    return !!this.parameters.showDispatcherPresence;
  }
  public get showGroupSymbol(): boolean {
    return !!this.parameters.showGroupSymbol;
  }
  public get showLatestSpeaker(): boolean {
    return !!this.parameters.showLatestSpeaker;
  }
  public get showLockbutton(): boolean {
    return this.channels.hasOneChannelWithLock;
  }
  public get showMessages(): boolean {
    return !!this.parameters.showMessages;
  }
  public get showOrganization(): boolean {
    return !!this.parameters.showOrganization;
  }
  public get showPatchIcon(): boolean {
    return this.channels.hasOneChannelWithPatch;
  }
  public get showPresence(): boolean {
    return !!this.parameters.showPresence;
  }
  public get showPttButton(): boolean {
    return !!this.parameters.showPttButton;
  }
  public get showReplayButton(): boolean {
    return !!this.parameters.showReplayButton;
  }
  public get showVolumeControls(): boolean {
    return !!this.parameters.showVolumeControls;
  }
  public onClosing(performClose: () => void): void {
    const numberStarredChannels = Object.keys(
      this.parameters.customFilter
    ).length;
    if (numberStarredChannels > 0) {
      this.state.dialogs.show({
        actions: [
          { label: "Cancel", onSelect: () => {} },
          {
            label: "Close panel",
            onSelect: () => {
              performClose();
            },
          },
        ],
        forceRespond: true,
        text: `This panel have ${numberStarredChannels} favorited channel${
          numberStarredChannels > 1 ? "s" : ""
        } which will be lost. Continue?`,
        title: "Confirm close panel",
      });
    } else {
      performClose();
    }
  }
  public onCreate(): void {
    this.disposeChannels = reaction(
      () => ({
        groupIds: this.customList,
        name: this.filterSetting.value ? `%${this.filterSetting.value}%` : null,
        organizationIds: this.organizationFilter
          ? [this.organizationFilter]
          : null,
        selection:
          this.groupFilter === ChannelsTabSetting.Joined ||
          this.groupFilter === ChannelsTabSetting.Mobile
            ? {
                profile: Constants.PROFILE,
                selected: true,
              }
            : null,
      }),
      (filter) => {
        this.channels.setFilter(filter);
      },

      { fireImmediately: true }
    );
    this.subscribeToNewMessages();
    this.subscribeToGroupSelection();
    void this.organizations.fetchOrganizations();
  }
  public onDelete(): void {
    if (this.disposeChannels) {
      this.disposeChannels();
      this.disposeChannels = undefined;
    }
    this.messageUnsubscriber?.();
    this.messageUnsubscriber = undefined;
    this.groupSelectionUnsubscriber?.();
    this.groupSelectionUnsubscriber = undefined;
  }
  public setGroupFilter(id: ChannelsTabSetting): void {
    if (id !== this.groupFilter) {
      this.filterSetting.setValue("");
      this.filterSetting.validate();
    }
    this.panel.setData(["groupFilterSetting"], id);
  }
  public setOrganization(organization: Organization | null): void {
    this.panel.setData(
      ["organizationFilter"],
      organization ? organization.id : null
    );
    this.panel.setData(
      ["organizationFilterName"],
      organization ? organization.name : null
    );
  }
  public toggleDetailedView(): void {
    this.panel.setData(["detailedView"], !this.detailedView);
  }
  public toggleIncludeGroup(channelId: ChannelUuid): void {
    this.panel.setData(
      ["customFilter", channelId],
      !this.includeGroup(channelId)
    );
  }
  public toggleShowDescription(): void {
    this.panel.setData(["showDescription"], !this.showDescription);
  }
  public toggleShowDispatcherPresence(): void {
    return this.panel.setData(
      [`showDispatcherPresence`],
      !this.showDispatcherPresence
    );
  }
  public toggleShowGroupSymbol(): void {
    this.panel.setData(["showGroupSymbol"], !this.showGroupSymbol);
  }
  public toggleShowLatestSpeaker(): void {
    this.panel.setData(["showLatestSpeaker"], !this.showLatestSpeaker);
  }
  public toggleShowMessages(): void {
    this.panel.setData(["showMessages"], !this.showMessages);
  }
  public toggleShowOrganization(): void {
    this.panel.setData(["showOrganization"], !this.showOrganization);
  }
  public toggleShowPresence(): void {
    this.panel.setData(["showPresence"], !this.showPresence);
  }
  public toggleShowPttButton(): void {
    this.panel.setData(["showPttButton"], !this.showPttButton);
  }
  public toggleShowReplayButton(): void {
    this.panel.setData(["showReplayButton"], !this.showReplayButton);
  }
  public toggleShowVolumeControls(): void {
    this.panel.setData(["showVolumeControls"], !this.showVolumeControls);
  }
  private get customList(): ChannelUuid[] | null {
    switch (this.groupFilter) {
      case ChannelsTabSetting.Favorites:
        return Object.keys(this.parameters.customFilter);
      default:
        return null;
    }
  }
  private subscribeToGroupSelection(): void {
    this.groupSelectionUnsubscriber =
      this.state.online?.subscriptionModule?.subscribe({
        onEvent: action((msg: any) => {
          log.debug("Group change event", msg);
          if (msg.type === "ClientPTTSelectionChange") {
            log.debug("Group selection changed", msg.payload);
            const updatedSelections = msg.payload
              .updatedSelections as ChannelUuid[];
            if (updatedSelections) {
              const filteredChannels = updatedSelections.filter((uuid) =>
                this.channels.channelWithUuid(uuid)
              );
              if (
                filteredChannels.length > CHANNELS_PER_FETCH ||
                this.groupFilter === ChannelsTabSetting.Joined ||
                this.groupFilter === ChannelsTabSetting.Mobile
              ) {
                this.channels.refresh();
              } else if (filteredChannels.length > 0) {
                void this.channels.queryUpdateSelectionData({
                  groupIds: filteredChannels,
                });
              }
            }
          } else if (msg.type === "GroupLockStateChange") {
            log.debug("Group lock changed", msg.payload);
            const { groupId, lock } = msg.payload;
            const channel = this.channels.channelWithUuid(groupId);
            if (channel) {
              channel.lockStatus = lock;
            }
          } else if (msg.type === "GroupRemovedFromPatch") {
            log.debug("Group patch removed", msg.payload);
            const { groupId } = msg.payload;
            const channel = this.channels.channelWithUuid(groupId);
            if (channel) {
              channel.patch = null;
            }
          } else if (msg.type === "GroupAddedToPatch") {
            log.debug("Group patch added", msg.payload);
            const { groupId, patchId } = msg.payload;
            const channel = this.channels.channelWithUuid(groupId);
            if (channel) {
              channel.patch = new Patch(this.state, { id: patchId });
            }
          }
        }),
        onSetupSubscription: async (webSocketId) => {
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
              mutation subscribeGroupChanges(
                $groupFilter: GroupFilter!,
                $profile: String,
                $webSocketId: String!
              ) {
                subscribeGroupChanges(input: {
                  groupFilter: $groupFilter,
                  profile: $profile,
                  webSocketId: $webSocketId
                }) {
                  subscriptionId
                }
              }
            `),
            variables: {
              groupFilter: {
                allGroups: true,
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeGroupChanges.subscriptionId;
        },
        onTearDownSubscription: (subscriptionId) => {
          try {
            void this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
                mutation unsubscribeGroupChanges(
                  $subscriptionId: ID!
                ) {
                  unsubscribeGroupChanges(input: {
                    subscriptionId: $subscriptionId
                  }) {
                    error {
                      __typename
                      ... on AuthorizationError {
                          message
                      }
                    }
                  }
                }
              `),
              variables: { subscriptionId },
            });
          } catch (e) {
            log.warn(`Unable to unsubscribe from group selection: ${e}`);
          }
        },
      });
  }
  // Message subscription
  private subscribeToNewMessages(): void {
    this.messageUnsubscriber = this.state.online?.subscriptionModule?.subscribe(
      {
        onEvent: (msg: any) => {
          log.trace("Updating unread count");
          const { groupId, unreadCount } = msg.payload;
          this.channels.channelWithUuid(groupId)?.setUnreadCount(unreadCount);
        },
        onSetupSubscription: async (webSocketId) => {
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
          mutation subscribeNewMessages(
            $webSocketId: String!,
            $profile: String!,
            $channelFilter: ChannelFilter!
          ) {
            subscribeNewMessages(input: {
              webSocketId: $webSocketId,
              profile: $profile,
              channelFilter: $channelFilter
            }) {
              subscriptionId
            }
          }
        `),
            variables: {
              channelFilter: {
                allChannelMessages: true,
                allPrivateMessages: false,
                channelIds: null,
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeNewMessages.subscriptionId;
        },
        onTearDownSubscription: (subscriptionId) => {
          try {
            void this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
            mutation unsubscribeMessages(
              $subscriptionId: ID!
            ) {
              unsubscribeMessages(input: {
                subscriptionId: $subscriptionId
              }) {
                error {
                  __typename
                  ... on AuthorizationError {
                      message
                  }
                }
              }
            }
          `),
              variables: { subscriptionId },
            });
          } catch (e) {
            log.warn(`Unable to unsubscribe from messages: ${e}`);
          }
        },
      }
    );
  }
}
