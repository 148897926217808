import { GtMenu } from "src/app/gui/components/GtMenu";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import type { GtTableColumn } from "src/app/gui/components/GtTableColumn";

type TableProps<T> = {
  columns: GtTableColumn<T>[];
  /**
   * Custom data is passed into render function as second argument
   */
  customData?: any;
  /**
   * @param row
   * @returns Should return a row color for that row or undefined for default
   */
  getRowColor?: (row: any) =>
    | {
        dark: string;
        light: string;
      }
    | undefined;
  /**
   * @param row
   * @returns Should return a unique key for that row
   */
  getRowKey: (row: T) => string;
  getRowMenuItems?: (row: T) => any;
  onClickRow?: (row: T) => void;
  /**
   * Each row is passed into various function
   */
  rows: T[];
};

/**
 * @returns Create a GtTable<T> with rows of type T
 */
export const CreateGtTable = <T extends {}>(): React.ForwardRefExoticComponent<
  TableProps<T> & React.RefAttributes<any>
> => {
  const GtTable = connect<TableProps<T & { disabled?: boolean }>>(
    ({
      columns,
      customData,
      getRowColor,
      getRowKey,
      getRowMenuItems,
      onClickRow,
      rows,
    }) => {
      return (
        <Layout alignTop column>
          <Table>
            {columns.find((column) => column.header != null) && (
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} style={{ width: column.width }}>
                      <Text>{column.header}</Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows.map((row, index) => (
                <GtMenu
                  onOpen={async () => {
                    if (row.disabled) {
                      return false;
                    }
                    if (onClickRow) {
                      onClickRow(row);
                    }
                    if (!getRowMenuItems) {
                      return false;
                    }
                    return true;
                  }}
                  style={{
                    background:
                      index % 2 === 1
                        ? (getRowColor &&
                            getRowColor(row) &&
                            getRowColor(row)!.light) ||
                          undefined
                        : (getRowColor &&
                            getRowColor(row) &&
                            getRowColor(row)!.dark) ||
                          "#7789a2",
                    cursor: row.disabled ? "auto" : "pointer",
                    height: 30,
                  }}
                  base={TableRow}
                  className="gt_hoverable"
                  id={getRowKey(row)}
                  items={getRowMenuItems ? getRowMenuItems(row) : []}
                  key={getRowKey(row)}
                >
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.render(row, customData)}
                    </TableCell>
                  ))}
                </GtMenu>
              ))}
            </TableBody>
          </Table>
        </Layout>
      );
    }
  );
  return GtTable;
};
