import { ReconnectingWebsocket } from "src/nextgen/ReconnectingWebsocket";
import { Logger } from "src/util/Logger";
import { WrappedPromise } from "src/util/WrappedPromise";
import type { AuthenticationModule } from "src/nextgen/AuthenticationModule";

const log = Logger.getLogger("NotificationModule");

const PING_INTERVAL = 60000;
const PING_TIMEOUT_INTERVAL = 20000;

export class NotificationModule {
  private readonly websocket: ReconnectingWebsocket;
  public constructor(
    authenticationModule: AuthenticationModule,
    notificationWebsocketURI: string,
    profile: string
  ) {
    this.websocket = new ReconnectingWebsocket(
      authenticationModule,
      `${notificationWebsocketURI}?profile=${profile}`,
      () => {
        const handshakePromise = new WrappedPromise<void>();
        return {
          handshakePromise: handshakePromise.promise,
          handshakeTimeout: 10000,
          onMessage: (json: any): void => {
            // this should be the first connection established message containing the WebSocketId
            if (json.type !== "ConnectionEstablished") {
              handshakePromise.reject(
                new Error(
                  `Wrong type ${json.type} on initial handshake message`
                )
              );
              return;
            }
            const idleTimeout = json.data.IdleTimeout;
            if (idleTimeout === undefined || typeof idleTimeout !== "number") {
              handshakePromise.reject(
                new Error(
                  "ConnectionEstablished message didn't contain numeric IdleTimeout"
                )
              );
              return;
            }
            // Calculate keep alive interval: minimum 30s, prefer PING_INTERVAL unless
            // server given interval is shorter
            const pingInterval = Math.max(
              30000,
              Math.min(idleTimeout - PING_TIMEOUT_INTERVAL, PING_INTERVAL)
            );
            log.debug(
              `Notification websocket keep alive interval is ${pingInterval}ms (server idle timeout is ${idleTimeout}ms)`
            );

            handshakePromise.resolve();
            this.websocket.startKeepAlive({
              pingInterval,
              pingResponseTimeout: PING_TIMEOUT_INTERVAL,
            });
          },
        };
      }
    );
  }
  public closeDown(): void {
    log.debug("Closing notification websocket");
    this.websocket.closeDown();
  }
  /*
   * Subscribe to notifications.
   *
   * Returns a function that is used for unsubscribing.
   */
  public subscribe({
    onActive,
    onEvent,
    onInactive,
  }: {
    onActive: () => void;
    onEvent: (msg: any) => void;
    onInactive: () => void;
  }): () => void {
    return this.websocket.subscribe({
      onConnected: () => {
        if (onActive) {
          try {
            onActive();
          } catch (onActiveErr) {
            log.warn(
              `Error handling notification onActive event: ${onActiveErr}`
            );
          }
        }
      },
      onDisconnected: () => {
        if (onInactive) {
          try {
            onInactive();
          } catch (onInactiveErr: any) {
            log.warn(
              `Error handling notification onInactive event: ${onInactiveErr}`
            );
          }
        }
      },
      onMessage: (msg: any) => {
        if (onEvent) {
          try {
            onEvent(msg);
          } catch (onEventErr) {
            log.warn(
              `Error handling incoming notification event: ${onEventErr}`
            );
          }
        }
      },
    });
  }
}
