import { SoundService } from "src/app/model/audio/SoundService";
import { ChannelsHandler } from "src/app/model/channels/ChannelsHandler";
import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { observableClass } from "src/app/state/observableClass";
import { PresenceType } from "src/lib/types/PresenceType";
import { SessionMediaType } from "src/lib/types/SessionMediaType";
import { SessionType } from "src/lib/types/SessionType";
import { getReadableTimeFormat } from "src/util/getReadableTimeFormat";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import { now } from "mobx-utils";
import type { Panel } from "src/app/model/panels/Panel";
import type { Presence } from "src/app/model/presence/Presence";
import type { State } from "src/app/model/State";
import type { Room } from "src/app/model/video/Room";
import type { IncomingTalkburst } from "src/lib/modules/IncomingTalkburst";
import type { OutgoingTalkburst } from "src/lib/modules/OutgoingTalkburst";
import type { Session as NodeSession } from "src/lib/modules/Session";
import type { CallReferenceNodeId } from "src/lib/types/CallReferenceNodeId";
import type { ChannelNodeId } from "src/lib/types/ChannelNodeId";
import type { SessionNodeId } from "src/lib/types/SessionNodeId";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { FullDuplexUuid } from "src/nextgen/types/FullDuplexUuid";

const log = Logger.getLogger("Sessions");

const initialSolo = (state: State, type: SessionType): boolean =>
  (state.settings.privateCall.privateCallsInSolo.value &&
    type === SessionType.Call) ||
  (state.settings.privateCall.monitoringCallsInSolo.value &&
    type === SessionType.MonitoringListener);

const SHOW_LATEST_SPEAKER_IN_SECONDS = 300; // 5 minutes

export class Session {
  public readonly channelId?: ChannelNodeId;
  public readonly channelUuid?: ChannelUuid;
  public readonly fullDuplexUuid?: FullDuplexUuid;
  public readonly mediaType?: SessionMediaType;
  public readonly name: string;
  public readonly peerUserUuid?: ClientUserUuid;
  public readonly sessionId: SessionNodeId;
  public readonly stoppable: boolean;
  public readonly txDenied?: boolean;
  public readonly type: SessionType;
  public callRef?: CallReferenceNodeId | null;
  /**
   * Configured mute of this session. <code>true</code> if this session should be muted.
   * Note: <code>actualMute</code> may differ from <code>configuredMute</code> due to
   * other sessions being solo-ed.
   * @member {boolean}
   */
  public configuredMute: boolean;
  /**
   * Configured solo of this session. <code>true</code> if this session should be solo-ed.
   * Note: solo of the same session will override mute.
   * @member {boolean}
   */
  public configuredSolo: boolean;
  public incomingTalkburst: IncomingTalkburst | null = null;
  public inputLevel: null | number = null;
  public lastIncomingTalkburst: IncomingTalkburst | null = null;
  public outgoingTalkburst?: OutgoingTalkburst;
  public replaying = false;
  private readonly session: NodeSession;
  /**
   * Configured volume of this session.
   * 0 = silent, 50 = softer, 100 = no amplifcation, 125 = louder.
   * Note: <code>actualVolume</code> may differ from <code>configuredVolume</code> due to this
   * session being muted or other sessions being solo-ed.
   * @member {integer}
   */
  private configuredVolume;
  private latestSpeakerSourceId?: string;
  private latestSpeakerTime: Date | null = null;
  private latestSpeakerUserEntityId?: string;
  private pttIsDown = false;
  public constructor(private readonly state: State, session: NodeSession) {
    this.session = session;
    this.sessionId = session.sessionId;
    this.name = session.name;
    this.type = session.type;
    this.stoppable = session.stoppable;
    this.channelId = session.channelId;
    this.channelUuid = session.channelUuid;
    this.callRef = session.callRef;
    this.txDenied = session.txDenied;
    this.mediaType = session.mediaType;
    this.configuredSolo = this.channelUuid
      ? ChannelsHandler.savedChannelSolo(this.channelUuid)
      : initialSolo(state, session.type);
    this.configuredMute = this.channelUuid
      ? ChannelsHandler.savedChannelMute(this.channelUuid)
      : false;
    this.configuredVolume = this.channelUuid
      ? ChannelsHandler.savedChannelVolume(this.channelUuid)
      : 100;
    this.peerUserUuid = session.peerUserUuid;
    this.fullDuplexUuid = session.fullDuplexUuid;
    observableClass(this);
  }
  /**
   * Get actual mute of session. This may differ from <code>configuredMute</code> due
   * to this session also being solo-ed (overriding mute) or other sessions being solo-ed.
   * @returns {boolean} <code>true</code> if this session is actually being muted.
   */
  public get actualMute(): boolean {
    if (this.state.online?.broadcast.broadcasting) {
      return false;
    }
    if (this.state.online?.sessions.hasSolodSession && !this.configuredSolo) {
      return true;
    }
    return this.configuredMute && !this.configuredSolo;
  }
  public get actualSolo(): boolean {
    if (this.state.online?.broadcast.broadcasting) {
      return false;
    }
    return this.configuredSolo;
  }
  /**
   * Get actual volume of session. This may differ from <code>configuredVolume</code> due
   * to this session being muted or other sessions solo-ed.
   * @returns {integer} The actual volume used for playback:
   * 0 = silent, 50 = softer, 100 = no amplifcation, 125 = louder.
   * */
  public get actualVolume(): number {
    if (
      !this.configuredSolo &&
      (this.configuredMute || this.state.online?.sessions.hasSolodSession)
    ) {
      return 0;
    }
    return this.configuredVolume;
  }
  public get broadcasting(): boolean {
    return !!this.state.online?.broadcast.broadcasting;
  }
  public get isFocused(): boolean {
    return this.sessionId === this.state.online?.sessions.sessionFocus;
  }
  public get isFullDuplex(): boolean {
    return this.mediaType === SessionMediaType.TwilioFullDuplex;
  }
  public get latestSpeakerName(): null | string {
    const latestSpeaker = this.latestSpeakerPresence;
    return latestSpeaker ? latestSpeaker.name : null;
  }
  public get latestSpeakerPresence(): Presence | undefined {
    return this.presence.find(
      (presence) =>
        presence.sourceId === this.latestSpeakerSourceId ||
        (this.latestSpeakerUserEntityId != null &&
          presence.userEntityId === this.latestSpeakerUserEntityId)
    );
  }
  public get mayReplayTalkburst(): boolean {
    return this.talkburstToReplay !== null && !this.replaying;
  }
  public get maySelectForBroadcasting(): boolean {
    return (
      this.broadcasting && this.type === SessionType.Group && !this.txDenied
    );
  }
  public get mobilePresence(): Presence[] {
    return this.presence.filter(
      (presence) =>
        presence.type === PresenceType.Mobile ||
        presence.type === PresenceType.Dialed
    );
  }
  public get muteDisabled(): boolean {
    return !!(
      this.state.online?.sessions.hasSolodSession ||
      this.state.online?.broadcast.broadcasting
    );
  }
  public get mutedIncomingTalkburst(): boolean {
    return !!(
      this.incomingTalkburst &&
      this.state.online?.sessions.muteGroups?.selected &&
      this.incomingTalkburst.muteGroupId ===
        this.state.online?.sessions.muteGroups.selected.id
    );
  }
  public get percentActive(): number {
    if (this.latestSpeakerTime) {
      // Update every second.
      const nowDate = new Date(now(1000));
      const totalSeconds = Math.max(
        Math.floor(nowDate.getTime() / 1000) -
          this.latestSpeakerTime.getTime() / 1000,
        0
      );
      return Math.max(1 - totalSeconds / SHOW_LATEST_SPEAKER_IN_SECONDS, 0);
    }
    return 0;
  }
  public get presence(): Presence[] {
    if (!this.state.online) {
      return [];
    }
    return Object.values(this.state.online!.presence.list)
      .filter((presence) => presence.sessionId === this.sessionId)
      .sort((a, b) => {
        if (a.alarmActive && !b.alarmActive) {
          return -1;
        }
        if (!a.alarmActive && b.alarmActive) {
          return 1;
        }
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        }
        return 0;
      });
  }
  public get room(): Room | null {
    if (this.isFullDuplex) {
      if (this.peerUserUuid) {
        return (
          this.state.online?.rooms.roomWithPeerUserUuid(this.peerUserUuid) ??
          null
        );
      }
      if (this.channelUuid) {
        return (
          this.state.online?.rooms.roomWithChannelUuid(this.channelUuid) ?? null
        );
      }
    }
    return null;
  }
  public get selectedForBroadcasting(): boolean {
    return !!(
      this.maySelectForBroadcasting &&
      this.channelId &&
      this.state.online?.broadcast.broadcastGroups.contains(this.channelId)
    );
  }
  public get soloDisabled(): boolean {
    return !!this.state.online?.broadcast.broadcasting;
  }
  public get timeSinceLastActive(): null | string {
    // Update every second.
    if (this.latestSpeakerTime) {
      return getReadableTimeFormat({
        ago: true,
        fromTime: this.latestSpeakerTime,
        now: new Date(now(1000)),
        seconds: true,
      });
    }
    return null;
  }
  public broadcastPttDown(): void {
    this.state.online?.sessions.broadcastPttDown(this.sessionId);
  }
  public broadcastPttUp(): void {
    this.state.online?.sessions.broadcastPttUp(this.sessionId);
  }
  public async endCall(
    options: { dontCloseTicket?: boolean } = {}
  ): Promise<void> {
    if (this.stoppable) {
      if (!options.dontCloseTicket) {
        this.state.online?.queueManagement.closeTicketsWithCallSession(
          this.session
        );
      }
      await this.session.stop();
    }
  }
  public focus(): void {
    this.state.online?.sessions.setSessionFocus(this);
  }
  public openPresencePanel(panel: Panel): void {
    if (this.channelUuid) {
      this.state.online?.channels.openOrFocusChannelInPanel({
        id: this.channelUuid,
        name: this.name,
        panel,
        tab: ChannelTabSetting.Presence,
      });
    }
  }
  public possiblyMigrateLastTalkburst(): void {
    if (
      this.lastIncomingTalkburst?.globalTalkburstId &&
      !this.lastIncomingTalkburst.audioSuppressed
    ) {
      // Here before we overwrite lastIncomingTalkburst we migrate last incoming talkburst
      // to some other session that depends on this for repeat
      const sessionWithSuppressedProxiedTalkburst =
        this.state.online?.sessions.sessionWithLatestTalkburst(
          this.lastIncomingTalkburst.globalTalkburstId,
          true
        );
      if (sessionWithSuppressedProxiedTalkburst) {
        sessionWithSuppressedProxiedTalkburst.lastIncomingTalkburst =
          this.lastIncomingTalkburst;
      }
    }
  }
  public async pttDown(broadcast = false): Promise<void> {
    if (this.room) {
      this.room.unmuteRoom();
      return;
    }
    let recordDelay = this.state.settings.soundInput.latencyCompensation.value;
    if (this.pttIsDown) {
      return;
    }
    this.pttIsDown = true;
    if (!broadcast) {
      this.focus();
      if (this.state.settings.notificationSounds.startSounds.value) {
        SoundService.playSound("send-start");
        recordDelay += 275;
      }
    }
    const mutegroup = this.state.online?.sessions.muteGroups?.selected;
    try {
      const outgoingTalkburst =
        await this.state.online?.audio.startTransmission({
          delay: recordDelay,
          muteGroupId: mutegroup ? (mutegroup.id as string) : undefined,
          onInterrupt: action(() => {
            SoundService.playSound("ptt-error");
            this.outgoingTalkburst = undefined;
          }),
          onStopped: action(() => {
            this.outgoingTalkburst = undefined;
          }),
          sessionId: this.sessionId,
        });
      if (outgoingTalkburst) {
        this.outgoingTalkburst = outgoingTalkburst;
        this.outgoingTalkburst.onRecordedLevel(
          action((inputLevel) => {
            this.inputLevel = inputLevel;
          })
        );
        if (!this.pttIsDown) {
          this.pttUp();
        }
      } else if (!broadcast) {
        SoundService.playSound("ptt-error");
      }
    } catch (error: any) {
      if (!broadcast) {
        log.error(error);
        SoundService.playSound("ptt-error");
      }
      this.outgoingTalkburst = undefined;
    }
  }
  public pttUp(broadcast = false): void {
    if (this.room) {
      this.room.muteRoom();
      return;
    }
    if (this.outgoingTalkburst) {
      if (
        !broadcast &&
        this.state.settings.notificationSounds.stopSounds.value
      ) {
        SoundService.playSound("send-stop");
      }
      void this.outgoingTalkburst.stop();
      this.outgoingTalkburst = undefined;
    }
    this.pttIsDown = false;
  }
  public pttWithPossibleBroadcastDown(): void {
    if (this.selectedForBroadcasting) {
      this.broadcastPttDown();
    } else if (this.broadcasting && !this.selectedForBroadcasting) {
      SoundService.playSound("ptt-error");
      this.state.flashMessage.info({
        message: `${this.name} not selected for broadcasting.`,
      });
    } else {
      void this.pttDown();
    }
  }
  public pttWithPossibleBroadcastUp(): void {
    if (this.selectedForBroadcasting) {
      this.broadcastPttUp();
    } else {
      this.pttUp();
    }
  }
  public replayTalkburst(): void {
    if (this.mayReplayTalkburst && this.state.online) {
      this.replaying = true;
      this.state.online.sessions.lastActiveSession = this;
      this.state.online.audio.replayTalkburst({
        onFinished: action(() => {
          this.replaying = false;
        }),
        talkburst: this.talkburstToReplay!,
      });
    }
  }
  public setLastIncomingTalkburst(talkburst: IncomingTalkburst): void {
    this.incomingTalkburst = talkburst;
    this.possiblyMigrateLastTalkburst();
    this.lastIncomingTalkburst = talkburst;
    const presence = Object.values(this.state.online?.presence.list ?? []).find(
      (p) => p.sourceId === talkburst.sourceId
    );
    if (presence) {
      this.latestSpeakerSourceId = presence.sourceId;
      this.latestSpeakerUserEntityId = presence.userEntityId;
      this.latestSpeakerTime = new Date();
    }
  }
  /**
   * Set whether this session should be muted.
   * @param {boolean} mute
   */
  public setMuted(mute: boolean): void {
    this.configuredMute = mute;
    if (this.channelUuid !== undefined) {
      ChannelsHandler.setSavedChannelMute(this.channelUuid, mute);
    }
  }
  /**
   * Set whether this session should be solod. If at least one active session is solo-ed, sessions
   * which are not solo-ed will be muted.
   * @param {boolean} solo
   */
  public setSolo(solo: boolean): void {
    this.configuredSolo = solo;
    if (this.channelUuid !== undefined) {
      ChannelsHandler.setSavedChannelSolo(this.channelUuid, solo);
    }
  }
  /**
   * Set volume for this session
   * @param {integer} volume 0 = silent, 50 = softer, 100 = no amplifcation, 125 = louder
   */
  public setVolume(volume: number): void {
    this.configuredVolume = volume;
    if (this.channelUuid !== undefined) {
      ChannelsHandler.setSavedChannelVolume(this.channelUuid, volume);
    }
  }
  public toggleSelectForBroadcast(): void {
    if (this.channelId) {
      if (this.selectedForBroadcasting) {
        this.state.online?.broadcast.broadcastGroups.remove(this.channelId);
      } else {
        this.state.online?.broadcast.broadcastGroups.add(this.channelId);
      }
    }
  }
  private get talkburstToReplay(): IncomingTalkburst | null {
    if (this.lastIncomingTalkburst?.globalTalkburstId) {
      if (!this.lastIncomingTalkburst.audioSuppressed) {
        return this.lastIncomingTalkburst;
      }
      const session = this.state.online?.sessions.sessionWithLatestTalkburst(
        this.lastIncomingTalkburst.globalTalkburstId,
        false
      );
      if (session) {
        return session.lastIncomingTalkburst;
      }
    }
    return null;
  }
}
