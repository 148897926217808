import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";

export const Head = connect((_, state) => {
  const { title, version } = state;
  return (
    <Layout alignCenter column marginBottom={30}>
      <Text color="#b1b9c3" size={30} weight={200}>
        {title}
      </Text>
      <Text color="#a5b4c7" size={20} weight={200}>
        {version}
      </Text>
    </Layout>
  );
});
