import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * No microphone found.
 */
export class NoMicrophoneFound extends GroupTalkAPIError {
  public constructor() {
    super(
      "No microphone found. Make sure you have a plugged in recording device.",
      false
    );
  }
}
