import { BackgroundTimer } from "src/util/BackgroundTimer";
import { WrappedPromise } from "src/util/WrappedPromise";

export class BackingOffTimer<T> {
  private readonly delays = [1000, 2000, 4000, 8000, 16000, 32000];
  private cancelTimeout?: () => void;
  private numFailures = 0;
  private pending?: WrappedPromise<T>;
  /**
   * Create a BackingOffTimer with a taskfactory, taking no arguments and returning a promise.
   */
  public constructor(private readonly taskFactory: () => Promise<T>) {}
  /**
   * Calling cancel will cause a pending, unresolved promise be rejected immediately.
   * If no promise is pending nothing happens.
   * It is allowed to call start() again after calling cancel().
   */
  public cancel(): void {
    this.cancelTimeout?.();
    if (this.pending) {
      this.pending.reject(new Error("Cancelled"));
      this.pending = undefined;
    }
  }
  /**
   * Start the task that delivers a promise. The taskFactory will be called (possibly after a delay,
   * depending on how many times it has failed before) to create a promise. The start method will
   * return a promise that is resolved when the created promise is resolved and rejected if the
   * promise is rejected.
   * Calling start() again before the last call was resolved will result in a return rejected
   * promise.
   */
  public start(): Promise<T> {
    if (this.pending) {
      return Promise.reject(new Error("Already running"));
    }

    const promise = new WrappedPromise<T>();
    this.pending = promise;
    const doTask = async (): Promise<void> => {
      try {
        const res = await this.taskFactory();
        // We need to make sure that the promise was not cancelled and some other promise is pending
        if (this.pending === promise) {
          // Reset number of consecutive failures
          this.numFailures = 0;
          this.pending = undefined;
          promise.resolve(res);
        }
      } catch (err) {
        if (this.pending === promise) {
          this.numFailures += 1;
          this.pending = undefined;
          promise.reject(err);
        }
      }
    };
    if (this.numFailures > 0) {
      const cap =
        this.numFailures > this.delays.length
          ? this.delays.length
          : this.numFailures;
      this.cancelTimeout = BackgroundTimer.setTimeout(() => {
        void doTask();
      }, this.delays[cap - 1]);
    } else {
      void doTask();
    }
    return this.pending.promise;
  }
}
