import { defaultPanelLayout } from "src/app/model/panels/defaultPanelLayout";
import { Panel } from "src/app/model/panels/Panel";
import { templates } from "src/app/model/panels/templates";
import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { CustomData } from "src/app/types/CustomData";
import type { PanelConfig } from "src/app/types/PanelConfig";
import type { TabsetId } from "src/app/types/TabsetId";
import type { Template } from "src/app/types/Template";
import type { TemplateId } from "src/app/types/TemplateId";

const PANELS = "gt2.panels";

const readPanels = (state: State): Record<ComponentId, Panel> => {
  let panelData = localStorage.getItem(PANELS) as Record<
    ComponentId,
    PanelConfig
  > | null;
  if (panelData == null) {
    panelData = { ...defaultPanelLayout.panels };
  }
  return Object.entries(panelData).reduce(
    (ack, [id, panel]: [string, PanelConfig]) => ({
      ...ack,
      [id]: new Panel(state, { ...panel, id }),
    }),
    {}
  );
};

export class Panels {
  public list: Record<ComponentId, Panel>;
  public constructor(private readonly state: State) {
    this.list = readPanels(state);
    observableClass(this);
  }
  public static get visibleTemplates(): Template[] {
    return Object.values(templates).filter((template) => template.showInMenu);
  }
  public get customizable() {
    return (id: ComponentId): boolean => !!this.list[id].template.customizable;
  }
  public get isCustomizing() {
    return (id: ComponentId): boolean =>
      !!(this.list[id] && this.list[id].customizing);
  }
  public add(template: {
    customData?: Record<string, CustomData>;
    name?: string;
    panel?: Panel;
    panelId?: TabsetId;
    templateId: TemplateId;
  }): Panel {
    const id = this.nextId;
    this.list[id] = new Panel(this.state, {
      customData: template.customData,
      id,
      name: template.name,
      templateId: template.templateId,
    });
    this.state.layout.createPanel(
      id,
      template.name!,
      template.panel,
      template.panelId ?? null
    );
    this.save();
    return this.list[id];
  }
  public createPatch(): void {
    void this.state.online?.patches.createPatch();
  }
  public customize(id: ComponentId, panelId: TabsetId): void {
    this.list[id].toggleCustomize(panelId);
  }
  public find(predicate: (panel: Panel) => boolean): Panel | undefined {
    return Object.values(this.list).find(predicate);
  }
  public forEach(
    predicate: (panel: Panel) => boolean,
    doSomething: (panel: Panel) => void
  ): void {
    Object.values(this.list).filter(predicate).forEach(doSomething);
  }
  public onClosing(id: ComponentId, performClose: () => void): void {
    if (this.list[id]) {
      this.list[id].onClosing(performClose);
    } else {
      performClose();
    }
  }
  public onCreate(id: ComponentId): void {
    if (this.list[id]) {
      this.list[id].onCreate();
    }
  }
  public onDelete(id: ComponentId): void {
    if (this.list[id]) {
      this.list[id].onDelete();
      this.list[id].onDestroy();
      delete this.list[id];
      this.save();
    }
  }
  public onGone(): void {
    // Just notify panels that we are no longer visible, but no real change here
    Object.values(this.list)
      .filter((panel) => panel.onDelete)
      .forEach((panel) => panel.onDelete());
  }
  public reset(): void {
    localStorage.removeItem(PANELS);
    this.list = readPanels(this.state);
    this.state.online?.patches.updatePanels();
    this.state.online?.queueManagement.updateTicketPanels();
    this.state.online?.sessions.openOrFocusCallSessions();
  }
  public save(): void {
    localStorage.setItem(PANELS, this.savedData);
  }
  public updateTabColor(id: ComponentId): void {
    if (this.list[id]) {
      this.list[id].updateTabColor();
    }
  }
  private get nextId(): string {
    return (
      Math.max(...Object.keys(this.list).map((v) => parseInt(v, 10)), -1) + 1
    ).toString();
  }
  private get savedData(): Record<
    ComponentId,
    {
      customData: Record<string, CustomData>;
      templateId: TemplateId;
    }
  > {
    return Object.entries(this.list).reduce(
      (ack, [id, panel]) => ({
        ...ack,
        [id]: panel.savedData,
      }),
      {}
    );
  }
}
