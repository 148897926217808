import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { ListSetting } from "src/app/gui/settings/ListSetting";
import { NumberSetting } from "src/app/gui/settings/NumberSetting";
import { connect } from "src/app/state/connect";

export const CodecSettings = connect((_, state) => {
  const { opus } = state.settings.codecSettings;
  const br = opus.bitrate.value;
  const maxBR = opus.maxFrameSize.value * 8 * 50;
  const targetBitrate = br < maxBR ? br : maxBR;
  const overheadBitrate = 41 * 8 * 50;
  return (
    <Layout column width={600}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          Opus Codec Settings
        </Text>
      </Layout>
      <ListSetting
        id="bandwidth"
        label="Bandwidth"
        none={false}
        setting={opus.bandwidth}
      />
      <NumberSetting
        label="Target codec bitrate, 500-512000 (bit/s)"
        setting={opus.bitrate}
      />
      <NumberSetting
        label="Max frame size (bytes)"
        setting={opus.maxFrameSize}
      />
      <Layout marginBottom={16} marginTop={10}>
        <Text color="#080808" weight={300}>
          Network bandwidth used with this setting would be
          {opus.variableBitrate.value ? "around" : "fixed at"}
          &nbsp;
          {((targetBitrate + overheadBitrate) / 1000.0).toFixed(1)}
          kbit/s
        </Text>
      </Layout>
      <BooleanSetting label="Variable bitrate" setting={opus.variableBitrate} />
      <BooleanSetting
        label="Forward Error Correction (FEC)"
        setting={opus.forwardErrorCorrection}
      />
      <NumberSetting
        label="Expected packet loss (percent)"
        setting={opus.expectedPacketLoss}
      />
    </Layout>
  );
});
