import { ChannelPanelData } from "src/app/model/channels/ChannelPanelData";
import { ChannelsPanelData } from "src/app/model/channels/ChannelsPanelData";
import { ContactPanelData } from "src/app/model/contacts/ContactPanelData";
import { ContactsPanelData } from "src/app/model/contacts/ContactsPanelData";
import { LocationPanelData } from "src/app/model/location/LocationPanelData";
import { templates } from "src/app/model/panels/templates";
import { PatchPanelData } from "src/app/model/patches/PatchPanelData";
import { QueuePanelData } from "src/app/model/queues/QueuePanelData";
import { TicketPanelData } from "src/app/model/ticket/TicketPanelData";
import { observableClass } from "src/app/state/observableClass";
import { setDataWithDefault } from "src/app/state/setDataWithDefault";
import { PanelType } from "src/app/types/PanelType";
import { objectDeepMerge } from "src/util/objectDeepMerge";
import * as FlexLayout from "flexlayout-react";
import { action, reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { CustomData } from "src/app/types/CustomData";
import type { PanelConfig } from "src/app/types/PanelConfig";
import type { PanelData } from "src/app/types/PanelData";
import type { TabColor } from "src/app/types/TabColor";
import type { TabsetId } from "src/app/types/TabsetId";
import type { Template } from "src/app/types/Template";
import type { TemplateId } from "src/app/types/TemplateId";

const createHandler = (
  state: State,
  panel: PanelConfig & { id: ComponentId }
): PanelData | null => {
  if (!templates[panel.templateId]) {
    return null;
  }
  switch (templates[panel.templateId].type) {
    case PanelType.ticket:
    case PanelType.priorityTicket:
    case PanelType.emergencyTicket:
      return new TicketPanelData(state, panel.id);
    case PanelType.tickets:
      return new QueuePanelData(state, panel.id);
    case PanelType.contacts:
      return new ContactsPanelData(state, panel.id);
    case PanelType.contact:
      return new ContactPanelData(state, panel.id);
    case PanelType.location:
      return new LocationPanelData(state, panel.id);
    case PanelType.channels:
      return new ChannelsPanelData(state, panel.id);
    case PanelType.channel:
      return new ChannelPanelData(state, panel.id);
    case PanelType.patch:
      return new PatchPanelData(state, panel.id);
    default:
      return null;
  }
};

export class Panel {
  public readonly id: ComponentId;
  public readonly templateId: TemplateId;
  public customizing = false;
  public highlighted = false;
  public opened = false;
  public tab = 0;
  private readonly customHandler: PanelData | null;
  private colorReaction?: IReactionDisposer;
  private created = false;
  private customData?: Record<string, CustomData>;
  public constructor(
    private readonly state: State,
    panel: PanelConfig & { id: ComponentId }
  ) {
    this.id = panel.id;
    this.templateId = panel.templateId;
    this.customData = panel.customData;
    this.customizing = false;
    this.created = false;
    this.opened = false;
    this.highlighted = false;
    // Create the custom handler here, as we need it to live with same lifecycle as the panel.
    // We do not allow changing of type.
    this.customHandler = createHandler(state, panel);
    this.colorReaction = this.setupColorReaction();
    observableClass(this);
  }
  public get badgeNumber(): number | undefined {
    return this.customHandler ? this.customHandler.badgeNumber : 0;
  }
  public get channelPanelData(): ChannelPanelData | null {
    return this.customHandler as ChannelPanelData | null;
  }
  public get channelsPanelData(): ChannelsPanelData | null {
    return this.customHandler as ChannelsPanelData | null;
  }
  public get contactPanelData(): ContactPanelData | null {
    return this.customHandler as ContactPanelData | null;
  }
  public get contactsPanelData(): ContactsPanelData | null {
    return this.customHandler as ContactsPanelData | null;
  }
  public get flexRect(): FlexLayout.Rect | undefined {
    return this.state.layout.rectOfPanel(this.id);
  }
  public get isChannelPanel(): boolean {
    return this.customHandler instanceof ChannelPanelData;
  }
  public get isContactPanel(): boolean {
    return this.customHandler instanceof ContactPanelData;
  }
  public get isCustom(): boolean {
    return this.customData != null && Object.keys(this.customData).length > 0;
  }
  public get isTicketPanel(): boolean {
    return this.customHandler instanceof TicketPanelData;
  }
  public get locationPanelData(): LocationPanelData | null {
    return this.customHandler as LocationPanelData | null;
  }
  public get mayConfigure() {
    return (path: string[]): boolean => {
      let obj = this.template.configurable;
      path.find((p) => {
        if (typeof obj === "object") {
          obj = obj[p];
          return false;
        }
        return true;
      });
      return obj !== false;
    };
  }
  public get parameters(): Record<string, CustomData> {
    return objectDeepMerge(this.default, this.customData);
  }
  public get patchPanelData(): PatchPanelData | null {
    return this.customHandler as PatchPanelData | null;
  }
  public get queuePanelData(): QueuePanelData | null {
    return this.customHandler as QueuePanelData | null;
  }
  public get savedData(): {
    customData?: Record<string, CustomData>;
    templateId: TemplateId;
  } {
    const data: {
      customData?: Record<string, CustomData>;
      templateId: TemplateId;
    } = {
      templateId: this.templateId,
    };
    if (this.isCustom) {
      data.customData = this.customData;
    }
    return data;
  }
  public get tabColor(): TabColor | undefined {
    return this.customHandler?.tabColor;
  }
  public get template(): Template {
    return (
      templates[this.templateId] || {
        name: "Unknown",
        templateId: this.templateId,
        type: "unknown",
      }
    );
  }
  public get templateData(): Record<string, CustomData> | undefined {
    return this.template.customData;
  }
  public get ticketPanelData(): TicketPanelData | null {
    return this.customHandler as TicketPanelData | null;
  }
  public get type(): PanelType {
    return this.template.type;
  }
  public get visible(): boolean {
    return this.state.layout.visible(this.id);
  }
  public flash(): void {
    if (!this.highlighted) {
      this.highlighted = true;
      setTimeout(
        action(() => {
          this.highlighted = false;
        }),
        400
      );
    }
  }
  public onClosing(performClose: () => void): void {
    if (this.created) {
      if (this.customHandler && this.customHandler.onClosing) {
        this.customHandler.onClosing(performClose);
      } else {
        performClose();
      }
    }
  }
  public onCreate(): void {
    if (!this.created) {
      this.created = true;
      this.updateTabColor();
      if (this.customHandler && this.customHandler.onCreate) {
        this.customHandler.onCreate();
      }
    }
  }
  public onDelete(): void {
    if (this.created) {
      this.created = false;
      if (this.customHandler && this.customHandler.onDelete) {
        this.customHandler.onDelete();
      }
    }
  }
  public onDestroy(): void {
    // onDelete is called when the panel is removed from the screen
    // onDestroy is called when the panel is removed from memory
    this.colorReaction?.();
    this.colorReaction = undefined;
  }
  public reset(): void {
    this.customData = undefined;
    // propagate the reset to the customHandler behind the panel
    if (this.customHandler?.reset !== undefined) {
      this.customHandler.reset();
    }
    this.state.panels.save();
  }
  public select(): void {
    this.state.layout.selectPanel(this.id);
  }
  public setData(path: string[], newValue: any): void {
    if (
      setDataWithDefault({
        baseObject: this,
        defaultObject: { customData: this.default },
        newValue,
        path: ["customData", ...path],
      })
    ) {
      this.state.panels.save();
    }
  }
  public setName(name: string): void {
    this.state.layout.updateName(this.id, name);
  }
  public setTab(newValue: number): void {
    this.tab = newValue;
  }
  public toggleCustomize(panelId: TabsetId): void {
    this.customizing = !this.customizing;

    // preCustomize() makes it possible to fetch data needed when showing
    // the customizing screen
    if (
      this.customizing &&
      this.customHandler &&
      typeof this.customHandler.preCustomize === "function"
    ) {
      this.customHandler.preCustomize();
    }

    // postCustomize() makes it possible to clean up data after leaving the customizing screen
    if (
      !this.customizing &&
      this.customHandler &&
      typeof this.customHandler.postCustomize === "function"
    ) {
      this.customHandler.postCustomize();
    }

    const { flexLayoutModel, maximizeToggle } = this.state.layout;
    if (this.customizing !== !!flexLayoutModel.getMaximizedTabset()) {
      maximizeToggle(panelId);
    }
    flexLayoutModel.doAction(FlexLayout.Actions.updateModelAttributes({}));
  }
  public updateTabColor(): void {
    const elements = document.querySelectorAll(`.gt-panel-${this.id}`);
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i] as HTMLElement;
      element.style.background = this.tabColor?.background ?? "";
      element.style.color = this.tabColor?.textColor ?? "";
    }
  }
  private get default(): Record<string, CustomData> {
    if (this.customHandler) {
      return objectDeepMerge(
        this.customHandler.defaultParameters,
        this.templateData
      );
    }
    return { ...this.templateData };
  }
  private setupColorReaction(): IReactionDisposer {
    return reaction(
      () => ({
        tabColor: this.tabColor,
      }),
      () => {
        this.updateTabColor();
      },
      { fireImmediately: true }
    );
  }
}
