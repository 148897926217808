import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { HEADER_HEIGHT } from "src/app/gui/header/Header";
import { NO_MICROPHONE_HEIGHT } from "src/app/gui/header/NoMicrophone";
import { CodecSettings } from "src/app/gui/settings/CodecSettings";
import { MapSettings } from "src/app/gui/settings/MapSettings";
import { MuteGroups } from "src/app/gui/settings/MuteGroups";
import { NotificationSounds } from "src/app/gui/settings/NotificationSounds";
import { PrivateCall } from "src/app/gui/settings/PrivateCall";
import { QueueSettings } from "src/app/gui/settings/QueueSettings";
import { ResetSettings } from "src/app/gui/settings/ResetSettings";
import { SoundInput } from "src/app/gui/settings/SoundInput";
import { USBSettings } from "src/app/gui/settings/USBSettings";
import {
  QueueIcon,
  LocationIcon,
  CancelIcon,
  MuteGroupIcon,
  MicIcon,
  CallIcon,
  IncomingIcon,
  UsbIcon,
  ResetIcon,
  EqualizerIcon,
} from "src/app/images";
import { Usb } from "src/app/model/input/Usb";
import { Page } from "src/app/model/settings/Page";
import { connect } from "src/app/state/connect";
import { Button } from "@mui/material";

export const Settings = connect((_, state) => {
  const { hide, page, setPage } = state.settings;
  const settingPages: Partial<
    Record<
      Page,
      {
        icon: JSX.Element;
        label: string;
        page: JSX.Element;
      }
    >
  > = {
    [Page.CodecSettings]: {
      icon: <EqualizerIcon />,
      label: "Codec Settings",
      page: <CodecSettings />,
    },
    [Page.Map]: { icon: <LocationIcon />, label: "Map", page: <MapSettings /> },
    [Page.MuteGroups]: {
      icon: <MuteGroupIcon />,
      label: "Mute Groups",
      page: <MuteGroups />,
    },
    [Page.NotificationSounds]: {
      icon: <IncomingIcon />,
      label: "Notifications",
      page: <NotificationSounds />,
    },
    [Page.PrivateCalls]: {
      icon: <CallIcon />,
      label: "Private Calls",
      page: <PrivateCall />,
    },
    [Page.Queues]: {
      icon: (
        <QueueIcon style={{ fill: page === Page.Queues ? "#fff" : "#aaa" }} />
      ),
      label: "Queues",
      page: <QueueSettings />,
    },
    [Page.Reset]: {
      icon: <ResetIcon />,
      label: "Reset",
      page: <ResetSettings />,
    },
    [Page.SoundInput]: {
      icon: <MicIcon />,
      label: "Sound Input",
      page: <SoundInput />,
    },
    [Page.UsbButtons]: {
      icon: <UsbIcon />,
      label: "USB buttons",
      page: <USBSettings />,
    },
  };
  const { showNoMicrophoneInput } = state.online!.audio;
  const showMuteGroups =
    (state.online?.sessions.muteGroups?.list.length ?? 0) > 0;
  const showCodecSettings = !!window.gtConfig.showCodecSettings;
  const showUSBSettings = Usb.hidSupported;
  if (!showMuteGroups) {
    delete settingPages[Page.MuteGroups];
  }
  if (!showCodecSettings) {
    delete settingPages[Page.CodecSettings];
  }
  if (!showUSBSettings) {
    delete settingPages[Page.UsbButtons];
  }
  return (
    <Layout
      top={
        showNoMicrophoneInput
          ? NO_MICROPHONE_HEIGHT + HEADER_HEIGHT
          : HEADER_HEIGHT
      }
      absolute
      bottom={0}
      column
      left={0}
      right={0}
    >
      <Layout grow row style={{ overflow: "hidden" }}>
        <Layout
          alignRight
          background="#1d222a52"
          column
          paddingLeft={60}
          paddingRight={40}
          paddingTop={40}
          width={200}
        >
          <Layout borderBox marginBottom={10} paddingLeft={8} width={200}>
            <Text color="#a6a6a6" size={13} weight={400}>
              User settings
            </Text>
          </Layout>
          {Object.entries(settingPages).map(([key, value]) => (
            <Button
              style={{
                color: key === page ? "#fff" : "#aaa",
                justifyContent: "flex-start",
                paddingLeft: 8,
                textTransform: "none",
                width: 200,
              }}
              key={key}
              onClick={() => setPage(key as Page)}
            >
              {value.icon}
              <Layout padding={5}>{value.label}</Layout>
            </Button>
          ))}
        </Layout>
        <Layout column grow={4} padding={50}>
          <Layout
            className="custom-scroll"
            column
            style={{ overflowY: "scroll" }}
          >
            {settingPages[page]?.page ?? false}
          </Layout>
        </Layout>
        <Layout absolute column right={80} top={80} width={50}>
          <Button onClick={() => hide()}>
            <Layout alignCenter column>
              <CancelIcon />
              <Text>Close</Text>
            </Layout>
          </Button>
        </Layout>
      </Layout>
    </Layout>
  );
});
