import { ButtonMapper } from "src/app/model/input/ButtonMapper";
import { Usb } from "src/app/model/input/Usb";
import { observableClass } from "src/app/state/observableClass";
import type { State } from "src/app/model/State";

export class Input {
  public readonly buttonMapper: ButtonMapper;
  public readonly usb: Usb;
  public constructor(state: State) {
    this.buttonMapper = new ButtonMapper(state);
    this.usb = new Usb(state);
    observableClass(this);
  }
  public closeDown(): void {
    void this.usb.closeDown();
  }
  public init(): void {
    this.usb.init();
  }
}
