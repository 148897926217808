import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import { Fragment } from "react";
import type { Message } from "src/app/model/messages/Message";

const firstUnreadDivider = (
  <Layout
    style={{
      boxShadow: "rgb(155 0 0 / 11%) 0px 0px 5px 1px",
    }}
    background="rgb(132 101 101 / 44%)"
    height={1}
    key="first_unread"
    margin={10}
  />
);

export const MessageGroup = connect<{
  messageGroup: Message[];
}>(({ messageGroup }) => (
  <Layout column>
    {messageGroup[0].isFirstUnreadMessage && firstUnreadDivider}
    <Layout
      alignTop
      className="gt_message"
      gap={12}
      paddingBottom={3}
      paddingLeft={10}
      paddingRight={5}
      paddingTop={3}
      row
    >
      <Layout column>
        <Layout alignMiddle gap={0} row>
          <Text className="gt_message_timestamp" style={{ width: 40 }}>
            {messageGroup[0].readableTime}
          </Text>
          <Text bold size={12}>
            {messageGroup[0].name}
          </Text>
        </Layout>
        <Layout className="message" marginLeft={40}>
          <Text style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {messageGroup[0].content}
          </Text>
        </Layout>
      </Layout>
    </Layout>
    {messageGroup.slice(1).map((message) => (
      <Fragment key={message.id}>
        {message.isFirstUnreadMessage && firstUnreadDivider}
        <Layout
          className="gt_message"
          gap={0}
          paddingBottom={3}
          paddingLeft={10}
          paddingTop={3}
          row
        >
          <Layout
            className="gt_message_timestamp_hidden"
            minWidth={40}
            paddingTop={3}
            row
          >
            <Text className="gt_message_timestamp">{message.readableTime}</Text>
          </Layout>
          <Text style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {message.content}
          </Text>
        </Layout>
      </Fragment>
    ))}
  </Layout>
));
