import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { Location } from "src/lib/types/Location";
import type { LocationAccuracy } from "src/lib/types/LocationAccuracy";
import type { LocationReportHints } from "src/lib/types/LocationReportHints";
import type { LocationUpdateHints } from "src/lib/types/LocationUpdateHints";

const log = Logger.getLogger("LocationReportModule");

/**
 * Returned from <code>setupLocationReportModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class LocationReportModule {
  private readonly onStartReport: (reportHints: LocationReportHints) => void;
  private readonly onStopReport: () => void;
  private readonly onUpdate: (
    updateHints: LocationUpdateHints
  ) => Promise<Location>;
  private constructor(
    private readonly requestManager: RequestManager,
    {
      onStartReport,
      onStopReport,
      onUpdate,
    }: {
      onStartReport: (reportHints: LocationReportHints) => void;
      onStopReport: () => void;
      onUpdate: (updateHints: LocationUpdateHints) => Promise<Location>;
    }
  ) {
    /**
     * Called when server wants to start knowing your location. Client should report his
     * location with an interval of hintInterval milliseconds or when the distance has
     * exceeded hintDistance.
     * @member {function(LocationReportHints)}
     */
    this.onStartReport = onStartReport;
    /**
     * Called when server no longer wants reports of our location.
     * @member {function()}
     */
    this.onStopReport = onStopReport;
    /**
     * Called when server wants a single location update. Client should report his location as
     * soon as possible but must comply to all supplied parameters.
     * @member {function(LocationUpdateHints):Promise<Location>}
     */
    this.onUpdate = onUpdate;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onStartReport: (reportHints: LocationReportHints) => void;
      onStopReport: () => void;
      onUpdate: (updateHints: LocationUpdateHints) => Promise<Location>;
    }
  ): Promise<LocationReportModule> {
    const response = await requestManager.send({
      locationReport: { setupRequest: {} },
    });
    log.debug("locationReport module setup.", response);
    return new LocationReportModule(requestManager, options);
  }
  public onRequest(
    message: proto.ILocationReportAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.startReportingRequest) {
      this.onStartReporting(message.startReportingRequest, respond);
    } else if (message.stopReportingRequest) {
      this.onStopReporting(message.stopReportingRequest, respond);
    } else if (message.updateRequest) {
      void this.onUpdateRequest(message.updateRequest, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Report one or many locations. This method should be called when appropriate according to
   * <code>{@link LocationReportHints}</code> supplied with the <code>onStartReport</code> callback.
   * @param {Location} location Latest location to report. Must be present.
   * @param {Array<Location>} [batchedLocations] List of batched locations.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async report(
    location: Location,
    batchedLocations: Location[] = []
  ): Promise<void> {
    await this.requestManager.send({
      locationReport: { reportRequest: { batchedLocations, location } },
    });
  }
  private onStartReporting(
    message: proto.ILocationReportStartReportingRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onStartReport) {
      this.onStartReport({
        hintAccuracy: message.hintAccuracy as number as LocationAccuracy,
        hintBatchTimeInMillis:
          message.hintBatchTimeInMillis != null
            ? longToNumber(message.hintBatchTimeInMillis)
            : undefined,
        hintDistance: message.hintDistance,
        hintInterval: longToNumber(message.hintInterval),
      });
    }
  }
  private onStopReporting(
    _: proto.ILocationReportStopReportingRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onStopReport) {
      this.onStopReport();
    }
  }
  private async onUpdateRequest(
    message: proto.ILocationReportUpdateRequest,
    respond: (code: proto.ResponseCode, location?: Location) => void
  ): Promise<void> {
    if (this.onUpdate) {
      respond(proto.ResponseCode.TRYING);
      try {
        const location = await this.onUpdate({
          hintAcceptAllLocationsAfterMillis:
            message.hintAcceptAllLocationsAfterMillis != null
              ? longToNumber(message.hintAcceptAllLocationsAfterMillis)
              : undefined,
          hintAcceptableAccuracyInMeters:
            message.hintAcceptableAccuracyInMeters ?? undefined,
          hintAcceptableAgeInMillis:
            message.hintAcceptableAgeInMillis != null
              ? longToNumber(message.hintAcceptableAgeInMillis)
              : undefined,
          timeoutInMillis:
            message.timeoutInMillis != null
              ? longToNumber(message.timeoutInMillis)
              : undefined,
        });
        respond(proto.ResponseCode.OK, location);
      } catch (error: any) {
        respond(proto.ResponseCode.LOCATION_UNAVAILABLE);
      }
    } else {
      respond(proto.ResponseCode.LOCATION_UNAVAILABLE);
    }
  }
}
