import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { SetSetting } from "src/app/model/settings/SetSetting";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";

const log = Logger.getLogger("broadcast");

export class BroadcastManager {
  public broadcastGroups: SetSetting;
  private readonly broadcastSetting: BooleanSetting;
  public constructor(private readonly state: State) {
    this.broadcastSetting = new BooleanSetting({
      defaultValue: false,
      key: "gt2.settings.broadcast",
    });
    this.broadcastGroups = new SetSetting({
      key: "gt2.settings.broadcastGroups",
    });
    observableClass(this);
  }
  public get broadcasting(): boolean {
    return this.broadcastSetting.value;
  }
  public selectAllGroups(): void {
    this.state.online?.sessions.groupSessions.forEach((session: Session) => {
      this.broadcastGroups.add(session.channelId!);
    });
  }
  public selectNoGroups(): void {
    this.broadcastGroups.clear();
  }
  public setBroadcasting(newValue: boolean): void {
    this.broadcastSetting.setValue(newValue);
    log.debug(`broadcasting ${this.broadcasting}`);
  }
}
