import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";

export class BooleanSetting {
  private readonly defaultValue: boolean;
  private readonly key: string;
  public constructor({
    defaultValue,
    key,
  }: {
    defaultValue: boolean;
    key: string;
  }) {
    this.defaultValue =
      window.gtConfig.defaultSettings &&
      window.gtConfig.defaultSettings[key] !== undefined
        ? window.gtConfig.defaultSettings[key]
        : defaultValue;
    this.key = key;
    observableClass(this);
  }
  public get configurable(): boolean {
    return window.gtConfig.configurableSettings &&
      window.gtConfig.configurableSettings[this.key] !== undefined
      ? window.gtConfig.configurableSettings[this.key]
      : true;
  }
  public get value(): boolean {
    const value = localStorage.getItem(this.key);
    if (value == null) {
      return this.defaultValue;
    }
    return value;
  }
  public setValue(newValue: boolean): void {
    if (newValue === this.defaultValue) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, newValue);
    }
  }
  public toggle(): void {
    if (!this.value === this.defaultValue) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, !this.value);
    }
  }
}
