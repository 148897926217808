import { LocationData } from "src/app/model/location/LocationData";
import { observableClass } from "src/app/state/observableClass";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { MapBounds } from "src/app/types/MapBounds";
import type { PanelData } from "src/app/types/PanelData";

type Parameters = {
  bounds: MapBounds;
  entityId: null | string;
  storedLayer: string;
};

export class LocationPanelData implements PanelData {
  public locationData: LocationData | null;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    this.locationData = null;
    observableClass(this);
  }
  public get defaultParameters(): Parameters {
    return {
      bounds: {},
      entityId: null,
      storedLayer: this.state.settings.map.storedLayer.selected.id as string,
    };
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public onCreate(): void {
    this.locationData = new LocationData(
      this.state,
      this.panel,
      this.entityId,
      "Locations"
    );
  }
  public onDelete(): void {
    if (this.locationData) {
      void this.locationData.onDelete();
      this.locationData = null;
    }
  }
  private get entityId(): null | string {
    return this.parameters.entityId;
  }
}
