import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import { Button } from "@mui/material";

export const ResetSettings = connect((_, state) => {
  const { resetLayout, resetUserSettings } = state.settings;
  return (
    <Layout column gap={30}>
      <Layout column gap={10}>
        <Text bold size={15}>
          Reset all user settings to default.
        </Text>
        <Layout alignLeft column gap={5}>
          <Text>This will not reset panel customizations.</Text>
          <Text color="#720f0f" italic>
            This cannot be undone.
          </Text>
          <Button
            onClick={() => {
              resetUserSettings();
            }}
            variant="contained"
          >
            Reset settings
          </Button>
        </Layout>
      </Layout>
      <Layout column gap={10}>
        <Text bold size={15}>
          Reset panels to default layout.
        </Text>
        <Layout alignLeft column gap={5}>
          <Text>This will also reset all panel customizations.</Text>
          <Text color="#720f0f" italic>
            This cannot be undone.
          </Text>
          <Button
            onClick={() => {
              resetLayout();
            }}
            variant="contained"
          >
            Reset panels layout
          </Button>
        </Layout>
      </Layout>
    </Layout>
  );
});
