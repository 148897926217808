import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Callee(s) busy.
 */
export class BusyHere extends GroupTalkAPIError {
  public constructor() {
    super("Callee(s) busy.", true);
  }
}
