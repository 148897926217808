import { Layout } from "src/app/gui/components/Layout";
import { Channel } from "src/app/gui/panels/channel/Channel";
import { Channels } from "src/app/gui/panels/channels/Channels";
import { Contact } from "src/app/gui/panels/contact/Contact";
import { Contacts } from "src/app/gui/panels/contacts/Contacts";
import { Customizing } from "src/app/gui/panels/customizing/Customizing";
import { Location } from "src/app/gui/panels/location/Location";
import { Patch } from "src/app/gui/panels/patch/Patch";
import { Ticket } from "src/app/gui/panels/ticket/Ticket";
import { Tickets } from "src/app/gui/panels/tickets/Tickets";
import { connect } from "src/app/state/connect";
import type { ComponentId } from "src/app/types/ComponentId";

export const Panel = connect<{
  panelId: ComponentId;
}>(({ panelId }, state) => {
  const panel = state.panels.list[panelId];
  if (!panel) {
    return <div />;
  }
  const { highlighted } = panel;
  if (panel.customizing) {
    return <Customizing panel={panel} />;
  }
  return (
    <Layout
      style={{
        borderColor: highlighted ? "#2c8766" : "#00000000",
        borderStyle: "solid",
        borderWidth: "12px",
        boxSizing: "border-box",
        overflow: "hidden",
        transition: "border-color 0.1s ease-in",
      }}
      alignTop
      column
      height="100%"
      padding={0}
    >
      {panel.type === "channels" && <Channels panel={panel} />}
      {panel.type === "contacts" && <Contacts panel={panel} />}
      {panel.type === "contact" && <Contact panel={panel} />}
      {panel.type === "tickets" && <Tickets panel={panel} />}
      {(panel.type === "ticket" ||
        panel.type === "priorityTicket" ||
        panel.type === "emergencyTicket") && <Ticket panel={panel} />}
      {panel.type === "location" && <Location panel={panel} />}
      {panel.type === "channel" && <Channel panel={panel} />}
      {panel.type === "patch" && <Patch panel={panel} />}
    </Layout>
  );
});
