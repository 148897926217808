import { BackingOffTimer } from "src/nextgen/BackingOffTimer";
import { ReconnectingWebsocket } from "src/nextgen/ReconnectingWebsocket";
import { Logger } from "src/util/Logger";
import { WrappedPromise } from "src/util/WrappedPromise";
import type { AuthenticationModule } from "src/nextgen/AuthenticationModule";

const log = Logger.getLogger("SubscriptionModule");

const PING_INTERVAL = 60000;
const PING_TIMEOUT_INTERVAL = 20000;

export class SubscriptionModule {
  private readonly websocket: ReconnectingWebsocket;
  private webSocketId?: string;
  public constructor(
    authenticationModule: AuthenticationModule,
    subscriptionWebsocketURI: string
  ) {
    this.websocket = new ReconnectingWebsocket(
      authenticationModule,
      subscriptionWebsocketURI,
      () => {
        const handshakePromise = new WrappedPromise<void>();
        return {
          handshakePromise: handshakePromise.promise,
          handshakeTimeout: 10000,
          onMessage: (json: any) => {
            // this should be the first connection established message containing the WebSocketId
            if (json.type !== "ConnectionEstablished") {
              handshakePromise.reject(
                new Error(
                  `Wrong type ${json.type} on initial handshake message`
                )
              );
              return;
            }
            const id = json.data.WebSocketId as string | undefined;
            if (id === undefined) {
              handshakePromise.reject(
                new Error(
                  "Missing WebSocketId on ConnectionEstablished handshake message"
                )
              );
              return;
            }
            const idleTimeout = json.data.IdleTimeout;
            if (idleTimeout === undefined || typeof idleTimeout !== "number") {
              handshakePromise.reject(
                new Error(
                  "ConnectionEstablished message didn't contain numeric IdleTimeout"
                )
              );
              return;
            }
            // Calculate keep alive interval: minimum 30s,
            // prefer PING_INTERVAL unless server given interval is shorter
            const pingInterval = Math.max(
              30000,
              Math.min(idleTimeout - PING_TIMEOUT_INTERVAL, PING_INTERVAL)
            );
            log.debug(
              `Subscription websocket keep alive interval is ${pingInterval}ms (server idle timeout is ${idleTimeout}ms)`
            );

            this.webSocketId = id;
            handshakePromise.resolve();

            this.websocket.startKeepAlive({
              pingInterval,
              pingResponseTimeout: PING_TIMEOUT_INTERVAL,
            });
          },
        };
      }
    );

    this.webSocketId = undefined;
  }
  public closeDown(): void {
    log.debug("Closing subscription websocket");
    this.websocket.closeDown();
  }
  /*
   * Request a subscription. Note that all callbacks can be called multiple times in the lifecycle
   * of the subscription.
   *
   * onSetupSubscription - called with webSocketId whenever subscription should be
   *                       setup towards server
   * onTearDownSubscription - called with subscriptionId that should be cancelled towards server
   * onActive - called when subscription is ready and is waiting for events
   * onInactive - called when subscription is no longer active due to
   *              failure (e.g. websocket went down)
   * onEvent - called with event when event matching subscriptionId arrives on websocket
   *
   * Returns a function that is used for unsubscribing.
   */
  public subscribe({
    onActive,
    onEvent,
    onInactive,
    onSetupSubscription,
    onTearDownSubscription,
  }: {
    onActive?: () => void;
    onEvent?: (msg: any) => void;
    onInactive?: () => void;
    onSetupSubscription: (
      websocketId: string
    ) => Promise<null | string | undefined>;
    onTearDownSubscription: (subscriptionId: string) => void;
  }): () => void {
    // true when subscriber has cancelled the subscription
    let unsubscribed = false;
    let subscriptionId: null | string | undefined = null;
    const subscribeTimer = new BackingOffTimer<null | string | undefined>(() =>
      onSetupSubscription(this.webSocketId!)
    );
    async function startSubscribe(): Promise<null | string | undefined> {
      if (!unsubscribed) {
        try {
          return await subscribeTimer.start();
        } catch (err) {
          // Retry, will use backing off timer to throttle
          return startSubscribe();
        }
      }
      throw new Error("Unsubscribed");
    }

    function teardownSubscription(): void {
      if (subscriptionId) {
        const id = subscriptionId;
        subscriptionId = null;
        if (onInactive) {
          onInactive();
        }
        if (onTearDownSubscription) {
          try {
            onTearDownSubscription(id);
          } catch (e) {
            // We do not make any retries when unsubscribing, and don't wait for it to finish
          }
        }
      }
    }

    const websocketUnsubscriber: () => void = this.websocket.subscribe({
      onConnected: () => {
        void (async () => {
          try {
            subscriptionId = await startSubscribe();
            if (onActive) {
              try {
                onActive();
              } catch (onActiveErr) {
                log.warn(
                  `Error handling subscription onActive event: ${onActiveErr}`
                );
              }
            }
          } catch (err) {
            // Can happen while unsubscribing
            subscriptionId = null;
          }
        })();
      },
      onDisconnected: () => {
        // No point in subscribing when we are disconnected
        subscribeTimer.cancel();
        teardownSubscription();
      },
      onMessage: (msg) => {
        if (
          !unsubscribed &&
          onEvent &&
          subscriptionId &&
          msg.subscriptionId === subscriptionId
        ) {
          try {
            onEvent(msg);
          } catch (onEventErr) {
            log.warn(
              `Error handling incoming subscription event: ${onEventErr}`
            );
          }
        }
      },
    });
    return () => {
      if (!unsubscribed) {
        unsubscribed = true;
        subscribeTimer.cancel();
        teardownSubscription();
        websocketUnsubscriber();
      }
    };
  }
}
