import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Upgrade needed. Client is not compatible with server.
 */
export class UpgradeNeeded extends GroupTalkAPIError {
  public constructor() {
    super("Client not compatible with server.", false);
  }
}
