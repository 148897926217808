import { BusyHere } from "src/lib/errors/BusyHere";
import { CompanyInvalid } from "src/lib/errors/CompanyInvalid";
import { Declined } from "src/lib/errors/Declined";
import { Forbidden } from "src/lib/errors/Forbidden";
import { GeneralFailure } from "src/lib/errors/GeneralFailure";
import { InvalidLoginOrPassword } from "src/lib/errors/InvalidLoginOrPassword";
import { NotAcceptableHere } from "src/lib/errors/NotAcceptableHere";
import { NotFound } from "src/lib/errors/NotFound";
import { NotOnline } from "src/lib/errors/NotOnline";
import { PaymentRequired } from "src/lib/errors/PaymentRequired";
import { RequestTerminated } from "src/lib/errors/RequestTerminated";
import { RestrictedDevice } from "src/lib/errors/RestrictedDevice";
import { RestrictedNetwork } from "src/lib/errors/RestrictedNetwork";
import { ServiceUnavailable } from "src/lib/errors/ServiceUnavailable";
import { TemporarilyUnavailable } from "src/lib/errors/TemporarilyUnavailable";
import { TokenExpired } from "src/lib/errors/TokenExpired";
import { TooManyRequests } from "src/lib/errors/TooManyRequests";
import { TransmissionDenied } from "src/lib/errors/TransmissionDenied";
import { Unauthorized } from "src/lib/errors/Unauthorized";
import { UnknownError } from "src/lib/errors/UnknownError";
import { UnsupportedByPeer } from "src/lib/errors/UnsupportedByPeer";
import { VersionNotSupported } from "src/lib/errors/VersionNotSupported";
import { proto } from "src/lib/protobuf/proto";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

export const apiErrorfromCode = (
  responseCode: number
): GroupTalkAPIError | null => {
  switch (responseCode) {
    case proto.ResponseCode.OK:
      return null;
    case proto.ResponseCode.SERVICE_UNAVAILABLE:
      return new ServiceUnavailable();
    case proto.ResponseCode.UNAUTHORIZED:
      return new Unauthorized();
    case proto.ResponseCode.INVALID_LOGIN_OR_PASSWORD:
      return new InvalidLoginOrPassword();
    case proto.ResponseCode.TOKEN_EXPIRED:
      return new TokenExpired();
    case proto.ResponseCode.COMPANY_INVALID:
      return new CompanyInvalid();
    case proto.ResponseCode.FORBIDDEN:
      return new Forbidden();
    case proto.ResponseCode.DECLINED:
      return new Declined();
    case proto.ResponseCode.REQUEST_TERMINATED:
      return new RequestTerminated();
    case proto.ResponseCode.NOT_ACCEPTABLE_HERE:
      return new NotAcceptableHere();
    case proto.ResponseCode.BUSY_HERE:
      return new BusyHere();
    case proto.ResponseCode.TEMPORARILY_UNAVAILABLE:
      return new TemporarilyUnavailable();
    case proto.ResponseCode.NOT_ONLINE:
      return new NotOnline();
    case proto.ResponseCode.RESTRICTED_DEVICE:
      return new RestrictedDevice();
    case proto.ResponseCode.RESTRICTED_NETWORK:
      return new RestrictedNetwork();
    case proto.ResponseCode.UNSUPPORTED_BY_PEER:
      return new UnsupportedByPeer();
    case proto.ResponseCode.TALKBURST_TRANSMISSION_DENIED:
      return new TransmissionDenied();
    case proto.ResponseCode.NOT_FOUND:
      return new NotFound();
    case proto.ResponseCode.VERSION_NOT_SUPPORTED:
      return new VersionNotSupported();
    case proto.ResponseCode.PAYMENT_REQUIRED:
      return new PaymentRequired();
    case proto.ResponseCode.TOO_MANY_REQUESTS:
      return new TooManyRequests();
    case proto.ResponseCode.GENERAL_FAILURE:
      return new GeneralFailure();
    default: {
      return new UnknownError();
    }
  }
};
