import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { ListSetting as Setting } from "src/app/model/settings/ListSetting";

export const ListSetting = connect<{
  id: string;
  label: string;
  none?: boolean;
  onSelect?: (selected: number | string) => void;
  setting: Setting;
}>(({ id, label, none = true, onSelect, setting }) => (
  <Layout alignLeft alignMiddle gap={20} row>
    <Layout alignMiddle column width={130}>
      <Text>{label}</Text>
    </Layout>
    <Layout row>
      <GtMenu
        items={[
          none && (
            <GtMenuItem
              icon={
                setting.selected == null ? (
                  <CheckIcon style={{ fill: "#666" }} />
                ) : null
              }
              label={
                <Text color="#666" italic>
                  None
                </Text>
              }
              onSelect={() => {
                setting.deselect();
              }}
              key="none"
            />
          ),
          ...setting.list.map((entry) => (
            <GtMenuItem
              icon={
                setting.selected && setting.selected.id === entry.id ? (
                  <CheckIcon />
                ) : null
              }
              onSelect={() => {
                setting.select(entry);
                if (onSelect) {
                  onSelect(setting.selected.id);
                }
              }}
              key={entry.id}
              label={entry.name}
            />
          )),
        ]}
        disabled={!setting.configurable}
        id={id}
      >
        <Layout alignCenter alignMiddle row>
          {setting.selected ? setting.selected.name : "None"}
        </Layout>
      </GtMenu>
    </Layout>
  </Layout>
));
