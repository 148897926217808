import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import { Alert } from "@mui/material";

export const GtError = connect<{
  message: string;
  severity?: "error" | "info" | "success" | "warning";
}>(({ message, severity = "error", ...props }) => (
  <Layout column margin={10} {...props}>
    <Alert
      severity={severity}
      style={{ background: "#ffffff36", border: "1px solid #5c5c5c" }}
    >
      {message}
    </Alert>
  </Layout>
));
