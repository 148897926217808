import { createResampler } from "src/audio/resampler/createResampler";
import { MicrophoneNotAllowed } from "src/lib/errors/MicrophoneNotAllowed";
import { MicrophoneNotReadable } from "src/lib/errors/MicrophoneNotReadable";
import { NoMicrophoneFound } from "src/lib/errors/NoMicrophoneFound";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { delay } from "src/util/delay";
import { Logger } from "src/util/Logger";
import { Throttled } from "src/util/Throttled";

const log = Logger.getLogger("AudioRecorder");

export class AudioRecorder {
  private readonly deviceChangedThrottled = new Throttled(1000, false);
  private readonly node: AudioWorkletNode;
  private readonly onReportRecordingStatus?: (recording: boolean) => void;
  private audioSource?: MediaStreamAudioSourceNode;
  // bufferLengthInMs updates every on every audioprocess
  private bufferLengthInMs = 0;
  private closed = false;
  private hasAudioCapability = true;
  private latestAudioProcess = 0;
  private onAudio?: (audio: Float32Array) => void;
  private constructor(
    private readonly audioContext: AudioContext,
    private stream: MediaStream,
    options: {
      onReportRecordingStatus?: (recording: boolean) => void;
    }
  ) {
    this.onReportRecordingStatus = options.onReportRecordingStatus;
    this.audioSource = audioContext.createMediaStreamSource(stream);
    // Save a reference to mediastream to avoid Firefox Bug:
    // see https://bugzilla.mozilla.org/show_bug.cgi?id=934512
    window.audioSource = this.audioSource;
    this.node = new AudioWorkletNode(audioContext, "forward-processor");
    // Save a reference to node to avoid it to be garbage collected
    window.nodeReference = this.node; // Needed?
    const resampler = createResampler({
      fromHz: audioContext.sampleRate,
      toHz: 16000,
    });
    // if the script node is not connected to an output the 'onaudioprocess'
    // event is not triggered in chrome.
    this.audioSource.connect(this.node);
    this.node.connect(audioContext.destination);

    this.node.port.onmessage = (event: MessageEvent<any>) => {
      if (
        !event.data ||
        event.data.length === 0 ||
        event.data[0].length === 0
      ) {
        return;
      }
      const dataChunk = event.data[0][0]; // First channel of first sound input.
      this.bufferLengthInMs =
        (dataChunk.length / audioContext.sampleRate) * 1000;

      if (this.onReportRecordingStatus && Math.abs(dataChunk[0]) > 1e-9) {
        this.latestAudioProcess = new Date().getTime();
        if (!this.hasAudioCapability) {
          this.hasAudioCapability = true;
          this.onReportRecordingStatus(true);
          this.scheduleCheck();
        }
      }
      if (!this.onAudio) {
        return;
      }
      // Downsample from sampleRate/floats (-1.0 to 1.0) to 16kHz/floats (-32768 to 32767)
      const resampledBuffer = resampler.resample(dataChunk);
      const sampleCount = resampledBuffer.length;
      for (let i = 0; i < sampleCount; i += 1) {
        const s = resampledBuffer[i];
        resampledBuffer[i] = s * (s < 0 ? 32768 : 32767);
      }
      setTimeout(() => {
        if (this.onAudio) {
          this.onAudio(resampledBuffer);
        }
      }, 0);
    };
    if (this.onReportRecordingStatus) {
      this.scheduleCheck();
    }
    this.onDeviceChanged = this.onDeviceChanged.bind(this);
    window.navigator?.mediaDevices?.addEventListener(
      "devicechange",
      this.onDeviceChanged
    );
  }
  public static async setup(
    audioContext: AudioContext,
    options: {
      onReportRecordingStatus?: (recording: boolean) => void;
    }
  ): Promise<AudioRecorder> {
    if (!window.navigator?.mediaDevices?.getUserMedia) {
      throw new Error("Browser not compatible for recording.");
    }
    try {
      const stream = await window.navigator?.mediaDevices?.getUserMedia({
        audio: true,
      });
      await audioContext.audioWorklet.addModule("worklets/forward.js");
      return new AudioRecorder(audioContext, stream, options);
    } catch (error: any) {
      throw AudioRecorder.convertError(error);
    }
  }
  public getBufferLengthInMs(): number {
    return this.bufferLengthInMs;
  }
  public release(): void {
    if (!this.closed) {
      log.debug("Stopping recording stream");
      this.closed = true;
      this.onAudio = undefined;
      for (const t of this.stream.getTracks()) {
        t.stop();
      }
      this.audioSource?.disconnect();
      // We set audioSource to undefined so that it can't disconnect from the node twice.
      this.audioSource = undefined;
      this.node.disconnect();
      // Send a shut-down message to kill the worker.
      this.node.port.postMessage(0);
      window.navigator?.mediaDevices?.removeEventListener(
        "devicechange",
        this.onDeviceChanged
      );
    }
  }
  public start(onAudio: (audio: Float32Array) => void): void {
    if (this.closed) {
      throw new Error("Cannot start recording. Recorder is released.");
    } else if (this.onAudio) {
      log.debug("Skip starting recording. Already recording.");
    } else {
      log.debug("Start recording");
      this.onAudio = onAudio;
    }
  }
  public stop(): void {
    if (!this.closed) {
      log.debug("Stop recording");
      this.onAudio = undefined;
    }
  }
  private static convertError(error: Error): Error {
    switch (error.name) {
      case "NotAllowedError":
      case "PermissionDeniedError":
        return new MicrophoneNotAllowed();
      case "NotFoundError":
      case "DevicesNotFoundError":
        return new NoMicrophoneFound();
      case "NotReadableError":
        return new MicrophoneNotReadable();
      default:
        return error;
    }
  }
  private check(): void {
    if (this.closed) {
      return;
    }
    const currentTime = new Date().getTime();
    if (currentTime > this.latestAudioProcess + 10000) {
      if (this.hasAudioCapability) {
        log.debug("Warn user of no audio");
        this.hasAudioCapability = false;
        this.onReportRecordingStatus?.(false);
      }
    } else {
      this.scheduleCheck();
    }
  }
  private onDeviceChanged(): void {
    // When plugging out USB devices etc, multiple devices may change if the device is a hub etc.
    // So we add a throttle so this is called max once every so often.
    this.deviceChangedThrottled.throttled(() => {
      log.debug("Device changed. Get a new user media stream.");
      void (async () => {
        if (this.closed) {
          return;
        }
        for (const t of this.stream.getTracks()) {
          t.stop();
        }
        this.audioSource?.disconnect();
        // We set audioSource to undefined so that it can't disconnect from the node twice.
        this.audioSource = undefined;
        this.node.disconnect();
        try {
          // We add a delay here, because otherwise getUserMedia can throw a NotFoundError
          await delay(1000);
          if (this.closed) {
            return;
          }
          this.stream = await window.navigator?.mediaDevices?.getUserMedia({
            audio: true,
          });
          this.audioSource = this.audioContext.createMediaStreamSource(
            this.stream
          );
          window.audioSource = this.audioSource;
          this.audioSource.connect(this.node);
          this.node.connect(this.audioContext.destination);
        } catch (error: any) {
          log.error(error);
        }
      })();
    });
  }
  private scheduleCheck(): void {
    BackgroundTimer.setTimeout(() => {
      this.check();
    }, 4000);
  }
}
