import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Connecting } from "src/app/gui/offline/Connecting";
import { ServerSelect } from "src/app/gui/offline/ServerSelect";
import { ExitIcon } from "src/app/images";
import { Server } from "src/app/model/appData/Server";
import { connect } from "src/app/state/connect";
import { Button, CircularProgress } from "@mui/material";

export const Login = connect((_, state) => {
  const { deauthenticate } = state.offline;
  const { error, init, loading } = state.login;
  const { showServerSelect } = Server;
  return (
    <form
      onSubmit={(event) => {
        void init({ removeQueryParams: true, showServer: false });
        event.preventDefault();
      }}
      noValidate
    >
      <Layout column>
        <Connecting header="Login">
          {!loading && Server.showServerSelect && <ServerSelect />}
          {!loading && (showServerSelect || error) && (
            <Layout column gap={20} marginBottom={40} marginTop={30}>
              <Button
                color="primary"
                disableRipple
                size="large"
                type="submit"
                variant="contained"
              >
                {error ? "Retry" : "Login"}
              </Button>
            </Layout>
          )}
          {loading && (
            <Layout column marginBottom={40} marginTop={30}>
              <CircularProgress />
            </Layout>
          )}
        </Connecting>
        {error && (
          <Layout column marginTop={10}>
            <Button
              onClick={() => {
                deauthenticate();
              }}
              type="button"
            >
              <Layout gap={10} row>
                <ExitIcon style={{ fill: "rgba(0, 0, 0)" }} />
                <Text>Change user</Text>
              </Layout>
            </Button>
          </Layout>
        )}
      </Layout>
    </form>
  );
});
