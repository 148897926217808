import { Ringer } from "src/app/model/setupCall/Ringer";
import { observableClass } from "src/app/state/observableClass";
import { CallStatus } from "src/lib/types/CallStatus";
import { Logger } from "src/util/Logger";
import type { DialogHandle } from "src/app/model/dialogs/DialogHandle";
import type { State } from "src/app/model/State";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { SetupCallModule } from "src/lib/modules/SetupCallModule";
import type { CallReferenceNodeId } from "src/lib/types/CallReferenceNodeId";

const log = Logger.getLogger("setupcall");

export class SetupCall {
  private setupCallModule?: SetupCallModule;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public call({
    autoAnswer,
    callableEntityId,
    fullDuplex,
    name,
    onCallRef,
  }: {
    autoAnswer: boolean;
    callableEntityId: string;
    fullDuplex: boolean;
    name: string;
    onCallRef?: (callRef: CallReferenceNodeId) => void;
  }): void {
    log.debug(
      `Setup ${
        autoAnswer ? "auto" : ""
      }call to ${name} with id ${callableEntityId}`
    );
    let callingDialog: DialogHandle;
    const ringer = new Ringer();
    if (!this.setupCallModule) {
      log.warn("Setup call module not setup.");
      return;
    }
    const cancelCall = this.setupCallModule.setupCall({
      autoAccept: autoAnswer,
      callableReferences: [callableEntityId],
      fullDuplex,
      onResult: (setupCallResponse) => {
        const { callRef, callStatus } = setupCallResponse;
        log.debug("Setup call response", setupCallResponse);
        let showError;
        ringer.stopRinging();
        switch (callStatus) {
          case CallStatus.Busy:
            showError = "Callee busy.";
            break;
          case CallStatus.Declined:
            showError = "Call declined.";
            break;
          case CallStatus.CalleeNotOnline:
            showError = "Callee not online.";
            break;
          case CallStatus.CalleeNotFound:
            showError = "Could not find callee.";
            break;
          case CallStatus.GeneralFailure:
            showError = `Failed to call ${name}.`;
            break;
          case CallStatus.NoAnswer:
            showError = "No answer.";
            break;
          case CallStatus.Unsupported:
            showError = "Callee does not support this feature.";
            break;
          default:
            break;
        }
        if (showError) {
          if (callingDialog) {
            callingDialog.update({
              actions: [{ label: "OK" }],
              forceRespond: false,
              text: showError,
            });
          } else {
            this.state.dialogs.show({
              text: showError,
              title: `Outgoing call to ${name}`,
            });
          }
        } else if (callingDialog) {
          log.debug("Closing dialog...");
          callingDialog.close();
        }
        if (onCallRef && callRef) {
          onCallRef(callRef);
        }
      },
    });
    if (!autoAnswer) {
      callingDialog = this.state.dialogs.show({
        actions: [
          {
            label: "Cancel",
            onSelect: () => {
              ringer.stopRinging();
              cancelCall();
            },
          },
        ],
        forceRespond: true,
        text: "Calling...",
        title: `Outgoing call to ${name}`,
      });
    }
  }
  public monitor(monitorEntityId: string): void {
    void this.setupCallModule
      ?.startMonitoring(monitorEntityId)
      .then((setupCallResponse) => {
        const { callRef, callStatus } = setupCallResponse;
        if (CallStatus.Accepted) {
          log.debug(`Monitoring to ${callRef} accepted.`);
        } else {
          log.debug(`Monitoring to ${callRef} is ${callStatus}`);
        }
      });
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    this.setupCallModule = await authenticatedModule.setupSetupCallModule();
  }
}
