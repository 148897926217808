import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { HttpRequest } from "src/lib/types/HttpRequest";
import type { HttpRequestType } from "src/lib/types/HttpRequestType";

const log = Logger.getLogger("HttpRequestModule");
/**
 * Returned from <code>setupHttpRequestModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class HttpRequestModule {
  private readonly onHttpRequest?: (
    httpRequest: HttpRequest
  ) => Promise<number>;
  private constructor(
    options: {
      onHttpRequest?: (httpRequest: HttpRequest) => Promise<number>;
    } = {}
  ) {
    /**
     * Callback when server wants client to make a http request. The callback should return a
     * promise of the response code aquired from the request.
     * @member {function(HttpRequest):Promise<Number>}
     */
    this.onHttpRequest = options.onHttpRequest;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onHttpRequest?: (httpRequest: HttpRequest) => Promise<number>;
    }
  ): Promise<HttpRequestModule> {
    const response = await requestManager.send({
      httpRequest: { setupRequest: {} },
    });
    log.debug("httpRequest module setup.", response);
    return new HttpRequestModule(options);
  }
  public onRequest(
    message: proto.IHTTPRequestAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.httpRequest) {
      void this.handleHttpRequest(message.httpRequest, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  private async handleHttpRequest(
    message: proto.IHTTPRequest,
    respond: (
      code: proto.ResponseCode,
      response?: { responseCode: number }
    ) => void
  ): Promise<void> {
    if (this.onHttpRequest) {
      respond(proto.ResponseCode.TRYING);
      const responseCode = await this.onHttpRequest({
        type: message.type as number as HttpRequestType,
        url: message.url,
      });
      respond(proto.ResponseCode.OK, { responseCode });
    }
  }
}
