import { connect } from "src/app/state/connect";
import { Circle } from "react-leaflet";
import type { Location } from "src/lib/types/Location";

export const AccuracyRing = connect<{
  location: Location;
}>(({ location }) => {
  return (
    <Circle
      center={[location.latitude, location.longitude]}
      color="#ad8297"
      fillColor="#ad8297"
      fillOpacity={0.15}
      opacity={0.3}
      radius={location.accuracy!}
      weight={2}
    />
  );
});
