import { AudioPlayback } from "src/audio/html5/AudioPlayback";
import { AudioRecorder } from "src/audio/html5/AudioRecorder";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { Logger } from "src/util/Logger";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { IncomingTalkburst } from "src/lib/modules/IncomingTalkburst";
import type { OutgoingTalkburst } from "src/lib/modules/OutgoingTalkburst";
import type { Session } from "src/lib/modules/Session";
import type { TalkburstModule } from "src/lib/modules/TalkburstModule";
import type { CodecSettings } from "src/lib/types/CodecSettings";

const log = Logger.getLogger("AudioManager");

export class AudioManager {
  private readonly transmissions: Record<string, OutgoingTalkburst> = {};
  public constructor(
    private readonly audioContext: AudioContext,
    private readonly audioPlayback: AudioPlayback,
    private readonly audioRecorder: AudioRecorder,
    private readonly talkburstModule: TalkburstModule,
    private readonly codecSettings: () => CodecSettings
  ) {}
  public static async setup(
    authenticatedModule: AuthenticatedModule,
    options: {
      codecSettings: () => CodecSettings;
      muted: (talkburst: IncomingTalkburst) => boolean;
      onReportRecordingStatus: (hasRecording: boolean) => void;
      onTalkburstStart: (talkburst: IncomingTalkburst) => void;
      onTalkburstStop: (talkburst: IncomingTalkburst) => void;
      volume: (session: Session) => () => number;
    }
  ): Promise<AudioManager> {
    const {
      codecSettings,
      muted,
      onReportRecordingStatus,
      onTalkburstStart,
      onTalkburstStop,
      volume,
    } = options;

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      throw new Error("Browser does not support AudioContext.");
    }
    const audioContext = new AudioContext();
    const audioPlayback = new AudioPlayback(audioContext);
    const audioRecorder = await AudioRecorder.setup(audioContext, {
      onReportRecordingStatus,
    });

    const talkburstModule = await authenticatedModule.setupTalkburstModule({
      onTalkburstStart: (talkburst: IncomingTalkburst) => {
        log.debug("Start playback");
        const volumeGetter =
          volume &&
          talkburst.session !== undefined &&
          volume(talkburst.session);
        const isMuted = muted(talkburst);
        audioPlayback.playStream(() => {
          const audio = talkburst.getAudio();
          let vol = audio && volumeGetter ? volumeGetter() : 100;
          if (isMuted) {
            vol = 0;
          }
          return audio && audio.map((frame) => frame * (vol / 100.0) ** 3);
        });
        if (onTalkburstStart) {
          onTalkburstStart(talkburst);
        }
      },
      onTalkburstStop: (talkburst: IncomingTalkburst) => {
        if (onTalkburstStop) {
          onTalkburstStop(talkburst);
        }
      },
    });
    return new AudioManager(
      audioContext,
      audioPlayback,
      audioRecorder,
      talkburstModule,
      codecSettings
    );
  }
  public release(): void {
    if (this.audioPlayback) {
      this.audioPlayback.release();
    }
    if (this.audioRecorder) {
      this.audioRecorder.release();
    }
    if (this.audioContext.close) {
      void this.audioContext.close();
    }
  }
  public replayTalkburst({
    onFinished,
    talkburst,
  }: {
    onFinished: () => void;
    talkburst: IncomingTalkburst;
  }): void {
    if (talkburst.jitterBuffer) {
      const jitterBufferCopy = talkburst.jitterBuffer.getReplayCopy({
        onFinished,
      });
      this.audioPlayback.playStream(() => jitterBufferCopy.poll());
    } else {
      onFinished();
    }
  }
  public async startTransmission({
    delay,
    muteGroupId,
    onInterrupt,
    onStopped,
    sessionId,
  }: {
    delay: number;
    muteGroupId?: string;
    onInterrupt: () => void;
    onStopped: () => void;
    sessionId: number;
  }): Promise<OutgoingTalkburst | undefined> {
    if (this.transmissions[sessionId]) {
      return this.transmissions[sessionId];
    }
    log.debug(
      `Start transmission in session ${sessionId}. Mutegroup: ${muteGroupId}`
    );
    const codecSettings = this.codecSettings();
    const talkburst = await this.talkburstModule.startTransmission(
      sessionId,
      muteGroupId,
      codecSettings,
      onInterrupt,
      () => {
        log.debug("Stop recording");
        return new Promise<void>((resolve) => {
          BackgroundTimer.setTimeout(() => {
            delete this.transmissions[sessionId];
            if (Object.keys(this.transmissions).length === 0) {
              this.audioRecorder.stop();
            }
            if (onStopped) {
              onStopped();
            }
            resolve();
          }, delay + Math.round(this.audioRecorder.getBufferLengthInMs()));
        });
      }
    );
    if (talkburst !== undefined) {
      this.transmissions[sessionId] = talkburst;
      log.debug("Start recording");
      if (Object.keys(this.transmissions).length === 1) {
        BackgroundTimer.setTimeout(() => {
          this.audioRecorder.start((audio) => {
            Object.values(this.transmissions).forEach((t) => {
              t.sendAudio(audio);
            });
          });
        }, delay);
      }
    }
    return talkburst;
  }
}
