import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { ListSetting } from "src/app/gui/settings/ListSetting";
import { connect } from "src/app/state/connect";

export const MuteGroups = connect((_, state) => {
  const muteGroups = state.online?.sessions.muteGroups;
  const { showInMenu } = state.settings.muteGroups;
  return (
    <Layout column width={400}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          Mute Groups
        </Text>
      </Layout>
      {muteGroups !== undefined && (
        <ListSetting
          id="mute_group"
          label="Use Mute group:"
          setting={muteGroups!}
        />
      )}
      <Layout marginBottom={16} marginTop={10}>
        <Text color="#080808" weight={300}>
          {"Dispatchers using the same mute group will mute each other. " +
            "This can be useful if multiple dispatchers are used in the same location."}
        </Text>
      </Layout>
      <BooleanSetting
        label="Show Mute group selection in top bar"
        setting={showInMenu}
      />
    </Layout>
  );
});
