import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ButtonBase } from "@mui/material";

export const GtPanelTab = ({
  Icon,
  disabled,
  hideIfDisabled = false,
  highlightNumber = false,
  id,
  number,
  onClick,
  positiveOnly = true,
  selected,
  tooltip,
}: {
  Icon:
    | React.ComponentType<{ style: React.CSSProperties }>
    | React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  disabled?: boolean;
  hideIfDisabled?: boolean;
  highlightNumber?: boolean;
  id: string;
  number?: number;
  onClick?: () => void;
  positiveOnly?: boolean;
  selected: boolean;
  tooltip: string;
}): JSX.Element => {
  let opacity = 1;
  if (disabled) {
    opacity = hideIfDisabled ? 0 : 0.2;
  }
  return (
    <GtTooltip id={id} title={tooltip}>
      <ButtonBase
        style={{
          background: selected ? "#ffffff22" : "#00000022",
          border: "1px solid black",
          borderRadius: 5,
          height: 40,
          marginLeft: 5,
          minWidth: 40,
          opacity,
          width: 40,
        }}
        disabled={disabled}
        onClick={() => onClick && !disabled && onClick()}
      >
        <Layout alignCenter alignMiddle column padding={0}>
          <Icon
            style={{
              height: 20,
              margin: 0,
              padding: 0,
              width: 20,
            }}
          />
          {number != null &&
            (number > 0 || !positiveOnly) &&
            highlightNumber && (
              <Layout
                style={{
                  borderRadius: "20%",
                }}
                background="rgb(170, 51, 51)"
                column
                height={10}
                padding={2}
              >
                <Text bold color="rgb(170, 170, 170)" size={10}>
                  {number}
                </Text>
              </Layout>
            )}
          {number != null &&
            (number > 0 || !positiveOnly) &&
            !highlightNumber && (
              <Layout column>
                <Text bold size={10}>
                  {number}
                </Text>
              </Layout>
            )}
        </Layout>
      </ButtonBase>
    </GtTooltip>
  );
};
