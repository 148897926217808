import { gql } from "src/app/graphql";
import { Constants } from "src/app/model/Constants";
import { Message } from "src/app/model/messages/Message";
import { observableClass } from "src/app/state/observableClass";
import { datesAreOnSameDay } from "src/util/datesAreOnSameDay";
import { Logger } from "src/util/Logger";
import { action, reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { MessageFieldsFragment } from "src/app/graphql/graphql";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";

const log = Logger.getLogger("MessageChannel");

const GROUPING_THRESHOLD_IN_SEC = 5 * 60;

const CHANNEL_TYPE_GROUP = "group";
const CHANNEL_TYPE_PRIVATE = "private";

export class MessageChannel {
  public currentMessage = "";
  public error: null | string = null;
  public hasMessaging = false;
  public lastReadMessageId?: string;
  public locked = false;
  public mayLock = false;
  public mayRead = false;
  public mayWrite = false;
  public name?: string;
  public notificationsEnabled = true;
  public onTop = false;
  public scroll = false;
  public subscribed = false;
  public type: "group" | "private" | null = null;
  public unread = 0;
  /**
   * The displayedLastReadMessageId is initially set to the loaded read marker.
   * It should be updated to reflect current read marker
   */
  private displayedLastReadMessageId?: null | string = null;
  private disposeVisible?: IReactionDisposer;
  private hasLoaded = false;
  private isUpdatingReadMarker = false;
  private loadingMore = false;
  private loadingNew = false;
  /**
   * Should new messages be displayed
   */
  private markNewAsRead = false;
  private messages: Message[] = [];
  private nextCursor?: string;
  private pendingLoadNew = false;
  private previousCursor?: string;
  private unsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    private readonly panel: Panel,
    private readonly channelId: ChannelUuid
  ) {
    // Setup subscription
    // This will trigger a message fetch as soon as subscription is completed
    this.subscribeToNewMessages();
    this.setupMarkAsRead();
    observableClass(this);
  }
  /* BEGIN: Read marker related */
  public get firstUnreadMessage(): Message | null {
    if (!this.displayedLastReadMessageId) {
      return null;
    }
    const lastReadIndex = this.messages.findIndex(
      (m) => m.id === this.displayedLastReadMessageId
    );
    if (lastReadIndex < 1) {
      // If last read is not found or is the newest, then there cannot be any first unread
      return null;
    }
    return this.messages[lastReadIndex - 1];
  }
  public get loading(): boolean {
    return this.loadingMore || !this.hasLoaded;
  }
  public get sameDayMessagesGroupsReversed(): Message[][][] {
    return this.sameDayMessagesGroups.slice().reverse();
  }
  public loadMore(): void {
    if (this.hasLoaded && this.previousCursor && !this.loadingMore) {
      log.debug("Loading earlier messages");
      this.loadingMore = true;
      void (async () => {
        try {
          const messageChannel = (
            await this.state.graphqlModule.queryDataOrThrow({
              fetchPolicy: "no-cache", // 'no-cache' when cursor is null to always get latest data
              query: gql(`
                query messageChannel(
                  $channelId: ID!,
                  $cursor: String!
                ) {
                  messageChannel(channelId: $channelId) {
                    messages(cursor: $cursor) {
                      items {
                        ... on Message {
                          ...MessageFields
                        }
                      }
                      cursor {
                        previous
                      }
                    }
                  }
                }
              `),
              variables: {
                channelId: this.channelId,
                cursor: this.previousCursor,
              },
            })
          ).messageChannel!;
          this.error = null;
          this.loadingMore = false;
          if (messageChannel.messages.items) {
            // Append new messages last in messages
            this.messages = this.messages.concat(
              (messageChannel.messages.items as MessageFieldsFragment[]).map(
                (message) => new Message(message, this)
              )
            );
            this.previousCursor =
              messageChannel.messages.cursor.previous ?? undefined;
          } else {
            // No older messages
            log.warn(
              `Received no messages from a previous cursor ${this.previousCursor} and received new previous cursor ${messageChannel.messages.cursor.previous}`
            );
          }
        } catch (err: any) {
          log.error(err);
          this.loadingMore = false;
          this.error = "Failed to fetch messages";
        }
      })();
    }
  }
  public onDelete(): void {
    log.debug("Disposing message tab");
    this.disposeVisible?.();
    this.disposeVisible = undefined;
    this.unsubscriber?.();
    this.unsubscriber = undefined;
  }
  public async postMessage(): Promise<void> {
    const message = this.currentMessage.trim();
    this.clearCurrentMessage();
    try {
      if (this.hasMessaging && message.length > 0) {
        const res = await this.postTextMessage(message);
        if (res.error) {
          // operation failed due to AuthorizationError (the only possible error
          // returned from this operation)
          log.error(res.error.message!);
          // This should not typically not happen, since the text input should be disabled
          // unless the configuration changed in between refreshes.
          this.error = "Not allowed to post message";
          // Restore the message that we failed to post
          this.currentMessage = message;
        } else {
          // no error => successful
          this.error = null;
        }
      }
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to post message";
      // Restore the message that we failed to post
      this.currentMessage = message;
    }
  }
  public setCurrentMessage(newMessage: string): void {
    this.currentMessage = newMessage;
  }
  public setOnTop(value: boolean): void {
    this.onTop = value;
  }
  public setScroll(scroll: boolean): void {
    this.scroll = scroll;
  }
  public async toggleLocked(): Promise<void> {
    // change the value
    this.locked = !this.locked;
    // persist the change on the server
    let error = null;
    if (this.locked) {
      error = await this.lockChannel();
    } else {
      error = await this.unlockChannel();
    }
    if (
      error &&
      (error.__typename === "AuthorizationError" ||
        error.__typename === "NotFoundError")
    ) {
      // operation failed due to AuthorizationError or due to no such channel
      log.error(error.message!);
      this.error = "Failed to toggle lock state of channel";
      // change back the value
      this.locked = !this.locked;
    } else {
      // no error => successful
      this.error = null;
    }
  }
  public async toggleNotifications(): Promise<void> {
    // change the value
    this.notificationsEnabled = !this.notificationsEnabled;
    // persist the change on the server
    const setMessageChannelNotifications = (
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation setMessageChannelNotifications(
            $channelId: ID!
            $notifications: Boolean!
          ) {
            setMessageChannelNotifications(
              input: { channelId: $channelId, notifications: $notifications }
            ) {
              error {
                __typename
                ... on AuthorizationError {
                  message
                }
                ... on NotFoundError {
                  message
                }
              }
            }
          }
        `),
        variables: {
          channelId: this.channelId,
          notifications: this.notificationsEnabled,
        },
      })
    ).setMessageChannelNotifications!;
    if (
      setMessageChannelNotifications.error &&
      (setMessageChannelNotifications.error.__typename ===
        "AuthorizationError" ||
        setMessageChannelNotifications.error.__typename === "NotFoundError")
    ) {
      // operation failed due to AuthorizationError or due to no such channel
      log.error(setMessageChannelNotifications.error.message!);
      this.error = "Failed to save channel notifications setting";
      // change back the value
      this.notificationsEnabled = !this.notificationsEnabled;
    } else {
      // no error => successful
      this.error = null;
    }
  }
  /* BEGIN: pagination related */
  private get consecutiveMessagesGroups(): Message[][] {
    return this.messagesInvertedOrder.reduce((ack: Message[][], message) => {
      const lastMessageGroup = ack.length > 0 && ack[ack.length - 1];
      const lastMessage =
        lastMessageGroup && lastMessageGroup[lastMessageGroup.length - 1];
      if (
        lastMessage &&
        lastMessage.fromId === message.fromId &&
        (message.time.getTime() - lastMessage.time.getTime()) / 1000 <=
          GROUPING_THRESHOLD_IN_SEC
      ) {
        return [...ack.slice(0, -1), [...lastMessageGroup, message]];
      }
      return [...ack, [message]];
    }, []);
  }
  /* BEGIN: channel related */
  private get messagesInvertedOrder(): Message[] {
    return this.messages.slice().reverse();
  }
  private get sameDayMessagesGroups(): Message[][][] {
    return this.consecutiveMessagesGroups.reduce(
      (ack: Message[][][], messageGroup) => {
        const lastDayGroup = ack.length > 0 && ack[ack.length - 1];
        const firstGroup = lastDayGroup && lastDayGroup[0];
        if (
          firstGroup &&
          datesAreOnSameDay(firstGroup[0].time, messageGroup[0].time)
        ) {
          return [...ack.slice(0, -1), [...lastDayGroup, messageGroup]];
        }
        return [...ack, [messageGroup]];
      },
      []
    );
  }
  private get visible(): boolean {
    return !!this.panel.channelPanelData?.messagingVisible;
  }
  private clearCurrentMessage(): void {
    this.currentMessage = "";
  }
  private async load(): Promise<void> {
    try {
      const { messageChannel } =
        await this.state.graphqlModule.queryDataOrThrow({
          fetchPolicy: "no-cache", // 'no-cache' when cursor is null to always get latest data
          query: gql(`
            query messageChannelFull(
              $channelId: ID!
            ) {
              messageChannel(channelId: $channelId) {
                id
                locked
                notificationsDisabled
                unreadCount
                permissions {
                  read
                  lock
                  write
                }
                source {
                  ... on Group {
                    __typename
                    name
                  }
                  ... on PrivateMessageChannel {
                    __typename
                  }
                }
                lastReadMessage {
                  id
                }
                messages {
                  items {
                    ... on Message {
                      ...MessageFields
                    }
                  }
                  cursor {
                    next
                    previous
                  }
                }
              }
            }
          `),
          variables: {
            channelId: this.channelId,
          },
        });
      if (messageChannel) {
        this.error = null;
        if (messageChannel.source.__typename === "PrivateMessageChannel") {
          this.type = CHANNEL_TYPE_PRIVATE;
        } else {
          this.type = CHANNEL_TYPE_GROUP;
          this.name = messageChannel.source.name;
        }
        this.locked = messageChannel.locked;
        this.notificationsEnabled = !messageChannel.notificationsDisabled;
        this.unread = messageChannel.unreadCount;
        if (messageChannel.messages.items) {
          this.messages = (
            messageChannel.messages.items as MessageFieldsFragment[]
          ).map((message) => new Message(message, this));
        } else {
          this.messages = [];
        }
        this.lastReadMessageId =
          (messageChannel.lastReadMessage &&
            messageChannel.lastReadMessage.id) ??
          undefined;
        this.displayedLastReadMessageId = this.lastReadMessageId;
        this.nextCursor = messageChannel.messages.cursor.next ?? undefined;
        this.previousCursor =
          messageChannel.messages.cursor.previous ?? undefined;
        this.hasMessaging = true;
        this.hasLoaded = true;
        this.mayWrite = messageChannel.permissions.write;
        this.mayRead = messageChannel.permissions.read;
        this.mayLock = messageChannel.permissions.lock;
      } else {
        this.error = "Group not found";
        this.hasLoaded = true;
      }
      // Update read counter if applicable
      void this.updateReadMarker();

      // If we got an update while loading
      if (this.pendingLoadNew) {
        this.pendingLoadNew = false;
        void this.loadNew();
      }
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to fetch messages";
      this.hasLoaded = true;
    }
  }
  private async loadNew(): Promise<void> {
    if (this.loadingNew) {
      // Update while update was in progress
      this.pendingLoadNew = true;
      return;
    }
    if (this.hasLoaded) {
      this.loadingNew = true;
      try {
        const messageChannel = (
          await this.state.graphqlModule.queryDataOrThrow({
            fetchPolicy: "no-cache", // 'no-cache' when cursor is null to always get latest data
            query: gql(`
              query messageChannelMore(
                $channelId: ID!,
                $cursor: String!
              ) {
                messageChannel(channelId: $channelId) {
                  unreadCount
                  lastReadMessage {
                    id
                  }
                  messages(cursor: $cursor) {
                    items {
                      ... on Message {
                        ...MessageFields
                      }
                    }
                    cursor {
                      next
                    }
                  }
                }
              }
            `),
            variables: {
              channelId: this.channelId,
              cursor: this.nextCursor,
            },
          })
        ).messageChannel!;
        // TODO: we should really have a next AND 'refresh' cursor,
        // to be able to know when there are no new messages pending
        this.error = null;
        this.lastReadMessageId =
          (messageChannel.lastReadMessage &&
            messageChannel.lastReadMessage.id) ??
          undefined;
        if (messageChannel.messages.items) {
          // Append new messages first in messages
          this.messages = (
            messageChannel.messages.items as MessageFieldsFragment[]
          )
            .map((message) => new Message(message, this))
            .concat(this.messages);
        } else {
          // No new messages
        }
        this.nextCursor = messageChannel.messages.cursor.next ?? undefined;
        this.loadingNew = false;
        if (this.pendingLoadNew) {
          this.pendingLoadNew = false;
          void this.loadNew();
        } else {
          // Update read mark/unread counter
          void this.updateReadMarker(messageChannel.unreadCount);
        }
      } catch (err: any) {
        log.error(err);
        this.error = "Failed to fetch messages";
        this.loadingNew = false;
      }
    } else {
      this.pendingLoadNew = true;
    }
  }
  private async loadPermissions(): Promise<void> {
    try {
      const { messageChannel } =
        await this.state.graphqlModule.queryDataOrThrow({
          fetchPolicy: "no-cache", // 'no-cache' to always get latest data
          query: gql(`
            query messageChannelPermissions(
              $channelId: ID!
            ) {
              messageChannel(channelId: $channelId) {
                locked
                permissions {
                  read
                  lock
                  write
                }
              }
            }
          `),
          variables: {
            channelId: this.channelId,
          },
        });
      if (messageChannel) {
        this.error = null;
        this.locked = messageChannel.locked;
        this.mayRead = messageChannel.permissions.read;
        this.mayLock = messageChannel.permissions.lock;
        this.mayWrite = messageChannel.permissions.write;
      }
    } catch (err: any) {
      log.error(err);
    }
  }
  private async lockChannel(): Promise<
    | {
        __typename: "AuthorizationError";
        message?: null | string | undefined;
      }
    | {
        __typename: "NotFoundError";
        message?: null | string | undefined;
      }
    | null
  > {
    const { lockMessageChannel } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation lockMessageChannel(
            $channelId: ID!
          ) {
            lockMessageChannel(input: { channelId: $channelId }) {
              error {
                __typename
                ... on AuthorizationError {
                  message
                }
                ... on NotFoundError {
                  message
                }
              }
            }
          }
        `),
        variables: {
          channelId: this.channelId,
        },
      });
    return lockMessageChannel.error;
  }
  private async postTextMessage(textMessage: string): Promise<{
    error?: { message?: null | string } | null;
    message?: { id: string } | null;
  }> {
    const data = await this.state.graphqlModule.mutationDataOrThrow({
      mutation: gql(`
        mutation postMessage(
          $channelId: ID!,
          $messageInput: MessageInput!
        ) {
          messagePost(
            input: { channelId: $channelId, message: $messageInput }
          ) {
            error {
              __typename
              ... on AuthorizationError {
                message
              }
            }
            message {
              id
            }
          }
        }
      `),
      variables: {
        channelId: this.channelId,
        messageInput: {
          data: textMessage,
          type: "text/plain",
        },
      },
    });
    return data!.messagePost as {
      error?: { message?: null | string } | null;
      message?: { id: string } | null;
    };
  }
  private setupMarkAsRead(): void {
    this.disposeVisible = reaction(
      () => this.onTop && this.visible,
      (mark, oldMark) => {
        if (mark && !oldMark) {
          // Whenever panel becomes visible, we update displayed lastReadMessageId so that the marker
          // is persistent until we no longer observe the panel. Next time we get back,
          // the indicator will be on the actually first unread message.
          log.trace(`Messages for ${this.channelId} is visible`);
          log.trace(
            `New messages will be marked as read in channel ${this.channelId}`
          );
          this.displayedLastReadMessageId = this.lastReadMessageId;
          this.markNewAsRead = true;
          void this.updateReadMarker();
        } else if (!mark && oldMark) {
          log.trace(`Messages for ${this.channelId} is invisible`);
          log.trace(
            `New messages will NOT be marked as read in channel ${this.channelId}`
          );
          this.markNewAsRead = false;
        }
      }
    );
  }
  private subscribeToNewMessages(): void {
    this.unsubscriber = this.state.online?.subscriptionModule?.subscribe({
      onActive: action(() => {
        log.trace("Message subscription is active");
        if (!this.hasLoaded) {
          void this.load();
        } else {
          void this.loadNew();
        }
        this.subscribed = true;
      }),
      onEvent: action((msg) => {
        if (msg.type === "NewMessage") {
          log.trace("Loading new messages", msg);
          void this.loadNew();
        } else if (msg.type === "MetadataUpdate") {
          if (msg.payload.unreadCount !== undefined) {
            this.unread = msg.payload.unreadCount;
          } else if (msg.payload.locked !== undefined) {
            this.locked = msg.payload.locked;
            if (this.type === CHANNEL_TYPE_PRIVATE && this.mayLock === false) {
              // mayLock maps directly to the initiatePrivateMessaging permission.
              // If the user does not have this permission the permissions in the channel are
              // determined by the state of the locked flag.
              // So refresh the permissions with a GraphQL query
              void this.loadPermissions();
            }
          } else if (msg.payload.notificationsDisabled !== undefined) {
            this.notificationsEnabled = !msg.payload.notificationsDisabled;
          }
        }
      }),
      onInactive: action(() => {
        log.trace("Message subscription is inactive");
        this.subscribed = false;
      }),
      onSetupSubscription: async (webSocketId) => {
        try {
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
            mutation subscribeNewMessages(
              $webSocketId: String!,
              $profile: String!,
              $channelFilter: ChannelFilter!
            ) {
              subscribeNewMessages(input: {
                webSocketId: $webSocketId,
                profile: $profile,
                channelFilter: $channelFilter
              }) {
                subscriptionId
              }
            }
          `),
            variables: {
              channelFilter: {
                allChannelMessages: false,
                allPrivateMessages: false,
                channelIds: [this.channelId],
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeNewMessages.subscriptionId;
        } catch (err: any) {
          this.error = "Failed to subscribe to messages";
          // TODO: we would like a callback if the connection to the subscription fails
          // Load data anyway, so we at least have something even if it is not real-time updated
          void this.load();
        }
        return null;
      },
      onTearDownSubscription: (subscriptionId) => {
        try {
          void this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
            mutation unsubscribeMessages(
              $subscriptionId: ID!
            ) {
              unsubscribeMessages(input: {
                subscriptionId: $subscriptionId
              }) {
                error {
                  __typename
                  ... on AuthorizationError {
                      message
                  }
                }
              }
            }
          `),
            variables: { subscriptionId },
          });
        } catch (e) {
          log.warn(`Unable to unsubscribe from messages: ${e}`);
        }
      },
    });
  }
  private async unlockChannel(): Promise<
    | {
        __typename: "AuthorizationError";
        message?: null | string | undefined;
      }
    | {
        __typename: "NotFoundError";
        message?: null | string | undefined;
      }
    | null
  > {
    const { unlockMessageChannel } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation unlockMessageChannel(
            $channelId: ID!
          ) {
            unlockMessageChannel(input: { channelId: $channelId }) {
              error {
                __typename
                ... on AuthorizationError {
                  message
                }
                ... on NotFoundError {
                  message
                }
              }
            }
          }
        `),
        variables: {
          channelId: this.channelId,
        },
      });

    return unlockMessageChannel.error;
  }
  /* Update read marker on server to latest message we have if we should.
  If not, update unread field instead. */
  private async updateReadMarker(optionalUnreadCount?: number): Promise<void> {
    if (
      !this.hasLoaded ||
      this.isUpdatingReadMarker || // we are currently in an update operation, do not start new
      !this.markNewAsRead || // do not mark new messages as read
      this.messages.length === 0 // No messages, so unable to set unread count (lack of id...)
    ) {
      if (optionalUnreadCount !== undefined && optionalUnreadCount > 0) {
        log.debug(`Updating unread: unread=${optionalUnreadCount}`);
        this.unread = optionalUnreadCount;
      }
      return;
    }

    const newLastReadMessageId = this.messages[0].id;
    if (this.lastReadMessageId === newLastReadMessageId) {
      // We have already read to the last message (that we know of)
      return;
    }

    // Update last read message on server
    try {
      this.isUpdatingReadMarker = true;
      log.trace(`Updating unread marker to ${newLastReadMessageId}`);
      const messageUpdateReadMarker = (
        await this.state.graphqlModule.mutationDataOrThrow({
          mutation: gql(`
            mutation messageUpdateReadMarker(
              $messageId: ID!
            ) {
              messageUpdateReadMarker(input: { messageId: $messageId }) {
                error {
                  __typename
                  ... on AuthorizationError {
                    message
                  }
                  ... on NewMarkerOlderThanExistingMarkerError {
                    message
                    existingMarkerMessageId
                  }
                }
              }
            }
          `),
          variables: {
            messageId: newLastReadMessageId,
          },
        })
      ).messageUpdateReadMarker!;
      if (
        messageUpdateReadMarker.error &&
        messageUpdateReadMarker.error.__typename === "AuthorizationError"
      ) {
        // operation failed due to AuthorizationError
        log.error(messageUpdateReadMarker.error.message!);
        this.error = "Failed to mark messages as read";
        if (optionalUnreadCount !== undefined && optionalUnreadCount > 0) {
          this.unread = optionalUnreadCount;
        }
      } else if (
        messageUpdateReadMarker.error &&
        messageUpdateReadMarker.error.__typename ===
          "NewMarkerOlderThanExistingMarkerError"
      ) {
        // operation failed due to NewMarkerOlderThanExistingMarkerError
        //
        // This means that this update request tried to set the read marker for a channel to a
        // message earlier in the timeline than the currently set read marker for the channel.
        // That is not allowed, the read marker can only be moved forward in time.
        // This is expected to happen in some reload scenarios and especially if the same message
        // channel is displayed in multiple panels.
        //
        log.debug(
          "Trying to set a read marker to message older than previously set read marker"
        );
        this.error = null;
        this.unread = 0;
        // set internal read marker to the value set serverside
        this.lastReadMessageId = (
          messageUpdateReadMarker.error as { existingMarkerMessageId: string }
        ).existingMarkerMessageId;
      } else {
        // no error => successful
        this.error = null;
        this.unread = 0;
        this.lastReadMessageId = newLastReadMessageId;
      }

      this.isUpdatingReadMarker = false;
      // TODO: track if we should update again?
      //        this.updateReadMarker();
    } catch (err: any) {
      this.isUpdatingReadMarker = false;
      log.error(err);
      this.error = "Failed to mark messages as read";
      if (optionalUnreadCount !== undefined && optionalUnreadCount > 0) {
        this.unread = optionalUnreadCount;
      }
    }
  }
}
