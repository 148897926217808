import { Text } from "src/app/gui/components/Text";
import { UserIcon } from "src/app/images";
import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const RoomsPresenceIcon = connect<{
  channel: Channel;
  panel: Panel;
}>(({ channel, panel }) => {
  const presenceCount = Object.keys(channel.room!.presence).length;
  return (
    <IconButton
      onClick={() => {
        channel.openOrFocusPanel(panel, ChannelTabSetting.Presence);
      }}
      style={{
        background: presenceCount > 0 ? "#86b96982" : "#00000010",
        borderRadius: 4,
        padding: 0,
        paddingLeft: 4,
        paddingRight: 4,
      }}
      aria-label="Presence"
    >
      <UserIcon />
      <Text size={16}>{presenceCount}</Text>
    </IconButton>
  );
});
