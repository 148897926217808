import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MessageIcon } from "src/app/images";
import type { Notification } from "src/app/model/notifications/Notification";

const ellipse = (str: string, maxLength: number): string =>
  str.length > maxLength ? `${str.substr(0, maxLength - 1)}…` : str;

export class NotificationRenderer {
  public static createNotificationBody(
    notification: Notification
  ): JSX.Element | null {
    if (notification.message) {
      return NotificationRenderer.MessageNotificationBody(notification);
    }
    return null;
  }
  public static createNotificationIcon(
    notification: Notification
  ): JSX.Element | null {
    if (notification.message) {
      return NotificationRenderer.MessageNotificationIcon(notification);
    }
    return null;
  }
  private static MessageNotificationBody(
    notification: Notification
  ): JSX.Element {
    const message = notification.message!;
    const { timeSince } = notification;
    return (
      <Layout alignCenter alignMiddle padding={2} row>
        <Layout column grow padding={0}>
          <Layout alignMiddle gap={5} grow row>
            <Text bold nowrap size={12}>
              {message.from.name}
            </Text>
            {message.channel.name && (
              <Text nowrap size={12}>
                wrote in
              </Text>
            )}
            {message.channel.name && (
              <Text bold nowrap size={12}>
                {message.channel.name}
              </Text>
            )}
          </Layout>
          {message.text && <Text size={14}>{ellipse(message.text, 50)}</Text>}
          <Layout grow>
            <Text size={12}>{timeSince}</Text>
          </Layout>
        </Layout>
      </Layout>
    );
  }
  private static MessageNotificationIcon(
    notification: Notification
  ): JSX.Element {
    const message = notification.message!;
    return (
      <Layout alignCenter alignMiddle column relative>
        <MessageIcon />
        {message.channel.unreadCount > 0 && (
          <Text size={10}>{message.channel.unreadCount}</Text>
        )}
      </Layout>
    );
  }
}
