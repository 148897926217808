import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Not allowed to login with client.
 */
export class RestrictedDevice extends GroupTalkAPIError {
  public constructor() {
    super("Not allowed to login with client.", false);
  }
}
