import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import { Button, Tooltip } from "@mui/material";
import { useState } from "react";

export const GtActionButton = connect<{
  action: {
    disabled?: boolean;
    id: string;
    name: string;
    onClick: () => void;
    render: () => React.ReactElement;
    show: boolean;
  };
  style?: React.CSSProperties;
}>(({ action, style = {} }) => {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip aria-label={action.id} open={open} title={action.name}>
      <Layout
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        style={{
          cursor: action.disabled ? "default" : "pointer",
          opacity: action.disabled ? 0.5 : 1.0,
        }}
        column
      >
        <Button
          onClick={() => {
            if (!action.disabled) {
              setOpen(false);
              action.onClick();
            }
          }}
          // disabled={action.disabled}
          style={{
            background: "#616577",
            padding: 4,
            ...style,
          }}
          className="action_button"
          size="small"
          variant="contained"
        >
          {action.render()}
        </Button>
      </Layout>
    </Tooltip>
  );
});
