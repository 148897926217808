import { createResampler } from "src/audio/resampler/createResampler";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { Logger } from "src/util/Logger";
import type { Resampler } from "src/audio/resampler/Resampler";

const log = Logger.getLogger("AudioPlayback");

const FRAMES = 320;
const SAMPLE_RATE = 16000;

type AudioStream = () => Float32Array | null;

export class AudioPlayback {
  private readonly audioContext: AudioContext;
  private readonly resampler: Resampler;
  private readonly reusableBuffer = new Float32Array(FRAMES);
  private readonly sampleRate: number;
  private closed = false;
  private nextPacketNr = 0;
  private nextStartTime?: number;
  private playing = false;
  private startTime = new Date().getTime();
  private streams: AudioStream[] = [];
  public constructor(audioContext: AudioContext) {
    this.audioContext = audioContext;
    this.sampleRate = this.audioContext.sampleRate;
    this.resampler = createResampler({
      fromHz: SAMPLE_RATE,
      toHz: this.sampleRate,
    });
  }
  public playStream(stream: AudioStream): void {
    if (this.closed) {
      return;
    }
    this.streams.push(stream);
    this.ensurePlaying();
  }
  public release(): void {
    if (!this.closed) {
      this.closed = true;
      this.streams.length = 0;
    }
  }
  private ensurePlaying(): void {
    if (!this.playing) {
      this.playing = true;
      this.startTime = new Date().getTime();
      this.nextPacketNr = 0;
      this.poll();
    }
  }
  private mixStreams(audio: Float32Array[]): void {
    for (let f = 0; f < FRAMES; f += 1) {
      this.reusableBuffer[f] = audio[0][f];
      for (let s = 1; s < audio.length; s += 1) {
        this.reusableBuffer[f] += audio[s][f];
      }
    }
  }
  private playAudio(): void {
    // Upsample from 16kHz/floats (-32768 to 32767) to sampleRate/floats (-1.0 to 1.0)
    const upSampledBuffer = this.resampler.resample(this.reusableBuffer);
    const sampleCount = upSampledBuffer.length;
    for (let i = 0; i < sampleCount; i += 1) {
      let s = upSampledBuffer[i];
      if (s < -32768) {
        s = -32768;
      }
      if (s > 32767) {
        s = 32767;
      }
      upSampledBuffer[i] = s / (s < 0 ? 32768 : 32767);
    }

    if (
      !this.nextStartTime ||
      this.nextStartTime < this.audioContext.currentTime
    ) {
      this.nextStartTime = this.audioContext.currentTime + 0.05; // 50 ms buffering
    }
    const buffer = this.audioContext.createBuffer(
      1,
      upSampledBuffer.length,
      this.sampleRate
    );
    buffer.getChannelData(0).set(upSampledBuffer);

    const src = this.audioContext.createBufferSource();
    src.buffer = buffer;
    src.start(this.nextStartTime);
    src.connect(this.audioContext.destination);
    this.nextStartTime += buffer.duration;
  }
  private poll(): void {
    if (this.closed) {
      return;
    }
    this.nextPacketNr += 1;
    let audio = this.streams.map((stream) => stream());
    this.streams = this.streams.filter((_, index) => audio[index] != null);
    audio = audio.filter((p) => p != null);
    if (audio.length > 0) {
      this.mixStreams(audio as Float32Array[]);
      this.playAudio();
      this.schedulePoll();
    } else {
      this.playing = false;
      log.debug("Audio stop.");
    }
  }
  private schedulePoll(): void {
    let nextTime =
      this.startTime + this.nextPacketNr * 20 - new Date().getTime();
    if (nextTime < 0) {
      nextTime = 1;
    }
    log.trace(`Polling in ${nextTime}`);
    if (nextTime <= 1) {
      this.poll();
    } else {
      BackgroundTimer.setTimeout(() => {
        this.poll();
      }, nextTime);
    }
  }
}
