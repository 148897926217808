import { OpusSettings } from "src/app/model/settings/OpusSettings";
import { observableClass } from "src/app/state/observableClass";

export class CodecSettings {
  public readonly opus: OpusSettings;
  public constructor() {
    this.opus = new OpusSettings();
    observableClass(this);
  }
}
