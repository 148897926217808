import { Layout } from "src/app/gui/components/Layout";
import { RotatedMarker } from "src/app/gui/components/RotatedMarker";
import { Text } from "src/app/gui/components/Text";
import { leafletIcons } from "src/app/gui/panels/location/leafletIcons";
import { OnlineIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { getReadableTimeFormat } from "src/util/getReadableTimeFormat";
import L from "leaflet";
import { Tooltip } from "react-leaflet";
import type { LeafletMouseEvent } from "leaflet";
import type { DeviceLocation } from "src/app/model/location/DeviceLocation";

export const DeviceDot = connect<{
  deviceEntry: DeviceLocation;
  following: boolean;
  globalTime?: Date;
  onContextMenu: (event: LeafletMouseEvent) => void;
  onSelect: () => void;
  selected: boolean;
}>(
  ({
    deviceEntry,
    following,
    globalTime,
    onContextMenu,
    onSelect,
    selected,
  }) => {
    const myLatLng: [number, number] = [
      deviceEntry.latestLocation!.latitude,
      deviceEntry.latestLocation!.longitude,
    ];
    const emergency = deviceEntry.hasEmergencyTicket;
    const hasDirection = !!deviceEntry.latestLocation!.bearing;

    let iconType = hasDirection
      ? leafletIcons.directionDefault
      : leafletIcons.default;
    let zIndexOffset = 5000;
    if (emergency) {
      iconType = leafletIcons.emergency;
      zIndexOffset = 7000;
    } else if (!deviceEntry.online) {
      iconType = leafletIcons.offline;
    }
    if (!emergency && selected) {
      zIndexOffset = 6000;
    }
    const icon = selected ? iconType.selected : iconType.nonSelected;
    const time = new Date(deviceEntry.latestLocation!.time);
    const label = getReadableTimeFormat({
      ago: true,
      fromTime: time,
      now: globalTime,
    });

    const { status } = deviceEntry;
    const background = (status && status.backgroundColor) || "#fff";
    const textColor = status && status.foregroundColor;

    return (
      <RotatedMarker
        eventHandlers={{
          click: () => {
            onSelect();
          },
          contextmenu: (e: LeafletMouseEvent) => {
            onSelect();
            onContextMenu(e);
          },
        }}
        icon={icon}
        position={myLatLng}
        riseOnHover={false}
        rotationAngle={deviceEntry.latestLocation!.bearing}
        zIndexOffset={zIndexOffset}
      >
        <Tooltip
          className={selected ? "front-tooltip-class" : undefined}
          direction="bottom"
          offset={L.point(0, 10)}
          permanent
        >
          <Layout background={background} column padding={6}>
            <Text color={textColor || "#000"}>{deviceEntry.name}</Text>
            {selected && deviceEntry.title && (
              <Text color={textColor || "#000"} italic size={10} weight={400}>
                {deviceEntry.title}
              </Text>
            )}
            {selected && (
              <Text color={textColor || "#000"} italic size={10} weight={400}>
                {label}
              </Text>
            )}
            {selected && deviceEntry.online && (
              <Layout alignMiddle gap={2} row>
                <OnlineIcon
                  style={{
                    fill: textColor || "#095f09",
                    height: 18,
                    width: 18,
                  }}
                />
                <Text color={textColor || "#095f09"}>online</Text>
              </Layout>
            )}
            {selected && !deviceEntry.online && (
              <Layout marginTop={3}>
                <Text color={textColor || "#787878"} italic weight={400}>
                  offline
                </Text>
              </Layout>
            )}
            {selected && following && (
              <Text color={textColor || "#000"}>following</Text>
            )}
            {selected && status && (
              <Layout marginTop={3}>
                <Text color={textColor || "#000"}>{status.name}</Text>
              </Layout>
            )}
          </Layout>
        </Tooltip>
      </RotatedMarker>
    );
  }
);
