import { CreateGtTable } from "src/app/gui/components/CreateGtTable";
import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Group } from "src/app/gui/panels/channels/Group";
import { Preview } from "src/app/gui/panels/customizing/Preview";
import { ChannelsTabSetting } from "src/app/model/channels/ChannelsTabSetting";
import { Presence } from "src/app/model/presence/Presence";
import { connect } from "src/app/state/connect";
import { PresenceType } from "src/lib/types/PresenceType";
import { SessionType } from "src/lib/types/SessionType";
import type { GtTableRow } from "src/app/gui/components/GtTableRow";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

const GtTable = CreateGtTable<GtTableRow>();
export const Settings = connect<{
  panel: Panel;
}>((props, state) => {
  const { panel } = props;
  const {
    detailedView,
    mayToggleDetailedView,
    mayToggleShowDescription,
    mayToggleShowDispatcherPresence,
    mayToggleShowGroupSymbol,
    mayToggleShowLatestSpeaker,
    mayToggleShowMessages,
    mayToggleShowOrganization,
    mayToggleShowPresence,
    mayToggleShowPttButton,
    mayToggleShowReplayButton,
    mayToggleShowVolumeControls,
    showDescription,
    showDispatcherPresence,
    showGroupSymbol,
    showLatestSpeaker,
    showMessages,
    showOrganization,
    showPresence,
    showPttButton,
    showReplayButton,
    showVolumeControls,
    toggleDetailedView,
    toggleShowDescription,
    toggleShowDispatcherPresence,
    toggleShowGroupSymbol,
    toggleShowLatestSpeaker,
    toggleShowMessages,
    toggleShowOrganization,
    toggleShowPresence,
    toggleShowPttButton,
    toggleShowReplayButton,
    toggleShowVolumeControls,
  } = panel.channelsPanelData!;
  // TODO: Make a real channel and session objects which are type-safe.
  const dummyChannel: any = {
    description: "Description",
    isFavorite: () => false,
    joined: true,
    name: "Group West",
    openOrFocusPanel: () => {},
    owner: "Pretty Good Security INC",
    session: {
      broadcastPttDown: () => {},
      broadcastPttUp: () => {},
      channel: { openOrFocusPanel: () => {} },
      configuredVolume: 100,
      description: "Description",
      focus: () => {},
      latestSpeakerPresence: {
        name: "Braxton Barnet",
        presenceId: 0,
        title: "Guard 1",
        type: PresenceType.Mobile,
      },
      latestSpeakerTime: null,
      mayReplayTalkburst: true,
      name: "Group West",
      openMap: () => {},
      openMessages: () => {},
      percentActive: 1,
      presence: [
        {
          name: "Braxton Barnet",
          presenceId: 0,
          title: "Guard 1",
          type: PresenceType.Mobile,
        },
        {
          name: "Jennifer Ledford",
          presenceId: 1,
          title: "Guard 2",
          type: PresenceType.Mobile,
        },
        {
          name: "Debby Aston",
          presenceId: 2,
          title: "Office",
          type: PresenceType.Dispatcher,
        },
      ].map(
        (p) =>
          new Presence(state, {
            ...p,
            alarmActive: false,
            sessionId: -1,
            unavailable: false,
          })
      ),
      pttDown: () => Promise.resolve(),
      pttUp: () => {},
      replayTalkburst: () => {},
      setMuted: () => {},
      setSolo: () => {},
      setVolume: () => {},
      timeSinceLastActive: "5 sec ago",
      type: SessionType.Group,
    },
    toggleFavorite: () => {},
    unreadCount: 8,
    updateJoined: () => {},
  };
  return (
    <Layout column gap={5}>
      <Text>Set appearance for groups in this panel:</Text>
      <GtTable
        columns={[
          {
            id: "value",
            render: ({ checked, disabled, label }) => (
              <Layout alignLeft alignMiddle row>
                <GtCheckbox checked={checked} disabled={disabled} />
                <Text>{label}</Text>
              </Layout>
            ),
          },
        ]}
        rows={[
          {
            checked: detailedView,
            disabled: !mayToggleDetailedView,
            id: "detailedView",
            label: "Detailed view",
            onClickRow: () => toggleDetailedView(),
          },
          {
            checked: showGroupSymbol,
            disabled: !mayToggleShowGroupSymbol,
            id: "showGroupSymbol",
            label: "Show group symbol",
            onClickRow: () => toggleShowGroupSymbol(),
          },
          {
            checked: showPttButton,
            disabled: !mayToggleShowPttButton,
            id: "showPttButton",
            label: "Show ptt button",
            onClickRow: () => toggleShowPttButton(),
          },
          {
            checked: showOrganization,
            disabled: !mayToggleShowOrganization,
            id: "showOrganization",
            label: "Show organization",
            onClickRow: () => toggleShowOrganization(),
          },
          {
            checked: showReplayButton,
            disabled: !mayToggleShowReplayButton,
            id: "showReplayButton",
            label: "Show replay button",
            onClickRow: () => toggleShowReplayButton(),
          },
          {
            checked: showVolumeControls,
            disabled: !mayToggleShowVolumeControls,
            id: "showVolumeControls",
            label: "Show volume controls",
            onClickRow: () => toggleShowVolumeControls(),
          },
          {
            checked: showLatestSpeaker,
            disabled: !mayToggleShowLatestSpeaker,
            id: "showLatestSpeaker",
            label: "Show latest speaker",
            onClickRow: () => toggleShowLatestSpeaker(),
          },
          {
            checked: showMessages,
            disabled: !mayToggleShowMessages,
            id: "showMessages",
            label: "Show unread messages",
            onClickRow: () => toggleShowMessages(),
          },
          {
            checked: showPresence,
            disabled: !mayToggleShowPresence,
            id: "showPresence",
            label: "Show presence",
            onClickRow: () => toggleShowPresence(),
          },
          {
            checked: showDispatcherPresence,
            disabled: !mayToggleShowDispatcherPresence,
            id: "showDispatcherPresence",
            label: "Show dispatcher presence",
            onClickRow: () => toggleShowDispatcherPresence(),
          },
          {
            checked: showDescription && !detailedView,
            disabled: !mayToggleShowDescription || detailedView,
            id: "showDescription",
            label: "Show description",
            onClickRow: () => toggleShowDescription(),
          },
        ]}
        getRowKey={(row) => row.id}
        onClickRow={(row) => row.onClickRow()}
      />
      <Preview>
        <Group
          channel={dummyChannel as Channel}
          detailedView={detailedView}
          groupFilter={ChannelsTabSetting.All}
          isDummy={true}
          panel={panel}
          showDescription={showDescription}
          showDispatcherPresence={showDispatcherPresence}
          showGroupSymbol={showGroupSymbol}
          showLatestSpeaker={showLatestSpeaker}
          showMessages={showMessages}
          showOrganization={showOrganization}
          showPresence={showPresence}
          showPttButton={showPttButton}
          showReplayButton={showReplayButton}
          showVolumeControls={showVolumeControls}
        />
      </Preview>
    </Layout>
  );
});
