import { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";

export class StartupState extends QueueState {
  public constructor(alertService: QueueAlertService) {
    super(alertService, "StartupState");
    this.pendingSubscriptionsCount = 0;
  }
  public subscriptionEvent(flag: boolean): void {
    this.pendingSubscriptionsCount += flag ? 1 : -1;
  }
}
