import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Talkburst transmission denied.
 */
export class TransmissionDenied extends GroupTalkAPIError {
  public constructor() {
    super("Talkburst transmission denied.", true);
  }
}
