import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Service unavailable.
 */
export class ServiceUnavailable extends GroupTalkAPIError {
  public constructor() {
    super("Service unavailable.", true);
  }
}
