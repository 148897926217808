import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { GroupInfo } from "src/app/gui/panels/channels/GroupInfo";
import { LatestSpeaker } from "src/app/gui/panels/channels/LatestSpeaker";
import {
  GroupIcon,
  BroadcastIcon,
  ConferenceGroupIcon,
  PatchIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const GroupRow = connect<{
  channel: Channel;
  evenRow?: boolean;
  isDummy?: boolean;
  panel: Panel;
  showDescription: boolean;
  showDispatcherPresence?: boolean;
  showFavoriteButton: boolean;
  showGroupSymbol: boolean;
  showLatestSpeaker: boolean;
  showLockbutton?: boolean;
  showMessages: boolean;
  showOrganization: boolean;
  showPatchIcon?: boolean;
  showPowerbutton: boolean;
  showPowerbuttonWithMenu: boolean;
  showPresence: boolean;
  showPttButton: boolean;
  showReplayButton: boolean;
  showSpaces: boolean;
  showVolumeControls: boolean;
}>(
  ({
    channel,
    evenRow,
    isDummy,
    panel,
    showDescription,
    showDispatcherPresence,
    showFavoriteButton,
    showGroupSymbol,
    showLatestSpeaker,
    showLockbutton,
    showMessages,
    showOrganization,
    showPatchIcon,
    showPowerbutton,
    showPowerbuttonWithMenu,
    showPresence,
    showPttButton,
    showReplayButton,
    showSpaces,
    showVolumeControls,
  }) => {
    let bg = evenRow ? "#7d91aa" : "#7789a2";
    const { hasPatch, isFullDuplex, patchColor, session } = channel;
    const focused = session && session.isFocused;
    const maySelectForBroadcasting =
      session && session.maySelectForBroadcasting;
    let Icon: typeof ConferenceGroupIcon | typeof GroupIcon | typeof PatchIcon =
      GroupIcon;
    if (isFullDuplex) {
      Icon = ConferenceGroupIcon;
    } else if (hasPatch) {
      Icon = PatchIcon;
    }
    if (focused) {
      bg = "#5479b1";
    }
    return (
      <Layout
        onClick={(event) => {
          if (session && event.target === event.currentTarget) {
            session.focus();
          }
        }}
        style={{
          borderRadius: focused ? 5 : 0,
          cursor: session ? "pointer" : "default",
        }}
        alignMiddle
        background={bg}
        margin={0}
        padding={2}
        row
      >
        {showGroupSymbol && (!maySelectForBroadcasting || isFullDuplex) && (
          <Icon
            onClick={() => {
              if (session) {
                session.focus();
              }
            }}
            style={{
              background: patchColor != null ? patchColor : undefined,
              borderRadius: 4,
              fill: channel.joined ? "#143e1b" : "#00000045",
              marginRight: 10,
            }}
          />
        )}
        {maySelectForBroadcasting && !isFullDuplex && (
          <Layout row>
            <BroadcastIcon style={{ fill: "#1d568c", height: 32, width: 32 }} />
            <GtCheckbox
              checked={session.selectedForBroadcasting}
              onChange={() => session.toggleSelectForBroadcast()}
            />
          </Layout>
        )}
        <Layout
          onClick={() => {
            if (session) {
              session.focus();
            }
          }}
          column
        >
          <Layout row>
            {channel.owner && showOrganization && (
              <Layout column marginRight={5}>
                <Text
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  color="#ffbf00"
                  nowrap
                  size={14}
                >
                  {`${channel.owner}: `}
                </Text>
              </Layout>
            )}
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              color="#000"
              nowrap
              size={14}
            >
              {channel.name}
            </Text>
          </Layout>
          {showDescription && (
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              color="#474747"
              nowrap
              size={10}
            >
              {channel.description}
            </Text>
          )}
        </Layout>
        <Layout column grow />
        {showLatestSpeaker &&
          channel.session &&
          !channel.session.outgoingTalkburst &&
          channel.session.percentActive > 0 &&
          channel.session.latestSpeakerPresence && (
            <LatestSpeaker panel={panel} session={channel.session} />
          )}
        <GroupInfo
          channel={channel}
          isDummy={isDummy}
          mini
          panel={panel}
          showDispatcherPresence={showDispatcherPresence}
          showFavoriteButton={showFavoriteButton}
          showFullDuplexButtons={showPttButton && isFullDuplex}
          showLockbutton={showLockbutton}
          showMessages={showMessages}
          showPatchIcon={showPatchIcon}
          showPowerbutton={showPowerbutton}
          showPowerbuttonWithMenu={showPowerbuttonWithMenu}
          showPresence={showPresence && !isFullDuplex}
          showPttButton={showPttButton && !isFullDuplex}
          showReplayButton={showReplayButton && !isFullDuplex}
          showRoomsPresence={showPresence && isFullDuplex}
          showSpaces={showSpaces}
          showVolumeControls={showVolumeControls && !isFullDuplex}
        />
      </Layout>
    );
  }
);
