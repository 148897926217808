import { gql } from "src/app/graphql";
import { Constants } from "src/app/model/Constants";
import { Patch } from "src/app/model/patches/Patch";
import { PatchColors } from "src/app/model/patches/PatchColors";
import { observableClass } from "src/app/state/observableClass";
import { PanelType } from "src/app/types/PanelType";
import { TemplateId } from "src/app/types/TemplateId";
import { Logger } from "src/util/Logger";
import localStorage from "mobx-localstorage";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { PatchInfo } from "src/nextgen/types/PatchInfo";

const log = Logger.getLogger("Patches");

export class Patches {
  public loading = true;
  private readonly colors: PatchColors;
  /**
   * This is a list patches created by logged in user. These are global for the application
   * Other patch objects may be stored in various panels.
   */
  private myPatches: Record<string, Patch> = {};
  public constructor(private readonly state: State) {
    this.colors = new PatchColors(state);
    observableClass(this);
  }
  public async createPatch(): Promise<void> {
    log.debug("Creating new patch...");
    const patchId = await this.mutationCreatePatch();
    log.debug(`Created patch with id ${patchId}`);
    // TODO: error handling
  }
  public getColorForPatchId(patchId: string): Promise<string | undefined> {
    return this.colors.getColorForPatchId(patchId);
  }
  public startManagingPatches(): void {
    // This subscription is never released
    this.state.online?.subscriptionModule?.subscribe({
      onActive: () => {
        void this.fetchPatches();
      },
      onEvent: (msg) => {
        log.trace("Patch updated", msg);
        const { patchId } = msg.payload;
        if (
          msg.type === "ClientPatchCreated" ||
          msg.type === "ClientPatchGroupAdded" ||
          msg.type === "ClientPatchGroupRemoved"
        ) {
          // Lookup the patch, and possibly create a new panel for it
          void this.fetchPatch(patchId, msg.type === "ClientPatchCreated");
        } else if (msg.type === "ClientPatchDeleted") {
          // Remove the patch from our list and make sure panel is closed
          delete this.myPatches[patchId];
          this.updatePanels();
        }
      },
      onSetupSubscription: async (webSocketId) => {
        const data = await this.state.graphqlModule.mutationDataOrThrow({
          mutation: gql(`
            mutation subscribePatchChanges(
              $webSocketId: String!,
            ) {
              subscribePatchChanges(input: {
                webSocketId: $webSocketId,
              }) {
                subscriptionId
              }
            }
          `),
          variables: {
            webSocketId,
          },
        });
        return data.subscribePatchChanges.subscriptionId;
      },
      onTearDownSubscription: (subscriptionId) => {
        try {
          void this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
              mutation unsubscribePatchChanges(
                $subscriptionId: ID!
              ) {
                unsubscribePatchChanges(input: {
                  subscriptionId: $subscriptionId
                }) {
                  error {
                    __typename
                    ... on AuthorizationError {
                        message
                    }
                  }
                }
              }
            `),
            variables: { subscriptionId },
          });
        } catch (e) {
          log.warn(`Unable to unsubscribe from patch changes: ${e}`);
        }
      },
    });
  }
  // Ensure that we have one panel open for each patch we own, and no other patch panels
  public updatePanels(): void {
    // Ensure existing panels without patches are closed and the other should have a patch set.
    this.patchPanels.forEach((panel) => {
      if (panel.patchPanelData?.parameters.patchId) {
        const patchForPanel =
          this.myPatches[panel.patchPanelData.parameters.patchId];
        if (!patchForPanel) {
          log.debug(
            `Closing panel ${panel.id} since no patch ${panel.patchPanelData.parameters.patchId} exists anymore`
          );
          this.state.layout.closePanel(panel.id);
        } else {
          log.debug(`Updating panel ${panel.id} with patch:`, patchForPanel);
          panel.patchPanelData.setPatch(patchForPanel);
        }
      }
    });
    // Ensure panels are created for patches without panel
    Object.values(this.myPatches)
      .filter((patch) => !this.patchPanel(patch.patchId))
      .forEach((newPatch) => {
        // Open new patch panel
        const patchName = this.freePatchName();
        const panel = this.state.panels.add({
          customData: {
            patchId: newPatch.patchId,
            patchName,
          },
          name: `Patch ${patchName}`,
          panelId:
            localStorage.getItem(Constants.LAST_CLOSED_PATCH_TAB) || null,
          templateId: TemplateId.patch,
        });
        panel.patchPanelData?.setPatch(newPatch);
      });
  }
  private get patchPanels(): Panel[] {
    return Object.values(this.state.panels.list).filter(
      (panel) => panel.type === PanelType.patch
    );
  }
  private async fetchPatch(patchId: string, created: boolean): Promise<void> {
    try {
      log.debug(`fetching patch ${patchId}`);
      const patch = (await this.queryPatch(patchId)) as PatchInfo;
      if (patch) {
        log.debug("Fetched patch", patch);
        this.myPatches[patch.id] = new Patch(this.state, patch);
        this.updatePanels();
        if (created) {
          const panel = this.patchPanel(patchId);
          if (panel && panel.patchPanelData) {
            panel.patchPanelData.editing = true;
          }
        }
      } else {
        log.debug("Failed to fetched patch", patchId);
        // TODO: handle
      }
    } catch (err: any) {
      log.error(err);
    }
  }
  private async fetchPatches(): Promise<void> {
    try {
      log.debug("fetching patches");
      this.loading = true;
      const patches = (await this.queryClientPatches()) as PatchInfo[];
      this.loading = false;
      if (patches) {
        log.debug("Fetched patches", patches);
        // Add to or replace myPatches with received patches
        patches.forEach((p) => {
          log.debug(`Adding new patch ${p.id}`);
          this.myPatches[p.id] = new Patch(this.state, p);
        });
        // Remove any existing patches not received
        Object.keys(this.myPatches)
          .filter((mpId) => !patches.find((p) => p.id === mpId))
          .forEach((mpId) => {
            log.debug(`Removing patch ${mpId} that is now gone`);
            delete this.myPatches[mpId];
          });

        // Ensure that panels reflect our patches
        this.updatePanels();
      }
    } catch (err: any) {
      log.error(err);
      // TODO: schedule retry
    }
  }
  private freePatchName(): string {
    let idx = 1;
    const existing = this.patchPanels.map(
      (pp) => pp.patchPanelData?.parameters.patchName
    );
    while (existing.includes(`${idx}`)) {
      idx += 1;
    }
    return `${idx}`;
  }
  private async mutationCreatePatch(): Promise<string | undefined> {
    const { createPatch } = await this.state.graphqlModule.mutationDataOrThrow({
      mutation: gql(`
        mutation createPatch
        {
          createPatch {
            patchId
            error {
              __typename
              ... on ErrorInterface {
                message
              }
            }
          }
        }
      `),
      variables: {},
    });
    log.info("Created patch", createPatch);
    if (createPatch.error) {
      // TODO: handle errors
    }
    return createPatch.patchId ?? undefined;
  }
  private patchPanel(patchId: string): Panel | undefined {
    return this.patchPanels.find(
      (pp) => pp.patchPanelData?.parameters.patchId === patchId
    );
  }
  private async queryClientPatches(): Promise<PatchInfo[]> {
    const clientPatches = (
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "no-cache",
        query: gql(`
          query clientPatches
          {
            clientPatches {
              ... PatchFields
            }
        }`),
        variables: {},
      })
    ).clientPatches;
    return clientPatches;
  }
  private async queryPatch(patchId: string): Promise<PatchInfo | null> {
    const clientPatch = (
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "no-cache",
        query: gql(`
          query clientPatch(
            $patchId: ID!
          ) {
            clientPatch(id: $patchId) {
              ...PatchFields
            }
          }
        `),
        variables: {
          patchId,
        },
      })
    ).clientPatch;
    return clientPatch;
  }
}
