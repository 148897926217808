import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import type { Status } from "src/lib/types/Status";

export const StatusBadge = connect<{
  defaultBackground?: boolean;
  statusOption: Status;
}>(({ defaultBackground, statusOption }) => {
  const { backgroundColor, foregroundColor, name } = statusOption;
  return (
    <Layout
      background={
        backgroundColor || (defaultBackground ? "#88888824" : undefined)
      }
      style={{
        borderRadius: 5,
        width: "100%",
      }}
      alignCenter
      column
      padding={5}
    >
      <Text center color={foregroundColor || "#000"}>
        {name}
      </Text>
    </Layout>
  );
});
