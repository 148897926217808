import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { EncryptionParameters } from "src/lib/types/EncryptionParameters";

const log = Logger.getLogger("UdpModule");
/**
 * Returned from <code>setupUdpModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @private
 * @namespace
 */
export class UdpModule {
  /**
   * Parameters that client uses when encrypting data to server.
   * @member {EncryptionParameters}
   */
  public readonly encryptionParameters?: EncryptionParameters;
  /**
   * Id that client must use when sending UDP data to server.
   * @member {Number}
   */
  public readonly udpId: number;
  /**
   * Callback on start keep open. Will supply the interval between sequential keep open packets
   * in milliseconds as the first argument.
   * @member {function(integer)}
   */
  private readonly onStartKeepOpen: (interval: number) => void;
  /**
   * Callback on stop keep open.
   * @member {function()}
   */
  private readonly onStopKeepOpen: () => void;
  private constructor(options: {
    encryptionParameters?: EncryptionParameters;
    onStartKeepOpen: (interval: number) => void;
    onStopKeepOpen: () => void;
    udpId: number;
  }) {
    this.encryptionParameters = options.encryptionParameters;
    this.udpId = options.udpId;
    this.onStartKeepOpen = options.onStartKeepOpen;
    this.onStopKeepOpen = options.onStopKeepOpen;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      encryptionParameters: EncryptionParameters;
      onStartKeepOpen: (interval: number) => void;
      onStopKeepOpen: () => void;
    }
  ): Promise<UdpModule> {
    const response = (await requestManager.send({
      udp: {
        setupRequest: { encryptionParameters: options.encryptionParameters },
      },
    })) as proto.IUDPModuleSetupResponse;
    log.debug("udp module setup.", response);
    return new UdpModule({
      encryptionParameters: response.encryptionParameters ?? undefined,
      onStartKeepOpen: options.onStartKeepOpen,
      onStopKeepOpen: options.onStopKeepOpen,
      udpId: longToNumber(response.udpId),
    });
  }
  public onRequest(
    message: proto.IUDPAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.startKeepOpen) {
      this.onStartKeepOpenRequest(message.startKeepOpen, respond);
    } else if (message.stopKeepOpen) {
      this.onStopKeepOpenRequest(message.stopKeepOpen, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  private onStartKeepOpenRequest(
    message: proto.IUDPStartKeepOpenRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onStartKeepOpen) {
      this.onStartKeepOpen(message.interval);
    }
  }
  private onStopKeepOpenRequest(
    _: proto.IUDPStopKeepOpenRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onStopKeepOpen) {
      this.onStopKeepOpen();
    }
  }
}
