import { App } from "src/app/gui/App";
import { State } from "src/app/model/State";
import { Provider } from "src/app/state/Provider";
import { configure } from "mobx";
import { render } from "react-dom";
import "src/app/model/location/leafletSetup";
import "src/app/styles/index.css";
import "src/app/styles/flexlayout_dark.css";
import "src/app/styles/flexlayout_gt.css";
import type { GtConfig } from "src/app/types/GtConfig";

configure({ computedRequiresReaction: true, enforceActions: "always" });

// These are some variables used on global window object
declare global {
  interface Window {
    audioSource: MediaStreamAudioSourceNode;
    gtConfig: GtConfig;
    nodeReference: AudioWorkletNode;
    webkitAudioContext: any;
  }
}

render(
  <Provider model={State}>
    <App />
  </Provider>,
  document.getElementById("App")
);
