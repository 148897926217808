import { GtError } from "src/app/gui/components/GtError";
import { GtPanelTab } from "src/app/gui/components/GtPanelTab";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { PTTButton } from "src/app/gui/panels/channels/detailedGroup/PTTButton";
import { VolumeControls } from "src/app/gui/panels/channels/detailedGroup/VolumeControls";
import { ReplayButton } from "src/app/gui/panels/channels/ReplayButton";
import { Actions } from "src/app/gui/panels/contact/Actions";
import { EndCallButton } from "src/app/gui/panels/contact/EndCallButton";
import { ContactIcon } from "src/app/gui/panels/contacts/ContactIcon";
import { Map } from "src/app/gui/panels/location/Map";
import { Messages } from "src/app/gui/panels/messages/Messages";
import { FullDuplexSession } from "src/app/gui/panels/video/FullDuplexSession";
import {
  MessageIcon,
  LocationIcon,
  InfoIcon,
  OnlineIcon,
  OfflineIcon,
  FullDuplexIcon,
} from "src/app/images";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const Contact = connect<{
  panel: Panel;
}>(({ panel }) => {
  const {
    contact,
    contactTab,
    contactTabIsEnabled,
    hasFullDuplexRoom,
    hasMessaging,
    id,
    locationData,
    messageChannel,
    setContactTab,
  } = panel.contactPanelData!;

  if (!contact && !messageChannel) {
    return (
      <Layout column>
        <GtError message="No contact selected" severity="info" />
      </Layout>
    );
  }

  return (
    <Layout column grow style={{ overflow: "hidden" }}>
      <Layout alignMiddle height={55} marginBottom={12} row>
        {!hasFullDuplexRoom &&
          contact &&
          contact.session &&
          contact.session.presence.length > 0 && (
            <PTTButton session={contact.session} showLatestSpeaker={false} />
          )}
        {!hasFullDuplexRoom && contact && contact.session && (
          <ReplayButton session={contact.session} />
        )}
        {!hasFullDuplexRoom && contact && contact.session && (
          <EndCallButton session={contact.session} />
        )}
        {contact && !(contact.session && !contact.session.isFullDuplex) && (
          <Layout row>
            <ContactIcon />
            <Layout column>
              <Text
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                bold
                color="#000"
                nowrap
                size={14}
              >
                {contact.name}
              </Text>
              {contact.description && (
                <Text
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  color="#474747"
                  nowrap
                  size={10}
                >
                  {contact.description}
                </Text>
              )}
            </Layout>
          </Layout>
        )}
        <Layout grow row />
        <GtPanelTab
          onClick={() => {
            setContactTab(ContactTabSetting.Call);
          }}
          Icon={FullDuplexIcon}
          disabled={!contactTabIsEnabled(ContactTabSetting.Call)}
          hideIfDisabled={true}
          id={`show_call_${id}`}
          selected={contactTab === ContactTabSetting.Call}
          tooltip="Show ongoing call"
        />
        <GtPanelTab
          onClick={() => {
            setContactTab(ContactTabSetting.Actions);
          }}
          Icon={InfoIcon}
          disabled={!contactTabIsEnabled(ContactTabSetting.Actions)}
          id={`show_info_${id}`}
          selected={contactTab === ContactTabSetting.Actions}
          tooltip="Show info"
        />
        <GtPanelTab
          onClick={() => {
            if (contactTab !== ContactTabSetting.Messages) {
              setContactTab(ContactTabSetting.Messages);
            } else {
              messageChannel?.setScroll(true);
            }
          }}
          Icon={MessageIcon}
          disabled={!hasMessaging}
          highlightNumber
          id={`show_messages_${id}`}
          number={messageChannel == null ? 0 : messageChannel.unread}
          positiveOnly
          selected={contactTab === ContactTabSetting.Messages}
          tooltip="Show messages"
        />
        <GtPanelTab
          onClick={() => {
            setContactTab(ContactTabSetting.Locations);
          }}
          Icon={LocationIcon}
          disabled={!contactTabIsEnabled(ContactTabSetting.Locations)}
          id={`show_locations_${id}`}
          selected={contactTab === ContactTabSetting.Locations}
          tooltip="Show locations"
        />
      </Layout>
      {!hasFullDuplexRoom && contact && contact.session && (
        <Layout column marginBottom={12} width={200}>
          <VolumeControls session={contact.session} />
        </Layout>
      )}
      {contact && contact.online !== null && (
        <Layout
          alignCenter
          alignMiddle
          background={contact.online ? "#0032001a" : "#3200001a"}
          column
          marginBottom={12}
          padding={2}
          style={{ borderRadius: 5 }}
        >
          {contact.online && (
            <Layout alignMiddle row>
              <OnlineIcon
                style={{ borderRadius: 5, fill: "rgb(0 38 0)", marginRight: 5 }}
              />
              <Text color="#2b5141" weight={500}>
                online
              </Text>
            </Layout>
          )}
          {!contact.online && (
            <Layout alignMiddle row>
              <OfflineIcon
                style={{ borderRadius: 5, fill: "#444", marginRight: 5 }}
              />
              <Text color="#444" weight={500}>
                offline
              </Text>
            </Layout>
          )}
        </Layout>
      )}
      <Layout
        alignTop
        className="custom-scroll"
        column
        grow
        style={{ overflowY: "auto" }}
      >
        {contactTab === ContactTabSetting.Call &&
          contactTabIsEnabled(ContactTabSetting.Call) && (
            <FullDuplexSession contact={contact!} panel={panel} />
          )}
        {contactTab === ContactTabSetting.Actions &&
          contactTabIsEnabled(ContactTabSetting.Actions) && (
            <Actions contact={contact!} panel={panel} />
          )}
        {contactTab === ContactTabSetting.Messages &&
          contactTabIsEnabled(ContactTabSetting.Messages) &&
          messageChannel && (
            <Messages messageChannel={messageChannel} panel={panel} />
          )}
        {contactTab === ContactTabSetting.Locations &&
          contactTabIsEnabled(ContactTabSetting.Locations) && (
            <Map locationData={locationData!} />
          )}
      </Layout>
    </Layout>
  );
});
