import { gql } from "src/app/graphql";
import { Channel } from "src/app/model/channels/Channel";
import { CHANNELS_PER_FETCH } from "src/app/model/channels/Channels";
import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { observableClass } from "src/app/state/observableClass";
import { PanelType } from "src/app/types/PanelType";
import { TemplateId } from "src/app/types/TemplateId";
import localStorage from "mobx-localstorage";
import type { GroupType, LockState } from "src/app/graphql/graphql";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { PageInfo } from "src/app/types/PageInfo";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { ChannelModule } from "src/lib/modules/ChannelModule";
import type { ChannelNodeId } from "src/lib/types/ChannelNodeId";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { OrganizationUuid } from "src/nextgen/types/OrganizationUuid";

const SOLO_CHANNEL_KEY_PREFIX = "gt2.solo.channel";
const MUTE_CHANNEL_KEY_PREFIX = "gt2.mute.channel";
const VOLUME_CHANNEL_KEY_PREFIX = "gt2.volume.channel";

export class ChannelsHandler {
  private channelModule?: ChannelModule;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public get channelWithUuid() {
    return async (uuid: ChannelUuid): Promise<Channel | undefined> => {
      const clientGroup = await this.queryClientGroup(uuid);
      return clientGroup != null
        ? new Channel(this.state, clientGroup)
        : undefined;
    };
  }
  public openOrFocusChannelInPanel({
    id,
    name,
    panel,
    tab,
  }: {
    id: ChannelUuid;
    name: string;
    panel?: Panel;
    tab: ChannelTabSetting;
  }): void {
    const existingPanel = this.state.panels.find(
      (p: Panel) =>
        p.type === PanelType.channel &&
        p.channelPanelData?.channel !== undefined &&
        p.channelPanelData.channel.channelUuid === id
    );
    if (existingPanel) {
      existingPanel.select();
      if (tab) {
        existingPanel.channelPanelData!.setGroupTab(tab);
        if (tab === ChannelTabSetting.Messages) {
          if (existingPanel.channelPanelData!.messageChannel) {
            existingPanel.channelPanelData!.messageChannel.setScroll(true);
          }
        }
      }
      existingPanel.flash();
    } else {
      const useTab = tab || ChannelTabSetting.Messages;
      const newPanel = this.state.panels.add({
        customData: {
          channelId: id,
          groupTab: useTab,
        },
        name,
        panel,
        templateId: TemplateId.channel,
      });
      newPanel.channelPanelData!.setGroupTab(useTab);
    }
  }
  public async queryUpdateSelectionData({
    groupIds,
  }: {
    groupIds: ChannelUuid[];
  }): Promise<{
    items: {
      id: string;
      lock: LockState;
      pttSelection: {
        scanned: boolean;
        selected: boolean;
      };
    }[];
    pageInfo: PageInfo;
  }> {
    const { clientGroups } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientGroupsBasicFields(
          $pagination: OffsetLimitInput!,
          $filter: ClientGroupsFilterInput
        ) {
          clientGroups(pagination: $pagination, filter: $filter) {
            items {
              id
              lock
              pttSelection(profile: "dispatcher") {
                scanned
                selected
              }
            }
            pageInfo {
              totalItems
              offset
              limit
            }
          }
        }
      `),
      variables: {
        filter: {
          groupIds,
        },
        pagination: {
          limit: CHANNELS_PER_FETCH,
          offset: 0,
        },
      },
    });
    return clientGroups;
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    const channelModule = await authenticatedModule.setupChannelModule();
    this.channelModule = channelModule;
    // Leave all scanned and autojoined groups
    void this.channelModule.leaveAll(false, true, true);
  }
  public async updateJoined(
    channelId: ChannelNodeId,
    joined: boolean
  ): Promise<void> {
    if (this.channelModule) {
      await this.channelModule.updateJoined(channelId, joined);
    }
  }
  public async updateScanned(
    channelId: ChannelNodeId,
    joined: boolean
  ): Promise<void> {
    if (this.channelModule) {
      await this.channelModule.updateScanned(channelId, joined);
    }
  }
  public static get savedChannelMute() {
    return (channelUuid: string): boolean => {
      return (
        localStorage.getItem(`${MUTE_CHANNEL_KEY_PREFIX}.${channelUuid}`) ??
        false
      );
    };
  }
  public static get savedChannelSolo() {
    return (channelUuid: string): boolean => {
      return (
        localStorage.getItem(`${SOLO_CHANNEL_KEY_PREFIX}.${channelUuid}`) ??
        false
      );
    };
  }
  public static get savedChannelVolume() {
    return (channelUuid: string): number => {
      return (
        localStorage.getItem(`${VOLUME_CHANNEL_KEY_PREFIX}.${channelUuid}`) ??
        100
      );
    };
  }
  public static setSavedChannelMute(channelUuid: string, mute: boolean): void {
    localStorage.setItem(`${MUTE_CHANNEL_KEY_PREFIX}.${channelUuid}`, mute);
  }
  public static setSavedChannelSolo(channelUuid: string, solo: boolean): void {
    localStorage.setItem(`${SOLO_CHANNEL_KEY_PREFIX}.${channelUuid}`, solo);
  }
  public static setSavedChannelVolume(
    channelUuid: string,
    volume: number
  ): void {
    localStorage.setItem(`${VOLUME_CHANNEL_KEY_PREFIX}.${channelUuid}`, volume);
  }
  private async queryClientGroup(id: ChannelUuid): Promise<{
    description: null | string;
    displayName: string;
    entityId: ChannelNodeId | null;
    id: ChannelUuid;
    lock: LockState;
    messageChannel: {
      unreadCount: number;
    } | null;
    organization: { id: OrganizationUuid; name: string };
    patch: { id: string } | null;
    pttSelection: {
      scanned: boolean;
      selected: boolean;
    };
    type: GroupType;
  } | null> {
    const { clientGroup } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientGroupFullChannelFields(
          $id: ID!
        ) {
          clientGroup(id: $id) {
            id
            type
            entityId
            displayName
            description
            organization {
              name
              id
            }
            messageChannel {
              unreadCount
            }
            lock
            pttSelection(profile: "dispatcher") {
              scanned
              selected
            }
            patch {
              id
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientGroup;
  }
}
