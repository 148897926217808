import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ContactMenu } from "src/app/gui/panels/contacts/ContactMenu";
import { StatusBadge } from "src/app/gui/panels/contacts/StatusBadge";
import { MessageButton } from "src/app/gui/shared/MessageButton";
import { PresenceIcon } from "src/app/gui/shared/PresenceIcon";
import { AlarmIcon, TimeIcon } from "src/app/images";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";
import type { Presence } from "src/app/model/presence/Presence";

export const PresenceRow = connect<{
  evenRow: boolean;
  panel: Panel;
  presence: Presence;
}>(({ evenRow, panel, presence }) => {
  const {
    alarmActive,
    contactDetails,
    fetchContactDetails,
    isLatestSpeaker,
    isSpeaking,
    mayOpenAsPanel,
    name,
    title,
    type,
    unavailable,
  } = presence;
  const nameColor = isSpeaking || alarmActive ? "#fff" : "#000";
  const titleColor = isSpeaking || alarmActive ? "#fff" : "#474747";
  const iconFill = isSpeaking || alarmActive ? "#fff" : "#333";
  const iconStyle = {
    fill: iconFill,
    marginLeft: 10,
    marginRight: 10,
    transition: "all .4s",
  };
  const showDescription = true;
  const bg = (() => {
    if (alarmActive) {
      return "#d13e3e";
    }
    if (isSpeaking) {
      return "#33aa33";
    }
    return evenRow ? "#7d91aa" : "#7789a2";
  })();

  return (
    <ContactMenu
      contactDetails={contactDetails}
      fetchContactDetails={fetchContactDetails}
      menuId={`${presence.presenceId}`}
      panel={panel}
    >
      <Layout
        style={{
          border: isSpeaking ? "1px solid #333" : undefined,
          borderRadius: isSpeaking ? "6px" : "3px",
          cursor: "pointer",
          opacity: unavailable ? 0.5 : 1.0,
          transition: "all .4s",
        }}
        alignMiddle
        background={bg}
        margin={0}
        minHeight={36}
        padding={2}
        row
      >
        {!isLatestSpeaker && !alarmActive && (
          <PresenceIcon presenceType={type} style={iconStyle} />
        )}
        {isLatestSpeaker && !alarmActive && <TimeIcon style={iconStyle} />}
        {alarmActive && <AlarmIcon style={iconStyle} />}
        <Layout column>
          <Text
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              transition: "all .4s",
            }}
            color={nameColor}
            nowrap
            size={isSpeaking ? 18 : 14}
          >
            {name}
          </Text>
          {showDescription && (
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                transition: "all .4s",
              }}
              color={titleColor}
              nowrap
              size={isSpeaking ? 15 : 10}
            >
              {title}
            </Text>
          )}
        </Layout>
        <Layout column grow />
        {presence.status && (
          <Layout column marginLeft={4} marginRight={10}>
            <StatusBadge
              defaultBackground={false}
              statusOption={presence.status}
            />
          </Layout>
        )}
        {mayOpenAsPanel && (
          <Layout column marginRight={10}>
            <MessageButton
              entity={presence}
              panel={panel}
              panelTab={ContactTabSetting.Actions}
              showMessages={false}
            />
          </Layout>
        )}
      </Layout>
    </ContactMenu>
  );
});
