export const objectDeepMerge = (target: any, source: any): any => {
  if (source == null) {
    return target || {};
  }
  if (target == null) {
    return source;
  }
  return Object.entries({ ...target, ...source }).reduce(
    (ack, [key, value]) => {
      if (source[key] === undefined) {
        return { ...ack, [key]: target[key] };
      }
      if (target[key] === undefined) {
        return { ...ack, [key]: source[key] };
      }
      if (typeof value === "object") {
        return { ...ack, [key]: objectDeepMerge(target[key], source[key]) };
      }
      return { ...ack, [key]: source[key] };
    },
    {}
  );
};
