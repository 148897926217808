import { Button } from "src/app/model/input/Button";
import type { ButtonMapper } from "src/app/model/input/ButtonMapper";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";

export class SpeakerBox implements DeviceHandler {
  private state = 0;
  public constructor(
    private readonly buttonMapper: ButtonMapper,
    private readonly id: string
  ) {}
  public onData(data: ArrayBuffer): void {
    const newState = new Uint8Array(data)[1] & 0xff;
    if (this.state !== 64 && newState === 64) {
      this.buttonMapper.onButtonDown(this.id, Button.PRIMARY);
    }
    if (this.state === 64 && newState !== 64) {
      this.buttonMapper.onButtonUp(this.id, Button.PRIMARY);
    }
    if (this.state !== 48 && newState === 48) {
      this.buttonMapper.onButtonDown(this.id, Button.SECONDARY1);
    }
    if (this.state === 48 && newState !== 48) {
      this.buttonMapper.onButtonUp(this.id, Button.SECONDARY1);
    }
    if (this.state !== 32 && newState === 32) {
      this.buttonMapper.onButtonDown(this.id, Button.SECONDARY2);
    }
    if (this.state === 32 && newState !== 32) {
      this.buttonMapper.onButtonUp(this.id, Button.SECONDARY2);
    }
    if (this.state !== 16 && newState === 16) {
      this.buttonMapper.onButtonDown(this.id, Button.SECONDARY3);
    }
    if (this.state === 16 && newState !== 16) {
      this.buttonMapper.onButtonUp(this.id, Button.SECONDARY3);
    }
    this.state = newState;
  }
}
