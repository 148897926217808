import localStorage from "mobx-localstorage";
import querystring from "querystring";

function parseQuery(): querystring.ParsedUrlQuery {
  const query = window.location.search.substring(1);
  return querystring.decode(query);
}
const DOMAIN_HINT = "gt2.account.domainHint";
const LOGIN_HINT = "gt2.account.loginHint";

const getHint = (
  queryName: string,
  configName: "domainHint" | "loginHint",
  localStorageKey: string
): string | undefined => {
  const query = parseQuery();

  if (query[queryName]) {
    localStorage.setItem(localStorageKey, query[queryName]);
  } else if (query[queryName] === "") {
    localStorage.removeItem(localStorageKey);
  }
  return localStorage.getItem(localStorageKey) || window.gtConfig[configName];
};

export const loginHints = (): {
  domain_hint?: string;
  login_hint?: string;
} => ({
  domain_hint: getHint("domain_hint", "domainHint", DOMAIN_HINT),
  login_hint: getHint("login_hint", "loginHint", LOGIN_HINT),
});
