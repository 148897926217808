import { Layout } from "src/app/gui/components/Layout";
import { Login } from "src/app/gui/offline/Login";
import { Offline } from "src/app/gui/offline/Offline";
import { Online } from "src/app/gui/Online";
import { Dialogs } from "src/app/gui/shared/Dialogs";
import { FlashMessages } from "src/app/gui/shared/FlashMessages";
import { connect } from "src/app/state/connect";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: "#000",
        },
      },
    },
  },
  palette: {
    primary: {
      contrastText: "#000000",
      dark: "#93a7b4",
      light: "#cfd8de",
      main: "#99adc8",
    },
    secondary: {
      contrastText: "#000000",
      dark: "#bc6258",
      light: "#ffc2b5",
      main: "#eaa23c",
    },
  },
});

export const App = connect((_, state) => {
  const loggedIn = !!state.online;
  const offline = state.offline.hasToken;
  return (
    <ThemeProvider theme={theme}>
      <Layout
        alignCenter
        alignMiddle
        background="linear-gradient(to bottom, #505d6a 0%, #7b90aa 500px, #7b90aa 100%)"
        column
        minHeight="100vh"
      >
        {!loggedIn && (
          <Layout column marginBottom="300" marginTop="50">
            {!offline && <Login />}
            {offline && <Offline />}
          </Layout>
        )}
        {loggedIn && <Online />}
        <Dialogs />
        <FlashMessages />
      </Layout>
    </ThemeProvider>
  );
});
