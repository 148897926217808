import { GtError } from "src/app/gui/components/GtError";
import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { GtPanelTab } from "src/app/gui/components/GtPanelTab";
import { Layout } from "src/app/gui/components/Layout";
import { Group } from "src/app/gui/panels/channels/Group";
import { SearchSetting } from "src/app/gui/settings/SearchSetting";
import { OrganizationFilter } from "src/app/gui/shared/OrganizationFilter";
import {
  GroupIcon,
  JoinGroupIcon,
  FavoriteFilledIcon,
  MobileIcon,
} from "src/app/images";
import { ChannelsTabSetting } from "src/app/model/channels/ChannelsTabSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const Channels = connect<{
  panel: Panel;
}>(({ panel }) => {
  const {
    detailedView,
    filterSetting,
    groupFilter,
    id,
    isShowingJoined,
    setGroupFilter,
    showDescription,
    showDispatcherPresence,
    showGroupSymbol,
    showLatestSpeaker,
    showLockbutton,
    showMessages,
    showOrganization,
    showPatchIcon,
    showPresence,
    showPttButton,
    showReplayButton,
    showVolumeControls,
  } = panel.channelsPanelData!;
  const { channels, error, loadMore, loading } =
    panel.channelsPanelData!.channels;
  let noElementsMessage = "No groups available.";
  if (filterSetting.value.length > 0) {
    noElementsMessage = "No groups matching filter";
  } else if (groupFilter === ChannelsTabSetting.Favorites) {
    noElementsMessage = "No favorite groups available.";
  } else if (groupFilter === ChannelsTabSetting.Joined) {
    noElementsMessage = "No groups joined.";
  }
  return (
    <Layout column grow>
      <Layout alignMiddle minHeight={52} row>
        <Layout marginRight={20} row>
          <SearchSetting label="Search" setting={filterSetting} />
        </Layout>
        <OrganizationFilter panelData={panel.channelsPanelData!} />
        <Layout grow row />
        <GtPanelTab
          onClick={() => {
            setGroupFilter(ChannelsTabSetting.All);
          }}
          Icon={GroupIcon}
          id={`show_all_groups_${id}`}
          selected={groupFilter === ChannelsTabSetting.All}
          tooltip="Show all groups"
        />
        <GtPanelTab
          onClick={() => {
            setGroupFilter(ChannelsTabSetting.Favorites);
          }}
          Icon={FavoriteFilledIcon}
          id={`show_favorite_groups_${id}`}
          selected={groupFilter === ChannelsTabSetting.Favorites}
          tooltip="Show favorite groups"
        />
        <GtPanelTab
          onClick={() => {
            setGroupFilter(ChannelsTabSetting.Joined);
          }}
          Icon={JoinGroupIcon}
          id={`show_joined_groups_${id}`}
          selected={groupFilter === ChannelsTabSetting.Joined}
          tooltip="Show joined groups"
        />
        <GtPanelTab
          onClick={() => {
            setGroupFilter(ChannelsTabSetting.Mobile);
          }}
          Icon={MobileIcon}
          id={`show_joined_groups_with_mobile_${id}`}
          selected={groupFilter === ChannelsTabSetting.Mobile}
          tooltip="Show joined groups with mobile users present"
        />
      </Layout>
      {error && <GtError message={error} />}
      {!error && (
        <Layout alignTop column grow marginTop={10}>
          <GtInfiniteRenderer
            elements={channels
              .filter((channel) => channel.session || !isShowingJoined)
              .filter(
                (channel) =>
                  channel.hasMobileUsers ||
                  groupFilter !== ChannelsTabSetting.Mobile
              )
              .map((channel, index) => (
                <Group
                  channel={channel}
                  detailedView={detailedView}
                  evenRow={index % 2 === 1}
                  groupFilter={groupFilter}
                  key={channel.channelUuid}
                  panel={panel}
                  showDescription={showDescription}
                  showDispatcherPresence={showDispatcherPresence}
                  showGroupSymbol={showGroupSymbol}
                  showLatestSpeaker={showLatestSpeaker}
                  showLockbutton={showLockbutton}
                  showMessages={showMessages}
                  showOrganization={showOrganization}
                  showPatchIcon={showPatchIcon}
                  showPresence={showPresence}
                  showPttButton={showPttButton}
                  showReplayButton={showReplayButton}
                  showVolumeControls={showVolumeControls}
                />
              ))}
            loadMore={loadMore}
            loading={loading}
            noElements={noElementsMessage}
          />
        </Layout>
      )}
    </Layout>
  );
});
