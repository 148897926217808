/**
 * This will set newValue to a deep path of baseObject:
 * baseObject[path[0]][path[1]]... = newValue
 * constructing objects if needed.
 *
 * Once the value is set it compares it with a defaultValue:
 * defaultObject[path[0]][path[1]]
 *
 * and removes it if the value is same, or if the default is missing and newValue is falsy.
 * Once removing a value the object is collapsed so that no empty object exists.
 *
 * @param {Object} options.baseObject The object to modify
 * @param {Array[String]} options.path Variable path to modification
 * @param {Any} options.newValue The new value to set
 * @param {Object} options.defaultObject Default object. If setting a value that matches the default
 * it will be removed
 * @return true if baseObject was modified
 */
export const setDataWithDefault = ({
  baseObject,
  defaultObject,
  newValue,
  path,
}: {
  baseObject: Record<string, any>;
  defaultObject: Record<string, any>;
  newValue: any;
  path: string[];
}): boolean => {
  const param = path[path.length - 1];
  let object = baseObject;
  const objectArray = [];
  for (let i = 0; i < path.length - 1; i += 1) {
    if (object[path[i]] == null) {
      object[path[i]] = {};
    }
    objectArray.push(object);
    object = object[path[i]];
  }
  let objectIndex = path.length - 1;
  const oldValue = object[param];
  object[param] = newValue;

  // No modification
  if (oldValue === newValue) {
    return false;
  }

  // Find out default value
  let defObject = defaultObject;
  const defPath = [...path];
  while (defPath.length > 0 && defObject !== undefined) {
    const defParam = defPath.shift()!;
    defObject = defObject[defParam];
  }
  const defaultValue = defObject;

  if (newValue === defaultValue || (defaultValue == null && !newValue)) {
    // Clean up and remove empty objects
    delete object[param];
    while (Object.keys(object).length === 0 && objectIndex > 1) {
      objectIndex -= 1;
      object = objectArray.pop()!;
      delete object[path[objectIndex]];
    }
  }
  return true;
};
