import { JoinGroupIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import type { Channel } from "src/app/model/channels/Channel";

export const PowerButton = connect<{
  channel: Channel;
  noClick?: boolean;
}>(({ channel, noClick }) => {
  if (channel.joined) {
    return (
      <IconButton
        aria-label="Leave"
        onClick={() => !noClick && channel.updateJoined(false)}
        style={{ padding: 4 }}
      >
        <JoinGroupIcon
          style={{
            background: "#86b96982",
            borderRadius: 5,
            fill: "rgb(0 38 0)",
          }}
        />
      </IconButton>
    );
  }
  return (
    <IconButton
      aria-label="Join"
      onClick={() => !noClick && channel.updateJoined(true)}
      style={{ padding: 4 }}
    >
      <JoinGroupIcon />
    </IconButton>
  );
});
