import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { StatusMenu } from "src/app/gui/panels/contacts/StatusMenu";
import {
  PrivateCallIcon,
  LocationIcon,
  CallsignIcon,
  MessageIcon,
  ContactPhoneIcon,
  ContactMailIcon,
  InfoIcon,
  FullDuplexIcon,
} from "src/app/images";
import { ContactManagement } from "src/app/model/contacts/ContactManagement";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { connect } from "src/app/state/connect";
import { Divider } from "@mui/material";
import React from "react";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { Panel } from "src/app/model/panels/Panel";

export const ContactMenu = connect<{
  children: React.ReactElement;
  contactDetails?: ContactDetails;
  fetchContactDetails: () => Promise<void>;
  menuId: string;
  panel?: Panel;
}>(({ children, contactDetails, fetchContactDetails, menuId, panel }) => {
  const menuItems = [];
  if (contactDetails) {
    if (contactDetails.callPermission) {
      menuItems.push(
        <GtMenuItem
          onSelect={() => {
            void contactDetails.call();
          }}
          disabled={!contactDetails.callable || !!contactDetails.session}
          icon={<PrivateCallIcon />}
          key="privateCall"
          label={`Make PTT call to ${contactDetails.name}`}
        />,
        <GtMenuItem
          onSelect={() => {
            void contactDetails.autoAnswerCall();
          }}
          disabled={!contactDetails.callable || !!contactDetails.session}
          icon={<PrivateCallIcon />}
          key="privateAutoCall"
          label={`Make auto-answer PTT call to ${contactDetails.name}`}
        />,
        <GtMenuItem
          disabled={
            !contactDetails.fullDuplexCallable || !!contactDetails.session
          }
          onSelect={() => {
            void contactDetails.fullDuplexCall();
          }}
          icon={<FullDuplexIcon />}
          key="fullDuplexCall"
          label={`Make full-duplex call to ${contactDetails.name}`}
        />
      );
    }
    if (contactDetails.messagable) {
      menuItems.push(
        <GtMenuItem
          onSelect={() => {
            contactDetails.openOrFocusPanel(panel, ContactTabSetting.Messages);
          }}
          icon={<MessageIcon />}
          key="privateMessage"
          label={`Send private message to ${contactDetails.name}`}
        />
      );
    }
    if (contactDetails.locatable) {
      menuItems.push(
        <GtMenuItem
          onSelect={() => {
            contactDetails.openOrFocusPanel(panel, ContactTabSetting.Locations);
          }}
          icon={<LocationIcon />}
          key="showLocation"
          label={`Show location of ${contactDetails.name}`}
        />
      );
    }
    if (contactDetails.callsignLabel != null) {
      menuItems.push(
        <GtMenuItem
          label={
            contactDetails.callsign
              ? `Set ${contactDetails.callsignLabel} (${contactDetails.callsign})`
              : `Set ${contactDetails.callsignLabel}`
          }
          onSelect={() => {
            contactDetails.updateCallsign();
          }}
          icon={<CallsignIcon />}
          key="callsign"
        />
      );
    }
    if (
      contactDetails.status ||
      (contactDetails.statusList && contactDetails.statusList.length > 0)
    ) {
      menuItems.push(<Divider key="divider" />);
      menuItems.push(<StatusMenu entity={contactDetails} key="status" />);
    }

    if (contactDetails.phoneNumbers) {
      contactDetails.phoneNumbers.forEach((phoneNumber) => {
        menuItems.push(
          <GtMenuItem
            onSelect={() => {
              ContactManagement.callPhoneNumber(phoneNumber);
            }}
            icon={<ContactPhoneIcon />}
            key={`call_${phoneNumber}`}
            label={`Call ${phoneNumber}`}
          />
        );
      });
    }
    if (contactDetails.email) {
      menuItems.push(
        <GtMenuItem
          onSelect={() => {
            ContactManagement.sendEmail(contactDetails.email!);
          }}
          icon={<ContactMailIcon />}
          key={`email_${contactDetails.email}`}
          label={`Email ${contactDetails.email}`}
        />
      );
    }
  }
  if (menuItems.length === 0) {
    menuItems.push(
      <GtMenuItem
        label={
          <Layout alignCenter alignMiddle column grow padding={2} width="100%">
            <Text color="#555" size={14}>
              No actions available
            </Text>
          </Layout>
        }
        icon={<InfoIcon />}
        key="no_actions"
        onSelect={() => {}}
      />
    );
  }

  const handleOpen = async (): Promise<boolean> => {
    await fetchContactDetails();
    return true;
  };

  return (
    <GtMenu
      base={Layout}
      id={`panel_${panel?.id ?? "global"}_contact_${menuId}`}
      items={menuItems}
      onOpen={handleOpen}
    >
      {children}
    </GtMenu>
  );
});
