import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Client may not be used on this network.
 */
export class RestrictedNetwork extends GroupTalkAPIError {
  public constructor() {
    super("Client may not be used on this network.", false);
  }
}
