import { Button } from "src/app/model/input/Button";
import type { ButtonMapper } from "src/app/model/input/ButtonMapper";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";

export class OuterplaneCustom implements DeviceHandler {
  private state = 240;
  public constructor(
    private readonly buttonMapper: ButtonMapper,
    private readonly id: string
  ) {}
  public onData(data: ArrayBuffer): void {
    const newState = new Uint8Array(data)[1] & 0xff;
    if (newState === 0) {
      // May be the case when unplugging the device. Ignore this.
      return;
    }
    const newP = newState ^ 240;
    const oldP = this.state ^ 240;
    for (let i = 0; i < 4; i += 1) {
      if ((newP & (1 << i)) === 1 << i && (oldP & (1 << i)) === 0) {
        if (i === 0) {
          this.buttonMapper.onButtonDown(this.id, Button.PRIMARY);
        } else {
          postMessage(`gtevent.secondaryButton${i}Down`, this.id);
        }
      } else if ((newP & (1 << i)) === 0 && (oldP & (1 << i)) === 1 << i) {
        if (i === 0) {
          this.buttonMapper.onButtonUp(this.id, Button.PRIMARY);
        } else {
          postMessage(`gtevent.secondaryButton${i}Up`, this.id);
        }
      }
    }
    this.state = newState;
  }
}
