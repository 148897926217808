import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Microphone not allowed.
 */
export class MicrophoneNotAllowed extends GroupTalkAPIError {
  public constructor() {
    super(
      "Microphone not allowed. Make sure to allow access to the microphone.",
      false
    );
  }
}
