import { Logger } from "src/util/Logger";
import { WrappedPromise } from "src/util/WrappedPromise";

const log = Logger.getLogger("SoundService");

const preloadSounds = [
  "alert-0",
  "alert-1",
  "alert-2",
  "alert-3",
  "alert-4",
  "alert-5",
  "alert-6",
  "alert-7",
  "alert-8",
  "alert-9",
  "alert-10",
  "alert-11",
  "alert-12",
  "busy",
  "call-end",
  "call-start",
  "ringback",
  "ringing",
  "ringing-2",
  "send-start",
  "send-stop",
  "ptt-error",
  "notification",
];
const sounds: Record<string, Promise<ExtHTMLAudioElement>> = {};
const currentSinkIds: Record<string, string> = {};

interface ExtHTMLAudioElement extends HTMLAudioElement {
  setSinkId?: (sinkId: string) => Promise<void>;
}

export class SoundService {
  public static audioOutputMediaDevices(): Promise<MediaDeviceInfo[]> {
    // NOTE: Safari does not support enumerating audiooutput devices, only input.
    return SoundService.findMediaDevices("audiooutput");
  }

  private static async findMediaDevices(
    kind: string
  ): Promise<MediaDeviceInfo[]> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      throw new Error("Selection of media devices not supported");
    }
    const devices = (await navigator.mediaDevices.enumerateDevices()).filter(
      (d) => d.kind === kind
    );
    return devices;
  }

  private static async loadSound(soundfile: string): Promise<HTMLAudioElement> {
    if (soundfile in sounds) {
      return sounds[soundfile];
    }
    log.debug(`Loading sound ${soundfile}`);
    const sound = new Audio(`sounds/${soundfile}.mp3`);
    const promise = new WrappedPromise<HTMLAudioElement>();
    sounds[soundfile] = promise.promise;
    sound.onloadeddata = () => promise.resolve(sound);
    sound.onerror = (evt) => {
      log.error(`Error loading sound ${soundfile}: ${evt}`);
      promise.reject(evt);
      delete sounds[soundfile];
    };
    return promise.promise;
  }

  public static playSound(
    soundfile: string,
    mediaDeviceId?: string,
    volume?: number
  ): void {
    void this.playSoundAsync(soundfile, mediaDeviceId, volume);
  }

  public static async playSoundAsync(
    soundfile: string,
    mediaDeviceId?: string,
    volume?: number
  ): Promise<void> {
    // Do not use truthy checks on volume, as it may be 0.
    const actualVolume =
      volume !== undefined && volume >= 0 && volume <= 1 ? volume : 1.0;
    log.debug(`Playing sound ${soundfile} with volume ${actualVolume}`);
    const sound = (await SoundService.loadSound(
      soundfile
    )) as ExtHTMLAudioElement;
    if (mediaDeviceId) {
      try {
        if (sound.setSinkId && currentSinkIds[soundfile] !== mediaDeviceId) {
          log.debug(
            `Setting output device ${mediaDeviceId} for sound ${soundfile}`
          );
          await sound.setSinkId(mediaDeviceId || "default");
          currentSinkIds[soundfile] = mediaDeviceId;
        }
      } catch (err) {
        log.debug(`Unable to set sound output device ${mediaDeviceId}`, err);
      }
    }
    sound.volume = actualVolume;
    sound.currentTime = 0;
    try {
      await sound.play();
      log.debug(
        `Completed playing sound ${soundfile} on ${mediaDeviceId}: ${sound.currentTime}`
      );
    } catch (e) {
      log.warn(`Failed playing sound ${soundfile} on ${mediaDeviceId}`, e);
      throw e;
    }
  }

  public static preloadSounds(): void {
    // Preload sounds, these should match the sounds folder.
    // If not preloaded, the sound will not play if it is played for
    // the first time while in the background
    preloadSounds.forEach((soundfile) => {
      void SoundService.loadSound(soundfile);
    });
  }

  public static stopSound(soundfile: string): void {
    if (soundfile in sounds) {
      SoundService.loadSound(soundfile)
        .then((sound: HTMLAudioElement) => {
          if (sound.pause && !sound.paused) {
            sound.pause();
          }
        })
        .catch((e) => {
          log.warn(`Unable to pause sound ${soundfile}`, e);
        });
    }
  }
}
