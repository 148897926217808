import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Server is not responding.
 */
export class ClientRequestTimeout extends GroupTalkAPIError {
  public constructor() {
    super("Server is not responding.", true);
  }
}
