import { Presence } from "src/lib/modules/Presence";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { PresenceDelta } from "src/lib/types/PresenceDelta";

const log = Logger.getLogger("PresenceModule");

/**
 * Returned from <code>setupPresenceModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class PresenceModule {
  public onPresenceDelta?: (delta: PresenceDelta) => void;
  public subscriptionId?: number;
  private constructor(private readonly requestManager: RequestManager) {}
  public static setup(requestManager: RequestManager): Promise<PresenceModule> {
    log.debug("presence module setup.");
    // No setup request required for setupCall module
    return Promise.resolve(new PresenceModule(requestManager));
  }
  public onRequest(
    message: proto.IPresenceAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.presenceDelta) {
      this.onPresenceDeltaRequest(message.presenceDelta, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Start subscribing to presence updates (for all ongoing and future sessions).
   * Note that an additional subscribe will invalidate this subscription.
   * @param {function(PresenceDelta)} onPresenceDelta Callback receiving updates to presence
   * relative the initially returned presence collection and subsequent deltas.
   * @returns {Promise<Array<Presence>>} Promise of the initial list of presences.
   */
  public async subscribe(
    onPresenceDelta: (delta: PresenceDelta) => void
  ): Promise<Presence[]> {
    this.onPresenceDelta = onPresenceDelta;
    const response = (await this.requestManager.send({
      presence: { subscribeRequest: {} },
    })) as proto.PresenceSubscribeResponse;
    this.subscriptionId = longToNumber(response.subscriptionId);
    if (response.initialPresence) {
      return response.initialPresence.map((presence) => new Presence(presence));
    }
    return [];
  }
  /**
   * Stop subscribing to presence updates (for all ongoing and future sessions).
   * @returns {Promise} Resolves when the unsubscription is completed
   */
  public async unsubscribe(): Promise<void> {
    delete this.subscriptionId;
    delete this.onPresenceDelta;
    await this.requestManager.send({ presence: { unsubscribeRequest: {} } });
  }
  private onPresenceDeltaRequest(
    message: proto.IPresenceDelta,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onPresenceDelta) {
      const { status, subscriptionId, updated } = message;
      log.debug("presenceDelta:", message);
      if (subscriptionId === this.subscriptionId) {
        const updatedOrNew = updated
          ? updated.map(
              (presence) => new Presence(presence, status ?? undefined)
            )
          : undefined;
        const deletedPresenceIds = message.deletedPresenceIds
          ? message.deletedPresenceIds.map((n) => longToNumber(n))
          : undefined;
        this.onPresenceDelta({ deletedPresenceIds, updatedOrNew });
      } else {
        log.warn("Presence delta received with incorrect subscription id");
      }
    }
  }
}
