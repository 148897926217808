import { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";

export class PickedPrioState extends QueueState {
  public constructor(alertService: QueueAlertService) {
    super(alertService, "PickedPrioState");
  }
  public newUnpickedEmergencyEntryEvent(): void {
    this.alertService.playEmergencyAlert();
  }
  public newUnpickedPrioEntryEvent(): void {
    this.alertService.playPrioAlert();
  }
}
