import { gql } from "src/app/graphql";
import { Channel } from "src/app/model/channels/Channel";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import type { DocumentNode } from "graphql";
import type {
  ClientGroupsFullChannelFieldsQuery,
  ClientGroupsPatchChannelFieldsQuery,
} from "src/app/graphql/graphql";
import type { State } from "src/app/model/State";
import type { PageInfo } from "src/app/types/PageInfo";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { ClientGroup } from "src/nextgen/types/ClientGroup";

const log = Logger.getLogger("Channels");

export const CHANNELS_PER_FETCH = 50;

type Filter = {
  groupIds?: null | string[];
  name: null | string;
  notInPatchId?: null | string;
  organizationIds?: null | string[];
  selection?: {
    profile: string;
    scanned?: boolean;
    selected: boolean;
    selectedOrScanned?: boolean;
  } | null;
};

export class Channels {
  public error?: string;
  public loading = true;
  private readonly groups: Channel[] = [];
  private filter: Filter | null = null;
  private offset?: number;
  private searchTimer?: NodeJS.Timeout;
  private totalItems?: number;
  public constructor(
    private readonly state: State,
    private readonly queryVariant:
      | "FullChannel"
      | "PatchChannel"
      | "QueueCustomization" = "FullChannel"
  ) {
    observableClass(this);
  }
  public get channelWithUuid() {
    return (uuid: ChannelUuid): Channel | undefined =>
      this.channels.find((channel) => channel.channelUuid === uuid);
  }
  public get channels(): Channel[] {
    return this.groups;
  }
  public get hasOneChannelWithLock(): boolean {
    return (
      this.groups &&
      this.groups.find((channel) => channel.hasLock) !== undefined
    );
  }
  public get hasOneChannelWithPatch(): boolean {
    return (
      this.groups &&
      this.groups.find((channel) => channel.hasPatch) !== undefined
    );
  }
  public async fetchChannels({ reset = false } = {}): Promise<void> {
    if (reset) {
      this.offset = undefined;
    }
    if (!this.hasMore || this.error || !this.filter) {
      return;
    }
    try {
      log.debug("fetching channels with filter", this.filter);
      this.loading = true;
      const clientGroups = await this.queryClientGroups();
      this.error = undefined;
      this.loading = false;
      if (clientGroups) {
        log.debug("Fetched groups", clientGroups.items);
        this.totalItems = clientGroups.pageInfo.totalItems;
        const { limit } = clientGroups.pageInfo;
        this.offset = (this.offset ?? 0) + limit;
        if (reset) {
          this.groups.length = 0;
        }
        this.groups.push(
          ...clientGroups.items.map(
            (clientGroup) => new Channel(this.state, clientGroup)
          )
        );
      } else {
        this.groups.length = 0;
      }
    } catch (err: any) {
      log.error(err);
      this.error = "Failed to fetch channels";
    }
  }
  public loadMore(): void {
    if (!this.loading) {
      void this.fetchChannels();
    }
  }
  public async queryUpdateSelectionData({
    groupIds,
  }: {
    groupIds: ChannelUuid[];
  }): Promise<void> {
    const clientGroups =
      await this.state.online?.channels.queryUpdateSelectionData({ groupIds });
    clientGroups?.items.forEach((clientGroup) => {
      const channel = this.channelWithUuid(clientGroup.id);
      if (channel) {
        channel.pttSelection = clientGroup.pttSelection;
        channel.lockStatus = clientGroup.lock;
      }
    });
  }
  public refresh(): void {
    this.error = undefined;
    this.loading = true;
    void this.fetchChannels({ reset: true });
  }
  public setFilter(filter: Filter): void {
    if (!this.state.online) {
      return;
    }
    let debounce = false;
    if (this.filter && this.filter.name && filter.name !== this.filter.name) {
      debounce = true;
    }
    if (
      this.filter &&
      filter.name === this.filter.name &&
      filter.groupIds === this.filter.groupIds &&
      filter.selection === this.filter.selection &&
      (filter.groupIds == null ||
        (filter.groupIds.length === this.filter.groupIds?.length &&
          filter.groupIds.every(
            (value, index) => value === this.filter!.groupIds?.[index]
          ))) &&
      filter.notInPatchId === this.filter.notInPatchId &&
      filter.organizationIds === this.filter.organizationIds &&
      (filter.organizationIds == null ||
        (filter.organizationIds.length ===
          this.filter.organizationIds?.length &&
          filter.organizationIds.every(
            (value, index) => value === this.filter!.organizationIds?.[index]
          )))
    ) {
      return;
    }
    log.debug("set filter", filter);
    this.filter = filter;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.error = undefined;
    this.loading = true;
    if (debounce) {
      this.searchTimer = setTimeout(
        action(() => {
          this.searchTimer = undefined;
          this.refresh();
        }),
        500
      );
    } else {
      this.refresh();
    }
  }
  private get hasMore(): boolean {
    return (
      this.totalItems === undefined ||
      this.offset === undefined ||
      this.offset < this.totalItems
    );
  }
  private get queryDocument(): DocumentNode {
    switch (this.queryVariant) {
      case "QueueCustomization":
        return gql(`
          query clientGroupsQueueCustomizationChannelFields(
            $pagination: OffsetLimitInput!,
            $filter: ClientGroupsFilterInput
          ) {
            clientGroups(pagination: $pagination, filter: $filter) {
              items {
                id
                type
                entityId
                displayName
                description
                pttSelection(profile: "dispatcher") {
                  scanned
                  selected
                }
              }
              pageInfo {
                totalItems
                offset
                limit
              }
            }
          }
        `);
      case "PatchChannel":
        return gql(`
          query clientGroupsPatchChannelFields(
            $pagination: OffsetLimitInput!,
            $filter: ClientGroupsFilterInput
          ) {
            clientGroups(pagination: $pagination, filter: $filter) {
              items {
                id
                type
                entityId
                displayName
                description
                organization {
                  name
                  id
                }
                patch {
                  id
                }
              }
              pageInfo {
                totalItems
                offset
                limit
              }
            }
          }
        `);
      case "FullChannel":
      default:
        return gql(`
          query clientGroupsFullChannelFields(
            $pagination: OffsetLimitInput!,
            $filter: ClientGroupsFilterInput
          ) {
            clientGroups(pagination: $pagination, filter: $filter) {
              items {
                id
                type
                entityId
                displayName
                description
                organization {
                  name
                  id
                }
                messageChannel {
                  unreadCount
                }
                lock
                pttSelection(profile: "dispatcher") {
                  scanned
                  selected
                }
                patch {
                  id
                }
              }
              pageInfo {
                totalItems
                offset
                limit
              }
            }
          }
        `);
    }
  }
  private async queryClientGroups(): Promise<{
    items: ClientGroup[];
    pageInfo: PageInfo;
  }> {
    if (this.offset === undefined) {
      this.offset = 0;
    }
    if (this.filter!.groupIds != null && this.filter!.groupIds.length === 0) {
      // Empty group filter should always return empty array
      return {
        items: [],
        pageInfo: { limit: 0, offset: 0, totalItems: 0 },
      };
    }
    if (
      this.filter!.organizationIds != null &&
      this.filter!.organizationIds.length === 0
    ) {
      // Empty organization filter should always return empty array
      return {
        items: [],
        pageInfo: { limit: 0, offset: 0, totalItems: 0 },
      };
    }
    const { clientGroups } = await this.state.graphqlModule.queryDataOrThrow<
      ClientGroupsFullChannelFieldsQuery | ClientGroupsPatchChannelFieldsQuery
    >({
      fetchPolicy: "no-cache",
      query: this.queryDocument,
      variables: {
        filter: this.filter,
        pagination: {
          limit: CHANNELS_PER_FETCH,
          offset: this.offset,
        },
      },
    });
    return clientGroups;
  }
}
