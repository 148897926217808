import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Network error.
 */
export class NetworkError extends GroupTalkAPIError {
  public constructor() {
    super("Network error.", true);
  }
}
