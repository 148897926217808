import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { ClientUserForPresence } from "src/app/model/presence/ClientUserForPresence";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";
import type { Presence as NodePresence } from "src/lib/modules/Presence";
import type { PresenceType } from "src/lib/types/PresenceType";
import type { Status } from "src/lib/types/Status";
import type { ClientUserDetailed } from "src/nextgen/types/ClientUserDetailed";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";

const log = Logger.getLogger("Presence");

export class Presence {
  public readonly alarmActive: boolean;
  public readonly name: string;
  public readonly presenceId: number;
  public readonly sessionId: number;
  public readonly sourceId?: string;
  public readonly status?: Status;
  public readonly title: string;
  public readonly type: PresenceType;
  public readonly unavailable: boolean;
  public readonly userEntityId?: string;
  public contactDetails?: ContactDetails;
  /**
   * Unread count is currently not updated - but here because of general treatment.
   */
  public unreadCount = 0;
  public constructor(private readonly state: State, presence: NodePresence) {
    this.sessionId = presence.sessionId;
    this.presenceId = presence.presenceId;
    this.name = presence.name;
    this.title = presence.title;
    this.type = presence.type;
    this.unavailable = presence.unavailable;
    this.sourceId = presence.sourceId;
    this.userEntityId = presence.userEntityId;
    this.alarmActive = presence.alarmActive;
    this.status = presence.status;
    observableClass(this);
  }
  public get isLatestSpeaker(): boolean {
    return !!(
      this.session &&
      this.session.latestSpeakerPresence &&
      this.session.latestSpeakerPresence.presenceId === this.presenceId
    );
  }
  public get isSpeaking(): boolean {
    return !!(
      this.session &&
      this.session.incomingTalkburst &&
      this.session.incomingTalkburst.sourceId === this.sourceId
    );
  }
  public get mayOpenAsPanel(): boolean {
    return !!this.userEntityId;
  }
  public get session(): Session | undefined {
    return this.state.online?.sessions.list.find(
      (s) => s.sessionId === this.sessionId
    );
  }
  public async fetchContactDetails(): Promise<void> {
    let res: ContactDetails;
    if (!this.userEntityId) {
      return;
    }
    try {
      const data = await this.queryContactDetails(this.userEntityId);
      if (data != null) {
        res = new ContactDetails(this.state, {
          callPermission: data.permissions.call,
          email: data.email,
          entityId: data.entityId,
          fullDuplexPermission: data.permissions.fullDuplexCall,
          id: data.id,
          locatable: data.permissions.locate,
          messagePermission: data.permissions.message,
          name: data.displayName,
          online: data.onlineStatus.state === "ONLINE",
          organization: data.organization,
          phoneNumbers: data.phoneNumbers,
          status: data.currentStatus,
          statusList: data.availableStatuses,
        });
        // fetch callsign from Callsign Module and the Node API
        await res.fetchCallsign();
        this.contactDetails = res;
      }
    } catch (err: any) {
      log.error(err);
      this.contactDetails = undefined;
    }
  }
  public openOrFocusPanel(panel?: Panel, tab?: ContactTabSetting): void {
    if (this.userEntityId) {
      void (async () => {
        if (!this.contactDetails) {
          await this.fetchContactDetails();
        }
        if (this.contactDetails) {
          this.state.contactManagement.openOrFocusPanel({
            name: this.contactDetails.name,
            panel,
            tab,
            userUuid: this.contactDetails.id,
          });
        }
      })();
    }
  }
  private async queryContactDetails(
    id: ClientUserUuid
  ): Promise<ClientUserDetailed | null> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: ClientUserForPresence,
      variables: {
        id,
      },
    });
    return clientUser;
  }
}
