import { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { action } from "mobx";
import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";

export class UnpickedPrioState extends QueueState {
  public constructor(
    alertService: QueueAlertService,
    private newUnpickedPrioEntry: boolean
  ) {
    super(alertService, "UnpickedPrioState");
  }
  public newUnpickedEmergencyEntryEvent(): void {
    this.alertService.playEmergencyAlert();
  }
  public newUnpickedPrioEntryEvent(): void {
    if (
      this.newUnpickedPrioEntry ||
      this.settings.prio.playSound.selected.id === "always"
    ) {
      this.alertService.playPrioAlert();
      this.remindAboutPrio();
      this.newUnpickedPrioEntry = false;
    }
  }
  public onEnter(): void {
    this.remindAboutPrio();
    this.settingsUnsubscribe =
      this.settings.prio.reminderIntervalInSeconds.subscribeOnChanges(() => {
        this.remindAboutPrio();
      });
  }
  public onExit(): void {
    if (this.alertTimer !== undefined) {
      this.alertTimer();
      this.alertTimer = undefined;
    }
    this.settingsUnsubscribe?.();
  }
  private remindAboutPrio(): void {
    if (this.alertTimer !== undefined) {
      this.alertTimer();
      this.alertTimer = undefined;
    }
    if (this.settings.prio.reminderIntervalInSeconds.value) {
      this.alertTimer = BackgroundTimer.setTimeout(
        action(() => {
          if (this.settings.prio.reminderIntervalInSeconds.value) {
            this.alertService.playPrioAlert();
            this.remindAboutPrio();
          }
        }),
        this.settings.prio.reminderIntervalInSeconds.value * 1000
      );
    }
  }
}
