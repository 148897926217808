import { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";

export class PickedEmergencyState extends QueueState {
  public constructor(alertService: QueueAlertService) {
    super(alertService, "PickedEmergencyState");
  }
  public newUnpickedEmergencyEntryEvent(): void {
    this.alertService.playEmergencyAlert();
  }
}
