import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type {
  DynamicListEntry,
  DynamicListSetting as Setting,
} from "src/app/model/settings/DynamicListSetting";

export const DynamicListSetting = connect<{
  id: string;
  label: string;
  onSelect?: (selected: DynamicListEntry) => void;
  setting: Setting;
}>(({ id, label, onSelect, setting }) => (
  <Layout alignLeft alignMiddle gap={20} row>
    <Layout alignMiddle column width={130}>
      <Text>{label}</Text>
    </Layout>
    <Layout row>
      <GtMenu
        items={[
          ...setting.list.map((entry) => (
            <GtMenuItem
              onSelect={() => {
                setting.select(entry);
                if (onSelect) {
                  onSelect(setting.selected);
                }
              }}
              icon={setting.selected.id === entry.id ? <CheckIcon /> : null}
              key={entry.id}
              label={entry.name}
            />
          )),
        ]}
        onOpen={async () => {
          try {
            await setting.refresh();
            return true;
          } catch (err) {
            return false;
          }
        }}
        disabled={!setting.configurable}
        id={id}
      >
        <Layout alignCenter alignMiddle row>
          {setting.selected ? setting.selected.name : "None"}
        </Layout>
      </GtMenu>
    </Layout>
  </Layout>
));
