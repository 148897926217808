import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { DeviceLocationDelta } from "src/lib/types/DeviceLocationDelta";
import type { LocationRange } from "src/lib/types/LocationRange";

const log = Logger.getLogger("LocationEnquiryModule");

type Options = {
  onDelta: (delta: DeviceLocationDelta) => void;
  requestManager: RequestManager;
  subscriptionId: number;
  unsubscriber: () => void;
};
export class LocationEnquirySubscription {
  public readonly onDelta: (delta: DeviceLocationDelta) => void;
  public readonly unsubscriber: () => void;
  private readonly requestManager: RequestManager;
  private readonly subscriptionId: number;
  private closed = false;
  public constructor(options: Options) {
    this.requestManager = options.requestManager;
    this.subscriptionId = options.subscriptionId;
    this.unsubscriber = options.unsubscriber;
    /**
     * Callback when new location data or updated device parameters are available or when a device
     * no longer matches the filter.
     * @member {function(DeviceLocationDelta)}
     */
    this.onDelta = options.onDelta;
  }
  public onUnsubscribed(): void {
    this.closed = true;
  }
  /**
   * Close this subscription.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async unsubscribe(): Promise<void> {
    if (!this.closed) {
      this.unsubscriber();
      await this.requestManager.send({
        locationEnquiry: {
          unsubscribeRequest: { subscriptionId: this.subscriptionId },
        },
      });
      log.debug(
        `Unsubscribed to location updates for subscriptionId ${this.subscriptionId}`
      );
    }
  }
  /**
   * Update the filter for this subscription. Use this when the map showing locations is
   * scrolled or zoomed.
   * @param {Array<string>} [entityIds] List of entities to subscribe on. Entity may be
   * user, group, queue entry etc. If no entities are specified, all devices in the company are
   * subscribed upon. <code>(optional)</code>
   * @param {LocationRange} [range] Geographical range of which we are interested of
   * updates. No range means whole world. <code>(optional)</code>
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateFilter({
    entityIds,
    range,
  }: {
    entityIds?: string[];
    range?: LocationRange;
  }): Promise<void> {
    await this.requestManager.send({
      locationEnquiry: {
        updateFilterRequest: {
          filter: { entityIds: entityIds ?? [], range },
          subscriptionId: this.subscriptionId,
        },
      },
    });
  }
}
