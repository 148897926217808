import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Client not compatible with server.
 */
export class VersionNotSupported extends GroupTalkAPIError {
  public constructor() {
    super("Client version not compatible with server.", false);
  }
}
