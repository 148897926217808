import { longToNumber } from "src/lib/modules/util/longToNumber";
import { Codec } from "src/lib/types/Codec";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";
import type { CallReferenceNodeId } from "src/lib/types/CallReferenceNodeId";
import type { ChannelNodeId } from "src/lib/types/ChannelNodeId";
import type { SessionMediaType } from "src/lib/types/SessionMediaType";
import type { SessionNodeId } from "src/lib/types/SessionNodeId";
import type { SessionType } from "src/lib/types/SessionType";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { FullDuplexUuid } from "src/nextgen/types/FullDuplexUuid";

/**
 * Obtained from <code>onStarted</code> callback of <code>{@link SessionModule}</code>.
 * @namespace
 */
export class Session {
  /**
   * List of accepted codecs
   * @member {Array<Codec>}
   */
  public acceptedCodecs: Codec[];
  /**
   * The call reference, or <code>null</code> if not associated with a call.
   * @member {string}
   */
  public callRef?: CallReferenceNodeId;
  /**
   * The channel id, or <code>null</code> if not associated with a channel.
   * @member {string}
   */
  public channelId?: ChannelNodeId;
  /**
   * The channel uuid, or <code>null</code> if not associated with a channel.
   * @member {string?}
   */
  public channelUuid?: ChannelUuid;
  /**
   * The fullDuplex uuid, or <code>null</code> if not a full duplex session.
   * @member {string?}
   */
  public fullDuplexUuid?: FullDuplexUuid;
  /**
   * Which kind of media type this session is.
   * @member {SessionMediaType}
   */
  public mediaType?: SessionMediaType;
  /**
   * Human readable name of the session.
   * @member {string}
   */
  public name: string;
  /**
   * In case of a private call, userUuid of the other user
   */
  public peerUserUuid?: ClientUserUuid;
  /**
   * The unique session id.
   * @member {Number}
   */
  public sessionId: SessionNodeId;
  /**
   * <code>true</code> if session may be stopped (e.g. a call); otherwise, <code>false</code>.
   * @member {boolean}
   */
  public stoppable: boolean;
  /**
   * <code>true</code> if transmission is not allowed; otherwise, <code>false</code>.
   * member {boolean}
   */
  public txDenied?: boolean;
  /**
   * The type of session.
   * @member {SessionType}
   */
  public type: SessionType;
  public constructor(
    private readonly requestManager: RequestManager,
    sessionStarted: proto.ISessionStarted
  ) {
    this.sessionId = longToNumber(sessionStarted.sessionId);
    this.name = sessionStarted.name;
    this.stoppable = sessionStarted.stoppable;
    this.type = sessionStarted.type as number as SessionType;
    this.channelId = sessionStarted.channelId ?? undefined;
    this.callRef = sessionStarted.callRef ?? undefined;
    this.txDenied = sessionStarted.txDenied ?? undefined;
    this.acceptedCodecs = sessionStarted.acceptedCodecs ?? [Codec.iLBC];
    this.channelUuid = sessionStarted.channelUuid ?? undefined;
    this.peerUserUuid = sessionStarted.peerUserUuid ?? undefined;
    this.mediaType = sessionStarted.mediaType as number as SessionMediaType;
    this.fullDuplexUuid = sessionStarted.fullDuplexUuid ?? undefined;
  }
  /**
   * Try to stop a session (e.g. hang up a call).
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async stop(): Promise<void> {
    await this.requestManager.send({
      session: { stopRequest: { sessionId: this.sessionId } },
    });
  }
}
