import { Layout } from "src/app/gui/components/Layout";
import { FullDuplexFabs } from "src/app/gui/panels/video/FullDuplexFabs";
import { VideoSpace } from "src/app/gui/panels/video/VideoSpace";
import { Room } from "src/app/model/video/Room";
import { connect } from "src/app/state/connect";
import { useRef } from "react";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";
import type { Panel } from "src/app/model/panels/Panel";

const PIXELS_USED_UP_IN_HEIGHT_BY_OTHER_THINGS_THAN_VIDEO = 256;

export const FullDuplexSession = connect<{
  contact: ContactDetails;
  panel: Panel;
}>(({ contact, panel }, state) => {
  const { fullDuplexRoom, session } = contact;
  const displayName = state.userData.displayName ?? "You";
  const videoSize = Room.videoSize;
  const localCameraIsOn = fullDuplexRoom?.localCameraIsOn ?? false;
  const peerCameraIsOn = fullDuplexRoom?.peerCameraIsOn ?? false;
  const peerIsPresent = (fullDuplexRoom?.presenceCount ?? 0) > 0;
  const localAudioMuted = fullDuplexRoom?.localAudioMuted ?? false;
  const peerAudioMuted = fullDuplexRoom?.peerAudioMuted ?? false;
  const maxHeight =
    (panel.flexRect?.height ?? videoSize.height) -
    PIXELS_USED_UP_IN_HEIGHT_BY_OTHER_THINGS_THAN_VIDEO;
  const maxWidth = (panel.flexRect?.width ?? videoSize.width) * 0.9;
  const scale = Math.min(
    maxWidth / videoSize.width,
    maxHeight / videoSize.height
  );
  const width = videoSize.width * scale;
  const height = videoSize.height * scale;

  const outerRef = useRef<HTMLDivElement>(null);
  const toggleFullScreen = (): void => {
    if (document.fullscreenElement) {
      void document.exitFullscreen();
    } else {
      void outerRef.current?.requestFullscreen();
    }
  };
  return (
    <Layout alignTop column grow>
      {session && (
        <Layout
          alignCenter
          background="#738396"
          column
          gap={20}
          padding={20}
          style={{ borderRadius: 5 }}
        >
          <Layout height={height} ref={outerRef} relative width={width}>
            <VideoSpace
              videoTrack={
                peerCameraIsOn
                  ? fullDuplexRoom!.twilioPeerVideoTrack
                  : undefined
              }
              mirrored={false}
              muted={peerAudioMuted}
              name={contact.name}
              preview={false}
              visible={peerIsPresent}
            />
            <VideoSpace
              videoTrack={
                localCameraIsOn
                  ? fullDuplexRoom!.twilioLocalVideoTrack
                  : undefined
              }
              mirrored={true}
              muted={localAudioMuted}
              name={displayName}
              preview={peerIsPresent}
              visible={true}
            />
            {document.fullscreenElement !== null && (
              <Layout absolute alignCenter bottom={20} width="100%">
                <FullDuplexFabs
                  contact={contact}
                  onClickFullScreen={toggleFullScreen}
                  panel={panel}
                />
              </Layout>
            )}
          </Layout>
          <FullDuplexFabs
            contact={contact}
            onClickFullScreen={toggleFullScreen}
            panel={panel}
          />
        </Layout>
      )}
    </Layout>
  );
});
