import { Server } from "src/app/model/appData/Server";
import { Audio } from "src/app/model/audio/Audio";
import { BroadcastManager } from "src/app/model/broadcast/BroadcastManager";
import { CallsignHandler } from "src/app/model/callsign/CallsignHandler";
import { ChannelsHandler } from "src/app/model/channels/ChannelsHandler";
import { Constants } from "src/app/model/Constants";
import { EventManager } from "src/app/model/events/EventManager";
import { IncomingCallHandler } from "src/app/model/incomingCall/IncomingCallHandler";
import { Input } from "src/app/model/input/Input";
import { LocationSubscriber } from "src/app/model/location/LocationSubscriber";
import { NotificationManager } from "src/app/model/notifications/NotificationManager";
import { Patches } from "src/app/model/patches/Patches";
import { PresenceList } from "src/app/model/presence/PresenceList";
import { QueueManagement } from "src/app/model/queues/QueueManagement";
import { Sessions } from "src/app/model/sessions/Sessions";
import { SetupCall } from "src/app/model/setupCall/SetupCall";
import { StatusHandler } from "src/app/model/status/StatusHandler";
import { Rooms } from "src/app/model/video/Rooms";
import { observableClass } from "src/app/state/observableClass";
import { NotFound } from "src/lib/errors/NotFound";
import { NotificationModule } from "src/nextgen/NotificationModule";
import { SubscriptionModule } from "src/nextgen/SubscriptionModule";
import { Logger } from "src/util/Logger";
import type { MenuData } from "src/app/model/notifications/MenuData";
import type { State } from "src/app/model/State";
import type { CapabilitiesModule } from "src/lib/modules/CapabilitiesModule";

const log = Logger.getLogger("online");
export class Online {
  public readonly audio: Audio;
  public readonly broadcast: BroadcastManager;
  public readonly callsign: CallsignHandler;
  public readonly channels: ChannelsHandler;
  public readonly eventManager: EventManager;
  public readonly input: Input;
  public readonly location: LocationSubscriber;
  public readonly patches: Patches;
  public readonly presence: PresenceList;
  public readonly queueManagement: QueueManagement;
  public readonly rooms: Rooms;
  public readonly sessions: Sessions;
  public readonly setupCall: SetupCall;
  public readonly status: StatusHandler;
  public notificationModule?: NotificationModule;
  public subscriptionModule?: SubscriptionModule;
  private readonly incomingCallHandler: IncomingCallHandler;
  private readonly notifications: NotificationManager;
  private capabilitiesModule?: CapabilitiesModule;
  public constructor(private readonly state: State) {
    this.audio = new Audio(state);
    this.broadcast = new BroadcastManager(state);
    this.callsign = new CallsignHandler(state);
    this.presence = new PresenceList(state);
    this.rooms = new Rooms(state);
    this.sessions = new Sessions(state);
    this.channels = new ChannelsHandler(state);
    this.queueManagement = new QueueManagement(state);
    this.input = new Input(state);
    this.notifications = new NotificationManager(state);
    this.setupCall = new SetupCall(state);
    this.patches = new Patches(state);
    this.location = new LocationSubscriber();
    this.status = new StatusHandler();
    this.incomingCallHandler = new IncomingCallHandler(state);
    this.eventManager = new EventManager(state);
    observableClass(this);
  }
  public get notificationMenuData(): MenuData {
    return this.notifications.menuData;
  }
  public closeDown(): void {
    this.rooms.closeDown();
    this.audio.closeDown();
    this.sessions.closeDown();
    this.queueManagement.closeDown();
    this.input.closeDown();
    this.incomingCallHandler.closeDown();
    this.subscriptionModule?.closeDown();
    this.notificationModule?.closeDown();
    this.eventManager?.closeDown();
  }
  public goOffline(): void {
    if (this.capabilitiesModule !== undefined) {
      this.checkForOpenTickets({
        title: "Go Offline",
        whenConfirmed: () => {
          void this.capabilitiesModule?.goOffline();
        },
      });
    }
  }
  public goOfflineAndLogout(): void {
    this.checkForOpenTickets({
      title: "Logout",
      whenConfirmed: () => {
        void (async () => {
          if (this.capabilitiesModule !== undefined) {
            await this.capabilitiesModule?.goOffline();
          }
          this.state.offline.deauthenticate();
        })();
      },
    });
  }
  public async setup(
    capabilitiesModule: CapabilitiesModule,
    options?: { purchaseUsage: boolean }
  ): Promise<void> {
    this.capabilitiesModule = capabilitiesModule;
    const authenticationModule = this.state.login.authenticationModule!;
    const jwt = await authenticationModule.login();
    const authenticatedModule = await capabilitiesModule.authenticateWithJWT(
      jwt,
      options
    );
    this.state.userData.setDisplayName(
      authenticatedModule.accountInfo.displayName
    );
    this.state.userData.setOrganization(
      authenticatedModule.accountInfo.organization
    );
    if (capabilitiesModule.capabilities.callsign) {
      await this.callsign.setup(authenticatedModule, {
        onCallsignChanged: (userDisplayInfo) => {
          this.state.userData.setDisplayName(
            userDisplayInfo.displayName ?? null
          );
        },
      });
    }
    if (capabilitiesModule.capabilities.status) {
      await this.status.setup(authenticatedModule);
    }
    await this.channels.setup(authenticatedModule);
    await authenticatedModule.setupCustomActionModule();
    try {
      await authenticatedModule.setupEmergencyModule({
        onAlarmCleared: (alarm) => {
          log.debug("Alarm cleared", alarm);
        },
        onAlarmRaised: (alarm) => {
          log.debug("Alarm raised", alarm);
        },
      });
    } catch (error) {
      if (error instanceof NotFound) {
        log.debug("No emergency configured");
      } else {
        throw error;
      }
    }
    await authenticatedModule.setupHttpRequestModule({
      onHttpRequest: (httpRequest) => {
        log.debug("Http request", httpRequest);
        return Promise.resolve(501); // Not implemented
      },
    });
    await this.location.setup(authenticatedModule);
    await authenticatedModule.setupLocationReportModule({
      onStartReport: (startReport) => {
        log.debug("Location start report", startReport);
      },
      onStopReport: () => {
        log.debug("Location stop report");
      },
      onUpdate: (locationUpdateHints) => {
        log.debug("Location update", locationUpdateHints);
        // Not implemented
        return Promise.reject();
      },
    });
    await this.presence.setup(authenticatedModule);
    await this.queueManagement.setup(authenticatedModule);
    await this.incomingCallHandler.setup(authenticatedModule);
    await this.sessions.setup(authenticatedModule);
    await this.setupCall.setup(authenticatedModule);
    await authenticatedModule.setupSmsModule();

    await this.audio.setup(authenticatedModule);
    await authenticatedModule.setupThirdPartyCallControlModule({
      onInitiate: (initiateCall) => {
        log.debug("Third party call control initiate call", initiateCall);
      },
      onTerminate: (terminateCall) => {
        log.debug("Third party call control terminate call", terminateCall);
      },
    });
    await authenticatedModule.setupWebappModule();
    const { notificationWebsocket, subscriptionWebsocket } = Server;
    this.subscriptionModule = new SubscriptionModule(
      authenticationModule,
      subscriptionWebsocket
    );
    this.notificationModule = new NotificationModule(
      authenticationModule,
      notificationWebsocket,
      Constants.PROFILE
    );
    this.notifications.setup(this.notificationModule, this.subscriptionModule);
    this.eventManager.setupGlobalSubscriptions(this.subscriptionModule);
    this.queueManagement.startSubscribingToChanges(this.eventManager);
    this.input.init();
    log.info("Finish setup");
  }
  private checkForOpenTickets({
    title,
    whenConfirmed,
  }: {
    title: string;
    whenConfirmed: () => void;
  }): void {
    if (this.state.online?.queueManagement.hasPickedTickets) {
      this.state.dialogs.show({
        actions: [
          { label: "Cancel", onSelect: () => {} },
          { label: title, onSelect: whenConfirmed },
        ],
        forceRespond: true,
        text: "You have at least one picked ticket. It is recommended to close all tickets before going offline, otherwise they will remain being picked indefinitely.",
        title: `${title}?`,
      });
    } else {
      whenConfirmed();
    }
  }
}
