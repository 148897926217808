import { gql } from "src/app/graphql";
import { Constants } from "src/app/model/Constants";
import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { LocationData } from "src/app/model/location/LocationData";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";
import type { Ticket } from "src/app/model/queues/Ticket";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { MapBounds } from "src/app/types/MapBounds";
import type { ClientUserDetailed } from "src/nextgen/types/ClientUserDetailed";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { MessageChannelUuid } from "src/nextgen/types/MessageChannelUuid";

const log = Logger.getLogger("TicketPanelData");

type Parameters = {
  bounds: MapBounds;
  queueEntryEntityId: null | string;
  storedLayer: string;
};

export class TicketPanelData {
  public contact: ContactDetails | null = null;
  public group?: Channel;
  public locationData?: LocationData;
  public ticket: Ticket | null = null;
  private groupSelectionUnsubscriber?: () => void;
  private isSetup = false;
  private messageUnsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    observableClass(this);
  }
  public get defaultParameters(): Parameters {
    return {
      bounds: {}, // Map bounds
      queueEntryEntityId: null,
      storedLayer: this.state.settings.map.storedLayer.selected.id as string,
    };
  }
  public get hasFullDuplexSession(): boolean {
    return this.contact?.hasFullDuplexRoom ?? false;
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get queueEntryEntityId(): null | string {
    return this.parameters.queueEntryEntityId;
  }
  public get session(): Session | undefined {
    return this.contact?.session;
  }
  public closeTicket(): void {
    if (this.ticket && this.ticket.isEmergency) {
      this.onClosing();
    } else {
      this.rememberAndClose();
    }
  }
  public onClosing(performClose?: () => void): void {
    const label = this.ticket!.isEmergency ? "alarm" : "ticket";
    if (!this.ticket || !this.ticket.pickedByMe) {
      performClose?.();
    } else {
      this.state.dialogs.show({
        actions: [
          { label: "Cancel", onSelect: () => {} },
          {
            label: `Close ${label}`,
            onSelect: () => {
              // close ticket...
              this.rememberAndClose();
            },
          },
        ],
        forceRespond: true,
        text: `Close ${label} from ${this.ticket.name}?`,
        title: `Close ${label}?`,
      });
    }
  }
  public onCreate(): void {
    log.debug(
      `Creating ticket panel data with id: ${this.parameters.queueEntryEntityId}`
    );
    if (this.ticket !== null) {
      void this.setup(this.ticket);
    }
  }
  public onDelete(): void {
    log.debug(
      `Deleting ticket panel data with id: ${this.parameters.queueEntryEntityId}`
    );
    this.isSetup = false;
    this.messageUnsubscriber?.();
    this.messageUnsubscriber = undefined;
    this.groupSelectionUnsubscriber?.();
    this.groupSelectionUnsubscriber = undefined;
    this.contact?.unsubscribeToUpdates();
    void this.locationData?.onDelete();
    this.locationData = undefined;
  }
  public async setTicket(ticket: Ticket): Promise<void> {
    this.ticket = ticket;
    void this.setup(ticket);
  }
  private async queryContactDetails(
    id: ClientUserUuid
  ): Promise<ClientUserDetailed | null | undefined> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserForTicket($id: ID!) {
          clientUser(id: $id) {
            id
            entityId
            displayName
            title
            email
            phoneNumbers
            onlineStatus {
              state
            }
            organization {
              id
              name
            }
            currentStatus {
              id
              name
              backgroundColor
              foregroundColor
            }
            availableStatuses {
              ... on ClientUserStatus {
                id
                name
                backgroundColor
                foregroundColor
              }
            }
            permissions {
              locate
              call
              message
              fullDuplexCall
            }
            privateMessageChannel {
              id
              unreadCount
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser;
  }
  private async queryUnreadCount(id: ClientUserUuid): Promise<{
    id: string;
    privateMessageChannel?: {
      id: MessageChannelUuid;
      unreadCount: number;
    } | null;
  } | null> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserUnreadCount($id: ID!) {
          clientUser(id: $id) {
            id
            privateMessageChannel { 
              id
              unreadCount
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser;
  }
  private async refreshUnreadCount(id: ClientUserUuid): Promise<void> {
    const contact = await this.queryUnreadCount(id);
    if (contact != null && contact.privateMessageChannel) {
      if (contact.privateMessageChannel.id) {
        this.contact?.setPrivateMessageChannelId(
          contact.privateMessageChannel.id
        );
      }
      if (contact.privateMessageChannel.unreadCount) {
        this.contact?.setUnreadCount(contact.privateMessageChannel.unreadCount);
      }
    }
  }
  private rememberAndClose(): void {
    const tabset = this.state.layout.tabsetIdContainingPanel(this.panel.id);
    localStorage.setItem(Constants.LAST_CLOSED_TICKET_TAB, tabset!);
    if (this.ticket) {
      this.ticket.close();
    }
    if (
      this.contact?.session &&
      this.ticket?.closeTicketsAndCallSimultaneously === false
    ) {
      // When we have just closed a ticket and have an ongoing private call with that user,
      // and we have not the setting enabled that closes the call automatically,
      // we need to open this call in a new panel (if there isn't one already)
      // so that we can close it manually.
      this.contact.openOrFocusPanel(this.panel, ContactTabSetting.Call);
    }
  }
  private async setup(ticket: Ticket): Promise<void> {
    if (this.isSetup) {
      return;
    }
    this.isSetup = true;
    log.debug(
      `Setting ticket panel data with id: ${ticket.queueEntryEntityId}`
    );
    try {
      if (ticket.userUuid != null) {
        const contact = await this.queryContactDetails(ticket.userUuid);
        if (contact != null) {
          this.contact = new ContactDetails(this.state, {
            callPermission: contact.permissions.call,
            description: contact.title,
            email: contact.email,
            entityId: contact.entityId,
            fullDuplexPermission: contact.permissions.fullDuplexCall,
            id: contact.id,
            locatable: contact.permissions.locate,
            messagePermission: contact.permissions.message,
            name: contact.displayName,
            online: contact.onlineStatus.state === "ONLINE",
            organization: contact.organization,
            phoneNumbers: contact.phoneNumbers,
            privateMessageChannelId: contact.privateMessageChannel?.id,
            status: contact.currentStatus,
            statusList: contact.availableStatuses,
            unreadCount: contact.privateMessageChannel?.unreadCount,
          });
          this.contact.subscribeToUpdates({
            onNameUpdated: (newName) => {
              this.panel.setName(newName);
            },
          });
          if (
            ticket.queueEntryEntityId != null &&
            (ticket.isEmergency || this.contact.locatable)
          ) {
            log.debug(
              `Creating location data with queueEntryEntityId: ${ticket.queueEntryEntityId}`
            );
            this.locationData = new LocationData(
              this.state,
              this.panel,
              ticket.queueEntryEntityId,
              ticket.name
            );
          }
          // fetch callsign from Callsign Module and the Node API
          void this.contact.fetchCallsign();
          if (contact.privateMessageChannel) {
            this.subscribeToNewMessages();
          }
        }
      }
      void (async () => {
        if (ticket.channelUuid) {
          // We do not await on the subscribe since we want to do both
          // fetch the group and subscribe to changes simultanously
          // to decrease latency
          this.subscribeToGroupSelection(ticket.channelUuid);
          const group = await this.state.online?.channels.channelWithUuid(
            ticket.channelUuid
          );
          this.group = group;
        }
      })();
    } catch (err: any) {
      log.error(err);
    }
  }
  private subscribeToGroupSelection(ticketGroupId: string): void {
    this.groupSelectionUnsubscriber =
      this.state.online?.subscriptionModule?.subscribe({
        onEvent: (msg) => {
          void (async () => {
            log.debug("Group selection changed", msg.payload);
            if (msg.type === "ClientPTTSelectionChange") {
              const { updatedSelections } = msg.payload;
              if (
                updatedSelections.length > 0 &&
                updatedSelections.includes(ticketGroupId)
              ) {
                const group = await this.state.online?.channels.channelWithUuid(
                  ticketGroupId
                );
                this.group = group;
              } else {
                log.warn(
                  `Group selection changed but payload did not contain the channel in the ticket: ${ticketGroupId}`
                );
              }
            } else if (msg.type === "GroupLockStateChange") {
              const { groupId, lock } = msg.payload;
              if (groupId === ticketGroupId) {
                if (this.group) {
                  this.group.lockStatus = lock;
                } else {
                  log.debug("Group lock status event before group exists.");
                }
              } else {
                log.warn(
                  `Lock changed but payload id ${groupId} is not the same as the channel in the ticket: ${ticketGroupId}`
                );
              }
            }
          })();
        },
        onSetupSubscription: async (webSocketId) => {
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
            mutation subscribeGroupChanges(
              $groupFilter: GroupFilter!,
              $profile: String,
              $webSocketId: String!
            ) {
              subscribeGroupChanges(input: {
                groupFilter: $groupFilter,
                profile: $profile,
                webSocketId: $webSocketId
              }) {
                subscriptionId
              }
            }
          `),
            variables: {
              groupFilter: {
                allGroups: false,
                groupIds: [ticketGroupId],
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeGroupChanges.subscriptionId;
        },
        onTearDownSubscription: (subscriptionId) => {
          try {
            void this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
              mutation unsubscribeGroupChanges(
                $subscriptionId: ID!
              ) {
                unsubscribeGroupChanges(input: {
                  subscriptionId: $subscriptionId
                }) {
                  error {
                    __typename
                    ... on AuthorizationError {
                        message
                    }
                  }
                }
              }
            `),
              variables: { subscriptionId },
            });
          } catch (e) {
            log.warn(`Unable to unsubscribe from group selection: ${e}`);
          }
        },
      });
  }
  private subscribeToNewMessages(): void {
    if (this.ticket && this.ticket.userEntityId) {
      log.debug("subscribing to new messages...");
      this.messageUnsubscriber =
        this.state.online?.subscriptionModule?.subscribe({
          onEvent: (msg) => {
            if (msg.type === "NewMessage") {
              // new message in a private message channel => No action required for the ticket view
            } else if (msg.type === "MetadataUpdate") {
              const { groupId } = msg.payload;
              log.debug("MetadataUpdate for message channel", groupId);

              // check if the ticket's user's private message channel matches the
              // group/channel the MetadataUpdate is for, if so we need to re-fetch the
              // data to get the changes
              if (
                this.ticket?.userUuid &&
                this.contact?.hasPrivateMessageChannel(groupId)
              ) {
                log.debug("Refreshing private message channel");
                void this.refreshUnreadCount(this.ticket.userUuid);
              }
            } else if (msg.type === "PrivateMessageChannelCreated") {
              // PrivateMessageChannelCreated => Re-fetch data if the ticket's user is one of
              // the participants of the newly created channel
              const participants = msg.payload.participants as ClientUserUuid[];
              log.debug("Private message channel created with", participants);
              if (
                this.ticket?.userUuid &&
                participants.some((v) => this.ticket!.userUuid === v)
              ) {
                log.debug("Refreshing private message channel");
                void this.refreshUnreadCount(this.ticket.userUuid);
              }
            }
          },
          onSetupSubscription: async (webSocketId) => {
            const data = await this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
            mutation subscribeNewMessagesToTicket(
              $webSocketId: String!,
              $profile: String!,
              $channelFilter: ChannelFilter!
            ) {
              subscribeNewMessages(input: {
                webSocketId: $webSocketId,
                profile: $profile,
                channelFilter: $channelFilter
              }) {
                subscriptionId
              }
            }
          `),
              variables: {
                channelFilter: {
                  allChannelMessages: false,
                  allPrivateMessages: true,
                  channelIds: null,
                },
                profile: Constants.PROFILE,
                webSocketId,
              },
            });
            return data.subscribeNewMessages.subscriptionId;
          },
          onTearDownSubscription: (subscriptionId) => {
            try {
              void this.state.graphqlModule.mutationDataOrThrow({
                mutation: gql(`
              mutation unsubscribeMessages(
                $subscriptionId: ID!
              ) {
                unsubscribeMessages(input: {
                  subscriptionId: $subscriptionId
                }) {
                  error {
                    __typename
                    ... on AuthorizationError {
                        message
                    }
                  }
                }
              }
            `),
                variables: { subscriptionId },
              });
            } catch (e) {
              log.warn(`Unable to unsubscribe from messages: ${e}`);
            }
          },
        });
    }
  }
}
