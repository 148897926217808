import { Channels } from "src/app/model/channels/Channels";
import { StringSetting } from "src/app/model/settings/StringSetting";
import { observableClass } from "src/app/state/observableClass";
import { reaction } from "mobx";
import localStorage from "mobx-localstorage";
import type { IReactionDisposer } from "mobx";
import type { State } from "src/app/model/State";

export class GroupSelectionSetting {
  public readonly filterSetting: StringSetting;
  public readonly groups: Channels;
  private disposeChannels?: IReactionDisposer;
  public constructor(state: State, id?: string) {
    // TODO: maybe does not need the full fields fragment?
    this.groups = new Channels(state);
    this.filterSetting = new StringSetting({
      defaultValue: "",
      key: `gt2.filter.groupSelectionSetting${
        id !== undefined ? `.${id}` : ""
      }`,
    });
    observableClass(this);
  }
  public static get selected() {
    return (key: string, uuid: string): boolean =>
      localStorage.getItem(key) === uuid;
  }
  public static select(key: string, uuid: string): void {
    if (GroupSelectionSetting.selected(key, uuid)) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, uuid);
    }
  }
  public closeDown(): void {
    this.disposeChannels?.();
    this.disposeChannels = undefined;
  }
  public setup(): void {
    void this.groups.fetchChannels();
    this.disposeChannels = reaction(
      () => ({
        name: this.filterSetting.value ? `%${this.filterSetting.value}%` : null,
      }),
      (filter) => {
        this.groups.setFilter(filter);
      },
      { fireImmediately: true }
    );
  }
}
