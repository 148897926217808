import { Alarm } from "src/lib/modules/Alarm";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { Location } from "src/lib/types/Location";

const log = Logger.getLogger("EmergencyModule");

/**
 * Returned from <code>setupEmergencyModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class EmergencyModule {
  public readonly emergencyDialupNumber?: string;
  public readonly emergencySms?: string;
  public readonly emergencySmsReceivers?: string[];
  private readonly onAlarmClearedCallback?: (alarm: Alarm) => void;
  private readonly onAlarmRaisedCallback?: (alarm: Alarm) => void;
  private alarm?: Alarm;
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      emergencyDialupNumber?: string;
      emergencySms?: string;
      emergencySmsReceivers?: string[];
      onAlarmCleared?: (alarm: Alarm) => void;
      onAlarmRaised?: (alarm: Alarm) => void;
    } = {}
  ) {
    /**
     * Emergency SMS string to be sent when panic alarm is triggered.
     * It contains placeholders that needs to be replaced according to specification:
     * {@link http://dl.grouptalk.com/grouptalk-emergency-specification.pdf}.
     * @member {string}
     */
    this.emergencySms = options.emergencySms;
    /**
     * Receivers (phone-numbers) of emergency sms.
     * @member {Array<string>}
     */
    this.emergencySmsReceivers = options.emergencySmsReceivers;
    /**
     * Dialup number that client should call when panic alarm is triggered.
     * @member {string}
     */
    this.emergencyDialupNumber = options.emergencyDialupNumber;
    /**
     * Callback when alarm is active on server.
     * @member {function(Alarm)}
     */
    this.onAlarmRaisedCallback = options.onAlarmRaised;
    /**
     * Callback when alarm has been cleared from server.
     * @member {function(Alarm)}
     */
    this.onAlarmClearedCallback = options.onAlarmCleared;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      emergencyDialupNumber?: string;
      emergencySms?: string;
      emergencySmsReceivers?: string[];
      onAlarmCleared?: (alarm: Alarm) => void;
      onAlarmRaised?: (alarm: Alarm) => void;
    }
  ): Promise<EmergencyModule> {
    const response = (await requestManager.send({
      emergency: { setupRequest: {} },
    })) as proto.IEmergencyModuleSetupResponse;
    log.debug("emergency module setup.", response);
    return new EmergencyModule(requestManager, {
      emergencyDialupNumber: response.emergencyDialupNumber ?? undefined,
      emergencySms: response.emergencySms ?? undefined,
      emergencySmsReceivers: response.emergencySmsReceivers ?? undefined,
      ...options,
    });
  }
  public onRequest(
    message: proto.IEmergencyAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.alarmRaised) {
      this.onAlarmRaised(message.alarmRaised, respond);
    } else if (message.alarmCleared) {
      this.onAlarmCleared(message.alarmCleared, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Raise alarm to GroupTalk servers.
   * <br/><strong>Note:</strong> This does not send any SMSs or make any dialup calls.
   * @param {Location} [location] Location at the time of the alarm. <code>(optional)</code>
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async raiseAlarm(location: Location): Promise<void> {
    await this.requestManager.send({
      emergency: { raiseAlarmRequest: { location } },
    });
  }
  private onAlarmCleared(
    message: proto.IEmergencyModuleAlarmCleared,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.alarm && this.alarm.alarmId === message.alarmId) {
      this.alarm.cleared = true;
      this.onAlarmClearedCallback?.(this.alarm);
    } else {
      log.warn("Unknown alarm cleared");
    }
  }
  private onAlarmRaised(
    message: proto.IEmergencyModuleAlarmRaised,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    const { alarmId, clientMayClear } = message;
    this.alarm = new Alarm(
      this.requestManager,
      longToNumber(alarmId),
      clientMayClear
    );
    this.onAlarmRaisedCallback?.(this.alarm);
  }
}
