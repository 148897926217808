import { GtTextField } from "src/app/gui/components/GtTextField";
import { connect } from "src/app/state/connect";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const Dialogs = connect((_, state) => (
  <>
    {Object.values(state.dialogs.list).map((dialog) => {
      const {
        actions,
        forceRespond,
        id,
        onClose,
        open,
        setTextFieldValue,
        text,
        textField,
        title,
      } = dialog;
      return (
        <Dialog
          onClose={(__, reason) => {
            if (
              !forceRespond ||
              (reason !== "backdropClick" && reason !== "escapeKeyDown")
            ) {
              onClose();
            }
          }}
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          disableEscapeKeyDown={forceRespond}
          key={id}
          open={open}
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {text && (
              <DialogContentText id="alert-dialog-description">
                {text}
              </DialogContentText>
            )}
            {textField && (
              <GtTextField
                fullWidth={false}
                label={textField.hint}
                onValueChanged={(value) => setTextFieldValue(value)}
                style={{ textAlign: "center" }}
                type={textField.type}
                value={textField.value}
              />
            )}
          </DialogContent>
          <DialogActions>
            {actions.map((action) => (
              <Button
                onClick={() => {
                  if (action.onSelect) {
                    action.onSelect({ textField });
                  }
                  onClose();
                }}
                color="primary"
                key={action.label}
              >
                {action.label}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      );
    })}
  </>
));
