import { IlbcEncoder } from "src/audio/codec/IlbcEncoder";
import { OpusEncoder } from "src/audio/codec/OpusEncoder";
import { Codec } from "src/lib/types/Codec";
import { Logger } from "src/util/Logger";
import type { Encoder } from "src/audio/codec/Encoder";
import type { AudioModule } from "src/lib/modules/AudioModule";
import type { TalkburstTransmission } from "src/lib/modules/TalkburstTransmission";
import type { CodecSettings } from "src/lib/types/CodecSettings";

const FRAMES = 320;
const log = Logger.getLogger("TalkburstModule");

/**
 * A single talkburst transmission.
 * @namespace
 */
export class OutgoingTalkburst {
  private readonly audioModule: AudioModule;
  private readonly codec: Codec;
  private readonly encoder: Encoder;
  private readonly talkburstTransmission: TalkburstTransmission;
  private inputLevel = 0;
  private onRecordedLevelListener?: (level: number) => void;
  private recordBufferPointer = 0;
  private seqNo = 0;
  private unencodedRecordBuffer = new Float32Array(FRAMES);
  public constructor(options: {
    audioModule: AudioModule;
    codec: Codec;
    codecSettings: CodecSettings;
    talkburstTransmission: TalkburstTransmission;
  }) {
    this.audioModule = options.audioModule;
    this.talkburstTransmission = options.talkburstTransmission;
    this.codec = options.codec;
    this.encoder =
      this.codec === Codec.iLBC
        ? new IlbcEncoder()
        : new OpusEncoder(options.codecSettings.opus);
  }
  public onRecordedLevel(listener: (level: number) => void): void {
    this.onRecordedLevelListener = listener;
    this.onRecordedLevelListener(this.inputLevel);
  }
  public onStopped(): void {
    this.encoder.release();
  }
  /**
   * @param {Float32Array} audio Send audio in 16kHz 16 bit mono signed PCM format. Accepts variable
   * arrays lengths.
   */
  public sendAudio(audio: Float32Array): void {
    let p = 0;
    const l = audio.length;
    let calculateInputLevel = false;
    while (p < l) {
      const remainingFramesInEncodedRecordBuffer =
        FRAMES - this.recordBufferPointer;
      if (p + remainingFramesInEncodedRecordBuffer < l) {
        this.unencodedRecordBuffer.set(
          audio.subarray(p, p + remainingFramesInEncodedRecordBuffer),
          this.recordBufferPointer
        );
        this.recordBufferPointer = FRAMES;
        this.flushEncodingBuffer();
        calculateInputLevel = true;
        p += remainingFramesInEncodedRecordBuffer;
      } else {
        if (calculateInputLevel) {
          // Last chance to update input level before clearing buffer
          this.updateInputLevel();
          calculateInputLevel = false;
        }
        this.unencodedRecordBuffer.set(
          audio.subarray(p, l),
          this.recordBufferPointer
        );
        this.recordBufferPointer += l - p;
        p = l;
      }
    }
    if (calculateInputLevel) {
      this.updateInputLevel();
    }
  }
  /**
   * Stop this transmission.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async stop(): Promise<void> {
    const { onStopped } = this.talkburstTransmission;
    if (onStopped) {
      this.talkburstTransmission.onStopped = undefined;
      await onStopped();
    }
    try {
      await this.talkburstTransmission.stopTransmission(this.seqNo - 1);
    } catch (error: any) {
      log.error(error);
    }
  }
  private flushEncodingBuffer(): void {
    // Zero out remaining buffer with empty sound. (Usually recordBufferPointer == FRAMES)
    for (let i = this.recordBufferPointer; i < FRAMES; i += 1) {
      this.unencodedRecordBuffer[i] = 0;
    }
    this.recordBufferPointer = 0;
    this.encoder.encode(this.unencodedRecordBuffer, (buffer) => {
      this.sendBuffer(buffer);
    });
  }
  private sendBuffer(buffer: ArrayBuffer): void {
    // Todo: Handle case of sending audio with UDP module.
    this.audioModule.sendAudio({
      audio: new Uint8Array(buffer),
      codec: this.codec,
      seqNo: this.seqNo,
      talkburstId: this.talkburstTransmission.talkburstId,
    });
    this.seqNo += 1;
  }
  private updateInputLevel(): void {
    let inputLevel = this.unencodedRecordBuffer.reduce((maxValue, frame) =>
      Math.max(maxValue, frame >= 0 ? frame : -frame)
    );
    if (inputLevel > 0) {
      inputLevel = 20 * Math.log10(inputLevel / 32768.0);
    } else {
      inputLevel = Number.NEGATIVE_INFINITY;
    }
    inputLevel = 20 - Math.min(Math.max(Math.floor(-inputLevel / 2.5), 0), 20);
    if (this.inputLevel < inputLevel) {
      this.inputLevel = Math.min(this.inputLevel + 2, inputLevel);
    } else if (this.inputLevel > inputLevel) {
      this.inputLevel = Math.max(this.inputLevel - 2, inputLevel);
    }
    this.onRecordedLevelListener?.(this.inputLevel);
  }
}
