import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * No answer.
 */
export class TemporarilyUnavailable extends GroupTalkAPIError {
  public constructor() {
    super("No answer.", true);
  }
}
