import { apiErrorfromCode } from "src/lib/apiErrorfromCode";
import { ClientRequestTimeout } from "src/lib/errors/ClientRequestTimeout";
import { DecodingError } from "src/lib/errors/DecodingError";
import { ServiceUnavailable } from "src/lib/errors/ServiceUnavailable";
import { TooManyRequests } from "src/lib/errors/TooManyRequests";
import { UnknownError } from "src/lib/errors/UnknownError";
import { ConnectedModule } from "src/lib/modules/ConnectedModule";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { WebSocketConnection } from "src/nextgen/WebSocketConnection";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { Logger } from "src/util/Logger";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";
import type { CancelListener } from "src/lib/types/CancelListener";
import type { IClientResponse } from "src/lib/types/IClientResponse";
import type { IServerResponse } from "src/lib/types/IServerResponse";

const log = Logger.getLogger("RequestManager");

const CANCEL_TIMEOUT = 10000;
const TEMPORARY_TIMEOUT = 10000;
const FINAL_TIMEOUT = 60000;

function getResponseFromClientMessage(
  message: proto.IClientMessage,
  response: Uint8Array
): {
  [k: string]: any;
} | null {
  if (message.authenticate && message.authenticate.authenticateRequest) {
    return proto.AuthenticateResponse.toObject(
      proto.AuthenticateResponse.decode(response)
    );
  }
  if (message.authenticate && message.authenticate.createTokenRequest) {
    return proto.CreateTokenResponse.toObject(
      proto.CreateTokenResponse.decode(response)
    );
  }
  if (message.callsignV2 && message.callsignV2.setupRequest) {
    return proto.CallsignV2ModuleSetupResponse.toObject(
      proto.CallsignV2ModuleSetupResponse.decode(response)
    );
  }
  if (message.callsignV2 && message.callsignV2.getCallsignRequest) {
    return proto.CallsignV2GetCallsignResponse.toObject(
      proto.CallsignV2GetCallsignResponse.decode(response)
    );
  }
  if (message.capabilities) {
    return proto.CapabilitiesResponse.toObject(
      proto.CapabilitiesResponse.decode(response)
    );
  }
  if (message.channel && message.channel.setupRequest) {
    return proto.ChannelModuleSetupResponse.toObject(
      proto.ChannelModuleSetupResponse.decode(response)
    );
  }
  if (message.channel && message.channel.subscribeRequest) {
    return proto.ChannelSubscribeResponse.toObject(
      proto.ChannelSubscribeResponse.decode(response)
    );
  }
  if (message.customAction && message.customAction.setupRequest) {
    return proto.CustomActionModuleSetupResponse.toObject(
      proto.CustomActionModuleSetupResponse.decode(response)
    );
  }
  if (message.emergency && message.emergency.setupRequest) {
    return proto.EmergencyModuleSetupResponse.toObject(
      proto.EmergencyModuleSetupResponse.decode(response)
    );
  }
  if (message.keepAliveSetup) {
    return proto.KeepAliveAPIv1SetupResponse.toObject(
      proto.KeepAliveAPIv1SetupResponse.decode(response)
    );
  }
  if (message.locationEnquiry && message.locationEnquiry.setupRequest) {
    return proto.LocationEnquiryModuleSetupResponse.toObject(
      proto.LocationEnquiryModuleSetupResponse.decode(response)
    );
  }
  if (message.locationEnquiry && message.locationEnquiry.subscribeRequest) {
    return proto.LocationEnquirySubscribeResponse.toObject(
      proto.LocationEnquirySubscribeResponse.decode(response)
    );
  }
  if (message.phoneBook && message.phoneBook.setupRequest) {
    return proto.PhoneBookModuleSetupResponse.toObject(
      proto.PhoneBookModuleSetupResponse.decode(response)
    );
  }
  if (message.phoneBook && message.phoneBook.detailedListRequest) {
    return proto.PhoneBookDetailedListResponse.toObject(
      proto.PhoneBookDetailedListResponse.decode(response)
    );
  }
  if (message.phoneBook && message.phoneBook.lookupRequest) {
    return proto.PhoneBookLookupResponse.toObject(
      proto.PhoneBookLookupResponse.decode(response)
    );
  }
  if (message.phoneBook && message.phoneBook.subscribeRequest) {
    return proto.PhoneBookSubscribeResponse.toObject(
      proto.PhoneBookSubscribeResponse.decode(response)
    );
  }
  if (message.presence && message.presence.subscribeRequest) {
    return proto.PresenceSubscribeResponse.toObject(
      proto.PresenceSubscribeResponse.decode(response)
    );
  }
  if (message.queueManagement && message.queueManagement.setupRequest) {
    return proto.QueueManagementModuleSetupResponse.toObject(
      proto.QueueManagementModuleSetupResponse.decode(response)
    );
  }
  if (message.queueManagement && message.queueManagement.subscribeRequest) {
    return proto.QueueManagementSubscribeResponse.toObject(
      proto.QueueManagementSubscribeResponse.decode(response)
    );
  }
  if (message.setupCall && message.setupCall.initiateRequest) {
    return proto.SetupCallInitiateResponse.toObject(
      proto.SetupCallInitiateResponse.decode(response)
    );
  }
  if (message.setupCall && message.setupCall.monitoringRequest) {
    return proto.MonitoringInitiateResponse.toObject(
      proto.MonitoringInitiateResponse.decode(response)
    );
  }
  if (message.session && message.session.setupRequest) {
    return proto.SessionModuleSetupResponse.toObject(
      proto.SessionModuleSetupResponse.decode(response)
    );
  }
  if (message.status && message.status.setupRequest) {
    return proto.StatusModuleSetupResponse.toObject(
      proto.StatusModuleSetupResponse.decode(response)
    );
  }
  if (message.status && message.status.listAvailableRequest) {
    return proto.StatusModuleListAvailableResponse.toObject(
      proto.StatusModuleListAvailableResponse.decode(response)
    );
  }
  if (message.talkburstReception && message.talkburstReception.setupRequest) {
    return proto.TalkburstReceptionModuleSetupResponse.toObject(
      proto.TalkburstReceptionModuleSetupResponse.decode(response)
    );
  }
  if (message.udp && message.udp.setupRequest) {
    return proto.UDPModuleSetupResponse.toObject(
      proto.UDPModuleSetupResponse.decode(response)
    );
  }
  if (message.webapp && message.webapp.setupRequest) {
    return proto.WebappModuleSetupResponse.toObject(
      proto.WebappModuleSetupResponse.decode(response)
    );
  }
  return null;
}

function getResponseFromServerMessage(
  message: proto.IServerMessage,
  response: IServerResponse
): Uint8Array | undefined {
  if (message.authenticate && message.authenticate.passwordChallengeRequest) {
    return proto.PasswordChallengeResponse.encode(
      response as proto.IPasswordChallengeResponse
    ).finish();
  }
  if (message.authenticate && message.authenticate.tokenChallengeRequest) {
    return proto.TokenChallengeResponse.encode(
      response as proto.ITokenChallengeResponse
    ).finish();
  }
  if (message.httpRequest && message.httpRequest.httpRequest) {
    return proto.HTTPRequestResponse.encode(
      response as proto.IHTTPRequestResponse
    ).finish();
  }
  if (message.locationReport && message.locationReport.updateRequest) {
    return proto.LocationReportUpdateResponse.encode(
      response as proto.ILocationReportUpdateResponse
    ).finish();
  }
  if (message.talkburstReception && message.talkburstReception.stop) {
    return proto.TalkburstReceptionStoppedResponse.encode(
      response as proto.ITalkburstReceptionStoppedResponse
    ).finish();
  }
  return undefined;
}

export class RequestManager {
  public connectedModule?: ConnectedModule;
  private readonly onDisconnect: (error: GroupTalkAPIError) => void;
  private readonly sentRequests: Record<
    number,
    (responseCode: number, response?: Uint8Array) => void
  > = {};
  private readonly server: string;
  private readonly timeout: number;
  private connection?: WebSocketConnection;
  private error?: GroupTalkAPIError;
  private nextRequestId = 0;
  private receivedRequests: Record<
    number,
    {
      cancelListener?: CancelListener;
      respond: (responseCode: number, response?: IServerResponse) => void;
    }
  > = {};
  private stopped = false;
  public constructor(options: {
    onDisconnect: (error: GroupTalkAPIError) => void;
    server: string;
    timeout?: number;
  }) {
    const { onDisconnect, server, timeout = 5000 } = options;
    this.server = server;
    this.timeout = timeout || 5000;
    this.onDisconnect = onDisconnect;
  }
  // Wraps sendRequest in a promise for convinience. Does not include temporary results or cancel.
  public send(
    message: proto.IClientMessage
  ): Promise<IClientResponse | undefined> {
    return new Promise<IClientResponse | undefined>((resolve, reject) => {
      this.sendRequest(message, {
        onFinalResponse(error, response) {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        },
      });
    });
  }
  public sendRequest(
    message: proto.IClientMessage,
    {
      onFinalResponse,
      onTemporaryResponse,
    }: {
      onFinalResponse: (
        error: GroupTalkAPIError | null,
        response?: IClientResponse
      ) => void;
      onTemporaryResponse?: (responseCode: number) => void;
    }
  ): CancelListener {
    if (this.stopped) {
      onFinalResponse(new ServiceUnavailable());
      return () => {};
    }
    if (message.audio && message.audio.data) {
      this.sendClientMessage(message);
      // This request cannot be cancelled.
      return () => {};
    }
    let timeoutTimer: () => undefined | void;
    const clearTimeoutTimer = (): void => {
      timeoutTimer?.();
    };
    const startTimeout = (delay: number): void => {
      clearTimeoutTimer();
      timeoutTimer = BackgroundTimer.setTimeout(() => {
        log.warn("Request timeout, closing connection");
        this.stop(new ClientRequestTimeout());
      }, delay);
    };
    const requestId = this.nextRequestId;
    let waitingForResponse = true;
    let requestCancelled = false;
    this.sentRequests[requestId] = (
      responseCode: number,
      response?: Uint8Array
    ) => {
      if (waitingForResponse) {
        if (responseCode < 200) {
          startTimeout(FINAL_TIMEOUT);
          if (onTemporaryResponse) {
            if (response) {
              const ResponseType = getResponseFromClientMessage(
                message,
                response
              );
              if (ResponseType) {
                onTemporaryResponse(responseCode);
              } else {
                log.warn(
                  `Got temporary response with unknown type for request ${requestId}`
                );
                onTemporaryResponse(responseCode);
              }
            } else {
              onTemporaryResponse(responseCode);
            }
          }
        } else {
          waitingForResponse = false;
          clearTimeoutTimer();
          delete this.sentRequests[requestId];
          if (onFinalResponse) {
            const error = apiErrorfromCode(responseCode);
            if (error instanceof TooManyRequests) {
              BackgroundTimer.setTimeout(() => {
                onFinalResponse(error);
              }, 3000);
            } else {
              if (error instanceof UnknownError) {
                log.warn(`Unknown response code: ${responseCode}`);
              } else if (error) {
                log.warn(`Error response ${responseCode} to request`, message);
              }
              if (response) {
                const clientResponse = getResponseFromClientMessage(
                  message,
                  response
                );
                if (clientResponse) {
                  onFinalResponse(error, clientResponse);
                } else {
                  log.warn(
                    `Got response with unknown type for request ${requestId} with initial request`,
                    message
                  );
                  onFinalResponse(error);
                }
              } else {
                onFinalResponse(error);
              }
            }
          }
        }
      } else {
        log.warn(
          `Got response to request ${requestId} while not waiting for response. Ignoring`
        );
      }
    };

    this.sendClientMessage({ ...message, requestId });
    this.nextRequestId += 1;
    startTimeout(TEMPORARY_TIMEOUT);

    return () => {
      if (waitingForResponse && !requestCancelled) {
        requestCancelled = true;
        this.sendClientMessage({ cancelRequest: true, requestId });
        startTimeout(CANCEL_TIMEOUT);
      }
    };
  }
  public async start(): Promise<ConnectedModule> {
    const connectionTimeoutTimer = BackgroundTimer.setTimeout(() => {
      log.warn("Connecting timeout, closing connection");
      this.stop(new ClientRequestTimeout());
    }, this.timeout);

    this.connection = new WebSocketConnection({
      onDisconnect: (disconnectError: GroupTalkAPIError) => {
        if (!this.stopped) {
          const error = this.error || disconnectError;
          log.debug(`Connection closed (${error})`);
          this.stopped = true;
          connectionTimeoutTimer();
          if (this.connectedModule) {
            this.connectedModule.onDisconnect();
            this.connectedModule = undefined;
          }
          Object.values(this.sentRequests).forEach((respond) =>
            respond(proto.ResponseCode.SERVICE_UNAVAILABLE)
          );
          if (this.onDisconnect) {
            this.onDisconnect(error);
          }
        }
      },
      onMessage: (data) => this.onMessage(data),
      server: this.server,
    });
    log.debug(`Open connection to ${this.server}...`);
    await this.connection.start();
    log.debug(`Connected to ${this.server}`);
    connectionTimeoutTimer();
    this.connectedModule = new ConnectedModule(this);
    return this.connectedModule;
  }
  public stop(error: GroupTalkAPIError): void {
    if (!this.stopped) {
      log.debug(`Stopping RequestManager (${error})`);
      log.error(error.message);
      this.error = error;
      this.connection?.stop();
      this.connection = undefined;
    }
  }
  private onMessage(data: any): void {
    if (!(data instanceof ArrayBuffer)) {
      return;
    }
    let serverMessage: proto.IServerMessage;
    try {
      serverMessage = proto.ServerMessage.toObject(
        proto.ServerMessage.decode(new Uint8Array(data))
      );
    } catch (e: any) {
      log.error(e);
      this.stop(new DecodingError());
      return;
    }
    const requestId =
      serverMessage.requestId != null
        ? longToNumber(serverMessage.requestId)
        : undefined;
    if (requestId === undefined) {
      // The only allowed request without requestId is an audio packet.
      if (serverMessage.audio && serverMessage.audio.data) {
        if (this.connectedModule) {
          this.connectedModule.onRequest(serverMessage, () => {
            /*
              No response available for these kinds of requests
            */
          });
        }
        return;
      }
      log.warn(
        "Received non-audio message without request-id, closing connection"
      );
      this.stop(new DecodingError());
      return;
    }

    if (serverMessage.cancelRequest) {
      log.debug(`Received cancel request for request ${requestId}`);
      const { cancelListener, respond } = this.receivedRequests[requestId];
      if (respond) {
        delete this.receivedRequests[requestId];
        respond(proto.ResponseCode.REQUEST_TERMINATED);
        if (cancelListener) {
          cancelListener();
        }
      } else {
        log.warn(
          `Received cancel request for unknown or already responded to, request ${requestId}`
        );
      }
      return;
    }
    if (serverMessage.responseCode) {
      // Response from previous request.
      const respond = this.sentRequests[requestId];
      if (!respond) {
        log.warn(`Received response to unknown request ${requestId}`);
        return;
      }
      respond(serverMessage.responseCode, serverMessage.response ?? undefined);
      return;
    }

    // New request.
    if (this.receivedRequests[requestId]) {
      log.warn(
        `Received new request with same id (${requestId}), closing connection`
      );
      this.stop(new DecodingError());
      return;
    }
    log.trace(`Received new request with id ${requestId}`, serverMessage);

    const respond = (
      responseCode: number,
      response?: IServerResponse
    ): void => {
      if (responseCode >= 200) {
        delete this.receivedRequests[requestId];
      }
      let codedResponse: Uint8Array | undefined;
      if (response) {
        codedResponse = getResponseFromServerMessage(serverMessage, response);
        if (codedResponse === null) {
          log.warn(`Unknown response type of request ${requestId}`);
          log.warn(`${serverMessage}`);
        }
      }
      const clientMessage: proto.IClientMessage = {
        requestId: serverMessage.requestId,
        response: codedResponse,
        responseCode,
      };
      this.sendClientMessage(clientMessage);
    };

    this.receivedRequests[requestId] = { respond };
    if (this.connectedModule) {
      this.receivedRequests[requestId].cancelListener =
        this.connectedModule.onRequest(serverMessage, respond);
    }
  }
  private sendClientMessage(message: proto.IClientMessage): void {
    this.connection?.sendMessage(proto.ClientMessage.encode(message).finish());
  }
}
