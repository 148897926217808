import {
  AlertIcon,
  MessageIcon,
  SessionIcon,
  GroupIcon,
  UsersIcon,
  UserIcon,
  QueueIcon,
  TicketIcon,
  LocationIcon,
  AlarmIcon,
  PriorityTicketIcon,
  PatchIcon,
} from "src/app/images";

export const panelTypeInfo = {
  channel: { icon: <GroupIcon />, label: "Group" },
  channels: { icon: <GroupIcon />, label: "Groups" },
  contact: { icon: <UserIcon />, label: "User" },
  contacts: { icon: <UsersIcon />, label: "Users" },
  emergencyTicket: { icon: <AlarmIcon />, label: "Ticket" },
  location: { icon: <LocationIcon />, label: "Location" },
  messages: { icon: <MessageIcon />, label: "Messages" },
  patch: { icon: <PatchIcon />, label: "Add Patch" },
  priorityTicket: { icon: <PriorityTicketIcon />, label: "Ticket" },
  sessions: { icon: <SessionIcon />, label: "Sessions" },
  ticket: { icon: <TicketIcon />, label: "Ticket" },
  tickets: { icon: <QueueIcon />, label: "Tickets" },
  unknown: { icon: <AlertIcon />, label: "Unknown" },
};
