import { GtTextField } from "src/app/gui/components/GtTextField";
import { Layout } from "src/app/gui/components/Layout";
import { SearchIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import type { StringSetting } from "src/app/model/settings/StringSetting";

export const SearchSetting = connect<{
  alwaysExpanded?: boolean;
  label: string;
  onValueChange?: (value: string) => void;
  setting: StringSetting;
}>(({ alwaysExpanded = false, label, onValueChange, setting }) => {
  const [expanded, setExpanded] = useState(
    setting.unvalidated.length > 0 || alwaysExpanded
  );
  const [keepOpen, setKeepOpen] = useState(alwaysExpanded);

  // Automatically collapse search field to an icon in case search
  // input is emptied from outside this component.
  useEffect(() => {
    if (setting.unvalidated.length === 0 && !keepOpen) {
      setExpanded(false);
    }
  }, [setting.unvalidated, keepOpen]);

  return (
    <Layout alignLeft alignMiddle gap={20} row>
      <Layout>
        {!expanded && (
          <IconButton
            onClick={() => {
              setExpanded(true);
            }}
            aria-label="Search"
            style={{ padding: 4 }}
          >
            <SearchIcon />
          </IconButton>
        )}
        {expanded && (
          <GtTextField
            onValidate={() => {
              if (setting.unvalidated.length === 0) {
                setExpanded(alwaysExpanded);
              }
            }}
            onValueChanged={(value) => {
              setting.setValue(value);
              setting.validate();
              if (onValueChange) {
                onValueChange(value);
              }
              // If we clear the input field ourself, do not collapse search field.
              setKeepOpen(setting.unvalidated.length === 0);
            }}
            InputLabelProps={{ style: { color: "#000" } }}
            autoFocus
            fullWidth={false}
            label={label}
            margin="dense"
            style={{ textAlign: "center" }}
            type="search"
            value={setting.unvalidated}
          />
        )}
      </Layout>
    </Layout>
  );
});
