export enum PanelType {
  channel = "channel",
  channels = "channels",
  contact = "contact",
  contacts = "contacts",
  emergencyTicket = "emergencyTicket",
  location = "location",
  patch = "patch",
  priorityTicket = "priorityTicket",
  ticket = "ticket",
  tickets = "tickets",
}
