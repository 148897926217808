import { observableClass } from "src/app/state/observableClass";
import { datesAreOnSameDay } from "src/util/datesAreOnSameDay";
import type { MessageFieldsFragment } from "src/app/graphql/graphql";
import type { MessageChannel } from "src/app/model/messages/MessageChannel";

export class Message {
  public readonly id: string;
  public readonly time: Date;
  public content: null | string | undefined;
  public fromId: string;
  public name: string;
  private readonly now = new Date();
  private readonly yesterday: Date;
  public constructor(
    message: MessageFieldsFragment,
    private readonly messageChannel: MessageChannel
  ) {
    this.yesterday = new Date(this.now.getTime() - 86400000);
    this.id = message.id;
    this.name = message.from.name;
    this.fromId = message.from.id;
    this.time = new Date(message.timestamp);
    this.content = message.content!.data;

    observableClass(this);
  }
  public get isFirstUnreadMessage(): boolean {
    return !!(
      this.messageChannel.firstUnreadMessage &&
      this.messageChannel.firstUnreadMessage.id === this.id
    );
  }
  public get isLastReadMessage(): boolean {
    return this.messageChannel.lastReadMessageId === this.id;
  }
  public get postedThisYear(): boolean {
    return this.time.getFullYear() === this.now.getFullYear();
  }
  public get postedToday(): boolean {
    return datesAreOnSameDay(this.time, this.now);
  }
  public get postedYesterday(): boolean {
    return datesAreOnSameDay(this.time, this.yesterday);
  }
  public get readableDay(): string {
    if (this.postedToday) {
      return "Today";
    }
    if (this.postedYesterday) {
      return "Yesterday";
    }
    return this.time.toLocaleDateString([], {
      day: "numeric",
      month: "long",
      weekday: "long",
      year: this.postedThisYear ? undefined : "numeric",
    });
  }
  public get readableTime(): string {
    return this.time.toLocaleTimeString([], {
      hour: "2-digit",
      hour12: false,
      minute: "2-digit",
    });
  }
}
