import { DecodingError } from "src/lib/errors/DecodingError";
import { CustomFolderAction } from "src/lib/modules/CustomFolderAction";
import { CustomPerformAction } from "src/lib/modules/CustomPerformAction";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { ActionType } from "src/lib/types/ActionType";
import { Logger } from "src/util/Logger";
import type { CustomAction } from "src/lib/modules/CustomAction";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("CustomActionModule");

/**
 * Returned from <code>setupCustomActionModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class CustomActionModule {
  private readonly customActions: CustomAction[];
  private constructor(options: { customActions: CustomAction[] }) {
    /**
     * Available custom actions which can be ordered in a hierarchy.
     * @member {Array<CustomAction>}
     */
    this.customActions = options.customActions;
  }
  /**
   * A subset of all custom actions of type <code>Queue</code> in a flat array.
   * @type {Array<CustomPerformAction>}
   */
  public get queueActions(): CustomAction[] {
    return this.customActions.reduce(
      (ack: CustomAction[], action: CustomAction) => [
        ...ack,
        ...action.queues(),
      ],
      []
    );
  }
  public static parseCustomAction(requestManager: RequestManager) {
    return (customAction: proto.ICustomAction): CustomAction => {
      const { caption, type } = customAction;
      const actionType =
        type === proto.CustomActionType.GENERIC
          ? ActionType.Generic
          : ActionType.Queue;
      if (customAction.performAction) {
        return new CustomPerformAction(
          requestManager,
          caption,
          actionType,
          longToNumber(customAction.performAction.id)
        );
      }
      if (
        customAction.folderAction != null &&
        customAction.folderAction.actions != null
      ) {
        const customActions = customAction.folderAction.actions.map((cA) =>
          CustomActionModule.parseCustomAction(requestManager)(cA)
        );
        return new CustomFolderAction(
          requestManager,
          caption,
          actionType,
          customActions
        );
      }
      throw new DecodingError();
    };
  }
  public static async setup(
    requestManager: RequestManager
  ): Promise<CustomActionModule> {
    const response = (await requestManager.send({
      customAction: { setupRequest: {} },
    })) as proto.ICustomActionModuleSetupResponse;
    log.debug("customAction module setup.", response);
    const customActions = response.customActions
      ? response.customActions.map(
          CustomActionModule.parseCustomAction(requestManager)
        )
      : [];
    return new CustomActionModule({ customActions });
  }
}
