import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { TicketIcon, PriorityTicketIcon, AlarmIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";
import type { Ticket } from "src/app/model/queues/Ticket";

export const TicketMenu = connect((_, state) => {
  const {
    hasPickedAlarmTickets,
    numberOfPickedTickets,
    numberOfUnpickedAlarmTickets,
    numberOfUnpickedTickets,
    ticketPanels,
    unpickedTickets,
  } = state.online!.queueManagement;
  let bg = "#60677c";
  let label = "No tickets";
  let showTicketIcon = false;
  let showHighPriorityIcon = false;
  let showAlarmIcon = false;
  let tickets: {
    panel?: Panel;
    ticket: Ticket | null;
  }[] = [];
  let verb = "";
  const showPicked =
    numberOfPickedTickets > 0 &&
    (numberOfUnpickedAlarmTickets === 0 || hasPickedAlarmTickets);

  if (showPicked) {
    bg = "#5f8b64";
    label = `${numberOfPickedTickets} picked`;
    tickets = ticketPanels.map((panel) => ({
      panel,
      ticket: panel.ticketPanelData!.ticket,
    }));
  } else if (numberOfUnpickedTickets > 0) {
    bg = "#3277d1";
    label = `${numberOfUnpickedTickets} unpicked`;
    tickets = unpickedTickets.map((ticket) => ({ ticket }));
    verb = "Pick ";
  }

  const items: JSX.Element[] = [];
  tickets.forEach(({ panel, ticket }) => {
    if (items.length === 10 || ticket === null) {
      return; /* Show max ten tickets */
    }
    let UseIcon:
      | typeof AlarmIcon
      | typeof PriorityTicketIcon
      | typeof TicketIcon = TicketIcon;
    if (ticket.isEmergency) {
      showAlarmIcon = true;
      UseIcon = AlarmIcon;
      bg = "#d13e3e";
    } else if (ticket.isHighPriority) {
      showHighPriorityIcon = true;
      UseIcon = PriorityTicketIcon;
      if (!showAlarmIcon) {
        bg = "#967a2e";
      }
    } else {
      showTicketIcon = true;
    }
    items.push(
      <GtMenuItem
        label={
          <Text color="#444">{`${verb}${ticket.queue.name}: ${ticket.name}`}</Text>
        }
        onSelect={() => {
          if (showPicked) {
            panel!.select();
            panel!.flash();
          } else {
            void ticket.pick();
          }
        }}
        icon={<UseIcon style={{ fill: "#666" }} />}
        key={`ticket-${ticket.queueEntryEntityId}`}
      />
    );
  });

  return (
    <Layout row>
      <GtMenu
        id="tickets"
        items={items}
        onOpen={() => Promise.resolve(items.length > 0)}
      >
        <Layout
          style={{
            borderRadius: 5,
            width: "100%",
          }}
          alignCenter
          alignMiddle
          background={bg}
          grow
          margin={8}
          padding="5px 10px 5px 10px"
          row
        >
          <Text center color="#fff">
            {label}
          </Text>
          {showTicketIcon && (
            <TicketIcon style={{ fill: "#fff", marginLeft: 5 }} />
          )}
          {showHighPriorityIcon && (
            <PriorityTicketIcon style={{ fill: "#fff", marginLeft: 5 }} />
          )}
          {showAlarmIcon && (
            <AlarmIcon style={{ fill: "#fff", marginLeft: 5 }} />
          )}
        </Layout>
      </GtMenu>
    </Layout>
  );
});
