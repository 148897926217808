import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Connection closed locally.
 */
export class ConnectionClosedLocally extends GroupTalkAPIError {
  public constructor() {
    super("Connection closed locally.", false);
  }
}
