import { Button } from "src/app/model/input/Button";
import { ButtonFunction } from "src/app/model/input/ButtonFunction";
import type { ButtonData } from "src/app/model/input/ButtonData";

export const Buttons: Record<Button, ButtonData> = {
  PRIMARY: {
    default: ButtonFunction.PTT_SELECTED_GROUP,
    id: Button.PRIMARY,
    name: "Primary button",
  },
  SECONDARY1: {
    default: ButtonFunction.PTT_SELECTED_GROUP,
    id: Button.SECONDARY1,
    name: "Function button 1",
  },
  SECONDARY2: {
    default: ButtonFunction.PTT_SELECTED_GROUP,
    id: Button.SECONDARY2,
    name: "Function button 2",
  },
  SECONDARY3: {
    default: ButtonFunction.PTT_SELECTED_GROUP,
    id: Button.SECONDARY3,
    name: "Function button 3",
  },
  SECONDARY4: {
    default: ButtonFunction.PTT_SELECTED_GROUP,
    id: Button.SECONDARY4,
    name: "Function button 4",
  },
};
