import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { observableClass } from "src/app/state/observableClass";

export class NotificationSoundsSettings {
  public readonly callStartSounds: BooleanSetting;
  public readonly callStopSounds: BooleanSetting;
  public readonly notificationSounds: BooleanSetting;
  public readonly showCallsMenu: BooleanSetting;
  public readonly showLatestSpeaker: BooleanSetting;
  public readonly startSounds: BooleanSetting;
  public readonly stopSounds: BooleanSetting;
  public constructor() {
    this.startSounds = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.playStartSound",
    });
    this.stopSounds = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.playStopSound",
    });
    this.callStartSounds = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.playCallStartSound",
    });
    this.callStopSounds = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.playCallStopSound",
    });
    this.notificationSounds = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.notificationSounds",
    });
    this.showCallsMenu = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.showCallsMenu",
    });
    this.showLatestSpeaker = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.showLatestSpeaker",
    });
    observableClass(this);
  }
}
