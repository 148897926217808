import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Forbidden.
 */
export class Forbidden extends GroupTalkAPIError {
  public constructor() {
    super("Forbidden.", false);
  }
}
