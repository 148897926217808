import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { PresenceRow } from "src/app/gui/panels/channel/PresenceRow";
import { SearchSetting } from "src/app/gui/settings/SearchSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";

export const Presence = connect<{
  panel: Panel;
  session: Session;
}>(({ panel, session }) => {
  const { filterPresence, filterSetting } = panel.channelPanelData!;
  const { moreElements, presence } = filterPresence(session.presence);

  const elementsToShow = presence.map((presenceRow, index) => (
    <PresenceRow
      evenRow={index % 2 === 1}
      key={presenceRow.presenceId}
      panel={panel}
      presence={presenceRow}
    />
  ));

  if (moreElements > 0) {
    elementsToShow.push(
      <Layout
        alignMiddle
        key="moreElements"
        minHeight={36}
        paddingBottom={5}
        paddingLeft={10}
        paddingRight={5}
        paddingTop={5}
        row
        style={{ cursor: "pointer" }}
      >
        <Text color="#000000" size={16} weight={500}>
          ... {moreElements} more
        </Text>
      </Layout>
    );
  }

  return (
    <Layout alignTop column grow>
      <Layout alignMiddle minHeight={52} row>
        <Layout marginRight={20} row>
          <SearchSetting label="Search" setting={filterSetting} />
        </Layout>
      </Layout>
      <GtInfiniteRenderer
        elements={elementsToShow}
        noElements="No users in group."
      />
    </Layout>
  );
});
