import { Button } from "src/app/model/input/Button";
import type { ButtonMapper } from "src/app/model/input/ButtonMapper";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";

export class MobilitySoundFootpedalHandler implements DeviceHandler {
  private state = 0;
  public constructor(
    private readonly buttonMapper: ButtonMapper,
    private readonly id: string
  ) {}
  public onData(data: ArrayBuffer): void {
    const newState = new Uint8Array(data)[0] & 0xff;
    if (this.state !== 32 && newState === 32) {
      this.buttonMapper.onButtonDown(this.id, Button.PRIMARY);
    }
    if (this.state === 32 && newState !== 32) {
      this.buttonMapper.onButtonUp(this.id, Button.PRIMARY);
    }
    this.state = newState;
  }
}
