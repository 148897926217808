import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Another browser connected.
 */
export class DeviceReconnected extends GroupTalkAPIError {
  public constructor() {
    super("Another browser or browser tab connected.", false);
  }
}
