import { Text } from "src/app/gui/components/Text";
import { UserIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { PresenceType } from "src/lib/types/PresenceType";
import { IconButton } from "@mui/material";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";

export const PresenceIcon = connect<{
  panel: Panel;
  session: Session;
  showDispatcherPresence?: boolean;
}>(({ panel, session, showDispatcherPresence }) => {
  const presenceCount = showDispatcherPresence
    ? session.presence.length
    : session.presence.filter((p) => p.type !== PresenceType.Dispatcher).length;
  return (
    <IconButton
      onClick={() => {
        session.openPresencePanel(panel);
      }}
      style={{
        background: presenceCount > 0 ? "#86b96982" : "#00000010",
        borderRadius: 4,
        padding: 0,
        paddingLeft: 4,
        paddingRight: 4,
      }}
      aria-label="Presence"
    >
      <UserIcon />
      <Text size={16}>{presenceCount}</Text>
    </IconButton>
  );
});
