import { Button } from "src/app/model/input/Button";
import type { ButtonMapper } from "src/app/model/input/ButtonMapper";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";

export class InfinityHandler implements DeviceHandler {
  private state = 0;
  public constructor(
    private readonly buttonMapper: ButtonMapper,
    private readonly id: string
  ) {}
  public onData(data: ArrayBuffer): void {
    const newState = new Uint8Array(data)[0];
    if (!(this.state & (1 << 0)) && newState & (1 << 0)) {
      this.buttonMapper.onButtonDown(this.id, Button.SECONDARY1);
    }
    if (this.state & (1 << 0) && !(newState & (1 << 0))) {
      this.buttonMapper.onButtonUp(this.id, Button.SECONDARY1);
    }
    if (!(this.state & (1 << 1)) && newState & (1 << 1)) {
      this.buttonMapper.onButtonDown(this.id, Button.PRIMARY);
    }
    if (this.state & (1 << 1) && !(newState & (1 << 1))) {
      this.buttonMapper.onButtonUp(this.id, Button.PRIMARY);
    }
    if (!(this.state & (1 << 2)) && newState & (1 << 2)) {
      this.buttonMapper.onButtonDown(this.id, Button.SECONDARY2);
    }
    if (this.state & (1 << 2) && !(newState & (1 << 2))) {
      this.buttonMapper.onButtonUp(this.id, Button.SECONDARY2);
    }
    this.state = newState;
  }
}
