import { IncomingCall } from "src/lib/modules/IncomingCall";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { CancelListener } from "src/lib/types/CancelListener";

const log = Logger.getLogger("ReceiveCallModule");

/**
 * Returned from <code>setupReceiveCallModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class ReceiveCallModule {
  /**
   * Callback handling incoming calls. Respond with a suitable <code>{@link CallStatus}</code>.
   * @member {function(IncomingCall)}
   */
  private readonly onIncomingCall: (incomingCall: IncomingCall) => void;
  private constructor({
    onIncomingCall,
  }: {
    onIncomingCall: (incomingCall: IncomingCall) => void;
  }) {
    this.onIncomingCall = onIncomingCall;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onIncomingCall: (incomingCall: IncomingCall) => void;
    }
  ): Promise<ReceiveCallModule> {
    const response = await requestManager.send({
      receiveCall: { setupRequest: { supportsTwilioFullDuplex: true } },
    });
    log.debug("setupCall module setup.", response);
    return new ReceiveCallModule(options);
  }
  public onRequest(
    message: proto.IReceiveCallAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): CancelListener {
    if (message.receiveCallStart) {
      return this.onReceiveCallStart(message.receiveCallStart, respond);
    }
    log.warn("Unhandled request", message);
    respond(proto.ResponseCode.REQUEST_UNKNOWN);
    return () => {};
  }
  private onReceiveCallStart(
    message: proto.IReceiveCallStart,
    respond: (code: proto.ResponseCode) => void
  ): CancelListener {
    if (this.onIncomingCall) {
      respond(proto.ResponseCode.TRYING);
      const incomingCall = new IncomingCall(message, respond);
      this.onIncomingCall(incomingCall);
      return () => {
        incomingCall.onCancel?.();
      };
    }
    log.warn("No handler on incoming call");
    respond(proto.ResponseCode.NOT_FOUND);
    return () => {};
  }
}
