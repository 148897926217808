import { implicitPx } from "src/util/implicitPx";
import { createElement, forwardRef } from "react";

type LayoutProps = {
  absolute?: true;
  alignBottom?: true;
  alignCenter?: true;
  alignColumnsCenter?: true;
  alignColumnsLeft?: true;
  alignColumnsRight?: true;
  alignColumnsSpaceAround?: true;
  alignColumnsSpaceBetween?: true;
  alignLeft?: true;
  alignMiddle?: true;
  alignRight?: true;
  alignRowsBottom?: true;
  alignRowsMiddle?: true;
  alignRowsSpaceAround?: true;
  alignRowsSpaceBetween?: true;
  alignRowsTop?: true;
  alignSelfBottom?: true;
  alignSelfCenter?: true;
  alignSelfLeft?: true;
  alignSelfMiddle?: true;
  alignSelfRight?: true;
  alignSelfTop?: true;
  alignTop?: true;
  background?: string;
  borderBox?: true;
  bottom?: number | string;
  boxShadow?: true;
  column?: true;
  element?: any;
  fixed?: true;
  gap?: number | string;
  grow?: number | true;
  height?: number | string;
  left?: number | string;
  margin?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  marginTop?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  padding?: number | string;
  paddingBottom?: number | string;
  paddingLeft?: number | string;
  paddingRight?: number | string;
  paddingTop?: number | string;
  relative?: true;
  reverse?: true;
  right?: number | string;
  row?: true;
  spaceAround?: true;
  spaceBetween?: true;
  spaceEvenly?: true;
  stretch?: true;
  style?: React.CSSProperties;
  top?: number | string;
  width?: number | string;
  wrap?: true;
};

const mapPropsToStyles: Record<
  string,
  (props: LayoutProps) => React.CSSProperties
> = {
  absolute: () => ({ position: "absolute" }),
  alignBottom: (props: LayoutProps) => ({
    ...(props.row && { alignItems: "flex-end" }),
    ...(props.column && { justifyContent: "flex-end" }),
  }),
  alignCenter: (props: LayoutProps) => ({
    ...(props.row && { justifyContent: "center" }),
    ...(props.column && { alignItems: "center" }),
  }),
  alignColumnsCenter: (props: LayoutProps) => ({
    ...(props.column && { alignContent: "center" }),
  }),
  alignColumnsLeft: (props: LayoutProps) => ({
    ...(props.column && { alignContent: "flex-start" }),
  }),
  alignColumnsRight: (props: LayoutProps) => ({
    ...(props.column && { alignContent: "flex-end" }),
  }),
  alignColumnsSpaceAround: (props: LayoutProps) => ({
    ...(props.column && { alignContent: "space-around" }),
  }),
  alignColumnsSpaceBetween: (props: LayoutProps) => ({
    ...(props.column && { alignContent: "space-between" }),
  }),
  alignLeft: (props: LayoutProps) => ({
    ...(props.row && { justifyContent: "flex-start" }),
    ...(props.column && { alignItems: "flex-start" }),
  }),
  alignMiddle: (props: LayoutProps) => ({
    ...(props.row && { alignItems: "center" }),
    ...(props.column && { justifyContent: "center" }),
  }),
  alignRight: (props: LayoutProps) => ({
    ...(props.row && { justifyContent: "flex-end" }),
    ...(props.column && { alignItems: "flex-end" }),
  }),
  alignRowsBottom: (props: LayoutProps) => ({
    ...(props.row && { alignContent: "flex-end" }),
  }),
  alignRowsMiddle: (props: LayoutProps) => ({
    ...(props.row && { alignContent: "center" }),
  }),
  alignRowsSpaceAround: (props: LayoutProps) => ({
    ...(props.row && { alignContent: "space-around" }),
  }),
  alignRowsSpaceBetween: (props: LayoutProps) => ({
    ...(props.row && { alignContent: "space-between" }),
  }),
  alignRowsTop: (props: LayoutProps) => ({
    ...(props.row && { alignContent: "flex-start" }),
  }),
  alignSelfBottom: (props: LayoutProps) => ({
    ...(props.column && { alignSelf: "flex-end" }),
  }),
  alignSelfCenter: (props: LayoutProps) => ({
    ...(props.row && { alignSelf: "center" }),
  }),
  alignSelfLeft: (props: LayoutProps) => ({
    ...(props.row && { alignSelf: "flex-start" }),
  }),
  alignSelfMiddle: (props: LayoutProps) => ({
    ...(props.column && { alignSelf: "center" }),
  }),
  alignSelfRight: (props: LayoutProps) => ({
    ...(props.row && { alignSelf: "flex-end" }),
  }),
  alignSelfTop: (props: LayoutProps) => ({
    ...(props.column && { alignSelf: "flex-start" }),
  }),
  alignTop: (props: LayoutProps) => ({
    ...(props.row && { alignItems: "flex-start" }),
    ...(props.column && { justifyContent: "flex-start" }),
  }),
  background: (props: LayoutProps) => ({ background: props.background }),
  borderBox: () => ({ boxSizing: "border-box" }),
  bottom: (props: LayoutProps) => ({ bottom: implicitPx(props.bottom) }),
  boxShadow: () => ({
    boxShadow:
      "0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12)",
  }),
  column: () => ({
    display: "flex",
    flexDirection: "column",
  }),
  element: () => ({}),
  fixed: () => ({
    position: "fixed",
    transform: "translate3d(0, 0, 0)",
  }),
  gap: (props: LayoutProps) => ({ gap: implicitPx(props.gap) }),
  grow: (props: LayoutProps) => ({
    flexGrow: props.grow === true ? "1" : String(props.grow),
  }),
  height: (props: LayoutProps) => ({ height: implicitPx(props.height) }),
  left: (props: LayoutProps) => ({ left: implicitPx(props.left) }),
  margin: (props: LayoutProps) => ({ margin: implicitPx(props.margin) }),
  marginBottom: (props: LayoutProps) => ({
    marginBottom: implicitPx(props.marginBottom),
  }),
  marginLeft: (props: LayoutProps) => ({
    marginLeft: implicitPx(props.marginLeft),
  }),
  marginRight: (props: LayoutProps) => ({
    marginRight: implicitPx(props.marginRight),
  }),
  marginTop: (props: LayoutProps) => ({
    marginTop: implicitPx(props.marginTop),
  }),
  maxHeight: (props: LayoutProps) => ({
    maxHeight: implicitPx(props.maxHeight),
  }),
  maxWidth: (props: LayoutProps) => ({ maxWidth: implicitPx(props.maxWidth) }),
  minHeight: (props: LayoutProps) => ({
    minHeight: implicitPx(props.minHeight),
  }),
  minWidth: (props: LayoutProps) => ({ minWidth: implicitPx(props.minWidth) }),
  padding: (props: LayoutProps) => ({ padding: implicitPx(props.padding) }),
  paddingBottom: (props: LayoutProps) => ({
    paddingBottom: implicitPx(props.paddingBottom),
  }),
  paddingLeft: (props: LayoutProps) => ({
    paddingLeft: implicitPx(props.paddingLeft),
  }),
  paddingRight: (props: LayoutProps) => ({
    paddingRight: implicitPx(props.paddingRight),
  }),
  paddingTop: (props: LayoutProps) => ({
    paddingTop: implicitPx(props.paddingTop),
  }),
  relative: () => ({ position: "relative" }),
  reverse: (props: LayoutProps) => ({
    ...(props.row && { flexDirection: "row-reverse" }),
    ...(props.column && { flexDirection: "column-reverse" }),
  }),
  right: (props: LayoutProps) => ({ right: implicitPx(props.right) }),
  row: () => ({ display: "flex", flexDirection: "row" }),
  spaceAround: (props: LayoutProps) => ({
    ...(props.row || props.column ? { justifyContent: "space-around" } : {}),
  }),
  spaceBetween: (props: LayoutProps) => ({
    ...((props.row || props.column) && props.spaceBetween === true
      ? { justifyContent: "space-between" }
      : {}),
  }),
  spaceEvenly: (props: LayoutProps) => ({
    ...(props.row || props.column ? { justifyContent: "space-evenly" } : {}),
  }),
  stretch: () => ({ alignItems: "stretch" }),
  top: (props: LayoutProps) => ({ top: implicitPx(props.top) }),
  width: (props: LayoutProps) => ({ width: implicitPx(props.width) }),
  wrap: (props: LayoutProps) => ({
    flexWrap: props.reverse ? "wrap-reverse" : "wrap",
  }),
};

export const Layout = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<LayoutProps & React.HTMLAttributes<HTMLDivElement>>
>((props, ref) => {
  const { style, ...otherProps } = props;
  const computedStyle = Object.keys(props).reduce((obj, prop) => {
    const fn = mapPropsToStyles[prop];
    return fn != null && props[prop as keyof LayoutProps] !== undefined
      ? { ...obj, ...fn(props) }
      : obj;
  }, style);

  const passProps = Object.keys(otherProps)
    .filter((key) => mapPropsToStyles[key] === undefined)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: otherProps[key as keyof typeof otherProps],
      }),
      {}
    );

  const { children } = props;
  const element = props.element || "div";
  return createElement(
    element,
    { ref, ...passProps, style: computedStyle },
    children
  );
});
