export const removeKeyRecursivly = (object: any, removeKey: string): any => {
  const newObject = Array.isArray(object) ? [...object] : { ...object };
  Object.entries(newObject).forEach(([key, value]) => {
    if (typeof value === "object") {
      newObject[key] = removeKeyRecursivly(value, removeKey);
    } else if (key === removeKey) {
      delete newObject[key];
    }
  });
  return newObject;
};
