import { gql } from "src/app/graphql";
import { EventType } from "src/app/model/events/types/EventType";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import type { DetailedEvent } from "src/app/model/events/types/DetailedEvent";
import type { EventStatusUpdated } from "src/app/model/events/types/EventStatusUpdated";
import type { EventUserDetailsUpdated } from "src/app/model/events/types/EventUserDetailsUpdated";
import type { PartialEvent } from "src/app/model/events/types/PartialEvent";
import type { State } from "src/app/model/State";
import type { SubscriptionModule } from "src/nextgen/SubscriptionModule";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";

const log = Logger.getLogger("EventManager");

type EventSubscriber = {
  /**
   * @param msg The detailed message
   */
  onDetailedEvent: (msg: DetailedEvent) => void;
  /**
   * @param msg The message
   * @returns true if the subscriber is interested in details
   */
  onEvent: (msg: PartialEvent) => boolean;
  types: EventType[];
};

export class EventManager {
  private readonly subscribers: EventSubscriber[] = [];
  public constructor(private readonly state: State) {}
  public closeDown(): void {
    this.subscribers.length = 0;
    // The subscription websocket will be closed when going offline so we do not need to
    // unsubscribe towards the server.
  }
  public setupGlobalSubscriptions(
    subscriptionModule: SubscriptionModule
  ): void {
    log.debug("Setting up global events subscription.");
    subscriptionModule.subscribe({
      onEvent: action((msg: any) => {
        void (async () => {
          log.debug("Global event: ", msg);
          const needDetails: EventSubscriber[] = [];
          for (const subscriber of this.subscribers) {
            if (subscriber.types.includes(msg.type)) {
              if (
                subscriber.onEvent({
                  payload: { id: msg.payload.userId },
                  type: msg.type,
                })
              )
                needDetails.push(subscriber);
            }
          }
          if (needDetails.length > 0) {
            let detailedEvent: DetailedEvent | undefined;
            if (msg.type === EventType.UserDetailsUpdated) {
              const userDetails = await this.fetchUserDetails(
                msg.payload.userId
              );
              detailedEvent = userDetails
                ? {
                    payload: userDetails,
                    type: EventType.UserDetailsUpdated,
                  }
                : undefined;
            } else if (msg.type === EventType.StatusUpdated) {
              const userStatus = await this.fetchUserStatus(msg.payload.userId);
              detailedEvent = userStatus
                ? {
                    payload: userStatus,
                    type: EventType.StatusUpdated,
                  }
                : undefined;
            } else if (msg.type === EventType.OnlineUpdated) {
              // There are no more data to fetch, so just return the message.
              detailedEvent = {
                payload: {
                  id: msg.payload.userId,
                  online: msg.payload.state === "ONLINE",
                },
                type: EventType.OnlineUpdated,
              };
            } else {
              log.warn(`Unknown event with type: ${msg.type}`);
            }
            if (detailedEvent) {
              for (const subscriber of needDetails) {
                subscriber.onDetailedEvent(detailedEvent);
              }
            }
          }
        })();
      }),
      onSetupSubscription: async (webSocketId) => {
        const data = await this.state.graphqlModule.mutationDataOrThrow({
          mutation: gql(`
            mutation subscribeUserChanges(
              $webSocketId: String!
            ) {
              subscribeUserChanges(input: {
                webSocketId: $webSocketId
              }) {
                subscriptionId
              }
            }
          `),
          variables: {
            webSocketId,
          },
        });
        return data.subscribeUserChanges.subscriptionId;
      },
      onTearDownSubscription: (subscriptionId) => {
        try {
          void this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
            mutation unsubscribeUserChanges(
              $subscriptionId: ID!
            ) {
              unsubscribeUserChanges(input: {
                subscriptionId: $subscriptionId
              }) {
                error {
                  __typename
                  ... on AuthorizationError {
                      message
                  }
                }
              }
            }
          `),
            variables: { subscriptionId },
          });
        } catch (e) {
          log.warn(`Unable to unsubscribe from user changes: ${e}`);
        }
      },
    });
  }
  public subscribe(subscriber: EventSubscriber): () => void {
    log.debug(`Subscribing to ${subscriber.types}`);
    const subscriberCopy = { ...subscriber };
    this.subscribers.push(subscriberCopy);
    return () => {
      log.debug(`Unsubscribing to ${subscriber.types}`);
      const index = this.subscribers.indexOf(subscriberCopy);
      if (index >= 0) {
        this.subscribers.splice(index, 1);
      }
    };
  }
  private async fetchUserDetails(
    id: ClientUserUuid
  ): Promise<EventUserDetailsUpdated | undefined> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserDetails($id: ID!) {
          clientUser(id: $id) {
            id
            displayName
            title
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser ?? undefined;
  }
  private async fetchUserStatus(
    id: ClientUserUuid
  ): Promise<EventStatusUpdated | undefined> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserStatus($id: ID!) {
          clientUser(id: $id) {
            id
            currentStatus {
              id
              name
              backgroundColor
              foregroundColor
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser ?? undefined;
  }
}
