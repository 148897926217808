import { Layout } from "src/app/gui/components/Layout";
import { Server } from "src/app/model/appData/Server";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const ServerSelect = (): JSX.Element => {
  const { list, select, selectedConfig } = Server;
  return (
    <Layout margin={20}>
      <FormControl style={{ maxWidth: 300 }}>
        <InputLabel>Server</InputLabel>
        <Select
          onChange={(event) => {
            select(event.target.value);
          }}
          value={selectedConfig.WEBSOCKET_URL}
        >
          {list.map((server) => (
            <MenuItem key={server.WEBSOCKET_URL} value={server.WEBSOCKET_URL}>
              {server.WEBSOCKET_URL}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Layout>
  );
};
