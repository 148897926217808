import { Constants } from "src/app/model/Constants";
import { EventType } from "src/app/model/events/types/EventType";
import { Queue } from "src/app/model/queues/Queue";
import { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";
import { Ticket } from "src/app/model/queues/Ticket";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import { Logger } from "src/util/Logger";
import { action, reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { EventManager } from "src/app/model/events/EventManager";
import type { DetailedEvent } from "src/app/model/events/types/DetailedEvent";
import type { PartialEvent } from "src/app/model/events/types/PartialEvent";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { Flags } from "src/app/types/Flags";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { Queue as NodeQueue } from "src/lib/modules/Queue";
import type { QueueEntry } from "src/lib/modules/QueueEntry";
import type { Session } from "src/lib/modules/Session";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";

const log = Logger.getLogger("QueueManagement");

const queueTicketSort = (a: Ticket, b: Ticket): number => {
  if (a.isEmergency && !b.isEmergency) {
    return -1;
  }
  if (!a.isEmergency && b.isEmergency) {
    return 1;
  }
  if (a.priority > b.priority) {
    return -1;
  }
  if (a.priority < b.priority) {
    return 1;
  }
  if (a.openedTime < b.openedTime) {
    return -1;
  }
  if (a.openedTime > b.openedTime) {
    return 1;
  }
  return 0;
};

export class QueueManagement {
  public queueList: Record<string, Queue> = {};
  private readonly alertService: QueueAlertService;
  private readonly unsubscribers: (() => Promise<void>)[] = [];
  private disposeFlagsUpdate?: IReactionDisposer;
  private ticketList: Record<string, Ticket> = {};
  private unsubscribeToUpdates?: () => void;
  public constructor(private readonly state: State) {
    this.alertService = new QueueAlertService(state);
    observableClass(this);
  }
  public get userHasEmergencyTicket() {
    return (userUuid: string): boolean =>
      Object.values(this.ticketList).some(
        (ticket) => ticket.userUuid === userUuid && ticket.isEmergency
      );
  }
  public get hasPickedAlarmTickets(): boolean {
    return !!Object.values(this.ticketList).find(
      (ticket) => ticket.pickedByMe && ticket.isEmergency
    );
  }
  public get hasPickedTickets(): boolean {
    return !!Object.values(this.ticketList).find((ticket) => ticket.pickedByMe);
  }
  public get numberOfPickedTickets(): number {
    const tickets: Record<string, number> = {};
    this.ticketPanels
      .filter((panel) => panel.ticketPanelData?.ticket)
      .forEach((panel) => {
        tickets[panel.ticketPanelData!.ticket!.queueEntryEntityId] = 1;
      });
    return Object.keys(tickets).length;
  }
  public get numberOfUnpickedAlarmTickets(): number {
    return this.unpickedAlarmTickets.length;
  }
  public get numberOfUnpickedTickets(): number {
    return this.unpickedTickets.length;
  }
  public get sortedTicketList() {
    return (panel: Panel) => {
      const queuePanelData = panel.queuePanelData!;
      return Object.values(this.ticketList)
        .filter((ticket) => {
          const { queueEntityId } = ticket.queue;
          const { channelId, channelUuid } = ticket;
          if (ticket.closed) return false; // Never show closed tickets

          // Group filter
          if (channelId == null || channelId === "") {
            if (!queuePanelData.includeUnknownOrNoGroup) {
              return false;
            }
          } else {
            const channelIsActive =
              queuePanelData.includeActiveGroups &&
              this.state.online &&
              channelUuid &&
              this.state.online.sessions.hasSessionWithChannelUuid(channelUuid);
            if (!queuePanelData.includeGroup(channelId) && !channelIsActive) {
              return false;
            }
          }
          // Queue filter
          if (
            queuePanelData.unpicked(queueEntityId) &&
            ticket.pickedBy == null
          ) {
            // Time filter
            return ticket.hasPassedDelay(queuePanelData.ticketDelay);
          }
          if (
            queuePanelData.pickedByOther(queueEntityId) &&
            ticket.pickedBy != null &&
            !ticket.pickedByMe
          ) {
            // Time filter
            return ticket.hasPassedDelay(queuePanelData.ticketDelay);
          }
          return false;
        })
        .sort(queueTicketSort);
    };
  }
  public get ticketPanels(): Panel[] {
    return Object.values(this.state.panels.list).filter(
      (panel) =>
        panel.type === "ticket" ||
        panel.type === "priorityTicket" ||
        panel.type === "emergencyTicket"
    );
  }
  public get ticketsPanels(): Panel[] {
    return Object.values(this.state.panels.list).filter(
      (panel) => panel.type === "tickets"
    );
  }
  public get unpickedTickets(): Ticket[] {
    const tickets: Record<string, Ticket> = {};
    this.ticketsPanels.forEach((panel) => {
      this.sortedTicketList(panel).forEach((ticket) => {
        tickets[ticket.queueEntryEntityId] = ticket;
      });
    });
    return Object.values(tickets).sort(queueTicketSort);
  }
  public get useQueues(): boolean {
    return this.ticketsPanels.some((panel) => {
      const queuePanelData = panel.queuePanelData!;
      return Object.values(this.queueList).some(
        (queue) =>
          queuePanelData.unpicked(queue.queueEntityId) ||
          queuePanelData.pickedByOther(queue.queueEntityId)
      );
    });
  }
  public cleanupMonitoringSessions(): void {
    const sessionsInPanels = this.ticketPanels
      .filter(
        (panel) =>
          panel.ticketPanelData!.ticket &&
          panel.ticketPanelData!.ticket.monitoringSession
      )
      .map(
        (panel) => panel.ticketPanelData!.ticket!.monitoringSession!.sessionId
      );
    this.state.online?.sessions.clearMonitoringCallSessionsExceptList(
      sessionsInPanels
    );
  }
  public closeDown(): void {
    this.ticketList = {};
    this.queueList = {};
    this.alertService.stop();
    // We are not actually unsubscribing since we are losing connection.
    this.unsubscribers.length = 0;
    this.disposeFlagsUpdate?.();
    this.disposeFlagsUpdate = undefined;
    this.unsubscribeToUpdates?.();
    this.unsubscribeToUpdates = undefined;
  }
  public closeTicketsWithCallSession(session: Session): void {
    const ticketToClose = Object.values(this.ticketList).find(
      (ticket) =>
        ticket.callSession?.sessionId === session.sessionId &&
        ticket.closeTicketsAndCallSimultaneously &&
        !ticket.closed &&
        ticket.pickedByMe
    );
    if (ticketToClose) {
      ticketToClose.close({ alsoCloseCall: false });
    }
  }
  public pickNextTicket(
    options: { auto?: boolean; onlyPanelId?: ComponentId } = {}
  ): void {
    const { auto = true, onlyPanelId } = options;
    let panels = this.ticketsPanels;
    if (onlyPanelId) {
      panels = panels.filter((panel) => panel.id === onlyPanelId);
    }
    let autopickTicket: Ticket | undefined;

    // First look to auto pick alarm tickets if no such has been picked
    if (!this.hasPickedAlarmTickets) {
      panels.some((panel) => {
        autopickTicket = this.sortedTicketList(panel).find(
          (ticket) =>
            ticket.mayPick &&
            ticket.isEmergency &&
            (!auto ||
              this.state.settings.queues.emergency.autoPickNextTicket.value)
        );
        return autopickTicket != null;
      });
    }

    // Secondly look to pick any high priority ticket if no ticket has been picked
    if (!autopickTicket && !this.hasPickedTickets) {
      panels.some((panel) => {
        autopickTicket = this.sortedTicketList(panel).find(
          (ticket) =>
            ticket.mayPick &&
            ticket.isHighPriority &&
            (!auto || this.state.settings.queues.prio.autoPickNextTicket.value)
        );
        return autopickTicket != null;
      });
    }

    // Thridly look to pick any other ticket if no ticket has been picked
    if (!autopickTicket && !this.hasPickedTickets) {
      panels.some((panel) => {
        autopickTicket = this.sortedTicketList(panel).find(
          (ticket) =>
            ticket.mayPick &&
            ticket.isNormal &&
            (!auto ||
              this.state.settings.queues.normal.autoPickNextTicket.value)
        );
        return autopickTicket != null;
      });
    }

    if (autopickTicket) {
      log.debug(`Autopicking from queue ${autopickTicket.queue.name}`);
      void autopickTicket.pick();
    }
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    const queueManagementModule =
      await authenticatedModule.setupQueueManagementModule();
    const { availableQueues } = queueManagementModule;
    this.alertService.start();
    availableQueues.forEach((queue) => {
      void this.addQueue(queue);
    });
    this.disposeFlagsUpdate = reaction(
      () => this.flags,
      () => {
        this.updateState();
      }
    );
  }
  public startSubscribingToChanges(eventManager: EventManager): void {
    this.unsubscribeToUpdates = eventManager.subscribe({
      onDetailedEvent: action((msg: DetailedEvent) => {
        for (const ticket of Object.values(this.ticketList).filter(
          (t) => t.userUuid?.toUpperCase() === msg.payload.id.toUpperCase()
        )) {
          if (ticket !== undefined) {
            if (msg.type === EventType.OnlineUpdated) {
              ticket.online = msg.payload.online;
            } else if (msg.type === EventType.UserDetailsUpdated) {
              ticket.name = msg.payload.displayName;
              ticket.title = msg.payload.title ?? undefined;
            } else if (msg.type === EventType.StatusUpdated) {
              ticket.status = msg.payload.currentStatus ?? undefined;
            }
          }
        }
      }),
      onEvent: (msg: PartialEvent) => {
        return Object.values(this.ticketList).some(
          (d) => d.userUuid?.toUpperCase() === msg.payload.id?.toUpperCase()
        );
      },
      types: [
        EventType.UserDetailsUpdated,
        EventType.OnlineUpdated,
        EventType.StatusUpdated,
      ],
    });
  }
  public updateTicketPanels(): void {
    const pickedTicketsId = this.pickedTickets.map(
      (ticket) => ticket.queueEntryEntityId
    );
    const panelsTicketsId = this.ticketPanels.map(
      (panel) => panel.ticketPanelData!.queueEntryEntityId
    );

    // Close deprecated panel type
    this.pickedTicketsPanels.forEach((panel) => {
      this.state.layout.closePanel(panel.id);
    });
    this.ticketPanels.forEach((panel) => {
      if (
        panel.ticketPanelData!.queueEntryEntityId === null ||
        pickedTicketsId.indexOf(panel.ticketPanelData!.queueEntryEntityId) ===
          -1
      ) {
        this.state.layout.closePanel(panel.id);
      } else {
        void panel.ticketPanelData!.setTicket(
          this.pickedTickets.find(
            (ticket) =>
              ticket.queueEntryEntityId ===
              panel.ticketPanelData!.queueEntryEntityId
          )!
        );
      }
    });
    this.pickedTickets
      .filter(
        (ticket) => panelsTicketsId.indexOf(ticket.queueEntryEntityId) === -1
      )
      .forEach((ticket) => {
        // Open new ticket panel
        let templateId = TemplateId.ticket;
        if (ticket.isEmergency) {
          templateId = TemplateId.emergencyTicket;
        } else if (ticket.isHighPriority) {
          templateId = TemplateId.priorityTicket;
        }
        const panel = this.state.panels.add({
          customData: {
            queueEntryEntityId: ticket.queueEntryEntityId,
          },
          name: ticket.name,
          panelId:
            localStorage.getItem(Constants.LAST_CLOSED_TICKET_TAB) || undefined,
          templateId,
        });
        void panel.ticketPanelData!.setTicket(ticket);
      });
    this.cleanupMonitoringSessions();
  }
  private get flags(): Flags {
    const flags = {
      newUnpickedEmergencyEntry: false,
      newUnpickedPrioEntry: false,
      newUnpickedQueueEntry: false,
      pickedEmergencyEntry: false,
      pickedPrioEntry: false,
      pickedQueueEntry: false,
      unpickedEmergencyEntry: false,
      unpickedPrioEntry: false,
      unpickedQueueEntry: false,
    };
    this.ticketsPanels.forEach((panel) => {
      // Process tickets not picked by me
      this.sortedTicketList(panel).forEach((ticket) => {
        if (ticket.pickedBy == null && ticket.isNormal) {
          flags.unpickedQueueEntry = true;
          if (ticket.isNew) {
            flags.newUnpickedQueueEntry = true;
          }
        }
        if (ticket.pickedBy == null && ticket.isHighPriority) {
          flags.unpickedPrioEntry = true;
          if (ticket.isNew) {
            flags.newUnpickedPrioEntry = true;
          }
        }
        if (ticket.pickedBy == null && ticket.isEmergency) {
          flags.unpickedEmergencyEntry = true;
          if (ticket.isNew) {
            flags.newUnpickedEmergencyEntry = true;
          }
        }
      });
    });
    this.pickedTickets.forEach((ticket) => {
      if (ticket.isNormal) {
        flags.pickedQueueEntry = true;
      }
      if (ticket.isHighPriority) {
        flags.pickedPrioEntry = true;
      }
      if (ticket.isEmergency) {
        flags.pickedEmergencyEntry = true;
      }
    });
    return flags;
  }
  private get pickedTickets(): Ticket[] {
    return Object.values(this.ticketList).filter((ticket) => ticket.pickedByMe);
  }
  private get pickedTicketsPanels(): Panel[] {
    return Object.values(this.state.panels.list).filter(
      (panel) => panel.templateId === TemplateId.pickedtickets
    );
  }
  private get unpickedAlarmTickets(): Ticket[] {
    return this.unpickedTickets.filter((ticket) => ticket.isEmergency);
  }
  private async addQueue(queue: NodeQueue): Promise<void> {
    this.queueList[queue.queueEntityId] = new Queue(queue);
    this.alertService.addSubscription();
    const unsubscriber = await queue.subscribe((queueEntries) => {
      this.handleQueueEntries(queueEntries);
      this.alertService.subscriptionFinish();
    });
    this.unsubscribers.push(unsubscriber);
  }
  private async fetchClientUsers(userList: ClientUserUuid[]): Promise<void> {
    log.debug(`Fetching ${userList.length} client contacts for tickets`);
    const users = await this.state.contactManagement.fetchClientUsers(userList);
    for (const user of users) {
      for (const ticket of Object.values(this.ticketList).filter(
        (t) => t.userUuid === user.userUuid
      )) {
        ticket.name = user.name;
        ticket.title = user.title;
        ticket.status = user.status;
        ticket.online = user.online;
      }
    }
  }
  private handleQueueEntries(queueEntries: QueueEntry[]): void {
    // NOTE: an entry can be included among the queueEntries even if nothing changed on
    // that particular entry. We need to be robust for multiple calls with the same entry.
    const newUserUuids: ClientUserUuid[] = [];
    queueEntries.forEach((queueEntry) => {
      if (queueEntry.closed !== undefined) {
        delete this.ticketList[queueEntry.queueEntryEntityId];
      } else {
        const queue = this.queueList[queueEntry.queue.queueEntityId];
        const oldTicket = this.ticketList[queueEntry.queueEntryEntityId];
        const newTicket = new Ticket(this.state, queueEntry, queue, oldTicket);
        this.ticketList[queueEntry.queueEntryEntityId] = newTicket;
        if (
          !oldTicket &&
          newTicket.userUuid &&
          !newUserUuids.includes(newTicket.userUuid)
        ) {
          newUserUuids.push(newTicket.userUuid);
        }
        // Only trigger actions when the queue entry update is due to it being picked.
        // We check that we have actually seen the entry before, to avoid triggering
        // call from a disconnect/reconnect or reload.
        if (oldTicket && !oldTicket.pickedByMe && newTicket.pickedByMe) {
          if (
            newTicket.joinGroupWhenPicking &&
            newTicket.mayJoinGroup &&
            !newTicket.session
          ) {
            newTicket.joinGroup();
            newTicket.leaveGroupWhenClosing = true;
          }
          if (newTicket.startPrivateCallWhenPicking && newTicket.callable) {
            newTicket.autoAnswerCall();
          }
          if (newTicket.startMonitoringWhenPicking && newTicket.monitorable) {
            newTicket.monitor();
          }
        }
      }
    });
    if (newUserUuids.length > 0) {
      void this.fetchClientUsers(newUserUuids);
    }
    // Possibly autopick this ticket
    if (this.state.online) {
      this.pickNextTicket();
      this.updateTicketPanels();
    }
  }
  private updateState(): void {
    if (this.state.online) {
      this.alertService.update(this.flags);
      // Reset isNew boolean flag for all tickets
      this.ticketsPanels.forEach((panel) => {
        this.sortedTicketList(panel).forEach((queueEntry) => {
          queueEntry.setIsNew(false);
        });
      });
    }
  }
}
