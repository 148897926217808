import { Logger } from "src/util/Logger";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";
import type { AudioData } from "src/lib/types/AudioData";

const log = Logger.getLogger("AudioModule");
/**
 * Returned from <code>setupAudioModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @private
 * @namespace
 */
export class AudioModule {
  /**
   * Callback on incoming audio.
   * @member {function(IAudioData)}
   */
  private readonly onAudio?: (audio: proto.IAudioData) => void;
  private constructor(
    private readonly requestManager: RequestManager,
    options: { onAudio?: (audio: proto.IAudioData) => void } = {}
  ) {
    this.requestManager = requestManager;

    this.onAudio = options.onAudio;
  }
  public static async setup(
    requestManager: RequestManager,
    options: { onAudio?: (audio: proto.IAudioData) => void }
  ): Promise<AudioModule> {
    const response = await requestManager.send({ audio: { setupRequest: {} } });
    log.debug("audio module setup.", response);
    return new AudioModule(requestManager, options);
  }
  public onRequest(message: proto.IAudioAPIv1Server): void {
    if (message.data) {
      if (this.onAudio) {
        this.onAudio(message.data);
      }
    } else {
      log.warn("Unhandled request", message);
    }
  }
  /**
   * Send audio data.
   * @param {AudioData} audioData
   */
  public sendAudio(audioData: AudioData): void {
    void this.requestManager.send({ audio: { data: audioData } });
  }
}
