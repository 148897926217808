import { Reconnector } from "src/lib/Reconnector";
import { RequestManager } from "src/lib/RequestManager";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";
import type { ConnectedModule } from "src/lib/modules/ConnectedModule";
/**
 * Main entry point of the API
 * @namespace
 */
export class GroupTalkAPI {
  /**
   * Connect to GroupTalk once and returns a <code>{@link ConnectedModule}</code> once connected for
   * further actions. On <code>onDisconnect</code>, the ConnectedModule and all of its descendant
   * resources may no longer be used.
   * @param {string} options.server The server to connect to
   * @param {Number} [options.timeout=5000] Connection timeout in milliseconds
   * @param {function(GroupTalkAPIError)} options.onDisconnect Callback on disconnect with the
   * disconnect reason.
   * @returns {Promise<ConnectedModule>} Module that can be used when connected
   */
  public static connect(options: {
    onDisconnect: (error: GroupTalkAPIError) => void;
    server: string;
    timeout?: number;
  }): Promise<ConnectedModule> {
    const connectedModule = new RequestManager(options).start();
    return connectedModule;
  }
  /**
   * Connects to GroupTalk and reconnect on temporary errors. For every connect, the
   * <code>onConnect</code> callback will trigger with a new <code>{@link ConnectedModule}</code>.
   * For every <code>onDisconnect</code>, the old ConnectedModule and all of its descendant
   * resources may no longer be used.
   *
   * @param {string} options.server The server to connect to.
   * @param {function(ConnectedModule)} options.onConnect Callback on connect with access to
   * ConnectedModule.
   * @param {function(GroupTalkAPIError)} options.onDisconnect Callback on disconnect with
   * disconnect reason.
   * @returns {function} Call function to abort/stop connection and dispose of all resources.
   */
  public static keepConnected(options: {
    onConnect: (connectedModule: ConnectedModule) => void;
    onDisconnect: (groupTalkAPIError: GroupTalkAPIError) => void;
    server: string;
    timeout?: number;
  }): Promise<() => void> {
    return new Reconnector(options).start();
  }
}
