import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ReplayButton } from "src/app/gui/panels/channels/ReplayButton";
import { ContactMenu } from "src/app/gui/panels/contacts/ContactMenu";
import { PresenceIcon } from "src/app/gui/shared/PresenceIcon";
import {
  GroupIcon,
  MonitorIcon,
  PrivateCallIcon,
  TimeIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";
import { SessionType } from "src/lib/types/SessionType";
import type { Session } from "src/app/model/sessions/Session";
import type { PresenceType } from "src/lib/types/PresenceType";

const sessionIcon = (
  iconStyle: React.CSSProperties
): Record<SessionType, JSX.Element> => ({
  [SessionType.AutoJoin]: <GroupIcon style={iconStyle} />,
  [SessionType.Call]: <PrivateCallIcon style={iconStyle} />,
  [SessionType.Group]: <GroupIcon style={iconStyle} />,
  [SessionType.MonitoringListener]: <MonitorIcon style={iconStyle} />,
  [SessionType.MonitoringSender]: <MonitorIcon style={iconStyle} />,
  [SessionType.Scan]: <MonitorIcon style={iconStyle} />,
});
const transistionStyle = { transition: "all .4s" };

const Box = connect<{
  hasMenu?: boolean;
  isSpeaking: boolean;
  replaying: boolean;
  sender?: string;
  session: Session;
  type?: PresenceType;
}>(({ hasMenu, isSpeaking, replaying, sender, session, type }) => {
  const nameColor = isSpeaking ? "#fff" : "#eee";
  const { name } = session;
  const iconStyle = {
    ...transistionStyle,
    fill: isSpeaking ? "#fff" : "#eee",
    height: 12,
    width: 12,
  };
  const textStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...transistionStyle,
  };
  return (
    <Layout
      style={{
        ...transistionStyle,
        borderRadius: isSpeaking ? "6px" : "0px",
        cursor: hasMenu ? "pointer" : "not-allowed",
        textAlign: "left",
      }}
      alignMiddle
      background={isSpeaking ? "#33aa33" : undefined}
      column
      margin={6}
      padding={4}
    >
      <Layout alignMiddle gap={5} row>
        {sessionIcon(iconStyle)[session.type]}
        <Text color={nameColor} nowrap size={10} style={textStyle}>
          {replaying ? "REPLAY" : name}
        </Text>
      </Layout>
      <Layout alignMiddle gap={5} marginTop={3} row>
        <PresenceIcon presenceType={type} style={iconStyle} />
        <Text color={nameColor} nowrap size={10} style={textStyle}>
          {sender}
        </Text>
      </Layout>
      <Layout alignMiddle gap={5} marginTop={3} row>
        <TimeIcon style={iconStyle} />
        <Text color={nameColor} nowrap size={10} style={textStyle}>
          {session.timeSinceLastActive}
        </Text>
      </Layout>
    </Layout>
  );
});

export const LatestSpeaker = connect<{
  session: Session;
}>(({ session }) => {
  const { lastIncomingTalkburst, latestSpeakerPresence, replaying } = session;
  const sender = lastIncomingTalkburst?.sender;
  const type = lastIncomingTalkburst?.type;
  const isSpeaking = latestSpeakerPresence && latestSpeakerPresence.isSpeaking;
  if (latestSpeakerPresence) {
    const { contactDetails, fetchContactDetails } = latestSpeakerPresence;
    return (
      <Layout alignMiddle row>
        <ContactMenu
          contactDetails={contactDetails}
          fetchContactDetails={fetchContactDetails}
          menuId={`${latestSpeakerPresence.presenceId}`}
        >
          <Box
            hasMenu
            isSpeaking={isSpeaking || replaying}
            replaying={replaying}
            sender={sender}
            session={session}
            type={type}
          />
        </ContactMenu>
        <ReplayButton session={session} />
      </Layout>
    );
  }
  return (
    <Layout alignMiddle row>
      <Box
        isSpeaking={replaying}
        replaying={replaying}
        sender={sender}
        session={session}
        type={type}
      />
      <ReplayButton session={session} />
    </Layout>
  );
});
