export const implicitPx = (
  value: number | string | undefined
): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  if (Number.isNaN(Number(value))) {
    return String(value);
  }
  return `${Number(value)}px`;
};
