import { Logger } from "src/util/Logger";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";
import type { ChannelAccessType } from "src/lib/types/ChannelAccessType";
import type { ChannelMediaType } from "src/lib/types/ChannelMediaType";

const log = Logger.getLogger("ChannelModule");

/**
 * Returned from <code>{@link ChannelModule}.subscribe</code> and in
 * <code>{@link ChannelDelta}</code> after subscribing.
 * @namespace
 */
export class Channel {
  /**
   * <code>true</code> if autojoined; otherwise, <code>false</code>.
   * @member {boolean}
   */
  public autojoined: boolean;
  /**
   * @member {string}
   */
  public channelId: string;
  /**
   * @member {string}
   */
  public channelUuid?: string;
  /**
   * @member {string}
   */
  public description: string;
  /**
   * Info about how the channel can be joined.
   * @member {ChannelAccessType}
   */
  public joinAccessType?: ChannelAccessType;
  /**
   * <code>true</code> if joined; otherwise, <code>false</code>.
   * @member {boolean}
   */
  public joined: boolean;
  /**
   * Which kind of media type this channel is.
   * @member {ChannelMediaType}
   */
  public mediaType?: ChannelMediaType;
  /**
   * @member {string}
   */
  public name: string;
  /**
   * The owner of the channel; only non-<code>null</code> if this channel is shared by some other
   * organization.
   * @member {string}
   */
  public owner?: string;
  /**
   * The quick select number for the channel.
   * @member {Number}
   */
  public quickSelectNumber?: number;
  /**
   * Info about how the channel can be scanned.
   * @member {ChannelAccessType}
   */
  public scanAccessType?: ChannelAccessType;
  /**
   * <code>true</code> if scanned; otherwise, <code>false</code>.
   * @member {boolean}
   */
  public scanned: boolean;
  public constructor(
    private readonly requestManager: RequestManager,
    channelInfo: proto.IChannelInfo
  ) {
    this.requestManager = requestManager;
    this.channelId = channelInfo.channelId;
    this.channelUuid = channelInfo.channelUuid ?? undefined;
    this.name = channelInfo.name;
    this.description = channelInfo.desc;
    this.owner = channelInfo.owner ?? undefined;
    this.quickSelectNumber = channelInfo.quickSelectNumber ?? undefined;
    this.joined = channelInfo.joined;
    this.scanned = channelInfo.scanned;
    this.autojoined = channelInfo.autojoined;
    this.joinAccessType =
      channelInfo.joinAccessType as number as ChannelAccessType;
    this.scanAccessType =
      channelInfo.scanAccessType as number as ChannelAccessType;
    this.mediaType = channelInfo.mediaType as number as ChannelMediaType;
  }
  /**
   * @param {boolean} autoJoined <code>true</code> if this channel should be auto-joined;
   * <code>false</code> if this channel should not be auto-joined.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateAutoJoined(autoJoined: boolean): Promise<void> {
    if (autoJoined) {
      await this.requestManager.send({
        channel: { startAutojoinRequest: { channelId: this.channelId } },
      });
    } else {
      await this.requestManager.send({
        channel: { stopAutojoinRequest: { channelId: this.channelId } },
      });
    }
  }
  /**
   * @param {boolean} joined <code>true</code> if this channel should be joined;
   * <code>false</code> if this channel should be left.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateJoined(joined: boolean): Promise<void> {
    if (joined) {
      await this.requestManager.send({
        channel: { joinRequest: { channelId: this.channelId } },
      });
      log.debug(`Channel ${this.name} joined.`);
    } else {
      await this.requestManager.send({
        channel: { leaveRequest: { channelId: this.channelId } },
      });
      log.debug(`Left channel ${this.name}.`);
    }
  }
  /**
   * @param {boolean} scanned <code>true</code> if this channel should be scanned;
   * <code>false</code> if this channel should not be scanned.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateScanned(scanned: boolean): Promise<void> {
    if (scanned) {
      await this.requestManager.send({
        channel: { startScanRequest: { channelId: this.channelId } },
      });
    } else {
      await this.requestManager.send({
        channel: { stopScanRequest: { channelId: this.channelId } },
      });
    }
  }
}
