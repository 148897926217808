import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { GroupIcon } from "src/app/images";
import { connect } from "src/app/state/connect";

export const GroupFilterRow = connect<{
  checked: boolean;
  description?: string;
  disabled?: boolean;
  evenRow?: boolean;
  name: string;
  onClick?: () => void;
  type?: "joinable_group" | "joined_group";
}>(
  ({
    checked,
    description,
    disabled = false,
    evenRow,
    name,
    onClick,
    type,
  }) => {
    const bg = evenRow ? "#7d91aa" : "#7789a2";
    return (
      <Layout
        onClick={() => {
          if (!disabled) {
            onClick?.();
          }
        }}
        alignMiddle
        background={bg}
        margin={0}
        padding={2}
        row
        style={{ cursor: disabled ? "auto" : "pointer" }}
      >
        {type === "joined_group" && (
          <GroupIcon style={{ fill: "#143e1b", marginRight: 10 }} />
        )}
        {type === "joinable_group" && (
          <GroupIcon style={{ fill: "#00000045", marginRight: 10 }} />
        )}
        <Layout alignMiddle column height={30}>
          <Text
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color="#000"
            nowrap
            size={14}
          >
            {name}
          </Text>
          {description && (
            <Text
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              color="#474747"
              nowrap
              size={10}
            >
              {description}
            </Text>
          )}
        </Layout>
        <Layout grow />
        <GtCheckbox checked={checked} disabled={disabled} />
      </Layout>
    );
  }
);
