export enum SessionMediaType {
  /** Session is a PTT session. */
  GroupTalkPtt = 0,
  /** Reserved. */
  Reserved1 = 2,
  /** Reserved. */
  Reserved2 = 3,
  /** Reserved. */
  Reserved3 = 4,
  /** Reserved. */
  Reserved4 = 5,
  /** Session is a Twilio Full Duplex session. */
  TwilioFullDuplex = 1,
}
