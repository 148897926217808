import { RepeatIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import type { Session } from "src/app/model/sessions/Session";

export const ReplayButton = connect<{
  session: Session;
}>(({ session }) => (
  <IconButton
    aria-label="Replay"
    disabled={!session.mayReplayTalkburst}
    onClick={() => session.replayTalkburst()}
    style={{ padding: 4 }}
  >
    <RepeatIcon
      style={{
        background: "#65655f2e",
        borderRadius: 5,
        opacity: session.mayReplayTalkburst ? 1.0 : 0.4,
      }}
    />
  </IconButton>
));
