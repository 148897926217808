import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { DynamicListSetting } from "src/app/gui/settings/DynamicListSetting";
import { ListSetting } from "src/app/gui/settings/ListSetting";
import { NumberSetting } from "src/app/gui/settings/NumberSetting";
import { SoundService } from "src/app/model/audio/SoundService";
import { connect } from "src/app/state/connect";
import { action } from "mobx";
import type { QueueSettings as QueueSettingsModel } from "src/app/model/settings/QueueSettings";

export const QueueSettings = connect((_, { settings }) => {
  const { emergency, normal, prio, showTicketMenu } = settings.queues;
  const playSound = (setting: QueueSettingsModel): void => {
    void SoundService.playSound(
      setting.alertSound.selected.id as string,
      setting.alertDevice.selected?.id as string,
      setting.alertVolume.value
    );
  };
  return (
    <Layout column>
      <Layout marginBottom={50}>
        <Text bold size={15}>
          All tickets
        </Text>
        <Layout column marginTop={10}>
          <BooleanSetting
            label="Show ticket status in top bar"
            setting={showTicketMenu}
          />
        </Layout>
      </Layout>
      <Layout column gap={50}>
        {[normal, prio, emergency].map((setting) => (
          <Layout column key={setting.id} width={500}>
            <Layout marginBottom={10}>
              {setting === normal && (
                <Text bold size={15}>
                  Normal tickets
                </Text>
              )}
              {setting === prio && (
                <Text bold size={15}>
                  High priority tickets
                </Text>
              )}
              {setting === emergency && (
                <Text bold size={15}>
                  Emergency tickets
                </Text>
              )}
            </Layout>
            <ListSetting
              id={`play_${setting.id}`}
              label="Play alerts:"
              none={false}
              setting={setting.playSound}
            />
            {setting.playSound.selected.id !== "never" && (
              <ListSetting
                id={`alert_${setting.id}`}
                label="Alert sound:"
                none={false}
                onSelect={action(() => playSound(setting))}
                setting={setting.alertSound}
              />
            )}
            {setting.playSound.selected.id !== "never" && (
              <DynamicListSetting
                id={`device_${setting.id}`}
                label="Audio device:"
                onSelect={action(() => playSound(setting))}
                setting={setting.alertDevice}
              />
            )}
            {setting.playSound.selected.id !== "never" && (
              <NumberSetting
                label="Audio volume:"
                onUpdated={action(() => playSound(setting))}
                setting={setting.alertVolume}
                slider={true}
              />
            )}
            {setting.playSound.selected.id !== "never" && (
              <NumberSetting
                label="Reminder interval (seconds)"
                setting={setting.reminderIntervalInSeconds}
              />
            )}
            <BooleanSetting
              label="Automatically pick next ticket"
              setting={setting.autoPickNextTicket}
            />
            {setting.id === "emergency" && (
              <BooleanSetting
                label="Start monitoring when picking tickets"
                setting={setting.startMonitoringWhenPicking!}
              />
            )}
            <BooleanSetting
              label="Start private call when picking tickets"
              setting={setting.startPrivateCallWhenPicking}
            />
            <BooleanSetting
              label="Join group when picking tickets"
              setting={setting.joinGroupWhenPicking}
            />
            <BooleanSetting
              label="Close ticket and call simultaneously"
              setting={setting.closeTicketsAndCallSimultaneously}
            />
          </Layout>
        ))}
      </Layout>
      <Layout marginBottom={200} marginTop={10}>
        <Text color="#080808" weight={300}>
          One is considered &quot;available&quot; for a ticket if one does not
          have a ticket with the same or higher priority.
        </Text>
      </Layout>
    </Layout>
  );
});
