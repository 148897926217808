import { IlbcDecoder } from "src/audio/codec/IlbcDecoder";
import { OpusDecoder } from "src/audio/codec/OpusDecoder";
import { JitterBuffer } from "src/audio/JitterBuffer";
import { UpgradeNeeded } from "src/lib/errors/UpgradeNeeded";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { Codec } from "src/lib/types/Codec";
import type { Decoder } from "src/audio/codec/Decoder";
import type { Session } from "src/lib/modules/Session";
import type { proto } from "src/lib/protobuf/proto";
import type { GlobalTalkburstId } from "src/lib/types/GlobalTalkburstId";
import type { PresenceType } from "src/lib/types/PresenceType";
import type { ReceptionStatistics } from "src/lib/types/ReceptionStatistics";

/**
 * Obtained from <code>{@link TalkburstModule}.onTalkburst</code> callback.
 * @namespace
 */
export class IncomingTalkburst {
  public audioSuppressed?: boolean;
  public globalTalkburstId?: GlobalTalkburstId;
  public jitterBuffer?: JitterBuffer;
  public muteGroupId?: string;
  public sender?: string;
  public session?: Session;
  public sessionId?: number;
  public sourceId?: string;
  public talkburstId?: number;
  public type?: PresenceType;
  private decoder?: Decoder;
  /**
   * @returns {Float32Array} Get 20ms of audio (320 frames) in 16kHz 16 bit mono signed PCM format.
   * Will return <code>null</code> if no more audio is available.
   */
  public getAudio(): Float32Array | null {
    if (this.jitterBuffer && this.session) {
      return this.jitterBuffer.poll();
    }
    // Still waiting for first packet. Play empty sound.
    return JitterBuffer.EMPTY_PACKET;
  }
  public onAudio({
    audio,
    codec,
    seqNo,
  }: {
    audio: Uint8Array;
    codec: Codec;
    seqNo: number;
  }): void {
    if (!this.decoder) {
      if (codec === Codec.iLBC) {
        this.decoder = new IlbcDecoder();
      } else if (codec === Codec.Opus) {
        this.decoder = new OpusDecoder();
      } else {
        throw new UpgradeNeeded();
      }
    }
    if (!this.jitterBuffer) {
      this.jitterBuffer = new JitterBuffer();
    }
    this.jitterBuffer.put({ audio: audio.slice(0), seqNo }, this.decoder);
  }
  public setLastSentSeqNo(
    lastSentSeqNo: number,
    onReceptionStatistics: (receptionStats: ReceptionStatistics) => void
  ): void {
    if (!this.jitterBuffer) {
      this.jitterBuffer = new JitterBuffer();
    }
    this.jitterBuffer.setLastSeqNumber(lastSentSeqNo, (statistics) => {
      if (this.decoder) {
        this.decoder.release();
      }
      if (onReceptionStatistics) {
        onReceptionStatistics(statistics);
      }
    });
  }
  public setParameters(
    talkburstReceptionStart: proto.ITalkburstReceptionStarted,
    session: Session
  ): void {
    /**
     * Unique id of the talkburst
     * @member {string}
     */
    this.talkburstId = longToNumber(talkburstReceptionStart.talkburstId);
    this.session = session;
    /**
     * Session in which this talkburst reception takes place.
     * @member {Number}
     */
    this.sessionId = longToNumber(talkburstReceptionStart.sessionId);
    /**
     * Name/info about sender.
     * @member {string}
     */
    this.sender = talkburstReceptionStart.sender;
    /**
     * Kind of sender.
     * @member {PresenceType}
     */
    this.type = (talkburstReceptionStart.type ??
      talkburstReceptionStart.limitedType) as number as PresenceType;
    /**
     * Optional string that uniquely identifies the device/source of the talkburst.
     * Can be matched with a presence.
     * @member {string}
     */
    this.sourceId = talkburstReceptionStart.sourceId ?? undefined;
    /**
     * Optional id of mute group from which this talkburst originate from.
     * @member {string}
     */
    this.muteGroupId = talkburstReceptionStart.muteGroupId ?? undefined;
    /**
     * Global id that is same for all receptions of a proxied/patched talkburst.
     * @member {string}
     */
    this.globalTalkburstId =
      talkburstReceptionStart.globalTalkburstId ?? undefined;
    /**
     * No audio will be sent for this instance of the talkburst.
     * @member {boolean}
     */
    this.audioSuppressed = talkburstReceptionStart.audioSuppressed ?? undefined;
  }
}
