import { AnotherDeviceConnected } from "src/lib/errors/AnotherDeviceConnected";
import { ConnectionClosedLocally } from "src/lib/errors/ConnectionClosedLocally";
import { DeviceReconnected } from "src/lib/errors/DeviceReconnected";
import { TerminationByRequest } from "src/lib/errors/TerminationByRequest";
import { UsageLicenseExpired } from "src/lib/errors/UsageLicenseExpired";
import { CapabilitiesModule } from "src/lib/modules/CapabilitiesModule";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";
import type { RequestManager } from "src/lib/RequestManager";
import type { CancelListener } from "src/lib/types/CancelListener";
import type { IServerResponse } from "src/lib/types/IServerResponse";

const log = Logger.getLogger("ConnectedModule");

/**
 * Aquired from <code>{@link GroupTalkAPI}</code> when connected.
 * @namespace
 */
export class ConnectedModule {
  public capabilitiesModule?: CapabilitiesModule;
  public constructor(private readonly requestManager: RequestManager) {}
  /**
   * Close the connection. This will not send a GoOffline request to server.
   * This will resolve to a <code>{@link ConnectionClosedLocally}</code> unless an error is
   * supplied.
   * @param {Error} error
   */
  public disconnect(error: GroupTalkAPIError): void {
    this.requestManager.stop(error || new ConnectionClosedLocally());
  }
  public onDisconnect(): void {
    if (this.capabilitiesModule) {
      this.capabilitiesModule.onDisconnect();
    }
  }
  public onRequest(
    message: proto.IServerMessage,
    respond: (code: proto.ResponseCode, response?: IServerResponse) => void
  ): CancelListener | undefined {
    if (message.terminateRequest) {
      this.onTerminate(message.terminateRequest, respond);
    } else if (this.capabilitiesModule) {
      return this.capabilitiesModule.onRequest(message, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
    return undefined;
  }
  /**
   * Query the server for its capabilities.
   * @param {string} options.userAgent User agent.
   * @param {string} [options.deviceId='web'] The id of the device, up to 32 characters.
   * @returns {Promise} Promise of <code>{@link CapabilitiesModule}</code> to be used for
   * further actions.
   */
  public async requestCapabilities({
    deviceId,
    userAgent,
  }: {
    deviceId?: string;
    userAgent: string;
  }): Promise<CapabilitiesModule> {
    this.capabilitiesModule = await CapabilitiesModule.setup(
      this.requestManager,
      {
        deviceId: deviceId ?? "web",
        userAgent,
      }
    );
    return this.capabilitiesModule;
  }
  private onTerminate(
    message: proto.ITerminateConnection,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    let ReportError;
    const { deprecatedReason, reason } = message;
    const {
      ANOTHER_DEVICE_CONNECTED,
      DEVICE_RECONNECTED,
      USAGE_LICENSE_EXPIRED,
    } = proto.TerminateConnection.TerminateConnectionReason;
    const { DEVICE_RECONNECTED_DEPRECATED } =
      proto.TerminateConnection.TerminateConnectionReasonDeprecated;
    if (reason) {
      switch (reason) {
        case DEVICE_RECONNECTED:
          ReportError = DeviceReconnected;
          break;
        case USAGE_LICENSE_EXPIRED:
          ReportError = UsageLicenseExpired;
          break;
        case ANOTHER_DEVICE_CONNECTED:
          ReportError = AnotherDeviceConnected;
          break;
        default:
          ReportError = TerminationByRequest;
          break;
      }
    } else if (deprecatedReason === DEVICE_RECONNECTED_DEPRECATED) {
      ReportError = DeviceReconnected;
    } else {
      ReportError = TerminationByRequest;
    }
    this.requestManager.stop(new ReportError());
  }
}
