import { gql } from "src/app/graphql";
import { ClientOnlineState } from "src/app/graphql/graphql";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { PageInfo } from "src/app/types/PageInfo";
import type { Status } from "src/lib/types/Status";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";

const CONTACTS_PER_FETCH = 100;

type UserInfo = {
  name: string;
  online: boolean;
  status?: Status;
  title?: string;
  userUuid: ClientUserUuid;
};

export class ContactManagement {
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public static callPhoneNumber(number: string): void {
    window.location.href = `tel:${number}`;
  }
  public static sendEmail(email: string): void {
    window.location.href = `mailto:${email}`;
  }
  public deselectCallTabInContactPanelsWithoutCall(): void {
    this.state.panels.forEach(
      (p) =>
        p.isContactPanel &&
        p.contactPanelData!.contactTab === ContactTabSetting.Call &&
        p.contactPanelData!.session === undefined,
      (p) => {
        p.contactPanelData!.setContactTab(ContactTabSetting.Actions);
      }
    );
  }
  public async fetchClientUsers(
    userList: ClientUserUuid[]
  ): Promise<UserInfo[]> {
    const userInfo: UserInfo[] = [];
    // as the number of contacts returned per request is anyway paged with a size of CONTACTS_PER_FETCH contacts
    // per call, we can as well chunk the userList array into same sized chunks. This means we won't
    // send more than CONTACTS_PER_FETCH ids per request.
    const chunks = ContactManagement.chunk(userList, CONTACTS_PER_FETCH);

    for (let i = 0, len = chunks.length; i < len; i += 1) {
      let offset = 0;
      while (true) {
        const { items, pageInfo } = await this.queryClientContacts(
          chunks[i]!,
          offset
        );
        for (const clientUser of items) {
          userInfo.push({
            name: clientUser.displayName,
            online: clientUser.onlineStatus.state === ClientOnlineState.Online,
            status: clientUser.currentStatus ?? undefined,
            title: clientUser.title ?? undefined,
            userUuid: clientUser.id,
          });
        }
        offset += pageInfo.limit;
        if (offset >= pageInfo.totalItems) {
          break;
        }
      }
    }
    return userInfo;
  }
  public openOrFocusPanel(options: {
    name: string;
    panel?: Panel;
    tab?: ContactTabSetting;
    userUuid: ClientUserUuid;
  }): void {
    const { name, panel, tab, userUuid } = options;
    const existingTicketPanel = this.state.panels.find(
      (p) => p.isTicketPanel && p.ticketPanelData?.ticket?.userUuid === userUuid
    );
    const existingContactPanel = this.state.panels.find(
      (p) => p.isContactPanel && p.contactPanelData!.contactId === userUuid
    );
    if (existingTicketPanel) {
      existingTicketPanel.select();
      existingTicketPanel.flash();
    } else if (existingContactPanel) {
      existingContactPanel.select();
      if (tab) {
        existingContactPanel.contactPanelData!.setContactTab(tab);
        if (tab === ContactTabSetting.Messages) {
          if (existingContactPanel.contactPanelData!.messageChannel) {
            existingContactPanel.contactPanelData!.messageChannel.setScroll(
              true
            );
          }
        }
      }
      existingContactPanel.flash();
    } else {
      const newTab = tab || ContactTabSetting.Actions;
      this.state.panels.add({
        customData: {
          contactId: userUuid,
          contactTab: newTab,
        },
        name,
        panel,
        templateId: TemplateId.contact,
      });
    }
  }
  private async queryClientContacts(
    userIds: ClientUserUuid[],
    offset: number
  ): Promise<{
    items: {
      currentStatus: {
        backgroundColor: null | string;
        foregroundColor: null | string;
        id: string;
        name: string;
      } | null;
      displayName: string;
      id: string;
      onlineStatus: {
        state: ClientOnlineState;
      };
      title?: null | string;
    }[];
    pageInfo: PageInfo;
  }> {
    const { clientUsers } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUsersForLocation(
          $pagination: OffsetLimitInput!
          $filter: ClientUsersFilterInput
        ) {
          clientUsers(pagination: $pagination, filter: $filter) {
            items {
              ... on ClientUser {
                id
                displayName
                title
                onlineStatus {
                  state
                }
                currentStatus {
                  id
                  name
                  backgroundColor
                  foregroundColor
                }
              }
            }
            pageInfo {
              totalItems
              offset
              limit
            }
          }
        }
      `),
      variables: {
        filter: {
          userIds,
        },
        pagination: {
          limit: CONTACTS_PER_FETCH,
          offset,
        },
      },
    });
    return clientUsers;
  }
  private static chunk(
    arr: ClientUserUuid[],
    chunkSize: number
  ): [ClientUserUuid[]?] {
    if (chunkSize <= 0) {
      return [arr];
    }
    const res: [ClientUserUuid[]?] = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      res.push(arr.slice(i, i + chunkSize));
    }
    return res;
  }
}
