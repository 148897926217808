import { observableClass } from "src/app/state/observableClass";
import type { Notification } from "src/app/model/notifications/Notification";

export class Message {
  public readonly duration: number;
  public readonly icon?: "reset" | "usb";
  public readonly key: number;
  public readonly message?: string;
  public readonly notification?: Notification;
  public readonly removeMe: () => void;
  public readonly severity: "info" | "success" | "warning";
  public opened = true;
  public constructor(options: {
    duration?: number;
    icon?: "reset" | "usb";
    key: number;
    message?: string;
    notification?: Notification;
    removeMe: () => void;
    severity?: "info" | "success" | "warning";
  }) {
    this.duration = options.duration || 6000;
    this.severity = options.severity || "success";
    this.message = options.message;
    this.icon = options.icon;
    this.notification = options.notification;
    this.key = options.key;
    this.removeMe = options.removeMe;
    observableClass(this);
  }
  public close(): void {
    this.opened = false;
    this.removeMe();
  }
}
