export const getReadableTimeFormat = (options: {
  ago: boolean;
  fromTime: Date;
  now?: Date;
  seconds?: boolean;
}): string => {
  const { ago, fromTime, now = new Date(), seconds = false } = options;
  let totalSeconds = Math.max(
    Math.floor(now.getTime() / 1000) - fromTime.getTime() / 1000,
    0
  );
  const days = Math.floor(totalSeconds / 86400);
  totalSeconds %= 86400;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);

  let timeString = "";
  if (days > 0) {
    timeString += `${days} ${days === 1 ? "day" : "days"}`;
  }
  if (hours > 0) {
    if (days > 0) {
      timeString += ", ";
    }
    timeString += `${hours} ${hours === 1 ? "hour" : "hours"}`;
  }
  if (days === 0 && minutes >= 0) {
    if (hours > 0) {
      timeString += ", ";
    }
    if (hours === 0 && minutes < 1) {
      timeString += "Just now";
    } else if (seconds) {
      timeString += `${minutes} min`;
    } else {
      timeString += `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    }
  }
  if (ago) {
    if (days === 0 && hours === 0 && minutes < 1) {
      if (seconds) {
        timeString = `${Math.floor(totalSeconds)}s ago`;
      } else {
        timeString = "< 1 minute ago";
      }
    } else {
      timeString += " ago";
    }
  }
  return timeString;
};
