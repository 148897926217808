import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { ListSetting } from "src/app/model/settings/ListSetting";
import { NumberSetting } from "src/app/model/settings/NumberSetting";
import { observableClass } from "src/app/state/observableClass";

export class OpusSettings {
  public readonly bandwidth: ListSetting;
  public readonly bitrate: NumberSetting;
  public readonly expectedPacketLoss: NumberSetting;
  public readonly forwardErrorCorrection: BooleanSetting;
  public readonly maxFrameSize: NumberSetting;
  public readonly variableBitrate: BooleanSetting;
  public constructor() {
    this.maxFrameSize = new NumberSetting({
      defaultValue: 100,
      key: "gt2.settings.codecSettings.opus.maxFrameSize",
      maxValue: 100,
      minValue: 10,
    });
    this.bitrate = new NumberSetting({
      defaultValue: 18000,
      key: "gt2.settings.codecSettings.opus.bitrate",
      maxValue: 512000,
      minValue: 500,
    });
    this.bandwidth = new ListSetting({
      defaultId: 1103,
      key: "gt2.settings.codecSettings.opus.bandwidth",
      list: [
        {
          id: 1101,
          name: "Narrowband",
        },
        {
          id: 1102,
          name: "Mediumband",
        },
        {
          id: 1103,
          name: "Wideband",
        },
      ],
    });
    this.variableBitrate = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.codecSettings.opus.variableBitrate",
    });
    this.forwardErrorCorrection = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.codecSettings.opus.forwardErrorCorrection",
    });
    this.expectedPacketLoss = new NumberSetting({
      defaultValue: 2,
      key: "gt2.settings.codecSettings.opus.expectedPacketLoss",
      maxValue: 100,
      minValue: 0,
    });
    observableClass(this);
  }
}
