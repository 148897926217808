import { Downsampler } from "src/audio/resampler/Downsampler";
import { Upsampler } from "src/audio/resampler/Upsampler";
import type { Resampler } from "src/audio/resampler/Resampler";

export const createResampler = (options: {
  fromHz: number;
  toHz: number;
}): Resampler => {
  const ratioWeight = options.fromHz / options.toHz;
  if (options.fromHz === options.toHz) {
    return {
      resample(buffer: Float32Array): Float32Array {
        return buffer;
      },
    };
  }
  if (options.fromHz < options.toHz) {
    return new Upsampler(ratioWeight);
  }
  return new Downsampler(ratioWeight);
};
