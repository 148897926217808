import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import {
  GroupIcon,
  ConferenceGroupIcon,
  PrivateCallIcon,
  MonitorIcon,
  BroadcastIcon,
  PatchIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";
import { SessionType } from "src/lib/types/SessionType";
import { highlight } from "src/util/highlight";
import type { Channel } from "src/app/model/channels/Channel";

const background = {
  [SessionType.AutoJoin]: { focused: highlight("#688898"), normal: "#688898" },
  [SessionType.Call]: { focused: highlight("#c88838"), normal: "#c88838" },
  [SessionType.Group]: { focused: "#576ab7", normal: "#589868" },
  [SessionType.MonitoringListener]: {
    focused: highlight("#ab77c2"),
    normal: "#ab77c2",
  },
  [SessionType.MonitoringSender]: {
    focused: highlight("#ab77c2"),
    normal: "#ab77c2",
  },
  [SessionType.Scan]: { focused: highlight("#aa2323"), normal: "#aa2323" },
};

const icon = {
  [SessionType.AutoJoin]: GroupIcon,
  [SessionType.Call]: PrivateCallIcon,
  [SessionType.Group]: GroupIcon,
  [SessionType.MonitoringListener]: MonitorIcon,
  [SessionType.MonitoringSender]: MonitorIcon,
  [SessionType.Scan]: MonitorIcon,
};

export const SessionTypeArea = connect<{
  channel: Channel;
}>(({ channel }) => {
  const { hasPatch, isFullDuplex, patchColor, session } = channel;
  const type = session ? session.type : SessionType.Group;
  const isFocused = session ? session.isFocused : false;
  const maySelectForBroadcasting =
    !isFullDuplex && session ? session.maySelectForBroadcasting : false;
  let bg = background[type][isFocused ? "focused" : "normal"];
  if (maySelectForBroadcasting) {
    bg = isFocused ? highlight("#297dcb") : "#297dcb";
  }
  if (!session) {
    bg = "#717c86";
  }
  let Icon;
  if (maySelectForBroadcasting) {
    Icon = BroadcastIcon;
  } else if (isFullDuplex) {
    Icon = ConferenceGroupIcon;
  } else if (hasPatch) {
    Icon = PatchIcon;
    if (patchColor != null) {
      bg = patchColor;
    }
  } else {
    Icon = icon[type];
  }
  return (
    <Layout
      onClick={() => {
        if (session) {
          session.focus();
        }
      }}
      alignCenter
      alignMiddle
      background={bg}
      column
      gap={type === SessionType.AutoJoin ? 10 : 0}
      grow
      style={{ borderRight: "1px solid black", cursor: "pointer" }}
    >
      <Icon
        style={{
          fill: patchColor != null ? "#444" : "#ddd",
          height: 32,
          width: 32,
        }}
      />
      {maySelectForBroadcasting && (
        <GtCheckbox
          checked={session!.selectedForBroadcasting}
          onChange={() => session!.toggleSelectForBroadcast()}
        />
      )}
      {type === SessionType.AutoJoin && (
        <Text bold color="#ddd" size={12}>
          AUTO
        </Text>
      )}
    </Layout>
  );
});
