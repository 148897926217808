import { observableClass } from "src/app/state/observableClass";
import type { Queue as NodeQueue } from "src/lib/modules/Queue";

export class Queue {
  public description?: string;
  public emergency: boolean;
  public name: string;
  public owner?: string;
  public priority: number;
  public queueEntityId: string;
  public constructor(queue: NodeQueue) {
    this.queueEntityId = queue.queueEntityId;
    this.name = queue.name;
    this.description = queue.description;
    this.owner = queue.owner;
    this.emergency = queue.emergency;
    this.priority = queue.priority;
    observableClass(this);
  }
  public get isHighPriority(): boolean {
    return this.priority >= 6;
  }
}
