import { gql } from "src/app/graphql";

export const ClientUserForPresence = gql(`
  query clientUserForPresence($id: ID!) {
    clientUser(id: $id) {
      id
      entityId
      displayName
      email
      phoneNumbers
      onlineStatus {
        state
      }
      organization {
        id
        name
      }
      currentStatus {
        id
        name
        backgroundColor
        foregroundColor
      }
      availableStatuses {
        ... on ClientUserStatus {
          id
          name
          backgroundColor
          foregroundColor
        }
      }
      permissions {
        locate
        call
        message
        fullDuplexCall
      }
    }
  }
`);
