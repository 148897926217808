import { Button } from "src/app/model/input/Button";
import type { ButtonMapper } from "src/app/model/input/ButtonMapper";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";

export class ImtraDexHandler implements DeviceHandler {
  private state = 0;
  public constructor(
    private readonly buttonMapper: ButtonMapper,
    private readonly id: string
  ) {}
  public onData(data: ArrayBuffer): void {
    const newState = new Uint8Array(data)[0];
    if (!(this.state & (1 << 5)) && newState & (1 << 5)) {
      this.buttonMapper.onButtonDown(this.id, Button.PRIMARY);
    }
    if (this.state & (1 << 5) && !(newState & (1 << 5))) {
      this.buttonMapper.onButtonUp(this.id, Button.PRIMARY);
    }
    if (!(this.state & (1 << 6)) && newState & (1 << 6)) {
      this.buttonMapper.onButtonDown(this.id, Button.SECONDARY1);
    }
    if (this.state & (1 << 6) && !(newState & (1 << 6))) {
      this.buttonMapper.onButtonUp(this.id, Button.SECONDARY1);
    }
    this.state = newState;
  }
}
