import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MuteGroupIcon, CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";

export const MuteGroupMenu = connect((_, state) => {
  const { deselect, list, select, selected } =
    state.online!.sessions.muteGroups!;
  return (
    <Layout row>
      <GtMenu
        items={[
          <GtMenuItem
            icon={
              selected == null ? <CheckIcon style={{ fill: "#666" }} /> : null
            }
            label={
              <Text color="#666" italic>
                None
              </Text>
            }
            onSelect={() => {
              deselect();
            }}
            key="none"
          />,
          ...list.map((muteGroup) => (
            <GtMenuItem
              icon={
                selected && selected.id === muteGroup.id ? <CheckIcon /> : null
              }
              onSelect={() => {
                select(muteGroup);
              }}
              key={muteGroup.id}
              label={muteGroup.name}
            />
          )),
        ]}
        id="mutegroups"
      >
        <Layout alignCenter alignMiddle row>
          <MuteGroupIcon />
        </Layout>
      </GtMenu>
    </Layout>
  );
});
