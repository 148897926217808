import { CreateGtTable } from "src/app/gui/components/CreateGtTable";
import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { AlarmIcon, TicketIcon, PriorityTicketIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";
import type { Queue } from "src/app/model/queues/Queue";

type CombinedQueueAndAll = Queue & { allQueues: true };
const GtTable = CreateGtTable<CombinedQueueAndAll>();

export const QueueFilter = connect<{
  panel: Panel;
}>((props, state) => {
  const { panel } = props;
  const list = Object.values(state.online?.queueManagement.queueList ?? []);

  return (
    <Layout column gap={5}>
      <Text>Show tickets from following queues:</Text>
      <GtTable
        columns={[
          {
            header: "",
            id: "icon",
            render: ({ allQueues, emergency, isHighPriority }) =>
              allQueues ? (
                <Layout />
              ) : (
                <Layout alignMiddle row>
                  {emergency && <AlarmIcon style={{ fill: "#7d1616" }} />}
                  {!emergency && isHighPriority && (
                    <PriorityTicketIcon style={{ fill: "#4e4e4e" }} />
                  )}
                  {!emergency && !isHighPriority && (
                    <TicketIcon style={{ fill: "#1d1d1d" }} />
                  )}
                </Layout>
              ),
            width: 15,
          },
          {
            header: "Queue",
            id: "name",
            render: ({ allQueues, name }) =>
              allQueues ? <Text italic>All queues</Text> : <Text>{name}</Text>,
          },
          {
            header: "Description",
            id: "description",
            render: ({ allQueues, description }) =>
              allQueues ? <Text /> : <Text>{description}</Text>,
          },
          {
            header: "Unpicked",
            id: "unpicked",
            render: ({ allQueues, queueEntityId }, queuePanelData) =>
              allQueues ? (
                <GtCheckbox
                  checked={queuePanelData.defaultUnpicked}
                  disabled={!queuePanelData.mayToggleDefaultUnpicked}
                  onChange={() => queuePanelData.toggleDefaultUnpicked()}
                />
              ) : (
                <GtCheckbox
                  checked={queuePanelData.unpicked(queueEntityId)}
                  disabled={queuePanelData.defaultUnpicked}
                  onChange={() => queuePanelData.toggleUnpicked(queueEntityId)}
                />
              ),
          },
          {
            header: "Picked by other",
            id: "pickedbyother",
            render: ({ allQueues, queueEntityId }, queuePanelData) =>
              allQueues ? (
                <GtCheckbox
                  checked={queuePanelData.defaultPickedByOther}
                  disabled={!queuePanelData.mayToggleDefaultPickedByOther}
                  onChange={() => queuePanelData.toggleDefaultPickedByOther()}
                />
              ) : (
                <GtCheckbox
                  onChange={() =>
                    queuePanelData.togglePickedByOther(queueEntityId)
                  }
                  checked={queuePanelData.pickedByOther(queueEntityId)}
                  disabled={queuePanelData.defaultPickedByOther}
                />
              ),
          },
        ]}
        getRowKey={(queue) =>
          queue.allQueues ? "allqueues" : queue.queueEntityId
        }
        customData={panel.queuePanelData}
        /**
         * This is a bit of an hack to add a All queues entry.
         */
        rows={[{ allQueues: true }, ...list] as CombinedQueueAndAll[]}
      />
    </Layout>
  );
});
