import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { JoinGroupIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import React from "react";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const LeaveGroupMenu = connect<{
  channel: Channel;
  children: React.ReactElement;
  panel: Panel;
}>(({ channel, children, panel }) => (
  <GtMenu
    items={[
      <GtMenuItem
        onSelect={() => {
          channel.updateJoined(false);
        }}
        icon={<JoinGroupIcon />}
        key="leave"
        label="Leave group"
      />,
    ]}
    base={Layout}
    id={`leave_group_${panel.id}_${channel.channelUuid}`}
  >
    {children}
  </GtMenu>
));
