import { ClientRequestTimeout } from "src/lib/errors/ClientRequestTimeout";
import { ConnectionClosedLocally } from "src/lib/errors/ConnectionClosedLocally";
import { RequestManager } from "src/lib/RequestManager";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { Logger } from "src/util/Logger";
import type { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";
import type { ConnectedModule } from "src/lib/modules/ConnectedModule";

const log = Logger.getLogger("Reconnector");
const RECONNECTION_INTERVAL = [
  500, 3000, 3000, 5000, 10000, 10000, 15000, 15000, 15000, 15000, 15000, 15000,
  15000, 15000, 30000,
];
const INITIAL_CONNECTION_TIMEOUT_TIME = 5000;
const ADDITIONAL_CONNECTION_TIMEOUT_TIME = 500; // Increase each failure with 0.5s.
const MAX_CONNECTION_TIMEOUT_TIME = 10000; // Maximum timeout.

export class Reconnector {
  private readonly onConnect: (connectedModule: ConnectedModule) => void;
  private readonly onDisconnect: (groupTalkAPIError: GroupTalkAPIError) => void;
  private readonly server: string;
  private reconnectionAttempt = 0;
  private requestManager?: RequestManager;
  private timeout: number;
  public constructor({
    onConnect,
    onDisconnect,
    server,
    timeout,
  }: {
    onConnect: (connectedModule: ConnectedModule) => void;
    onDisconnect: (groupTalkAPIError: GroupTalkAPIError) => void;
    server: string;
    timeout?: number;
  }) {
    this.server = server;
    this.onDisconnect = onDisconnect;
    this.onConnect = onConnect;
    this.timeout = timeout || INITIAL_CONNECTION_TIMEOUT_TIME;
  }
  public async start(): Promise<() => void> {
    this.requestManager = new RequestManager({
      onDisconnect: (error) => {
        if (this.onDisconnect) {
          this.onDisconnect(error);
        }
        if (error.temporary === undefined || error.temporary) {
          const time = this.reconnectionTime();
          BackgroundTimer.setTimeout(() => {
            void this.start();
          }, time);
          this.reconnectionAttempt += 1;
          log.debug(
            `Reconnecting attempt #${this.reconnectionAttempt} in ${time}ms (${error})`
          );
          if (error instanceof ClientRequestTimeout) {
            if (this.timeout <= MAX_CONNECTION_TIMEOUT_TIME) {
              this.timeout += ADDITIONAL_CONNECTION_TIMEOUT_TIME;
            }
          }
        }
      },
      server: this.server,
      timeout: this.timeout,
    });
    try {
      const connectedModule = await this.requestManager.start();
      if (this.onConnect) {
        this.onConnect(connectedModule);
      }
      const savedReconnectionAttempt = this.reconnectionAttempt;
      BackgroundTimer.setTimeout(() => {
        if (this.reconnectionAttempt === savedReconnectionAttempt) {
          // This connection has not been disconnected for 10 seconds.
          this.reconnectionAttempt = 0;
          this.timeout = INITIAL_CONNECTION_TIMEOUT_TIME;
        }
      }, 10000);
    } catch (error: any) {
      log.error(error);
    }
    return () => {
      this.requestManager?.stop(new ConnectionClosedLocally());
    };
  }
  private reconnectionTime(): number {
    const index = Math.min(
      this.reconnectionAttempt,
      RECONNECTION_INTERVAL.length - 1
    );
    return RECONNECTION_INTERVAL[index] + Math.floor(Math.random() * 500);
  }
}
