import { gql } from "src/app/graphql";
import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { EventType } from "src/app/model/events/types/EventType";
import { DeviceLocation } from "src/app/model/location/DeviceLocation";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import { Logger } from "src/util/Logger";
import { action, observable } from "mobx";
import type { LatLng, LatLngBounds } from "leaflet";
import type { DetailedEvent } from "src/app/model/events/types/DetailedEvent";
import type { PartialEvent } from "src/app/model/events/types/PartialEvent";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { MapBounds } from "src/app/types/MapBounds";
import type { LocationEnquirySubscription } from "src/lib/modules/LocationEnquirySubscription";
import type { ClientUserDetailed } from "src/nextgen/types/ClientUserDetailed";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";

const log = Logger.getLogger("LocationData");

const PAD_BOUNDS = 0.5;

type LocationSubscription = (
  locationArray: [number, number][],
  storedBounds: [[number, number], [number, number]] | null,
  resetViewOnLogin: boolean
) => void;

export class LocationData {
  public contactDetails?: ContactDetails;
  public followingUser: DeviceLocation | null = null;
  public globalTime = new Date();
  private clickLocation: LatLng | null = null;
  private clickZoom: null | number = null;
  // Keyed by userId (not uuid)
  private locationEntries: Record<string, DeviceLocation> = {};
  private onSubscribed?: LocationSubscription;
  private resetViewOnFirstLocations = true;
  private selectedUserId?: string;
  private subscriptionHandle?: LocationEnquirySubscription;
  private timerInterval?: NodeJS.Timeout;
  private unsubscribeToUpdates?: () => void;
  private updateBoundZone: LatLngBounds | null = null;
  public constructor(
    private readonly state: State,
    public readonly panel: Panel,
    private readonly entityId: null | string,
    private readonly name: string
  ) {
    observableClass(this, { updateBoundZone: observable.ref });
    this.startSubscribingToChanges();
  }
  public get userLocations(): DeviceLocation[] {
    return Object.values(this.locationEntries);
  }
  public get googleUrlOfLocation(): null | string {
    if (this.clickLocation && this.clickZoom) {
      const longitude = this.clickLocation.lng;
      const latitude = this.clickLocation.lat;
      const zoom = this.clickZoom;
      return `https://maps.google.com/maps?q=${latitude},${longitude}&z=${zoom}`;
    }
    return null;
  }
  public get resetViewOnLogin(): boolean {
    return this.state.settings.map.resetView.value;
  }
  public get selectedUserLocation(): DeviceLocation | null {
    return this.selectedUserId
      ? this.locationEntries[this.selectedUserId]
      : null;
  }
  public get storedBounds(): [[number, number], [number, number]] | null {
    return this.parameters.bounds.neLat
      ? [
          [this.parameters.bounds.neLat!, this.parameters.bounds.neLng!],
          [this.parameters.bounds.swLat!, this.parameters.bounds.swLng!],
        ]
      : null;
  }
  public get storedLayer(): string {
    return this.parameters.storedLayer;
  }
  public deselect(): void {
    this.selectedUserId = undefined;
    this.contactDetails = undefined;
    this.stopGlobalTimer();
  }
  public async lookupSelectedContact(): Promise<void> {
    if (this.selectedUserLocation?.userUuid) {
      await this.fetchContactDetails(this.selectedUserLocation.userUuid);
    }
  }
  public async onDelete(): Promise<void> {
    log.debug("Closing down location data");
    if (this.subscriptionHandle) {
      try {
        await this.subscriptionHandle.unsubscribe();
      } catch (error: any) {
        log.warn("Unsubscribing failed: ", error.message);
      }
      this.subscriptionHandle = undefined;
      this.locationEntries = {};
    }
    this.unsubscribeToUpdates?.();
    this.unsubscribeToUpdates = undefined;
    this.stopGlobalTimer();
  }
  public openInNewPanel(): void {
    this.state.panels.add({
      customData: {
        entityId: this.entityId,
      },
      name: this.name,
      panel: this.panel,
      templateId: TemplateId.location,
    });
  }
  public async resetView(): Promise<void> {
    try {
      await this.subscriptionHandle?.unsubscribe();
    } catch (error: any) {
      log.warn("Unsubscribing failed: ", error.message);
    }
    this.locationEntries = {};
    void this.subscribe({ onSubscribed: this.onSubscribed });
  }
  public selectUserId(userId: string): void {
    this.selectedUserId = userId;
    this.startGlobalTimer();
  }
  public setClickLocation({
    location,
    zoom,
  }: {
    location: LatLng;
    zoom: number;
  }): void {
    this.clickLocation = location;
    this.clickZoom = zoom;
  }
  public setStoredLayer(name: string): void {
    this.panel.setData(["storedLayer"], name);
  }
  public async subscribe({
    onSubscribed,
  }: {
    onSubscribed?: LocationSubscription;
  }): Promise<void> {
    if (this.state.online?.location.maySubscribe) {
      const subscriptionHandle = await this.state.online.location.subscribe({
        entityIds: this.entityId ? [this.entityId] : [],
        onDelta: action((delta) => {
          if (delta.updatedOrNew) {
            const newUserUuids: ClientUserUuid[] = [];
            delta.updatedOrNew.forEach((deviceLocation) => {
              if (!this.locationEntries[deviceLocation.userId]) {
                const newDevice = new DeviceLocation(
                  this.state,
                  deviceLocation
                );
                this.locationEntries[deviceLocation.userId] = newDevice;
                if (newDevice.userUuid) {
                  newUserUuids.push(newDevice.userUuid);
                }
              } else {
                this.locationEntries[deviceLocation.userId].updateWithEntry(
                  deviceLocation
                );
              }
            });
            if (newUserUuids.length > 0) {
              void this.fetchClientUsers(newUserUuids);
            }
          }
          if (delta.removedUserIds) {
            delta.removedUserIds.forEach((userId) => {
              delete this.locationEntries[userId];
            });
          }
          if (
            onSubscribed &&
            delta.updatedOrNew &&
            delta.updatedOrNew.length > 0 &&
            this.resetViewOnFirstLocations
          ) {
            this.resetViewOnFirstLocations = false;
            onSubscribed(
              this.locationArray,
              this.storedBounds,
              this.resetViewOnLogin
            );
          }
          log.debug("Location delta", delta);
        }),
        range: undefined,
      });
      this.subscriptionHandle = subscriptionHandle;
      this.onSubscribed = onSubscribed;
      if (onSubscribed) {
        if (this.locationArray && this.locationArray.length > 0) {
          this.resetViewOnFirstLocations = false;
        }
        onSubscribed(
          this.locationArray,
          this.storedBounds,
          this.resetViewOnLogin
        );
      }
    }
  }
  public updateBoundsIfNeeded(bounds: LatLngBounds): void {
    this.saveBounds(bounds);
    if (this.updateBoundZone) {
      const latitudeLength =
        bounds.getNorthEast().lat - bounds.getSouthWest().lat;
      const longitudeLength =
        bounds.getNorthEast().lng - bounds.getSouthWest().lng;
      const oldLatitudeLength =
        this.updateBoundZone.getNorthEast().lat -
        this.updateBoundZone.getSouthWest().lat;
      const oldLongitudeLength =
        this.updateBoundZone.getNorthEast().lng -
        this.updateBoundZone.getSouthWest().lng;
      const factorResize =
        (latitudeLength * longitudeLength) /
        (oldLatitudeLength * oldLongitudeLength);
      if (
        !this.updateBoundZone.contains(
          bounds.getSouthWest() ||
            !this.updateBoundZone.contains(bounds.getNorthEast())
        ) ||
        factorResize < 0.75 / ((1 + 2 * PAD_BOUNDS) * (1 + 2 * PAD_BOUNDS))
      ) {
        this.updateZone(bounds);
      }
    } else {
      this.updateZone(bounds);
    }
  }
  private get locationArray(): [number, number][] {
    return this.userLocations.map((userLocation) => [
      userLocation.latestLocation!.latitude,
      userLocation.latestLocation!.longitude,
    ]);
  }
  private get parameters(): {
    bounds: MapBounds;
    entityId: null | string;
    storedLayer: string;
  } {
    return this.panel.parameters as {
      bounds: MapBounds;
      entityId: null | string;
      storedLayer: string;
    };
  }
  private async fetchClientUsers(userList: ClientUserUuid[]): Promise<void> {
    if (!this.state.online) {
      return;
    }
    log.debug(`Fetching ${userList.length} client contacts for location`);
    const users = await this.state.contactManagement.fetchClientUsers(userList);
    for (const user of users) {
      const deviceEntry = Object.values(this.locationEntries).find(
        (entry) => entry.userUuid === user.userUuid
      );
      if (deviceEntry) {
        deviceEntry.name = user.name;
        deviceEntry.title = user.title;
        deviceEntry.status = user.status;
        deviceEntry.online = user.online;
      }
    }
  }
  private async fetchContactDetails(contactId: ClientUserUuid): Promise<void> {
    let contactDetails: ContactDetails;
    try {
      const data = await this.queryContactDetails(contactId);

      if (data != null) {
        contactDetails = new ContactDetails(this.state, {
          callPermission: data.permissions.call,
          email: data.email,
          entityId: data.entityId,
          fullDuplexPermission: data.permissions.fullDuplexCall,
          id: data.id,
          locatable: data.permissions.locate,
          messagePermission: data.permissions.message,
          name: data.displayName,
          online: data.onlineStatus.state === "ONLINE",
          organization: data.organization,
          phoneNumbers: data.phoneNumbers,
          status: data.currentStatus,
          statusList: data.availableStatuses,
        });
        // fetch callsign from Callsign Module and the Node API
        await contactDetails.fetchCallsign();
        this.contactDetails = contactDetails;
      }
    } catch (err: any) {
      log.error(err);
      this.contactDetails = undefined;
    }
  }
  private async queryContactDetails(
    id: ClientUserUuid
  ): Promise<ClientUserDetailed | null | undefined> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserForLocation($id: ID!) {
          clientUser(id: $id) {
            id
            entityId
            displayName
            title
            email
            phoneNumbers
            onlineStatus {
              state
            }
            currentStatus {
              id
              name
              backgroundColor
              foregroundColor
            }
            availableStatuses {
              ... on ClientUserStatus {
                id
                name
                backgroundColor
                foregroundColor
              }
            }
            permissions {
              locate
              call
              message
              fullDuplexCall
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser;
  }
  private saveBounds(bounds: LatLngBounds): void {
    this.panel.setData(["bounds", "neLat"], bounds.getNorthEast().lat);
    this.panel.setData(["bounds", "neLng"], bounds.getNorthEast().lng);
    this.panel.setData(["bounds", "swLat"], bounds.getSouthWest().lat);
    this.panel.setData(["bounds", "swLng"], bounds.getSouthWest().lng);
  }
  private startGlobalTimer(): void {
    // A timer that updates ever 30 seconds, used to update location labels
    if (!this.timerInterval) {
      this.timerInterval = setInterval(
        action(() => {
          this.globalTime = new Date();
        }),
        30000
      );
    }
  }
  private startSubscribingToChanges(): void {
    this.unsubscribeToUpdates = this.state.online?.eventManager.subscribe({
      onDetailedEvent: action((msg: DetailedEvent) => {
        const deviceEntry = Object.values(this.locationEntries).find(
          (d) => d.userUuid?.toUpperCase() === msg.payload.id.toUpperCase()
        );
        if (deviceEntry !== undefined) {
          if (msg.type === EventType.OnlineUpdated) {
            deviceEntry.online = msg.payload.online;
          } else if (msg.type === EventType.UserDetailsUpdated) {
            deviceEntry.name = msg.payload.displayName;
            deviceEntry.title = msg.payload.title ?? undefined;
          } else if (msg.type === EventType.StatusUpdated) {
            deviceEntry.status = msg.payload.currentStatus ?? undefined;
          }
        }
      }),
      onEvent: (msg: PartialEvent) => {
        return Object.values(this.locationEntries).some(
          (d) => d.userUuid?.toUpperCase() === msg.payload.id?.toUpperCase()
        );
      },
      types: [
        EventType.UserDetailsUpdated,
        EventType.OnlineUpdated,
        EventType.StatusUpdated,
      ],
    });
  }
  private stopGlobalTimer(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = undefined;
    }
  }
  private updateZone(bounds: LatLngBounds): void {
    if (this.subscriptionHandle) {
      this.updateBoundZone = bounds.pad(PAD_BOUNDS);
      const range = {
        northEastLatitude: Math.min(
          this.updateBoundZone.getNorthEast().lat,
          90
        ),
        northEastLongitude: this.updateBoundZone.getNorthEast().lng,
        southWestLatitude: Math.max(
          this.updateBoundZone.getSouthWest().lat,
          -90
        ),
        southWestLongitude: this.updateBoundZone.getSouthWest().lng,
      };
      log.debug("Location zone updated", range);
      void this.subscriptionHandle.updateFilter({
        entityIds: this.entityId ? [this.entityId] : [],
        range,
      });
    }
  }
}
