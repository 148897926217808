import { StateContext } from "src/app/state/StateContext";
import { Observer } from "mobx-react-lite";
import { forwardRef, useContext } from "react";
import type { State } from "src/app/model/State";

export const connect = <
  P,
  T extends React.JSXElementConstructor<any> | string = any
>(
  WrappedComp: (
    props: React.PropsWithChildren<P>,
    state: State,
    ref: React.ForwardedRef<T>
  ) => React.ReactElement<P, T>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<T>
> =>
  forwardRef(
    (props: React.PropsWithChildren<P>, ref: React.ForwardedRef<T>) => {
      const state = useContext(StateContext);
      return Observer({
        render: () => WrappedComp(props, state!, ref),
      });
    }
  );
