let nextId = 0;
const timers: Record<
  number,
  {
    callback: () => void;
    handle: () => void;
  }
> = {};

const scriptContent = `
    onmessage = function(e) {
        setTimeout(() => {
            postMessage(e.data.id);
        }, e.data.duration);
    }
`;
const blob = new window.Blob([scriptContent], { type: "text/javascipt" });
const webWorker = new window.Worker(window.URL.createObjectURL(blob));
webWorker.addEventListener(
  "message",
  (e) => {
    const id = e.data;
    if (id in timers) {
      const { callback } = timers[id];
      delete timers[id];
      callback();
    }
  },
  false
);

export class BackgroundTimer {
  public static setTimeout(callback: () => void, duration: number): () => void {
    const id = nextId;
    nextId += 1;
    const cancelHandle = (): void => {
      delete timers[id];
    };
    timers[id] = {
      callback,
      handle: cancelHandle,
    };
    webWorker.postMessage({ duration, id });
    return cancelHandle;
  }
}
