import { gql } from "src/app/graphql";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { runInAction } from "mobx";
import type { State } from "src/app/model/State";
import type { PatchedGroup } from "src/nextgen/types/PatchedGroup";
import type { PatchInfo } from "src/nextgen/types/PatchInfo";

const log = Logger.getLogger("Patch");

export class Patch {
  public created?: string;
  public error?: string;
  public groups?: PatchedGroup[];
  public owner?: string;
  public patchColor?: string;
  public patchId: string;
  public constructor(
    private readonly state: State,
    patchInfo: {
      created?: string;
      groups?: PatchedGroup[];
      id: string;
      owner?: {
        name: string;
      };
    }
  ) {
    this.patchId = patchInfo.id!;
    this.created = patchInfo.created;
    this.owner = patchInfo.owner?.name ?? undefined;
    this.groups = patchInfo.groups; // TODO: map to a PatchedGroup class
    observableClass(this);
    runInAction(() => {
      void this.fetchPatchColor();
    });
  }
  public async addGroupToPatch(groupId: string): Promise<void> {
    const { addGroupToPatch } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation addGroupToPatch(
            $patchId: ID!,
            $groupId: ID!
          ) {
            addGroupToPatch(input: { patchId: $patchId, groupId: $groupId }) {
              error {
                __typename
                ... on ErrorInterface {
                  message
                }
              }
            }
          }
        `),
        variables: {
          groupId,
          patchId: this.patchId,
        },
      });
    const error = addGroupToPatch.error
      ? addGroupToPatch.error.message ||
        `Error adding group: ${addGroupToPatch.error.__typename}`
      : undefined;
    runInAction(() => {
      this.error = error;
    });
  }
  public async breakPatch({
    number,
    withConfirmation,
  }: {
    number?: string;
    withConfirmation: boolean;
  }): Promise<void> {
    if (withConfirmation) {
      this.state.dialogs.show({
        actions: [
          { label: "Cancel", onSelect: () => {} },
          {
            label: "Break patch",
            onSelect: () => {
              void this.breakPatch({ withConfirmation: false });
            },
          },
        ],
        forceRespond: true,
        text: `This patch made by ${this.owner}, ${new Date(
          this.created!
        ).toLocaleString()} comprises of ${
          this.groups!.length
        } group members. Proceeding with the breakup of this patch will result in interruption of inter-group communication. Shall we proceed?`,
        title: `Break Patch${number !== undefined ? ` ${number}` : ""}?`,
      });
    } else {
      const breakPatch = await this.breakPatchMutation();
      const error = breakPatch.error
        ? breakPatch.error.message ||
          `Error breaking patch: ${breakPatch.error.__typename}`
        : null;
      runInAction(() => {
        if (error !== null) {
          log.error(error);
        }
        // TODO: error handling
      });
    }
  }
  public async removeGroupFromPatch(groupId: string): Promise<void> {
    const { removeGroupFromPatch } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation removeGroupFromPatch($groupId: ID!) {
            removeGroupFromPatch(input: { groupId: $groupId }) {
              error {
                __typename
                ... on ErrorInterface {
                  message
                }
              }
            }
          }
        `),
        variables: {
          groupId,
        },
      });
    const error = removeGroupFromPatch.error
      ? removeGroupFromPatch.error.message ||
        `Error removing group: ${removeGroupFromPatch.error.__typename}`
      : null;
    runInAction(() => {
      this.error = error ?? undefined;
    });
  }
  public updateWithInfo(patchInfo: PatchInfo): void {
    this.patchId = patchInfo.id;
    this.created = patchInfo.created;
    this.owner = patchInfo.owner.name;
    this.groups = patchInfo.groups;
  }
  private async breakPatchMutation(): Promise<{
    __typename?: "BreakPatchPayload" | undefined;
    error?:
      | {
          __typename: "AuthorizationError";
          message?: null | string | undefined;
        }
      | {
          __typename: "NotFoundError";
          message?: null | string | undefined;
        }
      | null;
  }> {
    const { breakPatch } = await this.state.graphqlModule.mutationDataOrThrow({
      mutation: gql(`
        mutation breakPatch(
          $patchId: ID!
        ) {
          breakPatch(input: { patchId: $patchId }) {
            error {
              __typename
              ... on ErrorInterface {
                message
              }
            }
          }
        }
      `),
      variables: {
        patchId: this.patchId,
      },
    });
    return breakPatch;
  }
  private async fetchPatchColor(): Promise<void> {
    const patchColor = await this.state.online?.patches.getColorForPatchId(
      this.patchId!
    );
    runInAction(() => {
      this.patchColor = patchColor;
    });
  }
}
