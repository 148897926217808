import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { panelTypeInfo } from "src/app/gui/shared/panelTypeInfo";
import { AddPanelIcon } from "src/app/images";
import { Panels } from "src/app/model/panels/Panels";
import { connect } from "src/app/state/connect";

export const PanelMenu = connect((_, state) => {
  const { add, createPatch } = state.panels;
  const menuItems = Object.values(Panels.visibleTemplates).map((template) => (
    <GtMenuItem
      onSelect={() => {
        add(template);
      }}
      icon={panelTypeInfo[template.type].icon}
      key={template.templateId}
      label={<Text color="#333">{template.name}</Text>}
    />
  ));
  menuItems.push(
    <GtMenuItem
      onSelect={() => {
        createPatch();
      }}
      icon={panelTypeInfo.patch.icon}
      key="patch"
      label={<Text color="#333">Create Patch</Text>}
    />
  );

  return (
    <Layout grow row>
      <GtMenu id="panels" items={menuItems}>
        <Layout alignCenter alignMiddle row>
          <AddPanelIcon />
        </Layout>
      </GtMenu>
    </Layout>
  );
});
