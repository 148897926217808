import { gql } from "src/app/graphql";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { WrappedPromise } from "src/util/WrappedPromise";
import { runInAction } from "mobx";
import localStorage from "mobx-localstorage";
import type { State } from "src/app/model/State";

const PATCH_COLORS = [
  "#F292AE", // - a darker pink
  "#ffb57c", // - a darker peachy-orange
  "#B8B8E6", // - a darker lavender
  "#6CB5B5", // - a darker turquoise
  "#ada04f", // - a darker yellow
  "#7CCD7C", // - a darker green
  "#af7a7a", // - a darker pinkish-red
  "#877ebc", // - a darker purple
  "#E4D261", // - a darker golden-yellow
  "#7bafec", // - a darker blue
];
const SETTING_KEY = "gt2.patchColors";

const log = Logger.getLogger("PatchColors");

export class PatchColors {
  private allocatingPromise: WrappedPromise<string[]> | null = null;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public async getColorForPatchId(id: string): Promise<string | undefined> {
    if (this.patchColors[id] !== undefined) {
      return PatchColors.colorOfIndex(this.patchColors[id]);
    }
    let color;
    if (this.allocatingPromise !== null) {
      // Allocation in progress. Wait for result
      const activePatchIds = await this.allocatingPromise.promise;
      runInAction(() => {
        if (this.patchColors[id] === undefined && activePatchIds.includes(id)) {
          this.setColorIndexForPatchId(id, this.nextFreeColor);
        }
        color = PatchColors.colorOfIndex(this.patchColors[id]);
      });
      return color;
    }
    this.allocatingPromise = new WrappedPromise();

    // This patch has no allocated color. Lets allocate colors
    log.debug("Allocating colors.");

    // First remove all unused patch allocations
    const activePatchIds = await this.queryAllPatches();
    log.debug("Active patchIds:", activePatchIds);
    runInAction(() => {
      Object.keys(this.patchColors).forEach((key) => {
        if (!activePatchIds.includes(key)) {
          this.deletePatchId(key);
        }
      });
      // Then bump up "unallocated" patch-ids that have a color allocation larger than the
      // maximum amount
      while (true) {
        const unallocatedPatches = Object.keys(this.patchColors).filter(
          (patchId) => this.patchColors[patchId] >= PATCH_COLORS.length
        );
        if (
          unallocatedPatches.length > 0 &&
          this.nextFreeColor < PATCH_COLORS.length
        ) {
          this.setColorIndexForPatchId(
            unallocatedPatches[0],
            this.nextFreeColor
          );
        } else {
          break;
        }
      }

      // Then allocate a color if needed to this patch.
      if (this.patchColors[id] === undefined && activePatchIds.includes(id)) {
        this.setColorIndexForPatchId(id, this.nextFreeColor);
      }
      // Other may have been asking for allocations simultanously. Return the result to them.
      this.allocatingPromise?.resolve(activePatchIds);
      this.allocatingPromise = null;
      color = PatchColors.colorOfIndex(this.patchColors[id]);
    });
    return color;
  }
  private static get colorOfIndex() {
    return (colorIndex: number): string => {
      if (colorIndex < PATCH_COLORS.length) {
        return PATCH_COLORS[colorIndex];
      }
      // If we run out of colors, use a gradient combination of two colors.
      // If we use colors A + B we don't use the combination B + A.
      // This gives a total of 55 combinations.
      const numCombinations =
        (PATCH_COLORS.length * (PATCH_COLORS.length - 1)) / 2;
      const combinationIndex = colorIndex - PATCH_COLORS.length;
      if (combinationIndex >= numCombinations) {
        return PATCH_COLORS[0];
      }
      const n = PATCH_COLORS.length;
      const i =
        n -
        2 -
        Math.floor(
          Math.sqrt(-8 * combinationIndex + 4 * n * (n - 1) - 7) / 2.0 - 0.5
        );
      const j =
        combinationIndex +
        i +
        1 -
        (n * (n - 1)) / 2 +
        ((n - i) * (n - i - 1)) / 2;
      return `linear-gradient(to right, ${PATCH_COLORS[i]}, ${PATCH_COLORS[j]})`;
    };
  }
  private get nextFreeColor(): number {
    let n = 0;
    while (this.getPatchIdForColor(n) !== null) {
      n += 1;
    }
    return n;
  }
  // eslint-disable-next-line class-methods-use-this
  private get patchColors(): Record<string, number> {
    return localStorage.getItem(SETTING_KEY) || {};
  }
  private deletePatchId(patchId: string): void {
    const t = this.patchColors;
    delete t[patchId];
    localStorage.setItem(SETTING_KEY, t);
  }
  private getPatchIdForColor(value: number): null | string {
    const keys = Object.keys(this.patchColors);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (this.patchColors[key] === value) {
        return key;
      }
    }
    return null; // return null if value is not found
  }
  private async queryAllPatches(): Promise<string[]> {
    const { activePatchIds } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query queryActivePatchIds
        {
          activePatchIds
        }
      `),
      variables: {},
    });
    return activePatchIds;
  }
  private setColorIndexForPatchId(patchId: string, colorIndex: number): void {
    localStorage.setItem(SETTING_KEY, {
      ...this.patchColors,
      [patchId]: colorIndex,
    });
  }
}
