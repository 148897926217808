import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Text } from "src/app/gui/components/Text";
import { StatusBadge } from "src/app/gui/panels/contacts/StatusBadge";
import { CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";

export const StatusMenuItems = connect<{
  entity: ContactDetails;
}>(({ entity }) => {
  const { status, statusList, updateStatus } = entity;
  return (
    <>
      {[
        <GtMenuItem
          label={
            <Text color="#666" italic>
              No status
            </Text>
          }
          onSelect={() => {
            updateStatus();
          }}
          icon={status ? null : <CheckIcon style={{ fill: "#666" }} />}
          key="none"
        />,
        ...statusList!.map((statusOption) => (
          <GtMenuItem
            icon={
              status && status.id === statusOption.id ? <CheckIcon /> : null
            }
            label={
              <StatusBadge defaultBackground statusOption={statusOption} />
            }
            onSelect={() => {
              updateStatus(statusOption.id);
            }}
            key={statusOption.id}
          />
        )),
      ]}
    </>
  );
});
