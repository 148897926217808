import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MenuSeparator } from "src/app/gui/header/MenuSeparator";
import { BroadcastIcon, CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";

export const BroadcastMenu = connect((_, state) => {
  const { broadcasting, selectAllGroups, selectNoGroups, setBroadcasting } =
    state.online!.broadcast;
  return (
    <Layout grow row>
      <GtMenu
        items={[
          <GtMenuItem
            label={
              <Text color="#666" italic>
                Normal mode
              </Text>
            }
            onSelect={() => {
              setBroadcasting(false);
            }}
            icon={!broadcasting ? <CheckIcon style={{ fill: "#666" }} /> : null}
            key="normalmode"
          />,
          <GtMenuItem
            label={
              <Layout
                style={{
                  borderRadius: 5,
                  width: "100%",
                }}
                background="#297dcb"
                column
                grow
                padding={5}
              >
                <Text center color="#fff">
                  Broadcast mode
                </Text>
              </Layout>
            }
            onSelect={() => {
              setBroadcasting(true);
            }}
            icon={broadcasting ? <CheckIcon /> : null}
            key="broadcastmode"
          />,
          <MenuSeparator key="separator" />,
          <GtMenuItem
            onSelect={() => {
              selectAllGroups();
            }}
            disabled={!broadcasting}
            key="selectall"
            label="Select all groups"
          />,
          <GtMenuItem
            onSelect={() => {
              selectNoGroups();
            }}
            disabled={!broadcasting}
            key="deselect"
            label="Deselect all groups"
          />,
        ]}
        id="broadcast"
      >
        <Layout alignCenter alignMiddle row>
          <BroadcastIcon style={{ fill: "#aaa" }} />
          {broadcasting && (
            <Layout
              style={{
                borderRadius: 5,
                width: "100%",
              }}
              background="#297dcb"
              column
              grow
              marginLeft={8}
              padding="5px 10px 5px 10px"
            >
              <Text center color="#fff">
                Broadcasting
              </Text>
            </Layout>
          )}
        </Layout>
      </GtMenu>
    </Layout>
  );
});
