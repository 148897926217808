import { GtRadio } from "src/app/gui/components/GtRadio";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";

export const GroupSelectionRow = connect<{
  checked: boolean;
  description?: string;
  disabled?: boolean;
  evenRow: boolean;
  name: string;
  onClick: () => void;
  type: string;
}>(({ checked, description, disabled = false, evenRow, name, onClick }) => {
  const bg = evenRow ? "#7d91aa" : "#7789a2";
  return (
    <Layout
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      alignMiddle
      background={bg}
      margin={0}
      padding={2}
      row
      style={{ cursor: disabled ? "auto" : "pointer" }}
    >
      <GtRadio checked={checked} disabled={disabled} />
      <Layout alignMiddle column height={30}>
        <Text
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color="#000"
          nowrap
          size={14}
        >
          {name}
        </Text>
        {description && (
          <Text
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color="#474747"
            nowrap
            size={10}
          >
            {description}
          </Text>
        )}
      </Layout>
      <Layout grow />
    </Layout>
  );
});
