import { Channel } from "src/lib/modules/Channel";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { ChannelDelta } from "src/lib/types/ChannelDelta";

const log = Logger.getLogger("ChannelModule");
/**
 * Returned from <code>setupChannelModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class ChannelModule {
  /**
   * The max number of simultaneously auto-joined channels.
   * @member {Number}
   */
  public maxAutojoinedChannels: number;
  /**
   * The max number of simultaneously joined channels.
   * @member {Number}
   */
  public maxJoinedChannels: number;
  /**
   * The max number of simultaneously scanned channels.
   * @member {Number}
   */
  public maxScannedChannels: number;
  /**
   * <code>true</code> if channel join (and auto-join) settings may be changed.
   * @member {boolean}
   */
  public mayManageJoinChannels: boolean;
  /**
   * <code>true</code> if channel scan settings may be changed.
   * @member {boolean}
   */
  public mayManageScanChannels: boolean;
  private onChannelDelta?: (channelDelta: ChannelDelta) => void;
  private subscriptionId?: number;
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      maxAutojoinedChannels: number;
      maxJoinedChannels: number;
      maxScannedChannels: number;
      mayManageJoinChannels: boolean;
      mayManageScanChannels: boolean;
    }
  ) {
    this.maxJoinedChannels = options.maxJoinedChannels;
    this.maxScannedChannels = options.maxScannedChannels;
    this.maxAutojoinedChannels = options.maxAutojoinedChannels;
    this.mayManageJoinChannels = options.mayManageJoinChannels;
    this.mayManageScanChannels = options.mayManageScanChannels;
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      requestedMaxAutoJoined?: number;
      requestedMaxJoined?: number;
      requestedMaxScanned?: number;
      resetToDefault?: boolean;
    } = {}
  ): Promise<ChannelModule> {
    const {
      requestedMaxAutoJoined = 100,
      requestedMaxJoined = 100,
      requestedMaxScanned = 0,
      resetToDefault = false,
    } = options;
    const response = (await requestManager.send({
      channel: {
        setupRequest: {
          resetToDefaultChannels: resetToDefault,
          suggestedMaxAutojoinedChannels: requestedMaxAutoJoined,
          suggestedMaxJoinedChannels: requestedMaxJoined,
          suggestedMaxScannedChannels: requestedMaxScanned,
          supportsTwilioFullDuplex: true,
        },
      },
    })) as proto.IChannelModuleSetupResponse;
    log.debug("Channel module setup response: ", response);
    const {
      maxAutojoinedChannels,
      maxJoinedChannels,
      maxScannedChannels,
      mayManageChannels,
      mayManageJoinChannels,
      mayManageScanChannels,
    } = response;
    return new ChannelModule(requestManager, {
      maxAutojoinedChannels,
      maxJoinedChannels,
      maxScannedChannels,
      mayManageJoinChannels:
        mayManageJoinChannels ?? mayManageChannels ?? false,
      mayManageScanChannels:
        mayManageScanChannels ?? mayManageChannels ?? false,
    });
  }
  /**
   * Leave all channels of specified types.
   * @param {boolean} joined If set to <code>true</code> joined channels are unjoined.
   * @param {boolean} scanned If set to <code>true</code> scanned channels are unjoined.
   * @param {boolean} autojoined If set to <code>true</code> auto joined channels are unjoined.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async leaveAll(
    joined: boolean,
    scanned: boolean,
    autojoined: boolean
  ): Promise<void> {
    await this.requestManager.send({
      channel: { leaveAllRequest: { autojoined, joined, scanned } },
    });
  }
  public onRequest(
    message: proto.IChannelAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.channelListDelta) {
      this.onChannelListDelta(message.channelListDelta, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Quickselects the select channel asynchronously. Can only be used if max one channel is used.
   * @param {Number} quickSelectNumber Quick select number. Must be an integer.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async quickSelectChannel(quickSelectNumber: number): Promise<void> {
    await this.requestManager.send({
      channel: { quickSelectRequest: { quickSelectNumber } },
    });
  }
  /**
   * Step channel among quick select list. Can only be used if max one channel is used.
   * @param {Number} numSteps Number of steps (positive or negative) to step. Must be an integer.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async quickSelectStep(numSteps: number): Promise<void> {
    await this.requestManager.send({
      channel: { quickSelectStepRequest: { numSteps } },
    });
  }
  /**
   * Subscribe to channel list and settings. Note that if an additional
   * subscription is made, the old is invalidated.
   * @param {function(ChannelDelta)} onChannelDelta Callback when channel list or
   * settings is changed.
   * @returns {Promise<Array<Channel>>} The list of available channels and their settings.
   */
  public async subscribe(
    onChannelDelta: (channelDelta: ChannelDelta) => void
  ): Promise<Channel[]> {
    this.onChannelDelta = onChannelDelta;
    const response = (await this.requestManager.send({
      channel: { subscribeRequest: {} },
    })) as proto.ChannelSubscribeResponse;
    this.subscriptionId = longToNumber(response.subscriptionId);
    return response.initialChannels
      ? response.initialChannels.map(
          (channel) => new Channel(this.requestManager, channel)
        )
      : [];
  }
  /**
   * Unsubscribes from the channel updates
   * @returns {Promise} Resolves when the unsubscription is completed
   */
  public async unsubscribe(): Promise<void> {
    this.subscriptionId = undefined;
    this.onChannelDelta = undefined;
    await this.requestManager.send({ channel: { unsubscribeRequest: {} } });
  }
  /**
   * @param {string} channelId channelId of the channel to be updated;
   * @param {boolean} autoJoined <code>true</code> if this channel should be auto-joined;
   * <code>false</code> if this channel should not be auto-joined.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateAutoJoined(
    channelId: string,
    autoJoined: boolean
  ): Promise<void> {
    if (autoJoined) {
      await this.requestManager.send({
        channel: { startAutojoinRequest: { channelId } },
      });
    } else {
      await this.requestManager.send({
        channel: { stopAutojoinRequest: { channelId } },
      });
    }
  }
  /**
   * @param {string} channelId channelId of the channel to be updated;
   * @param {boolean} joined <code>true</code> if this channel should be joined;
   * <code>false</code> if this channel should be left.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateJoined(channelId: string, joined: boolean): Promise<void> {
    if (joined) {
      await this.requestManager.send({
        channel: { joinRequest: { channelId } },
      });
      log.debug(`Channel ${channelId} joined.`);
    } else {
      await this.requestManager.send({
        channel: { leaveRequest: { channelId } },
      });
      log.debug(`Left channel ${channelId}.`);
    }
  }
  /**
   * @param {string} channelId channelId of the channel to be updated;
   * @param {boolean} scanned <code>true</code> if this channel should be scanned;
   * <code>false</code> if this channel should not be scanned.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateScanned(
    channelId: string,
    scanned: boolean
  ): Promise<void> {
    if (scanned) {
      await this.requestManager.send({
        channel: { startScanRequest: { channelId } },
      });
    } else {
      await this.requestManager.send({
        channel: { stopScanRequest: { channelId } },
      });
    }
  }
  private onChannelListDelta(
    channelListDelta: proto.IChannelListDelta,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onChannelDelta) {
      const { deletedChannelIds, subscriptionId, updated } = channelListDelta;
      if (longToNumber(subscriptionId) === this.subscriptionId) {
        const updatedOrNew = updated
          ? updated.map((channel) => new Channel(this.requestManager, channel))
          : undefined;
        this.onChannelDelta({
          deletedChannelIds: deletedChannelIds ?? undefined,
          updatedOrNew,
        });
      } else {
        log.warn("Channel list delta received with incorrect subscription id");
      }
    }
  }
}
