export enum Page {
  CodecSettings = "CodecSettings",
  Map = "Map",
  MuteGroups = "MuteGroups",
  NotificationSounds = "NotificationSounds",
  PrivateCalls = "PrivateCalls",
  Queues = "Queues",
  Reset = "Reset",
  SoundInput = "SoundInput",
  UsbButtons = "UsbButtons",
}
