import { gql } from "src/app/graphql";
import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { Constants } from "src/app/model/Constants";
import { LocationData } from "src/app/model/location/LocationData";
import { MessageChannel } from "src/app/model/messages/MessageChannel";
import { Patch } from "src/app/model/patches/Patch";
import { Sessions } from "src/app/model/sessions/Sessions";
import { StringSetting } from "src/app/model/settings/StringSetting";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { reaction, action } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";
import type { Presence } from "src/app/model/presence/Presence";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { MapBounds } from "src/app/types/MapBounds";
import type { PanelData } from "src/app/types/PanelData";
import type { TabColor } from "src/app/types/TabColor";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";

const log = Logger.getLogger("ChannelPanelsData");

type Parameters = {
  bounds: MapBounds;
  channelId: ChannelUuid | null;
  groupTab: ChannelTabSetting;
  showLatestSpeaker: boolean;
  showPttButton: boolean;
  showReplayButton: boolean;
  showVolumeControls: boolean;
  storedLayer: string;
};

export class ChannelPanelData implements PanelData {
  public channel?: Channel;
  public readonly filterSetting: StringSetting;
  public locationData?: LocationData;
  public maxNumberOfPresenceToDisplay = 50; // the maximum number of presence to display in the GUI
  public messageChannel?: MessageChannel;
  private disposeChannel?: IReactionDisposer;
  private disposeLocation?: IReactionDisposer;
  private groupSelectionUnsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    this.filterSetting = new StringSetting({
      defaultValue: "",
      key: `gt2.filter.${id}`,
    });
    observableClass(this);
  }
  public get badgeNumber(): number {
    return this.messageChannel ? this.messageChannel.unread : 0;
  }
  public get defaultParameters(): Parameters {
    return {
      bounds: {},
      channelId: null,
      groupTab: ChannelTabSetting.Messages,
      showLatestSpeaker: true,
      showPttButton: true,
      showReplayButton: true,
      showVolumeControls: true,
      storedLayer: this.state.settings.map.storedLayer.selected.id as string,
    };
  }
  public filterPresence(presence: Presence[]): {
    moreElements: number;
    presence: Presence[];
  } {
    const filteredPresence = presence
      .sort((a, b) => {
        if (a.alarmActive && !b.alarmActive) {
          return -1;
        }
        if (!a.alarmActive && b.alarmActive) {
          return 1;
        }
        return 0;
      })
      .filter((p) => {
        return (
          p.alarmActive ||
          p.name.toLowerCase().includes(this.filterSetting.value.toLowerCase())
        );
      })
      .slice(0, this.maxNumberOfPresenceToDisplay);

    return {
      moreElements:
        filteredPresence.length < presence.length
          ? presence.length - filteredPresence.length
          : 0,
      presence: filteredPresence,
    };
  }
  public get groupTab(): ChannelTabSetting {
    return this.parameters.groupTab;
  }
  public get groupTabIsEnabled() {
    return (id: ChannelTabSetting): boolean => {
      switch (id) {
        case ChannelTabSetting.Messages:
          return this.messageChannel?.hasMessaging ?? false;
        case ChannelTabSetting.Presence:
          return this.channel?.session !== undefined ?? false;
        case ChannelTabSetting.Locations:
          return !!(
            this.channel?.session != null &&
            this.state.online &&
            this.state.online.location.maySubscribe &&
            this.locationData
          );
        default:
          return false;
      }
    };
  }
  public get mayToggleShowLatestSpeaker(): boolean {
    return this.panel.mayConfigure(["showLatestSpeaker"]);
  }
  public get mayToggleShowPttButton(): boolean {
    return this.panel.mayConfigure(["showPttButton"]);
  }
  public get mayToggleShowReplayButton(): boolean {
    return this.panel.mayConfigure(["showReplayButton"]);
  }
  public get mayToggleShowVolumeControls(): boolean {
    return this.panel.mayConfigure(["showVolumeControls"]);
  }
  public get messagingVisible(): boolean {
    return this.panel.visible && this.groupTab === ChannelTabSetting.Messages;
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get showLatestSpeaker(): boolean {
    return !!this.parameters.showLatestSpeaker;
  }
  public get showPttButton(): boolean {
    return !!this.parameters.showPttButton;
  }
  public get showReplayButton(): boolean {
    return !!this.parameters.showReplayButton;
  }
  public get showVolumeControls(): boolean {
    return !!this.parameters.showVolumeControls;
  }
  public get tabColor(): TabColor | undefined {
    return this.channel && this.channel.patchColor !== null
      ? {
          background: this.channel.patchColor,
          textColor: "#444",
        }
      : undefined;
  }
  public onCreate(): void {
    log.debug("Creating channel panel data");
    this.disposeLocation = reaction(
      () => (this.channel ? this.channel.entityId : null),
      (entityId, oldEntityId) => {
        if (oldEntityId != null && entityId !== oldEntityId) {
          void this.locationData?.onDelete();
          this.locationData = undefined;
        }
        if (entityId != null) {
          log.debug(`Creating location data with entityId id: ${entityId}`);
          this.locationData = new LocationData(
            this.state,
            this.panel,
            entityId,
            this.channel!.name!
          );
        }
      },

      { fireImmediately: true }
    );
    this.disposeChannel = reaction(
      () => this.channelId,
      (channelId, oldChannelId) => {
        if (oldChannelId != null && channelId !== oldChannelId) {
          this.channel = undefined;
          this.messageChannel?.onDelete();
          this.messageChannel = undefined;
        }
        if (channelId != null) {
          void this.setupChannel(channelId);
        }
      },
      { fireImmediately: true }
    );
  }
  public onDelete(): void {
    log.debug("Deleting channel panel data");
    this.disposeLocation?.();
    this.disposeLocation = undefined;
    this.disposeChannel?.();
    this.disposeChannel = undefined;
    this.messageChannel?.onDelete();
    this.messageChannel = undefined;
    void this.locationData?.onDelete();
    this.locationData = undefined;
    this.groupSelectionUnsubscriber?.();
    this.groupSelectionUnsubscriber = undefined;
  }
  public setGroupTab(id: ChannelTabSetting): void {
    if (this.groupTabIsEnabled(id)) {
      this.panel.setData(["groupTab"], id);
    } else {
      this.setEnabledGroupTab();
    }
  }
  public toggleShowLatestSpeaker(): void {
    this.panel.setData(["showLatestSpeaker"], !this.showLatestSpeaker);
  }
  public toggleShowPttButton(): void {
    this.panel.setData(["showPttButton"], !this.showPttButton);
  }
  public toggleShowReplayButton(): void {
    this.panel.setData(["showReplayButton"], !this.showReplayButton);
  }
  public toggleShowVolumeControls(): void {
    this.panel.setData(["showVolumeControls"], !this.showVolumeControls);
  }
  private get channelId(): ChannelUuid | undefined {
    if (!this.state.online) {
      return undefined;
    }
    return this.parameters.channelId || Sessions.channelFocus;
  }
  private setEnabledGroupTab(): void {
    const enabledId = [
      ChannelTabSetting.Messages,
      ChannelTabSetting.Presence,
      ChannelTabSetting.Locations,
    ].find((id) => this.groupTabIsEnabled(id));
    if (enabledId) {
      this.panel.setData(["groupTab"], enabledId);
    }
  }
  private async setupChannel(channelId: ChannelUuid): Promise<void> {
    log.debug(`Fetching channel data from id: ${channelId}`);
    const channel = await this.state.online?.channels.channelWithUuid(
      channelId
    );
    if (channel) {
      if (channel.hasMessaging) {
        log.debug(`Creating message channel with uuid: ${channelId}`);
        this.messageChannel = new MessageChannel(
          this.state,
          this.panel,
          channelId
        );
      }
      this.channel = channel;
      this.groupSelectionUnsubscriber?.();
      this.groupSelectionUnsubscriber = undefined;
      this.subscribeToGroupSelection();
    }
  }
  private subscribeToGroupSelection(): void {
    this.groupSelectionUnsubscriber =
      this.state.online?.subscriptionModule?.subscribe({
        onEvent: action((msg: any) => {
          void (async () => {
            if (this.channelId === undefined) {
              return;
            }
            if (msg.type === "ClientPTTSelectionChange") {
              const { updatedSelections } = msg.payload;
              if (
                updatedSelections.length > 0 &&
                updatedSelections.includes(this.channelId)
              ) {
                const channel =
                  await this.state.online?.channels.channelWithUuid(
                    this.channelId
                  );
                this.channel = channel;
              } else {
                log.warn(
                  `Group selection changed but payload did not contain active channel: ${this.channelId}`
                );
              }
            } else if (msg.type === "GroupLockStateChange") {
              const { groupId, lock } = msg.payload;
              if (groupId === this.channelId) {
                this.channel!.lockStatus = lock;
              } else {
                log.warn(
                  `Lock changed but payload id ${groupId} is not the same as active channel: ${this.channelId}`
                );
              }
            } else if (msg.type === "GroupRemovedFromPatch") {
              log.debug("Group patch removed", msg.payload);
              const { groupId } = msg.payload;
              if (groupId === this.channelId) {
                this.channel!.patch = null;
              }
            } else if (msg.type === "GroupAddedToPatch") {
              log.debug("Group patch added", msg.payload);
              const { groupId, patchId } = msg.payload;
              if (groupId === this.channelId) {
                this.channel!.patch = new Patch(this.state, { id: patchId });
              }
            }
          })();
        }),
        onSetupSubscription: async (webSocketId) => {
          if (!this.channel) {
            return null;
          }
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
              mutation subscribeGroupChanges(
                $groupFilter: GroupFilter!,
                $profile: String,
                $webSocketId: String!
              ) {
                subscribeGroupChanges(input: {
                  groupFilter: $groupFilter,
                  profile: $profile,
                  webSocketId: $webSocketId
                }) {
                  subscriptionId
                }
              }
            `),
            variables: {
              groupFilter: {
                allGroups: false,
                groupIds: [this.channel.channelUuid],
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeGroupChanges.subscriptionId;
        },
        onTearDownSubscription: (subscriptionId) => {
          try {
            void this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
              mutation unsubscribeGroupChanges(
                $subscriptionId: ID!
              ) {
                unsubscribeGroupChanges(input: {
                  subscriptionId: $subscriptionId
                }) {
                  error {
                    __typename
                    ... on AuthorizationError {
                        message
                    }
                  }
                }
              }
            `),
              variables: { subscriptionId },
            });
          } catch (e) {
            log.warn(`Unable to unsubscribe from group selection: ${e}`);
          }
        },
      });
  }
}
