import { observableClass } from "src/app/state/observableClass";
import type { State } from "src/app/model/State";
import type { DeviceLocation as NodeDeviceLocation } from "src/lib/types/DeviceLocation";
import type { Location } from "src/lib/types/Location";
import type { Status } from "src/lib/types/Status";

export class DeviceLocation {
  public readonly userId: string;
  public latestLocation: Location | undefined;
  public name: string | undefined;
  public online: boolean | undefined;
  public status: Status | undefined;
  public title: string | undefined;
  public userUuid: string | undefined;
  public constructor(private readonly state: State, entry: NodeDeviceLocation) {
    this.userId = entry.userId;
    this.name = entry.name;
    this.title = entry.title;
    this.latestLocation = entry.latestLocation;
    this.userUuid = entry.userUuid;
    this.online = entry.online;
    observableClass(this);
  }
  public get hasEmergencyTicket(): boolean {
    return (
      this.userUuid !== undefined &&
      !!this.state.online?.queueManagement.userHasEmergencyTicket(this.userUuid)
    );
  }
  public updateWithEntry(entry: NodeDeviceLocation): void {
    if (entry.latestLocation !== undefined) {
      this.latestLocation = entry.latestLocation;
    }
    if (entry.userUuid !== undefined) {
      this.userUuid = entry.userUuid;
    }
  }
}
