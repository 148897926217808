import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { connect } from "src/app/state/connect";

export const PrivateCall = connect((_, state) => {
  const { monitoringCallsInSolo, privateCallsInSolo } =
    state.settings.privateCall;
  return (
    <Layout column width={500}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          Private Calls
        </Text>
      </Layout>
      <BooleanSetting
        label="Start private calls in solo mode"
        setting={privateCallsInSolo}
      />
      <BooleanSetting
        label="Start monitoring sessions in solo mode"
        setting={monitoringCallsInSolo}
      />
      <Layout marginBottom={16} marginTop={10}>
        <Text color="#080808" weight={300}>
          In solo mode, other sessions will be muted during the call.
        </Text>
      </Layout>
    </Layout>
  );
});
