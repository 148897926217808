import { LocationEnquirySubscription } from "src/lib/modules/LocationEnquirySubscription";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { DeviceLocation } from "src/lib/types/DeviceLocation";
import type { DeviceLocationDelta } from "src/lib/types/DeviceLocationDelta";
import type { Location } from "src/lib/types/Location";
import type { LocationRange } from "src/lib/types/LocationRange";

const log = Logger.getLogger("LocationEnquiryModule");
/**
 * Returned from <code>setupLocationEnquiryModule</code> of
 * <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class LocationEnquiryModule {
  public readonly hintLocatableUsers: boolean;
  private subscriptions: Record<string, LocationEnquirySubscription> = {};
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      hintLocatableUsers: boolean;
    }
  ) {
    /**
     * <code>true</code> if there are locatable users; otherwise, <code>false</code>.
     * @member {boolean}
     */
    this.hintLocatableUsers = options.hintLocatableUsers;
  }
  public static fromProtoLocation(protoLocation: proto.ILocation): Location {
    return {
      accuracy: protoLocation.accuracy ?? undefined,
      altitude: protoLocation.altitude ?? undefined,
      bearing: protoLocation.bearing ?? undefined,
      latitude: protoLocation.latitude,
      longitude: protoLocation.longitude,
      speed: protoLocation.speed ?? undefined,
      time: longToNumber(protoLocation.time),
    };
  }
  public static async setup(
    requestManager: RequestManager
  ): Promise<LocationEnquiryModule> {
    const response = (await requestManager.send({
      locationEnquiry: { setupRequest: {} },
    })) as proto.LocationEnquiryModuleSetupResponse;
    log.debug("locationEnquiry module setup.", response);
    return new LocationEnquiryModule(requestManager, {
      hintLocatableUsers: response.hintLocatableUsers,
    });
  }
  public onDisconnect(): void {
    Object.values(this.subscriptions).forEach((subscription) => {
      subscription.unsubscriber();
    });
  }
  public onEnquiryDelta(
    message: proto.ILocationEnquiryDelta,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    const subcsription =
      this.subscriptions[longToNumber(message.subscriptionId)];
    if (subcsription) {
      const updatedOrNew = message.updatedLocations
        ? LocationEnquiryModule.fromProtoLocationEnquiryDeviceUpdate(
            message.updatedLocations
          )
        : undefined;
      subcsription.onDelta({
        removedUserIds: message.removedUserIds ?? undefined,
        updatedOrNew,
      });
    } else {
      // This happens when closing a ticket at the same time as closing down location subscription.
      log.debug(
        "Location delta received to unknown or unsubscribed subscription"
      );
    }
  }
  public onRequest(
    message: proto.ILocationEnquiryAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.enquiryDelta) {
      this.onEnquiryDelta(message.enquiryDelta, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Subscribe to locations with a filter of entities and range. You can make multiple
   * subscriptions.
   * @param {Object} parameters
   * @param {Array<string>} [parameters.entityIds] List of entities to subscribe on. Entity may be
   * user, group, queue entry etc. If no entities are specified, all devices in the company are
   * subscribed upon. <code>(optional)</code>
   * @param {LocationRange} [parameters.range] Geographical range of which we are interested of
   * updates. No range means whole world. <code>(optional)</code>
   * @param {function(DeviceLocationDelta)} parameters.onDelta
   * Callback when new location data or updated device parameters are available or when a device
   * no longer matches the filter.
   * @returns {Promise<LocationEnquirySubscription>} A subscription handle which can be used
   * to update the filter or unsubscribe.
   */
  public async subscribe(options: {
    entityIds?: string[];
    onDelta: (delta: DeviceLocationDelta) => void;
    range?: LocationRange;
  }): Promise<LocationEnquirySubscription> {
    const { entityIds = [], onDelta, range } = options;
    const response = (await this.requestManager.send({
      locationEnquiry: { subscribeRequest: { filter: { entityIds, range } } },
    })) as proto.ILocationEnquirySubscribeResponse;
    const initialDelta = response.initialDelta;
    const subscriptionId = longToNumber(initialDelta.subscriptionId);
    const updatedOrNew = initialDelta.updatedLocations
      ? LocationEnquiryModule.fromProtoLocationEnquiryDeviceUpdate(
          initialDelta.updatedLocations
        )
      : undefined;
    onDelta({
      removedUserIds: initialDelta.removedUserIds ?? undefined,
      updatedOrNew,
    });
    const subscription = new LocationEnquirySubscription({
      onDelta,
      requestManager: this.requestManager,
      subscriptionId: longToNumber(subscriptionId),
      unsubscriber: () => {
        subscription.onUnsubscribed();
        delete this.subscriptions[subscriptionId];
      },
    });
    this.subscriptions[subscriptionId] = subscription;
    return subscription;
  }
  private static fromProtoLocationEnquiryDeviceUpdate(
    updatedDevices: proto.ILocationEnquiryLocationUpdate[]
  ): DeviceLocation[] {
    return updatedDevices.map((updatedDevice) => {
      return {
        latestLocation: updatedDevice.location
          ? LocationEnquiryModule.fromProtoLocation(updatedDevice.location)
          : undefined,
        name: updatedDevice.info?.name,
        online: updatedDevice.online ?? undefined,
        title: updatedDevice.info?.title ?? undefined,
        userEntityId: updatedDevice.info?.userEntityId ?? undefined,
        userId: updatedDevice.userId,
        userUuid: updatedDevice.info?.userUuid ?? undefined,
      };
    });
  }
}
