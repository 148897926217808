import { CreateGtTable } from "src/app/gui/components/CreateGtTable";
import { Layout } from "src/app/gui/components/Layout";
import { TicketColumns } from "src/app/gui/panels/tickets/TicketColumns";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";
import type { Ticket } from "src/app/model/queues/Ticket";

const color = (
  ticket: Ticket
):
  | {
      dark: string;
      light: string;
    }
  | undefined => {
  if (ticket.isEmergency) {
    return { dark: "#d13e3e", light: "#f25555" };
  }
  if (ticket.isHighPriority) {
    return { dark: "#c19f60", light: "#c9aa70" };
  }
  return undefined; // default
};

const GtTable = CreateGtTable<Ticket>();

export const Tickets = connect<{
  panel: Panel;
}>(({ panel }, state) => {
  const list = state.online?.queueManagement.sortedTicketList(panel) ?? [];
  const { showColumn } = panel.queuePanelData!;

  return (
    <Layout className="custom-scroll" column grow style={{ overflow: "auto" }}>
      <GtTable
        columns={TicketColumns.filter((column) => showColumn(column.id))}
        customData={panel.queuePanelData}
        getRowColor={(ticket) => color(ticket)}
        getRowKey={(ticket) => ticket.queueEntryEntityId}
        rows={list}
      />
    </Layout>
  );
});
