import { TalkburstTransmission } from "src/lib/modules/TalkburstTransmission";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("TalkburstTransmissionModule");

/**
 * Returned from <code>setupTalkburstTransmissionModule</code> of
 * @private
 * <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class TalkburstTransmissionModule {
  private nextTalkburstId = 0;
  private talkbursts: Record<number, TalkburstTransmission> = {};
  private constructor(private readonly requestManager: RequestManager) {}
  public static async setup(
    requestManager: RequestManager
  ): Promise<TalkburstTransmissionModule> {
    const response = await requestManager.send({
      talkburstTransmission: { setupRequest: {} },
    });
    log.debug("talkburstTransmission module setup.", response);
    return new TalkburstTransmissionModule(requestManager);
  }
  public onRequest(
    message: proto.ITalkburstTransmissionAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.interrupted) {
      this.onInterrupted(message.interrupted, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  /**
   * Start a new transmission.
   * @param {Number} sessionId Which session to start transmission in.
   * @param {string} muteGroupId Hint to users with the same muteGroupId to mute this
   * talkburst to avoid feedback loops.
   * @param {function()} onInterrupt Callback on interrupt which can happen for example if a client
   * with higher priority send simultanously.
   * @param {function()} onStopped Callback when talkburst is stopped (for any reason).
   * @returns {Promise<TalkburstTransmission>}
   */
  public async startTransmission(
    sessionId: number,
    muteGroupId: string | undefined,
    onInterrupt: () => void,
    onStopped: () => Promise<void>
  ): Promise<TalkburstTransmission | null> {
    const talkburstId = this.nextTalkburstId;
    this.nextTalkburstId += 1;

    try {
      await this.requestManager.send({
        talkburstTransmission: {
          startRequest: {
            muteGroupId,
            sessionId,
            talkburstId,
          },
        },
      });
      const onStoppedInternal = (): void => {
        if (this.talkbursts[talkburstId]) {
          delete this.talkbursts[talkburstId];
        }
      };
      const talkburst = new TalkburstTransmission(this.requestManager, {
        onInterrupt,
        onStopped: async () => {
          onStoppedInternal();
          if (onStopped) {
            await onStopped();
          }
        },
        talkburstId,
      });
      this.talkbursts[talkburstId] = talkburst;
      return talkburst;
    } catch (error: any) {
      log.warn(error);
    }
    return null;
  }
  private onInterrupted(
    message: proto.ITalkburstTransmissionInterrupted,
    respond: (code: proto.ResponseCode) => void
  ): void {
    const talkburstId = longToNumber(message.talkburstId);
    const talkburst = this.talkbursts[talkburstId];
    if (talkburst) {
      respond(proto.ResponseCode.OK);
      if (talkburst.onInterrupt) {
        talkburst.onInterrupt();
      }
      if (talkburst.onStopped) {
        void talkburst.onStopped();
      }
    } else {
      respond(proto.ResponseCode.NOT_FOUND);
    }
  }
}
