import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Missing refresh token
 */
export class MissingRefreshToken extends GroupTalkAPIError {
  public constructor() {
    super("Missing refresh token.", false);
  }
}
