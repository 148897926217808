import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Not found.
 */
export class NotFound extends GroupTalkAPIError {
  public constructor() {
    super("Not found.", false);
  }
}
