import {
  DialedInIcon,
  DispatchIcon,
  PatchIcon,
  QuestionMarkIcon,
  RadioIcon,
  UserIcon,
} from "src/app/images";
import { PresenceType } from "src/lib/types/PresenceType";

export const PresenceIcon = ({
  presenceType = PresenceType.Unknown,
  style,
}: {
  presenceType?: PresenceType;
  style: React.CSSProperties;
}): JSX.Element =>
  ({
    [PresenceType.Dialed]: <DialedInIcon style={style} />,
    [PresenceType.Dispatcher]: <DispatchIcon style={style} />,
    [PresenceType.Group]: <PatchIcon style={style} />,
    [PresenceType.Mobile]: <UserIcon style={style} />,
    [PresenceType.Radio]: <RadioIcon style={style} />,
    [PresenceType.Unknown]: <QuestionMarkIcon style={style} />,
  }[presenceType]);
