import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { CodecSettings } from "src/app/model/settings/CodecSettings";
import { GroupSelectionSetting } from "src/app/model/settings/GroupSelectionSetting";
import { MapSettings } from "src/app/model/settings/MapSettings";
import { MuteGroupsSettings } from "src/app/model/settings/MuteGroupsSettings";
import { NotificationSoundsSettings } from "src/app/model/settings/NotificationSoundsSettings";
import { Page } from "src/app/model/settings/Page";
import { PrivateCallSettings } from "src/app/model/settings/PrivateCallSettings";
import { QueueSettings } from "src/app/model/settings/QueueSettings";
import { SoundInputSettings } from "src/app/model/settings/SoundInputSettings";
import { observableClass } from "src/app/state/observableClass";
import { action } from "mobx";
import mobxLocalStorage from "mobx-localstorage";
import type { State } from "src/app/model/State";

export class Settings {
  public readonly codecSettings: CodecSettings;
  public readonly groupSelectionSettings: GroupSelectionSetting;
  public readonly map: MapSettings;
  public readonly muteGroups: MuteGroupsSettings;
  public readonly notificationSounds: NotificationSoundsSettings;
  public readonly privateCall: PrivateCallSettings;
  public readonly queues: {
    emergency: QueueSettings;
    normal: QueueSettings;
    prio: QueueSettings;
    showTicketMenu: BooleanSetting;
  };
  public readonly soundInput: SoundInputSettings;
  public page: Page = Page.SoundInput;
  public showing = false;
  public constructor(private readonly state: State) {
    this.soundInput = new SoundInputSettings();
    this.codecSettings = new CodecSettings();
    this.notificationSounds = new NotificationSoundsSettings();
    this.privateCall = new PrivateCallSettings();
    this.muteGroups = new MuteGroupsSettings();
    this.map = new MapSettings();
    this.queues = {
      emergency: new QueueSettings("emergency", "alert-1"),
      normal: new QueueSettings("normal", "alert-0"),
      prio: new QueueSettings("prio", "alert-3"),
      showTicketMenu: new BooleanSetting({
        defaultValue: true,
        key: "gt2.settings.queues.showTicketMenu",
      }),
    };
    this.groupSelectionSettings = new GroupSelectionSetting(this.state);
    observableClass(this);
  }
  public hide(): void {
    this.showing = false;
  }
  public resetLayout(): void {
    this.state.layout.reset();
    this.state.panels.reset();
    this.state.flashMessage.success({
      icon: "reset",
      message: "Panels reset",
    });
  }
  public resetUserSettings(): void {
    Object.entries(localStorage)
      .map((x) => x[0])
      .filter(
        (x) =>
          x.startsWith("gt2.settings") ||
          x.startsWith("gt2.mutegroup") ||
          x.startsWith("gt2.buttonmapping")
      )
      .forEach((x) => {
        mobxLocalStorage.removeItem(x);
      });
    // This will update the entire settings resetting all temporary values etc.
    setTimeout(
      action(() => {
        const { page } = this;
        this.state.settings = new Settings(this.state);
        this.state.settings.showing = true;
        this.state.settings.page = page;
        this.state.flashMessage.success({
          icon: "reset",
          message: "Settings reset",
        });
      }),
      1
    );
  }
  public setPage(page: Page): void {
    this.page = page;
  }
  public show(): void {
    this.showing = true;
  }
}
