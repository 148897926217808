import type { Resampler } from "src/audio/resampler/Resampler";

/**
 * Generic linear interpolation
 * @private
 */
export class Upsampler implements Resampler {
  private lastOutput = 0;
  private lastWeight = 1;
  public constructor(private readonly ratioWeight: number) {}
  public resample(buffer: Float32Array): Float32Array {
    const outputBuffer = [];
    let bufferLength = buffer.length;
    if (bufferLength > 0) {
      let sourceOffset;
      let weight = this.lastWeight;
      let firstWeight = 0;
      let secondWeight = 0;
      let outputOffset = 0;
      for (; weight < 1; weight += this.ratioWeight) {
        secondWeight = weight % 1;
        firstWeight = 1 - secondWeight;
        outputBuffer[outputOffset] =
          this.lastOutput * firstWeight + buffer[0] * secondWeight;
        outputOffset += 1;
      }
      weight -= 1;
      for (
        bufferLength -= 1, sourceOffset = Math.floor(weight);
        sourceOffset < bufferLength;

      ) {
        secondWeight = weight % 1;
        firstWeight = 1 - secondWeight;
        outputBuffer[outputOffset] =
          buffer[sourceOffset] * firstWeight +
          buffer[sourceOffset + 1] * secondWeight;
        outputOffset += 1;
        weight += this.ratioWeight;
        sourceOffset = Math.floor(weight);
      }
      this.lastOutput = buffer[sourceOffset];

      this.lastWeight = weight % 1;
      return new Float32Array(outputBuffer.slice(0, outputOffset));
    }
    return new Float32Array(0);
  }
}
