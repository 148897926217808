import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { LocationEnquiryModule } from "src/lib/modules/LocationEnquiryModule";
import type { LocationEnquirySubscription } from "src/lib/modules/LocationEnquirySubscription";
import type { DeviceLocationDelta } from "src/lib/types/DeviceLocationDelta";
import type { LocationRange } from "src/lib/types/LocationRange";

const log = Logger.getLogger("locationSubscriber");

export class LocationSubscriber {
  private locationEnquireModule: LocationEnquiryModule | null;
  public constructor() {
    this.locationEnquireModule = null;
    observableClass(this);
  }
  public get maySubscribe(): boolean {
    return !!(
      this.locationEnquireModule &&
      this.locationEnquireModule.hintLocatableUsers
    );
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    const locationEnquireModule =
      await authenticatedModule.setupLocationEnquiryModule();
    log.debug(
      `Location Enquiry Module setup finished. Locatable users = ${locationEnquireModule.hintLocatableUsers}`
    );
    this.locationEnquireModule = locationEnquireModule;
  }
  public subscribe(options: {
    entityIds?: string[] | undefined;
    onDelta: (delta: DeviceLocationDelta) => void;
    range?: LocationRange | undefined;
  }): Promise<LocationEnquirySubscription> | undefined {
    if (this.maySubscribe) {
      return this.locationEnquireModule!.subscribe(options);
    }
    log.warn("Tried to subscribe to location for non-locatable users.");
    return undefined;
  }
}
