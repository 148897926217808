import { LockIcon, LockOpenIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { IconButton } from "@mui/material";
import type { Channel } from "src/app/model/channels/Channel";

export const LockButton = connect<{
  channel: Channel;
  noClick: boolean;
}>(({ channel, noClick }) => {
  if (channel.locked) {
    return (
      <IconButton
        aria-label="Unlock"
        onClick={() => !noClick && channel.updateLocked(false)}
        style={{ padding: 4 }}
      >
        <LockIcon style={{ fill: "#9a0000c7" }} />
      </IconButton>
    );
  }
  return (
    <IconButton
      aria-label="Lock"
      onClick={() => !noClick && channel.updateLocked(true)}
      style={{ padding: 4 }}
    >
      <LockOpenIcon />
    </IconButton>
  );
});
