import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { StatusMenuItems } from "src/app/gui/panels/contacts/StatusMenuItems";
import { StatusIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";

export const StatusMenu = connect<{
  entity: ContactDetails;
}>(({ entity }) => (
  <Layout alignMiddle gap={12} margin={10} marginLeft={16} row>
    <StatusIcon />
    <Text>Status:</Text>
    <Layout
      background="#fbfdfe"
      column
      grow
      marginLeft={5}
      padding={5}
      style={{ filter: "drop-shadow(0px 0px 4px #aaa)" }}
    >
      <StatusMenuItems entity={entity} />
    </Layout>
  </Layout>
));
