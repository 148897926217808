import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Invalid login or password.
 */
export class InvalidLoginOrPassword extends GroupTalkAPIError {
  public constructor() {
    super("Invalid login or password.", false);
  }
}
