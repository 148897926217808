import { SoundService } from "src/app/model/audio/SoundService";
import { ButtonFunction } from "src/app/model/input/ButtonFunction";
import { ButtonFunctions } from "src/app/model/input/ButtonFunctions";
import { Buttons } from "src/app/model/input/Buttons";
import { ListSetting } from "src/app/model/settings/ListSetting";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import localStorage from "mobx-localstorage";
import type { Button } from "src/app/model/input/Button";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";

const log = Logger.getLogger("buttonMapper");

export class ButtonMapper {
  private pressedButtons: Record<string, number> = {};
  public constructor(private readonly state: State) {
    this.pressedButtons = {};
    observableClass(this);
  }
  public get buttons() {
    return (serialNumber: string) =>
      Object.values(Buttons).map((button) => {
        const setting = ButtonMapper.buttonSetting(serialNumber, button.id);
        return {
          fixedGroupId: ButtonMapper.fixedGroupSettingId(
            serialNumber,
            button.id
          ),
          id: ButtonMapper.buttonSettingId(serialNumber, button.id),
          label: button.name,
          pressed: this.pressedButtons[`${button.id}.${serialNumber}`],
          setting,
          showGroup: setting.selected.id === ButtonFunction.PTT_FIXED_GROUP,
          showTicketPanel:
            setting.selected.id === ButtonFunction.PICK_TICKET_IN_PANEL,
          ticketPanel: this.ticketsPanelSetting(serialNumber, button.id),
          ticketPanelId: ButtonMapper.ticketsPanelSettingId(
            serialNumber,
            button.id
          ),
        };
      });
  }
  public onButtonDown(serialNumber: string, button: Button): void {
    const pressedId = `${button}.${serialNumber}`;
    this.pressedButtons[pressedId] = 1;
    const buttonFunction = ButtonMapper.buttonSetting(
      serialNumber,
      button
    ).selected;
    log.debug(`Button down: ${serialNumber} ${button} => ${buttonFunction.id}`);
    switch (buttonFunction.id) {
      case ButtonFunction.PTT_SELECTED_GROUP: {
        const session = this.state.online?.sessions.focusedSession;
        if (session) {
          session.pttWithPossibleBroadcastDown();
        }
        break;
      }
      case ButtonFunction.PTT_FIXED_GROUP: {
        const groupUuid = localStorage.getItem(
          ButtonMapper.fixedGroupSettingId(serialNumber, button)
        );
        if (groupUuid) {
          const session =
            this.state.online?.sessions.sessionWithChannelUuid(groupUuid);
          if (session) {
            session.pttWithPossibleBroadcastDown();
          } else {
            SoundService.playSound("ptt-error");
            this.state.flashMessage.info({ message: "Group not joined." });
          }
        } else {
          SoundService.playSound("ptt-error");
          this.state.flashMessage.info({ message: "Group not configured." });
        }
        break;
      }
      case ButtonFunction.PICK_NEXT_TICKET: {
        this.state.online?.queueManagement.pickNextTicket({ auto: false });
        break;
      }
      case ButtonFunction.PICK_TICKET_IN_PANEL: {
        const panel = this.ticketsPanelSetting(serialNumber, button).selected;
        this.state.online?.queueManagement.pickNextTicket({
          auto: false,
          onlyPanelId: panel?.id as ComponentId | undefined,
        });
        break;
      }
      case ButtonFunction.CLOSE_SELECTED_SESSION: {
        const session = this.state.online?.sessions.focusedSession;
        if (session) {
          void session.endCall();
        }
        break;
      }
      case ButtonFunction.TOGGLE_MUTE_OF_SELECTED_GROUP: {
        const session = this.state.online?.sessions.focusedSession;
        if (session) {
          session.setMuted(!session.configuredMute);
        }
        break;
      }
      default:
        break;
    }
  }
  public onButtonUp(serialNumber: string, button: Button): void {
    const pressedId = `${button}.${serialNumber}`;
    if (!this.pressedButtons[pressedId]) {
      return;
    }
    delete this.pressedButtons[pressedId];
    const buttonFunction = ButtonMapper.buttonSetting(
      serialNumber,
      button
    ).selected;
    log.debug(`Button up: ${serialNumber} ${button} => ${buttonFunction.id}`);
    switch (buttonFunction.id) {
      case ButtonFunction.PTT_SELECTED_GROUP: {
        const session = this.state.online?.sessions.focusedSession;
        if (session) {
          session.pttWithPossibleBroadcastUp();
        }
        break;
      }
      case ButtonFunction.PTT_FIXED_GROUP: {
        const groupUuid = localStorage.getItem(
          ButtonMapper.fixedGroupSettingId(serialNumber, button)
        );
        if (groupUuid) {
          const session =
            this.state.online?.sessions.sessionWithChannelUuid(groupUuid);
          if (session) {
            session.pttWithPossibleBroadcastUp();
          }
        }
        break;
      }
      default:
        break;
    }
  }
  private static get buttonSetting() {
    return (serialNumber: string, button: Button): ListSetting =>
      new ListSetting({
        defaultId: Buttons[button].default,
        key: ButtonMapper.buttonSettingId(serialNumber, button),
        list: Object.values(ButtonFunctions),
      });
  }
  private static get buttonSettingId() {
    return (serialNumber: string, button: Button): string =>
      `gt2.buttonmapping.category.${button}.${serialNumber}`;
  }
  private static get fixedGroupSettingId() {
    return (serialNumber: string, button: Button) =>
      `gt2.buttonmapping.fixedgroup.${button}.${serialNumber}`;
  }
  private static get ticketsPanelSettingId() {
    return (serialNumber: string, button: Button) =>
      `gt2.buttonmapping.ticketpanel.${button}.${serialNumber}`;
  }
  private get ticketsPanelSetting() {
    return (serialNumber: string, button: Button): ListSetting =>
      new ListSetting({
        key: ButtonMapper.ticketsPanelSettingId(serialNumber, button),
        list: this.state.online?.queueManagement.ticketsPanels.map(
          (ticketPanel) => ({
            id: ticketPanel.id,
            name: this.state.layout.nameOfPanel(ticketPanel.id),
          })
        ),
      });
  }
}
