import { gql } from "src/app/graphql";
import { Constants } from "src/app/model/Constants";
import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { Contacts } from "src/app/model/contacts/Contacts";
import { ContactsTabSetting } from "src/app/model/contacts/ContactsTabSetting";
import { Organizations } from "src/app/model/organizations/Organizations";
import { StringSetting } from "src/app/model/settings/StringSetting";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import { reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { ClientUserDetailed } from "src/nextgen/types/ClientUserDetailed";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { MessageChannelUuid } from "src/nextgen/types/MessageChannelUuid";
import type { Organization } from "src/nextgen/types/Organization";
import type { OrganizationUuid } from "src/nextgen/types/OrganizationUuid";

const log = Logger.getLogger("ContactsPanelData");

type Parameters = {
  contactFilterSetting: ContactsTabSetting;
  customFilter: Record<ClientUserUuid, boolean>;
  organizationFilter: OrganizationUuid | null;
  organizationFilterName: null | string;
  showDescription: boolean;
  showMessages: boolean;
  showOrganization: boolean;
};
export class ContactsPanelData {
  /**
   * Map of contactId (uuid) -> fetched contact details
   */
  public readonly contactDetails: Record<ClientUserUuid, ContactDetails> = {};
  public readonly contacts: Contacts;
  public readonly defaultParameters: Parameters = {
    contactFilterSetting: ContactsTabSetting.All,
    customFilter: {},
    organizationFilter: null,
    organizationFilterName: null,
    showDescription: true,
    showMessages: true,
    showOrganization: true,
  };
  public readonly filterSetting: StringSetting;
  public readonly organizations: Organizations;
  private disposeContacts?: IReactionDisposer;
  private messageUnsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    this.contacts = new Contacts(state);
    this.organizations = new Organizations(state, "user");
    this.filterSetting = new StringSetting({
      defaultValue: "",
      key: `gt2.filter.${id}`,
    });
    observableClass(this);
  }
  public get contactFilter(): ContactsTabSetting {
    return this.parameters.contactFilterSetting;
  }
  public get includeContact() {
    return (contactUuid: ClientUserUuid) =>
      !!this.parameters.customFilter[contactUuid];
  }
  public get mayToggleShowDescription(): boolean {
    return this.panel.mayConfigure(["showDescription"]);
  }
  public get mayToggleShowMessages(): boolean {
    return this.panel.mayConfigure(["showMessages"]);
  }
  public get mayToggleShowOrganization(): boolean {
    return this.panel.mayConfigure(["showOrganization"]);
  }
  public get organizationFilter(): OrganizationUuid | null {
    return this.parameters.organizationFilter;
  }
  public get organizationFilterName(): null | string {
    return this.parameters.organizationFilterName;
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get showDescription(): boolean {
    return !!this.parameters.showDescription;
  }
  public get showMessages(): boolean {
    return !!this.parameters.showMessages;
  }
  public get showOrganization(): boolean {
    return !!this.parameters.showOrganization;
  }
  public async fetchContactDetails(contactId: ClientUserUuid): Promise<void> {
    let contactDetails: ContactDetails;
    try {
      const data = await this.queryContactDetails(contactId);

      if (data != null) {
        contactDetails = new ContactDetails(this.state, {
          callPermission: data.permissions.call,
          email: data.email,
          entityId: data.entityId,
          fullDuplexPermission: data.permissions.fullDuplexCall,
          id: data.id,
          locatable: data.permissions.locate,
          messagePermission: data.permissions.message,
          name: data.displayName,
          online: data.onlineStatus.state === "ONLINE",
          organization: data.organization,
          phoneNumbers: data.phoneNumbers,
          status: data.currentStatus,
          statusList: data.availableStatuses,
        });
        // fetch callsign from Callsign Module and the Node API
        await contactDetails.fetchCallsign();
        this.contactDetails[contactId] = contactDetails;
      }
    } catch (err: any) {
      log.error(err);
      delete this.contactDetails[contactId];
    }
  }
  public onClosing(performClose: () => void): void {
    const numberStarredContacts = Object.keys(
      this.parameters.customFilter
    ).length;
    if (numberStarredContacts > 0) {
      this.state.dialogs.show({
        actions: [
          { label: "Cancel", onSelect: () => {} },
          {
            label: "Close panel",
            onSelect: () => {
              performClose();
            },
          },
        ],
        forceRespond: true,
        text: `This panel have ${numberStarredContacts} favorited contact${
          numberStarredContacts > 1 ? "s" : ""
        } which will be lost. Continue?`,
        title: "Confirm close panel",
      });
    } else {
      performClose();
    }
  }
  public onCreate(): void {
    this.disposeContacts = reaction(
      () => ({
        // name: this.filterSetting.value ? `%${this.filterSetting.value}%` : null,
        name: this.filterSetting.value ? `${this.filterSetting.value}` : null,
        online: this.isShowingOnlineTab,
        organizationIds: this.organizationFilter
          ? [this.organizationFilter]
          : null,
        userIds: this.customList,
      }),
      (filter) => {
        this.contacts.setFilter(filter);
      },

      { fireImmediately: true }
    );
    this.subscribeToNewMessages();
    void this.organizations.fetchOrganizations();
  }
  public onDelete(): void {
    this.disposeContacts?.();
    this.disposeContacts = undefined;
    this.messageUnsubscriber?.();
    this.messageUnsubscriber = undefined;
    this.contacts.closeDown();
  }
  public setContactFilter(id: ContactsTabSetting): void {
    if (id !== this.contactFilter) {
      this.filterSetting.setValue("");
      this.filterSetting.validate();
    }
    this.panel.setData(["contactFilterSetting"], id);
  }
  public setOrganization(organization: Organization | null): void {
    this.panel.setData(
      ["organizationFilter"],
      organization ? organization.id : null
    );
    this.panel.setData(
      ["organizationFilterName"],
      organization ? organization.name : null
    );
  }
  public toggleIncludeContact(contactId: ClientUserUuid): void {
    this.panel.setData(
      ["customFilter", contactId],
      !this.includeContact(contactId)
    );
  }
  public toggleShowDescription(): void {
    this.panel.setData(["showDescription"], !this.showDescription);
  }
  public toggleShowMessages(): void {
    this.panel.setData(["showMessages"], !this.showMessages);
  }
  public toggleShowOrganization(): void {
    this.panel.setData(["showOrganization"], !this.showOrganization);
  }
  private get customList(): ClientUserUuid[] | null {
    switch (this.contactFilter) {
      case ContactsTabSetting.Favorites:
        return Object.keys(this.parameters.customFilter);
      default:
        return null;
    }
  }
  private get isShowingOnlineTab(): boolean {
    return this.contactFilter === ContactsTabSetting.Online;
  }
  private async queryContactDetails(
    id: ClientUserUuid
  ): Promise<ClientUserDetailed | null> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserForContacts($id: ID!) {
          clientUser(id: $id) {
            id
            entityId
            displayName
            email
            phoneNumbers
            onlineStatus {
              state
            }
            organization {
              id
              name
            }
            currentStatus {
              id
              name
              backgroundColor
              foregroundColor
            }
            availableStatuses {
              ... on ClientUserStatus {
                id
                name
                backgroundColor
                foregroundColor
              }
            }
            permissions {
              locate
              call
              message
              fullDuplexCall
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser ?? null;
  }
  // Message subscription
  private subscribeToNewMessages(): void {
    this.messageUnsubscriber = this.state.online?.subscriptionModule?.subscribe(
      {
        onEvent: (msg) => {
          if (msg.type === "NewMessage") {
            // new message in a private message channel => No action required for the contacts list
          } else if (msg.type === "MetadataUpdate") {
            // MetadataUpdate => Re-fetch data if changed channel is shown
            const groupId = msg.payload.groupId as MessageChannelUuid;
            log.debug("MetadataUpdate for message channel", groupId);
            const unreadCount = msg.payload.unreadCount as number;
            if (groupId !== undefined && unreadCount !== undefined) {
              // check if any of the currently displayed contacts' message channel
              // matches the group/channel the MetadataUpdate is for, if so we need to update
              // the unreadCount for that contact.
              const contact = this.contacts.contacts.find(
                (c) => c.privateMessageChannelId === groupId
              );
              if (contact !== undefined) {
                log.debug(`Updating unreadCount for contact ${contact.name}.`);
                contact.unreadCount = unreadCount;
              }
            }
          } else if (msg.type === "PrivateMessageChannelCreated") {
            // PrivateMessageChannelCreated => Re-fetch data if participant
            // of created channel is shown
            const participants = msg.payload.participants as ClientUserUuid[];
            log.debug("Private message channel created with", participants);
            // check if the created private message channel was created for any
            // contact that is currently displayed, if so we need to refresh the
            // data to get the newly created channel
            const contact = this.contacts.contacts.find((c) =>
              participants.some((p) => p === c.id)
            );
            if (contact !== undefined && msg.payload.groupId !== undefined) {
              log.debug(`Updating groupId for contact ${contact.name}.`);
              contact.privateMessageChannelId = msg.payload.groupId;
              contact.unreadCount = 0;
            }
          } else {
            // Unknown, unhandled, possibly future event => No action
          }
        },
        onSetupSubscription: async (webSocketId) => {
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
            mutation subscribeNewMessagesToContacts(
              $webSocketId: String!,
              $profile: String!,
              $channelFilter: ChannelFilter!
            ) {
              subscribeNewMessages(input: {
                webSocketId: $webSocketId,
                profile: $profile,
                channelFilter: $channelFilter
              }) {
                subscriptionId
              }
            }
          `),
            variables: {
              channelFilter: {
                allChannelMessages: false,
                allPrivateMessages: true,
                channelIds: null,
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeNewMessages.subscriptionId;
        },
        onTearDownSubscription: (subscriptionId) => {
          try {
            void this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
              mutation unsubscribeMessages(
                $subscriptionId: ID!
              ) {
                unsubscribeMessages(input: {
                  subscriptionId: $subscriptionId
                }) {
                  error {
                    __typename
                    ... on AuthorizationError {
                        message
                    }
                  }
                }
              }
            `),
              variables: { subscriptionId },
            });
          } catch (e) {
            log.warn(`Unable to unsubscribe from messages: ${e}`);
          }
        },
      }
    );
  }
}
