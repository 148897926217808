import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { ListSetting } from "src/app/model/settings/ListSetting";
import { observableClass } from "src/app/state/observableClass";

export class MapSettings {
  public readonly resetView: BooleanSetting;
  public readonly storedLayer: ListSetting;
  public constructor() {
    this.resetView = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.map.resetView",
    });
    this.storedLayer = new ListSetting({
      defaultId: "Standard",
      key: "gt2.settings.map.storedLayer",
      list: [
        {
          id: "Standard",
          name: "Standard",
        },
        {
          id: "Satellite",
          name: "Satellite",
        },
        {
          id: "Alternative",
          name: "Alternative",
        },
      ],
    });
    observableClass(this);
  }
}
