import { CreateGtTable } from "src/app/gui/components/CreateGtTable";
import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { TicketColumns } from "src/app/gui/panels/tickets/TicketColumns";
import { connect } from "src/app/state/connect";
import type { GtTableColumn } from "src/app/gui/components/GtTableColumn";
import type { Panel } from "src/app/model/panels/Panel";
import type { Ticket } from "src/app/model/queues/Ticket";

const GtTable = CreateGtTable<GtTableColumn<Ticket>>();

export const Columns = connect<{
  panel: Panel;
}>((props) => {
  const queuePanelData = props.panel.queuePanelData!;
  return (
    <Layout gap={20} row>
      <Layout column gap={5} grow>
        <Text>Select which columns to show for tickets in this panel:</Text>
        <GtTable
          columns={[
            {
              header: "Column",
              id: "column",
              render: ({ header }) => <Text>{header}</Text>,
            },
            {
              header: "Show",
              id: "show",
              render: ({ id }) => (
                <GtCheckbox
                  checked={queuePanelData.showColumn(id)}
                  disabled={!queuePanelData.mayToggleShowColumn(id)}
                  key="show"
                />
              ),
              width: 100,
            },
          ]}
          onClickRow={(row) => {
            if (queuePanelData.mayToggleShowColumn(row.id)) {
              queuePanelData.toggleShowColumn(row.id);
            }
          }}
          getRowKey={(column) => column.id}
          rows={TicketColumns}
        />
      </Layout>
    </Layout>
  );
});
