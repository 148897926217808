import { gql } from "src/app/graphql";
import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { ClientUserForPresence } from "src/app/model/presence/ClientUserForPresence";
import { observableClass } from "src/app/state/observableClass";
import { PresenceType } from "src/lib/types/PresenceType";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import type { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import type { Panel } from "src/app/model/panels/Panel";
import type { State } from "src/app/model/State";
import type { Room } from "src/app/model/video/Room";
import type { ClientUserDetailed } from "src/nextgen/types/ClientUserDetailed";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { ParticipantInfo } from "src/nextgen/types/ParticipantInfo";
import type {
  RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteParticipant,
  RemoteTrack,
} from "twilio-video";

const log = Logger.getLogger("RoomParticipant");

export class RoomParticipant {
  public audioEnabled = false;
  public contactDetails?: ContactDetails;
  public twilioPeerAudioTrack?: RemoteAudioTrack;
  public twilioPeerVideoTrack?: RemoteVideoTrack;
  /**
   * Unread count is currently not updated - but here because of general treatment.
   */
  public unreadCount = 0;
  public videoEnabled = false;
  private participantInfo?: ParticipantInfo | null = null;
  public constructor(
    private readonly state: State,
    private readonly room: Room,
    private readonly participant: RemoteParticipant
  ) {
    observableClass(this);
  }
  /**
   * This is the GroupTalk Participant uuid which is bridged through the Twillio Access Token.
   */
  public get id(): string {
    return this.participant.identity;
  }
  public get isSpeaking(): boolean {
    return !!(
      this.room.dominantSpeaker &&
      this.room.dominantSpeaker.identity === this.id
    );
  }
  public get mayOpenAsPanel(): boolean {
    return !!this.userUuid;
  }
  public get name(): string {
    return this.participantInfo ? this.participantInfo.name : "Unknown";
  }
  /**
   * Alias for sid. Used for generic contact menu.
   */
  public get presenceId(): string {
    return this.sid;
  }
  /**
   * This is the Twillio ID that is unique per connection
   */
  public get sid(): string {
    return this.participant.sid;
  }
  public get title(): null | string {
    return this.participantInfo?.title ?? null;
  }
  public get type(): PresenceType {
    return this.participantInfo && this.participantInfo.client === "DISPATCH"
      ? PresenceType.Dispatcher
      : PresenceType.Mobile;
  }
  public get userUuid(): null | string {
    return this.participantInfo?.userId ?? null;
  }
  public async fetchContactDetails(): Promise<void> {
    let res: ContactDetails;
    if (!this.userUuid) {
      return;
    }
    try {
      const data = await this.queryContactDetails(this.userUuid);

      if (data != null) {
        res = new ContactDetails(this.state, {
          callPermission: data.permissions.call,
          email: data.email,
          entityId: data.entityId,
          fullDuplexPermission: data.permissions.fullDuplexCall,
          id: data.id,
          locatable: data.permissions.locate,
          messagePermission: data.permissions.message,
          name: data.displayName,
          online: data.onlineStatus.state === "ONLINE",
          organization: data.organization,
          phoneNumbers: data.phoneNumbers,
          status: data.currentStatus,
          statusList: data.availableStatuses,
        });
        // fetch callsign from Callsign Module and the Node API
        await res.fetchCallsign();
        this.contactDetails = res;
      }
    } catch (err: any) {
      log.error(err);
      this.contactDetails = undefined;
    }
  }
  public openOrFocusPanel(panel: Panel, tab: ContactTabSetting): void {
    if (this.userUuid) {
      this.state.contactManagement.openOrFocusPanel({
        name: this.name,
        panel,
        tab,
        userUuid: this.userUuid,
      });
    }
  }
  public async setup(
    participantInfo: ParticipantInfo | undefined
  ): Promise<void> {
    this.participant.tracks.forEach((publication) => {
      if (publication.isSubscribed && publication.track) {
        this.remoteTrackAdd(publication.track);
        this.handleTrackEvents(publication.track);
      }
    });
    this.participant.on("trackSubscribed", (track: RemoteTrack) => {
      this.remoteTrackAdd(track);
      this.handleTrackEvents(track);
    });
    this.participant.on("trackUnsubscribed", (track: RemoteTrack) => {
      this.remoteTrackRemove(track);
    });
    if (participantInfo === undefined) {
      await this.fetchParticipantInfo();
    } else {
      this.participantInfo = participantInfo;
    }
  }
  public updateParticipantInfo(info: Partial<ParticipantInfo>): void {
    this.participantInfo = {
      ...this.participantInfo,
      ...info,
    } as ParticipantInfo;
  }
  private async fetchParticipantInfo(): Promise<void> {
    log.debug(`Fetching participant info for ${this.id}`);
    try {
      const data = await this.queryParticipantInfo(this.id);
      log.debug("Fetched participant info", data);
      this.participantInfo = data;
    } catch (err: any) {
      log.error(err);
      this.participantInfo = undefined;
    }
  }
  private handleTrackEvents(track: RemoteTrack): void {
    track.on(
      "enabled",
      action(() => {
        log.debug(`Track was enabled: ${this.id}`);
        if (track.kind === "audio") {
          this.audioEnabled = true;
        } else if (track.kind === "video" && track.isEnabled) {
          this.videoEnabled = true;
        }
      })
    );
    track.on(
      "disabled",
      action(() => {
        log.debug(`Track was disabled: ${this.id}`);
        if (track.kind === "audio") {
          this.audioEnabled = false;
        } else if (track.kind === "video") {
          this.videoEnabled = false;
        }
      })
    );
  }
  private async queryContactDetails(
    id: ClientUserUuid
  ): Promise<ClientUserDetailed | null> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: ClientUserForPresence,
      variables: {
        id,
      },
    });
    return clientUser;
  }
  private async queryParticipantInfo(id: string): Promise<ParticipantInfo> {
    const { groupParticipant } =
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "no-cache",
        query: gql(`
          query groupParticipant(
            $id: ID!
          ) {
            groupParticipant(participantId: $id) {
              ... on Participant {
                id
                name
                title
              }
              ... on UserParticipant {
                id
                name
                title
                client
                userId
              }
            }
          }
        `),
        variables: {
          id,
        },
      });
    return groupParticipant!;
  }
  private remoteTrackAdd(track: RemoteTrack): void {
    if (track.kind === "audio") {
      this.twilioPeerAudioTrack = track;
      this.audioEnabled = track.isEnabled;
    } else if (track.kind === "video") {
      this.twilioPeerVideoTrack = track;
      this.videoEnabled = track.isEnabled;
    }
  }
  private remoteTrackRemove(track: RemoteTrack): void {
    if (track.kind === "audio") {
      this.twilioPeerAudioTrack = undefined;
      this.audioEnabled = false;
    } else if (track.kind === "video") {
      this.twilioPeerVideoTrack = undefined;
      this.videoEnabled = false;
    }
  }
}
