import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { NumberSetting } from "src/app/model/settings/NumberSetting";
import { observableClass } from "src/app/state/observableClass";

export class SoundInputSettings {
  public readonly inputWarning: BooleanSetting;
  public readonly latencyCompensation: NumberSetting;
  public constructor() {
    this.inputWarning = new BooleanSetting({
      defaultValue: true,
      key: "gt2.settings.inputWarning",
    });
    this.latencyCompensation = new NumberSetting({
      defaultValue: 0,
      key: "gt2.settings.recordDelay",
      maxValue: 5000,
      minValue: 0,
    });
    observableClass(this);
  }
}
