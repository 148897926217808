import { Forbidden } from "src/lib/errors/Forbidden";
import { NotAcceptableHere } from "src/lib/errors/NotAcceptableHere";
import { NotFound } from "src/lib/errors/NotFound";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { CallsignInfo } from "src/lib/types/CallsignInfo";
import type { UserDisplayInfo } from "src/lib/types/UserDisplayInfo";

const log = Logger.getLogger("CallsignModule");
/**
 * Returned from <code>setupCallsignModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class CallsignModule {
  public readonly callsignLabel?: string;
  public callsign?: string;
  private readonly onCallsignChanged?: (info: UserDisplayInfo) => void;
  private constructor(
    private readonly requestManager: RequestManager,
    options: {
      callsign?: string;
      callsignLabel?: string;
      onCallsignChanged?: (info: UserDisplayInfo) => void;
    } = {}
  ) {
    this.requestManager = requestManager;
    /**
     * The localized 'label' of the callsign concept.
     * @member {string}
     */
    this.callsignLabel = options.callsignLabel;
    /**
     * The user's current callsign.
     * @member {string}
     */
    this.callsign = options.callsign;
    /**
     * @param {function(UserDisplayInfo)} options.onCallsignChanged Callback when callsign changes.
     */
    this.onCallsignChanged = options.onCallsignChanged;
  }
  public static fromCallsignChangedRequest(
    callsignChangedRequest: proto.ICallsignV2CallsignChangedRequest
  ): UserDisplayInfo {
    return {
      callsign: callsignChangedRequest.callsign ?? undefined,
      displayName: callsignChangedRequest.displayName ?? undefined,
      title: callsignChangedRequest.title ?? undefined,
    };
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      callsign?: string;
      callsignLabel?: string;
      onCallsignChanged?: (info: UserDisplayInfo) => void;
    }
  ): Promise<CallsignModule> {
    const response = (await requestManager.send({
      callsignV2: { setupRequest: {} },
    })) as proto.CallsignModuleSetupResponse;
    log.debug("callsign module setup.", response);
    return new CallsignModule(requestManager, {
      callsign: response.currentCallsign,
      callsignLabel: response.callsignLabel,
      ...options,
    });
  }
  /**
   * Returns callsign of current user.
   * @param {string} options.userUuid If set, get callsign for another user. <code>(optional)</code>
   * @param {string} options.entitiyId If set, get callsign for another entity. <code>(optional)</code>
   * @returns {Promise<CallsignInfo>} Resolves with the callsign.
   */
  public async getCallsign({
    entityId,
    userUuid,
  }: {
    entityId?: string;
    userUuid?: string;
  }): Promise<CallsignInfo | null> {
    try {
      const response = (await this.requestManager.send({
        callsignV2: {
          getCallsignRequest: {
            entityId,
            userUuid,
          },
        },
      })) as proto.CallsignV2GetCallsignResponse;
      return {
        callsign: response.callsign,
        callsignLabel: response.callsignLabel,
      };
    } catch (error: any) {
      // if a user belongs to a user category with only a messaging relation defined
      // the server will return Forbidden. Instead of changing the server, catch the
      // error and handle it the same as NotFound.
      if (
        error instanceof Forbidden ||
        error instanceof NotFound ||
        error instanceof NotAcceptableHere
      ) {
        log.debug("No callsign configured");
      } else {
        throw error;
      }
      return null;
    }
  }
  public onRequest(
    message: proto.ICallsignAPIv2Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.callsignChangedRequest) {
      this.callsignChangedRequest(message.callsignChangedRequest, respond);
    }
    log.warn("Unhandled request", message);
    respond(proto.ResponseCode.REQUEST_UNKNOWN);
  }
  /**
   * Update the user's callsign which can be configured to change its display name and/or title
   * throughout the system. The callsign is persisted even after logging out.
   * @param {string} options.callsign The new callsign.
   * @param {string} options.userUuid If set, updates callsign for another user. <code>(optional)</code>
   * @param {string} options.entitiyId If set, updates callsign for another entity. <code>(optional)</code>
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async updateCallsign({
    callsign,
    entityId,
    userUuid,
  }: {
    callsign?: string;
    entityId?: string;
    userUuid?: string;
  }): Promise<void> {
    await this.requestManager.send({
      callsignV2: { setCallsignRequest: { callsign, entityId, userUuid } },
    });
    this.callsign = callsign;
  }
  private callsignChangedRequest(
    callsignChangedRequest: proto.ICallsignV2CallsignChangedRequest,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    this.callsign = callsignChangedRequest.callsign ?? undefined;
    if (this.onCallsignChanged) {
      this.onCallsignChanged(
        CallsignModule.fromCallsignChangedRequest(callsignChangedRequest)
      );
    }
  }
}
