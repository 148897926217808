import { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { action } from "mobx";
import type { QueueAlertService } from "src/app/model/queues/queueAlert/QueueAlertService";

export class UnpickedEmergencyState extends QueueState {
  public constructor(
    alertService: QueueAlertService,
    private newUnpickedEmergencyEntry: boolean
  ) {
    super(alertService, "UnpickedEmergencyState");
  }
  public newUnpickedEmergencyEntryEvent(): void {
    if (
      this.newUnpickedEmergencyEntry ||
      this.settings.emergency.playSound.selected.id === "always"
    ) {
      this.remindAboutEmergency();
      this.alertService.playEmergencyAlert();
      this.newUnpickedEmergencyEntry = false;
    }
  }
  public onEnter(): void {
    this.remindAboutEmergency();
    this.settingsUnsubscribe =
      this.settings.emergency.reminderIntervalInSeconds.subscribeOnChanges(
        () => {
          this.remindAboutEmergency();
        }
      );
  }
  public onExit(): void {
    if (this.alertTimer !== undefined) {
      this.alertTimer();
      this.alertTimer = undefined;
    }
    this.settingsUnsubscribe?.();
  }
  public remindAboutEmergency(): void {
    if (this.settings.emergency.reminderIntervalInSeconds.value > 0) {
      if (this.alertTimer !== undefined) {
        this.alertTimer();
      }
      this.alertTimer = BackgroundTimer.setTimeout(
        action(() => {
          // Check this again in case we since then have unset this
          if (this.settings.emergency.reminderIntervalInSeconds.value > 0) {
            this.alertService.playEmergencyAlert();
            this.remindAboutEmergency();
          }
        }),
        this.settings.emergency.reminderIntervalInSeconds.value * 1000
      );
    }
  }
}
