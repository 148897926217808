import { Layout } from "src/app/gui/components/Layout";

export const Separator = (): JSX.Element => (
  <Layout
    background="linear-gradient(to bottom, #747889a0, #616470a0)"
    marginBottom={5}
    marginTop={5}
    width={1}
  />
);
