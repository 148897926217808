/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.proto = (function() {

    /**
     * Namespace proto.
     * @exports proto
     * @namespace
     */
    var proto = {};

    proto.ClientMessage = (function() {

        /**
         * Properties of a ClientMessage.
         * @memberof proto
         * @interface IClientMessage
         * @property {number|Long|null} [requestId] ClientMessage requestId
         * @property {boolean|null} [cancelRequest] ClientMessage cancelRequest
         * @property {number|null} [responseCode] ClientMessage responseCode
         * @property {Uint8Array|null} [response] ClientMessage response
         * @property {proto.ICapabilitiesRequest|null} [capabilities] ClientMessage capabilities
         * @property {proto.IKeepAliveAPIv1SetupRequest|null} [keepAliveSetup] ClientMessage keepAliveSetup
         * @property {proto.IAuthenticateAPIv1Client|null} [authenticate] ClientMessage authenticate
         * @property {proto.ISessionAPIv1Client|null} [session] ClientMessage session
         * @property {proto.IChannelAPIv1Client|null} [channel] ClientMessage channel
         * @property {proto.IPresenceAPIv1Client|null} [presence] ClientMessage presence
         * @property {proto.IUDPAPIv1Client|null} [udp] ClientMessage udp
         * @property {proto.ITalkburstReceptionAPIv1Client|null} [talkburstReception] ClientMessage talkburstReception
         * @property {proto.ITalkburstTransmissionAPIv1Client|null} [talkburstTransmission] ClientMessage talkburstTransmission
         * @property {proto.IPhoneBookAPIv1Client|null} [phoneBook] ClientMessage phoneBook
         * @property {proto.ISetupCallAPIv1Client|null} [setupCall] ClientMessage setupCall
         * @property {proto.IReceiveCallAPIv1Client|null} [receiveCall] ClientMessage receiveCall
         * @property {proto.ICustomActionAPIv1Client|null} [customAction] ClientMessage customAction
         * @property {proto.IHTTPRequestAPIv1Client|null} [httpRequest] ClientMessage httpRequest
         * @property {proto.IEmergencyAPIv1Client|null} [emergency] ClientMessage emergency
         * @property {proto.IAudioAPIv1Client|null} [audio] ClientMessage audio
         * @property {proto.IThirdPartyCallControlAPIv1Client|null} [thirdPartyCallControl] ClientMessage thirdPartyCallControl
         * @property {proto.ISMSAPIv1Client|null} [sms] ClientMessage sms
         * @property {proto.IQueueManagementAPIv1Client|null} [queueManagement] ClientMessage queueManagement
         * @property {proto.ILocationReportAPIv1Client|null} [locationReport] ClientMessage locationReport
         * @property {proto.ILocationEnquiryAPIv1Client|null} [locationEnquiry] ClientMessage locationEnquiry
         * @property {proto.IDetachAPIv1Client|null} [detach] ClientMessage detach
         * @property {proto.ICallsignAPIv1Client|null} [callsign] ClientMessage callsign
         * @property {proto.IWebappAPIv1Client|null} [webapp] ClientMessage webapp
         * @property {proto.INotificationAPIv1Client|null} [notification] ClientMessage notification
         * @property {proto.IStatusAPIv1Client|null} [status] ClientMessage status
         * @property {proto.ICallsignAPIv2Client|null} [callsignV2] ClientMessage callsignV2
         * @property {proto.IReattachRequest|null} [reattach] ClientMessage reattach
         * @property {proto.IGoOfflineAPIv1Request|null} [goOffline] ClientMessage goOffline
         */

        /**
         * Constructs a new ClientMessage.
         * @memberof proto
         * @classdesc Represents a ClientMessage.
         * @implements IClientMessage
         * @constructor
         * @param {proto.IClientMessage=} [properties] Properties to set
         */
        function ClientMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientMessage requestId.
         * @member {number|Long} requestId
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ClientMessage cancelRequest.
         * @member {boolean} cancelRequest
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.cancelRequest = false;

        /**
         * ClientMessage responseCode.
         * @member {number} responseCode
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.responseCode = 0;

        /**
         * ClientMessage response.
         * @member {Uint8Array} response
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.response = $util.newBuffer([]);

        /**
         * ClientMessage capabilities.
         * @member {proto.ICapabilitiesRequest|null|undefined} capabilities
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.capabilities = null;

        /**
         * ClientMessage keepAliveSetup.
         * @member {proto.IKeepAliveAPIv1SetupRequest|null|undefined} keepAliveSetup
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.keepAliveSetup = null;

        /**
         * ClientMessage authenticate.
         * @member {proto.IAuthenticateAPIv1Client|null|undefined} authenticate
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.authenticate = null;

        /**
         * ClientMessage session.
         * @member {proto.ISessionAPIv1Client|null|undefined} session
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.session = null;

        /**
         * ClientMessage channel.
         * @member {proto.IChannelAPIv1Client|null|undefined} channel
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.channel = null;

        /**
         * ClientMessage presence.
         * @member {proto.IPresenceAPIv1Client|null|undefined} presence
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.presence = null;

        /**
         * ClientMessage udp.
         * @member {proto.IUDPAPIv1Client|null|undefined} udp
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.udp = null;

        /**
         * ClientMessage talkburstReception.
         * @member {proto.ITalkburstReceptionAPIv1Client|null|undefined} talkburstReception
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.talkburstReception = null;

        /**
         * ClientMessage talkburstTransmission.
         * @member {proto.ITalkburstTransmissionAPIv1Client|null|undefined} talkburstTransmission
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.talkburstTransmission = null;

        /**
         * ClientMessage phoneBook.
         * @member {proto.IPhoneBookAPIv1Client|null|undefined} phoneBook
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.phoneBook = null;

        /**
         * ClientMessage setupCall.
         * @member {proto.ISetupCallAPIv1Client|null|undefined} setupCall
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.setupCall = null;

        /**
         * ClientMessage receiveCall.
         * @member {proto.IReceiveCallAPIv1Client|null|undefined} receiveCall
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.receiveCall = null;

        /**
         * ClientMessage customAction.
         * @member {proto.ICustomActionAPIv1Client|null|undefined} customAction
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.customAction = null;

        /**
         * ClientMessage httpRequest.
         * @member {proto.IHTTPRequestAPIv1Client|null|undefined} httpRequest
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.httpRequest = null;

        /**
         * ClientMessage emergency.
         * @member {proto.IEmergencyAPIv1Client|null|undefined} emergency
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.emergency = null;

        /**
         * ClientMessage audio.
         * @member {proto.IAudioAPIv1Client|null|undefined} audio
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.audio = null;

        /**
         * ClientMessage thirdPartyCallControl.
         * @member {proto.IThirdPartyCallControlAPIv1Client|null|undefined} thirdPartyCallControl
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.thirdPartyCallControl = null;

        /**
         * ClientMessage sms.
         * @member {proto.ISMSAPIv1Client|null|undefined} sms
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.sms = null;

        /**
         * ClientMessage queueManagement.
         * @member {proto.IQueueManagementAPIv1Client|null|undefined} queueManagement
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.queueManagement = null;

        /**
         * ClientMessage locationReport.
         * @member {proto.ILocationReportAPIv1Client|null|undefined} locationReport
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.locationReport = null;

        /**
         * ClientMessage locationEnquiry.
         * @member {proto.ILocationEnquiryAPIv1Client|null|undefined} locationEnquiry
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.locationEnquiry = null;

        /**
         * ClientMessage detach.
         * @member {proto.IDetachAPIv1Client|null|undefined} detach
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.detach = null;

        /**
         * ClientMessage callsign.
         * @member {proto.ICallsignAPIv1Client|null|undefined} callsign
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.callsign = null;

        /**
         * ClientMessage webapp.
         * @member {proto.IWebappAPIv1Client|null|undefined} webapp
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.webapp = null;

        /**
         * ClientMessage notification.
         * @member {proto.INotificationAPIv1Client|null|undefined} notification
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.notification = null;

        /**
         * ClientMessage status.
         * @member {proto.IStatusAPIv1Client|null|undefined} status
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.status = null;

        /**
         * ClientMessage callsignV2.
         * @member {proto.ICallsignAPIv2Client|null|undefined} callsignV2
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.callsignV2 = null;

        /**
         * ClientMessage reattach.
         * @member {proto.IReattachRequest|null|undefined} reattach
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.reattach = null;

        /**
         * ClientMessage goOffline.
         * @member {proto.IGoOfflineAPIv1Request|null|undefined} goOffline
         * @memberof proto.ClientMessage
         * @instance
         */
        ClientMessage.prototype.goOffline = null;

        /**
         * Creates a new ClientMessage instance using the specified properties.
         * @function create
         * @memberof proto.ClientMessage
         * @static
         * @param {proto.IClientMessage=} [properties] Properties to set
         * @returns {proto.ClientMessage} ClientMessage instance
         */
        ClientMessage.create = function create(properties) {
            return new ClientMessage(properties);
        };

        /**
         * Encodes the specified ClientMessage message. Does not implicitly {@link proto.ClientMessage.verify|verify} messages.
         * @function encode
         * @memberof proto.ClientMessage
         * @static
         * @param {proto.IClientMessage} message ClientMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.requestId);
            if (message.cancelRequest != null && Object.hasOwnProperty.call(message, "cancelRequest"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.cancelRequest);
            if (message.responseCode != null && Object.hasOwnProperty.call(message, "responseCode"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.responseCode);
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.response);
            if (message.capabilities != null && Object.hasOwnProperty.call(message, "capabilities"))
                $root.proto.CapabilitiesRequest.encode(message.capabilities, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.keepAliveSetup != null && Object.hasOwnProperty.call(message, "keepAliveSetup"))
                $root.proto.KeepAliveAPIv1SetupRequest.encode(message.keepAliveSetup, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.authenticate != null && Object.hasOwnProperty.call(message, "authenticate"))
                $root.proto.AuthenticateAPIv1Client.encode(message.authenticate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.session != null && Object.hasOwnProperty.call(message, "session"))
                $root.proto.SessionAPIv1Client.encode(message.session, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                $root.proto.ChannelAPIv1Client.encode(message.channel, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.presence != null && Object.hasOwnProperty.call(message, "presence"))
                $root.proto.PresenceAPIv1Client.encode(message.presence, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.udp != null && Object.hasOwnProperty.call(message, "udp"))
                $root.proto.UDPAPIv1Client.encode(message.udp, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.talkburstReception != null && Object.hasOwnProperty.call(message, "talkburstReception"))
                $root.proto.TalkburstReceptionAPIv1Client.encode(message.talkburstReception, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.talkburstTransmission != null && Object.hasOwnProperty.call(message, "talkburstTransmission"))
                $root.proto.TalkburstTransmissionAPIv1Client.encode(message.talkburstTransmission, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.phoneBook != null && Object.hasOwnProperty.call(message, "phoneBook"))
                $root.proto.PhoneBookAPIv1Client.encode(message.phoneBook, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.setupCall != null && Object.hasOwnProperty.call(message, "setupCall"))
                $root.proto.SetupCallAPIv1Client.encode(message.setupCall, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.receiveCall != null && Object.hasOwnProperty.call(message, "receiveCall"))
                $root.proto.ReceiveCallAPIv1Client.encode(message.receiveCall, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.customAction != null && Object.hasOwnProperty.call(message, "customAction"))
                $root.proto.CustomActionAPIv1Client.encode(message.customAction, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.httpRequest != null && Object.hasOwnProperty.call(message, "httpRequest"))
                $root.proto.HTTPRequestAPIv1Client.encode(message.httpRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.emergency != null && Object.hasOwnProperty.call(message, "emergency"))
                $root.proto.EmergencyAPIv1Client.encode(message.emergency, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                $root.proto.AudioAPIv1Client.encode(message.audio, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.thirdPartyCallControl != null && Object.hasOwnProperty.call(message, "thirdPartyCallControl"))
                $root.proto.ThirdPartyCallControlAPIv1Client.encode(message.thirdPartyCallControl, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.sms != null && Object.hasOwnProperty.call(message, "sms"))
                $root.proto.SMSAPIv1Client.encode(message.sms, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.queueManagement != null && Object.hasOwnProperty.call(message, "queueManagement"))
                $root.proto.QueueManagementAPIv1Client.encode(message.queueManagement, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.locationReport != null && Object.hasOwnProperty.call(message, "locationReport"))
                $root.proto.LocationReportAPIv1Client.encode(message.locationReport, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.locationEnquiry != null && Object.hasOwnProperty.call(message, "locationEnquiry"))
                $root.proto.LocationEnquiryAPIv1Client.encode(message.locationEnquiry, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.detach != null && Object.hasOwnProperty.call(message, "detach"))
                $root.proto.DetachAPIv1Client.encode(message.detach, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.callsign != null && Object.hasOwnProperty.call(message, "callsign"))
                $root.proto.CallsignAPIv1Client.encode(message.callsign, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.webapp != null && Object.hasOwnProperty.call(message, "webapp"))
                $root.proto.WebappAPIv1Client.encode(message.webapp, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                $root.proto.NotificationAPIv1Client.encode(message.notification, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                $root.proto.StatusAPIv1Client.encode(message.status, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.callsignV2 != null && Object.hasOwnProperty.call(message, "callsignV2"))
                $root.proto.CallsignAPIv2Client.encode(message.callsignV2, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.reattach != null && Object.hasOwnProperty.call(message, "reattach"))
                $root.proto.ReattachRequest.encode(message.reattach, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            if (message.goOffline != null && Object.hasOwnProperty.call(message, "goOffline"))
                $root.proto.GoOfflineAPIv1Request.encode(message.goOffline, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClientMessage message, length delimited. Does not implicitly {@link proto.ClientMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ClientMessage
         * @static
         * @param {proto.IClientMessage} message ClientMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClientMessage message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ClientMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ClientMessage} ClientMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ClientMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.requestId = reader.int64();
                        break;
                    }
                case 2: {
                        message.cancelRequest = reader.bool();
                        break;
                    }
                case 3: {
                        message.responseCode = reader.int32();
                        break;
                    }
                case 4: {
                        message.response = reader.bytes();
                        break;
                    }
                case 5: {
                        message.capabilities = $root.proto.CapabilitiesRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.keepAliveSetup = $root.proto.KeepAliveAPIv1SetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.authenticate = $root.proto.AuthenticateAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.session = $root.proto.SessionAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.channel = $root.proto.ChannelAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.presence = $root.proto.PresenceAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.udp = $root.proto.UDPAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.talkburstReception = $root.proto.TalkburstReceptionAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.talkburstTransmission = $root.proto.TalkburstTransmissionAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.phoneBook = $root.proto.PhoneBookAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.setupCall = $root.proto.SetupCallAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.receiveCall = $root.proto.ReceiveCallAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.customAction = $root.proto.CustomActionAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.httpRequest = $root.proto.HTTPRequestAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.emergency = $root.proto.EmergencyAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.audio = $root.proto.AudioAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.thirdPartyCallControl = $root.proto.ThirdPartyCallControlAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.sms = $root.proto.SMSAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.queueManagement = $root.proto.QueueManagementAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.locationReport = $root.proto.LocationReportAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.locationEnquiry = $root.proto.LocationEnquiryAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.detach = $root.proto.DetachAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 27: {
                        message.callsign = $root.proto.CallsignAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 28: {
                        message.webapp = $root.proto.WebappAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 29: {
                        message.notification = $root.proto.NotificationAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.status = $root.proto.StatusAPIv1Client.decode(reader, reader.uint32());
                        break;
                    }
                case 31: {
                        message.callsignV2 = $root.proto.CallsignAPIv2Client.decode(reader, reader.uint32());
                        break;
                    }
                case 99: {
                        message.reattach = $root.proto.ReattachRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 100: {
                        message.goOffline = $root.proto.GoOfflineAPIv1Request.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClientMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ClientMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ClientMessage} ClientMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClientMessage message.
         * @function verify
         * @memberof proto.ClientMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                    return "requestId: integer|Long expected";
            if (message.cancelRequest != null && message.hasOwnProperty("cancelRequest"))
                if (typeof message.cancelRequest !== "boolean")
                    return "cancelRequest: boolean expected";
            if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                if (!$util.isInteger(message.responseCode))
                    return "responseCode: integer expected";
            if (message.response != null && message.hasOwnProperty("response"))
                if (!(message.response && typeof message.response.length === "number" || $util.isString(message.response)))
                    return "response: buffer expected";
            if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                var error = $root.proto.CapabilitiesRequest.verify(message.capabilities);
                if (error)
                    return "capabilities." + error;
            }
            if (message.keepAliveSetup != null && message.hasOwnProperty("keepAliveSetup")) {
                var error = $root.proto.KeepAliveAPIv1SetupRequest.verify(message.keepAliveSetup);
                if (error)
                    return "keepAliveSetup." + error;
            }
            if (message.authenticate != null && message.hasOwnProperty("authenticate")) {
                var error = $root.proto.AuthenticateAPIv1Client.verify(message.authenticate);
                if (error)
                    return "authenticate." + error;
            }
            if (message.session != null && message.hasOwnProperty("session")) {
                var error = $root.proto.SessionAPIv1Client.verify(message.session);
                if (error)
                    return "session." + error;
            }
            if (message.channel != null && message.hasOwnProperty("channel")) {
                var error = $root.proto.ChannelAPIv1Client.verify(message.channel);
                if (error)
                    return "channel." + error;
            }
            if (message.presence != null && message.hasOwnProperty("presence")) {
                var error = $root.proto.PresenceAPIv1Client.verify(message.presence);
                if (error)
                    return "presence." + error;
            }
            if (message.udp != null && message.hasOwnProperty("udp")) {
                var error = $root.proto.UDPAPIv1Client.verify(message.udp);
                if (error)
                    return "udp." + error;
            }
            if (message.talkburstReception != null && message.hasOwnProperty("talkburstReception")) {
                var error = $root.proto.TalkburstReceptionAPIv1Client.verify(message.talkburstReception);
                if (error)
                    return "talkburstReception." + error;
            }
            if (message.talkburstTransmission != null && message.hasOwnProperty("talkburstTransmission")) {
                var error = $root.proto.TalkburstTransmissionAPIv1Client.verify(message.talkburstTransmission);
                if (error)
                    return "talkburstTransmission." + error;
            }
            if (message.phoneBook != null && message.hasOwnProperty("phoneBook")) {
                var error = $root.proto.PhoneBookAPIv1Client.verify(message.phoneBook);
                if (error)
                    return "phoneBook." + error;
            }
            if (message.setupCall != null && message.hasOwnProperty("setupCall")) {
                var error = $root.proto.SetupCallAPIv1Client.verify(message.setupCall);
                if (error)
                    return "setupCall." + error;
            }
            if (message.receiveCall != null && message.hasOwnProperty("receiveCall")) {
                var error = $root.proto.ReceiveCallAPIv1Client.verify(message.receiveCall);
                if (error)
                    return "receiveCall." + error;
            }
            if (message.customAction != null && message.hasOwnProperty("customAction")) {
                var error = $root.proto.CustomActionAPIv1Client.verify(message.customAction);
                if (error)
                    return "customAction." + error;
            }
            if (message.httpRequest != null && message.hasOwnProperty("httpRequest")) {
                var error = $root.proto.HTTPRequestAPIv1Client.verify(message.httpRequest);
                if (error)
                    return "httpRequest." + error;
            }
            if (message.emergency != null && message.hasOwnProperty("emergency")) {
                var error = $root.proto.EmergencyAPIv1Client.verify(message.emergency);
                if (error)
                    return "emergency." + error;
            }
            if (message.audio != null && message.hasOwnProperty("audio")) {
                var error = $root.proto.AudioAPIv1Client.verify(message.audio);
                if (error)
                    return "audio." + error;
            }
            if (message.thirdPartyCallControl != null && message.hasOwnProperty("thirdPartyCallControl")) {
                var error = $root.proto.ThirdPartyCallControlAPIv1Client.verify(message.thirdPartyCallControl);
                if (error)
                    return "thirdPartyCallControl." + error;
            }
            if (message.sms != null && message.hasOwnProperty("sms")) {
                var error = $root.proto.SMSAPIv1Client.verify(message.sms);
                if (error)
                    return "sms." + error;
            }
            if (message.queueManagement != null && message.hasOwnProperty("queueManagement")) {
                var error = $root.proto.QueueManagementAPIv1Client.verify(message.queueManagement);
                if (error)
                    return "queueManagement." + error;
            }
            if (message.locationReport != null && message.hasOwnProperty("locationReport")) {
                var error = $root.proto.LocationReportAPIv1Client.verify(message.locationReport);
                if (error)
                    return "locationReport." + error;
            }
            if (message.locationEnquiry != null && message.hasOwnProperty("locationEnquiry")) {
                var error = $root.proto.LocationEnquiryAPIv1Client.verify(message.locationEnquiry);
                if (error)
                    return "locationEnquiry." + error;
            }
            if (message.detach != null && message.hasOwnProperty("detach")) {
                var error = $root.proto.DetachAPIv1Client.verify(message.detach);
                if (error)
                    return "detach." + error;
            }
            if (message.callsign != null && message.hasOwnProperty("callsign")) {
                var error = $root.proto.CallsignAPIv1Client.verify(message.callsign);
                if (error)
                    return "callsign." + error;
            }
            if (message.webapp != null && message.hasOwnProperty("webapp")) {
                var error = $root.proto.WebappAPIv1Client.verify(message.webapp);
                if (error)
                    return "webapp." + error;
            }
            if (message.notification != null && message.hasOwnProperty("notification")) {
                var error = $root.proto.NotificationAPIv1Client.verify(message.notification);
                if (error)
                    return "notification." + error;
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                var error = $root.proto.StatusAPIv1Client.verify(message.status);
                if (error)
                    return "status." + error;
            }
            if (message.callsignV2 != null && message.hasOwnProperty("callsignV2")) {
                var error = $root.proto.CallsignAPIv2Client.verify(message.callsignV2);
                if (error)
                    return "callsignV2." + error;
            }
            if (message.reattach != null && message.hasOwnProperty("reattach")) {
                var error = $root.proto.ReattachRequest.verify(message.reattach);
                if (error)
                    return "reattach." + error;
            }
            if (message.goOffline != null && message.hasOwnProperty("goOffline")) {
                var error = $root.proto.GoOfflineAPIv1Request.verify(message.goOffline);
                if (error)
                    return "goOffline." + error;
            }
            return null;
        };

        /**
         * Creates a ClientMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ClientMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ClientMessage} ClientMessage
         */
        ClientMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ClientMessage)
                return object;
            var message = new $root.proto.ClientMessage();
            if (object.requestId != null)
                if ($util.Long)
                    (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = false;
                else if (typeof object.requestId === "string")
                    message.requestId = parseInt(object.requestId, 10);
                else if (typeof object.requestId === "number")
                    message.requestId = object.requestId;
                else if (typeof object.requestId === "object")
                    message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber();
            if (object.cancelRequest != null)
                message.cancelRequest = Boolean(object.cancelRequest);
            if (object.responseCode != null)
                message.responseCode = object.responseCode | 0;
            if (object.response != null)
                if (typeof object.response === "string")
                    $util.base64.decode(object.response, message.response = $util.newBuffer($util.base64.length(object.response)), 0);
                else if (object.response.length >= 0)
                    message.response = object.response;
            if (object.capabilities != null) {
                if (typeof object.capabilities !== "object")
                    throw TypeError(".proto.ClientMessage.capabilities: object expected");
                message.capabilities = $root.proto.CapabilitiesRequest.fromObject(object.capabilities);
            }
            if (object.keepAliveSetup != null) {
                if (typeof object.keepAliveSetup !== "object")
                    throw TypeError(".proto.ClientMessage.keepAliveSetup: object expected");
                message.keepAliveSetup = $root.proto.KeepAliveAPIv1SetupRequest.fromObject(object.keepAliveSetup);
            }
            if (object.authenticate != null) {
                if (typeof object.authenticate !== "object")
                    throw TypeError(".proto.ClientMessage.authenticate: object expected");
                message.authenticate = $root.proto.AuthenticateAPIv1Client.fromObject(object.authenticate);
            }
            if (object.session != null) {
                if (typeof object.session !== "object")
                    throw TypeError(".proto.ClientMessage.session: object expected");
                message.session = $root.proto.SessionAPIv1Client.fromObject(object.session);
            }
            if (object.channel != null) {
                if (typeof object.channel !== "object")
                    throw TypeError(".proto.ClientMessage.channel: object expected");
                message.channel = $root.proto.ChannelAPIv1Client.fromObject(object.channel);
            }
            if (object.presence != null) {
                if (typeof object.presence !== "object")
                    throw TypeError(".proto.ClientMessage.presence: object expected");
                message.presence = $root.proto.PresenceAPIv1Client.fromObject(object.presence);
            }
            if (object.udp != null) {
                if (typeof object.udp !== "object")
                    throw TypeError(".proto.ClientMessage.udp: object expected");
                message.udp = $root.proto.UDPAPIv1Client.fromObject(object.udp);
            }
            if (object.talkburstReception != null) {
                if (typeof object.talkburstReception !== "object")
                    throw TypeError(".proto.ClientMessage.talkburstReception: object expected");
                message.talkburstReception = $root.proto.TalkburstReceptionAPIv1Client.fromObject(object.talkburstReception);
            }
            if (object.talkburstTransmission != null) {
                if (typeof object.talkburstTransmission !== "object")
                    throw TypeError(".proto.ClientMessage.talkburstTransmission: object expected");
                message.talkburstTransmission = $root.proto.TalkburstTransmissionAPIv1Client.fromObject(object.talkburstTransmission);
            }
            if (object.phoneBook != null) {
                if (typeof object.phoneBook !== "object")
                    throw TypeError(".proto.ClientMessage.phoneBook: object expected");
                message.phoneBook = $root.proto.PhoneBookAPIv1Client.fromObject(object.phoneBook);
            }
            if (object.setupCall != null) {
                if (typeof object.setupCall !== "object")
                    throw TypeError(".proto.ClientMessage.setupCall: object expected");
                message.setupCall = $root.proto.SetupCallAPIv1Client.fromObject(object.setupCall);
            }
            if (object.receiveCall != null) {
                if (typeof object.receiveCall !== "object")
                    throw TypeError(".proto.ClientMessage.receiveCall: object expected");
                message.receiveCall = $root.proto.ReceiveCallAPIv1Client.fromObject(object.receiveCall);
            }
            if (object.customAction != null) {
                if (typeof object.customAction !== "object")
                    throw TypeError(".proto.ClientMessage.customAction: object expected");
                message.customAction = $root.proto.CustomActionAPIv1Client.fromObject(object.customAction);
            }
            if (object.httpRequest != null) {
                if (typeof object.httpRequest !== "object")
                    throw TypeError(".proto.ClientMessage.httpRequest: object expected");
                message.httpRequest = $root.proto.HTTPRequestAPIv1Client.fromObject(object.httpRequest);
            }
            if (object.emergency != null) {
                if (typeof object.emergency !== "object")
                    throw TypeError(".proto.ClientMessage.emergency: object expected");
                message.emergency = $root.proto.EmergencyAPIv1Client.fromObject(object.emergency);
            }
            if (object.audio != null) {
                if (typeof object.audio !== "object")
                    throw TypeError(".proto.ClientMessage.audio: object expected");
                message.audio = $root.proto.AudioAPIv1Client.fromObject(object.audio);
            }
            if (object.thirdPartyCallControl != null) {
                if (typeof object.thirdPartyCallControl !== "object")
                    throw TypeError(".proto.ClientMessage.thirdPartyCallControl: object expected");
                message.thirdPartyCallControl = $root.proto.ThirdPartyCallControlAPIv1Client.fromObject(object.thirdPartyCallControl);
            }
            if (object.sms != null) {
                if (typeof object.sms !== "object")
                    throw TypeError(".proto.ClientMessage.sms: object expected");
                message.sms = $root.proto.SMSAPIv1Client.fromObject(object.sms);
            }
            if (object.queueManagement != null) {
                if (typeof object.queueManagement !== "object")
                    throw TypeError(".proto.ClientMessage.queueManagement: object expected");
                message.queueManagement = $root.proto.QueueManagementAPIv1Client.fromObject(object.queueManagement);
            }
            if (object.locationReport != null) {
                if (typeof object.locationReport !== "object")
                    throw TypeError(".proto.ClientMessage.locationReport: object expected");
                message.locationReport = $root.proto.LocationReportAPIv1Client.fromObject(object.locationReport);
            }
            if (object.locationEnquiry != null) {
                if (typeof object.locationEnquiry !== "object")
                    throw TypeError(".proto.ClientMessage.locationEnquiry: object expected");
                message.locationEnquiry = $root.proto.LocationEnquiryAPIv1Client.fromObject(object.locationEnquiry);
            }
            if (object.detach != null) {
                if (typeof object.detach !== "object")
                    throw TypeError(".proto.ClientMessage.detach: object expected");
                message.detach = $root.proto.DetachAPIv1Client.fromObject(object.detach);
            }
            if (object.callsign != null) {
                if (typeof object.callsign !== "object")
                    throw TypeError(".proto.ClientMessage.callsign: object expected");
                message.callsign = $root.proto.CallsignAPIv1Client.fromObject(object.callsign);
            }
            if (object.webapp != null) {
                if (typeof object.webapp !== "object")
                    throw TypeError(".proto.ClientMessage.webapp: object expected");
                message.webapp = $root.proto.WebappAPIv1Client.fromObject(object.webapp);
            }
            if (object.notification != null) {
                if (typeof object.notification !== "object")
                    throw TypeError(".proto.ClientMessage.notification: object expected");
                message.notification = $root.proto.NotificationAPIv1Client.fromObject(object.notification);
            }
            if (object.status != null) {
                if (typeof object.status !== "object")
                    throw TypeError(".proto.ClientMessage.status: object expected");
                message.status = $root.proto.StatusAPIv1Client.fromObject(object.status);
            }
            if (object.callsignV2 != null) {
                if (typeof object.callsignV2 !== "object")
                    throw TypeError(".proto.ClientMessage.callsignV2: object expected");
                message.callsignV2 = $root.proto.CallsignAPIv2Client.fromObject(object.callsignV2);
            }
            if (object.reattach != null) {
                if (typeof object.reattach !== "object")
                    throw TypeError(".proto.ClientMessage.reattach: object expected");
                message.reattach = $root.proto.ReattachRequest.fromObject(object.reattach);
            }
            if (object.goOffline != null) {
                if (typeof object.goOffline !== "object")
                    throw TypeError(".proto.ClientMessage.goOffline: object expected");
                message.goOffline = $root.proto.GoOfflineAPIv1Request.fromObject(object.goOffline);
            }
            return message;
        };

        /**
         * Creates a plain object from a ClientMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ClientMessage
         * @static
         * @param {proto.ClientMessage} message ClientMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestId = options.longs === String ? "0" : 0;
                object.cancelRequest = false;
                object.responseCode = 0;
                if (options.bytes === String)
                    object.response = "";
                else {
                    object.response = [];
                    if (options.bytes !== Array)
                        object.response = $util.newBuffer(object.response);
                }
                object.capabilities = null;
                object.keepAliveSetup = null;
                object.authenticate = null;
                object.session = null;
                object.channel = null;
                object.presence = null;
                object.udp = null;
                object.talkburstReception = null;
                object.talkburstTransmission = null;
                object.phoneBook = null;
                object.setupCall = null;
                object.receiveCall = null;
                object.customAction = null;
                object.httpRequest = null;
                object.emergency = null;
                object.audio = null;
                object.thirdPartyCallControl = null;
                object.sms = null;
                object.queueManagement = null;
                object.locationReport = null;
                object.locationEnquiry = null;
                object.detach = null;
                object.callsign = null;
                object.webapp = null;
                object.notification = null;
                object.status = null;
                object.callsignV2 = null;
                object.reattach = null;
                object.goOffline = null;
            }
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (typeof message.requestId === "number")
                    object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                else
                    object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber() : message.requestId;
            if (message.cancelRequest != null && message.hasOwnProperty("cancelRequest"))
                object.cancelRequest = message.cancelRequest;
            if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                object.responseCode = message.responseCode;
            if (message.response != null && message.hasOwnProperty("response"))
                object.response = options.bytes === String ? $util.base64.encode(message.response, 0, message.response.length) : options.bytes === Array ? Array.prototype.slice.call(message.response) : message.response;
            if (message.capabilities != null && message.hasOwnProperty("capabilities"))
                object.capabilities = $root.proto.CapabilitiesRequest.toObject(message.capabilities, options);
            if (message.keepAliveSetup != null && message.hasOwnProperty("keepAliveSetup"))
                object.keepAliveSetup = $root.proto.KeepAliveAPIv1SetupRequest.toObject(message.keepAliveSetup, options);
            if (message.authenticate != null && message.hasOwnProperty("authenticate"))
                object.authenticate = $root.proto.AuthenticateAPIv1Client.toObject(message.authenticate, options);
            if (message.session != null && message.hasOwnProperty("session"))
                object.session = $root.proto.SessionAPIv1Client.toObject(message.session, options);
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = $root.proto.ChannelAPIv1Client.toObject(message.channel, options);
            if (message.presence != null && message.hasOwnProperty("presence"))
                object.presence = $root.proto.PresenceAPIv1Client.toObject(message.presence, options);
            if (message.udp != null && message.hasOwnProperty("udp"))
                object.udp = $root.proto.UDPAPIv1Client.toObject(message.udp, options);
            if (message.talkburstReception != null && message.hasOwnProperty("talkburstReception"))
                object.talkburstReception = $root.proto.TalkburstReceptionAPIv1Client.toObject(message.talkburstReception, options);
            if (message.talkburstTransmission != null && message.hasOwnProperty("talkburstTransmission"))
                object.talkburstTransmission = $root.proto.TalkburstTransmissionAPIv1Client.toObject(message.talkburstTransmission, options);
            if (message.phoneBook != null && message.hasOwnProperty("phoneBook"))
                object.phoneBook = $root.proto.PhoneBookAPIv1Client.toObject(message.phoneBook, options);
            if (message.setupCall != null && message.hasOwnProperty("setupCall"))
                object.setupCall = $root.proto.SetupCallAPIv1Client.toObject(message.setupCall, options);
            if (message.receiveCall != null && message.hasOwnProperty("receiveCall"))
                object.receiveCall = $root.proto.ReceiveCallAPIv1Client.toObject(message.receiveCall, options);
            if (message.customAction != null && message.hasOwnProperty("customAction"))
                object.customAction = $root.proto.CustomActionAPIv1Client.toObject(message.customAction, options);
            if (message.httpRequest != null && message.hasOwnProperty("httpRequest"))
                object.httpRequest = $root.proto.HTTPRequestAPIv1Client.toObject(message.httpRequest, options);
            if (message.emergency != null && message.hasOwnProperty("emergency"))
                object.emergency = $root.proto.EmergencyAPIv1Client.toObject(message.emergency, options);
            if (message.audio != null && message.hasOwnProperty("audio"))
                object.audio = $root.proto.AudioAPIv1Client.toObject(message.audio, options);
            if (message.thirdPartyCallControl != null && message.hasOwnProperty("thirdPartyCallControl"))
                object.thirdPartyCallControl = $root.proto.ThirdPartyCallControlAPIv1Client.toObject(message.thirdPartyCallControl, options);
            if (message.sms != null && message.hasOwnProperty("sms"))
                object.sms = $root.proto.SMSAPIv1Client.toObject(message.sms, options);
            if (message.queueManagement != null && message.hasOwnProperty("queueManagement"))
                object.queueManagement = $root.proto.QueueManagementAPIv1Client.toObject(message.queueManagement, options);
            if (message.locationReport != null && message.hasOwnProperty("locationReport"))
                object.locationReport = $root.proto.LocationReportAPIv1Client.toObject(message.locationReport, options);
            if (message.locationEnquiry != null && message.hasOwnProperty("locationEnquiry"))
                object.locationEnquiry = $root.proto.LocationEnquiryAPIv1Client.toObject(message.locationEnquiry, options);
            if (message.detach != null && message.hasOwnProperty("detach"))
                object.detach = $root.proto.DetachAPIv1Client.toObject(message.detach, options);
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                object.callsign = $root.proto.CallsignAPIv1Client.toObject(message.callsign, options);
            if (message.webapp != null && message.hasOwnProperty("webapp"))
                object.webapp = $root.proto.WebappAPIv1Client.toObject(message.webapp, options);
            if (message.notification != null && message.hasOwnProperty("notification"))
                object.notification = $root.proto.NotificationAPIv1Client.toObject(message.notification, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = $root.proto.StatusAPIv1Client.toObject(message.status, options);
            if (message.callsignV2 != null && message.hasOwnProperty("callsignV2"))
                object.callsignV2 = $root.proto.CallsignAPIv2Client.toObject(message.callsignV2, options);
            if (message.reattach != null && message.hasOwnProperty("reattach"))
                object.reattach = $root.proto.ReattachRequest.toObject(message.reattach, options);
            if (message.goOffline != null && message.hasOwnProperty("goOffline"))
                object.goOffline = $root.proto.GoOfflineAPIv1Request.toObject(message.goOffline, options);
            return object;
        };

        /**
         * Converts this ClientMessage to JSON.
         * @function toJSON
         * @memberof proto.ClientMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ClientMessage
         * @function getTypeUrl
         * @memberof proto.ClientMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ClientMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ClientMessage";
        };

        return ClientMessage;
    })();

    proto.ServerMessage = (function() {

        /**
         * Properties of a ServerMessage.
         * @memberof proto
         * @interface IServerMessage
         * @property {number|Long|null} [requestId] ServerMessage requestId
         * @property {boolean|null} [cancelRequest] ServerMessage cancelRequest
         * @property {number|null} [responseCode] ServerMessage responseCode
         * @property {Uint8Array|null} [response] ServerMessage response
         * @property {proto.IKeepAliveAPIv1PingRequest|null} [keepAlivePing] ServerMessage keepAlivePing
         * @property {proto.IAuthenticateAPIv1Server|null} [authenticate] ServerMessage authenticate
         * @property {proto.ISessionAPIv1Server|null} [session] ServerMessage session
         * @property {proto.IChannelAPIv1Server|null} [channel] ServerMessage channel
         * @property {proto.IPresenceAPIv1Server|null} [presence] ServerMessage presence
         * @property {proto.IUDPAPIv1Server|null} [udp] ServerMessage udp
         * @property {proto.ITalkburstReceptionAPIv1Server|null} [talkburstReception] ServerMessage talkburstReception
         * @property {proto.ITalkburstTransmissionAPIv1Server|null} [talkburstTransmission] ServerMessage talkburstTransmission
         * @property {proto.IPhoneBookAPIv1Server|null} [phoneBook] ServerMessage phoneBook
         * @property {proto.IReceiveCallAPIv1Server|null} [receiveCall] ServerMessage receiveCall
         * @property {proto.IHTTPRequestAPIv1Server|null} [httpRequest] ServerMessage httpRequest
         * @property {proto.IEmergencyAPIv1Server|null} [emergency] ServerMessage emergency
         * @property {proto.IAudioAPIv1Server|null} [audio] ServerMessage audio
         * @property {proto.IThirdPartyCallControlAPIv1Server|null} [thirdPartyCallControl] ServerMessage thirdPartyCallControl
         * @property {proto.IQueueManagementAPIv1Server|null} [queueManagement] ServerMessage queueManagement
         * @property {proto.ILocationReportAPIv1Server|null} [locationReport] ServerMessage locationReport
         * @property {proto.ILocationEnquiryAPIv1Server|null} [locationEnquiry] ServerMessage locationEnquiry
         * @property {proto.INotificationAPIv1Server|null} [notification] ServerMessage notification
         * @property {proto.IStatusAPIv1Server|null} [status] ServerMessage status
         * @property {proto.ICallsignAPIv2Server|null} [callsignV2] ServerMessage callsignV2
         * @property {proto.ITerminateConnection|null} [terminateRequest] ServerMessage terminateRequest
         */

        /**
         * Constructs a new ServerMessage.
         * @memberof proto
         * @classdesc Represents a ServerMessage.
         * @implements IServerMessage
         * @constructor
         * @param {proto.IServerMessage=} [properties] Properties to set
         */
        function ServerMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerMessage requestId.
         * @member {number|Long} requestId
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ServerMessage cancelRequest.
         * @member {boolean} cancelRequest
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.cancelRequest = false;

        /**
         * ServerMessage responseCode.
         * @member {number} responseCode
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.responseCode = 0;

        /**
         * ServerMessage response.
         * @member {Uint8Array} response
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.response = $util.newBuffer([]);

        /**
         * ServerMessage keepAlivePing.
         * @member {proto.IKeepAliveAPIv1PingRequest|null|undefined} keepAlivePing
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.keepAlivePing = null;

        /**
         * ServerMessage authenticate.
         * @member {proto.IAuthenticateAPIv1Server|null|undefined} authenticate
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.authenticate = null;

        /**
         * ServerMessage session.
         * @member {proto.ISessionAPIv1Server|null|undefined} session
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.session = null;

        /**
         * ServerMessage channel.
         * @member {proto.IChannelAPIv1Server|null|undefined} channel
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.channel = null;

        /**
         * ServerMessage presence.
         * @member {proto.IPresenceAPIv1Server|null|undefined} presence
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.presence = null;

        /**
         * ServerMessage udp.
         * @member {proto.IUDPAPIv1Server|null|undefined} udp
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.udp = null;

        /**
         * ServerMessage talkburstReception.
         * @member {proto.ITalkburstReceptionAPIv1Server|null|undefined} talkburstReception
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.talkburstReception = null;

        /**
         * ServerMessage talkburstTransmission.
         * @member {proto.ITalkburstTransmissionAPIv1Server|null|undefined} talkburstTransmission
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.talkburstTransmission = null;

        /**
         * ServerMessage phoneBook.
         * @member {proto.IPhoneBookAPIv1Server|null|undefined} phoneBook
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.phoneBook = null;

        /**
         * ServerMessage receiveCall.
         * @member {proto.IReceiveCallAPIv1Server|null|undefined} receiveCall
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.receiveCall = null;

        /**
         * ServerMessage httpRequest.
         * @member {proto.IHTTPRequestAPIv1Server|null|undefined} httpRequest
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.httpRequest = null;

        /**
         * ServerMessage emergency.
         * @member {proto.IEmergencyAPIv1Server|null|undefined} emergency
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.emergency = null;

        /**
         * ServerMessage audio.
         * @member {proto.IAudioAPIv1Server|null|undefined} audio
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.audio = null;

        /**
         * ServerMessage thirdPartyCallControl.
         * @member {proto.IThirdPartyCallControlAPIv1Server|null|undefined} thirdPartyCallControl
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.thirdPartyCallControl = null;

        /**
         * ServerMessage queueManagement.
         * @member {proto.IQueueManagementAPIv1Server|null|undefined} queueManagement
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.queueManagement = null;

        /**
         * ServerMessage locationReport.
         * @member {proto.ILocationReportAPIv1Server|null|undefined} locationReport
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.locationReport = null;

        /**
         * ServerMessage locationEnquiry.
         * @member {proto.ILocationEnquiryAPIv1Server|null|undefined} locationEnquiry
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.locationEnquiry = null;

        /**
         * ServerMessage notification.
         * @member {proto.INotificationAPIv1Server|null|undefined} notification
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.notification = null;

        /**
         * ServerMessage status.
         * @member {proto.IStatusAPIv1Server|null|undefined} status
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.status = null;

        /**
         * ServerMessage callsignV2.
         * @member {proto.ICallsignAPIv2Server|null|undefined} callsignV2
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.callsignV2 = null;

        /**
         * ServerMessage terminateRequest.
         * @member {proto.ITerminateConnection|null|undefined} terminateRequest
         * @memberof proto.ServerMessage
         * @instance
         */
        ServerMessage.prototype.terminateRequest = null;

        /**
         * Creates a new ServerMessage instance using the specified properties.
         * @function create
         * @memberof proto.ServerMessage
         * @static
         * @param {proto.IServerMessage=} [properties] Properties to set
         * @returns {proto.ServerMessage} ServerMessage instance
         */
        ServerMessage.create = function create(properties) {
            return new ServerMessage(properties);
        };

        /**
         * Encodes the specified ServerMessage message. Does not implicitly {@link proto.ServerMessage.verify|verify} messages.
         * @function encode
         * @memberof proto.ServerMessage
         * @static
         * @param {proto.IServerMessage} message ServerMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.requestId);
            if (message.cancelRequest != null && Object.hasOwnProperty.call(message, "cancelRequest"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.cancelRequest);
            if (message.responseCode != null && Object.hasOwnProperty.call(message, "responseCode"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.responseCode);
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.response);
            if (message.keepAlivePing != null && Object.hasOwnProperty.call(message, "keepAlivePing"))
                $root.proto.KeepAliveAPIv1PingRequest.encode(message.keepAlivePing, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.authenticate != null && Object.hasOwnProperty.call(message, "authenticate"))
                $root.proto.AuthenticateAPIv1Server.encode(message.authenticate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.session != null && Object.hasOwnProperty.call(message, "session"))
                $root.proto.SessionAPIv1Server.encode(message.session, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                $root.proto.ChannelAPIv1Server.encode(message.channel, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.presence != null && Object.hasOwnProperty.call(message, "presence"))
                $root.proto.PresenceAPIv1Server.encode(message.presence, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.udp != null && Object.hasOwnProperty.call(message, "udp"))
                $root.proto.UDPAPIv1Server.encode(message.udp, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.talkburstReception != null && Object.hasOwnProperty.call(message, "talkburstReception"))
                $root.proto.TalkburstReceptionAPIv1Server.encode(message.talkburstReception, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.talkburstTransmission != null && Object.hasOwnProperty.call(message, "talkburstTransmission"))
                $root.proto.TalkburstTransmissionAPIv1Server.encode(message.talkburstTransmission, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.phoneBook != null && Object.hasOwnProperty.call(message, "phoneBook"))
                $root.proto.PhoneBookAPIv1Server.encode(message.phoneBook, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.receiveCall != null && Object.hasOwnProperty.call(message, "receiveCall"))
                $root.proto.ReceiveCallAPIv1Server.encode(message.receiveCall, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.httpRequest != null && Object.hasOwnProperty.call(message, "httpRequest"))
                $root.proto.HTTPRequestAPIv1Server.encode(message.httpRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.emergency != null && Object.hasOwnProperty.call(message, "emergency"))
                $root.proto.EmergencyAPIv1Server.encode(message.emergency, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                $root.proto.AudioAPIv1Server.encode(message.audio, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.thirdPartyCallControl != null && Object.hasOwnProperty.call(message, "thirdPartyCallControl"))
                $root.proto.ThirdPartyCallControlAPIv1Server.encode(message.thirdPartyCallControl, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.queueManagement != null && Object.hasOwnProperty.call(message, "queueManagement"))
                $root.proto.QueueManagementAPIv1Server.encode(message.queueManagement, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.locationReport != null && Object.hasOwnProperty.call(message, "locationReport"))
                $root.proto.LocationReportAPIv1Server.encode(message.locationReport, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.locationEnquiry != null && Object.hasOwnProperty.call(message, "locationEnquiry"))
                $root.proto.LocationEnquiryAPIv1Server.encode(message.locationEnquiry, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                $root.proto.NotificationAPIv1Server.encode(message.notification, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                $root.proto.StatusAPIv1Server.encode(message.status, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.callsignV2 != null && Object.hasOwnProperty.call(message, "callsignV2"))
                $root.proto.CallsignAPIv2Server.encode(message.callsignV2, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.terminateRequest != null && Object.hasOwnProperty.call(message, "terminateRequest"))
                $root.proto.TerminateConnection.encode(message.terminateRequest, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ServerMessage message, length delimited. Does not implicitly {@link proto.ServerMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ServerMessage
         * @static
         * @param {proto.IServerMessage} message ServerMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServerMessage message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ServerMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ServerMessage} ServerMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ServerMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.requestId = reader.int64();
                        break;
                    }
                case 2: {
                        message.cancelRequest = reader.bool();
                        break;
                    }
                case 3: {
                        message.responseCode = reader.int32();
                        break;
                    }
                case 4: {
                        message.response = reader.bytes();
                        break;
                    }
                case 5: {
                        message.keepAlivePing = $root.proto.KeepAliveAPIv1PingRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.authenticate = $root.proto.AuthenticateAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.session = $root.proto.SessionAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.channel = $root.proto.ChannelAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.presence = $root.proto.PresenceAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.udp = $root.proto.UDPAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.talkburstReception = $root.proto.TalkburstReceptionAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.talkburstTransmission = $root.proto.TalkburstTransmissionAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.phoneBook = $root.proto.PhoneBookAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.receiveCall = $root.proto.ReceiveCallAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.httpRequest = $root.proto.HTTPRequestAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.emergency = $root.proto.EmergencyAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.audio = $root.proto.AudioAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.thirdPartyCallControl = $root.proto.ThirdPartyCallControlAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.queueManagement = $root.proto.QueueManagementAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.locationReport = $root.proto.LocationReportAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.locationEnquiry = $root.proto.LocationEnquiryAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.notification = $root.proto.NotificationAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.status = $root.proto.StatusAPIv1Server.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.callsignV2 = $root.proto.CallsignAPIv2Server.decode(reader, reader.uint32());
                        break;
                    }
                case 99: {
                        message.terminateRequest = $root.proto.TerminateConnection.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServerMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ServerMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ServerMessage} ServerMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServerMessage message.
         * @function verify
         * @memberof proto.ServerMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                    return "requestId: integer|Long expected";
            if (message.cancelRequest != null && message.hasOwnProperty("cancelRequest"))
                if (typeof message.cancelRequest !== "boolean")
                    return "cancelRequest: boolean expected";
            if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                if (!$util.isInteger(message.responseCode))
                    return "responseCode: integer expected";
            if (message.response != null && message.hasOwnProperty("response"))
                if (!(message.response && typeof message.response.length === "number" || $util.isString(message.response)))
                    return "response: buffer expected";
            if (message.keepAlivePing != null && message.hasOwnProperty("keepAlivePing")) {
                var error = $root.proto.KeepAliveAPIv1PingRequest.verify(message.keepAlivePing);
                if (error)
                    return "keepAlivePing." + error;
            }
            if (message.authenticate != null && message.hasOwnProperty("authenticate")) {
                var error = $root.proto.AuthenticateAPIv1Server.verify(message.authenticate);
                if (error)
                    return "authenticate." + error;
            }
            if (message.session != null && message.hasOwnProperty("session")) {
                var error = $root.proto.SessionAPIv1Server.verify(message.session);
                if (error)
                    return "session." + error;
            }
            if (message.channel != null && message.hasOwnProperty("channel")) {
                var error = $root.proto.ChannelAPIv1Server.verify(message.channel);
                if (error)
                    return "channel." + error;
            }
            if (message.presence != null && message.hasOwnProperty("presence")) {
                var error = $root.proto.PresenceAPIv1Server.verify(message.presence);
                if (error)
                    return "presence." + error;
            }
            if (message.udp != null && message.hasOwnProperty("udp")) {
                var error = $root.proto.UDPAPIv1Server.verify(message.udp);
                if (error)
                    return "udp." + error;
            }
            if (message.talkburstReception != null && message.hasOwnProperty("talkburstReception")) {
                var error = $root.proto.TalkburstReceptionAPIv1Server.verify(message.talkburstReception);
                if (error)
                    return "talkburstReception." + error;
            }
            if (message.talkburstTransmission != null && message.hasOwnProperty("talkburstTransmission")) {
                var error = $root.proto.TalkburstTransmissionAPIv1Server.verify(message.talkburstTransmission);
                if (error)
                    return "talkburstTransmission." + error;
            }
            if (message.phoneBook != null && message.hasOwnProperty("phoneBook")) {
                var error = $root.proto.PhoneBookAPIv1Server.verify(message.phoneBook);
                if (error)
                    return "phoneBook." + error;
            }
            if (message.receiveCall != null && message.hasOwnProperty("receiveCall")) {
                var error = $root.proto.ReceiveCallAPIv1Server.verify(message.receiveCall);
                if (error)
                    return "receiveCall." + error;
            }
            if (message.httpRequest != null && message.hasOwnProperty("httpRequest")) {
                var error = $root.proto.HTTPRequestAPIv1Server.verify(message.httpRequest);
                if (error)
                    return "httpRequest." + error;
            }
            if (message.emergency != null && message.hasOwnProperty("emergency")) {
                var error = $root.proto.EmergencyAPIv1Server.verify(message.emergency);
                if (error)
                    return "emergency." + error;
            }
            if (message.audio != null && message.hasOwnProperty("audio")) {
                var error = $root.proto.AudioAPIv1Server.verify(message.audio);
                if (error)
                    return "audio." + error;
            }
            if (message.thirdPartyCallControl != null && message.hasOwnProperty("thirdPartyCallControl")) {
                var error = $root.proto.ThirdPartyCallControlAPIv1Server.verify(message.thirdPartyCallControl);
                if (error)
                    return "thirdPartyCallControl." + error;
            }
            if (message.queueManagement != null && message.hasOwnProperty("queueManagement")) {
                var error = $root.proto.QueueManagementAPIv1Server.verify(message.queueManagement);
                if (error)
                    return "queueManagement." + error;
            }
            if (message.locationReport != null && message.hasOwnProperty("locationReport")) {
                var error = $root.proto.LocationReportAPIv1Server.verify(message.locationReport);
                if (error)
                    return "locationReport." + error;
            }
            if (message.locationEnquiry != null && message.hasOwnProperty("locationEnquiry")) {
                var error = $root.proto.LocationEnquiryAPIv1Server.verify(message.locationEnquiry);
                if (error)
                    return "locationEnquiry." + error;
            }
            if (message.notification != null && message.hasOwnProperty("notification")) {
                var error = $root.proto.NotificationAPIv1Server.verify(message.notification);
                if (error)
                    return "notification." + error;
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                var error = $root.proto.StatusAPIv1Server.verify(message.status);
                if (error)
                    return "status." + error;
            }
            if (message.callsignV2 != null && message.hasOwnProperty("callsignV2")) {
                var error = $root.proto.CallsignAPIv2Server.verify(message.callsignV2);
                if (error)
                    return "callsignV2." + error;
            }
            if (message.terminateRequest != null && message.hasOwnProperty("terminateRequest")) {
                var error = $root.proto.TerminateConnection.verify(message.terminateRequest);
                if (error)
                    return "terminateRequest." + error;
            }
            return null;
        };

        /**
         * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ServerMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ServerMessage} ServerMessage
         */
        ServerMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ServerMessage)
                return object;
            var message = new $root.proto.ServerMessage();
            if (object.requestId != null)
                if ($util.Long)
                    (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = false;
                else if (typeof object.requestId === "string")
                    message.requestId = parseInt(object.requestId, 10);
                else if (typeof object.requestId === "number")
                    message.requestId = object.requestId;
                else if (typeof object.requestId === "object")
                    message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber();
            if (object.cancelRequest != null)
                message.cancelRequest = Boolean(object.cancelRequest);
            if (object.responseCode != null)
                message.responseCode = object.responseCode | 0;
            if (object.response != null)
                if (typeof object.response === "string")
                    $util.base64.decode(object.response, message.response = $util.newBuffer($util.base64.length(object.response)), 0);
                else if (object.response.length >= 0)
                    message.response = object.response;
            if (object.keepAlivePing != null) {
                if (typeof object.keepAlivePing !== "object")
                    throw TypeError(".proto.ServerMessage.keepAlivePing: object expected");
                message.keepAlivePing = $root.proto.KeepAliveAPIv1PingRequest.fromObject(object.keepAlivePing);
            }
            if (object.authenticate != null) {
                if (typeof object.authenticate !== "object")
                    throw TypeError(".proto.ServerMessage.authenticate: object expected");
                message.authenticate = $root.proto.AuthenticateAPIv1Server.fromObject(object.authenticate);
            }
            if (object.session != null) {
                if (typeof object.session !== "object")
                    throw TypeError(".proto.ServerMessage.session: object expected");
                message.session = $root.proto.SessionAPIv1Server.fromObject(object.session);
            }
            if (object.channel != null) {
                if (typeof object.channel !== "object")
                    throw TypeError(".proto.ServerMessage.channel: object expected");
                message.channel = $root.proto.ChannelAPIv1Server.fromObject(object.channel);
            }
            if (object.presence != null) {
                if (typeof object.presence !== "object")
                    throw TypeError(".proto.ServerMessage.presence: object expected");
                message.presence = $root.proto.PresenceAPIv1Server.fromObject(object.presence);
            }
            if (object.udp != null) {
                if (typeof object.udp !== "object")
                    throw TypeError(".proto.ServerMessage.udp: object expected");
                message.udp = $root.proto.UDPAPIv1Server.fromObject(object.udp);
            }
            if (object.talkburstReception != null) {
                if (typeof object.talkburstReception !== "object")
                    throw TypeError(".proto.ServerMessage.talkburstReception: object expected");
                message.talkburstReception = $root.proto.TalkburstReceptionAPIv1Server.fromObject(object.talkburstReception);
            }
            if (object.talkburstTransmission != null) {
                if (typeof object.talkburstTransmission !== "object")
                    throw TypeError(".proto.ServerMessage.talkburstTransmission: object expected");
                message.talkburstTransmission = $root.proto.TalkburstTransmissionAPIv1Server.fromObject(object.talkburstTransmission);
            }
            if (object.phoneBook != null) {
                if (typeof object.phoneBook !== "object")
                    throw TypeError(".proto.ServerMessage.phoneBook: object expected");
                message.phoneBook = $root.proto.PhoneBookAPIv1Server.fromObject(object.phoneBook);
            }
            if (object.receiveCall != null) {
                if (typeof object.receiveCall !== "object")
                    throw TypeError(".proto.ServerMessage.receiveCall: object expected");
                message.receiveCall = $root.proto.ReceiveCallAPIv1Server.fromObject(object.receiveCall);
            }
            if (object.httpRequest != null) {
                if (typeof object.httpRequest !== "object")
                    throw TypeError(".proto.ServerMessage.httpRequest: object expected");
                message.httpRequest = $root.proto.HTTPRequestAPIv1Server.fromObject(object.httpRequest);
            }
            if (object.emergency != null) {
                if (typeof object.emergency !== "object")
                    throw TypeError(".proto.ServerMessage.emergency: object expected");
                message.emergency = $root.proto.EmergencyAPIv1Server.fromObject(object.emergency);
            }
            if (object.audio != null) {
                if (typeof object.audio !== "object")
                    throw TypeError(".proto.ServerMessage.audio: object expected");
                message.audio = $root.proto.AudioAPIv1Server.fromObject(object.audio);
            }
            if (object.thirdPartyCallControl != null) {
                if (typeof object.thirdPartyCallControl !== "object")
                    throw TypeError(".proto.ServerMessage.thirdPartyCallControl: object expected");
                message.thirdPartyCallControl = $root.proto.ThirdPartyCallControlAPIv1Server.fromObject(object.thirdPartyCallControl);
            }
            if (object.queueManagement != null) {
                if (typeof object.queueManagement !== "object")
                    throw TypeError(".proto.ServerMessage.queueManagement: object expected");
                message.queueManagement = $root.proto.QueueManagementAPIv1Server.fromObject(object.queueManagement);
            }
            if (object.locationReport != null) {
                if (typeof object.locationReport !== "object")
                    throw TypeError(".proto.ServerMessage.locationReport: object expected");
                message.locationReport = $root.proto.LocationReportAPIv1Server.fromObject(object.locationReport);
            }
            if (object.locationEnquiry != null) {
                if (typeof object.locationEnquiry !== "object")
                    throw TypeError(".proto.ServerMessage.locationEnquiry: object expected");
                message.locationEnquiry = $root.proto.LocationEnquiryAPIv1Server.fromObject(object.locationEnquiry);
            }
            if (object.notification != null) {
                if (typeof object.notification !== "object")
                    throw TypeError(".proto.ServerMessage.notification: object expected");
                message.notification = $root.proto.NotificationAPIv1Server.fromObject(object.notification);
            }
            if (object.status != null) {
                if (typeof object.status !== "object")
                    throw TypeError(".proto.ServerMessage.status: object expected");
                message.status = $root.proto.StatusAPIv1Server.fromObject(object.status);
            }
            if (object.callsignV2 != null) {
                if (typeof object.callsignV2 !== "object")
                    throw TypeError(".proto.ServerMessage.callsignV2: object expected");
                message.callsignV2 = $root.proto.CallsignAPIv2Server.fromObject(object.callsignV2);
            }
            if (object.terminateRequest != null) {
                if (typeof object.terminateRequest !== "object")
                    throw TypeError(".proto.ServerMessage.terminateRequest: object expected");
                message.terminateRequest = $root.proto.TerminateConnection.fromObject(object.terminateRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ServerMessage
         * @static
         * @param {proto.ServerMessage} message ServerMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestId = options.longs === String ? "0" : 0;
                object.cancelRequest = false;
                object.responseCode = 0;
                if (options.bytes === String)
                    object.response = "";
                else {
                    object.response = [];
                    if (options.bytes !== Array)
                        object.response = $util.newBuffer(object.response);
                }
                object.keepAlivePing = null;
                object.authenticate = null;
                object.session = null;
                object.channel = null;
                object.presence = null;
                object.udp = null;
                object.talkburstReception = null;
                object.talkburstTransmission = null;
                object.phoneBook = null;
                object.receiveCall = null;
                object.httpRequest = null;
                object.emergency = null;
                object.audio = null;
                object.thirdPartyCallControl = null;
                object.queueManagement = null;
                object.locationReport = null;
                object.locationEnquiry = null;
                object.notification = null;
                object.status = null;
                object.callsignV2 = null;
                object.terminateRequest = null;
            }
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (typeof message.requestId === "number")
                    object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                else
                    object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber() : message.requestId;
            if (message.cancelRequest != null && message.hasOwnProperty("cancelRequest"))
                object.cancelRequest = message.cancelRequest;
            if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                object.responseCode = message.responseCode;
            if (message.response != null && message.hasOwnProperty("response"))
                object.response = options.bytes === String ? $util.base64.encode(message.response, 0, message.response.length) : options.bytes === Array ? Array.prototype.slice.call(message.response) : message.response;
            if (message.keepAlivePing != null && message.hasOwnProperty("keepAlivePing"))
                object.keepAlivePing = $root.proto.KeepAliveAPIv1PingRequest.toObject(message.keepAlivePing, options);
            if (message.authenticate != null && message.hasOwnProperty("authenticate"))
                object.authenticate = $root.proto.AuthenticateAPIv1Server.toObject(message.authenticate, options);
            if (message.session != null && message.hasOwnProperty("session"))
                object.session = $root.proto.SessionAPIv1Server.toObject(message.session, options);
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = $root.proto.ChannelAPIv1Server.toObject(message.channel, options);
            if (message.presence != null && message.hasOwnProperty("presence"))
                object.presence = $root.proto.PresenceAPIv1Server.toObject(message.presence, options);
            if (message.udp != null && message.hasOwnProperty("udp"))
                object.udp = $root.proto.UDPAPIv1Server.toObject(message.udp, options);
            if (message.talkburstReception != null && message.hasOwnProperty("talkburstReception"))
                object.talkburstReception = $root.proto.TalkburstReceptionAPIv1Server.toObject(message.talkburstReception, options);
            if (message.talkburstTransmission != null && message.hasOwnProperty("talkburstTransmission"))
                object.talkburstTransmission = $root.proto.TalkburstTransmissionAPIv1Server.toObject(message.talkburstTransmission, options);
            if (message.phoneBook != null && message.hasOwnProperty("phoneBook"))
                object.phoneBook = $root.proto.PhoneBookAPIv1Server.toObject(message.phoneBook, options);
            if (message.receiveCall != null && message.hasOwnProperty("receiveCall"))
                object.receiveCall = $root.proto.ReceiveCallAPIv1Server.toObject(message.receiveCall, options);
            if (message.httpRequest != null && message.hasOwnProperty("httpRequest"))
                object.httpRequest = $root.proto.HTTPRequestAPIv1Server.toObject(message.httpRequest, options);
            if (message.emergency != null && message.hasOwnProperty("emergency"))
                object.emergency = $root.proto.EmergencyAPIv1Server.toObject(message.emergency, options);
            if (message.audio != null && message.hasOwnProperty("audio"))
                object.audio = $root.proto.AudioAPIv1Server.toObject(message.audio, options);
            if (message.thirdPartyCallControl != null && message.hasOwnProperty("thirdPartyCallControl"))
                object.thirdPartyCallControl = $root.proto.ThirdPartyCallControlAPIv1Server.toObject(message.thirdPartyCallControl, options);
            if (message.queueManagement != null && message.hasOwnProperty("queueManagement"))
                object.queueManagement = $root.proto.QueueManagementAPIv1Server.toObject(message.queueManagement, options);
            if (message.locationReport != null && message.hasOwnProperty("locationReport"))
                object.locationReport = $root.proto.LocationReportAPIv1Server.toObject(message.locationReport, options);
            if (message.locationEnquiry != null && message.hasOwnProperty("locationEnquiry"))
                object.locationEnquiry = $root.proto.LocationEnquiryAPIv1Server.toObject(message.locationEnquiry, options);
            if (message.notification != null && message.hasOwnProperty("notification"))
                object.notification = $root.proto.NotificationAPIv1Server.toObject(message.notification, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = $root.proto.StatusAPIv1Server.toObject(message.status, options);
            if (message.callsignV2 != null && message.hasOwnProperty("callsignV2"))
                object.callsignV2 = $root.proto.CallsignAPIv2Server.toObject(message.callsignV2, options);
            if (message.terminateRequest != null && message.hasOwnProperty("terminateRequest"))
                object.terminateRequest = $root.proto.TerminateConnection.toObject(message.terminateRequest, options);
            return object;
        };

        /**
         * Converts this ServerMessage to JSON.
         * @function toJSON
         * @memberof proto.ServerMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServerMessage
         * @function getTypeUrl
         * @memberof proto.ServerMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServerMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ServerMessage";
        };

        return ServerMessage;
    })();

    /**
     * ResponseCode enum.
     * @name proto.ResponseCode
     * @enum {number}
     * @property {number} TRYING=100 TRYING value
     * @property {number} RINGING=180 RINGING value
     * @property {number} OK=200 OK value
     * @property {number} MESSAGE_DELIVERY_STATUS_UNKNOWN=201 MESSAGE_DELIVERY_STATUS_UNKNOWN value
     * @property {number} REDIRECT=301 REDIRECT value
     * @property {number} NETWORK_ERROR=350 NETWORK_ERROR value
     * @property {number} NO_JOINED_GROUP=360 NO_JOINED_GROUP value
     * @property {number} MESSAGE_DELIVERY_FAILED=370 MESSAGE_DELIVERY_FAILED value
     * @property {number} RESTRICTED_DEVICE=380 RESTRICTED_DEVICE value
     * @property {number} RESTRICTED_NETWORK=381 RESTRICTED_NETWORK value
     * @property {number} SMS_LIMIT_REACHED=382 SMS_LIMIT_REACHED value
     * @property {number} TWO_FACTOR_ERROR=383 TWO_FACTOR_ERROR value
     * @property {number} BAD_REALM=384 BAD_REALM value
     * @property {number} TOKEN_EXPIRED=385 TOKEN_EXPIRED value
     * @property {number} UNKNOWN_PHONE_NUMBER=386 UNKNOWN_PHONE_NUMBER value
     * @property {number} UNKNOWN_EMAIL_ADDRESS=387 UNKNOWN_EMAIL_ADDRESS value
     * @property {number} INVALID_LOGIN_OR_PASSWORD=389 INVALID_LOGIN_OR_PASSWORD value
     * @property {number} BAD_CHALLENGE_RESPONSE=390 BAD_CHALLENGE_RESPONSE value
     * @property {number} TALKBURST_TRANSMISSION_DENIED=391 TALKBURST_TRANSMISSION_DENIED value
     * @property {number} ALREADY_IN_SESSION=392 ALREADY_IN_SESSION value
     * @property {number} LOCATION_UNAVAILABLE=393 LOCATION_UNAVAILABLE value
     * @property {number} LOCATION_UNKNOWN=394 LOCATION_UNKNOWN value
     * @property {number} PUSH_REQUIRED=395 PUSH_REQUIRED value
     * @property {number} TOKEN_INVALID=396 TOKEN_INVALID value
     * @property {number} GENERAL_FAILURE=399 GENERAL_FAILURE value
     * @property {number} BAD_REQUEST=400 BAD_REQUEST value
     * @property {number} UNAUTHORIZED=401 UNAUTHORIZED value
     * @property {number} PAYMENT_REQUIRED=402 PAYMENT_REQUIRED value
     * @property {number} FORBIDDEN=403 FORBIDDEN value
     * @property {number} NOT_FOUND=404 NOT_FOUND value
     * @property {number} AUTH_METHOD_NOT_SUPPORTED=405 AUTH_METHOD_NOT_SUPPORTED value
     * @property {number} REQUEST_TIMEOUT=408 REQUEST_TIMEOUT value
     * @property {number} COMPANY_INVALID=409 COMPANY_INVALID value
     * @property {number} TOO_MANY_REQUESTS=429 TOO_MANY_REQUESTS value
     * @property {number} NOT_ONLINE=450 NOT_ONLINE value
     * @property {number} MULTIPLE_REASONS=451 MULTIPLE_REASONS value
     * @property {number} TEMPORARILY_UNAVAILABLE=480 TEMPORARILY_UNAVAILABLE value
     * @property {number} BUSY_HERE=486 BUSY_HERE value
     * @property {number} REQUEST_TERMINATED=487 REQUEST_TERMINATED value
     * @property {number} NOT_ACCEPTABLE_HERE=488 NOT_ACCEPTABLE_HERE value
     * @property {number} UNSUPPORTED_BY_PEER=489 UNSUPPORTED_BY_PEER value
     * @property {number} REQUEST_PENDING=491 REQUEST_PENDING value
     * @property {number} ENCRYPTION_REQUIRED=497 ENCRYPTION_REQUIRED value
     * @property {number} ENCRYPTION_NOT_ALLOWED=498 ENCRYPTION_NOT_ALLOWED value
     * @property {number} REQUEST_UNKNOWN=499 REQUEST_UNKNOWN value
     * @property {number} INTERNAL_ERROR=500 INTERNAL_ERROR value
     * @property {number} SERVICE_UNAVAILABLE=503 SERVICE_UNAVAILABLE value
     * @property {number} VERSION_NOT_SUPPORTED=505 VERSION_NOT_SUPPORTED value
     * @property {number} DECLINED=603 DECLINED value
     */
    proto.ResponseCode = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[100] = "TRYING"] = 100;
        values[valuesById[180] = "RINGING"] = 180;
        values[valuesById[200] = "OK"] = 200;
        values[valuesById[201] = "MESSAGE_DELIVERY_STATUS_UNKNOWN"] = 201;
        values[valuesById[301] = "REDIRECT"] = 301;
        values[valuesById[350] = "NETWORK_ERROR"] = 350;
        values[valuesById[360] = "NO_JOINED_GROUP"] = 360;
        values[valuesById[370] = "MESSAGE_DELIVERY_FAILED"] = 370;
        values[valuesById[380] = "RESTRICTED_DEVICE"] = 380;
        values[valuesById[381] = "RESTRICTED_NETWORK"] = 381;
        values[valuesById[382] = "SMS_LIMIT_REACHED"] = 382;
        values[valuesById[383] = "TWO_FACTOR_ERROR"] = 383;
        values[valuesById[384] = "BAD_REALM"] = 384;
        values[valuesById[385] = "TOKEN_EXPIRED"] = 385;
        values[valuesById[386] = "UNKNOWN_PHONE_NUMBER"] = 386;
        values[valuesById[387] = "UNKNOWN_EMAIL_ADDRESS"] = 387;
        values[valuesById[389] = "INVALID_LOGIN_OR_PASSWORD"] = 389;
        values[valuesById[390] = "BAD_CHALLENGE_RESPONSE"] = 390;
        values[valuesById[391] = "TALKBURST_TRANSMISSION_DENIED"] = 391;
        values[valuesById[392] = "ALREADY_IN_SESSION"] = 392;
        values[valuesById[393] = "LOCATION_UNAVAILABLE"] = 393;
        values[valuesById[394] = "LOCATION_UNKNOWN"] = 394;
        values[valuesById[395] = "PUSH_REQUIRED"] = 395;
        values[valuesById[396] = "TOKEN_INVALID"] = 396;
        values[valuesById[399] = "GENERAL_FAILURE"] = 399;
        values[valuesById[400] = "BAD_REQUEST"] = 400;
        values[valuesById[401] = "UNAUTHORIZED"] = 401;
        values[valuesById[402] = "PAYMENT_REQUIRED"] = 402;
        values[valuesById[403] = "FORBIDDEN"] = 403;
        values[valuesById[404] = "NOT_FOUND"] = 404;
        values[valuesById[405] = "AUTH_METHOD_NOT_SUPPORTED"] = 405;
        values[valuesById[408] = "REQUEST_TIMEOUT"] = 408;
        values[valuesById[409] = "COMPANY_INVALID"] = 409;
        values[valuesById[429] = "TOO_MANY_REQUESTS"] = 429;
        values[valuesById[450] = "NOT_ONLINE"] = 450;
        values[valuesById[451] = "MULTIPLE_REASONS"] = 451;
        values[valuesById[480] = "TEMPORARILY_UNAVAILABLE"] = 480;
        values[valuesById[486] = "BUSY_HERE"] = 486;
        values[valuesById[487] = "REQUEST_TERMINATED"] = 487;
        values[valuesById[488] = "NOT_ACCEPTABLE_HERE"] = 488;
        values[valuesById[489] = "UNSUPPORTED_BY_PEER"] = 489;
        values[valuesById[491] = "REQUEST_PENDING"] = 491;
        values[valuesById[497] = "ENCRYPTION_REQUIRED"] = 497;
        values[valuesById[498] = "ENCRYPTION_NOT_ALLOWED"] = 498;
        values[valuesById[499] = "REQUEST_UNKNOWN"] = 499;
        values[valuesById[500] = "INTERNAL_ERROR"] = 500;
        values[valuesById[503] = "SERVICE_UNAVAILABLE"] = 503;
        values[valuesById[505] = "VERSION_NOT_SUPPORTED"] = 505;
        values[valuesById[603] = "DECLINED"] = 603;
        return values;
    })();

    proto.RedirectResponse = (function() {

        /**
         * Properties of a RedirectResponse.
         * @memberof proto
         * @interface IRedirectResponse
         * @property {string} domain RedirectResponse domain
         * @property {boolean} encryptionRequired RedirectResponse encryptionRequired
         */

        /**
         * Constructs a new RedirectResponse.
         * @memberof proto
         * @classdesc Represents a RedirectResponse.
         * @implements IRedirectResponse
         * @constructor
         * @param {proto.IRedirectResponse=} [properties] Properties to set
         */
        function RedirectResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RedirectResponse domain.
         * @member {string} domain
         * @memberof proto.RedirectResponse
         * @instance
         */
        RedirectResponse.prototype.domain = "";

        /**
         * RedirectResponse encryptionRequired.
         * @member {boolean} encryptionRequired
         * @memberof proto.RedirectResponse
         * @instance
         */
        RedirectResponse.prototype.encryptionRequired = false;

        /**
         * Creates a new RedirectResponse instance using the specified properties.
         * @function create
         * @memberof proto.RedirectResponse
         * @static
         * @param {proto.IRedirectResponse=} [properties] Properties to set
         * @returns {proto.RedirectResponse} RedirectResponse instance
         */
        RedirectResponse.create = function create(properties) {
            return new RedirectResponse(properties);
        };

        /**
         * Encodes the specified RedirectResponse message. Does not implicitly {@link proto.RedirectResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.RedirectResponse
         * @static
         * @param {proto.IRedirectResponse} message RedirectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RedirectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.domain);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.encryptionRequired);
            return writer;
        };

        /**
         * Encodes the specified RedirectResponse message, length delimited. Does not implicitly {@link proto.RedirectResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.RedirectResponse
         * @static
         * @param {proto.IRedirectResponse} message RedirectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RedirectResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RedirectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.RedirectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.RedirectResponse} RedirectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RedirectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.RedirectResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.domain = reader.string();
                        break;
                    }
                case 2: {
                        message.encryptionRequired = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("domain"))
                throw $util.ProtocolError("missing required 'domain'", { instance: message });
            if (!message.hasOwnProperty("encryptionRequired"))
                throw $util.ProtocolError("missing required 'encryptionRequired'", { instance: message });
            return message;
        };

        /**
         * Decodes a RedirectResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.RedirectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.RedirectResponse} RedirectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RedirectResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RedirectResponse message.
         * @function verify
         * @memberof proto.RedirectResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RedirectResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.domain))
                return "domain: string expected";
            if (typeof message.encryptionRequired !== "boolean")
                return "encryptionRequired: boolean expected";
            return null;
        };

        /**
         * Creates a RedirectResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.RedirectResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.RedirectResponse} RedirectResponse
         */
        RedirectResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.RedirectResponse)
                return object;
            var message = new $root.proto.RedirectResponse();
            if (object.domain != null)
                message.domain = String(object.domain);
            if (object.encryptionRequired != null)
                message.encryptionRequired = Boolean(object.encryptionRequired);
            return message;
        };

        /**
         * Creates a plain object from a RedirectResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.RedirectResponse
         * @static
         * @param {proto.RedirectResponse} message RedirectResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RedirectResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.domain = "";
                object.encryptionRequired = false;
            }
            if (message.domain != null && message.hasOwnProperty("domain"))
                object.domain = message.domain;
            if (message.encryptionRequired != null && message.hasOwnProperty("encryptionRequired"))
                object.encryptionRequired = message.encryptionRequired;
            return object;
        };

        /**
         * Converts this RedirectResponse to JSON.
         * @function toJSON
         * @memberof proto.RedirectResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RedirectResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RedirectResponse
         * @function getTypeUrl
         * @memberof proto.RedirectResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RedirectResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.RedirectResponse";
        };

        return RedirectResponse;
    })();

    proto.CapabilitiesRequest = (function() {

        /**
         * Properties of a CapabilitiesRequest.
         * @memberof proto
         * @interface ICapabilitiesRequest
         * @property {string} userAgent CapabilitiesRequest userAgent
         * @property {string} deviceId CapabilitiesRequest deviceId
         * @property {proto.IPushToken|null} [pushToken] CapabilitiesRequest pushToken
         * @property {string|null} [domain] CapabilitiesRequest domain
         */

        /**
         * Constructs a new CapabilitiesRequest.
         * @memberof proto
         * @classdesc Represents a CapabilitiesRequest.
         * @implements ICapabilitiesRequest
         * @constructor
         * @param {proto.ICapabilitiesRequest=} [properties] Properties to set
         */
        function CapabilitiesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CapabilitiesRequest userAgent.
         * @member {string} userAgent
         * @memberof proto.CapabilitiesRequest
         * @instance
         */
        CapabilitiesRequest.prototype.userAgent = "";

        /**
         * CapabilitiesRequest deviceId.
         * @member {string} deviceId
         * @memberof proto.CapabilitiesRequest
         * @instance
         */
        CapabilitiesRequest.prototype.deviceId = "";

        /**
         * CapabilitiesRequest pushToken.
         * @member {proto.IPushToken|null|undefined} pushToken
         * @memberof proto.CapabilitiesRequest
         * @instance
         */
        CapabilitiesRequest.prototype.pushToken = null;

        /**
         * CapabilitiesRequest domain.
         * @member {string} domain
         * @memberof proto.CapabilitiesRequest
         * @instance
         */
        CapabilitiesRequest.prototype.domain = "";

        /**
         * Creates a new CapabilitiesRequest instance using the specified properties.
         * @function create
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {proto.ICapabilitiesRequest=} [properties] Properties to set
         * @returns {proto.CapabilitiesRequest} CapabilitiesRequest instance
         */
        CapabilitiesRequest.create = function create(properties) {
            return new CapabilitiesRequest(properties);
        };

        /**
         * Encodes the specified CapabilitiesRequest message. Does not implicitly {@link proto.CapabilitiesRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {proto.ICapabilitiesRequest} message CapabilitiesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CapabilitiesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userAgent);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.deviceId);
            if (message.pushToken != null && Object.hasOwnProperty.call(message, "pushToken"))
                $root.proto.PushToken.encode(message.pushToken, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.domain);
            return writer;
        };

        /**
         * Encodes the specified CapabilitiesRequest message, length delimited. Does not implicitly {@link proto.CapabilitiesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {proto.ICapabilitiesRequest} message CapabilitiesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CapabilitiesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CapabilitiesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CapabilitiesRequest} CapabilitiesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CapabilitiesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CapabilitiesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userAgent = reader.string();
                        break;
                    }
                case 2: {
                        message.deviceId = reader.string();
                        break;
                    }
                case 3: {
                        message.pushToken = $root.proto.PushToken.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.domain = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("userAgent"))
                throw $util.ProtocolError("missing required 'userAgent'", { instance: message });
            if (!message.hasOwnProperty("deviceId"))
                throw $util.ProtocolError("missing required 'deviceId'", { instance: message });
            return message;
        };

        /**
         * Decodes a CapabilitiesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CapabilitiesRequest} CapabilitiesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CapabilitiesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CapabilitiesRequest message.
         * @function verify
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CapabilitiesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.userAgent))
                return "userAgent: string expected";
            if (!$util.isString(message.deviceId))
                return "deviceId: string expected";
            if (message.pushToken != null && message.hasOwnProperty("pushToken")) {
                var error = $root.proto.PushToken.verify(message.pushToken);
                if (error)
                    return "pushToken." + error;
            }
            if (message.domain != null && message.hasOwnProperty("domain"))
                if (!$util.isString(message.domain))
                    return "domain: string expected";
            return null;
        };

        /**
         * Creates a CapabilitiesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CapabilitiesRequest} CapabilitiesRequest
         */
        CapabilitiesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CapabilitiesRequest)
                return object;
            var message = new $root.proto.CapabilitiesRequest();
            if (object.userAgent != null)
                message.userAgent = String(object.userAgent);
            if (object.deviceId != null)
                message.deviceId = String(object.deviceId);
            if (object.pushToken != null) {
                if (typeof object.pushToken !== "object")
                    throw TypeError(".proto.CapabilitiesRequest.pushToken: object expected");
                message.pushToken = $root.proto.PushToken.fromObject(object.pushToken);
            }
            if (object.domain != null)
                message.domain = String(object.domain);
            return message;
        };

        /**
         * Creates a plain object from a CapabilitiesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {proto.CapabilitiesRequest} message CapabilitiesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CapabilitiesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userAgent = "";
                object.deviceId = "";
                object.pushToken = null;
                object.domain = "";
            }
            if (message.userAgent != null && message.hasOwnProperty("userAgent"))
                object.userAgent = message.userAgent;
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = message.deviceId;
            if (message.pushToken != null && message.hasOwnProperty("pushToken"))
                object.pushToken = $root.proto.PushToken.toObject(message.pushToken, options);
            if (message.domain != null && message.hasOwnProperty("domain"))
                object.domain = message.domain;
            return object;
        };

        /**
         * Converts this CapabilitiesRequest to JSON.
         * @function toJSON
         * @memberof proto.CapabilitiesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CapabilitiesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CapabilitiesRequest
         * @function getTypeUrl
         * @memberof proto.CapabilitiesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CapabilitiesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CapabilitiesRequest";
        };

        return CapabilitiesRequest;
    })();

    proto.PushToken = (function() {

        /**
         * Properties of a PushToken.
         * @memberof proto
         * @interface IPushToken
         * @property {proto.IAPNToken|null} [apnToken] PushToken apnToken
         * @property {proto.IFCMToken|null} [fcmToken] PushToken fcmToken
         */

        /**
         * Constructs a new PushToken.
         * @memberof proto
         * @classdesc Represents a PushToken.
         * @implements IPushToken
         * @constructor
         * @param {proto.IPushToken=} [properties] Properties to set
         */
        function PushToken(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PushToken apnToken.
         * @member {proto.IAPNToken|null|undefined} apnToken
         * @memberof proto.PushToken
         * @instance
         */
        PushToken.prototype.apnToken = null;

        /**
         * PushToken fcmToken.
         * @member {proto.IFCMToken|null|undefined} fcmToken
         * @memberof proto.PushToken
         * @instance
         */
        PushToken.prototype.fcmToken = null;

        /**
         * Creates a new PushToken instance using the specified properties.
         * @function create
         * @memberof proto.PushToken
         * @static
         * @param {proto.IPushToken=} [properties] Properties to set
         * @returns {proto.PushToken} PushToken instance
         */
        PushToken.create = function create(properties) {
            return new PushToken(properties);
        };

        /**
         * Encodes the specified PushToken message. Does not implicitly {@link proto.PushToken.verify|verify} messages.
         * @function encode
         * @memberof proto.PushToken
         * @static
         * @param {proto.IPushToken} message PushToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PushToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.apnToken != null && Object.hasOwnProperty.call(message, "apnToken"))
                $root.proto.APNToken.encode(message.apnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.fcmToken != null && Object.hasOwnProperty.call(message, "fcmToken"))
                $root.proto.FCMToken.encode(message.fcmToken, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PushToken message, length delimited. Does not implicitly {@link proto.PushToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PushToken
         * @static
         * @param {proto.IPushToken} message PushToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PushToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PushToken message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PushToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PushToken} PushToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PushToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PushToken();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.apnToken = $root.proto.APNToken.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.fcmToken = $root.proto.FCMToken.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PushToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PushToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PushToken} PushToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PushToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PushToken message.
         * @function verify
         * @memberof proto.PushToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PushToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.apnToken != null && message.hasOwnProperty("apnToken")) {
                var error = $root.proto.APNToken.verify(message.apnToken);
                if (error)
                    return "apnToken." + error;
            }
            if (message.fcmToken != null && message.hasOwnProperty("fcmToken")) {
                var error = $root.proto.FCMToken.verify(message.fcmToken);
                if (error)
                    return "fcmToken." + error;
            }
            return null;
        };

        /**
         * Creates a PushToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PushToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PushToken} PushToken
         */
        PushToken.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PushToken)
                return object;
            var message = new $root.proto.PushToken();
            if (object.apnToken != null) {
                if (typeof object.apnToken !== "object")
                    throw TypeError(".proto.PushToken.apnToken: object expected");
                message.apnToken = $root.proto.APNToken.fromObject(object.apnToken);
            }
            if (object.fcmToken != null) {
                if (typeof object.fcmToken !== "object")
                    throw TypeError(".proto.PushToken.fcmToken: object expected");
                message.fcmToken = $root.proto.FCMToken.fromObject(object.fcmToken);
            }
            return message;
        };

        /**
         * Creates a plain object from a PushToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PushToken
         * @static
         * @param {proto.PushToken} message PushToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PushToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.apnToken = null;
                object.fcmToken = null;
            }
            if (message.apnToken != null && message.hasOwnProperty("apnToken"))
                object.apnToken = $root.proto.APNToken.toObject(message.apnToken, options);
            if (message.fcmToken != null && message.hasOwnProperty("fcmToken"))
                object.fcmToken = $root.proto.FCMToken.toObject(message.fcmToken, options);
            return object;
        };

        /**
         * Converts this PushToken to JSON.
         * @function toJSON
         * @memberof proto.PushToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PushToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PushToken
         * @function getTypeUrl
         * @memberof proto.PushToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PushToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PushToken";
        };

        return PushToken;
    })();

    proto.APNToken = (function() {

        /**
         * Properties of a APNToken.
         * @memberof proto
         * @interface IAPNToken
         * @property {Uint8Array|null} [voipToken] APNToken voipToken
         * @property {boolean} sandbox APNToken sandbox
         * @property {string} appBundle APNToken appBundle
         * @property {Uint8Array|null} [notificationToken] APNToken notificationToken
         * @property {Uint8Array|null} [pttToken] APNToken pttToken
         */

        /**
         * Constructs a new APNToken.
         * @memberof proto
         * @classdesc Represents a APNToken.
         * @implements IAPNToken
         * @constructor
         * @param {proto.IAPNToken=} [properties] Properties to set
         */
        function APNToken(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APNToken voipToken.
         * @member {Uint8Array} voipToken
         * @memberof proto.APNToken
         * @instance
         */
        APNToken.prototype.voipToken = $util.newBuffer([]);

        /**
         * APNToken sandbox.
         * @member {boolean} sandbox
         * @memberof proto.APNToken
         * @instance
         */
        APNToken.prototype.sandbox = false;

        /**
         * APNToken appBundle.
         * @member {string} appBundle
         * @memberof proto.APNToken
         * @instance
         */
        APNToken.prototype.appBundle = "";

        /**
         * APNToken notificationToken.
         * @member {Uint8Array} notificationToken
         * @memberof proto.APNToken
         * @instance
         */
        APNToken.prototype.notificationToken = $util.newBuffer([]);

        /**
         * APNToken pttToken.
         * @member {Uint8Array} pttToken
         * @memberof proto.APNToken
         * @instance
         */
        APNToken.prototype.pttToken = $util.newBuffer([]);

        /**
         * Creates a new APNToken instance using the specified properties.
         * @function create
         * @memberof proto.APNToken
         * @static
         * @param {proto.IAPNToken=} [properties] Properties to set
         * @returns {proto.APNToken} APNToken instance
         */
        APNToken.create = function create(properties) {
            return new APNToken(properties);
        };

        /**
         * Encodes the specified APNToken message. Does not implicitly {@link proto.APNToken.verify|verify} messages.
         * @function encode
         * @memberof proto.APNToken
         * @static
         * @param {proto.IAPNToken} message APNToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APNToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.voipToken != null && Object.hasOwnProperty.call(message, "voipToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.voipToken);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.sandbox);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.appBundle);
            if (message.notificationToken != null && Object.hasOwnProperty.call(message, "notificationToken"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.notificationToken);
            if (message.pttToken != null && Object.hasOwnProperty.call(message, "pttToken"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.pttToken);
            return writer;
        };

        /**
         * Encodes the specified APNToken message, length delimited. Does not implicitly {@link proto.APNToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.APNToken
         * @static
         * @param {proto.IAPNToken} message APNToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APNToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APNToken message from the specified reader or buffer.
         * @function decode
         * @memberof proto.APNToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.APNToken} APNToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APNToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.APNToken();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.voipToken = reader.bytes();
                        break;
                    }
                case 2: {
                        message.sandbox = reader.bool();
                        break;
                    }
                case 3: {
                        message.appBundle = reader.string();
                        break;
                    }
                case 4: {
                        message.notificationToken = reader.bytes();
                        break;
                    }
                case 5: {
                        message.pttToken = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sandbox"))
                throw $util.ProtocolError("missing required 'sandbox'", { instance: message });
            if (!message.hasOwnProperty("appBundle"))
                throw $util.ProtocolError("missing required 'appBundle'", { instance: message });
            return message;
        };

        /**
         * Decodes a APNToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.APNToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.APNToken} APNToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APNToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APNToken message.
         * @function verify
         * @memberof proto.APNToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APNToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.voipToken != null && message.hasOwnProperty("voipToken"))
                if (!(message.voipToken && typeof message.voipToken.length === "number" || $util.isString(message.voipToken)))
                    return "voipToken: buffer expected";
            if (typeof message.sandbox !== "boolean")
                return "sandbox: boolean expected";
            if (!$util.isString(message.appBundle))
                return "appBundle: string expected";
            if (message.notificationToken != null && message.hasOwnProperty("notificationToken"))
                if (!(message.notificationToken && typeof message.notificationToken.length === "number" || $util.isString(message.notificationToken)))
                    return "notificationToken: buffer expected";
            if (message.pttToken != null && message.hasOwnProperty("pttToken"))
                if (!(message.pttToken && typeof message.pttToken.length === "number" || $util.isString(message.pttToken)))
                    return "pttToken: buffer expected";
            return null;
        };

        /**
         * Creates a APNToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.APNToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.APNToken} APNToken
         */
        APNToken.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.APNToken)
                return object;
            var message = new $root.proto.APNToken();
            if (object.voipToken != null)
                if (typeof object.voipToken === "string")
                    $util.base64.decode(object.voipToken, message.voipToken = $util.newBuffer($util.base64.length(object.voipToken)), 0);
                else if (object.voipToken.length >= 0)
                    message.voipToken = object.voipToken;
            if (object.sandbox != null)
                message.sandbox = Boolean(object.sandbox);
            if (object.appBundle != null)
                message.appBundle = String(object.appBundle);
            if (object.notificationToken != null)
                if (typeof object.notificationToken === "string")
                    $util.base64.decode(object.notificationToken, message.notificationToken = $util.newBuffer($util.base64.length(object.notificationToken)), 0);
                else if (object.notificationToken.length >= 0)
                    message.notificationToken = object.notificationToken;
            if (object.pttToken != null)
                if (typeof object.pttToken === "string")
                    $util.base64.decode(object.pttToken, message.pttToken = $util.newBuffer($util.base64.length(object.pttToken)), 0);
                else if (object.pttToken.length >= 0)
                    message.pttToken = object.pttToken;
            return message;
        };

        /**
         * Creates a plain object from a APNToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.APNToken
         * @static
         * @param {proto.APNToken} message APNToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APNToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.voipToken = "";
                else {
                    object.voipToken = [];
                    if (options.bytes !== Array)
                        object.voipToken = $util.newBuffer(object.voipToken);
                }
                object.sandbox = false;
                object.appBundle = "";
                if (options.bytes === String)
                    object.notificationToken = "";
                else {
                    object.notificationToken = [];
                    if (options.bytes !== Array)
                        object.notificationToken = $util.newBuffer(object.notificationToken);
                }
                if (options.bytes === String)
                    object.pttToken = "";
                else {
                    object.pttToken = [];
                    if (options.bytes !== Array)
                        object.pttToken = $util.newBuffer(object.pttToken);
                }
            }
            if (message.voipToken != null && message.hasOwnProperty("voipToken"))
                object.voipToken = options.bytes === String ? $util.base64.encode(message.voipToken, 0, message.voipToken.length) : options.bytes === Array ? Array.prototype.slice.call(message.voipToken) : message.voipToken;
            if (message.sandbox != null && message.hasOwnProperty("sandbox"))
                object.sandbox = message.sandbox;
            if (message.appBundle != null && message.hasOwnProperty("appBundle"))
                object.appBundle = message.appBundle;
            if (message.notificationToken != null && message.hasOwnProperty("notificationToken"))
                object.notificationToken = options.bytes === String ? $util.base64.encode(message.notificationToken, 0, message.notificationToken.length) : options.bytes === Array ? Array.prototype.slice.call(message.notificationToken) : message.notificationToken;
            if (message.pttToken != null && message.hasOwnProperty("pttToken"))
                object.pttToken = options.bytes === String ? $util.base64.encode(message.pttToken, 0, message.pttToken.length) : options.bytes === Array ? Array.prototype.slice.call(message.pttToken) : message.pttToken;
            return object;
        };

        /**
         * Converts this APNToken to JSON.
         * @function toJSON
         * @memberof proto.APNToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APNToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for APNToken
         * @function getTypeUrl
         * @memberof proto.APNToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        APNToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.APNToken";
        };

        return APNToken;
    })();

    proto.FCMToken = (function() {

        /**
         * Properties of a FCMToken.
         * @memberof proto
         * @interface IFCMToken
         * @property {string|null} [notificationToken] FCMToken notificationToken
         */

        /**
         * Constructs a new FCMToken.
         * @memberof proto
         * @classdesc Represents a FCMToken.
         * @implements IFCMToken
         * @constructor
         * @param {proto.IFCMToken=} [properties] Properties to set
         */
        function FCMToken(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FCMToken notificationToken.
         * @member {string} notificationToken
         * @memberof proto.FCMToken
         * @instance
         */
        FCMToken.prototype.notificationToken = "";

        /**
         * Creates a new FCMToken instance using the specified properties.
         * @function create
         * @memberof proto.FCMToken
         * @static
         * @param {proto.IFCMToken=} [properties] Properties to set
         * @returns {proto.FCMToken} FCMToken instance
         */
        FCMToken.create = function create(properties) {
            return new FCMToken(properties);
        };

        /**
         * Encodes the specified FCMToken message. Does not implicitly {@link proto.FCMToken.verify|verify} messages.
         * @function encode
         * @memberof proto.FCMToken
         * @static
         * @param {proto.IFCMToken} message FCMToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FCMToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.notificationToken != null && Object.hasOwnProperty.call(message, "notificationToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.notificationToken);
            return writer;
        };

        /**
         * Encodes the specified FCMToken message, length delimited. Does not implicitly {@link proto.FCMToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.FCMToken
         * @static
         * @param {proto.IFCMToken} message FCMToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FCMToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FCMToken message from the specified reader or buffer.
         * @function decode
         * @memberof proto.FCMToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.FCMToken} FCMToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FCMToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.FCMToken();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.notificationToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FCMToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.FCMToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.FCMToken} FCMToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FCMToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FCMToken message.
         * @function verify
         * @memberof proto.FCMToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FCMToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.notificationToken != null && message.hasOwnProperty("notificationToken"))
                if (!$util.isString(message.notificationToken))
                    return "notificationToken: string expected";
            return null;
        };

        /**
         * Creates a FCMToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.FCMToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.FCMToken} FCMToken
         */
        FCMToken.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.FCMToken)
                return object;
            var message = new $root.proto.FCMToken();
            if (object.notificationToken != null)
                message.notificationToken = String(object.notificationToken);
            return message;
        };

        /**
         * Creates a plain object from a FCMToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.FCMToken
         * @static
         * @param {proto.FCMToken} message FCMToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FCMToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.notificationToken = "";
            if (message.notificationToken != null && message.hasOwnProperty("notificationToken"))
                object.notificationToken = message.notificationToken;
            return object;
        };

        /**
         * Converts this FCMToken to JSON.
         * @function toJSON
         * @memberof proto.FCMToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FCMToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FCMToken
         * @function getTypeUrl
         * @memberof proto.FCMToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FCMToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.FCMToken";
        };

        return FCMToken;
    })();

    proto.CapabilitiesResponse = (function() {

        /**
         * Properties of a CapabilitiesResponse.
         * @memberof proto
         * @interface ICapabilitiesResponse
         * @property {string} userAgent CapabilitiesResponse userAgent
         * @property {string} connectionId CapabilitiesResponse connectionId
         * @property {boolean|null} [supportsKeepAliveAPIv1] CapabilitiesResponse supportsKeepAliveAPIv1
         * @property {boolean|null} [supportsAuthenticateAPIv1] CapabilitiesResponse supportsAuthenticateAPIv1
         * @property {boolean|null} [supportsSessionAPIv1] CapabilitiesResponse supportsSessionAPIv1
         * @property {boolean|null} [supportsChannelAPIv1] CapabilitiesResponse supportsChannelAPIv1
         * @property {boolean|null} [supportsPresenceAPIv1] CapabilitiesResponse supportsPresenceAPIv1
         * @property {boolean|null} [supportsUDPAPIv1] CapabilitiesResponse supportsUDPAPIv1
         * @property {boolean|null} [supportsTalkburstReceptionAPIv1] CapabilitiesResponse supportsTalkburstReceptionAPIv1
         * @property {boolean|null} [supportsTalkburstTransmissionAPIv1] CapabilitiesResponse supportsTalkburstTransmissionAPIv1
         * @property {boolean|null} [supportsPhoneBookAPIv1] CapabilitiesResponse supportsPhoneBookAPIv1
         * @property {boolean|null} [supportsSetupCallAPIv1] CapabilitiesResponse supportsSetupCallAPIv1
         * @property {boolean|null} [supportsReceiveCallAPIv1] CapabilitiesResponse supportsReceiveCallAPIv1
         * @property {boolean|null} [supportsCustomActionAPIv1] CapabilitiesResponse supportsCustomActionAPIv1
         * @property {boolean|null} [supportsHTTPRequestAPIv1] CapabilitiesResponse supportsHTTPRequestAPIv1
         * @property {boolean|null} [supportsEmergencyAPIv1] CapabilitiesResponse supportsEmergencyAPIv1
         * @property {boolean|null} [supportsAudioAPIv1] CapabilitiesResponse supportsAudioAPIv1
         * @property {boolean|null} [supportsThirdPartyCallControlAPIv1] CapabilitiesResponse supportsThirdPartyCallControlAPIv1
         * @property {boolean|null} [supportsSMSAPIv1] CapabilitiesResponse supportsSMSAPIv1
         * @property {boolean|null} [supportsQueueManagementAPIv1] CapabilitiesResponse supportsQueueManagementAPIv1
         * @property {boolean|null} [supportsLocationReportAPIv1] CapabilitiesResponse supportsLocationReportAPIv1
         * @property {boolean|null} [supportsLocationEnquiryAPIv1] CapabilitiesResponse supportsLocationEnquiryAPIv1
         * @property {boolean|null} [supportsDetachAPIv1] CapabilitiesResponse supportsDetachAPIv1
         * @property {boolean|null} [supportsGoOfflineAPIv1] CapabilitiesResponse supportsGoOfflineAPIv1
         * @property {boolean|null} [supportsCallsignAPIv1] CapabilitiesResponse supportsCallsignAPIv1
         * @property {boolean|null} [supportsWebappAPIv1] CapabilitiesResponse supportsWebappAPIv1
         * @property {boolean|null} [supportsNotificationAPIv1] CapabilitiesResponse supportsNotificationAPIv1
         * @property {boolean|null} [supportsStatusAPIv1] CapabilitiesResponse supportsStatusAPIv1
         * @property {boolean|null} [supportsCallsignAPIv2] CapabilitiesResponse supportsCallsignAPIv2
         */

        /**
         * Constructs a new CapabilitiesResponse.
         * @memberof proto
         * @classdesc Represents a CapabilitiesResponse.
         * @implements ICapabilitiesResponse
         * @constructor
         * @param {proto.ICapabilitiesResponse=} [properties] Properties to set
         */
        function CapabilitiesResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CapabilitiesResponse userAgent.
         * @member {string} userAgent
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.userAgent = "";

        /**
         * CapabilitiesResponse connectionId.
         * @member {string} connectionId
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.connectionId = "";

        /**
         * CapabilitiesResponse supportsKeepAliveAPIv1.
         * @member {boolean} supportsKeepAliveAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsKeepAliveAPIv1 = false;

        /**
         * CapabilitiesResponse supportsAuthenticateAPIv1.
         * @member {boolean} supportsAuthenticateAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsAuthenticateAPIv1 = false;

        /**
         * CapabilitiesResponse supportsSessionAPIv1.
         * @member {boolean} supportsSessionAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsSessionAPIv1 = false;

        /**
         * CapabilitiesResponse supportsChannelAPIv1.
         * @member {boolean} supportsChannelAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsChannelAPIv1 = false;

        /**
         * CapabilitiesResponse supportsPresenceAPIv1.
         * @member {boolean} supportsPresenceAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsPresenceAPIv1 = false;

        /**
         * CapabilitiesResponse supportsUDPAPIv1.
         * @member {boolean} supportsUDPAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsUDPAPIv1 = false;

        /**
         * CapabilitiesResponse supportsTalkburstReceptionAPIv1.
         * @member {boolean} supportsTalkburstReceptionAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsTalkburstReceptionAPIv1 = false;

        /**
         * CapabilitiesResponse supportsTalkburstTransmissionAPIv1.
         * @member {boolean} supportsTalkburstTransmissionAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsTalkburstTransmissionAPIv1 = false;

        /**
         * CapabilitiesResponse supportsPhoneBookAPIv1.
         * @member {boolean} supportsPhoneBookAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsPhoneBookAPIv1 = false;

        /**
         * CapabilitiesResponse supportsSetupCallAPIv1.
         * @member {boolean} supportsSetupCallAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsSetupCallAPIv1 = false;

        /**
         * CapabilitiesResponse supportsReceiveCallAPIv1.
         * @member {boolean} supportsReceiveCallAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsReceiveCallAPIv1 = false;

        /**
         * CapabilitiesResponse supportsCustomActionAPIv1.
         * @member {boolean} supportsCustomActionAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsCustomActionAPIv1 = false;

        /**
         * CapabilitiesResponse supportsHTTPRequestAPIv1.
         * @member {boolean} supportsHTTPRequestAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsHTTPRequestAPIv1 = false;

        /**
         * CapabilitiesResponse supportsEmergencyAPIv1.
         * @member {boolean} supportsEmergencyAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsEmergencyAPIv1 = false;

        /**
         * CapabilitiesResponse supportsAudioAPIv1.
         * @member {boolean} supportsAudioAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsAudioAPIv1 = false;

        /**
         * CapabilitiesResponse supportsThirdPartyCallControlAPIv1.
         * @member {boolean} supportsThirdPartyCallControlAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsThirdPartyCallControlAPIv1 = false;

        /**
         * CapabilitiesResponse supportsSMSAPIv1.
         * @member {boolean} supportsSMSAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsSMSAPIv1 = false;

        /**
         * CapabilitiesResponse supportsQueueManagementAPIv1.
         * @member {boolean} supportsQueueManagementAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsQueueManagementAPIv1 = false;

        /**
         * CapabilitiesResponse supportsLocationReportAPIv1.
         * @member {boolean} supportsLocationReportAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsLocationReportAPIv1 = false;

        /**
         * CapabilitiesResponse supportsLocationEnquiryAPIv1.
         * @member {boolean} supportsLocationEnquiryAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsLocationEnquiryAPIv1 = false;

        /**
         * CapabilitiesResponse supportsDetachAPIv1.
         * @member {boolean} supportsDetachAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsDetachAPIv1 = false;

        /**
         * CapabilitiesResponse supportsGoOfflineAPIv1.
         * @member {boolean} supportsGoOfflineAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsGoOfflineAPIv1 = false;

        /**
         * CapabilitiesResponse supportsCallsignAPIv1.
         * @member {boolean} supportsCallsignAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsCallsignAPIv1 = false;

        /**
         * CapabilitiesResponse supportsWebappAPIv1.
         * @member {boolean} supportsWebappAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsWebappAPIv1 = false;

        /**
         * CapabilitiesResponse supportsNotificationAPIv1.
         * @member {boolean} supportsNotificationAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsNotificationAPIv1 = false;

        /**
         * CapabilitiesResponse supportsStatusAPIv1.
         * @member {boolean} supportsStatusAPIv1
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsStatusAPIv1 = false;

        /**
         * CapabilitiesResponse supportsCallsignAPIv2.
         * @member {boolean} supportsCallsignAPIv2
         * @memberof proto.CapabilitiesResponse
         * @instance
         */
        CapabilitiesResponse.prototype.supportsCallsignAPIv2 = false;

        /**
         * Creates a new CapabilitiesResponse instance using the specified properties.
         * @function create
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {proto.ICapabilitiesResponse=} [properties] Properties to set
         * @returns {proto.CapabilitiesResponse} CapabilitiesResponse instance
         */
        CapabilitiesResponse.create = function create(properties) {
            return new CapabilitiesResponse(properties);
        };

        /**
         * Encodes the specified CapabilitiesResponse message. Does not implicitly {@link proto.CapabilitiesResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {proto.ICapabilitiesResponse} message CapabilitiesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CapabilitiesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userAgent);
            if (message.supportsKeepAliveAPIv1 != null && Object.hasOwnProperty.call(message, "supportsKeepAliveAPIv1"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.supportsKeepAliveAPIv1);
            if (message.supportsAuthenticateAPIv1 != null && Object.hasOwnProperty.call(message, "supportsAuthenticateAPIv1"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.supportsAuthenticateAPIv1);
            if (message.supportsSessionAPIv1 != null && Object.hasOwnProperty.call(message, "supportsSessionAPIv1"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.supportsSessionAPIv1);
            if (message.supportsChannelAPIv1 != null && Object.hasOwnProperty.call(message, "supportsChannelAPIv1"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.supportsChannelAPIv1);
            if (message.supportsPresenceAPIv1 != null && Object.hasOwnProperty.call(message, "supportsPresenceAPIv1"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.supportsPresenceAPIv1);
            if (message.supportsUDPAPIv1 != null && Object.hasOwnProperty.call(message, "supportsUDPAPIv1"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.supportsUDPAPIv1);
            if (message.supportsTalkburstReceptionAPIv1 != null && Object.hasOwnProperty.call(message, "supportsTalkburstReceptionAPIv1"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.supportsTalkburstReceptionAPIv1);
            if (message.supportsTalkburstTransmissionAPIv1 != null && Object.hasOwnProperty.call(message, "supportsTalkburstTransmissionAPIv1"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.supportsTalkburstTransmissionAPIv1);
            if (message.supportsPhoneBookAPIv1 != null && Object.hasOwnProperty.call(message, "supportsPhoneBookAPIv1"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.supportsPhoneBookAPIv1);
            if (message.supportsSetupCallAPIv1 != null && Object.hasOwnProperty.call(message, "supportsSetupCallAPIv1"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.supportsSetupCallAPIv1);
            if (message.supportsReceiveCallAPIv1 != null && Object.hasOwnProperty.call(message, "supportsReceiveCallAPIv1"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.supportsReceiveCallAPIv1);
            if (message.supportsCustomActionAPIv1 != null && Object.hasOwnProperty.call(message, "supportsCustomActionAPIv1"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.supportsCustomActionAPIv1);
            if (message.supportsHTTPRequestAPIv1 != null && Object.hasOwnProperty.call(message, "supportsHTTPRequestAPIv1"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.supportsHTTPRequestAPIv1);
            if (message.supportsEmergencyAPIv1 != null && Object.hasOwnProperty.call(message, "supportsEmergencyAPIv1"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.supportsEmergencyAPIv1);
            if (message.supportsAudioAPIv1 != null && Object.hasOwnProperty.call(message, "supportsAudioAPIv1"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.supportsAudioAPIv1);
            if (message.supportsThirdPartyCallControlAPIv1 != null && Object.hasOwnProperty.call(message, "supportsThirdPartyCallControlAPIv1"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.supportsThirdPartyCallControlAPIv1);
            if (message.supportsSMSAPIv1 != null && Object.hasOwnProperty.call(message, "supportsSMSAPIv1"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.supportsSMSAPIv1);
            if (message.supportsQueueManagementAPIv1 != null && Object.hasOwnProperty.call(message, "supportsQueueManagementAPIv1"))
                writer.uint32(/* id 19, wireType 0 =*/152).bool(message.supportsQueueManagementAPIv1);
            if (message.supportsLocationReportAPIv1 != null && Object.hasOwnProperty.call(message, "supportsLocationReportAPIv1"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.supportsLocationReportAPIv1);
            if (message.supportsLocationEnquiryAPIv1 != null && Object.hasOwnProperty.call(message, "supportsLocationEnquiryAPIv1"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.supportsLocationEnquiryAPIv1);
            if (message.supportsDetachAPIv1 != null && Object.hasOwnProperty.call(message, "supportsDetachAPIv1"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.supportsDetachAPIv1);
            if (message.supportsGoOfflineAPIv1 != null && Object.hasOwnProperty.call(message, "supportsGoOfflineAPIv1"))
                writer.uint32(/* id 23, wireType 0 =*/184).bool(message.supportsGoOfflineAPIv1);
            if (message.supportsCallsignAPIv1 != null && Object.hasOwnProperty.call(message, "supportsCallsignAPIv1"))
                writer.uint32(/* id 24, wireType 0 =*/192).bool(message.supportsCallsignAPIv1);
            if (message.supportsWebappAPIv1 != null && Object.hasOwnProperty.call(message, "supportsWebappAPIv1"))
                writer.uint32(/* id 25, wireType 0 =*/200).bool(message.supportsWebappAPIv1);
            if (message.supportsNotificationAPIv1 != null && Object.hasOwnProperty.call(message, "supportsNotificationAPIv1"))
                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.supportsNotificationAPIv1);
            if (message.supportsStatusAPIv1 != null && Object.hasOwnProperty.call(message, "supportsStatusAPIv1"))
                writer.uint32(/* id 27, wireType 0 =*/216).bool(message.supportsStatusAPIv1);
            if (message.supportsCallsignAPIv2 != null && Object.hasOwnProperty.call(message, "supportsCallsignAPIv2"))
                writer.uint32(/* id 28, wireType 0 =*/224).bool(message.supportsCallsignAPIv2);
            writer.uint32(/* id 99, wireType 2 =*/794).string(message.connectionId);
            return writer;
        };

        /**
         * Encodes the specified CapabilitiesResponse message, length delimited. Does not implicitly {@link proto.CapabilitiesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {proto.ICapabilitiesResponse} message CapabilitiesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CapabilitiesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CapabilitiesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CapabilitiesResponse} CapabilitiesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CapabilitiesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CapabilitiesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userAgent = reader.string();
                        break;
                    }
                case 99: {
                        message.connectionId = reader.string();
                        break;
                    }
                case 2: {
                        message.supportsKeepAliveAPIv1 = reader.bool();
                        break;
                    }
                case 3: {
                        message.supportsAuthenticateAPIv1 = reader.bool();
                        break;
                    }
                case 4: {
                        message.supportsSessionAPIv1 = reader.bool();
                        break;
                    }
                case 5: {
                        message.supportsChannelAPIv1 = reader.bool();
                        break;
                    }
                case 6: {
                        message.supportsPresenceAPIv1 = reader.bool();
                        break;
                    }
                case 7: {
                        message.supportsUDPAPIv1 = reader.bool();
                        break;
                    }
                case 8: {
                        message.supportsTalkburstReceptionAPIv1 = reader.bool();
                        break;
                    }
                case 9: {
                        message.supportsTalkburstTransmissionAPIv1 = reader.bool();
                        break;
                    }
                case 10: {
                        message.supportsPhoneBookAPIv1 = reader.bool();
                        break;
                    }
                case 11: {
                        message.supportsSetupCallAPIv1 = reader.bool();
                        break;
                    }
                case 12: {
                        message.supportsReceiveCallAPIv1 = reader.bool();
                        break;
                    }
                case 13: {
                        message.supportsCustomActionAPIv1 = reader.bool();
                        break;
                    }
                case 14: {
                        message.supportsHTTPRequestAPIv1 = reader.bool();
                        break;
                    }
                case 15: {
                        message.supportsEmergencyAPIv1 = reader.bool();
                        break;
                    }
                case 16: {
                        message.supportsAudioAPIv1 = reader.bool();
                        break;
                    }
                case 17: {
                        message.supportsThirdPartyCallControlAPIv1 = reader.bool();
                        break;
                    }
                case 18: {
                        message.supportsSMSAPIv1 = reader.bool();
                        break;
                    }
                case 19: {
                        message.supportsQueueManagementAPIv1 = reader.bool();
                        break;
                    }
                case 20: {
                        message.supportsLocationReportAPIv1 = reader.bool();
                        break;
                    }
                case 21: {
                        message.supportsLocationEnquiryAPIv1 = reader.bool();
                        break;
                    }
                case 22: {
                        message.supportsDetachAPIv1 = reader.bool();
                        break;
                    }
                case 23: {
                        message.supportsGoOfflineAPIv1 = reader.bool();
                        break;
                    }
                case 24: {
                        message.supportsCallsignAPIv1 = reader.bool();
                        break;
                    }
                case 25: {
                        message.supportsWebappAPIv1 = reader.bool();
                        break;
                    }
                case 26: {
                        message.supportsNotificationAPIv1 = reader.bool();
                        break;
                    }
                case 27: {
                        message.supportsStatusAPIv1 = reader.bool();
                        break;
                    }
                case 28: {
                        message.supportsCallsignAPIv2 = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("userAgent"))
                throw $util.ProtocolError("missing required 'userAgent'", { instance: message });
            if (!message.hasOwnProperty("connectionId"))
                throw $util.ProtocolError("missing required 'connectionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a CapabilitiesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CapabilitiesResponse} CapabilitiesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CapabilitiesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CapabilitiesResponse message.
         * @function verify
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CapabilitiesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.userAgent))
                return "userAgent: string expected";
            if (!$util.isString(message.connectionId))
                return "connectionId: string expected";
            if (message.supportsKeepAliveAPIv1 != null && message.hasOwnProperty("supportsKeepAliveAPIv1"))
                if (typeof message.supportsKeepAliveAPIv1 !== "boolean")
                    return "supportsKeepAliveAPIv1: boolean expected";
            if (message.supportsAuthenticateAPIv1 != null && message.hasOwnProperty("supportsAuthenticateAPIv1"))
                if (typeof message.supportsAuthenticateAPIv1 !== "boolean")
                    return "supportsAuthenticateAPIv1: boolean expected";
            if (message.supportsSessionAPIv1 != null && message.hasOwnProperty("supportsSessionAPIv1"))
                if (typeof message.supportsSessionAPIv1 !== "boolean")
                    return "supportsSessionAPIv1: boolean expected";
            if (message.supportsChannelAPIv1 != null && message.hasOwnProperty("supportsChannelAPIv1"))
                if (typeof message.supportsChannelAPIv1 !== "boolean")
                    return "supportsChannelAPIv1: boolean expected";
            if (message.supportsPresenceAPIv1 != null && message.hasOwnProperty("supportsPresenceAPIv1"))
                if (typeof message.supportsPresenceAPIv1 !== "boolean")
                    return "supportsPresenceAPIv1: boolean expected";
            if (message.supportsUDPAPIv1 != null && message.hasOwnProperty("supportsUDPAPIv1"))
                if (typeof message.supportsUDPAPIv1 !== "boolean")
                    return "supportsUDPAPIv1: boolean expected";
            if (message.supportsTalkburstReceptionAPIv1 != null && message.hasOwnProperty("supportsTalkburstReceptionAPIv1"))
                if (typeof message.supportsTalkburstReceptionAPIv1 !== "boolean")
                    return "supportsTalkburstReceptionAPIv1: boolean expected";
            if (message.supportsTalkburstTransmissionAPIv1 != null && message.hasOwnProperty("supportsTalkburstTransmissionAPIv1"))
                if (typeof message.supportsTalkburstTransmissionAPIv1 !== "boolean")
                    return "supportsTalkburstTransmissionAPIv1: boolean expected";
            if (message.supportsPhoneBookAPIv1 != null && message.hasOwnProperty("supportsPhoneBookAPIv1"))
                if (typeof message.supportsPhoneBookAPIv1 !== "boolean")
                    return "supportsPhoneBookAPIv1: boolean expected";
            if (message.supportsSetupCallAPIv1 != null && message.hasOwnProperty("supportsSetupCallAPIv1"))
                if (typeof message.supportsSetupCallAPIv1 !== "boolean")
                    return "supportsSetupCallAPIv1: boolean expected";
            if (message.supportsReceiveCallAPIv1 != null && message.hasOwnProperty("supportsReceiveCallAPIv1"))
                if (typeof message.supportsReceiveCallAPIv1 !== "boolean")
                    return "supportsReceiveCallAPIv1: boolean expected";
            if (message.supportsCustomActionAPIv1 != null && message.hasOwnProperty("supportsCustomActionAPIv1"))
                if (typeof message.supportsCustomActionAPIv1 !== "boolean")
                    return "supportsCustomActionAPIv1: boolean expected";
            if (message.supportsHTTPRequestAPIv1 != null && message.hasOwnProperty("supportsHTTPRequestAPIv1"))
                if (typeof message.supportsHTTPRequestAPIv1 !== "boolean")
                    return "supportsHTTPRequestAPIv1: boolean expected";
            if (message.supportsEmergencyAPIv1 != null && message.hasOwnProperty("supportsEmergencyAPIv1"))
                if (typeof message.supportsEmergencyAPIv1 !== "boolean")
                    return "supportsEmergencyAPIv1: boolean expected";
            if (message.supportsAudioAPIv1 != null && message.hasOwnProperty("supportsAudioAPIv1"))
                if (typeof message.supportsAudioAPIv1 !== "boolean")
                    return "supportsAudioAPIv1: boolean expected";
            if (message.supportsThirdPartyCallControlAPIv1 != null && message.hasOwnProperty("supportsThirdPartyCallControlAPIv1"))
                if (typeof message.supportsThirdPartyCallControlAPIv1 !== "boolean")
                    return "supportsThirdPartyCallControlAPIv1: boolean expected";
            if (message.supportsSMSAPIv1 != null && message.hasOwnProperty("supportsSMSAPIv1"))
                if (typeof message.supportsSMSAPIv1 !== "boolean")
                    return "supportsSMSAPIv1: boolean expected";
            if (message.supportsQueueManagementAPIv1 != null && message.hasOwnProperty("supportsQueueManagementAPIv1"))
                if (typeof message.supportsQueueManagementAPIv1 !== "boolean")
                    return "supportsQueueManagementAPIv1: boolean expected";
            if (message.supportsLocationReportAPIv1 != null && message.hasOwnProperty("supportsLocationReportAPIv1"))
                if (typeof message.supportsLocationReportAPIv1 !== "boolean")
                    return "supportsLocationReportAPIv1: boolean expected";
            if (message.supportsLocationEnquiryAPIv1 != null && message.hasOwnProperty("supportsLocationEnquiryAPIv1"))
                if (typeof message.supportsLocationEnquiryAPIv1 !== "boolean")
                    return "supportsLocationEnquiryAPIv1: boolean expected";
            if (message.supportsDetachAPIv1 != null && message.hasOwnProperty("supportsDetachAPIv1"))
                if (typeof message.supportsDetachAPIv1 !== "boolean")
                    return "supportsDetachAPIv1: boolean expected";
            if (message.supportsGoOfflineAPIv1 != null && message.hasOwnProperty("supportsGoOfflineAPIv1"))
                if (typeof message.supportsGoOfflineAPIv1 !== "boolean")
                    return "supportsGoOfflineAPIv1: boolean expected";
            if (message.supportsCallsignAPIv1 != null && message.hasOwnProperty("supportsCallsignAPIv1"))
                if (typeof message.supportsCallsignAPIv1 !== "boolean")
                    return "supportsCallsignAPIv1: boolean expected";
            if (message.supportsWebappAPIv1 != null && message.hasOwnProperty("supportsWebappAPIv1"))
                if (typeof message.supportsWebappAPIv1 !== "boolean")
                    return "supportsWebappAPIv1: boolean expected";
            if (message.supportsNotificationAPIv1 != null && message.hasOwnProperty("supportsNotificationAPIv1"))
                if (typeof message.supportsNotificationAPIv1 !== "boolean")
                    return "supportsNotificationAPIv1: boolean expected";
            if (message.supportsStatusAPIv1 != null && message.hasOwnProperty("supportsStatusAPIv1"))
                if (typeof message.supportsStatusAPIv1 !== "boolean")
                    return "supportsStatusAPIv1: boolean expected";
            if (message.supportsCallsignAPIv2 != null && message.hasOwnProperty("supportsCallsignAPIv2"))
                if (typeof message.supportsCallsignAPIv2 !== "boolean")
                    return "supportsCallsignAPIv2: boolean expected";
            return null;
        };

        /**
         * Creates a CapabilitiesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CapabilitiesResponse} CapabilitiesResponse
         */
        CapabilitiesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CapabilitiesResponse)
                return object;
            var message = new $root.proto.CapabilitiesResponse();
            if (object.userAgent != null)
                message.userAgent = String(object.userAgent);
            if (object.connectionId != null)
                message.connectionId = String(object.connectionId);
            if (object.supportsKeepAliveAPIv1 != null)
                message.supportsKeepAliveAPIv1 = Boolean(object.supportsKeepAliveAPIv1);
            if (object.supportsAuthenticateAPIv1 != null)
                message.supportsAuthenticateAPIv1 = Boolean(object.supportsAuthenticateAPIv1);
            if (object.supportsSessionAPIv1 != null)
                message.supportsSessionAPIv1 = Boolean(object.supportsSessionAPIv1);
            if (object.supportsChannelAPIv1 != null)
                message.supportsChannelAPIv1 = Boolean(object.supportsChannelAPIv1);
            if (object.supportsPresenceAPIv1 != null)
                message.supportsPresenceAPIv1 = Boolean(object.supportsPresenceAPIv1);
            if (object.supportsUDPAPIv1 != null)
                message.supportsUDPAPIv1 = Boolean(object.supportsUDPAPIv1);
            if (object.supportsTalkburstReceptionAPIv1 != null)
                message.supportsTalkburstReceptionAPIv1 = Boolean(object.supportsTalkburstReceptionAPIv1);
            if (object.supportsTalkburstTransmissionAPIv1 != null)
                message.supportsTalkburstTransmissionAPIv1 = Boolean(object.supportsTalkburstTransmissionAPIv1);
            if (object.supportsPhoneBookAPIv1 != null)
                message.supportsPhoneBookAPIv1 = Boolean(object.supportsPhoneBookAPIv1);
            if (object.supportsSetupCallAPIv1 != null)
                message.supportsSetupCallAPIv1 = Boolean(object.supportsSetupCallAPIv1);
            if (object.supportsReceiveCallAPIv1 != null)
                message.supportsReceiveCallAPIv1 = Boolean(object.supportsReceiveCallAPIv1);
            if (object.supportsCustomActionAPIv1 != null)
                message.supportsCustomActionAPIv1 = Boolean(object.supportsCustomActionAPIv1);
            if (object.supportsHTTPRequestAPIv1 != null)
                message.supportsHTTPRequestAPIv1 = Boolean(object.supportsHTTPRequestAPIv1);
            if (object.supportsEmergencyAPIv1 != null)
                message.supportsEmergencyAPIv1 = Boolean(object.supportsEmergencyAPIv1);
            if (object.supportsAudioAPIv1 != null)
                message.supportsAudioAPIv1 = Boolean(object.supportsAudioAPIv1);
            if (object.supportsThirdPartyCallControlAPIv1 != null)
                message.supportsThirdPartyCallControlAPIv1 = Boolean(object.supportsThirdPartyCallControlAPIv1);
            if (object.supportsSMSAPIv1 != null)
                message.supportsSMSAPIv1 = Boolean(object.supportsSMSAPIv1);
            if (object.supportsQueueManagementAPIv1 != null)
                message.supportsQueueManagementAPIv1 = Boolean(object.supportsQueueManagementAPIv1);
            if (object.supportsLocationReportAPIv1 != null)
                message.supportsLocationReportAPIv1 = Boolean(object.supportsLocationReportAPIv1);
            if (object.supportsLocationEnquiryAPIv1 != null)
                message.supportsLocationEnquiryAPIv1 = Boolean(object.supportsLocationEnquiryAPIv1);
            if (object.supportsDetachAPIv1 != null)
                message.supportsDetachAPIv1 = Boolean(object.supportsDetachAPIv1);
            if (object.supportsGoOfflineAPIv1 != null)
                message.supportsGoOfflineAPIv1 = Boolean(object.supportsGoOfflineAPIv1);
            if (object.supportsCallsignAPIv1 != null)
                message.supportsCallsignAPIv1 = Boolean(object.supportsCallsignAPIv1);
            if (object.supportsWebappAPIv1 != null)
                message.supportsWebappAPIv1 = Boolean(object.supportsWebappAPIv1);
            if (object.supportsNotificationAPIv1 != null)
                message.supportsNotificationAPIv1 = Boolean(object.supportsNotificationAPIv1);
            if (object.supportsStatusAPIv1 != null)
                message.supportsStatusAPIv1 = Boolean(object.supportsStatusAPIv1);
            if (object.supportsCallsignAPIv2 != null)
                message.supportsCallsignAPIv2 = Boolean(object.supportsCallsignAPIv2);
            return message;
        };

        /**
         * Creates a plain object from a CapabilitiesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {proto.CapabilitiesResponse} message CapabilitiesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CapabilitiesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userAgent = "";
                object.supportsKeepAliveAPIv1 = false;
                object.supportsAuthenticateAPIv1 = false;
                object.supportsSessionAPIv1 = false;
                object.supportsChannelAPIv1 = false;
                object.supportsPresenceAPIv1 = false;
                object.supportsUDPAPIv1 = false;
                object.supportsTalkburstReceptionAPIv1 = false;
                object.supportsTalkburstTransmissionAPIv1 = false;
                object.supportsPhoneBookAPIv1 = false;
                object.supportsSetupCallAPIv1 = false;
                object.supportsReceiveCallAPIv1 = false;
                object.supportsCustomActionAPIv1 = false;
                object.supportsHTTPRequestAPIv1 = false;
                object.supportsEmergencyAPIv1 = false;
                object.supportsAudioAPIv1 = false;
                object.supportsThirdPartyCallControlAPIv1 = false;
                object.supportsSMSAPIv1 = false;
                object.supportsQueueManagementAPIv1 = false;
                object.supportsLocationReportAPIv1 = false;
                object.supportsLocationEnquiryAPIv1 = false;
                object.supportsDetachAPIv1 = false;
                object.supportsGoOfflineAPIv1 = false;
                object.supportsCallsignAPIv1 = false;
                object.supportsWebappAPIv1 = false;
                object.supportsNotificationAPIv1 = false;
                object.supportsStatusAPIv1 = false;
                object.supportsCallsignAPIv2 = false;
                object.connectionId = "";
            }
            if (message.userAgent != null && message.hasOwnProperty("userAgent"))
                object.userAgent = message.userAgent;
            if (message.supportsKeepAliveAPIv1 != null && message.hasOwnProperty("supportsKeepAliveAPIv1"))
                object.supportsKeepAliveAPIv1 = message.supportsKeepAliveAPIv1;
            if (message.supportsAuthenticateAPIv1 != null && message.hasOwnProperty("supportsAuthenticateAPIv1"))
                object.supportsAuthenticateAPIv1 = message.supportsAuthenticateAPIv1;
            if (message.supportsSessionAPIv1 != null && message.hasOwnProperty("supportsSessionAPIv1"))
                object.supportsSessionAPIv1 = message.supportsSessionAPIv1;
            if (message.supportsChannelAPIv1 != null && message.hasOwnProperty("supportsChannelAPIv1"))
                object.supportsChannelAPIv1 = message.supportsChannelAPIv1;
            if (message.supportsPresenceAPIv1 != null && message.hasOwnProperty("supportsPresenceAPIv1"))
                object.supportsPresenceAPIv1 = message.supportsPresenceAPIv1;
            if (message.supportsUDPAPIv1 != null && message.hasOwnProperty("supportsUDPAPIv1"))
                object.supportsUDPAPIv1 = message.supportsUDPAPIv1;
            if (message.supportsTalkburstReceptionAPIv1 != null && message.hasOwnProperty("supportsTalkburstReceptionAPIv1"))
                object.supportsTalkburstReceptionAPIv1 = message.supportsTalkburstReceptionAPIv1;
            if (message.supportsTalkburstTransmissionAPIv1 != null && message.hasOwnProperty("supportsTalkburstTransmissionAPIv1"))
                object.supportsTalkburstTransmissionAPIv1 = message.supportsTalkburstTransmissionAPIv1;
            if (message.supportsPhoneBookAPIv1 != null && message.hasOwnProperty("supportsPhoneBookAPIv1"))
                object.supportsPhoneBookAPIv1 = message.supportsPhoneBookAPIv1;
            if (message.supportsSetupCallAPIv1 != null && message.hasOwnProperty("supportsSetupCallAPIv1"))
                object.supportsSetupCallAPIv1 = message.supportsSetupCallAPIv1;
            if (message.supportsReceiveCallAPIv1 != null && message.hasOwnProperty("supportsReceiveCallAPIv1"))
                object.supportsReceiveCallAPIv1 = message.supportsReceiveCallAPIv1;
            if (message.supportsCustomActionAPIv1 != null && message.hasOwnProperty("supportsCustomActionAPIv1"))
                object.supportsCustomActionAPIv1 = message.supportsCustomActionAPIv1;
            if (message.supportsHTTPRequestAPIv1 != null && message.hasOwnProperty("supportsHTTPRequestAPIv1"))
                object.supportsHTTPRequestAPIv1 = message.supportsHTTPRequestAPIv1;
            if (message.supportsEmergencyAPIv1 != null && message.hasOwnProperty("supportsEmergencyAPIv1"))
                object.supportsEmergencyAPIv1 = message.supportsEmergencyAPIv1;
            if (message.supportsAudioAPIv1 != null && message.hasOwnProperty("supportsAudioAPIv1"))
                object.supportsAudioAPIv1 = message.supportsAudioAPIv1;
            if (message.supportsThirdPartyCallControlAPIv1 != null && message.hasOwnProperty("supportsThirdPartyCallControlAPIv1"))
                object.supportsThirdPartyCallControlAPIv1 = message.supportsThirdPartyCallControlAPIv1;
            if (message.supportsSMSAPIv1 != null && message.hasOwnProperty("supportsSMSAPIv1"))
                object.supportsSMSAPIv1 = message.supportsSMSAPIv1;
            if (message.supportsQueueManagementAPIv1 != null && message.hasOwnProperty("supportsQueueManagementAPIv1"))
                object.supportsQueueManagementAPIv1 = message.supportsQueueManagementAPIv1;
            if (message.supportsLocationReportAPIv1 != null && message.hasOwnProperty("supportsLocationReportAPIv1"))
                object.supportsLocationReportAPIv1 = message.supportsLocationReportAPIv1;
            if (message.supportsLocationEnquiryAPIv1 != null && message.hasOwnProperty("supportsLocationEnquiryAPIv1"))
                object.supportsLocationEnquiryAPIv1 = message.supportsLocationEnquiryAPIv1;
            if (message.supportsDetachAPIv1 != null && message.hasOwnProperty("supportsDetachAPIv1"))
                object.supportsDetachAPIv1 = message.supportsDetachAPIv1;
            if (message.supportsGoOfflineAPIv1 != null && message.hasOwnProperty("supportsGoOfflineAPIv1"))
                object.supportsGoOfflineAPIv1 = message.supportsGoOfflineAPIv1;
            if (message.supportsCallsignAPIv1 != null && message.hasOwnProperty("supportsCallsignAPIv1"))
                object.supportsCallsignAPIv1 = message.supportsCallsignAPIv1;
            if (message.supportsWebappAPIv1 != null && message.hasOwnProperty("supportsWebappAPIv1"))
                object.supportsWebappAPIv1 = message.supportsWebappAPIv1;
            if (message.supportsNotificationAPIv1 != null && message.hasOwnProperty("supportsNotificationAPIv1"))
                object.supportsNotificationAPIv1 = message.supportsNotificationAPIv1;
            if (message.supportsStatusAPIv1 != null && message.hasOwnProperty("supportsStatusAPIv1"))
                object.supportsStatusAPIv1 = message.supportsStatusAPIv1;
            if (message.supportsCallsignAPIv2 != null && message.hasOwnProperty("supportsCallsignAPIv2"))
                object.supportsCallsignAPIv2 = message.supportsCallsignAPIv2;
            if (message.connectionId != null && message.hasOwnProperty("connectionId"))
                object.connectionId = message.connectionId;
            return object;
        };

        /**
         * Converts this CapabilitiesResponse to JSON.
         * @function toJSON
         * @memberof proto.CapabilitiesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CapabilitiesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CapabilitiesResponse
         * @function getTypeUrl
         * @memberof proto.CapabilitiesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CapabilitiesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CapabilitiesResponse";
        };

        return CapabilitiesResponse;
    })();

    proto.GoOfflineAPIv1Request = (function() {

        /**
         * Properties of a GoOfflineAPIv1Request.
         * @memberof proto
         * @interface IGoOfflineAPIv1Request
         */

        /**
         * Constructs a new GoOfflineAPIv1Request.
         * @memberof proto
         * @classdesc Represents a GoOfflineAPIv1Request.
         * @implements IGoOfflineAPIv1Request
         * @constructor
         * @param {proto.IGoOfflineAPIv1Request=} [properties] Properties to set
         */
        function GoOfflineAPIv1Request(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GoOfflineAPIv1Request instance using the specified properties.
         * @function create
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {proto.IGoOfflineAPIv1Request=} [properties] Properties to set
         * @returns {proto.GoOfflineAPIv1Request} GoOfflineAPIv1Request instance
         */
        GoOfflineAPIv1Request.create = function create(properties) {
            return new GoOfflineAPIv1Request(properties);
        };

        /**
         * Encodes the specified GoOfflineAPIv1Request message. Does not implicitly {@link proto.GoOfflineAPIv1Request.verify|verify} messages.
         * @function encode
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {proto.IGoOfflineAPIv1Request} message GoOfflineAPIv1Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoOfflineAPIv1Request.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GoOfflineAPIv1Request message, length delimited. Does not implicitly {@link proto.GoOfflineAPIv1Request.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {proto.IGoOfflineAPIv1Request} message GoOfflineAPIv1Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoOfflineAPIv1Request.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoOfflineAPIv1Request message from the specified reader or buffer.
         * @function decode
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.GoOfflineAPIv1Request} GoOfflineAPIv1Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoOfflineAPIv1Request.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.GoOfflineAPIv1Request();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoOfflineAPIv1Request message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.GoOfflineAPIv1Request} GoOfflineAPIv1Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoOfflineAPIv1Request.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoOfflineAPIv1Request message.
         * @function verify
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoOfflineAPIv1Request.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GoOfflineAPIv1Request message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.GoOfflineAPIv1Request} GoOfflineAPIv1Request
         */
        GoOfflineAPIv1Request.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.GoOfflineAPIv1Request)
                return object;
            return new $root.proto.GoOfflineAPIv1Request();
        };

        /**
         * Creates a plain object from a GoOfflineAPIv1Request message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {proto.GoOfflineAPIv1Request} message GoOfflineAPIv1Request
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoOfflineAPIv1Request.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GoOfflineAPIv1Request to JSON.
         * @function toJSON
         * @memberof proto.GoOfflineAPIv1Request
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoOfflineAPIv1Request.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GoOfflineAPIv1Request
         * @function getTypeUrl
         * @memberof proto.GoOfflineAPIv1Request
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GoOfflineAPIv1Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.GoOfflineAPIv1Request";
        };

        return GoOfflineAPIv1Request;
    })();

    proto.TerminateConnection = (function() {

        /**
         * Properties of a TerminateConnection.
         * @memberof proto
         * @interface ITerminateConnection
         * @property {proto.TerminateConnection.TerminateConnectionReasonDeprecated} deprecatedReason TerminateConnection deprecatedReason
         * @property {proto.TerminateConnection.TerminateConnectionReason|null} [reason] TerminateConnection reason
         */

        /**
         * Constructs a new TerminateConnection.
         * @memberof proto
         * @classdesc Represents a TerminateConnection.
         * @implements ITerminateConnection
         * @constructor
         * @param {proto.ITerminateConnection=} [properties] Properties to set
         */
        function TerminateConnection(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TerminateConnection deprecatedReason.
         * @member {proto.TerminateConnection.TerminateConnectionReasonDeprecated} deprecatedReason
         * @memberof proto.TerminateConnection
         * @instance
         */
        TerminateConnection.prototype.deprecatedReason = 1;

        /**
         * TerminateConnection reason.
         * @member {proto.TerminateConnection.TerminateConnectionReason} reason
         * @memberof proto.TerminateConnection
         * @instance
         */
        TerminateConnection.prototype.reason = 1;

        /**
         * Creates a new TerminateConnection instance using the specified properties.
         * @function create
         * @memberof proto.TerminateConnection
         * @static
         * @param {proto.ITerminateConnection=} [properties] Properties to set
         * @returns {proto.TerminateConnection} TerminateConnection instance
         */
        TerminateConnection.create = function create(properties) {
            return new TerminateConnection(properties);
        };

        /**
         * Encodes the specified TerminateConnection message. Does not implicitly {@link proto.TerminateConnection.verify|verify} messages.
         * @function encode
         * @memberof proto.TerminateConnection
         * @static
         * @param {proto.ITerminateConnection} message TerminateConnection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TerminateConnection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deprecatedReason);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.reason);
            return writer;
        };

        /**
         * Encodes the specified TerminateConnection message, length delimited. Does not implicitly {@link proto.TerminateConnection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TerminateConnection
         * @static
         * @param {proto.ITerminateConnection} message TerminateConnection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TerminateConnection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TerminateConnection message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TerminateConnection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TerminateConnection} TerminateConnection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TerminateConnection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TerminateConnection();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deprecatedReason = reader.int32();
                        break;
                    }
                case 2: {
                        message.reason = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("deprecatedReason"))
                throw $util.ProtocolError("missing required 'deprecatedReason'", { instance: message });
            return message;
        };

        /**
         * Decodes a TerminateConnection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TerminateConnection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TerminateConnection} TerminateConnection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TerminateConnection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TerminateConnection message.
         * @function verify
         * @memberof proto.TerminateConnection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TerminateConnection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.deprecatedReason) {
            default:
                return "deprecatedReason: enum value expected";
            case 1:
            case 2:
            case 3:
                break;
            }
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a TerminateConnection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TerminateConnection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TerminateConnection} TerminateConnection
         */
        TerminateConnection.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TerminateConnection)
                return object;
            var message = new $root.proto.TerminateConnection();
            switch (object.deprecatedReason) {
            default:
                if (typeof object.deprecatedReason === "number") {
                    message.deprecatedReason = object.deprecatedReason;
                    break;
                }
                break;
            case "UNKNOWN_DEPRECATED":
            case 1:
                message.deprecatedReason = 1;
                break;
            case "DEVICE_RECONNECTED_DEPRECATED":
            case 2:
                message.deprecatedReason = 2;
                break;
            case "TERMINATED_BY_REQUEST_DEPRECATED":
            case 3:
                message.deprecatedReason = 3;
                break;
            }
            switch (object.reason) {
            default:
                if (typeof object.reason === "number") {
                    message.reason = object.reason;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.reason = 1;
                break;
            case "DEVICE_RECONNECTED":
            case 2:
                message.reason = 2;
                break;
            case "TERMINATED_BY_REQUEST":
            case 3:
                message.reason = 3;
                break;
            case "USAGE_LICENSE_EXPIRED":
            case 4:
                message.reason = 4;
                break;
            case "ANOTHER_DEVICE_CONNECTED":
            case 5:
                message.reason = 5;
                break;
            case "PUSH_TOKEN_EXPIRED":
            case 6:
                message.reason = 6;
                break;
            case "INACTIVITY":
            case 7:
                message.reason = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a TerminateConnection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TerminateConnection
         * @static
         * @param {proto.TerminateConnection} message TerminateConnection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TerminateConnection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.deprecatedReason = options.enums === String ? "UNKNOWN_DEPRECATED" : 1;
                object.reason = options.enums === String ? "UNKNOWN" : 1;
            }
            if (message.deprecatedReason != null && message.hasOwnProperty("deprecatedReason"))
                object.deprecatedReason = options.enums === String ? $root.proto.TerminateConnection.TerminateConnectionReasonDeprecated[message.deprecatedReason] === undefined ? message.deprecatedReason : $root.proto.TerminateConnection.TerminateConnectionReasonDeprecated[message.deprecatedReason] : message.deprecatedReason;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.proto.TerminateConnection.TerminateConnectionReason[message.reason] === undefined ? message.reason : $root.proto.TerminateConnection.TerminateConnectionReason[message.reason] : message.reason;
            return object;
        };

        /**
         * Converts this TerminateConnection to JSON.
         * @function toJSON
         * @memberof proto.TerminateConnection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TerminateConnection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TerminateConnection
         * @function getTypeUrl
         * @memberof proto.TerminateConnection
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TerminateConnection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TerminateConnection";
        };

        /**
         * TerminateConnectionReasonDeprecated enum.
         * @name proto.TerminateConnection.TerminateConnectionReasonDeprecated
         * @enum {number}
         * @property {number} UNKNOWN_DEPRECATED=1 UNKNOWN_DEPRECATED value
         * @property {number} DEVICE_RECONNECTED_DEPRECATED=2 DEVICE_RECONNECTED_DEPRECATED value
         * @property {number} TERMINATED_BY_REQUEST_DEPRECATED=3 TERMINATED_BY_REQUEST_DEPRECATED value
         */
        TerminateConnection.TerminateConnectionReasonDeprecated = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "UNKNOWN_DEPRECATED"] = 1;
            values[valuesById[2] = "DEVICE_RECONNECTED_DEPRECATED"] = 2;
            values[valuesById[3] = "TERMINATED_BY_REQUEST_DEPRECATED"] = 3;
            return values;
        })();

        /**
         * TerminateConnectionReason enum.
         * @name proto.TerminateConnection.TerminateConnectionReason
         * @enum {number}
         * @property {number} UNKNOWN=1 UNKNOWN value
         * @property {number} DEVICE_RECONNECTED=2 DEVICE_RECONNECTED value
         * @property {number} TERMINATED_BY_REQUEST=3 TERMINATED_BY_REQUEST value
         * @property {number} USAGE_LICENSE_EXPIRED=4 USAGE_LICENSE_EXPIRED value
         * @property {number} ANOTHER_DEVICE_CONNECTED=5 ANOTHER_DEVICE_CONNECTED value
         * @property {number} PUSH_TOKEN_EXPIRED=6 PUSH_TOKEN_EXPIRED value
         * @property {number} INACTIVITY=7 INACTIVITY value
         */
        TerminateConnection.TerminateConnectionReason = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "UNKNOWN"] = 1;
            values[valuesById[2] = "DEVICE_RECONNECTED"] = 2;
            values[valuesById[3] = "TERMINATED_BY_REQUEST"] = 3;
            values[valuesById[4] = "USAGE_LICENSE_EXPIRED"] = 4;
            values[valuesById[5] = "ANOTHER_DEVICE_CONNECTED"] = 5;
            values[valuesById[6] = "PUSH_TOKEN_EXPIRED"] = 6;
            values[valuesById[7] = "INACTIVITY"] = 7;
            return values;
        })();

        return TerminateConnection;
    })();

    proto.KeepAliveAPIv1SetupRequest = (function() {

        /**
         * Properties of a KeepAliveAPIv1SetupRequest.
         * @memberof proto
         * @interface IKeepAliveAPIv1SetupRequest
         * @property {number} suggestedInterval KeepAliveAPIv1SetupRequest suggestedInterval
         */

        /**
         * Constructs a new KeepAliveAPIv1SetupRequest.
         * @memberof proto
         * @classdesc Represents a KeepAliveAPIv1SetupRequest.
         * @implements IKeepAliveAPIv1SetupRequest
         * @constructor
         * @param {proto.IKeepAliveAPIv1SetupRequest=} [properties] Properties to set
         */
        function KeepAliveAPIv1SetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeepAliveAPIv1SetupRequest suggestedInterval.
         * @member {number} suggestedInterval
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @instance
         */
        KeepAliveAPIv1SetupRequest.prototype.suggestedInterval = 0;

        /**
         * Creates a new KeepAliveAPIv1SetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {proto.IKeepAliveAPIv1SetupRequest=} [properties] Properties to set
         * @returns {proto.KeepAliveAPIv1SetupRequest} KeepAliveAPIv1SetupRequest instance
         */
        KeepAliveAPIv1SetupRequest.create = function create(properties) {
            return new KeepAliveAPIv1SetupRequest(properties);
        };

        /**
         * Encodes the specified KeepAliveAPIv1SetupRequest message. Does not implicitly {@link proto.KeepAliveAPIv1SetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {proto.IKeepAliveAPIv1SetupRequest} message KeepAliveAPIv1SetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeepAliveAPIv1SetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.suggestedInterval);
            return writer;
        };

        /**
         * Encodes the specified KeepAliveAPIv1SetupRequest message, length delimited. Does not implicitly {@link proto.KeepAliveAPIv1SetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {proto.IKeepAliveAPIv1SetupRequest} message KeepAliveAPIv1SetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeepAliveAPIv1SetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeepAliveAPIv1SetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.KeepAliveAPIv1SetupRequest} KeepAliveAPIv1SetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeepAliveAPIv1SetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.KeepAliveAPIv1SetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.suggestedInterval = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("suggestedInterval"))
                throw $util.ProtocolError("missing required 'suggestedInterval'", { instance: message });
            return message;
        };

        /**
         * Decodes a KeepAliveAPIv1SetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.KeepAliveAPIv1SetupRequest} KeepAliveAPIv1SetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeepAliveAPIv1SetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeepAliveAPIv1SetupRequest message.
         * @function verify
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeepAliveAPIv1SetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.suggestedInterval))
                return "suggestedInterval: integer expected";
            return null;
        };

        /**
         * Creates a KeepAliveAPIv1SetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.KeepAliveAPIv1SetupRequest} KeepAliveAPIv1SetupRequest
         */
        KeepAliveAPIv1SetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.KeepAliveAPIv1SetupRequest)
                return object;
            var message = new $root.proto.KeepAliveAPIv1SetupRequest();
            if (object.suggestedInterval != null)
                message.suggestedInterval = object.suggestedInterval | 0;
            return message;
        };

        /**
         * Creates a plain object from a KeepAliveAPIv1SetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {proto.KeepAliveAPIv1SetupRequest} message KeepAliveAPIv1SetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeepAliveAPIv1SetupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.suggestedInterval = 0;
            if (message.suggestedInterval != null && message.hasOwnProperty("suggestedInterval"))
                object.suggestedInterval = message.suggestedInterval;
            return object;
        };

        /**
         * Converts this KeepAliveAPIv1SetupRequest to JSON.
         * @function toJSON
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeepAliveAPIv1SetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for KeepAliveAPIv1SetupRequest
         * @function getTypeUrl
         * @memberof proto.KeepAliveAPIv1SetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        KeepAliveAPIv1SetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.KeepAliveAPIv1SetupRequest";
        };

        return KeepAliveAPIv1SetupRequest;
    })();

    proto.KeepAliveAPIv1SetupResponse = (function() {

        /**
         * Properties of a KeepAliveAPIv1SetupResponse.
         * @memberof proto
         * @interface IKeepAliveAPIv1SetupResponse
         * @property {number} keepAliveInterval KeepAliveAPIv1SetupResponse keepAliveInterval
         */

        /**
         * Constructs a new KeepAliveAPIv1SetupResponse.
         * @memberof proto
         * @classdesc Represents a KeepAliveAPIv1SetupResponse.
         * @implements IKeepAliveAPIv1SetupResponse
         * @constructor
         * @param {proto.IKeepAliveAPIv1SetupResponse=} [properties] Properties to set
         */
        function KeepAliveAPIv1SetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeepAliveAPIv1SetupResponse keepAliveInterval.
         * @member {number} keepAliveInterval
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @instance
         */
        KeepAliveAPIv1SetupResponse.prototype.keepAliveInterval = 0;

        /**
         * Creates a new KeepAliveAPIv1SetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {proto.IKeepAliveAPIv1SetupResponse=} [properties] Properties to set
         * @returns {proto.KeepAliveAPIv1SetupResponse} KeepAliveAPIv1SetupResponse instance
         */
        KeepAliveAPIv1SetupResponse.create = function create(properties) {
            return new KeepAliveAPIv1SetupResponse(properties);
        };

        /**
         * Encodes the specified KeepAliveAPIv1SetupResponse message. Does not implicitly {@link proto.KeepAliveAPIv1SetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {proto.IKeepAliveAPIv1SetupResponse} message KeepAliveAPIv1SetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeepAliveAPIv1SetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.keepAliveInterval);
            return writer;
        };

        /**
         * Encodes the specified KeepAliveAPIv1SetupResponse message, length delimited. Does not implicitly {@link proto.KeepAliveAPIv1SetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {proto.IKeepAliveAPIv1SetupResponse} message KeepAliveAPIv1SetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeepAliveAPIv1SetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeepAliveAPIv1SetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.KeepAliveAPIv1SetupResponse} KeepAliveAPIv1SetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeepAliveAPIv1SetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.KeepAliveAPIv1SetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.keepAliveInterval = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("keepAliveInterval"))
                throw $util.ProtocolError("missing required 'keepAliveInterval'", { instance: message });
            return message;
        };

        /**
         * Decodes a KeepAliveAPIv1SetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.KeepAliveAPIv1SetupResponse} KeepAliveAPIv1SetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeepAliveAPIv1SetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeepAliveAPIv1SetupResponse message.
         * @function verify
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeepAliveAPIv1SetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.keepAliveInterval))
                return "keepAliveInterval: integer expected";
            return null;
        };

        /**
         * Creates a KeepAliveAPIv1SetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.KeepAliveAPIv1SetupResponse} KeepAliveAPIv1SetupResponse
         */
        KeepAliveAPIv1SetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.KeepAliveAPIv1SetupResponse)
                return object;
            var message = new $root.proto.KeepAliveAPIv1SetupResponse();
            if (object.keepAliveInterval != null)
                message.keepAliveInterval = object.keepAliveInterval | 0;
            return message;
        };

        /**
         * Creates a plain object from a KeepAliveAPIv1SetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {proto.KeepAliveAPIv1SetupResponse} message KeepAliveAPIv1SetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeepAliveAPIv1SetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.keepAliveInterval = 0;
            if (message.keepAliveInterval != null && message.hasOwnProperty("keepAliveInterval"))
                object.keepAliveInterval = message.keepAliveInterval;
            return object;
        };

        /**
         * Converts this KeepAliveAPIv1SetupResponse to JSON.
         * @function toJSON
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeepAliveAPIv1SetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for KeepAliveAPIv1SetupResponse
         * @function getTypeUrl
         * @memberof proto.KeepAliveAPIv1SetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        KeepAliveAPIv1SetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.KeepAliveAPIv1SetupResponse";
        };

        return KeepAliveAPIv1SetupResponse;
    })();

    proto.KeepAliveAPIv1PingRequest = (function() {

        /**
         * Properties of a KeepAliveAPIv1PingRequest.
         * @memberof proto
         * @interface IKeepAliveAPIv1PingRequest
         */

        /**
         * Constructs a new KeepAliveAPIv1PingRequest.
         * @memberof proto
         * @classdesc Represents a KeepAliveAPIv1PingRequest.
         * @implements IKeepAliveAPIv1PingRequest
         * @constructor
         * @param {proto.IKeepAliveAPIv1PingRequest=} [properties] Properties to set
         */
        function KeepAliveAPIv1PingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new KeepAliveAPIv1PingRequest instance using the specified properties.
         * @function create
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {proto.IKeepAliveAPIv1PingRequest=} [properties] Properties to set
         * @returns {proto.KeepAliveAPIv1PingRequest} KeepAliveAPIv1PingRequest instance
         */
        KeepAliveAPIv1PingRequest.create = function create(properties) {
            return new KeepAliveAPIv1PingRequest(properties);
        };

        /**
         * Encodes the specified KeepAliveAPIv1PingRequest message. Does not implicitly {@link proto.KeepAliveAPIv1PingRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {proto.IKeepAliveAPIv1PingRequest} message KeepAliveAPIv1PingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeepAliveAPIv1PingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified KeepAliveAPIv1PingRequest message, length delimited. Does not implicitly {@link proto.KeepAliveAPIv1PingRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {proto.IKeepAliveAPIv1PingRequest} message KeepAliveAPIv1PingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeepAliveAPIv1PingRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeepAliveAPIv1PingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.KeepAliveAPIv1PingRequest} KeepAliveAPIv1PingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeepAliveAPIv1PingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.KeepAliveAPIv1PingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeepAliveAPIv1PingRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.KeepAliveAPIv1PingRequest} KeepAliveAPIv1PingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeepAliveAPIv1PingRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeepAliveAPIv1PingRequest message.
         * @function verify
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeepAliveAPIv1PingRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a KeepAliveAPIv1PingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.KeepAliveAPIv1PingRequest} KeepAliveAPIv1PingRequest
         */
        KeepAliveAPIv1PingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.KeepAliveAPIv1PingRequest)
                return object;
            return new $root.proto.KeepAliveAPIv1PingRequest();
        };

        /**
         * Creates a plain object from a KeepAliveAPIv1PingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {proto.KeepAliveAPIv1PingRequest} message KeepAliveAPIv1PingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeepAliveAPIv1PingRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this KeepAliveAPIv1PingRequest to JSON.
         * @function toJSON
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeepAliveAPIv1PingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for KeepAliveAPIv1PingRequest
         * @function getTypeUrl
         * @memberof proto.KeepAliveAPIv1PingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        KeepAliveAPIv1PingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.KeepAliveAPIv1PingRequest";
        };

        return KeepAliveAPIv1PingRequest;
    })();

    proto.AuthenticateAPIv1Client = (function() {

        /**
         * Properties of an AuthenticateAPIv1Client.
         * @memberof proto
         * @interface IAuthenticateAPIv1Client
         * @property {proto.ICreateTokenRequest|null} [createTokenRequest] AuthenticateAPIv1Client createTokenRequest
         * @property {proto.IAuthenticateRequest|null} [authenticateRequest] AuthenticateAPIv1Client authenticateRequest
         * @property {proto.IDeauthenticateRequest|null} [deauthenticateRequest] AuthenticateAPIv1Client deauthenticateRequest
         */

        /**
         * Constructs a new AuthenticateAPIv1Client.
         * @memberof proto
         * @classdesc Represents an AuthenticateAPIv1Client.
         * @implements IAuthenticateAPIv1Client
         * @constructor
         * @param {proto.IAuthenticateAPIv1Client=} [properties] Properties to set
         */
        function AuthenticateAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateAPIv1Client createTokenRequest.
         * @member {proto.ICreateTokenRequest|null|undefined} createTokenRequest
         * @memberof proto.AuthenticateAPIv1Client
         * @instance
         */
        AuthenticateAPIv1Client.prototype.createTokenRequest = null;

        /**
         * AuthenticateAPIv1Client authenticateRequest.
         * @member {proto.IAuthenticateRequest|null|undefined} authenticateRequest
         * @memberof proto.AuthenticateAPIv1Client
         * @instance
         */
        AuthenticateAPIv1Client.prototype.authenticateRequest = null;

        /**
         * AuthenticateAPIv1Client deauthenticateRequest.
         * @member {proto.IDeauthenticateRequest|null|undefined} deauthenticateRequest
         * @memberof proto.AuthenticateAPIv1Client
         * @instance
         */
        AuthenticateAPIv1Client.prototype.deauthenticateRequest = null;

        /**
         * Creates a new AuthenticateAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {proto.IAuthenticateAPIv1Client=} [properties] Properties to set
         * @returns {proto.AuthenticateAPIv1Client} AuthenticateAPIv1Client instance
         */
        AuthenticateAPIv1Client.create = function create(properties) {
            return new AuthenticateAPIv1Client(properties);
        };

        /**
         * Encodes the specified AuthenticateAPIv1Client message. Does not implicitly {@link proto.AuthenticateAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {proto.IAuthenticateAPIv1Client} message AuthenticateAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createTokenRequest != null && Object.hasOwnProperty.call(message, "createTokenRequest"))
                $root.proto.CreateTokenRequest.encode(message.createTokenRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.authenticateRequest != null && Object.hasOwnProperty.call(message, "authenticateRequest"))
                $root.proto.AuthenticateRequest.encode(message.authenticateRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.deauthenticateRequest != null && Object.hasOwnProperty.call(message, "deauthenticateRequest"))
                $root.proto.DeauthenticateRequest.encode(message.deauthenticateRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticateAPIv1Client message, length delimited. Does not implicitly {@link proto.AuthenticateAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {proto.IAuthenticateAPIv1Client} message AuthenticateAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AuthenticateAPIv1Client} AuthenticateAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AuthenticateAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.createTokenRequest = $root.proto.CreateTokenRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.authenticateRequest = $root.proto.AuthenticateRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.deauthenticateRequest = $root.proto.DeauthenticateRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AuthenticateAPIv1Client} AuthenticateAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateAPIv1Client message.
         * @function verify
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.createTokenRequest != null && message.hasOwnProperty("createTokenRequest")) {
                var error = $root.proto.CreateTokenRequest.verify(message.createTokenRequest);
                if (error)
                    return "createTokenRequest." + error;
            }
            if (message.authenticateRequest != null && message.hasOwnProperty("authenticateRequest")) {
                var error = $root.proto.AuthenticateRequest.verify(message.authenticateRequest);
                if (error)
                    return "authenticateRequest." + error;
            }
            if (message.deauthenticateRequest != null && message.hasOwnProperty("deauthenticateRequest")) {
                var error = $root.proto.DeauthenticateRequest.verify(message.deauthenticateRequest);
                if (error)
                    return "deauthenticateRequest." + error;
            }
            return null;
        };

        /**
         * Creates an AuthenticateAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AuthenticateAPIv1Client} AuthenticateAPIv1Client
         */
        AuthenticateAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AuthenticateAPIv1Client)
                return object;
            var message = new $root.proto.AuthenticateAPIv1Client();
            if (object.createTokenRequest != null) {
                if (typeof object.createTokenRequest !== "object")
                    throw TypeError(".proto.AuthenticateAPIv1Client.createTokenRequest: object expected");
                message.createTokenRequest = $root.proto.CreateTokenRequest.fromObject(object.createTokenRequest);
            }
            if (object.authenticateRequest != null) {
                if (typeof object.authenticateRequest !== "object")
                    throw TypeError(".proto.AuthenticateAPIv1Client.authenticateRequest: object expected");
                message.authenticateRequest = $root.proto.AuthenticateRequest.fromObject(object.authenticateRequest);
            }
            if (object.deauthenticateRequest != null) {
                if (typeof object.deauthenticateRequest !== "object")
                    throw TypeError(".proto.AuthenticateAPIv1Client.deauthenticateRequest: object expected");
                message.deauthenticateRequest = $root.proto.DeauthenticateRequest.fromObject(object.deauthenticateRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {proto.AuthenticateAPIv1Client} message AuthenticateAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.createTokenRequest = null;
                object.authenticateRequest = null;
                object.deauthenticateRequest = null;
            }
            if (message.createTokenRequest != null && message.hasOwnProperty("createTokenRequest"))
                object.createTokenRequest = $root.proto.CreateTokenRequest.toObject(message.createTokenRequest, options);
            if (message.authenticateRequest != null && message.hasOwnProperty("authenticateRequest"))
                object.authenticateRequest = $root.proto.AuthenticateRequest.toObject(message.authenticateRequest, options);
            if (message.deauthenticateRequest != null && message.hasOwnProperty("deauthenticateRequest"))
                object.deauthenticateRequest = $root.proto.DeauthenticateRequest.toObject(message.deauthenticateRequest, options);
            return object;
        };

        /**
         * Converts this AuthenticateAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.AuthenticateAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticateAPIv1Client
         * @function getTypeUrl
         * @memberof proto.AuthenticateAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticateAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AuthenticateAPIv1Client";
        };

        return AuthenticateAPIv1Client;
    })();

    proto.CreateTokenRequest = (function() {

        /**
         * Properties of a CreateTokenRequest.
         * @memberof proto
         * @interface ICreateTokenRequest
         * @property {proto.CreateTokenRequest.AuthenticationMethod} method CreateTokenRequest method
         * @property {string} data CreateTokenRequest data
         * @property {string|null} [androidAppHash] CreateTokenRequest androidAppHash
         */

        /**
         * Constructs a new CreateTokenRequest.
         * @memberof proto
         * @classdesc Represents a CreateTokenRequest.
         * @implements ICreateTokenRequest
         * @constructor
         * @param {proto.ICreateTokenRequest=} [properties] Properties to set
         */
        function CreateTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateTokenRequest method.
         * @member {proto.CreateTokenRequest.AuthenticationMethod} method
         * @memberof proto.CreateTokenRequest
         * @instance
         */
        CreateTokenRequest.prototype.method = 1;

        /**
         * CreateTokenRequest data.
         * @member {string} data
         * @memberof proto.CreateTokenRequest
         * @instance
         */
        CreateTokenRequest.prototype.data = "";

        /**
         * CreateTokenRequest androidAppHash.
         * @member {string} androidAppHash
         * @memberof proto.CreateTokenRequest
         * @instance
         */
        CreateTokenRequest.prototype.androidAppHash = "";

        /**
         * Creates a new CreateTokenRequest instance using the specified properties.
         * @function create
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {proto.ICreateTokenRequest=} [properties] Properties to set
         * @returns {proto.CreateTokenRequest} CreateTokenRequest instance
         */
        CreateTokenRequest.create = function create(properties) {
            return new CreateTokenRequest(properties);
        };

        /**
         * Encodes the specified CreateTokenRequest message. Does not implicitly {@link proto.CreateTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {proto.ICreateTokenRequest} message CreateTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.method);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.data);
            if (message.androidAppHash != null && Object.hasOwnProperty.call(message, "androidAppHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.androidAppHash);
            return writer;
        };

        /**
         * Encodes the specified CreateTokenRequest message, length delimited. Does not implicitly {@link proto.CreateTokenRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {proto.ICreateTokenRequest} message CreateTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CreateTokenRequest} CreateTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CreateTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.method = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = reader.string();
                        break;
                    }
                case 3: {
                        message.androidAppHash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("method"))
                throw $util.ProtocolError("missing required 'method'", { instance: message });
            if (!message.hasOwnProperty("data"))
                throw $util.ProtocolError("missing required 'data'", { instance: message });
            return message;
        };

        /**
         * Decodes a CreateTokenRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CreateTokenRequest} CreateTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTokenRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateTokenRequest message.
         * @function verify
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateTokenRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.method) {
            default:
                return "method: enum value expected";
            case 1:
            case 2:
            case 3:
                break;
            }
            if (!$util.isString(message.data))
                return "data: string expected";
            if (message.androidAppHash != null && message.hasOwnProperty("androidAppHash"))
                if (!$util.isString(message.androidAppHash))
                    return "androidAppHash: string expected";
            return null;
        };

        /**
         * Creates a CreateTokenRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CreateTokenRequest} CreateTokenRequest
         */
        CreateTokenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CreateTokenRequest)
                return object;
            var message = new $root.proto.CreateTokenRequest();
            switch (object.method) {
            default:
                if (typeof object.method === "number") {
                    message.method = object.method;
                    break;
                }
                break;
            case "PHONENUMBER":
            case 1:
                message.method = 1;
                break;
            case "EMAIL":
            case 2:
                message.method = 2;
                break;
            case "USERNAME":
            case 3:
                message.method = 3;
                break;
            }
            if (object.data != null)
                message.data = String(object.data);
            if (object.androidAppHash != null)
                message.androidAppHash = String(object.androidAppHash);
            return message;
        };

        /**
         * Creates a plain object from a CreateTokenRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {proto.CreateTokenRequest} message CreateTokenRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateTokenRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.method = options.enums === String ? "PHONENUMBER" : 1;
                object.data = "";
                object.androidAppHash = "";
            }
            if (message.method != null && message.hasOwnProperty("method"))
                object.method = options.enums === String ? $root.proto.CreateTokenRequest.AuthenticationMethod[message.method] === undefined ? message.method : $root.proto.CreateTokenRequest.AuthenticationMethod[message.method] : message.method;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = message.data;
            if (message.androidAppHash != null && message.hasOwnProperty("androidAppHash"))
                object.androidAppHash = message.androidAppHash;
            return object;
        };

        /**
         * Converts this CreateTokenRequest to JSON.
         * @function toJSON
         * @memberof proto.CreateTokenRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateTokenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateTokenRequest
         * @function getTypeUrl
         * @memberof proto.CreateTokenRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CreateTokenRequest";
        };

        /**
         * AuthenticationMethod enum.
         * @name proto.CreateTokenRequest.AuthenticationMethod
         * @enum {number}
         * @property {number} PHONENUMBER=1 PHONENUMBER value
         * @property {number} EMAIL=2 EMAIL value
         * @property {number} USERNAME=3 USERNAME value
         */
        CreateTokenRequest.AuthenticationMethod = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "PHONENUMBER"] = 1;
            values[valuesById[2] = "EMAIL"] = 2;
            values[valuesById[3] = "USERNAME"] = 3;
            return values;
        })();

        return CreateTokenRequest;
    })();

    proto.CreateTokenResponse = (function() {

        /**
         * Properties of a CreateTokenResponse.
         * @memberof proto
         * @interface ICreateTokenResponse
         * @property {string} realm CreateTokenResponse realm
         * @property {string} tokenId CreateTokenResponse tokenId
         * @property {string} token CreateTokenResponse token
         * @property {boolean} incomplete CreateTokenResponse incomplete
         * @property {string|null} [domain] CreateTokenResponse domain
         */

        /**
         * Constructs a new CreateTokenResponse.
         * @memberof proto
         * @classdesc Represents a CreateTokenResponse.
         * @implements ICreateTokenResponse
         * @constructor
         * @param {proto.ICreateTokenResponse=} [properties] Properties to set
         */
        function CreateTokenResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateTokenResponse realm.
         * @member {string} realm
         * @memberof proto.CreateTokenResponse
         * @instance
         */
        CreateTokenResponse.prototype.realm = "";

        /**
         * CreateTokenResponse tokenId.
         * @member {string} tokenId
         * @memberof proto.CreateTokenResponse
         * @instance
         */
        CreateTokenResponse.prototype.tokenId = "";

        /**
         * CreateTokenResponse token.
         * @member {string} token
         * @memberof proto.CreateTokenResponse
         * @instance
         */
        CreateTokenResponse.prototype.token = "";

        /**
         * CreateTokenResponse incomplete.
         * @member {boolean} incomplete
         * @memberof proto.CreateTokenResponse
         * @instance
         */
        CreateTokenResponse.prototype.incomplete = false;

        /**
         * CreateTokenResponse domain.
         * @member {string} domain
         * @memberof proto.CreateTokenResponse
         * @instance
         */
        CreateTokenResponse.prototype.domain = "";

        /**
         * Creates a new CreateTokenResponse instance using the specified properties.
         * @function create
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {proto.ICreateTokenResponse=} [properties] Properties to set
         * @returns {proto.CreateTokenResponse} CreateTokenResponse instance
         */
        CreateTokenResponse.create = function create(properties) {
            return new CreateTokenResponse(properties);
        };

        /**
         * Encodes the specified CreateTokenResponse message. Does not implicitly {@link proto.CreateTokenResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {proto.ICreateTokenResponse} message CreateTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.realm);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.tokenId);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.token);
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.incomplete);
            if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.domain);
            return writer;
        };

        /**
         * Encodes the specified CreateTokenResponse message, length delimited. Does not implicitly {@link proto.CreateTokenResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {proto.ICreateTokenResponse} message CreateTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateTokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CreateTokenResponse} CreateTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CreateTokenResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.realm = reader.string();
                        break;
                    }
                case 2: {
                        message.tokenId = reader.string();
                        break;
                    }
                case 3: {
                        message.token = reader.string();
                        break;
                    }
                case 4: {
                        message.incomplete = reader.bool();
                        break;
                    }
                case 5: {
                        message.domain = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("realm"))
                throw $util.ProtocolError("missing required 'realm'", { instance: message });
            if (!message.hasOwnProperty("tokenId"))
                throw $util.ProtocolError("missing required 'tokenId'", { instance: message });
            if (!message.hasOwnProperty("token"))
                throw $util.ProtocolError("missing required 'token'", { instance: message });
            if (!message.hasOwnProperty("incomplete"))
                throw $util.ProtocolError("missing required 'incomplete'", { instance: message });
            return message;
        };

        /**
         * Decodes a CreateTokenResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CreateTokenResponse} CreateTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTokenResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateTokenResponse message.
         * @function verify
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateTokenResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.realm))
                return "realm: string expected";
            if (!$util.isString(message.tokenId))
                return "tokenId: string expected";
            if (!$util.isString(message.token))
                return "token: string expected";
            if (typeof message.incomplete !== "boolean")
                return "incomplete: boolean expected";
            if (message.domain != null && message.hasOwnProperty("domain"))
                if (!$util.isString(message.domain))
                    return "domain: string expected";
            return null;
        };

        /**
         * Creates a CreateTokenResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CreateTokenResponse} CreateTokenResponse
         */
        CreateTokenResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CreateTokenResponse)
                return object;
            var message = new $root.proto.CreateTokenResponse();
            if (object.realm != null)
                message.realm = String(object.realm);
            if (object.tokenId != null)
                message.tokenId = String(object.tokenId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.incomplete != null)
                message.incomplete = Boolean(object.incomplete);
            if (object.domain != null)
                message.domain = String(object.domain);
            return message;
        };

        /**
         * Creates a plain object from a CreateTokenResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {proto.CreateTokenResponse} message CreateTokenResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateTokenResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.realm = "";
                object.tokenId = "";
                object.token = "";
                object.incomplete = false;
                object.domain = "";
            }
            if (message.realm != null && message.hasOwnProperty("realm"))
                object.realm = message.realm;
            if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                object.tokenId = message.tokenId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.incomplete != null && message.hasOwnProperty("incomplete"))
                object.incomplete = message.incomplete;
            if (message.domain != null && message.hasOwnProperty("domain"))
                object.domain = message.domain;
            return object;
        };

        /**
         * Converts this CreateTokenResponse to JSON.
         * @function toJSON
         * @memberof proto.CreateTokenResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateTokenResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateTokenResponse
         * @function getTypeUrl
         * @memberof proto.CreateTokenResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CreateTokenResponse";
        };

        return CreateTokenResponse;
    })();

    proto.AuthenticateRequest = (function() {

        /**
         * Properties of an AuthenticateRequest.
         * @memberof proto
         * @interface IAuthenticateRequest
         * @property {boolean|null} [purchaseUsage] AuthenticateRequest purchaseUsage
         * @property {string|null} [jwt] AuthenticateRequest jwt
         * @property {boolean|null} [automaticConnect] AuthenticateRequest automaticConnect
         */

        /**
         * Constructs a new AuthenticateRequest.
         * @memberof proto
         * @classdesc Represents an AuthenticateRequest.
         * @implements IAuthenticateRequest
         * @constructor
         * @param {proto.IAuthenticateRequest=} [properties] Properties to set
         */
        function AuthenticateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateRequest purchaseUsage.
         * @member {boolean} purchaseUsage
         * @memberof proto.AuthenticateRequest
         * @instance
         */
        AuthenticateRequest.prototype.purchaseUsage = false;

        /**
         * AuthenticateRequest jwt.
         * @member {string} jwt
         * @memberof proto.AuthenticateRequest
         * @instance
         */
        AuthenticateRequest.prototype.jwt = "";

        /**
         * AuthenticateRequest automaticConnect.
         * @member {boolean} automaticConnect
         * @memberof proto.AuthenticateRequest
         * @instance
         */
        AuthenticateRequest.prototype.automaticConnect = false;

        /**
         * Creates a new AuthenticateRequest instance using the specified properties.
         * @function create
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {proto.IAuthenticateRequest=} [properties] Properties to set
         * @returns {proto.AuthenticateRequest} AuthenticateRequest instance
         */
        AuthenticateRequest.create = function create(properties) {
            return new AuthenticateRequest(properties);
        };

        /**
         * Encodes the specified AuthenticateRequest message. Does not implicitly {@link proto.AuthenticateRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {proto.IAuthenticateRequest} message AuthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.purchaseUsage != null && Object.hasOwnProperty.call(message, "purchaseUsage"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.purchaseUsage);
            if (message.jwt != null && Object.hasOwnProperty.call(message, "jwt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.jwt);
            if (message.automaticConnect != null && Object.hasOwnProperty.call(message, "automaticConnect"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.automaticConnect);
            return writer;
        };

        /**
         * Encodes the specified AuthenticateRequest message, length delimited. Does not implicitly {@link proto.AuthenticateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {proto.IAuthenticateRequest} message AuthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AuthenticateRequest} AuthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AuthenticateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.purchaseUsage = reader.bool();
                        break;
                    }
                case 2: {
                        message.jwt = reader.string();
                        break;
                    }
                case 3: {
                        message.automaticConnect = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AuthenticateRequest} AuthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateRequest message.
         * @function verify
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.purchaseUsage != null && message.hasOwnProperty("purchaseUsage"))
                if (typeof message.purchaseUsage !== "boolean")
                    return "purchaseUsage: boolean expected";
            if (message.jwt != null && message.hasOwnProperty("jwt"))
                if (!$util.isString(message.jwt))
                    return "jwt: string expected";
            if (message.automaticConnect != null && message.hasOwnProperty("automaticConnect"))
                if (typeof message.automaticConnect !== "boolean")
                    return "automaticConnect: boolean expected";
            return null;
        };

        /**
         * Creates an AuthenticateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AuthenticateRequest} AuthenticateRequest
         */
        AuthenticateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AuthenticateRequest)
                return object;
            var message = new $root.proto.AuthenticateRequest();
            if (object.purchaseUsage != null)
                message.purchaseUsage = Boolean(object.purchaseUsage);
            if (object.jwt != null)
                message.jwt = String(object.jwt);
            if (object.automaticConnect != null)
                message.automaticConnect = Boolean(object.automaticConnect);
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {proto.AuthenticateRequest} message AuthenticateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.purchaseUsage = false;
                object.jwt = "";
                object.automaticConnect = false;
            }
            if (message.purchaseUsage != null && message.hasOwnProperty("purchaseUsage"))
                object.purchaseUsage = message.purchaseUsage;
            if (message.jwt != null && message.hasOwnProperty("jwt"))
                object.jwt = message.jwt;
            if (message.automaticConnect != null && message.hasOwnProperty("automaticConnect"))
                object.automaticConnect = message.automaticConnect;
            return object;
        };

        /**
         * Converts this AuthenticateRequest to JSON.
         * @function toJSON
         * @memberof proto.AuthenticateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticateRequest
         * @function getTypeUrl
         * @memberof proto.AuthenticateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AuthenticateRequest";
        };

        return AuthenticateRequest;
    })();

    proto.AuthenticateResponse = (function() {

        /**
         * Properties of an AuthenticateResponse.
         * @memberof proto
         * @interface IAuthenticateResponse
         * @property {string|null} [accountId] AuthenticateResponse accountId
         * @property {string} displayName AuthenticateResponse displayName
         * @property {string} organization AuthenticateResponse organization
         * @property {string} loginId AuthenticateResponse loginId
         * @property {string|null} [email] AuthenticateResponse email
         * @property {Array.<string>|null} [phoneNumbers] AuthenticateResponse phoneNumbers
         * @property {string|null} [domain] AuthenticateResponse domain
         * @property {boolean|null} [hasContacts] AuthenticateResponse hasContacts
         * @property {boolean|null} [canMakeCalls] AuthenticateResponse canMakeCalls
         * @property {boolean|null} [canReceiveCalls] AuthenticateResponse canReceiveCalls
         * @property {boolean|null} [canLocateOthers] AuthenticateResponse canLocateOthers
         * @property {boolean|null} [canBeLocated] AuthenticateResponse canBeLocated
         * @property {boolean|null} [canEnterQueues] AuthenticateResponse canEnterQueues
         * @property {boolean|null} [canManageQueues] AuthenticateResponse canManageQueues
         * @property {boolean|null} [canTriggerAlarm] AuthenticateResponse canTriggerAlarm
         * @property {boolean|null} [canManageAlarms] AuthenticateResponse canManageAlarms
         * @property {string|null} [title] AuthenticateResponse title
         * @property {string|null} [uuid] AuthenticateResponse uuid
         */

        /**
         * Constructs a new AuthenticateResponse.
         * @memberof proto
         * @classdesc Represents an AuthenticateResponse.
         * @implements IAuthenticateResponse
         * @constructor
         * @param {proto.IAuthenticateResponse=} [properties] Properties to set
         */
        function AuthenticateResponse(properties) {
            this.phoneNumbers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateResponse accountId.
         * @member {string} accountId
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.accountId = "";

        /**
         * AuthenticateResponse displayName.
         * @member {string} displayName
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.displayName = "";

        /**
         * AuthenticateResponse organization.
         * @member {string} organization
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.organization = "";

        /**
         * AuthenticateResponse loginId.
         * @member {string} loginId
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.loginId = "";

        /**
         * AuthenticateResponse email.
         * @member {string} email
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.email = "";

        /**
         * AuthenticateResponse phoneNumbers.
         * @member {Array.<string>} phoneNumbers
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.phoneNumbers = $util.emptyArray;

        /**
         * AuthenticateResponse domain.
         * @member {string} domain
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.domain = "";

        /**
         * AuthenticateResponse hasContacts.
         * @member {boolean} hasContacts
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.hasContacts = false;

        /**
         * AuthenticateResponse canMakeCalls.
         * @member {boolean} canMakeCalls
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canMakeCalls = false;

        /**
         * AuthenticateResponse canReceiveCalls.
         * @member {boolean} canReceiveCalls
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canReceiveCalls = false;

        /**
         * AuthenticateResponse canLocateOthers.
         * @member {boolean} canLocateOthers
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canLocateOthers = false;

        /**
         * AuthenticateResponse canBeLocated.
         * @member {boolean} canBeLocated
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canBeLocated = false;

        /**
         * AuthenticateResponse canEnterQueues.
         * @member {boolean} canEnterQueues
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canEnterQueues = false;

        /**
         * AuthenticateResponse canManageQueues.
         * @member {boolean} canManageQueues
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canManageQueues = false;

        /**
         * AuthenticateResponse canTriggerAlarm.
         * @member {boolean} canTriggerAlarm
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canTriggerAlarm = false;

        /**
         * AuthenticateResponse canManageAlarms.
         * @member {boolean} canManageAlarms
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.canManageAlarms = false;

        /**
         * AuthenticateResponse title.
         * @member {string} title
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.title = "";

        /**
         * AuthenticateResponse uuid.
         * @member {string} uuid
         * @memberof proto.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.uuid = "";

        /**
         * Creates a new AuthenticateResponse instance using the specified properties.
         * @function create
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {proto.IAuthenticateResponse=} [properties] Properties to set
         * @returns {proto.AuthenticateResponse} AuthenticateResponse instance
         */
        AuthenticateResponse.create = function create(properties) {
            return new AuthenticateResponse(properties);
        };

        /**
         * Encodes the specified AuthenticateResponse message. Does not implicitly {@link proto.AuthenticateResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {proto.IAuthenticateResponse} message AuthenticateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayName);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.organization);
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.loginId);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
            if (message.phoneNumbers != null && message.phoneNumbers.length)
                for (var i = 0; i < message.phoneNumbers.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.phoneNumbers[i]);
            if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.domain);
            if (message.hasContacts != null && Object.hasOwnProperty.call(message, "hasContacts"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.hasContacts);
            if (message.canMakeCalls != null && Object.hasOwnProperty.call(message, "canMakeCalls"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.canMakeCalls);
            if (message.canReceiveCalls != null && Object.hasOwnProperty.call(message, "canReceiveCalls"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.canReceiveCalls);
            if (message.canLocateOthers != null && Object.hasOwnProperty.call(message, "canLocateOthers"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.canLocateOthers);
            if (message.canBeLocated != null && Object.hasOwnProperty.call(message, "canBeLocated"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.canBeLocated);
            if (message.canEnterQueues != null && Object.hasOwnProperty.call(message, "canEnterQueues"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.canEnterQueues);
            if (message.canManageQueues != null && Object.hasOwnProperty.call(message, "canManageQueues"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.canManageQueues);
            if (message.canTriggerAlarm != null && Object.hasOwnProperty.call(message, "canTriggerAlarm"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.canTriggerAlarm);
            if (message.canManageAlarms != null && Object.hasOwnProperty.call(message, "canManageAlarms"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.canManageAlarms);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.title);
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.uuid);
            return writer;
        };

        /**
         * Encodes the specified AuthenticateResponse message, length delimited. Does not implicitly {@link proto.AuthenticateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {proto.IAuthenticateResponse} message AuthenticateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AuthenticateResponse} AuthenticateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AuthenticateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.accountId = reader.string();
                        break;
                    }
                case 2: {
                        message.displayName = reader.string();
                        break;
                    }
                case 3: {
                        message.organization = reader.string();
                        break;
                    }
                case 4: {
                        message.loginId = reader.string();
                        break;
                    }
                case 5: {
                        message.email = reader.string();
                        break;
                    }
                case 6: {
                        if (!(message.phoneNumbers && message.phoneNumbers.length))
                            message.phoneNumbers = [];
                        message.phoneNumbers.push(reader.string());
                        break;
                    }
                case 7: {
                        message.domain = reader.string();
                        break;
                    }
                case 8: {
                        message.hasContacts = reader.bool();
                        break;
                    }
                case 9: {
                        message.canMakeCalls = reader.bool();
                        break;
                    }
                case 10: {
                        message.canReceiveCalls = reader.bool();
                        break;
                    }
                case 11: {
                        message.canLocateOthers = reader.bool();
                        break;
                    }
                case 12: {
                        message.canBeLocated = reader.bool();
                        break;
                    }
                case 13: {
                        message.canEnterQueues = reader.bool();
                        break;
                    }
                case 14: {
                        message.canManageQueues = reader.bool();
                        break;
                    }
                case 15: {
                        message.canTriggerAlarm = reader.bool();
                        break;
                    }
                case 16: {
                        message.canManageAlarms = reader.bool();
                        break;
                    }
                case 17: {
                        message.title = reader.string();
                        break;
                    }
                case 18: {
                        message.uuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("displayName"))
                throw $util.ProtocolError("missing required 'displayName'", { instance: message });
            if (!message.hasOwnProperty("organization"))
                throw $util.ProtocolError("missing required 'organization'", { instance: message });
            if (!message.hasOwnProperty("loginId"))
                throw $util.ProtocolError("missing required 'loginId'", { instance: message });
            return message;
        };

        /**
         * Decodes an AuthenticateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AuthenticateResponse} AuthenticateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateResponse message.
         * @function verify
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accountId != null && message.hasOwnProperty("accountId"))
                if (!$util.isString(message.accountId))
                    return "accountId: string expected";
            if (!$util.isString(message.displayName))
                return "displayName: string expected";
            if (!$util.isString(message.organization))
                return "organization: string expected";
            if (!$util.isString(message.loginId))
                return "loginId: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.phoneNumbers != null && message.hasOwnProperty("phoneNumbers")) {
                if (!Array.isArray(message.phoneNumbers))
                    return "phoneNumbers: array expected";
                for (var i = 0; i < message.phoneNumbers.length; ++i)
                    if (!$util.isString(message.phoneNumbers[i]))
                        return "phoneNumbers: string[] expected";
            }
            if (message.domain != null && message.hasOwnProperty("domain"))
                if (!$util.isString(message.domain))
                    return "domain: string expected";
            if (message.hasContacts != null && message.hasOwnProperty("hasContacts"))
                if (typeof message.hasContacts !== "boolean")
                    return "hasContacts: boolean expected";
            if (message.canMakeCalls != null && message.hasOwnProperty("canMakeCalls"))
                if (typeof message.canMakeCalls !== "boolean")
                    return "canMakeCalls: boolean expected";
            if (message.canReceiveCalls != null && message.hasOwnProperty("canReceiveCalls"))
                if (typeof message.canReceiveCalls !== "boolean")
                    return "canReceiveCalls: boolean expected";
            if (message.canLocateOthers != null && message.hasOwnProperty("canLocateOthers"))
                if (typeof message.canLocateOthers !== "boolean")
                    return "canLocateOthers: boolean expected";
            if (message.canBeLocated != null && message.hasOwnProperty("canBeLocated"))
                if (typeof message.canBeLocated !== "boolean")
                    return "canBeLocated: boolean expected";
            if (message.canEnterQueues != null && message.hasOwnProperty("canEnterQueues"))
                if (typeof message.canEnterQueues !== "boolean")
                    return "canEnterQueues: boolean expected";
            if (message.canManageQueues != null && message.hasOwnProperty("canManageQueues"))
                if (typeof message.canManageQueues !== "boolean")
                    return "canManageQueues: boolean expected";
            if (message.canTriggerAlarm != null && message.hasOwnProperty("canTriggerAlarm"))
                if (typeof message.canTriggerAlarm !== "boolean")
                    return "canTriggerAlarm: boolean expected";
            if (message.canManageAlarms != null && message.hasOwnProperty("canManageAlarms"))
                if (typeof message.canManageAlarms !== "boolean")
                    return "canManageAlarms: boolean expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            return null;
        };

        /**
         * Creates an AuthenticateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AuthenticateResponse} AuthenticateResponse
         */
        AuthenticateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AuthenticateResponse)
                return object;
            var message = new $root.proto.AuthenticateResponse();
            if (object.accountId != null)
                message.accountId = String(object.accountId);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.organization != null)
                message.organization = String(object.organization);
            if (object.loginId != null)
                message.loginId = String(object.loginId);
            if (object.email != null)
                message.email = String(object.email);
            if (object.phoneNumbers) {
                if (!Array.isArray(object.phoneNumbers))
                    throw TypeError(".proto.AuthenticateResponse.phoneNumbers: array expected");
                message.phoneNumbers = [];
                for (var i = 0; i < object.phoneNumbers.length; ++i)
                    message.phoneNumbers[i] = String(object.phoneNumbers[i]);
            }
            if (object.domain != null)
                message.domain = String(object.domain);
            if (object.hasContacts != null)
                message.hasContacts = Boolean(object.hasContacts);
            if (object.canMakeCalls != null)
                message.canMakeCalls = Boolean(object.canMakeCalls);
            if (object.canReceiveCalls != null)
                message.canReceiveCalls = Boolean(object.canReceiveCalls);
            if (object.canLocateOthers != null)
                message.canLocateOthers = Boolean(object.canLocateOthers);
            if (object.canBeLocated != null)
                message.canBeLocated = Boolean(object.canBeLocated);
            if (object.canEnterQueues != null)
                message.canEnterQueues = Boolean(object.canEnterQueues);
            if (object.canManageQueues != null)
                message.canManageQueues = Boolean(object.canManageQueues);
            if (object.canTriggerAlarm != null)
                message.canTriggerAlarm = Boolean(object.canTriggerAlarm);
            if (object.canManageAlarms != null)
                message.canManageAlarms = Boolean(object.canManageAlarms);
            if (object.title != null)
                message.title = String(object.title);
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {proto.AuthenticateResponse} message AuthenticateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.phoneNumbers = [];
            if (options.defaults) {
                object.accountId = "";
                object.displayName = "";
                object.organization = "";
                object.loginId = "";
                object.email = "";
                object.domain = "";
                object.hasContacts = false;
                object.canMakeCalls = false;
                object.canReceiveCalls = false;
                object.canLocateOthers = false;
                object.canBeLocated = false;
                object.canEnterQueues = false;
                object.canManageQueues = false;
                object.canTriggerAlarm = false;
                object.canManageAlarms = false;
                object.title = "";
                object.uuid = "";
            }
            if (message.accountId != null && message.hasOwnProperty("accountId"))
                object.accountId = message.accountId;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.organization != null && message.hasOwnProperty("organization"))
                object.organization = message.organization;
            if (message.loginId != null && message.hasOwnProperty("loginId"))
                object.loginId = message.loginId;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.phoneNumbers && message.phoneNumbers.length) {
                object.phoneNumbers = [];
                for (var j = 0; j < message.phoneNumbers.length; ++j)
                    object.phoneNumbers[j] = message.phoneNumbers[j];
            }
            if (message.domain != null && message.hasOwnProperty("domain"))
                object.domain = message.domain;
            if (message.hasContacts != null && message.hasOwnProperty("hasContacts"))
                object.hasContacts = message.hasContacts;
            if (message.canMakeCalls != null && message.hasOwnProperty("canMakeCalls"))
                object.canMakeCalls = message.canMakeCalls;
            if (message.canReceiveCalls != null && message.hasOwnProperty("canReceiveCalls"))
                object.canReceiveCalls = message.canReceiveCalls;
            if (message.canLocateOthers != null && message.hasOwnProperty("canLocateOthers"))
                object.canLocateOthers = message.canLocateOthers;
            if (message.canBeLocated != null && message.hasOwnProperty("canBeLocated"))
                object.canBeLocated = message.canBeLocated;
            if (message.canEnterQueues != null && message.hasOwnProperty("canEnterQueues"))
                object.canEnterQueues = message.canEnterQueues;
            if (message.canManageQueues != null && message.hasOwnProperty("canManageQueues"))
                object.canManageQueues = message.canManageQueues;
            if (message.canTriggerAlarm != null && message.hasOwnProperty("canTriggerAlarm"))
                object.canTriggerAlarm = message.canTriggerAlarm;
            if (message.canManageAlarms != null && message.hasOwnProperty("canManageAlarms"))
                object.canManageAlarms = message.canManageAlarms;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            return object;
        };

        /**
         * Converts this AuthenticateResponse to JSON.
         * @function toJSON
         * @memberof proto.AuthenticateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticateResponse
         * @function getTypeUrl
         * @memberof proto.AuthenticateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AuthenticateResponse";
        };

        return AuthenticateResponse;
    })();

    proto.DeauthenticateRequest = (function() {

        /**
         * Properties of a DeauthenticateRequest.
         * @memberof proto
         * @interface IDeauthenticateRequest
         * @property {boolean} allDevices DeauthenticateRequest allDevices
         */

        /**
         * Constructs a new DeauthenticateRequest.
         * @memberof proto
         * @classdesc Represents a DeauthenticateRequest.
         * @implements IDeauthenticateRequest
         * @constructor
         * @param {proto.IDeauthenticateRequest=} [properties] Properties to set
         */
        function DeauthenticateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeauthenticateRequest allDevices.
         * @member {boolean} allDevices
         * @memberof proto.DeauthenticateRequest
         * @instance
         */
        DeauthenticateRequest.prototype.allDevices = false;

        /**
         * Creates a new DeauthenticateRequest instance using the specified properties.
         * @function create
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {proto.IDeauthenticateRequest=} [properties] Properties to set
         * @returns {proto.DeauthenticateRequest} DeauthenticateRequest instance
         */
        DeauthenticateRequest.create = function create(properties) {
            return new DeauthenticateRequest(properties);
        };

        /**
         * Encodes the specified DeauthenticateRequest message. Does not implicitly {@link proto.DeauthenticateRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {proto.IDeauthenticateRequest} message DeauthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeauthenticateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.allDevices);
            return writer;
        };

        /**
         * Encodes the specified DeauthenticateRequest message, length delimited. Does not implicitly {@link proto.DeauthenticateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {proto.IDeauthenticateRequest} message DeauthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeauthenticateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeauthenticateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.DeauthenticateRequest} DeauthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeauthenticateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.DeauthenticateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.allDevices = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("allDevices"))
                throw $util.ProtocolError("missing required 'allDevices'", { instance: message });
            return message;
        };

        /**
         * Decodes a DeauthenticateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.DeauthenticateRequest} DeauthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeauthenticateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeauthenticateRequest message.
         * @function verify
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeauthenticateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.allDevices !== "boolean")
                return "allDevices: boolean expected";
            return null;
        };

        /**
         * Creates a DeauthenticateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.DeauthenticateRequest} DeauthenticateRequest
         */
        DeauthenticateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.DeauthenticateRequest)
                return object;
            var message = new $root.proto.DeauthenticateRequest();
            if (object.allDevices != null)
                message.allDevices = Boolean(object.allDevices);
            return message;
        };

        /**
         * Creates a plain object from a DeauthenticateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {proto.DeauthenticateRequest} message DeauthenticateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeauthenticateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.allDevices = false;
            if (message.allDevices != null && message.hasOwnProperty("allDevices"))
                object.allDevices = message.allDevices;
            return object;
        };

        /**
         * Converts this DeauthenticateRequest to JSON.
         * @function toJSON
         * @memberof proto.DeauthenticateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeauthenticateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeauthenticateRequest
         * @function getTypeUrl
         * @memberof proto.DeauthenticateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeauthenticateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.DeauthenticateRequest";
        };

        return DeauthenticateRequest;
    })();

    proto.AuthenticateAPIv1Server = (function() {

        /**
         * Properties of an AuthenticateAPIv1Server.
         * @memberof proto
         * @interface IAuthenticateAPIv1Server
         * @property {proto.IPasswordChallengeRequest|null} [passwordChallengeRequest] AuthenticateAPIv1Server passwordChallengeRequest
         * @property {proto.ITokenChallengeRequest|null} [tokenChallengeRequest] AuthenticateAPIv1Server tokenChallengeRequest
         */

        /**
         * Constructs a new AuthenticateAPIv1Server.
         * @memberof proto
         * @classdesc Represents an AuthenticateAPIv1Server.
         * @implements IAuthenticateAPIv1Server
         * @constructor
         * @param {proto.IAuthenticateAPIv1Server=} [properties] Properties to set
         */
        function AuthenticateAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateAPIv1Server passwordChallengeRequest.
         * @member {proto.IPasswordChallengeRequest|null|undefined} passwordChallengeRequest
         * @memberof proto.AuthenticateAPIv1Server
         * @instance
         */
        AuthenticateAPIv1Server.prototype.passwordChallengeRequest = null;

        /**
         * AuthenticateAPIv1Server tokenChallengeRequest.
         * @member {proto.ITokenChallengeRequest|null|undefined} tokenChallengeRequest
         * @memberof proto.AuthenticateAPIv1Server
         * @instance
         */
        AuthenticateAPIv1Server.prototype.tokenChallengeRequest = null;

        /**
         * Creates a new AuthenticateAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {proto.IAuthenticateAPIv1Server=} [properties] Properties to set
         * @returns {proto.AuthenticateAPIv1Server} AuthenticateAPIv1Server instance
         */
        AuthenticateAPIv1Server.create = function create(properties) {
            return new AuthenticateAPIv1Server(properties);
        };

        /**
         * Encodes the specified AuthenticateAPIv1Server message. Does not implicitly {@link proto.AuthenticateAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {proto.IAuthenticateAPIv1Server} message AuthenticateAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.passwordChallengeRequest != null && Object.hasOwnProperty.call(message, "passwordChallengeRequest"))
                $root.proto.PasswordChallengeRequest.encode(message.passwordChallengeRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tokenChallengeRequest != null && Object.hasOwnProperty.call(message, "tokenChallengeRequest"))
                $root.proto.TokenChallengeRequest.encode(message.tokenChallengeRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AuthenticateAPIv1Server message, length delimited. Does not implicitly {@link proto.AuthenticateAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {proto.IAuthenticateAPIv1Server} message AuthenticateAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AuthenticateAPIv1Server} AuthenticateAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AuthenticateAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.passwordChallengeRequest = $root.proto.PasswordChallengeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.tokenChallengeRequest = $root.proto.TokenChallengeRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AuthenticateAPIv1Server} AuthenticateAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateAPIv1Server message.
         * @function verify
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.passwordChallengeRequest != null && message.hasOwnProperty("passwordChallengeRequest")) {
                var error = $root.proto.PasswordChallengeRequest.verify(message.passwordChallengeRequest);
                if (error)
                    return "passwordChallengeRequest." + error;
            }
            if (message.tokenChallengeRequest != null && message.hasOwnProperty("tokenChallengeRequest")) {
                var error = $root.proto.TokenChallengeRequest.verify(message.tokenChallengeRequest);
                if (error)
                    return "tokenChallengeRequest." + error;
            }
            return null;
        };

        /**
         * Creates an AuthenticateAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AuthenticateAPIv1Server} AuthenticateAPIv1Server
         */
        AuthenticateAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AuthenticateAPIv1Server)
                return object;
            var message = new $root.proto.AuthenticateAPIv1Server();
            if (object.passwordChallengeRequest != null) {
                if (typeof object.passwordChallengeRequest !== "object")
                    throw TypeError(".proto.AuthenticateAPIv1Server.passwordChallengeRequest: object expected");
                message.passwordChallengeRequest = $root.proto.PasswordChallengeRequest.fromObject(object.passwordChallengeRequest);
            }
            if (object.tokenChallengeRequest != null) {
                if (typeof object.tokenChallengeRequest !== "object")
                    throw TypeError(".proto.AuthenticateAPIv1Server.tokenChallengeRequest: object expected");
                message.tokenChallengeRequest = $root.proto.TokenChallengeRequest.fromObject(object.tokenChallengeRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {proto.AuthenticateAPIv1Server} message AuthenticateAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.passwordChallengeRequest = null;
                object.tokenChallengeRequest = null;
            }
            if (message.passwordChallengeRequest != null && message.hasOwnProperty("passwordChallengeRequest"))
                object.passwordChallengeRequest = $root.proto.PasswordChallengeRequest.toObject(message.passwordChallengeRequest, options);
            if (message.tokenChallengeRequest != null && message.hasOwnProperty("tokenChallengeRequest"))
                object.tokenChallengeRequest = $root.proto.TokenChallengeRequest.toObject(message.tokenChallengeRequest, options);
            return object;
        };

        /**
         * Converts this AuthenticateAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.AuthenticateAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthenticateAPIv1Server
         * @function getTypeUrl
         * @memberof proto.AuthenticateAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthenticateAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AuthenticateAPIv1Server";
        };

        return AuthenticateAPIv1Server;
    })();

    proto.PasswordChallengeRequest = (function() {

        /**
         * Properties of a PasswordChallengeRequest.
         * @memberof proto
         * @interface IPasswordChallengeRequest
         * @property {Uint8Array} prefix PasswordChallengeRequest prefix
         * @property {Uint8Array} suffix PasswordChallengeRequest suffix
         */

        /**
         * Constructs a new PasswordChallengeRequest.
         * @memberof proto
         * @classdesc Represents a PasswordChallengeRequest.
         * @implements IPasswordChallengeRequest
         * @constructor
         * @param {proto.IPasswordChallengeRequest=} [properties] Properties to set
         */
        function PasswordChallengeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PasswordChallengeRequest prefix.
         * @member {Uint8Array} prefix
         * @memberof proto.PasswordChallengeRequest
         * @instance
         */
        PasswordChallengeRequest.prototype.prefix = $util.newBuffer([]);

        /**
         * PasswordChallengeRequest suffix.
         * @member {Uint8Array} suffix
         * @memberof proto.PasswordChallengeRequest
         * @instance
         */
        PasswordChallengeRequest.prototype.suffix = $util.newBuffer([]);

        /**
         * Creates a new PasswordChallengeRequest instance using the specified properties.
         * @function create
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {proto.IPasswordChallengeRequest=} [properties] Properties to set
         * @returns {proto.PasswordChallengeRequest} PasswordChallengeRequest instance
         */
        PasswordChallengeRequest.create = function create(properties) {
            return new PasswordChallengeRequest(properties);
        };

        /**
         * Encodes the specified PasswordChallengeRequest message. Does not implicitly {@link proto.PasswordChallengeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {proto.IPasswordChallengeRequest} message PasswordChallengeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PasswordChallengeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.prefix);
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.suffix);
            return writer;
        };

        /**
         * Encodes the specified PasswordChallengeRequest message, length delimited. Does not implicitly {@link proto.PasswordChallengeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {proto.IPasswordChallengeRequest} message PasswordChallengeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PasswordChallengeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PasswordChallengeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PasswordChallengeRequest} PasswordChallengeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PasswordChallengeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PasswordChallengeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.prefix = reader.bytes();
                        break;
                    }
                case 2: {
                        message.suffix = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("prefix"))
                throw $util.ProtocolError("missing required 'prefix'", { instance: message });
            if (!message.hasOwnProperty("suffix"))
                throw $util.ProtocolError("missing required 'suffix'", { instance: message });
            return message;
        };

        /**
         * Decodes a PasswordChallengeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PasswordChallengeRequest} PasswordChallengeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PasswordChallengeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PasswordChallengeRequest message.
         * @function verify
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PasswordChallengeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.prefix && typeof message.prefix.length === "number" || $util.isString(message.prefix)))
                return "prefix: buffer expected";
            if (!(message.suffix && typeof message.suffix.length === "number" || $util.isString(message.suffix)))
                return "suffix: buffer expected";
            return null;
        };

        /**
         * Creates a PasswordChallengeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PasswordChallengeRequest} PasswordChallengeRequest
         */
        PasswordChallengeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PasswordChallengeRequest)
                return object;
            var message = new $root.proto.PasswordChallengeRequest();
            if (object.prefix != null)
                if (typeof object.prefix === "string")
                    $util.base64.decode(object.prefix, message.prefix = $util.newBuffer($util.base64.length(object.prefix)), 0);
                else if (object.prefix.length >= 0)
                    message.prefix = object.prefix;
            if (object.suffix != null)
                if (typeof object.suffix === "string")
                    $util.base64.decode(object.suffix, message.suffix = $util.newBuffer($util.base64.length(object.suffix)), 0);
                else if (object.suffix.length >= 0)
                    message.suffix = object.suffix;
            return message;
        };

        /**
         * Creates a plain object from a PasswordChallengeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {proto.PasswordChallengeRequest} message PasswordChallengeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PasswordChallengeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.prefix = "";
                else {
                    object.prefix = [];
                    if (options.bytes !== Array)
                        object.prefix = $util.newBuffer(object.prefix);
                }
                if (options.bytes === String)
                    object.suffix = "";
                else {
                    object.suffix = [];
                    if (options.bytes !== Array)
                        object.suffix = $util.newBuffer(object.suffix);
                }
            }
            if (message.prefix != null && message.hasOwnProperty("prefix"))
                object.prefix = options.bytes === String ? $util.base64.encode(message.prefix, 0, message.prefix.length) : options.bytes === Array ? Array.prototype.slice.call(message.prefix) : message.prefix;
            if (message.suffix != null && message.hasOwnProperty("suffix"))
                object.suffix = options.bytes === String ? $util.base64.encode(message.suffix, 0, message.suffix.length) : options.bytes === Array ? Array.prototype.slice.call(message.suffix) : message.suffix;
            return object;
        };

        /**
         * Converts this PasswordChallengeRequest to JSON.
         * @function toJSON
         * @memberof proto.PasswordChallengeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PasswordChallengeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PasswordChallengeRequest
         * @function getTypeUrl
         * @memberof proto.PasswordChallengeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PasswordChallengeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PasswordChallengeRequest";
        };

        return PasswordChallengeRequest;
    })();

    proto.PasswordChallengeResponse = (function() {

        /**
         * Properties of a PasswordChallengeResponse.
         * @memberof proto
         * @interface IPasswordChallengeResponse
         * @property {Uint8Array} hashResponse PasswordChallengeResponse hashResponse
         */

        /**
         * Constructs a new PasswordChallengeResponse.
         * @memberof proto
         * @classdesc Represents a PasswordChallengeResponse.
         * @implements IPasswordChallengeResponse
         * @constructor
         * @param {proto.IPasswordChallengeResponse=} [properties] Properties to set
         */
        function PasswordChallengeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PasswordChallengeResponse hashResponse.
         * @member {Uint8Array} hashResponse
         * @memberof proto.PasswordChallengeResponse
         * @instance
         */
        PasswordChallengeResponse.prototype.hashResponse = $util.newBuffer([]);

        /**
         * Creates a new PasswordChallengeResponse instance using the specified properties.
         * @function create
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {proto.IPasswordChallengeResponse=} [properties] Properties to set
         * @returns {proto.PasswordChallengeResponse} PasswordChallengeResponse instance
         */
        PasswordChallengeResponse.create = function create(properties) {
            return new PasswordChallengeResponse(properties);
        };

        /**
         * Encodes the specified PasswordChallengeResponse message. Does not implicitly {@link proto.PasswordChallengeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {proto.IPasswordChallengeResponse} message PasswordChallengeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PasswordChallengeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.hashResponse);
            return writer;
        };

        /**
         * Encodes the specified PasswordChallengeResponse message, length delimited. Does not implicitly {@link proto.PasswordChallengeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {proto.IPasswordChallengeResponse} message PasswordChallengeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PasswordChallengeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PasswordChallengeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PasswordChallengeResponse} PasswordChallengeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PasswordChallengeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PasswordChallengeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hashResponse = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("hashResponse"))
                throw $util.ProtocolError("missing required 'hashResponse'", { instance: message });
            return message;
        };

        /**
         * Decodes a PasswordChallengeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PasswordChallengeResponse} PasswordChallengeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PasswordChallengeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PasswordChallengeResponse message.
         * @function verify
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PasswordChallengeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.hashResponse && typeof message.hashResponse.length === "number" || $util.isString(message.hashResponse)))
                return "hashResponse: buffer expected";
            return null;
        };

        /**
         * Creates a PasswordChallengeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PasswordChallengeResponse} PasswordChallengeResponse
         */
        PasswordChallengeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PasswordChallengeResponse)
                return object;
            var message = new $root.proto.PasswordChallengeResponse();
            if (object.hashResponse != null)
                if (typeof object.hashResponse === "string")
                    $util.base64.decode(object.hashResponse, message.hashResponse = $util.newBuffer($util.base64.length(object.hashResponse)), 0);
                else if (object.hashResponse.length >= 0)
                    message.hashResponse = object.hashResponse;
            return message;
        };

        /**
         * Creates a plain object from a PasswordChallengeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {proto.PasswordChallengeResponse} message PasswordChallengeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PasswordChallengeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.hashResponse = "";
                else {
                    object.hashResponse = [];
                    if (options.bytes !== Array)
                        object.hashResponse = $util.newBuffer(object.hashResponse);
                }
            if (message.hashResponse != null && message.hasOwnProperty("hashResponse"))
                object.hashResponse = options.bytes === String ? $util.base64.encode(message.hashResponse, 0, message.hashResponse.length) : options.bytes === Array ? Array.prototype.slice.call(message.hashResponse) : message.hashResponse;
            return object;
        };

        /**
         * Converts this PasswordChallengeResponse to JSON.
         * @function toJSON
         * @memberof proto.PasswordChallengeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PasswordChallengeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PasswordChallengeResponse
         * @function getTypeUrl
         * @memberof proto.PasswordChallengeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PasswordChallengeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PasswordChallengeResponse";
        };

        return PasswordChallengeResponse;
    })();

    proto.TokenChallengeRequest = (function() {

        /**
         * Properties of a TokenChallengeRequest.
         * @memberof proto
         * @interface ITokenChallengeRequest
         * @property {Array.<string>|null} [acceptedRealms] TokenChallengeRequest acceptedRealms
         * @property {Uint8Array} nonce TokenChallengeRequest nonce
         */

        /**
         * Constructs a new TokenChallengeRequest.
         * @memberof proto
         * @classdesc Represents a TokenChallengeRequest.
         * @implements ITokenChallengeRequest
         * @constructor
         * @param {proto.ITokenChallengeRequest=} [properties] Properties to set
         */
        function TokenChallengeRequest(properties) {
            this.acceptedRealms = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TokenChallengeRequest acceptedRealms.
         * @member {Array.<string>} acceptedRealms
         * @memberof proto.TokenChallengeRequest
         * @instance
         */
        TokenChallengeRequest.prototype.acceptedRealms = $util.emptyArray;

        /**
         * TokenChallengeRequest nonce.
         * @member {Uint8Array} nonce
         * @memberof proto.TokenChallengeRequest
         * @instance
         */
        TokenChallengeRequest.prototype.nonce = $util.newBuffer([]);

        /**
         * Creates a new TokenChallengeRequest instance using the specified properties.
         * @function create
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {proto.ITokenChallengeRequest=} [properties] Properties to set
         * @returns {proto.TokenChallengeRequest} TokenChallengeRequest instance
         */
        TokenChallengeRequest.create = function create(properties) {
            return new TokenChallengeRequest(properties);
        };

        /**
         * Encodes the specified TokenChallengeRequest message. Does not implicitly {@link proto.TokenChallengeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {proto.ITokenChallengeRequest} message TokenChallengeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenChallengeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.acceptedRealms != null && message.acceptedRealms.length)
                for (var i = 0; i < message.acceptedRealms.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.acceptedRealms[i]);
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
            return writer;
        };

        /**
         * Encodes the specified TokenChallengeRequest message, length delimited. Does not implicitly {@link proto.TokenChallengeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {proto.ITokenChallengeRequest} message TokenChallengeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenChallengeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenChallengeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TokenChallengeRequest} TokenChallengeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenChallengeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TokenChallengeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.acceptedRealms && message.acceptedRealms.length))
                            message.acceptedRealms = [];
                        message.acceptedRealms.push(reader.string());
                        break;
                    }
                case 2: {
                        message.nonce = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("nonce"))
                throw $util.ProtocolError("missing required 'nonce'", { instance: message });
            return message;
        };

        /**
         * Decodes a TokenChallengeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TokenChallengeRequest} TokenChallengeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenChallengeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenChallengeRequest message.
         * @function verify
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenChallengeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.acceptedRealms != null && message.hasOwnProperty("acceptedRealms")) {
                if (!Array.isArray(message.acceptedRealms))
                    return "acceptedRealms: array expected";
                for (var i = 0; i < message.acceptedRealms.length; ++i)
                    if (!$util.isString(message.acceptedRealms[i]))
                        return "acceptedRealms: string[] expected";
            }
            if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                return "nonce: buffer expected";
            return null;
        };

        /**
         * Creates a TokenChallengeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TokenChallengeRequest} TokenChallengeRequest
         */
        TokenChallengeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TokenChallengeRequest)
                return object;
            var message = new $root.proto.TokenChallengeRequest();
            if (object.acceptedRealms) {
                if (!Array.isArray(object.acceptedRealms))
                    throw TypeError(".proto.TokenChallengeRequest.acceptedRealms: array expected");
                message.acceptedRealms = [];
                for (var i = 0; i < object.acceptedRealms.length; ++i)
                    message.acceptedRealms[i] = String(object.acceptedRealms[i]);
            }
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length >= 0)
                    message.nonce = object.nonce;
            return message;
        };

        /**
         * Creates a plain object from a TokenChallengeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {proto.TokenChallengeRequest} message TokenChallengeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenChallengeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.acceptedRealms = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
            if (message.acceptedRealms && message.acceptedRealms.length) {
                object.acceptedRealms = [];
                for (var j = 0; j < message.acceptedRealms.length; ++j)
                    object.acceptedRealms[j] = message.acceptedRealms[j];
            }
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            return object;
        };

        /**
         * Converts this TokenChallengeRequest to JSON.
         * @function toJSON
         * @memberof proto.TokenChallengeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenChallengeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TokenChallengeRequest
         * @function getTypeUrl
         * @memberof proto.TokenChallengeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TokenChallengeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TokenChallengeRequest";
        };

        return TokenChallengeRequest;
    })();

    proto.TokenChallengeResponse = (function() {

        /**
         * Properties of a TokenChallengeResponse.
         * @memberof proto
         * @interface ITokenChallengeResponse
         * @property {string} realm TokenChallengeResponse realm
         * @property {string} tokenId TokenChallengeResponse tokenId
         * @property {Uint8Array} hashedSecret TokenChallengeResponse hashedSecret
         */

        /**
         * Constructs a new TokenChallengeResponse.
         * @memberof proto
         * @classdesc Represents a TokenChallengeResponse.
         * @implements ITokenChallengeResponse
         * @constructor
         * @param {proto.ITokenChallengeResponse=} [properties] Properties to set
         */
        function TokenChallengeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TokenChallengeResponse realm.
         * @member {string} realm
         * @memberof proto.TokenChallengeResponse
         * @instance
         */
        TokenChallengeResponse.prototype.realm = "";

        /**
         * TokenChallengeResponse tokenId.
         * @member {string} tokenId
         * @memberof proto.TokenChallengeResponse
         * @instance
         */
        TokenChallengeResponse.prototype.tokenId = "";

        /**
         * TokenChallengeResponse hashedSecret.
         * @member {Uint8Array} hashedSecret
         * @memberof proto.TokenChallengeResponse
         * @instance
         */
        TokenChallengeResponse.prototype.hashedSecret = $util.newBuffer([]);

        /**
         * Creates a new TokenChallengeResponse instance using the specified properties.
         * @function create
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {proto.ITokenChallengeResponse=} [properties] Properties to set
         * @returns {proto.TokenChallengeResponse} TokenChallengeResponse instance
         */
        TokenChallengeResponse.create = function create(properties) {
            return new TokenChallengeResponse(properties);
        };

        /**
         * Encodes the specified TokenChallengeResponse message. Does not implicitly {@link proto.TokenChallengeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {proto.ITokenChallengeResponse} message TokenChallengeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenChallengeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.realm);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.tokenId);
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.hashedSecret);
            return writer;
        };

        /**
         * Encodes the specified TokenChallengeResponse message, length delimited. Does not implicitly {@link proto.TokenChallengeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {proto.ITokenChallengeResponse} message TokenChallengeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenChallengeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenChallengeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TokenChallengeResponse} TokenChallengeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenChallengeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TokenChallengeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.realm = reader.string();
                        break;
                    }
                case 2: {
                        message.tokenId = reader.string();
                        break;
                    }
                case 3: {
                        message.hashedSecret = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("realm"))
                throw $util.ProtocolError("missing required 'realm'", { instance: message });
            if (!message.hasOwnProperty("tokenId"))
                throw $util.ProtocolError("missing required 'tokenId'", { instance: message });
            if (!message.hasOwnProperty("hashedSecret"))
                throw $util.ProtocolError("missing required 'hashedSecret'", { instance: message });
            return message;
        };

        /**
         * Decodes a TokenChallengeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TokenChallengeResponse} TokenChallengeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenChallengeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenChallengeResponse message.
         * @function verify
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenChallengeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.realm))
                return "realm: string expected";
            if (!$util.isString(message.tokenId))
                return "tokenId: string expected";
            if (!(message.hashedSecret && typeof message.hashedSecret.length === "number" || $util.isString(message.hashedSecret)))
                return "hashedSecret: buffer expected";
            return null;
        };

        /**
         * Creates a TokenChallengeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TokenChallengeResponse} TokenChallengeResponse
         */
        TokenChallengeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TokenChallengeResponse)
                return object;
            var message = new $root.proto.TokenChallengeResponse();
            if (object.realm != null)
                message.realm = String(object.realm);
            if (object.tokenId != null)
                message.tokenId = String(object.tokenId);
            if (object.hashedSecret != null)
                if (typeof object.hashedSecret === "string")
                    $util.base64.decode(object.hashedSecret, message.hashedSecret = $util.newBuffer($util.base64.length(object.hashedSecret)), 0);
                else if (object.hashedSecret.length >= 0)
                    message.hashedSecret = object.hashedSecret;
            return message;
        };

        /**
         * Creates a plain object from a TokenChallengeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {proto.TokenChallengeResponse} message TokenChallengeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenChallengeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.realm = "";
                object.tokenId = "";
                if (options.bytes === String)
                    object.hashedSecret = "";
                else {
                    object.hashedSecret = [];
                    if (options.bytes !== Array)
                        object.hashedSecret = $util.newBuffer(object.hashedSecret);
                }
            }
            if (message.realm != null && message.hasOwnProperty("realm"))
                object.realm = message.realm;
            if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                object.tokenId = message.tokenId;
            if (message.hashedSecret != null && message.hasOwnProperty("hashedSecret"))
                object.hashedSecret = options.bytes === String ? $util.base64.encode(message.hashedSecret, 0, message.hashedSecret.length) : options.bytes === Array ? Array.prototype.slice.call(message.hashedSecret) : message.hashedSecret;
            return object;
        };

        /**
         * Converts this TokenChallengeResponse to JSON.
         * @function toJSON
         * @memberof proto.TokenChallengeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenChallengeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TokenChallengeResponse
         * @function getTypeUrl
         * @memberof proto.TokenChallengeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TokenChallengeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TokenChallengeResponse";
        };

        return TokenChallengeResponse;
    })();

    proto.ReattachRequest = (function() {

        /**
         * Properties of a ReattachRequest.
         * @memberof proto
         * @interface IReattachRequest
         * @property {Uint8Array} nonce ReattachRequest nonce
         * @property {Uint8Array} hashedSecret ReattachRequest hashedSecret
         */

        /**
         * Constructs a new ReattachRequest.
         * @memberof proto
         * @classdesc Represents a ReattachRequest.
         * @implements IReattachRequest
         * @constructor
         * @param {proto.IReattachRequest=} [properties] Properties to set
         */
        function ReattachRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReattachRequest nonce.
         * @member {Uint8Array} nonce
         * @memberof proto.ReattachRequest
         * @instance
         */
        ReattachRequest.prototype.nonce = $util.newBuffer([]);

        /**
         * ReattachRequest hashedSecret.
         * @member {Uint8Array} hashedSecret
         * @memberof proto.ReattachRequest
         * @instance
         */
        ReattachRequest.prototype.hashedSecret = $util.newBuffer([]);

        /**
         * Creates a new ReattachRequest instance using the specified properties.
         * @function create
         * @memberof proto.ReattachRequest
         * @static
         * @param {proto.IReattachRequest=} [properties] Properties to set
         * @returns {proto.ReattachRequest} ReattachRequest instance
         */
        ReattachRequest.create = function create(properties) {
            return new ReattachRequest(properties);
        };

        /**
         * Encodes the specified ReattachRequest message. Does not implicitly {@link proto.ReattachRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ReattachRequest
         * @static
         * @param {proto.IReattachRequest} message ReattachRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReattachRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nonce);
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.hashedSecret);
            return writer;
        };

        /**
         * Encodes the specified ReattachRequest message, length delimited. Does not implicitly {@link proto.ReattachRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ReattachRequest
         * @static
         * @param {proto.IReattachRequest} message ReattachRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReattachRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReattachRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ReattachRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ReattachRequest} ReattachRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReattachRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ReattachRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nonce = reader.bytes();
                        break;
                    }
                case 2: {
                        message.hashedSecret = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("nonce"))
                throw $util.ProtocolError("missing required 'nonce'", { instance: message });
            if (!message.hasOwnProperty("hashedSecret"))
                throw $util.ProtocolError("missing required 'hashedSecret'", { instance: message });
            return message;
        };

        /**
         * Decodes a ReattachRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ReattachRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ReattachRequest} ReattachRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReattachRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReattachRequest message.
         * @function verify
         * @memberof proto.ReattachRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReattachRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                return "nonce: buffer expected";
            if (!(message.hashedSecret && typeof message.hashedSecret.length === "number" || $util.isString(message.hashedSecret)))
                return "hashedSecret: buffer expected";
            return null;
        };

        /**
         * Creates a ReattachRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ReattachRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ReattachRequest} ReattachRequest
         */
        ReattachRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ReattachRequest)
                return object;
            var message = new $root.proto.ReattachRequest();
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length >= 0)
                    message.nonce = object.nonce;
            if (object.hashedSecret != null)
                if (typeof object.hashedSecret === "string")
                    $util.base64.decode(object.hashedSecret, message.hashedSecret = $util.newBuffer($util.base64.length(object.hashedSecret)), 0);
                else if (object.hashedSecret.length >= 0)
                    message.hashedSecret = object.hashedSecret;
            return message;
        };

        /**
         * Creates a plain object from a ReattachRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ReattachRequest
         * @static
         * @param {proto.ReattachRequest} message ReattachRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReattachRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
                if (options.bytes === String)
                    object.hashedSecret = "";
                else {
                    object.hashedSecret = [];
                    if (options.bytes !== Array)
                        object.hashedSecret = $util.newBuffer(object.hashedSecret);
                }
            }
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            if (message.hashedSecret != null && message.hasOwnProperty("hashedSecret"))
                object.hashedSecret = options.bytes === String ? $util.base64.encode(message.hashedSecret, 0, message.hashedSecret.length) : options.bytes === Array ? Array.prototype.slice.call(message.hashedSecret) : message.hashedSecret;
            return object;
        };

        /**
         * Converts this ReattachRequest to JSON.
         * @function toJSON
         * @memberof proto.ReattachRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReattachRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReattachRequest
         * @function getTypeUrl
         * @memberof proto.ReattachRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReattachRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ReattachRequest";
        };

        return ReattachRequest;
    })();

    proto.DetachAPIv1Client = (function() {

        /**
         * Properties of a DetachAPIv1Client.
         * @memberof proto
         * @interface IDetachAPIv1Client
         * @property {proto.IDetachModuleSetupRequest|null} [setupRequest] DetachAPIv1Client setupRequest
         * @property {proto.IDetachRequest|null} [detachRequest] DetachAPIv1Client detachRequest
         */

        /**
         * Constructs a new DetachAPIv1Client.
         * @memberof proto
         * @classdesc Represents a DetachAPIv1Client.
         * @implements IDetachAPIv1Client
         * @constructor
         * @param {proto.IDetachAPIv1Client=} [properties] Properties to set
         */
        function DetachAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DetachAPIv1Client setupRequest.
         * @member {proto.IDetachModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.DetachAPIv1Client
         * @instance
         */
        DetachAPIv1Client.prototype.setupRequest = null;

        /**
         * DetachAPIv1Client detachRequest.
         * @member {proto.IDetachRequest|null|undefined} detachRequest
         * @memberof proto.DetachAPIv1Client
         * @instance
         */
        DetachAPIv1Client.prototype.detachRequest = null;

        /**
         * Creates a new DetachAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {proto.IDetachAPIv1Client=} [properties] Properties to set
         * @returns {proto.DetachAPIv1Client} DetachAPIv1Client instance
         */
        DetachAPIv1Client.create = function create(properties) {
            return new DetachAPIv1Client(properties);
        };

        /**
         * Encodes the specified DetachAPIv1Client message. Does not implicitly {@link proto.DetachAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {proto.IDetachAPIv1Client} message DetachAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.DetachModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.detachRequest != null && Object.hasOwnProperty.call(message, "detachRequest"))
                $root.proto.DetachRequest.encode(message.detachRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DetachAPIv1Client message, length delimited. Does not implicitly {@link proto.DetachAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {proto.IDetachAPIv1Client} message DetachAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DetachAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.DetachAPIv1Client} DetachAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.DetachAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.DetachModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.detachRequest = $root.proto.DetachRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DetachAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.DetachAPIv1Client} DetachAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DetachAPIv1Client message.
         * @function verify
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DetachAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.DetachModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.detachRequest != null && message.hasOwnProperty("detachRequest")) {
                var error = $root.proto.DetachRequest.verify(message.detachRequest);
                if (error)
                    return "detachRequest." + error;
            }
            return null;
        };

        /**
         * Creates a DetachAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.DetachAPIv1Client} DetachAPIv1Client
         */
        DetachAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.DetachAPIv1Client)
                return object;
            var message = new $root.proto.DetachAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.DetachAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.DetachModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.detachRequest != null) {
                if (typeof object.detachRequest !== "object")
                    throw TypeError(".proto.DetachAPIv1Client.detachRequest: object expected");
                message.detachRequest = $root.proto.DetachRequest.fromObject(object.detachRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a DetachAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {proto.DetachAPIv1Client} message DetachAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DetachAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.detachRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.DetachModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.detachRequest != null && message.hasOwnProperty("detachRequest"))
                object.detachRequest = $root.proto.DetachRequest.toObject(message.detachRequest, options);
            return object;
        };

        /**
         * Converts this DetachAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.DetachAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DetachAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DetachAPIv1Client
         * @function getTypeUrl
         * @memberof proto.DetachAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DetachAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.DetachAPIv1Client";
        };

        return DetachAPIv1Client;
    })();

    proto.DetachModuleSetupRequest = (function() {

        /**
         * Properties of a DetachModuleSetupRequest.
         * @memberof proto
         * @interface IDetachModuleSetupRequest
         */

        /**
         * Constructs a new DetachModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a DetachModuleSetupRequest.
         * @implements IDetachModuleSetupRequest
         * @constructor
         * @param {proto.IDetachModuleSetupRequest=} [properties] Properties to set
         */
        function DetachModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DetachModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {proto.IDetachModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.DetachModuleSetupRequest} DetachModuleSetupRequest instance
         */
        DetachModuleSetupRequest.create = function create(properties) {
            return new DetachModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified DetachModuleSetupRequest message. Does not implicitly {@link proto.DetachModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {proto.IDetachModuleSetupRequest} message DetachModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DetachModuleSetupRequest message, length delimited. Does not implicitly {@link proto.DetachModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {proto.IDetachModuleSetupRequest} message DetachModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DetachModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.DetachModuleSetupRequest} DetachModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.DetachModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DetachModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.DetachModuleSetupRequest} DetachModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DetachModuleSetupRequest message.
         * @function verify
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DetachModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DetachModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.DetachModuleSetupRequest} DetachModuleSetupRequest
         */
        DetachModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.DetachModuleSetupRequest)
                return object;
            return new $root.proto.DetachModuleSetupRequest();
        };

        /**
         * Creates a plain object from a DetachModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {proto.DetachModuleSetupRequest} message DetachModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DetachModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DetachModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.DetachModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DetachModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DetachModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.DetachModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DetachModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.DetachModuleSetupRequest";
        };

        return DetachModuleSetupRequest;
    })();

    proto.DetachRequest = (function() {

        /**
         * Properties of a DetachRequest.
         * @memberof proto
         * @interface IDetachRequest
         */

        /**
         * Constructs a new DetachRequest.
         * @memberof proto
         * @classdesc Represents a DetachRequest.
         * @implements IDetachRequest
         * @constructor
         * @param {proto.IDetachRequest=} [properties] Properties to set
         */
        function DetachRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DetachRequest instance using the specified properties.
         * @function create
         * @memberof proto.DetachRequest
         * @static
         * @param {proto.IDetachRequest=} [properties] Properties to set
         * @returns {proto.DetachRequest} DetachRequest instance
         */
        DetachRequest.create = function create(properties) {
            return new DetachRequest(properties);
        };

        /**
         * Encodes the specified DetachRequest message. Does not implicitly {@link proto.DetachRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.DetachRequest
         * @static
         * @param {proto.IDetachRequest} message DetachRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DetachRequest message, length delimited. Does not implicitly {@link proto.DetachRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.DetachRequest
         * @static
         * @param {proto.IDetachRequest} message DetachRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DetachRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.DetachRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.DetachRequest} DetachRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.DetachRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DetachRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.DetachRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.DetachRequest} DetachRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DetachRequest message.
         * @function verify
         * @memberof proto.DetachRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DetachRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DetachRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.DetachRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.DetachRequest} DetachRequest
         */
        DetachRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.DetachRequest)
                return object;
            return new $root.proto.DetachRequest();
        };

        /**
         * Creates a plain object from a DetachRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.DetachRequest
         * @static
         * @param {proto.DetachRequest} message DetachRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DetachRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DetachRequest to JSON.
         * @function toJSON
         * @memberof proto.DetachRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DetachRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DetachRequest
         * @function getTypeUrl
         * @memberof proto.DetachRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DetachRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.DetachRequest";
        };

        return DetachRequest;
    })();

    proto.DetachResponse = (function() {

        /**
         * Properties of a DetachResponse.
         * @memberof proto
         * @interface IDetachResponse
         * @property {Uint8Array} nonce DetachResponse nonce
         */

        /**
         * Constructs a new DetachResponse.
         * @memberof proto
         * @classdesc Represents a DetachResponse.
         * @implements IDetachResponse
         * @constructor
         * @param {proto.IDetachResponse=} [properties] Properties to set
         */
        function DetachResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DetachResponse nonce.
         * @member {Uint8Array} nonce
         * @memberof proto.DetachResponse
         * @instance
         */
        DetachResponse.prototype.nonce = $util.newBuffer([]);

        /**
         * Creates a new DetachResponse instance using the specified properties.
         * @function create
         * @memberof proto.DetachResponse
         * @static
         * @param {proto.IDetachResponse=} [properties] Properties to set
         * @returns {proto.DetachResponse} DetachResponse instance
         */
        DetachResponse.create = function create(properties) {
            return new DetachResponse(properties);
        };

        /**
         * Encodes the specified DetachResponse message. Does not implicitly {@link proto.DetachResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.DetachResponse
         * @static
         * @param {proto.IDetachResponse} message DetachResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nonce);
            return writer;
        };

        /**
         * Encodes the specified DetachResponse message, length delimited. Does not implicitly {@link proto.DetachResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.DetachResponse
         * @static
         * @param {proto.IDetachResponse} message DetachResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DetachResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DetachResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.DetachResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.DetachResponse} DetachResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.DetachResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nonce = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("nonce"))
                throw $util.ProtocolError("missing required 'nonce'", { instance: message });
            return message;
        };

        /**
         * Decodes a DetachResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.DetachResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.DetachResponse} DetachResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DetachResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DetachResponse message.
         * @function verify
         * @memberof proto.DetachResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DetachResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                return "nonce: buffer expected";
            return null;
        };

        /**
         * Creates a DetachResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.DetachResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.DetachResponse} DetachResponse
         */
        DetachResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.DetachResponse)
                return object;
            var message = new $root.proto.DetachResponse();
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length >= 0)
                    message.nonce = object.nonce;
            return message;
        };

        /**
         * Creates a plain object from a DetachResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.DetachResponse
         * @static
         * @param {proto.DetachResponse} message DetachResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DetachResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            return object;
        };

        /**
         * Converts this DetachResponse to JSON.
         * @function toJSON
         * @memberof proto.DetachResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DetachResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DetachResponse
         * @function getTypeUrl
         * @memberof proto.DetachResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DetachResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.DetachResponse";
        };

        return DetachResponse;
    })();

    proto.SessionAPIv1Client = (function() {

        /**
         * Properties of a SessionAPIv1Client.
         * @memberof proto
         * @interface ISessionAPIv1Client
         * @property {proto.ISessionModuleSetupRequest|null} [setupRequest] SessionAPIv1Client setupRequest
         * @property {proto.ISessionStopRequest|null} [stopRequest] SessionAPIv1Client stopRequest
         */

        /**
         * Constructs a new SessionAPIv1Client.
         * @memberof proto
         * @classdesc Represents a SessionAPIv1Client.
         * @implements ISessionAPIv1Client
         * @constructor
         * @param {proto.ISessionAPIv1Client=} [properties] Properties to set
         */
        function SessionAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionAPIv1Client setupRequest.
         * @member {proto.ISessionModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.SessionAPIv1Client
         * @instance
         */
        SessionAPIv1Client.prototype.setupRequest = null;

        /**
         * SessionAPIv1Client stopRequest.
         * @member {proto.ISessionStopRequest|null|undefined} stopRequest
         * @memberof proto.SessionAPIv1Client
         * @instance
         */
        SessionAPIv1Client.prototype.stopRequest = null;

        /**
         * Creates a new SessionAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {proto.ISessionAPIv1Client=} [properties] Properties to set
         * @returns {proto.SessionAPIv1Client} SessionAPIv1Client instance
         */
        SessionAPIv1Client.create = function create(properties) {
            return new SessionAPIv1Client(properties);
        };

        /**
         * Encodes the specified SessionAPIv1Client message. Does not implicitly {@link proto.SessionAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {proto.ISessionAPIv1Client} message SessionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.SessionModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.stopRequest != null && Object.hasOwnProperty.call(message, "stopRequest"))
                $root.proto.SessionStopRequest.encode(message.stopRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SessionAPIv1Client message, length delimited. Does not implicitly {@link proto.SessionAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {proto.ISessionAPIv1Client} message SessionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionAPIv1Client} SessionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.SessionModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.stopRequest = $root.proto.SessionStopRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionAPIv1Client} SessionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionAPIv1Client message.
         * @function verify
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.SessionModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.stopRequest != null && message.hasOwnProperty("stopRequest")) {
                var error = $root.proto.SessionStopRequest.verify(message.stopRequest);
                if (error)
                    return "stopRequest." + error;
            }
            return null;
        };

        /**
         * Creates a SessionAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionAPIv1Client} SessionAPIv1Client
         */
        SessionAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionAPIv1Client)
                return object;
            var message = new $root.proto.SessionAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.SessionAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.SessionModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.stopRequest != null) {
                if (typeof object.stopRequest !== "object")
                    throw TypeError(".proto.SessionAPIv1Client.stopRequest: object expected");
                message.stopRequest = $root.proto.SessionStopRequest.fromObject(object.stopRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a SessionAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {proto.SessionAPIv1Client} message SessionAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.stopRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.SessionModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.stopRequest != null && message.hasOwnProperty("stopRequest"))
                object.stopRequest = $root.proto.SessionStopRequest.toObject(message.stopRequest, options);
            return object;
        };

        /**
         * Converts this SessionAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.SessionAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionAPIv1Client
         * @function getTypeUrl
         * @memberof proto.SessionAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionAPIv1Client";
        };

        return SessionAPIv1Client;
    })();

    proto.SessionModuleSetupRequest = (function() {

        /**
         * Properties of a SessionModuleSetupRequest.
         * @memberof proto
         * @interface ISessionModuleSetupRequest
         * @property {number} maxSessions SessionModuleSetupRequest maxSessions
         * @property {number} maxCalls SessionModuleSetupRequest maxCalls
         * @property {Array.<number>|null} [acceptedCodecs] SessionModuleSetupRequest acceptedCodecs
         * @property {boolean|null} [supportsMonitoring] SessionModuleSetupRequest supportsMonitoring
         * @property {boolean|null} [supportsTwilioFullDuplex] SessionModuleSetupRequest supportsTwilioFullDuplex
         */

        /**
         * Constructs a new SessionModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a SessionModuleSetupRequest.
         * @implements ISessionModuleSetupRequest
         * @constructor
         * @param {proto.ISessionModuleSetupRequest=} [properties] Properties to set
         */
        function SessionModuleSetupRequest(properties) {
            this.acceptedCodecs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionModuleSetupRequest maxSessions.
         * @member {number} maxSessions
         * @memberof proto.SessionModuleSetupRequest
         * @instance
         */
        SessionModuleSetupRequest.prototype.maxSessions = 0;

        /**
         * SessionModuleSetupRequest maxCalls.
         * @member {number} maxCalls
         * @memberof proto.SessionModuleSetupRequest
         * @instance
         */
        SessionModuleSetupRequest.prototype.maxCalls = 0;

        /**
         * SessionModuleSetupRequest acceptedCodecs.
         * @member {Array.<number>} acceptedCodecs
         * @memberof proto.SessionModuleSetupRequest
         * @instance
         */
        SessionModuleSetupRequest.prototype.acceptedCodecs = $util.emptyArray;

        /**
         * SessionModuleSetupRequest supportsMonitoring.
         * @member {boolean} supportsMonitoring
         * @memberof proto.SessionModuleSetupRequest
         * @instance
         */
        SessionModuleSetupRequest.prototype.supportsMonitoring = false;

        /**
         * SessionModuleSetupRequest supportsTwilioFullDuplex.
         * @member {boolean} supportsTwilioFullDuplex
         * @memberof proto.SessionModuleSetupRequest
         * @instance
         */
        SessionModuleSetupRequest.prototype.supportsTwilioFullDuplex = false;

        /**
         * Creates a new SessionModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {proto.ISessionModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.SessionModuleSetupRequest} SessionModuleSetupRequest instance
         */
        SessionModuleSetupRequest.create = function create(properties) {
            return new SessionModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified SessionModuleSetupRequest message. Does not implicitly {@link proto.SessionModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {proto.ISessionModuleSetupRequest} message SessionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxSessions);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxCalls);
            if (message.acceptedCodecs != null && message.acceptedCodecs.length)
                for (var i = 0; i < message.acceptedCodecs.length; ++i)
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.acceptedCodecs[i]);
            if (message.supportsMonitoring != null && Object.hasOwnProperty.call(message, "supportsMonitoring"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.supportsMonitoring);
            if (message.supportsTwilioFullDuplex != null && Object.hasOwnProperty.call(message, "supportsTwilioFullDuplex"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.supportsTwilioFullDuplex);
            return writer;
        };

        /**
         * Encodes the specified SessionModuleSetupRequest message, length delimited. Does not implicitly {@link proto.SessionModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {proto.ISessionModuleSetupRequest} message SessionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionModuleSetupRequest} SessionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxSessions = reader.int32();
                        break;
                    }
                case 2: {
                        message.maxCalls = reader.int32();
                        break;
                    }
                case 3: {
                        if (!(message.acceptedCodecs && message.acceptedCodecs.length))
                            message.acceptedCodecs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.acceptedCodecs.push(reader.int32());
                        } else
                            message.acceptedCodecs.push(reader.int32());
                        break;
                    }
                case 4: {
                        message.supportsMonitoring = reader.bool();
                        break;
                    }
                case 5: {
                        message.supportsTwilioFullDuplex = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("maxSessions"))
                throw $util.ProtocolError("missing required 'maxSessions'", { instance: message });
            if (!message.hasOwnProperty("maxCalls"))
                throw $util.ProtocolError("missing required 'maxCalls'", { instance: message });
            return message;
        };

        /**
         * Decodes a SessionModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionModuleSetupRequest} SessionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionModuleSetupRequest message.
         * @function verify
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.maxSessions))
                return "maxSessions: integer expected";
            if (!$util.isInteger(message.maxCalls))
                return "maxCalls: integer expected";
            if (message.acceptedCodecs != null && message.hasOwnProperty("acceptedCodecs")) {
                if (!Array.isArray(message.acceptedCodecs))
                    return "acceptedCodecs: array expected";
                for (var i = 0; i < message.acceptedCodecs.length; ++i)
                    if (!$util.isInteger(message.acceptedCodecs[i]))
                        return "acceptedCodecs: integer[] expected";
            }
            if (message.supportsMonitoring != null && message.hasOwnProperty("supportsMonitoring"))
                if (typeof message.supportsMonitoring !== "boolean")
                    return "supportsMonitoring: boolean expected";
            if (message.supportsTwilioFullDuplex != null && message.hasOwnProperty("supportsTwilioFullDuplex"))
                if (typeof message.supportsTwilioFullDuplex !== "boolean")
                    return "supportsTwilioFullDuplex: boolean expected";
            return null;
        };

        /**
         * Creates a SessionModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionModuleSetupRequest} SessionModuleSetupRequest
         */
        SessionModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionModuleSetupRequest)
                return object;
            var message = new $root.proto.SessionModuleSetupRequest();
            if (object.maxSessions != null)
                message.maxSessions = object.maxSessions | 0;
            if (object.maxCalls != null)
                message.maxCalls = object.maxCalls | 0;
            if (object.acceptedCodecs) {
                if (!Array.isArray(object.acceptedCodecs))
                    throw TypeError(".proto.SessionModuleSetupRequest.acceptedCodecs: array expected");
                message.acceptedCodecs = [];
                for (var i = 0; i < object.acceptedCodecs.length; ++i)
                    message.acceptedCodecs[i] = object.acceptedCodecs[i] | 0;
            }
            if (object.supportsMonitoring != null)
                message.supportsMonitoring = Boolean(object.supportsMonitoring);
            if (object.supportsTwilioFullDuplex != null)
                message.supportsTwilioFullDuplex = Boolean(object.supportsTwilioFullDuplex);
            return message;
        };

        /**
         * Creates a plain object from a SessionModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {proto.SessionModuleSetupRequest} message SessionModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionModuleSetupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.acceptedCodecs = [];
            if (options.defaults) {
                object.maxSessions = 0;
                object.maxCalls = 0;
                object.supportsMonitoring = false;
                object.supportsTwilioFullDuplex = false;
            }
            if (message.maxSessions != null && message.hasOwnProperty("maxSessions"))
                object.maxSessions = message.maxSessions;
            if (message.maxCalls != null && message.hasOwnProperty("maxCalls"))
                object.maxCalls = message.maxCalls;
            if (message.acceptedCodecs && message.acceptedCodecs.length) {
                object.acceptedCodecs = [];
                for (var j = 0; j < message.acceptedCodecs.length; ++j)
                    object.acceptedCodecs[j] = message.acceptedCodecs[j];
            }
            if (message.supportsMonitoring != null && message.hasOwnProperty("supportsMonitoring"))
                object.supportsMonitoring = message.supportsMonitoring;
            if (message.supportsTwilioFullDuplex != null && message.hasOwnProperty("supportsTwilioFullDuplex"))
                object.supportsTwilioFullDuplex = message.supportsTwilioFullDuplex;
            return object;
        };

        /**
         * Converts this SessionModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.SessionModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.SessionModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionModuleSetupRequest";
        };

        return SessionModuleSetupRequest;
    })();

    proto.SessionModuleSetupResponse = (function() {

        /**
         * Properties of a SessionModuleSetupResponse.
         * @memberof proto
         * @interface ISessionModuleSetupResponse
         * @property {Array.<proto.IMuteGroup>|null} [muteGroups] SessionModuleSetupResponse muteGroups
         */

        /**
         * Constructs a new SessionModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a SessionModuleSetupResponse.
         * @implements ISessionModuleSetupResponse
         * @constructor
         * @param {proto.ISessionModuleSetupResponse=} [properties] Properties to set
         */
        function SessionModuleSetupResponse(properties) {
            this.muteGroups = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionModuleSetupResponse muteGroups.
         * @member {Array.<proto.IMuteGroup>} muteGroups
         * @memberof proto.SessionModuleSetupResponse
         * @instance
         */
        SessionModuleSetupResponse.prototype.muteGroups = $util.emptyArray;

        /**
         * Creates a new SessionModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {proto.ISessionModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.SessionModuleSetupResponse} SessionModuleSetupResponse instance
         */
        SessionModuleSetupResponse.create = function create(properties) {
            return new SessionModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified SessionModuleSetupResponse message. Does not implicitly {@link proto.SessionModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {proto.ISessionModuleSetupResponse} message SessionModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.muteGroups != null && message.muteGroups.length)
                for (var i = 0; i < message.muteGroups.length; ++i)
                    $root.proto.MuteGroup.encode(message.muteGroups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SessionModuleSetupResponse message, length delimited. Does not implicitly {@link proto.SessionModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {proto.ISessionModuleSetupResponse} message SessionModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionModuleSetupResponse} SessionModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.muteGroups && message.muteGroups.length))
                            message.muteGroups = [];
                        message.muteGroups.push($root.proto.MuteGroup.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionModuleSetupResponse} SessionModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionModuleSetupResponse message.
         * @function verify
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.muteGroups != null && message.hasOwnProperty("muteGroups")) {
                if (!Array.isArray(message.muteGroups))
                    return "muteGroups: array expected";
                for (var i = 0; i < message.muteGroups.length; ++i) {
                    var error = $root.proto.MuteGroup.verify(message.muteGroups[i]);
                    if (error)
                        return "muteGroups." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SessionModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionModuleSetupResponse} SessionModuleSetupResponse
         */
        SessionModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionModuleSetupResponse)
                return object;
            var message = new $root.proto.SessionModuleSetupResponse();
            if (object.muteGroups) {
                if (!Array.isArray(object.muteGroups))
                    throw TypeError(".proto.SessionModuleSetupResponse.muteGroups: array expected");
                message.muteGroups = [];
                for (var i = 0; i < object.muteGroups.length; ++i) {
                    if (typeof object.muteGroups[i] !== "object")
                        throw TypeError(".proto.SessionModuleSetupResponse.muteGroups: object expected");
                    message.muteGroups[i] = $root.proto.MuteGroup.fromObject(object.muteGroups[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SessionModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {proto.SessionModuleSetupResponse} message SessionModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.muteGroups = [];
            if (message.muteGroups && message.muteGroups.length) {
                object.muteGroups = [];
                for (var j = 0; j < message.muteGroups.length; ++j)
                    object.muteGroups[j] = $root.proto.MuteGroup.toObject(message.muteGroups[j], options);
            }
            return object;
        };

        /**
         * Converts this SessionModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.SessionModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.SessionModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionModuleSetupResponse";
        };

        return SessionModuleSetupResponse;
    })();

    proto.MuteGroup = (function() {

        /**
         * Properties of a MuteGroup.
         * @memberof proto
         * @interface IMuteGroup
         * @property {string} id MuteGroup id
         * @property {string} name MuteGroup name
         */

        /**
         * Constructs a new MuteGroup.
         * @memberof proto
         * @classdesc Represents a MuteGroup.
         * @implements IMuteGroup
         * @constructor
         * @param {proto.IMuteGroup=} [properties] Properties to set
         */
        function MuteGroup(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuteGroup id.
         * @member {string} id
         * @memberof proto.MuteGroup
         * @instance
         */
        MuteGroup.prototype.id = "";

        /**
         * MuteGroup name.
         * @member {string} name
         * @memberof proto.MuteGroup
         * @instance
         */
        MuteGroup.prototype.name = "";

        /**
         * Creates a new MuteGroup instance using the specified properties.
         * @function create
         * @memberof proto.MuteGroup
         * @static
         * @param {proto.IMuteGroup=} [properties] Properties to set
         * @returns {proto.MuteGroup} MuteGroup instance
         */
        MuteGroup.create = function create(properties) {
            return new MuteGroup(properties);
        };

        /**
         * Encodes the specified MuteGroup message. Does not implicitly {@link proto.MuteGroup.verify|verify} messages.
         * @function encode
         * @memberof proto.MuteGroup
         * @static
         * @param {proto.IMuteGroup} message MuteGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuteGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified MuteGroup message, length delimited. Does not implicitly {@link proto.MuteGroup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.MuteGroup
         * @static
         * @param {proto.IMuteGroup} message MuteGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuteGroup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuteGroup message from the specified reader or buffer.
         * @function decode
         * @memberof proto.MuteGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.MuteGroup} MuteGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuteGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.MuteGroup();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes a MuteGroup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.MuteGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.MuteGroup} MuteGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuteGroup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuteGroup message.
         * @function verify
         * @memberof proto.MuteGroup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuteGroup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.id))
                return "id: string expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            return null;
        };

        /**
         * Creates a MuteGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.MuteGroup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.MuteGroup} MuteGroup
         */
        MuteGroup.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.MuteGroup)
                return object;
            var message = new $root.proto.MuteGroup();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a MuteGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.MuteGroup
         * @static
         * @param {proto.MuteGroup} message MuteGroup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuteGroup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this MuteGroup to JSON.
         * @function toJSON
         * @memberof proto.MuteGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuteGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MuteGroup
         * @function getTypeUrl
         * @memberof proto.MuteGroup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MuteGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.MuteGroup";
        };

        return MuteGroup;
    })();

    proto.SessionStopRequest = (function() {

        /**
         * Properties of a SessionStopRequest.
         * @memberof proto
         * @interface ISessionStopRequest
         * @property {number|Long} sessionId SessionStopRequest sessionId
         */

        /**
         * Constructs a new SessionStopRequest.
         * @memberof proto
         * @classdesc Represents a SessionStopRequest.
         * @implements ISessionStopRequest
         * @constructor
         * @param {proto.ISessionStopRequest=} [properties] Properties to set
         */
        function SessionStopRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionStopRequest sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.SessionStopRequest
         * @instance
         */
        SessionStopRequest.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SessionStopRequest instance using the specified properties.
         * @function create
         * @memberof proto.SessionStopRequest
         * @static
         * @param {proto.ISessionStopRequest=} [properties] Properties to set
         * @returns {proto.SessionStopRequest} SessionStopRequest instance
         */
        SessionStopRequest.create = function create(properties) {
            return new SessionStopRequest(properties);
        };

        /**
         * Encodes the specified SessionStopRequest message. Does not implicitly {@link proto.SessionStopRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionStopRequest
         * @static
         * @param {proto.ISessionStopRequest} message SessionStopRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionStopRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified SessionStopRequest message, length delimited. Does not implicitly {@link proto.SessionStopRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionStopRequest
         * @static
         * @param {proto.ISessionStopRequest} message SessionStopRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionStopRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionStopRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionStopRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionStopRequest} SessionStopRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionStopRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionStopRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a SessionStopRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionStopRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionStopRequest} SessionStopRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionStopRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionStopRequest message.
         * @function verify
         * @memberof proto.SessionStopRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionStopRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a SessionStopRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionStopRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionStopRequest} SessionStopRequest
         */
        SessionStopRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionStopRequest)
                return object;
            var message = new $root.proto.SessionStopRequest();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SessionStopRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionStopRequest
         * @static
         * @param {proto.SessionStopRequest} message SessionStopRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionStopRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            return object;
        };

        /**
         * Converts this SessionStopRequest to JSON.
         * @function toJSON
         * @memberof proto.SessionStopRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionStopRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionStopRequest
         * @function getTypeUrl
         * @memberof proto.SessionStopRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionStopRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionStopRequest";
        };

        return SessionStopRequest;
    })();

    proto.SessionAPIv1Server = (function() {

        /**
         * Properties of a SessionAPIv1Server.
         * @memberof proto
         * @interface ISessionAPIv1Server
         * @property {proto.ISessionStarted|null} [sessionStarted] SessionAPIv1Server sessionStarted
         * @property {proto.ISessionStopped|null} [sessionStopped] SessionAPIv1Server sessionStopped
         * @property {proto.ISessionAcceptedCodecUpdated|null} [sessionAcceptedCodecUpdated] SessionAPIv1Server sessionAcceptedCodecUpdated
         * @property {proto.ISessionMonitoringStatus|null} [sessionMonitoringStatus] SessionAPIv1Server sessionMonitoringStatus
         */

        /**
         * Constructs a new SessionAPIv1Server.
         * @memberof proto
         * @classdesc Represents a SessionAPIv1Server.
         * @implements ISessionAPIv1Server
         * @constructor
         * @param {proto.ISessionAPIv1Server=} [properties] Properties to set
         */
        function SessionAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionAPIv1Server sessionStarted.
         * @member {proto.ISessionStarted|null|undefined} sessionStarted
         * @memberof proto.SessionAPIv1Server
         * @instance
         */
        SessionAPIv1Server.prototype.sessionStarted = null;

        /**
         * SessionAPIv1Server sessionStopped.
         * @member {proto.ISessionStopped|null|undefined} sessionStopped
         * @memberof proto.SessionAPIv1Server
         * @instance
         */
        SessionAPIv1Server.prototype.sessionStopped = null;

        /**
         * SessionAPIv1Server sessionAcceptedCodecUpdated.
         * @member {proto.ISessionAcceptedCodecUpdated|null|undefined} sessionAcceptedCodecUpdated
         * @memberof proto.SessionAPIv1Server
         * @instance
         */
        SessionAPIv1Server.prototype.sessionAcceptedCodecUpdated = null;

        /**
         * SessionAPIv1Server sessionMonitoringStatus.
         * @member {proto.ISessionMonitoringStatus|null|undefined} sessionMonitoringStatus
         * @memberof proto.SessionAPIv1Server
         * @instance
         */
        SessionAPIv1Server.prototype.sessionMonitoringStatus = null;

        /**
         * Creates a new SessionAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {proto.ISessionAPIv1Server=} [properties] Properties to set
         * @returns {proto.SessionAPIv1Server} SessionAPIv1Server instance
         */
        SessionAPIv1Server.create = function create(properties) {
            return new SessionAPIv1Server(properties);
        };

        /**
         * Encodes the specified SessionAPIv1Server message. Does not implicitly {@link proto.SessionAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {proto.ISessionAPIv1Server} message SessionAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionStarted != null && Object.hasOwnProperty.call(message, "sessionStarted"))
                $root.proto.SessionStarted.encode(message.sessionStarted, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sessionStopped != null && Object.hasOwnProperty.call(message, "sessionStopped"))
                $root.proto.SessionStopped.encode(message.sessionStopped, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.sessionAcceptedCodecUpdated != null && Object.hasOwnProperty.call(message, "sessionAcceptedCodecUpdated"))
                $root.proto.SessionAcceptedCodecUpdated.encode(message.sessionAcceptedCodecUpdated, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.sessionMonitoringStatus != null && Object.hasOwnProperty.call(message, "sessionMonitoringStatus"))
                $root.proto.SessionMonitoringStatus.encode(message.sessionMonitoringStatus, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SessionAPIv1Server message, length delimited. Does not implicitly {@link proto.SessionAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {proto.ISessionAPIv1Server} message SessionAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionAPIv1Server} SessionAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionStarted = $root.proto.SessionStarted.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.sessionStopped = $root.proto.SessionStopped.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.sessionAcceptedCodecUpdated = $root.proto.SessionAcceptedCodecUpdated.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.sessionMonitoringStatus = $root.proto.SessionMonitoringStatus.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionAPIv1Server} SessionAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionAPIv1Server message.
         * @function verify
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionStarted != null && message.hasOwnProperty("sessionStarted")) {
                var error = $root.proto.SessionStarted.verify(message.sessionStarted);
                if (error)
                    return "sessionStarted." + error;
            }
            if (message.sessionStopped != null && message.hasOwnProperty("sessionStopped")) {
                var error = $root.proto.SessionStopped.verify(message.sessionStopped);
                if (error)
                    return "sessionStopped." + error;
            }
            if (message.sessionAcceptedCodecUpdated != null && message.hasOwnProperty("sessionAcceptedCodecUpdated")) {
                var error = $root.proto.SessionAcceptedCodecUpdated.verify(message.sessionAcceptedCodecUpdated);
                if (error)
                    return "sessionAcceptedCodecUpdated." + error;
            }
            if (message.sessionMonitoringStatus != null && message.hasOwnProperty("sessionMonitoringStatus")) {
                var error = $root.proto.SessionMonitoringStatus.verify(message.sessionMonitoringStatus);
                if (error)
                    return "sessionMonitoringStatus." + error;
            }
            return null;
        };

        /**
         * Creates a SessionAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionAPIv1Server} SessionAPIv1Server
         */
        SessionAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionAPIv1Server)
                return object;
            var message = new $root.proto.SessionAPIv1Server();
            if (object.sessionStarted != null) {
                if (typeof object.sessionStarted !== "object")
                    throw TypeError(".proto.SessionAPIv1Server.sessionStarted: object expected");
                message.sessionStarted = $root.proto.SessionStarted.fromObject(object.sessionStarted);
            }
            if (object.sessionStopped != null) {
                if (typeof object.sessionStopped !== "object")
                    throw TypeError(".proto.SessionAPIv1Server.sessionStopped: object expected");
                message.sessionStopped = $root.proto.SessionStopped.fromObject(object.sessionStopped);
            }
            if (object.sessionAcceptedCodecUpdated != null) {
                if (typeof object.sessionAcceptedCodecUpdated !== "object")
                    throw TypeError(".proto.SessionAPIv1Server.sessionAcceptedCodecUpdated: object expected");
                message.sessionAcceptedCodecUpdated = $root.proto.SessionAcceptedCodecUpdated.fromObject(object.sessionAcceptedCodecUpdated);
            }
            if (object.sessionMonitoringStatus != null) {
                if (typeof object.sessionMonitoringStatus !== "object")
                    throw TypeError(".proto.SessionAPIv1Server.sessionMonitoringStatus: object expected");
                message.sessionMonitoringStatus = $root.proto.SessionMonitoringStatus.fromObject(object.sessionMonitoringStatus);
            }
            return message;
        };

        /**
         * Creates a plain object from a SessionAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {proto.SessionAPIv1Server} message SessionAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.sessionStarted = null;
                object.sessionStopped = null;
                object.sessionAcceptedCodecUpdated = null;
                object.sessionMonitoringStatus = null;
            }
            if (message.sessionStarted != null && message.hasOwnProperty("sessionStarted"))
                object.sessionStarted = $root.proto.SessionStarted.toObject(message.sessionStarted, options);
            if (message.sessionStopped != null && message.hasOwnProperty("sessionStopped"))
                object.sessionStopped = $root.proto.SessionStopped.toObject(message.sessionStopped, options);
            if (message.sessionAcceptedCodecUpdated != null && message.hasOwnProperty("sessionAcceptedCodecUpdated"))
                object.sessionAcceptedCodecUpdated = $root.proto.SessionAcceptedCodecUpdated.toObject(message.sessionAcceptedCodecUpdated, options);
            if (message.sessionMonitoringStatus != null && message.hasOwnProperty("sessionMonitoringStatus"))
                object.sessionMonitoringStatus = $root.proto.SessionMonitoringStatus.toObject(message.sessionMonitoringStatus, options);
            return object;
        };

        /**
         * Converts this SessionAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.SessionAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionAPIv1Server
         * @function getTypeUrl
         * @memberof proto.SessionAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionAPIv1Server";
        };

        return SessionAPIv1Server;
    })();

    /**
     * SessionType enum.
     * @name proto.SessionType
     * @enum {number}
     * @property {number} GROUP=1 GROUP value
     * @property {number} SCAN=2 SCAN value
     * @property {number} CALL=3 CALL value
     * @property {number} AUTOJOIN=4 AUTOJOIN value
     * @property {number} MONITORING_SENDER=5 MONITORING_SENDER value
     * @property {number} MONITORING_LISTENER=6 MONITORING_LISTENER value
     */
    proto.SessionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "GROUP"] = 1;
        values[valuesById[2] = "SCAN"] = 2;
        values[valuesById[3] = "CALL"] = 3;
        values[valuesById[4] = "AUTOJOIN"] = 4;
        values[valuesById[5] = "MONITORING_SENDER"] = 5;
        values[valuesById[6] = "MONITORING_LISTENER"] = 6;
        return values;
    })();

    /**
     * SessionMediaType enum.
     * @name proto.SessionMediaType
     * @enum {number}
     * @property {number} SESSION_MEDIA_TYPE_GROUPTALK_PTT=0 SESSION_MEDIA_TYPE_GROUPTALK_PTT value
     * @property {number} SESSION_MEDIA_TYPE_TWILIO_FULL_DUPLEX=1 SESSION_MEDIA_TYPE_TWILIO_FULL_DUPLEX value
     * @property {number} SESSION_MEDIA_TYPE_RESERVED_1=2 SESSION_MEDIA_TYPE_RESERVED_1 value
     * @property {number} SESSION_MEDIA_TYPE_RESERVED_2=3 SESSION_MEDIA_TYPE_RESERVED_2 value
     * @property {number} SESSION_MEDIA_TYPE_RESERVED_3=4 SESSION_MEDIA_TYPE_RESERVED_3 value
     * @property {number} SESSION_MEDIA_TYPE_RESERVED_4=5 SESSION_MEDIA_TYPE_RESERVED_4 value
     */
    proto.SessionMediaType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SESSION_MEDIA_TYPE_GROUPTALK_PTT"] = 0;
        values[valuesById[1] = "SESSION_MEDIA_TYPE_TWILIO_FULL_DUPLEX"] = 1;
        values[valuesById[2] = "SESSION_MEDIA_TYPE_RESERVED_1"] = 2;
        values[valuesById[3] = "SESSION_MEDIA_TYPE_RESERVED_2"] = 3;
        values[valuesById[4] = "SESSION_MEDIA_TYPE_RESERVED_3"] = 4;
        values[valuesById[5] = "SESSION_MEDIA_TYPE_RESERVED_4"] = 5;
        return values;
    })();

    proto.SessionStarted = (function() {

        /**
         * Properties of a SessionStarted.
         * @memberof proto
         * @interface ISessionStarted
         * @property {number|Long} sessionId SessionStarted sessionId
         * @property {string} name SessionStarted name
         * @property {boolean} stoppable SessionStarted stoppable
         * @property {proto.SessionType} type SessionStarted type
         * @property {string|null} [channelId] SessionStarted channelId
         * @property {string|null} [callRef] SessionStarted callRef
         * @property {Array.<number>|null} [acceptedCodecs] SessionStarted acceptedCodecs
         * @property {boolean|null} [txDenied] SessionStarted txDenied
         * @property {boolean|null} [allowTones] SessionStarted allowTones
         * @property {string|null} [channelUuid] SessionStarted channelUuid
         * @property {proto.SessionMediaType|null} [mediaType] SessionStarted mediaType
         * @property {string|null} [peerUserUuid] SessionStarted peerUserUuid
         * @property {string|null} [fullDuplexUuid] SessionStarted fullDuplexUuid
         */

        /**
         * Constructs a new SessionStarted.
         * @memberof proto
         * @classdesc Represents a SessionStarted.
         * @implements ISessionStarted
         * @constructor
         * @param {proto.ISessionStarted=} [properties] Properties to set
         */
        function SessionStarted(properties) {
            this.acceptedCodecs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionStarted sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SessionStarted name.
         * @member {string} name
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.name = "";

        /**
         * SessionStarted stoppable.
         * @member {boolean} stoppable
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.stoppable = false;

        /**
         * SessionStarted type.
         * @member {proto.SessionType} type
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.type = 1;

        /**
         * SessionStarted channelId.
         * @member {string} channelId
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.channelId = "";

        /**
         * SessionStarted callRef.
         * @member {string} callRef
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.callRef = "";

        /**
         * SessionStarted acceptedCodecs.
         * @member {Array.<number>} acceptedCodecs
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.acceptedCodecs = $util.emptyArray;

        /**
         * SessionStarted txDenied.
         * @member {boolean} txDenied
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.txDenied = false;

        /**
         * SessionStarted allowTones.
         * @member {boolean} allowTones
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.allowTones = false;

        /**
         * SessionStarted channelUuid.
         * @member {string} channelUuid
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.channelUuid = "";

        /**
         * SessionStarted mediaType.
         * @member {proto.SessionMediaType} mediaType
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.mediaType = 0;

        /**
         * SessionStarted peerUserUuid.
         * @member {string} peerUserUuid
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.peerUserUuid = "";

        /**
         * SessionStarted fullDuplexUuid.
         * @member {string} fullDuplexUuid
         * @memberof proto.SessionStarted
         * @instance
         */
        SessionStarted.prototype.fullDuplexUuid = "";

        /**
         * Creates a new SessionStarted instance using the specified properties.
         * @function create
         * @memberof proto.SessionStarted
         * @static
         * @param {proto.ISessionStarted=} [properties] Properties to set
         * @returns {proto.SessionStarted} SessionStarted instance
         */
        SessionStarted.create = function create(properties) {
            return new SessionStarted(properties);
        };

        /**
         * Encodes the specified SessionStarted message. Does not implicitly {@link proto.SessionStarted.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionStarted
         * @static
         * @param {proto.ISessionStarted} message SessionStarted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionStarted.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sessionId);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.stoppable);
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
            if (message.channelId != null && Object.hasOwnProperty.call(message, "channelId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.channelId);
            if (message.callRef != null && Object.hasOwnProperty.call(message, "callRef"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.callRef);
            if (message.acceptedCodecs != null && message.acceptedCodecs.length)
                for (var i = 0; i < message.acceptedCodecs.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.acceptedCodecs[i]);
            if (message.txDenied != null && Object.hasOwnProperty.call(message, "txDenied"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.txDenied);
            if (message.allowTones != null && Object.hasOwnProperty.call(message, "allowTones"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.allowTones);
            if (message.channelUuid != null && Object.hasOwnProperty.call(message, "channelUuid"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.channelUuid);
            if (message.mediaType != null && Object.hasOwnProperty.call(message, "mediaType"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.mediaType);
            if (message.peerUserUuid != null && Object.hasOwnProperty.call(message, "peerUserUuid"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.peerUserUuid);
            if (message.fullDuplexUuid != null && Object.hasOwnProperty.call(message, "fullDuplexUuid"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.fullDuplexUuid);
            return writer;
        };

        /**
         * Encodes the specified SessionStarted message, length delimited. Does not implicitly {@link proto.SessionStarted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionStarted
         * @static
         * @param {proto.ISessionStarted} message SessionStarted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionStarted.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionStarted message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionStarted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionStarted} SessionStarted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionStarted.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionStarted();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.stoppable = reader.bool();
                        break;
                    }
                case 4: {
                        message.type = reader.int32();
                        break;
                    }
                case 5: {
                        message.channelId = reader.string();
                        break;
                    }
                case 6: {
                        message.callRef = reader.string();
                        break;
                    }
                case 7: {
                        if (!(message.acceptedCodecs && message.acceptedCodecs.length))
                            message.acceptedCodecs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.acceptedCodecs.push(reader.int32());
                        } else
                            message.acceptedCodecs.push(reader.int32());
                        break;
                    }
                case 8: {
                        message.txDenied = reader.bool();
                        break;
                    }
                case 9: {
                        message.allowTones = reader.bool();
                        break;
                    }
                case 10: {
                        message.channelUuid = reader.string();
                        break;
                    }
                case 11: {
                        message.mediaType = reader.int32();
                        break;
                    }
                case 12: {
                        message.peerUserUuid = reader.string();
                        break;
                    }
                case 13: {
                        message.fullDuplexUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("stoppable"))
                throw $util.ProtocolError("missing required 'stoppable'", { instance: message });
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            return message;
        };

        /**
         * Decodes a SessionStarted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionStarted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionStarted} SessionStarted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionStarted.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionStarted message.
         * @function verify
         * @memberof proto.SessionStarted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionStarted.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (typeof message.stoppable !== "boolean")
                return "stoppable: boolean expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (!$util.isString(message.channelId))
                    return "channelId: string expected";
            if (message.callRef != null && message.hasOwnProperty("callRef"))
                if (!$util.isString(message.callRef))
                    return "callRef: string expected";
            if (message.acceptedCodecs != null && message.hasOwnProperty("acceptedCodecs")) {
                if (!Array.isArray(message.acceptedCodecs))
                    return "acceptedCodecs: array expected";
                for (var i = 0; i < message.acceptedCodecs.length; ++i)
                    if (!$util.isInteger(message.acceptedCodecs[i]))
                        return "acceptedCodecs: integer[] expected";
            }
            if (message.txDenied != null && message.hasOwnProperty("txDenied"))
                if (typeof message.txDenied !== "boolean")
                    return "txDenied: boolean expected";
            if (message.allowTones != null && message.hasOwnProperty("allowTones"))
                if (typeof message.allowTones !== "boolean")
                    return "allowTones: boolean expected";
            if (message.channelUuid != null && message.hasOwnProperty("channelUuid"))
                if (!$util.isString(message.channelUuid))
                    return "channelUuid: string expected";
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                switch (message.mediaType) {
                default:
                    return "mediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.peerUserUuid != null && message.hasOwnProperty("peerUserUuid"))
                if (!$util.isString(message.peerUserUuid))
                    return "peerUserUuid: string expected";
            if (message.fullDuplexUuid != null && message.hasOwnProperty("fullDuplexUuid"))
                if (!$util.isString(message.fullDuplexUuid))
                    return "fullDuplexUuid: string expected";
            return null;
        };

        /**
         * Creates a SessionStarted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionStarted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionStarted} SessionStarted
         */
        SessionStarted.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionStarted)
                return object;
            var message = new $root.proto.SessionStarted();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.name != null)
                message.name = String(object.name);
            if (object.stoppable != null)
                message.stoppable = Boolean(object.stoppable);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "GROUP":
            case 1:
                message.type = 1;
                break;
            case "SCAN":
            case 2:
                message.type = 2;
                break;
            case "CALL":
            case 3:
                message.type = 3;
                break;
            case "AUTOJOIN":
            case 4:
                message.type = 4;
                break;
            case "MONITORING_SENDER":
            case 5:
                message.type = 5;
                break;
            case "MONITORING_LISTENER":
            case 6:
                message.type = 6;
                break;
            }
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            if (object.callRef != null)
                message.callRef = String(object.callRef);
            if (object.acceptedCodecs) {
                if (!Array.isArray(object.acceptedCodecs))
                    throw TypeError(".proto.SessionStarted.acceptedCodecs: array expected");
                message.acceptedCodecs = [];
                for (var i = 0; i < object.acceptedCodecs.length; ++i)
                    message.acceptedCodecs[i] = object.acceptedCodecs[i] | 0;
            }
            if (object.txDenied != null)
                message.txDenied = Boolean(object.txDenied);
            if (object.allowTones != null)
                message.allowTones = Boolean(object.allowTones);
            if (object.channelUuid != null)
                message.channelUuid = String(object.channelUuid);
            switch (object.mediaType) {
            default:
                if (typeof object.mediaType === "number") {
                    message.mediaType = object.mediaType;
                    break;
                }
                break;
            case "SESSION_MEDIA_TYPE_GROUPTALK_PTT":
            case 0:
                message.mediaType = 0;
                break;
            case "SESSION_MEDIA_TYPE_TWILIO_FULL_DUPLEX":
            case 1:
                message.mediaType = 1;
                break;
            case "SESSION_MEDIA_TYPE_RESERVED_1":
            case 2:
                message.mediaType = 2;
                break;
            case "SESSION_MEDIA_TYPE_RESERVED_2":
            case 3:
                message.mediaType = 3;
                break;
            case "SESSION_MEDIA_TYPE_RESERVED_3":
            case 4:
                message.mediaType = 4;
                break;
            case "SESSION_MEDIA_TYPE_RESERVED_4":
            case 5:
                message.mediaType = 5;
                break;
            }
            if (object.peerUserUuid != null)
                message.peerUserUuid = String(object.peerUserUuid);
            if (object.fullDuplexUuid != null)
                message.fullDuplexUuid = String(object.fullDuplexUuid);
            return message;
        };

        /**
         * Creates a plain object from a SessionStarted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionStarted
         * @static
         * @param {proto.SessionStarted} message SessionStarted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionStarted.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.acceptedCodecs = [];
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                object.name = "";
                object.stoppable = false;
                object.type = options.enums === String ? "GROUP" : 1;
                object.channelId = "";
                object.callRef = "";
                object.txDenied = false;
                object.allowTones = false;
                object.channelUuid = "";
                object.mediaType = options.enums === String ? "SESSION_MEDIA_TYPE_GROUPTALK_PTT" : 0;
                object.peerUserUuid = "";
                object.fullDuplexUuid = "";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.stoppable != null && message.hasOwnProperty("stoppable"))
                object.stoppable = message.stoppable;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.SessionType[message.type] === undefined ? message.type : $root.proto.SessionType[message.type] : message.type;
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            if (message.callRef != null && message.hasOwnProperty("callRef"))
                object.callRef = message.callRef;
            if (message.acceptedCodecs && message.acceptedCodecs.length) {
                object.acceptedCodecs = [];
                for (var j = 0; j < message.acceptedCodecs.length; ++j)
                    object.acceptedCodecs[j] = message.acceptedCodecs[j];
            }
            if (message.txDenied != null && message.hasOwnProperty("txDenied"))
                object.txDenied = message.txDenied;
            if (message.allowTones != null && message.hasOwnProperty("allowTones"))
                object.allowTones = message.allowTones;
            if (message.channelUuid != null && message.hasOwnProperty("channelUuid"))
                object.channelUuid = message.channelUuid;
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                object.mediaType = options.enums === String ? $root.proto.SessionMediaType[message.mediaType] === undefined ? message.mediaType : $root.proto.SessionMediaType[message.mediaType] : message.mediaType;
            if (message.peerUserUuid != null && message.hasOwnProperty("peerUserUuid"))
                object.peerUserUuid = message.peerUserUuid;
            if (message.fullDuplexUuid != null && message.hasOwnProperty("fullDuplexUuid"))
                object.fullDuplexUuid = message.fullDuplexUuid;
            return object;
        };

        /**
         * Converts this SessionStarted to JSON.
         * @function toJSON
         * @memberof proto.SessionStarted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionStarted.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionStarted
         * @function getTypeUrl
         * @memberof proto.SessionStarted
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionStarted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionStarted";
        };

        return SessionStarted;
    })();

    proto.SessionStopped = (function() {

        /**
         * Properties of a SessionStopped.
         * @memberof proto
         * @interface ISessionStopped
         * @property {number|Long} sessionId SessionStopped sessionId
         * @property {boolean|null} [last] SessionStopped last
         */

        /**
         * Constructs a new SessionStopped.
         * @memberof proto
         * @classdesc Represents a SessionStopped.
         * @implements ISessionStopped
         * @constructor
         * @param {proto.ISessionStopped=} [properties] Properties to set
         */
        function SessionStopped(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionStopped sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.SessionStopped
         * @instance
         */
        SessionStopped.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SessionStopped last.
         * @member {boolean} last
         * @memberof proto.SessionStopped
         * @instance
         */
        SessionStopped.prototype.last = false;

        /**
         * Creates a new SessionStopped instance using the specified properties.
         * @function create
         * @memberof proto.SessionStopped
         * @static
         * @param {proto.ISessionStopped=} [properties] Properties to set
         * @returns {proto.SessionStopped} SessionStopped instance
         */
        SessionStopped.create = function create(properties) {
            return new SessionStopped(properties);
        };

        /**
         * Encodes the specified SessionStopped message. Does not implicitly {@link proto.SessionStopped.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionStopped
         * @static
         * @param {proto.ISessionStopped} message SessionStopped message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionStopped.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sessionId);
            if (message.last != null && Object.hasOwnProperty.call(message, "last"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.last);
            return writer;
        };

        /**
         * Encodes the specified SessionStopped message, length delimited. Does not implicitly {@link proto.SessionStopped.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionStopped
         * @static
         * @param {proto.ISessionStopped} message SessionStopped message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionStopped.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionStopped message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionStopped
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionStopped} SessionStopped
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionStopped.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionStopped();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.last = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a SessionStopped message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionStopped
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionStopped} SessionStopped
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionStopped.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionStopped message.
         * @function verify
         * @memberof proto.SessionStopped
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionStopped.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (message.last != null && message.hasOwnProperty("last"))
                if (typeof message.last !== "boolean")
                    return "last: boolean expected";
            return null;
        };

        /**
         * Creates a SessionStopped message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionStopped
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionStopped} SessionStopped
         */
        SessionStopped.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionStopped)
                return object;
            var message = new $root.proto.SessionStopped();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.last != null)
                message.last = Boolean(object.last);
            return message;
        };

        /**
         * Creates a plain object from a SessionStopped message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionStopped
         * @static
         * @param {proto.SessionStopped} message SessionStopped
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionStopped.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                object.last = false;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.last != null && message.hasOwnProperty("last"))
                object.last = message.last;
            return object;
        };

        /**
         * Converts this SessionStopped to JSON.
         * @function toJSON
         * @memberof proto.SessionStopped
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionStopped.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionStopped
         * @function getTypeUrl
         * @memberof proto.SessionStopped
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionStopped.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionStopped";
        };

        return SessionStopped;
    })();

    proto.SessionAcceptedCodecUpdated = (function() {

        /**
         * Properties of a SessionAcceptedCodecUpdated.
         * @memberof proto
         * @interface ISessionAcceptedCodecUpdated
         * @property {number|Long} sessionId SessionAcceptedCodecUpdated sessionId
         * @property {Array.<number>|null} [acceptedCodecs] SessionAcceptedCodecUpdated acceptedCodecs
         */

        /**
         * Constructs a new SessionAcceptedCodecUpdated.
         * @memberof proto
         * @classdesc Represents a SessionAcceptedCodecUpdated.
         * @implements ISessionAcceptedCodecUpdated
         * @constructor
         * @param {proto.ISessionAcceptedCodecUpdated=} [properties] Properties to set
         */
        function SessionAcceptedCodecUpdated(properties) {
            this.acceptedCodecs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionAcceptedCodecUpdated sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.SessionAcceptedCodecUpdated
         * @instance
         */
        SessionAcceptedCodecUpdated.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SessionAcceptedCodecUpdated acceptedCodecs.
         * @member {Array.<number>} acceptedCodecs
         * @memberof proto.SessionAcceptedCodecUpdated
         * @instance
         */
        SessionAcceptedCodecUpdated.prototype.acceptedCodecs = $util.emptyArray;

        /**
         * Creates a new SessionAcceptedCodecUpdated instance using the specified properties.
         * @function create
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {proto.ISessionAcceptedCodecUpdated=} [properties] Properties to set
         * @returns {proto.SessionAcceptedCodecUpdated} SessionAcceptedCodecUpdated instance
         */
        SessionAcceptedCodecUpdated.create = function create(properties) {
            return new SessionAcceptedCodecUpdated(properties);
        };

        /**
         * Encodes the specified SessionAcceptedCodecUpdated message. Does not implicitly {@link proto.SessionAcceptedCodecUpdated.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {proto.ISessionAcceptedCodecUpdated} message SessionAcceptedCodecUpdated message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionAcceptedCodecUpdated.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sessionId);
            if (message.acceptedCodecs != null && message.acceptedCodecs.length)
                for (var i = 0; i < message.acceptedCodecs.length; ++i)
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.acceptedCodecs[i]);
            return writer;
        };

        /**
         * Encodes the specified SessionAcceptedCodecUpdated message, length delimited. Does not implicitly {@link proto.SessionAcceptedCodecUpdated.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {proto.ISessionAcceptedCodecUpdated} message SessionAcceptedCodecUpdated message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionAcceptedCodecUpdated.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionAcceptedCodecUpdated message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionAcceptedCodecUpdated} SessionAcceptedCodecUpdated
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionAcceptedCodecUpdated.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionAcceptedCodecUpdated();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.acceptedCodecs && message.acceptedCodecs.length))
                            message.acceptedCodecs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.acceptedCodecs.push(reader.int32());
                        } else
                            message.acceptedCodecs.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a SessionAcceptedCodecUpdated message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionAcceptedCodecUpdated} SessionAcceptedCodecUpdated
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionAcceptedCodecUpdated.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionAcceptedCodecUpdated message.
         * @function verify
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionAcceptedCodecUpdated.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (message.acceptedCodecs != null && message.hasOwnProperty("acceptedCodecs")) {
                if (!Array.isArray(message.acceptedCodecs))
                    return "acceptedCodecs: array expected";
                for (var i = 0; i < message.acceptedCodecs.length; ++i)
                    if (!$util.isInteger(message.acceptedCodecs[i]))
                        return "acceptedCodecs: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a SessionAcceptedCodecUpdated message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionAcceptedCodecUpdated} SessionAcceptedCodecUpdated
         */
        SessionAcceptedCodecUpdated.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionAcceptedCodecUpdated)
                return object;
            var message = new $root.proto.SessionAcceptedCodecUpdated();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.acceptedCodecs) {
                if (!Array.isArray(object.acceptedCodecs))
                    throw TypeError(".proto.SessionAcceptedCodecUpdated.acceptedCodecs: array expected");
                message.acceptedCodecs = [];
                for (var i = 0; i < object.acceptedCodecs.length; ++i)
                    message.acceptedCodecs[i] = object.acceptedCodecs[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a SessionAcceptedCodecUpdated message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {proto.SessionAcceptedCodecUpdated} message SessionAcceptedCodecUpdated
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionAcceptedCodecUpdated.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.acceptedCodecs = [];
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.acceptedCodecs && message.acceptedCodecs.length) {
                object.acceptedCodecs = [];
                for (var j = 0; j < message.acceptedCodecs.length; ++j)
                    object.acceptedCodecs[j] = message.acceptedCodecs[j];
            }
            return object;
        };

        /**
         * Converts this SessionAcceptedCodecUpdated to JSON.
         * @function toJSON
         * @memberof proto.SessionAcceptedCodecUpdated
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionAcceptedCodecUpdated.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionAcceptedCodecUpdated
         * @function getTypeUrl
         * @memberof proto.SessionAcceptedCodecUpdated
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionAcceptedCodecUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionAcceptedCodecUpdated";
        };

        return SessionAcceptedCodecUpdated;
    })();

    proto.SessionMonitoringStatus = (function() {

        /**
         * Properties of a SessionMonitoringStatus.
         * @memberof proto
         * @interface ISessionMonitoringStatus
         * @property {number|Long} sessionId SessionMonitoringStatus sessionId
         * @property {boolean} monitored SessionMonitoringStatus monitored
         */

        /**
         * Constructs a new SessionMonitoringStatus.
         * @memberof proto
         * @classdesc Represents a SessionMonitoringStatus.
         * @implements ISessionMonitoringStatus
         * @constructor
         * @param {proto.ISessionMonitoringStatus=} [properties] Properties to set
         */
        function SessionMonitoringStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionMonitoringStatus sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.SessionMonitoringStatus
         * @instance
         */
        SessionMonitoringStatus.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SessionMonitoringStatus monitored.
         * @member {boolean} monitored
         * @memberof proto.SessionMonitoringStatus
         * @instance
         */
        SessionMonitoringStatus.prototype.monitored = false;

        /**
         * Creates a new SessionMonitoringStatus instance using the specified properties.
         * @function create
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {proto.ISessionMonitoringStatus=} [properties] Properties to set
         * @returns {proto.SessionMonitoringStatus} SessionMonitoringStatus instance
         */
        SessionMonitoringStatus.create = function create(properties) {
            return new SessionMonitoringStatus(properties);
        };

        /**
         * Encodes the specified SessionMonitoringStatus message. Does not implicitly {@link proto.SessionMonitoringStatus.verify|verify} messages.
         * @function encode
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {proto.ISessionMonitoringStatus} message SessionMonitoringStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionMonitoringStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sessionId);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.monitored);
            return writer;
        };

        /**
         * Encodes the specified SessionMonitoringStatus message, length delimited. Does not implicitly {@link proto.SessionMonitoringStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {proto.ISessionMonitoringStatus} message SessionMonitoringStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionMonitoringStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionMonitoringStatus message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SessionMonitoringStatus} SessionMonitoringStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionMonitoringStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SessionMonitoringStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.monitored = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            if (!message.hasOwnProperty("monitored"))
                throw $util.ProtocolError("missing required 'monitored'", { instance: message });
            return message;
        };

        /**
         * Decodes a SessionMonitoringStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SessionMonitoringStatus} SessionMonitoringStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionMonitoringStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionMonitoringStatus message.
         * @function verify
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionMonitoringStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (typeof message.monitored !== "boolean")
                return "monitored: boolean expected";
            return null;
        };

        /**
         * Creates a SessionMonitoringStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SessionMonitoringStatus} SessionMonitoringStatus
         */
        SessionMonitoringStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SessionMonitoringStatus)
                return object;
            var message = new $root.proto.SessionMonitoringStatus();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.monitored != null)
                message.monitored = Boolean(object.monitored);
            return message;
        };

        /**
         * Creates a plain object from a SessionMonitoringStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {proto.SessionMonitoringStatus} message SessionMonitoringStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionMonitoringStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                object.monitored = false;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.monitored != null && message.hasOwnProperty("monitored"))
                object.monitored = message.monitored;
            return object;
        };

        /**
         * Converts this SessionMonitoringStatus to JSON.
         * @function toJSON
         * @memberof proto.SessionMonitoringStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionMonitoringStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SessionMonitoringStatus
         * @function getTypeUrl
         * @memberof proto.SessionMonitoringStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SessionMonitoringStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SessionMonitoringStatus";
        };

        return SessionMonitoringStatus;
    })();

    proto.ChannelAPIv1Client = (function() {

        /**
         * Properties of a ChannelAPIv1Client.
         * @memberof proto
         * @interface IChannelAPIv1Client
         * @property {proto.IChannelModuleSetupRequest|null} [setupRequest] ChannelAPIv1Client setupRequest
         * @property {proto.IChannelSubscribeRequest|null} [subscribeRequest] ChannelAPIv1Client subscribeRequest
         * @property {proto.IChannelUnsubscribeRequest|null} [unsubscribeRequest] ChannelAPIv1Client unsubscribeRequest
         * @property {proto.IChannelJoinRequest|null} [joinRequest] ChannelAPIv1Client joinRequest
         * @property {proto.IChannelLeaveRequest|null} [leaveRequest] ChannelAPIv1Client leaveRequest
         * @property {proto.IChannelStartScanRequest|null} [startScanRequest] ChannelAPIv1Client startScanRequest
         * @property {proto.IChannelStopScanRequest|null} [stopScanRequest] ChannelAPIv1Client stopScanRequest
         * @property {proto.IChannelStartAutojoinRequest|null} [startAutojoinRequest] ChannelAPIv1Client startAutojoinRequest
         * @property {proto.IChannelStopAutojoinRequest|null} [stopAutojoinRequest] ChannelAPIv1Client stopAutojoinRequest
         * @property {proto.IChannelQuickSelectRequest|null} [quickSelectRequest] ChannelAPIv1Client quickSelectRequest
         * @property {proto.IChannelQuickSelectStepRequest|null} [quickSelectStepRequest] ChannelAPIv1Client quickSelectStepRequest
         * @property {proto.IChannelLeaveAllRequest|null} [leaveAllRequest] ChannelAPIv1Client leaveAllRequest
         */

        /**
         * Constructs a new ChannelAPIv1Client.
         * @memberof proto
         * @classdesc Represents a ChannelAPIv1Client.
         * @implements IChannelAPIv1Client
         * @constructor
         * @param {proto.IChannelAPIv1Client=} [properties] Properties to set
         */
        function ChannelAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelAPIv1Client setupRequest.
         * @member {proto.IChannelModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.setupRequest = null;

        /**
         * ChannelAPIv1Client subscribeRequest.
         * @member {proto.IChannelSubscribeRequest|null|undefined} subscribeRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.subscribeRequest = null;

        /**
         * ChannelAPIv1Client unsubscribeRequest.
         * @member {proto.IChannelUnsubscribeRequest|null|undefined} unsubscribeRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.unsubscribeRequest = null;

        /**
         * ChannelAPIv1Client joinRequest.
         * @member {proto.IChannelJoinRequest|null|undefined} joinRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.joinRequest = null;

        /**
         * ChannelAPIv1Client leaveRequest.
         * @member {proto.IChannelLeaveRequest|null|undefined} leaveRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.leaveRequest = null;

        /**
         * ChannelAPIv1Client startScanRequest.
         * @member {proto.IChannelStartScanRequest|null|undefined} startScanRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.startScanRequest = null;

        /**
         * ChannelAPIv1Client stopScanRequest.
         * @member {proto.IChannelStopScanRequest|null|undefined} stopScanRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.stopScanRequest = null;

        /**
         * ChannelAPIv1Client startAutojoinRequest.
         * @member {proto.IChannelStartAutojoinRequest|null|undefined} startAutojoinRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.startAutojoinRequest = null;

        /**
         * ChannelAPIv1Client stopAutojoinRequest.
         * @member {proto.IChannelStopAutojoinRequest|null|undefined} stopAutojoinRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.stopAutojoinRequest = null;

        /**
         * ChannelAPIv1Client quickSelectRequest.
         * @member {proto.IChannelQuickSelectRequest|null|undefined} quickSelectRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.quickSelectRequest = null;

        /**
         * ChannelAPIv1Client quickSelectStepRequest.
         * @member {proto.IChannelQuickSelectStepRequest|null|undefined} quickSelectStepRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.quickSelectStepRequest = null;

        /**
         * ChannelAPIv1Client leaveAllRequest.
         * @member {proto.IChannelLeaveAllRequest|null|undefined} leaveAllRequest
         * @memberof proto.ChannelAPIv1Client
         * @instance
         */
        ChannelAPIv1Client.prototype.leaveAllRequest = null;

        /**
         * Creates a new ChannelAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {proto.IChannelAPIv1Client=} [properties] Properties to set
         * @returns {proto.ChannelAPIv1Client} ChannelAPIv1Client instance
         */
        ChannelAPIv1Client.create = function create(properties) {
            return new ChannelAPIv1Client(properties);
        };

        /**
         * Encodes the specified ChannelAPIv1Client message. Does not implicitly {@link proto.ChannelAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {proto.IChannelAPIv1Client} message ChannelAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.ChannelModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.subscribeRequest != null && Object.hasOwnProperty.call(message, "subscribeRequest"))
                $root.proto.ChannelSubscribeRequest.encode(message.subscribeRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.unsubscribeRequest != null && Object.hasOwnProperty.call(message, "unsubscribeRequest"))
                $root.proto.ChannelUnsubscribeRequest.encode(message.unsubscribeRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.joinRequest != null && Object.hasOwnProperty.call(message, "joinRequest"))
                $root.proto.ChannelJoinRequest.encode(message.joinRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.leaveRequest != null && Object.hasOwnProperty.call(message, "leaveRequest"))
                $root.proto.ChannelLeaveRequest.encode(message.leaveRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.startScanRequest != null && Object.hasOwnProperty.call(message, "startScanRequest"))
                $root.proto.ChannelStartScanRequest.encode(message.startScanRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.stopScanRequest != null && Object.hasOwnProperty.call(message, "stopScanRequest"))
                $root.proto.ChannelStopScanRequest.encode(message.stopScanRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.startAutojoinRequest != null && Object.hasOwnProperty.call(message, "startAutojoinRequest"))
                $root.proto.ChannelStartAutojoinRequest.encode(message.startAutojoinRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.stopAutojoinRequest != null && Object.hasOwnProperty.call(message, "stopAutojoinRequest"))
                $root.proto.ChannelStopAutojoinRequest.encode(message.stopAutojoinRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.quickSelectRequest != null && Object.hasOwnProperty.call(message, "quickSelectRequest"))
                $root.proto.ChannelQuickSelectRequest.encode(message.quickSelectRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.quickSelectStepRequest != null && Object.hasOwnProperty.call(message, "quickSelectStepRequest"))
                $root.proto.ChannelQuickSelectStepRequest.encode(message.quickSelectStepRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.leaveAllRequest != null && Object.hasOwnProperty.call(message, "leaveAllRequest"))
                $root.proto.ChannelLeaveAllRequest.encode(message.leaveAllRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelAPIv1Client message, length delimited. Does not implicitly {@link proto.ChannelAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {proto.IChannelAPIv1Client} message ChannelAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelAPIv1Client} ChannelAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.ChannelModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.subscribeRequest = $root.proto.ChannelSubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.unsubscribeRequest = $root.proto.ChannelUnsubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.joinRequest = $root.proto.ChannelJoinRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.leaveRequest = $root.proto.ChannelLeaveRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.startScanRequest = $root.proto.ChannelStartScanRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.stopScanRequest = $root.proto.ChannelStopScanRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.startAutojoinRequest = $root.proto.ChannelStartAutojoinRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.stopAutojoinRequest = $root.proto.ChannelStopAutojoinRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.quickSelectRequest = $root.proto.ChannelQuickSelectRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.quickSelectStepRequest = $root.proto.ChannelQuickSelectStepRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.leaveAllRequest = $root.proto.ChannelLeaveAllRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelAPIv1Client} ChannelAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelAPIv1Client message.
         * @function verify
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.ChannelModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest")) {
                var error = $root.proto.ChannelSubscribeRequest.verify(message.subscribeRequest);
                if (error)
                    return "subscribeRequest." + error;
            }
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest")) {
                var error = $root.proto.ChannelUnsubscribeRequest.verify(message.unsubscribeRequest);
                if (error)
                    return "unsubscribeRequest." + error;
            }
            if (message.joinRequest != null && message.hasOwnProperty("joinRequest")) {
                var error = $root.proto.ChannelJoinRequest.verify(message.joinRequest);
                if (error)
                    return "joinRequest." + error;
            }
            if (message.leaveRequest != null && message.hasOwnProperty("leaveRequest")) {
                var error = $root.proto.ChannelLeaveRequest.verify(message.leaveRequest);
                if (error)
                    return "leaveRequest." + error;
            }
            if (message.startScanRequest != null && message.hasOwnProperty("startScanRequest")) {
                var error = $root.proto.ChannelStartScanRequest.verify(message.startScanRequest);
                if (error)
                    return "startScanRequest." + error;
            }
            if (message.stopScanRequest != null && message.hasOwnProperty("stopScanRequest")) {
                var error = $root.proto.ChannelStopScanRequest.verify(message.stopScanRequest);
                if (error)
                    return "stopScanRequest." + error;
            }
            if (message.startAutojoinRequest != null && message.hasOwnProperty("startAutojoinRequest")) {
                var error = $root.proto.ChannelStartAutojoinRequest.verify(message.startAutojoinRequest);
                if (error)
                    return "startAutojoinRequest." + error;
            }
            if (message.stopAutojoinRequest != null && message.hasOwnProperty("stopAutojoinRequest")) {
                var error = $root.proto.ChannelStopAutojoinRequest.verify(message.stopAutojoinRequest);
                if (error)
                    return "stopAutojoinRequest." + error;
            }
            if (message.quickSelectRequest != null && message.hasOwnProperty("quickSelectRequest")) {
                var error = $root.proto.ChannelQuickSelectRequest.verify(message.quickSelectRequest);
                if (error)
                    return "quickSelectRequest." + error;
            }
            if (message.quickSelectStepRequest != null && message.hasOwnProperty("quickSelectStepRequest")) {
                var error = $root.proto.ChannelQuickSelectStepRequest.verify(message.quickSelectStepRequest);
                if (error)
                    return "quickSelectStepRequest." + error;
            }
            if (message.leaveAllRequest != null && message.hasOwnProperty("leaveAllRequest")) {
                var error = $root.proto.ChannelLeaveAllRequest.verify(message.leaveAllRequest);
                if (error)
                    return "leaveAllRequest." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelAPIv1Client} ChannelAPIv1Client
         */
        ChannelAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelAPIv1Client)
                return object;
            var message = new $root.proto.ChannelAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.ChannelModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.subscribeRequest != null) {
                if (typeof object.subscribeRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.subscribeRequest: object expected");
                message.subscribeRequest = $root.proto.ChannelSubscribeRequest.fromObject(object.subscribeRequest);
            }
            if (object.unsubscribeRequest != null) {
                if (typeof object.unsubscribeRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.unsubscribeRequest: object expected");
                message.unsubscribeRequest = $root.proto.ChannelUnsubscribeRequest.fromObject(object.unsubscribeRequest);
            }
            if (object.joinRequest != null) {
                if (typeof object.joinRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.joinRequest: object expected");
                message.joinRequest = $root.proto.ChannelJoinRequest.fromObject(object.joinRequest);
            }
            if (object.leaveRequest != null) {
                if (typeof object.leaveRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.leaveRequest: object expected");
                message.leaveRequest = $root.proto.ChannelLeaveRequest.fromObject(object.leaveRequest);
            }
            if (object.startScanRequest != null) {
                if (typeof object.startScanRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.startScanRequest: object expected");
                message.startScanRequest = $root.proto.ChannelStartScanRequest.fromObject(object.startScanRequest);
            }
            if (object.stopScanRequest != null) {
                if (typeof object.stopScanRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.stopScanRequest: object expected");
                message.stopScanRequest = $root.proto.ChannelStopScanRequest.fromObject(object.stopScanRequest);
            }
            if (object.startAutojoinRequest != null) {
                if (typeof object.startAutojoinRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.startAutojoinRequest: object expected");
                message.startAutojoinRequest = $root.proto.ChannelStartAutojoinRequest.fromObject(object.startAutojoinRequest);
            }
            if (object.stopAutojoinRequest != null) {
                if (typeof object.stopAutojoinRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.stopAutojoinRequest: object expected");
                message.stopAutojoinRequest = $root.proto.ChannelStopAutojoinRequest.fromObject(object.stopAutojoinRequest);
            }
            if (object.quickSelectRequest != null) {
                if (typeof object.quickSelectRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.quickSelectRequest: object expected");
                message.quickSelectRequest = $root.proto.ChannelQuickSelectRequest.fromObject(object.quickSelectRequest);
            }
            if (object.quickSelectStepRequest != null) {
                if (typeof object.quickSelectStepRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.quickSelectStepRequest: object expected");
                message.quickSelectStepRequest = $root.proto.ChannelQuickSelectStepRequest.fromObject(object.quickSelectStepRequest);
            }
            if (object.leaveAllRequest != null) {
                if (typeof object.leaveAllRequest !== "object")
                    throw TypeError(".proto.ChannelAPIv1Client.leaveAllRequest: object expected");
                message.leaveAllRequest = $root.proto.ChannelLeaveAllRequest.fromObject(object.leaveAllRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {proto.ChannelAPIv1Client} message ChannelAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.subscribeRequest = null;
                object.unsubscribeRequest = null;
                object.joinRequest = null;
                object.leaveRequest = null;
                object.startScanRequest = null;
                object.stopScanRequest = null;
                object.startAutojoinRequest = null;
                object.stopAutojoinRequest = null;
                object.quickSelectRequest = null;
                object.quickSelectStepRequest = null;
                object.leaveAllRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.ChannelModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest"))
                object.subscribeRequest = $root.proto.ChannelSubscribeRequest.toObject(message.subscribeRequest, options);
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest"))
                object.unsubscribeRequest = $root.proto.ChannelUnsubscribeRequest.toObject(message.unsubscribeRequest, options);
            if (message.joinRequest != null && message.hasOwnProperty("joinRequest"))
                object.joinRequest = $root.proto.ChannelJoinRequest.toObject(message.joinRequest, options);
            if (message.leaveRequest != null && message.hasOwnProperty("leaveRequest"))
                object.leaveRequest = $root.proto.ChannelLeaveRequest.toObject(message.leaveRequest, options);
            if (message.startScanRequest != null && message.hasOwnProperty("startScanRequest"))
                object.startScanRequest = $root.proto.ChannelStartScanRequest.toObject(message.startScanRequest, options);
            if (message.stopScanRequest != null && message.hasOwnProperty("stopScanRequest"))
                object.stopScanRequest = $root.proto.ChannelStopScanRequest.toObject(message.stopScanRequest, options);
            if (message.startAutojoinRequest != null && message.hasOwnProperty("startAutojoinRequest"))
                object.startAutojoinRequest = $root.proto.ChannelStartAutojoinRequest.toObject(message.startAutojoinRequest, options);
            if (message.stopAutojoinRequest != null && message.hasOwnProperty("stopAutojoinRequest"))
                object.stopAutojoinRequest = $root.proto.ChannelStopAutojoinRequest.toObject(message.stopAutojoinRequest, options);
            if (message.quickSelectRequest != null && message.hasOwnProperty("quickSelectRequest"))
                object.quickSelectRequest = $root.proto.ChannelQuickSelectRequest.toObject(message.quickSelectRequest, options);
            if (message.quickSelectStepRequest != null && message.hasOwnProperty("quickSelectStepRequest"))
                object.quickSelectStepRequest = $root.proto.ChannelQuickSelectStepRequest.toObject(message.quickSelectStepRequest, options);
            if (message.leaveAllRequest != null && message.hasOwnProperty("leaveAllRequest"))
                object.leaveAllRequest = $root.proto.ChannelLeaveAllRequest.toObject(message.leaveAllRequest, options);
            return object;
        };

        /**
         * Converts this ChannelAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.ChannelAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelAPIv1Client
         * @function getTypeUrl
         * @memberof proto.ChannelAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelAPIv1Client";
        };

        return ChannelAPIv1Client;
    })();

    proto.ChannelModuleSetupRequest = (function() {

        /**
         * Properties of a ChannelModuleSetupRequest.
         * @memberof proto
         * @interface IChannelModuleSetupRequest
         * @property {number} suggestedMaxJoinedChannels ChannelModuleSetupRequest suggestedMaxJoinedChannels
         * @property {number} suggestedMaxScannedChannels ChannelModuleSetupRequest suggestedMaxScannedChannels
         * @property {number} suggestedMaxAutojoinedChannels ChannelModuleSetupRequest suggestedMaxAutojoinedChannels
         * @property {boolean|null} [resetToDefaultChannels] ChannelModuleSetupRequest resetToDefaultChannels
         * @property {boolean|null} [supportsTwilioFullDuplex] ChannelModuleSetupRequest supportsTwilioFullDuplex
         */

        /**
         * Constructs a new ChannelModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a ChannelModuleSetupRequest.
         * @implements IChannelModuleSetupRequest
         * @constructor
         * @param {proto.IChannelModuleSetupRequest=} [properties] Properties to set
         */
        function ChannelModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelModuleSetupRequest suggestedMaxJoinedChannels.
         * @member {number} suggestedMaxJoinedChannels
         * @memberof proto.ChannelModuleSetupRequest
         * @instance
         */
        ChannelModuleSetupRequest.prototype.suggestedMaxJoinedChannels = 0;

        /**
         * ChannelModuleSetupRequest suggestedMaxScannedChannels.
         * @member {number} suggestedMaxScannedChannels
         * @memberof proto.ChannelModuleSetupRequest
         * @instance
         */
        ChannelModuleSetupRequest.prototype.suggestedMaxScannedChannels = 0;

        /**
         * ChannelModuleSetupRequest suggestedMaxAutojoinedChannels.
         * @member {number} suggestedMaxAutojoinedChannels
         * @memberof proto.ChannelModuleSetupRequest
         * @instance
         */
        ChannelModuleSetupRequest.prototype.suggestedMaxAutojoinedChannels = 0;

        /**
         * ChannelModuleSetupRequest resetToDefaultChannels.
         * @member {boolean} resetToDefaultChannels
         * @memberof proto.ChannelModuleSetupRequest
         * @instance
         */
        ChannelModuleSetupRequest.prototype.resetToDefaultChannels = false;

        /**
         * ChannelModuleSetupRequest supportsTwilioFullDuplex.
         * @member {boolean} supportsTwilioFullDuplex
         * @memberof proto.ChannelModuleSetupRequest
         * @instance
         */
        ChannelModuleSetupRequest.prototype.supportsTwilioFullDuplex = false;

        /**
         * Creates a new ChannelModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {proto.IChannelModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.ChannelModuleSetupRequest} ChannelModuleSetupRequest instance
         */
        ChannelModuleSetupRequest.create = function create(properties) {
            return new ChannelModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified ChannelModuleSetupRequest message. Does not implicitly {@link proto.ChannelModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {proto.IChannelModuleSetupRequest} message ChannelModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.suggestedMaxJoinedChannels);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.suggestedMaxScannedChannels);
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.suggestedMaxAutojoinedChannels);
            if (message.resetToDefaultChannels != null && Object.hasOwnProperty.call(message, "resetToDefaultChannels"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.resetToDefaultChannels);
            if (message.supportsTwilioFullDuplex != null && Object.hasOwnProperty.call(message, "supportsTwilioFullDuplex"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.supportsTwilioFullDuplex);
            return writer;
        };

        /**
         * Encodes the specified ChannelModuleSetupRequest message, length delimited. Does not implicitly {@link proto.ChannelModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {proto.IChannelModuleSetupRequest} message ChannelModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelModuleSetupRequest} ChannelModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.suggestedMaxJoinedChannels = reader.int32();
                        break;
                    }
                case 2: {
                        message.suggestedMaxScannedChannels = reader.int32();
                        break;
                    }
                case 3: {
                        message.suggestedMaxAutojoinedChannels = reader.int32();
                        break;
                    }
                case 4: {
                        message.resetToDefaultChannels = reader.bool();
                        break;
                    }
                case 5: {
                        message.supportsTwilioFullDuplex = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("suggestedMaxJoinedChannels"))
                throw $util.ProtocolError("missing required 'suggestedMaxJoinedChannels'", { instance: message });
            if (!message.hasOwnProperty("suggestedMaxScannedChannels"))
                throw $util.ProtocolError("missing required 'suggestedMaxScannedChannels'", { instance: message });
            if (!message.hasOwnProperty("suggestedMaxAutojoinedChannels"))
                throw $util.ProtocolError("missing required 'suggestedMaxAutojoinedChannels'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelModuleSetupRequest} ChannelModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelModuleSetupRequest message.
         * @function verify
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.suggestedMaxJoinedChannels))
                return "suggestedMaxJoinedChannels: integer expected";
            if (!$util.isInteger(message.suggestedMaxScannedChannels))
                return "suggestedMaxScannedChannels: integer expected";
            if (!$util.isInteger(message.suggestedMaxAutojoinedChannels))
                return "suggestedMaxAutojoinedChannels: integer expected";
            if (message.resetToDefaultChannels != null && message.hasOwnProperty("resetToDefaultChannels"))
                if (typeof message.resetToDefaultChannels !== "boolean")
                    return "resetToDefaultChannels: boolean expected";
            if (message.supportsTwilioFullDuplex != null && message.hasOwnProperty("supportsTwilioFullDuplex"))
                if (typeof message.supportsTwilioFullDuplex !== "boolean")
                    return "supportsTwilioFullDuplex: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelModuleSetupRequest} ChannelModuleSetupRequest
         */
        ChannelModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelModuleSetupRequest)
                return object;
            var message = new $root.proto.ChannelModuleSetupRequest();
            if (object.suggestedMaxJoinedChannels != null)
                message.suggestedMaxJoinedChannels = object.suggestedMaxJoinedChannels | 0;
            if (object.suggestedMaxScannedChannels != null)
                message.suggestedMaxScannedChannels = object.suggestedMaxScannedChannels | 0;
            if (object.suggestedMaxAutojoinedChannels != null)
                message.suggestedMaxAutojoinedChannels = object.suggestedMaxAutojoinedChannels | 0;
            if (object.resetToDefaultChannels != null)
                message.resetToDefaultChannels = Boolean(object.resetToDefaultChannels);
            if (object.supportsTwilioFullDuplex != null)
                message.supportsTwilioFullDuplex = Boolean(object.supportsTwilioFullDuplex);
            return message;
        };

        /**
         * Creates a plain object from a ChannelModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {proto.ChannelModuleSetupRequest} message ChannelModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelModuleSetupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.suggestedMaxJoinedChannels = 0;
                object.suggestedMaxScannedChannels = 0;
                object.suggestedMaxAutojoinedChannels = 0;
                object.resetToDefaultChannels = false;
                object.supportsTwilioFullDuplex = false;
            }
            if (message.suggestedMaxJoinedChannels != null && message.hasOwnProperty("suggestedMaxJoinedChannels"))
                object.suggestedMaxJoinedChannels = message.suggestedMaxJoinedChannels;
            if (message.suggestedMaxScannedChannels != null && message.hasOwnProperty("suggestedMaxScannedChannels"))
                object.suggestedMaxScannedChannels = message.suggestedMaxScannedChannels;
            if (message.suggestedMaxAutojoinedChannels != null && message.hasOwnProperty("suggestedMaxAutojoinedChannels"))
                object.suggestedMaxAutojoinedChannels = message.suggestedMaxAutojoinedChannels;
            if (message.resetToDefaultChannels != null && message.hasOwnProperty("resetToDefaultChannels"))
                object.resetToDefaultChannels = message.resetToDefaultChannels;
            if (message.supportsTwilioFullDuplex != null && message.hasOwnProperty("supportsTwilioFullDuplex"))
                object.supportsTwilioFullDuplex = message.supportsTwilioFullDuplex;
            return object;
        };

        /**
         * Converts this ChannelModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.ChannelModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelModuleSetupRequest";
        };

        return ChannelModuleSetupRequest;
    })();

    proto.ChannelModuleSetupResponse = (function() {

        /**
         * Properties of a ChannelModuleSetupResponse.
         * @memberof proto
         * @interface IChannelModuleSetupResponse
         * @property {number} maxJoinedChannels ChannelModuleSetupResponse maxJoinedChannels
         * @property {number} maxScannedChannels ChannelModuleSetupResponse maxScannedChannels
         * @property {number} maxAutojoinedChannels ChannelModuleSetupResponse maxAutojoinedChannels
         * @property {boolean|null} [mayManageChannels] ChannelModuleSetupResponse mayManageChannels
         * @property {boolean|null} [mayManageJoinChannels] ChannelModuleSetupResponse mayManageJoinChannels
         * @property {boolean|null} [mayManageScanChannels] ChannelModuleSetupResponse mayManageScanChannels
         */

        /**
         * Constructs a new ChannelModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a ChannelModuleSetupResponse.
         * @implements IChannelModuleSetupResponse
         * @constructor
         * @param {proto.IChannelModuleSetupResponse=} [properties] Properties to set
         */
        function ChannelModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelModuleSetupResponse maxJoinedChannels.
         * @member {number} maxJoinedChannels
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         */
        ChannelModuleSetupResponse.prototype.maxJoinedChannels = 0;

        /**
         * ChannelModuleSetupResponse maxScannedChannels.
         * @member {number} maxScannedChannels
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         */
        ChannelModuleSetupResponse.prototype.maxScannedChannels = 0;

        /**
         * ChannelModuleSetupResponse maxAutojoinedChannels.
         * @member {number} maxAutojoinedChannels
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         */
        ChannelModuleSetupResponse.prototype.maxAutojoinedChannels = 0;

        /**
         * ChannelModuleSetupResponse mayManageChannels.
         * @member {boolean} mayManageChannels
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         */
        ChannelModuleSetupResponse.prototype.mayManageChannels = false;

        /**
         * ChannelModuleSetupResponse mayManageJoinChannels.
         * @member {boolean} mayManageJoinChannels
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         */
        ChannelModuleSetupResponse.prototype.mayManageJoinChannels = false;

        /**
         * ChannelModuleSetupResponse mayManageScanChannels.
         * @member {boolean} mayManageScanChannels
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         */
        ChannelModuleSetupResponse.prototype.mayManageScanChannels = false;

        /**
         * Creates a new ChannelModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {proto.IChannelModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.ChannelModuleSetupResponse} ChannelModuleSetupResponse instance
         */
        ChannelModuleSetupResponse.create = function create(properties) {
            return new ChannelModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified ChannelModuleSetupResponse message. Does not implicitly {@link proto.ChannelModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {proto.IChannelModuleSetupResponse} message ChannelModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxJoinedChannels);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxScannedChannels);
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxAutojoinedChannels);
            if (message.mayManageChannels != null && Object.hasOwnProperty.call(message, "mayManageChannels"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.mayManageChannels);
            if (message.mayManageJoinChannels != null && Object.hasOwnProperty.call(message, "mayManageJoinChannels"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.mayManageJoinChannels);
            if (message.mayManageScanChannels != null && Object.hasOwnProperty.call(message, "mayManageScanChannels"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.mayManageScanChannels);
            return writer;
        };

        /**
         * Encodes the specified ChannelModuleSetupResponse message, length delimited. Does not implicitly {@link proto.ChannelModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {proto.IChannelModuleSetupResponse} message ChannelModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelModuleSetupResponse} ChannelModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxJoinedChannels = reader.int32();
                        break;
                    }
                case 2: {
                        message.maxScannedChannels = reader.int32();
                        break;
                    }
                case 3: {
                        message.maxAutojoinedChannels = reader.int32();
                        break;
                    }
                case 4: {
                        message.mayManageChannels = reader.bool();
                        break;
                    }
                case 5: {
                        message.mayManageJoinChannels = reader.bool();
                        break;
                    }
                case 6: {
                        message.mayManageScanChannels = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("maxJoinedChannels"))
                throw $util.ProtocolError("missing required 'maxJoinedChannels'", { instance: message });
            if (!message.hasOwnProperty("maxScannedChannels"))
                throw $util.ProtocolError("missing required 'maxScannedChannels'", { instance: message });
            if (!message.hasOwnProperty("maxAutojoinedChannels"))
                throw $util.ProtocolError("missing required 'maxAutojoinedChannels'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelModuleSetupResponse} ChannelModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelModuleSetupResponse message.
         * @function verify
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.maxJoinedChannels))
                return "maxJoinedChannels: integer expected";
            if (!$util.isInteger(message.maxScannedChannels))
                return "maxScannedChannels: integer expected";
            if (!$util.isInteger(message.maxAutojoinedChannels))
                return "maxAutojoinedChannels: integer expected";
            if (message.mayManageChannels != null && message.hasOwnProperty("mayManageChannels"))
                if (typeof message.mayManageChannels !== "boolean")
                    return "mayManageChannels: boolean expected";
            if (message.mayManageJoinChannels != null && message.hasOwnProperty("mayManageJoinChannels"))
                if (typeof message.mayManageJoinChannels !== "boolean")
                    return "mayManageJoinChannels: boolean expected";
            if (message.mayManageScanChannels != null && message.hasOwnProperty("mayManageScanChannels"))
                if (typeof message.mayManageScanChannels !== "boolean")
                    return "mayManageScanChannels: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelModuleSetupResponse} ChannelModuleSetupResponse
         */
        ChannelModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelModuleSetupResponse)
                return object;
            var message = new $root.proto.ChannelModuleSetupResponse();
            if (object.maxJoinedChannels != null)
                message.maxJoinedChannels = object.maxJoinedChannels | 0;
            if (object.maxScannedChannels != null)
                message.maxScannedChannels = object.maxScannedChannels | 0;
            if (object.maxAutojoinedChannels != null)
                message.maxAutojoinedChannels = object.maxAutojoinedChannels | 0;
            if (object.mayManageChannels != null)
                message.mayManageChannels = Boolean(object.mayManageChannels);
            if (object.mayManageJoinChannels != null)
                message.mayManageJoinChannels = Boolean(object.mayManageJoinChannels);
            if (object.mayManageScanChannels != null)
                message.mayManageScanChannels = Boolean(object.mayManageScanChannels);
            return message;
        };

        /**
         * Creates a plain object from a ChannelModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {proto.ChannelModuleSetupResponse} message ChannelModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.maxJoinedChannels = 0;
                object.maxScannedChannels = 0;
                object.maxAutojoinedChannels = 0;
                object.mayManageChannels = false;
                object.mayManageJoinChannels = false;
                object.mayManageScanChannels = false;
            }
            if (message.maxJoinedChannels != null && message.hasOwnProperty("maxJoinedChannels"))
                object.maxJoinedChannels = message.maxJoinedChannels;
            if (message.maxScannedChannels != null && message.hasOwnProperty("maxScannedChannels"))
                object.maxScannedChannels = message.maxScannedChannels;
            if (message.maxAutojoinedChannels != null && message.hasOwnProperty("maxAutojoinedChannels"))
                object.maxAutojoinedChannels = message.maxAutojoinedChannels;
            if (message.mayManageChannels != null && message.hasOwnProperty("mayManageChannels"))
                object.mayManageChannels = message.mayManageChannels;
            if (message.mayManageJoinChannels != null && message.hasOwnProperty("mayManageJoinChannels"))
                object.mayManageJoinChannels = message.mayManageJoinChannels;
            if (message.mayManageScanChannels != null && message.hasOwnProperty("mayManageScanChannels"))
                object.mayManageScanChannels = message.mayManageScanChannels;
            return object;
        };

        /**
         * Converts this ChannelModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.ChannelModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.ChannelModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelModuleSetupResponse";
        };

        return ChannelModuleSetupResponse;
    })();

    proto.ChannelSubscribeRequest = (function() {

        /**
         * Properties of a ChannelSubscribeRequest.
         * @memberof proto
         * @interface IChannelSubscribeRequest
         */

        /**
         * Constructs a new ChannelSubscribeRequest.
         * @memberof proto
         * @classdesc Represents a ChannelSubscribeRequest.
         * @implements IChannelSubscribeRequest
         * @constructor
         * @param {proto.IChannelSubscribeRequest=} [properties] Properties to set
         */
        function ChannelSubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ChannelSubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {proto.IChannelSubscribeRequest=} [properties] Properties to set
         * @returns {proto.ChannelSubscribeRequest} ChannelSubscribeRequest instance
         */
        ChannelSubscribeRequest.create = function create(properties) {
            return new ChannelSubscribeRequest(properties);
        };

        /**
         * Encodes the specified ChannelSubscribeRequest message. Does not implicitly {@link proto.ChannelSubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {proto.IChannelSubscribeRequest} message ChannelSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelSubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ChannelSubscribeRequest message, length delimited. Does not implicitly {@link proto.ChannelSubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {proto.IChannelSubscribeRequest} message ChannelSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelSubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelSubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelSubscribeRequest} ChannelSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelSubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelSubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelSubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelSubscribeRequest} ChannelSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelSubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelSubscribeRequest message.
         * @function verify
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelSubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ChannelSubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelSubscribeRequest} ChannelSubscribeRequest
         */
        ChannelSubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelSubscribeRequest)
                return object;
            return new $root.proto.ChannelSubscribeRequest();
        };

        /**
         * Creates a plain object from a ChannelSubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {proto.ChannelSubscribeRequest} message ChannelSubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelSubscribeRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ChannelSubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelSubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelSubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelSubscribeRequest
         * @function getTypeUrl
         * @memberof proto.ChannelSubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelSubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelSubscribeRequest";
        };

        return ChannelSubscribeRequest;
    })();

    proto.ChannelSubscribeResponse = (function() {

        /**
         * Properties of a ChannelSubscribeResponse.
         * @memberof proto
         * @interface IChannelSubscribeResponse
         * @property {number|Long} subscriptionId ChannelSubscribeResponse subscriptionId
         * @property {Array.<proto.IChannelInfo>|null} [initialChannels] ChannelSubscribeResponse initialChannels
         */

        /**
         * Constructs a new ChannelSubscribeResponse.
         * @memberof proto
         * @classdesc Represents a ChannelSubscribeResponse.
         * @implements IChannelSubscribeResponse
         * @constructor
         * @param {proto.IChannelSubscribeResponse=} [properties] Properties to set
         */
        function ChannelSubscribeResponse(properties) {
            this.initialChannels = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelSubscribeResponse subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.ChannelSubscribeResponse
         * @instance
         */
        ChannelSubscribeResponse.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelSubscribeResponse initialChannels.
         * @member {Array.<proto.IChannelInfo>} initialChannels
         * @memberof proto.ChannelSubscribeResponse
         * @instance
         */
        ChannelSubscribeResponse.prototype.initialChannels = $util.emptyArray;

        /**
         * Creates a new ChannelSubscribeResponse instance using the specified properties.
         * @function create
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {proto.IChannelSubscribeResponse=} [properties] Properties to set
         * @returns {proto.ChannelSubscribeResponse} ChannelSubscribeResponse instance
         */
        ChannelSubscribeResponse.create = function create(properties) {
            return new ChannelSubscribeResponse(properties);
        };

        /**
         * Encodes the specified ChannelSubscribeResponse message. Does not implicitly {@link proto.ChannelSubscribeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {proto.IChannelSubscribeResponse} message ChannelSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelSubscribeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.initialChannels != null && message.initialChannels.length)
                for (var i = 0; i < message.initialChannels.length; ++i)
                    $root.proto.ChannelInfo.encode(message.initialChannels[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelSubscribeResponse message, length delimited. Does not implicitly {@link proto.ChannelSubscribeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {proto.IChannelSubscribeResponse} message ChannelSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelSubscribeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelSubscribeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelSubscribeResponse} ChannelSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelSubscribeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelSubscribeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.initialChannels && message.initialChannels.length))
                            message.initialChannels = [];
                        message.initialChannels.push($root.proto.ChannelInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelSubscribeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelSubscribeResponse} ChannelSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelSubscribeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelSubscribeResponse message.
         * @function verify
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelSubscribeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.initialChannels != null && message.hasOwnProperty("initialChannels")) {
                if (!Array.isArray(message.initialChannels))
                    return "initialChannels: array expected";
                for (var i = 0; i < message.initialChannels.length; ++i) {
                    var error = $root.proto.ChannelInfo.verify(message.initialChannels[i]);
                    if (error)
                        return "initialChannels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ChannelSubscribeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelSubscribeResponse} ChannelSubscribeResponse
         */
        ChannelSubscribeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelSubscribeResponse)
                return object;
            var message = new $root.proto.ChannelSubscribeResponse();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.initialChannels) {
                if (!Array.isArray(object.initialChannels))
                    throw TypeError(".proto.ChannelSubscribeResponse.initialChannels: array expected");
                message.initialChannels = [];
                for (var i = 0; i < object.initialChannels.length; ++i) {
                    if (typeof object.initialChannels[i] !== "object")
                        throw TypeError(".proto.ChannelSubscribeResponse.initialChannels: object expected");
                    message.initialChannels[i] = $root.proto.ChannelInfo.fromObject(object.initialChannels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelSubscribeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {proto.ChannelSubscribeResponse} message ChannelSubscribeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelSubscribeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.initialChannels = [];
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.initialChannels && message.initialChannels.length) {
                object.initialChannels = [];
                for (var j = 0; j < message.initialChannels.length; ++j)
                    object.initialChannels[j] = $root.proto.ChannelInfo.toObject(message.initialChannels[j], options);
            }
            return object;
        };

        /**
         * Converts this ChannelSubscribeResponse to JSON.
         * @function toJSON
         * @memberof proto.ChannelSubscribeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelSubscribeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelSubscribeResponse
         * @function getTypeUrl
         * @memberof proto.ChannelSubscribeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelSubscribeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelSubscribeResponse";
        };

        return ChannelSubscribeResponse;
    })();

    proto.ChannelUnsubscribeRequest = (function() {

        /**
         * Properties of a ChannelUnsubscribeRequest.
         * @memberof proto
         * @interface IChannelUnsubscribeRequest
         */

        /**
         * Constructs a new ChannelUnsubscribeRequest.
         * @memberof proto
         * @classdesc Represents a ChannelUnsubscribeRequest.
         * @implements IChannelUnsubscribeRequest
         * @constructor
         * @param {proto.IChannelUnsubscribeRequest=} [properties] Properties to set
         */
        function ChannelUnsubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ChannelUnsubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {proto.IChannelUnsubscribeRequest=} [properties] Properties to set
         * @returns {proto.ChannelUnsubscribeRequest} ChannelUnsubscribeRequest instance
         */
        ChannelUnsubscribeRequest.create = function create(properties) {
            return new ChannelUnsubscribeRequest(properties);
        };

        /**
         * Encodes the specified ChannelUnsubscribeRequest message. Does not implicitly {@link proto.ChannelUnsubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {proto.IChannelUnsubscribeRequest} message ChannelUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelUnsubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ChannelUnsubscribeRequest message, length delimited. Does not implicitly {@link proto.ChannelUnsubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {proto.IChannelUnsubscribeRequest} message ChannelUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelUnsubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelUnsubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelUnsubscribeRequest} ChannelUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelUnsubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelUnsubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelUnsubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelUnsubscribeRequest} ChannelUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelUnsubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelUnsubscribeRequest message.
         * @function verify
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelUnsubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ChannelUnsubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelUnsubscribeRequest} ChannelUnsubscribeRequest
         */
        ChannelUnsubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelUnsubscribeRequest)
                return object;
            return new $root.proto.ChannelUnsubscribeRequest();
        };

        /**
         * Creates a plain object from a ChannelUnsubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {proto.ChannelUnsubscribeRequest} message ChannelUnsubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelUnsubscribeRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ChannelUnsubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelUnsubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelUnsubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelUnsubscribeRequest
         * @function getTypeUrl
         * @memberof proto.ChannelUnsubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelUnsubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelUnsubscribeRequest";
        };

        return ChannelUnsubscribeRequest;
    })();

    proto.ChannelJoinRequest = (function() {

        /**
         * Properties of a ChannelJoinRequest.
         * @memberof proto
         * @interface IChannelJoinRequest
         * @property {string} channelId ChannelJoinRequest channelId
         */

        /**
         * Constructs a new ChannelJoinRequest.
         * @memberof proto
         * @classdesc Represents a ChannelJoinRequest.
         * @implements IChannelJoinRequest
         * @constructor
         * @param {proto.IChannelJoinRequest=} [properties] Properties to set
         */
        function ChannelJoinRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelJoinRequest channelId.
         * @member {string} channelId
         * @memberof proto.ChannelJoinRequest
         * @instance
         */
        ChannelJoinRequest.prototype.channelId = "";

        /**
         * Creates a new ChannelJoinRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {proto.IChannelJoinRequest=} [properties] Properties to set
         * @returns {proto.ChannelJoinRequest} ChannelJoinRequest instance
         */
        ChannelJoinRequest.create = function create(properties) {
            return new ChannelJoinRequest(properties);
        };

        /**
         * Encodes the specified ChannelJoinRequest message. Does not implicitly {@link proto.ChannelJoinRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {proto.IChannelJoinRequest} message ChannelJoinRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelJoinRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            return writer;
        };

        /**
         * Encodes the specified ChannelJoinRequest message, length delimited. Does not implicitly {@link proto.ChannelJoinRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {proto.IChannelJoinRequest} message ChannelJoinRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelJoinRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelJoinRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelJoinRequest} ChannelJoinRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelJoinRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelJoinRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelJoinRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelJoinRequest} ChannelJoinRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelJoinRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelJoinRequest message.
         * @function verify
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelJoinRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            return null;
        };

        /**
         * Creates a ChannelJoinRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelJoinRequest} ChannelJoinRequest
         */
        ChannelJoinRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelJoinRequest)
                return object;
            var message = new $root.proto.ChannelJoinRequest();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            return message;
        };

        /**
         * Creates a plain object from a ChannelJoinRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {proto.ChannelJoinRequest} message ChannelJoinRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelJoinRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelId = "";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            return object;
        };

        /**
         * Converts this ChannelJoinRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelJoinRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelJoinRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelJoinRequest
         * @function getTypeUrl
         * @memberof proto.ChannelJoinRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelJoinRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelJoinRequest";
        };

        return ChannelJoinRequest;
    })();

    proto.ChannelLeaveRequest = (function() {

        /**
         * Properties of a ChannelLeaveRequest.
         * @memberof proto
         * @interface IChannelLeaveRequest
         * @property {string} channelId ChannelLeaveRequest channelId
         */

        /**
         * Constructs a new ChannelLeaveRequest.
         * @memberof proto
         * @classdesc Represents a ChannelLeaveRequest.
         * @implements IChannelLeaveRequest
         * @constructor
         * @param {proto.IChannelLeaveRequest=} [properties] Properties to set
         */
        function ChannelLeaveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelLeaveRequest channelId.
         * @member {string} channelId
         * @memberof proto.ChannelLeaveRequest
         * @instance
         */
        ChannelLeaveRequest.prototype.channelId = "";

        /**
         * Creates a new ChannelLeaveRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {proto.IChannelLeaveRequest=} [properties] Properties to set
         * @returns {proto.ChannelLeaveRequest} ChannelLeaveRequest instance
         */
        ChannelLeaveRequest.create = function create(properties) {
            return new ChannelLeaveRequest(properties);
        };

        /**
         * Encodes the specified ChannelLeaveRequest message. Does not implicitly {@link proto.ChannelLeaveRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {proto.IChannelLeaveRequest} message ChannelLeaveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelLeaveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            return writer;
        };

        /**
         * Encodes the specified ChannelLeaveRequest message, length delimited. Does not implicitly {@link proto.ChannelLeaveRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {proto.IChannelLeaveRequest} message ChannelLeaveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelLeaveRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelLeaveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelLeaveRequest} ChannelLeaveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelLeaveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelLeaveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelLeaveRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelLeaveRequest} ChannelLeaveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelLeaveRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelLeaveRequest message.
         * @function verify
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelLeaveRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            return null;
        };

        /**
         * Creates a ChannelLeaveRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelLeaveRequest} ChannelLeaveRequest
         */
        ChannelLeaveRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelLeaveRequest)
                return object;
            var message = new $root.proto.ChannelLeaveRequest();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            return message;
        };

        /**
         * Creates a plain object from a ChannelLeaveRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {proto.ChannelLeaveRequest} message ChannelLeaveRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelLeaveRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelId = "";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            return object;
        };

        /**
         * Converts this ChannelLeaveRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelLeaveRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelLeaveRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelLeaveRequest
         * @function getTypeUrl
         * @memberof proto.ChannelLeaveRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelLeaveRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelLeaveRequest";
        };

        return ChannelLeaveRequest;
    })();

    proto.ChannelStartScanRequest = (function() {

        /**
         * Properties of a ChannelStartScanRequest.
         * @memberof proto
         * @interface IChannelStartScanRequest
         * @property {string} channelId ChannelStartScanRequest channelId
         */

        /**
         * Constructs a new ChannelStartScanRequest.
         * @memberof proto
         * @classdesc Represents a ChannelStartScanRequest.
         * @implements IChannelStartScanRequest
         * @constructor
         * @param {proto.IChannelStartScanRequest=} [properties] Properties to set
         */
        function ChannelStartScanRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelStartScanRequest channelId.
         * @member {string} channelId
         * @memberof proto.ChannelStartScanRequest
         * @instance
         */
        ChannelStartScanRequest.prototype.channelId = "";

        /**
         * Creates a new ChannelStartScanRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {proto.IChannelStartScanRequest=} [properties] Properties to set
         * @returns {proto.ChannelStartScanRequest} ChannelStartScanRequest instance
         */
        ChannelStartScanRequest.create = function create(properties) {
            return new ChannelStartScanRequest(properties);
        };

        /**
         * Encodes the specified ChannelStartScanRequest message. Does not implicitly {@link proto.ChannelStartScanRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {proto.IChannelStartScanRequest} message ChannelStartScanRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStartScanRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            return writer;
        };

        /**
         * Encodes the specified ChannelStartScanRequest message, length delimited. Does not implicitly {@link proto.ChannelStartScanRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {proto.IChannelStartScanRequest} message ChannelStartScanRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStartScanRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelStartScanRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelStartScanRequest} ChannelStartScanRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStartScanRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelStartScanRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelStartScanRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelStartScanRequest} ChannelStartScanRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStartScanRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelStartScanRequest message.
         * @function verify
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelStartScanRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            return null;
        };

        /**
         * Creates a ChannelStartScanRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelStartScanRequest} ChannelStartScanRequest
         */
        ChannelStartScanRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelStartScanRequest)
                return object;
            var message = new $root.proto.ChannelStartScanRequest();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            return message;
        };

        /**
         * Creates a plain object from a ChannelStartScanRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {proto.ChannelStartScanRequest} message ChannelStartScanRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelStartScanRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelId = "";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            return object;
        };

        /**
         * Converts this ChannelStartScanRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelStartScanRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelStartScanRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelStartScanRequest
         * @function getTypeUrl
         * @memberof proto.ChannelStartScanRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelStartScanRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelStartScanRequest";
        };

        return ChannelStartScanRequest;
    })();

    proto.ChannelStopScanRequest = (function() {

        /**
         * Properties of a ChannelStopScanRequest.
         * @memberof proto
         * @interface IChannelStopScanRequest
         * @property {string} channelId ChannelStopScanRequest channelId
         */

        /**
         * Constructs a new ChannelStopScanRequest.
         * @memberof proto
         * @classdesc Represents a ChannelStopScanRequest.
         * @implements IChannelStopScanRequest
         * @constructor
         * @param {proto.IChannelStopScanRequest=} [properties] Properties to set
         */
        function ChannelStopScanRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelStopScanRequest channelId.
         * @member {string} channelId
         * @memberof proto.ChannelStopScanRequest
         * @instance
         */
        ChannelStopScanRequest.prototype.channelId = "";

        /**
         * Creates a new ChannelStopScanRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {proto.IChannelStopScanRequest=} [properties] Properties to set
         * @returns {proto.ChannelStopScanRequest} ChannelStopScanRequest instance
         */
        ChannelStopScanRequest.create = function create(properties) {
            return new ChannelStopScanRequest(properties);
        };

        /**
         * Encodes the specified ChannelStopScanRequest message. Does not implicitly {@link proto.ChannelStopScanRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {proto.IChannelStopScanRequest} message ChannelStopScanRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStopScanRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            return writer;
        };

        /**
         * Encodes the specified ChannelStopScanRequest message, length delimited. Does not implicitly {@link proto.ChannelStopScanRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {proto.IChannelStopScanRequest} message ChannelStopScanRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStopScanRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelStopScanRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelStopScanRequest} ChannelStopScanRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStopScanRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelStopScanRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelStopScanRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelStopScanRequest} ChannelStopScanRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStopScanRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelStopScanRequest message.
         * @function verify
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelStopScanRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            return null;
        };

        /**
         * Creates a ChannelStopScanRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelStopScanRequest} ChannelStopScanRequest
         */
        ChannelStopScanRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelStopScanRequest)
                return object;
            var message = new $root.proto.ChannelStopScanRequest();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            return message;
        };

        /**
         * Creates a plain object from a ChannelStopScanRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {proto.ChannelStopScanRequest} message ChannelStopScanRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelStopScanRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelId = "";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            return object;
        };

        /**
         * Converts this ChannelStopScanRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelStopScanRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelStopScanRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelStopScanRequest
         * @function getTypeUrl
         * @memberof proto.ChannelStopScanRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelStopScanRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelStopScanRequest";
        };

        return ChannelStopScanRequest;
    })();

    proto.ChannelStartAutojoinRequest = (function() {

        /**
         * Properties of a ChannelStartAutojoinRequest.
         * @memberof proto
         * @interface IChannelStartAutojoinRequest
         * @property {string} channelId ChannelStartAutojoinRequest channelId
         */

        /**
         * Constructs a new ChannelStartAutojoinRequest.
         * @memberof proto
         * @classdesc Represents a ChannelStartAutojoinRequest.
         * @implements IChannelStartAutojoinRequest
         * @constructor
         * @param {proto.IChannelStartAutojoinRequest=} [properties] Properties to set
         */
        function ChannelStartAutojoinRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelStartAutojoinRequest channelId.
         * @member {string} channelId
         * @memberof proto.ChannelStartAutojoinRequest
         * @instance
         */
        ChannelStartAutojoinRequest.prototype.channelId = "";

        /**
         * Creates a new ChannelStartAutojoinRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {proto.IChannelStartAutojoinRequest=} [properties] Properties to set
         * @returns {proto.ChannelStartAutojoinRequest} ChannelStartAutojoinRequest instance
         */
        ChannelStartAutojoinRequest.create = function create(properties) {
            return new ChannelStartAutojoinRequest(properties);
        };

        /**
         * Encodes the specified ChannelStartAutojoinRequest message. Does not implicitly {@link proto.ChannelStartAutojoinRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {proto.IChannelStartAutojoinRequest} message ChannelStartAutojoinRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStartAutojoinRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            return writer;
        };

        /**
         * Encodes the specified ChannelStartAutojoinRequest message, length delimited. Does not implicitly {@link proto.ChannelStartAutojoinRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {proto.IChannelStartAutojoinRequest} message ChannelStartAutojoinRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStartAutojoinRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelStartAutojoinRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelStartAutojoinRequest} ChannelStartAutojoinRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStartAutojoinRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelStartAutojoinRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelStartAutojoinRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelStartAutojoinRequest} ChannelStartAutojoinRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStartAutojoinRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelStartAutojoinRequest message.
         * @function verify
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelStartAutojoinRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            return null;
        };

        /**
         * Creates a ChannelStartAutojoinRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelStartAutojoinRequest} ChannelStartAutojoinRequest
         */
        ChannelStartAutojoinRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelStartAutojoinRequest)
                return object;
            var message = new $root.proto.ChannelStartAutojoinRequest();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            return message;
        };

        /**
         * Creates a plain object from a ChannelStartAutojoinRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {proto.ChannelStartAutojoinRequest} message ChannelStartAutojoinRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelStartAutojoinRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelId = "";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            return object;
        };

        /**
         * Converts this ChannelStartAutojoinRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelStartAutojoinRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelStartAutojoinRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelStartAutojoinRequest
         * @function getTypeUrl
         * @memberof proto.ChannelStartAutojoinRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelStartAutojoinRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelStartAutojoinRequest";
        };

        return ChannelStartAutojoinRequest;
    })();

    proto.ChannelStopAutojoinRequest = (function() {

        /**
         * Properties of a ChannelStopAutojoinRequest.
         * @memberof proto
         * @interface IChannelStopAutojoinRequest
         * @property {string} channelId ChannelStopAutojoinRequest channelId
         */

        /**
         * Constructs a new ChannelStopAutojoinRequest.
         * @memberof proto
         * @classdesc Represents a ChannelStopAutojoinRequest.
         * @implements IChannelStopAutojoinRequest
         * @constructor
         * @param {proto.IChannelStopAutojoinRequest=} [properties] Properties to set
         */
        function ChannelStopAutojoinRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelStopAutojoinRequest channelId.
         * @member {string} channelId
         * @memberof proto.ChannelStopAutojoinRequest
         * @instance
         */
        ChannelStopAutojoinRequest.prototype.channelId = "";

        /**
         * Creates a new ChannelStopAutojoinRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {proto.IChannelStopAutojoinRequest=} [properties] Properties to set
         * @returns {proto.ChannelStopAutojoinRequest} ChannelStopAutojoinRequest instance
         */
        ChannelStopAutojoinRequest.create = function create(properties) {
            return new ChannelStopAutojoinRequest(properties);
        };

        /**
         * Encodes the specified ChannelStopAutojoinRequest message. Does not implicitly {@link proto.ChannelStopAutojoinRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {proto.IChannelStopAutojoinRequest} message ChannelStopAutojoinRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStopAutojoinRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            return writer;
        };

        /**
         * Encodes the specified ChannelStopAutojoinRequest message, length delimited. Does not implicitly {@link proto.ChannelStopAutojoinRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {proto.IChannelStopAutojoinRequest} message ChannelStopAutojoinRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelStopAutojoinRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelStopAutojoinRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelStopAutojoinRequest} ChannelStopAutojoinRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStopAutojoinRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelStopAutojoinRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelStopAutojoinRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelStopAutojoinRequest} ChannelStopAutojoinRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelStopAutojoinRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelStopAutojoinRequest message.
         * @function verify
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelStopAutojoinRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            return null;
        };

        /**
         * Creates a ChannelStopAutojoinRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelStopAutojoinRequest} ChannelStopAutojoinRequest
         */
        ChannelStopAutojoinRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelStopAutojoinRequest)
                return object;
            var message = new $root.proto.ChannelStopAutojoinRequest();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            return message;
        };

        /**
         * Creates a plain object from a ChannelStopAutojoinRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {proto.ChannelStopAutojoinRequest} message ChannelStopAutojoinRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelStopAutojoinRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelId = "";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            return object;
        };

        /**
         * Converts this ChannelStopAutojoinRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelStopAutojoinRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelStopAutojoinRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelStopAutojoinRequest
         * @function getTypeUrl
         * @memberof proto.ChannelStopAutojoinRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelStopAutojoinRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelStopAutojoinRequest";
        };

        return ChannelStopAutojoinRequest;
    })();

    proto.ChannelLeaveAllRequest = (function() {

        /**
         * Properties of a ChannelLeaveAllRequest.
         * @memberof proto
         * @interface IChannelLeaveAllRequest
         * @property {boolean|null} [joined] ChannelLeaveAllRequest joined
         * @property {boolean|null} [scanned] ChannelLeaveAllRequest scanned
         * @property {boolean|null} [autojoined] ChannelLeaveAllRequest autojoined
         */

        /**
         * Constructs a new ChannelLeaveAllRequest.
         * @memberof proto
         * @classdesc Represents a ChannelLeaveAllRequest.
         * @implements IChannelLeaveAllRequest
         * @constructor
         * @param {proto.IChannelLeaveAllRequest=} [properties] Properties to set
         */
        function ChannelLeaveAllRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelLeaveAllRequest joined.
         * @member {boolean} joined
         * @memberof proto.ChannelLeaveAllRequest
         * @instance
         */
        ChannelLeaveAllRequest.prototype.joined = false;

        /**
         * ChannelLeaveAllRequest scanned.
         * @member {boolean} scanned
         * @memberof proto.ChannelLeaveAllRequest
         * @instance
         */
        ChannelLeaveAllRequest.prototype.scanned = false;

        /**
         * ChannelLeaveAllRequest autojoined.
         * @member {boolean} autojoined
         * @memberof proto.ChannelLeaveAllRequest
         * @instance
         */
        ChannelLeaveAllRequest.prototype.autojoined = false;

        /**
         * Creates a new ChannelLeaveAllRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {proto.IChannelLeaveAllRequest=} [properties] Properties to set
         * @returns {proto.ChannelLeaveAllRequest} ChannelLeaveAllRequest instance
         */
        ChannelLeaveAllRequest.create = function create(properties) {
            return new ChannelLeaveAllRequest(properties);
        };

        /**
         * Encodes the specified ChannelLeaveAllRequest message. Does not implicitly {@link proto.ChannelLeaveAllRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {proto.IChannelLeaveAllRequest} message ChannelLeaveAllRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelLeaveAllRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.joined != null && Object.hasOwnProperty.call(message, "joined"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.joined);
            if (message.scanned != null && Object.hasOwnProperty.call(message, "scanned"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.scanned);
            if (message.autojoined != null && Object.hasOwnProperty.call(message, "autojoined"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.autojoined);
            return writer;
        };

        /**
         * Encodes the specified ChannelLeaveAllRequest message, length delimited. Does not implicitly {@link proto.ChannelLeaveAllRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {proto.IChannelLeaveAllRequest} message ChannelLeaveAllRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelLeaveAllRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelLeaveAllRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelLeaveAllRequest} ChannelLeaveAllRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelLeaveAllRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelLeaveAllRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.joined = reader.bool();
                        break;
                    }
                case 2: {
                        message.scanned = reader.bool();
                        break;
                    }
                case 3: {
                        message.autojoined = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelLeaveAllRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelLeaveAllRequest} ChannelLeaveAllRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelLeaveAllRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelLeaveAllRequest message.
         * @function verify
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelLeaveAllRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.joined != null && message.hasOwnProperty("joined"))
                if (typeof message.joined !== "boolean")
                    return "joined: boolean expected";
            if (message.scanned != null && message.hasOwnProperty("scanned"))
                if (typeof message.scanned !== "boolean")
                    return "scanned: boolean expected";
            if (message.autojoined != null && message.hasOwnProperty("autojoined"))
                if (typeof message.autojoined !== "boolean")
                    return "autojoined: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelLeaveAllRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelLeaveAllRequest} ChannelLeaveAllRequest
         */
        ChannelLeaveAllRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelLeaveAllRequest)
                return object;
            var message = new $root.proto.ChannelLeaveAllRequest();
            if (object.joined != null)
                message.joined = Boolean(object.joined);
            if (object.scanned != null)
                message.scanned = Boolean(object.scanned);
            if (object.autojoined != null)
                message.autojoined = Boolean(object.autojoined);
            return message;
        };

        /**
         * Creates a plain object from a ChannelLeaveAllRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {proto.ChannelLeaveAllRequest} message ChannelLeaveAllRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelLeaveAllRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.joined = false;
                object.scanned = false;
                object.autojoined = false;
            }
            if (message.joined != null && message.hasOwnProperty("joined"))
                object.joined = message.joined;
            if (message.scanned != null && message.hasOwnProperty("scanned"))
                object.scanned = message.scanned;
            if (message.autojoined != null && message.hasOwnProperty("autojoined"))
                object.autojoined = message.autojoined;
            return object;
        };

        /**
         * Converts this ChannelLeaveAllRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelLeaveAllRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelLeaveAllRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelLeaveAllRequest
         * @function getTypeUrl
         * @memberof proto.ChannelLeaveAllRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelLeaveAllRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelLeaveAllRequest";
        };

        return ChannelLeaveAllRequest;
    })();

    proto.ChannelAPIv1Server = (function() {

        /**
         * Properties of a ChannelAPIv1Server.
         * @memberof proto
         * @interface IChannelAPIv1Server
         * @property {proto.IChannelListDelta|null} [channelListDelta] ChannelAPIv1Server channelListDelta
         */

        /**
         * Constructs a new ChannelAPIv1Server.
         * @memberof proto
         * @classdesc Represents a ChannelAPIv1Server.
         * @implements IChannelAPIv1Server
         * @constructor
         * @param {proto.IChannelAPIv1Server=} [properties] Properties to set
         */
        function ChannelAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelAPIv1Server channelListDelta.
         * @member {proto.IChannelListDelta|null|undefined} channelListDelta
         * @memberof proto.ChannelAPIv1Server
         * @instance
         */
        ChannelAPIv1Server.prototype.channelListDelta = null;

        /**
         * Creates a new ChannelAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {proto.IChannelAPIv1Server=} [properties] Properties to set
         * @returns {proto.ChannelAPIv1Server} ChannelAPIv1Server instance
         */
        ChannelAPIv1Server.create = function create(properties) {
            return new ChannelAPIv1Server(properties);
        };

        /**
         * Encodes the specified ChannelAPIv1Server message. Does not implicitly {@link proto.ChannelAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {proto.IChannelAPIv1Server} message ChannelAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelListDelta != null && Object.hasOwnProperty.call(message, "channelListDelta"))
                $root.proto.ChannelListDelta.encode(message.channelListDelta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelAPIv1Server message, length delimited. Does not implicitly {@link proto.ChannelAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {proto.IChannelAPIv1Server} message ChannelAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelAPIv1Server} ChannelAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelListDelta = $root.proto.ChannelListDelta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelAPIv1Server} ChannelAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelAPIv1Server message.
         * @function verify
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelListDelta != null && message.hasOwnProperty("channelListDelta")) {
                var error = $root.proto.ChannelListDelta.verify(message.channelListDelta);
                if (error)
                    return "channelListDelta." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelAPIv1Server} ChannelAPIv1Server
         */
        ChannelAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelAPIv1Server)
                return object;
            var message = new $root.proto.ChannelAPIv1Server();
            if (object.channelListDelta != null) {
                if (typeof object.channelListDelta !== "object")
                    throw TypeError(".proto.ChannelAPIv1Server.channelListDelta: object expected");
                message.channelListDelta = $root.proto.ChannelListDelta.fromObject(object.channelListDelta);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {proto.ChannelAPIv1Server} message ChannelAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.channelListDelta = null;
            if (message.channelListDelta != null && message.hasOwnProperty("channelListDelta"))
                object.channelListDelta = $root.proto.ChannelListDelta.toObject(message.channelListDelta, options);
            return object;
        };

        /**
         * Converts this ChannelAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.ChannelAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelAPIv1Server
         * @function getTypeUrl
         * @memberof proto.ChannelAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelAPIv1Server";
        };

        return ChannelAPIv1Server;
    })();

    proto.ChannelListDelta = (function() {

        /**
         * Properties of a ChannelListDelta.
         * @memberof proto
         * @interface IChannelListDelta
         * @property {number|Long} subscriptionId ChannelListDelta subscriptionId
         * @property {Array.<proto.IChannelInfo>|null} [updated] ChannelListDelta updated
         * @property {Array.<string>|null} [deletedChannelIds] ChannelListDelta deletedChannelIds
         */

        /**
         * Constructs a new ChannelListDelta.
         * @memberof proto
         * @classdesc Represents a ChannelListDelta.
         * @implements IChannelListDelta
         * @constructor
         * @param {proto.IChannelListDelta=} [properties] Properties to set
         */
        function ChannelListDelta(properties) {
            this.updated = [];
            this.deletedChannelIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelListDelta subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.ChannelListDelta
         * @instance
         */
        ChannelListDelta.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelListDelta updated.
         * @member {Array.<proto.IChannelInfo>} updated
         * @memberof proto.ChannelListDelta
         * @instance
         */
        ChannelListDelta.prototype.updated = $util.emptyArray;

        /**
         * ChannelListDelta deletedChannelIds.
         * @member {Array.<string>} deletedChannelIds
         * @memberof proto.ChannelListDelta
         * @instance
         */
        ChannelListDelta.prototype.deletedChannelIds = $util.emptyArray;

        /**
         * Creates a new ChannelListDelta instance using the specified properties.
         * @function create
         * @memberof proto.ChannelListDelta
         * @static
         * @param {proto.IChannelListDelta=} [properties] Properties to set
         * @returns {proto.ChannelListDelta} ChannelListDelta instance
         */
        ChannelListDelta.create = function create(properties) {
            return new ChannelListDelta(properties);
        };

        /**
         * Encodes the specified ChannelListDelta message. Does not implicitly {@link proto.ChannelListDelta.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelListDelta
         * @static
         * @param {proto.IChannelListDelta} message ChannelListDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelListDelta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.updated != null && message.updated.length)
                for (var i = 0; i < message.updated.length; ++i)
                    $root.proto.ChannelInfo.encode(message.updated[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.deletedChannelIds != null && message.deletedChannelIds.length)
                for (var i = 0; i < message.deletedChannelIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.deletedChannelIds[i]);
            return writer;
        };

        /**
         * Encodes the specified ChannelListDelta message, length delimited. Does not implicitly {@link proto.ChannelListDelta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelListDelta
         * @static
         * @param {proto.IChannelListDelta} message ChannelListDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelListDelta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelListDelta message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelListDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelListDelta} ChannelListDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelListDelta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelListDelta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.updated && message.updated.length))
                            message.updated = [];
                        message.updated.push($root.proto.ChannelInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.deletedChannelIds && message.deletedChannelIds.length))
                            message.deletedChannelIds = [];
                        message.deletedChannelIds.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelListDelta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelListDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelListDelta} ChannelListDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelListDelta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelListDelta message.
         * @function verify
         * @memberof proto.ChannelListDelta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelListDelta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.updated != null && message.hasOwnProperty("updated")) {
                if (!Array.isArray(message.updated))
                    return "updated: array expected";
                for (var i = 0; i < message.updated.length; ++i) {
                    var error = $root.proto.ChannelInfo.verify(message.updated[i]);
                    if (error)
                        return "updated." + error;
                }
            }
            if (message.deletedChannelIds != null && message.hasOwnProperty("deletedChannelIds")) {
                if (!Array.isArray(message.deletedChannelIds))
                    return "deletedChannelIds: array expected";
                for (var i = 0; i < message.deletedChannelIds.length; ++i)
                    if (!$util.isString(message.deletedChannelIds[i]))
                        return "deletedChannelIds: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ChannelListDelta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelListDelta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelListDelta} ChannelListDelta
         */
        ChannelListDelta.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelListDelta)
                return object;
            var message = new $root.proto.ChannelListDelta();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.updated) {
                if (!Array.isArray(object.updated))
                    throw TypeError(".proto.ChannelListDelta.updated: array expected");
                message.updated = [];
                for (var i = 0; i < object.updated.length; ++i) {
                    if (typeof object.updated[i] !== "object")
                        throw TypeError(".proto.ChannelListDelta.updated: object expected");
                    message.updated[i] = $root.proto.ChannelInfo.fromObject(object.updated[i]);
                }
            }
            if (object.deletedChannelIds) {
                if (!Array.isArray(object.deletedChannelIds))
                    throw TypeError(".proto.ChannelListDelta.deletedChannelIds: array expected");
                message.deletedChannelIds = [];
                for (var i = 0; i < object.deletedChannelIds.length; ++i)
                    message.deletedChannelIds[i] = String(object.deletedChannelIds[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelListDelta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelListDelta
         * @static
         * @param {proto.ChannelListDelta} message ChannelListDelta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelListDelta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.updated = [];
                object.deletedChannelIds = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.updated && message.updated.length) {
                object.updated = [];
                for (var j = 0; j < message.updated.length; ++j)
                    object.updated[j] = $root.proto.ChannelInfo.toObject(message.updated[j], options);
            }
            if (message.deletedChannelIds && message.deletedChannelIds.length) {
                object.deletedChannelIds = [];
                for (var j = 0; j < message.deletedChannelIds.length; ++j)
                    object.deletedChannelIds[j] = message.deletedChannelIds[j];
            }
            return object;
        };

        /**
         * Converts this ChannelListDelta to JSON.
         * @function toJSON
         * @memberof proto.ChannelListDelta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelListDelta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelListDelta
         * @function getTypeUrl
         * @memberof proto.ChannelListDelta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelListDelta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelListDelta";
        };

        return ChannelListDelta;
    })();

    /**
     * ChannelInfoAccessType enum.
     * @name proto.ChannelInfoAccessType
     * @enum {number}
     * @property {number} NONE=1 NONE value
     * @property {number} MANUAL=2 MANUAL value
     * @property {number} DEFAULT=3 DEFAULT value
     * @property {number} MANDATORY=4 MANDATORY value
     */
    proto.ChannelInfoAccessType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "NONE"] = 1;
        values[valuesById[2] = "MANUAL"] = 2;
        values[valuesById[3] = "DEFAULT"] = 3;
        values[valuesById[4] = "MANDATORY"] = 4;
        return values;
    })();

    /**
     * ChannelMediaType enum.
     * @name proto.ChannelMediaType
     * @enum {number}
     * @property {number} CHANNEL_MEDIA_TYPE_GROUPTALK_PTT=0 CHANNEL_MEDIA_TYPE_GROUPTALK_PTT value
     * @property {number} CHANNEL_MEDIA_TYPE_TWILIO_FULL_DUPLEX=1 CHANNEL_MEDIA_TYPE_TWILIO_FULL_DUPLEX value
     * @property {number} CHANNEL_MEDIA_TYPE_RESERVED_1=2 CHANNEL_MEDIA_TYPE_RESERVED_1 value
     * @property {number} CHANNEL_MEDIA_TYPE_RESERVED_2=3 CHANNEL_MEDIA_TYPE_RESERVED_2 value
     * @property {number} CHANNEL_MEDIA_TYPE_RESERVED_3=4 CHANNEL_MEDIA_TYPE_RESERVED_3 value
     * @property {number} CHANNEL_MEDIA_TYPE_RESERVED_4=5 CHANNEL_MEDIA_TYPE_RESERVED_4 value
     */
    proto.ChannelMediaType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CHANNEL_MEDIA_TYPE_GROUPTALK_PTT"] = 0;
        values[valuesById[1] = "CHANNEL_MEDIA_TYPE_TWILIO_FULL_DUPLEX"] = 1;
        values[valuesById[2] = "CHANNEL_MEDIA_TYPE_RESERVED_1"] = 2;
        values[valuesById[3] = "CHANNEL_MEDIA_TYPE_RESERVED_2"] = 3;
        values[valuesById[4] = "CHANNEL_MEDIA_TYPE_RESERVED_3"] = 4;
        values[valuesById[5] = "CHANNEL_MEDIA_TYPE_RESERVED_4"] = 5;
        return values;
    })();

    proto.ChannelInfo = (function() {

        /**
         * Properties of a ChannelInfo.
         * @memberof proto
         * @interface IChannelInfo
         * @property {string} channelId ChannelInfo channelId
         * @property {string} name ChannelInfo name
         * @property {string} desc ChannelInfo desc
         * @property {boolean} joined ChannelInfo joined
         * @property {boolean} scanned ChannelInfo scanned
         * @property {boolean} autojoined ChannelInfo autojoined
         * @property {string|null} [owner] ChannelInfo owner
         * @property {number|null} [quickSelectNumber] ChannelInfo quickSelectNumber
         * @property {proto.ChannelInfoAccessType|null} [joinAccessType] ChannelInfo joinAccessType
         * @property {proto.ChannelInfoAccessType|null} [scanAccessType] ChannelInfo scanAccessType
         * @property {string|null} [channelUuid] ChannelInfo channelUuid
         * @property {boolean|null} [locked] ChannelInfo locked
         * @property {proto.ChannelMediaType|null} [mediaType] ChannelInfo mediaType
         */

        /**
         * Constructs a new ChannelInfo.
         * @memberof proto
         * @classdesc Represents a ChannelInfo.
         * @implements IChannelInfo
         * @constructor
         * @param {proto.IChannelInfo=} [properties] Properties to set
         */
        function ChannelInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelInfo channelId.
         * @member {string} channelId
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.channelId = "";

        /**
         * ChannelInfo name.
         * @member {string} name
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.name = "";

        /**
         * ChannelInfo desc.
         * @member {string} desc
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.desc = "";

        /**
         * ChannelInfo joined.
         * @member {boolean} joined
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.joined = false;

        /**
         * ChannelInfo scanned.
         * @member {boolean} scanned
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.scanned = false;

        /**
         * ChannelInfo autojoined.
         * @member {boolean} autojoined
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.autojoined = false;

        /**
         * ChannelInfo owner.
         * @member {string} owner
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.owner = "";

        /**
         * ChannelInfo quickSelectNumber.
         * @member {number} quickSelectNumber
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.quickSelectNumber = 0;

        /**
         * ChannelInfo joinAccessType.
         * @member {proto.ChannelInfoAccessType} joinAccessType
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.joinAccessType = 1;

        /**
         * ChannelInfo scanAccessType.
         * @member {proto.ChannelInfoAccessType} scanAccessType
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.scanAccessType = 1;

        /**
         * ChannelInfo channelUuid.
         * @member {string} channelUuid
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.channelUuid = "";

        /**
         * ChannelInfo locked.
         * @member {boolean} locked
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.locked = false;

        /**
         * ChannelInfo mediaType.
         * @member {proto.ChannelMediaType} mediaType
         * @memberof proto.ChannelInfo
         * @instance
         */
        ChannelInfo.prototype.mediaType = 0;

        /**
         * Creates a new ChannelInfo instance using the specified properties.
         * @function create
         * @memberof proto.ChannelInfo
         * @static
         * @param {proto.IChannelInfo=} [properties] Properties to set
         * @returns {proto.ChannelInfo} ChannelInfo instance
         */
        ChannelInfo.create = function create(properties) {
            return new ChannelInfo(properties);
        };

        /**
         * Encodes the specified ChannelInfo message. Does not implicitly {@link proto.ChannelInfo.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelInfo
         * @static
         * @param {proto.IChannelInfo} message ChannelInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelId);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.desc);
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.joined);
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.scanned);
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.autojoined);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.owner);
            if (message.quickSelectNumber != null && Object.hasOwnProperty.call(message, "quickSelectNumber"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.quickSelectNumber);
            if (message.joinAccessType != null && Object.hasOwnProperty.call(message, "joinAccessType"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.joinAccessType);
            if (message.scanAccessType != null && Object.hasOwnProperty.call(message, "scanAccessType"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.scanAccessType);
            if (message.channelUuid != null && Object.hasOwnProperty.call(message, "channelUuid"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.channelUuid);
            if (message.locked != null && Object.hasOwnProperty.call(message, "locked"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.locked);
            if (message.mediaType != null && Object.hasOwnProperty.call(message, "mediaType"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.mediaType);
            return writer;
        };

        /**
         * Encodes the specified ChannelInfo message, length delimited. Does not implicitly {@link proto.ChannelInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelInfo
         * @static
         * @param {proto.IChannelInfo} message ChannelInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelInfo message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelInfo} ChannelInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.desc = reader.string();
                        break;
                    }
                case 4: {
                        message.joined = reader.bool();
                        break;
                    }
                case 5: {
                        message.scanned = reader.bool();
                        break;
                    }
                case 6: {
                        message.autojoined = reader.bool();
                        break;
                    }
                case 7: {
                        message.owner = reader.string();
                        break;
                    }
                case 8: {
                        message.quickSelectNumber = reader.int32();
                        break;
                    }
                case 9: {
                        message.joinAccessType = reader.int32();
                        break;
                    }
                case 10: {
                        message.scanAccessType = reader.int32();
                        break;
                    }
                case 11: {
                        message.channelUuid = reader.string();
                        break;
                    }
                case 12: {
                        message.locked = reader.bool();
                        break;
                    }
                case 13: {
                        message.mediaType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("channelId"))
                throw $util.ProtocolError("missing required 'channelId'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("desc"))
                throw $util.ProtocolError("missing required 'desc'", { instance: message });
            if (!message.hasOwnProperty("joined"))
                throw $util.ProtocolError("missing required 'joined'", { instance: message });
            if (!message.hasOwnProperty("scanned"))
                throw $util.ProtocolError("missing required 'scanned'", { instance: message });
            if (!message.hasOwnProperty("autojoined"))
                throw $util.ProtocolError("missing required 'autojoined'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelInfo} ChannelInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelInfo message.
         * @function verify
         * @memberof proto.ChannelInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.channelId))
                return "channelId: string expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isString(message.desc))
                return "desc: string expected";
            if (typeof message.joined !== "boolean")
                return "joined: boolean expected";
            if (typeof message.scanned !== "boolean")
                return "scanned: boolean expected";
            if (typeof message.autojoined !== "boolean")
                return "autojoined: boolean expected";
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (!$util.isString(message.owner))
                    return "owner: string expected";
            if (message.quickSelectNumber != null && message.hasOwnProperty("quickSelectNumber"))
                if (!$util.isInteger(message.quickSelectNumber))
                    return "quickSelectNumber: integer expected";
            if (message.joinAccessType != null && message.hasOwnProperty("joinAccessType"))
                switch (message.joinAccessType) {
                default:
                    return "joinAccessType: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.scanAccessType != null && message.hasOwnProperty("scanAccessType"))
                switch (message.scanAccessType) {
                default:
                    return "scanAccessType: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.channelUuid != null && message.hasOwnProperty("channelUuid"))
                if (!$util.isString(message.channelUuid))
                    return "channelUuid: string expected";
            if (message.locked != null && message.hasOwnProperty("locked"))
                if (typeof message.locked !== "boolean")
                    return "locked: boolean expected";
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                switch (message.mediaType) {
                default:
                    return "mediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a ChannelInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelInfo} ChannelInfo
         */
        ChannelInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelInfo)
                return object;
            var message = new $root.proto.ChannelInfo();
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.desc != null)
                message.desc = String(object.desc);
            if (object.joined != null)
                message.joined = Boolean(object.joined);
            if (object.scanned != null)
                message.scanned = Boolean(object.scanned);
            if (object.autojoined != null)
                message.autojoined = Boolean(object.autojoined);
            if (object.owner != null)
                message.owner = String(object.owner);
            if (object.quickSelectNumber != null)
                message.quickSelectNumber = object.quickSelectNumber | 0;
            switch (object.joinAccessType) {
            default:
                if (typeof object.joinAccessType === "number") {
                    message.joinAccessType = object.joinAccessType;
                    break;
                }
                break;
            case "NONE":
            case 1:
                message.joinAccessType = 1;
                break;
            case "MANUAL":
            case 2:
                message.joinAccessType = 2;
                break;
            case "DEFAULT":
            case 3:
                message.joinAccessType = 3;
                break;
            case "MANDATORY":
            case 4:
                message.joinAccessType = 4;
                break;
            }
            switch (object.scanAccessType) {
            default:
                if (typeof object.scanAccessType === "number") {
                    message.scanAccessType = object.scanAccessType;
                    break;
                }
                break;
            case "NONE":
            case 1:
                message.scanAccessType = 1;
                break;
            case "MANUAL":
            case 2:
                message.scanAccessType = 2;
                break;
            case "DEFAULT":
            case 3:
                message.scanAccessType = 3;
                break;
            case "MANDATORY":
            case 4:
                message.scanAccessType = 4;
                break;
            }
            if (object.channelUuid != null)
                message.channelUuid = String(object.channelUuid);
            if (object.locked != null)
                message.locked = Boolean(object.locked);
            switch (object.mediaType) {
            default:
                if (typeof object.mediaType === "number") {
                    message.mediaType = object.mediaType;
                    break;
                }
                break;
            case "CHANNEL_MEDIA_TYPE_GROUPTALK_PTT":
            case 0:
                message.mediaType = 0;
                break;
            case "CHANNEL_MEDIA_TYPE_TWILIO_FULL_DUPLEX":
            case 1:
                message.mediaType = 1;
                break;
            case "CHANNEL_MEDIA_TYPE_RESERVED_1":
            case 2:
                message.mediaType = 2;
                break;
            case "CHANNEL_MEDIA_TYPE_RESERVED_2":
            case 3:
                message.mediaType = 3;
                break;
            case "CHANNEL_MEDIA_TYPE_RESERVED_3":
            case 4:
                message.mediaType = 4;
                break;
            case "CHANNEL_MEDIA_TYPE_RESERVED_4":
            case 5:
                message.mediaType = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelInfo
         * @static
         * @param {proto.ChannelInfo} message ChannelInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.channelId = "";
                object.name = "";
                object.desc = "";
                object.joined = false;
                object.scanned = false;
                object.autojoined = false;
                object.owner = "";
                object.quickSelectNumber = 0;
                object.joinAccessType = options.enums === String ? "NONE" : 1;
                object.scanAccessType = options.enums === String ? "NONE" : 1;
                object.channelUuid = "";
                object.locked = false;
                object.mediaType = options.enums === String ? "CHANNEL_MEDIA_TYPE_GROUPTALK_PTT" : 0;
            }
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.desc != null && message.hasOwnProperty("desc"))
                object.desc = message.desc;
            if (message.joined != null && message.hasOwnProperty("joined"))
                object.joined = message.joined;
            if (message.scanned != null && message.hasOwnProperty("scanned"))
                object.scanned = message.scanned;
            if (message.autojoined != null && message.hasOwnProperty("autojoined"))
                object.autojoined = message.autojoined;
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            if (message.quickSelectNumber != null && message.hasOwnProperty("quickSelectNumber"))
                object.quickSelectNumber = message.quickSelectNumber;
            if (message.joinAccessType != null && message.hasOwnProperty("joinAccessType"))
                object.joinAccessType = options.enums === String ? $root.proto.ChannelInfoAccessType[message.joinAccessType] === undefined ? message.joinAccessType : $root.proto.ChannelInfoAccessType[message.joinAccessType] : message.joinAccessType;
            if (message.scanAccessType != null && message.hasOwnProperty("scanAccessType"))
                object.scanAccessType = options.enums === String ? $root.proto.ChannelInfoAccessType[message.scanAccessType] === undefined ? message.scanAccessType : $root.proto.ChannelInfoAccessType[message.scanAccessType] : message.scanAccessType;
            if (message.channelUuid != null && message.hasOwnProperty("channelUuid"))
                object.channelUuid = message.channelUuid;
            if (message.locked != null && message.hasOwnProperty("locked"))
                object.locked = message.locked;
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                object.mediaType = options.enums === String ? $root.proto.ChannelMediaType[message.mediaType] === undefined ? message.mediaType : $root.proto.ChannelMediaType[message.mediaType] : message.mediaType;
            return object;
        };

        /**
         * Converts this ChannelInfo to JSON.
         * @function toJSON
         * @memberof proto.ChannelInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelInfo
         * @function getTypeUrl
         * @memberof proto.ChannelInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelInfo";
        };

        return ChannelInfo;
    })();

    proto.ChannelQuickSelectRequest = (function() {

        /**
         * Properties of a ChannelQuickSelectRequest.
         * @memberof proto
         * @interface IChannelQuickSelectRequest
         * @property {number} quickSelectNumber ChannelQuickSelectRequest quickSelectNumber
         */

        /**
         * Constructs a new ChannelQuickSelectRequest.
         * @memberof proto
         * @classdesc Represents a ChannelQuickSelectRequest.
         * @implements IChannelQuickSelectRequest
         * @constructor
         * @param {proto.IChannelQuickSelectRequest=} [properties] Properties to set
         */
        function ChannelQuickSelectRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelQuickSelectRequest quickSelectNumber.
         * @member {number} quickSelectNumber
         * @memberof proto.ChannelQuickSelectRequest
         * @instance
         */
        ChannelQuickSelectRequest.prototype.quickSelectNumber = 0;

        /**
         * Creates a new ChannelQuickSelectRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {proto.IChannelQuickSelectRequest=} [properties] Properties to set
         * @returns {proto.ChannelQuickSelectRequest} ChannelQuickSelectRequest instance
         */
        ChannelQuickSelectRequest.create = function create(properties) {
            return new ChannelQuickSelectRequest(properties);
        };

        /**
         * Encodes the specified ChannelQuickSelectRequest message. Does not implicitly {@link proto.ChannelQuickSelectRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {proto.IChannelQuickSelectRequest} message ChannelQuickSelectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelQuickSelectRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.quickSelectNumber);
            return writer;
        };

        /**
         * Encodes the specified ChannelQuickSelectRequest message, length delimited. Does not implicitly {@link proto.ChannelQuickSelectRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {proto.IChannelQuickSelectRequest} message ChannelQuickSelectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelQuickSelectRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelQuickSelectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelQuickSelectRequest} ChannelQuickSelectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelQuickSelectRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelQuickSelectRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.quickSelectNumber = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("quickSelectNumber"))
                throw $util.ProtocolError("missing required 'quickSelectNumber'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelQuickSelectRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelQuickSelectRequest} ChannelQuickSelectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelQuickSelectRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelQuickSelectRequest message.
         * @function verify
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelQuickSelectRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.quickSelectNumber))
                return "quickSelectNumber: integer expected";
            return null;
        };

        /**
         * Creates a ChannelQuickSelectRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelQuickSelectRequest} ChannelQuickSelectRequest
         */
        ChannelQuickSelectRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelQuickSelectRequest)
                return object;
            var message = new $root.proto.ChannelQuickSelectRequest();
            if (object.quickSelectNumber != null)
                message.quickSelectNumber = object.quickSelectNumber | 0;
            return message;
        };

        /**
         * Creates a plain object from a ChannelQuickSelectRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {proto.ChannelQuickSelectRequest} message ChannelQuickSelectRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelQuickSelectRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.quickSelectNumber = 0;
            if (message.quickSelectNumber != null && message.hasOwnProperty("quickSelectNumber"))
                object.quickSelectNumber = message.quickSelectNumber;
            return object;
        };

        /**
         * Converts this ChannelQuickSelectRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelQuickSelectRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelQuickSelectRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelQuickSelectRequest
         * @function getTypeUrl
         * @memberof proto.ChannelQuickSelectRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelQuickSelectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelQuickSelectRequest";
        };

        return ChannelQuickSelectRequest;
    })();

    proto.ChannelQuickSelectStepRequest = (function() {

        /**
         * Properties of a ChannelQuickSelectStepRequest.
         * @memberof proto
         * @interface IChannelQuickSelectStepRequest
         * @property {number} numSteps ChannelQuickSelectStepRequest numSteps
         */

        /**
         * Constructs a new ChannelQuickSelectStepRequest.
         * @memberof proto
         * @classdesc Represents a ChannelQuickSelectStepRequest.
         * @implements IChannelQuickSelectStepRequest
         * @constructor
         * @param {proto.IChannelQuickSelectStepRequest=} [properties] Properties to set
         */
        function ChannelQuickSelectStepRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelQuickSelectStepRequest numSteps.
         * @member {number} numSteps
         * @memberof proto.ChannelQuickSelectStepRequest
         * @instance
         */
        ChannelQuickSelectStepRequest.prototype.numSteps = 0;

        /**
         * Creates a new ChannelQuickSelectStepRequest instance using the specified properties.
         * @function create
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {proto.IChannelQuickSelectStepRequest=} [properties] Properties to set
         * @returns {proto.ChannelQuickSelectStepRequest} ChannelQuickSelectStepRequest instance
         */
        ChannelQuickSelectStepRequest.create = function create(properties) {
            return new ChannelQuickSelectStepRequest(properties);
        };

        /**
         * Encodes the specified ChannelQuickSelectStepRequest message. Does not implicitly {@link proto.ChannelQuickSelectStepRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {proto.IChannelQuickSelectStepRequest} message ChannelQuickSelectStepRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelQuickSelectStepRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.numSteps);
            return writer;
        };

        /**
         * Encodes the specified ChannelQuickSelectStepRequest message, length delimited. Does not implicitly {@link proto.ChannelQuickSelectStepRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {proto.IChannelQuickSelectStepRequest} message ChannelQuickSelectStepRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelQuickSelectStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelQuickSelectStepRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ChannelQuickSelectStepRequest} ChannelQuickSelectStepRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelQuickSelectStepRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ChannelQuickSelectStepRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.numSteps = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("numSteps"))
                throw $util.ProtocolError("missing required 'numSteps'", { instance: message });
            return message;
        };

        /**
         * Decodes a ChannelQuickSelectStepRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ChannelQuickSelectStepRequest} ChannelQuickSelectStepRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelQuickSelectStepRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelQuickSelectStepRequest message.
         * @function verify
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelQuickSelectStepRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.numSteps))
                return "numSteps: integer expected";
            return null;
        };

        /**
         * Creates a ChannelQuickSelectStepRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ChannelQuickSelectStepRequest} ChannelQuickSelectStepRequest
         */
        ChannelQuickSelectStepRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ChannelQuickSelectStepRequest)
                return object;
            var message = new $root.proto.ChannelQuickSelectStepRequest();
            if (object.numSteps != null)
                message.numSteps = object.numSteps | 0;
            return message;
        };

        /**
         * Creates a plain object from a ChannelQuickSelectStepRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {proto.ChannelQuickSelectStepRequest} message ChannelQuickSelectStepRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelQuickSelectStepRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.numSteps = 0;
            if (message.numSteps != null && message.hasOwnProperty("numSteps"))
                object.numSteps = message.numSteps;
            return object;
        };

        /**
         * Converts this ChannelQuickSelectStepRequest to JSON.
         * @function toJSON
         * @memberof proto.ChannelQuickSelectStepRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelQuickSelectStepRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChannelQuickSelectStepRequest
         * @function getTypeUrl
         * @memberof proto.ChannelQuickSelectStepRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChannelQuickSelectStepRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ChannelQuickSelectStepRequest";
        };

        return ChannelQuickSelectStepRequest;
    })();

    proto.PresenceAPIv1Client = (function() {

        /**
         * Properties of a PresenceAPIv1Client.
         * @memberof proto
         * @interface IPresenceAPIv1Client
         * @property {proto.IPresenceSubscribeRequest|null} [subscribeRequest] PresenceAPIv1Client subscribeRequest
         * @property {proto.IPresenceUnsubscribeRequest|null} [unsubscribeRequest] PresenceAPIv1Client unsubscribeRequest
         */

        /**
         * Constructs a new PresenceAPIv1Client.
         * @memberof proto
         * @classdesc Represents a PresenceAPIv1Client.
         * @implements IPresenceAPIv1Client
         * @constructor
         * @param {proto.IPresenceAPIv1Client=} [properties] Properties to set
         */
        function PresenceAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceAPIv1Client subscribeRequest.
         * @member {proto.IPresenceSubscribeRequest|null|undefined} subscribeRequest
         * @memberof proto.PresenceAPIv1Client
         * @instance
         */
        PresenceAPIv1Client.prototype.subscribeRequest = null;

        /**
         * PresenceAPIv1Client unsubscribeRequest.
         * @member {proto.IPresenceUnsubscribeRequest|null|undefined} unsubscribeRequest
         * @memberof proto.PresenceAPIv1Client
         * @instance
         */
        PresenceAPIv1Client.prototype.unsubscribeRequest = null;

        /**
         * Creates a new PresenceAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {proto.IPresenceAPIv1Client=} [properties] Properties to set
         * @returns {proto.PresenceAPIv1Client} PresenceAPIv1Client instance
         */
        PresenceAPIv1Client.create = function create(properties) {
            return new PresenceAPIv1Client(properties);
        };

        /**
         * Encodes the specified PresenceAPIv1Client message. Does not implicitly {@link proto.PresenceAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {proto.IPresenceAPIv1Client} message PresenceAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subscribeRequest != null && Object.hasOwnProperty.call(message, "subscribeRequest"))
                $root.proto.PresenceSubscribeRequest.encode(message.subscribeRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.unsubscribeRequest != null && Object.hasOwnProperty.call(message, "unsubscribeRequest"))
                $root.proto.PresenceUnsubscribeRequest.encode(message.unsubscribeRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PresenceAPIv1Client message, length delimited. Does not implicitly {@link proto.PresenceAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {proto.IPresenceAPIv1Client} message PresenceAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceAPIv1Client} PresenceAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscribeRequest = $root.proto.PresenceSubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.unsubscribeRequest = $root.proto.PresenceUnsubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PresenceAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceAPIv1Client} PresenceAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceAPIv1Client message.
         * @function verify
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest")) {
                var error = $root.proto.PresenceSubscribeRequest.verify(message.subscribeRequest);
                if (error)
                    return "subscribeRequest." + error;
            }
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest")) {
                var error = $root.proto.PresenceUnsubscribeRequest.verify(message.unsubscribeRequest);
                if (error)
                    return "unsubscribeRequest." + error;
            }
            return null;
        };

        /**
         * Creates a PresenceAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceAPIv1Client} PresenceAPIv1Client
         */
        PresenceAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceAPIv1Client)
                return object;
            var message = new $root.proto.PresenceAPIv1Client();
            if (object.subscribeRequest != null) {
                if (typeof object.subscribeRequest !== "object")
                    throw TypeError(".proto.PresenceAPIv1Client.subscribeRequest: object expected");
                message.subscribeRequest = $root.proto.PresenceSubscribeRequest.fromObject(object.subscribeRequest);
            }
            if (object.unsubscribeRequest != null) {
                if (typeof object.unsubscribeRequest !== "object")
                    throw TypeError(".proto.PresenceAPIv1Client.unsubscribeRequest: object expected");
                message.unsubscribeRequest = $root.proto.PresenceUnsubscribeRequest.fromObject(object.unsubscribeRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a PresenceAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {proto.PresenceAPIv1Client} message PresenceAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.subscribeRequest = null;
                object.unsubscribeRequest = null;
            }
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest"))
                object.subscribeRequest = $root.proto.PresenceSubscribeRequest.toObject(message.subscribeRequest, options);
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest"))
                object.unsubscribeRequest = $root.proto.PresenceUnsubscribeRequest.toObject(message.unsubscribeRequest, options);
            return object;
        };

        /**
         * Converts this PresenceAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.PresenceAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceAPIv1Client
         * @function getTypeUrl
         * @memberof proto.PresenceAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceAPIv1Client";
        };

        return PresenceAPIv1Client;
    })();

    proto.PresenceSubscribeRequest = (function() {

        /**
         * Properties of a PresenceSubscribeRequest.
         * @memberof proto
         * @interface IPresenceSubscribeRequest
         */

        /**
         * Constructs a new PresenceSubscribeRequest.
         * @memberof proto
         * @classdesc Represents a PresenceSubscribeRequest.
         * @implements IPresenceSubscribeRequest
         * @constructor
         * @param {proto.IPresenceSubscribeRequest=} [properties] Properties to set
         */
        function PresenceSubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PresenceSubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {proto.IPresenceSubscribeRequest=} [properties] Properties to set
         * @returns {proto.PresenceSubscribeRequest} PresenceSubscribeRequest instance
         */
        PresenceSubscribeRequest.create = function create(properties) {
            return new PresenceSubscribeRequest(properties);
        };

        /**
         * Encodes the specified PresenceSubscribeRequest message. Does not implicitly {@link proto.PresenceSubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {proto.IPresenceSubscribeRequest} message PresenceSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceSubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PresenceSubscribeRequest message, length delimited. Does not implicitly {@link proto.PresenceSubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {proto.IPresenceSubscribeRequest} message PresenceSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceSubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceSubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceSubscribeRequest} PresenceSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceSubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceSubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PresenceSubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceSubscribeRequest} PresenceSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceSubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceSubscribeRequest message.
         * @function verify
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceSubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PresenceSubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceSubscribeRequest} PresenceSubscribeRequest
         */
        PresenceSubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceSubscribeRequest)
                return object;
            return new $root.proto.PresenceSubscribeRequest();
        };

        /**
         * Creates a plain object from a PresenceSubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {proto.PresenceSubscribeRequest} message PresenceSubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceSubscribeRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PresenceSubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.PresenceSubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceSubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceSubscribeRequest
         * @function getTypeUrl
         * @memberof proto.PresenceSubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceSubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceSubscribeRequest";
        };

        return PresenceSubscribeRequest;
    })();

    proto.PresenceSubscribeResponse = (function() {

        /**
         * Properties of a PresenceSubscribeResponse.
         * @memberof proto
         * @interface IPresenceSubscribeResponse
         * @property {number|Long} subscriptionId PresenceSubscribeResponse subscriptionId
         * @property {Array.<proto.IPresenceInfo>|null} [initialPresence] PresenceSubscribeResponse initialPresence
         * @property {Array.<proto.IStatus>|null} [status] PresenceSubscribeResponse status
         */

        /**
         * Constructs a new PresenceSubscribeResponse.
         * @memberof proto
         * @classdesc Represents a PresenceSubscribeResponse.
         * @implements IPresenceSubscribeResponse
         * @constructor
         * @param {proto.IPresenceSubscribeResponse=} [properties] Properties to set
         */
        function PresenceSubscribeResponse(properties) {
            this.initialPresence = [];
            this.status = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceSubscribeResponse subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.PresenceSubscribeResponse
         * @instance
         */
        PresenceSubscribeResponse.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PresenceSubscribeResponse initialPresence.
         * @member {Array.<proto.IPresenceInfo>} initialPresence
         * @memberof proto.PresenceSubscribeResponse
         * @instance
         */
        PresenceSubscribeResponse.prototype.initialPresence = $util.emptyArray;

        /**
         * PresenceSubscribeResponse status.
         * @member {Array.<proto.IStatus>} status
         * @memberof proto.PresenceSubscribeResponse
         * @instance
         */
        PresenceSubscribeResponse.prototype.status = $util.emptyArray;

        /**
         * Creates a new PresenceSubscribeResponse instance using the specified properties.
         * @function create
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {proto.IPresenceSubscribeResponse=} [properties] Properties to set
         * @returns {proto.PresenceSubscribeResponse} PresenceSubscribeResponse instance
         */
        PresenceSubscribeResponse.create = function create(properties) {
            return new PresenceSubscribeResponse(properties);
        };

        /**
         * Encodes the specified PresenceSubscribeResponse message. Does not implicitly {@link proto.PresenceSubscribeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {proto.IPresenceSubscribeResponse} message PresenceSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceSubscribeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.initialPresence != null && message.initialPresence.length)
                for (var i = 0; i < message.initialPresence.length; ++i)
                    $root.proto.PresenceInfo.encode(message.initialPresence[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.status != null && message.status.length)
                for (var i = 0; i < message.status.length; ++i)
                    $root.proto.Status.encode(message.status[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PresenceSubscribeResponse message, length delimited. Does not implicitly {@link proto.PresenceSubscribeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {proto.IPresenceSubscribeResponse} message PresenceSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceSubscribeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceSubscribeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceSubscribeResponse} PresenceSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceSubscribeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceSubscribeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.initialPresence && message.initialPresence.length))
                            message.initialPresence = [];
                        message.initialPresence.push($root.proto.PresenceInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.status && message.status.length))
                            message.status = [];
                        message.status.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a PresenceSubscribeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceSubscribeResponse} PresenceSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceSubscribeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceSubscribeResponse message.
         * @function verify
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceSubscribeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.initialPresence != null && message.hasOwnProperty("initialPresence")) {
                if (!Array.isArray(message.initialPresence))
                    return "initialPresence: array expected";
                for (var i = 0; i < message.initialPresence.length; ++i) {
                    var error = $root.proto.PresenceInfo.verify(message.initialPresence[i]);
                    if (error)
                        return "initialPresence." + error;
                }
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (!Array.isArray(message.status))
                    return "status: array expected";
                for (var i = 0; i < message.status.length; ++i) {
                    var error = $root.proto.Status.verify(message.status[i]);
                    if (error)
                        return "status." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PresenceSubscribeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceSubscribeResponse} PresenceSubscribeResponse
         */
        PresenceSubscribeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceSubscribeResponse)
                return object;
            var message = new $root.proto.PresenceSubscribeResponse();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.initialPresence) {
                if (!Array.isArray(object.initialPresence))
                    throw TypeError(".proto.PresenceSubscribeResponse.initialPresence: array expected");
                message.initialPresence = [];
                for (var i = 0; i < object.initialPresence.length; ++i) {
                    if (typeof object.initialPresence[i] !== "object")
                        throw TypeError(".proto.PresenceSubscribeResponse.initialPresence: object expected");
                    message.initialPresence[i] = $root.proto.PresenceInfo.fromObject(object.initialPresence[i]);
                }
            }
            if (object.status) {
                if (!Array.isArray(object.status))
                    throw TypeError(".proto.PresenceSubscribeResponse.status: array expected");
                message.status = [];
                for (var i = 0; i < object.status.length; ++i) {
                    if (typeof object.status[i] !== "object")
                        throw TypeError(".proto.PresenceSubscribeResponse.status: object expected");
                    message.status[i] = $root.proto.Status.fromObject(object.status[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PresenceSubscribeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {proto.PresenceSubscribeResponse} message PresenceSubscribeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceSubscribeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.initialPresence = [];
                object.status = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.initialPresence && message.initialPresence.length) {
                object.initialPresence = [];
                for (var j = 0; j < message.initialPresence.length; ++j)
                    object.initialPresence[j] = $root.proto.PresenceInfo.toObject(message.initialPresence[j], options);
            }
            if (message.status && message.status.length) {
                object.status = [];
                for (var j = 0; j < message.status.length; ++j)
                    object.status[j] = $root.proto.Status.toObject(message.status[j], options);
            }
            return object;
        };

        /**
         * Converts this PresenceSubscribeResponse to JSON.
         * @function toJSON
         * @memberof proto.PresenceSubscribeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceSubscribeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceSubscribeResponse
         * @function getTypeUrl
         * @memberof proto.PresenceSubscribeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceSubscribeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceSubscribeResponse";
        };

        return PresenceSubscribeResponse;
    })();

    proto.PresenceUnsubscribeRequest = (function() {

        /**
         * Properties of a PresenceUnsubscribeRequest.
         * @memberof proto
         * @interface IPresenceUnsubscribeRequest
         */

        /**
         * Constructs a new PresenceUnsubscribeRequest.
         * @memberof proto
         * @classdesc Represents a PresenceUnsubscribeRequest.
         * @implements IPresenceUnsubscribeRequest
         * @constructor
         * @param {proto.IPresenceUnsubscribeRequest=} [properties] Properties to set
         */
        function PresenceUnsubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PresenceUnsubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {proto.IPresenceUnsubscribeRequest=} [properties] Properties to set
         * @returns {proto.PresenceUnsubscribeRequest} PresenceUnsubscribeRequest instance
         */
        PresenceUnsubscribeRequest.create = function create(properties) {
            return new PresenceUnsubscribeRequest(properties);
        };

        /**
         * Encodes the specified PresenceUnsubscribeRequest message. Does not implicitly {@link proto.PresenceUnsubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {proto.IPresenceUnsubscribeRequest} message PresenceUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceUnsubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PresenceUnsubscribeRequest message, length delimited. Does not implicitly {@link proto.PresenceUnsubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {proto.IPresenceUnsubscribeRequest} message PresenceUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceUnsubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceUnsubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceUnsubscribeRequest} PresenceUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceUnsubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceUnsubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PresenceUnsubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceUnsubscribeRequest} PresenceUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceUnsubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceUnsubscribeRequest message.
         * @function verify
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceUnsubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PresenceUnsubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceUnsubscribeRequest} PresenceUnsubscribeRequest
         */
        PresenceUnsubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceUnsubscribeRequest)
                return object;
            return new $root.proto.PresenceUnsubscribeRequest();
        };

        /**
         * Creates a plain object from a PresenceUnsubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {proto.PresenceUnsubscribeRequest} message PresenceUnsubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceUnsubscribeRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PresenceUnsubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.PresenceUnsubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceUnsubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceUnsubscribeRequest
         * @function getTypeUrl
         * @memberof proto.PresenceUnsubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceUnsubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceUnsubscribeRequest";
        };

        return PresenceUnsubscribeRequest;
    })();

    proto.PresenceAPIv1Server = (function() {

        /**
         * Properties of a PresenceAPIv1Server.
         * @memberof proto
         * @interface IPresenceAPIv1Server
         * @property {proto.IPresenceDelta|null} [presenceDelta] PresenceAPIv1Server presenceDelta
         */

        /**
         * Constructs a new PresenceAPIv1Server.
         * @memberof proto
         * @classdesc Represents a PresenceAPIv1Server.
         * @implements IPresenceAPIv1Server
         * @constructor
         * @param {proto.IPresenceAPIv1Server=} [properties] Properties to set
         */
        function PresenceAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceAPIv1Server presenceDelta.
         * @member {proto.IPresenceDelta|null|undefined} presenceDelta
         * @memberof proto.PresenceAPIv1Server
         * @instance
         */
        PresenceAPIv1Server.prototype.presenceDelta = null;

        /**
         * Creates a new PresenceAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {proto.IPresenceAPIv1Server=} [properties] Properties to set
         * @returns {proto.PresenceAPIv1Server} PresenceAPIv1Server instance
         */
        PresenceAPIv1Server.create = function create(properties) {
            return new PresenceAPIv1Server(properties);
        };

        /**
         * Encodes the specified PresenceAPIv1Server message. Does not implicitly {@link proto.PresenceAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {proto.IPresenceAPIv1Server} message PresenceAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.presenceDelta != null && Object.hasOwnProperty.call(message, "presenceDelta"))
                $root.proto.PresenceDelta.encode(message.presenceDelta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PresenceAPIv1Server message, length delimited. Does not implicitly {@link proto.PresenceAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {proto.IPresenceAPIv1Server} message PresenceAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceAPIv1Server} PresenceAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.presenceDelta = $root.proto.PresenceDelta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PresenceAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceAPIv1Server} PresenceAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceAPIv1Server message.
         * @function verify
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.presenceDelta != null && message.hasOwnProperty("presenceDelta")) {
                var error = $root.proto.PresenceDelta.verify(message.presenceDelta);
                if (error)
                    return "presenceDelta." + error;
            }
            return null;
        };

        /**
         * Creates a PresenceAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceAPIv1Server} PresenceAPIv1Server
         */
        PresenceAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceAPIv1Server)
                return object;
            var message = new $root.proto.PresenceAPIv1Server();
            if (object.presenceDelta != null) {
                if (typeof object.presenceDelta !== "object")
                    throw TypeError(".proto.PresenceAPIv1Server.presenceDelta: object expected");
                message.presenceDelta = $root.proto.PresenceDelta.fromObject(object.presenceDelta);
            }
            return message;
        };

        /**
         * Creates a plain object from a PresenceAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {proto.PresenceAPIv1Server} message PresenceAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.presenceDelta = null;
            if (message.presenceDelta != null && message.hasOwnProperty("presenceDelta"))
                object.presenceDelta = $root.proto.PresenceDelta.toObject(message.presenceDelta, options);
            return object;
        };

        /**
         * Converts this PresenceAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.PresenceAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceAPIv1Server
         * @function getTypeUrl
         * @memberof proto.PresenceAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceAPIv1Server";
        };

        return PresenceAPIv1Server;
    })();

    proto.PresenceDelta = (function() {

        /**
         * Properties of a PresenceDelta.
         * @memberof proto
         * @interface IPresenceDelta
         * @property {number|Long} subscriptionId PresenceDelta subscriptionId
         * @property {Array.<proto.IPresenceInfo>|null} [updated] PresenceDelta updated
         * @property {Array.<number|Long>|null} [deletedPresenceIds] PresenceDelta deletedPresenceIds
         * @property {Array.<proto.IStatus>|null} [status] PresenceDelta status
         */

        /**
         * Constructs a new PresenceDelta.
         * @memberof proto
         * @classdesc Represents a PresenceDelta.
         * @implements IPresenceDelta
         * @constructor
         * @param {proto.IPresenceDelta=} [properties] Properties to set
         */
        function PresenceDelta(properties) {
            this.updated = [];
            this.deletedPresenceIds = [];
            this.status = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceDelta subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.PresenceDelta
         * @instance
         */
        PresenceDelta.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PresenceDelta updated.
         * @member {Array.<proto.IPresenceInfo>} updated
         * @memberof proto.PresenceDelta
         * @instance
         */
        PresenceDelta.prototype.updated = $util.emptyArray;

        /**
         * PresenceDelta deletedPresenceIds.
         * @member {Array.<number|Long>} deletedPresenceIds
         * @memberof proto.PresenceDelta
         * @instance
         */
        PresenceDelta.prototype.deletedPresenceIds = $util.emptyArray;

        /**
         * PresenceDelta status.
         * @member {Array.<proto.IStatus>} status
         * @memberof proto.PresenceDelta
         * @instance
         */
        PresenceDelta.prototype.status = $util.emptyArray;

        /**
         * Creates a new PresenceDelta instance using the specified properties.
         * @function create
         * @memberof proto.PresenceDelta
         * @static
         * @param {proto.IPresenceDelta=} [properties] Properties to set
         * @returns {proto.PresenceDelta} PresenceDelta instance
         */
        PresenceDelta.create = function create(properties) {
            return new PresenceDelta(properties);
        };

        /**
         * Encodes the specified PresenceDelta message. Does not implicitly {@link proto.PresenceDelta.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceDelta
         * @static
         * @param {proto.IPresenceDelta} message PresenceDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceDelta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.updated != null && message.updated.length)
                for (var i = 0; i < message.updated.length; ++i)
                    $root.proto.PresenceInfo.encode(message.updated[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.deletedPresenceIds != null && message.deletedPresenceIds.length)
                for (var i = 0; i < message.deletedPresenceIds.length; ++i)
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.deletedPresenceIds[i]);
            if (message.status != null && message.status.length)
                for (var i = 0; i < message.status.length; ++i)
                    $root.proto.Status.encode(message.status[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PresenceDelta message, length delimited. Does not implicitly {@link proto.PresenceDelta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceDelta
         * @static
         * @param {proto.IPresenceDelta} message PresenceDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceDelta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceDelta message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceDelta} PresenceDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceDelta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceDelta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.updated && message.updated.length))
                            message.updated = [];
                        message.updated.push($root.proto.PresenceInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.deletedPresenceIds && message.deletedPresenceIds.length))
                            message.deletedPresenceIds = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.deletedPresenceIds.push(reader.int64());
                        } else
                            message.deletedPresenceIds.push(reader.int64());
                        break;
                    }
                case 4: {
                        if (!(message.status && message.status.length))
                            message.status = [];
                        message.status.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a PresenceDelta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceDelta} PresenceDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceDelta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceDelta message.
         * @function verify
         * @memberof proto.PresenceDelta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceDelta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.updated != null && message.hasOwnProperty("updated")) {
                if (!Array.isArray(message.updated))
                    return "updated: array expected";
                for (var i = 0; i < message.updated.length; ++i) {
                    var error = $root.proto.PresenceInfo.verify(message.updated[i]);
                    if (error)
                        return "updated." + error;
                }
            }
            if (message.deletedPresenceIds != null && message.hasOwnProperty("deletedPresenceIds")) {
                if (!Array.isArray(message.deletedPresenceIds))
                    return "deletedPresenceIds: array expected";
                for (var i = 0; i < message.deletedPresenceIds.length; ++i)
                    if (!$util.isInteger(message.deletedPresenceIds[i]) && !(message.deletedPresenceIds[i] && $util.isInteger(message.deletedPresenceIds[i].low) && $util.isInteger(message.deletedPresenceIds[i].high)))
                        return "deletedPresenceIds: integer|Long[] expected";
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (!Array.isArray(message.status))
                    return "status: array expected";
                for (var i = 0; i < message.status.length; ++i) {
                    var error = $root.proto.Status.verify(message.status[i]);
                    if (error)
                        return "status." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PresenceDelta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceDelta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceDelta} PresenceDelta
         */
        PresenceDelta.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceDelta)
                return object;
            var message = new $root.proto.PresenceDelta();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.updated) {
                if (!Array.isArray(object.updated))
                    throw TypeError(".proto.PresenceDelta.updated: array expected");
                message.updated = [];
                for (var i = 0; i < object.updated.length; ++i) {
                    if (typeof object.updated[i] !== "object")
                        throw TypeError(".proto.PresenceDelta.updated: object expected");
                    message.updated[i] = $root.proto.PresenceInfo.fromObject(object.updated[i]);
                }
            }
            if (object.deletedPresenceIds) {
                if (!Array.isArray(object.deletedPresenceIds))
                    throw TypeError(".proto.PresenceDelta.deletedPresenceIds: array expected");
                message.deletedPresenceIds = [];
                for (var i = 0; i < object.deletedPresenceIds.length; ++i)
                    if ($util.Long)
                        (message.deletedPresenceIds[i] = $util.Long.fromValue(object.deletedPresenceIds[i])).unsigned = false;
                    else if (typeof object.deletedPresenceIds[i] === "string")
                        message.deletedPresenceIds[i] = parseInt(object.deletedPresenceIds[i], 10);
                    else if (typeof object.deletedPresenceIds[i] === "number")
                        message.deletedPresenceIds[i] = object.deletedPresenceIds[i];
                    else if (typeof object.deletedPresenceIds[i] === "object")
                        message.deletedPresenceIds[i] = new $util.LongBits(object.deletedPresenceIds[i].low >>> 0, object.deletedPresenceIds[i].high >>> 0).toNumber();
            }
            if (object.status) {
                if (!Array.isArray(object.status))
                    throw TypeError(".proto.PresenceDelta.status: array expected");
                message.status = [];
                for (var i = 0; i < object.status.length; ++i) {
                    if (typeof object.status[i] !== "object")
                        throw TypeError(".proto.PresenceDelta.status: object expected");
                    message.status[i] = $root.proto.Status.fromObject(object.status[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PresenceDelta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceDelta
         * @static
         * @param {proto.PresenceDelta} message PresenceDelta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceDelta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.updated = [];
                object.deletedPresenceIds = [];
                object.status = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.updated && message.updated.length) {
                object.updated = [];
                for (var j = 0; j < message.updated.length; ++j)
                    object.updated[j] = $root.proto.PresenceInfo.toObject(message.updated[j], options);
            }
            if (message.deletedPresenceIds && message.deletedPresenceIds.length) {
                object.deletedPresenceIds = [];
                for (var j = 0; j < message.deletedPresenceIds.length; ++j)
                    if (typeof message.deletedPresenceIds[j] === "number")
                        object.deletedPresenceIds[j] = options.longs === String ? String(message.deletedPresenceIds[j]) : message.deletedPresenceIds[j];
                    else
                        object.deletedPresenceIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.deletedPresenceIds[j]) : options.longs === Number ? new $util.LongBits(message.deletedPresenceIds[j].low >>> 0, message.deletedPresenceIds[j].high >>> 0).toNumber() : message.deletedPresenceIds[j];
            }
            if (message.status && message.status.length) {
                object.status = [];
                for (var j = 0; j < message.status.length; ++j)
                    object.status[j] = $root.proto.Status.toObject(message.status[j], options);
            }
            return object;
        };

        /**
         * Converts this PresenceDelta to JSON.
         * @function toJSON
         * @memberof proto.PresenceDelta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceDelta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceDelta
         * @function getTypeUrl
         * @memberof proto.PresenceDelta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceDelta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceDelta";
        };

        return PresenceDelta;
    })();

    /**
     * PresenceType enum.
     * @name proto.PresenceType
     * @enum {number}
     * @property {number} UNKNOWN=1 UNKNOWN value
     * @property {number} MOBILE=2 MOBILE value
     * @property {number} DISPATCHER=3 DISPATCHER value
     * @property {number} DIALED=4 DIALED value
     * @property {number} RADIO=5 RADIO value
     * @property {number} PATCHED_GROUP=6 PATCHED_GROUP value
     */
    proto.PresenceType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "UNKNOWN"] = 1;
        values[valuesById[2] = "MOBILE"] = 2;
        values[valuesById[3] = "DISPATCHER"] = 3;
        values[valuesById[4] = "DIALED"] = 4;
        values[valuesById[5] = "RADIO"] = 5;
        values[valuesById[6] = "PATCHED_GROUP"] = 6;
        return values;
    })();

    proto.PresenceInfo = (function() {

        /**
         * Properties of a PresenceInfo.
         * @memberof proto
         * @interface IPresenceInfo
         * @property {number|Long} sessionId PresenceInfo sessionId
         * @property {number|Long} presenceId PresenceInfo presenceId
         * @property {proto.PresenceType} limitedType PresenceInfo limitedType
         * @property {string} name PresenceInfo name
         * @property {string} title PresenceInfo title
         * @property {boolean} unavailable PresenceInfo unavailable
         * @property {string|null} [callableReference] PresenceInfo callableReference
         * @property {string|null} [phoneBookEntryId] PresenceInfo phoneBookEntryId
         * @property {string|null} [sourceId] PresenceInfo sourceId
         * @property {string|null} [userEntityId] PresenceInfo userEntityId
         * @property {boolean|null} [alarmActive] PresenceInfo alarmActive
         * @property {number|null} [statusIndex] PresenceInfo statusIndex
         * @property {proto.PresenceType|null} [type] PresenceInfo type
         */

        /**
         * Constructs a new PresenceInfo.
         * @memberof proto
         * @classdesc Represents a PresenceInfo.
         * @implements IPresenceInfo
         * @constructor
         * @param {proto.IPresenceInfo=} [properties] Properties to set
         */
        function PresenceInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceInfo sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PresenceInfo presenceId.
         * @member {number|Long} presenceId
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.presenceId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PresenceInfo limitedType.
         * @member {proto.PresenceType} limitedType
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.limitedType = 1;

        /**
         * PresenceInfo name.
         * @member {string} name
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.name = "";

        /**
         * PresenceInfo title.
         * @member {string} title
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.title = "";

        /**
         * PresenceInfo unavailable.
         * @member {boolean} unavailable
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.unavailable = false;

        /**
         * PresenceInfo callableReference.
         * @member {string} callableReference
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.callableReference = "";

        /**
         * PresenceInfo phoneBookEntryId.
         * @member {string} phoneBookEntryId
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.phoneBookEntryId = "";

        /**
         * PresenceInfo sourceId.
         * @member {string} sourceId
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.sourceId = "";

        /**
         * PresenceInfo userEntityId.
         * @member {string} userEntityId
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.userEntityId = "";

        /**
         * PresenceInfo alarmActive.
         * @member {boolean} alarmActive
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.alarmActive = false;

        /**
         * PresenceInfo statusIndex.
         * @member {number} statusIndex
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.statusIndex = 0;

        /**
         * PresenceInfo type.
         * @member {proto.PresenceType} type
         * @memberof proto.PresenceInfo
         * @instance
         */
        PresenceInfo.prototype.type = 1;

        /**
         * Creates a new PresenceInfo instance using the specified properties.
         * @function create
         * @memberof proto.PresenceInfo
         * @static
         * @param {proto.IPresenceInfo=} [properties] Properties to set
         * @returns {proto.PresenceInfo} PresenceInfo instance
         */
        PresenceInfo.create = function create(properties) {
            return new PresenceInfo(properties);
        };

        /**
         * Encodes the specified PresenceInfo message. Does not implicitly {@link proto.PresenceInfo.verify|verify} messages.
         * @function encode
         * @memberof proto.PresenceInfo
         * @static
         * @param {proto.IPresenceInfo} message PresenceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.sessionId);
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.presenceId);
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limitedType);
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.title);
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.unavailable);
            if (message.callableReference != null && Object.hasOwnProperty.call(message, "callableReference"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.callableReference);
            if (message.phoneBookEntryId != null && Object.hasOwnProperty.call(message, "phoneBookEntryId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.phoneBookEntryId);
            if (message.sourceId != null && Object.hasOwnProperty.call(message, "sourceId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.sourceId);
            if (message.userEntityId != null && Object.hasOwnProperty.call(message, "userEntityId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.userEntityId);
            if (message.alarmActive != null && Object.hasOwnProperty.call(message, "alarmActive"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.alarmActive);
            if (message.statusIndex != null && Object.hasOwnProperty.call(message, "statusIndex"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.statusIndex);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.type);
            return writer;
        };

        /**
         * Encodes the specified PresenceInfo message, length delimited. Does not implicitly {@link proto.PresenceInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PresenceInfo
         * @static
         * @param {proto.IPresenceInfo} message PresenceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresenceInfo message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PresenceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PresenceInfo} PresenceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PresenceInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.presenceId = reader.int64();
                        break;
                    }
                case 3: {
                        message.limitedType = reader.int32();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.title = reader.string();
                        break;
                    }
                case 6: {
                        message.unavailable = reader.bool();
                        break;
                    }
                case 7: {
                        message.callableReference = reader.string();
                        break;
                    }
                case 8: {
                        message.phoneBookEntryId = reader.string();
                        break;
                    }
                case 9: {
                        message.sourceId = reader.string();
                        break;
                    }
                case 10: {
                        message.userEntityId = reader.string();
                        break;
                    }
                case 11: {
                        message.alarmActive = reader.bool();
                        break;
                    }
                case 12: {
                        message.statusIndex = reader.int32();
                        break;
                    }
                case 13: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            if (!message.hasOwnProperty("presenceId"))
                throw $util.ProtocolError("missing required 'presenceId'", { instance: message });
            if (!message.hasOwnProperty("limitedType"))
                throw $util.ProtocolError("missing required 'limitedType'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("title"))
                throw $util.ProtocolError("missing required 'title'", { instance: message });
            if (!message.hasOwnProperty("unavailable"))
                throw $util.ProtocolError("missing required 'unavailable'", { instance: message });
            return message;
        };

        /**
         * Decodes a PresenceInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PresenceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PresenceInfo} PresenceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresenceInfo message.
         * @function verify
         * @memberof proto.PresenceInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresenceInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (!$util.isInteger(message.presenceId) && !(message.presenceId && $util.isInteger(message.presenceId.low) && $util.isInteger(message.presenceId.high)))
                return "presenceId: integer|Long expected";
            switch (message.limitedType) {
            default:
                return "limitedType: enum value expected";
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isString(message.title))
                return "title: string expected";
            if (typeof message.unavailable !== "boolean")
                return "unavailable: boolean expected";
            if (message.callableReference != null && message.hasOwnProperty("callableReference"))
                if (!$util.isString(message.callableReference))
                    return "callableReference: string expected";
            if (message.phoneBookEntryId != null && message.hasOwnProperty("phoneBookEntryId"))
                if (!$util.isString(message.phoneBookEntryId))
                    return "phoneBookEntryId: string expected";
            if (message.sourceId != null && message.hasOwnProperty("sourceId"))
                if (!$util.isString(message.sourceId))
                    return "sourceId: string expected";
            if (message.userEntityId != null && message.hasOwnProperty("userEntityId"))
                if (!$util.isString(message.userEntityId))
                    return "userEntityId: string expected";
            if (message.alarmActive != null && message.hasOwnProperty("alarmActive"))
                if (typeof message.alarmActive !== "boolean")
                    return "alarmActive: boolean expected";
            if (message.statusIndex != null && message.hasOwnProperty("statusIndex"))
                if (!$util.isInteger(message.statusIndex))
                    return "statusIndex: integer expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            return null;
        };

        /**
         * Creates a PresenceInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PresenceInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PresenceInfo} PresenceInfo
         */
        PresenceInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PresenceInfo)
                return object;
            var message = new $root.proto.PresenceInfo();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.presenceId != null)
                if ($util.Long)
                    (message.presenceId = $util.Long.fromValue(object.presenceId)).unsigned = false;
                else if (typeof object.presenceId === "string")
                    message.presenceId = parseInt(object.presenceId, 10);
                else if (typeof object.presenceId === "number")
                    message.presenceId = object.presenceId;
                else if (typeof object.presenceId === "object")
                    message.presenceId = new $util.LongBits(object.presenceId.low >>> 0, object.presenceId.high >>> 0).toNumber();
            switch (object.limitedType) {
            default:
                if (typeof object.limitedType === "number") {
                    message.limitedType = object.limitedType;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.limitedType = 1;
                break;
            case "MOBILE":
            case 2:
                message.limitedType = 2;
                break;
            case "DISPATCHER":
            case 3:
                message.limitedType = 3;
                break;
            case "DIALED":
            case 4:
                message.limitedType = 4;
                break;
            case "RADIO":
            case 5:
                message.limitedType = 5;
                break;
            case "PATCHED_GROUP":
            case 6:
                message.limitedType = 6;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.title != null)
                message.title = String(object.title);
            if (object.unavailable != null)
                message.unavailable = Boolean(object.unavailable);
            if (object.callableReference != null)
                message.callableReference = String(object.callableReference);
            if (object.phoneBookEntryId != null)
                message.phoneBookEntryId = String(object.phoneBookEntryId);
            if (object.sourceId != null)
                message.sourceId = String(object.sourceId);
            if (object.userEntityId != null)
                message.userEntityId = String(object.userEntityId);
            if (object.alarmActive != null)
                message.alarmActive = Boolean(object.alarmActive);
            if (object.statusIndex != null)
                message.statusIndex = object.statusIndex | 0;
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.type = 1;
                break;
            case "MOBILE":
            case 2:
                message.type = 2;
                break;
            case "DISPATCHER":
            case 3:
                message.type = 3;
                break;
            case "DIALED":
            case 4:
                message.type = 4;
                break;
            case "RADIO":
            case 5:
                message.type = 5;
                break;
            case "PATCHED_GROUP":
            case 6:
                message.type = 6;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PresenceInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PresenceInfo
         * @static
         * @param {proto.PresenceInfo} message PresenceInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresenceInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.presenceId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.presenceId = options.longs === String ? "0" : 0;
                object.limitedType = options.enums === String ? "UNKNOWN" : 1;
                object.name = "";
                object.title = "";
                object.unavailable = false;
                object.callableReference = "";
                object.phoneBookEntryId = "";
                object.sourceId = "";
                object.userEntityId = "";
                object.alarmActive = false;
                object.statusIndex = 0;
                object.type = options.enums === String ? "UNKNOWN" : 1;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.presenceId != null && message.hasOwnProperty("presenceId"))
                if (typeof message.presenceId === "number")
                    object.presenceId = options.longs === String ? String(message.presenceId) : message.presenceId;
                else
                    object.presenceId = options.longs === String ? $util.Long.prototype.toString.call(message.presenceId) : options.longs === Number ? new $util.LongBits(message.presenceId.low >>> 0, message.presenceId.high >>> 0).toNumber() : message.presenceId;
            if (message.limitedType != null && message.hasOwnProperty("limitedType"))
                object.limitedType = options.enums === String ? $root.proto.PresenceType[message.limitedType] === undefined ? message.limitedType : $root.proto.PresenceType[message.limitedType] : message.limitedType;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.unavailable != null && message.hasOwnProperty("unavailable"))
                object.unavailable = message.unavailable;
            if (message.callableReference != null && message.hasOwnProperty("callableReference"))
                object.callableReference = message.callableReference;
            if (message.phoneBookEntryId != null && message.hasOwnProperty("phoneBookEntryId"))
                object.phoneBookEntryId = message.phoneBookEntryId;
            if (message.sourceId != null && message.hasOwnProperty("sourceId"))
                object.sourceId = message.sourceId;
            if (message.userEntityId != null && message.hasOwnProperty("userEntityId"))
                object.userEntityId = message.userEntityId;
            if (message.alarmActive != null && message.hasOwnProperty("alarmActive"))
                object.alarmActive = message.alarmActive;
            if (message.statusIndex != null && message.hasOwnProperty("statusIndex"))
                object.statusIndex = message.statusIndex;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.PresenceType[message.type] === undefined ? message.type : $root.proto.PresenceType[message.type] : message.type;
            return object;
        };

        /**
         * Converts this PresenceInfo to JSON.
         * @function toJSON
         * @memberof proto.PresenceInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresenceInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PresenceInfo
         * @function getTypeUrl
         * @memberof proto.PresenceInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PresenceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PresenceInfo";
        };

        return PresenceInfo;
    })();

    proto.UDPAPIv1Client = (function() {

        /**
         * Properties of a UDPAPIv1Client.
         * @memberof proto
         * @interface IUDPAPIv1Client
         * @property {proto.IUDPModuleSetupRequest|null} [setupRequest] UDPAPIv1Client setupRequest
         */

        /**
         * Constructs a new UDPAPIv1Client.
         * @memberof proto
         * @classdesc Represents a UDPAPIv1Client.
         * @implements IUDPAPIv1Client
         * @constructor
         * @param {proto.IUDPAPIv1Client=} [properties] Properties to set
         */
        function UDPAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDPAPIv1Client setupRequest.
         * @member {proto.IUDPModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.UDPAPIv1Client
         * @instance
         */
        UDPAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new UDPAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {proto.IUDPAPIv1Client=} [properties] Properties to set
         * @returns {proto.UDPAPIv1Client} UDPAPIv1Client instance
         */
        UDPAPIv1Client.create = function create(properties) {
            return new UDPAPIv1Client(properties);
        };

        /**
         * Encodes the specified UDPAPIv1Client message. Does not implicitly {@link proto.UDPAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {proto.IUDPAPIv1Client} message UDPAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.UDPModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDPAPIv1Client message, length delimited. Does not implicitly {@link proto.UDPAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {proto.IUDPAPIv1Client} message UDPAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPAPIv1Client} UDPAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.UDPModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDPAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPAPIv1Client} UDPAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPAPIv1Client message.
         * @function verify
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.UDPModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a UDPAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPAPIv1Client} UDPAPIv1Client
         */
        UDPAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPAPIv1Client)
                return object;
            var message = new $root.proto.UDPAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.UDPAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.UDPModuleSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a UDPAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {proto.UDPAPIv1Client} message UDPAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.UDPModuleSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this UDPAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.UDPAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPAPIv1Client
         * @function getTypeUrl
         * @memberof proto.UDPAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPAPIv1Client";
        };

        return UDPAPIv1Client;
    })();

    proto.UDPModuleSetupRequest = (function() {

        /**
         * Properties of a UDPModuleSetupRequest.
         * @memberof proto
         * @interface IUDPModuleSetupRequest
         * @property {proto.IUDPEncryptionParameters|null} [encryptionParameters] UDPModuleSetupRequest encryptionParameters
         */

        /**
         * Constructs a new UDPModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a UDPModuleSetupRequest.
         * @implements IUDPModuleSetupRequest
         * @constructor
         * @param {proto.IUDPModuleSetupRequest=} [properties] Properties to set
         */
        function UDPModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDPModuleSetupRequest encryptionParameters.
         * @member {proto.IUDPEncryptionParameters|null|undefined} encryptionParameters
         * @memberof proto.UDPModuleSetupRequest
         * @instance
         */
        UDPModuleSetupRequest.prototype.encryptionParameters = null;

        /**
         * Creates a new UDPModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {proto.IUDPModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.UDPModuleSetupRequest} UDPModuleSetupRequest instance
         */
        UDPModuleSetupRequest.create = function create(properties) {
            return new UDPModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified UDPModuleSetupRequest message. Does not implicitly {@link proto.UDPModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {proto.IUDPModuleSetupRequest} message UDPModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.encryptionParameters != null && Object.hasOwnProperty.call(message, "encryptionParameters"))
                $root.proto.UDPEncryptionParameters.encode(message.encryptionParameters, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDPModuleSetupRequest message, length delimited. Does not implicitly {@link proto.UDPModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {proto.IUDPModuleSetupRequest} message UDPModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPModuleSetupRequest} UDPModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.encryptionParameters = $root.proto.UDPEncryptionParameters.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDPModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPModuleSetupRequest} UDPModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPModuleSetupRequest message.
         * @function verify
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.encryptionParameters != null && message.hasOwnProperty("encryptionParameters")) {
                var error = $root.proto.UDPEncryptionParameters.verify(message.encryptionParameters);
                if (error)
                    return "encryptionParameters." + error;
            }
            return null;
        };

        /**
         * Creates a UDPModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPModuleSetupRequest} UDPModuleSetupRequest
         */
        UDPModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPModuleSetupRequest)
                return object;
            var message = new $root.proto.UDPModuleSetupRequest();
            if (object.encryptionParameters != null) {
                if (typeof object.encryptionParameters !== "object")
                    throw TypeError(".proto.UDPModuleSetupRequest.encryptionParameters: object expected");
                message.encryptionParameters = $root.proto.UDPEncryptionParameters.fromObject(object.encryptionParameters);
            }
            return message;
        };

        /**
         * Creates a plain object from a UDPModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {proto.UDPModuleSetupRequest} message UDPModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPModuleSetupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.encryptionParameters = null;
            if (message.encryptionParameters != null && message.hasOwnProperty("encryptionParameters"))
                object.encryptionParameters = $root.proto.UDPEncryptionParameters.toObject(message.encryptionParameters, options);
            return object;
        };

        /**
         * Converts this UDPModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.UDPModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.UDPModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPModuleSetupRequest";
        };

        return UDPModuleSetupRequest;
    })();

    proto.UDPModuleSetupResponse = (function() {

        /**
         * Properties of a UDPModuleSetupResponse.
         * @memberof proto
         * @interface IUDPModuleSetupResponse
         * @property {number|Long} udpId UDPModuleSetupResponse udpId
         * @property {proto.IUDPEncryptionParameters|null} [encryptionParameters] UDPModuleSetupResponse encryptionParameters
         */

        /**
         * Constructs a new UDPModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a UDPModuleSetupResponse.
         * @implements IUDPModuleSetupResponse
         * @constructor
         * @param {proto.IUDPModuleSetupResponse=} [properties] Properties to set
         */
        function UDPModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDPModuleSetupResponse udpId.
         * @member {number|Long} udpId
         * @memberof proto.UDPModuleSetupResponse
         * @instance
         */
        UDPModuleSetupResponse.prototype.udpId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UDPModuleSetupResponse encryptionParameters.
         * @member {proto.IUDPEncryptionParameters|null|undefined} encryptionParameters
         * @memberof proto.UDPModuleSetupResponse
         * @instance
         */
        UDPModuleSetupResponse.prototype.encryptionParameters = null;

        /**
         * Creates a new UDPModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {proto.IUDPModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.UDPModuleSetupResponse} UDPModuleSetupResponse instance
         */
        UDPModuleSetupResponse.create = function create(properties) {
            return new UDPModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified UDPModuleSetupResponse message. Does not implicitly {@link proto.UDPModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {proto.IUDPModuleSetupResponse} message UDPModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.udpId);
            if (message.encryptionParameters != null && Object.hasOwnProperty.call(message, "encryptionParameters"))
                $root.proto.UDPEncryptionParameters.encode(message.encryptionParameters, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDPModuleSetupResponse message, length delimited. Does not implicitly {@link proto.UDPModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {proto.IUDPModuleSetupResponse} message UDPModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPModuleSetupResponse} UDPModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.udpId = reader.int64();
                        break;
                    }
                case 2: {
                        message.encryptionParameters = $root.proto.UDPEncryptionParameters.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("udpId"))
                throw $util.ProtocolError("missing required 'udpId'", { instance: message });
            return message;
        };

        /**
         * Decodes a UDPModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPModuleSetupResponse} UDPModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPModuleSetupResponse message.
         * @function verify
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.udpId) && !(message.udpId && $util.isInteger(message.udpId.low) && $util.isInteger(message.udpId.high)))
                return "udpId: integer|Long expected";
            if (message.encryptionParameters != null && message.hasOwnProperty("encryptionParameters")) {
                var error = $root.proto.UDPEncryptionParameters.verify(message.encryptionParameters);
                if (error)
                    return "encryptionParameters." + error;
            }
            return null;
        };

        /**
         * Creates a UDPModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPModuleSetupResponse} UDPModuleSetupResponse
         */
        UDPModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPModuleSetupResponse)
                return object;
            var message = new $root.proto.UDPModuleSetupResponse();
            if (object.udpId != null)
                if ($util.Long)
                    (message.udpId = $util.Long.fromValue(object.udpId)).unsigned = false;
                else if (typeof object.udpId === "string")
                    message.udpId = parseInt(object.udpId, 10);
                else if (typeof object.udpId === "number")
                    message.udpId = object.udpId;
                else if (typeof object.udpId === "object")
                    message.udpId = new $util.LongBits(object.udpId.low >>> 0, object.udpId.high >>> 0).toNumber();
            if (object.encryptionParameters != null) {
                if (typeof object.encryptionParameters !== "object")
                    throw TypeError(".proto.UDPModuleSetupResponse.encryptionParameters: object expected");
                message.encryptionParameters = $root.proto.UDPEncryptionParameters.fromObject(object.encryptionParameters);
            }
            return message;
        };

        /**
         * Creates a plain object from a UDPModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {proto.UDPModuleSetupResponse} message UDPModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.udpId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.udpId = options.longs === String ? "0" : 0;
                object.encryptionParameters = null;
            }
            if (message.udpId != null && message.hasOwnProperty("udpId"))
                if (typeof message.udpId === "number")
                    object.udpId = options.longs === String ? String(message.udpId) : message.udpId;
                else
                    object.udpId = options.longs === String ? $util.Long.prototype.toString.call(message.udpId) : options.longs === Number ? new $util.LongBits(message.udpId.low >>> 0, message.udpId.high >>> 0).toNumber() : message.udpId;
            if (message.encryptionParameters != null && message.hasOwnProperty("encryptionParameters"))
                object.encryptionParameters = $root.proto.UDPEncryptionParameters.toObject(message.encryptionParameters, options);
            return object;
        };

        /**
         * Converts this UDPModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.UDPModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.UDPModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPModuleSetupResponse";
        };

        return UDPModuleSetupResponse;
    })();

    proto.UDPEncryptionParameters = (function() {

        /**
         * Properties of a UDPEncryptionParameters.
         * @memberof proto
         * @interface IUDPEncryptionParameters
         * @property {Uint8Array} ivNonce UDPEncryptionParameters ivNonce
         * @property {Uint8Array} secretKey UDPEncryptionParameters secretKey
         */

        /**
         * Constructs a new UDPEncryptionParameters.
         * @memberof proto
         * @classdesc Represents a UDPEncryptionParameters.
         * @implements IUDPEncryptionParameters
         * @constructor
         * @param {proto.IUDPEncryptionParameters=} [properties] Properties to set
         */
        function UDPEncryptionParameters(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDPEncryptionParameters ivNonce.
         * @member {Uint8Array} ivNonce
         * @memberof proto.UDPEncryptionParameters
         * @instance
         */
        UDPEncryptionParameters.prototype.ivNonce = $util.newBuffer([]);

        /**
         * UDPEncryptionParameters secretKey.
         * @member {Uint8Array} secretKey
         * @memberof proto.UDPEncryptionParameters
         * @instance
         */
        UDPEncryptionParameters.prototype.secretKey = $util.newBuffer([]);

        /**
         * Creates a new UDPEncryptionParameters instance using the specified properties.
         * @function create
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {proto.IUDPEncryptionParameters=} [properties] Properties to set
         * @returns {proto.UDPEncryptionParameters} UDPEncryptionParameters instance
         */
        UDPEncryptionParameters.create = function create(properties) {
            return new UDPEncryptionParameters(properties);
        };

        /**
         * Encodes the specified UDPEncryptionParameters message. Does not implicitly {@link proto.UDPEncryptionParameters.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {proto.IUDPEncryptionParameters} message UDPEncryptionParameters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPEncryptionParameters.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.ivNonce);
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.secretKey);
            return writer;
        };

        /**
         * Encodes the specified UDPEncryptionParameters message, length delimited. Does not implicitly {@link proto.UDPEncryptionParameters.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {proto.IUDPEncryptionParameters} message UDPEncryptionParameters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPEncryptionParameters.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPEncryptionParameters message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPEncryptionParameters} UDPEncryptionParameters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPEncryptionParameters.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPEncryptionParameters();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ivNonce = reader.bytes();
                        break;
                    }
                case 2: {
                        message.secretKey = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("ivNonce"))
                throw $util.ProtocolError("missing required 'ivNonce'", { instance: message });
            if (!message.hasOwnProperty("secretKey"))
                throw $util.ProtocolError("missing required 'secretKey'", { instance: message });
            return message;
        };

        /**
         * Decodes a UDPEncryptionParameters message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPEncryptionParameters} UDPEncryptionParameters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPEncryptionParameters.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPEncryptionParameters message.
         * @function verify
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPEncryptionParameters.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.ivNonce && typeof message.ivNonce.length === "number" || $util.isString(message.ivNonce)))
                return "ivNonce: buffer expected";
            if (!(message.secretKey && typeof message.secretKey.length === "number" || $util.isString(message.secretKey)))
                return "secretKey: buffer expected";
            return null;
        };

        /**
         * Creates a UDPEncryptionParameters message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPEncryptionParameters} UDPEncryptionParameters
         */
        UDPEncryptionParameters.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPEncryptionParameters)
                return object;
            var message = new $root.proto.UDPEncryptionParameters();
            if (object.ivNonce != null)
                if (typeof object.ivNonce === "string")
                    $util.base64.decode(object.ivNonce, message.ivNonce = $util.newBuffer($util.base64.length(object.ivNonce)), 0);
                else if (object.ivNonce.length >= 0)
                    message.ivNonce = object.ivNonce;
            if (object.secretKey != null)
                if (typeof object.secretKey === "string")
                    $util.base64.decode(object.secretKey, message.secretKey = $util.newBuffer($util.base64.length(object.secretKey)), 0);
                else if (object.secretKey.length >= 0)
                    message.secretKey = object.secretKey;
            return message;
        };

        /**
         * Creates a plain object from a UDPEncryptionParameters message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {proto.UDPEncryptionParameters} message UDPEncryptionParameters
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPEncryptionParameters.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.ivNonce = "";
                else {
                    object.ivNonce = [];
                    if (options.bytes !== Array)
                        object.ivNonce = $util.newBuffer(object.ivNonce);
                }
                if (options.bytes === String)
                    object.secretKey = "";
                else {
                    object.secretKey = [];
                    if (options.bytes !== Array)
                        object.secretKey = $util.newBuffer(object.secretKey);
                }
            }
            if (message.ivNonce != null && message.hasOwnProperty("ivNonce"))
                object.ivNonce = options.bytes === String ? $util.base64.encode(message.ivNonce, 0, message.ivNonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.ivNonce) : message.ivNonce;
            if (message.secretKey != null && message.hasOwnProperty("secretKey"))
                object.secretKey = options.bytes === String ? $util.base64.encode(message.secretKey, 0, message.secretKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.secretKey) : message.secretKey;
            return object;
        };

        /**
         * Converts this UDPEncryptionParameters to JSON.
         * @function toJSON
         * @memberof proto.UDPEncryptionParameters
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPEncryptionParameters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPEncryptionParameters
         * @function getTypeUrl
         * @memberof proto.UDPEncryptionParameters
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPEncryptionParameters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPEncryptionParameters";
        };

        return UDPEncryptionParameters;
    })();

    proto.UDPAPIv1Server = (function() {

        /**
         * Properties of a UDPAPIv1Server.
         * @memberof proto
         * @interface IUDPAPIv1Server
         * @property {proto.IUDPStartKeepOpenRequest|null} [startKeepOpen] UDPAPIv1Server startKeepOpen
         * @property {proto.IUDPStopKeepOpenRequest|null} [stopKeepOpen] UDPAPIv1Server stopKeepOpen
         */

        /**
         * Constructs a new UDPAPIv1Server.
         * @memberof proto
         * @classdesc Represents a UDPAPIv1Server.
         * @implements IUDPAPIv1Server
         * @constructor
         * @param {proto.IUDPAPIv1Server=} [properties] Properties to set
         */
        function UDPAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDPAPIv1Server startKeepOpen.
         * @member {proto.IUDPStartKeepOpenRequest|null|undefined} startKeepOpen
         * @memberof proto.UDPAPIv1Server
         * @instance
         */
        UDPAPIv1Server.prototype.startKeepOpen = null;

        /**
         * UDPAPIv1Server stopKeepOpen.
         * @member {proto.IUDPStopKeepOpenRequest|null|undefined} stopKeepOpen
         * @memberof proto.UDPAPIv1Server
         * @instance
         */
        UDPAPIv1Server.prototype.stopKeepOpen = null;

        /**
         * Creates a new UDPAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {proto.IUDPAPIv1Server=} [properties] Properties to set
         * @returns {proto.UDPAPIv1Server} UDPAPIv1Server instance
         */
        UDPAPIv1Server.create = function create(properties) {
            return new UDPAPIv1Server(properties);
        };

        /**
         * Encodes the specified UDPAPIv1Server message. Does not implicitly {@link proto.UDPAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {proto.IUDPAPIv1Server} message UDPAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startKeepOpen != null && Object.hasOwnProperty.call(message, "startKeepOpen"))
                $root.proto.UDPStartKeepOpenRequest.encode(message.startKeepOpen, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.stopKeepOpen != null && Object.hasOwnProperty.call(message, "stopKeepOpen"))
                $root.proto.UDPStopKeepOpenRequest.encode(message.stopKeepOpen, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDPAPIv1Server message, length delimited. Does not implicitly {@link proto.UDPAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {proto.IUDPAPIv1Server} message UDPAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPAPIv1Server} UDPAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.startKeepOpen = $root.proto.UDPStartKeepOpenRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.stopKeepOpen = $root.proto.UDPStopKeepOpenRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDPAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPAPIv1Server} UDPAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPAPIv1Server message.
         * @function verify
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startKeepOpen != null && message.hasOwnProperty("startKeepOpen")) {
                var error = $root.proto.UDPStartKeepOpenRequest.verify(message.startKeepOpen);
                if (error)
                    return "startKeepOpen." + error;
            }
            if (message.stopKeepOpen != null && message.hasOwnProperty("stopKeepOpen")) {
                var error = $root.proto.UDPStopKeepOpenRequest.verify(message.stopKeepOpen);
                if (error)
                    return "stopKeepOpen." + error;
            }
            return null;
        };

        /**
         * Creates a UDPAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPAPIv1Server} UDPAPIv1Server
         */
        UDPAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPAPIv1Server)
                return object;
            var message = new $root.proto.UDPAPIv1Server();
            if (object.startKeepOpen != null) {
                if (typeof object.startKeepOpen !== "object")
                    throw TypeError(".proto.UDPAPIv1Server.startKeepOpen: object expected");
                message.startKeepOpen = $root.proto.UDPStartKeepOpenRequest.fromObject(object.startKeepOpen);
            }
            if (object.stopKeepOpen != null) {
                if (typeof object.stopKeepOpen !== "object")
                    throw TypeError(".proto.UDPAPIv1Server.stopKeepOpen: object expected");
                message.stopKeepOpen = $root.proto.UDPStopKeepOpenRequest.fromObject(object.stopKeepOpen);
            }
            return message;
        };

        /**
         * Creates a plain object from a UDPAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {proto.UDPAPIv1Server} message UDPAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.startKeepOpen = null;
                object.stopKeepOpen = null;
            }
            if (message.startKeepOpen != null && message.hasOwnProperty("startKeepOpen"))
                object.startKeepOpen = $root.proto.UDPStartKeepOpenRequest.toObject(message.startKeepOpen, options);
            if (message.stopKeepOpen != null && message.hasOwnProperty("stopKeepOpen"))
                object.stopKeepOpen = $root.proto.UDPStopKeepOpenRequest.toObject(message.stopKeepOpen, options);
            return object;
        };

        /**
         * Converts this UDPAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.UDPAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPAPIv1Server
         * @function getTypeUrl
         * @memberof proto.UDPAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPAPIv1Server";
        };

        return UDPAPIv1Server;
    })();

    proto.UDPStartKeepOpenRequest = (function() {

        /**
         * Properties of a UDPStartKeepOpenRequest.
         * @memberof proto
         * @interface IUDPStartKeepOpenRequest
         * @property {number} interval UDPStartKeepOpenRequest interval
         */

        /**
         * Constructs a new UDPStartKeepOpenRequest.
         * @memberof proto
         * @classdesc Represents a UDPStartKeepOpenRequest.
         * @implements IUDPStartKeepOpenRequest
         * @constructor
         * @param {proto.IUDPStartKeepOpenRequest=} [properties] Properties to set
         */
        function UDPStartKeepOpenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDPStartKeepOpenRequest interval.
         * @member {number} interval
         * @memberof proto.UDPStartKeepOpenRequest
         * @instance
         */
        UDPStartKeepOpenRequest.prototype.interval = 0;

        /**
         * Creates a new UDPStartKeepOpenRequest instance using the specified properties.
         * @function create
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {proto.IUDPStartKeepOpenRequest=} [properties] Properties to set
         * @returns {proto.UDPStartKeepOpenRequest} UDPStartKeepOpenRequest instance
         */
        UDPStartKeepOpenRequest.create = function create(properties) {
            return new UDPStartKeepOpenRequest(properties);
        };

        /**
         * Encodes the specified UDPStartKeepOpenRequest message. Does not implicitly {@link proto.UDPStartKeepOpenRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {proto.IUDPStartKeepOpenRequest} message UDPStartKeepOpenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPStartKeepOpenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.interval);
            return writer;
        };

        /**
         * Encodes the specified UDPStartKeepOpenRequest message, length delimited. Does not implicitly {@link proto.UDPStartKeepOpenRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {proto.IUDPStartKeepOpenRequest} message UDPStartKeepOpenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPStartKeepOpenRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPStartKeepOpenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPStartKeepOpenRequest} UDPStartKeepOpenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPStartKeepOpenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPStartKeepOpenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.interval = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("interval"))
                throw $util.ProtocolError("missing required 'interval'", { instance: message });
            return message;
        };

        /**
         * Decodes a UDPStartKeepOpenRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPStartKeepOpenRequest} UDPStartKeepOpenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPStartKeepOpenRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPStartKeepOpenRequest message.
         * @function verify
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPStartKeepOpenRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.interval))
                return "interval: integer expected";
            return null;
        };

        /**
         * Creates a UDPStartKeepOpenRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPStartKeepOpenRequest} UDPStartKeepOpenRequest
         */
        UDPStartKeepOpenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPStartKeepOpenRequest)
                return object;
            var message = new $root.proto.UDPStartKeepOpenRequest();
            if (object.interval != null)
                message.interval = object.interval | 0;
            return message;
        };

        /**
         * Creates a plain object from a UDPStartKeepOpenRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {proto.UDPStartKeepOpenRequest} message UDPStartKeepOpenRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPStartKeepOpenRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.interval = 0;
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = message.interval;
            return object;
        };

        /**
         * Converts this UDPStartKeepOpenRequest to JSON.
         * @function toJSON
         * @memberof proto.UDPStartKeepOpenRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPStartKeepOpenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPStartKeepOpenRequest
         * @function getTypeUrl
         * @memberof proto.UDPStartKeepOpenRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPStartKeepOpenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPStartKeepOpenRequest";
        };

        return UDPStartKeepOpenRequest;
    })();

    proto.UDPStopKeepOpenRequest = (function() {

        /**
         * Properties of a UDPStopKeepOpenRequest.
         * @memberof proto
         * @interface IUDPStopKeepOpenRequest
         */

        /**
         * Constructs a new UDPStopKeepOpenRequest.
         * @memberof proto
         * @classdesc Represents a UDPStopKeepOpenRequest.
         * @implements IUDPStopKeepOpenRequest
         * @constructor
         * @param {proto.IUDPStopKeepOpenRequest=} [properties] Properties to set
         */
        function UDPStopKeepOpenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UDPStopKeepOpenRequest instance using the specified properties.
         * @function create
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {proto.IUDPStopKeepOpenRequest=} [properties] Properties to set
         * @returns {proto.UDPStopKeepOpenRequest} UDPStopKeepOpenRequest instance
         */
        UDPStopKeepOpenRequest.create = function create(properties) {
            return new UDPStopKeepOpenRequest(properties);
        };

        /**
         * Encodes the specified UDPStopKeepOpenRequest message. Does not implicitly {@link proto.UDPStopKeepOpenRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {proto.IUDPStopKeepOpenRequest} message UDPStopKeepOpenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPStopKeepOpenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UDPStopKeepOpenRequest message, length delimited. Does not implicitly {@link proto.UDPStopKeepOpenRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {proto.IUDPStopKeepOpenRequest} message UDPStopKeepOpenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDPStopKeepOpenRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDPStopKeepOpenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.UDPStopKeepOpenRequest} UDPStopKeepOpenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPStopKeepOpenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.UDPStopKeepOpenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDPStopKeepOpenRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.UDPStopKeepOpenRequest} UDPStopKeepOpenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDPStopKeepOpenRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDPStopKeepOpenRequest message.
         * @function verify
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDPStopKeepOpenRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a UDPStopKeepOpenRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.UDPStopKeepOpenRequest} UDPStopKeepOpenRequest
         */
        UDPStopKeepOpenRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.UDPStopKeepOpenRequest)
                return object;
            return new $root.proto.UDPStopKeepOpenRequest();
        };

        /**
         * Creates a plain object from a UDPStopKeepOpenRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {proto.UDPStopKeepOpenRequest} message UDPStopKeepOpenRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDPStopKeepOpenRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UDPStopKeepOpenRequest to JSON.
         * @function toJSON
         * @memberof proto.UDPStopKeepOpenRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDPStopKeepOpenRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDPStopKeepOpenRequest
         * @function getTypeUrl
         * @memberof proto.UDPStopKeepOpenRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDPStopKeepOpenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.UDPStopKeepOpenRequest";
        };

        return UDPStopKeepOpenRequest;
    })();

    proto.TalkburstReceptionAPIv1Client = (function() {

        /**
         * Properties of a TalkburstReceptionAPIv1Client.
         * @memberof proto
         * @interface ITalkburstReceptionAPIv1Client
         * @property {proto.ITalkburstReceptionModuleSetupRequest|null} [setupRequest] TalkburstReceptionAPIv1Client setupRequest
         */

        /**
         * Constructs a new TalkburstReceptionAPIv1Client.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionAPIv1Client.
         * @implements ITalkburstReceptionAPIv1Client
         * @constructor
         * @param {proto.ITalkburstReceptionAPIv1Client=} [properties] Properties to set
         */
        function TalkburstReceptionAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionAPIv1Client setupRequest.
         * @member {proto.ITalkburstReceptionModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @instance
         */
        TalkburstReceptionAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new TalkburstReceptionAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {proto.ITalkburstReceptionAPIv1Client=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionAPIv1Client} TalkburstReceptionAPIv1Client instance
         */
        TalkburstReceptionAPIv1Client.create = function create(properties) {
            return new TalkburstReceptionAPIv1Client(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionAPIv1Client message. Does not implicitly {@link proto.TalkburstReceptionAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {proto.ITalkburstReceptionAPIv1Client} message TalkburstReceptionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.TalkburstReceptionModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionAPIv1Client message, length delimited. Does not implicitly {@link proto.TalkburstReceptionAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {proto.ITalkburstReceptionAPIv1Client} message TalkburstReceptionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionAPIv1Client} TalkburstReceptionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.TalkburstReceptionModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstReceptionAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionAPIv1Client} TalkburstReceptionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionAPIv1Client message.
         * @function verify
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.TalkburstReceptionModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a TalkburstReceptionAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionAPIv1Client} TalkburstReceptionAPIv1Client
         */
        TalkburstReceptionAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionAPIv1Client)
                return object;
            var message = new $root.proto.TalkburstReceptionAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.TalkburstReceptionAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.TalkburstReceptionModuleSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {proto.TalkburstReceptionAPIv1Client} message TalkburstReceptionAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.TalkburstReceptionModuleSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this TalkburstReceptionAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionAPIv1Client
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionAPIv1Client";
        };

        return TalkburstReceptionAPIv1Client;
    })();

    proto.TalkburstReceptionModuleSetupRequest = (function() {

        /**
         * Properties of a TalkburstReceptionModuleSetupRequest.
         * @memberof proto
         * @interface ITalkburstReceptionModuleSetupRequest
         */

        /**
         * Constructs a new TalkburstReceptionModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionModuleSetupRequest.
         * @implements ITalkburstReceptionModuleSetupRequest
         * @constructor
         * @param {proto.ITalkburstReceptionModuleSetupRequest=} [properties] Properties to set
         */
        function TalkburstReceptionModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TalkburstReceptionModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {proto.ITalkburstReceptionModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionModuleSetupRequest} TalkburstReceptionModuleSetupRequest instance
         */
        TalkburstReceptionModuleSetupRequest.create = function create(properties) {
            return new TalkburstReceptionModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionModuleSetupRequest message. Does not implicitly {@link proto.TalkburstReceptionModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {proto.ITalkburstReceptionModuleSetupRequest} message TalkburstReceptionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionModuleSetupRequest message, length delimited. Does not implicitly {@link proto.TalkburstReceptionModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {proto.ITalkburstReceptionModuleSetupRequest} message TalkburstReceptionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionModuleSetupRequest} TalkburstReceptionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstReceptionModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionModuleSetupRequest} TalkburstReceptionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionModuleSetupRequest message.
         * @function verify
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TalkburstReceptionModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionModuleSetupRequest} TalkburstReceptionModuleSetupRequest
         */
        TalkburstReceptionModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionModuleSetupRequest)
                return object;
            return new $root.proto.TalkburstReceptionModuleSetupRequest();
        };

        /**
         * Creates a plain object from a TalkburstReceptionModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {proto.TalkburstReceptionModuleSetupRequest} message TalkburstReceptionModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TalkburstReceptionModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionModuleSetupRequest";
        };

        return TalkburstReceptionModuleSetupRequest;
    })();

    proto.TalkburstReceptionModuleSetupResponse = (function() {

        /**
         * Properties of a TalkburstReceptionModuleSetupResponse.
         * @memberof proto
         * @interface ITalkburstReceptionModuleSetupResponse
         * @property {boolean} returnReceptionStatistics TalkburstReceptionModuleSetupResponse returnReceptionStatistics
         */

        /**
         * Constructs a new TalkburstReceptionModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionModuleSetupResponse.
         * @implements ITalkburstReceptionModuleSetupResponse
         * @constructor
         * @param {proto.ITalkburstReceptionModuleSetupResponse=} [properties] Properties to set
         */
        function TalkburstReceptionModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionModuleSetupResponse returnReceptionStatistics.
         * @member {boolean} returnReceptionStatistics
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @instance
         */
        TalkburstReceptionModuleSetupResponse.prototype.returnReceptionStatistics = false;

        /**
         * Creates a new TalkburstReceptionModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {proto.ITalkburstReceptionModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionModuleSetupResponse} TalkburstReceptionModuleSetupResponse instance
         */
        TalkburstReceptionModuleSetupResponse.create = function create(properties) {
            return new TalkburstReceptionModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionModuleSetupResponse message. Does not implicitly {@link proto.TalkburstReceptionModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {proto.ITalkburstReceptionModuleSetupResponse} message TalkburstReceptionModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.returnReceptionStatistics);
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionModuleSetupResponse message, length delimited. Does not implicitly {@link proto.TalkburstReceptionModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {proto.ITalkburstReceptionModuleSetupResponse} message TalkburstReceptionModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionModuleSetupResponse} TalkburstReceptionModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.returnReceptionStatistics = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("returnReceptionStatistics"))
                throw $util.ProtocolError("missing required 'returnReceptionStatistics'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstReceptionModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionModuleSetupResponse} TalkburstReceptionModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionModuleSetupResponse message.
         * @function verify
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.returnReceptionStatistics !== "boolean")
                return "returnReceptionStatistics: boolean expected";
            return null;
        };

        /**
         * Creates a TalkburstReceptionModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionModuleSetupResponse} TalkburstReceptionModuleSetupResponse
         */
        TalkburstReceptionModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionModuleSetupResponse)
                return object;
            var message = new $root.proto.TalkburstReceptionModuleSetupResponse();
            if (object.returnReceptionStatistics != null)
                message.returnReceptionStatistics = Boolean(object.returnReceptionStatistics);
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {proto.TalkburstReceptionModuleSetupResponse} message TalkburstReceptionModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.returnReceptionStatistics = false;
            if (message.returnReceptionStatistics != null && message.hasOwnProperty("returnReceptionStatistics"))
                object.returnReceptionStatistics = message.returnReceptionStatistics;
            return object;
        };

        /**
         * Converts this TalkburstReceptionModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionModuleSetupResponse";
        };

        return TalkburstReceptionModuleSetupResponse;
    })();

    proto.TalkburstReceptionAPIv1Server = (function() {

        /**
         * Properties of a TalkburstReceptionAPIv1Server.
         * @memberof proto
         * @interface ITalkburstReceptionAPIv1Server
         * @property {proto.ITalkburstReceptionStarted|null} [start] TalkburstReceptionAPIv1Server start
         * @property {proto.ITalkburstReceptionStopped|null} [stop] TalkburstReceptionAPIv1Server stop
         */

        /**
         * Constructs a new TalkburstReceptionAPIv1Server.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionAPIv1Server.
         * @implements ITalkburstReceptionAPIv1Server
         * @constructor
         * @param {proto.ITalkburstReceptionAPIv1Server=} [properties] Properties to set
         */
        function TalkburstReceptionAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionAPIv1Server start.
         * @member {proto.ITalkburstReceptionStarted|null|undefined} start
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @instance
         */
        TalkburstReceptionAPIv1Server.prototype.start = null;

        /**
         * TalkburstReceptionAPIv1Server stop.
         * @member {proto.ITalkburstReceptionStopped|null|undefined} stop
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @instance
         */
        TalkburstReceptionAPIv1Server.prototype.stop = null;

        /**
         * Creates a new TalkburstReceptionAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {proto.ITalkburstReceptionAPIv1Server=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionAPIv1Server} TalkburstReceptionAPIv1Server instance
         */
        TalkburstReceptionAPIv1Server.create = function create(properties) {
            return new TalkburstReceptionAPIv1Server(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionAPIv1Server message. Does not implicitly {@link proto.TalkburstReceptionAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {proto.ITalkburstReceptionAPIv1Server} message TalkburstReceptionAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.proto.TalkburstReceptionStarted.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.stop != null && Object.hasOwnProperty.call(message, "stop"))
                $root.proto.TalkburstReceptionStopped.encode(message.stop, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionAPIv1Server message, length delimited. Does not implicitly {@link proto.TalkburstReceptionAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {proto.ITalkburstReceptionAPIv1Server} message TalkburstReceptionAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionAPIv1Server} TalkburstReceptionAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start = $root.proto.TalkburstReceptionStarted.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.stop = $root.proto.TalkburstReceptionStopped.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstReceptionAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionAPIv1Server} TalkburstReceptionAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionAPIv1Server message.
         * @function verify
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                var error = $root.proto.TalkburstReceptionStarted.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.stop != null && message.hasOwnProperty("stop")) {
                var error = $root.proto.TalkburstReceptionStopped.verify(message.stop);
                if (error)
                    return "stop." + error;
            }
            return null;
        };

        /**
         * Creates a TalkburstReceptionAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionAPIv1Server} TalkburstReceptionAPIv1Server
         */
        TalkburstReceptionAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionAPIv1Server)
                return object;
            var message = new $root.proto.TalkburstReceptionAPIv1Server();
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".proto.TalkburstReceptionAPIv1Server.start: object expected");
                message.start = $root.proto.TalkburstReceptionStarted.fromObject(object.start);
            }
            if (object.stop != null) {
                if (typeof object.stop !== "object")
                    throw TypeError(".proto.TalkburstReceptionAPIv1Server.stop: object expected");
                message.stop = $root.proto.TalkburstReceptionStopped.fromObject(object.stop);
            }
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {proto.TalkburstReceptionAPIv1Server} message TalkburstReceptionAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.start = null;
                object.stop = null;
            }
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.proto.TalkburstReceptionStarted.toObject(message.start, options);
            if (message.stop != null && message.hasOwnProperty("stop"))
                object.stop = $root.proto.TalkburstReceptionStopped.toObject(message.stop, options);
            return object;
        };

        /**
         * Converts this TalkburstReceptionAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionAPIv1Server
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionAPIv1Server";
        };

        return TalkburstReceptionAPIv1Server;
    })();

    proto.TalkburstReceptionStarted = (function() {

        /**
         * Properties of a TalkburstReceptionStarted.
         * @memberof proto
         * @interface ITalkburstReceptionStarted
         * @property {number|Long} talkburstId TalkburstReceptionStarted talkburstId
         * @property {number|Long} sessionId TalkburstReceptionStarted sessionId
         * @property {string} sender TalkburstReceptionStarted sender
         * @property {proto.PresenceType} limitedType TalkburstReceptionStarted limitedType
         * @property {string|null} [sourceId] TalkburstReceptionStarted sourceId
         * @property {string|null} [muteGroupId] TalkburstReceptionStarted muteGroupId
         * @property {boolean|null} [tone] TalkburstReceptionStarted tone
         * @property {proto.PresenceType|null} [type] TalkburstReceptionStarted type
         * @property {string|null} [globalTalkburstId] TalkburstReceptionStarted globalTalkburstId
         * @property {boolean|null} [audioSuppressed] TalkburstReceptionStarted audioSuppressed
         */

        /**
         * Constructs a new TalkburstReceptionStarted.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionStarted.
         * @implements ITalkburstReceptionStarted
         * @constructor
         * @param {proto.ITalkburstReceptionStarted=} [properties] Properties to set
         */
        function TalkburstReceptionStarted(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionStarted talkburstId.
         * @member {number|Long} talkburstId
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.talkburstId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstReceptionStarted sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstReceptionStarted sender.
         * @member {string} sender
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.sender = "";

        /**
         * TalkburstReceptionStarted limitedType.
         * @member {proto.PresenceType} limitedType
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.limitedType = 1;

        /**
         * TalkburstReceptionStarted sourceId.
         * @member {string} sourceId
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.sourceId = "";

        /**
         * TalkburstReceptionStarted muteGroupId.
         * @member {string} muteGroupId
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.muteGroupId = "";

        /**
         * TalkburstReceptionStarted tone.
         * @member {boolean} tone
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.tone = false;

        /**
         * TalkburstReceptionStarted type.
         * @member {proto.PresenceType} type
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.type = 1;

        /**
         * TalkburstReceptionStarted globalTalkburstId.
         * @member {string} globalTalkburstId
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.globalTalkburstId = "";

        /**
         * TalkburstReceptionStarted audioSuppressed.
         * @member {boolean} audioSuppressed
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         */
        TalkburstReceptionStarted.prototype.audioSuppressed = false;

        /**
         * Creates a new TalkburstReceptionStarted instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {proto.ITalkburstReceptionStarted=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionStarted} TalkburstReceptionStarted instance
         */
        TalkburstReceptionStarted.create = function create(properties) {
            return new TalkburstReceptionStarted(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionStarted message. Does not implicitly {@link proto.TalkburstReceptionStarted.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {proto.ITalkburstReceptionStarted} message TalkburstReceptionStarted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStarted.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.talkburstId);
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sessionId);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.sender);
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limitedType);
            if (message.sourceId != null && Object.hasOwnProperty.call(message, "sourceId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.sourceId);
            if (message.muteGroupId != null && Object.hasOwnProperty.call(message, "muteGroupId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.muteGroupId);
            if (message.tone != null && Object.hasOwnProperty.call(message, "tone"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.tone);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
            if (message.globalTalkburstId != null && Object.hasOwnProperty.call(message, "globalTalkburstId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.globalTalkburstId);
            if (message.audioSuppressed != null && Object.hasOwnProperty.call(message, "audioSuppressed"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.audioSuppressed);
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionStarted message, length delimited. Does not implicitly {@link proto.TalkburstReceptionStarted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {proto.ITalkburstReceptionStarted} message TalkburstReceptionStarted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStarted.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionStarted message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionStarted} TalkburstReceptionStarted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStarted.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionStarted();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.talkburstId = reader.int64();
                        break;
                    }
                case 2: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 3: {
                        message.sender = reader.string();
                        break;
                    }
                case 4: {
                        message.limitedType = reader.int32();
                        break;
                    }
                case 5: {
                        message.sourceId = reader.string();
                        break;
                    }
                case 6: {
                        message.muteGroupId = reader.string();
                        break;
                    }
                case 7: {
                        message.tone = reader.bool();
                        break;
                    }
                case 8: {
                        message.type = reader.int32();
                        break;
                    }
                case 9: {
                        message.globalTalkburstId = reader.string();
                        break;
                    }
                case 10: {
                        message.audioSuppressed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("talkburstId"))
                throw $util.ProtocolError("missing required 'talkburstId'", { instance: message });
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            if (!message.hasOwnProperty("sender"))
                throw $util.ProtocolError("missing required 'sender'", { instance: message });
            if (!message.hasOwnProperty("limitedType"))
                throw $util.ProtocolError("missing required 'limitedType'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstReceptionStarted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionStarted} TalkburstReceptionStarted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStarted.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionStarted message.
         * @function verify
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionStarted.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.talkburstId) && !(message.talkburstId && $util.isInteger(message.talkburstId.low) && $util.isInteger(message.talkburstId.high)))
                return "talkburstId: integer|Long expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (!$util.isString(message.sender))
                return "sender: string expected";
            switch (message.limitedType) {
            default:
                return "limitedType: enum value expected";
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
            if (message.sourceId != null && message.hasOwnProperty("sourceId"))
                if (!$util.isString(message.sourceId))
                    return "sourceId: string expected";
            if (message.muteGroupId != null && message.hasOwnProperty("muteGroupId"))
                if (!$util.isString(message.muteGroupId))
                    return "muteGroupId: string expected";
            if (message.tone != null && message.hasOwnProperty("tone"))
                if (typeof message.tone !== "boolean")
                    return "tone: boolean expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.globalTalkburstId != null && message.hasOwnProperty("globalTalkburstId"))
                if (!$util.isString(message.globalTalkburstId))
                    return "globalTalkburstId: string expected";
            if (message.audioSuppressed != null && message.hasOwnProperty("audioSuppressed"))
                if (typeof message.audioSuppressed !== "boolean")
                    return "audioSuppressed: boolean expected";
            return null;
        };

        /**
         * Creates a TalkburstReceptionStarted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionStarted} TalkburstReceptionStarted
         */
        TalkburstReceptionStarted.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionStarted)
                return object;
            var message = new $root.proto.TalkburstReceptionStarted();
            if (object.talkburstId != null)
                if ($util.Long)
                    (message.talkburstId = $util.Long.fromValue(object.talkburstId)).unsigned = false;
                else if (typeof object.talkburstId === "string")
                    message.talkburstId = parseInt(object.talkburstId, 10);
                else if (typeof object.talkburstId === "number")
                    message.talkburstId = object.talkburstId;
                else if (typeof object.talkburstId === "object")
                    message.talkburstId = new $util.LongBits(object.talkburstId.low >>> 0, object.talkburstId.high >>> 0).toNumber();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.sender != null)
                message.sender = String(object.sender);
            switch (object.limitedType) {
            default:
                if (typeof object.limitedType === "number") {
                    message.limitedType = object.limitedType;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.limitedType = 1;
                break;
            case "MOBILE":
            case 2:
                message.limitedType = 2;
                break;
            case "DISPATCHER":
            case 3:
                message.limitedType = 3;
                break;
            case "DIALED":
            case 4:
                message.limitedType = 4;
                break;
            case "RADIO":
            case 5:
                message.limitedType = 5;
                break;
            case "PATCHED_GROUP":
            case 6:
                message.limitedType = 6;
                break;
            }
            if (object.sourceId != null)
                message.sourceId = String(object.sourceId);
            if (object.muteGroupId != null)
                message.muteGroupId = String(object.muteGroupId);
            if (object.tone != null)
                message.tone = Boolean(object.tone);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.type = 1;
                break;
            case "MOBILE":
            case 2:
                message.type = 2;
                break;
            case "DISPATCHER":
            case 3:
                message.type = 3;
                break;
            case "DIALED":
            case 4:
                message.type = 4;
                break;
            case "RADIO":
            case 5:
                message.type = 5;
                break;
            case "PATCHED_GROUP":
            case 6:
                message.type = 6;
                break;
            }
            if (object.globalTalkburstId != null)
                message.globalTalkburstId = String(object.globalTalkburstId);
            if (object.audioSuppressed != null)
                message.audioSuppressed = Boolean(object.audioSuppressed);
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionStarted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {proto.TalkburstReceptionStarted} message TalkburstReceptionStarted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionStarted.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.talkburstId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.talkburstId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                object.sender = "";
                object.limitedType = options.enums === String ? "UNKNOWN" : 1;
                object.sourceId = "";
                object.muteGroupId = "";
                object.tone = false;
                object.type = options.enums === String ? "UNKNOWN" : 1;
                object.globalTalkburstId = "";
                object.audioSuppressed = false;
            }
            if (message.talkburstId != null && message.hasOwnProperty("talkburstId"))
                if (typeof message.talkburstId === "number")
                    object.talkburstId = options.longs === String ? String(message.talkburstId) : message.talkburstId;
                else
                    object.talkburstId = options.longs === String ? $util.Long.prototype.toString.call(message.talkburstId) : options.longs === Number ? new $util.LongBits(message.talkburstId.low >>> 0, message.talkburstId.high >>> 0).toNumber() : message.talkburstId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.sender != null && message.hasOwnProperty("sender"))
                object.sender = message.sender;
            if (message.limitedType != null && message.hasOwnProperty("limitedType"))
                object.limitedType = options.enums === String ? $root.proto.PresenceType[message.limitedType] === undefined ? message.limitedType : $root.proto.PresenceType[message.limitedType] : message.limitedType;
            if (message.sourceId != null && message.hasOwnProperty("sourceId"))
                object.sourceId = message.sourceId;
            if (message.muteGroupId != null && message.hasOwnProperty("muteGroupId"))
                object.muteGroupId = message.muteGroupId;
            if (message.tone != null && message.hasOwnProperty("tone"))
                object.tone = message.tone;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.PresenceType[message.type] === undefined ? message.type : $root.proto.PresenceType[message.type] : message.type;
            if (message.globalTalkburstId != null && message.hasOwnProperty("globalTalkburstId"))
                object.globalTalkburstId = message.globalTalkburstId;
            if (message.audioSuppressed != null && message.hasOwnProperty("audioSuppressed"))
                object.audioSuppressed = message.audioSuppressed;
            return object;
        };

        /**
         * Converts this TalkburstReceptionStarted to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionStarted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionStarted.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionStarted
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionStarted
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionStarted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionStarted";
        };

        return TalkburstReceptionStarted;
    })();

    proto.TalkburstReceptionStopped = (function() {

        /**
         * Properties of a TalkburstReceptionStopped.
         * @memberof proto
         * @interface ITalkburstReceptionStopped
         * @property {number|Long} talkburstId TalkburstReceptionStopped talkburstId
         * @property {number} lastSentSeqNo TalkburstReceptionStopped lastSentSeqNo
         */

        /**
         * Constructs a new TalkburstReceptionStopped.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionStopped.
         * @implements ITalkburstReceptionStopped
         * @constructor
         * @param {proto.ITalkburstReceptionStopped=} [properties] Properties to set
         */
        function TalkburstReceptionStopped(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionStopped talkburstId.
         * @member {number|Long} talkburstId
         * @memberof proto.TalkburstReceptionStopped
         * @instance
         */
        TalkburstReceptionStopped.prototype.talkburstId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstReceptionStopped lastSentSeqNo.
         * @member {number} lastSentSeqNo
         * @memberof proto.TalkburstReceptionStopped
         * @instance
         */
        TalkburstReceptionStopped.prototype.lastSentSeqNo = 0;

        /**
         * Creates a new TalkburstReceptionStopped instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {proto.ITalkburstReceptionStopped=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionStopped} TalkburstReceptionStopped instance
         */
        TalkburstReceptionStopped.create = function create(properties) {
            return new TalkburstReceptionStopped(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionStopped message. Does not implicitly {@link proto.TalkburstReceptionStopped.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {proto.ITalkburstReceptionStopped} message TalkburstReceptionStopped message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStopped.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.talkburstId);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.lastSentSeqNo);
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionStopped message, length delimited. Does not implicitly {@link proto.TalkburstReceptionStopped.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {proto.ITalkburstReceptionStopped} message TalkburstReceptionStopped message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStopped.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionStopped message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionStopped} TalkburstReceptionStopped
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStopped.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionStopped();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.talkburstId = reader.int64();
                        break;
                    }
                case 2: {
                        message.lastSentSeqNo = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("talkburstId"))
                throw $util.ProtocolError("missing required 'talkburstId'", { instance: message });
            if (!message.hasOwnProperty("lastSentSeqNo"))
                throw $util.ProtocolError("missing required 'lastSentSeqNo'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstReceptionStopped message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionStopped} TalkburstReceptionStopped
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStopped.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionStopped message.
         * @function verify
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionStopped.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.talkburstId) && !(message.talkburstId && $util.isInteger(message.talkburstId.low) && $util.isInteger(message.talkburstId.high)))
                return "talkburstId: integer|Long expected";
            if (!$util.isInteger(message.lastSentSeqNo))
                return "lastSentSeqNo: integer expected";
            return null;
        };

        /**
         * Creates a TalkburstReceptionStopped message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionStopped} TalkburstReceptionStopped
         */
        TalkburstReceptionStopped.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionStopped)
                return object;
            var message = new $root.proto.TalkburstReceptionStopped();
            if (object.talkburstId != null)
                if ($util.Long)
                    (message.talkburstId = $util.Long.fromValue(object.talkburstId)).unsigned = false;
                else if (typeof object.talkburstId === "string")
                    message.talkburstId = parseInt(object.talkburstId, 10);
                else if (typeof object.talkburstId === "number")
                    message.talkburstId = object.talkburstId;
                else if (typeof object.talkburstId === "object")
                    message.talkburstId = new $util.LongBits(object.talkburstId.low >>> 0, object.talkburstId.high >>> 0).toNumber();
            if (object.lastSentSeqNo != null)
                message.lastSentSeqNo = object.lastSentSeqNo | 0;
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionStopped message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {proto.TalkburstReceptionStopped} message TalkburstReceptionStopped
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionStopped.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.talkburstId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.talkburstId = options.longs === String ? "0" : 0;
                object.lastSentSeqNo = 0;
            }
            if (message.talkburstId != null && message.hasOwnProperty("talkburstId"))
                if (typeof message.talkburstId === "number")
                    object.talkburstId = options.longs === String ? String(message.talkburstId) : message.talkburstId;
                else
                    object.talkburstId = options.longs === String ? $util.Long.prototype.toString.call(message.talkburstId) : options.longs === Number ? new $util.LongBits(message.talkburstId.low >>> 0, message.talkburstId.high >>> 0).toNumber() : message.talkburstId;
            if (message.lastSentSeqNo != null && message.hasOwnProperty("lastSentSeqNo"))
                object.lastSentSeqNo = message.lastSentSeqNo;
            return object;
        };

        /**
         * Converts this TalkburstReceptionStopped to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionStopped
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionStopped.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionStopped
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionStopped
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionStopped.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionStopped";
        };

        return TalkburstReceptionStopped;
    })();

    proto.TalkburstReceptionStoppedResponse = (function() {

        /**
         * Properties of a TalkburstReceptionStoppedResponse.
         * @memberof proto
         * @interface ITalkburstReceptionStoppedResponse
         * @property {proto.ITalkburstReceptionStatistics|null} [statistics] TalkburstReceptionStoppedResponse statistics
         */

        /**
         * Constructs a new TalkburstReceptionStoppedResponse.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionStoppedResponse.
         * @implements ITalkburstReceptionStoppedResponse
         * @constructor
         * @param {proto.ITalkburstReceptionStoppedResponse=} [properties] Properties to set
         */
        function TalkburstReceptionStoppedResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionStoppedResponse statistics.
         * @member {proto.ITalkburstReceptionStatistics|null|undefined} statistics
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @instance
         */
        TalkburstReceptionStoppedResponse.prototype.statistics = null;

        /**
         * Creates a new TalkburstReceptionStoppedResponse instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {proto.ITalkburstReceptionStoppedResponse=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionStoppedResponse} TalkburstReceptionStoppedResponse instance
         */
        TalkburstReceptionStoppedResponse.create = function create(properties) {
            return new TalkburstReceptionStoppedResponse(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionStoppedResponse message. Does not implicitly {@link proto.TalkburstReceptionStoppedResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {proto.ITalkburstReceptionStoppedResponse} message TalkburstReceptionStoppedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStoppedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statistics != null && Object.hasOwnProperty.call(message, "statistics"))
                $root.proto.TalkburstReceptionStatistics.encode(message.statistics, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionStoppedResponse message, length delimited. Does not implicitly {@link proto.TalkburstReceptionStoppedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {proto.ITalkburstReceptionStoppedResponse} message TalkburstReceptionStoppedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStoppedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionStoppedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionStoppedResponse} TalkburstReceptionStoppedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStoppedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionStoppedResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.statistics = $root.proto.TalkburstReceptionStatistics.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstReceptionStoppedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionStoppedResponse} TalkburstReceptionStoppedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStoppedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionStoppedResponse message.
         * @function verify
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionStoppedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statistics != null && message.hasOwnProperty("statistics")) {
                var error = $root.proto.TalkburstReceptionStatistics.verify(message.statistics);
                if (error)
                    return "statistics." + error;
            }
            return null;
        };

        /**
         * Creates a TalkburstReceptionStoppedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionStoppedResponse} TalkburstReceptionStoppedResponse
         */
        TalkburstReceptionStoppedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionStoppedResponse)
                return object;
            var message = new $root.proto.TalkburstReceptionStoppedResponse();
            if (object.statistics != null) {
                if (typeof object.statistics !== "object")
                    throw TypeError(".proto.TalkburstReceptionStoppedResponse.statistics: object expected");
                message.statistics = $root.proto.TalkburstReceptionStatistics.fromObject(object.statistics);
            }
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionStoppedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {proto.TalkburstReceptionStoppedResponse} message TalkburstReceptionStoppedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionStoppedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.statistics = null;
            if (message.statistics != null && message.hasOwnProperty("statistics"))
                object.statistics = $root.proto.TalkburstReceptionStatistics.toObject(message.statistics, options);
            return object;
        };

        /**
         * Converts this TalkburstReceptionStoppedResponse to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionStoppedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionStoppedResponse
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionStoppedResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionStoppedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionStoppedResponse";
        };

        return TalkburstReceptionStoppedResponse;
    })();

    proto.TalkburstReceptionStatistics = (function() {

        /**
         * Properties of a TalkburstReceptionStatistics.
         * @memberof proto
         * @interface ITalkburstReceptionStatistics
         * @property {number|Long} startedReceiveTime TalkburstReceptionStatistics startedReceiveTime
         * @property {number|Long} stoppedReceiveTime TalkburstReceptionStatistics stoppedReceiveTime
         * @property {Array.<number>|null} [packetSeqNos] TalkburstReceptionStatistics packetSeqNos
         * @property {Array.<number|Long>|null} [packetReceiveTimes] TalkburstReceptionStatistics packetReceiveTimes
         * @property {Array.<proto.TalkburstReceptionStatistics.INetworkInfo>|null} [networkInfo] TalkburstReceptionStatistics networkInfo
         * @property {number|null} [numRebuffering] TalkburstReceptionStatistics numRebuffering
         * @property {number|null} [numPlc] TalkburstReceptionStatistics numPlc
         * @property {number|null} [numLate] TalkburstReceptionStatistics numLate
         * @property {number|null} [numLost] TalkburstReceptionStatistics numLost
         */

        /**
         * Constructs a new TalkburstReceptionStatistics.
         * @memberof proto
         * @classdesc Represents a TalkburstReceptionStatistics.
         * @implements ITalkburstReceptionStatistics
         * @constructor
         * @param {proto.ITalkburstReceptionStatistics=} [properties] Properties to set
         */
        function TalkburstReceptionStatistics(properties) {
            this.packetSeqNos = [];
            this.packetReceiveTimes = [];
            this.networkInfo = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstReceptionStatistics startedReceiveTime.
         * @member {number|Long} startedReceiveTime
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.startedReceiveTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstReceptionStatistics stoppedReceiveTime.
         * @member {number|Long} stoppedReceiveTime
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.stoppedReceiveTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstReceptionStatistics packetSeqNos.
         * @member {Array.<number>} packetSeqNos
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.packetSeqNos = $util.emptyArray;

        /**
         * TalkburstReceptionStatistics packetReceiveTimes.
         * @member {Array.<number|Long>} packetReceiveTimes
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.packetReceiveTimes = $util.emptyArray;

        /**
         * TalkburstReceptionStatistics networkInfo.
         * @member {Array.<proto.TalkburstReceptionStatistics.INetworkInfo>} networkInfo
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.networkInfo = $util.emptyArray;

        /**
         * TalkburstReceptionStatistics numRebuffering.
         * @member {number} numRebuffering
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.numRebuffering = 0;

        /**
         * TalkburstReceptionStatistics numPlc.
         * @member {number} numPlc
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.numPlc = 0;

        /**
         * TalkburstReceptionStatistics numLate.
         * @member {number} numLate
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.numLate = 0;

        /**
         * TalkburstReceptionStatistics numLost.
         * @member {number} numLost
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         */
        TalkburstReceptionStatistics.prototype.numLost = 0;

        /**
         * Creates a new TalkburstReceptionStatistics instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {proto.ITalkburstReceptionStatistics=} [properties] Properties to set
         * @returns {proto.TalkburstReceptionStatistics} TalkburstReceptionStatistics instance
         */
        TalkburstReceptionStatistics.create = function create(properties) {
            return new TalkburstReceptionStatistics(properties);
        };

        /**
         * Encodes the specified TalkburstReceptionStatistics message. Does not implicitly {@link proto.TalkburstReceptionStatistics.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {proto.ITalkburstReceptionStatistics} message TalkburstReceptionStatistics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStatistics.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.startedReceiveTime);
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.stoppedReceiveTime);
            if (message.packetSeqNos != null && message.packetSeqNos.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.packetSeqNos.length; ++i)
                    writer.int32(message.packetSeqNos[i]);
                writer.ldelim();
            }
            if (message.packetReceiveTimes != null && message.packetReceiveTimes.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.packetReceiveTimes.length; ++i)
                    writer.int64(message.packetReceiveTimes[i]);
                writer.ldelim();
            }
            if (message.networkInfo != null && message.networkInfo.length)
                for (var i = 0; i < message.networkInfo.length; ++i)
                    $root.proto.TalkburstReceptionStatistics.NetworkInfo.encode(message.networkInfo[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.numRebuffering != null && Object.hasOwnProperty.call(message, "numRebuffering"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.numRebuffering);
            if (message.numPlc != null && Object.hasOwnProperty.call(message, "numPlc"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.numPlc);
            if (message.numLate != null && Object.hasOwnProperty.call(message, "numLate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.numLate);
            if (message.numLost != null && Object.hasOwnProperty.call(message, "numLost"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.numLost);
            return writer;
        };

        /**
         * Encodes the specified TalkburstReceptionStatistics message, length delimited. Does not implicitly {@link proto.TalkburstReceptionStatistics.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {proto.ITalkburstReceptionStatistics} message TalkburstReceptionStatistics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstReceptionStatistics.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstReceptionStatistics message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstReceptionStatistics} TalkburstReceptionStatistics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStatistics.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionStatistics();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.startedReceiveTime = reader.int64();
                        break;
                    }
                case 2: {
                        message.stoppedReceiveTime = reader.int64();
                        break;
                    }
                case 3: {
                        if (!(message.packetSeqNos && message.packetSeqNos.length))
                            message.packetSeqNos = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.packetSeqNos.push(reader.int32());
                        } else
                            message.packetSeqNos.push(reader.int32());
                        break;
                    }
                case 4: {
                        if (!(message.packetReceiveTimes && message.packetReceiveTimes.length))
                            message.packetReceiveTimes = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.packetReceiveTimes.push(reader.int64());
                        } else
                            message.packetReceiveTimes.push(reader.int64());
                        break;
                    }
                case 5: {
                        if (!(message.networkInfo && message.networkInfo.length))
                            message.networkInfo = [];
                        message.networkInfo.push($root.proto.TalkburstReceptionStatistics.NetworkInfo.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.numRebuffering = reader.int32();
                        break;
                    }
                case 7: {
                        message.numPlc = reader.int32();
                        break;
                    }
                case 8: {
                        message.numLate = reader.int32();
                        break;
                    }
                case 9: {
                        message.numLost = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("startedReceiveTime"))
                throw $util.ProtocolError("missing required 'startedReceiveTime'", { instance: message });
            if (!message.hasOwnProperty("stoppedReceiveTime"))
                throw $util.ProtocolError("missing required 'stoppedReceiveTime'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstReceptionStatistics message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstReceptionStatistics} TalkburstReceptionStatistics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstReceptionStatistics.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstReceptionStatistics message.
         * @function verify
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstReceptionStatistics.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.startedReceiveTime) && !(message.startedReceiveTime && $util.isInteger(message.startedReceiveTime.low) && $util.isInteger(message.startedReceiveTime.high)))
                return "startedReceiveTime: integer|Long expected";
            if (!$util.isInteger(message.stoppedReceiveTime) && !(message.stoppedReceiveTime && $util.isInteger(message.stoppedReceiveTime.low) && $util.isInteger(message.stoppedReceiveTime.high)))
                return "stoppedReceiveTime: integer|Long expected";
            if (message.packetSeqNos != null && message.hasOwnProperty("packetSeqNos")) {
                if (!Array.isArray(message.packetSeqNos))
                    return "packetSeqNos: array expected";
                for (var i = 0; i < message.packetSeqNos.length; ++i)
                    if (!$util.isInteger(message.packetSeqNos[i]))
                        return "packetSeqNos: integer[] expected";
            }
            if (message.packetReceiveTimes != null && message.hasOwnProperty("packetReceiveTimes")) {
                if (!Array.isArray(message.packetReceiveTimes))
                    return "packetReceiveTimes: array expected";
                for (var i = 0; i < message.packetReceiveTimes.length; ++i)
                    if (!$util.isInteger(message.packetReceiveTimes[i]) && !(message.packetReceiveTimes[i] && $util.isInteger(message.packetReceiveTimes[i].low) && $util.isInteger(message.packetReceiveTimes[i].high)))
                        return "packetReceiveTimes: integer|Long[] expected";
            }
            if (message.networkInfo != null && message.hasOwnProperty("networkInfo")) {
                if (!Array.isArray(message.networkInfo))
                    return "networkInfo: array expected";
                for (var i = 0; i < message.networkInfo.length; ++i) {
                    var error = $root.proto.TalkburstReceptionStatistics.NetworkInfo.verify(message.networkInfo[i]);
                    if (error)
                        return "networkInfo." + error;
                }
            }
            if (message.numRebuffering != null && message.hasOwnProperty("numRebuffering"))
                if (!$util.isInteger(message.numRebuffering))
                    return "numRebuffering: integer expected";
            if (message.numPlc != null && message.hasOwnProperty("numPlc"))
                if (!$util.isInteger(message.numPlc))
                    return "numPlc: integer expected";
            if (message.numLate != null && message.hasOwnProperty("numLate"))
                if (!$util.isInteger(message.numLate))
                    return "numLate: integer expected";
            if (message.numLost != null && message.hasOwnProperty("numLost"))
                if (!$util.isInteger(message.numLost))
                    return "numLost: integer expected";
            return null;
        };

        /**
         * Creates a TalkburstReceptionStatistics message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstReceptionStatistics} TalkburstReceptionStatistics
         */
        TalkburstReceptionStatistics.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstReceptionStatistics)
                return object;
            var message = new $root.proto.TalkburstReceptionStatistics();
            if (object.startedReceiveTime != null)
                if ($util.Long)
                    (message.startedReceiveTime = $util.Long.fromValue(object.startedReceiveTime)).unsigned = false;
                else if (typeof object.startedReceiveTime === "string")
                    message.startedReceiveTime = parseInt(object.startedReceiveTime, 10);
                else if (typeof object.startedReceiveTime === "number")
                    message.startedReceiveTime = object.startedReceiveTime;
                else if (typeof object.startedReceiveTime === "object")
                    message.startedReceiveTime = new $util.LongBits(object.startedReceiveTime.low >>> 0, object.startedReceiveTime.high >>> 0).toNumber();
            if (object.stoppedReceiveTime != null)
                if ($util.Long)
                    (message.stoppedReceiveTime = $util.Long.fromValue(object.stoppedReceiveTime)).unsigned = false;
                else if (typeof object.stoppedReceiveTime === "string")
                    message.stoppedReceiveTime = parseInt(object.stoppedReceiveTime, 10);
                else if (typeof object.stoppedReceiveTime === "number")
                    message.stoppedReceiveTime = object.stoppedReceiveTime;
                else if (typeof object.stoppedReceiveTime === "object")
                    message.stoppedReceiveTime = new $util.LongBits(object.stoppedReceiveTime.low >>> 0, object.stoppedReceiveTime.high >>> 0).toNumber();
            if (object.packetSeqNos) {
                if (!Array.isArray(object.packetSeqNos))
                    throw TypeError(".proto.TalkburstReceptionStatistics.packetSeqNos: array expected");
                message.packetSeqNos = [];
                for (var i = 0; i < object.packetSeqNos.length; ++i)
                    message.packetSeqNos[i] = object.packetSeqNos[i] | 0;
            }
            if (object.packetReceiveTimes) {
                if (!Array.isArray(object.packetReceiveTimes))
                    throw TypeError(".proto.TalkburstReceptionStatistics.packetReceiveTimes: array expected");
                message.packetReceiveTimes = [];
                for (var i = 0; i < object.packetReceiveTimes.length; ++i)
                    if ($util.Long)
                        (message.packetReceiveTimes[i] = $util.Long.fromValue(object.packetReceiveTimes[i])).unsigned = false;
                    else if (typeof object.packetReceiveTimes[i] === "string")
                        message.packetReceiveTimes[i] = parseInt(object.packetReceiveTimes[i], 10);
                    else if (typeof object.packetReceiveTimes[i] === "number")
                        message.packetReceiveTimes[i] = object.packetReceiveTimes[i];
                    else if (typeof object.packetReceiveTimes[i] === "object")
                        message.packetReceiveTimes[i] = new $util.LongBits(object.packetReceiveTimes[i].low >>> 0, object.packetReceiveTimes[i].high >>> 0).toNumber();
            }
            if (object.networkInfo) {
                if (!Array.isArray(object.networkInfo))
                    throw TypeError(".proto.TalkburstReceptionStatistics.networkInfo: array expected");
                message.networkInfo = [];
                for (var i = 0; i < object.networkInfo.length; ++i) {
                    if (typeof object.networkInfo[i] !== "object")
                        throw TypeError(".proto.TalkburstReceptionStatistics.networkInfo: object expected");
                    message.networkInfo[i] = $root.proto.TalkburstReceptionStatistics.NetworkInfo.fromObject(object.networkInfo[i]);
                }
            }
            if (object.numRebuffering != null)
                message.numRebuffering = object.numRebuffering | 0;
            if (object.numPlc != null)
                message.numPlc = object.numPlc | 0;
            if (object.numLate != null)
                message.numLate = object.numLate | 0;
            if (object.numLost != null)
                message.numLost = object.numLost | 0;
            return message;
        };

        /**
         * Creates a plain object from a TalkburstReceptionStatistics message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {proto.TalkburstReceptionStatistics} message TalkburstReceptionStatistics
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstReceptionStatistics.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.packetSeqNos = [];
                object.packetReceiveTimes = [];
                object.networkInfo = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.startedReceiveTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startedReceiveTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.stoppedReceiveTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.stoppedReceiveTime = options.longs === String ? "0" : 0;
                object.numRebuffering = 0;
                object.numPlc = 0;
                object.numLate = 0;
                object.numLost = 0;
            }
            if (message.startedReceiveTime != null && message.hasOwnProperty("startedReceiveTime"))
                if (typeof message.startedReceiveTime === "number")
                    object.startedReceiveTime = options.longs === String ? String(message.startedReceiveTime) : message.startedReceiveTime;
                else
                    object.startedReceiveTime = options.longs === String ? $util.Long.prototype.toString.call(message.startedReceiveTime) : options.longs === Number ? new $util.LongBits(message.startedReceiveTime.low >>> 0, message.startedReceiveTime.high >>> 0).toNumber() : message.startedReceiveTime;
            if (message.stoppedReceiveTime != null && message.hasOwnProperty("stoppedReceiveTime"))
                if (typeof message.stoppedReceiveTime === "number")
                    object.stoppedReceiveTime = options.longs === String ? String(message.stoppedReceiveTime) : message.stoppedReceiveTime;
                else
                    object.stoppedReceiveTime = options.longs === String ? $util.Long.prototype.toString.call(message.stoppedReceiveTime) : options.longs === Number ? new $util.LongBits(message.stoppedReceiveTime.low >>> 0, message.stoppedReceiveTime.high >>> 0).toNumber() : message.stoppedReceiveTime;
            if (message.packetSeqNos && message.packetSeqNos.length) {
                object.packetSeqNos = [];
                for (var j = 0; j < message.packetSeqNos.length; ++j)
                    object.packetSeqNos[j] = message.packetSeqNos[j];
            }
            if (message.packetReceiveTimes && message.packetReceiveTimes.length) {
                object.packetReceiveTimes = [];
                for (var j = 0; j < message.packetReceiveTimes.length; ++j)
                    if (typeof message.packetReceiveTimes[j] === "number")
                        object.packetReceiveTimes[j] = options.longs === String ? String(message.packetReceiveTimes[j]) : message.packetReceiveTimes[j];
                    else
                        object.packetReceiveTimes[j] = options.longs === String ? $util.Long.prototype.toString.call(message.packetReceiveTimes[j]) : options.longs === Number ? new $util.LongBits(message.packetReceiveTimes[j].low >>> 0, message.packetReceiveTimes[j].high >>> 0).toNumber() : message.packetReceiveTimes[j];
            }
            if (message.networkInfo && message.networkInfo.length) {
                object.networkInfo = [];
                for (var j = 0; j < message.networkInfo.length; ++j)
                    object.networkInfo[j] = $root.proto.TalkburstReceptionStatistics.NetworkInfo.toObject(message.networkInfo[j], options);
            }
            if (message.numRebuffering != null && message.hasOwnProperty("numRebuffering"))
                object.numRebuffering = message.numRebuffering;
            if (message.numPlc != null && message.hasOwnProperty("numPlc"))
                object.numPlc = message.numPlc;
            if (message.numLate != null && message.hasOwnProperty("numLate"))
                object.numLate = message.numLate;
            if (message.numLost != null && message.hasOwnProperty("numLost"))
                object.numLost = message.numLost;
            return object;
        };

        /**
         * Converts this TalkburstReceptionStatistics to JSON.
         * @function toJSON
         * @memberof proto.TalkburstReceptionStatistics
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstReceptionStatistics.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstReceptionStatistics
         * @function getTypeUrl
         * @memberof proto.TalkburstReceptionStatistics
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstReceptionStatistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstReceptionStatistics";
        };

        TalkburstReceptionStatistics.NetworkInfo = (function() {

            /**
             * Properties of a NetworkInfo.
             * @memberof proto.TalkburstReceptionStatistics
             * @interface INetworkInfo
             * @property {number|Long} time NetworkInfo time
             * @property {string} type NetworkInfo type
             */

            /**
             * Constructs a new NetworkInfo.
             * @memberof proto.TalkburstReceptionStatistics
             * @classdesc Represents a NetworkInfo.
             * @implements INetworkInfo
             * @constructor
             * @param {proto.TalkburstReceptionStatistics.INetworkInfo=} [properties] Properties to set
             */
            function NetworkInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NetworkInfo time.
             * @member {number|Long} time
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @instance
             */
            NetworkInfo.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * NetworkInfo type.
             * @member {string} type
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @instance
             */
            NetworkInfo.prototype.type = "";

            /**
             * Creates a new NetworkInfo instance using the specified properties.
             * @function create
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {proto.TalkburstReceptionStatistics.INetworkInfo=} [properties] Properties to set
             * @returns {proto.TalkburstReceptionStatistics.NetworkInfo} NetworkInfo instance
             */
            NetworkInfo.create = function create(properties) {
                return new NetworkInfo(properties);
            };

            /**
             * Encodes the specified NetworkInfo message. Does not implicitly {@link proto.TalkburstReceptionStatistics.NetworkInfo.verify|verify} messages.
             * @function encode
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {proto.TalkburstReceptionStatistics.INetworkInfo} message NetworkInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NetworkInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                return writer;
            };

            /**
             * Encodes the specified NetworkInfo message, length delimited. Does not implicitly {@link proto.TalkburstReceptionStatistics.NetworkInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {proto.TalkburstReceptionStatistics.INetworkInfo} message NetworkInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NetworkInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NetworkInfo message from the specified reader or buffer.
             * @function decode
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {proto.TalkburstReceptionStatistics.NetworkInfo} NetworkInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NetworkInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstReceptionStatistics.NetworkInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.time = reader.int64();
                            break;
                        }
                    case 2: {
                            message.type = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                if (!message.hasOwnProperty("time"))
                    throw $util.ProtocolError("missing required 'time'", { instance: message });
                if (!message.hasOwnProperty("type"))
                    throw $util.ProtocolError("missing required 'type'", { instance: message });
                return message;
            };

            /**
             * Decodes a NetworkInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {proto.TalkburstReceptionStatistics.NetworkInfo} NetworkInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NetworkInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NetworkInfo message.
             * @function verify
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NetworkInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
                if (!$util.isString(message.type))
                    return "type: string expected";
                return null;
            };

            /**
             * Creates a NetworkInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {proto.TalkburstReceptionStatistics.NetworkInfo} NetworkInfo
             */
            NetworkInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.proto.TalkburstReceptionStatistics.NetworkInfo)
                    return object;
                var message = new $root.proto.TalkburstReceptionStatistics.NetworkInfo();
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                if (object.type != null)
                    message.type = String(object.type);
                return message;
            };

            /**
             * Creates a plain object from a NetworkInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {proto.TalkburstReceptionStatistics.NetworkInfo} message NetworkInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NetworkInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                    object.type = "";
                }
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = message.type;
                return object;
            };

            /**
             * Converts this NetworkInfo to JSON.
             * @function toJSON
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NetworkInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NetworkInfo
             * @function getTypeUrl
             * @memberof proto.TalkburstReceptionStatistics.NetworkInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NetworkInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/proto.TalkburstReceptionStatistics.NetworkInfo";
            };

            return NetworkInfo;
        })();

        return TalkburstReceptionStatistics;
    })();

    proto.TalkburstTransmissionAPIv1Client = (function() {

        /**
         * Properties of a TalkburstTransmissionAPIv1Client.
         * @memberof proto
         * @interface ITalkburstTransmissionAPIv1Client
         * @property {proto.ITalkburstTransmissionModuleSetupRequest|null} [setupRequest] TalkburstTransmissionAPIv1Client setupRequest
         * @property {proto.ITalkburstTransmissionStartRequest|null} [startRequest] TalkburstTransmissionAPIv1Client startRequest
         * @property {proto.ITalkburstTransmissionStopRequest|null} [stopRequest] TalkburstTransmissionAPIv1Client stopRequest
         */

        /**
         * Constructs a new TalkburstTransmissionAPIv1Client.
         * @memberof proto
         * @classdesc Represents a TalkburstTransmissionAPIv1Client.
         * @implements ITalkburstTransmissionAPIv1Client
         * @constructor
         * @param {proto.ITalkburstTransmissionAPIv1Client=} [properties] Properties to set
         */
        function TalkburstTransmissionAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstTransmissionAPIv1Client setupRequest.
         * @member {proto.ITalkburstTransmissionModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @instance
         */
        TalkburstTransmissionAPIv1Client.prototype.setupRequest = null;

        /**
         * TalkburstTransmissionAPIv1Client startRequest.
         * @member {proto.ITalkburstTransmissionStartRequest|null|undefined} startRequest
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @instance
         */
        TalkburstTransmissionAPIv1Client.prototype.startRequest = null;

        /**
         * TalkburstTransmissionAPIv1Client stopRequest.
         * @member {proto.ITalkburstTransmissionStopRequest|null|undefined} stopRequest
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @instance
         */
        TalkburstTransmissionAPIv1Client.prototype.stopRequest = null;

        /**
         * Creates a new TalkburstTransmissionAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {proto.ITalkburstTransmissionAPIv1Client=} [properties] Properties to set
         * @returns {proto.TalkburstTransmissionAPIv1Client} TalkburstTransmissionAPIv1Client instance
         */
        TalkburstTransmissionAPIv1Client.create = function create(properties) {
            return new TalkburstTransmissionAPIv1Client(properties);
        };

        /**
         * Encodes the specified TalkburstTransmissionAPIv1Client message. Does not implicitly {@link proto.TalkburstTransmissionAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {proto.ITalkburstTransmissionAPIv1Client} message TalkburstTransmissionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.TalkburstTransmissionModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.startRequest != null && Object.hasOwnProperty.call(message, "startRequest"))
                $root.proto.TalkburstTransmissionStartRequest.encode(message.startRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.stopRequest != null && Object.hasOwnProperty.call(message, "stopRequest"))
                $root.proto.TalkburstTransmissionStopRequest.encode(message.stopRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TalkburstTransmissionAPIv1Client message, length delimited. Does not implicitly {@link proto.TalkburstTransmissionAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {proto.ITalkburstTransmissionAPIv1Client} message TalkburstTransmissionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstTransmissionAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstTransmissionAPIv1Client} TalkburstTransmissionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstTransmissionAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.TalkburstTransmissionModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.startRequest = $root.proto.TalkburstTransmissionStartRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.stopRequest = $root.proto.TalkburstTransmissionStopRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstTransmissionAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstTransmissionAPIv1Client} TalkburstTransmissionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstTransmissionAPIv1Client message.
         * @function verify
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstTransmissionAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.TalkburstTransmissionModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.startRequest != null && message.hasOwnProperty("startRequest")) {
                var error = $root.proto.TalkburstTransmissionStartRequest.verify(message.startRequest);
                if (error)
                    return "startRequest." + error;
            }
            if (message.stopRequest != null && message.hasOwnProperty("stopRequest")) {
                var error = $root.proto.TalkburstTransmissionStopRequest.verify(message.stopRequest);
                if (error)
                    return "stopRequest." + error;
            }
            return null;
        };

        /**
         * Creates a TalkburstTransmissionAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstTransmissionAPIv1Client} TalkburstTransmissionAPIv1Client
         */
        TalkburstTransmissionAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstTransmissionAPIv1Client)
                return object;
            var message = new $root.proto.TalkburstTransmissionAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.TalkburstTransmissionAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.TalkburstTransmissionModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.startRequest != null) {
                if (typeof object.startRequest !== "object")
                    throw TypeError(".proto.TalkburstTransmissionAPIv1Client.startRequest: object expected");
                message.startRequest = $root.proto.TalkburstTransmissionStartRequest.fromObject(object.startRequest);
            }
            if (object.stopRequest != null) {
                if (typeof object.stopRequest !== "object")
                    throw TypeError(".proto.TalkburstTransmissionAPIv1Client.stopRequest: object expected");
                message.stopRequest = $root.proto.TalkburstTransmissionStopRequest.fromObject(object.stopRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a TalkburstTransmissionAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {proto.TalkburstTransmissionAPIv1Client} message TalkburstTransmissionAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstTransmissionAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.startRequest = null;
                object.stopRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.TalkburstTransmissionModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.startRequest != null && message.hasOwnProperty("startRequest"))
                object.startRequest = $root.proto.TalkburstTransmissionStartRequest.toObject(message.startRequest, options);
            if (message.stopRequest != null && message.hasOwnProperty("stopRequest"))
                object.stopRequest = $root.proto.TalkburstTransmissionStopRequest.toObject(message.stopRequest, options);
            return object;
        };

        /**
         * Converts this TalkburstTransmissionAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstTransmissionAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstTransmissionAPIv1Client
         * @function getTypeUrl
         * @memberof proto.TalkburstTransmissionAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstTransmissionAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstTransmissionAPIv1Client";
        };

        return TalkburstTransmissionAPIv1Client;
    })();

    proto.TalkburstTransmissionModuleSetupRequest = (function() {

        /**
         * Properties of a TalkburstTransmissionModuleSetupRequest.
         * @memberof proto
         * @interface ITalkburstTransmissionModuleSetupRequest
         */

        /**
         * Constructs a new TalkburstTransmissionModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a TalkburstTransmissionModuleSetupRequest.
         * @implements ITalkburstTransmissionModuleSetupRequest
         * @constructor
         * @param {proto.ITalkburstTransmissionModuleSetupRequest=} [properties] Properties to set
         */
        function TalkburstTransmissionModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TalkburstTransmissionModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {proto.ITalkburstTransmissionModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.TalkburstTransmissionModuleSetupRequest} TalkburstTransmissionModuleSetupRequest instance
         */
        TalkburstTransmissionModuleSetupRequest.create = function create(properties) {
            return new TalkburstTransmissionModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified TalkburstTransmissionModuleSetupRequest message. Does not implicitly {@link proto.TalkburstTransmissionModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {proto.ITalkburstTransmissionModuleSetupRequest} message TalkburstTransmissionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TalkburstTransmissionModuleSetupRequest message, length delimited. Does not implicitly {@link proto.TalkburstTransmissionModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {proto.ITalkburstTransmissionModuleSetupRequest} message TalkburstTransmissionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstTransmissionModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstTransmissionModuleSetupRequest} TalkburstTransmissionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstTransmissionModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstTransmissionModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstTransmissionModuleSetupRequest} TalkburstTransmissionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstTransmissionModuleSetupRequest message.
         * @function verify
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstTransmissionModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TalkburstTransmissionModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstTransmissionModuleSetupRequest} TalkburstTransmissionModuleSetupRequest
         */
        TalkburstTransmissionModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstTransmissionModuleSetupRequest)
                return object;
            return new $root.proto.TalkburstTransmissionModuleSetupRequest();
        };

        /**
         * Creates a plain object from a TalkburstTransmissionModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {proto.TalkburstTransmissionModuleSetupRequest} message TalkburstTransmissionModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstTransmissionModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TalkburstTransmissionModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstTransmissionModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstTransmissionModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.TalkburstTransmissionModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstTransmissionModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstTransmissionModuleSetupRequest";
        };

        return TalkburstTransmissionModuleSetupRequest;
    })();

    proto.TalkburstTransmissionStartRequest = (function() {

        /**
         * Properties of a TalkburstTransmissionStartRequest.
         * @memberof proto
         * @interface ITalkburstTransmissionStartRequest
         * @property {number|Long} talkburstId TalkburstTransmissionStartRequest talkburstId
         * @property {number|Long} sessionId TalkburstTransmissionStartRequest sessionId
         * @property {string|null} [muteGroupId] TalkburstTransmissionStartRequest muteGroupId
         * @property {boolean|null} [tone] TalkburstTransmissionStartRequest tone
         */

        /**
         * Constructs a new TalkburstTransmissionStartRequest.
         * @memberof proto
         * @classdesc Represents a TalkburstTransmissionStartRequest.
         * @implements ITalkburstTransmissionStartRequest
         * @constructor
         * @param {proto.ITalkburstTransmissionStartRequest=} [properties] Properties to set
         */
        function TalkburstTransmissionStartRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstTransmissionStartRequest talkburstId.
         * @member {number|Long} talkburstId
         * @memberof proto.TalkburstTransmissionStartRequest
         * @instance
         */
        TalkburstTransmissionStartRequest.prototype.talkburstId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstTransmissionStartRequest sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.TalkburstTransmissionStartRequest
         * @instance
         */
        TalkburstTransmissionStartRequest.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstTransmissionStartRequest muteGroupId.
         * @member {string} muteGroupId
         * @memberof proto.TalkburstTransmissionStartRequest
         * @instance
         */
        TalkburstTransmissionStartRequest.prototype.muteGroupId = "";

        /**
         * TalkburstTransmissionStartRequest tone.
         * @member {boolean} tone
         * @memberof proto.TalkburstTransmissionStartRequest
         * @instance
         */
        TalkburstTransmissionStartRequest.prototype.tone = false;

        /**
         * Creates a new TalkburstTransmissionStartRequest instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {proto.ITalkburstTransmissionStartRequest=} [properties] Properties to set
         * @returns {proto.TalkburstTransmissionStartRequest} TalkburstTransmissionStartRequest instance
         */
        TalkburstTransmissionStartRequest.create = function create(properties) {
            return new TalkburstTransmissionStartRequest(properties);
        };

        /**
         * Encodes the specified TalkburstTransmissionStartRequest message. Does not implicitly {@link proto.TalkburstTransmissionStartRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {proto.ITalkburstTransmissionStartRequest} message TalkburstTransmissionStartRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionStartRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.talkburstId);
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sessionId);
            if (message.muteGroupId != null && Object.hasOwnProperty.call(message, "muteGroupId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.muteGroupId);
            if (message.tone != null && Object.hasOwnProperty.call(message, "tone"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.tone);
            return writer;
        };

        /**
         * Encodes the specified TalkburstTransmissionStartRequest message, length delimited. Does not implicitly {@link proto.TalkburstTransmissionStartRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {proto.ITalkburstTransmissionStartRequest} message TalkburstTransmissionStartRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionStartRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstTransmissionStartRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstTransmissionStartRequest} TalkburstTransmissionStartRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionStartRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstTransmissionStartRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.talkburstId = reader.int64();
                        break;
                    }
                case 2: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 3: {
                        message.muteGroupId = reader.string();
                        break;
                    }
                case 4: {
                        message.tone = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("talkburstId"))
                throw $util.ProtocolError("missing required 'talkburstId'", { instance: message });
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstTransmissionStartRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstTransmissionStartRequest} TalkburstTransmissionStartRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionStartRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstTransmissionStartRequest message.
         * @function verify
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstTransmissionStartRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.talkburstId) && !(message.talkburstId && $util.isInteger(message.talkburstId.low) && $util.isInteger(message.talkburstId.high)))
                return "talkburstId: integer|Long expected";
            if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                return "sessionId: integer|Long expected";
            if (message.muteGroupId != null && message.hasOwnProperty("muteGroupId"))
                if (!$util.isString(message.muteGroupId))
                    return "muteGroupId: string expected";
            if (message.tone != null && message.hasOwnProperty("tone"))
                if (typeof message.tone !== "boolean")
                    return "tone: boolean expected";
            return null;
        };

        /**
         * Creates a TalkburstTransmissionStartRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstTransmissionStartRequest} TalkburstTransmissionStartRequest
         */
        TalkburstTransmissionStartRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstTransmissionStartRequest)
                return object;
            var message = new $root.proto.TalkburstTransmissionStartRequest();
            if (object.talkburstId != null)
                if ($util.Long)
                    (message.talkburstId = $util.Long.fromValue(object.talkburstId)).unsigned = false;
                else if (typeof object.talkburstId === "string")
                    message.talkburstId = parseInt(object.talkburstId, 10);
                else if (typeof object.talkburstId === "number")
                    message.talkburstId = object.talkburstId;
                else if (typeof object.talkburstId === "object")
                    message.talkburstId = new $util.LongBits(object.talkburstId.low >>> 0, object.talkburstId.high >>> 0).toNumber();
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.muteGroupId != null)
                message.muteGroupId = String(object.muteGroupId);
            if (object.tone != null)
                message.tone = Boolean(object.tone);
            return message;
        };

        /**
         * Creates a plain object from a TalkburstTransmissionStartRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {proto.TalkburstTransmissionStartRequest} message TalkburstTransmissionStartRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstTransmissionStartRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.talkburstId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.talkburstId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                object.muteGroupId = "";
                object.tone = false;
            }
            if (message.talkburstId != null && message.hasOwnProperty("talkburstId"))
                if (typeof message.talkburstId === "number")
                    object.talkburstId = options.longs === String ? String(message.talkburstId) : message.talkburstId;
                else
                    object.talkburstId = options.longs === String ? $util.Long.prototype.toString.call(message.talkburstId) : options.longs === Number ? new $util.LongBits(message.talkburstId.low >>> 0, message.talkburstId.high >>> 0).toNumber() : message.talkburstId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.muteGroupId != null && message.hasOwnProperty("muteGroupId"))
                object.muteGroupId = message.muteGroupId;
            if (message.tone != null && message.hasOwnProperty("tone"))
                object.tone = message.tone;
            return object;
        };

        /**
         * Converts this TalkburstTransmissionStartRequest to JSON.
         * @function toJSON
         * @memberof proto.TalkburstTransmissionStartRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstTransmissionStartRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstTransmissionStartRequest
         * @function getTypeUrl
         * @memberof proto.TalkburstTransmissionStartRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstTransmissionStartRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstTransmissionStartRequest";
        };

        return TalkburstTransmissionStartRequest;
    })();

    proto.TalkburstTransmissionStopRequest = (function() {

        /**
         * Properties of a TalkburstTransmissionStopRequest.
         * @memberof proto
         * @interface ITalkburstTransmissionStopRequest
         * @property {number|Long} talkburstId TalkburstTransmissionStopRequest talkburstId
         * @property {number} lastSentSeqNo TalkburstTransmissionStopRequest lastSentSeqNo
         */

        /**
         * Constructs a new TalkburstTransmissionStopRequest.
         * @memberof proto
         * @classdesc Represents a TalkburstTransmissionStopRequest.
         * @implements ITalkburstTransmissionStopRequest
         * @constructor
         * @param {proto.ITalkburstTransmissionStopRequest=} [properties] Properties to set
         */
        function TalkburstTransmissionStopRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstTransmissionStopRequest talkburstId.
         * @member {number|Long} talkburstId
         * @memberof proto.TalkburstTransmissionStopRequest
         * @instance
         */
        TalkburstTransmissionStopRequest.prototype.talkburstId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TalkburstTransmissionStopRequest lastSentSeqNo.
         * @member {number} lastSentSeqNo
         * @memberof proto.TalkburstTransmissionStopRequest
         * @instance
         */
        TalkburstTransmissionStopRequest.prototype.lastSentSeqNo = 0;

        /**
         * Creates a new TalkburstTransmissionStopRequest instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {proto.ITalkburstTransmissionStopRequest=} [properties] Properties to set
         * @returns {proto.TalkburstTransmissionStopRequest} TalkburstTransmissionStopRequest instance
         */
        TalkburstTransmissionStopRequest.create = function create(properties) {
            return new TalkburstTransmissionStopRequest(properties);
        };

        /**
         * Encodes the specified TalkburstTransmissionStopRequest message. Does not implicitly {@link proto.TalkburstTransmissionStopRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {proto.ITalkburstTransmissionStopRequest} message TalkburstTransmissionStopRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionStopRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.talkburstId);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.lastSentSeqNo);
            return writer;
        };

        /**
         * Encodes the specified TalkburstTransmissionStopRequest message, length delimited. Does not implicitly {@link proto.TalkburstTransmissionStopRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {proto.ITalkburstTransmissionStopRequest} message TalkburstTransmissionStopRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionStopRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstTransmissionStopRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstTransmissionStopRequest} TalkburstTransmissionStopRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionStopRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstTransmissionStopRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.talkburstId = reader.int64();
                        break;
                    }
                case 2: {
                        message.lastSentSeqNo = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("talkburstId"))
                throw $util.ProtocolError("missing required 'talkburstId'", { instance: message });
            if (!message.hasOwnProperty("lastSentSeqNo"))
                throw $util.ProtocolError("missing required 'lastSentSeqNo'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstTransmissionStopRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstTransmissionStopRequest} TalkburstTransmissionStopRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionStopRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstTransmissionStopRequest message.
         * @function verify
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstTransmissionStopRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.talkburstId) && !(message.talkburstId && $util.isInteger(message.talkburstId.low) && $util.isInteger(message.talkburstId.high)))
                return "talkburstId: integer|Long expected";
            if (!$util.isInteger(message.lastSentSeqNo))
                return "lastSentSeqNo: integer expected";
            return null;
        };

        /**
         * Creates a TalkburstTransmissionStopRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstTransmissionStopRequest} TalkburstTransmissionStopRequest
         */
        TalkburstTransmissionStopRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstTransmissionStopRequest)
                return object;
            var message = new $root.proto.TalkburstTransmissionStopRequest();
            if (object.talkburstId != null)
                if ($util.Long)
                    (message.talkburstId = $util.Long.fromValue(object.talkburstId)).unsigned = false;
                else if (typeof object.talkburstId === "string")
                    message.talkburstId = parseInt(object.talkburstId, 10);
                else if (typeof object.talkburstId === "number")
                    message.talkburstId = object.talkburstId;
                else if (typeof object.talkburstId === "object")
                    message.talkburstId = new $util.LongBits(object.talkburstId.low >>> 0, object.talkburstId.high >>> 0).toNumber();
            if (object.lastSentSeqNo != null)
                message.lastSentSeqNo = object.lastSentSeqNo | 0;
            return message;
        };

        /**
         * Creates a plain object from a TalkburstTransmissionStopRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {proto.TalkburstTransmissionStopRequest} message TalkburstTransmissionStopRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstTransmissionStopRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.talkburstId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.talkburstId = options.longs === String ? "0" : 0;
                object.lastSentSeqNo = 0;
            }
            if (message.talkburstId != null && message.hasOwnProperty("talkburstId"))
                if (typeof message.talkburstId === "number")
                    object.talkburstId = options.longs === String ? String(message.talkburstId) : message.talkburstId;
                else
                    object.talkburstId = options.longs === String ? $util.Long.prototype.toString.call(message.talkburstId) : options.longs === Number ? new $util.LongBits(message.talkburstId.low >>> 0, message.talkburstId.high >>> 0).toNumber() : message.talkburstId;
            if (message.lastSentSeqNo != null && message.hasOwnProperty("lastSentSeqNo"))
                object.lastSentSeqNo = message.lastSentSeqNo;
            return object;
        };

        /**
         * Converts this TalkburstTransmissionStopRequest to JSON.
         * @function toJSON
         * @memberof proto.TalkburstTransmissionStopRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstTransmissionStopRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstTransmissionStopRequest
         * @function getTypeUrl
         * @memberof proto.TalkburstTransmissionStopRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstTransmissionStopRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstTransmissionStopRequest";
        };

        return TalkburstTransmissionStopRequest;
    })();

    proto.TalkburstTransmissionAPIv1Server = (function() {

        /**
         * Properties of a TalkburstTransmissionAPIv1Server.
         * @memberof proto
         * @interface ITalkburstTransmissionAPIv1Server
         * @property {proto.ITalkburstTransmissionInterrupted|null} [interrupted] TalkburstTransmissionAPIv1Server interrupted
         */

        /**
         * Constructs a new TalkburstTransmissionAPIv1Server.
         * @memberof proto
         * @classdesc Represents a TalkburstTransmissionAPIv1Server.
         * @implements ITalkburstTransmissionAPIv1Server
         * @constructor
         * @param {proto.ITalkburstTransmissionAPIv1Server=} [properties] Properties to set
         */
        function TalkburstTransmissionAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstTransmissionAPIv1Server interrupted.
         * @member {proto.ITalkburstTransmissionInterrupted|null|undefined} interrupted
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @instance
         */
        TalkburstTransmissionAPIv1Server.prototype.interrupted = null;

        /**
         * Creates a new TalkburstTransmissionAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {proto.ITalkburstTransmissionAPIv1Server=} [properties] Properties to set
         * @returns {proto.TalkburstTransmissionAPIv1Server} TalkburstTransmissionAPIv1Server instance
         */
        TalkburstTransmissionAPIv1Server.create = function create(properties) {
            return new TalkburstTransmissionAPIv1Server(properties);
        };

        /**
         * Encodes the specified TalkburstTransmissionAPIv1Server message. Does not implicitly {@link proto.TalkburstTransmissionAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {proto.ITalkburstTransmissionAPIv1Server} message TalkburstTransmissionAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.interrupted != null && Object.hasOwnProperty.call(message, "interrupted"))
                $root.proto.TalkburstTransmissionInterrupted.encode(message.interrupted, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TalkburstTransmissionAPIv1Server message, length delimited. Does not implicitly {@link proto.TalkburstTransmissionAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {proto.ITalkburstTransmissionAPIv1Server} message TalkburstTransmissionAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstTransmissionAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstTransmissionAPIv1Server} TalkburstTransmissionAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstTransmissionAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.interrupted = $root.proto.TalkburstTransmissionInterrupted.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TalkburstTransmissionAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstTransmissionAPIv1Server} TalkburstTransmissionAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstTransmissionAPIv1Server message.
         * @function verify
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstTransmissionAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.interrupted != null && message.hasOwnProperty("interrupted")) {
                var error = $root.proto.TalkburstTransmissionInterrupted.verify(message.interrupted);
                if (error)
                    return "interrupted." + error;
            }
            return null;
        };

        /**
         * Creates a TalkburstTransmissionAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstTransmissionAPIv1Server} TalkburstTransmissionAPIv1Server
         */
        TalkburstTransmissionAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstTransmissionAPIv1Server)
                return object;
            var message = new $root.proto.TalkburstTransmissionAPIv1Server();
            if (object.interrupted != null) {
                if (typeof object.interrupted !== "object")
                    throw TypeError(".proto.TalkburstTransmissionAPIv1Server.interrupted: object expected");
                message.interrupted = $root.proto.TalkburstTransmissionInterrupted.fromObject(object.interrupted);
            }
            return message;
        };

        /**
         * Creates a plain object from a TalkburstTransmissionAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {proto.TalkburstTransmissionAPIv1Server} message TalkburstTransmissionAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstTransmissionAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.interrupted = null;
            if (message.interrupted != null && message.hasOwnProperty("interrupted"))
                object.interrupted = $root.proto.TalkburstTransmissionInterrupted.toObject(message.interrupted, options);
            return object;
        };

        /**
         * Converts this TalkburstTransmissionAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstTransmissionAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstTransmissionAPIv1Server
         * @function getTypeUrl
         * @memberof proto.TalkburstTransmissionAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstTransmissionAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstTransmissionAPIv1Server";
        };

        return TalkburstTransmissionAPIv1Server;
    })();

    proto.TalkburstTransmissionInterrupted = (function() {

        /**
         * Properties of a TalkburstTransmissionInterrupted.
         * @memberof proto
         * @interface ITalkburstTransmissionInterrupted
         * @property {number|Long} talkburstId TalkburstTransmissionInterrupted talkburstId
         */

        /**
         * Constructs a new TalkburstTransmissionInterrupted.
         * @memberof proto
         * @classdesc Represents a TalkburstTransmissionInterrupted.
         * @implements ITalkburstTransmissionInterrupted
         * @constructor
         * @param {proto.ITalkburstTransmissionInterrupted=} [properties] Properties to set
         */
        function TalkburstTransmissionInterrupted(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TalkburstTransmissionInterrupted talkburstId.
         * @member {number|Long} talkburstId
         * @memberof proto.TalkburstTransmissionInterrupted
         * @instance
         */
        TalkburstTransmissionInterrupted.prototype.talkburstId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TalkburstTransmissionInterrupted instance using the specified properties.
         * @function create
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {proto.ITalkburstTransmissionInterrupted=} [properties] Properties to set
         * @returns {proto.TalkburstTransmissionInterrupted} TalkburstTransmissionInterrupted instance
         */
        TalkburstTransmissionInterrupted.create = function create(properties) {
            return new TalkburstTransmissionInterrupted(properties);
        };

        /**
         * Encodes the specified TalkburstTransmissionInterrupted message. Does not implicitly {@link proto.TalkburstTransmissionInterrupted.verify|verify} messages.
         * @function encode
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {proto.ITalkburstTransmissionInterrupted} message TalkburstTransmissionInterrupted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionInterrupted.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.talkburstId);
            return writer;
        };

        /**
         * Encodes the specified TalkburstTransmissionInterrupted message, length delimited. Does not implicitly {@link proto.TalkburstTransmissionInterrupted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {proto.ITalkburstTransmissionInterrupted} message TalkburstTransmissionInterrupted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TalkburstTransmissionInterrupted.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TalkburstTransmissionInterrupted message from the specified reader or buffer.
         * @function decode
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.TalkburstTransmissionInterrupted} TalkburstTransmissionInterrupted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionInterrupted.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.TalkburstTransmissionInterrupted();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.talkburstId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("talkburstId"))
                throw $util.ProtocolError("missing required 'talkburstId'", { instance: message });
            return message;
        };

        /**
         * Decodes a TalkburstTransmissionInterrupted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.TalkburstTransmissionInterrupted} TalkburstTransmissionInterrupted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TalkburstTransmissionInterrupted.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TalkburstTransmissionInterrupted message.
         * @function verify
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TalkburstTransmissionInterrupted.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.talkburstId) && !(message.talkburstId && $util.isInteger(message.talkburstId.low) && $util.isInteger(message.talkburstId.high)))
                return "talkburstId: integer|Long expected";
            return null;
        };

        /**
         * Creates a TalkburstTransmissionInterrupted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.TalkburstTransmissionInterrupted} TalkburstTransmissionInterrupted
         */
        TalkburstTransmissionInterrupted.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.TalkburstTransmissionInterrupted)
                return object;
            var message = new $root.proto.TalkburstTransmissionInterrupted();
            if (object.talkburstId != null)
                if ($util.Long)
                    (message.talkburstId = $util.Long.fromValue(object.talkburstId)).unsigned = false;
                else if (typeof object.talkburstId === "string")
                    message.talkburstId = parseInt(object.talkburstId, 10);
                else if (typeof object.talkburstId === "number")
                    message.talkburstId = object.talkburstId;
                else if (typeof object.talkburstId === "object")
                    message.talkburstId = new $util.LongBits(object.talkburstId.low >>> 0, object.talkburstId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TalkburstTransmissionInterrupted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {proto.TalkburstTransmissionInterrupted} message TalkburstTransmissionInterrupted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TalkburstTransmissionInterrupted.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.talkburstId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.talkburstId = options.longs === String ? "0" : 0;
            if (message.talkburstId != null && message.hasOwnProperty("talkburstId"))
                if (typeof message.talkburstId === "number")
                    object.talkburstId = options.longs === String ? String(message.talkburstId) : message.talkburstId;
                else
                    object.talkburstId = options.longs === String ? $util.Long.prototype.toString.call(message.talkburstId) : options.longs === Number ? new $util.LongBits(message.talkburstId.low >>> 0, message.talkburstId.high >>> 0).toNumber() : message.talkburstId;
            return object;
        };

        /**
         * Converts this TalkburstTransmissionInterrupted to JSON.
         * @function toJSON
         * @memberof proto.TalkburstTransmissionInterrupted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TalkburstTransmissionInterrupted.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TalkburstTransmissionInterrupted
         * @function getTypeUrl
         * @memberof proto.TalkburstTransmissionInterrupted
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TalkburstTransmissionInterrupted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.TalkburstTransmissionInterrupted";
        };

        return TalkburstTransmissionInterrupted;
    })();

    proto.PhoneBookAPIv1Client = (function() {

        /**
         * Properties of a PhoneBookAPIv1Client.
         * @memberof proto
         * @interface IPhoneBookAPIv1Client
         * @property {proto.IPhoneBookModuleSetupRequest|null} [setupRequest] PhoneBookAPIv1Client setupRequest
         * @property {proto.IPhoneBookSubscribeRequest|null} [subscribeRequest] PhoneBookAPIv1Client subscribeRequest
         * @property {proto.IPhoneBookUnsubscribeRequest|null} [unsubscribeRequest] PhoneBookAPIv1Client unsubscribeRequest
         * @property {proto.IPhoneBookLookupRequest|null} [lookupRequest] PhoneBookAPIv1Client lookupRequest
         * @property {proto.IPhoneBookDetailedListRequest|null} [detailedListRequest] PhoneBookAPIv1Client detailedListRequest
         */

        /**
         * Constructs a new PhoneBookAPIv1Client.
         * @memberof proto
         * @classdesc Represents a PhoneBookAPIv1Client.
         * @implements IPhoneBookAPIv1Client
         * @constructor
         * @param {proto.IPhoneBookAPIv1Client=} [properties] Properties to set
         */
        function PhoneBookAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookAPIv1Client setupRequest.
         * @member {proto.IPhoneBookModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.PhoneBookAPIv1Client
         * @instance
         */
        PhoneBookAPIv1Client.prototype.setupRequest = null;

        /**
         * PhoneBookAPIv1Client subscribeRequest.
         * @member {proto.IPhoneBookSubscribeRequest|null|undefined} subscribeRequest
         * @memberof proto.PhoneBookAPIv1Client
         * @instance
         */
        PhoneBookAPIv1Client.prototype.subscribeRequest = null;

        /**
         * PhoneBookAPIv1Client unsubscribeRequest.
         * @member {proto.IPhoneBookUnsubscribeRequest|null|undefined} unsubscribeRequest
         * @memberof proto.PhoneBookAPIv1Client
         * @instance
         */
        PhoneBookAPIv1Client.prototype.unsubscribeRequest = null;

        /**
         * PhoneBookAPIv1Client lookupRequest.
         * @member {proto.IPhoneBookLookupRequest|null|undefined} lookupRequest
         * @memberof proto.PhoneBookAPIv1Client
         * @instance
         */
        PhoneBookAPIv1Client.prototype.lookupRequest = null;

        /**
         * PhoneBookAPIv1Client detailedListRequest.
         * @member {proto.IPhoneBookDetailedListRequest|null|undefined} detailedListRequest
         * @memberof proto.PhoneBookAPIv1Client
         * @instance
         */
        PhoneBookAPIv1Client.prototype.detailedListRequest = null;

        /**
         * Creates a new PhoneBookAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {proto.IPhoneBookAPIv1Client=} [properties] Properties to set
         * @returns {proto.PhoneBookAPIv1Client} PhoneBookAPIv1Client instance
         */
        PhoneBookAPIv1Client.create = function create(properties) {
            return new PhoneBookAPIv1Client(properties);
        };

        /**
         * Encodes the specified PhoneBookAPIv1Client message. Does not implicitly {@link proto.PhoneBookAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {proto.IPhoneBookAPIv1Client} message PhoneBookAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.PhoneBookModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.subscribeRequest != null && Object.hasOwnProperty.call(message, "subscribeRequest"))
                $root.proto.PhoneBookSubscribeRequest.encode(message.subscribeRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.unsubscribeRequest != null && Object.hasOwnProperty.call(message, "unsubscribeRequest"))
                $root.proto.PhoneBookUnsubscribeRequest.encode(message.unsubscribeRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.lookupRequest != null && Object.hasOwnProperty.call(message, "lookupRequest"))
                $root.proto.PhoneBookLookupRequest.encode(message.lookupRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.detailedListRequest != null && Object.hasOwnProperty.call(message, "detailedListRequest"))
                $root.proto.PhoneBookDetailedListRequest.encode(message.detailedListRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookAPIv1Client message, length delimited. Does not implicitly {@link proto.PhoneBookAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {proto.IPhoneBookAPIv1Client} message PhoneBookAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookAPIv1Client} PhoneBookAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.PhoneBookModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.subscribeRequest = $root.proto.PhoneBookSubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.unsubscribeRequest = $root.proto.PhoneBookUnsubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.lookupRequest = $root.proto.PhoneBookLookupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.detailedListRequest = $root.proto.PhoneBookDetailedListRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookAPIv1Client} PhoneBookAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookAPIv1Client message.
         * @function verify
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.PhoneBookModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest")) {
                var error = $root.proto.PhoneBookSubscribeRequest.verify(message.subscribeRequest);
                if (error)
                    return "subscribeRequest." + error;
            }
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest")) {
                var error = $root.proto.PhoneBookUnsubscribeRequest.verify(message.unsubscribeRequest);
                if (error)
                    return "unsubscribeRequest." + error;
            }
            if (message.lookupRequest != null && message.hasOwnProperty("lookupRequest")) {
                var error = $root.proto.PhoneBookLookupRequest.verify(message.lookupRequest);
                if (error)
                    return "lookupRequest." + error;
            }
            if (message.detailedListRequest != null && message.hasOwnProperty("detailedListRequest")) {
                var error = $root.proto.PhoneBookDetailedListRequest.verify(message.detailedListRequest);
                if (error)
                    return "detailedListRequest." + error;
            }
            return null;
        };

        /**
         * Creates a PhoneBookAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookAPIv1Client} PhoneBookAPIv1Client
         */
        PhoneBookAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookAPIv1Client)
                return object;
            var message = new $root.proto.PhoneBookAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.PhoneBookAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.PhoneBookModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.subscribeRequest != null) {
                if (typeof object.subscribeRequest !== "object")
                    throw TypeError(".proto.PhoneBookAPIv1Client.subscribeRequest: object expected");
                message.subscribeRequest = $root.proto.PhoneBookSubscribeRequest.fromObject(object.subscribeRequest);
            }
            if (object.unsubscribeRequest != null) {
                if (typeof object.unsubscribeRequest !== "object")
                    throw TypeError(".proto.PhoneBookAPIv1Client.unsubscribeRequest: object expected");
                message.unsubscribeRequest = $root.proto.PhoneBookUnsubscribeRequest.fromObject(object.unsubscribeRequest);
            }
            if (object.lookupRequest != null) {
                if (typeof object.lookupRequest !== "object")
                    throw TypeError(".proto.PhoneBookAPIv1Client.lookupRequest: object expected");
                message.lookupRequest = $root.proto.PhoneBookLookupRequest.fromObject(object.lookupRequest);
            }
            if (object.detailedListRequest != null) {
                if (typeof object.detailedListRequest !== "object")
                    throw TypeError(".proto.PhoneBookAPIv1Client.detailedListRequest: object expected");
                message.detailedListRequest = $root.proto.PhoneBookDetailedListRequest.fromObject(object.detailedListRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {proto.PhoneBookAPIv1Client} message PhoneBookAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.subscribeRequest = null;
                object.unsubscribeRequest = null;
                object.lookupRequest = null;
                object.detailedListRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.PhoneBookModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest"))
                object.subscribeRequest = $root.proto.PhoneBookSubscribeRequest.toObject(message.subscribeRequest, options);
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest"))
                object.unsubscribeRequest = $root.proto.PhoneBookUnsubscribeRequest.toObject(message.unsubscribeRequest, options);
            if (message.lookupRequest != null && message.hasOwnProperty("lookupRequest"))
                object.lookupRequest = $root.proto.PhoneBookLookupRequest.toObject(message.lookupRequest, options);
            if (message.detailedListRequest != null && message.hasOwnProperty("detailedListRequest"))
                object.detailedListRequest = $root.proto.PhoneBookDetailedListRequest.toObject(message.detailedListRequest, options);
            return object;
        };

        /**
         * Converts this PhoneBookAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookAPIv1Client
         * @function getTypeUrl
         * @memberof proto.PhoneBookAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookAPIv1Client";
        };

        return PhoneBookAPIv1Client;
    })();

    proto.PhoneBookModuleSetupRequest = (function() {

        /**
         * Properties of a PhoneBookModuleSetupRequest.
         * @memberof proto
         * @interface IPhoneBookModuleSetupRequest
         */

        /**
         * Constructs a new PhoneBookModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a PhoneBookModuleSetupRequest.
         * @implements IPhoneBookModuleSetupRequest
         * @constructor
         * @param {proto.IPhoneBookModuleSetupRequest=} [properties] Properties to set
         */
        function PhoneBookModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PhoneBookModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {proto.IPhoneBookModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.PhoneBookModuleSetupRequest} PhoneBookModuleSetupRequest instance
         */
        PhoneBookModuleSetupRequest.create = function create(properties) {
            return new PhoneBookModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified PhoneBookModuleSetupRequest message. Does not implicitly {@link proto.PhoneBookModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {proto.IPhoneBookModuleSetupRequest} message PhoneBookModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookModuleSetupRequest message, length delimited. Does not implicitly {@link proto.PhoneBookModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {proto.IPhoneBookModuleSetupRequest} message PhoneBookModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookModuleSetupRequest} PhoneBookModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookModuleSetupRequest} PhoneBookModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookModuleSetupRequest message.
         * @function verify
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PhoneBookModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookModuleSetupRequest} PhoneBookModuleSetupRequest
         */
        PhoneBookModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookModuleSetupRequest)
                return object;
            return new $root.proto.PhoneBookModuleSetupRequest();
        };

        /**
         * Creates a plain object from a PhoneBookModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {proto.PhoneBookModuleSetupRequest} message PhoneBookModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PhoneBookModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.PhoneBookModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookModuleSetupRequest";
        };

        return PhoneBookModuleSetupRequest;
    })();

    proto.PhoneBookModuleSetupResponse = (function() {

        /**
         * Properties of a PhoneBookModuleSetupResponse.
         * @memberof proto
         * @interface IPhoneBookModuleSetupResponse
         * @property {boolean} hasPTTCall PhoneBookModuleSetupResponse hasPTTCall
         * @property {boolean} hasContacts PhoneBookModuleSetupResponse hasContacts
         */

        /**
         * Constructs a new PhoneBookModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a PhoneBookModuleSetupResponse.
         * @implements IPhoneBookModuleSetupResponse
         * @constructor
         * @param {proto.IPhoneBookModuleSetupResponse=} [properties] Properties to set
         */
        function PhoneBookModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookModuleSetupResponse hasPTTCall.
         * @member {boolean} hasPTTCall
         * @memberof proto.PhoneBookModuleSetupResponse
         * @instance
         */
        PhoneBookModuleSetupResponse.prototype.hasPTTCall = false;

        /**
         * PhoneBookModuleSetupResponse hasContacts.
         * @member {boolean} hasContacts
         * @memberof proto.PhoneBookModuleSetupResponse
         * @instance
         */
        PhoneBookModuleSetupResponse.prototype.hasContacts = false;

        /**
         * Creates a new PhoneBookModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {proto.IPhoneBookModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.PhoneBookModuleSetupResponse} PhoneBookModuleSetupResponse instance
         */
        PhoneBookModuleSetupResponse.create = function create(properties) {
            return new PhoneBookModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified PhoneBookModuleSetupResponse message. Does not implicitly {@link proto.PhoneBookModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {proto.IPhoneBookModuleSetupResponse} message PhoneBookModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasPTTCall);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hasContacts);
            return writer;
        };

        /**
         * Encodes the specified PhoneBookModuleSetupResponse message, length delimited. Does not implicitly {@link proto.PhoneBookModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {proto.IPhoneBookModuleSetupResponse} message PhoneBookModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookModuleSetupResponse} PhoneBookModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hasPTTCall = reader.bool();
                        break;
                    }
                case 2: {
                        message.hasContacts = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("hasPTTCall"))
                throw $util.ProtocolError("missing required 'hasPTTCall'", { instance: message });
            if (!message.hasOwnProperty("hasContacts"))
                throw $util.ProtocolError("missing required 'hasContacts'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookModuleSetupResponse} PhoneBookModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookModuleSetupResponse message.
         * @function verify
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.hasPTTCall !== "boolean")
                return "hasPTTCall: boolean expected";
            if (typeof message.hasContacts !== "boolean")
                return "hasContacts: boolean expected";
            return null;
        };

        /**
         * Creates a PhoneBookModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookModuleSetupResponse} PhoneBookModuleSetupResponse
         */
        PhoneBookModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookModuleSetupResponse)
                return object;
            var message = new $root.proto.PhoneBookModuleSetupResponse();
            if (object.hasPTTCall != null)
                message.hasPTTCall = Boolean(object.hasPTTCall);
            if (object.hasContacts != null)
                message.hasContacts = Boolean(object.hasContacts);
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {proto.PhoneBookModuleSetupResponse} message PhoneBookModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.hasPTTCall = false;
                object.hasContacts = false;
            }
            if (message.hasPTTCall != null && message.hasOwnProperty("hasPTTCall"))
                object.hasPTTCall = message.hasPTTCall;
            if (message.hasContacts != null && message.hasOwnProperty("hasContacts"))
                object.hasContacts = message.hasContacts;
            return object;
        };

        /**
         * Converts this PhoneBookModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.PhoneBookModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookModuleSetupResponse";
        };

        return PhoneBookModuleSetupResponse;
    })();

    proto.PhoneBookSubscribeRequest = (function() {

        /**
         * Properties of a PhoneBookSubscribeRequest.
         * @memberof proto
         * @interface IPhoneBookSubscribeRequest
         * @property {boolean|null} [includeStatus] PhoneBookSubscribeRequest includeStatus
         */

        /**
         * Constructs a new PhoneBookSubscribeRequest.
         * @memberof proto
         * @classdesc Represents a PhoneBookSubscribeRequest.
         * @implements IPhoneBookSubscribeRequest
         * @constructor
         * @param {proto.IPhoneBookSubscribeRequest=} [properties] Properties to set
         */
        function PhoneBookSubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookSubscribeRequest includeStatus.
         * @member {boolean} includeStatus
         * @memberof proto.PhoneBookSubscribeRequest
         * @instance
         */
        PhoneBookSubscribeRequest.prototype.includeStatus = false;

        /**
         * Creates a new PhoneBookSubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {proto.IPhoneBookSubscribeRequest=} [properties] Properties to set
         * @returns {proto.PhoneBookSubscribeRequest} PhoneBookSubscribeRequest instance
         */
        PhoneBookSubscribeRequest.create = function create(properties) {
            return new PhoneBookSubscribeRequest(properties);
        };

        /**
         * Encodes the specified PhoneBookSubscribeRequest message. Does not implicitly {@link proto.PhoneBookSubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {proto.IPhoneBookSubscribeRequest} message PhoneBookSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookSubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeStatus != null && Object.hasOwnProperty.call(message, "includeStatus"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeStatus);
            return writer;
        };

        /**
         * Encodes the specified PhoneBookSubscribeRequest message, length delimited. Does not implicitly {@link proto.PhoneBookSubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {proto.IPhoneBookSubscribeRequest} message PhoneBookSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookSubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookSubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookSubscribeRequest} PhoneBookSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookSubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookSubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.includeStatus = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookSubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookSubscribeRequest} PhoneBookSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookSubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookSubscribeRequest message.
         * @function verify
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookSubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeStatus != null && message.hasOwnProperty("includeStatus"))
                if (typeof message.includeStatus !== "boolean")
                    return "includeStatus: boolean expected";
            return null;
        };

        /**
         * Creates a PhoneBookSubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookSubscribeRequest} PhoneBookSubscribeRequest
         */
        PhoneBookSubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookSubscribeRequest)
                return object;
            var message = new $root.proto.PhoneBookSubscribeRequest();
            if (object.includeStatus != null)
                message.includeStatus = Boolean(object.includeStatus);
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookSubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {proto.PhoneBookSubscribeRequest} message PhoneBookSubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookSubscribeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.includeStatus = false;
            if (message.includeStatus != null && message.hasOwnProperty("includeStatus"))
                object.includeStatus = message.includeStatus;
            return object;
        };

        /**
         * Converts this PhoneBookSubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookSubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookSubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookSubscribeRequest
         * @function getTypeUrl
         * @memberof proto.PhoneBookSubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookSubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookSubscribeRequest";
        };

        return PhoneBookSubscribeRequest;
    })();

    proto.PhoneBookSubscribeResponse = (function() {

        /**
         * Properties of a PhoneBookSubscribeResponse.
         * @memberof proto
         * @interface IPhoneBookSubscribeResponse
         * @property {number|Long} subscriptionId PhoneBookSubscribeResponse subscriptionId
         * @property {Array.<proto.IPhoneBookEntrySummary>|null} [initialEntries] PhoneBookSubscribeResponse initialEntries
         * @property {Array.<proto.IStatus>|null} [status] PhoneBookSubscribeResponse status
         */

        /**
         * Constructs a new PhoneBookSubscribeResponse.
         * @memberof proto
         * @classdesc Represents a PhoneBookSubscribeResponse.
         * @implements IPhoneBookSubscribeResponse
         * @constructor
         * @param {proto.IPhoneBookSubscribeResponse=} [properties] Properties to set
         */
        function PhoneBookSubscribeResponse(properties) {
            this.initialEntries = [];
            this.status = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookSubscribeResponse subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.PhoneBookSubscribeResponse
         * @instance
         */
        PhoneBookSubscribeResponse.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PhoneBookSubscribeResponse initialEntries.
         * @member {Array.<proto.IPhoneBookEntrySummary>} initialEntries
         * @memberof proto.PhoneBookSubscribeResponse
         * @instance
         */
        PhoneBookSubscribeResponse.prototype.initialEntries = $util.emptyArray;

        /**
         * PhoneBookSubscribeResponse status.
         * @member {Array.<proto.IStatus>} status
         * @memberof proto.PhoneBookSubscribeResponse
         * @instance
         */
        PhoneBookSubscribeResponse.prototype.status = $util.emptyArray;

        /**
         * Creates a new PhoneBookSubscribeResponse instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {proto.IPhoneBookSubscribeResponse=} [properties] Properties to set
         * @returns {proto.PhoneBookSubscribeResponse} PhoneBookSubscribeResponse instance
         */
        PhoneBookSubscribeResponse.create = function create(properties) {
            return new PhoneBookSubscribeResponse(properties);
        };

        /**
         * Encodes the specified PhoneBookSubscribeResponse message. Does not implicitly {@link proto.PhoneBookSubscribeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {proto.IPhoneBookSubscribeResponse} message PhoneBookSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookSubscribeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.initialEntries != null && message.initialEntries.length)
                for (var i = 0; i < message.initialEntries.length; ++i)
                    $root.proto.PhoneBookEntrySummary.encode(message.initialEntries[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.status != null && message.status.length)
                for (var i = 0; i < message.status.length; ++i)
                    $root.proto.Status.encode(message.status[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookSubscribeResponse message, length delimited. Does not implicitly {@link proto.PhoneBookSubscribeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {proto.IPhoneBookSubscribeResponse} message PhoneBookSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookSubscribeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookSubscribeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookSubscribeResponse} PhoneBookSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookSubscribeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookSubscribeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.initialEntries && message.initialEntries.length))
                            message.initialEntries = [];
                        message.initialEntries.push($root.proto.PhoneBookEntrySummary.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.status && message.status.length))
                            message.status = [];
                        message.status.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookSubscribeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookSubscribeResponse} PhoneBookSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookSubscribeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookSubscribeResponse message.
         * @function verify
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookSubscribeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.initialEntries != null && message.hasOwnProperty("initialEntries")) {
                if (!Array.isArray(message.initialEntries))
                    return "initialEntries: array expected";
                for (var i = 0; i < message.initialEntries.length; ++i) {
                    var error = $root.proto.PhoneBookEntrySummary.verify(message.initialEntries[i]);
                    if (error)
                        return "initialEntries." + error;
                }
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (!Array.isArray(message.status))
                    return "status: array expected";
                for (var i = 0; i < message.status.length; ++i) {
                    var error = $root.proto.Status.verify(message.status[i]);
                    if (error)
                        return "status." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PhoneBookSubscribeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookSubscribeResponse} PhoneBookSubscribeResponse
         */
        PhoneBookSubscribeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookSubscribeResponse)
                return object;
            var message = new $root.proto.PhoneBookSubscribeResponse();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.initialEntries) {
                if (!Array.isArray(object.initialEntries))
                    throw TypeError(".proto.PhoneBookSubscribeResponse.initialEntries: array expected");
                message.initialEntries = [];
                for (var i = 0; i < object.initialEntries.length; ++i) {
                    if (typeof object.initialEntries[i] !== "object")
                        throw TypeError(".proto.PhoneBookSubscribeResponse.initialEntries: object expected");
                    message.initialEntries[i] = $root.proto.PhoneBookEntrySummary.fromObject(object.initialEntries[i]);
                }
            }
            if (object.status) {
                if (!Array.isArray(object.status))
                    throw TypeError(".proto.PhoneBookSubscribeResponse.status: array expected");
                message.status = [];
                for (var i = 0; i < object.status.length; ++i) {
                    if (typeof object.status[i] !== "object")
                        throw TypeError(".proto.PhoneBookSubscribeResponse.status: object expected");
                    message.status[i] = $root.proto.Status.fromObject(object.status[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookSubscribeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {proto.PhoneBookSubscribeResponse} message PhoneBookSubscribeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookSubscribeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.initialEntries = [];
                object.status = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.initialEntries && message.initialEntries.length) {
                object.initialEntries = [];
                for (var j = 0; j < message.initialEntries.length; ++j)
                    object.initialEntries[j] = $root.proto.PhoneBookEntrySummary.toObject(message.initialEntries[j], options);
            }
            if (message.status && message.status.length) {
                object.status = [];
                for (var j = 0; j < message.status.length; ++j)
                    object.status[j] = $root.proto.Status.toObject(message.status[j], options);
            }
            return object;
        };

        /**
         * Converts this PhoneBookSubscribeResponse to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookSubscribeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookSubscribeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookSubscribeResponse
         * @function getTypeUrl
         * @memberof proto.PhoneBookSubscribeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookSubscribeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookSubscribeResponse";
        };

        return PhoneBookSubscribeResponse;
    })();

    proto.PhoneBookUnsubscribeRequest = (function() {

        /**
         * Properties of a PhoneBookUnsubscribeRequest.
         * @memberof proto
         * @interface IPhoneBookUnsubscribeRequest
         */

        /**
         * Constructs a new PhoneBookUnsubscribeRequest.
         * @memberof proto
         * @classdesc Represents a PhoneBookUnsubscribeRequest.
         * @implements IPhoneBookUnsubscribeRequest
         * @constructor
         * @param {proto.IPhoneBookUnsubscribeRequest=} [properties] Properties to set
         */
        function PhoneBookUnsubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PhoneBookUnsubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {proto.IPhoneBookUnsubscribeRequest=} [properties] Properties to set
         * @returns {proto.PhoneBookUnsubscribeRequest} PhoneBookUnsubscribeRequest instance
         */
        PhoneBookUnsubscribeRequest.create = function create(properties) {
            return new PhoneBookUnsubscribeRequest(properties);
        };

        /**
         * Encodes the specified PhoneBookUnsubscribeRequest message. Does not implicitly {@link proto.PhoneBookUnsubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {proto.IPhoneBookUnsubscribeRequest} message PhoneBookUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookUnsubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookUnsubscribeRequest message, length delimited. Does not implicitly {@link proto.PhoneBookUnsubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {proto.IPhoneBookUnsubscribeRequest} message PhoneBookUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookUnsubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookUnsubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookUnsubscribeRequest} PhoneBookUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookUnsubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookUnsubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookUnsubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookUnsubscribeRequest} PhoneBookUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookUnsubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookUnsubscribeRequest message.
         * @function verify
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookUnsubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PhoneBookUnsubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookUnsubscribeRequest} PhoneBookUnsubscribeRequest
         */
        PhoneBookUnsubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookUnsubscribeRequest)
                return object;
            return new $root.proto.PhoneBookUnsubscribeRequest();
        };

        /**
         * Creates a plain object from a PhoneBookUnsubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {proto.PhoneBookUnsubscribeRequest} message PhoneBookUnsubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookUnsubscribeRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PhoneBookUnsubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookUnsubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookUnsubscribeRequest
         * @function getTypeUrl
         * @memberof proto.PhoneBookUnsubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookUnsubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookUnsubscribeRequest";
        };

        return PhoneBookUnsubscribeRequest;
    })();

    proto.PhoneBookLookupRequest = (function() {

        /**
         * Properties of a PhoneBookLookupRequest.
         * @memberof proto
         * @interface IPhoneBookLookupRequest
         * @property {string} entryId PhoneBookLookupRequest entryId
         * @property {boolean|null} [includeStatus] PhoneBookLookupRequest includeStatus
         */

        /**
         * Constructs a new PhoneBookLookupRequest.
         * @memberof proto
         * @classdesc Represents a PhoneBookLookupRequest.
         * @implements IPhoneBookLookupRequest
         * @constructor
         * @param {proto.IPhoneBookLookupRequest=} [properties] Properties to set
         */
        function PhoneBookLookupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookLookupRequest entryId.
         * @member {string} entryId
         * @memberof proto.PhoneBookLookupRequest
         * @instance
         */
        PhoneBookLookupRequest.prototype.entryId = "";

        /**
         * PhoneBookLookupRequest includeStatus.
         * @member {boolean} includeStatus
         * @memberof proto.PhoneBookLookupRequest
         * @instance
         */
        PhoneBookLookupRequest.prototype.includeStatus = false;

        /**
         * Creates a new PhoneBookLookupRequest instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {proto.IPhoneBookLookupRequest=} [properties] Properties to set
         * @returns {proto.PhoneBookLookupRequest} PhoneBookLookupRequest instance
         */
        PhoneBookLookupRequest.create = function create(properties) {
            return new PhoneBookLookupRequest(properties);
        };

        /**
         * Encodes the specified PhoneBookLookupRequest message. Does not implicitly {@link proto.PhoneBookLookupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {proto.IPhoneBookLookupRequest} message PhoneBookLookupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookLookupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.entryId);
            if (message.includeStatus != null && Object.hasOwnProperty.call(message, "includeStatus"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeStatus);
            return writer;
        };

        /**
         * Encodes the specified PhoneBookLookupRequest message, length delimited. Does not implicitly {@link proto.PhoneBookLookupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {proto.IPhoneBookLookupRequest} message PhoneBookLookupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookLookupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookLookupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookLookupRequest} PhoneBookLookupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookLookupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookLookupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entryId = reader.string();
                        break;
                    }
                case 2: {
                        message.includeStatus = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("entryId"))
                throw $util.ProtocolError("missing required 'entryId'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookLookupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookLookupRequest} PhoneBookLookupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookLookupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookLookupRequest message.
         * @function verify
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookLookupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.entryId))
                return "entryId: string expected";
            if (message.includeStatus != null && message.hasOwnProperty("includeStatus"))
                if (typeof message.includeStatus !== "boolean")
                    return "includeStatus: boolean expected";
            return null;
        };

        /**
         * Creates a PhoneBookLookupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookLookupRequest} PhoneBookLookupRequest
         */
        PhoneBookLookupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookLookupRequest)
                return object;
            var message = new $root.proto.PhoneBookLookupRequest();
            if (object.entryId != null)
                message.entryId = String(object.entryId);
            if (object.includeStatus != null)
                message.includeStatus = Boolean(object.includeStatus);
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookLookupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {proto.PhoneBookLookupRequest} message PhoneBookLookupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookLookupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.entryId = "";
                object.includeStatus = false;
            }
            if (message.entryId != null && message.hasOwnProperty("entryId"))
                object.entryId = message.entryId;
            if (message.includeStatus != null && message.hasOwnProperty("includeStatus"))
                object.includeStatus = message.includeStatus;
            return object;
        };

        /**
         * Converts this PhoneBookLookupRequest to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookLookupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookLookupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookLookupRequest
         * @function getTypeUrl
         * @memberof proto.PhoneBookLookupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookLookupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookLookupRequest";
        };

        return PhoneBookLookupRequest;
    })();

    proto.PhoneBookLookupResponse = (function() {

        /**
         * Properties of a PhoneBookLookupResponse.
         * @memberof proto
         * @interface IPhoneBookLookupResponse
         * @property {proto.IPhoneBookEntryDetails} phoneBookEntryDetails PhoneBookLookupResponse phoneBookEntryDetails
         * @property {Array.<proto.IStatus>|null} [status] PhoneBookLookupResponse status
         */

        /**
         * Constructs a new PhoneBookLookupResponse.
         * @memberof proto
         * @classdesc Represents a PhoneBookLookupResponse.
         * @implements IPhoneBookLookupResponse
         * @constructor
         * @param {proto.IPhoneBookLookupResponse=} [properties] Properties to set
         */
        function PhoneBookLookupResponse(properties) {
            this.status = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookLookupResponse phoneBookEntryDetails.
         * @member {proto.IPhoneBookEntryDetails} phoneBookEntryDetails
         * @memberof proto.PhoneBookLookupResponse
         * @instance
         */
        PhoneBookLookupResponse.prototype.phoneBookEntryDetails = null;

        /**
         * PhoneBookLookupResponse status.
         * @member {Array.<proto.IStatus>} status
         * @memberof proto.PhoneBookLookupResponse
         * @instance
         */
        PhoneBookLookupResponse.prototype.status = $util.emptyArray;

        /**
         * Creates a new PhoneBookLookupResponse instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {proto.IPhoneBookLookupResponse=} [properties] Properties to set
         * @returns {proto.PhoneBookLookupResponse} PhoneBookLookupResponse instance
         */
        PhoneBookLookupResponse.create = function create(properties) {
            return new PhoneBookLookupResponse(properties);
        };

        /**
         * Encodes the specified PhoneBookLookupResponse message. Does not implicitly {@link proto.PhoneBookLookupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {proto.IPhoneBookLookupResponse} message PhoneBookLookupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookLookupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            $root.proto.PhoneBookEntryDetails.encode(message.phoneBookEntryDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.status != null && message.status.length)
                for (var i = 0; i < message.status.length; ++i)
                    $root.proto.Status.encode(message.status[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookLookupResponse message, length delimited. Does not implicitly {@link proto.PhoneBookLookupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {proto.IPhoneBookLookupResponse} message PhoneBookLookupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookLookupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookLookupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookLookupResponse} PhoneBookLookupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookLookupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookLookupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.phoneBookEntryDetails = $root.proto.PhoneBookEntryDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.status && message.status.length))
                            message.status = [];
                        message.status.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("phoneBookEntryDetails"))
                throw $util.ProtocolError("missing required 'phoneBookEntryDetails'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookLookupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookLookupResponse} PhoneBookLookupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookLookupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookLookupResponse message.
         * @function verify
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookLookupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            {
                var error = $root.proto.PhoneBookEntryDetails.verify(message.phoneBookEntryDetails);
                if (error)
                    return "phoneBookEntryDetails." + error;
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (!Array.isArray(message.status))
                    return "status: array expected";
                for (var i = 0; i < message.status.length; ++i) {
                    var error = $root.proto.Status.verify(message.status[i]);
                    if (error)
                        return "status." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PhoneBookLookupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookLookupResponse} PhoneBookLookupResponse
         */
        PhoneBookLookupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookLookupResponse)
                return object;
            var message = new $root.proto.PhoneBookLookupResponse();
            if (object.phoneBookEntryDetails != null) {
                if (typeof object.phoneBookEntryDetails !== "object")
                    throw TypeError(".proto.PhoneBookLookupResponse.phoneBookEntryDetails: object expected");
                message.phoneBookEntryDetails = $root.proto.PhoneBookEntryDetails.fromObject(object.phoneBookEntryDetails);
            }
            if (object.status) {
                if (!Array.isArray(object.status))
                    throw TypeError(".proto.PhoneBookLookupResponse.status: array expected");
                message.status = [];
                for (var i = 0; i < object.status.length; ++i) {
                    if (typeof object.status[i] !== "object")
                        throw TypeError(".proto.PhoneBookLookupResponse.status: object expected");
                    message.status[i] = $root.proto.Status.fromObject(object.status[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookLookupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {proto.PhoneBookLookupResponse} message PhoneBookLookupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookLookupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.status = [];
            if (options.defaults)
                object.phoneBookEntryDetails = null;
            if (message.phoneBookEntryDetails != null && message.hasOwnProperty("phoneBookEntryDetails"))
                object.phoneBookEntryDetails = $root.proto.PhoneBookEntryDetails.toObject(message.phoneBookEntryDetails, options);
            if (message.status && message.status.length) {
                object.status = [];
                for (var j = 0; j < message.status.length; ++j)
                    object.status[j] = $root.proto.Status.toObject(message.status[j], options);
            }
            return object;
        };

        /**
         * Converts this PhoneBookLookupResponse to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookLookupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookLookupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookLookupResponse
         * @function getTypeUrl
         * @memberof proto.PhoneBookLookupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookLookupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookLookupResponse";
        };

        return PhoneBookLookupResponse;
    })();

    proto.PhoneBookDetailedListRequest = (function() {

        /**
         * Properties of a PhoneBookDetailedListRequest.
         * @memberof proto
         * @interface IPhoneBookDetailedListRequest
         * @property {boolean|null} [includeStatus] PhoneBookDetailedListRequest includeStatus
         */

        /**
         * Constructs a new PhoneBookDetailedListRequest.
         * @memberof proto
         * @classdesc Represents a PhoneBookDetailedListRequest.
         * @implements IPhoneBookDetailedListRequest
         * @constructor
         * @param {proto.IPhoneBookDetailedListRequest=} [properties] Properties to set
         */
        function PhoneBookDetailedListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookDetailedListRequest includeStatus.
         * @member {boolean} includeStatus
         * @memberof proto.PhoneBookDetailedListRequest
         * @instance
         */
        PhoneBookDetailedListRequest.prototype.includeStatus = false;

        /**
         * Creates a new PhoneBookDetailedListRequest instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {proto.IPhoneBookDetailedListRequest=} [properties] Properties to set
         * @returns {proto.PhoneBookDetailedListRequest} PhoneBookDetailedListRequest instance
         */
        PhoneBookDetailedListRequest.create = function create(properties) {
            return new PhoneBookDetailedListRequest(properties);
        };

        /**
         * Encodes the specified PhoneBookDetailedListRequest message. Does not implicitly {@link proto.PhoneBookDetailedListRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {proto.IPhoneBookDetailedListRequest} message PhoneBookDetailedListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookDetailedListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeStatus != null && Object.hasOwnProperty.call(message, "includeStatus"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeStatus);
            return writer;
        };

        /**
         * Encodes the specified PhoneBookDetailedListRequest message, length delimited. Does not implicitly {@link proto.PhoneBookDetailedListRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {proto.IPhoneBookDetailedListRequest} message PhoneBookDetailedListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookDetailedListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookDetailedListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookDetailedListRequest} PhoneBookDetailedListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookDetailedListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookDetailedListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.includeStatus = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookDetailedListRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookDetailedListRequest} PhoneBookDetailedListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookDetailedListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookDetailedListRequest message.
         * @function verify
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookDetailedListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeStatus != null && message.hasOwnProperty("includeStatus"))
                if (typeof message.includeStatus !== "boolean")
                    return "includeStatus: boolean expected";
            return null;
        };

        /**
         * Creates a PhoneBookDetailedListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookDetailedListRequest} PhoneBookDetailedListRequest
         */
        PhoneBookDetailedListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookDetailedListRequest)
                return object;
            var message = new $root.proto.PhoneBookDetailedListRequest();
            if (object.includeStatus != null)
                message.includeStatus = Boolean(object.includeStatus);
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookDetailedListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {proto.PhoneBookDetailedListRequest} message PhoneBookDetailedListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookDetailedListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.includeStatus = false;
            if (message.includeStatus != null && message.hasOwnProperty("includeStatus"))
                object.includeStatus = message.includeStatus;
            return object;
        };

        /**
         * Converts this PhoneBookDetailedListRequest to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookDetailedListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookDetailedListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookDetailedListRequest
         * @function getTypeUrl
         * @memberof proto.PhoneBookDetailedListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookDetailedListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookDetailedListRequest";
        };

        return PhoneBookDetailedListRequest;
    })();

    proto.PhoneBookDetailedListResponse = (function() {

        /**
         * Properties of a PhoneBookDetailedListResponse.
         * @memberof proto
         * @interface IPhoneBookDetailedListResponse
         * @property {Array.<proto.IPhoneBookEntryDetails>|null} [phoneBookEntryDetails] PhoneBookDetailedListResponse phoneBookEntryDetails
         * @property {Array.<proto.IStatus>|null} [status] PhoneBookDetailedListResponse status
         */

        /**
         * Constructs a new PhoneBookDetailedListResponse.
         * @memberof proto
         * @classdesc Represents a PhoneBookDetailedListResponse.
         * @implements IPhoneBookDetailedListResponse
         * @constructor
         * @param {proto.IPhoneBookDetailedListResponse=} [properties] Properties to set
         */
        function PhoneBookDetailedListResponse(properties) {
            this.phoneBookEntryDetails = [];
            this.status = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookDetailedListResponse phoneBookEntryDetails.
         * @member {Array.<proto.IPhoneBookEntryDetails>} phoneBookEntryDetails
         * @memberof proto.PhoneBookDetailedListResponse
         * @instance
         */
        PhoneBookDetailedListResponse.prototype.phoneBookEntryDetails = $util.emptyArray;

        /**
         * PhoneBookDetailedListResponse status.
         * @member {Array.<proto.IStatus>} status
         * @memberof proto.PhoneBookDetailedListResponse
         * @instance
         */
        PhoneBookDetailedListResponse.prototype.status = $util.emptyArray;

        /**
         * Creates a new PhoneBookDetailedListResponse instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {proto.IPhoneBookDetailedListResponse=} [properties] Properties to set
         * @returns {proto.PhoneBookDetailedListResponse} PhoneBookDetailedListResponse instance
         */
        PhoneBookDetailedListResponse.create = function create(properties) {
            return new PhoneBookDetailedListResponse(properties);
        };

        /**
         * Encodes the specified PhoneBookDetailedListResponse message. Does not implicitly {@link proto.PhoneBookDetailedListResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {proto.IPhoneBookDetailedListResponse} message PhoneBookDetailedListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookDetailedListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phoneBookEntryDetails != null && message.phoneBookEntryDetails.length)
                for (var i = 0; i < message.phoneBookEntryDetails.length; ++i)
                    $root.proto.PhoneBookEntryDetails.encode(message.phoneBookEntryDetails[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.status != null && message.status.length)
                for (var i = 0; i < message.status.length; ++i)
                    $root.proto.Status.encode(message.status[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookDetailedListResponse message, length delimited. Does not implicitly {@link proto.PhoneBookDetailedListResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {proto.IPhoneBookDetailedListResponse} message PhoneBookDetailedListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookDetailedListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookDetailedListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookDetailedListResponse} PhoneBookDetailedListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookDetailedListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookDetailedListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.phoneBookEntryDetails && message.phoneBookEntryDetails.length))
                            message.phoneBookEntryDetails = [];
                        message.phoneBookEntryDetails.push($root.proto.PhoneBookEntryDetails.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.status && message.status.length))
                            message.status = [];
                        message.status.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookDetailedListResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookDetailedListResponse} PhoneBookDetailedListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookDetailedListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookDetailedListResponse message.
         * @function verify
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookDetailedListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phoneBookEntryDetails != null && message.hasOwnProperty("phoneBookEntryDetails")) {
                if (!Array.isArray(message.phoneBookEntryDetails))
                    return "phoneBookEntryDetails: array expected";
                for (var i = 0; i < message.phoneBookEntryDetails.length; ++i) {
                    var error = $root.proto.PhoneBookEntryDetails.verify(message.phoneBookEntryDetails[i]);
                    if (error)
                        return "phoneBookEntryDetails." + error;
                }
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (!Array.isArray(message.status))
                    return "status: array expected";
                for (var i = 0; i < message.status.length; ++i) {
                    var error = $root.proto.Status.verify(message.status[i]);
                    if (error)
                        return "status." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PhoneBookDetailedListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookDetailedListResponse} PhoneBookDetailedListResponse
         */
        PhoneBookDetailedListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookDetailedListResponse)
                return object;
            var message = new $root.proto.PhoneBookDetailedListResponse();
            if (object.phoneBookEntryDetails) {
                if (!Array.isArray(object.phoneBookEntryDetails))
                    throw TypeError(".proto.PhoneBookDetailedListResponse.phoneBookEntryDetails: array expected");
                message.phoneBookEntryDetails = [];
                for (var i = 0; i < object.phoneBookEntryDetails.length; ++i) {
                    if (typeof object.phoneBookEntryDetails[i] !== "object")
                        throw TypeError(".proto.PhoneBookDetailedListResponse.phoneBookEntryDetails: object expected");
                    message.phoneBookEntryDetails[i] = $root.proto.PhoneBookEntryDetails.fromObject(object.phoneBookEntryDetails[i]);
                }
            }
            if (object.status) {
                if (!Array.isArray(object.status))
                    throw TypeError(".proto.PhoneBookDetailedListResponse.status: array expected");
                message.status = [];
                for (var i = 0; i < object.status.length; ++i) {
                    if (typeof object.status[i] !== "object")
                        throw TypeError(".proto.PhoneBookDetailedListResponse.status: object expected");
                    message.status[i] = $root.proto.Status.fromObject(object.status[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookDetailedListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {proto.PhoneBookDetailedListResponse} message PhoneBookDetailedListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookDetailedListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.phoneBookEntryDetails = [];
                object.status = [];
            }
            if (message.phoneBookEntryDetails && message.phoneBookEntryDetails.length) {
                object.phoneBookEntryDetails = [];
                for (var j = 0; j < message.phoneBookEntryDetails.length; ++j)
                    object.phoneBookEntryDetails[j] = $root.proto.PhoneBookEntryDetails.toObject(message.phoneBookEntryDetails[j], options);
            }
            if (message.status && message.status.length) {
                object.status = [];
                for (var j = 0; j < message.status.length; ++j)
                    object.status[j] = $root.proto.Status.toObject(message.status[j], options);
            }
            return object;
        };

        /**
         * Converts this PhoneBookDetailedListResponse to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookDetailedListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookDetailedListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookDetailedListResponse
         * @function getTypeUrl
         * @memberof proto.PhoneBookDetailedListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookDetailedListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookDetailedListResponse";
        };

        return PhoneBookDetailedListResponse;
    })();

    proto.PhoneBookAPIv1Server = (function() {

        /**
         * Properties of a PhoneBookAPIv1Server.
         * @memberof proto
         * @interface IPhoneBookAPIv1Server
         * @property {proto.IPhoneBookListDelta|null} [phoneBookListDelta] PhoneBookAPIv1Server phoneBookListDelta
         */

        /**
         * Constructs a new PhoneBookAPIv1Server.
         * @memberof proto
         * @classdesc Represents a PhoneBookAPIv1Server.
         * @implements IPhoneBookAPIv1Server
         * @constructor
         * @param {proto.IPhoneBookAPIv1Server=} [properties] Properties to set
         */
        function PhoneBookAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookAPIv1Server phoneBookListDelta.
         * @member {proto.IPhoneBookListDelta|null|undefined} phoneBookListDelta
         * @memberof proto.PhoneBookAPIv1Server
         * @instance
         */
        PhoneBookAPIv1Server.prototype.phoneBookListDelta = null;

        /**
         * Creates a new PhoneBookAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {proto.IPhoneBookAPIv1Server=} [properties] Properties to set
         * @returns {proto.PhoneBookAPIv1Server} PhoneBookAPIv1Server instance
         */
        PhoneBookAPIv1Server.create = function create(properties) {
            return new PhoneBookAPIv1Server(properties);
        };

        /**
         * Encodes the specified PhoneBookAPIv1Server message. Does not implicitly {@link proto.PhoneBookAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {proto.IPhoneBookAPIv1Server} message PhoneBookAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phoneBookListDelta != null && Object.hasOwnProperty.call(message, "phoneBookListDelta"))
                $root.proto.PhoneBookListDelta.encode(message.phoneBookListDelta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookAPIv1Server message, length delimited. Does not implicitly {@link proto.PhoneBookAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {proto.IPhoneBookAPIv1Server} message PhoneBookAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookAPIv1Server} PhoneBookAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.phoneBookListDelta = $root.proto.PhoneBookListDelta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneBookAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookAPIv1Server} PhoneBookAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookAPIv1Server message.
         * @function verify
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phoneBookListDelta != null && message.hasOwnProperty("phoneBookListDelta")) {
                var error = $root.proto.PhoneBookListDelta.verify(message.phoneBookListDelta);
                if (error)
                    return "phoneBookListDelta." + error;
            }
            return null;
        };

        /**
         * Creates a PhoneBookAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookAPIv1Server} PhoneBookAPIv1Server
         */
        PhoneBookAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookAPIv1Server)
                return object;
            var message = new $root.proto.PhoneBookAPIv1Server();
            if (object.phoneBookListDelta != null) {
                if (typeof object.phoneBookListDelta !== "object")
                    throw TypeError(".proto.PhoneBookAPIv1Server.phoneBookListDelta: object expected");
                message.phoneBookListDelta = $root.proto.PhoneBookListDelta.fromObject(object.phoneBookListDelta);
            }
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {proto.PhoneBookAPIv1Server} message PhoneBookAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.phoneBookListDelta = null;
            if (message.phoneBookListDelta != null && message.hasOwnProperty("phoneBookListDelta"))
                object.phoneBookListDelta = $root.proto.PhoneBookListDelta.toObject(message.phoneBookListDelta, options);
            return object;
        };

        /**
         * Converts this PhoneBookAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookAPIv1Server
         * @function getTypeUrl
         * @memberof proto.PhoneBookAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookAPIv1Server";
        };

        return PhoneBookAPIv1Server;
    })();

    proto.PhoneBookListDelta = (function() {

        /**
         * Properties of a PhoneBookListDelta.
         * @memberof proto
         * @interface IPhoneBookListDelta
         * @property {number|Long} subscriptionId PhoneBookListDelta subscriptionId
         * @property {Array.<proto.IPhoneBookEntrySummary>|null} [updated] PhoneBookListDelta updated
         * @property {Array.<string>|null} [deletedEntryIds] PhoneBookListDelta deletedEntryIds
         * @property {Array.<proto.IStatus>|null} [status] PhoneBookListDelta status
         */

        /**
         * Constructs a new PhoneBookListDelta.
         * @memberof proto
         * @classdesc Represents a PhoneBookListDelta.
         * @implements IPhoneBookListDelta
         * @constructor
         * @param {proto.IPhoneBookListDelta=} [properties] Properties to set
         */
        function PhoneBookListDelta(properties) {
            this.updated = [];
            this.deletedEntryIds = [];
            this.status = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookListDelta subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.PhoneBookListDelta
         * @instance
         */
        PhoneBookListDelta.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PhoneBookListDelta updated.
         * @member {Array.<proto.IPhoneBookEntrySummary>} updated
         * @memberof proto.PhoneBookListDelta
         * @instance
         */
        PhoneBookListDelta.prototype.updated = $util.emptyArray;

        /**
         * PhoneBookListDelta deletedEntryIds.
         * @member {Array.<string>} deletedEntryIds
         * @memberof proto.PhoneBookListDelta
         * @instance
         */
        PhoneBookListDelta.prototype.deletedEntryIds = $util.emptyArray;

        /**
         * PhoneBookListDelta status.
         * @member {Array.<proto.IStatus>} status
         * @memberof proto.PhoneBookListDelta
         * @instance
         */
        PhoneBookListDelta.prototype.status = $util.emptyArray;

        /**
         * Creates a new PhoneBookListDelta instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {proto.IPhoneBookListDelta=} [properties] Properties to set
         * @returns {proto.PhoneBookListDelta} PhoneBookListDelta instance
         */
        PhoneBookListDelta.create = function create(properties) {
            return new PhoneBookListDelta(properties);
        };

        /**
         * Encodes the specified PhoneBookListDelta message. Does not implicitly {@link proto.PhoneBookListDelta.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {proto.IPhoneBookListDelta} message PhoneBookListDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookListDelta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.updated != null && message.updated.length)
                for (var i = 0; i < message.updated.length; ++i)
                    $root.proto.PhoneBookEntrySummary.encode(message.updated[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.deletedEntryIds != null && message.deletedEntryIds.length)
                for (var i = 0; i < message.deletedEntryIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.deletedEntryIds[i]);
            if (message.status != null && message.status.length)
                for (var i = 0; i < message.status.length; ++i)
                    $root.proto.Status.encode(message.status[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PhoneBookListDelta message, length delimited. Does not implicitly {@link proto.PhoneBookListDelta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {proto.IPhoneBookListDelta} message PhoneBookListDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookListDelta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookListDelta message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookListDelta} PhoneBookListDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookListDelta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookListDelta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.updated && message.updated.length))
                            message.updated = [];
                        message.updated.push($root.proto.PhoneBookEntrySummary.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.deletedEntryIds && message.deletedEntryIds.length))
                            message.deletedEntryIds = [];
                        message.deletedEntryIds.push(reader.string());
                        break;
                    }
                case 4: {
                        if (!(message.status && message.status.length))
                            message.status = [];
                        message.status.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookListDelta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookListDelta} PhoneBookListDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookListDelta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookListDelta message.
         * @function verify
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookListDelta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.updated != null && message.hasOwnProperty("updated")) {
                if (!Array.isArray(message.updated))
                    return "updated: array expected";
                for (var i = 0; i < message.updated.length; ++i) {
                    var error = $root.proto.PhoneBookEntrySummary.verify(message.updated[i]);
                    if (error)
                        return "updated." + error;
                }
            }
            if (message.deletedEntryIds != null && message.hasOwnProperty("deletedEntryIds")) {
                if (!Array.isArray(message.deletedEntryIds))
                    return "deletedEntryIds: array expected";
                for (var i = 0; i < message.deletedEntryIds.length; ++i)
                    if (!$util.isString(message.deletedEntryIds[i]))
                        return "deletedEntryIds: string[] expected";
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (!Array.isArray(message.status))
                    return "status: array expected";
                for (var i = 0; i < message.status.length; ++i) {
                    var error = $root.proto.Status.verify(message.status[i]);
                    if (error)
                        return "status." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PhoneBookListDelta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookListDelta} PhoneBookListDelta
         */
        PhoneBookListDelta.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookListDelta)
                return object;
            var message = new $root.proto.PhoneBookListDelta();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.updated) {
                if (!Array.isArray(object.updated))
                    throw TypeError(".proto.PhoneBookListDelta.updated: array expected");
                message.updated = [];
                for (var i = 0; i < object.updated.length; ++i) {
                    if (typeof object.updated[i] !== "object")
                        throw TypeError(".proto.PhoneBookListDelta.updated: object expected");
                    message.updated[i] = $root.proto.PhoneBookEntrySummary.fromObject(object.updated[i]);
                }
            }
            if (object.deletedEntryIds) {
                if (!Array.isArray(object.deletedEntryIds))
                    throw TypeError(".proto.PhoneBookListDelta.deletedEntryIds: array expected");
                message.deletedEntryIds = [];
                for (var i = 0; i < object.deletedEntryIds.length; ++i)
                    message.deletedEntryIds[i] = String(object.deletedEntryIds[i]);
            }
            if (object.status) {
                if (!Array.isArray(object.status))
                    throw TypeError(".proto.PhoneBookListDelta.status: array expected");
                message.status = [];
                for (var i = 0; i < object.status.length; ++i) {
                    if (typeof object.status[i] !== "object")
                        throw TypeError(".proto.PhoneBookListDelta.status: object expected");
                    message.status[i] = $root.proto.Status.fromObject(object.status[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookListDelta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {proto.PhoneBookListDelta} message PhoneBookListDelta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookListDelta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.updated = [];
                object.deletedEntryIds = [];
                object.status = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.updated && message.updated.length) {
                object.updated = [];
                for (var j = 0; j < message.updated.length; ++j)
                    object.updated[j] = $root.proto.PhoneBookEntrySummary.toObject(message.updated[j], options);
            }
            if (message.deletedEntryIds && message.deletedEntryIds.length) {
                object.deletedEntryIds = [];
                for (var j = 0; j < message.deletedEntryIds.length; ++j)
                    object.deletedEntryIds[j] = message.deletedEntryIds[j];
            }
            if (message.status && message.status.length) {
                object.status = [];
                for (var j = 0; j < message.status.length; ++j)
                    object.status[j] = $root.proto.Status.toObject(message.status[j], options);
            }
            return object;
        };

        /**
         * Converts this PhoneBookListDelta to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookListDelta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookListDelta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookListDelta
         * @function getTypeUrl
         * @memberof proto.PhoneBookListDelta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookListDelta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookListDelta";
        };

        return PhoneBookListDelta;
    })();

    /**
     * PhoneBookEntryType enum.
     * @name proto.PhoneBookEntryType
     * @enum {number}
     * @property {number} USER=1 USER value
     * @property {number} CALL_GROUP=2 CALL_GROUP value
     * @property {number} CONTACT=3 CONTACT value
     */
    proto.PhoneBookEntryType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "USER"] = 1;
        values[valuesById[2] = "CALL_GROUP"] = 2;
        values[valuesById[3] = "CONTACT"] = 3;
        return values;
    })();

    proto.PhoneBookEntrySummary = (function() {

        /**
         * Properties of a PhoneBookEntrySummary.
         * @memberof proto
         * @interface IPhoneBookEntrySummary
         * @property {string} id PhoneBookEntrySummary id
         * @property {proto.PhoneBookEntryType} type PhoneBookEntrySummary type
         * @property {string} name PhoneBookEntrySummary name
         * @property {string|null} [desc] PhoneBookEntrySummary desc
         * @property {boolean} online PhoneBookEntrySummary online
         * @property {Array.<string>|null} [onlineEntityIds] PhoneBookEntrySummary onlineEntityIds
         * @property {string|null} [owner] PhoneBookEntrySummary owner
         * @property {number|null} [statusIndex] PhoneBookEntrySummary statusIndex
         * @property {string|null} [userUuid] PhoneBookEntrySummary userUuid
         */

        /**
         * Constructs a new PhoneBookEntrySummary.
         * @memberof proto
         * @classdesc Represents a PhoneBookEntrySummary.
         * @implements IPhoneBookEntrySummary
         * @constructor
         * @param {proto.IPhoneBookEntrySummary=} [properties] Properties to set
         */
        function PhoneBookEntrySummary(properties) {
            this.onlineEntityIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookEntrySummary id.
         * @member {string} id
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.id = "";

        /**
         * PhoneBookEntrySummary type.
         * @member {proto.PhoneBookEntryType} type
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.type = 1;

        /**
         * PhoneBookEntrySummary name.
         * @member {string} name
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.name = "";

        /**
         * PhoneBookEntrySummary desc.
         * @member {string} desc
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.desc = "";

        /**
         * PhoneBookEntrySummary online.
         * @member {boolean} online
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.online = false;

        /**
         * PhoneBookEntrySummary onlineEntityIds.
         * @member {Array.<string>} onlineEntityIds
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.onlineEntityIds = $util.emptyArray;

        /**
         * PhoneBookEntrySummary owner.
         * @member {string} owner
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.owner = "";

        /**
         * PhoneBookEntrySummary statusIndex.
         * @member {number} statusIndex
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.statusIndex = 0;

        /**
         * PhoneBookEntrySummary userUuid.
         * @member {string} userUuid
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         */
        PhoneBookEntrySummary.prototype.userUuid = "";

        /**
         * Creates a new PhoneBookEntrySummary instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {proto.IPhoneBookEntrySummary=} [properties] Properties to set
         * @returns {proto.PhoneBookEntrySummary} PhoneBookEntrySummary instance
         */
        PhoneBookEntrySummary.create = function create(properties) {
            return new PhoneBookEntrySummary(properties);
        };

        /**
         * Encodes the specified PhoneBookEntrySummary message. Does not implicitly {@link proto.PhoneBookEntrySummary.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {proto.IPhoneBookEntrySummary} message PhoneBookEntrySummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookEntrySummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.desc != null && Object.hasOwnProperty.call(message, "desc"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.desc);
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.online);
            if (message.onlineEntityIds != null && message.onlineEntityIds.length)
                for (var i = 0; i < message.onlineEntityIds.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.onlineEntityIds[i]);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.owner);
            if (message.statusIndex != null && Object.hasOwnProperty.call(message, "statusIndex"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.statusIndex);
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.userUuid);
            return writer;
        };

        /**
         * Encodes the specified PhoneBookEntrySummary message, length delimited. Does not implicitly {@link proto.PhoneBookEntrySummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {proto.IPhoneBookEntrySummary} message PhoneBookEntrySummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookEntrySummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookEntrySummary message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookEntrySummary} PhoneBookEntrySummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookEntrySummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookEntrySummary();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.desc = reader.string();
                        break;
                    }
                case 5: {
                        message.online = reader.bool();
                        break;
                    }
                case 6: {
                        if (!(message.onlineEntityIds && message.onlineEntityIds.length))
                            message.onlineEntityIds = [];
                        message.onlineEntityIds.push(reader.string());
                        break;
                    }
                case 7: {
                        message.owner = reader.string();
                        break;
                    }
                case 8: {
                        message.statusIndex = reader.int32();
                        break;
                    }
                case 9: {
                        message.userUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("online"))
                throw $util.ProtocolError("missing required 'online'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookEntrySummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookEntrySummary} PhoneBookEntrySummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookEntrySummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookEntrySummary message.
         * @function verify
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookEntrySummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.id))
                return "id: string expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 1:
            case 2:
            case 3:
                break;
            }
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.desc != null && message.hasOwnProperty("desc"))
                if (!$util.isString(message.desc))
                    return "desc: string expected";
            if (typeof message.online !== "boolean")
                return "online: boolean expected";
            if (message.onlineEntityIds != null && message.hasOwnProperty("onlineEntityIds")) {
                if (!Array.isArray(message.onlineEntityIds))
                    return "onlineEntityIds: array expected";
                for (var i = 0; i < message.onlineEntityIds.length; ++i)
                    if (!$util.isString(message.onlineEntityIds[i]))
                        return "onlineEntityIds: string[] expected";
            }
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (!$util.isString(message.owner))
                    return "owner: string expected";
            if (message.statusIndex != null && message.hasOwnProperty("statusIndex"))
                if (!$util.isInteger(message.statusIndex))
                    return "statusIndex: integer expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            return null;
        };

        /**
         * Creates a PhoneBookEntrySummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookEntrySummary} PhoneBookEntrySummary
         */
        PhoneBookEntrySummary.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookEntrySummary)
                return object;
            var message = new $root.proto.PhoneBookEntrySummary();
            if (object.id != null)
                message.id = String(object.id);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "USER":
            case 1:
                message.type = 1;
                break;
            case "CALL_GROUP":
            case 2:
                message.type = 2;
                break;
            case "CONTACT":
            case 3:
                message.type = 3;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.desc != null)
                message.desc = String(object.desc);
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.onlineEntityIds) {
                if (!Array.isArray(object.onlineEntityIds))
                    throw TypeError(".proto.PhoneBookEntrySummary.onlineEntityIds: array expected");
                message.onlineEntityIds = [];
                for (var i = 0; i < object.onlineEntityIds.length; ++i)
                    message.onlineEntityIds[i] = String(object.onlineEntityIds[i]);
            }
            if (object.owner != null)
                message.owner = String(object.owner);
            if (object.statusIndex != null)
                message.statusIndex = object.statusIndex | 0;
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookEntrySummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {proto.PhoneBookEntrySummary} message PhoneBookEntrySummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookEntrySummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.onlineEntityIds = [];
            if (options.defaults) {
                object.id = "";
                object.type = options.enums === String ? "USER" : 1;
                object.name = "";
                object.desc = "";
                object.online = false;
                object.owner = "";
                object.statusIndex = 0;
                object.userUuid = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.PhoneBookEntryType[message.type] === undefined ? message.type : $root.proto.PhoneBookEntryType[message.type] : message.type;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.desc != null && message.hasOwnProperty("desc"))
                object.desc = message.desc;
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.onlineEntityIds && message.onlineEntityIds.length) {
                object.onlineEntityIds = [];
                for (var j = 0; j < message.onlineEntityIds.length; ++j)
                    object.onlineEntityIds[j] = message.onlineEntityIds[j];
            }
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            if (message.statusIndex != null && message.hasOwnProperty("statusIndex"))
                object.statusIndex = message.statusIndex;
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            return object;
        };

        /**
         * Converts this PhoneBookEntrySummary to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookEntrySummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookEntrySummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookEntrySummary
         * @function getTypeUrl
         * @memberof proto.PhoneBookEntrySummary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookEntrySummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookEntrySummary";
        };

        return PhoneBookEntrySummary;
    })();

    proto.PhoneBookEntryDetails = (function() {

        /**
         * Properties of a PhoneBookEntryDetails.
         * @memberof proto
         * @interface IPhoneBookEntryDetails
         * @property {string} id PhoneBookEntryDetails id
         * @property {proto.PhoneBookEntryType} type PhoneBookEntryDetails type
         * @property {string} name PhoneBookEntryDetails name
         * @property {string|null} [desc] PhoneBookEntryDetails desc
         * @property {boolean} online PhoneBookEntryDetails online
         * @property {Array.<string>|null} [phoneNumbers] PhoneBookEntryDetails phoneNumbers
         * @property {Array.<string>|null} [emails] PhoneBookEntryDetails emails
         * @property {string|null} [callableReference] PhoneBookEntryDetails callableReference
         * @property {Array.<string>|null} [onlineEntityIds] PhoneBookEntryDetails onlineEntityIds
         * @property {boolean|null} [locatable] PhoneBookEntryDetails locatable
         * @property {string|null} [owner] PhoneBookEntryDetails owner
         * @property {number|null} [statusIndex] PhoneBookEntryDetails statusIndex
         * @property {string|null} [userUuid] PhoneBookEntryDetails userUuid
         */

        /**
         * Constructs a new PhoneBookEntryDetails.
         * @memberof proto
         * @classdesc Represents a PhoneBookEntryDetails.
         * @implements IPhoneBookEntryDetails
         * @constructor
         * @param {proto.IPhoneBookEntryDetails=} [properties] Properties to set
         */
        function PhoneBookEntryDetails(properties) {
            this.phoneNumbers = [];
            this.emails = [];
            this.onlineEntityIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneBookEntryDetails id.
         * @member {string} id
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.id = "";

        /**
         * PhoneBookEntryDetails type.
         * @member {proto.PhoneBookEntryType} type
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.type = 1;

        /**
         * PhoneBookEntryDetails name.
         * @member {string} name
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.name = "";

        /**
         * PhoneBookEntryDetails desc.
         * @member {string} desc
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.desc = "";

        /**
         * PhoneBookEntryDetails online.
         * @member {boolean} online
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.online = false;

        /**
         * PhoneBookEntryDetails phoneNumbers.
         * @member {Array.<string>} phoneNumbers
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.phoneNumbers = $util.emptyArray;

        /**
         * PhoneBookEntryDetails emails.
         * @member {Array.<string>} emails
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.emails = $util.emptyArray;

        /**
         * PhoneBookEntryDetails callableReference.
         * @member {string} callableReference
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.callableReference = "";

        /**
         * PhoneBookEntryDetails onlineEntityIds.
         * @member {Array.<string>} onlineEntityIds
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.onlineEntityIds = $util.emptyArray;

        /**
         * PhoneBookEntryDetails locatable.
         * @member {boolean} locatable
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.locatable = false;

        /**
         * PhoneBookEntryDetails owner.
         * @member {string} owner
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.owner = "";

        /**
         * PhoneBookEntryDetails statusIndex.
         * @member {number} statusIndex
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.statusIndex = 0;

        /**
         * PhoneBookEntryDetails userUuid.
         * @member {string} userUuid
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         */
        PhoneBookEntryDetails.prototype.userUuid = "";

        /**
         * Creates a new PhoneBookEntryDetails instance using the specified properties.
         * @function create
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {proto.IPhoneBookEntryDetails=} [properties] Properties to set
         * @returns {proto.PhoneBookEntryDetails} PhoneBookEntryDetails instance
         */
        PhoneBookEntryDetails.create = function create(properties) {
            return new PhoneBookEntryDetails(properties);
        };

        /**
         * Encodes the specified PhoneBookEntryDetails message. Does not implicitly {@link proto.PhoneBookEntryDetails.verify|verify} messages.
         * @function encode
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {proto.IPhoneBookEntryDetails} message PhoneBookEntryDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookEntryDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.desc != null && Object.hasOwnProperty.call(message, "desc"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.desc);
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.online);
            if (message.phoneNumbers != null && message.phoneNumbers.length)
                for (var i = 0; i < message.phoneNumbers.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.phoneNumbers[i]);
            if (message.emails != null && message.emails.length)
                for (var i = 0; i < message.emails.length; ++i)
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.emails[i]);
            if (message.callableReference != null && Object.hasOwnProperty.call(message, "callableReference"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.callableReference);
            if (message.onlineEntityIds != null && message.onlineEntityIds.length)
                for (var i = 0; i < message.onlineEntityIds.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.onlineEntityIds[i]);
            if (message.locatable != null && Object.hasOwnProperty.call(message, "locatable"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.locatable);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.owner);
            if (message.statusIndex != null && Object.hasOwnProperty.call(message, "statusIndex"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.statusIndex);
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.userUuid);
            return writer;
        };

        /**
         * Encodes the specified PhoneBookEntryDetails message, length delimited. Does not implicitly {@link proto.PhoneBookEntryDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {proto.IPhoneBookEntryDetails} message PhoneBookEntryDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneBookEntryDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneBookEntryDetails message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PhoneBookEntryDetails} PhoneBookEntryDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookEntryDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PhoneBookEntryDetails();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.desc = reader.string();
                        break;
                    }
                case 5: {
                        message.online = reader.bool();
                        break;
                    }
                case 6: {
                        if (!(message.phoneNumbers && message.phoneNumbers.length))
                            message.phoneNumbers = [];
                        message.phoneNumbers.push(reader.string());
                        break;
                    }
                case 7: {
                        if (!(message.emails && message.emails.length))
                            message.emails = [];
                        message.emails.push(reader.string());
                        break;
                    }
                case 8: {
                        message.callableReference = reader.string();
                        break;
                    }
                case 9: {
                        if (!(message.onlineEntityIds && message.onlineEntityIds.length))
                            message.onlineEntityIds = [];
                        message.onlineEntityIds.push(reader.string());
                        break;
                    }
                case 10: {
                        message.locatable = reader.bool();
                        break;
                    }
                case 11: {
                        message.owner = reader.string();
                        break;
                    }
                case 12: {
                        message.statusIndex = reader.int32();
                        break;
                    }
                case 13: {
                        message.userUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("online"))
                throw $util.ProtocolError("missing required 'online'", { instance: message });
            return message;
        };

        /**
         * Decodes a PhoneBookEntryDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PhoneBookEntryDetails} PhoneBookEntryDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneBookEntryDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneBookEntryDetails message.
         * @function verify
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneBookEntryDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.id))
                return "id: string expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 1:
            case 2:
            case 3:
                break;
            }
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.desc != null && message.hasOwnProperty("desc"))
                if (!$util.isString(message.desc))
                    return "desc: string expected";
            if (typeof message.online !== "boolean")
                return "online: boolean expected";
            if (message.phoneNumbers != null && message.hasOwnProperty("phoneNumbers")) {
                if (!Array.isArray(message.phoneNumbers))
                    return "phoneNumbers: array expected";
                for (var i = 0; i < message.phoneNumbers.length; ++i)
                    if (!$util.isString(message.phoneNumbers[i]))
                        return "phoneNumbers: string[] expected";
            }
            if (message.emails != null && message.hasOwnProperty("emails")) {
                if (!Array.isArray(message.emails))
                    return "emails: array expected";
                for (var i = 0; i < message.emails.length; ++i)
                    if (!$util.isString(message.emails[i]))
                        return "emails: string[] expected";
            }
            if (message.callableReference != null && message.hasOwnProperty("callableReference"))
                if (!$util.isString(message.callableReference))
                    return "callableReference: string expected";
            if (message.onlineEntityIds != null && message.hasOwnProperty("onlineEntityIds")) {
                if (!Array.isArray(message.onlineEntityIds))
                    return "onlineEntityIds: array expected";
                for (var i = 0; i < message.onlineEntityIds.length; ++i)
                    if (!$util.isString(message.onlineEntityIds[i]))
                        return "onlineEntityIds: string[] expected";
            }
            if (message.locatable != null && message.hasOwnProperty("locatable"))
                if (typeof message.locatable !== "boolean")
                    return "locatable: boolean expected";
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (!$util.isString(message.owner))
                    return "owner: string expected";
            if (message.statusIndex != null && message.hasOwnProperty("statusIndex"))
                if (!$util.isInteger(message.statusIndex))
                    return "statusIndex: integer expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            return null;
        };

        /**
         * Creates a PhoneBookEntryDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PhoneBookEntryDetails} PhoneBookEntryDetails
         */
        PhoneBookEntryDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PhoneBookEntryDetails)
                return object;
            var message = new $root.proto.PhoneBookEntryDetails();
            if (object.id != null)
                message.id = String(object.id);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "USER":
            case 1:
                message.type = 1;
                break;
            case "CALL_GROUP":
            case 2:
                message.type = 2;
                break;
            case "CONTACT":
            case 3:
                message.type = 3;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.desc != null)
                message.desc = String(object.desc);
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.phoneNumbers) {
                if (!Array.isArray(object.phoneNumbers))
                    throw TypeError(".proto.PhoneBookEntryDetails.phoneNumbers: array expected");
                message.phoneNumbers = [];
                for (var i = 0; i < object.phoneNumbers.length; ++i)
                    message.phoneNumbers[i] = String(object.phoneNumbers[i]);
            }
            if (object.emails) {
                if (!Array.isArray(object.emails))
                    throw TypeError(".proto.PhoneBookEntryDetails.emails: array expected");
                message.emails = [];
                for (var i = 0; i < object.emails.length; ++i)
                    message.emails[i] = String(object.emails[i]);
            }
            if (object.callableReference != null)
                message.callableReference = String(object.callableReference);
            if (object.onlineEntityIds) {
                if (!Array.isArray(object.onlineEntityIds))
                    throw TypeError(".proto.PhoneBookEntryDetails.onlineEntityIds: array expected");
                message.onlineEntityIds = [];
                for (var i = 0; i < object.onlineEntityIds.length; ++i)
                    message.onlineEntityIds[i] = String(object.onlineEntityIds[i]);
            }
            if (object.locatable != null)
                message.locatable = Boolean(object.locatable);
            if (object.owner != null)
                message.owner = String(object.owner);
            if (object.statusIndex != null)
                message.statusIndex = object.statusIndex | 0;
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            return message;
        };

        /**
         * Creates a plain object from a PhoneBookEntryDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {proto.PhoneBookEntryDetails} message PhoneBookEntryDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneBookEntryDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.phoneNumbers = [];
                object.emails = [];
                object.onlineEntityIds = [];
            }
            if (options.defaults) {
                object.id = "";
                object.type = options.enums === String ? "USER" : 1;
                object.name = "";
                object.desc = "";
                object.online = false;
                object.callableReference = "";
                object.locatable = false;
                object.owner = "";
                object.statusIndex = 0;
                object.userUuid = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.PhoneBookEntryType[message.type] === undefined ? message.type : $root.proto.PhoneBookEntryType[message.type] : message.type;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.desc != null && message.hasOwnProperty("desc"))
                object.desc = message.desc;
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.phoneNumbers && message.phoneNumbers.length) {
                object.phoneNumbers = [];
                for (var j = 0; j < message.phoneNumbers.length; ++j)
                    object.phoneNumbers[j] = message.phoneNumbers[j];
            }
            if (message.emails && message.emails.length) {
                object.emails = [];
                for (var j = 0; j < message.emails.length; ++j)
                    object.emails[j] = message.emails[j];
            }
            if (message.callableReference != null && message.hasOwnProperty("callableReference"))
                object.callableReference = message.callableReference;
            if (message.onlineEntityIds && message.onlineEntityIds.length) {
                object.onlineEntityIds = [];
                for (var j = 0; j < message.onlineEntityIds.length; ++j)
                    object.onlineEntityIds[j] = message.onlineEntityIds[j];
            }
            if (message.locatable != null && message.hasOwnProperty("locatable"))
                object.locatable = message.locatable;
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            if (message.statusIndex != null && message.hasOwnProperty("statusIndex"))
                object.statusIndex = message.statusIndex;
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            return object;
        };

        /**
         * Converts this PhoneBookEntryDetails to JSON.
         * @function toJSON
         * @memberof proto.PhoneBookEntryDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneBookEntryDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneBookEntryDetails
         * @function getTypeUrl
         * @memberof proto.PhoneBookEntryDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneBookEntryDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.PhoneBookEntryDetails";
        };

        return PhoneBookEntryDetails;
    })();

    proto.SetupCallAPIv1Client = (function() {

        /**
         * Properties of a SetupCallAPIv1Client.
         * @memberof proto
         * @interface ISetupCallAPIv1Client
         * @property {proto.ISetupCallInitiateRequest|null} [initiateRequest] SetupCallAPIv1Client initiateRequest
         * @property {proto.IMonitoringInitiateRequest|null} [monitoringRequest] SetupCallAPIv1Client monitoringRequest
         */

        /**
         * Constructs a new SetupCallAPIv1Client.
         * @memberof proto
         * @classdesc Represents a SetupCallAPIv1Client.
         * @implements ISetupCallAPIv1Client
         * @constructor
         * @param {proto.ISetupCallAPIv1Client=} [properties] Properties to set
         */
        function SetupCallAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetupCallAPIv1Client initiateRequest.
         * @member {proto.ISetupCallInitiateRequest|null|undefined} initiateRequest
         * @memberof proto.SetupCallAPIv1Client
         * @instance
         */
        SetupCallAPIv1Client.prototype.initiateRequest = null;

        /**
         * SetupCallAPIv1Client monitoringRequest.
         * @member {proto.IMonitoringInitiateRequest|null|undefined} monitoringRequest
         * @memberof proto.SetupCallAPIv1Client
         * @instance
         */
        SetupCallAPIv1Client.prototype.monitoringRequest = null;

        /**
         * Creates a new SetupCallAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {proto.ISetupCallAPIv1Client=} [properties] Properties to set
         * @returns {proto.SetupCallAPIv1Client} SetupCallAPIv1Client instance
         */
        SetupCallAPIv1Client.create = function create(properties) {
            return new SetupCallAPIv1Client(properties);
        };

        /**
         * Encodes the specified SetupCallAPIv1Client message. Does not implicitly {@link proto.SetupCallAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {proto.ISetupCallAPIv1Client} message SetupCallAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.initiateRequest != null && Object.hasOwnProperty.call(message, "initiateRequest"))
                $root.proto.SetupCallInitiateRequest.encode(message.initiateRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.monitoringRequest != null && Object.hasOwnProperty.call(message, "monitoringRequest"))
                $root.proto.MonitoringInitiateRequest.encode(message.monitoringRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetupCallAPIv1Client message, length delimited. Does not implicitly {@link proto.SetupCallAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {proto.ISetupCallAPIv1Client} message SetupCallAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetupCallAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SetupCallAPIv1Client} SetupCallAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SetupCallAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.initiateRequest = $root.proto.SetupCallInitiateRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.monitoringRequest = $root.proto.MonitoringInitiateRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetupCallAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SetupCallAPIv1Client} SetupCallAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetupCallAPIv1Client message.
         * @function verify
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetupCallAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.initiateRequest != null && message.hasOwnProperty("initiateRequest")) {
                var error = $root.proto.SetupCallInitiateRequest.verify(message.initiateRequest);
                if (error)
                    return "initiateRequest." + error;
            }
            if (message.monitoringRequest != null && message.hasOwnProperty("monitoringRequest")) {
                var error = $root.proto.MonitoringInitiateRequest.verify(message.monitoringRequest);
                if (error)
                    return "monitoringRequest." + error;
            }
            return null;
        };

        /**
         * Creates a SetupCallAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SetupCallAPIv1Client} SetupCallAPIv1Client
         */
        SetupCallAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SetupCallAPIv1Client)
                return object;
            var message = new $root.proto.SetupCallAPIv1Client();
            if (object.initiateRequest != null) {
                if (typeof object.initiateRequest !== "object")
                    throw TypeError(".proto.SetupCallAPIv1Client.initiateRequest: object expected");
                message.initiateRequest = $root.proto.SetupCallInitiateRequest.fromObject(object.initiateRequest);
            }
            if (object.monitoringRequest != null) {
                if (typeof object.monitoringRequest !== "object")
                    throw TypeError(".proto.SetupCallAPIv1Client.monitoringRequest: object expected");
                message.monitoringRequest = $root.proto.MonitoringInitiateRequest.fromObject(object.monitoringRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a SetupCallAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {proto.SetupCallAPIv1Client} message SetupCallAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetupCallAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.initiateRequest = null;
                object.monitoringRequest = null;
            }
            if (message.initiateRequest != null && message.hasOwnProperty("initiateRequest"))
                object.initiateRequest = $root.proto.SetupCallInitiateRequest.toObject(message.initiateRequest, options);
            if (message.monitoringRequest != null && message.hasOwnProperty("monitoringRequest"))
                object.monitoringRequest = $root.proto.MonitoringInitiateRequest.toObject(message.monitoringRequest, options);
            return object;
        };

        /**
         * Converts this SetupCallAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.SetupCallAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetupCallAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetupCallAPIv1Client
         * @function getTypeUrl
         * @memberof proto.SetupCallAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetupCallAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SetupCallAPIv1Client";
        };

        return SetupCallAPIv1Client;
    })();

    proto.SetupCallInitiateRequest = (function() {

        /**
         * Properties of a SetupCallInitiateRequest.
         * @memberof proto
         * @interface ISetupCallInitiateRequest
         * @property {Array.<string>|null} [callableReferences] SetupCallInitiateRequest callableReferences
         * @property {number|Long|null} [sessionId] SetupCallInitiateRequest sessionId
         * @property {boolean|null} [autoAccept] SetupCallInitiateRequest autoAccept
         * @property {boolean|null} [fullDuplex] SetupCallInitiateRequest fullDuplex
         */

        /**
         * Constructs a new SetupCallInitiateRequest.
         * @memberof proto
         * @classdesc Represents a SetupCallInitiateRequest.
         * @implements ISetupCallInitiateRequest
         * @constructor
         * @param {proto.ISetupCallInitiateRequest=} [properties] Properties to set
         */
        function SetupCallInitiateRequest(properties) {
            this.callableReferences = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetupCallInitiateRequest callableReferences.
         * @member {Array.<string>} callableReferences
         * @memberof proto.SetupCallInitiateRequest
         * @instance
         */
        SetupCallInitiateRequest.prototype.callableReferences = $util.emptyArray;

        /**
         * SetupCallInitiateRequest sessionId.
         * @member {number|Long} sessionId
         * @memberof proto.SetupCallInitiateRequest
         * @instance
         */
        SetupCallInitiateRequest.prototype.sessionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SetupCallInitiateRequest autoAccept.
         * @member {boolean} autoAccept
         * @memberof proto.SetupCallInitiateRequest
         * @instance
         */
        SetupCallInitiateRequest.prototype.autoAccept = false;

        /**
         * SetupCallInitiateRequest fullDuplex.
         * @member {boolean} fullDuplex
         * @memberof proto.SetupCallInitiateRequest
         * @instance
         */
        SetupCallInitiateRequest.prototype.fullDuplex = false;

        /**
         * Creates a new SetupCallInitiateRequest instance using the specified properties.
         * @function create
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {proto.ISetupCallInitiateRequest=} [properties] Properties to set
         * @returns {proto.SetupCallInitiateRequest} SetupCallInitiateRequest instance
         */
        SetupCallInitiateRequest.create = function create(properties) {
            return new SetupCallInitiateRequest(properties);
        };

        /**
         * Encodes the specified SetupCallInitiateRequest message. Does not implicitly {@link proto.SetupCallInitiateRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {proto.ISetupCallInitiateRequest} message SetupCallInitiateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallInitiateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.callableReferences != null && message.callableReferences.length)
                for (var i = 0; i < message.callableReferences.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.callableReferences[i]);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sessionId);
            if (message.autoAccept != null && Object.hasOwnProperty.call(message, "autoAccept"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.autoAccept);
            if (message.fullDuplex != null && Object.hasOwnProperty.call(message, "fullDuplex"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.fullDuplex);
            return writer;
        };

        /**
         * Encodes the specified SetupCallInitiateRequest message, length delimited. Does not implicitly {@link proto.SetupCallInitiateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {proto.ISetupCallInitiateRequest} message SetupCallInitiateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallInitiateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetupCallInitiateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SetupCallInitiateRequest} SetupCallInitiateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallInitiateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SetupCallInitiateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.callableReferences && message.callableReferences.length))
                            message.callableReferences = [];
                        message.callableReferences.push(reader.string());
                        break;
                    }
                case 2: {
                        message.sessionId = reader.int64();
                        break;
                    }
                case 3: {
                        message.autoAccept = reader.bool();
                        break;
                    }
                case 4: {
                        message.fullDuplex = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetupCallInitiateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SetupCallInitiateRequest} SetupCallInitiateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallInitiateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetupCallInitiateRequest message.
         * @function verify
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetupCallInitiateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.callableReferences != null && message.hasOwnProperty("callableReferences")) {
                if (!Array.isArray(message.callableReferences))
                    return "callableReferences: array expected";
                for (var i = 0; i < message.callableReferences.length; ++i)
                    if (!$util.isString(message.callableReferences[i]))
                        return "callableReferences: string[] expected";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isInteger(message.sessionId) && !(message.sessionId && $util.isInteger(message.sessionId.low) && $util.isInteger(message.sessionId.high)))
                    return "sessionId: integer|Long expected";
            if (message.autoAccept != null && message.hasOwnProperty("autoAccept"))
                if (typeof message.autoAccept !== "boolean")
                    return "autoAccept: boolean expected";
            if (message.fullDuplex != null && message.hasOwnProperty("fullDuplex"))
                if (typeof message.fullDuplex !== "boolean")
                    return "fullDuplex: boolean expected";
            return null;
        };

        /**
         * Creates a SetupCallInitiateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SetupCallInitiateRequest} SetupCallInitiateRequest
         */
        SetupCallInitiateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SetupCallInitiateRequest)
                return object;
            var message = new $root.proto.SetupCallInitiateRequest();
            if (object.callableReferences) {
                if (!Array.isArray(object.callableReferences))
                    throw TypeError(".proto.SetupCallInitiateRequest.callableReferences: array expected");
                message.callableReferences = [];
                for (var i = 0; i < object.callableReferences.length; ++i)
                    message.callableReferences[i] = String(object.callableReferences[i]);
            }
            if (object.sessionId != null)
                if ($util.Long)
                    (message.sessionId = $util.Long.fromValue(object.sessionId)).unsigned = false;
                else if (typeof object.sessionId === "string")
                    message.sessionId = parseInt(object.sessionId, 10);
                else if (typeof object.sessionId === "number")
                    message.sessionId = object.sessionId;
                else if (typeof object.sessionId === "object")
                    message.sessionId = new $util.LongBits(object.sessionId.low >>> 0, object.sessionId.high >>> 0).toNumber();
            if (object.autoAccept != null)
                message.autoAccept = Boolean(object.autoAccept);
            if (object.fullDuplex != null)
                message.fullDuplex = Boolean(object.fullDuplex);
            return message;
        };

        /**
         * Creates a plain object from a SetupCallInitiateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {proto.SetupCallInitiateRequest} message SetupCallInitiateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetupCallInitiateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.callableReferences = [];
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.sessionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sessionId = options.longs === String ? "0" : 0;
                object.autoAccept = false;
                object.fullDuplex = false;
            }
            if (message.callableReferences && message.callableReferences.length) {
                object.callableReferences = [];
                for (var j = 0; j < message.callableReferences.length; ++j)
                    object.callableReferences[j] = message.callableReferences[j];
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (typeof message.sessionId === "number")
                    object.sessionId = options.longs === String ? String(message.sessionId) : message.sessionId;
                else
                    object.sessionId = options.longs === String ? $util.Long.prototype.toString.call(message.sessionId) : options.longs === Number ? new $util.LongBits(message.sessionId.low >>> 0, message.sessionId.high >>> 0).toNumber() : message.sessionId;
            if (message.autoAccept != null && message.hasOwnProperty("autoAccept"))
                object.autoAccept = message.autoAccept;
            if (message.fullDuplex != null && message.hasOwnProperty("fullDuplex"))
                object.fullDuplex = message.fullDuplex;
            return object;
        };

        /**
         * Converts this SetupCallInitiateRequest to JSON.
         * @function toJSON
         * @memberof proto.SetupCallInitiateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetupCallInitiateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetupCallInitiateRequest
         * @function getTypeUrl
         * @memberof proto.SetupCallInitiateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetupCallInitiateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SetupCallInitiateRequest";
        };

        return SetupCallInitiateRequest;
    })();

    proto.SetupCallInitiateResponse = (function() {

        /**
         * Properties of a SetupCallInitiateResponse.
         * @memberof proto
         * @interface ISetupCallInitiateResponse
         * @property {string} callRef SetupCallInitiateResponse callRef
         */

        /**
         * Constructs a new SetupCallInitiateResponse.
         * @memberof proto
         * @classdesc Represents a SetupCallInitiateResponse.
         * @implements ISetupCallInitiateResponse
         * @constructor
         * @param {proto.ISetupCallInitiateResponse=} [properties] Properties to set
         */
        function SetupCallInitiateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetupCallInitiateResponse callRef.
         * @member {string} callRef
         * @memberof proto.SetupCallInitiateResponse
         * @instance
         */
        SetupCallInitiateResponse.prototype.callRef = "";

        /**
         * Creates a new SetupCallInitiateResponse instance using the specified properties.
         * @function create
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {proto.ISetupCallInitiateResponse=} [properties] Properties to set
         * @returns {proto.SetupCallInitiateResponse} SetupCallInitiateResponse instance
         */
        SetupCallInitiateResponse.create = function create(properties) {
            return new SetupCallInitiateResponse(properties);
        };

        /**
         * Encodes the specified SetupCallInitiateResponse message. Does not implicitly {@link proto.SetupCallInitiateResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {proto.ISetupCallInitiateResponse} message SetupCallInitiateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallInitiateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callRef);
            return writer;
        };

        /**
         * Encodes the specified SetupCallInitiateResponse message, length delimited. Does not implicitly {@link proto.SetupCallInitiateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {proto.ISetupCallInitiateResponse} message SetupCallInitiateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallInitiateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetupCallInitiateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SetupCallInitiateResponse} SetupCallInitiateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallInitiateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SetupCallInitiateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callRef = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("callRef"))
                throw $util.ProtocolError("missing required 'callRef'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetupCallInitiateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SetupCallInitiateResponse} SetupCallInitiateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallInitiateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetupCallInitiateResponse message.
         * @function verify
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetupCallInitiateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.callRef))
                return "callRef: string expected";
            return null;
        };

        /**
         * Creates a SetupCallInitiateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SetupCallInitiateResponse} SetupCallInitiateResponse
         */
        SetupCallInitiateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SetupCallInitiateResponse)
                return object;
            var message = new $root.proto.SetupCallInitiateResponse();
            if (object.callRef != null)
                message.callRef = String(object.callRef);
            return message;
        };

        /**
         * Creates a plain object from a SetupCallInitiateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {proto.SetupCallInitiateResponse} message SetupCallInitiateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetupCallInitiateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.callRef = "";
            if (message.callRef != null && message.hasOwnProperty("callRef"))
                object.callRef = message.callRef;
            return object;
        };

        /**
         * Converts this SetupCallInitiateResponse to JSON.
         * @function toJSON
         * @memberof proto.SetupCallInitiateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetupCallInitiateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetupCallInitiateResponse
         * @function getTypeUrl
         * @memberof proto.SetupCallInitiateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetupCallInitiateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SetupCallInitiateResponse";
        };

        return SetupCallInitiateResponse;
    })();

    proto.MonitoringInitiateRequest = (function() {

        /**
         * Properties of a MonitoringInitiateRequest.
         * @memberof proto
         * @interface IMonitoringInitiateRequest
         * @property {string} entityId MonitoringInitiateRequest entityId
         */

        /**
         * Constructs a new MonitoringInitiateRequest.
         * @memberof proto
         * @classdesc Represents a MonitoringInitiateRequest.
         * @implements IMonitoringInitiateRequest
         * @constructor
         * @param {proto.IMonitoringInitiateRequest=} [properties] Properties to set
         */
        function MonitoringInitiateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonitoringInitiateRequest entityId.
         * @member {string} entityId
         * @memberof proto.MonitoringInitiateRequest
         * @instance
         */
        MonitoringInitiateRequest.prototype.entityId = "";

        /**
         * Creates a new MonitoringInitiateRequest instance using the specified properties.
         * @function create
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {proto.IMonitoringInitiateRequest=} [properties] Properties to set
         * @returns {proto.MonitoringInitiateRequest} MonitoringInitiateRequest instance
         */
        MonitoringInitiateRequest.create = function create(properties) {
            return new MonitoringInitiateRequest(properties);
        };

        /**
         * Encodes the specified MonitoringInitiateRequest message. Does not implicitly {@link proto.MonitoringInitiateRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {proto.IMonitoringInitiateRequest} message MonitoringInitiateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitoringInitiateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.entityId);
            return writer;
        };

        /**
         * Encodes the specified MonitoringInitiateRequest message, length delimited. Does not implicitly {@link proto.MonitoringInitiateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {proto.IMonitoringInitiateRequest} message MonitoringInitiateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitoringInitiateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MonitoringInitiateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.MonitoringInitiateRequest} MonitoringInitiateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitoringInitiateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.MonitoringInitiateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("entityId"))
                throw $util.ProtocolError("missing required 'entityId'", { instance: message });
            return message;
        };

        /**
         * Decodes a MonitoringInitiateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.MonitoringInitiateRequest} MonitoringInitiateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitoringInitiateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MonitoringInitiateRequest message.
         * @function verify
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitoringInitiateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.entityId))
                return "entityId: string expected";
            return null;
        };

        /**
         * Creates a MonitoringInitiateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.MonitoringInitiateRequest} MonitoringInitiateRequest
         */
        MonitoringInitiateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.MonitoringInitiateRequest)
                return object;
            var message = new $root.proto.MonitoringInitiateRequest();
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            return message;
        };

        /**
         * Creates a plain object from a MonitoringInitiateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {proto.MonitoringInitiateRequest} message MonitoringInitiateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitoringInitiateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.entityId = "";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            return object;
        };

        /**
         * Converts this MonitoringInitiateRequest to JSON.
         * @function toJSON
         * @memberof proto.MonitoringInitiateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitoringInitiateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MonitoringInitiateRequest
         * @function getTypeUrl
         * @memberof proto.MonitoringInitiateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MonitoringInitiateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.MonitoringInitiateRequest";
        };

        return MonitoringInitiateRequest;
    })();

    proto.MonitoringInitiateResponse = (function() {

        /**
         * Properties of a MonitoringInitiateResponse.
         * @memberof proto
         * @interface IMonitoringInitiateResponse
         * @property {string} callRef MonitoringInitiateResponse callRef
         */

        /**
         * Constructs a new MonitoringInitiateResponse.
         * @memberof proto
         * @classdesc Represents a MonitoringInitiateResponse.
         * @implements IMonitoringInitiateResponse
         * @constructor
         * @param {proto.IMonitoringInitiateResponse=} [properties] Properties to set
         */
        function MonitoringInitiateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonitoringInitiateResponse callRef.
         * @member {string} callRef
         * @memberof proto.MonitoringInitiateResponse
         * @instance
         */
        MonitoringInitiateResponse.prototype.callRef = "";

        /**
         * Creates a new MonitoringInitiateResponse instance using the specified properties.
         * @function create
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {proto.IMonitoringInitiateResponse=} [properties] Properties to set
         * @returns {proto.MonitoringInitiateResponse} MonitoringInitiateResponse instance
         */
        MonitoringInitiateResponse.create = function create(properties) {
            return new MonitoringInitiateResponse(properties);
        };

        /**
         * Encodes the specified MonitoringInitiateResponse message. Does not implicitly {@link proto.MonitoringInitiateResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {proto.IMonitoringInitiateResponse} message MonitoringInitiateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitoringInitiateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callRef);
            return writer;
        };

        /**
         * Encodes the specified MonitoringInitiateResponse message, length delimited. Does not implicitly {@link proto.MonitoringInitiateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {proto.IMonitoringInitiateResponse} message MonitoringInitiateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitoringInitiateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MonitoringInitiateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.MonitoringInitiateResponse} MonitoringInitiateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitoringInitiateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.MonitoringInitiateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callRef = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("callRef"))
                throw $util.ProtocolError("missing required 'callRef'", { instance: message });
            return message;
        };

        /**
         * Decodes a MonitoringInitiateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.MonitoringInitiateResponse} MonitoringInitiateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitoringInitiateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MonitoringInitiateResponse message.
         * @function verify
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitoringInitiateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.callRef))
                return "callRef: string expected";
            return null;
        };

        /**
         * Creates a MonitoringInitiateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.MonitoringInitiateResponse} MonitoringInitiateResponse
         */
        MonitoringInitiateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.MonitoringInitiateResponse)
                return object;
            var message = new $root.proto.MonitoringInitiateResponse();
            if (object.callRef != null)
                message.callRef = String(object.callRef);
            return message;
        };

        /**
         * Creates a plain object from a MonitoringInitiateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {proto.MonitoringInitiateResponse} message MonitoringInitiateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitoringInitiateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.callRef = "";
            if (message.callRef != null && message.hasOwnProperty("callRef"))
                object.callRef = message.callRef;
            return object;
        };

        /**
         * Converts this MonitoringInitiateResponse to JSON.
         * @function toJSON
         * @memberof proto.MonitoringInitiateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitoringInitiateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MonitoringInitiateResponse
         * @function getTypeUrl
         * @memberof proto.MonitoringInitiateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MonitoringInitiateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.MonitoringInitiateResponse";
        };

        return MonitoringInitiateResponse;
    })();

    proto.SetupCallAPIv1Server = (function() {

        /**
         * Properties of a SetupCallAPIv1Server.
         * @memberof proto
         * @interface ISetupCallAPIv1Server
         */

        /**
         * Constructs a new SetupCallAPIv1Server.
         * @memberof proto
         * @classdesc Represents a SetupCallAPIv1Server.
         * @implements ISetupCallAPIv1Server
         * @constructor
         * @param {proto.ISetupCallAPIv1Server=} [properties] Properties to set
         */
        function SetupCallAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetupCallAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {proto.ISetupCallAPIv1Server=} [properties] Properties to set
         * @returns {proto.SetupCallAPIv1Server} SetupCallAPIv1Server instance
         */
        SetupCallAPIv1Server.create = function create(properties) {
            return new SetupCallAPIv1Server(properties);
        };

        /**
         * Encodes the specified SetupCallAPIv1Server message. Does not implicitly {@link proto.SetupCallAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {proto.ISetupCallAPIv1Server} message SetupCallAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetupCallAPIv1Server message, length delimited. Does not implicitly {@link proto.SetupCallAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {proto.ISetupCallAPIv1Server} message SetupCallAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupCallAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetupCallAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SetupCallAPIv1Server} SetupCallAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SetupCallAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetupCallAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SetupCallAPIv1Server} SetupCallAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupCallAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetupCallAPIv1Server message.
         * @function verify
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetupCallAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetupCallAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SetupCallAPIv1Server} SetupCallAPIv1Server
         */
        SetupCallAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SetupCallAPIv1Server)
                return object;
            return new $root.proto.SetupCallAPIv1Server();
        };

        /**
         * Creates a plain object from a SetupCallAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {proto.SetupCallAPIv1Server} message SetupCallAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetupCallAPIv1Server.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetupCallAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.SetupCallAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetupCallAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetupCallAPIv1Server
         * @function getTypeUrl
         * @memberof proto.SetupCallAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetupCallAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SetupCallAPIv1Server";
        };

        return SetupCallAPIv1Server;
    })();

    proto.ReceiveCallAPIv1Client = (function() {

        /**
         * Properties of a ReceiveCallAPIv1Client.
         * @memberof proto
         * @interface IReceiveCallAPIv1Client
         * @property {proto.IReceiveCallSetupRequest|null} [setupRequest] ReceiveCallAPIv1Client setupRequest
         */

        /**
         * Constructs a new ReceiveCallAPIv1Client.
         * @memberof proto
         * @classdesc Represents a ReceiveCallAPIv1Client.
         * @implements IReceiveCallAPIv1Client
         * @constructor
         * @param {proto.IReceiveCallAPIv1Client=} [properties] Properties to set
         */
        function ReceiveCallAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveCallAPIv1Client setupRequest.
         * @member {proto.IReceiveCallSetupRequest|null|undefined} setupRequest
         * @memberof proto.ReceiveCallAPIv1Client
         * @instance
         */
        ReceiveCallAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new ReceiveCallAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {proto.IReceiveCallAPIv1Client=} [properties] Properties to set
         * @returns {proto.ReceiveCallAPIv1Client} ReceiveCallAPIv1Client instance
         */
        ReceiveCallAPIv1Client.create = function create(properties) {
            return new ReceiveCallAPIv1Client(properties);
        };

        /**
         * Encodes the specified ReceiveCallAPIv1Client message. Does not implicitly {@link proto.ReceiveCallAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {proto.IReceiveCallAPIv1Client} message ReceiveCallAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.ReceiveCallSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReceiveCallAPIv1Client message, length delimited. Does not implicitly {@link proto.ReceiveCallAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {proto.IReceiveCallAPIv1Client} message ReceiveCallAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiveCallAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ReceiveCallAPIv1Client} ReceiveCallAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ReceiveCallAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.ReceiveCallSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReceiveCallAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ReceiveCallAPIv1Client} ReceiveCallAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReceiveCallAPIv1Client message.
         * @function verify
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReceiveCallAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.ReceiveCallSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a ReceiveCallAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ReceiveCallAPIv1Client} ReceiveCallAPIv1Client
         */
        ReceiveCallAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ReceiveCallAPIv1Client)
                return object;
            var message = new $root.proto.ReceiveCallAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.ReceiveCallAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.ReceiveCallSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReceiveCallAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {proto.ReceiveCallAPIv1Client} message ReceiveCallAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveCallAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.ReceiveCallSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this ReceiveCallAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.ReceiveCallAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveCallAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveCallAPIv1Client
         * @function getTypeUrl
         * @memberof proto.ReceiveCallAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveCallAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ReceiveCallAPIv1Client";
        };

        return ReceiveCallAPIv1Client;
    })();

    proto.ReceiveCallSetupRequest = (function() {

        /**
         * Properties of a ReceiveCallSetupRequest.
         * @memberof proto
         * @interface IReceiveCallSetupRequest
         * @property {boolean|null} [supportsTwilioFullDuplex] ReceiveCallSetupRequest supportsTwilioFullDuplex
         */

        /**
         * Constructs a new ReceiveCallSetupRequest.
         * @memberof proto
         * @classdesc Represents a ReceiveCallSetupRequest.
         * @implements IReceiveCallSetupRequest
         * @constructor
         * @param {proto.IReceiveCallSetupRequest=} [properties] Properties to set
         */
        function ReceiveCallSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveCallSetupRequest supportsTwilioFullDuplex.
         * @member {boolean} supportsTwilioFullDuplex
         * @memberof proto.ReceiveCallSetupRequest
         * @instance
         */
        ReceiveCallSetupRequest.prototype.supportsTwilioFullDuplex = false;

        /**
         * Creates a new ReceiveCallSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {proto.IReceiveCallSetupRequest=} [properties] Properties to set
         * @returns {proto.ReceiveCallSetupRequest} ReceiveCallSetupRequest instance
         */
        ReceiveCallSetupRequest.create = function create(properties) {
            return new ReceiveCallSetupRequest(properties);
        };

        /**
         * Encodes the specified ReceiveCallSetupRequest message. Does not implicitly {@link proto.ReceiveCallSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {proto.IReceiveCallSetupRequest} message ReceiveCallSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.supportsTwilioFullDuplex != null && Object.hasOwnProperty.call(message, "supportsTwilioFullDuplex"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.supportsTwilioFullDuplex);
            return writer;
        };

        /**
         * Encodes the specified ReceiveCallSetupRequest message, length delimited. Does not implicitly {@link proto.ReceiveCallSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {proto.IReceiveCallSetupRequest} message ReceiveCallSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiveCallSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ReceiveCallSetupRequest} ReceiveCallSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ReceiveCallSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.supportsTwilioFullDuplex = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReceiveCallSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ReceiveCallSetupRequest} ReceiveCallSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReceiveCallSetupRequest message.
         * @function verify
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReceiveCallSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.supportsTwilioFullDuplex != null && message.hasOwnProperty("supportsTwilioFullDuplex"))
                if (typeof message.supportsTwilioFullDuplex !== "boolean")
                    return "supportsTwilioFullDuplex: boolean expected";
            return null;
        };

        /**
         * Creates a ReceiveCallSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ReceiveCallSetupRequest} ReceiveCallSetupRequest
         */
        ReceiveCallSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ReceiveCallSetupRequest)
                return object;
            var message = new $root.proto.ReceiveCallSetupRequest();
            if (object.supportsTwilioFullDuplex != null)
                message.supportsTwilioFullDuplex = Boolean(object.supportsTwilioFullDuplex);
            return message;
        };

        /**
         * Creates a plain object from a ReceiveCallSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {proto.ReceiveCallSetupRequest} message ReceiveCallSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveCallSetupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.supportsTwilioFullDuplex = false;
            if (message.supportsTwilioFullDuplex != null && message.hasOwnProperty("supportsTwilioFullDuplex"))
                object.supportsTwilioFullDuplex = message.supportsTwilioFullDuplex;
            return object;
        };

        /**
         * Converts this ReceiveCallSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.ReceiveCallSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveCallSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveCallSetupRequest
         * @function getTypeUrl
         * @memberof proto.ReceiveCallSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveCallSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ReceiveCallSetupRequest";
        };

        return ReceiveCallSetupRequest;
    })();

    proto.ReceiveCallAPIv1Server = (function() {

        /**
         * Properties of a ReceiveCallAPIv1Server.
         * @memberof proto
         * @interface IReceiveCallAPIv1Server
         * @property {proto.IReceiveCallStart|null} [receiveCallStart] ReceiveCallAPIv1Server receiveCallStart
         */

        /**
         * Constructs a new ReceiveCallAPIv1Server.
         * @memberof proto
         * @classdesc Represents a ReceiveCallAPIv1Server.
         * @implements IReceiveCallAPIv1Server
         * @constructor
         * @param {proto.IReceiveCallAPIv1Server=} [properties] Properties to set
         */
        function ReceiveCallAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveCallAPIv1Server receiveCallStart.
         * @member {proto.IReceiveCallStart|null|undefined} receiveCallStart
         * @memberof proto.ReceiveCallAPIv1Server
         * @instance
         */
        ReceiveCallAPIv1Server.prototype.receiveCallStart = null;

        /**
         * Creates a new ReceiveCallAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {proto.IReceiveCallAPIv1Server=} [properties] Properties to set
         * @returns {proto.ReceiveCallAPIv1Server} ReceiveCallAPIv1Server instance
         */
        ReceiveCallAPIv1Server.create = function create(properties) {
            return new ReceiveCallAPIv1Server(properties);
        };

        /**
         * Encodes the specified ReceiveCallAPIv1Server message. Does not implicitly {@link proto.ReceiveCallAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {proto.IReceiveCallAPIv1Server} message ReceiveCallAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.receiveCallStart != null && Object.hasOwnProperty.call(message, "receiveCallStart"))
                $root.proto.ReceiveCallStart.encode(message.receiveCallStart, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReceiveCallAPIv1Server message, length delimited. Does not implicitly {@link proto.ReceiveCallAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {proto.IReceiveCallAPIv1Server} message ReceiveCallAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiveCallAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ReceiveCallAPIv1Server} ReceiveCallAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ReceiveCallAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.receiveCallStart = $root.proto.ReceiveCallStart.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReceiveCallAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ReceiveCallAPIv1Server} ReceiveCallAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReceiveCallAPIv1Server message.
         * @function verify
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReceiveCallAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.receiveCallStart != null && message.hasOwnProperty("receiveCallStart")) {
                var error = $root.proto.ReceiveCallStart.verify(message.receiveCallStart);
                if (error)
                    return "receiveCallStart." + error;
            }
            return null;
        };

        /**
         * Creates a ReceiveCallAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ReceiveCallAPIv1Server} ReceiveCallAPIv1Server
         */
        ReceiveCallAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ReceiveCallAPIv1Server)
                return object;
            var message = new $root.proto.ReceiveCallAPIv1Server();
            if (object.receiveCallStart != null) {
                if (typeof object.receiveCallStart !== "object")
                    throw TypeError(".proto.ReceiveCallAPIv1Server.receiveCallStart: object expected");
                message.receiveCallStart = $root.proto.ReceiveCallStart.fromObject(object.receiveCallStart);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReceiveCallAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {proto.ReceiveCallAPIv1Server} message ReceiveCallAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveCallAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.receiveCallStart = null;
            if (message.receiveCallStart != null && message.hasOwnProperty("receiveCallStart"))
                object.receiveCallStart = $root.proto.ReceiveCallStart.toObject(message.receiveCallStart, options);
            return object;
        };

        /**
         * Converts this ReceiveCallAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.ReceiveCallAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveCallAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveCallAPIv1Server
         * @function getTypeUrl
         * @memberof proto.ReceiveCallAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveCallAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ReceiveCallAPIv1Server";
        };

        return ReceiveCallAPIv1Server;
    })();

    proto.ReceiveCallStart = (function() {

        /**
         * Properties of a ReceiveCallStart.
         * @memberof proto
         * @interface IReceiveCallStart
         * @property {string} caller ReceiveCallStart caller
         * @property {Array.<string>|null} [callees] ReceiveCallStart callees
         * @property {string|null} [callerPhoneBookEntryId] ReceiveCallStart callerPhoneBookEntryId
         * @property {string|null} [everyoneCallableReference] ReceiveCallStart everyoneCallableReference
         * @property {string|null} [callRef] ReceiveCallStart callRef
         * @property {string|null} [callerUserUuid] ReceiveCallStart callerUserUuid
         * @property {boolean|null} [fullDuplex] ReceiveCallStart fullDuplex
         */

        /**
         * Constructs a new ReceiveCallStart.
         * @memberof proto
         * @classdesc Represents a ReceiveCallStart.
         * @implements IReceiveCallStart
         * @constructor
         * @param {proto.IReceiveCallStart=} [properties] Properties to set
         */
        function ReceiveCallStart(properties) {
            this.callees = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveCallStart caller.
         * @member {string} caller
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.caller = "";

        /**
         * ReceiveCallStart callees.
         * @member {Array.<string>} callees
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.callees = $util.emptyArray;

        /**
         * ReceiveCallStart callerPhoneBookEntryId.
         * @member {string} callerPhoneBookEntryId
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.callerPhoneBookEntryId = "";

        /**
         * ReceiveCallStart everyoneCallableReference.
         * @member {string} everyoneCallableReference
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.everyoneCallableReference = "";

        /**
         * ReceiveCallStart callRef.
         * @member {string} callRef
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.callRef = "";

        /**
         * ReceiveCallStart callerUserUuid.
         * @member {string} callerUserUuid
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.callerUserUuid = "";

        /**
         * ReceiveCallStart fullDuplex.
         * @member {boolean} fullDuplex
         * @memberof proto.ReceiveCallStart
         * @instance
         */
        ReceiveCallStart.prototype.fullDuplex = false;

        /**
         * Creates a new ReceiveCallStart instance using the specified properties.
         * @function create
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {proto.IReceiveCallStart=} [properties] Properties to set
         * @returns {proto.ReceiveCallStart} ReceiveCallStart instance
         */
        ReceiveCallStart.create = function create(properties) {
            return new ReceiveCallStart(properties);
        };

        /**
         * Encodes the specified ReceiveCallStart message. Does not implicitly {@link proto.ReceiveCallStart.verify|verify} messages.
         * @function encode
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {proto.IReceiveCallStart} message ReceiveCallStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.caller);
            if (message.callees != null && message.callees.length)
                for (var i = 0; i < message.callees.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.callees[i]);
            if (message.callerPhoneBookEntryId != null && Object.hasOwnProperty.call(message, "callerPhoneBookEntryId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.callerPhoneBookEntryId);
            if (message.everyoneCallableReference != null && Object.hasOwnProperty.call(message, "everyoneCallableReference"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.everyoneCallableReference);
            if (message.callRef != null && Object.hasOwnProperty.call(message, "callRef"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.callRef);
            if (message.callerUserUuid != null && Object.hasOwnProperty.call(message, "callerUserUuid"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.callerUserUuid);
            if (message.fullDuplex != null && Object.hasOwnProperty.call(message, "fullDuplex"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.fullDuplex);
            return writer;
        };

        /**
         * Encodes the specified ReceiveCallStart message, length delimited. Does not implicitly {@link proto.ReceiveCallStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {proto.IReceiveCallStart} message ReceiveCallStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveCallStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiveCallStart message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ReceiveCallStart} ReceiveCallStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ReceiveCallStart();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.caller = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.callees && message.callees.length))
                            message.callees = [];
                        message.callees.push(reader.string());
                        break;
                    }
                case 3: {
                        message.callerPhoneBookEntryId = reader.string();
                        break;
                    }
                case 4: {
                        message.everyoneCallableReference = reader.string();
                        break;
                    }
                case 5: {
                        message.callRef = reader.string();
                        break;
                    }
                case 6: {
                        message.callerUserUuid = reader.string();
                        break;
                    }
                case 7: {
                        message.fullDuplex = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("caller"))
                throw $util.ProtocolError("missing required 'caller'", { instance: message });
            return message;
        };

        /**
         * Decodes a ReceiveCallStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ReceiveCallStart} ReceiveCallStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveCallStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReceiveCallStart message.
         * @function verify
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReceiveCallStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.caller))
                return "caller: string expected";
            if (message.callees != null && message.hasOwnProperty("callees")) {
                if (!Array.isArray(message.callees))
                    return "callees: array expected";
                for (var i = 0; i < message.callees.length; ++i)
                    if (!$util.isString(message.callees[i]))
                        return "callees: string[] expected";
            }
            if (message.callerPhoneBookEntryId != null && message.hasOwnProperty("callerPhoneBookEntryId"))
                if (!$util.isString(message.callerPhoneBookEntryId))
                    return "callerPhoneBookEntryId: string expected";
            if (message.everyoneCallableReference != null && message.hasOwnProperty("everyoneCallableReference"))
                if (!$util.isString(message.everyoneCallableReference))
                    return "everyoneCallableReference: string expected";
            if (message.callRef != null && message.hasOwnProperty("callRef"))
                if (!$util.isString(message.callRef))
                    return "callRef: string expected";
            if (message.callerUserUuid != null && message.hasOwnProperty("callerUserUuid"))
                if (!$util.isString(message.callerUserUuid))
                    return "callerUserUuid: string expected";
            if (message.fullDuplex != null && message.hasOwnProperty("fullDuplex"))
                if (typeof message.fullDuplex !== "boolean")
                    return "fullDuplex: boolean expected";
            return null;
        };

        /**
         * Creates a ReceiveCallStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ReceiveCallStart} ReceiveCallStart
         */
        ReceiveCallStart.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ReceiveCallStart)
                return object;
            var message = new $root.proto.ReceiveCallStart();
            if (object.caller != null)
                message.caller = String(object.caller);
            if (object.callees) {
                if (!Array.isArray(object.callees))
                    throw TypeError(".proto.ReceiveCallStart.callees: array expected");
                message.callees = [];
                for (var i = 0; i < object.callees.length; ++i)
                    message.callees[i] = String(object.callees[i]);
            }
            if (object.callerPhoneBookEntryId != null)
                message.callerPhoneBookEntryId = String(object.callerPhoneBookEntryId);
            if (object.everyoneCallableReference != null)
                message.everyoneCallableReference = String(object.everyoneCallableReference);
            if (object.callRef != null)
                message.callRef = String(object.callRef);
            if (object.callerUserUuid != null)
                message.callerUserUuid = String(object.callerUserUuid);
            if (object.fullDuplex != null)
                message.fullDuplex = Boolean(object.fullDuplex);
            return message;
        };

        /**
         * Creates a plain object from a ReceiveCallStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {proto.ReceiveCallStart} message ReceiveCallStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveCallStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.callees = [];
            if (options.defaults) {
                object.caller = "";
                object.callerPhoneBookEntryId = "";
                object.everyoneCallableReference = "";
                object.callRef = "";
                object.callerUserUuid = "";
                object.fullDuplex = false;
            }
            if (message.caller != null && message.hasOwnProperty("caller"))
                object.caller = message.caller;
            if (message.callees && message.callees.length) {
                object.callees = [];
                for (var j = 0; j < message.callees.length; ++j)
                    object.callees[j] = message.callees[j];
            }
            if (message.callerPhoneBookEntryId != null && message.hasOwnProperty("callerPhoneBookEntryId"))
                object.callerPhoneBookEntryId = message.callerPhoneBookEntryId;
            if (message.everyoneCallableReference != null && message.hasOwnProperty("everyoneCallableReference"))
                object.everyoneCallableReference = message.everyoneCallableReference;
            if (message.callRef != null && message.hasOwnProperty("callRef"))
                object.callRef = message.callRef;
            if (message.callerUserUuid != null && message.hasOwnProperty("callerUserUuid"))
                object.callerUserUuid = message.callerUserUuid;
            if (message.fullDuplex != null && message.hasOwnProperty("fullDuplex"))
                object.fullDuplex = message.fullDuplex;
            return object;
        };

        /**
         * Converts this ReceiveCallStart to JSON.
         * @function toJSON
         * @memberof proto.ReceiveCallStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveCallStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveCallStart
         * @function getTypeUrl
         * @memberof proto.ReceiveCallStart
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveCallStart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ReceiveCallStart";
        };

        return ReceiveCallStart;
    })();

    proto.CustomActionAPIv1Client = (function() {

        /**
         * Properties of a CustomActionAPIv1Client.
         * @memberof proto
         * @interface ICustomActionAPIv1Client
         * @property {proto.ICustomActionModuleSetupRequest|null} [setupRequest] CustomActionAPIv1Client setupRequest
         * @property {proto.ICustomActionPerformRequest|null} [performRequest] CustomActionAPIv1Client performRequest
         */

        /**
         * Constructs a new CustomActionAPIv1Client.
         * @memberof proto
         * @classdesc Represents a CustomActionAPIv1Client.
         * @implements ICustomActionAPIv1Client
         * @constructor
         * @param {proto.ICustomActionAPIv1Client=} [properties] Properties to set
         */
        function CustomActionAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomActionAPIv1Client setupRequest.
         * @member {proto.ICustomActionModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.CustomActionAPIv1Client
         * @instance
         */
        CustomActionAPIv1Client.prototype.setupRequest = null;

        /**
         * CustomActionAPIv1Client performRequest.
         * @member {proto.ICustomActionPerformRequest|null|undefined} performRequest
         * @memberof proto.CustomActionAPIv1Client
         * @instance
         */
        CustomActionAPIv1Client.prototype.performRequest = null;

        /**
         * Creates a new CustomActionAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {proto.ICustomActionAPIv1Client=} [properties] Properties to set
         * @returns {proto.CustomActionAPIv1Client} CustomActionAPIv1Client instance
         */
        CustomActionAPIv1Client.create = function create(properties) {
            return new CustomActionAPIv1Client(properties);
        };

        /**
         * Encodes the specified CustomActionAPIv1Client message. Does not implicitly {@link proto.CustomActionAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {proto.ICustomActionAPIv1Client} message CustomActionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.CustomActionModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.performRequest != null && Object.hasOwnProperty.call(message, "performRequest"))
                $root.proto.CustomActionPerformRequest.encode(message.performRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CustomActionAPIv1Client message, length delimited. Does not implicitly {@link proto.CustomActionAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {proto.ICustomActionAPIv1Client} message CustomActionAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomActionAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomActionAPIv1Client} CustomActionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomActionAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.CustomActionModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.performRequest = $root.proto.CustomActionPerformRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CustomActionAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomActionAPIv1Client} CustomActionAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomActionAPIv1Client message.
         * @function verify
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomActionAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.CustomActionModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.performRequest != null && message.hasOwnProperty("performRequest")) {
                var error = $root.proto.CustomActionPerformRequest.verify(message.performRequest);
                if (error)
                    return "performRequest." + error;
            }
            return null;
        };

        /**
         * Creates a CustomActionAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomActionAPIv1Client} CustomActionAPIv1Client
         */
        CustomActionAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomActionAPIv1Client)
                return object;
            var message = new $root.proto.CustomActionAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.CustomActionAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.CustomActionModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.performRequest != null) {
                if (typeof object.performRequest !== "object")
                    throw TypeError(".proto.CustomActionAPIv1Client.performRequest: object expected");
                message.performRequest = $root.proto.CustomActionPerformRequest.fromObject(object.performRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a CustomActionAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {proto.CustomActionAPIv1Client} message CustomActionAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomActionAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.performRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.CustomActionModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.performRequest != null && message.hasOwnProperty("performRequest"))
                object.performRequest = $root.proto.CustomActionPerformRequest.toObject(message.performRequest, options);
            return object;
        };

        /**
         * Converts this CustomActionAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.CustomActionAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomActionAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomActionAPIv1Client
         * @function getTypeUrl
         * @memberof proto.CustomActionAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomActionAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomActionAPIv1Client";
        };

        return CustomActionAPIv1Client;
    })();

    proto.CustomActionModuleSetupRequest = (function() {

        /**
         * Properties of a CustomActionModuleSetupRequest.
         * @memberof proto
         * @interface ICustomActionModuleSetupRequest
         */

        /**
         * Constructs a new CustomActionModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a CustomActionModuleSetupRequest.
         * @implements ICustomActionModuleSetupRequest
         * @constructor
         * @param {proto.ICustomActionModuleSetupRequest=} [properties] Properties to set
         */
        function CustomActionModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CustomActionModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {proto.ICustomActionModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.CustomActionModuleSetupRequest} CustomActionModuleSetupRequest instance
         */
        CustomActionModuleSetupRequest.create = function create(properties) {
            return new CustomActionModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified CustomActionModuleSetupRequest message. Does not implicitly {@link proto.CustomActionModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {proto.ICustomActionModuleSetupRequest} message CustomActionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CustomActionModuleSetupRequest message, length delimited. Does not implicitly {@link proto.CustomActionModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {proto.ICustomActionModuleSetupRequest} message CustomActionModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomActionModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomActionModuleSetupRequest} CustomActionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomActionModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CustomActionModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomActionModuleSetupRequest} CustomActionModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomActionModuleSetupRequest message.
         * @function verify
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomActionModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CustomActionModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomActionModuleSetupRequest} CustomActionModuleSetupRequest
         */
        CustomActionModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomActionModuleSetupRequest)
                return object;
            return new $root.proto.CustomActionModuleSetupRequest();
        };

        /**
         * Creates a plain object from a CustomActionModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {proto.CustomActionModuleSetupRequest} message CustomActionModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomActionModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CustomActionModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.CustomActionModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomActionModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomActionModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.CustomActionModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomActionModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomActionModuleSetupRequest";
        };

        return CustomActionModuleSetupRequest;
    })();

    proto.CustomActionModuleSetupResponse = (function() {

        /**
         * Properties of a CustomActionModuleSetupResponse.
         * @memberof proto
         * @interface ICustomActionModuleSetupResponse
         * @property {Array.<proto.ICustomAction>|null} [customActions] CustomActionModuleSetupResponse customActions
         */

        /**
         * Constructs a new CustomActionModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a CustomActionModuleSetupResponse.
         * @implements ICustomActionModuleSetupResponse
         * @constructor
         * @param {proto.ICustomActionModuleSetupResponse=} [properties] Properties to set
         */
        function CustomActionModuleSetupResponse(properties) {
            this.customActions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomActionModuleSetupResponse customActions.
         * @member {Array.<proto.ICustomAction>} customActions
         * @memberof proto.CustomActionModuleSetupResponse
         * @instance
         */
        CustomActionModuleSetupResponse.prototype.customActions = $util.emptyArray;

        /**
         * Creates a new CustomActionModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {proto.ICustomActionModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.CustomActionModuleSetupResponse} CustomActionModuleSetupResponse instance
         */
        CustomActionModuleSetupResponse.create = function create(properties) {
            return new CustomActionModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified CustomActionModuleSetupResponse message. Does not implicitly {@link proto.CustomActionModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {proto.ICustomActionModuleSetupResponse} message CustomActionModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customActions != null && message.customActions.length)
                for (var i = 0; i < message.customActions.length; ++i)
                    $root.proto.CustomAction.encode(message.customActions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CustomActionModuleSetupResponse message, length delimited. Does not implicitly {@link proto.CustomActionModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {proto.ICustomActionModuleSetupResponse} message CustomActionModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomActionModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomActionModuleSetupResponse} CustomActionModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomActionModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.customActions && message.customActions.length))
                            message.customActions = [];
                        message.customActions.push($root.proto.CustomAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CustomActionModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomActionModuleSetupResponse} CustomActionModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomActionModuleSetupResponse message.
         * @function verify
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomActionModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.customActions != null && message.hasOwnProperty("customActions")) {
                if (!Array.isArray(message.customActions))
                    return "customActions: array expected";
                for (var i = 0; i < message.customActions.length; ++i) {
                    var error = $root.proto.CustomAction.verify(message.customActions[i]);
                    if (error)
                        return "customActions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CustomActionModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomActionModuleSetupResponse} CustomActionModuleSetupResponse
         */
        CustomActionModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomActionModuleSetupResponse)
                return object;
            var message = new $root.proto.CustomActionModuleSetupResponse();
            if (object.customActions) {
                if (!Array.isArray(object.customActions))
                    throw TypeError(".proto.CustomActionModuleSetupResponse.customActions: array expected");
                message.customActions = [];
                for (var i = 0; i < object.customActions.length; ++i) {
                    if (typeof object.customActions[i] !== "object")
                        throw TypeError(".proto.CustomActionModuleSetupResponse.customActions: object expected");
                    message.customActions[i] = $root.proto.CustomAction.fromObject(object.customActions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CustomActionModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {proto.CustomActionModuleSetupResponse} message CustomActionModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomActionModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.customActions = [];
            if (message.customActions && message.customActions.length) {
                object.customActions = [];
                for (var j = 0; j < message.customActions.length; ++j)
                    object.customActions[j] = $root.proto.CustomAction.toObject(message.customActions[j], options);
            }
            return object;
        };

        /**
         * Converts this CustomActionModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.CustomActionModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomActionModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomActionModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.CustomActionModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomActionModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomActionModuleSetupResponse";
        };

        return CustomActionModuleSetupResponse;
    })();

    /**
     * CustomActionType enum.
     * @name proto.CustomActionType
     * @enum {number}
     * @property {number} GENERIC=1 GENERIC value
     * @property {number} QUEUE=2 QUEUE value
     */
    proto.CustomActionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "GENERIC"] = 1;
        values[valuesById[2] = "QUEUE"] = 2;
        return values;
    })();

    proto.CustomAction = (function() {

        /**
         * Properties of a CustomAction.
         * @memberof proto
         * @interface ICustomAction
         * @property {string} caption CustomAction caption
         * @property {proto.CustomActionType|null} [type] CustomAction type
         * @property {proto.ICustomPerformAction|null} [performAction] CustomAction performAction
         * @property {proto.ICustomFolderAction|null} [folderAction] CustomAction folderAction
         * @property {number|null} [priority] CustomAction priority
         */

        /**
         * Constructs a new CustomAction.
         * @memberof proto
         * @classdesc Represents a CustomAction.
         * @implements ICustomAction
         * @constructor
         * @param {proto.ICustomAction=} [properties] Properties to set
         */
        function CustomAction(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomAction caption.
         * @member {string} caption
         * @memberof proto.CustomAction
         * @instance
         */
        CustomAction.prototype.caption = "";

        /**
         * CustomAction type.
         * @member {proto.CustomActionType} type
         * @memberof proto.CustomAction
         * @instance
         */
        CustomAction.prototype.type = 1;

        /**
         * CustomAction performAction.
         * @member {proto.ICustomPerformAction|null|undefined} performAction
         * @memberof proto.CustomAction
         * @instance
         */
        CustomAction.prototype.performAction = null;

        /**
         * CustomAction folderAction.
         * @member {proto.ICustomFolderAction|null|undefined} folderAction
         * @memberof proto.CustomAction
         * @instance
         */
        CustomAction.prototype.folderAction = null;

        /**
         * CustomAction priority.
         * @member {number} priority
         * @memberof proto.CustomAction
         * @instance
         */
        CustomAction.prototype.priority = 0;

        /**
         * Creates a new CustomAction instance using the specified properties.
         * @function create
         * @memberof proto.CustomAction
         * @static
         * @param {proto.ICustomAction=} [properties] Properties to set
         * @returns {proto.CustomAction} CustomAction instance
         */
        CustomAction.create = function create(properties) {
            return new CustomAction(properties);
        };

        /**
         * Encodes the specified CustomAction message. Does not implicitly {@link proto.CustomAction.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomAction
         * @static
         * @param {proto.ICustomAction} message CustomAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.caption);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.performAction != null && Object.hasOwnProperty.call(message, "performAction"))
                $root.proto.CustomPerformAction.encode(message.performAction, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.folderAction != null && Object.hasOwnProperty.call(message, "folderAction"))
                $root.proto.CustomFolderAction.encode(message.folderAction, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.priority);
            return writer;
        };

        /**
         * Encodes the specified CustomAction message, length delimited. Does not implicitly {@link proto.CustomAction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomAction
         * @static
         * @param {proto.ICustomAction} message CustomAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomAction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomAction message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomAction} CustomAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomAction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.caption = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                case 3: {
                        message.performAction = $root.proto.CustomPerformAction.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.folderAction = $root.proto.CustomFolderAction.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.priority = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("caption"))
                throw $util.ProtocolError("missing required 'caption'", { instance: message });
            return message;
        };

        /**
         * Decodes a CustomAction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomAction} CustomAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomAction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomAction message.
         * @function verify
         * @memberof proto.CustomAction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomAction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.caption))
                return "caption: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 1:
                case 2:
                    break;
                }
            if (message.performAction != null && message.hasOwnProperty("performAction")) {
                var error = $root.proto.CustomPerformAction.verify(message.performAction);
                if (error)
                    return "performAction." + error;
            }
            if (message.folderAction != null && message.hasOwnProperty("folderAction")) {
                var error = $root.proto.CustomFolderAction.verify(message.folderAction);
                if (error)
                    return "folderAction." + error;
            }
            if (message.priority != null && message.hasOwnProperty("priority"))
                if (!$util.isInteger(message.priority))
                    return "priority: integer expected";
            return null;
        };

        /**
         * Creates a CustomAction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomAction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomAction} CustomAction
         */
        CustomAction.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomAction)
                return object;
            var message = new $root.proto.CustomAction();
            if (object.caption != null)
                message.caption = String(object.caption);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "GENERIC":
            case 1:
                message.type = 1;
                break;
            case "QUEUE":
            case 2:
                message.type = 2;
                break;
            }
            if (object.performAction != null) {
                if (typeof object.performAction !== "object")
                    throw TypeError(".proto.CustomAction.performAction: object expected");
                message.performAction = $root.proto.CustomPerformAction.fromObject(object.performAction);
            }
            if (object.folderAction != null) {
                if (typeof object.folderAction !== "object")
                    throw TypeError(".proto.CustomAction.folderAction: object expected");
                message.folderAction = $root.proto.CustomFolderAction.fromObject(object.folderAction);
            }
            if (object.priority != null)
                message.priority = object.priority | 0;
            return message;
        };

        /**
         * Creates a plain object from a CustomAction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomAction
         * @static
         * @param {proto.CustomAction} message CustomAction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.caption = "";
                object.type = options.enums === String ? "GENERIC" : 1;
                object.performAction = null;
                object.folderAction = null;
                object.priority = 0;
            }
            if (message.caption != null && message.hasOwnProperty("caption"))
                object.caption = message.caption;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.CustomActionType[message.type] === undefined ? message.type : $root.proto.CustomActionType[message.type] : message.type;
            if (message.performAction != null && message.hasOwnProperty("performAction"))
                object.performAction = $root.proto.CustomPerformAction.toObject(message.performAction, options);
            if (message.folderAction != null && message.hasOwnProperty("folderAction"))
                object.folderAction = $root.proto.CustomFolderAction.toObject(message.folderAction, options);
            if (message.priority != null && message.hasOwnProperty("priority"))
                object.priority = message.priority;
            return object;
        };

        /**
         * Converts this CustomAction to JSON.
         * @function toJSON
         * @memberof proto.CustomAction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomAction
         * @function getTypeUrl
         * @memberof proto.CustomAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomAction";
        };

        return CustomAction;
    })();

    proto.CustomPerformAction = (function() {

        /**
         * Properties of a CustomPerformAction.
         * @memberof proto
         * @interface ICustomPerformAction
         * @property {number|Long} id CustomPerformAction id
         */

        /**
         * Constructs a new CustomPerformAction.
         * @memberof proto
         * @classdesc Represents a CustomPerformAction.
         * @implements ICustomPerformAction
         * @constructor
         * @param {proto.ICustomPerformAction=} [properties] Properties to set
         */
        function CustomPerformAction(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomPerformAction id.
         * @member {number|Long} id
         * @memberof proto.CustomPerformAction
         * @instance
         */
        CustomPerformAction.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CustomPerformAction instance using the specified properties.
         * @function create
         * @memberof proto.CustomPerformAction
         * @static
         * @param {proto.ICustomPerformAction=} [properties] Properties to set
         * @returns {proto.CustomPerformAction} CustomPerformAction instance
         */
        CustomPerformAction.create = function create(properties) {
            return new CustomPerformAction(properties);
        };

        /**
         * Encodes the specified CustomPerformAction message. Does not implicitly {@link proto.CustomPerformAction.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomPerformAction
         * @static
         * @param {proto.ICustomPerformAction} message CustomPerformAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomPerformAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            return writer;
        };

        /**
         * Encodes the specified CustomPerformAction message, length delimited. Does not implicitly {@link proto.CustomPerformAction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomPerformAction
         * @static
         * @param {proto.ICustomPerformAction} message CustomPerformAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomPerformAction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomPerformAction message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomPerformAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomPerformAction} CustomPerformAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomPerformAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomPerformAction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            return message;
        };

        /**
         * Decodes a CustomPerformAction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomPerformAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomPerformAction} CustomPerformAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomPerformAction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomPerformAction message.
         * @function verify
         * @memberof proto.CustomPerformAction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomPerformAction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
            return null;
        };

        /**
         * Creates a CustomPerformAction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomPerformAction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomPerformAction} CustomPerformAction
         */
        CustomPerformAction.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomPerformAction)
                return object;
            var message = new $root.proto.CustomPerformAction();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CustomPerformAction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomPerformAction
         * @static
         * @param {proto.CustomPerformAction} message CustomPerformAction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomPerformAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            return object;
        };

        /**
         * Converts this CustomPerformAction to JSON.
         * @function toJSON
         * @memberof proto.CustomPerformAction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomPerformAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomPerformAction
         * @function getTypeUrl
         * @memberof proto.CustomPerformAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomPerformAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomPerformAction";
        };

        return CustomPerformAction;
    })();

    proto.CustomFolderAction = (function() {

        /**
         * Properties of a CustomFolderAction.
         * @memberof proto
         * @interface ICustomFolderAction
         * @property {Array.<proto.ICustomAction>|null} [actions] CustomFolderAction actions
         */

        /**
         * Constructs a new CustomFolderAction.
         * @memberof proto
         * @classdesc Represents a CustomFolderAction.
         * @implements ICustomFolderAction
         * @constructor
         * @param {proto.ICustomFolderAction=} [properties] Properties to set
         */
        function CustomFolderAction(properties) {
            this.actions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomFolderAction actions.
         * @member {Array.<proto.ICustomAction>} actions
         * @memberof proto.CustomFolderAction
         * @instance
         */
        CustomFolderAction.prototype.actions = $util.emptyArray;

        /**
         * Creates a new CustomFolderAction instance using the specified properties.
         * @function create
         * @memberof proto.CustomFolderAction
         * @static
         * @param {proto.ICustomFolderAction=} [properties] Properties to set
         * @returns {proto.CustomFolderAction} CustomFolderAction instance
         */
        CustomFolderAction.create = function create(properties) {
            return new CustomFolderAction(properties);
        };

        /**
         * Encodes the specified CustomFolderAction message. Does not implicitly {@link proto.CustomFolderAction.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomFolderAction
         * @static
         * @param {proto.ICustomFolderAction} message CustomFolderAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomFolderAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.actions != null && message.actions.length)
                for (var i = 0; i < message.actions.length; ++i)
                    $root.proto.CustomAction.encode(message.actions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CustomFolderAction message, length delimited. Does not implicitly {@link proto.CustomFolderAction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomFolderAction
         * @static
         * @param {proto.ICustomFolderAction} message CustomFolderAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomFolderAction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomFolderAction message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomFolderAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomFolderAction} CustomFolderAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomFolderAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomFolderAction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.actions && message.actions.length))
                            message.actions = [];
                        message.actions.push($root.proto.CustomAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CustomFolderAction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomFolderAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomFolderAction} CustomFolderAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomFolderAction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomFolderAction message.
         * @function verify
         * @memberof proto.CustomFolderAction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomFolderAction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.actions != null && message.hasOwnProperty("actions")) {
                if (!Array.isArray(message.actions))
                    return "actions: array expected";
                for (var i = 0; i < message.actions.length; ++i) {
                    var error = $root.proto.CustomAction.verify(message.actions[i]);
                    if (error)
                        return "actions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CustomFolderAction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomFolderAction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomFolderAction} CustomFolderAction
         */
        CustomFolderAction.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomFolderAction)
                return object;
            var message = new $root.proto.CustomFolderAction();
            if (object.actions) {
                if (!Array.isArray(object.actions))
                    throw TypeError(".proto.CustomFolderAction.actions: array expected");
                message.actions = [];
                for (var i = 0; i < object.actions.length; ++i) {
                    if (typeof object.actions[i] !== "object")
                        throw TypeError(".proto.CustomFolderAction.actions: object expected");
                    message.actions[i] = $root.proto.CustomAction.fromObject(object.actions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CustomFolderAction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomFolderAction
         * @static
         * @param {proto.CustomFolderAction} message CustomFolderAction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomFolderAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.actions = [];
            if (message.actions && message.actions.length) {
                object.actions = [];
                for (var j = 0; j < message.actions.length; ++j)
                    object.actions[j] = $root.proto.CustomAction.toObject(message.actions[j], options);
            }
            return object;
        };

        /**
         * Converts this CustomFolderAction to JSON.
         * @function toJSON
         * @memberof proto.CustomFolderAction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomFolderAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomFolderAction
         * @function getTypeUrl
         * @memberof proto.CustomFolderAction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomFolderAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomFolderAction";
        };

        return CustomFolderAction;
    })();

    proto.CustomActionPerformRequest = (function() {

        /**
         * Properties of a CustomActionPerformRequest.
         * @memberof proto
         * @interface ICustomActionPerformRequest
         * @property {number|Long} id CustomActionPerformRequest id
         */

        /**
         * Constructs a new CustomActionPerformRequest.
         * @memberof proto
         * @classdesc Represents a CustomActionPerformRequest.
         * @implements ICustomActionPerformRequest
         * @constructor
         * @param {proto.ICustomActionPerformRequest=} [properties] Properties to set
         */
        function CustomActionPerformRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomActionPerformRequest id.
         * @member {number|Long} id
         * @memberof proto.CustomActionPerformRequest
         * @instance
         */
        CustomActionPerformRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CustomActionPerformRequest instance using the specified properties.
         * @function create
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {proto.ICustomActionPerformRequest=} [properties] Properties to set
         * @returns {proto.CustomActionPerformRequest} CustomActionPerformRequest instance
         */
        CustomActionPerformRequest.create = function create(properties) {
            return new CustomActionPerformRequest(properties);
        };

        /**
         * Encodes the specified CustomActionPerformRequest message. Does not implicitly {@link proto.CustomActionPerformRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {proto.ICustomActionPerformRequest} message CustomActionPerformRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionPerformRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            return writer;
        };

        /**
         * Encodes the specified CustomActionPerformRequest message, length delimited. Does not implicitly {@link proto.CustomActionPerformRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {proto.ICustomActionPerformRequest} message CustomActionPerformRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomActionPerformRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomActionPerformRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CustomActionPerformRequest} CustomActionPerformRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionPerformRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CustomActionPerformRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            return message;
        };

        /**
         * Decodes a CustomActionPerformRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CustomActionPerformRequest} CustomActionPerformRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomActionPerformRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomActionPerformRequest message.
         * @function verify
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomActionPerformRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
            return null;
        };

        /**
         * Creates a CustomActionPerformRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CustomActionPerformRequest} CustomActionPerformRequest
         */
        CustomActionPerformRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CustomActionPerformRequest)
                return object;
            var message = new $root.proto.CustomActionPerformRequest();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CustomActionPerformRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {proto.CustomActionPerformRequest} message CustomActionPerformRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomActionPerformRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            return object;
        };

        /**
         * Converts this CustomActionPerformRequest to JSON.
         * @function toJSON
         * @memberof proto.CustomActionPerformRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomActionPerformRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CustomActionPerformRequest
         * @function getTypeUrl
         * @memberof proto.CustomActionPerformRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CustomActionPerformRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CustomActionPerformRequest";
        };

        return CustomActionPerformRequest;
    })();

    proto.HTTPRequestAPIv1Client = (function() {

        /**
         * Properties of a HTTPRequestAPIv1Client.
         * @memberof proto
         * @interface IHTTPRequestAPIv1Client
         * @property {proto.IHTTPRequestModuleSetupRequest|null} [setupRequest] HTTPRequestAPIv1Client setupRequest
         */

        /**
         * Constructs a new HTTPRequestAPIv1Client.
         * @memberof proto
         * @classdesc Represents a HTTPRequestAPIv1Client.
         * @implements IHTTPRequestAPIv1Client
         * @constructor
         * @param {proto.IHTTPRequestAPIv1Client=} [properties] Properties to set
         */
        function HTTPRequestAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HTTPRequestAPIv1Client setupRequest.
         * @member {proto.IHTTPRequestModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.HTTPRequestAPIv1Client
         * @instance
         */
        HTTPRequestAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new HTTPRequestAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {proto.IHTTPRequestAPIv1Client=} [properties] Properties to set
         * @returns {proto.HTTPRequestAPIv1Client} HTTPRequestAPIv1Client instance
         */
        HTTPRequestAPIv1Client.create = function create(properties) {
            return new HTTPRequestAPIv1Client(properties);
        };

        /**
         * Encodes the specified HTTPRequestAPIv1Client message. Does not implicitly {@link proto.HTTPRequestAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {proto.IHTTPRequestAPIv1Client} message HTTPRequestAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.HTTPRequestModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HTTPRequestAPIv1Client message, length delimited. Does not implicitly {@link proto.HTTPRequestAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {proto.IHTTPRequestAPIv1Client} message HTTPRequestAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTTPRequestAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.HTTPRequestAPIv1Client} HTTPRequestAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.HTTPRequestAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.HTTPRequestModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HTTPRequestAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.HTTPRequestAPIv1Client} HTTPRequestAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTTPRequestAPIv1Client message.
         * @function verify
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTTPRequestAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.HTTPRequestModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a HTTPRequestAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.HTTPRequestAPIv1Client} HTTPRequestAPIv1Client
         */
        HTTPRequestAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.HTTPRequestAPIv1Client)
                return object;
            var message = new $root.proto.HTTPRequestAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.HTTPRequestAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.HTTPRequestModuleSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a HTTPRequestAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {proto.HTTPRequestAPIv1Client} message HTTPRequestAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTTPRequestAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.HTTPRequestModuleSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this HTTPRequestAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.HTTPRequestAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTTPRequestAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HTTPRequestAPIv1Client
         * @function getTypeUrl
         * @memberof proto.HTTPRequestAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HTTPRequestAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.HTTPRequestAPIv1Client";
        };

        return HTTPRequestAPIv1Client;
    })();

    proto.HTTPRequestModuleSetupRequest = (function() {

        /**
         * Properties of a HTTPRequestModuleSetupRequest.
         * @memberof proto
         * @interface IHTTPRequestModuleSetupRequest
         */

        /**
         * Constructs a new HTTPRequestModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a HTTPRequestModuleSetupRequest.
         * @implements IHTTPRequestModuleSetupRequest
         * @constructor
         * @param {proto.IHTTPRequestModuleSetupRequest=} [properties] Properties to set
         */
        function HTTPRequestModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new HTTPRequestModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {proto.IHTTPRequestModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.HTTPRequestModuleSetupRequest} HTTPRequestModuleSetupRequest instance
         */
        HTTPRequestModuleSetupRequest.create = function create(properties) {
            return new HTTPRequestModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified HTTPRequestModuleSetupRequest message. Does not implicitly {@link proto.HTTPRequestModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {proto.IHTTPRequestModuleSetupRequest} message HTTPRequestModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified HTTPRequestModuleSetupRequest message, length delimited. Does not implicitly {@link proto.HTTPRequestModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {proto.IHTTPRequestModuleSetupRequest} message HTTPRequestModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTTPRequestModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.HTTPRequestModuleSetupRequest} HTTPRequestModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.HTTPRequestModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HTTPRequestModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.HTTPRequestModuleSetupRequest} HTTPRequestModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTTPRequestModuleSetupRequest message.
         * @function verify
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTTPRequestModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a HTTPRequestModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.HTTPRequestModuleSetupRequest} HTTPRequestModuleSetupRequest
         */
        HTTPRequestModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.HTTPRequestModuleSetupRequest)
                return object;
            return new $root.proto.HTTPRequestModuleSetupRequest();
        };

        /**
         * Creates a plain object from a HTTPRequestModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {proto.HTTPRequestModuleSetupRequest} message HTTPRequestModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTTPRequestModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this HTTPRequestModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTTPRequestModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HTTPRequestModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.HTTPRequestModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HTTPRequestModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.HTTPRequestModuleSetupRequest";
        };

        return HTTPRequestModuleSetupRequest;
    })();

    proto.HTTPRequestAPIv1Server = (function() {

        /**
         * Properties of a HTTPRequestAPIv1Server.
         * @memberof proto
         * @interface IHTTPRequestAPIv1Server
         * @property {proto.IHTTPRequest|null} [httpRequest] HTTPRequestAPIv1Server httpRequest
         */

        /**
         * Constructs a new HTTPRequestAPIv1Server.
         * @memberof proto
         * @classdesc Represents a HTTPRequestAPIv1Server.
         * @implements IHTTPRequestAPIv1Server
         * @constructor
         * @param {proto.IHTTPRequestAPIv1Server=} [properties] Properties to set
         */
        function HTTPRequestAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HTTPRequestAPIv1Server httpRequest.
         * @member {proto.IHTTPRequest|null|undefined} httpRequest
         * @memberof proto.HTTPRequestAPIv1Server
         * @instance
         */
        HTTPRequestAPIv1Server.prototype.httpRequest = null;

        /**
         * Creates a new HTTPRequestAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {proto.IHTTPRequestAPIv1Server=} [properties] Properties to set
         * @returns {proto.HTTPRequestAPIv1Server} HTTPRequestAPIv1Server instance
         */
        HTTPRequestAPIv1Server.create = function create(properties) {
            return new HTTPRequestAPIv1Server(properties);
        };

        /**
         * Encodes the specified HTTPRequestAPIv1Server message. Does not implicitly {@link proto.HTTPRequestAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {proto.IHTTPRequestAPIv1Server} message HTTPRequestAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.httpRequest != null && Object.hasOwnProperty.call(message, "httpRequest"))
                $root.proto.HTTPRequest.encode(message.httpRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HTTPRequestAPIv1Server message, length delimited. Does not implicitly {@link proto.HTTPRequestAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {proto.IHTTPRequestAPIv1Server} message HTTPRequestAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTTPRequestAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.HTTPRequestAPIv1Server} HTTPRequestAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.HTTPRequestAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.httpRequest = $root.proto.HTTPRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HTTPRequestAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.HTTPRequestAPIv1Server} HTTPRequestAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTTPRequestAPIv1Server message.
         * @function verify
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTTPRequestAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.httpRequest != null && message.hasOwnProperty("httpRequest")) {
                var error = $root.proto.HTTPRequest.verify(message.httpRequest);
                if (error)
                    return "httpRequest." + error;
            }
            return null;
        };

        /**
         * Creates a HTTPRequestAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.HTTPRequestAPIv1Server} HTTPRequestAPIv1Server
         */
        HTTPRequestAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.HTTPRequestAPIv1Server)
                return object;
            var message = new $root.proto.HTTPRequestAPIv1Server();
            if (object.httpRequest != null) {
                if (typeof object.httpRequest !== "object")
                    throw TypeError(".proto.HTTPRequestAPIv1Server.httpRequest: object expected");
                message.httpRequest = $root.proto.HTTPRequest.fromObject(object.httpRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a HTTPRequestAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {proto.HTTPRequestAPIv1Server} message HTTPRequestAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTTPRequestAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.httpRequest = null;
            if (message.httpRequest != null && message.hasOwnProperty("httpRequest"))
                object.httpRequest = $root.proto.HTTPRequest.toObject(message.httpRequest, options);
            return object;
        };

        /**
         * Converts this HTTPRequestAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.HTTPRequestAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTTPRequestAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HTTPRequestAPIv1Server
         * @function getTypeUrl
         * @memberof proto.HTTPRequestAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HTTPRequestAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.HTTPRequestAPIv1Server";
        };

        return HTTPRequestAPIv1Server;
    })();

    /**
     * HTTPRequestType enum.
     * @name proto.HTTPRequestType
     * @enum {number}
     * @property {number} BACKGROUND_POST=1 BACKGROUND_POST value
     * @property {number} BACKGROUND_GET=2 BACKGROUND_GET value
     * @property {number} BROWSER_GET=3 BROWSER_GET value
     */
    proto.HTTPRequestType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "BACKGROUND_POST"] = 1;
        values[valuesById[2] = "BACKGROUND_GET"] = 2;
        values[valuesById[3] = "BROWSER_GET"] = 3;
        return values;
    })();

    proto.HTTPRequest = (function() {

        /**
         * Properties of a HTTPRequest.
         * @memberof proto
         * @interface IHTTPRequest
         * @property {proto.HTTPRequestType} type HTTPRequest type
         * @property {string} url HTTPRequest url
         */

        /**
         * Constructs a new HTTPRequest.
         * @memberof proto
         * @classdesc Represents a HTTPRequest.
         * @implements IHTTPRequest
         * @constructor
         * @param {proto.IHTTPRequest=} [properties] Properties to set
         */
        function HTTPRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HTTPRequest type.
         * @member {proto.HTTPRequestType} type
         * @memberof proto.HTTPRequest
         * @instance
         */
        HTTPRequest.prototype.type = 1;

        /**
         * HTTPRequest url.
         * @member {string} url
         * @memberof proto.HTTPRequest
         * @instance
         */
        HTTPRequest.prototype.url = "";

        /**
         * Creates a new HTTPRequest instance using the specified properties.
         * @function create
         * @memberof proto.HTTPRequest
         * @static
         * @param {proto.IHTTPRequest=} [properties] Properties to set
         * @returns {proto.HTTPRequest} HTTPRequest instance
         */
        HTTPRequest.create = function create(properties) {
            return new HTTPRequest(properties);
        };

        /**
         * Encodes the specified HTTPRequest message. Does not implicitly {@link proto.HTTPRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.HTTPRequest
         * @static
         * @param {proto.IHTTPRequest} message HTTPRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            return writer;
        };

        /**
         * Encodes the specified HTTPRequest message, length delimited. Does not implicitly {@link proto.HTTPRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.HTTPRequest
         * @static
         * @param {proto.IHTTPRequest} message HTTPRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTTPRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.HTTPRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.HTTPRequest} HTTPRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.HTTPRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            if (!message.hasOwnProperty("url"))
                throw $util.ProtocolError("missing required 'url'", { instance: message });
            return message;
        };

        /**
         * Decodes a HTTPRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.HTTPRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.HTTPRequest} HTTPRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTTPRequest message.
         * @function verify
         * @memberof proto.HTTPRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTTPRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 1:
            case 2:
            case 3:
                break;
            }
            if (!$util.isString(message.url))
                return "url: string expected";
            return null;
        };

        /**
         * Creates a HTTPRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.HTTPRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.HTTPRequest} HTTPRequest
         */
        HTTPRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.HTTPRequest)
                return object;
            var message = new $root.proto.HTTPRequest();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "BACKGROUND_POST":
            case 1:
                message.type = 1;
                break;
            case "BACKGROUND_GET":
            case 2:
                message.type = 2;
                break;
            case "BROWSER_GET":
            case 3:
                message.type = 3;
                break;
            }
            if (object.url != null)
                message.url = String(object.url);
            return message;
        };

        /**
         * Creates a plain object from a HTTPRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.HTTPRequest
         * @static
         * @param {proto.HTTPRequest} message HTTPRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTTPRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "BACKGROUND_POST" : 1;
                object.url = "";
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.HTTPRequestType[message.type] === undefined ? message.type : $root.proto.HTTPRequestType[message.type] : message.type;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            return object;
        };

        /**
         * Converts this HTTPRequest to JSON.
         * @function toJSON
         * @memberof proto.HTTPRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTTPRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HTTPRequest
         * @function getTypeUrl
         * @memberof proto.HTTPRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HTTPRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.HTTPRequest";
        };

        return HTTPRequest;
    })();

    proto.HTTPRequestResponse = (function() {

        /**
         * Properties of a HTTPRequestResponse.
         * @memberof proto
         * @interface IHTTPRequestResponse
         * @property {number} responseCode HTTPRequestResponse responseCode
         */

        /**
         * Constructs a new HTTPRequestResponse.
         * @memberof proto
         * @classdesc Represents a HTTPRequestResponse.
         * @implements IHTTPRequestResponse
         * @constructor
         * @param {proto.IHTTPRequestResponse=} [properties] Properties to set
         */
        function HTTPRequestResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HTTPRequestResponse responseCode.
         * @member {number} responseCode
         * @memberof proto.HTTPRequestResponse
         * @instance
         */
        HTTPRequestResponse.prototype.responseCode = 0;

        /**
         * Creates a new HTTPRequestResponse instance using the specified properties.
         * @function create
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {proto.IHTTPRequestResponse=} [properties] Properties to set
         * @returns {proto.HTTPRequestResponse} HTTPRequestResponse instance
         */
        HTTPRequestResponse.create = function create(properties) {
            return new HTTPRequestResponse(properties);
        };

        /**
         * Encodes the specified HTTPRequestResponse message. Does not implicitly {@link proto.HTTPRequestResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {proto.IHTTPRequestResponse} message HTTPRequestResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.responseCode);
            return writer;
        };

        /**
         * Encodes the specified HTTPRequestResponse message, length delimited. Does not implicitly {@link proto.HTTPRequestResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {proto.IHTTPRequestResponse} message HTTPRequestResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTTPRequestResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTTPRequestResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.HTTPRequestResponse} HTTPRequestResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.HTTPRequestResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.responseCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("responseCode"))
                throw $util.ProtocolError("missing required 'responseCode'", { instance: message });
            return message;
        };

        /**
         * Decodes a HTTPRequestResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.HTTPRequestResponse} HTTPRequestResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTTPRequestResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTTPRequestResponse message.
         * @function verify
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTTPRequestResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.responseCode))
                return "responseCode: integer expected";
            return null;
        };

        /**
         * Creates a HTTPRequestResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.HTTPRequestResponse} HTTPRequestResponse
         */
        HTTPRequestResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.HTTPRequestResponse)
                return object;
            var message = new $root.proto.HTTPRequestResponse();
            if (object.responseCode != null)
                message.responseCode = object.responseCode | 0;
            return message;
        };

        /**
         * Creates a plain object from a HTTPRequestResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {proto.HTTPRequestResponse} message HTTPRequestResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTTPRequestResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.responseCode = 0;
            if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                object.responseCode = message.responseCode;
            return object;
        };

        /**
         * Converts this HTTPRequestResponse to JSON.
         * @function toJSON
         * @memberof proto.HTTPRequestResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTTPRequestResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HTTPRequestResponse
         * @function getTypeUrl
         * @memberof proto.HTTPRequestResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HTTPRequestResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.HTTPRequestResponse";
        };

        return HTTPRequestResponse;
    })();

    proto.EmergencyAPIv1Client = (function() {

        /**
         * Properties of an EmergencyAPIv1Client.
         * @memberof proto
         * @interface IEmergencyAPIv1Client
         * @property {proto.IEmergencyModuleSetupRequest|null} [setupRequest] EmergencyAPIv1Client setupRequest
         * @property {proto.IEmergencyModuleRaiseAlarmRequest|null} [raiseAlarmRequest] EmergencyAPIv1Client raiseAlarmRequest
         * @property {proto.IEmergencyModuleClearAlarmRequest|null} [clearAlarmRequest] EmergencyAPIv1Client clearAlarmRequest
         */

        /**
         * Constructs a new EmergencyAPIv1Client.
         * @memberof proto
         * @classdesc Represents an EmergencyAPIv1Client.
         * @implements IEmergencyAPIv1Client
         * @constructor
         * @param {proto.IEmergencyAPIv1Client=} [properties] Properties to set
         */
        function EmergencyAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyAPIv1Client setupRequest.
         * @member {proto.IEmergencyModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.EmergencyAPIv1Client
         * @instance
         */
        EmergencyAPIv1Client.prototype.setupRequest = null;

        /**
         * EmergencyAPIv1Client raiseAlarmRequest.
         * @member {proto.IEmergencyModuleRaiseAlarmRequest|null|undefined} raiseAlarmRequest
         * @memberof proto.EmergencyAPIv1Client
         * @instance
         */
        EmergencyAPIv1Client.prototype.raiseAlarmRequest = null;

        /**
         * EmergencyAPIv1Client clearAlarmRequest.
         * @member {proto.IEmergencyModuleClearAlarmRequest|null|undefined} clearAlarmRequest
         * @memberof proto.EmergencyAPIv1Client
         * @instance
         */
        EmergencyAPIv1Client.prototype.clearAlarmRequest = null;

        /**
         * Creates a new EmergencyAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {proto.IEmergencyAPIv1Client=} [properties] Properties to set
         * @returns {proto.EmergencyAPIv1Client} EmergencyAPIv1Client instance
         */
        EmergencyAPIv1Client.create = function create(properties) {
            return new EmergencyAPIv1Client(properties);
        };

        /**
         * Encodes the specified EmergencyAPIv1Client message. Does not implicitly {@link proto.EmergencyAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {proto.IEmergencyAPIv1Client} message EmergencyAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.EmergencyModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.raiseAlarmRequest != null && Object.hasOwnProperty.call(message, "raiseAlarmRequest"))
                $root.proto.EmergencyModuleRaiseAlarmRequest.encode(message.raiseAlarmRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.clearAlarmRequest != null && Object.hasOwnProperty.call(message, "clearAlarmRequest"))
                $root.proto.EmergencyModuleClearAlarmRequest.encode(message.clearAlarmRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EmergencyAPIv1Client message, length delimited. Does not implicitly {@link proto.EmergencyAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {proto.IEmergencyAPIv1Client} message EmergencyAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyAPIv1Client} EmergencyAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.EmergencyModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.raiseAlarmRequest = $root.proto.EmergencyModuleRaiseAlarmRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.clearAlarmRequest = $root.proto.EmergencyModuleClearAlarmRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmergencyAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyAPIv1Client} EmergencyAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyAPIv1Client message.
         * @function verify
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.EmergencyModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.raiseAlarmRequest != null && message.hasOwnProperty("raiseAlarmRequest")) {
                var error = $root.proto.EmergencyModuleRaiseAlarmRequest.verify(message.raiseAlarmRequest);
                if (error)
                    return "raiseAlarmRequest." + error;
            }
            if (message.clearAlarmRequest != null && message.hasOwnProperty("clearAlarmRequest")) {
                var error = $root.proto.EmergencyModuleClearAlarmRequest.verify(message.clearAlarmRequest);
                if (error)
                    return "clearAlarmRequest." + error;
            }
            return null;
        };

        /**
         * Creates an EmergencyAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyAPIv1Client} EmergencyAPIv1Client
         */
        EmergencyAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyAPIv1Client)
                return object;
            var message = new $root.proto.EmergencyAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.EmergencyAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.EmergencyModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.raiseAlarmRequest != null) {
                if (typeof object.raiseAlarmRequest !== "object")
                    throw TypeError(".proto.EmergencyAPIv1Client.raiseAlarmRequest: object expected");
                message.raiseAlarmRequest = $root.proto.EmergencyModuleRaiseAlarmRequest.fromObject(object.raiseAlarmRequest);
            }
            if (object.clearAlarmRequest != null) {
                if (typeof object.clearAlarmRequest !== "object")
                    throw TypeError(".proto.EmergencyAPIv1Client.clearAlarmRequest: object expected");
                message.clearAlarmRequest = $root.proto.EmergencyModuleClearAlarmRequest.fromObject(object.clearAlarmRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from an EmergencyAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {proto.EmergencyAPIv1Client} message EmergencyAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.raiseAlarmRequest = null;
                object.clearAlarmRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.EmergencyModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.raiseAlarmRequest != null && message.hasOwnProperty("raiseAlarmRequest"))
                object.raiseAlarmRequest = $root.proto.EmergencyModuleRaiseAlarmRequest.toObject(message.raiseAlarmRequest, options);
            if (message.clearAlarmRequest != null && message.hasOwnProperty("clearAlarmRequest"))
                object.clearAlarmRequest = $root.proto.EmergencyModuleClearAlarmRequest.toObject(message.clearAlarmRequest, options);
            return object;
        };

        /**
         * Converts this EmergencyAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.EmergencyAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyAPIv1Client
         * @function getTypeUrl
         * @memberof proto.EmergencyAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyAPIv1Client";
        };

        return EmergencyAPIv1Client;
    })();

    proto.EmergencyModuleSetupRequest = (function() {

        /**
         * Properties of an EmergencyModuleSetupRequest.
         * @memberof proto
         * @interface IEmergencyModuleSetupRequest
         */

        /**
         * Constructs a new EmergencyModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents an EmergencyModuleSetupRequest.
         * @implements IEmergencyModuleSetupRequest
         * @constructor
         * @param {proto.IEmergencyModuleSetupRequest=} [properties] Properties to set
         */
        function EmergencyModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EmergencyModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {proto.IEmergencyModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.EmergencyModuleSetupRequest} EmergencyModuleSetupRequest instance
         */
        EmergencyModuleSetupRequest.create = function create(properties) {
            return new EmergencyModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified EmergencyModuleSetupRequest message. Does not implicitly {@link proto.EmergencyModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {proto.IEmergencyModuleSetupRequest} message EmergencyModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EmergencyModuleSetupRequest message, length delimited. Does not implicitly {@link proto.EmergencyModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {proto.IEmergencyModuleSetupRequest} message EmergencyModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyModuleSetupRequest} EmergencyModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmergencyModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyModuleSetupRequest} EmergencyModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyModuleSetupRequest message.
         * @function verify
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EmergencyModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyModuleSetupRequest} EmergencyModuleSetupRequest
         */
        EmergencyModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyModuleSetupRequest)
                return object;
            return new $root.proto.EmergencyModuleSetupRequest();
        };

        /**
         * Creates a plain object from an EmergencyModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {proto.EmergencyModuleSetupRequest} message EmergencyModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EmergencyModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.EmergencyModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.EmergencyModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyModuleSetupRequest";
        };

        return EmergencyModuleSetupRequest;
    })();

    proto.EmergencyModuleSetupResponse = (function() {

        /**
         * Properties of an EmergencyModuleSetupResponse.
         * @memberof proto
         * @interface IEmergencyModuleSetupResponse
         * @property {Array.<string>|null} [emergencySmsReceivers] EmergencyModuleSetupResponse emergencySmsReceivers
         * @property {string|null} [emergencySms] EmergencyModuleSetupResponse emergencySms
         * @property {string|null} [emergencyDialupNumber] EmergencyModuleSetupResponse emergencyDialupNumber
         * @property {boolean|null} [silentAlarm] EmergencyModuleSetupResponse silentAlarm
         */

        /**
         * Constructs a new EmergencyModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents an EmergencyModuleSetupResponse.
         * @implements IEmergencyModuleSetupResponse
         * @constructor
         * @param {proto.IEmergencyModuleSetupResponse=} [properties] Properties to set
         */
        function EmergencyModuleSetupResponse(properties) {
            this.emergencySmsReceivers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyModuleSetupResponse emergencySmsReceivers.
         * @member {Array.<string>} emergencySmsReceivers
         * @memberof proto.EmergencyModuleSetupResponse
         * @instance
         */
        EmergencyModuleSetupResponse.prototype.emergencySmsReceivers = $util.emptyArray;

        /**
         * EmergencyModuleSetupResponse emergencySms.
         * @member {string} emergencySms
         * @memberof proto.EmergencyModuleSetupResponse
         * @instance
         */
        EmergencyModuleSetupResponse.prototype.emergencySms = "";

        /**
         * EmergencyModuleSetupResponse emergencyDialupNumber.
         * @member {string} emergencyDialupNumber
         * @memberof proto.EmergencyModuleSetupResponse
         * @instance
         */
        EmergencyModuleSetupResponse.prototype.emergencyDialupNumber = "";

        /**
         * EmergencyModuleSetupResponse silentAlarm.
         * @member {boolean} silentAlarm
         * @memberof proto.EmergencyModuleSetupResponse
         * @instance
         */
        EmergencyModuleSetupResponse.prototype.silentAlarm = true;

        /**
         * Creates a new EmergencyModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {proto.IEmergencyModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.EmergencyModuleSetupResponse} EmergencyModuleSetupResponse instance
         */
        EmergencyModuleSetupResponse.create = function create(properties) {
            return new EmergencyModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified EmergencyModuleSetupResponse message. Does not implicitly {@link proto.EmergencyModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {proto.IEmergencyModuleSetupResponse} message EmergencyModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emergencySmsReceivers != null && message.emergencySmsReceivers.length)
                for (var i = 0; i < message.emergencySmsReceivers.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.emergencySmsReceivers[i]);
            if (message.emergencySms != null && Object.hasOwnProperty.call(message, "emergencySms"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emergencySms);
            if (message.emergencyDialupNumber != null && Object.hasOwnProperty.call(message, "emergencyDialupNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.emergencyDialupNumber);
            if (message.silentAlarm != null && Object.hasOwnProperty.call(message, "silentAlarm"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.silentAlarm);
            return writer;
        };

        /**
         * Encodes the specified EmergencyModuleSetupResponse message, length delimited. Does not implicitly {@link proto.EmergencyModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {proto.IEmergencyModuleSetupResponse} message EmergencyModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyModuleSetupResponse} EmergencyModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.emergencySmsReceivers && message.emergencySmsReceivers.length))
                            message.emergencySmsReceivers = [];
                        message.emergencySmsReceivers.push(reader.string());
                        break;
                    }
                case 2: {
                        message.emergencySms = reader.string();
                        break;
                    }
                case 3: {
                        message.emergencyDialupNumber = reader.string();
                        break;
                    }
                case 4: {
                        message.silentAlarm = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmergencyModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyModuleSetupResponse} EmergencyModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyModuleSetupResponse message.
         * @function verify
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emergencySmsReceivers != null && message.hasOwnProperty("emergencySmsReceivers")) {
                if (!Array.isArray(message.emergencySmsReceivers))
                    return "emergencySmsReceivers: array expected";
                for (var i = 0; i < message.emergencySmsReceivers.length; ++i)
                    if (!$util.isString(message.emergencySmsReceivers[i]))
                        return "emergencySmsReceivers: string[] expected";
            }
            if (message.emergencySms != null && message.hasOwnProperty("emergencySms"))
                if (!$util.isString(message.emergencySms))
                    return "emergencySms: string expected";
            if (message.emergencyDialupNumber != null && message.hasOwnProperty("emergencyDialupNumber"))
                if (!$util.isString(message.emergencyDialupNumber))
                    return "emergencyDialupNumber: string expected";
            if (message.silentAlarm != null && message.hasOwnProperty("silentAlarm"))
                if (typeof message.silentAlarm !== "boolean")
                    return "silentAlarm: boolean expected";
            return null;
        };

        /**
         * Creates an EmergencyModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyModuleSetupResponse} EmergencyModuleSetupResponse
         */
        EmergencyModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyModuleSetupResponse)
                return object;
            var message = new $root.proto.EmergencyModuleSetupResponse();
            if (object.emergencySmsReceivers) {
                if (!Array.isArray(object.emergencySmsReceivers))
                    throw TypeError(".proto.EmergencyModuleSetupResponse.emergencySmsReceivers: array expected");
                message.emergencySmsReceivers = [];
                for (var i = 0; i < object.emergencySmsReceivers.length; ++i)
                    message.emergencySmsReceivers[i] = String(object.emergencySmsReceivers[i]);
            }
            if (object.emergencySms != null)
                message.emergencySms = String(object.emergencySms);
            if (object.emergencyDialupNumber != null)
                message.emergencyDialupNumber = String(object.emergencyDialupNumber);
            if (object.silentAlarm != null)
                message.silentAlarm = Boolean(object.silentAlarm);
            return message;
        };

        /**
         * Creates a plain object from an EmergencyModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {proto.EmergencyModuleSetupResponse} message EmergencyModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.emergencySmsReceivers = [];
            if (options.defaults) {
                object.emergencySms = "";
                object.emergencyDialupNumber = "";
                object.silentAlarm = true;
            }
            if (message.emergencySmsReceivers && message.emergencySmsReceivers.length) {
                object.emergencySmsReceivers = [];
                for (var j = 0; j < message.emergencySmsReceivers.length; ++j)
                    object.emergencySmsReceivers[j] = message.emergencySmsReceivers[j];
            }
            if (message.emergencySms != null && message.hasOwnProperty("emergencySms"))
                object.emergencySms = message.emergencySms;
            if (message.emergencyDialupNumber != null && message.hasOwnProperty("emergencyDialupNumber"))
                object.emergencyDialupNumber = message.emergencyDialupNumber;
            if (message.silentAlarm != null && message.hasOwnProperty("silentAlarm"))
                object.silentAlarm = message.silentAlarm;
            return object;
        };

        /**
         * Converts this EmergencyModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.EmergencyModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.EmergencyModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyModuleSetupResponse";
        };

        return EmergencyModuleSetupResponse;
    })();

    proto.EmergencyModuleRaiseAlarmRequest = (function() {

        /**
         * Properties of an EmergencyModuleRaiseAlarmRequest.
         * @memberof proto
         * @interface IEmergencyModuleRaiseAlarmRequest
         * @property {proto.ILocation|null} [location] EmergencyModuleRaiseAlarmRequest location
         */

        /**
         * Constructs a new EmergencyModuleRaiseAlarmRequest.
         * @memberof proto
         * @classdesc Represents an EmergencyModuleRaiseAlarmRequest.
         * @implements IEmergencyModuleRaiseAlarmRequest
         * @constructor
         * @param {proto.IEmergencyModuleRaiseAlarmRequest=} [properties] Properties to set
         */
        function EmergencyModuleRaiseAlarmRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyModuleRaiseAlarmRequest location.
         * @member {proto.ILocation|null|undefined} location
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @instance
         */
        EmergencyModuleRaiseAlarmRequest.prototype.location = null;

        /**
         * Creates a new EmergencyModuleRaiseAlarmRequest instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {proto.IEmergencyModuleRaiseAlarmRequest=} [properties] Properties to set
         * @returns {proto.EmergencyModuleRaiseAlarmRequest} EmergencyModuleRaiseAlarmRequest instance
         */
        EmergencyModuleRaiseAlarmRequest.create = function create(properties) {
            return new EmergencyModuleRaiseAlarmRequest(properties);
        };

        /**
         * Encodes the specified EmergencyModuleRaiseAlarmRequest message. Does not implicitly {@link proto.EmergencyModuleRaiseAlarmRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {proto.IEmergencyModuleRaiseAlarmRequest} message EmergencyModuleRaiseAlarmRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleRaiseAlarmRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.proto.Location.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EmergencyModuleRaiseAlarmRequest message, length delimited. Does not implicitly {@link proto.EmergencyModuleRaiseAlarmRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {proto.IEmergencyModuleRaiseAlarmRequest} message EmergencyModuleRaiseAlarmRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleRaiseAlarmRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyModuleRaiseAlarmRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyModuleRaiseAlarmRequest} EmergencyModuleRaiseAlarmRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleRaiseAlarmRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyModuleRaiseAlarmRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.location = $root.proto.Location.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmergencyModuleRaiseAlarmRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyModuleRaiseAlarmRequest} EmergencyModuleRaiseAlarmRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleRaiseAlarmRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyModuleRaiseAlarmRequest message.
         * @function verify
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyModuleRaiseAlarmRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.location != null && message.hasOwnProperty("location")) {
                var error = $root.proto.Location.verify(message.location);
                if (error)
                    return "location." + error;
            }
            return null;
        };

        /**
         * Creates an EmergencyModuleRaiseAlarmRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyModuleRaiseAlarmRequest} EmergencyModuleRaiseAlarmRequest
         */
        EmergencyModuleRaiseAlarmRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyModuleRaiseAlarmRequest)
                return object;
            var message = new $root.proto.EmergencyModuleRaiseAlarmRequest();
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".proto.EmergencyModuleRaiseAlarmRequest.location: object expected");
                message.location = $root.proto.Location.fromObject(object.location);
            }
            return message;
        };

        /**
         * Creates a plain object from an EmergencyModuleRaiseAlarmRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {proto.EmergencyModuleRaiseAlarmRequest} message EmergencyModuleRaiseAlarmRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyModuleRaiseAlarmRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.location = null;
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.proto.Location.toObject(message.location, options);
            return object;
        };

        /**
         * Converts this EmergencyModuleRaiseAlarmRequest to JSON.
         * @function toJSON
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyModuleRaiseAlarmRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyModuleRaiseAlarmRequest
         * @function getTypeUrl
         * @memberof proto.EmergencyModuleRaiseAlarmRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyModuleRaiseAlarmRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyModuleRaiseAlarmRequest";
        };

        return EmergencyModuleRaiseAlarmRequest;
    })();

    proto.EmergencyModuleClearAlarmRequest = (function() {

        /**
         * Properties of an EmergencyModuleClearAlarmRequest.
         * @memberof proto
         * @interface IEmergencyModuleClearAlarmRequest
         * @property {number|Long} alarmId EmergencyModuleClearAlarmRequest alarmId
         */

        /**
         * Constructs a new EmergencyModuleClearAlarmRequest.
         * @memberof proto
         * @classdesc Represents an EmergencyModuleClearAlarmRequest.
         * @implements IEmergencyModuleClearAlarmRequest
         * @constructor
         * @param {proto.IEmergencyModuleClearAlarmRequest=} [properties] Properties to set
         */
        function EmergencyModuleClearAlarmRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyModuleClearAlarmRequest alarmId.
         * @member {number|Long} alarmId
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @instance
         */
        EmergencyModuleClearAlarmRequest.prototype.alarmId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new EmergencyModuleClearAlarmRequest instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {proto.IEmergencyModuleClearAlarmRequest=} [properties] Properties to set
         * @returns {proto.EmergencyModuleClearAlarmRequest} EmergencyModuleClearAlarmRequest instance
         */
        EmergencyModuleClearAlarmRequest.create = function create(properties) {
            return new EmergencyModuleClearAlarmRequest(properties);
        };

        /**
         * Encodes the specified EmergencyModuleClearAlarmRequest message. Does not implicitly {@link proto.EmergencyModuleClearAlarmRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {proto.IEmergencyModuleClearAlarmRequest} message EmergencyModuleClearAlarmRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleClearAlarmRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.alarmId);
            return writer;
        };

        /**
         * Encodes the specified EmergencyModuleClearAlarmRequest message, length delimited. Does not implicitly {@link proto.EmergencyModuleClearAlarmRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {proto.IEmergencyModuleClearAlarmRequest} message EmergencyModuleClearAlarmRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleClearAlarmRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyModuleClearAlarmRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyModuleClearAlarmRequest} EmergencyModuleClearAlarmRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleClearAlarmRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyModuleClearAlarmRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.alarmId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("alarmId"))
                throw $util.ProtocolError("missing required 'alarmId'", { instance: message });
            return message;
        };

        /**
         * Decodes an EmergencyModuleClearAlarmRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyModuleClearAlarmRequest} EmergencyModuleClearAlarmRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleClearAlarmRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyModuleClearAlarmRequest message.
         * @function verify
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyModuleClearAlarmRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.alarmId) && !(message.alarmId && $util.isInteger(message.alarmId.low) && $util.isInteger(message.alarmId.high)))
                return "alarmId: integer|Long expected";
            return null;
        };

        /**
         * Creates an EmergencyModuleClearAlarmRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyModuleClearAlarmRequest} EmergencyModuleClearAlarmRequest
         */
        EmergencyModuleClearAlarmRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyModuleClearAlarmRequest)
                return object;
            var message = new $root.proto.EmergencyModuleClearAlarmRequest();
            if (object.alarmId != null)
                if ($util.Long)
                    (message.alarmId = $util.Long.fromValue(object.alarmId)).unsigned = false;
                else if (typeof object.alarmId === "string")
                    message.alarmId = parseInt(object.alarmId, 10);
                else if (typeof object.alarmId === "number")
                    message.alarmId = object.alarmId;
                else if (typeof object.alarmId === "object")
                    message.alarmId = new $util.LongBits(object.alarmId.low >>> 0, object.alarmId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an EmergencyModuleClearAlarmRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {proto.EmergencyModuleClearAlarmRequest} message EmergencyModuleClearAlarmRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyModuleClearAlarmRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.alarmId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.alarmId = options.longs === String ? "0" : 0;
            if (message.alarmId != null && message.hasOwnProperty("alarmId"))
                if (typeof message.alarmId === "number")
                    object.alarmId = options.longs === String ? String(message.alarmId) : message.alarmId;
                else
                    object.alarmId = options.longs === String ? $util.Long.prototype.toString.call(message.alarmId) : options.longs === Number ? new $util.LongBits(message.alarmId.low >>> 0, message.alarmId.high >>> 0).toNumber() : message.alarmId;
            return object;
        };

        /**
         * Converts this EmergencyModuleClearAlarmRequest to JSON.
         * @function toJSON
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyModuleClearAlarmRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyModuleClearAlarmRequest
         * @function getTypeUrl
         * @memberof proto.EmergencyModuleClearAlarmRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyModuleClearAlarmRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyModuleClearAlarmRequest";
        };

        return EmergencyModuleClearAlarmRequest;
    })();

    proto.EmergencyAPIv1Server = (function() {

        /**
         * Properties of an EmergencyAPIv1Server.
         * @memberof proto
         * @interface IEmergencyAPIv1Server
         * @property {proto.IEmergencyModuleAlarmRaised|null} [alarmRaised] EmergencyAPIv1Server alarmRaised
         * @property {proto.IEmergencyModuleAlarmCleared|null} [alarmCleared] EmergencyAPIv1Server alarmCleared
         */

        /**
         * Constructs a new EmergencyAPIv1Server.
         * @memberof proto
         * @classdesc Represents an EmergencyAPIv1Server.
         * @implements IEmergencyAPIv1Server
         * @constructor
         * @param {proto.IEmergencyAPIv1Server=} [properties] Properties to set
         */
        function EmergencyAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyAPIv1Server alarmRaised.
         * @member {proto.IEmergencyModuleAlarmRaised|null|undefined} alarmRaised
         * @memberof proto.EmergencyAPIv1Server
         * @instance
         */
        EmergencyAPIv1Server.prototype.alarmRaised = null;

        /**
         * EmergencyAPIv1Server alarmCleared.
         * @member {proto.IEmergencyModuleAlarmCleared|null|undefined} alarmCleared
         * @memberof proto.EmergencyAPIv1Server
         * @instance
         */
        EmergencyAPIv1Server.prototype.alarmCleared = null;

        /**
         * Creates a new EmergencyAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {proto.IEmergencyAPIv1Server=} [properties] Properties to set
         * @returns {proto.EmergencyAPIv1Server} EmergencyAPIv1Server instance
         */
        EmergencyAPIv1Server.create = function create(properties) {
            return new EmergencyAPIv1Server(properties);
        };

        /**
         * Encodes the specified EmergencyAPIv1Server message. Does not implicitly {@link proto.EmergencyAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {proto.IEmergencyAPIv1Server} message EmergencyAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.alarmRaised != null && Object.hasOwnProperty.call(message, "alarmRaised"))
                $root.proto.EmergencyModuleAlarmRaised.encode(message.alarmRaised, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.alarmCleared != null && Object.hasOwnProperty.call(message, "alarmCleared"))
                $root.proto.EmergencyModuleAlarmCleared.encode(message.alarmCleared, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EmergencyAPIv1Server message, length delimited. Does not implicitly {@link proto.EmergencyAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {proto.IEmergencyAPIv1Server} message EmergencyAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyAPIv1Server} EmergencyAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.alarmRaised = $root.proto.EmergencyModuleAlarmRaised.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.alarmCleared = $root.proto.EmergencyModuleAlarmCleared.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmergencyAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyAPIv1Server} EmergencyAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyAPIv1Server message.
         * @function verify
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.alarmRaised != null && message.hasOwnProperty("alarmRaised")) {
                var error = $root.proto.EmergencyModuleAlarmRaised.verify(message.alarmRaised);
                if (error)
                    return "alarmRaised." + error;
            }
            if (message.alarmCleared != null && message.hasOwnProperty("alarmCleared")) {
                var error = $root.proto.EmergencyModuleAlarmCleared.verify(message.alarmCleared);
                if (error)
                    return "alarmCleared." + error;
            }
            return null;
        };

        /**
         * Creates an EmergencyAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyAPIv1Server} EmergencyAPIv1Server
         */
        EmergencyAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyAPIv1Server)
                return object;
            var message = new $root.proto.EmergencyAPIv1Server();
            if (object.alarmRaised != null) {
                if (typeof object.alarmRaised !== "object")
                    throw TypeError(".proto.EmergencyAPIv1Server.alarmRaised: object expected");
                message.alarmRaised = $root.proto.EmergencyModuleAlarmRaised.fromObject(object.alarmRaised);
            }
            if (object.alarmCleared != null) {
                if (typeof object.alarmCleared !== "object")
                    throw TypeError(".proto.EmergencyAPIv1Server.alarmCleared: object expected");
                message.alarmCleared = $root.proto.EmergencyModuleAlarmCleared.fromObject(object.alarmCleared);
            }
            return message;
        };

        /**
         * Creates a plain object from an EmergencyAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {proto.EmergencyAPIv1Server} message EmergencyAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.alarmRaised = null;
                object.alarmCleared = null;
            }
            if (message.alarmRaised != null && message.hasOwnProperty("alarmRaised"))
                object.alarmRaised = $root.proto.EmergencyModuleAlarmRaised.toObject(message.alarmRaised, options);
            if (message.alarmCleared != null && message.hasOwnProperty("alarmCleared"))
                object.alarmCleared = $root.proto.EmergencyModuleAlarmCleared.toObject(message.alarmCleared, options);
            return object;
        };

        /**
         * Converts this EmergencyAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.EmergencyAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyAPIv1Server
         * @function getTypeUrl
         * @memberof proto.EmergencyAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyAPIv1Server";
        };

        return EmergencyAPIv1Server;
    })();

    proto.EmergencyModuleAlarmRaised = (function() {

        /**
         * Properties of an EmergencyModuleAlarmRaised.
         * @memberof proto
         * @interface IEmergencyModuleAlarmRaised
         * @property {number|Long} alarmId EmergencyModuleAlarmRaised alarmId
         * @property {boolean} clientMayClear EmergencyModuleAlarmRaised clientMayClear
         */

        /**
         * Constructs a new EmergencyModuleAlarmRaised.
         * @memberof proto
         * @classdesc Represents an EmergencyModuleAlarmRaised.
         * @implements IEmergencyModuleAlarmRaised
         * @constructor
         * @param {proto.IEmergencyModuleAlarmRaised=} [properties] Properties to set
         */
        function EmergencyModuleAlarmRaised(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyModuleAlarmRaised alarmId.
         * @member {number|Long} alarmId
         * @memberof proto.EmergencyModuleAlarmRaised
         * @instance
         */
        EmergencyModuleAlarmRaised.prototype.alarmId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * EmergencyModuleAlarmRaised clientMayClear.
         * @member {boolean} clientMayClear
         * @memberof proto.EmergencyModuleAlarmRaised
         * @instance
         */
        EmergencyModuleAlarmRaised.prototype.clientMayClear = false;

        /**
         * Creates a new EmergencyModuleAlarmRaised instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {proto.IEmergencyModuleAlarmRaised=} [properties] Properties to set
         * @returns {proto.EmergencyModuleAlarmRaised} EmergencyModuleAlarmRaised instance
         */
        EmergencyModuleAlarmRaised.create = function create(properties) {
            return new EmergencyModuleAlarmRaised(properties);
        };

        /**
         * Encodes the specified EmergencyModuleAlarmRaised message. Does not implicitly {@link proto.EmergencyModuleAlarmRaised.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {proto.IEmergencyModuleAlarmRaised} message EmergencyModuleAlarmRaised message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleAlarmRaised.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.alarmId);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.clientMayClear);
            return writer;
        };

        /**
         * Encodes the specified EmergencyModuleAlarmRaised message, length delimited. Does not implicitly {@link proto.EmergencyModuleAlarmRaised.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {proto.IEmergencyModuleAlarmRaised} message EmergencyModuleAlarmRaised message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleAlarmRaised.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyModuleAlarmRaised message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyModuleAlarmRaised} EmergencyModuleAlarmRaised
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleAlarmRaised.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyModuleAlarmRaised();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.alarmId = reader.int64();
                        break;
                    }
                case 2: {
                        message.clientMayClear = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("alarmId"))
                throw $util.ProtocolError("missing required 'alarmId'", { instance: message });
            if (!message.hasOwnProperty("clientMayClear"))
                throw $util.ProtocolError("missing required 'clientMayClear'", { instance: message });
            return message;
        };

        /**
         * Decodes an EmergencyModuleAlarmRaised message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyModuleAlarmRaised} EmergencyModuleAlarmRaised
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleAlarmRaised.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyModuleAlarmRaised message.
         * @function verify
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyModuleAlarmRaised.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.alarmId) && !(message.alarmId && $util.isInteger(message.alarmId.low) && $util.isInteger(message.alarmId.high)))
                return "alarmId: integer|Long expected";
            if (typeof message.clientMayClear !== "boolean")
                return "clientMayClear: boolean expected";
            return null;
        };

        /**
         * Creates an EmergencyModuleAlarmRaised message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyModuleAlarmRaised} EmergencyModuleAlarmRaised
         */
        EmergencyModuleAlarmRaised.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyModuleAlarmRaised)
                return object;
            var message = new $root.proto.EmergencyModuleAlarmRaised();
            if (object.alarmId != null)
                if ($util.Long)
                    (message.alarmId = $util.Long.fromValue(object.alarmId)).unsigned = false;
                else if (typeof object.alarmId === "string")
                    message.alarmId = parseInt(object.alarmId, 10);
                else if (typeof object.alarmId === "number")
                    message.alarmId = object.alarmId;
                else if (typeof object.alarmId === "object")
                    message.alarmId = new $util.LongBits(object.alarmId.low >>> 0, object.alarmId.high >>> 0).toNumber();
            if (object.clientMayClear != null)
                message.clientMayClear = Boolean(object.clientMayClear);
            return message;
        };

        /**
         * Creates a plain object from an EmergencyModuleAlarmRaised message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {proto.EmergencyModuleAlarmRaised} message EmergencyModuleAlarmRaised
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyModuleAlarmRaised.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.alarmId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.alarmId = options.longs === String ? "0" : 0;
                object.clientMayClear = false;
            }
            if (message.alarmId != null && message.hasOwnProperty("alarmId"))
                if (typeof message.alarmId === "number")
                    object.alarmId = options.longs === String ? String(message.alarmId) : message.alarmId;
                else
                    object.alarmId = options.longs === String ? $util.Long.prototype.toString.call(message.alarmId) : options.longs === Number ? new $util.LongBits(message.alarmId.low >>> 0, message.alarmId.high >>> 0).toNumber() : message.alarmId;
            if (message.clientMayClear != null && message.hasOwnProperty("clientMayClear"))
                object.clientMayClear = message.clientMayClear;
            return object;
        };

        /**
         * Converts this EmergencyModuleAlarmRaised to JSON.
         * @function toJSON
         * @memberof proto.EmergencyModuleAlarmRaised
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyModuleAlarmRaised.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyModuleAlarmRaised
         * @function getTypeUrl
         * @memberof proto.EmergencyModuleAlarmRaised
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyModuleAlarmRaised.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyModuleAlarmRaised";
        };

        return EmergencyModuleAlarmRaised;
    })();

    proto.EmergencyModuleAlarmCleared = (function() {

        /**
         * Properties of an EmergencyModuleAlarmCleared.
         * @memberof proto
         * @interface IEmergencyModuleAlarmCleared
         * @property {number|Long} alarmId EmergencyModuleAlarmCleared alarmId
         */

        /**
         * Constructs a new EmergencyModuleAlarmCleared.
         * @memberof proto
         * @classdesc Represents an EmergencyModuleAlarmCleared.
         * @implements IEmergencyModuleAlarmCleared
         * @constructor
         * @param {proto.IEmergencyModuleAlarmCleared=} [properties] Properties to set
         */
        function EmergencyModuleAlarmCleared(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyModuleAlarmCleared alarmId.
         * @member {number|Long} alarmId
         * @memberof proto.EmergencyModuleAlarmCleared
         * @instance
         */
        EmergencyModuleAlarmCleared.prototype.alarmId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new EmergencyModuleAlarmCleared instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {proto.IEmergencyModuleAlarmCleared=} [properties] Properties to set
         * @returns {proto.EmergencyModuleAlarmCleared} EmergencyModuleAlarmCleared instance
         */
        EmergencyModuleAlarmCleared.create = function create(properties) {
            return new EmergencyModuleAlarmCleared(properties);
        };

        /**
         * Encodes the specified EmergencyModuleAlarmCleared message. Does not implicitly {@link proto.EmergencyModuleAlarmCleared.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {proto.IEmergencyModuleAlarmCleared} message EmergencyModuleAlarmCleared message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleAlarmCleared.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.alarmId);
            return writer;
        };

        /**
         * Encodes the specified EmergencyModuleAlarmCleared message, length delimited. Does not implicitly {@link proto.EmergencyModuleAlarmCleared.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {proto.IEmergencyModuleAlarmCleared} message EmergencyModuleAlarmCleared message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyModuleAlarmCleared.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyModuleAlarmCleared message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyModuleAlarmCleared} EmergencyModuleAlarmCleared
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleAlarmCleared.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyModuleAlarmCleared();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.alarmId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("alarmId"))
                throw $util.ProtocolError("missing required 'alarmId'", { instance: message });
            return message;
        };

        /**
         * Decodes an EmergencyModuleAlarmCleared message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyModuleAlarmCleared} EmergencyModuleAlarmCleared
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyModuleAlarmCleared.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyModuleAlarmCleared message.
         * @function verify
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyModuleAlarmCleared.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.alarmId) && !(message.alarmId && $util.isInteger(message.alarmId.low) && $util.isInteger(message.alarmId.high)))
                return "alarmId: integer|Long expected";
            return null;
        };

        /**
         * Creates an EmergencyModuleAlarmCleared message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyModuleAlarmCleared} EmergencyModuleAlarmCleared
         */
        EmergencyModuleAlarmCleared.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyModuleAlarmCleared)
                return object;
            var message = new $root.proto.EmergencyModuleAlarmCleared();
            if (object.alarmId != null)
                if ($util.Long)
                    (message.alarmId = $util.Long.fromValue(object.alarmId)).unsigned = false;
                else if (typeof object.alarmId === "string")
                    message.alarmId = parseInt(object.alarmId, 10);
                else if (typeof object.alarmId === "number")
                    message.alarmId = object.alarmId;
                else if (typeof object.alarmId === "object")
                    message.alarmId = new $util.LongBits(object.alarmId.low >>> 0, object.alarmId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an EmergencyModuleAlarmCleared message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {proto.EmergencyModuleAlarmCleared} message EmergencyModuleAlarmCleared
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyModuleAlarmCleared.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.alarmId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.alarmId = options.longs === String ? "0" : 0;
            if (message.alarmId != null && message.hasOwnProperty("alarmId"))
                if (typeof message.alarmId === "number")
                    object.alarmId = options.longs === String ? String(message.alarmId) : message.alarmId;
                else
                    object.alarmId = options.longs === String ? $util.Long.prototype.toString.call(message.alarmId) : options.longs === Number ? new $util.LongBits(message.alarmId.low >>> 0, message.alarmId.high >>> 0).toNumber() : message.alarmId;
            return object;
        };

        /**
         * Converts this EmergencyModuleAlarmCleared to JSON.
         * @function toJSON
         * @memberof proto.EmergencyModuleAlarmCleared
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyModuleAlarmCleared.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyModuleAlarmCleared
         * @function getTypeUrl
         * @memberof proto.EmergencyModuleAlarmCleared
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyModuleAlarmCleared.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyModuleAlarmCleared";
        };

        return EmergencyModuleAlarmCleared;
    })();

    proto.LocationReportAPIv1Client = (function() {

        /**
         * Properties of a LocationReportAPIv1Client.
         * @memberof proto
         * @interface ILocationReportAPIv1Client
         * @property {proto.ILocationReportModuleSetupRequest|null} [setupRequest] LocationReportAPIv1Client setupRequest
         * @property {proto.ILocationReportReportRequest|null} [reportRequest] LocationReportAPIv1Client reportRequest
         */

        /**
         * Constructs a new LocationReportAPIv1Client.
         * @memberof proto
         * @classdesc Represents a LocationReportAPIv1Client.
         * @implements ILocationReportAPIv1Client
         * @constructor
         * @param {proto.ILocationReportAPIv1Client=} [properties] Properties to set
         */
        function LocationReportAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationReportAPIv1Client setupRequest.
         * @member {proto.ILocationReportModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.LocationReportAPIv1Client
         * @instance
         */
        LocationReportAPIv1Client.prototype.setupRequest = null;

        /**
         * LocationReportAPIv1Client reportRequest.
         * @member {proto.ILocationReportReportRequest|null|undefined} reportRequest
         * @memberof proto.LocationReportAPIv1Client
         * @instance
         */
        LocationReportAPIv1Client.prototype.reportRequest = null;

        /**
         * Creates a new LocationReportAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {proto.ILocationReportAPIv1Client=} [properties] Properties to set
         * @returns {proto.LocationReportAPIv1Client} LocationReportAPIv1Client instance
         */
        LocationReportAPIv1Client.create = function create(properties) {
            return new LocationReportAPIv1Client(properties);
        };

        /**
         * Encodes the specified LocationReportAPIv1Client message. Does not implicitly {@link proto.LocationReportAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {proto.ILocationReportAPIv1Client} message LocationReportAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.LocationReportModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.reportRequest != null && Object.hasOwnProperty.call(message, "reportRequest"))
                $root.proto.LocationReportReportRequest.encode(message.reportRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationReportAPIv1Client message, length delimited. Does not implicitly {@link proto.LocationReportAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {proto.ILocationReportAPIv1Client} message LocationReportAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportAPIv1Client} LocationReportAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.LocationReportModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.reportRequest = $root.proto.LocationReportReportRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationReportAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportAPIv1Client} LocationReportAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportAPIv1Client message.
         * @function verify
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.LocationReportModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.reportRequest != null && message.hasOwnProperty("reportRequest")) {
                var error = $root.proto.LocationReportReportRequest.verify(message.reportRequest);
                if (error)
                    return "reportRequest." + error;
            }
            return null;
        };

        /**
         * Creates a LocationReportAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportAPIv1Client} LocationReportAPIv1Client
         */
        LocationReportAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportAPIv1Client)
                return object;
            var message = new $root.proto.LocationReportAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.LocationReportAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.LocationReportModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.reportRequest != null) {
                if (typeof object.reportRequest !== "object")
                    throw TypeError(".proto.LocationReportAPIv1Client.reportRequest: object expected");
                message.reportRequest = $root.proto.LocationReportReportRequest.fromObject(object.reportRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationReportAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {proto.LocationReportAPIv1Client} message LocationReportAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.reportRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.LocationReportModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.reportRequest != null && message.hasOwnProperty("reportRequest"))
                object.reportRequest = $root.proto.LocationReportReportRequest.toObject(message.reportRequest, options);
            return object;
        };

        /**
         * Converts this LocationReportAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.LocationReportAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportAPIv1Client
         * @function getTypeUrl
         * @memberof proto.LocationReportAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportAPIv1Client";
        };

        return LocationReportAPIv1Client;
    })();

    proto.LocationReportModuleSetupRequest = (function() {

        /**
         * Properties of a LocationReportModuleSetupRequest.
         * @memberof proto
         * @interface ILocationReportModuleSetupRequest
         */

        /**
         * Constructs a new LocationReportModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a LocationReportModuleSetupRequest.
         * @implements ILocationReportModuleSetupRequest
         * @constructor
         * @param {proto.ILocationReportModuleSetupRequest=} [properties] Properties to set
         */
        function LocationReportModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LocationReportModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {proto.ILocationReportModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.LocationReportModuleSetupRequest} LocationReportModuleSetupRequest instance
         */
        LocationReportModuleSetupRequest.create = function create(properties) {
            return new LocationReportModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified LocationReportModuleSetupRequest message. Does not implicitly {@link proto.LocationReportModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {proto.ILocationReportModuleSetupRequest} message LocationReportModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LocationReportModuleSetupRequest message, length delimited. Does not implicitly {@link proto.LocationReportModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {proto.ILocationReportModuleSetupRequest} message LocationReportModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportModuleSetupRequest} LocationReportModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationReportModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportModuleSetupRequest} LocationReportModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportModuleSetupRequest message.
         * @function verify
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LocationReportModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportModuleSetupRequest} LocationReportModuleSetupRequest
         */
        LocationReportModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportModuleSetupRequest)
                return object;
            return new $root.proto.LocationReportModuleSetupRequest();
        };

        /**
         * Creates a plain object from a LocationReportModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {proto.LocationReportModuleSetupRequest} message LocationReportModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LocationReportModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationReportModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.LocationReportModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportModuleSetupRequest";
        };

        return LocationReportModuleSetupRequest;
    })();

    proto.LocationReportReportRequest = (function() {

        /**
         * Properties of a LocationReportReportRequest.
         * @memberof proto
         * @interface ILocationReportReportRequest
         * @property {proto.ILocation} location LocationReportReportRequest location
         * @property {Array.<proto.ILocation>|null} [batchedLocations] LocationReportReportRequest batchedLocations
         */

        /**
         * Constructs a new LocationReportReportRequest.
         * @memberof proto
         * @classdesc Represents a LocationReportReportRequest.
         * @implements ILocationReportReportRequest
         * @constructor
         * @param {proto.ILocationReportReportRequest=} [properties] Properties to set
         */
        function LocationReportReportRequest(properties) {
            this.batchedLocations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationReportReportRequest location.
         * @member {proto.ILocation} location
         * @memberof proto.LocationReportReportRequest
         * @instance
         */
        LocationReportReportRequest.prototype.location = null;

        /**
         * LocationReportReportRequest batchedLocations.
         * @member {Array.<proto.ILocation>} batchedLocations
         * @memberof proto.LocationReportReportRequest
         * @instance
         */
        LocationReportReportRequest.prototype.batchedLocations = $util.emptyArray;

        /**
         * Creates a new LocationReportReportRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {proto.ILocationReportReportRequest=} [properties] Properties to set
         * @returns {proto.LocationReportReportRequest} LocationReportReportRequest instance
         */
        LocationReportReportRequest.create = function create(properties) {
            return new LocationReportReportRequest(properties);
        };

        /**
         * Encodes the specified LocationReportReportRequest message. Does not implicitly {@link proto.LocationReportReportRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {proto.ILocationReportReportRequest} message LocationReportReportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportReportRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            $root.proto.Location.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.batchedLocations != null && message.batchedLocations.length)
                for (var i = 0; i < message.batchedLocations.length; ++i)
                    $root.proto.Location.encode(message.batchedLocations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationReportReportRequest message, length delimited. Does not implicitly {@link proto.LocationReportReportRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {proto.ILocationReportReportRequest} message LocationReportReportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportReportRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportReportRequest} LocationReportReportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportReportRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportReportRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.location = $root.proto.Location.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.batchedLocations && message.batchedLocations.length))
                            message.batchedLocations = [];
                        message.batchedLocations.push($root.proto.Location.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("location"))
                throw $util.ProtocolError("missing required 'location'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationReportReportRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportReportRequest} LocationReportReportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportReportRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportReportRequest message.
         * @function verify
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportReportRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            {
                var error = $root.proto.Location.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.batchedLocations != null && message.hasOwnProperty("batchedLocations")) {
                if (!Array.isArray(message.batchedLocations))
                    return "batchedLocations: array expected";
                for (var i = 0; i < message.batchedLocations.length; ++i) {
                    var error = $root.proto.Location.verify(message.batchedLocations[i]);
                    if (error)
                        return "batchedLocations." + error;
                }
            }
            return null;
        };

        /**
         * Creates a LocationReportReportRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportReportRequest} LocationReportReportRequest
         */
        LocationReportReportRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportReportRequest)
                return object;
            var message = new $root.proto.LocationReportReportRequest();
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".proto.LocationReportReportRequest.location: object expected");
                message.location = $root.proto.Location.fromObject(object.location);
            }
            if (object.batchedLocations) {
                if (!Array.isArray(object.batchedLocations))
                    throw TypeError(".proto.LocationReportReportRequest.batchedLocations: array expected");
                message.batchedLocations = [];
                for (var i = 0; i < object.batchedLocations.length; ++i) {
                    if (typeof object.batchedLocations[i] !== "object")
                        throw TypeError(".proto.LocationReportReportRequest.batchedLocations: object expected");
                    message.batchedLocations[i] = $root.proto.Location.fromObject(object.batchedLocations[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationReportReportRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {proto.LocationReportReportRequest} message LocationReportReportRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportReportRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.batchedLocations = [];
            if (options.defaults)
                object.location = null;
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.proto.Location.toObject(message.location, options);
            if (message.batchedLocations && message.batchedLocations.length) {
                object.batchedLocations = [];
                for (var j = 0; j < message.batchedLocations.length; ++j)
                    object.batchedLocations[j] = $root.proto.Location.toObject(message.batchedLocations[j], options);
            }
            return object;
        };

        /**
         * Converts this LocationReportReportRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationReportReportRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportReportRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportReportRequest
         * @function getTypeUrl
         * @memberof proto.LocationReportReportRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportReportRequest";
        };

        return LocationReportReportRequest;
    })();

    proto.LocationReportAPIv1Server = (function() {

        /**
         * Properties of a LocationReportAPIv1Server.
         * @memberof proto
         * @interface ILocationReportAPIv1Server
         * @property {proto.ILocationReportStartReportingRequest|null} [startReportingRequest] LocationReportAPIv1Server startReportingRequest
         * @property {proto.ILocationReportStopReportingRequest|null} [stopReportingRequest] LocationReportAPIv1Server stopReportingRequest
         * @property {proto.ILocationReportUpdateRequest|null} [updateRequest] LocationReportAPIv1Server updateRequest
         */

        /**
         * Constructs a new LocationReportAPIv1Server.
         * @memberof proto
         * @classdesc Represents a LocationReportAPIv1Server.
         * @implements ILocationReportAPIv1Server
         * @constructor
         * @param {proto.ILocationReportAPIv1Server=} [properties] Properties to set
         */
        function LocationReportAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationReportAPIv1Server startReportingRequest.
         * @member {proto.ILocationReportStartReportingRequest|null|undefined} startReportingRequest
         * @memberof proto.LocationReportAPIv1Server
         * @instance
         */
        LocationReportAPIv1Server.prototype.startReportingRequest = null;

        /**
         * LocationReportAPIv1Server stopReportingRequest.
         * @member {proto.ILocationReportStopReportingRequest|null|undefined} stopReportingRequest
         * @memberof proto.LocationReportAPIv1Server
         * @instance
         */
        LocationReportAPIv1Server.prototype.stopReportingRequest = null;

        /**
         * LocationReportAPIv1Server updateRequest.
         * @member {proto.ILocationReportUpdateRequest|null|undefined} updateRequest
         * @memberof proto.LocationReportAPIv1Server
         * @instance
         */
        LocationReportAPIv1Server.prototype.updateRequest = null;

        /**
         * Creates a new LocationReportAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {proto.ILocationReportAPIv1Server=} [properties] Properties to set
         * @returns {proto.LocationReportAPIv1Server} LocationReportAPIv1Server instance
         */
        LocationReportAPIv1Server.create = function create(properties) {
            return new LocationReportAPIv1Server(properties);
        };

        /**
         * Encodes the specified LocationReportAPIv1Server message. Does not implicitly {@link proto.LocationReportAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {proto.ILocationReportAPIv1Server} message LocationReportAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startReportingRequest != null && Object.hasOwnProperty.call(message, "startReportingRequest"))
                $root.proto.LocationReportStartReportingRequest.encode(message.startReportingRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.stopReportingRequest != null && Object.hasOwnProperty.call(message, "stopReportingRequest"))
                $root.proto.LocationReportStopReportingRequest.encode(message.stopReportingRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.updateRequest != null && Object.hasOwnProperty.call(message, "updateRequest"))
                $root.proto.LocationReportUpdateRequest.encode(message.updateRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationReportAPIv1Server message, length delimited. Does not implicitly {@link proto.LocationReportAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {proto.ILocationReportAPIv1Server} message LocationReportAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportAPIv1Server} LocationReportAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.startReportingRequest = $root.proto.LocationReportStartReportingRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.stopReportingRequest = $root.proto.LocationReportStopReportingRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.updateRequest = $root.proto.LocationReportUpdateRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationReportAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportAPIv1Server} LocationReportAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportAPIv1Server message.
         * @function verify
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startReportingRequest != null && message.hasOwnProperty("startReportingRequest")) {
                var error = $root.proto.LocationReportStartReportingRequest.verify(message.startReportingRequest);
                if (error)
                    return "startReportingRequest." + error;
            }
            if (message.stopReportingRequest != null && message.hasOwnProperty("stopReportingRequest")) {
                var error = $root.proto.LocationReportStopReportingRequest.verify(message.stopReportingRequest);
                if (error)
                    return "stopReportingRequest." + error;
            }
            if (message.updateRequest != null && message.hasOwnProperty("updateRequest")) {
                var error = $root.proto.LocationReportUpdateRequest.verify(message.updateRequest);
                if (error)
                    return "updateRequest." + error;
            }
            return null;
        };

        /**
         * Creates a LocationReportAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportAPIv1Server} LocationReportAPIv1Server
         */
        LocationReportAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportAPIv1Server)
                return object;
            var message = new $root.proto.LocationReportAPIv1Server();
            if (object.startReportingRequest != null) {
                if (typeof object.startReportingRequest !== "object")
                    throw TypeError(".proto.LocationReportAPIv1Server.startReportingRequest: object expected");
                message.startReportingRequest = $root.proto.LocationReportStartReportingRequest.fromObject(object.startReportingRequest);
            }
            if (object.stopReportingRequest != null) {
                if (typeof object.stopReportingRequest !== "object")
                    throw TypeError(".proto.LocationReportAPIv1Server.stopReportingRequest: object expected");
                message.stopReportingRequest = $root.proto.LocationReportStopReportingRequest.fromObject(object.stopReportingRequest);
            }
            if (object.updateRequest != null) {
                if (typeof object.updateRequest !== "object")
                    throw TypeError(".proto.LocationReportAPIv1Server.updateRequest: object expected");
                message.updateRequest = $root.proto.LocationReportUpdateRequest.fromObject(object.updateRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationReportAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {proto.LocationReportAPIv1Server} message LocationReportAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.startReportingRequest = null;
                object.stopReportingRequest = null;
                object.updateRequest = null;
            }
            if (message.startReportingRequest != null && message.hasOwnProperty("startReportingRequest"))
                object.startReportingRequest = $root.proto.LocationReportStartReportingRequest.toObject(message.startReportingRequest, options);
            if (message.stopReportingRequest != null && message.hasOwnProperty("stopReportingRequest"))
                object.stopReportingRequest = $root.proto.LocationReportStopReportingRequest.toObject(message.stopReportingRequest, options);
            if (message.updateRequest != null && message.hasOwnProperty("updateRequest"))
                object.updateRequest = $root.proto.LocationReportUpdateRequest.toObject(message.updateRequest, options);
            return object;
        };

        /**
         * Converts this LocationReportAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.LocationReportAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportAPIv1Server
         * @function getTypeUrl
         * @memberof proto.LocationReportAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportAPIv1Server";
        };

        return LocationReportAPIv1Server;
    })();

    proto.LocationReportStartReportingRequest = (function() {

        /**
         * Properties of a LocationReportStartReportingRequest.
         * @memberof proto
         * @interface ILocationReportStartReportingRequest
         * @property {number|Long} hintInterval LocationReportStartReportingRequest hintInterval
         * @property {number} hintDistance LocationReportStartReportingRequest hintDistance
         * @property {proto.LocationAccuracy|null} [hintAccuracy] LocationReportStartReportingRequest hintAccuracy
         * @property {number|Long|null} [hintBatchTimeInMillis] LocationReportStartReportingRequest hintBatchTimeInMillis
         */

        /**
         * Constructs a new LocationReportStartReportingRequest.
         * @memberof proto
         * @classdesc Represents a LocationReportStartReportingRequest.
         * @implements ILocationReportStartReportingRequest
         * @constructor
         * @param {proto.ILocationReportStartReportingRequest=} [properties] Properties to set
         */
        function LocationReportStartReportingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationReportStartReportingRequest hintInterval.
         * @member {number|Long} hintInterval
         * @memberof proto.LocationReportStartReportingRequest
         * @instance
         */
        LocationReportStartReportingRequest.prototype.hintInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LocationReportStartReportingRequest hintDistance.
         * @member {number} hintDistance
         * @memberof proto.LocationReportStartReportingRequest
         * @instance
         */
        LocationReportStartReportingRequest.prototype.hintDistance = 0;

        /**
         * LocationReportStartReportingRequest hintAccuracy.
         * @member {proto.LocationAccuracy} hintAccuracy
         * @memberof proto.LocationReportStartReportingRequest
         * @instance
         */
        LocationReportStartReportingRequest.prototype.hintAccuracy = 2;

        /**
         * LocationReportStartReportingRequest hintBatchTimeInMillis.
         * @member {number|Long} hintBatchTimeInMillis
         * @memberof proto.LocationReportStartReportingRequest
         * @instance
         */
        LocationReportStartReportingRequest.prototype.hintBatchTimeInMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new LocationReportStartReportingRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {proto.ILocationReportStartReportingRequest=} [properties] Properties to set
         * @returns {proto.LocationReportStartReportingRequest} LocationReportStartReportingRequest instance
         */
        LocationReportStartReportingRequest.create = function create(properties) {
            return new LocationReportStartReportingRequest(properties);
        };

        /**
         * Encodes the specified LocationReportStartReportingRequest message. Does not implicitly {@link proto.LocationReportStartReportingRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {proto.ILocationReportStartReportingRequest} message LocationReportStartReportingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportStartReportingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.hintInterval);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.hintDistance);
            if (message.hintAccuracy != null && Object.hasOwnProperty.call(message, "hintAccuracy"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.hintAccuracy);
            if (message.hintBatchTimeInMillis != null && Object.hasOwnProperty.call(message, "hintBatchTimeInMillis"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.hintBatchTimeInMillis);
            return writer;
        };

        /**
         * Encodes the specified LocationReportStartReportingRequest message, length delimited. Does not implicitly {@link proto.LocationReportStartReportingRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {proto.ILocationReportStartReportingRequest} message LocationReportStartReportingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportStartReportingRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportStartReportingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportStartReportingRequest} LocationReportStartReportingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportStartReportingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportStartReportingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hintInterval = reader.int64();
                        break;
                    }
                case 2: {
                        message.hintDistance = reader.int32();
                        break;
                    }
                case 3: {
                        message.hintAccuracy = reader.int32();
                        break;
                    }
                case 4: {
                        message.hintBatchTimeInMillis = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("hintInterval"))
                throw $util.ProtocolError("missing required 'hintInterval'", { instance: message });
            if (!message.hasOwnProperty("hintDistance"))
                throw $util.ProtocolError("missing required 'hintDistance'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationReportStartReportingRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportStartReportingRequest} LocationReportStartReportingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportStartReportingRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportStartReportingRequest message.
         * @function verify
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportStartReportingRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.hintInterval) && !(message.hintInterval && $util.isInteger(message.hintInterval.low) && $util.isInteger(message.hintInterval.high)))
                return "hintInterval: integer|Long expected";
            if (!$util.isInteger(message.hintDistance))
                return "hintDistance: integer expected";
            if (message.hintAccuracy != null && message.hasOwnProperty("hintAccuracy"))
                switch (message.hintAccuracy) {
                default:
                    return "hintAccuracy: enum value expected";
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.hintBatchTimeInMillis != null && message.hasOwnProperty("hintBatchTimeInMillis"))
                if (!$util.isInteger(message.hintBatchTimeInMillis) && !(message.hintBatchTimeInMillis && $util.isInteger(message.hintBatchTimeInMillis.low) && $util.isInteger(message.hintBatchTimeInMillis.high)))
                    return "hintBatchTimeInMillis: integer|Long expected";
            return null;
        };

        /**
         * Creates a LocationReportStartReportingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportStartReportingRequest} LocationReportStartReportingRequest
         */
        LocationReportStartReportingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportStartReportingRequest)
                return object;
            var message = new $root.proto.LocationReportStartReportingRequest();
            if (object.hintInterval != null)
                if ($util.Long)
                    (message.hintInterval = $util.Long.fromValue(object.hintInterval)).unsigned = false;
                else if (typeof object.hintInterval === "string")
                    message.hintInterval = parseInt(object.hintInterval, 10);
                else if (typeof object.hintInterval === "number")
                    message.hintInterval = object.hintInterval;
                else if (typeof object.hintInterval === "object")
                    message.hintInterval = new $util.LongBits(object.hintInterval.low >>> 0, object.hintInterval.high >>> 0).toNumber();
            if (object.hintDistance != null)
                message.hintDistance = object.hintDistance | 0;
            switch (object.hintAccuracy) {
            case "NEAREST_100_M":
            case 1:
                message.hintAccuracy = 1;
                break;
            default:
                if (typeof object.hintAccuracy === "number") {
                    message.hintAccuracy = object.hintAccuracy;
                    break;
                }
                break;
            case "NEAREST_10_M":
            case 2:
                message.hintAccuracy = 2;
                break;
            case "BEST":
            case 3:
                message.hintAccuracy = 3;
                break;
            }
            if (object.hintBatchTimeInMillis != null)
                if ($util.Long)
                    (message.hintBatchTimeInMillis = $util.Long.fromValue(object.hintBatchTimeInMillis)).unsigned = false;
                else if (typeof object.hintBatchTimeInMillis === "string")
                    message.hintBatchTimeInMillis = parseInt(object.hintBatchTimeInMillis, 10);
                else if (typeof object.hintBatchTimeInMillis === "number")
                    message.hintBatchTimeInMillis = object.hintBatchTimeInMillis;
                else if (typeof object.hintBatchTimeInMillis === "object")
                    message.hintBatchTimeInMillis = new $util.LongBits(object.hintBatchTimeInMillis.low >>> 0, object.hintBatchTimeInMillis.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a LocationReportStartReportingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {proto.LocationReportStartReportingRequest} message LocationReportStartReportingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportStartReportingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.hintInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.hintInterval = options.longs === String ? "0" : 0;
                object.hintDistance = 0;
                object.hintAccuracy = options.enums === String ? "NEAREST_10_M" : 2;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.hintBatchTimeInMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.hintBatchTimeInMillis = options.longs === String ? "0" : 0;
            }
            if (message.hintInterval != null && message.hasOwnProperty("hintInterval"))
                if (typeof message.hintInterval === "number")
                    object.hintInterval = options.longs === String ? String(message.hintInterval) : message.hintInterval;
                else
                    object.hintInterval = options.longs === String ? $util.Long.prototype.toString.call(message.hintInterval) : options.longs === Number ? new $util.LongBits(message.hintInterval.low >>> 0, message.hintInterval.high >>> 0).toNumber() : message.hintInterval;
            if (message.hintDistance != null && message.hasOwnProperty("hintDistance"))
                object.hintDistance = message.hintDistance;
            if (message.hintAccuracy != null && message.hasOwnProperty("hintAccuracy"))
                object.hintAccuracy = options.enums === String ? $root.proto.LocationAccuracy[message.hintAccuracy] === undefined ? message.hintAccuracy : $root.proto.LocationAccuracy[message.hintAccuracy] : message.hintAccuracy;
            if (message.hintBatchTimeInMillis != null && message.hasOwnProperty("hintBatchTimeInMillis"))
                if (typeof message.hintBatchTimeInMillis === "number")
                    object.hintBatchTimeInMillis = options.longs === String ? String(message.hintBatchTimeInMillis) : message.hintBatchTimeInMillis;
                else
                    object.hintBatchTimeInMillis = options.longs === String ? $util.Long.prototype.toString.call(message.hintBatchTimeInMillis) : options.longs === Number ? new $util.LongBits(message.hintBatchTimeInMillis.low >>> 0, message.hintBatchTimeInMillis.high >>> 0).toNumber() : message.hintBatchTimeInMillis;
            return object;
        };

        /**
         * Converts this LocationReportStartReportingRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationReportStartReportingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportStartReportingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportStartReportingRequest
         * @function getTypeUrl
         * @memberof proto.LocationReportStartReportingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportStartReportingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportStartReportingRequest";
        };

        return LocationReportStartReportingRequest;
    })();

    proto.LocationReportStopReportingRequest = (function() {

        /**
         * Properties of a LocationReportStopReportingRequest.
         * @memberof proto
         * @interface ILocationReportStopReportingRequest
         */

        /**
         * Constructs a new LocationReportStopReportingRequest.
         * @memberof proto
         * @classdesc Represents a LocationReportStopReportingRequest.
         * @implements ILocationReportStopReportingRequest
         * @constructor
         * @param {proto.ILocationReportStopReportingRequest=} [properties] Properties to set
         */
        function LocationReportStopReportingRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LocationReportStopReportingRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {proto.ILocationReportStopReportingRequest=} [properties] Properties to set
         * @returns {proto.LocationReportStopReportingRequest} LocationReportStopReportingRequest instance
         */
        LocationReportStopReportingRequest.create = function create(properties) {
            return new LocationReportStopReportingRequest(properties);
        };

        /**
         * Encodes the specified LocationReportStopReportingRequest message. Does not implicitly {@link proto.LocationReportStopReportingRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {proto.ILocationReportStopReportingRequest} message LocationReportStopReportingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportStopReportingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LocationReportStopReportingRequest message, length delimited. Does not implicitly {@link proto.LocationReportStopReportingRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {proto.ILocationReportStopReportingRequest} message LocationReportStopReportingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportStopReportingRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportStopReportingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportStopReportingRequest} LocationReportStopReportingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportStopReportingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportStopReportingRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationReportStopReportingRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportStopReportingRequest} LocationReportStopReportingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportStopReportingRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportStopReportingRequest message.
         * @function verify
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportStopReportingRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LocationReportStopReportingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportStopReportingRequest} LocationReportStopReportingRequest
         */
        LocationReportStopReportingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportStopReportingRequest)
                return object;
            return new $root.proto.LocationReportStopReportingRequest();
        };

        /**
         * Creates a plain object from a LocationReportStopReportingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {proto.LocationReportStopReportingRequest} message LocationReportStopReportingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportStopReportingRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LocationReportStopReportingRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationReportStopReportingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportStopReportingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportStopReportingRequest
         * @function getTypeUrl
         * @memberof proto.LocationReportStopReportingRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportStopReportingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportStopReportingRequest";
        };

        return LocationReportStopReportingRequest;
    })();

    proto.LocationReportUpdateRequest = (function() {

        /**
         * Properties of a LocationReportUpdateRequest.
         * @memberof proto
         * @interface ILocationReportUpdateRequest
         * @property {number|null} [hintAcceptableAccuracyInMeters] LocationReportUpdateRequest hintAcceptableAccuracyInMeters
         * @property {number|Long|null} [hintAcceptableAgeInMillis] LocationReportUpdateRequest hintAcceptableAgeInMillis
         * @property {number|Long|null} [hintAcceptAllLocationsAfterMillis] LocationReportUpdateRequest hintAcceptAllLocationsAfterMillis
         * @property {number|Long|null} [timeoutInMillis] LocationReportUpdateRequest timeoutInMillis
         */

        /**
         * Constructs a new LocationReportUpdateRequest.
         * @memberof proto
         * @classdesc Represents a LocationReportUpdateRequest.
         * @implements ILocationReportUpdateRequest
         * @constructor
         * @param {proto.ILocationReportUpdateRequest=} [properties] Properties to set
         */
        function LocationReportUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationReportUpdateRequest hintAcceptableAccuracyInMeters.
         * @member {number} hintAcceptableAccuracyInMeters
         * @memberof proto.LocationReportUpdateRequest
         * @instance
         */
        LocationReportUpdateRequest.prototype.hintAcceptableAccuracyInMeters = 0;

        /**
         * LocationReportUpdateRequest hintAcceptableAgeInMillis.
         * @member {number|Long} hintAcceptableAgeInMillis
         * @memberof proto.LocationReportUpdateRequest
         * @instance
         */
        LocationReportUpdateRequest.prototype.hintAcceptableAgeInMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LocationReportUpdateRequest hintAcceptAllLocationsAfterMillis.
         * @member {number|Long} hintAcceptAllLocationsAfterMillis
         * @memberof proto.LocationReportUpdateRequest
         * @instance
         */
        LocationReportUpdateRequest.prototype.hintAcceptAllLocationsAfterMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LocationReportUpdateRequest timeoutInMillis.
         * @member {number|Long} timeoutInMillis
         * @memberof proto.LocationReportUpdateRequest
         * @instance
         */
        LocationReportUpdateRequest.prototype.timeoutInMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new LocationReportUpdateRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {proto.ILocationReportUpdateRequest=} [properties] Properties to set
         * @returns {proto.LocationReportUpdateRequest} LocationReportUpdateRequest instance
         */
        LocationReportUpdateRequest.create = function create(properties) {
            return new LocationReportUpdateRequest(properties);
        };

        /**
         * Encodes the specified LocationReportUpdateRequest message. Does not implicitly {@link proto.LocationReportUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {proto.ILocationReportUpdateRequest} message LocationReportUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hintAcceptableAccuracyInMeters != null && Object.hasOwnProperty.call(message, "hintAcceptableAccuracyInMeters"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hintAcceptableAccuracyInMeters);
            if (message.hintAcceptableAgeInMillis != null && Object.hasOwnProperty.call(message, "hintAcceptableAgeInMillis"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.hintAcceptableAgeInMillis);
            if (message.hintAcceptAllLocationsAfterMillis != null && Object.hasOwnProperty.call(message, "hintAcceptAllLocationsAfterMillis"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.hintAcceptAllLocationsAfterMillis);
            if (message.timeoutInMillis != null && Object.hasOwnProperty.call(message, "timeoutInMillis"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.timeoutInMillis);
            return writer;
        };

        /**
         * Encodes the specified LocationReportUpdateRequest message, length delimited. Does not implicitly {@link proto.LocationReportUpdateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {proto.ILocationReportUpdateRequest} message LocationReportUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportUpdateRequest} LocationReportUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hintAcceptableAccuracyInMeters = reader.int32();
                        break;
                    }
                case 2: {
                        message.hintAcceptableAgeInMillis = reader.int64();
                        break;
                    }
                case 3: {
                        message.hintAcceptAllLocationsAfterMillis = reader.int64();
                        break;
                    }
                case 4: {
                        message.timeoutInMillis = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationReportUpdateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportUpdateRequest} LocationReportUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportUpdateRequest message.
         * @function verify
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportUpdateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hintAcceptableAccuracyInMeters != null && message.hasOwnProperty("hintAcceptableAccuracyInMeters"))
                if (!$util.isInteger(message.hintAcceptableAccuracyInMeters))
                    return "hintAcceptableAccuracyInMeters: integer expected";
            if (message.hintAcceptableAgeInMillis != null && message.hasOwnProperty("hintAcceptableAgeInMillis"))
                if (!$util.isInteger(message.hintAcceptableAgeInMillis) && !(message.hintAcceptableAgeInMillis && $util.isInteger(message.hintAcceptableAgeInMillis.low) && $util.isInteger(message.hintAcceptableAgeInMillis.high)))
                    return "hintAcceptableAgeInMillis: integer|Long expected";
            if (message.hintAcceptAllLocationsAfterMillis != null && message.hasOwnProperty("hintAcceptAllLocationsAfterMillis"))
                if (!$util.isInteger(message.hintAcceptAllLocationsAfterMillis) && !(message.hintAcceptAllLocationsAfterMillis && $util.isInteger(message.hintAcceptAllLocationsAfterMillis.low) && $util.isInteger(message.hintAcceptAllLocationsAfterMillis.high)))
                    return "hintAcceptAllLocationsAfterMillis: integer|Long expected";
            if (message.timeoutInMillis != null && message.hasOwnProperty("timeoutInMillis"))
                if (!$util.isInteger(message.timeoutInMillis) && !(message.timeoutInMillis && $util.isInteger(message.timeoutInMillis.low) && $util.isInteger(message.timeoutInMillis.high)))
                    return "timeoutInMillis: integer|Long expected";
            return null;
        };

        /**
         * Creates a LocationReportUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportUpdateRequest} LocationReportUpdateRequest
         */
        LocationReportUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportUpdateRequest)
                return object;
            var message = new $root.proto.LocationReportUpdateRequest();
            if (object.hintAcceptableAccuracyInMeters != null)
                message.hintAcceptableAccuracyInMeters = object.hintAcceptableAccuracyInMeters | 0;
            if (object.hintAcceptableAgeInMillis != null)
                if ($util.Long)
                    (message.hintAcceptableAgeInMillis = $util.Long.fromValue(object.hintAcceptableAgeInMillis)).unsigned = false;
                else if (typeof object.hintAcceptableAgeInMillis === "string")
                    message.hintAcceptableAgeInMillis = parseInt(object.hintAcceptableAgeInMillis, 10);
                else if (typeof object.hintAcceptableAgeInMillis === "number")
                    message.hintAcceptableAgeInMillis = object.hintAcceptableAgeInMillis;
                else if (typeof object.hintAcceptableAgeInMillis === "object")
                    message.hintAcceptableAgeInMillis = new $util.LongBits(object.hintAcceptableAgeInMillis.low >>> 0, object.hintAcceptableAgeInMillis.high >>> 0).toNumber();
            if (object.hintAcceptAllLocationsAfterMillis != null)
                if ($util.Long)
                    (message.hintAcceptAllLocationsAfterMillis = $util.Long.fromValue(object.hintAcceptAllLocationsAfterMillis)).unsigned = false;
                else if (typeof object.hintAcceptAllLocationsAfterMillis === "string")
                    message.hintAcceptAllLocationsAfterMillis = parseInt(object.hintAcceptAllLocationsAfterMillis, 10);
                else if (typeof object.hintAcceptAllLocationsAfterMillis === "number")
                    message.hintAcceptAllLocationsAfterMillis = object.hintAcceptAllLocationsAfterMillis;
                else if (typeof object.hintAcceptAllLocationsAfterMillis === "object")
                    message.hintAcceptAllLocationsAfterMillis = new $util.LongBits(object.hintAcceptAllLocationsAfterMillis.low >>> 0, object.hintAcceptAllLocationsAfterMillis.high >>> 0).toNumber();
            if (object.timeoutInMillis != null)
                if ($util.Long)
                    (message.timeoutInMillis = $util.Long.fromValue(object.timeoutInMillis)).unsigned = false;
                else if (typeof object.timeoutInMillis === "string")
                    message.timeoutInMillis = parseInt(object.timeoutInMillis, 10);
                else if (typeof object.timeoutInMillis === "number")
                    message.timeoutInMillis = object.timeoutInMillis;
                else if (typeof object.timeoutInMillis === "object")
                    message.timeoutInMillis = new $util.LongBits(object.timeoutInMillis.low >>> 0, object.timeoutInMillis.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a LocationReportUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {proto.LocationReportUpdateRequest} message LocationReportUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.hintAcceptableAccuracyInMeters = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.hintAcceptableAgeInMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.hintAcceptableAgeInMillis = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.hintAcceptAllLocationsAfterMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.hintAcceptAllLocationsAfterMillis = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.timeoutInMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeoutInMillis = options.longs === String ? "0" : 0;
            }
            if (message.hintAcceptableAccuracyInMeters != null && message.hasOwnProperty("hintAcceptableAccuracyInMeters"))
                object.hintAcceptableAccuracyInMeters = message.hintAcceptableAccuracyInMeters;
            if (message.hintAcceptableAgeInMillis != null && message.hasOwnProperty("hintAcceptableAgeInMillis"))
                if (typeof message.hintAcceptableAgeInMillis === "number")
                    object.hintAcceptableAgeInMillis = options.longs === String ? String(message.hintAcceptableAgeInMillis) : message.hintAcceptableAgeInMillis;
                else
                    object.hintAcceptableAgeInMillis = options.longs === String ? $util.Long.prototype.toString.call(message.hintAcceptableAgeInMillis) : options.longs === Number ? new $util.LongBits(message.hintAcceptableAgeInMillis.low >>> 0, message.hintAcceptableAgeInMillis.high >>> 0).toNumber() : message.hintAcceptableAgeInMillis;
            if (message.hintAcceptAllLocationsAfterMillis != null && message.hasOwnProperty("hintAcceptAllLocationsAfterMillis"))
                if (typeof message.hintAcceptAllLocationsAfterMillis === "number")
                    object.hintAcceptAllLocationsAfterMillis = options.longs === String ? String(message.hintAcceptAllLocationsAfterMillis) : message.hintAcceptAllLocationsAfterMillis;
                else
                    object.hintAcceptAllLocationsAfterMillis = options.longs === String ? $util.Long.prototype.toString.call(message.hintAcceptAllLocationsAfterMillis) : options.longs === Number ? new $util.LongBits(message.hintAcceptAllLocationsAfterMillis.low >>> 0, message.hintAcceptAllLocationsAfterMillis.high >>> 0).toNumber() : message.hintAcceptAllLocationsAfterMillis;
            if (message.timeoutInMillis != null && message.hasOwnProperty("timeoutInMillis"))
                if (typeof message.timeoutInMillis === "number")
                    object.timeoutInMillis = options.longs === String ? String(message.timeoutInMillis) : message.timeoutInMillis;
                else
                    object.timeoutInMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timeoutInMillis) : options.longs === Number ? new $util.LongBits(message.timeoutInMillis.low >>> 0, message.timeoutInMillis.high >>> 0).toNumber() : message.timeoutInMillis;
            return object;
        };

        /**
         * Converts this LocationReportUpdateRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationReportUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportUpdateRequest
         * @function getTypeUrl
         * @memberof proto.LocationReportUpdateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportUpdateRequest";
        };

        return LocationReportUpdateRequest;
    })();

    proto.LocationReportUpdateResponse = (function() {

        /**
         * Properties of a LocationReportUpdateResponse.
         * @memberof proto
         * @interface ILocationReportUpdateResponse
         * @property {proto.ILocation} location LocationReportUpdateResponse location
         */

        /**
         * Constructs a new LocationReportUpdateResponse.
         * @memberof proto
         * @classdesc Represents a LocationReportUpdateResponse.
         * @implements ILocationReportUpdateResponse
         * @constructor
         * @param {proto.ILocationReportUpdateResponse=} [properties] Properties to set
         */
        function LocationReportUpdateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationReportUpdateResponse location.
         * @member {proto.ILocation} location
         * @memberof proto.LocationReportUpdateResponse
         * @instance
         */
        LocationReportUpdateResponse.prototype.location = null;

        /**
         * Creates a new LocationReportUpdateResponse instance using the specified properties.
         * @function create
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {proto.ILocationReportUpdateResponse=} [properties] Properties to set
         * @returns {proto.LocationReportUpdateResponse} LocationReportUpdateResponse instance
         */
        LocationReportUpdateResponse.create = function create(properties) {
            return new LocationReportUpdateResponse(properties);
        };

        /**
         * Encodes the specified LocationReportUpdateResponse message. Does not implicitly {@link proto.LocationReportUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {proto.ILocationReportUpdateResponse} message LocationReportUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            $root.proto.Location.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationReportUpdateResponse message, length delimited. Does not implicitly {@link proto.LocationReportUpdateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {proto.ILocationReportUpdateResponse} message LocationReportUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationReportUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationReportUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationReportUpdateResponse} LocationReportUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationReportUpdateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.location = $root.proto.Location.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("location"))
                throw $util.ProtocolError("missing required 'location'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationReportUpdateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationReportUpdateResponse} LocationReportUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationReportUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationReportUpdateResponse message.
         * @function verify
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationReportUpdateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            {
                var error = $root.proto.Location.verify(message.location);
                if (error)
                    return "location." + error;
            }
            return null;
        };

        /**
         * Creates a LocationReportUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationReportUpdateResponse} LocationReportUpdateResponse
         */
        LocationReportUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationReportUpdateResponse)
                return object;
            var message = new $root.proto.LocationReportUpdateResponse();
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".proto.LocationReportUpdateResponse.location: object expected");
                message.location = $root.proto.Location.fromObject(object.location);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationReportUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {proto.LocationReportUpdateResponse} message LocationReportUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationReportUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.location = null;
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.proto.Location.toObject(message.location, options);
            return object;
        };

        /**
         * Converts this LocationReportUpdateResponse to JSON.
         * @function toJSON
         * @memberof proto.LocationReportUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationReportUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationReportUpdateResponse
         * @function getTypeUrl
         * @memberof proto.LocationReportUpdateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationReportUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationReportUpdateResponse";
        };

        return LocationReportUpdateResponse;
    })();

    /**
     * LocationAccuracy enum.
     * @name proto.LocationAccuracy
     * @enum {number}
     * @property {number} NEAREST_100_M=1 NEAREST_100_M value
     * @property {number} NEAREST_10_M=2 NEAREST_10_M value
     * @property {number} BEST=3 BEST value
     */
    proto.LocationAccuracy = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "NEAREST_100_M"] = 1;
        values[valuesById[2] = "NEAREST_10_M"] = 2;
        values[valuesById[3] = "BEST"] = 3;
        return values;
    })();

    proto.Location = (function() {

        /**
         * Properties of a Location.
         * @memberof proto
         * @interface ILocation
         * @property {number} longitude Location longitude
         * @property {number} latitude Location latitude
         * @property {number|null} [accuracy] Location accuracy
         * @property {number|null} [bearing] Location bearing
         * @property {number|null} [speed] Location speed
         * @property {number|null} [altitude] Location altitude
         * @property {number|Long} time Location time
         */

        /**
         * Constructs a new Location.
         * @memberof proto
         * @classdesc Represents a Location.
         * @implements ILocation
         * @constructor
         * @param {proto.ILocation=} [properties] Properties to set
         */
        function Location(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Location longitude.
         * @member {number} longitude
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.longitude = 0;

        /**
         * Location latitude.
         * @member {number} latitude
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.latitude = 0;

        /**
         * Location accuracy.
         * @member {number} accuracy
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.accuracy = 0;

        /**
         * Location bearing.
         * @member {number} bearing
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.bearing = 0;

        /**
         * Location speed.
         * @member {number} speed
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.speed = 0;

        /**
         * Location altitude.
         * @member {number} altitude
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.altitude = 0;

        /**
         * Location time.
         * @member {number|Long} time
         * @memberof proto.Location
         * @instance
         */
        Location.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Location instance using the specified properties.
         * @function create
         * @memberof proto.Location
         * @static
         * @param {proto.ILocation=} [properties] Properties to set
         * @returns {proto.Location} Location instance
         */
        Location.create = function create(properties) {
            return new Location(properties);
        };

        /**
         * Encodes the specified Location message. Does not implicitly {@link proto.Location.verify|verify} messages.
         * @function encode
         * @memberof proto.Location
         * @static
         * @param {proto.ILocation} message Location message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Location.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 1 =*/9).double(message.longitude);
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.latitude);
            if (message.accuracy != null && Object.hasOwnProperty.call(message, "accuracy"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.accuracy);
            if (message.bearing != null && Object.hasOwnProperty.call(message, "bearing"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.bearing);
            if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.speed);
            if (message.altitude != null && Object.hasOwnProperty.call(message, "altitude"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.altitude);
            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.time);
            return writer;
        };

        /**
         * Encodes the specified Location message, length delimited. Does not implicitly {@link proto.Location.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Location
         * @static
         * @param {proto.ILocation} message Location message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Location.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Location message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Location
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Location} Location
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Location.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Location();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.longitude = reader.double();
                        break;
                    }
                case 2: {
                        message.latitude = reader.double();
                        break;
                    }
                case 3: {
                        message.accuracy = reader.double();
                        break;
                    }
                case 4: {
                        message.bearing = reader.double();
                        break;
                    }
                case 5: {
                        message.speed = reader.double();
                        break;
                    }
                case 6: {
                        message.altitude = reader.double();
                        break;
                    }
                case 7: {
                        message.time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("longitude"))
                throw $util.ProtocolError("missing required 'longitude'", { instance: message });
            if (!message.hasOwnProperty("latitude"))
                throw $util.ProtocolError("missing required 'latitude'", { instance: message });
            if (!message.hasOwnProperty("time"))
                throw $util.ProtocolError("missing required 'time'", { instance: message });
            return message;
        };

        /**
         * Decodes a Location message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Location
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Location} Location
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Location.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Location message.
         * @function verify
         * @memberof proto.Location
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Location.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.longitude !== "number")
                return "longitude: number expected";
            if (typeof message.latitude !== "number")
                return "latitude: number expected";
            if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                if (typeof message.accuracy !== "number")
                    return "accuracy: number expected";
            if (message.bearing != null && message.hasOwnProperty("bearing"))
                if (typeof message.bearing !== "number")
                    return "bearing: number expected";
            if (message.speed != null && message.hasOwnProperty("speed"))
                if (typeof message.speed !== "number")
                    return "speed: number expected";
            if (message.altitude != null && message.hasOwnProperty("altitude"))
                if (typeof message.altitude !== "number")
                    return "altitude: number expected";
            if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                return "time: integer|Long expected";
            return null;
        };

        /**
         * Creates a Location message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Location
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Location} Location
         */
        Location.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Location)
                return object;
            var message = new $root.proto.Location();
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.accuracy != null)
                message.accuracy = Number(object.accuracy);
            if (object.bearing != null)
                message.bearing = Number(object.bearing);
            if (object.speed != null)
                message.speed = Number(object.speed);
            if (object.altitude != null)
                message.altitude = Number(object.altitude);
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Location message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Location
         * @static
         * @param {proto.Location} message Location
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Location.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.longitude = 0;
                object.latitude = 0;
                object.accuracy = 0;
                object.bearing = 0;
                object.speed = 0;
                object.altitude = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
            }
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                object.accuracy = options.json && !isFinite(message.accuracy) ? String(message.accuracy) : message.accuracy;
            if (message.bearing != null && message.hasOwnProperty("bearing"))
                object.bearing = options.json && !isFinite(message.bearing) ? String(message.bearing) : message.bearing;
            if (message.speed != null && message.hasOwnProperty("speed"))
                object.speed = options.json && !isFinite(message.speed) ? String(message.speed) : message.speed;
            if (message.altitude != null && message.hasOwnProperty("altitude"))
                object.altitude = options.json && !isFinite(message.altitude) ? String(message.altitude) : message.altitude;
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
            return object;
        };

        /**
         * Converts this Location to JSON.
         * @function toJSON
         * @memberof proto.Location
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Location.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Location
         * @function getTypeUrl
         * @memberof proto.Location
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.Location";
        };

        return Location;
    })();

    proto.LocationEnquiryAPIv1Client = (function() {

        /**
         * Properties of a LocationEnquiryAPIv1Client.
         * @memberof proto
         * @interface ILocationEnquiryAPIv1Client
         * @property {proto.ILocationEnquiryModuleSetupRequest|null} [setupRequest] LocationEnquiryAPIv1Client setupRequest
         * @property {proto.ILocationEnquirySubscribeRequest|null} [subscribeRequest] LocationEnquiryAPIv1Client subscribeRequest
         * @property {proto.ILocationEnquiryUnsubscribeRequest|null} [unsubscribeRequest] LocationEnquiryAPIv1Client unsubscribeRequest
         * @property {proto.ILocationEnquiryUpdateFilterRequest|null} [updateFilterRequest] LocationEnquiryAPIv1Client updateFilterRequest
         */

        /**
         * Constructs a new LocationEnquiryAPIv1Client.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryAPIv1Client.
         * @implements ILocationEnquiryAPIv1Client
         * @constructor
         * @param {proto.ILocationEnquiryAPIv1Client=} [properties] Properties to set
         */
        function LocationEnquiryAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryAPIv1Client setupRequest.
         * @member {proto.ILocationEnquiryModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.LocationEnquiryAPIv1Client
         * @instance
         */
        LocationEnquiryAPIv1Client.prototype.setupRequest = null;

        /**
         * LocationEnquiryAPIv1Client subscribeRequest.
         * @member {proto.ILocationEnquirySubscribeRequest|null|undefined} subscribeRequest
         * @memberof proto.LocationEnquiryAPIv1Client
         * @instance
         */
        LocationEnquiryAPIv1Client.prototype.subscribeRequest = null;

        /**
         * LocationEnquiryAPIv1Client unsubscribeRequest.
         * @member {proto.ILocationEnquiryUnsubscribeRequest|null|undefined} unsubscribeRequest
         * @memberof proto.LocationEnquiryAPIv1Client
         * @instance
         */
        LocationEnquiryAPIv1Client.prototype.unsubscribeRequest = null;

        /**
         * LocationEnquiryAPIv1Client updateFilterRequest.
         * @member {proto.ILocationEnquiryUpdateFilterRequest|null|undefined} updateFilterRequest
         * @memberof proto.LocationEnquiryAPIv1Client
         * @instance
         */
        LocationEnquiryAPIv1Client.prototype.updateFilterRequest = null;

        /**
         * Creates a new LocationEnquiryAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {proto.ILocationEnquiryAPIv1Client=} [properties] Properties to set
         * @returns {proto.LocationEnquiryAPIv1Client} LocationEnquiryAPIv1Client instance
         */
        LocationEnquiryAPIv1Client.create = function create(properties) {
            return new LocationEnquiryAPIv1Client(properties);
        };

        /**
         * Encodes the specified LocationEnquiryAPIv1Client message. Does not implicitly {@link proto.LocationEnquiryAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {proto.ILocationEnquiryAPIv1Client} message LocationEnquiryAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.LocationEnquiryModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.subscribeRequest != null && Object.hasOwnProperty.call(message, "subscribeRequest"))
                $root.proto.LocationEnquirySubscribeRequest.encode(message.subscribeRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.unsubscribeRequest != null && Object.hasOwnProperty.call(message, "unsubscribeRequest"))
                $root.proto.LocationEnquiryUnsubscribeRequest.encode(message.unsubscribeRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updateFilterRequest != null && Object.hasOwnProperty.call(message, "updateFilterRequest"))
                $root.proto.LocationEnquiryUpdateFilterRequest.encode(message.updateFilterRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryAPIv1Client message, length delimited. Does not implicitly {@link proto.LocationEnquiryAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {proto.ILocationEnquiryAPIv1Client} message LocationEnquiryAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryAPIv1Client} LocationEnquiryAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.LocationEnquiryModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.subscribeRequest = $root.proto.LocationEnquirySubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.unsubscribeRequest = $root.proto.LocationEnquiryUnsubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.updateFilterRequest = $root.proto.LocationEnquiryUpdateFilterRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationEnquiryAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryAPIv1Client} LocationEnquiryAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryAPIv1Client message.
         * @function verify
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.LocationEnquiryModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest")) {
                var error = $root.proto.LocationEnquirySubscribeRequest.verify(message.subscribeRequest);
                if (error)
                    return "subscribeRequest." + error;
            }
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest")) {
                var error = $root.proto.LocationEnquiryUnsubscribeRequest.verify(message.unsubscribeRequest);
                if (error)
                    return "unsubscribeRequest." + error;
            }
            if (message.updateFilterRequest != null && message.hasOwnProperty("updateFilterRequest")) {
                var error = $root.proto.LocationEnquiryUpdateFilterRequest.verify(message.updateFilterRequest);
                if (error)
                    return "updateFilterRequest." + error;
            }
            return null;
        };

        /**
         * Creates a LocationEnquiryAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryAPIv1Client} LocationEnquiryAPIv1Client
         */
        LocationEnquiryAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryAPIv1Client)
                return object;
            var message = new $root.proto.LocationEnquiryAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.LocationEnquiryAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.LocationEnquiryModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.subscribeRequest != null) {
                if (typeof object.subscribeRequest !== "object")
                    throw TypeError(".proto.LocationEnquiryAPIv1Client.subscribeRequest: object expected");
                message.subscribeRequest = $root.proto.LocationEnquirySubscribeRequest.fromObject(object.subscribeRequest);
            }
            if (object.unsubscribeRequest != null) {
                if (typeof object.unsubscribeRequest !== "object")
                    throw TypeError(".proto.LocationEnquiryAPIv1Client.unsubscribeRequest: object expected");
                message.unsubscribeRequest = $root.proto.LocationEnquiryUnsubscribeRequest.fromObject(object.unsubscribeRequest);
            }
            if (object.updateFilterRequest != null) {
                if (typeof object.updateFilterRequest !== "object")
                    throw TypeError(".proto.LocationEnquiryAPIv1Client.updateFilterRequest: object expected");
                message.updateFilterRequest = $root.proto.LocationEnquiryUpdateFilterRequest.fromObject(object.updateFilterRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {proto.LocationEnquiryAPIv1Client} message LocationEnquiryAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.subscribeRequest = null;
                object.unsubscribeRequest = null;
                object.updateFilterRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.LocationEnquiryModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest"))
                object.subscribeRequest = $root.proto.LocationEnquirySubscribeRequest.toObject(message.subscribeRequest, options);
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest"))
                object.unsubscribeRequest = $root.proto.LocationEnquiryUnsubscribeRequest.toObject(message.unsubscribeRequest, options);
            if (message.updateFilterRequest != null && message.hasOwnProperty("updateFilterRequest"))
                object.updateFilterRequest = $root.proto.LocationEnquiryUpdateFilterRequest.toObject(message.updateFilterRequest, options);
            return object;
        };

        /**
         * Converts this LocationEnquiryAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryAPIv1Client
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryAPIv1Client";
        };

        return LocationEnquiryAPIv1Client;
    })();

    proto.LocationEnquiryModuleSetupRequest = (function() {

        /**
         * Properties of a LocationEnquiryModuleSetupRequest.
         * @memberof proto
         * @interface ILocationEnquiryModuleSetupRequest
         */

        /**
         * Constructs a new LocationEnquiryModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryModuleSetupRequest.
         * @implements ILocationEnquiryModuleSetupRequest
         * @constructor
         * @param {proto.ILocationEnquiryModuleSetupRequest=} [properties] Properties to set
         */
        function LocationEnquiryModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LocationEnquiryModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {proto.ILocationEnquiryModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.LocationEnquiryModuleSetupRequest} LocationEnquiryModuleSetupRequest instance
         */
        LocationEnquiryModuleSetupRequest.create = function create(properties) {
            return new LocationEnquiryModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified LocationEnquiryModuleSetupRequest message. Does not implicitly {@link proto.LocationEnquiryModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {proto.ILocationEnquiryModuleSetupRequest} message LocationEnquiryModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryModuleSetupRequest message, length delimited. Does not implicitly {@link proto.LocationEnquiryModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {proto.ILocationEnquiryModuleSetupRequest} message LocationEnquiryModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryModuleSetupRequest} LocationEnquiryModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationEnquiryModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryModuleSetupRequest} LocationEnquiryModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryModuleSetupRequest message.
         * @function verify
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LocationEnquiryModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryModuleSetupRequest} LocationEnquiryModuleSetupRequest
         */
        LocationEnquiryModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryModuleSetupRequest)
                return object;
            return new $root.proto.LocationEnquiryModuleSetupRequest();
        };

        /**
         * Creates a plain object from a LocationEnquiryModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {proto.LocationEnquiryModuleSetupRequest} message LocationEnquiryModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LocationEnquiryModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryModuleSetupRequest";
        };

        return LocationEnquiryModuleSetupRequest;
    })();

    proto.LocationEnquiryModuleSetupResponse = (function() {

        /**
         * Properties of a LocationEnquiryModuleSetupResponse.
         * @memberof proto
         * @interface ILocationEnquiryModuleSetupResponse
         * @property {boolean} hintLocatableUsers LocationEnquiryModuleSetupResponse hintLocatableUsers
         */

        /**
         * Constructs a new LocationEnquiryModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryModuleSetupResponse.
         * @implements ILocationEnquiryModuleSetupResponse
         * @constructor
         * @param {proto.ILocationEnquiryModuleSetupResponse=} [properties] Properties to set
         */
        function LocationEnquiryModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryModuleSetupResponse hintLocatableUsers.
         * @member {boolean} hintLocatableUsers
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @instance
         */
        LocationEnquiryModuleSetupResponse.prototype.hintLocatableUsers = false;

        /**
         * Creates a new LocationEnquiryModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {proto.ILocationEnquiryModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.LocationEnquiryModuleSetupResponse} LocationEnquiryModuleSetupResponse instance
         */
        LocationEnquiryModuleSetupResponse.create = function create(properties) {
            return new LocationEnquiryModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified LocationEnquiryModuleSetupResponse message. Does not implicitly {@link proto.LocationEnquiryModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {proto.ILocationEnquiryModuleSetupResponse} message LocationEnquiryModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hintLocatableUsers);
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryModuleSetupResponse message, length delimited. Does not implicitly {@link proto.LocationEnquiryModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {proto.ILocationEnquiryModuleSetupResponse} message LocationEnquiryModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryModuleSetupResponse} LocationEnquiryModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hintLocatableUsers = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("hintLocatableUsers"))
                throw $util.ProtocolError("missing required 'hintLocatableUsers'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquiryModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryModuleSetupResponse} LocationEnquiryModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryModuleSetupResponse message.
         * @function verify
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.hintLocatableUsers !== "boolean")
                return "hintLocatableUsers: boolean expected";
            return null;
        };

        /**
         * Creates a LocationEnquiryModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryModuleSetupResponse} LocationEnquiryModuleSetupResponse
         */
        LocationEnquiryModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryModuleSetupResponse)
                return object;
            var message = new $root.proto.LocationEnquiryModuleSetupResponse();
            if (object.hintLocatableUsers != null)
                message.hintLocatableUsers = Boolean(object.hintLocatableUsers);
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {proto.LocationEnquiryModuleSetupResponse} message LocationEnquiryModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.hintLocatableUsers = false;
            if (message.hintLocatableUsers != null && message.hasOwnProperty("hintLocatableUsers"))
                object.hintLocatableUsers = message.hintLocatableUsers;
            return object;
        };

        /**
         * Converts this LocationEnquiryModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryModuleSetupResponse";
        };

        return LocationEnquiryModuleSetupResponse;
    })();

    proto.LocationEnquirySubscribeRequest = (function() {

        /**
         * Properties of a LocationEnquirySubscribeRequest.
         * @memberof proto
         * @interface ILocationEnquirySubscribeRequest
         * @property {proto.ILocationEnquiryFilter} filter LocationEnquirySubscribeRequest filter
         */

        /**
         * Constructs a new LocationEnquirySubscribeRequest.
         * @memberof proto
         * @classdesc Represents a LocationEnquirySubscribeRequest.
         * @implements ILocationEnquirySubscribeRequest
         * @constructor
         * @param {proto.ILocationEnquirySubscribeRequest=} [properties] Properties to set
         */
        function LocationEnquirySubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquirySubscribeRequest filter.
         * @member {proto.ILocationEnquiryFilter} filter
         * @memberof proto.LocationEnquirySubscribeRequest
         * @instance
         */
        LocationEnquirySubscribeRequest.prototype.filter = null;

        /**
         * Creates a new LocationEnquirySubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {proto.ILocationEnquirySubscribeRequest=} [properties] Properties to set
         * @returns {proto.LocationEnquirySubscribeRequest} LocationEnquirySubscribeRequest instance
         */
        LocationEnquirySubscribeRequest.create = function create(properties) {
            return new LocationEnquirySubscribeRequest(properties);
        };

        /**
         * Encodes the specified LocationEnquirySubscribeRequest message. Does not implicitly {@link proto.LocationEnquirySubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {proto.ILocationEnquirySubscribeRequest} message LocationEnquirySubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquirySubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            $root.proto.LocationEnquiryFilter.encode(message.filter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquirySubscribeRequest message, length delimited. Does not implicitly {@link proto.LocationEnquirySubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {proto.ILocationEnquirySubscribeRequest} message LocationEnquirySubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquirySubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquirySubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquirySubscribeRequest} LocationEnquirySubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquirySubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquirySubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.filter = $root.proto.LocationEnquiryFilter.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("filter"))
                throw $util.ProtocolError("missing required 'filter'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquirySubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquirySubscribeRequest} LocationEnquirySubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquirySubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquirySubscribeRequest message.
         * @function verify
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquirySubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            {
                var error = $root.proto.LocationEnquiryFilter.verify(message.filter);
                if (error)
                    return "filter." + error;
            }
            return null;
        };

        /**
         * Creates a LocationEnquirySubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquirySubscribeRequest} LocationEnquirySubscribeRequest
         */
        LocationEnquirySubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquirySubscribeRequest)
                return object;
            var message = new $root.proto.LocationEnquirySubscribeRequest();
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".proto.LocationEnquirySubscribeRequest.filter: object expected");
                message.filter = $root.proto.LocationEnquiryFilter.fromObject(object.filter);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquirySubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {proto.LocationEnquirySubscribeRequest} message LocationEnquirySubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquirySubscribeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.filter = null;
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.proto.LocationEnquiryFilter.toObject(message.filter, options);
            return object;
        };

        /**
         * Converts this LocationEnquirySubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquirySubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquirySubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquirySubscribeRequest
         * @function getTypeUrl
         * @memberof proto.LocationEnquirySubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquirySubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquirySubscribeRequest";
        };

        return LocationEnquirySubscribeRequest;
    })();

    proto.LocationEnquirySubscribeResponse = (function() {

        /**
         * Properties of a LocationEnquirySubscribeResponse.
         * @memberof proto
         * @interface ILocationEnquirySubscribeResponse
         * @property {proto.ILocationEnquiryDelta} initialDelta LocationEnquirySubscribeResponse initialDelta
         */

        /**
         * Constructs a new LocationEnquirySubscribeResponse.
         * @memberof proto
         * @classdesc Represents a LocationEnquirySubscribeResponse.
         * @implements ILocationEnquirySubscribeResponse
         * @constructor
         * @param {proto.ILocationEnquirySubscribeResponse=} [properties] Properties to set
         */
        function LocationEnquirySubscribeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquirySubscribeResponse initialDelta.
         * @member {proto.ILocationEnquiryDelta} initialDelta
         * @memberof proto.LocationEnquirySubscribeResponse
         * @instance
         */
        LocationEnquirySubscribeResponse.prototype.initialDelta = null;

        /**
         * Creates a new LocationEnquirySubscribeResponse instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {proto.ILocationEnquirySubscribeResponse=} [properties] Properties to set
         * @returns {proto.LocationEnquirySubscribeResponse} LocationEnquirySubscribeResponse instance
         */
        LocationEnquirySubscribeResponse.create = function create(properties) {
            return new LocationEnquirySubscribeResponse(properties);
        };

        /**
         * Encodes the specified LocationEnquirySubscribeResponse message. Does not implicitly {@link proto.LocationEnquirySubscribeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {proto.ILocationEnquirySubscribeResponse} message LocationEnquirySubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquirySubscribeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            $root.proto.LocationEnquiryDelta.encode(message.initialDelta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquirySubscribeResponse message, length delimited. Does not implicitly {@link proto.LocationEnquirySubscribeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {proto.ILocationEnquirySubscribeResponse} message LocationEnquirySubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquirySubscribeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquirySubscribeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquirySubscribeResponse} LocationEnquirySubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquirySubscribeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquirySubscribeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.initialDelta = $root.proto.LocationEnquiryDelta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("initialDelta"))
                throw $util.ProtocolError("missing required 'initialDelta'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquirySubscribeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquirySubscribeResponse} LocationEnquirySubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquirySubscribeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquirySubscribeResponse message.
         * @function verify
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquirySubscribeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            {
                var error = $root.proto.LocationEnquiryDelta.verify(message.initialDelta);
                if (error)
                    return "initialDelta." + error;
            }
            return null;
        };

        /**
         * Creates a LocationEnquirySubscribeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquirySubscribeResponse} LocationEnquirySubscribeResponse
         */
        LocationEnquirySubscribeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquirySubscribeResponse)
                return object;
            var message = new $root.proto.LocationEnquirySubscribeResponse();
            if (object.initialDelta != null) {
                if (typeof object.initialDelta !== "object")
                    throw TypeError(".proto.LocationEnquirySubscribeResponse.initialDelta: object expected");
                message.initialDelta = $root.proto.LocationEnquiryDelta.fromObject(object.initialDelta);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquirySubscribeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {proto.LocationEnquirySubscribeResponse} message LocationEnquirySubscribeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquirySubscribeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.initialDelta = null;
            if (message.initialDelta != null && message.hasOwnProperty("initialDelta"))
                object.initialDelta = $root.proto.LocationEnquiryDelta.toObject(message.initialDelta, options);
            return object;
        };

        /**
         * Converts this LocationEnquirySubscribeResponse to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquirySubscribeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquirySubscribeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquirySubscribeResponse
         * @function getTypeUrl
         * @memberof proto.LocationEnquirySubscribeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquirySubscribeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquirySubscribeResponse";
        };

        return LocationEnquirySubscribeResponse;
    })();

    proto.LocationEnquiryUnsubscribeRequest = (function() {

        /**
         * Properties of a LocationEnquiryUnsubscribeRequest.
         * @memberof proto
         * @interface ILocationEnquiryUnsubscribeRequest
         * @property {number|Long} subscriptionId LocationEnquiryUnsubscribeRequest subscriptionId
         */

        /**
         * Constructs a new LocationEnquiryUnsubscribeRequest.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryUnsubscribeRequest.
         * @implements ILocationEnquiryUnsubscribeRequest
         * @constructor
         * @param {proto.ILocationEnquiryUnsubscribeRequest=} [properties] Properties to set
         */
        function LocationEnquiryUnsubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryUnsubscribeRequest subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @instance
         */
        LocationEnquiryUnsubscribeRequest.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new LocationEnquiryUnsubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {proto.ILocationEnquiryUnsubscribeRequest=} [properties] Properties to set
         * @returns {proto.LocationEnquiryUnsubscribeRequest} LocationEnquiryUnsubscribeRequest instance
         */
        LocationEnquiryUnsubscribeRequest.create = function create(properties) {
            return new LocationEnquiryUnsubscribeRequest(properties);
        };

        /**
         * Encodes the specified LocationEnquiryUnsubscribeRequest message. Does not implicitly {@link proto.LocationEnquiryUnsubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {proto.ILocationEnquiryUnsubscribeRequest} message LocationEnquiryUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryUnsubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryUnsubscribeRequest message, length delimited. Does not implicitly {@link proto.LocationEnquiryUnsubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {proto.ILocationEnquiryUnsubscribeRequest} message LocationEnquiryUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryUnsubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryUnsubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryUnsubscribeRequest} LocationEnquiryUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryUnsubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryUnsubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquiryUnsubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryUnsubscribeRequest} LocationEnquiryUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryUnsubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryUnsubscribeRequest message.
         * @function verify
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryUnsubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a LocationEnquiryUnsubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryUnsubscribeRequest} LocationEnquiryUnsubscribeRequest
         */
        LocationEnquiryUnsubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryUnsubscribeRequest)
                return object;
            var message = new $root.proto.LocationEnquiryUnsubscribeRequest();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryUnsubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {proto.LocationEnquiryUnsubscribeRequest} message LocationEnquiryUnsubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryUnsubscribeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            return object;
        };

        /**
         * Converts this LocationEnquiryUnsubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryUnsubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryUnsubscribeRequest
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryUnsubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryUnsubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryUnsubscribeRequest";
        };

        return LocationEnquiryUnsubscribeRequest;
    })();

    proto.LocationEnquiryFilter = (function() {

        /**
         * Properties of a LocationEnquiryFilter.
         * @memberof proto
         * @interface ILocationEnquiryFilter
         * @property {Array.<string>|null} [entityIds] LocationEnquiryFilter entityIds
         * @property {proto.ILocationRange|null} [range] LocationEnquiryFilter range
         */

        /**
         * Constructs a new LocationEnquiryFilter.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryFilter.
         * @implements ILocationEnquiryFilter
         * @constructor
         * @param {proto.ILocationEnquiryFilter=} [properties] Properties to set
         */
        function LocationEnquiryFilter(properties) {
            this.entityIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryFilter entityIds.
         * @member {Array.<string>} entityIds
         * @memberof proto.LocationEnquiryFilter
         * @instance
         */
        LocationEnquiryFilter.prototype.entityIds = $util.emptyArray;

        /**
         * LocationEnquiryFilter range.
         * @member {proto.ILocationRange|null|undefined} range
         * @memberof proto.LocationEnquiryFilter
         * @instance
         */
        LocationEnquiryFilter.prototype.range = null;

        /**
         * Creates a new LocationEnquiryFilter instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {proto.ILocationEnquiryFilter=} [properties] Properties to set
         * @returns {proto.LocationEnquiryFilter} LocationEnquiryFilter instance
         */
        LocationEnquiryFilter.create = function create(properties) {
            return new LocationEnquiryFilter(properties);
        };

        /**
         * Encodes the specified LocationEnquiryFilter message. Does not implicitly {@link proto.LocationEnquiryFilter.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {proto.ILocationEnquiryFilter} message LocationEnquiryFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entityIds != null && message.entityIds.length)
                for (var i = 0; i < message.entityIds.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.entityIds[i]);
            if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                $root.proto.LocationRange.encode(message.range, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryFilter message, length delimited. Does not implicitly {@link proto.LocationEnquiryFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {proto.ILocationEnquiryFilter} message LocationEnquiryFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryFilter message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryFilter} LocationEnquiryFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryFilter();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.entityIds && message.entityIds.length))
                            message.entityIds = [];
                        message.entityIds.push(reader.string());
                        break;
                    }
                case 2: {
                        message.range = $root.proto.LocationRange.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationEnquiryFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryFilter} LocationEnquiryFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryFilter message.
         * @function verify
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.entityIds != null && message.hasOwnProperty("entityIds")) {
                if (!Array.isArray(message.entityIds))
                    return "entityIds: array expected";
                for (var i = 0; i < message.entityIds.length; ++i)
                    if (!$util.isString(message.entityIds[i]))
                        return "entityIds: string[] expected";
            }
            if (message.range != null && message.hasOwnProperty("range")) {
                var error = $root.proto.LocationRange.verify(message.range);
                if (error)
                    return "range." + error;
            }
            return null;
        };

        /**
         * Creates a LocationEnquiryFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryFilter} LocationEnquiryFilter
         */
        LocationEnquiryFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryFilter)
                return object;
            var message = new $root.proto.LocationEnquiryFilter();
            if (object.entityIds) {
                if (!Array.isArray(object.entityIds))
                    throw TypeError(".proto.LocationEnquiryFilter.entityIds: array expected");
                message.entityIds = [];
                for (var i = 0; i < object.entityIds.length; ++i)
                    message.entityIds[i] = String(object.entityIds[i]);
            }
            if (object.range != null) {
                if (typeof object.range !== "object")
                    throw TypeError(".proto.LocationEnquiryFilter.range: object expected");
                message.range = $root.proto.LocationRange.fromObject(object.range);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {proto.LocationEnquiryFilter} message LocationEnquiryFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.entityIds = [];
            if (options.defaults)
                object.range = null;
            if (message.entityIds && message.entityIds.length) {
                object.entityIds = [];
                for (var j = 0; j < message.entityIds.length; ++j)
                    object.entityIds[j] = message.entityIds[j];
            }
            if (message.range != null && message.hasOwnProperty("range"))
                object.range = $root.proto.LocationRange.toObject(message.range, options);
            return object;
        };

        /**
         * Converts this LocationEnquiryFilter to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryFilter
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryFilter";
        };

        return LocationEnquiryFilter;
    })();

    proto.LocationEnquiryUpdateFilterRequest = (function() {

        /**
         * Properties of a LocationEnquiryUpdateFilterRequest.
         * @memberof proto
         * @interface ILocationEnquiryUpdateFilterRequest
         * @property {number|Long} subscriptionId LocationEnquiryUpdateFilterRequest subscriptionId
         * @property {proto.ILocationEnquiryFilter} filter LocationEnquiryUpdateFilterRequest filter
         */

        /**
         * Constructs a new LocationEnquiryUpdateFilterRequest.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryUpdateFilterRequest.
         * @implements ILocationEnquiryUpdateFilterRequest
         * @constructor
         * @param {proto.ILocationEnquiryUpdateFilterRequest=} [properties] Properties to set
         */
        function LocationEnquiryUpdateFilterRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryUpdateFilterRequest subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @instance
         */
        LocationEnquiryUpdateFilterRequest.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LocationEnquiryUpdateFilterRequest filter.
         * @member {proto.ILocationEnquiryFilter} filter
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @instance
         */
        LocationEnquiryUpdateFilterRequest.prototype.filter = null;

        /**
         * Creates a new LocationEnquiryUpdateFilterRequest instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {proto.ILocationEnquiryUpdateFilterRequest=} [properties] Properties to set
         * @returns {proto.LocationEnquiryUpdateFilterRequest} LocationEnquiryUpdateFilterRequest instance
         */
        LocationEnquiryUpdateFilterRequest.create = function create(properties) {
            return new LocationEnquiryUpdateFilterRequest(properties);
        };

        /**
         * Encodes the specified LocationEnquiryUpdateFilterRequest message. Does not implicitly {@link proto.LocationEnquiryUpdateFilterRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {proto.ILocationEnquiryUpdateFilterRequest} message LocationEnquiryUpdateFilterRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryUpdateFilterRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            $root.proto.LocationEnquiryFilter.encode(message.filter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryUpdateFilterRequest message, length delimited. Does not implicitly {@link proto.LocationEnquiryUpdateFilterRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {proto.ILocationEnquiryUpdateFilterRequest} message LocationEnquiryUpdateFilterRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryUpdateFilterRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryUpdateFilterRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryUpdateFilterRequest} LocationEnquiryUpdateFilterRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryUpdateFilterRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryUpdateFilterRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.filter = $root.proto.LocationEnquiryFilter.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            if (!message.hasOwnProperty("filter"))
                throw $util.ProtocolError("missing required 'filter'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquiryUpdateFilterRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryUpdateFilterRequest} LocationEnquiryUpdateFilterRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryUpdateFilterRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryUpdateFilterRequest message.
         * @function verify
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryUpdateFilterRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            {
                var error = $root.proto.LocationEnquiryFilter.verify(message.filter);
                if (error)
                    return "filter." + error;
            }
            return null;
        };

        /**
         * Creates a LocationEnquiryUpdateFilterRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryUpdateFilterRequest} LocationEnquiryUpdateFilterRequest
         */
        LocationEnquiryUpdateFilterRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryUpdateFilterRequest)
                return object;
            var message = new $root.proto.LocationEnquiryUpdateFilterRequest();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.filter != null) {
                if (typeof object.filter !== "object")
                    throw TypeError(".proto.LocationEnquiryUpdateFilterRequest.filter: object expected");
                message.filter = $root.proto.LocationEnquiryFilter.fromObject(object.filter);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryUpdateFilterRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {proto.LocationEnquiryUpdateFilterRequest} message LocationEnquiryUpdateFilterRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryUpdateFilterRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
                object.filter = null;
            }
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = $root.proto.LocationEnquiryFilter.toObject(message.filter, options);
            return object;
        };

        /**
         * Converts this LocationEnquiryUpdateFilterRequest to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryUpdateFilterRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryUpdateFilterRequest
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryUpdateFilterRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryUpdateFilterRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryUpdateFilterRequest";
        };

        return LocationEnquiryUpdateFilterRequest;
    })();

    proto.LocationEnquiryDelta = (function() {

        /**
         * Properties of a LocationEnquiryDelta.
         * @memberof proto
         * @interface ILocationEnquiryDelta
         * @property {number|Long} subscriptionId LocationEnquiryDelta subscriptionId
         * @property {Array.<proto.ILocationEnquiryLocationUpdate>|null} [updatedLocations] LocationEnquiryDelta updatedLocations
         * @property {Array.<string>|null} [removedUserIds] LocationEnquiryDelta removedUserIds
         */

        /**
         * Constructs a new LocationEnquiryDelta.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryDelta.
         * @implements ILocationEnquiryDelta
         * @constructor
         * @param {proto.ILocationEnquiryDelta=} [properties] Properties to set
         */
        function LocationEnquiryDelta(properties) {
            this.updatedLocations = [];
            this.removedUserIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryDelta subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.LocationEnquiryDelta
         * @instance
         */
        LocationEnquiryDelta.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LocationEnquiryDelta updatedLocations.
         * @member {Array.<proto.ILocationEnquiryLocationUpdate>} updatedLocations
         * @memberof proto.LocationEnquiryDelta
         * @instance
         */
        LocationEnquiryDelta.prototype.updatedLocations = $util.emptyArray;

        /**
         * LocationEnquiryDelta removedUserIds.
         * @member {Array.<string>} removedUserIds
         * @memberof proto.LocationEnquiryDelta
         * @instance
         */
        LocationEnquiryDelta.prototype.removedUserIds = $util.emptyArray;

        /**
         * Creates a new LocationEnquiryDelta instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {proto.ILocationEnquiryDelta=} [properties] Properties to set
         * @returns {proto.LocationEnquiryDelta} LocationEnquiryDelta instance
         */
        LocationEnquiryDelta.create = function create(properties) {
            return new LocationEnquiryDelta(properties);
        };

        /**
         * Encodes the specified LocationEnquiryDelta message. Does not implicitly {@link proto.LocationEnquiryDelta.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {proto.ILocationEnquiryDelta} message LocationEnquiryDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryDelta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.updatedLocations != null && message.updatedLocations.length)
                for (var i = 0; i < message.updatedLocations.length; ++i)
                    $root.proto.LocationEnquiryLocationUpdate.encode(message.updatedLocations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.removedUserIds != null && message.removedUserIds.length)
                for (var i = 0; i < message.removedUserIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.removedUserIds[i]);
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryDelta message, length delimited. Does not implicitly {@link proto.LocationEnquiryDelta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {proto.ILocationEnquiryDelta} message LocationEnquiryDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryDelta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryDelta message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryDelta} LocationEnquiryDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryDelta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryDelta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.updatedLocations && message.updatedLocations.length))
                            message.updatedLocations = [];
                        message.updatedLocations.push($root.proto.LocationEnquiryLocationUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.removedUserIds && message.removedUserIds.length))
                            message.removedUserIds = [];
                        message.removedUserIds.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquiryDelta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryDelta} LocationEnquiryDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryDelta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryDelta message.
         * @function verify
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryDelta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.updatedLocations != null && message.hasOwnProperty("updatedLocations")) {
                if (!Array.isArray(message.updatedLocations))
                    return "updatedLocations: array expected";
                for (var i = 0; i < message.updatedLocations.length; ++i) {
                    var error = $root.proto.LocationEnquiryLocationUpdate.verify(message.updatedLocations[i]);
                    if (error)
                        return "updatedLocations." + error;
                }
            }
            if (message.removedUserIds != null && message.hasOwnProperty("removedUserIds")) {
                if (!Array.isArray(message.removedUserIds))
                    return "removedUserIds: array expected";
                for (var i = 0; i < message.removedUserIds.length; ++i)
                    if (!$util.isString(message.removedUserIds[i]))
                        return "removedUserIds: string[] expected";
            }
            return null;
        };

        /**
         * Creates a LocationEnquiryDelta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryDelta} LocationEnquiryDelta
         */
        LocationEnquiryDelta.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryDelta)
                return object;
            var message = new $root.proto.LocationEnquiryDelta();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.updatedLocations) {
                if (!Array.isArray(object.updatedLocations))
                    throw TypeError(".proto.LocationEnquiryDelta.updatedLocations: array expected");
                message.updatedLocations = [];
                for (var i = 0; i < object.updatedLocations.length; ++i) {
                    if (typeof object.updatedLocations[i] !== "object")
                        throw TypeError(".proto.LocationEnquiryDelta.updatedLocations: object expected");
                    message.updatedLocations[i] = $root.proto.LocationEnquiryLocationUpdate.fromObject(object.updatedLocations[i]);
                }
            }
            if (object.removedUserIds) {
                if (!Array.isArray(object.removedUserIds))
                    throw TypeError(".proto.LocationEnquiryDelta.removedUserIds: array expected");
                message.removedUserIds = [];
                for (var i = 0; i < object.removedUserIds.length; ++i)
                    message.removedUserIds[i] = String(object.removedUserIds[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryDelta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {proto.LocationEnquiryDelta} message LocationEnquiryDelta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryDelta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.updatedLocations = [];
                object.removedUserIds = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.updatedLocations && message.updatedLocations.length) {
                object.updatedLocations = [];
                for (var j = 0; j < message.updatedLocations.length; ++j)
                    object.updatedLocations[j] = $root.proto.LocationEnquiryLocationUpdate.toObject(message.updatedLocations[j], options);
            }
            if (message.removedUserIds && message.removedUserIds.length) {
                object.removedUserIds = [];
                for (var j = 0; j < message.removedUserIds.length; ++j)
                    object.removedUserIds[j] = message.removedUserIds[j];
            }
            return object;
        };

        /**
         * Converts this LocationEnquiryDelta to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryDelta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryDelta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryDelta
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryDelta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryDelta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryDelta";
        };

        return LocationEnquiryDelta;
    })();

    proto.LocationEnquiryLocationUpdate = (function() {

        /**
         * Properties of a LocationEnquiryLocationUpdate.
         * @memberof proto
         * @interface ILocationEnquiryLocationUpdate
         * @property {string} userId LocationEnquiryLocationUpdate userId
         * @property {proto.ILocationEnquiryUserInfo|null} [info] LocationEnquiryLocationUpdate info
         * @property {proto.ILocation|null} [location] LocationEnquiryLocationUpdate location
         * @property {boolean|null} [online] LocationEnquiryLocationUpdate online
         */

        /**
         * Constructs a new LocationEnquiryLocationUpdate.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryLocationUpdate.
         * @implements ILocationEnquiryLocationUpdate
         * @constructor
         * @param {proto.ILocationEnquiryLocationUpdate=} [properties] Properties to set
         */
        function LocationEnquiryLocationUpdate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryLocationUpdate userId.
         * @member {string} userId
         * @memberof proto.LocationEnquiryLocationUpdate
         * @instance
         */
        LocationEnquiryLocationUpdate.prototype.userId = "";

        /**
         * LocationEnquiryLocationUpdate info.
         * @member {proto.ILocationEnquiryUserInfo|null|undefined} info
         * @memberof proto.LocationEnquiryLocationUpdate
         * @instance
         */
        LocationEnquiryLocationUpdate.prototype.info = null;

        /**
         * LocationEnquiryLocationUpdate location.
         * @member {proto.ILocation|null|undefined} location
         * @memberof proto.LocationEnquiryLocationUpdate
         * @instance
         */
        LocationEnquiryLocationUpdate.prototype.location = null;

        /**
         * LocationEnquiryLocationUpdate online.
         * @member {boolean} online
         * @memberof proto.LocationEnquiryLocationUpdate
         * @instance
         */
        LocationEnquiryLocationUpdate.prototype.online = false;

        /**
         * Creates a new LocationEnquiryLocationUpdate instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {proto.ILocationEnquiryLocationUpdate=} [properties] Properties to set
         * @returns {proto.LocationEnquiryLocationUpdate} LocationEnquiryLocationUpdate instance
         */
        LocationEnquiryLocationUpdate.create = function create(properties) {
            return new LocationEnquiryLocationUpdate(properties);
        };

        /**
         * Encodes the specified LocationEnquiryLocationUpdate message. Does not implicitly {@link proto.LocationEnquiryLocationUpdate.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {proto.ILocationEnquiryLocationUpdate} message LocationEnquiryLocationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryLocationUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.proto.LocationEnquiryUserInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.proto.Location.encode(message.location, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.online);
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryLocationUpdate message, length delimited. Does not implicitly {@link proto.LocationEnquiryLocationUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {proto.ILocationEnquiryLocationUpdate} message LocationEnquiryLocationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryLocationUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryLocationUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryLocationUpdate} LocationEnquiryLocationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryLocationUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryLocationUpdate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userId = reader.string();
                        break;
                    }
                case 2: {
                        message.info = $root.proto.LocationEnquiryUserInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.location = $root.proto.Location.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.online = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("userId"))
                throw $util.ProtocolError("missing required 'userId'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquiryLocationUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryLocationUpdate} LocationEnquiryLocationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryLocationUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryLocationUpdate message.
         * @function verify
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryLocationUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.userId))
                return "userId: string expected";
            if (message.info != null && message.hasOwnProperty("info")) {
                var error = $root.proto.LocationEnquiryUserInfo.verify(message.info);
                if (error)
                    return "info." + error;
            }
            if (message.location != null && message.hasOwnProperty("location")) {
                var error = $root.proto.Location.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                if (typeof message.online !== "boolean")
                    return "online: boolean expected";
            return null;
        };

        /**
         * Creates a LocationEnquiryLocationUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryLocationUpdate} LocationEnquiryLocationUpdate
         */
        LocationEnquiryLocationUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryLocationUpdate)
                return object;
            var message = new $root.proto.LocationEnquiryLocationUpdate();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.info != null) {
                if (typeof object.info !== "object")
                    throw TypeError(".proto.LocationEnquiryLocationUpdate.info: object expected");
                message.info = $root.proto.LocationEnquiryUserInfo.fromObject(object.info);
            }
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".proto.LocationEnquiryLocationUpdate.location: object expected");
                message.location = $root.proto.Location.fromObject(object.location);
            }
            if (object.online != null)
                message.online = Boolean(object.online);
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryLocationUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {proto.LocationEnquiryLocationUpdate} message LocationEnquiryLocationUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryLocationUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userId = "";
                object.info = null;
                object.location = null;
                object.online = false;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = $root.proto.LocationEnquiryUserInfo.toObject(message.info, options);
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.proto.Location.toObject(message.location, options);
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            return object;
        };

        /**
         * Converts this LocationEnquiryLocationUpdate to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryLocationUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryLocationUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryLocationUpdate
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryLocationUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryLocationUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryLocationUpdate";
        };

        return LocationEnquiryLocationUpdate;
    })();

    proto.LocationEnquiryUserInfo = (function() {

        /**
         * Properties of a LocationEnquiryUserInfo.
         * @memberof proto
         * @interface ILocationEnquiryUserInfo
         * @property {string} name LocationEnquiryUserInfo name
         * @property {string|null} [title] LocationEnquiryUserInfo title
         * @property {string|null} [userEntityId] LocationEnquiryUserInfo userEntityId
         * @property {string|null} [userUuid] LocationEnquiryUserInfo userUuid
         */

        /**
         * Constructs a new LocationEnquiryUserInfo.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryUserInfo.
         * @implements ILocationEnquiryUserInfo
         * @constructor
         * @param {proto.ILocationEnquiryUserInfo=} [properties] Properties to set
         */
        function LocationEnquiryUserInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryUserInfo name.
         * @member {string} name
         * @memberof proto.LocationEnquiryUserInfo
         * @instance
         */
        LocationEnquiryUserInfo.prototype.name = "";

        /**
         * LocationEnquiryUserInfo title.
         * @member {string} title
         * @memberof proto.LocationEnquiryUserInfo
         * @instance
         */
        LocationEnquiryUserInfo.prototype.title = "";

        /**
         * LocationEnquiryUserInfo userEntityId.
         * @member {string} userEntityId
         * @memberof proto.LocationEnquiryUserInfo
         * @instance
         */
        LocationEnquiryUserInfo.prototype.userEntityId = "";

        /**
         * LocationEnquiryUserInfo userUuid.
         * @member {string} userUuid
         * @memberof proto.LocationEnquiryUserInfo
         * @instance
         */
        LocationEnquiryUserInfo.prototype.userUuid = "";

        /**
         * Creates a new LocationEnquiryUserInfo instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {proto.ILocationEnquiryUserInfo=} [properties] Properties to set
         * @returns {proto.LocationEnquiryUserInfo} LocationEnquiryUserInfo instance
         */
        LocationEnquiryUserInfo.create = function create(properties) {
            return new LocationEnquiryUserInfo(properties);
        };

        /**
         * Encodes the specified LocationEnquiryUserInfo message. Does not implicitly {@link proto.LocationEnquiryUserInfo.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {proto.ILocationEnquiryUserInfo} message LocationEnquiryUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryUserInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.userEntityId != null && Object.hasOwnProperty.call(message, "userEntityId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userEntityId);
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.userUuid);
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryUserInfo message, length delimited. Does not implicitly {@link proto.LocationEnquiryUserInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {proto.ILocationEnquiryUserInfo} message LocationEnquiryUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryUserInfo} LocationEnquiryUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryUserInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryUserInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.userEntityId = reader.string();
                        break;
                    }
                case 4: {
                        message.userUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationEnquiryUserInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryUserInfo} LocationEnquiryUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryUserInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryUserInfo message.
         * @function verify
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryUserInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.userEntityId != null && message.hasOwnProperty("userEntityId"))
                if (!$util.isString(message.userEntityId))
                    return "userEntityId: string expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            return null;
        };

        /**
         * Creates a LocationEnquiryUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryUserInfo} LocationEnquiryUserInfo
         */
        LocationEnquiryUserInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryUserInfo)
                return object;
            var message = new $root.proto.LocationEnquiryUserInfo();
            if (object.name != null)
                message.name = String(object.name);
            if (object.title != null)
                message.title = String(object.title);
            if (object.userEntityId != null)
                message.userEntityId = String(object.userEntityId);
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {proto.LocationEnquiryUserInfo} message LocationEnquiryUserInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryUserInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.title = "";
                object.userEntityId = "";
                object.userUuid = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.userEntityId != null && message.hasOwnProperty("userEntityId"))
                object.userEntityId = message.userEntityId;
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            return object;
        };

        /**
         * Converts this LocationEnquiryUserInfo to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryUserInfo
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryUserInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryUserInfo";
        };

        return LocationEnquiryUserInfo;
    })();

    proto.LocationEnquiryAPIv1Server = (function() {

        /**
         * Properties of a LocationEnquiryAPIv1Server.
         * @memberof proto
         * @interface ILocationEnquiryAPIv1Server
         * @property {proto.ILocationEnquiryDelta|null} [enquiryDelta] LocationEnquiryAPIv1Server enquiryDelta
         */

        /**
         * Constructs a new LocationEnquiryAPIv1Server.
         * @memberof proto
         * @classdesc Represents a LocationEnquiryAPIv1Server.
         * @implements ILocationEnquiryAPIv1Server
         * @constructor
         * @param {proto.ILocationEnquiryAPIv1Server=} [properties] Properties to set
         */
        function LocationEnquiryAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationEnquiryAPIv1Server enquiryDelta.
         * @member {proto.ILocationEnquiryDelta|null|undefined} enquiryDelta
         * @memberof proto.LocationEnquiryAPIv1Server
         * @instance
         */
        LocationEnquiryAPIv1Server.prototype.enquiryDelta = null;

        /**
         * Creates a new LocationEnquiryAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {proto.ILocationEnquiryAPIv1Server=} [properties] Properties to set
         * @returns {proto.LocationEnquiryAPIv1Server} LocationEnquiryAPIv1Server instance
         */
        LocationEnquiryAPIv1Server.create = function create(properties) {
            return new LocationEnquiryAPIv1Server(properties);
        };

        /**
         * Encodes the specified LocationEnquiryAPIv1Server message. Does not implicitly {@link proto.LocationEnquiryAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {proto.ILocationEnquiryAPIv1Server} message LocationEnquiryAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.enquiryDelta != null && Object.hasOwnProperty.call(message, "enquiryDelta"))
                $root.proto.LocationEnquiryDelta.encode(message.enquiryDelta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LocationEnquiryAPIv1Server message, length delimited. Does not implicitly {@link proto.LocationEnquiryAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {proto.ILocationEnquiryAPIv1Server} message LocationEnquiryAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationEnquiryAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationEnquiryAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationEnquiryAPIv1Server} LocationEnquiryAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationEnquiryAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.enquiryDelta = $root.proto.LocationEnquiryDelta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LocationEnquiryAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationEnquiryAPIv1Server} LocationEnquiryAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationEnquiryAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationEnquiryAPIv1Server message.
         * @function verify
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationEnquiryAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.enquiryDelta != null && message.hasOwnProperty("enquiryDelta")) {
                var error = $root.proto.LocationEnquiryDelta.verify(message.enquiryDelta);
                if (error)
                    return "enquiryDelta." + error;
            }
            return null;
        };

        /**
         * Creates a LocationEnquiryAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationEnquiryAPIv1Server} LocationEnquiryAPIv1Server
         */
        LocationEnquiryAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationEnquiryAPIv1Server)
                return object;
            var message = new $root.proto.LocationEnquiryAPIv1Server();
            if (object.enquiryDelta != null) {
                if (typeof object.enquiryDelta !== "object")
                    throw TypeError(".proto.LocationEnquiryAPIv1Server.enquiryDelta: object expected");
                message.enquiryDelta = $root.proto.LocationEnquiryDelta.fromObject(object.enquiryDelta);
            }
            return message;
        };

        /**
         * Creates a plain object from a LocationEnquiryAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {proto.LocationEnquiryAPIv1Server} message LocationEnquiryAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationEnquiryAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.enquiryDelta = null;
            if (message.enquiryDelta != null && message.hasOwnProperty("enquiryDelta"))
                object.enquiryDelta = $root.proto.LocationEnquiryDelta.toObject(message.enquiryDelta, options);
            return object;
        };

        /**
         * Converts this LocationEnquiryAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.LocationEnquiryAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationEnquiryAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationEnquiryAPIv1Server
         * @function getTypeUrl
         * @memberof proto.LocationEnquiryAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationEnquiryAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationEnquiryAPIv1Server";
        };

        return LocationEnquiryAPIv1Server;
    })();

    proto.LocationRange = (function() {

        /**
         * Properties of a LocationRange.
         * @memberof proto
         * @interface ILocationRange
         * @property {number} southWestLongitude LocationRange southWestLongitude
         * @property {number} southWestLatitude LocationRange southWestLatitude
         * @property {number} northEastLongitude LocationRange northEastLongitude
         * @property {number} northEastLatitude LocationRange northEastLatitude
         */

        /**
         * Constructs a new LocationRange.
         * @memberof proto
         * @classdesc Represents a LocationRange.
         * @implements ILocationRange
         * @constructor
         * @param {proto.ILocationRange=} [properties] Properties to set
         */
        function LocationRange(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocationRange southWestLongitude.
         * @member {number} southWestLongitude
         * @memberof proto.LocationRange
         * @instance
         */
        LocationRange.prototype.southWestLongitude = 0;

        /**
         * LocationRange southWestLatitude.
         * @member {number} southWestLatitude
         * @memberof proto.LocationRange
         * @instance
         */
        LocationRange.prototype.southWestLatitude = 0;

        /**
         * LocationRange northEastLongitude.
         * @member {number} northEastLongitude
         * @memberof proto.LocationRange
         * @instance
         */
        LocationRange.prototype.northEastLongitude = 0;

        /**
         * LocationRange northEastLatitude.
         * @member {number} northEastLatitude
         * @memberof proto.LocationRange
         * @instance
         */
        LocationRange.prototype.northEastLatitude = 0;

        /**
         * Creates a new LocationRange instance using the specified properties.
         * @function create
         * @memberof proto.LocationRange
         * @static
         * @param {proto.ILocationRange=} [properties] Properties to set
         * @returns {proto.LocationRange} LocationRange instance
         */
        LocationRange.create = function create(properties) {
            return new LocationRange(properties);
        };

        /**
         * Encodes the specified LocationRange message. Does not implicitly {@link proto.LocationRange.verify|verify} messages.
         * @function encode
         * @memberof proto.LocationRange
         * @static
         * @param {proto.ILocationRange} message LocationRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationRange.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 1 =*/9).double(message.southWestLongitude);
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.southWestLatitude);
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.northEastLongitude);
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.northEastLatitude);
            return writer;
        };

        /**
         * Encodes the specified LocationRange message, length delimited. Does not implicitly {@link proto.LocationRange.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.LocationRange
         * @static
         * @param {proto.ILocationRange} message LocationRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LocationRange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LocationRange message from the specified reader or buffer.
         * @function decode
         * @memberof proto.LocationRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.LocationRange} LocationRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationRange.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.LocationRange();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.southWestLongitude = reader.double();
                        break;
                    }
                case 2: {
                        message.southWestLatitude = reader.double();
                        break;
                    }
                case 3: {
                        message.northEastLongitude = reader.double();
                        break;
                    }
                case 4: {
                        message.northEastLatitude = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("southWestLongitude"))
                throw $util.ProtocolError("missing required 'southWestLongitude'", { instance: message });
            if (!message.hasOwnProperty("southWestLatitude"))
                throw $util.ProtocolError("missing required 'southWestLatitude'", { instance: message });
            if (!message.hasOwnProperty("northEastLongitude"))
                throw $util.ProtocolError("missing required 'northEastLongitude'", { instance: message });
            if (!message.hasOwnProperty("northEastLatitude"))
                throw $util.ProtocolError("missing required 'northEastLatitude'", { instance: message });
            return message;
        };

        /**
         * Decodes a LocationRange message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.LocationRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.LocationRange} LocationRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LocationRange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LocationRange message.
         * @function verify
         * @memberof proto.LocationRange
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LocationRange.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.southWestLongitude !== "number")
                return "southWestLongitude: number expected";
            if (typeof message.southWestLatitude !== "number")
                return "southWestLatitude: number expected";
            if (typeof message.northEastLongitude !== "number")
                return "northEastLongitude: number expected";
            if (typeof message.northEastLatitude !== "number")
                return "northEastLatitude: number expected";
            return null;
        };

        /**
         * Creates a LocationRange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.LocationRange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.LocationRange} LocationRange
         */
        LocationRange.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.LocationRange)
                return object;
            var message = new $root.proto.LocationRange();
            if (object.southWestLongitude != null)
                message.southWestLongitude = Number(object.southWestLongitude);
            if (object.southWestLatitude != null)
                message.southWestLatitude = Number(object.southWestLatitude);
            if (object.northEastLongitude != null)
                message.northEastLongitude = Number(object.northEastLongitude);
            if (object.northEastLatitude != null)
                message.northEastLatitude = Number(object.northEastLatitude);
            return message;
        };

        /**
         * Creates a plain object from a LocationRange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.LocationRange
         * @static
         * @param {proto.LocationRange} message LocationRange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LocationRange.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.southWestLongitude = 0;
                object.southWestLatitude = 0;
                object.northEastLongitude = 0;
                object.northEastLatitude = 0;
            }
            if (message.southWestLongitude != null && message.hasOwnProperty("southWestLongitude"))
                object.southWestLongitude = options.json && !isFinite(message.southWestLongitude) ? String(message.southWestLongitude) : message.southWestLongitude;
            if (message.southWestLatitude != null && message.hasOwnProperty("southWestLatitude"))
                object.southWestLatitude = options.json && !isFinite(message.southWestLatitude) ? String(message.southWestLatitude) : message.southWestLatitude;
            if (message.northEastLongitude != null && message.hasOwnProperty("northEastLongitude"))
                object.northEastLongitude = options.json && !isFinite(message.northEastLongitude) ? String(message.northEastLongitude) : message.northEastLongitude;
            if (message.northEastLatitude != null && message.hasOwnProperty("northEastLatitude"))
                object.northEastLatitude = options.json && !isFinite(message.northEastLatitude) ? String(message.northEastLatitude) : message.northEastLatitude;
            return object;
        };

        /**
         * Converts this LocationRange to JSON.
         * @function toJSON
         * @memberof proto.LocationRange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LocationRange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LocationRange
         * @function getTypeUrl
         * @memberof proto.LocationRange
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocationRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.LocationRange";
        };

        return LocationRange;
    })();

    proto.AudioAPIv1Client = (function() {

        /**
         * Properties of an AudioAPIv1Client.
         * @memberof proto
         * @interface IAudioAPIv1Client
         * @property {proto.IAudioModuleSetupRequest|null} [setupRequest] AudioAPIv1Client setupRequest
         * @property {proto.IAudioData|null} [data] AudioAPIv1Client data
         */

        /**
         * Constructs a new AudioAPIv1Client.
         * @memberof proto
         * @classdesc Represents an AudioAPIv1Client.
         * @implements IAudioAPIv1Client
         * @constructor
         * @param {proto.IAudioAPIv1Client=} [properties] Properties to set
         */
        function AudioAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AudioAPIv1Client setupRequest.
         * @member {proto.IAudioModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.AudioAPIv1Client
         * @instance
         */
        AudioAPIv1Client.prototype.setupRequest = null;

        /**
         * AudioAPIv1Client data.
         * @member {proto.IAudioData|null|undefined} data
         * @memberof proto.AudioAPIv1Client
         * @instance
         */
        AudioAPIv1Client.prototype.data = null;

        /**
         * Creates a new AudioAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {proto.IAudioAPIv1Client=} [properties] Properties to set
         * @returns {proto.AudioAPIv1Client} AudioAPIv1Client instance
         */
        AudioAPIv1Client.create = function create(properties) {
            return new AudioAPIv1Client(properties);
        };

        /**
         * Encodes the specified AudioAPIv1Client message. Does not implicitly {@link proto.AudioAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {proto.IAudioAPIv1Client} message AudioAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.AudioModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.proto.AudioData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AudioAPIv1Client message, length delimited. Does not implicitly {@link proto.AudioAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {proto.IAudioAPIv1Client} message AudioAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AudioAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AudioAPIv1Client} AudioAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AudioAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.AudioModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.data = $root.proto.AudioData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AudioAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AudioAPIv1Client} AudioAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AudioAPIv1Client message.
         * @function verify
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AudioAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.AudioModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.data != null && message.hasOwnProperty("data")) {
                var error = $root.proto.AudioData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            return null;
        };

        /**
         * Creates an AudioAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AudioAPIv1Client} AudioAPIv1Client
         */
        AudioAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AudioAPIv1Client)
                return object;
            var message = new $root.proto.AudioAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.AudioAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.AudioModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".proto.AudioAPIv1Client.data: object expected");
                message.data = $root.proto.AudioData.fromObject(object.data);
            }
            return message;
        };

        /**
         * Creates a plain object from an AudioAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {proto.AudioAPIv1Client} message AudioAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.data = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.AudioModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.proto.AudioData.toObject(message.data, options);
            return object;
        };

        /**
         * Converts this AudioAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.AudioAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioAPIv1Client
         * @function getTypeUrl
         * @memberof proto.AudioAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AudioAPIv1Client";
        };

        return AudioAPIv1Client;
    })();

    proto.AudioModuleSetupRequest = (function() {

        /**
         * Properties of an AudioModuleSetupRequest.
         * @memberof proto
         * @interface IAudioModuleSetupRequest
         */

        /**
         * Constructs a new AudioModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents an AudioModuleSetupRequest.
         * @implements IAudioModuleSetupRequest
         * @constructor
         * @param {proto.IAudioModuleSetupRequest=} [properties] Properties to set
         */
        function AudioModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AudioModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {proto.IAudioModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.AudioModuleSetupRequest} AudioModuleSetupRequest instance
         */
        AudioModuleSetupRequest.create = function create(properties) {
            return new AudioModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified AudioModuleSetupRequest message. Does not implicitly {@link proto.AudioModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {proto.IAudioModuleSetupRequest} message AudioModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AudioModuleSetupRequest message, length delimited. Does not implicitly {@link proto.AudioModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {proto.IAudioModuleSetupRequest} message AudioModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AudioModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AudioModuleSetupRequest} AudioModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AudioModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AudioModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AudioModuleSetupRequest} AudioModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AudioModuleSetupRequest message.
         * @function verify
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AudioModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AudioModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AudioModuleSetupRequest} AudioModuleSetupRequest
         */
        AudioModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AudioModuleSetupRequest)
                return object;
            return new $root.proto.AudioModuleSetupRequest();
        };

        /**
         * Creates a plain object from an AudioModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {proto.AudioModuleSetupRequest} message AudioModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AudioModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.AudioModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.AudioModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AudioModuleSetupRequest";
        };

        return AudioModuleSetupRequest;
    })();

    proto.AudioData = (function() {

        /**
         * Properties of an AudioData.
         * @memberof proto
         * @interface IAudioData
         * @property {number|Long} talkburstId AudioData talkburstId
         * @property {number} seqNo AudioData seqNo
         * @property {Uint8Array} audio AudioData audio
         * @property {number|null} [codec] AudioData codec
         */

        /**
         * Constructs a new AudioData.
         * @memberof proto
         * @classdesc Represents an AudioData.
         * @implements IAudioData
         * @constructor
         * @param {proto.IAudioData=} [properties] Properties to set
         */
        function AudioData(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AudioData talkburstId.
         * @member {number|Long} talkburstId
         * @memberof proto.AudioData
         * @instance
         */
        AudioData.prototype.talkburstId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AudioData seqNo.
         * @member {number} seqNo
         * @memberof proto.AudioData
         * @instance
         */
        AudioData.prototype.seqNo = 0;

        /**
         * AudioData audio.
         * @member {Uint8Array} audio
         * @memberof proto.AudioData
         * @instance
         */
        AudioData.prototype.audio = $util.newBuffer([]);

        /**
         * AudioData codec.
         * @member {number} codec
         * @memberof proto.AudioData
         * @instance
         */
        AudioData.prototype.codec = 1;

        /**
         * Creates a new AudioData instance using the specified properties.
         * @function create
         * @memberof proto.AudioData
         * @static
         * @param {proto.IAudioData=} [properties] Properties to set
         * @returns {proto.AudioData} AudioData instance
         */
        AudioData.create = function create(properties) {
            return new AudioData(properties);
        };

        /**
         * Encodes the specified AudioData message. Does not implicitly {@link proto.AudioData.verify|verify} messages.
         * @function encode
         * @memberof proto.AudioData
         * @static
         * @param {proto.IAudioData} message AudioData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.talkburstId);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.seqNo);
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.audio);
            if (message.codec != null && Object.hasOwnProperty.call(message, "codec"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.codec);
            return writer;
        };

        /**
         * Encodes the specified AudioData message, length delimited. Does not implicitly {@link proto.AudioData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AudioData
         * @static
         * @param {proto.IAudioData} message AudioData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AudioData message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AudioData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AudioData} AudioData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AudioData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.talkburstId = reader.int64();
                        break;
                    }
                case 2: {
                        message.seqNo = reader.int32();
                        break;
                    }
                case 3: {
                        message.audio = reader.bytes();
                        break;
                    }
                case 4: {
                        message.codec = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("talkburstId"))
                throw $util.ProtocolError("missing required 'talkburstId'", { instance: message });
            if (!message.hasOwnProperty("seqNo"))
                throw $util.ProtocolError("missing required 'seqNo'", { instance: message });
            if (!message.hasOwnProperty("audio"))
                throw $util.ProtocolError("missing required 'audio'", { instance: message });
            return message;
        };

        /**
         * Decodes an AudioData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AudioData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AudioData} AudioData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AudioData message.
         * @function verify
         * @memberof proto.AudioData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AudioData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.talkburstId) && !(message.talkburstId && $util.isInteger(message.talkburstId.low) && $util.isInteger(message.talkburstId.high)))
                return "talkburstId: integer|Long expected";
            if (!$util.isInteger(message.seqNo))
                return "seqNo: integer expected";
            if (!(message.audio && typeof message.audio.length === "number" || $util.isString(message.audio)))
                return "audio: buffer expected";
            if (message.codec != null && message.hasOwnProperty("codec"))
                if (!$util.isInteger(message.codec))
                    return "codec: integer expected";
            return null;
        };

        /**
         * Creates an AudioData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AudioData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AudioData} AudioData
         */
        AudioData.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AudioData)
                return object;
            var message = new $root.proto.AudioData();
            if (object.talkburstId != null)
                if ($util.Long)
                    (message.talkburstId = $util.Long.fromValue(object.talkburstId)).unsigned = false;
                else if (typeof object.talkburstId === "string")
                    message.talkburstId = parseInt(object.talkburstId, 10);
                else if (typeof object.talkburstId === "number")
                    message.talkburstId = object.talkburstId;
                else if (typeof object.talkburstId === "object")
                    message.talkburstId = new $util.LongBits(object.talkburstId.low >>> 0, object.talkburstId.high >>> 0).toNumber();
            if (object.seqNo != null)
                message.seqNo = object.seqNo | 0;
            if (object.audio != null)
                if (typeof object.audio === "string")
                    $util.base64.decode(object.audio, message.audio = $util.newBuffer($util.base64.length(object.audio)), 0);
                else if (object.audio.length >= 0)
                    message.audio = object.audio;
            if (object.codec != null)
                message.codec = object.codec | 0;
            return message;
        };

        /**
         * Creates a plain object from an AudioData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AudioData
         * @static
         * @param {proto.AudioData} message AudioData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.talkburstId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.talkburstId = options.longs === String ? "0" : 0;
                object.seqNo = 0;
                if (options.bytes === String)
                    object.audio = "";
                else {
                    object.audio = [];
                    if (options.bytes !== Array)
                        object.audio = $util.newBuffer(object.audio);
                }
                object.codec = 1;
            }
            if (message.talkburstId != null && message.hasOwnProperty("talkburstId"))
                if (typeof message.talkburstId === "number")
                    object.talkburstId = options.longs === String ? String(message.talkburstId) : message.talkburstId;
                else
                    object.talkburstId = options.longs === String ? $util.Long.prototype.toString.call(message.talkburstId) : options.longs === Number ? new $util.LongBits(message.talkburstId.low >>> 0, message.talkburstId.high >>> 0).toNumber() : message.talkburstId;
            if (message.seqNo != null && message.hasOwnProperty("seqNo"))
                object.seqNo = message.seqNo;
            if (message.audio != null && message.hasOwnProperty("audio"))
                object.audio = options.bytes === String ? $util.base64.encode(message.audio, 0, message.audio.length) : options.bytes === Array ? Array.prototype.slice.call(message.audio) : message.audio;
            if (message.codec != null && message.hasOwnProperty("codec"))
                object.codec = message.codec;
            return object;
        };

        /**
         * Converts this AudioData to JSON.
         * @function toJSON
         * @memberof proto.AudioData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioData
         * @function getTypeUrl
         * @memberof proto.AudioData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AudioData";
        };

        return AudioData;
    })();

    proto.AudioAPIv1Server = (function() {

        /**
         * Properties of an AudioAPIv1Server.
         * @memberof proto
         * @interface IAudioAPIv1Server
         * @property {proto.IAudioData|null} [data] AudioAPIv1Server data
         */

        /**
         * Constructs a new AudioAPIv1Server.
         * @memberof proto
         * @classdesc Represents an AudioAPIv1Server.
         * @implements IAudioAPIv1Server
         * @constructor
         * @param {proto.IAudioAPIv1Server=} [properties] Properties to set
         */
        function AudioAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AudioAPIv1Server data.
         * @member {proto.IAudioData|null|undefined} data
         * @memberof proto.AudioAPIv1Server
         * @instance
         */
        AudioAPIv1Server.prototype.data = null;

        /**
         * Creates a new AudioAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {proto.IAudioAPIv1Server=} [properties] Properties to set
         * @returns {proto.AudioAPIv1Server} AudioAPIv1Server instance
         */
        AudioAPIv1Server.create = function create(properties) {
            return new AudioAPIv1Server(properties);
        };

        /**
         * Encodes the specified AudioAPIv1Server message. Does not implicitly {@link proto.AudioAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {proto.IAudioAPIv1Server} message AudioAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.proto.AudioData.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AudioAPIv1Server message, length delimited. Does not implicitly {@link proto.AudioAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {proto.IAudioAPIv1Server} message AudioAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AudioAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AudioAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.AudioAPIv1Server} AudioAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.AudioAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = $root.proto.AudioData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AudioAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.AudioAPIv1Server} AudioAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AudioAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AudioAPIv1Server message.
         * @function verify
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AudioAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                var error = $root.proto.AudioData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            return null;
        };

        /**
         * Creates an AudioAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.AudioAPIv1Server} AudioAPIv1Server
         */
        AudioAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.AudioAPIv1Server)
                return object;
            var message = new $root.proto.AudioAPIv1Server();
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".proto.AudioAPIv1Server.data: object expected");
                message.data = $root.proto.AudioData.fromObject(object.data);
            }
            return message;
        };

        /**
         * Creates a plain object from an AudioAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {proto.AudioAPIv1Server} message AudioAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AudioAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.data = null;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.proto.AudioData.toObject(message.data, options);
            return object;
        };

        /**
         * Converts this AudioAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.AudioAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AudioAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AudioAPIv1Server
         * @function getTypeUrl
         * @memberof proto.AudioAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AudioAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.AudioAPIv1Server";
        };

        return AudioAPIv1Server;
    })();

    proto.ThirdPartyCallControlAPIv1Client = (function() {

        /**
         * Properties of a ThirdPartyCallControlAPIv1Client.
         * @memberof proto
         * @interface IThirdPartyCallControlAPIv1Client
         * @property {proto.IThirdPartyCallControlModuleSetupRequest|null} [setupRequest] ThirdPartyCallControlAPIv1Client setupRequest
         */

        /**
         * Constructs a new ThirdPartyCallControlAPIv1Client.
         * @memberof proto
         * @classdesc Represents a ThirdPartyCallControlAPIv1Client.
         * @implements IThirdPartyCallControlAPIv1Client
         * @constructor
         * @param {proto.IThirdPartyCallControlAPIv1Client=} [properties] Properties to set
         */
        function ThirdPartyCallControlAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ThirdPartyCallControlAPIv1Client setupRequest.
         * @member {proto.IThirdPartyCallControlModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @instance
         */
        ThirdPartyCallControlAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new ThirdPartyCallControlAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {proto.IThirdPartyCallControlAPIv1Client=} [properties] Properties to set
         * @returns {proto.ThirdPartyCallControlAPIv1Client} ThirdPartyCallControlAPIv1Client instance
         */
        ThirdPartyCallControlAPIv1Client.create = function create(properties) {
            return new ThirdPartyCallControlAPIv1Client(properties);
        };

        /**
         * Encodes the specified ThirdPartyCallControlAPIv1Client message. Does not implicitly {@link proto.ThirdPartyCallControlAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {proto.IThirdPartyCallControlAPIv1Client} message ThirdPartyCallControlAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.ThirdPartyCallControlModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ThirdPartyCallControlAPIv1Client message, length delimited. Does not implicitly {@link proto.ThirdPartyCallControlAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {proto.IThirdPartyCallControlAPIv1Client} message ThirdPartyCallControlAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ThirdPartyCallControlAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ThirdPartyCallControlAPIv1Client} ThirdPartyCallControlAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ThirdPartyCallControlAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.ThirdPartyCallControlModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ThirdPartyCallControlAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ThirdPartyCallControlAPIv1Client} ThirdPartyCallControlAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ThirdPartyCallControlAPIv1Client message.
         * @function verify
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ThirdPartyCallControlAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.ThirdPartyCallControlModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a ThirdPartyCallControlAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ThirdPartyCallControlAPIv1Client} ThirdPartyCallControlAPIv1Client
         */
        ThirdPartyCallControlAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ThirdPartyCallControlAPIv1Client)
                return object;
            var message = new $root.proto.ThirdPartyCallControlAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.ThirdPartyCallControlAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.ThirdPartyCallControlModuleSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a ThirdPartyCallControlAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {proto.ThirdPartyCallControlAPIv1Client} message ThirdPartyCallControlAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThirdPartyCallControlAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.ThirdPartyCallControlModuleSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this ThirdPartyCallControlAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThirdPartyCallControlAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ThirdPartyCallControlAPIv1Client
         * @function getTypeUrl
         * @memberof proto.ThirdPartyCallControlAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ThirdPartyCallControlAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ThirdPartyCallControlAPIv1Client";
        };

        return ThirdPartyCallControlAPIv1Client;
    })();

    proto.ThirdPartyCallControlModuleSetupRequest = (function() {

        /**
         * Properties of a ThirdPartyCallControlModuleSetupRequest.
         * @memberof proto
         * @interface IThirdPartyCallControlModuleSetupRequest
         */

        /**
         * Constructs a new ThirdPartyCallControlModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a ThirdPartyCallControlModuleSetupRequest.
         * @implements IThirdPartyCallControlModuleSetupRequest
         * @constructor
         * @param {proto.IThirdPartyCallControlModuleSetupRequest=} [properties] Properties to set
         */
        function ThirdPartyCallControlModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ThirdPartyCallControlModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {proto.IThirdPartyCallControlModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.ThirdPartyCallControlModuleSetupRequest} ThirdPartyCallControlModuleSetupRequest instance
         */
        ThirdPartyCallControlModuleSetupRequest.create = function create(properties) {
            return new ThirdPartyCallControlModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified ThirdPartyCallControlModuleSetupRequest message. Does not implicitly {@link proto.ThirdPartyCallControlModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {proto.IThirdPartyCallControlModuleSetupRequest} message ThirdPartyCallControlModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ThirdPartyCallControlModuleSetupRequest message, length delimited. Does not implicitly {@link proto.ThirdPartyCallControlModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {proto.IThirdPartyCallControlModuleSetupRequest} message ThirdPartyCallControlModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ThirdPartyCallControlModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ThirdPartyCallControlModuleSetupRequest} ThirdPartyCallControlModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ThirdPartyCallControlModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ThirdPartyCallControlModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ThirdPartyCallControlModuleSetupRequest} ThirdPartyCallControlModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ThirdPartyCallControlModuleSetupRequest message.
         * @function verify
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ThirdPartyCallControlModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ThirdPartyCallControlModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ThirdPartyCallControlModuleSetupRequest} ThirdPartyCallControlModuleSetupRequest
         */
        ThirdPartyCallControlModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ThirdPartyCallControlModuleSetupRequest)
                return object;
            return new $root.proto.ThirdPartyCallControlModuleSetupRequest();
        };

        /**
         * Creates a plain object from a ThirdPartyCallControlModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {proto.ThirdPartyCallControlModuleSetupRequest} message ThirdPartyCallControlModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThirdPartyCallControlModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ThirdPartyCallControlModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThirdPartyCallControlModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ThirdPartyCallControlModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.ThirdPartyCallControlModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ThirdPartyCallControlModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ThirdPartyCallControlModuleSetupRequest";
        };

        return ThirdPartyCallControlModuleSetupRequest;
    })();

    proto.ThirdPartyCallControlAPIv1Server = (function() {

        /**
         * Properties of a ThirdPartyCallControlAPIv1Server.
         * @memberof proto
         * @interface IThirdPartyCallControlAPIv1Server
         * @property {proto.IThirdPartyCallControlInitiateCall|null} [initiateCall] ThirdPartyCallControlAPIv1Server initiateCall
         * @property {proto.IThirdPartyCallControlTerminateCall|null} [terminateCall] ThirdPartyCallControlAPIv1Server terminateCall
         */

        /**
         * Constructs a new ThirdPartyCallControlAPIv1Server.
         * @memberof proto
         * @classdesc Represents a ThirdPartyCallControlAPIv1Server.
         * @implements IThirdPartyCallControlAPIv1Server
         * @constructor
         * @param {proto.IThirdPartyCallControlAPIv1Server=} [properties] Properties to set
         */
        function ThirdPartyCallControlAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ThirdPartyCallControlAPIv1Server initiateCall.
         * @member {proto.IThirdPartyCallControlInitiateCall|null|undefined} initiateCall
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @instance
         */
        ThirdPartyCallControlAPIv1Server.prototype.initiateCall = null;

        /**
         * ThirdPartyCallControlAPIv1Server terminateCall.
         * @member {proto.IThirdPartyCallControlTerminateCall|null|undefined} terminateCall
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @instance
         */
        ThirdPartyCallControlAPIv1Server.prototype.terminateCall = null;

        /**
         * Creates a new ThirdPartyCallControlAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {proto.IThirdPartyCallControlAPIv1Server=} [properties] Properties to set
         * @returns {proto.ThirdPartyCallControlAPIv1Server} ThirdPartyCallControlAPIv1Server instance
         */
        ThirdPartyCallControlAPIv1Server.create = function create(properties) {
            return new ThirdPartyCallControlAPIv1Server(properties);
        };

        /**
         * Encodes the specified ThirdPartyCallControlAPIv1Server message. Does not implicitly {@link proto.ThirdPartyCallControlAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {proto.IThirdPartyCallControlAPIv1Server} message ThirdPartyCallControlAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.initiateCall != null && Object.hasOwnProperty.call(message, "initiateCall"))
                $root.proto.ThirdPartyCallControlInitiateCall.encode(message.initiateCall, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.terminateCall != null && Object.hasOwnProperty.call(message, "terminateCall"))
                $root.proto.ThirdPartyCallControlTerminateCall.encode(message.terminateCall, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ThirdPartyCallControlAPIv1Server message, length delimited. Does not implicitly {@link proto.ThirdPartyCallControlAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {proto.IThirdPartyCallControlAPIv1Server} message ThirdPartyCallControlAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ThirdPartyCallControlAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ThirdPartyCallControlAPIv1Server} ThirdPartyCallControlAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ThirdPartyCallControlAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.initiateCall = $root.proto.ThirdPartyCallControlInitiateCall.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.terminateCall = $root.proto.ThirdPartyCallControlTerminateCall.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ThirdPartyCallControlAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ThirdPartyCallControlAPIv1Server} ThirdPartyCallControlAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ThirdPartyCallControlAPIv1Server message.
         * @function verify
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ThirdPartyCallControlAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.initiateCall != null && message.hasOwnProperty("initiateCall")) {
                var error = $root.proto.ThirdPartyCallControlInitiateCall.verify(message.initiateCall);
                if (error)
                    return "initiateCall." + error;
            }
            if (message.terminateCall != null && message.hasOwnProperty("terminateCall")) {
                var error = $root.proto.ThirdPartyCallControlTerminateCall.verify(message.terminateCall);
                if (error)
                    return "terminateCall." + error;
            }
            return null;
        };

        /**
         * Creates a ThirdPartyCallControlAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ThirdPartyCallControlAPIv1Server} ThirdPartyCallControlAPIv1Server
         */
        ThirdPartyCallControlAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ThirdPartyCallControlAPIv1Server)
                return object;
            var message = new $root.proto.ThirdPartyCallControlAPIv1Server();
            if (object.initiateCall != null) {
                if (typeof object.initiateCall !== "object")
                    throw TypeError(".proto.ThirdPartyCallControlAPIv1Server.initiateCall: object expected");
                message.initiateCall = $root.proto.ThirdPartyCallControlInitiateCall.fromObject(object.initiateCall);
            }
            if (object.terminateCall != null) {
                if (typeof object.terminateCall !== "object")
                    throw TypeError(".proto.ThirdPartyCallControlAPIv1Server.terminateCall: object expected");
                message.terminateCall = $root.proto.ThirdPartyCallControlTerminateCall.fromObject(object.terminateCall);
            }
            return message;
        };

        /**
         * Creates a plain object from a ThirdPartyCallControlAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {proto.ThirdPartyCallControlAPIv1Server} message ThirdPartyCallControlAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThirdPartyCallControlAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.initiateCall = null;
                object.terminateCall = null;
            }
            if (message.initiateCall != null && message.hasOwnProperty("initiateCall"))
                object.initiateCall = $root.proto.ThirdPartyCallControlInitiateCall.toObject(message.initiateCall, options);
            if (message.terminateCall != null && message.hasOwnProperty("terminateCall"))
                object.terminateCall = $root.proto.ThirdPartyCallControlTerminateCall.toObject(message.terminateCall, options);
            return object;
        };

        /**
         * Converts this ThirdPartyCallControlAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThirdPartyCallControlAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ThirdPartyCallControlAPIv1Server
         * @function getTypeUrl
         * @memberof proto.ThirdPartyCallControlAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ThirdPartyCallControlAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ThirdPartyCallControlAPIv1Server";
        };

        return ThirdPartyCallControlAPIv1Server;
    })();

    proto.ThirdPartyCallControlInitiateCall = (function() {

        /**
         * Properties of a ThirdPartyCallControlInitiateCall.
         * @memberof proto
         * @interface IThirdPartyCallControlInitiateCall
         * @property {Array.<string>|null} [callableReferences] ThirdPartyCallControlInitiateCall callableReferences
         * @property {string} referenceId ThirdPartyCallControlInitiateCall referenceId
         * @property {string} callName ThirdPartyCallControlInitiateCall callName
         */

        /**
         * Constructs a new ThirdPartyCallControlInitiateCall.
         * @memberof proto
         * @classdesc Represents a ThirdPartyCallControlInitiateCall.
         * @implements IThirdPartyCallControlInitiateCall
         * @constructor
         * @param {proto.IThirdPartyCallControlInitiateCall=} [properties] Properties to set
         */
        function ThirdPartyCallControlInitiateCall(properties) {
            this.callableReferences = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ThirdPartyCallControlInitiateCall callableReferences.
         * @member {Array.<string>} callableReferences
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @instance
         */
        ThirdPartyCallControlInitiateCall.prototype.callableReferences = $util.emptyArray;

        /**
         * ThirdPartyCallControlInitiateCall referenceId.
         * @member {string} referenceId
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @instance
         */
        ThirdPartyCallControlInitiateCall.prototype.referenceId = "";

        /**
         * ThirdPartyCallControlInitiateCall callName.
         * @member {string} callName
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @instance
         */
        ThirdPartyCallControlInitiateCall.prototype.callName = "";

        /**
         * Creates a new ThirdPartyCallControlInitiateCall instance using the specified properties.
         * @function create
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {proto.IThirdPartyCallControlInitiateCall=} [properties] Properties to set
         * @returns {proto.ThirdPartyCallControlInitiateCall} ThirdPartyCallControlInitiateCall instance
         */
        ThirdPartyCallControlInitiateCall.create = function create(properties) {
            return new ThirdPartyCallControlInitiateCall(properties);
        };

        /**
         * Encodes the specified ThirdPartyCallControlInitiateCall message. Does not implicitly {@link proto.ThirdPartyCallControlInitiateCall.verify|verify} messages.
         * @function encode
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {proto.IThirdPartyCallControlInitiateCall} message ThirdPartyCallControlInitiateCall message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlInitiateCall.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.callableReferences != null && message.callableReferences.length)
                for (var i = 0; i < message.callableReferences.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.callableReferences[i]);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.referenceId);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.callName);
            return writer;
        };

        /**
         * Encodes the specified ThirdPartyCallControlInitiateCall message, length delimited. Does not implicitly {@link proto.ThirdPartyCallControlInitiateCall.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {proto.IThirdPartyCallControlInitiateCall} message ThirdPartyCallControlInitiateCall message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlInitiateCall.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ThirdPartyCallControlInitiateCall message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ThirdPartyCallControlInitiateCall} ThirdPartyCallControlInitiateCall
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlInitiateCall.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ThirdPartyCallControlInitiateCall();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.callableReferences && message.callableReferences.length))
                            message.callableReferences = [];
                        message.callableReferences.push(reader.string());
                        break;
                    }
                case 2: {
                        message.referenceId = reader.string();
                        break;
                    }
                case 3: {
                        message.callName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("referenceId"))
                throw $util.ProtocolError("missing required 'referenceId'", { instance: message });
            if (!message.hasOwnProperty("callName"))
                throw $util.ProtocolError("missing required 'callName'", { instance: message });
            return message;
        };

        /**
         * Decodes a ThirdPartyCallControlInitiateCall message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ThirdPartyCallControlInitiateCall} ThirdPartyCallControlInitiateCall
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlInitiateCall.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ThirdPartyCallControlInitiateCall message.
         * @function verify
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ThirdPartyCallControlInitiateCall.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.callableReferences != null && message.hasOwnProperty("callableReferences")) {
                if (!Array.isArray(message.callableReferences))
                    return "callableReferences: array expected";
                for (var i = 0; i < message.callableReferences.length; ++i)
                    if (!$util.isString(message.callableReferences[i]))
                        return "callableReferences: string[] expected";
            }
            if (!$util.isString(message.referenceId))
                return "referenceId: string expected";
            if (!$util.isString(message.callName))
                return "callName: string expected";
            return null;
        };

        /**
         * Creates a ThirdPartyCallControlInitiateCall message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ThirdPartyCallControlInitiateCall} ThirdPartyCallControlInitiateCall
         */
        ThirdPartyCallControlInitiateCall.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ThirdPartyCallControlInitiateCall)
                return object;
            var message = new $root.proto.ThirdPartyCallControlInitiateCall();
            if (object.callableReferences) {
                if (!Array.isArray(object.callableReferences))
                    throw TypeError(".proto.ThirdPartyCallControlInitiateCall.callableReferences: array expected");
                message.callableReferences = [];
                for (var i = 0; i < object.callableReferences.length; ++i)
                    message.callableReferences[i] = String(object.callableReferences[i]);
            }
            if (object.referenceId != null)
                message.referenceId = String(object.referenceId);
            if (object.callName != null)
                message.callName = String(object.callName);
            return message;
        };

        /**
         * Creates a plain object from a ThirdPartyCallControlInitiateCall message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {proto.ThirdPartyCallControlInitiateCall} message ThirdPartyCallControlInitiateCall
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThirdPartyCallControlInitiateCall.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.callableReferences = [];
            if (options.defaults) {
                object.referenceId = "";
                object.callName = "";
            }
            if (message.callableReferences && message.callableReferences.length) {
                object.callableReferences = [];
                for (var j = 0; j < message.callableReferences.length; ++j)
                    object.callableReferences[j] = message.callableReferences[j];
            }
            if (message.referenceId != null && message.hasOwnProperty("referenceId"))
                object.referenceId = message.referenceId;
            if (message.callName != null && message.hasOwnProperty("callName"))
                object.callName = message.callName;
            return object;
        };

        /**
         * Converts this ThirdPartyCallControlInitiateCall to JSON.
         * @function toJSON
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThirdPartyCallControlInitiateCall.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ThirdPartyCallControlInitiateCall
         * @function getTypeUrl
         * @memberof proto.ThirdPartyCallControlInitiateCall
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ThirdPartyCallControlInitiateCall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ThirdPartyCallControlInitiateCall";
        };

        return ThirdPartyCallControlInitiateCall;
    })();

    proto.ThirdPartyCallControlTerminateCall = (function() {

        /**
         * Properties of a ThirdPartyCallControlTerminateCall.
         * @memberof proto
         * @interface IThirdPartyCallControlTerminateCall
         * @property {string} referenceId ThirdPartyCallControlTerminateCall referenceId
         */

        /**
         * Constructs a new ThirdPartyCallControlTerminateCall.
         * @memberof proto
         * @classdesc Represents a ThirdPartyCallControlTerminateCall.
         * @implements IThirdPartyCallControlTerminateCall
         * @constructor
         * @param {proto.IThirdPartyCallControlTerminateCall=} [properties] Properties to set
         */
        function ThirdPartyCallControlTerminateCall(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ThirdPartyCallControlTerminateCall referenceId.
         * @member {string} referenceId
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @instance
         */
        ThirdPartyCallControlTerminateCall.prototype.referenceId = "";

        /**
         * Creates a new ThirdPartyCallControlTerminateCall instance using the specified properties.
         * @function create
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {proto.IThirdPartyCallControlTerminateCall=} [properties] Properties to set
         * @returns {proto.ThirdPartyCallControlTerminateCall} ThirdPartyCallControlTerminateCall instance
         */
        ThirdPartyCallControlTerminateCall.create = function create(properties) {
            return new ThirdPartyCallControlTerminateCall(properties);
        };

        /**
         * Encodes the specified ThirdPartyCallControlTerminateCall message. Does not implicitly {@link proto.ThirdPartyCallControlTerminateCall.verify|verify} messages.
         * @function encode
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {proto.IThirdPartyCallControlTerminateCall} message ThirdPartyCallControlTerminateCall message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlTerminateCall.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.referenceId);
            return writer;
        };

        /**
         * Encodes the specified ThirdPartyCallControlTerminateCall message, length delimited. Does not implicitly {@link proto.ThirdPartyCallControlTerminateCall.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {proto.IThirdPartyCallControlTerminateCall} message ThirdPartyCallControlTerminateCall message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdPartyCallControlTerminateCall.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ThirdPartyCallControlTerminateCall message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ThirdPartyCallControlTerminateCall} ThirdPartyCallControlTerminateCall
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlTerminateCall.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ThirdPartyCallControlTerminateCall();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.referenceId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("referenceId"))
                throw $util.ProtocolError("missing required 'referenceId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ThirdPartyCallControlTerminateCall message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ThirdPartyCallControlTerminateCall} ThirdPartyCallControlTerminateCall
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdPartyCallControlTerminateCall.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ThirdPartyCallControlTerminateCall message.
         * @function verify
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ThirdPartyCallControlTerminateCall.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.referenceId))
                return "referenceId: string expected";
            return null;
        };

        /**
         * Creates a ThirdPartyCallControlTerminateCall message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ThirdPartyCallControlTerminateCall} ThirdPartyCallControlTerminateCall
         */
        ThirdPartyCallControlTerminateCall.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ThirdPartyCallControlTerminateCall)
                return object;
            var message = new $root.proto.ThirdPartyCallControlTerminateCall();
            if (object.referenceId != null)
                message.referenceId = String(object.referenceId);
            return message;
        };

        /**
         * Creates a plain object from a ThirdPartyCallControlTerminateCall message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {proto.ThirdPartyCallControlTerminateCall} message ThirdPartyCallControlTerminateCall
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThirdPartyCallControlTerminateCall.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.referenceId = "";
            if (message.referenceId != null && message.hasOwnProperty("referenceId"))
                object.referenceId = message.referenceId;
            return object;
        };

        /**
         * Converts this ThirdPartyCallControlTerminateCall to JSON.
         * @function toJSON
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThirdPartyCallControlTerminateCall.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ThirdPartyCallControlTerminateCall
         * @function getTypeUrl
         * @memberof proto.ThirdPartyCallControlTerminateCall
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ThirdPartyCallControlTerminateCall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.ThirdPartyCallControlTerminateCall";
        };

        return ThirdPartyCallControlTerminateCall;
    })();

    proto.SMSAPIv1Client = (function() {

        /**
         * Properties of a SMSAPIv1Client.
         * @memberof proto
         * @interface ISMSAPIv1Client
         * @property {proto.ISMSModuleSetupRequest|null} [setupRequest] SMSAPIv1Client setupRequest
         * @property {proto.ISMSSendRequest|null} [sendRequest] SMSAPIv1Client sendRequest
         */

        /**
         * Constructs a new SMSAPIv1Client.
         * @memberof proto
         * @classdesc Represents a SMSAPIv1Client.
         * @implements ISMSAPIv1Client
         * @constructor
         * @param {proto.ISMSAPIv1Client=} [properties] Properties to set
         */
        function SMSAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SMSAPIv1Client setupRequest.
         * @member {proto.ISMSModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.SMSAPIv1Client
         * @instance
         */
        SMSAPIv1Client.prototype.setupRequest = null;

        /**
         * SMSAPIv1Client sendRequest.
         * @member {proto.ISMSSendRequest|null|undefined} sendRequest
         * @memberof proto.SMSAPIv1Client
         * @instance
         */
        SMSAPIv1Client.prototype.sendRequest = null;

        /**
         * Creates a new SMSAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {proto.ISMSAPIv1Client=} [properties] Properties to set
         * @returns {proto.SMSAPIv1Client} SMSAPIv1Client instance
         */
        SMSAPIv1Client.create = function create(properties) {
            return new SMSAPIv1Client(properties);
        };

        /**
         * Encodes the specified SMSAPIv1Client message. Does not implicitly {@link proto.SMSAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {proto.ISMSAPIv1Client} message SMSAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SMSAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.SMSModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sendRequest != null && Object.hasOwnProperty.call(message, "sendRequest"))
                $root.proto.SMSSendRequest.encode(message.sendRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SMSAPIv1Client message, length delimited. Does not implicitly {@link proto.SMSAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {proto.ISMSAPIv1Client} message SMSAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SMSAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SMSAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SMSAPIv1Client} SMSAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SMSAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SMSAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.SMSModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.sendRequest = $root.proto.SMSSendRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SMSAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SMSAPIv1Client} SMSAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SMSAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SMSAPIv1Client message.
         * @function verify
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SMSAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.SMSModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.sendRequest != null && message.hasOwnProperty("sendRequest")) {
                var error = $root.proto.SMSSendRequest.verify(message.sendRequest);
                if (error)
                    return "sendRequest." + error;
            }
            return null;
        };

        /**
         * Creates a SMSAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SMSAPIv1Client} SMSAPIv1Client
         */
        SMSAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SMSAPIv1Client)
                return object;
            var message = new $root.proto.SMSAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.SMSAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.SMSModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.sendRequest != null) {
                if (typeof object.sendRequest !== "object")
                    throw TypeError(".proto.SMSAPIv1Client.sendRequest: object expected");
                message.sendRequest = $root.proto.SMSSendRequest.fromObject(object.sendRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a SMSAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {proto.SMSAPIv1Client} message SMSAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SMSAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.sendRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.SMSModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.sendRequest != null && message.hasOwnProperty("sendRequest"))
                object.sendRequest = $root.proto.SMSSendRequest.toObject(message.sendRequest, options);
            return object;
        };

        /**
         * Converts this SMSAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.SMSAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SMSAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SMSAPIv1Client
         * @function getTypeUrl
         * @memberof proto.SMSAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SMSAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SMSAPIv1Client";
        };

        return SMSAPIv1Client;
    })();

    proto.SMSModuleSetupRequest = (function() {

        /**
         * Properties of a SMSModuleSetupRequest.
         * @memberof proto
         * @interface ISMSModuleSetupRequest
         */

        /**
         * Constructs a new SMSModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a SMSModuleSetupRequest.
         * @implements ISMSModuleSetupRequest
         * @constructor
         * @param {proto.ISMSModuleSetupRequest=} [properties] Properties to set
         */
        function SMSModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SMSModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {proto.ISMSModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.SMSModuleSetupRequest} SMSModuleSetupRequest instance
         */
        SMSModuleSetupRequest.create = function create(properties) {
            return new SMSModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified SMSModuleSetupRequest message. Does not implicitly {@link proto.SMSModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {proto.ISMSModuleSetupRequest} message SMSModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SMSModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SMSModuleSetupRequest message, length delimited. Does not implicitly {@link proto.SMSModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {proto.ISMSModuleSetupRequest} message SMSModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SMSModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SMSModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SMSModuleSetupRequest} SMSModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SMSModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SMSModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SMSModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SMSModuleSetupRequest} SMSModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SMSModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SMSModuleSetupRequest message.
         * @function verify
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SMSModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SMSModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SMSModuleSetupRequest} SMSModuleSetupRequest
         */
        SMSModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SMSModuleSetupRequest)
                return object;
            return new $root.proto.SMSModuleSetupRequest();
        };

        /**
         * Creates a plain object from a SMSModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {proto.SMSModuleSetupRequest} message SMSModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SMSModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SMSModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.SMSModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SMSModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SMSModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.SMSModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SMSModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SMSModuleSetupRequest";
        };

        return SMSModuleSetupRequest;
    })();

    proto.SMSSendRequest = (function() {

        /**
         * Properties of a SMSSendRequest.
         * @memberof proto
         * @interface ISMSSendRequest
         * @property {string} receiver SMSSendRequest receiver
         * @property {string} message SMSSendRequest message
         */

        /**
         * Constructs a new SMSSendRequest.
         * @memberof proto
         * @classdesc Represents a SMSSendRequest.
         * @implements ISMSSendRequest
         * @constructor
         * @param {proto.ISMSSendRequest=} [properties] Properties to set
         */
        function SMSSendRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SMSSendRequest receiver.
         * @member {string} receiver
         * @memberof proto.SMSSendRequest
         * @instance
         */
        SMSSendRequest.prototype.receiver = "";

        /**
         * SMSSendRequest message.
         * @member {string} message
         * @memberof proto.SMSSendRequest
         * @instance
         */
        SMSSendRequest.prototype.message = "";

        /**
         * Creates a new SMSSendRequest instance using the specified properties.
         * @function create
         * @memberof proto.SMSSendRequest
         * @static
         * @param {proto.ISMSSendRequest=} [properties] Properties to set
         * @returns {proto.SMSSendRequest} SMSSendRequest instance
         */
        SMSSendRequest.create = function create(properties) {
            return new SMSSendRequest(properties);
        };

        /**
         * Encodes the specified SMSSendRequest message. Does not implicitly {@link proto.SMSSendRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.SMSSendRequest
         * @static
         * @param {proto.ISMSSendRequest} message SMSSendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SMSSendRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.receiver);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified SMSSendRequest message, length delimited. Does not implicitly {@link proto.SMSSendRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.SMSSendRequest
         * @static
         * @param {proto.ISMSSendRequest} message SMSSendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SMSSendRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SMSSendRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.SMSSendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.SMSSendRequest} SMSSendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SMSSendRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.SMSSendRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.receiver = reader.string();
                        break;
                    }
                case 2: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("receiver"))
                throw $util.ProtocolError("missing required 'receiver'", { instance: message });
            if (!message.hasOwnProperty("message"))
                throw $util.ProtocolError("missing required 'message'", { instance: message });
            return message;
        };

        /**
         * Decodes a SMSSendRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.SMSSendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.SMSSendRequest} SMSSendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SMSSendRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SMSSendRequest message.
         * @function verify
         * @memberof proto.SMSSendRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SMSSendRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.receiver))
                return "receiver: string expected";
            if (!$util.isString(message.message))
                return "message: string expected";
            return null;
        };

        /**
         * Creates a SMSSendRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.SMSSendRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.SMSSendRequest} SMSSendRequest
         */
        SMSSendRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.SMSSendRequest)
                return object;
            var message = new $root.proto.SMSSendRequest();
            if (object.receiver != null)
                message.receiver = String(object.receiver);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a SMSSendRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.SMSSendRequest
         * @static
         * @param {proto.SMSSendRequest} message SMSSendRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SMSSendRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.receiver = "";
                object.message = "";
            }
            if (message.receiver != null && message.hasOwnProperty("receiver"))
                object.receiver = message.receiver;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this SMSSendRequest to JSON.
         * @function toJSON
         * @memberof proto.SMSSendRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SMSSendRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SMSSendRequest
         * @function getTypeUrl
         * @memberof proto.SMSSendRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SMSSendRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.SMSSendRequest";
        };

        return SMSSendRequest;
    })();

    proto.QueueManagementAPIv1Client = (function() {

        /**
         * Properties of a QueueManagementAPIv1Client.
         * @memberof proto
         * @interface IQueueManagementAPIv1Client
         * @property {proto.IQueueManagementModuleSetupRequest|null} [setupRequest] QueueManagementAPIv1Client setupRequest
         * @property {proto.IQueueManagementSubscribeRequest|null} [subscribeRequest] QueueManagementAPIv1Client subscribeRequest
         * @property {proto.IQueueManagementUnsubscribeRequest|null} [unsubscribeRequest] QueueManagementAPIv1Client unsubscribeRequest
         * @property {proto.IQueueManagementPickRequest|null} [pickRequest] QueueManagementAPIv1Client pickRequest
         * @property {proto.IQueueManagementUnpickRequest|null} [unpickRequest] QueueManagementAPIv1Client unpickRequest
         * @property {proto.IQueueManagementCloseRequest|null} [closeRequest] QueueManagementAPIv1Client closeRequest
         */

        /**
         * Constructs a new QueueManagementAPIv1Client.
         * @memberof proto
         * @classdesc Represents a QueueManagementAPIv1Client.
         * @implements IQueueManagementAPIv1Client
         * @constructor
         * @param {proto.IQueueManagementAPIv1Client=} [properties] Properties to set
         */
        function QueueManagementAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementAPIv1Client setupRequest.
         * @member {proto.IQueueManagementModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         */
        QueueManagementAPIv1Client.prototype.setupRequest = null;

        /**
         * QueueManagementAPIv1Client subscribeRequest.
         * @member {proto.IQueueManagementSubscribeRequest|null|undefined} subscribeRequest
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         */
        QueueManagementAPIv1Client.prototype.subscribeRequest = null;

        /**
         * QueueManagementAPIv1Client unsubscribeRequest.
         * @member {proto.IQueueManagementUnsubscribeRequest|null|undefined} unsubscribeRequest
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         */
        QueueManagementAPIv1Client.prototype.unsubscribeRequest = null;

        /**
         * QueueManagementAPIv1Client pickRequest.
         * @member {proto.IQueueManagementPickRequest|null|undefined} pickRequest
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         */
        QueueManagementAPIv1Client.prototype.pickRequest = null;

        /**
         * QueueManagementAPIv1Client unpickRequest.
         * @member {proto.IQueueManagementUnpickRequest|null|undefined} unpickRequest
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         */
        QueueManagementAPIv1Client.prototype.unpickRequest = null;

        /**
         * QueueManagementAPIv1Client closeRequest.
         * @member {proto.IQueueManagementCloseRequest|null|undefined} closeRequest
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         */
        QueueManagementAPIv1Client.prototype.closeRequest = null;

        /**
         * Creates a new QueueManagementAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {proto.IQueueManagementAPIv1Client=} [properties] Properties to set
         * @returns {proto.QueueManagementAPIv1Client} QueueManagementAPIv1Client instance
         */
        QueueManagementAPIv1Client.create = function create(properties) {
            return new QueueManagementAPIv1Client(properties);
        };

        /**
         * Encodes the specified QueueManagementAPIv1Client message. Does not implicitly {@link proto.QueueManagementAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {proto.IQueueManagementAPIv1Client} message QueueManagementAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.QueueManagementModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.subscribeRequest != null && Object.hasOwnProperty.call(message, "subscribeRequest"))
                $root.proto.QueueManagementSubscribeRequest.encode(message.subscribeRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.unsubscribeRequest != null && Object.hasOwnProperty.call(message, "unsubscribeRequest"))
                $root.proto.QueueManagementUnsubscribeRequest.encode(message.unsubscribeRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.pickRequest != null && Object.hasOwnProperty.call(message, "pickRequest"))
                $root.proto.QueueManagementPickRequest.encode(message.pickRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.unpickRequest != null && Object.hasOwnProperty.call(message, "unpickRequest"))
                $root.proto.QueueManagementUnpickRequest.encode(message.unpickRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.closeRequest != null && Object.hasOwnProperty.call(message, "closeRequest"))
                $root.proto.QueueManagementCloseRequest.encode(message.closeRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueueManagementAPIv1Client message, length delimited. Does not implicitly {@link proto.QueueManagementAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {proto.IQueueManagementAPIv1Client} message QueueManagementAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementAPIv1Client} QueueManagementAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.QueueManagementModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.subscribeRequest = $root.proto.QueueManagementSubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.unsubscribeRequest = $root.proto.QueueManagementUnsubscribeRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.pickRequest = $root.proto.QueueManagementPickRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.unpickRequest = $root.proto.QueueManagementUnpickRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.closeRequest = $root.proto.QueueManagementCloseRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueueManagementAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementAPIv1Client} QueueManagementAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementAPIv1Client message.
         * @function verify
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.QueueManagementModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest")) {
                var error = $root.proto.QueueManagementSubscribeRequest.verify(message.subscribeRequest);
                if (error)
                    return "subscribeRequest." + error;
            }
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest")) {
                var error = $root.proto.QueueManagementUnsubscribeRequest.verify(message.unsubscribeRequest);
                if (error)
                    return "unsubscribeRequest." + error;
            }
            if (message.pickRequest != null && message.hasOwnProperty("pickRequest")) {
                var error = $root.proto.QueueManagementPickRequest.verify(message.pickRequest);
                if (error)
                    return "pickRequest." + error;
            }
            if (message.unpickRequest != null && message.hasOwnProperty("unpickRequest")) {
                var error = $root.proto.QueueManagementUnpickRequest.verify(message.unpickRequest);
                if (error)
                    return "unpickRequest." + error;
            }
            if (message.closeRequest != null && message.hasOwnProperty("closeRequest")) {
                var error = $root.proto.QueueManagementCloseRequest.verify(message.closeRequest);
                if (error)
                    return "closeRequest." + error;
            }
            return null;
        };

        /**
         * Creates a QueueManagementAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementAPIv1Client} QueueManagementAPIv1Client
         */
        QueueManagementAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementAPIv1Client)
                return object;
            var message = new $root.proto.QueueManagementAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.QueueManagementModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.subscribeRequest != null) {
                if (typeof object.subscribeRequest !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Client.subscribeRequest: object expected");
                message.subscribeRequest = $root.proto.QueueManagementSubscribeRequest.fromObject(object.subscribeRequest);
            }
            if (object.unsubscribeRequest != null) {
                if (typeof object.unsubscribeRequest !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Client.unsubscribeRequest: object expected");
                message.unsubscribeRequest = $root.proto.QueueManagementUnsubscribeRequest.fromObject(object.unsubscribeRequest);
            }
            if (object.pickRequest != null) {
                if (typeof object.pickRequest !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Client.pickRequest: object expected");
                message.pickRequest = $root.proto.QueueManagementPickRequest.fromObject(object.pickRequest);
            }
            if (object.unpickRequest != null) {
                if (typeof object.unpickRequest !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Client.unpickRequest: object expected");
                message.unpickRequest = $root.proto.QueueManagementUnpickRequest.fromObject(object.unpickRequest);
            }
            if (object.closeRequest != null) {
                if (typeof object.closeRequest !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Client.closeRequest: object expected");
                message.closeRequest = $root.proto.QueueManagementCloseRequest.fromObject(object.closeRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {proto.QueueManagementAPIv1Client} message QueueManagementAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.subscribeRequest = null;
                object.unsubscribeRequest = null;
                object.pickRequest = null;
                object.unpickRequest = null;
                object.closeRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.QueueManagementModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.subscribeRequest != null && message.hasOwnProperty("subscribeRequest"))
                object.subscribeRequest = $root.proto.QueueManagementSubscribeRequest.toObject(message.subscribeRequest, options);
            if (message.unsubscribeRequest != null && message.hasOwnProperty("unsubscribeRequest"))
                object.unsubscribeRequest = $root.proto.QueueManagementUnsubscribeRequest.toObject(message.unsubscribeRequest, options);
            if (message.pickRequest != null && message.hasOwnProperty("pickRequest"))
                object.pickRequest = $root.proto.QueueManagementPickRequest.toObject(message.pickRequest, options);
            if (message.unpickRequest != null && message.hasOwnProperty("unpickRequest"))
                object.unpickRequest = $root.proto.QueueManagementUnpickRequest.toObject(message.unpickRequest, options);
            if (message.closeRequest != null && message.hasOwnProperty("closeRequest"))
                object.closeRequest = $root.proto.QueueManagementCloseRequest.toObject(message.closeRequest, options);
            return object;
        };

        /**
         * Converts this QueueManagementAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementAPIv1Client
         * @function getTypeUrl
         * @memberof proto.QueueManagementAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementAPIv1Client";
        };

        return QueueManagementAPIv1Client;
    })();

    proto.QueueManagementAPIv1Server = (function() {

        /**
         * Properties of a QueueManagementAPIv1Server.
         * @memberof proto
         * @interface IQueueManagementAPIv1Server
         * @property {proto.IQueueManagementDelta|null} [delta] QueueManagementAPIv1Server delta
         */

        /**
         * Constructs a new QueueManagementAPIv1Server.
         * @memberof proto
         * @classdesc Represents a QueueManagementAPIv1Server.
         * @implements IQueueManagementAPIv1Server
         * @constructor
         * @param {proto.IQueueManagementAPIv1Server=} [properties] Properties to set
         */
        function QueueManagementAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementAPIv1Server delta.
         * @member {proto.IQueueManagementDelta|null|undefined} delta
         * @memberof proto.QueueManagementAPIv1Server
         * @instance
         */
        QueueManagementAPIv1Server.prototype.delta = null;

        /**
         * Creates a new QueueManagementAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {proto.IQueueManagementAPIv1Server=} [properties] Properties to set
         * @returns {proto.QueueManagementAPIv1Server} QueueManagementAPIv1Server instance
         */
        QueueManagementAPIv1Server.create = function create(properties) {
            return new QueueManagementAPIv1Server(properties);
        };

        /**
         * Encodes the specified QueueManagementAPIv1Server message. Does not implicitly {@link proto.QueueManagementAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {proto.IQueueManagementAPIv1Server} message QueueManagementAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.delta != null && Object.hasOwnProperty.call(message, "delta"))
                $root.proto.QueueManagementDelta.encode(message.delta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueueManagementAPIv1Server message, length delimited. Does not implicitly {@link proto.QueueManagementAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {proto.IQueueManagementAPIv1Server} message QueueManagementAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementAPIv1Server} QueueManagementAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.delta = $root.proto.QueueManagementDelta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueueManagementAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementAPIv1Server} QueueManagementAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementAPIv1Server message.
         * @function verify
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.delta != null && message.hasOwnProperty("delta")) {
                var error = $root.proto.QueueManagementDelta.verify(message.delta);
                if (error)
                    return "delta." + error;
            }
            return null;
        };

        /**
         * Creates a QueueManagementAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementAPIv1Server} QueueManagementAPIv1Server
         */
        QueueManagementAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementAPIv1Server)
                return object;
            var message = new $root.proto.QueueManagementAPIv1Server();
            if (object.delta != null) {
                if (typeof object.delta !== "object")
                    throw TypeError(".proto.QueueManagementAPIv1Server.delta: object expected");
                message.delta = $root.proto.QueueManagementDelta.fromObject(object.delta);
            }
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {proto.QueueManagementAPIv1Server} message QueueManagementAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.delta = null;
            if (message.delta != null && message.hasOwnProperty("delta"))
                object.delta = $root.proto.QueueManagementDelta.toObject(message.delta, options);
            return object;
        };

        /**
         * Converts this QueueManagementAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementAPIv1Server
         * @function getTypeUrl
         * @memberof proto.QueueManagementAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementAPIv1Server";
        };

        return QueueManagementAPIv1Server;
    })();

    proto.QueueManagementModuleSetupRequest = (function() {

        /**
         * Properties of a QueueManagementModuleSetupRequest.
         * @memberof proto
         * @interface IQueueManagementModuleSetupRequest
         */

        /**
         * Constructs a new QueueManagementModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a QueueManagementModuleSetupRequest.
         * @implements IQueueManagementModuleSetupRequest
         * @constructor
         * @param {proto.IQueueManagementModuleSetupRequest=} [properties] Properties to set
         */
        function QueueManagementModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new QueueManagementModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {proto.IQueueManagementModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.QueueManagementModuleSetupRequest} QueueManagementModuleSetupRequest instance
         */
        QueueManagementModuleSetupRequest.create = function create(properties) {
            return new QueueManagementModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified QueueManagementModuleSetupRequest message. Does not implicitly {@link proto.QueueManagementModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {proto.IQueueManagementModuleSetupRequest} message QueueManagementModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified QueueManagementModuleSetupRequest message, length delimited. Does not implicitly {@link proto.QueueManagementModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {proto.IQueueManagementModuleSetupRequest} message QueueManagementModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementModuleSetupRequest} QueueManagementModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueueManagementModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementModuleSetupRequest} QueueManagementModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementModuleSetupRequest message.
         * @function verify
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a QueueManagementModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementModuleSetupRequest} QueueManagementModuleSetupRequest
         */
        QueueManagementModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementModuleSetupRequest)
                return object;
            return new $root.proto.QueueManagementModuleSetupRequest();
        };

        /**
         * Creates a plain object from a QueueManagementModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {proto.QueueManagementModuleSetupRequest} message QueueManagementModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this QueueManagementModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.QueueManagementModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementModuleSetupRequest";
        };

        return QueueManagementModuleSetupRequest;
    })();

    proto.QueueManagementModuleSetupResponse = (function() {

        /**
         * Properties of a QueueManagementModuleSetupResponse.
         * @memberof proto
         * @interface IQueueManagementModuleSetupResponse
         * @property {Array.<proto.IQueueInfo>|null} [availableQueues] QueueManagementModuleSetupResponse availableQueues
         */

        /**
         * Constructs a new QueueManagementModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a QueueManagementModuleSetupResponse.
         * @implements IQueueManagementModuleSetupResponse
         * @constructor
         * @param {proto.IQueueManagementModuleSetupResponse=} [properties] Properties to set
         */
        function QueueManagementModuleSetupResponse(properties) {
            this.availableQueues = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementModuleSetupResponse availableQueues.
         * @member {Array.<proto.IQueueInfo>} availableQueues
         * @memberof proto.QueueManagementModuleSetupResponse
         * @instance
         */
        QueueManagementModuleSetupResponse.prototype.availableQueues = $util.emptyArray;

        /**
         * Creates a new QueueManagementModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {proto.IQueueManagementModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.QueueManagementModuleSetupResponse} QueueManagementModuleSetupResponse instance
         */
        QueueManagementModuleSetupResponse.create = function create(properties) {
            return new QueueManagementModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified QueueManagementModuleSetupResponse message. Does not implicitly {@link proto.QueueManagementModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {proto.IQueueManagementModuleSetupResponse} message QueueManagementModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.availableQueues != null && message.availableQueues.length)
                for (var i = 0; i < message.availableQueues.length; ++i)
                    $root.proto.QueueInfo.encode(message.availableQueues[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueueManagementModuleSetupResponse message, length delimited. Does not implicitly {@link proto.QueueManagementModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {proto.IQueueManagementModuleSetupResponse} message QueueManagementModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementModuleSetupResponse} QueueManagementModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.availableQueues && message.availableQueues.length))
                            message.availableQueues = [];
                        message.availableQueues.push($root.proto.QueueInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueueManagementModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementModuleSetupResponse} QueueManagementModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementModuleSetupResponse message.
         * @function verify
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.availableQueues != null && message.hasOwnProperty("availableQueues")) {
                if (!Array.isArray(message.availableQueues))
                    return "availableQueues: array expected";
                for (var i = 0; i < message.availableQueues.length; ++i) {
                    var error = $root.proto.QueueInfo.verify(message.availableQueues[i]);
                    if (error)
                        return "availableQueues." + error;
                }
            }
            return null;
        };

        /**
         * Creates a QueueManagementModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementModuleSetupResponse} QueueManagementModuleSetupResponse
         */
        QueueManagementModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementModuleSetupResponse)
                return object;
            var message = new $root.proto.QueueManagementModuleSetupResponse();
            if (object.availableQueues) {
                if (!Array.isArray(object.availableQueues))
                    throw TypeError(".proto.QueueManagementModuleSetupResponse.availableQueues: array expected");
                message.availableQueues = [];
                for (var i = 0; i < object.availableQueues.length; ++i) {
                    if (typeof object.availableQueues[i] !== "object")
                        throw TypeError(".proto.QueueManagementModuleSetupResponse.availableQueues: object expected");
                    message.availableQueues[i] = $root.proto.QueueInfo.fromObject(object.availableQueues[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {proto.QueueManagementModuleSetupResponse} message QueueManagementModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.availableQueues = [];
            if (message.availableQueues && message.availableQueues.length) {
                object.availableQueues = [];
                for (var j = 0; j < message.availableQueues.length; ++j)
                    object.availableQueues[j] = $root.proto.QueueInfo.toObject(message.availableQueues[j], options);
            }
            return object;
        };

        /**
         * Converts this QueueManagementModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.QueueManagementModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementModuleSetupResponse";
        };

        return QueueManagementModuleSetupResponse;
    })();

    proto.QueueInfo = (function() {

        /**
         * Properties of a QueueInfo.
         * @memberof proto
         * @interface IQueueInfo
         * @property {string} queueEntityId QueueInfo queueEntityId
         * @property {string} name QueueInfo name
         * @property {string|null} [info] QueueInfo info
         * @property {boolean} emergency QueueInfo emergency
         * @property {number} priority QueueInfo priority
         * @property {string|null} [owner] QueueInfo owner
         * @property {proto.IEmergencyAlarmInfo|null} [emergencyAlarmInfo] QueueInfo emergencyAlarmInfo
         * @property {string|null} [queueUuid] QueueInfo queueUuid
         */

        /**
         * Constructs a new QueueInfo.
         * @memberof proto
         * @classdesc Represents a QueueInfo.
         * @implements IQueueInfo
         * @constructor
         * @param {proto.IQueueInfo=} [properties] Properties to set
         */
        function QueueInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueInfo queueEntityId.
         * @member {string} queueEntityId
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.queueEntityId = "";

        /**
         * QueueInfo name.
         * @member {string} name
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.name = "";

        /**
         * QueueInfo info.
         * @member {string} info
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.info = "";

        /**
         * QueueInfo emergency.
         * @member {boolean} emergency
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.emergency = false;

        /**
         * QueueInfo priority.
         * @member {number} priority
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.priority = 0;

        /**
         * QueueInfo owner.
         * @member {string} owner
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.owner = "";

        /**
         * QueueInfo emergencyAlarmInfo.
         * @member {proto.IEmergencyAlarmInfo|null|undefined} emergencyAlarmInfo
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.emergencyAlarmInfo = null;

        /**
         * QueueInfo queueUuid.
         * @member {string} queueUuid
         * @memberof proto.QueueInfo
         * @instance
         */
        QueueInfo.prototype.queueUuid = "";

        /**
         * Creates a new QueueInfo instance using the specified properties.
         * @function create
         * @memberof proto.QueueInfo
         * @static
         * @param {proto.IQueueInfo=} [properties] Properties to set
         * @returns {proto.QueueInfo} QueueInfo instance
         */
        QueueInfo.create = function create(properties) {
            return new QueueInfo(properties);
        };

        /**
         * Encodes the specified QueueInfo message. Does not implicitly {@link proto.QueueInfo.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueInfo
         * @static
         * @param {proto.IQueueInfo} message QueueInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueEntityId);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.emergency);
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.priority);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.owner);
            if (message.emergencyAlarmInfo != null && Object.hasOwnProperty.call(message, "emergencyAlarmInfo"))
                $root.proto.EmergencyAlarmInfo.encode(message.emergencyAlarmInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.queueUuid);
            return writer;
        };

        /**
         * Encodes the specified QueueInfo message, length delimited. Does not implicitly {@link proto.QueueInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueInfo
         * @static
         * @param {proto.IQueueInfo} message QueueInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueInfo message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueInfo} QueueInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.queueEntityId = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.info = reader.string();
                        break;
                    }
                case 4: {
                        message.emergency = reader.bool();
                        break;
                    }
                case 5: {
                        message.priority = reader.int32();
                        break;
                    }
                case 6: {
                        message.owner = reader.string();
                        break;
                    }
                case 7: {
                        message.emergencyAlarmInfo = $root.proto.EmergencyAlarmInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.queueUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("queueEntityId"))
                throw $util.ProtocolError("missing required 'queueEntityId'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("emergency"))
                throw $util.ProtocolError("missing required 'emergency'", { instance: message });
            if (!message.hasOwnProperty("priority"))
                throw $util.ProtocolError("missing required 'priority'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueInfo} QueueInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueInfo message.
         * @function verify
         * @memberof proto.QueueInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.queueEntityId))
                return "queueEntityId: string expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (typeof message.emergency !== "boolean")
                return "emergency: boolean expected";
            if (!$util.isInteger(message.priority))
                return "priority: integer expected";
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (!$util.isString(message.owner))
                    return "owner: string expected";
            if (message.emergencyAlarmInfo != null && message.hasOwnProperty("emergencyAlarmInfo")) {
                var error = $root.proto.EmergencyAlarmInfo.verify(message.emergencyAlarmInfo);
                if (error)
                    return "emergencyAlarmInfo." + error;
            }
            if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                if (!$util.isString(message.queueUuid))
                    return "queueUuid: string expected";
            return null;
        };

        /**
         * Creates a QueueInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueInfo} QueueInfo
         */
        QueueInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueInfo)
                return object;
            var message = new $root.proto.QueueInfo();
            if (object.queueEntityId != null)
                message.queueEntityId = String(object.queueEntityId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.info != null)
                message.info = String(object.info);
            if (object.emergency != null)
                message.emergency = Boolean(object.emergency);
            if (object.priority != null)
                message.priority = object.priority | 0;
            if (object.owner != null)
                message.owner = String(object.owner);
            if (object.emergencyAlarmInfo != null) {
                if (typeof object.emergencyAlarmInfo !== "object")
                    throw TypeError(".proto.QueueInfo.emergencyAlarmInfo: object expected");
                message.emergencyAlarmInfo = $root.proto.EmergencyAlarmInfo.fromObject(object.emergencyAlarmInfo);
            }
            if (object.queueUuid != null)
                message.queueUuid = String(object.queueUuid);
            return message;
        };

        /**
         * Creates a plain object from a QueueInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueInfo
         * @static
         * @param {proto.QueueInfo} message QueueInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.queueEntityId = "";
                object.name = "";
                object.info = "";
                object.emergency = false;
                object.priority = 0;
                object.owner = "";
                object.emergencyAlarmInfo = null;
                object.queueUuid = "";
            }
            if (message.queueEntityId != null && message.hasOwnProperty("queueEntityId"))
                object.queueEntityId = message.queueEntityId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.emergency != null && message.hasOwnProperty("emergency"))
                object.emergency = message.emergency;
            if (message.priority != null && message.hasOwnProperty("priority"))
                object.priority = message.priority;
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            if (message.emergencyAlarmInfo != null && message.hasOwnProperty("emergencyAlarmInfo"))
                object.emergencyAlarmInfo = $root.proto.EmergencyAlarmInfo.toObject(message.emergencyAlarmInfo, options);
            if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                object.queueUuid = message.queueUuid;
            return object;
        };

        /**
         * Converts this QueueInfo to JSON.
         * @function toJSON
         * @memberof proto.QueueInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueInfo
         * @function getTypeUrl
         * @memberof proto.QueueInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueInfo";
        };

        return QueueInfo;
    })();

    proto.EmergencyAlarmInfo = (function() {

        /**
         * Properties of an EmergencyAlarmInfo.
         * @memberof proto
         * @interface IEmergencyAlarmInfo
         * @property {boolean|null} [alarmToGroup] EmergencyAlarmInfo alarmToGroup
         * @property {boolean|null} [monitoring] EmergencyAlarmInfo monitoring
         * @property {number|null} [monitoringDuration] EmergencyAlarmInfo monitoringDuration
         */

        /**
         * Constructs a new EmergencyAlarmInfo.
         * @memberof proto
         * @classdesc Represents an EmergencyAlarmInfo.
         * @implements IEmergencyAlarmInfo
         * @constructor
         * @param {proto.IEmergencyAlarmInfo=} [properties] Properties to set
         */
        function EmergencyAlarmInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmergencyAlarmInfo alarmToGroup.
         * @member {boolean} alarmToGroup
         * @memberof proto.EmergencyAlarmInfo
         * @instance
         */
        EmergencyAlarmInfo.prototype.alarmToGroup = false;

        /**
         * EmergencyAlarmInfo monitoring.
         * @member {boolean} monitoring
         * @memberof proto.EmergencyAlarmInfo
         * @instance
         */
        EmergencyAlarmInfo.prototype.monitoring = false;

        /**
         * EmergencyAlarmInfo monitoringDuration.
         * @member {number} monitoringDuration
         * @memberof proto.EmergencyAlarmInfo
         * @instance
         */
        EmergencyAlarmInfo.prototype.monitoringDuration = 0;

        /**
         * Creates a new EmergencyAlarmInfo instance using the specified properties.
         * @function create
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {proto.IEmergencyAlarmInfo=} [properties] Properties to set
         * @returns {proto.EmergencyAlarmInfo} EmergencyAlarmInfo instance
         */
        EmergencyAlarmInfo.create = function create(properties) {
            return new EmergencyAlarmInfo(properties);
        };

        /**
         * Encodes the specified EmergencyAlarmInfo message. Does not implicitly {@link proto.EmergencyAlarmInfo.verify|verify} messages.
         * @function encode
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {proto.IEmergencyAlarmInfo} message EmergencyAlarmInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyAlarmInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.alarmToGroup != null && Object.hasOwnProperty.call(message, "alarmToGroup"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.alarmToGroup);
            if (message.monitoring != null && Object.hasOwnProperty.call(message, "monitoring"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.monitoring);
            if (message.monitoringDuration != null && Object.hasOwnProperty.call(message, "monitoringDuration"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.monitoringDuration);
            return writer;
        };

        /**
         * Encodes the specified EmergencyAlarmInfo message, length delimited. Does not implicitly {@link proto.EmergencyAlarmInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {proto.IEmergencyAlarmInfo} message EmergencyAlarmInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmergencyAlarmInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmergencyAlarmInfo message from the specified reader or buffer.
         * @function decode
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.EmergencyAlarmInfo} EmergencyAlarmInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyAlarmInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.EmergencyAlarmInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.alarmToGroup = reader.bool();
                        break;
                    }
                case 2: {
                        message.monitoring = reader.bool();
                        break;
                    }
                case 3: {
                        message.monitoringDuration = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmergencyAlarmInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.EmergencyAlarmInfo} EmergencyAlarmInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmergencyAlarmInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmergencyAlarmInfo message.
         * @function verify
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmergencyAlarmInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.alarmToGroup != null && message.hasOwnProperty("alarmToGroup"))
                if (typeof message.alarmToGroup !== "boolean")
                    return "alarmToGroup: boolean expected";
            if (message.monitoring != null && message.hasOwnProperty("monitoring"))
                if (typeof message.monitoring !== "boolean")
                    return "monitoring: boolean expected";
            if (message.monitoringDuration != null && message.hasOwnProperty("monitoringDuration"))
                if (!$util.isInteger(message.monitoringDuration))
                    return "monitoringDuration: integer expected";
            return null;
        };

        /**
         * Creates an EmergencyAlarmInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.EmergencyAlarmInfo} EmergencyAlarmInfo
         */
        EmergencyAlarmInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.EmergencyAlarmInfo)
                return object;
            var message = new $root.proto.EmergencyAlarmInfo();
            if (object.alarmToGroup != null)
                message.alarmToGroup = Boolean(object.alarmToGroup);
            if (object.monitoring != null)
                message.monitoring = Boolean(object.monitoring);
            if (object.monitoringDuration != null)
                message.monitoringDuration = object.monitoringDuration | 0;
            return message;
        };

        /**
         * Creates a plain object from an EmergencyAlarmInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {proto.EmergencyAlarmInfo} message EmergencyAlarmInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmergencyAlarmInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.alarmToGroup = false;
                object.monitoring = false;
                object.monitoringDuration = 0;
            }
            if (message.alarmToGroup != null && message.hasOwnProperty("alarmToGroup"))
                object.alarmToGroup = message.alarmToGroup;
            if (message.monitoring != null && message.hasOwnProperty("monitoring"))
                object.monitoring = message.monitoring;
            if (message.monitoringDuration != null && message.hasOwnProperty("monitoringDuration"))
                object.monitoringDuration = message.monitoringDuration;
            return object;
        };

        /**
         * Converts this EmergencyAlarmInfo to JSON.
         * @function toJSON
         * @memberof proto.EmergencyAlarmInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmergencyAlarmInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmergencyAlarmInfo
         * @function getTypeUrl
         * @memberof proto.EmergencyAlarmInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmergencyAlarmInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.EmergencyAlarmInfo";
        };

        return EmergencyAlarmInfo;
    })();

    proto.QueueManagementSubscribeRequest = (function() {

        /**
         * Properties of a QueueManagementSubscribeRequest.
         * @memberof proto
         * @interface IQueueManagementSubscribeRequest
         * @property {string} queueEntityId QueueManagementSubscribeRequest queueEntityId
         */

        /**
         * Constructs a new QueueManagementSubscribeRequest.
         * @memberof proto
         * @classdesc Represents a QueueManagementSubscribeRequest.
         * @implements IQueueManagementSubscribeRequest
         * @constructor
         * @param {proto.IQueueManagementSubscribeRequest=} [properties] Properties to set
         */
        function QueueManagementSubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementSubscribeRequest queueEntityId.
         * @member {string} queueEntityId
         * @memberof proto.QueueManagementSubscribeRequest
         * @instance
         */
        QueueManagementSubscribeRequest.prototype.queueEntityId = "";

        /**
         * Creates a new QueueManagementSubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {proto.IQueueManagementSubscribeRequest=} [properties] Properties to set
         * @returns {proto.QueueManagementSubscribeRequest} QueueManagementSubscribeRequest instance
         */
        QueueManagementSubscribeRequest.create = function create(properties) {
            return new QueueManagementSubscribeRequest(properties);
        };

        /**
         * Encodes the specified QueueManagementSubscribeRequest message. Does not implicitly {@link proto.QueueManagementSubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {proto.IQueueManagementSubscribeRequest} message QueueManagementSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementSubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueEntityId);
            return writer;
        };

        /**
         * Encodes the specified QueueManagementSubscribeRequest message, length delimited. Does not implicitly {@link proto.QueueManagementSubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {proto.IQueueManagementSubscribeRequest} message QueueManagementSubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementSubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementSubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementSubscribeRequest} QueueManagementSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementSubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementSubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.queueEntityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("queueEntityId"))
                throw $util.ProtocolError("missing required 'queueEntityId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementSubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementSubscribeRequest} QueueManagementSubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementSubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementSubscribeRequest message.
         * @function verify
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementSubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.queueEntityId))
                return "queueEntityId: string expected";
            return null;
        };

        /**
         * Creates a QueueManagementSubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementSubscribeRequest} QueueManagementSubscribeRequest
         */
        QueueManagementSubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementSubscribeRequest)
                return object;
            var message = new $root.proto.QueueManagementSubscribeRequest();
            if (object.queueEntityId != null)
                message.queueEntityId = String(object.queueEntityId);
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementSubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {proto.QueueManagementSubscribeRequest} message QueueManagementSubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementSubscribeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.queueEntityId = "";
            if (message.queueEntityId != null && message.hasOwnProperty("queueEntityId"))
                object.queueEntityId = message.queueEntityId;
            return object;
        };

        /**
         * Converts this QueueManagementSubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementSubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementSubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementSubscribeRequest
         * @function getTypeUrl
         * @memberof proto.QueueManagementSubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementSubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementSubscribeRequest";
        };

        return QueueManagementSubscribeRequest;
    })();

    proto.QueueManagementSubscribeResponse = (function() {

        /**
         * Properties of a QueueManagementSubscribeResponse.
         * @memberof proto
         * @interface IQueueManagementSubscribeResponse
         * @property {number|Long} subscriptionId QueueManagementSubscribeResponse subscriptionId
         * @property {Array.<proto.IQueueEntry>|null} [currentEntries] QueueManagementSubscribeResponse currentEntries
         */

        /**
         * Constructs a new QueueManagementSubscribeResponse.
         * @memberof proto
         * @classdesc Represents a QueueManagementSubscribeResponse.
         * @implements IQueueManagementSubscribeResponse
         * @constructor
         * @param {proto.IQueueManagementSubscribeResponse=} [properties] Properties to set
         */
        function QueueManagementSubscribeResponse(properties) {
            this.currentEntries = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementSubscribeResponse subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.QueueManagementSubscribeResponse
         * @instance
         */
        QueueManagementSubscribeResponse.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QueueManagementSubscribeResponse currentEntries.
         * @member {Array.<proto.IQueueEntry>} currentEntries
         * @memberof proto.QueueManagementSubscribeResponse
         * @instance
         */
        QueueManagementSubscribeResponse.prototype.currentEntries = $util.emptyArray;

        /**
         * Creates a new QueueManagementSubscribeResponse instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {proto.IQueueManagementSubscribeResponse=} [properties] Properties to set
         * @returns {proto.QueueManagementSubscribeResponse} QueueManagementSubscribeResponse instance
         */
        QueueManagementSubscribeResponse.create = function create(properties) {
            return new QueueManagementSubscribeResponse(properties);
        };

        /**
         * Encodes the specified QueueManagementSubscribeResponse message. Does not implicitly {@link proto.QueueManagementSubscribeResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {proto.IQueueManagementSubscribeResponse} message QueueManagementSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementSubscribeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.currentEntries != null && message.currentEntries.length)
                for (var i = 0; i < message.currentEntries.length; ++i)
                    $root.proto.QueueEntry.encode(message.currentEntries[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueueManagementSubscribeResponse message, length delimited. Does not implicitly {@link proto.QueueManagementSubscribeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {proto.IQueueManagementSubscribeResponse} message QueueManagementSubscribeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementSubscribeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementSubscribeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementSubscribeResponse} QueueManagementSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementSubscribeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementSubscribeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.currentEntries && message.currentEntries.length))
                            message.currentEntries = [];
                        message.currentEntries.push($root.proto.QueueEntry.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementSubscribeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementSubscribeResponse} QueueManagementSubscribeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementSubscribeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementSubscribeResponse message.
         * @function verify
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementSubscribeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.currentEntries != null && message.hasOwnProperty("currentEntries")) {
                if (!Array.isArray(message.currentEntries))
                    return "currentEntries: array expected";
                for (var i = 0; i < message.currentEntries.length; ++i) {
                    var error = $root.proto.QueueEntry.verify(message.currentEntries[i]);
                    if (error)
                        return "currentEntries." + error;
                }
            }
            return null;
        };

        /**
         * Creates a QueueManagementSubscribeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementSubscribeResponse} QueueManagementSubscribeResponse
         */
        QueueManagementSubscribeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementSubscribeResponse)
                return object;
            var message = new $root.proto.QueueManagementSubscribeResponse();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.currentEntries) {
                if (!Array.isArray(object.currentEntries))
                    throw TypeError(".proto.QueueManagementSubscribeResponse.currentEntries: array expected");
                message.currentEntries = [];
                for (var i = 0; i < object.currentEntries.length; ++i) {
                    if (typeof object.currentEntries[i] !== "object")
                        throw TypeError(".proto.QueueManagementSubscribeResponse.currentEntries: object expected");
                    message.currentEntries[i] = $root.proto.QueueEntry.fromObject(object.currentEntries[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementSubscribeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {proto.QueueManagementSubscribeResponse} message QueueManagementSubscribeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementSubscribeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.currentEntries = [];
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.currentEntries && message.currentEntries.length) {
                object.currentEntries = [];
                for (var j = 0; j < message.currentEntries.length; ++j)
                    object.currentEntries[j] = $root.proto.QueueEntry.toObject(message.currentEntries[j], options);
            }
            return object;
        };

        /**
         * Converts this QueueManagementSubscribeResponse to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementSubscribeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementSubscribeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementSubscribeResponse
         * @function getTypeUrl
         * @memberof proto.QueueManagementSubscribeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementSubscribeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementSubscribeResponse";
        };

        return QueueManagementSubscribeResponse;
    })();

    proto.QueueEntry = (function() {

        /**
         * Properties of a QueueEntry.
         * @memberof proto
         * @interface IQueueEntry
         * @property {string} queueEntryEntityId QueueEntry queueEntryEntityId
         * @property {number|Long} opened QueueEntry opened
         * @property {number|Long|null} [closed] QueueEntry closed
         * @property {string|null} [pickedBy] QueueEntry pickedBy
         * @property {boolean|null} [pickedByMe] QueueEntry pickedByMe
         * @property {string} name QueueEntry name
         * @property {string|null} [title] QueueEntry title
         * @property {number} numRequests QueueEntry numRequests
         * @property {string|null} [userEntityId] QueueEntry userEntityId
         * @property {string|null} [deviceEntityId] QueueEntry deviceEntityId
         * @property {string|null} [callableReference] QueueEntry callableReference
         * @property {proto.ILocation|null} [lastLocation] QueueEntry lastLocation
         * @property {string|null} [monitorEntityId] QueueEntry monitorEntityId
         * @property {string|null} [channelId] QueueEntry channelId
         * @property {string|null} [channelName] QueueEntry channelName
         * @property {string|null} [channelUuid] QueueEntry channelUuid
         * @property {string|null} [userUuid] QueueEntry userUuid
         * @property {string|null} [queueEntryUuid] QueueEntry queueEntryUuid
         * @property {string|null} [queueUuid] QueueEntry queueUuid
         * @property {string|null} [pickedByUuid] QueueEntry pickedByUuid
         */

        /**
         * Constructs a new QueueEntry.
         * @memberof proto
         * @classdesc Represents a QueueEntry.
         * @implements IQueueEntry
         * @constructor
         * @param {proto.IQueueEntry=} [properties] Properties to set
         */
        function QueueEntry(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueEntry queueEntryEntityId.
         * @member {string} queueEntryEntityId
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.queueEntryEntityId = "";

        /**
         * QueueEntry opened.
         * @member {number|Long} opened
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.opened = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QueueEntry closed.
         * @member {number|Long} closed
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.closed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QueueEntry pickedBy.
         * @member {string} pickedBy
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.pickedBy = "";

        /**
         * QueueEntry pickedByMe.
         * @member {boolean} pickedByMe
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.pickedByMe = false;

        /**
         * QueueEntry name.
         * @member {string} name
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.name = "";

        /**
         * QueueEntry title.
         * @member {string} title
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.title = "";

        /**
         * QueueEntry numRequests.
         * @member {number} numRequests
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.numRequests = 0;

        /**
         * QueueEntry userEntityId.
         * @member {string} userEntityId
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.userEntityId = "";

        /**
         * QueueEntry deviceEntityId.
         * @member {string} deviceEntityId
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.deviceEntityId = "";

        /**
         * QueueEntry callableReference.
         * @member {string} callableReference
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.callableReference = "";

        /**
         * QueueEntry lastLocation.
         * @member {proto.ILocation|null|undefined} lastLocation
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.lastLocation = null;

        /**
         * QueueEntry monitorEntityId.
         * @member {string} monitorEntityId
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.monitorEntityId = "";

        /**
         * QueueEntry channelId.
         * @member {string} channelId
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.channelId = "";

        /**
         * QueueEntry channelName.
         * @member {string} channelName
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.channelName = "";

        /**
         * QueueEntry channelUuid.
         * @member {string} channelUuid
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.channelUuid = "";

        /**
         * QueueEntry userUuid.
         * @member {string} userUuid
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.userUuid = "";

        /**
         * QueueEntry queueEntryUuid.
         * @member {string} queueEntryUuid
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.queueEntryUuid = "";

        /**
         * QueueEntry queueUuid.
         * @member {string} queueUuid
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.queueUuid = "";

        /**
         * QueueEntry pickedByUuid.
         * @member {string} pickedByUuid
         * @memberof proto.QueueEntry
         * @instance
         */
        QueueEntry.prototype.pickedByUuid = "";

        /**
         * Creates a new QueueEntry instance using the specified properties.
         * @function create
         * @memberof proto.QueueEntry
         * @static
         * @param {proto.IQueueEntry=} [properties] Properties to set
         * @returns {proto.QueueEntry} QueueEntry instance
         */
        QueueEntry.create = function create(properties) {
            return new QueueEntry(properties);
        };

        /**
         * Encodes the specified QueueEntry message. Does not implicitly {@link proto.QueueEntry.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueEntry
         * @static
         * @param {proto.IQueueEntry} message QueueEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueEntryEntityId);
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.opened);
            if (message.closed != null && Object.hasOwnProperty.call(message, "closed"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.closed);
            if (message.pickedBy != null && Object.hasOwnProperty.call(message, "pickedBy"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.pickedBy);
            if (message.pickedByMe != null && Object.hasOwnProperty.call(message, "pickedByMe"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.pickedByMe);
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.title);
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.numRequests);
            if (message.deviceEntityId != null && Object.hasOwnProperty.call(message, "deviceEntityId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.deviceEntityId);
            if (message.callableReference != null && Object.hasOwnProperty.call(message, "callableReference"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.callableReference);
            if (message.lastLocation != null && Object.hasOwnProperty.call(message, "lastLocation"))
                $root.proto.Location.encode(message.lastLocation, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.userEntityId != null && Object.hasOwnProperty.call(message, "userEntityId"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.userEntityId);
            if (message.monitorEntityId != null && Object.hasOwnProperty.call(message, "monitorEntityId"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.monitorEntityId);
            if (message.channelId != null && Object.hasOwnProperty.call(message, "channelId"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.channelId);
            if (message.channelName != null && Object.hasOwnProperty.call(message, "channelName"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.channelName);
            if (message.channelUuid != null && Object.hasOwnProperty.call(message, "channelUuid"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.channelUuid);
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.userUuid);
            if (message.queueEntryUuid != null && Object.hasOwnProperty.call(message, "queueEntryUuid"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.queueEntryUuid);
            if (message.queueUuid != null && Object.hasOwnProperty.call(message, "queueUuid"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.queueUuid);
            if (message.pickedByUuid != null && Object.hasOwnProperty.call(message, "pickedByUuid"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.pickedByUuid);
            return writer;
        };

        /**
         * Encodes the specified QueueEntry message, length delimited. Does not implicitly {@link proto.QueueEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueEntry
         * @static
         * @param {proto.IQueueEntry} message QueueEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueEntry message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueEntry} QueueEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueEntry();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.queueEntryEntityId = reader.string();
                        break;
                    }
                case 2: {
                        message.opened = reader.int64();
                        break;
                    }
                case 3: {
                        message.closed = reader.int64();
                        break;
                    }
                case 4: {
                        message.pickedBy = reader.string();
                        break;
                    }
                case 5: {
                        message.pickedByMe = reader.bool();
                        break;
                    }
                case 6: {
                        message.name = reader.string();
                        break;
                    }
                case 7: {
                        message.title = reader.string();
                        break;
                    }
                case 8: {
                        message.numRequests = reader.int32();
                        break;
                    }
                case 12: {
                        message.userEntityId = reader.string();
                        break;
                    }
                case 9: {
                        message.deviceEntityId = reader.string();
                        break;
                    }
                case 10: {
                        message.callableReference = reader.string();
                        break;
                    }
                case 11: {
                        message.lastLocation = $root.proto.Location.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.monitorEntityId = reader.string();
                        break;
                    }
                case 14: {
                        message.channelId = reader.string();
                        break;
                    }
                case 15: {
                        message.channelName = reader.string();
                        break;
                    }
                case 16: {
                        message.channelUuid = reader.string();
                        break;
                    }
                case 17: {
                        message.userUuid = reader.string();
                        break;
                    }
                case 18: {
                        message.queueEntryUuid = reader.string();
                        break;
                    }
                case 19: {
                        message.queueUuid = reader.string();
                        break;
                    }
                case 20: {
                        message.pickedByUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("queueEntryEntityId"))
                throw $util.ProtocolError("missing required 'queueEntryEntityId'", { instance: message });
            if (!message.hasOwnProperty("opened"))
                throw $util.ProtocolError("missing required 'opened'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("numRequests"))
                throw $util.ProtocolError("missing required 'numRequests'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueEntry} QueueEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueEntry message.
         * @function verify
         * @memberof proto.QueueEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.queueEntryEntityId))
                return "queueEntryEntityId: string expected";
            if (!$util.isInteger(message.opened) && !(message.opened && $util.isInteger(message.opened.low) && $util.isInteger(message.opened.high)))
                return "opened: integer|Long expected";
            if (message.closed != null && message.hasOwnProperty("closed"))
                if (!$util.isInteger(message.closed) && !(message.closed && $util.isInteger(message.closed.low) && $util.isInteger(message.closed.high)))
                    return "closed: integer|Long expected";
            if (message.pickedBy != null && message.hasOwnProperty("pickedBy"))
                if (!$util.isString(message.pickedBy))
                    return "pickedBy: string expected";
            if (message.pickedByMe != null && message.hasOwnProperty("pickedByMe"))
                if (typeof message.pickedByMe !== "boolean")
                    return "pickedByMe: boolean expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (!$util.isInteger(message.numRequests))
                return "numRequests: integer expected";
            if (message.userEntityId != null && message.hasOwnProperty("userEntityId"))
                if (!$util.isString(message.userEntityId))
                    return "userEntityId: string expected";
            if (message.deviceEntityId != null && message.hasOwnProperty("deviceEntityId"))
                if (!$util.isString(message.deviceEntityId))
                    return "deviceEntityId: string expected";
            if (message.callableReference != null && message.hasOwnProperty("callableReference"))
                if (!$util.isString(message.callableReference))
                    return "callableReference: string expected";
            if (message.lastLocation != null && message.hasOwnProperty("lastLocation")) {
                var error = $root.proto.Location.verify(message.lastLocation);
                if (error)
                    return "lastLocation." + error;
            }
            if (message.monitorEntityId != null && message.hasOwnProperty("monitorEntityId"))
                if (!$util.isString(message.monitorEntityId))
                    return "monitorEntityId: string expected";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (!$util.isString(message.channelId))
                    return "channelId: string expected";
            if (message.channelName != null && message.hasOwnProperty("channelName"))
                if (!$util.isString(message.channelName))
                    return "channelName: string expected";
            if (message.channelUuid != null && message.hasOwnProperty("channelUuid"))
                if (!$util.isString(message.channelUuid))
                    return "channelUuid: string expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            if (message.queueEntryUuid != null && message.hasOwnProperty("queueEntryUuid"))
                if (!$util.isString(message.queueEntryUuid))
                    return "queueEntryUuid: string expected";
            if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                if (!$util.isString(message.queueUuid))
                    return "queueUuid: string expected";
            if (message.pickedByUuid != null && message.hasOwnProperty("pickedByUuid"))
                if (!$util.isString(message.pickedByUuid))
                    return "pickedByUuid: string expected";
            return null;
        };

        /**
         * Creates a QueueEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueEntry} QueueEntry
         */
        QueueEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueEntry)
                return object;
            var message = new $root.proto.QueueEntry();
            if (object.queueEntryEntityId != null)
                message.queueEntryEntityId = String(object.queueEntryEntityId);
            if (object.opened != null)
                if ($util.Long)
                    (message.opened = $util.Long.fromValue(object.opened)).unsigned = false;
                else if (typeof object.opened === "string")
                    message.opened = parseInt(object.opened, 10);
                else if (typeof object.opened === "number")
                    message.opened = object.opened;
                else if (typeof object.opened === "object")
                    message.opened = new $util.LongBits(object.opened.low >>> 0, object.opened.high >>> 0).toNumber();
            if (object.closed != null)
                if ($util.Long)
                    (message.closed = $util.Long.fromValue(object.closed)).unsigned = false;
                else if (typeof object.closed === "string")
                    message.closed = parseInt(object.closed, 10);
                else if (typeof object.closed === "number")
                    message.closed = object.closed;
                else if (typeof object.closed === "object")
                    message.closed = new $util.LongBits(object.closed.low >>> 0, object.closed.high >>> 0).toNumber();
            if (object.pickedBy != null)
                message.pickedBy = String(object.pickedBy);
            if (object.pickedByMe != null)
                message.pickedByMe = Boolean(object.pickedByMe);
            if (object.name != null)
                message.name = String(object.name);
            if (object.title != null)
                message.title = String(object.title);
            if (object.numRequests != null)
                message.numRequests = object.numRequests | 0;
            if (object.userEntityId != null)
                message.userEntityId = String(object.userEntityId);
            if (object.deviceEntityId != null)
                message.deviceEntityId = String(object.deviceEntityId);
            if (object.callableReference != null)
                message.callableReference = String(object.callableReference);
            if (object.lastLocation != null) {
                if (typeof object.lastLocation !== "object")
                    throw TypeError(".proto.QueueEntry.lastLocation: object expected");
                message.lastLocation = $root.proto.Location.fromObject(object.lastLocation);
            }
            if (object.monitorEntityId != null)
                message.monitorEntityId = String(object.monitorEntityId);
            if (object.channelId != null)
                message.channelId = String(object.channelId);
            if (object.channelName != null)
                message.channelName = String(object.channelName);
            if (object.channelUuid != null)
                message.channelUuid = String(object.channelUuid);
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            if (object.queueEntryUuid != null)
                message.queueEntryUuid = String(object.queueEntryUuid);
            if (object.queueUuid != null)
                message.queueUuid = String(object.queueUuid);
            if (object.pickedByUuid != null)
                message.pickedByUuid = String(object.pickedByUuid);
            return message;
        };

        /**
         * Creates a plain object from a QueueEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueEntry
         * @static
         * @param {proto.QueueEntry} message QueueEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.queueEntryEntityId = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.opened = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.opened = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.closed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.closed = options.longs === String ? "0" : 0;
                object.pickedBy = "";
                object.pickedByMe = false;
                object.name = "";
                object.title = "";
                object.numRequests = 0;
                object.deviceEntityId = "";
                object.callableReference = "";
                object.lastLocation = null;
                object.userEntityId = "";
                object.monitorEntityId = "";
                object.channelId = "";
                object.channelName = "";
                object.channelUuid = "";
                object.userUuid = "";
                object.queueEntryUuid = "";
                object.queueUuid = "";
                object.pickedByUuid = "";
            }
            if (message.queueEntryEntityId != null && message.hasOwnProperty("queueEntryEntityId"))
                object.queueEntryEntityId = message.queueEntryEntityId;
            if (message.opened != null && message.hasOwnProperty("opened"))
                if (typeof message.opened === "number")
                    object.opened = options.longs === String ? String(message.opened) : message.opened;
                else
                    object.opened = options.longs === String ? $util.Long.prototype.toString.call(message.opened) : options.longs === Number ? new $util.LongBits(message.opened.low >>> 0, message.opened.high >>> 0).toNumber() : message.opened;
            if (message.closed != null && message.hasOwnProperty("closed"))
                if (typeof message.closed === "number")
                    object.closed = options.longs === String ? String(message.closed) : message.closed;
                else
                    object.closed = options.longs === String ? $util.Long.prototype.toString.call(message.closed) : options.longs === Number ? new $util.LongBits(message.closed.low >>> 0, message.closed.high >>> 0).toNumber() : message.closed;
            if (message.pickedBy != null && message.hasOwnProperty("pickedBy"))
                object.pickedBy = message.pickedBy;
            if (message.pickedByMe != null && message.hasOwnProperty("pickedByMe"))
                object.pickedByMe = message.pickedByMe;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.numRequests != null && message.hasOwnProperty("numRequests"))
                object.numRequests = message.numRequests;
            if (message.deviceEntityId != null && message.hasOwnProperty("deviceEntityId"))
                object.deviceEntityId = message.deviceEntityId;
            if (message.callableReference != null && message.hasOwnProperty("callableReference"))
                object.callableReference = message.callableReference;
            if (message.lastLocation != null && message.hasOwnProperty("lastLocation"))
                object.lastLocation = $root.proto.Location.toObject(message.lastLocation, options);
            if (message.userEntityId != null && message.hasOwnProperty("userEntityId"))
                object.userEntityId = message.userEntityId;
            if (message.monitorEntityId != null && message.hasOwnProperty("monitorEntityId"))
                object.monitorEntityId = message.monitorEntityId;
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                object.channelId = message.channelId;
            if (message.channelName != null && message.hasOwnProperty("channelName"))
                object.channelName = message.channelName;
            if (message.channelUuid != null && message.hasOwnProperty("channelUuid"))
                object.channelUuid = message.channelUuid;
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            if (message.queueEntryUuid != null && message.hasOwnProperty("queueEntryUuid"))
                object.queueEntryUuid = message.queueEntryUuid;
            if (message.queueUuid != null && message.hasOwnProperty("queueUuid"))
                object.queueUuid = message.queueUuid;
            if (message.pickedByUuid != null && message.hasOwnProperty("pickedByUuid"))
                object.pickedByUuid = message.pickedByUuid;
            return object;
        };

        /**
         * Converts this QueueEntry to JSON.
         * @function toJSON
         * @memberof proto.QueueEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueEntry
         * @function getTypeUrl
         * @memberof proto.QueueEntry
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueEntry";
        };

        return QueueEntry;
    })();

    proto.QueueManagementUnsubscribeRequest = (function() {

        /**
         * Properties of a QueueManagementUnsubscribeRequest.
         * @memberof proto
         * @interface IQueueManagementUnsubscribeRequest
         * @property {number|Long} subscriptionId QueueManagementUnsubscribeRequest subscriptionId
         */

        /**
         * Constructs a new QueueManagementUnsubscribeRequest.
         * @memberof proto
         * @classdesc Represents a QueueManagementUnsubscribeRequest.
         * @implements IQueueManagementUnsubscribeRequest
         * @constructor
         * @param {proto.IQueueManagementUnsubscribeRequest=} [properties] Properties to set
         */
        function QueueManagementUnsubscribeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementUnsubscribeRequest subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @instance
         */
        QueueManagementUnsubscribeRequest.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new QueueManagementUnsubscribeRequest instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {proto.IQueueManagementUnsubscribeRequest=} [properties] Properties to set
         * @returns {proto.QueueManagementUnsubscribeRequest} QueueManagementUnsubscribeRequest instance
         */
        QueueManagementUnsubscribeRequest.create = function create(properties) {
            return new QueueManagementUnsubscribeRequest(properties);
        };

        /**
         * Encodes the specified QueueManagementUnsubscribeRequest message. Does not implicitly {@link proto.QueueManagementUnsubscribeRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {proto.IQueueManagementUnsubscribeRequest} message QueueManagementUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementUnsubscribeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            return writer;
        };

        /**
         * Encodes the specified QueueManagementUnsubscribeRequest message, length delimited. Does not implicitly {@link proto.QueueManagementUnsubscribeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {proto.IQueueManagementUnsubscribeRequest} message QueueManagementUnsubscribeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementUnsubscribeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementUnsubscribeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementUnsubscribeRequest} QueueManagementUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementUnsubscribeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementUnsubscribeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementUnsubscribeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementUnsubscribeRequest} QueueManagementUnsubscribeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementUnsubscribeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementUnsubscribeRequest message.
         * @function verify
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementUnsubscribeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a QueueManagementUnsubscribeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementUnsubscribeRequest} QueueManagementUnsubscribeRequest
         */
        QueueManagementUnsubscribeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementUnsubscribeRequest)
                return object;
            var message = new $root.proto.QueueManagementUnsubscribeRequest();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementUnsubscribeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {proto.QueueManagementUnsubscribeRequest} message QueueManagementUnsubscribeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementUnsubscribeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            return object;
        };

        /**
         * Converts this QueueManagementUnsubscribeRequest to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementUnsubscribeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementUnsubscribeRequest
         * @function getTypeUrl
         * @memberof proto.QueueManagementUnsubscribeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementUnsubscribeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementUnsubscribeRequest";
        };

        return QueueManagementUnsubscribeRequest;
    })();

    proto.QueueManagementDelta = (function() {

        /**
         * Properties of a QueueManagementDelta.
         * @memberof proto
         * @interface IQueueManagementDelta
         * @property {number|Long} subscriptionId QueueManagementDelta subscriptionId
         * @property {Array.<proto.IQueueEntry>|null} [updatedEntries] QueueManagementDelta updatedEntries
         */

        /**
         * Constructs a new QueueManagementDelta.
         * @memberof proto
         * @classdesc Represents a QueueManagementDelta.
         * @implements IQueueManagementDelta
         * @constructor
         * @param {proto.IQueueManagementDelta=} [properties] Properties to set
         */
        function QueueManagementDelta(properties) {
            this.updatedEntries = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementDelta subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof proto.QueueManagementDelta
         * @instance
         */
        QueueManagementDelta.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QueueManagementDelta updatedEntries.
         * @member {Array.<proto.IQueueEntry>} updatedEntries
         * @memberof proto.QueueManagementDelta
         * @instance
         */
        QueueManagementDelta.prototype.updatedEntries = $util.emptyArray;

        /**
         * Creates a new QueueManagementDelta instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {proto.IQueueManagementDelta=} [properties] Properties to set
         * @returns {proto.QueueManagementDelta} QueueManagementDelta instance
         */
        QueueManagementDelta.create = function create(properties) {
            return new QueueManagementDelta(properties);
        };

        /**
         * Encodes the specified QueueManagementDelta message. Does not implicitly {@link proto.QueueManagementDelta.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {proto.IQueueManagementDelta} message QueueManagementDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementDelta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.subscriptionId);
            if (message.updatedEntries != null && message.updatedEntries.length)
                for (var i = 0; i < message.updatedEntries.length; ++i)
                    $root.proto.QueueEntry.encode(message.updatedEntries[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueueManagementDelta message, length delimited. Does not implicitly {@link proto.QueueManagementDelta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {proto.IQueueManagementDelta} message QueueManagementDelta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementDelta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementDelta message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementDelta} QueueManagementDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementDelta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementDelta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subscriptionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.updatedEntries && message.updatedEntries.length))
                            message.updatedEntries = [];
                        message.updatedEntries.push($root.proto.QueueEntry.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("subscriptionId"))
                throw $util.ProtocolError("missing required 'subscriptionId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementDelta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementDelta} QueueManagementDelta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementDelta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementDelta message.
         * @function verify
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementDelta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                return "subscriptionId: integer|Long expected";
            if (message.updatedEntries != null && message.hasOwnProperty("updatedEntries")) {
                if (!Array.isArray(message.updatedEntries))
                    return "updatedEntries: array expected";
                for (var i = 0; i < message.updatedEntries.length; ++i) {
                    var error = $root.proto.QueueEntry.verify(message.updatedEntries[i]);
                    if (error)
                        return "updatedEntries." + error;
                }
            }
            return null;
        };

        /**
         * Creates a QueueManagementDelta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementDelta} QueueManagementDelta
         */
        QueueManagementDelta.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementDelta)
                return object;
            var message = new $root.proto.QueueManagementDelta();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = false;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber();
            if (object.updatedEntries) {
                if (!Array.isArray(object.updatedEntries))
                    throw TypeError(".proto.QueueManagementDelta.updatedEntries: array expected");
                message.updatedEntries = [];
                for (var i = 0; i < object.updatedEntries.length; ++i) {
                    if (typeof object.updatedEntries[i] !== "object")
                        throw TypeError(".proto.QueueManagementDelta.updatedEntries: object expected");
                    message.updatedEntries[i] = $root.proto.QueueEntry.fromObject(object.updatedEntries[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementDelta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {proto.QueueManagementDelta} message QueueManagementDelta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementDelta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.updatedEntries = [];
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber() : message.subscriptionId;
            if (message.updatedEntries && message.updatedEntries.length) {
                object.updatedEntries = [];
                for (var j = 0; j < message.updatedEntries.length; ++j)
                    object.updatedEntries[j] = $root.proto.QueueEntry.toObject(message.updatedEntries[j], options);
            }
            return object;
        };

        /**
         * Converts this QueueManagementDelta to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementDelta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementDelta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementDelta
         * @function getTypeUrl
         * @memberof proto.QueueManagementDelta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementDelta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementDelta";
        };

        return QueueManagementDelta;
    })();

    proto.QueueManagementPickRequest = (function() {

        /**
         * Properties of a QueueManagementPickRequest.
         * @memberof proto
         * @interface IQueueManagementPickRequest
         * @property {string} queueEntryEntityId QueueManagementPickRequest queueEntryEntityId
         * @property {boolean|null} [stealIfPicked] QueueManagementPickRequest stealIfPicked
         */

        /**
         * Constructs a new QueueManagementPickRequest.
         * @memberof proto
         * @classdesc Represents a QueueManagementPickRequest.
         * @implements IQueueManagementPickRequest
         * @constructor
         * @param {proto.IQueueManagementPickRequest=} [properties] Properties to set
         */
        function QueueManagementPickRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementPickRequest queueEntryEntityId.
         * @member {string} queueEntryEntityId
         * @memberof proto.QueueManagementPickRequest
         * @instance
         */
        QueueManagementPickRequest.prototype.queueEntryEntityId = "";

        /**
         * QueueManagementPickRequest stealIfPicked.
         * @member {boolean} stealIfPicked
         * @memberof proto.QueueManagementPickRequest
         * @instance
         */
        QueueManagementPickRequest.prototype.stealIfPicked = false;

        /**
         * Creates a new QueueManagementPickRequest instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {proto.IQueueManagementPickRequest=} [properties] Properties to set
         * @returns {proto.QueueManagementPickRequest} QueueManagementPickRequest instance
         */
        QueueManagementPickRequest.create = function create(properties) {
            return new QueueManagementPickRequest(properties);
        };

        /**
         * Encodes the specified QueueManagementPickRequest message. Does not implicitly {@link proto.QueueManagementPickRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {proto.IQueueManagementPickRequest} message QueueManagementPickRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementPickRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueEntryEntityId);
            if (message.stealIfPicked != null && Object.hasOwnProperty.call(message, "stealIfPicked"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.stealIfPicked);
            return writer;
        };

        /**
         * Encodes the specified QueueManagementPickRequest message, length delimited. Does not implicitly {@link proto.QueueManagementPickRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {proto.IQueueManagementPickRequest} message QueueManagementPickRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementPickRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementPickRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementPickRequest} QueueManagementPickRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementPickRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementPickRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.queueEntryEntityId = reader.string();
                        break;
                    }
                case 2: {
                        message.stealIfPicked = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("queueEntryEntityId"))
                throw $util.ProtocolError("missing required 'queueEntryEntityId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementPickRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementPickRequest} QueueManagementPickRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementPickRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementPickRequest message.
         * @function verify
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementPickRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.queueEntryEntityId))
                return "queueEntryEntityId: string expected";
            if (message.stealIfPicked != null && message.hasOwnProperty("stealIfPicked"))
                if (typeof message.stealIfPicked !== "boolean")
                    return "stealIfPicked: boolean expected";
            return null;
        };

        /**
         * Creates a QueueManagementPickRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementPickRequest} QueueManagementPickRequest
         */
        QueueManagementPickRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementPickRequest)
                return object;
            var message = new $root.proto.QueueManagementPickRequest();
            if (object.queueEntryEntityId != null)
                message.queueEntryEntityId = String(object.queueEntryEntityId);
            if (object.stealIfPicked != null)
                message.stealIfPicked = Boolean(object.stealIfPicked);
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementPickRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {proto.QueueManagementPickRequest} message QueueManagementPickRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementPickRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.queueEntryEntityId = "";
                object.stealIfPicked = false;
            }
            if (message.queueEntryEntityId != null && message.hasOwnProperty("queueEntryEntityId"))
                object.queueEntryEntityId = message.queueEntryEntityId;
            if (message.stealIfPicked != null && message.hasOwnProperty("stealIfPicked"))
                object.stealIfPicked = message.stealIfPicked;
            return object;
        };

        /**
         * Converts this QueueManagementPickRequest to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementPickRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementPickRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementPickRequest
         * @function getTypeUrl
         * @memberof proto.QueueManagementPickRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementPickRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementPickRequest";
        };

        return QueueManagementPickRequest;
    })();

    proto.QueueManagementUnpickRequest = (function() {

        /**
         * Properties of a QueueManagementUnpickRequest.
         * @memberof proto
         * @interface IQueueManagementUnpickRequest
         * @property {string} queueEntryEntityId QueueManagementUnpickRequest queueEntryEntityId
         */

        /**
         * Constructs a new QueueManagementUnpickRequest.
         * @memberof proto
         * @classdesc Represents a QueueManagementUnpickRequest.
         * @implements IQueueManagementUnpickRequest
         * @constructor
         * @param {proto.IQueueManagementUnpickRequest=} [properties] Properties to set
         */
        function QueueManagementUnpickRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementUnpickRequest queueEntryEntityId.
         * @member {string} queueEntryEntityId
         * @memberof proto.QueueManagementUnpickRequest
         * @instance
         */
        QueueManagementUnpickRequest.prototype.queueEntryEntityId = "";

        /**
         * Creates a new QueueManagementUnpickRequest instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {proto.IQueueManagementUnpickRequest=} [properties] Properties to set
         * @returns {proto.QueueManagementUnpickRequest} QueueManagementUnpickRequest instance
         */
        QueueManagementUnpickRequest.create = function create(properties) {
            return new QueueManagementUnpickRequest(properties);
        };

        /**
         * Encodes the specified QueueManagementUnpickRequest message. Does not implicitly {@link proto.QueueManagementUnpickRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {proto.IQueueManagementUnpickRequest} message QueueManagementUnpickRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementUnpickRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueEntryEntityId);
            return writer;
        };

        /**
         * Encodes the specified QueueManagementUnpickRequest message, length delimited. Does not implicitly {@link proto.QueueManagementUnpickRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {proto.IQueueManagementUnpickRequest} message QueueManagementUnpickRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementUnpickRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementUnpickRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementUnpickRequest} QueueManagementUnpickRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementUnpickRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementUnpickRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.queueEntryEntityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("queueEntryEntityId"))
                throw $util.ProtocolError("missing required 'queueEntryEntityId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementUnpickRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementUnpickRequest} QueueManagementUnpickRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementUnpickRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementUnpickRequest message.
         * @function verify
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementUnpickRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.queueEntryEntityId))
                return "queueEntryEntityId: string expected";
            return null;
        };

        /**
         * Creates a QueueManagementUnpickRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementUnpickRequest} QueueManagementUnpickRequest
         */
        QueueManagementUnpickRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementUnpickRequest)
                return object;
            var message = new $root.proto.QueueManagementUnpickRequest();
            if (object.queueEntryEntityId != null)
                message.queueEntryEntityId = String(object.queueEntryEntityId);
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementUnpickRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {proto.QueueManagementUnpickRequest} message QueueManagementUnpickRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementUnpickRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.queueEntryEntityId = "";
            if (message.queueEntryEntityId != null && message.hasOwnProperty("queueEntryEntityId"))
                object.queueEntryEntityId = message.queueEntryEntityId;
            return object;
        };

        /**
         * Converts this QueueManagementUnpickRequest to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementUnpickRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementUnpickRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementUnpickRequest
         * @function getTypeUrl
         * @memberof proto.QueueManagementUnpickRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementUnpickRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementUnpickRequest";
        };

        return QueueManagementUnpickRequest;
    })();

    proto.QueueManagementCloseRequest = (function() {

        /**
         * Properties of a QueueManagementCloseRequest.
         * @memberof proto
         * @interface IQueueManagementCloseRequest
         * @property {string} queueEntryEntityId QueueManagementCloseRequest queueEntryEntityId
         */

        /**
         * Constructs a new QueueManagementCloseRequest.
         * @memberof proto
         * @classdesc Represents a QueueManagementCloseRequest.
         * @implements IQueueManagementCloseRequest
         * @constructor
         * @param {proto.IQueueManagementCloseRequest=} [properties] Properties to set
         */
        function QueueManagementCloseRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueueManagementCloseRequest queueEntryEntityId.
         * @member {string} queueEntryEntityId
         * @memberof proto.QueueManagementCloseRequest
         * @instance
         */
        QueueManagementCloseRequest.prototype.queueEntryEntityId = "";

        /**
         * Creates a new QueueManagementCloseRequest instance using the specified properties.
         * @function create
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {proto.IQueueManagementCloseRequest=} [properties] Properties to set
         * @returns {proto.QueueManagementCloseRequest} QueueManagementCloseRequest instance
         */
        QueueManagementCloseRequest.create = function create(properties) {
            return new QueueManagementCloseRequest(properties);
        };

        /**
         * Encodes the specified QueueManagementCloseRequest message. Does not implicitly {@link proto.QueueManagementCloseRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {proto.IQueueManagementCloseRequest} message QueueManagementCloseRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementCloseRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queueEntryEntityId);
            return writer;
        };

        /**
         * Encodes the specified QueueManagementCloseRequest message, length delimited. Does not implicitly {@link proto.QueueManagementCloseRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {proto.IQueueManagementCloseRequest} message QueueManagementCloseRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueueManagementCloseRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueueManagementCloseRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.QueueManagementCloseRequest} QueueManagementCloseRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementCloseRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.QueueManagementCloseRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.queueEntryEntityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("queueEntryEntityId"))
                throw $util.ProtocolError("missing required 'queueEntryEntityId'", { instance: message });
            return message;
        };

        /**
         * Decodes a QueueManagementCloseRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.QueueManagementCloseRequest} QueueManagementCloseRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueueManagementCloseRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueueManagementCloseRequest message.
         * @function verify
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueueManagementCloseRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.queueEntryEntityId))
                return "queueEntryEntityId: string expected";
            return null;
        };

        /**
         * Creates a QueueManagementCloseRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.QueueManagementCloseRequest} QueueManagementCloseRequest
         */
        QueueManagementCloseRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.QueueManagementCloseRequest)
                return object;
            var message = new $root.proto.QueueManagementCloseRequest();
            if (object.queueEntryEntityId != null)
                message.queueEntryEntityId = String(object.queueEntryEntityId);
            return message;
        };

        /**
         * Creates a plain object from a QueueManagementCloseRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {proto.QueueManagementCloseRequest} message QueueManagementCloseRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueueManagementCloseRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.queueEntryEntityId = "";
            if (message.queueEntryEntityId != null && message.hasOwnProperty("queueEntryEntityId"))
                object.queueEntryEntityId = message.queueEntryEntityId;
            return object;
        };

        /**
         * Converts this QueueManagementCloseRequest to JSON.
         * @function toJSON
         * @memberof proto.QueueManagementCloseRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueueManagementCloseRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueueManagementCloseRequest
         * @function getTypeUrl
         * @memberof proto.QueueManagementCloseRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueueManagementCloseRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.QueueManagementCloseRequest";
        };

        return QueueManagementCloseRequest;
    })();

    proto.CallsignAPIv1Client = (function() {

        /**
         * Properties of a CallsignAPIv1Client.
         * @memberof proto
         * @interface ICallsignAPIv1Client
         * @property {proto.ICallsignModuleSetupRequest|null} [setupRequest] CallsignAPIv1Client setupRequest
         * @property {proto.ICallsignSetCallsignRequest|null} [setCallsignRequest] CallsignAPIv1Client setCallsignRequest
         */

        /**
         * Constructs a new CallsignAPIv1Client.
         * @memberof proto
         * @classdesc Represents a CallsignAPIv1Client.
         * @implements ICallsignAPIv1Client
         * @constructor
         * @param {proto.ICallsignAPIv1Client=} [properties] Properties to set
         */
        function CallsignAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignAPIv1Client setupRequest.
         * @member {proto.ICallsignModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.CallsignAPIv1Client
         * @instance
         */
        CallsignAPIv1Client.prototype.setupRequest = null;

        /**
         * CallsignAPIv1Client setCallsignRequest.
         * @member {proto.ICallsignSetCallsignRequest|null|undefined} setCallsignRequest
         * @memberof proto.CallsignAPIv1Client
         * @instance
         */
        CallsignAPIv1Client.prototype.setCallsignRequest = null;

        /**
         * Creates a new CallsignAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {proto.ICallsignAPIv1Client=} [properties] Properties to set
         * @returns {proto.CallsignAPIv1Client} CallsignAPIv1Client instance
         */
        CallsignAPIv1Client.create = function create(properties) {
            return new CallsignAPIv1Client(properties);
        };

        /**
         * Encodes the specified CallsignAPIv1Client message. Does not implicitly {@link proto.CallsignAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {proto.ICallsignAPIv1Client} message CallsignAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.CallsignModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.setCallsignRequest != null && Object.hasOwnProperty.call(message, "setCallsignRequest"))
                $root.proto.CallsignSetCallsignRequest.encode(message.setCallsignRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CallsignAPIv1Client message, length delimited. Does not implicitly {@link proto.CallsignAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {proto.ICallsignAPIv1Client} message CallsignAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignAPIv1Client} CallsignAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.CallsignModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.setCallsignRequest = $root.proto.CallsignSetCallsignRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignAPIv1Client} CallsignAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignAPIv1Client message.
         * @function verify
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.CallsignModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.setCallsignRequest != null && message.hasOwnProperty("setCallsignRequest")) {
                var error = $root.proto.CallsignSetCallsignRequest.verify(message.setCallsignRequest);
                if (error)
                    return "setCallsignRequest." + error;
            }
            return null;
        };

        /**
         * Creates a CallsignAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignAPIv1Client} CallsignAPIv1Client
         */
        CallsignAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignAPIv1Client)
                return object;
            var message = new $root.proto.CallsignAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.CallsignAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.CallsignModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.setCallsignRequest != null) {
                if (typeof object.setCallsignRequest !== "object")
                    throw TypeError(".proto.CallsignAPIv1Client.setCallsignRequest: object expected");
                message.setCallsignRequest = $root.proto.CallsignSetCallsignRequest.fromObject(object.setCallsignRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a CallsignAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {proto.CallsignAPIv1Client} message CallsignAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.setCallsignRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.CallsignModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.setCallsignRequest != null && message.hasOwnProperty("setCallsignRequest"))
                object.setCallsignRequest = $root.proto.CallsignSetCallsignRequest.toObject(message.setCallsignRequest, options);
            return object;
        };

        /**
         * Converts this CallsignAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.CallsignAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignAPIv1Client
         * @function getTypeUrl
         * @memberof proto.CallsignAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignAPIv1Client";
        };

        return CallsignAPIv1Client;
    })();

    proto.CallsignModuleSetupRequest = (function() {

        /**
         * Properties of a CallsignModuleSetupRequest.
         * @memberof proto
         * @interface ICallsignModuleSetupRequest
         */

        /**
         * Constructs a new CallsignModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a CallsignModuleSetupRequest.
         * @implements ICallsignModuleSetupRequest
         * @constructor
         * @param {proto.ICallsignModuleSetupRequest=} [properties] Properties to set
         */
        function CallsignModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CallsignModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {proto.ICallsignModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.CallsignModuleSetupRequest} CallsignModuleSetupRequest instance
         */
        CallsignModuleSetupRequest.create = function create(properties) {
            return new CallsignModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified CallsignModuleSetupRequest message. Does not implicitly {@link proto.CallsignModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {proto.ICallsignModuleSetupRequest} message CallsignModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CallsignModuleSetupRequest message, length delimited. Does not implicitly {@link proto.CallsignModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {proto.ICallsignModuleSetupRequest} message CallsignModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignModuleSetupRequest} CallsignModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignModuleSetupRequest} CallsignModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignModuleSetupRequest message.
         * @function verify
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CallsignModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignModuleSetupRequest} CallsignModuleSetupRequest
         */
        CallsignModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignModuleSetupRequest)
                return object;
            return new $root.proto.CallsignModuleSetupRequest();
        };

        /**
         * Creates a plain object from a CallsignModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {proto.CallsignModuleSetupRequest} message CallsignModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CallsignModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.CallsignModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.CallsignModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignModuleSetupRequest";
        };

        return CallsignModuleSetupRequest;
    })();

    proto.CallsignModuleSetupResponse = (function() {

        /**
         * Properties of a CallsignModuleSetupResponse.
         * @memberof proto
         * @interface ICallsignModuleSetupResponse
         * @property {string} callsignLabel CallsignModuleSetupResponse callsignLabel
         * @property {string|null} [currentCallsign] CallsignModuleSetupResponse currentCallsign
         */

        /**
         * Constructs a new CallsignModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a CallsignModuleSetupResponse.
         * @implements ICallsignModuleSetupResponse
         * @constructor
         * @param {proto.ICallsignModuleSetupResponse=} [properties] Properties to set
         */
        function CallsignModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignModuleSetupResponse callsignLabel.
         * @member {string} callsignLabel
         * @memberof proto.CallsignModuleSetupResponse
         * @instance
         */
        CallsignModuleSetupResponse.prototype.callsignLabel = "";

        /**
         * CallsignModuleSetupResponse currentCallsign.
         * @member {string} currentCallsign
         * @memberof proto.CallsignModuleSetupResponse
         * @instance
         */
        CallsignModuleSetupResponse.prototype.currentCallsign = "";

        /**
         * Creates a new CallsignModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {proto.ICallsignModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.CallsignModuleSetupResponse} CallsignModuleSetupResponse instance
         */
        CallsignModuleSetupResponse.create = function create(properties) {
            return new CallsignModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified CallsignModuleSetupResponse message. Does not implicitly {@link proto.CallsignModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {proto.ICallsignModuleSetupResponse} message CallsignModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callsignLabel);
            if (message.currentCallsign != null && Object.hasOwnProperty.call(message, "currentCallsign"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currentCallsign);
            return writer;
        };

        /**
         * Encodes the specified CallsignModuleSetupResponse message, length delimited. Does not implicitly {@link proto.CallsignModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {proto.ICallsignModuleSetupResponse} message CallsignModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignModuleSetupResponse} CallsignModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callsignLabel = reader.string();
                        break;
                    }
                case 2: {
                        message.currentCallsign = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("callsignLabel"))
                throw $util.ProtocolError("missing required 'callsignLabel'", { instance: message });
            return message;
        };

        /**
         * Decodes a CallsignModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignModuleSetupResponse} CallsignModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignModuleSetupResponse message.
         * @function verify
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.callsignLabel))
                return "callsignLabel: string expected";
            if (message.currentCallsign != null && message.hasOwnProperty("currentCallsign"))
                if (!$util.isString(message.currentCallsign))
                    return "currentCallsign: string expected";
            return null;
        };

        /**
         * Creates a CallsignModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignModuleSetupResponse} CallsignModuleSetupResponse
         */
        CallsignModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignModuleSetupResponse)
                return object;
            var message = new $root.proto.CallsignModuleSetupResponse();
            if (object.callsignLabel != null)
                message.callsignLabel = String(object.callsignLabel);
            if (object.currentCallsign != null)
                message.currentCallsign = String(object.currentCallsign);
            return message;
        };

        /**
         * Creates a plain object from a CallsignModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {proto.CallsignModuleSetupResponse} message CallsignModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.callsignLabel = "";
                object.currentCallsign = "";
            }
            if (message.callsignLabel != null && message.hasOwnProperty("callsignLabel"))
                object.callsignLabel = message.callsignLabel;
            if (message.currentCallsign != null && message.hasOwnProperty("currentCallsign"))
                object.currentCallsign = message.currentCallsign;
            return object;
        };

        /**
         * Converts this CallsignModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.CallsignModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.CallsignModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignModuleSetupResponse";
        };

        return CallsignModuleSetupResponse;
    })();

    proto.CallsignSetCallsignRequest = (function() {

        /**
         * Properties of a CallsignSetCallsignRequest.
         * @memberof proto
         * @interface ICallsignSetCallsignRequest
         * @property {string} callsign CallsignSetCallsignRequest callsign
         */

        /**
         * Constructs a new CallsignSetCallsignRequest.
         * @memberof proto
         * @classdesc Represents a CallsignSetCallsignRequest.
         * @implements ICallsignSetCallsignRequest
         * @constructor
         * @param {proto.ICallsignSetCallsignRequest=} [properties] Properties to set
         */
        function CallsignSetCallsignRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignSetCallsignRequest callsign.
         * @member {string} callsign
         * @memberof proto.CallsignSetCallsignRequest
         * @instance
         */
        CallsignSetCallsignRequest.prototype.callsign = "";

        /**
         * Creates a new CallsignSetCallsignRequest instance using the specified properties.
         * @function create
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {proto.ICallsignSetCallsignRequest=} [properties] Properties to set
         * @returns {proto.CallsignSetCallsignRequest} CallsignSetCallsignRequest instance
         */
        CallsignSetCallsignRequest.create = function create(properties) {
            return new CallsignSetCallsignRequest(properties);
        };

        /**
         * Encodes the specified CallsignSetCallsignRequest message. Does not implicitly {@link proto.CallsignSetCallsignRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {proto.ICallsignSetCallsignRequest} message CallsignSetCallsignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignSetCallsignRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callsign);
            return writer;
        };

        /**
         * Encodes the specified CallsignSetCallsignRequest message, length delimited. Does not implicitly {@link proto.CallsignSetCallsignRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {proto.ICallsignSetCallsignRequest} message CallsignSetCallsignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignSetCallsignRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignSetCallsignRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignSetCallsignRequest} CallsignSetCallsignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignSetCallsignRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignSetCallsignRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callsign = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("callsign"))
                throw $util.ProtocolError("missing required 'callsign'", { instance: message });
            return message;
        };

        /**
         * Decodes a CallsignSetCallsignRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignSetCallsignRequest} CallsignSetCallsignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignSetCallsignRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignSetCallsignRequest message.
         * @function verify
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignSetCallsignRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.callsign))
                return "callsign: string expected";
            return null;
        };

        /**
         * Creates a CallsignSetCallsignRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignSetCallsignRequest} CallsignSetCallsignRequest
         */
        CallsignSetCallsignRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignSetCallsignRequest)
                return object;
            var message = new $root.proto.CallsignSetCallsignRequest();
            if (object.callsign != null)
                message.callsign = String(object.callsign);
            return message;
        };

        /**
         * Creates a plain object from a CallsignSetCallsignRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {proto.CallsignSetCallsignRequest} message CallsignSetCallsignRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignSetCallsignRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.callsign = "";
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                object.callsign = message.callsign;
            return object;
        };

        /**
         * Converts this CallsignSetCallsignRequest to JSON.
         * @function toJSON
         * @memberof proto.CallsignSetCallsignRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignSetCallsignRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignSetCallsignRequest
         * @function getTypeUrl
         * @memberof proto.CallsignSetCallsignRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignSetCallsignRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignSetCallsignRequest";
        };

        return CallsignSetCallsignRequest;
    })();

    proto.CallsignSetCallsignResponse = (function() {

        /**
         * Properties of a CallsignSetCallsignResponse.
         * @memberof proto
         * @interface ICallsignSetCallsignResponse
         * @property {string|null} [displayName] CallsignSetCallsignResponse displayName
         * @property {string|null} [title] CallsignSetCallsignResponse title
         */

        /**
         * Constructs a new CallsignSetCallsignResponse.
         * @memberof proto
         * @classdesc Represents a CallsignSetCallsignResponse.
         * @implements ICallsignSetCallsignResponse
         * @constructor
         * @param {proto.ICallsignSetCallsignResponse=} [properties] Properties to set
         */
        function CallsignSetCallsignResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignSetCallsignResponse displayName.
         * @member {string} displayName
         * @memberof proto.CallsignSetCallsignResponse
         * @instance
         */
        CallsignSetCallsignResponse.prototype.displayName = "";

        /**
         * CallsignSetCallsignResponse title.
         * @member {string} title
         * @memberof proto.CallsignSetCallsignResponse
         * @instance
         */
        CallsignSetCallsignResponse.prototype.title = "";

        /**
         * Creates a new CallsignSetCallsignResponse instance using the specified properties.
         * @function create
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {proto.ICallsignSetCallsignResponse=} [properties] Properties to set
         * @returns {proto.CallsignSetCallsignResponse} CallsignSetCallsignResponse instance
         */
        CallsignSetCallsignResponse.create = function create(properties) {
            return new CallsignSetCallsignResponse(properties);
        };

        /**
         * Encodes the specified CallsignSetCallsignResponse message. Does not implicitly {@link proto.CallsignSetCallsignResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {proto.ICallsignSetCallsignResponse} message CallsignSetCallsignResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignSetCallsignResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Encodes the specified CallsignSetCallsignResponse message, length delimited. Does not implicitly {@link proto.CallsignSetCallsignResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {proto.ICallsignSetCallsignResponse} message CallsignSetCallsignResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignSetCallsignResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignSetCallsignResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignSetCallsignResponse} CallsignSetCallsignResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignSetCallsignResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignSetCallsignResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.displayName = reader.string();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignSetCallsignResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignSetCallsignResponse} CallsignSetCallsignResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignSetCallsignResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignSetCallsignResponse message.
         * @function verify
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignSetCallsignResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            return null;
        };

        /**
         * Creates a CallsignSetCallsignResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignSetCallsignResponse} CallsignSetCallsignResponse
         */
        CallsignSetCallsignResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignSetCallsignResponse)
                return object;
            var message = new $root.proto.CallsignSetCallsignResponse();
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.title != null)
                message.title = String(object.title);
            return message;
        };

        /**
         * Creates a plain object from a CallsignSetCallsignResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {proto.CallsignSetCallsignResponse} message CallsignSetCallsignResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignSetCallsignResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.displayName = "";
                object.title = "";
            }
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            return object;
        };

        /**
         * Converts this CallsignSetCallsignResponse to JSON.
         * @function toJSON
         * @memberof proto.CallsignSetCallsignResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignSetCallsignResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignSetCallsignResponse
         * @function getTypeUrl
         * @memberof proto.CallsignSetCallsignResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignSetCallsignResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignSetCallsignResponse";
        };

        return CallsignSetCallsignResponse;
    })();

    proto.CallsignAPIv2Client = (function() {

        /**
         * Properties of a CallsignAPIv2Client.
         * @memberof proto
         * @interface ICallsignAPIv2Client
         * @property {proto.ICallsignV2ModuleSetupRequest|null} [setupRequest] CallsignAPIv2Client setupRequest
         * @property {proto.ICallsignV2SetCallsignRequest|null} [setCallsignRequest] CallsignAPIv2Client setCallsignRequest
         * @property {proto.ICallsignV2GetCallsignRequest|null} [getCallsignRequest] CallsignAPIv2Client getCallsignRequest
         */

        /**
         * Constructs a new CallsignAPIv2Client.
         * @memberof proto
         * @classdesc Represents a CallsignAPIv2Client.
         * @implements ICallsignAPIv2Client
         * @constructor
         * @param {proto.ICallsignAPIv2Client=} [properties] Properties to set
         */
        function CallsignAPIv2Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignAPIv2Client setupRequest.
         * @member {proto.ICallsignV2ModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.CallsignAPIv2Client
         * @instance
         */
        CallsignAPIv2Client.prototype.setupRequest = null;

        /**
         * CallsignAPIv2Client setCallsignRequest.
         * @member {proto.ICallsignV2SetCallsignRequest|null|undefined} setCallsignRequest
         * @memberof proto.CallsignAPIv2Client
         * @instance
         */
        CallsignAPIv2Client.prototype.setCallsignRequest = null;

        /**
         * CallsignAPIv2Client getCallsignRequest.
         * @member {proto.ICallsignV2GetCallsignRequest|null|undefined} getCallsignRequest
         * @memberof proto.CallsignAPIv2Client
         * @instance
         */
        CallsignAPIv2Client.prototype.getCallsignRequest = null;

        /**
         * Creates a new CallsignAPIv2Client instance using the specified properties.
         * @function create
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {proto.ICallsignAPIv2Client=} [properties] Properties to set
         * @returns {proto.CallsignAPIv2Client} CallsignAPIv2Client instance
         */
        CallsignAPIv2Client.create = function create(properties) {
            return new CallsignAPIv2Client(properties);
        };

        /**
         * Encodes the specified CallsignAPIv2Client message. Does not implicitly {@link proto.CallsignAPIv2Client.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {proto.ICallsignAPIv2Client} message CallsignAPIv2Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignAPIv2Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.CallsignV2ModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.setCallsignRequest != null && Object.hasOwnProperty.call(message, "setCallsignRequest"))
                $root.proto.CallsignV2SetCallsignRequest.encode(message.setCallsignRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.getCallsignRequest != null && Object.hasOwnProperty.call(message, "getCallsignRequest"))
                $root.proto.CallsignV2GetCallsignRequest.encode(message.getCallsignRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CallsignAPIv2Client message, length delimited. Does not implicitly {@link proto.CallsignAPIv2Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {proto.ICallsignAPIv2Client} message CallsignAPIv2Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignAPIv2Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignAPIv2Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignAPIv2Client} CallsignAPIv2Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignAPIv2Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignAPIv2Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.CallsignV2ModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.setCallsignRequest = $root.proto.CallsignV2SetCallsignRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.getCallsignRequest = $root.proto.CallsignV2GetCallsignRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignAPIv2Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignAPIv2Client} CallsignAPIv2Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignAPIv2Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignAPIv2Client message.
         * @function verify
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignAPIv2Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.CallsignV2ModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.setCallsignRequest != null && message.hasOwnProperty("setCallsignRequest")) {
                var error = $root.proto.CallsignV2SetCallsignRequest.verify(message.setCallsignRequest);
                if (error)
                    return "setCallsignRequest." + error;
            }
            if (message.getCallsignRequest != null && message.hasOwnProperty("getCallsignRequest")) {
                var error = $root.proto.CallsignV2GetCallsignRequest.verify(message.getCallsignRequest);
                if (error)
                    return "getCallsignRequest." + error;
            }
            return null;
        };

        /**
         * Creates a CallsignAPIv2Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignAPIv2Client} CallsignAPIv2Client
         */
        CallsignAPIv2Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignAPIv2Client)
                return object;
            var message = new $root.proto.CallsignAPIv2Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.CallsignAPIv2Client.setupRequest: object expected");
                message.setupRequest = $root.proto.CallsignV2ModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.setCallsignRequest != null) {
                if (typeof object.setCallsignRequest !== "object")
                    throw TypeError(".proto.CallsignAPIv2Client.setCallsignRequest: object expected");
                message.setCallsignRequest = $root.proto.CallsignV2SetCallsignRequest.fromObject(object.setCallsignRequest);
            }
            if (object.getCallsignRequest != null) {
                if (typeof object.getCallsignRequest !== "object")
                    throw TypeError(".proto.CallsignAPIv2Client.getCallsignRequest: object expected");
                message.getCallsignRequest = $root.proto.CallsignV2GetCallsignRequest.fromObject(object.getCallsignRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a CallsignAPIv2Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {proto.CallsignAPIv2Client} message CallsignAPIv2Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignAPIv2Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.setCallsignRequest = null;
                object.getCallsignRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.CallsignV2ModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.setCallsignRequest != null && message.hasOwnProperty("setCallsignRequest"))
                object.setCallsignRequest = $root.proto.CallsignV2SetCallsignRequest.toObject(message.setCallsignRequest, options);
            if (message.getCallsignRequest != null && message.hasOwnProperty("getCallsignRequest"))
                object.getCallsignRequest = $root.proto.CallsignV2GetCallsignRequest.toObject(message.getCallsignRequest, options);
            return object;
        };

        /**
         * Converts this CallsignAPIv2Client to JSON.
         * @function toJSON
         * @memberof proto.CallsignAPIv2Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignAPIv2Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignAPIv2Client
         * @function getTypeUrl
         * @memberof proto.CallsignAPIv2Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignAPIv2Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignAPIv2Client";
        };

        return CallsignAPIv2Client;
    })();

    proto.CallsignV2ModuleSetupRequest = (function() {

        /**
         * Properties of a CallsignV2ModuleSetupRequest.
         * @memberof proto
         * @interface ICallsignV2ModuleSetupRequest
         */

        /**
         * Constructs a new CallsignV2ModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a CallsignV2ModuleSetupRequest.
         * @implements ICallsignV2ModuleSetupRequest
         * @constructor
         * @param {proto.ICallsignV2ModuleSetupRequest=} [properties] Properties to set
         */
        function CallsignV2ModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CallsignV2ModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {proto.ICallsignV2ModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.CallsignV2ModuleSetupRequest} CallsignV2ModuleSetupRequest instance
         */
        CallsignV2ModuleSetupRequest.create = function create(properties) {
            return new CallsignV2ModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified CallsignV2ModuleSetupRequest message. Does not implicitly {@link proto.CallsignV2ModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {proto.ICallsignV2ModuleSetupRequest} message CallsignV2ModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2ModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CallsignV2ModuleSetupRequest message, length delimited. Does not implicitly {@link proto.CallsignV2ModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {proto.ICallsignV2ModuleSetupRequest} message CallsignV2ModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2ModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignV2ModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignV2ModuleSetupRequest} CallsignV2ModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2ModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignV2ModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignV2ModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignV2ModuleSetupRequest} CallsignV2ModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2ModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignV2ModuleSetupRequest message.
         * @function verify
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignV2ModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CallsignV2ModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignV2ModuleSetupRequest} CallsignV2ModuleSetupRequest
         */
        CallsignV2ModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignV2ModuleSetupRequest)
                return object;
            return new $root.proto.CallsignV2ModuleSetupRequest();
        };

        /**
         * Creates a plain object from a CallsignV2ModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {proto.CallsignV2ModuleSetupRequest} message CallsignV2ModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignV2ModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CallsignV2ModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignV2ModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignV2ModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.CallsignV2ModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignV2ModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignV2ModuleSetupRequest";
        };

        return CallsignV2ModuleSetupRequest;
    })();

    proto.CallsignV2ModuleSetupResponse = (function() {

        /**
         * Properties of a CallsignV2ModuleSetupResponse.
         * @memberof proto
         * @interface ICallsignV2ModuleSetupResponse
         * @property {string|null} [callsignLabel] CallsignV2ModuleSetupResponse callsignLabel
         * @property {string|null} [currentCallsign] CallsignV2ModuleSetupResponse currentCallsign
         */

        /**
         * Constructs a new CallsignV2ModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a CallsignV2ModuleSetupResponse.
         * @implements ICallsignV2ModuleSetupResponse
         * @constructor
         * @param {proto.ICallsignV2ModuleSetupResponse=} [properties] Properties to set
         */
        function CallsignV2ModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignV2ModuleSetupResponse callsignLabel.
         * @member {string} callsignLabel
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @instance
         */
        CallsignV2ModuleSetupResponse.prototype.callsignLabel = "";

        /**
         * CallsignV2ModuleSetupResponse currentCallsign.
         * @member {string} currentCallsign
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @instance
         */
        CallsignV2ModuleSetupResponse.prototype.currentCallsign = "";

        /**
         * Creates a new CallsignV2ModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {proto.ICallsignV2ModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.CallsignV2ModuleSetupResponse} CallsignV2ModuleSetupResponse instance
         */
        CallsignV2ModuleSetupResponse.create = function create(properties) {
            return new CallsignV2ModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified CallsignV2ModuleSetupResponse message. Does not implicitly {@link proto.CallsignV2ModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {proto.ICallsignV2ModuleSetupResponse} message CallsignV2ModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2ModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.callsignLabel != null && Object.hasOwnProperty.call(message, "callsignLabel"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.callsignLabel);
            if (message.currentCallsign != null && Object.hasOwnProperty.call(message, "currentCallsign"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currentCallsign);
            return writer;
        };

        /**
         * Encodes the specified CallsignV2ModuleSetupResponse message, length delimited. Does not implicitly {@link proto.CallsignV2ModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {proto.ICallsignV2ModuleSetupResponse} message CallsignV2ModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2ModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignV2ModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignV2ModuleSetupResponse} CallsignV2ModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2ModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignV2ModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callsignLabel = reader.string();
                        break;
                    }
                case 2: {
                        message.currentCallsign = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignV2ModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignV2ModuleSetupResponse} CallsignV2ModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2ModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignV2ModuleSetupResponse message.
         * @function verify
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignV2ModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.callsignLabel != null && message.hasOwnProperty("callsignLabel"))
                if (!$util.isString(message.callsignLabel))
                    return "callsignLabel: string expected";
            if (message.currentCallsign != null && message.hasOwnProperty("currentCallsign"))
                if (!$util.isString(message.currentCallsign))
                    return "currentCallsign: string expected";
            return null;
        };

        /**
         * Creates a CallsignV2ModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignV2ModuleSetupResponse} CallsignV2ModuleSetupResponse
         */
        CallsignV2ModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignV2ModuleSetupResponse)
                return object;
            var message = new $root.proto.CallsignV2ModuleSetupResponse();
            if (object.callsignLabel != null)
                message.callsignLabel = String(object.callsignLabel);
            if (object.currentCallsign != null)
                message.currentCallsign = String(object.currentCallsign);
            return message;
        };

        /**
         * Creates a plain object from a CallsignV2ModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {proto.CallsignV2ModuleSetupResponse} message CallsignV2ModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignV2ModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.callsignLabel = "";
                object.currentCallsign = "";
            }
            if (message.callsignLabel != null && message.hasOwnProperty("callsignLabel"))
                object.callsignLabel = message.callsignLabel;
            if (message.currentCallsign != null && message.hasOwnProperty("currentCallsign"))
                object.currentCallsign = message.currentCallsign;
            return object;
        };

        /**
         * Converts this CallsignV2ModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignV2ModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignV2ModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.CallsignV2ModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignV2ModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignV2ModuleSetupResponse";
        };

        return CallsignV2ModuleSetupResponse;
    })();

    proto.CallsignV2SetCallsignRequest = (function() {

        /**
         * Properties of a CallsignV2SetCallsignRequest.
         * @memberof proto
         * @interface ICallsignV2SetCallsignRequest
         * @property {string|null} [callsign] CallsignV2SetCallsignRequest callsign
         * @property {string|null} [userUuid] CallsignV2SetCallsignRequest userUuid
         * @property {string|null} [entityId] CallsignV2SetCallsignRequest entityId
         */

        /**
         * Constructs a new CallsignV2SetCallsignRequest.
         * @memberof proto
         * @classdesc Represents a CallsignV2SetCallsignRequest.
         * @implements ICallsignV2SetCallsignRequest
         * @constructor
         * @param {proto.ICallsignV2SetCallsignRequest=} [properties] Properties to set
         */
        function CallsignV2SetCallsignRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignV2SetCallsignRequest callsign.
         * @member {string} callsign
         * @memberof proto.CallsignV2SetCallsignRequest
         * @instance
         */
        CallsignV2SetCallsignRequest.prototype.callsign = "";

        /**
         * CallsignV2SetCallsignRequest userUuid.
         * @member {string} userUuid
         * @memberof proto.CallsignV2SetCallsignRequest
         * @instance
         */
        CallsignV2SetCallsignRequest.prototype.userUuid = "";

        /**
         * CallsignV2SetCallsignRequest entityId.
         * @member {string} entityId
         * @memberof proto.CallsignV2SetCallsignRequest
         * @instance
         */
        CallsignV2SetCallsignRequest.prototype.entityId = "";

        /**
         * Creates a new CallsignV2SetCallsignRequest instance using the specified properties.
         * @function create
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {proto.ICallsignV2SetCallsignRequest=} [properties] Properties to set
         * @returns {proto.CallsignV2SetCallsignRequest} CallsignV2SetCallsignRequest instance
         */
        CallsignV2SetCallsignRequest.create = function create(properties) {
            return new CallsignV2SetCallsignRequest(properties);
        };

        /**
         * Encodes the specified CallsignV2SetCallsignRequest message. Does not implicitly {@link proto.CallsignV2SetCallsignRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {proto.ICallsignV2SetCallsignRequest} message CallsignV2SetCallsignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2SetCallsignRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.callsign != null && Object.hasOwnProperty.call(message, "callsign"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.callsign);
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userUuid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.entityId);
            return writer;
        };

        /**
         * Encodes the specified CallsignV2SetCallsignRequest message, length delimited. Does not implicitly {@link proto.CallsignV2SetCallsignRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {proto.ICallsignV2SetCallsignRequest} message CallsignV2SetCallsignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2SetCallsignRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignV2SetCallsignRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignV2SetCallsignRequest} CallsignV2SetCallsignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2SetCallsignRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignV2SetCallsignRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callsign = reader.string();
                        break;
                    }
                case 2: {
                        message.userUuid = reader.string();
                        break;
                    }
                case 3: {
                        message.entityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignV2SetCallsignRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignV2SetCallsignRequest} CallsignV2SetCallsignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2SetCallsignRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignV2SetCallsignRequest message.
         * @function verify
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignV2SetCallsignRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                if (!$util.isString(message.callsign))
                    return "callsign: string expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isString(message.entityId))
                    return "entityId: string expected";
            return null;
        };

        /**
         * Creates a CallsignV2SetCallsignRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignV2SetCallsignRequest} CallsignV2SetCallsignRequest
         */
        CallsignV2SetCallsignRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignV2SetCallsignRequest)
                return object;
            var message = new $root.proto.CallsignV2SetCallsignRequest();
            if (object.callsign != null)
                message.callsign = String(object.callsign);
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            return message;
        };

        /**
         * Creates a plain object from a CallsignV2SetCallsignRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {proto.CallsignV2SetCallsignRequest} message CallsignV2SetCallsignRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignV2SetCallsignRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.callsign = "";
                object.userUuid = "";
                object.entityId = "";
            }
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                object.callsign = message.callsign;
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            return object;
        };

        /**
         * Converts this CallsignV2SetCallsignRequest to JSON.
         * @function toJSON
         * @memberof proto.CallsignV2SetCallsignRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignV2SetCallsignRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignV2SetCallsignRequest
         * @function getTypeUrl
         * @memberof proto.CallsignV2SetCallsignRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignV2SetCallsignRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignV2SetCallsignRequest";
        };

        return CallsignV2SetCallsignRequest;
    })();

    proto.CallsignV2GetCallsignRequest = (function() {

        /**
         * Properties of a CallsignV2GetCallsignRequest.
         * @memberof proto
         * @interface ICallsignV2GetCallsignRequest
         * @property {string|null} [userUuid] CallsignV2GetCallsignRequest userUuid
         * @property {string|null} [entityId] CallsignV2GetCallsignRequest entityId
         */

        /**
         * Constructs a new CallsignV2GetCallsignRequest.
         * @memberof proto
         * @classdesc Represents a CallsignV2GetCallsignRequest.
         * @implements ICallsignV2GetCallsignRequest
         * @constructor
         * @param {proto.ICallsignV2GetCallsignRequest=} [properties] Properties to set
         */
        function CallsignV2GetCallsignRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignV2GetCallsignRequest userUuid.
         * @member {string} userUuid
         * @memberof proto.CallsignV2GetCallsignRequest
         * @instance
         */
        CallsignV2GetCallsignRequest.prototype.userUuid = "";

        /**
         * CallsignV2GetCallsignRequest entityId.
         * @member {string} entityId
         * @memberof proto.CallsignV2GetCallsignRequest
         * @instance
         */
        CallsignV2GetCallsignRequest.prototype.entityId = "";

        /**
         * Creates a new CallsignV2GetCallsignRequest instance using the specified properties.
         * @function create
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {proto.ICallsignV2GetCallsignRequest=} [properties] Properties to set
         * @returns {proto.CallsignV2GetCallsignRequest} CallsignV2GetCallsignRequest instance
         */
        CallsignV2GetCallsignRequest.create = function create(properties) {
            return new CallsignV2GetCallsignRequest(properties);
        };

        /**
         * Encodes the specified CallsignV2GetCallsignRequest message. Does not implicitly {@link proto.CallsignV2GetCallsignRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {proto.ICallsignV2GetCallsignRequest} message CallsignV2GetCallsignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2GetCallsignRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUuid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.entityId);
            return writer;
        };

        /**
         * Encodes the specified CallsignV2GetCallsignRequest message, length delimited. Does not implicitly {@link proto.CallsignV2GetCallsignRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {proto.ICallsignV2GetCallsignRequest} message CallsignV2GetCallsignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2GetCallsignRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignV2GetCallsignRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignV2GetCallsignRequest} CallsignV2GetCallsignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2GetCallsignRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignV2GetCallsignRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userUuid = reader.string();
                        break;
                    }
                case 2: {
                        message.entityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignV2GetCallsignRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignV2GetCallsignRequest} CallsignV2GetCallsignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2GetCallsignRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignV2GetCallsignRequest message.
         * @function verify
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignV2GetCallsignRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isString(message.entityId))
                    return "entityId: string expected";
            return null;
        };

        /**
         * Creates a CallsignV2GetCallsignRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignV2GetCallsignRequest} CallsignV2GetCallsignRequest
         */
        CallsignV2GetCallsignRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignV2GetCallsignRequest)
                return object;
            var message = new $root.proto.CallsignV2GetCallsignRequest();
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            return message;
        };

        /**
         * Creates a plain object from a CallsignV2GetCallsignRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {proto.CallsignV2GetCallsignRequest} message CallsignV2GetCallsignRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignV2GetCallsignRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userUuid = "";
                object.entityId = "";
            }
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            return object;
        };

        /**
         * Converts this CallsignV2GetCallsignRequest to JSON.
         * @function toJSON
         * @memberof proto.CallsignV2GetCallsignRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignV2GetCallsignRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignV2GetCallsignRequest
         * @function getTypeUrl
         * @memberof proto.CallsignV2GetCallsignRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignV2GetCallsignRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignV2GetCallsignRequest";
        };

        return CallsignV2GetCallsignRequest;
    })();

    proto.CallsignV2GetCallsignResponse = (function() {

        /**
         * Properties of a CallsignV2GetCallsignResponse.
         * @memberof proto
         * @interface ICallsignV2GetCallsignResponse
         * @property {string} callsignLabel CallsignV2GetCallsignResponse callsignLabel
         * @property {string|null} [callsign] CallsignV2GetCallsignResponse callsign
         */

        /**
         * Constructs a new CallsignV2GetCallsignResponse.
         * @memberof proto
         * @classdesc Represents a CallsignV2GetCallsignResponse.
         * @implements ICallsignV2GetCallsignResponse
         * @constructor
         * @param {proto.ICallsignV2GetCallsignResponse=} [properties] Properties to set
         */
        function CallsignV2GetCallsignResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignV2GetCallsignResponse callsignLabel.
         * @member {string} callsignLabel
         * @memberof proto.CallsignV2GetCallsignResponse
         * @instance
         */
        CallsignV2GetCallsignResponse.prototype.callsignLabel = "";

        /**
         * CallsignV2GetCallsignResponse callsign.
         * @member {string} callsign
         * @memberof proto.CallsignV2GetCallsignResponse
         * @instance
         */
        CallsignV2GetCallsignResponse.prototype.callsign = "";

        /**
         * Creates a new CallsignV2GetCallsignResponse instance using the specified properties.
         * @function create
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {proto.ICallsignV2GetCallsignResponse=} [properties] Properties to set
         * @returns {proto.CallsignV2GetCallsignResponse} CallsignV2GetCallsignResponse instance
         */
        CallsignV2GetCallsignResponse.create = function create(properties) {
            return new CallsignV2GetCallsignResponse(properties);
        };

        /**
         * Encodes the specified CallsignV2GetCallsignResponse message. Does not implicitly {@link proto.CallsignV2GetCallsignResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {proto.ICallsignV2GetCallsignResponse} message CallsignV2GetCallsignResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2GetCallsignResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.callsignLabel);
            if (message.callsign != null && Object.hasOwnProperty.call(message, "callsign"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.callsign);
            return writer;
        };

        /**
         * Encodes the specified CallsignV2GetCallsignResponse message, length delimited. Does not implicitly {@link proto.CallsignV2GetCallsignResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {proto.ICallsignV2GetCallsignResponse} message CallsignV2GetCallsignResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2GetCallsignResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignV2GetCallsignResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignV2GetCallsignResponse} CallsignV2GetCallsignResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2GetCallsignResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignV2GetCallsignResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callsignLabel = reader.string();
                        break;
                    }
                case 2: {
                        message.callsign = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("callsignLabel"))
                throw $util.ProtocolError("missing required 'callsignLabel'", { instance: message });
            return message;
        };

        /**
         * Decodes a CallsignV2GetCallsignResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignV2GetCallsignResponse} CallsignV2GetCallsignResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2GetCallsignResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignV2GetCallsignResponse message.
         * @function verify
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignV2GetCallsignResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.callsignLabel))
                return "callsignLabel: string expected";
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                if (!$util.isString(message.callsign))
                    return "callsign: string expected";
            return null;
        };

        /**
         * Creates a CallsignV2GetCallsignResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignV2GetCallsignResponse} CallsignV2GetCallsignResponse
         */
        CallsignV2GetCallsignResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignV2GetCallsignResponse)
                return object;
            var message = new $root.proto.CallsignV2GetCallsignResponse();
            if (object.callsignLabel != null)
                message.callsignLabel = String(object.callsignLabel);
            if (object.callsign != null)
                message.callsign = String(object.callsign);
            return message;
        };

        /**
         * Creates a plain object from a CallsignV2GetCallsignResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {proto.CallsignV2GetCallsignResponse} message CallsignV2GetCallsignResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignV2GetCallsignResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.callsignLabel = "";
                object.callsign = "";
            }
            if (message.callsignLabel != null && message.hasOwnProperty("callsignLabel"))
                object.callsignLabel = message.callsignLabel;
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                object.callsign = message.callsign;
            return object;
        };

        /**
         * Converts this CallsignV2GetCallsignResponse to JSON.
         * @function toJSON
         * @memberof proto.CallsignV2GetCallsignResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignV2GetCallsignResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignV2GetCallsignResponse
         * @function getTypeUrl
         * @memberof proto.CallsignV2GetCallsignResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignV2GetCallsignResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignV2GetCallsignResponse";
        };

        return CallsignV2GetCallsignResponse;
    })();

    proto.CallsignAPIv2Server = (function() {

        /**
         * Properties of a CallsignAPIv2Server.
         * @memberof proto
         * @interface ICallsignAPIv2Server
         * @property {proto.ICallsignV2CallsignChangedRequest|null} [callsignChangedRequest] CallsignAPIv2Server callsignChangedRequest
         */

        /**
         * Constructs a new CallsignAPIv2Server.
         * @memberof proto
         * @classdesc Represents a CallsignAPIv2Server.
         * @implements ICallsignAPIv2Server
         * @constructor
         * @param {proto.ICallsignAPIv2Server=} [properties] Properties to set
         */
        function CallsignAPIv2Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignAPIv2Server callsignChangedRequest.
         * @member {proto.ICallsignV2CallsignChangedRequest|null|undefined} callsignChangedRequest
         * @memberof proto.CallsignAPIv2Server
         * @instance
         */
        CallsignAPIv2Server.prototype.callsignChangedRequest = null;

        /**
         * Creates a new CallsignAPIv2Server instance using the specified properties.
         * @function create
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {proto.ICallsignAPIv2Server=} [properties] Properties to set
         * @returns {proto.CallsignAPIv2Server} CallsignAPIv2Server instance
         */
        CallsignAPIv2Server.create = function create(properties) {
            return new CallsignAPIv2Server(properties);
        };

        /**
         * Encodes the specified CallsignAPIv2Server message. Does not implicitly {@link proto.CallsignAPIv2Server.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {proto.ICallsignAPIv2Server} message CallsignAPIv2Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignAPIv2Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.callsignChangedRequest != null && Object.hasOwnProperty.call(message, "callsignChangedRequest"))
                $root.proto.CallsignV2CallsignChangedRequest.encode(message.callsignChangedRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CallsignAPIv2Server message, length delimited. Does not implicitly {@link proto.CallsignAPIv2Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {proto.ICallsignAPIv2Server} message CallsignAPIv2Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignAPIv2Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignAPIv2Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignAPIv2Server} CallsignAPIv2Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignAPIv2Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignAPIv2Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.callsignChangedRequest = $root.proto.CallsignV2CallsignChangedRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignAPIv2Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignAPIv2Server} CallsignAPIv2Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignAPIv2Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignAPIv2Server message.
         * @function verify
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignAPIv2Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.callsignChangedRequest != null && message.hasOwnProperty("callsignChangedRequest")) {
                var error = $root.proto.CallsignV2CallsignChangedRequest.verify(message.callsignChangedRequest);
                if (error)
                    return "callsignChangedRequest." + error;
            }
            return null;
        };

        /**
         * Creates a CallsignAPIv2Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignAPIv2Server} CallsignAPIv2Server
         */
        CallsignAPIv2Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignAPIv2Server)
                return object;
            var message = new $root.proto.CallsignAPIv2Server();
            if (object.callsignChangedRequest != null) {
                if (typeof object.callsignChangedRequest !== "object")
                    throw TypeError(".proto.CallsignAPIv2Server.callsignChangedRequest: object expected");
                message.callsignChangedRequest = $root.proto.CallsignV2CallsignChangedRequest.fromObject(object.callsignChangedRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a CallsignAPIv2Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {proto.CallsignAPIv2Server} message CallsignAPIv2Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignAPIv2Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.callsignChangedRequest = null;
            if (message.callsignChangedRequest != null && message.hasOwnProperty("callsignChangedRequest"))
                object.callsignChangedRequest = $root.proto.CallsignV2CallsignChangedRequest.toObject(message.callsignChangedRequest, options);
            return object;
        };

        /**
         * Converts this CallsignAPIv2Server to JSON.
         * @function toJSON
         * @memberof proto.CallsignAPIv2Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignAPIv2Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignAPIv2Server
         * @function getTypeUrl
         * @memberof proto.CallsignAPIv2Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignAPIv2Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignAPIv2Server";
        };

        return CallsignAPIv2Server;
    })();

    proto.CallsignV2CallsignChangedRequest = (function() {

        /**
         * Properties of a CallsignV2CallsignChangedRequest.
         * @memberof proto
         * @interface ICallsignV2CallsignChangedRequest
         * @property {string|null} [displayName] CallsignV2CallsignChangedRequest displayName
         * @property {string|null} [title] CallsignV2CallsignChangedRequest title
         * @property {string|null} [callsign] CallsignV2CallsignChangedRequest callsign
         */

        /**
         * Constructs a new CallsignV2CallsignChangedRequest.
         * @memberof proto
         * @classdesc Represents a CallsignV2CallsignChangedRequest.
         * @implements ICallsignV2CallsignChangedRequest
         * @constructor
         * @param {proto.ICallsignV2CallsignChangedRequest=} [properties] Properties to set
         */
        function CallsignV2CallsignChangedRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CallsignV2CallsignChangedRequest displayName.
         * @member {string} displayName
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @instance
         */
        CallsignV2CallsignChangedRequest.prototype.displayName = "";

        /**
         * CallsignV2CallsignChangedRequest title.
         * @member {string} title
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @instance
         */
        CallsignV2CallsignChangedRequest.prototype.title = "";

        /**
         * CallsignV2CallsignChangedRequest callsign.
         * @member {string} callsign
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @instance
         */
        CallsignV2CallsignChangedRequest.prototype.callsign = "";

        /**
         * Creates a new CallsignV2CallsignChangedRequest instance using the specified properties.
         * @function create
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {proto.ICallsignV2CallsignChangedRequest=} [properties] Properties to set
         * @returns {proto.CallsignV2CallsignChangedRequest} CallsignV2CallsignChangedRequest instance
         */
        CallsignV2CallsignChangedRequest.create = function create(properties) {
            return new CallsignV2CallsignChangedRequest(properties);
        };

        /**
         * Encodes the specified CallsignV2CallsignChangedRequest message. Does not implicitly {@link proto.CallsignV2CallsignChangedRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {proto.ICallsignV2CallsignChangedRequest} message CallsignV2CallsignChangedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2CallsignChangedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.displayName);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.callsign != null && Object.hasOwnProperty.call(message, "callsign"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.callsign);
            return writer;
        };

        /**
         * Encodes the specified CallsignV2CallsignChangedRequest message, length delimited. Does not implicitly {@link proto.CallsignV2CallsignChangedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {proto.ICallsignV2CallsignChangedRequest} message CallsignV2CallsignChangedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CallsignV2CallsignChangedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CallsignV2CallsignChangedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.CallsignV2CallsignChangedRequest} CallsignV2CallsignChangedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2CallsignChangedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.CallsignV2CallsignChangedRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.displayName = reader.string();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.callsign = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CallsignV2CallsignChangedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.CallsignV2CallsignChangedRequest} CallsignV2CallsignChangedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CallsignV2CallsignChangedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CallsignV2CallsignChangedRequest message.
         * @function verify
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CallsignV2CallsignChangedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                if (!$util.isString(message.callsign))
                    return "callsign: string expected";
            return null;
        };

        /**
         * Creates a CallsignV2CallsignChangedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.CallsignV2CallsignChangedRequest} CallsignV2CallsignChangedRequest
         */
        CallsignV2CallsignChangedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.CallsignV2CallsignChangedRequest)
                return object;
            var message = new $root.proto.CallsignV2CallsignChangedRequest();
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.title != null)
                message.title = String(object.title);
            if (object.callsign != null)
                message.callsign = String(object.callsign);
            return message;
        };

        /**
         * Creates a plain object from a CallsignV2CallsignChangedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {proto.CallsignV2CallsignChangedRequest} message CallsignV2CallsignChangedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CallsignV2CallsignChangedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.displayName = "";
                object.title = "";
                object.callsign = "";
            }
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.callsign != null && message.hasOwnProperty("callsign"))
                object.callsign = message.callsign;
            return object;
        };

        /**
         * Converts this CallsignV2CallsignChangedRequest to JSON.
         * @function toJSON
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CallsignV2CallsignChangedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CallsignV2CallsignChangedRequest
         * @function getTypeUrl
         * @memberof proto.CallsignV2CallsignChangedRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CallsignV2CallsignChangedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.CallsignV2CallsignChangedRequest";
        };

        return CallsignV2CallsignChangedRequest;
    })();

    proto.WebappAPIv1Client = (function() {

        /**
         * Properties of a WebappAPIv1Client.
         * @memberof proto
         * @interface IWebappAPIv1Client
         * @property {proto.IWebappModuleSetupRequest|null} [setupRequest] WebappAPIv1Client setupRequest
         */

        /**
         * Constructs a new WebappAPIv1Client.
         * @memberof proto
         * @classdesc Represents a WebappAPIv1Client.
         * @implements IWebappAPIv1Client
         * @constructor
         * @param {proto.IWebappAPIv1Client=} [properties] Properties to set
         */
        function WebappAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebappAPIv1Client setupRequest.
         * @member {proto.IWebappModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.WebappAPIv1Client
         * @instance
         */
        WebappAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new WebappAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {proto.IWebappAPIv1Client=} [properties] Properties to set
         * @returns {proto.WebappAPIv1Client} WebappAPIv1Client instance
         */
        WebappAPIv1Client.create = function create(properties) {
            return new WebappAPIv1Client(properties);
        };

        /**
         * Encodes the specified WebappAPIv1Client message. Does not implicitly {@link proto.WebappAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {proto.IWebappAPIv1Client} message WebappAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.WebappModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WebappAPIv1Client message, length delimited. Does not implicitly {@link proto.WebappAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {proto.IWebappAPIv1Client} message WebappAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebappAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.WebappAPIv1Client} WebappAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.WebappAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.WebappModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WebappAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.WebappAPIv1Client} WebappAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebappAPIv1Client message.
         * @function verify
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebappAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.WebappModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a WebappAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.WebappAPIv1Client} WebappAPIv1Client
         */
        WebappAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.WebappAPIv1Client)
                return object;
            var message = new $root.proto.WebappAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.WebappAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.WebappModuleSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a WebappAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {proto.WebappAPIv1Client} message WebappAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebappAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.WebappModuleSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this WebappAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.WebappAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebappAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebappAPIv1Client
         * @function getTypeUrl
         * @memberof proto.WebappAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebappAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.WebappAPIv1Client";
        };

        return WebappAPIv1Client;
    })();

    proto.WebappModuleSetupRequest = (function() {

        /**
         * Properties of a WebappModuleSetupRequest.
         * @memberof proto
         * @interface IWebappModuleSetupRequest
         */

        /**
         * Constructs a new WebappModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a WebappModuleSetupRequest.
         * @implements IWebappModuleSetupRequest
         * @constructor
         * @param {proto.IWebappModuleSetupRequest=} [properties] Properties to set
         */
        function WebappModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new WebappModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {proto.IWebappModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.WebappModuleSetupRequest} WebappModuleSetupRequest instance
         */
        WebappModuleSetupRequest.create = function create(properties) {
            return new WebappModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified WebappModuleSetupRequest message. Does not implicitly {@link proto.WebappModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {proto.IWebappModuleSetupRequest} message WebappModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified WebappModuleSetupRequest message, length delimited. Does not implicitly {@link proto.WebappModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {proto.IWebappModuleSetupRequest} message WebappModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebappModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.WebappModuleSetupRequest} WebappModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.WebappModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WebappModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.WebappModuleSetupRequest} WebappModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebappModuleSetupRequest message.
         * @function verify
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebappModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a WebappModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.WebappModuleSetupRequest} WebappModuleSetupRequest
         */
        WebappModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.WebappModuleSetupRequest)
                return object;
            return new $root.proto.WebappModuleSetupRequest();
        };

        /**
         * Creates a plain object from a WebappModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {proto.WebappModuleSetupRequest} message WebappModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebappModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this WebappModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.WebappModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebappModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebappModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.WebappModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebappModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.WebappModuleSetupRequest";
        };

        return WebappModuleSetupRequest;
    })();

    proto.WebappModuleSetupResponse = (function() {

        /**
         * Properties of a WebappModuleSetupResponse.
         * @memberof proto
         * @interface IWebappModuleSetupResponse
         * @property {Array.<proto.IWebappInfo>|null} [webapps] WebappModuleSetupResponse webapps
         */

        /**
         * Constructs a new WebappModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a WebappModuleSetupResponse.
         * @implements IWebappModuleSetupResponse
         * @constructor
         * @param {proto.IWebappModuleSetupResponse=} [properties] Properties to set
         */
        function WebappModuleSetupResponse(properties) {
            this.webapps = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebappModuleSetupResponse webapps.
         * @member {Array.<proto.IWebappInfo>} webapps
         * @memberof proto.WebappModuleSetupResponse
         * @instance
         */
        WebappModuleSetupResponse.prototype.webapps = $util.emptyArray;

        /**
         * Creates a new WebappModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {proto.IWebappModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.WebappModuleSetupResponse} WebappModuleSetupResponse instance
         */
        WebappModuleSetupResponse.create = function create(properties) {
            return new WebappModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified WebappModuleSetupResponse message. Does not implicitly {@link proto.WebappModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {proto.IWebappModuleSetupResponse} message WebappModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.webapps != null && message.webapps.length)
                for (var i = 0; i < message.webapps.length; ++i)
                    $root.proto.WebappInfo.encode(message.webapps[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WebappModuleSetupResponse message, length delimited. Does not implicitly {@link proto.WebappModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {proto.IWebappModuleSetupResponse} message WebappModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebappModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.WebappModuleSetupResponse} WebappModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.WebappModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.webapps && message.webapps.length))
                            message.webapps = [];
                        message.webapps.push($root.proto.WebappInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WebappModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.WebappModuleSetupResponse} WebappModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebappModuleSetupResponse message.
         * @function verify
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebappModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.webapps != null && message.hasOwnProperty("webapps")) {
                if (!Array.isArray(message.webapps))
                    return "webapps: array expected";
                for (var i = 0; i < message.webapps.length; ++i) {
                    var error = $root.proto.WebappInfo.verify(message.webapps[i]);
                    if (error)
                        return "webapps." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WebappModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.WebappModuleSetupResponse} WebappModuleSetupResponse
         */
        WebappModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.WebappModuleSetupResponse)
                return object;
            var message = new $root.proto.WebappModuleSetupResponse();
            if (object.webapps) {
                if (!Array.isArray(object.webapps))
                    throw TypeError(".proto.WebappModuleSetupResponse.webapps: array expected");
                message.webapps = [];
                for (var i = 0; i < object.webapps.length; ++i) {
                    if (typeof object.webapps[i] !== "object")
                        throw TypeError(".proto.WebappModuleSetupResponse.webapps: object expected");
                    message.webapps[i] = $root.proto.WebappInfo.fromObject(object.webapps[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WebappModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {proto.WebappModuleSetupResponse} message WebappModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebappModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.webapps = [];
            if (message.webapps && message.webapps.length) {
                object.webapps = [];
                for (var j = 0; j < message.webapps.length; ++j)
                    object.webapps[j] = $root.proto.WebappInfo.toObject(message.webapps[j], options);
            }
            return object;
        };

        /**
         * Converts this WebappModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.WebappModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebappModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebappModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.WebappModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebappModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.WebappModuleSetupResponse";
        };

        return WebappModuleSetupResponse;
    })();

    proto.WebappInfo = (function() {

        /**
         * Properties of a WebappInfo.
         * @memberof proto
         * @interface IWebappInfo
         * @property {string} webappId WebappInfo webappId
         * @property {string} name WebappInfo name
         * @property {string} shortName WebappInfo shortName
         * @property {string} url WebappInfo url
         * @property {boolean} autoLaunch WebappInfo autoLaunch
         * @property {boolean} enableIntegration WebappInfo enableIntegration
         * @property {string|null} [iconUrl] WebappInfo iconUrl
         * @property {proto.WebappInfo.WebappType|null} [type] WebappInfo type
         */

        /**
         * Constructs a new WebappInfo.
         * @memberof proto
         * @classdesc Represents a WebappInfo.
         * @implements IWebappInfo
         * @constructor
         * @param {proto.IWebappInfo=} [properties] Properties to set
         */
        function WebappInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebappInfo webappId.
         * @member {string} webappId
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.webappId = "";

        /**
         * WebappInfo name.
         * @member {string} name
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.name = "";

        /**
         * WebappInfo shortName.
         * @member {string} shortName
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.shortName = "";

        /**
         * WebappInfo url.
         * @member {string} url
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.url = "";

        /**
         * WebappInfo autoLaunch.
         * @member {boolean} autoLaunch
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.autoLaunch = false;

        /**
         * WebappInfo enableIntegration.
         * @member {boolean} enableIntegration
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.enableIntegration = false;

        /**
         * WebappInfo iconUrl.
         * @member {string} iconUrl
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.iconUrl = "";

        /**
         * WebappInfo type.
         * @member {proto.WebappInfo.WebappType} type
         * @memberof proto.WebappInfo
         * @instance
         */
        WebappInfo.prototype.type = 1;

        /**
         * Creates a new WebappInfo instance using the specified properties.
         * @function create
         * @memberof proto.WebappInfo
         * @static
         * @param {proto.IWebappInfo=} [properties] Properties to set
         * @returns {proto.WebappInfo} WebappInfo instance
         */
        WebappInfo.create = function create(properties) {
            return new WebappInfo(properties);
        };

        /**
         * Encodes the specified WebappInfo message. Does not implicitly {@link proto.WebappInfo.verify|verify} messages.
         * @function encode
         * @memberof proto.WebappInfo
         * @static
         * @param {proto.IWebappInfo} message WebappInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.webappId);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.shortName);
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.url);
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.autoLaunch);
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.enableIntegration);
            if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.iconUrl);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
            return writer;
        };

        /**
         * Encodes the specified WebappInfo message, length delimited. Does not implicitly {@link proto.WebappInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.WebappInfo
         * @static
         * @param {proto.IWebappInfo} message WebappInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebappInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebappInfo message from the specified reader or buffer.
         * @function decode
         * @memberof proto.WebappInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.WebappInfo} WebappInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.WebappInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.webappId = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.shortName = reader.string();
                        break;
                    }
                case 4: {
                        message.url = reader.string();
                        break;
                    }
                case 5: {
                        message.autoLaunch = reader.bool();
                        break;
                    }
                case 6: {
                        message.enableIntegration = reader.bool();
                        break;
                    }
                case 7: {
                        message.iconUrl = reader.string();
                        break;
                    }
                case 8: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("webappId"))
                throw $util.ProtocolError("missing required 'webappId'", { instance: message });
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("shortName"))
                throw $util.ProtocolError("missing required 'shortName'", { instance: message });
            if (!message.hasOwnProperty("url"))
                throw $util.ProtocolError("missing required 'url'", { instance: message });
            if (!message.hasOwnProperty("autoLaunch"))
                throw $util.ProtocolError("missing required 'autoLaunch'", { instance: message });
            if (!message.hasOwnProperty("enableIntegration"))
                throw $util.ProtocolError("missing required 'enableIntegration'", { instance: message });
            return message;
        };

        /**
         * Decodes a WebappInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.WebappInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.WebappInfo} WebappInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebappInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebappInfo message.
         * @function verify
         * @memberof proto.WebappInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebappInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.webappId))
                return "webappId: string expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isString(message.shortName))
                return "shortName: string expected";
            if (!$util.isString(message.url))
                return "url: string expected";
            if (typeof message.autoLaunch !== "boolean")
                return "autoLaunch: boolean expected";
            if (typeof message.enableIntegration !== "boolean")
                return "enableIntegration: boolean expected";
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                if (!$util.isString(message.iconUrl))
                    return "iconUrl: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a WebappInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.WebappInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.WebappInfo} WebappInfo
         */
        WebappInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.WebappInfo)
                return object;
            var message = new $root.proto.WebappInfo();
            if (object.webappId != null)
                message.webappId = String(object.webappId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.shortName != null)
                message.shortName = String(object.shortName);
            if (object.url != null)
                message.url = String(object.url);
            if (object.autoLaunch != null)
                message.autoLaunch = Boolean(object.autoLaunch);
            if (object.enableIntegration != null)
                message.enableIntegration = Boolean(object.enableIntegration);
            if (object.iconUrl != null)
                message.iconUrl = String(object.iconUrl);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.type = 1;
                break;
            case "INLINE":
            case 2:
                message.type = 2;
                break;
            case "MODAL":
            case 3:
                message.type = 3;
                break;
            case "EXTERNAL":
            case 4:
                message.type = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WebappInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.WebappInfo
         * @static
         * @param {proto.WebappInfo} message WebappInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebappInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.webappId = "";
                object.name = "";
                object.shortName = "";
                object.url = "";
                object.autoLaunch = false;
                object.enableIntegration = false;
                object.iconUrl = "";
                object.type = options.enums === String ? "UNKNOWN" : 1;
            }
            if (message.webappId != null && message.hasOwnProperty("webappId"))
                object.webappId = message.webappId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.shortName != null && message.hasOwnProperty("shortName"))
                object.shortName = message.shortName;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.autoLaunch != null && message.hasOwnProperty("autoLaunch"))
                object.autoLaunch = message.autoLaunch;
            if (message.enableIntegration != null && message.hasOwnProperty("enableIntegration"))
                object.enableIntegration = message.enableIntegration;
            if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
                object.iconUrl = message.iconUrl;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.proto.WebappInfo.WebappType[message.type] === undefined ? message.type : $root.proto.WebappInfo.WebappType[message.type] : message.type;
            return object;
        };

        /**
         * Converts this WebappInfo to JSON.
         * @function toJSON
         * @memberof proto.WebappInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebappInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebappInfo
         * @function getTypeUrl
         * @memberof proto.WebappInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebappInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.WebappInfo";
        };

        /**
         * WebappType enum.
         * @name proto.WebappInfo.WebappType
         * @enum {number}
         * @property {number} UNKNOWN=1 UNKNOWN value
         * @property {number} INLINE=2 INLINE value
         * @property {number} MODAL=3 MODAL value
         * @property {number} EXTERNAL=4 EXTERNAL value
         */
        WebappInfo.WebappType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "UNKNOWN"] = 1;
            values[valuesById[2] = "INLINE"] = 2;
            values[valuesById[3] = "MODAL"] = 3;
            values[valuesById[4] = "EXTERNAL"] = 4;
            return values;
        })();

        return WebappInfo;
    })();

    proto.NotificationAPIv1Client = (function() {

        /**
         * Properties of a NotificationAPIv1Client.
         * @memberof proto
         * @interface INotificationAPIv1Client
         * @property {proto.INotificationModuleSetupRequest|null} [setupRequest] NotificationAPIv1Client setupRequest
         */

        /**
         * Constructs a new NotificationAPIv1Client.
         * @memberof proto
         * @classdesc Represents a NotificationAPIv1Client.
         * @implements INotificationAPIv1Client
         * @constructor
         * @param {proto.INotificationAPIv1Client=} [properties] Properties to set
         */
        function NotificationAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationAPIv1Client setupRequest.
         * @member {proto.INotificationModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.NotificationAPIv1Client
         * @instance
         */
        NotificationAPIv1Client.prototype.setupRequest = null;

        /**
         * Creates a new NotificationAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {proto.INotificationAPIv1Client=} [properties] Properties to set
         * @returns {proto.NotificationAPIv1Client} NotificationAPIv1Client instance
         */
        NotificationAPIv1Client.create = function create(properties) {
            return new NotificationAPIv1Client(properties);
        };

        /**
         * Encodes the specified NotificationAPIv1Client message. Does not implicitly {@link proto.NotificationAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {proto.INotificationAPIv1Client} message NotificationAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.NotificationModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotificationAPIv1Client message, length delimited. Does not implicitly {@link proto.NotificationAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {proto.INotificationAPIv1Client} message NotificationAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotificationAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.NotificationAPIv1Client} NotificationAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.NotificationAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.NotificationModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotificationAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.NotificationAPIv1Client} NotificationAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotificationAPIv1Client message.
         * @function verify
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotificationAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.NotificationModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            return null;
        };

        /**
         * Creates a NotificationAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.NotificationAPIv1Client} NotificationAPIv1Client
         */
        NotificationAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.NotificationAPIv1Client)
                return object;
            var message = new $root.proto.NotificationAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.NotificationAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.NotificationModuleSetupRequest.fromObject(object.setupRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {proto.NotificationAPIv1Client} message NotificationAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.setupRequest = null;
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.NotificationModuleSetupRequest.toObject(message.setupRequest, options);
            return object;
        };

        /**
         * Converts this NotificationAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.NotificationAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationAPIv1Client
         * @function getTypeUrl
         * @memberof proto.NotificationAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.NotificationAPIv1Client";
        };

        return NotificationAPIv1Client;
    })();

    proto.NotificationModuleSetupRequest = (function() {

        /**
         * Properties of a NotificationModuleSetupRequest.
         * @memberof proto
         * @interface INotificationModuleSetupRequest
         */

        /**
         * Constructs a new NotificationModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a NotificationModuleSetupRequest.
         * @implements INotificationModuleSetupRequest
         * @constructor
         * @param {proto.INotificationModuleSetupRequest=} [properties] Properties to set
         */
        function NotificationModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new NotificationModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {proto.INotificationModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.NotificationModuleSetupRequest} NotificationModuleSetupRequest instance
         */
        NotificationModuleSetupRequest.create = function create(properties) {
            return new NotificationModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified NotificationModuleSetupRequest message. Does not implicitly {@link proto.NotificationModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {proto.INotificationModuleSetupRequest} message NotificationModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified NotificationModuleSetupRequest message, length delimited. Does not implicitly {@link proto.NotificationModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {proto.INotificationModuleSetupRequest} message NotificationModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotificationModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.NotificationModuleSetupRequest} NotificationModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.NotificationModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotificationModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.NotificationModuleSetupRequest} NotificationModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotificationModuleSetupRequest message.
         * @function verify
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotificationModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a NotificationModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.NotificationModuleSetupRequest} NotificationModuleSetupRequest
         */
        NotificationModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.NotificationModuleSetupRequest)
                return object;
            return new $root.proto.NotificationModuleSetupRequest();
        };

        /**
         * Creates a plain object from a NotificationModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {proto.NotificationModuleSetupRequest} message NotificationModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this NotificationModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.NotificationModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.NotificationModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.NotificationModuleSetupRequest";
        };

        return NotificationModuleSetupRequest;
    })();

    proto.NotificationAPIv1Server = (function() {

        /**
         * Properties of a NotificationAPIv1Server.
         * @memberof proto
         * @interface INotificationAPIv1Server
         * @property {proto.INotificationRequest|null} [notification] NotificationAPIv1Server notification
         */

        /**
         * Constructs a new NotificationAPIv1Server.
         * @memberof proto
         * @classdesc Represents a NotificationAPIv1Server.
         * @implements INotificationAPIv1Server
         * @constructor
         * @param {proto.INotificationAPIv1Server=} [properties] Properties to set
         */
        function NotificationAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationAPIv1Server notification.
         * @member {proto.INotificationRequest|null|undefined} notification
         * @memberof proto.NotificationAPIv1Server
         * @instance
         */
        NotificationAPIv1Server.prototype.notification = null;

        /**
         * Creates a new NotificationAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {proto.INotificationAPIv1Server=} [properties] Properties to set
         * @returns {proto.NotificationAPIv1Server} NotificationAPIv1Server instance
         */
        NotificationAPIv1Server.create = function create(properties) {
            return new NotificationAPIv1Server(properties);
        };

        /**
         * Encodes the specified NotificationAPIv1Server message. Does not implicitly {@link proto.NotificationAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {proto.INotificationAPIv1Server} message NotificationAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                $root.proto.NotificationRequest.encode(message.notification, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotificationAPIv1Server message, length delimited. Does not implicitly {@link proto.NotificationAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {proto.INotificationAPIv1Server} message NotificationAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotificationAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.NotificationAPIv1Server} NotificationAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.NotificationAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.notification = $root.proto.NotificationRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotificationAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.NotificationAPIv1Server} NotificationAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotificationAPIv1Server message.
         * @function verify
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotificationAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.notification != null && message.hasOwnProperty("notification")) {
                var error = $root.proto.NotificationRequest.verify(message.notification);
                if (error)
                    return "notification." + error;
            }
            return null;
        };

        /**
         * Creates a NotificationAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.NotificationAPIv1Server} NotificationAPIv1Server
         */
        NotificationAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.NotificationAPIv1Server)
                return object;
            var message = new $root.proto.NotificationAPIv1Server();
            if (object.notification != null) {
                if (typeof object.notification !== "object")
                    throw TypeError(".proto.NotificationAPIv1Server.notification: object expected");
                message.notification = $root.proto.NotificationRequest.fromObject(object.notification);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {proto.NotificationAPIv1Server} message NotificationAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.notification = null;
            if (message.notification != null && message.hasOwnProperty("notification"))
                object.notification = $root.proto.NotificationRequest.toObject(message.notification, options);
            return object;
        };

        /**
         * Converts this NotificationAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.NotificationAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationAPIv1Server
         * @function getTypeUrl
         * @memberof proto.NotificationAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.NotificationAPIv1Server";
        };

        return NotificationAPIv1Server;
    })();

    proto.NotificationRequest = (function() {

        /**
         * Properties of a NotificationRequest.
         * @memberof proto
         * @interface INotificationRequest
         * @property {string} category NotificationRequest category
         * @property {string} title NotificationRequest title
         * @property {string|null} [subtitle] NotificationRequest subtitle
         * @property {string|null} [body] NotificationRequest body
         * @property {string|null} [sound] NotificationRequest sound
         * @property {number|null} [badgeNumber] NotificationRequest badgeNumber
         * @property {boolean|null} [mutable] NotificationRequest mutable
         * @property {Array.<proto.INotificationProperty>|null} [properties] NotificationRequest properties
         * @property {proto.NotificationRequest.InterruptLevel|null} [interruptLevel] NotificationRequest interruptLevel
         */

        /**
         * Constructs a new NotificationRequest.
         * @memberof proto
         * @classdesc Represents a NotificationRequest.
         * @implements INotificationRequest
         * @constructor
         * @param {proto.INotificationRequest=} [properties] Properties to set
         */
        function NotificationRequest(properties) {
            this.properties = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationRequest category.
         * @member {string} category
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.category = "";

        /**
         * NotificationRequest title.
         * @member {string} title
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.title = "";

        /**
         * NotificationRequest subtitle.
         * @member {string} subtitle
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.subtitle = "";

        /**
         * NotificationRequest body.
         * @member {string} body
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.body = "";

        /**
         * NotificationRequest sound.
         * @member {string} sound
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.sound = "";

        /**
         * NotificationRequest badgeNumber.
         * @member {number} badgeNumber
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.badgeNumber = 0;

        /**
         * NotificationRequest mutable.
         * @member {boolean} mutable
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.mutable = false;

        /**
         * NotificationRequest properties.
         * @member {Array.<proto.INotificationProperty>} properties
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.properties = $util.emptyArray;

        /**
         * NotificationRequest interruptLevel.
         * @member {proto.NotificationRequest.InterruptLevel} interruptLevel
         * @memberof proto.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.interruptLevel = 1;

        /**
         * Creates a new NotificationRequest instance using the specified properties.
         * @function create
         * @memberof proto.NotificationRequest
         * @static
         * @param {proto.INotificationRequest=} [properties] Properties to set
         * @returns {proto.NotificationRequest} NotificationRequest instance
         */
        NotificationRequest.create = function create(properties) {
            return new NotificationRequest(properties);
        };

        /**
         * Encodes the specified NotificationRequest message. Does not implicitly {@link proto.NotificationRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.NotificationRequest
         * @static
         * @param {proto.INotificationRequest} message NotificationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.category);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.subtitle != null && Object.hasOwnProperty.call(message, "subtitle"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.subtitle);
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.body);
            if (message.sound != null && Object.hasOwnProperty.call(message, "sound"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.sound);
            if (message.badgeNumber != null && Object.hasOwnProperty.call(message, "badgeNumber"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.badgeNumber);
            if (message.mutable != null && Object.hasOwnProperty.call(message, "mutable"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mutable);
            if (message.properties != null && message.properties.length)
                for (var i = 0; i < message.properties.length; ++i)
                    $root.proto.NotificationProperty.encode(message.properties[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.interruptLevel != null && Object.hasOwnProperty.call(message, "interruptLevel"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.interruptLevel);
            return writer;
        };

        /**
         * Encodes the specified NotificationRequest message, length delimited. Does not implicitly {@link proto.NotificationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.NotificationRequest
         * @static
         * @param {proto.INotificationRequest} message NotificationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotificationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.NotificationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.NotificationRequest} NotificationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.NotificationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.category = reader.string();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.subtitle = reader.string();
                        break;
                    }
                case 4: {
                        message.body = reader.string();
                        break;
                    }
                case 5: {
                        message.sound = reader.string();
                        break;
                    }
                case 6: {
                        message.badgeNumber = reader.int32();
                        break;
                    }
                case 7: {
                        message.mutable = reader.bool();
                        break;
                    }
                case 8: {
                        if (!(message.properties && message.properties.length))
                            message.properties = [];
                        message.properties.push($root.proto.NotificationProperty.decode(reader, reader.uint32()));
                        break;
                    }
                case 9: {
                        message.interruptLevel = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("category"))
                throw $util.ProtocolError("missing required 'category'", { instance: message });
            if (!message.hasOwnProperty("title"))
                throw $util.ProtocolError("missing required 'title'", { instance: message });
            return message;
        };

        /**
         * Decodes a NotificationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.NotificationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.NotificationRequest} NotificationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotificationRequest message.
         * @function verify
         * @memberof proto.NotificationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotificationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.category))
                return "category: string expected";
            if (!$util.isString(message.title))
                return "title: string expected";
            if (message.subtitle != null && message.hasOwnProperty("subtitle"))
                if (!$util.isString(message.subtitle))
                    return "subtitle: string expected";
            if (message.body != null && message.hasOwnProperty("body"))
                if (!$util.isString(message.body))
                    return "body: string expected";
            if (message.sound != null && message.hasOwnProperty("sound"))
                if (!$util.isString(message.sound))
                    return "sound: string expected";
            if (message.badgeNumber != null && message.hasOwnProperty("badgeNumber"))
                if (!$util.isInteger(message.badgeNumber))
                    return "badgeNumber: integer expected";
            if (message.mutable != null && message.hasOwnProperty("mutable"))
                if (typeof message.mutable !== "boolean")
                    return "mutable: boolean expected";
            if (message.properties != null && message.hasOwnProperty("properties")) {
                if (!Array.isArray(message.properties))
                    return "properties: array expected";
                for (var i = 0; i < message.properties.length; ++i) {
                    var error = $root.proto.NotificationProperty.verify(message.properties[i]);
                    if (error)
                        return "properties." + error;
                }
            }
            if (message.interruptLevel != null && message.hasOwnProperty("interruptLevel"))
                switch (message.interruptLevel) {
                default:
                    return "interruptLevel: enum value expected";
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a NotificationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.NotificationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.NotificationRequest} NotificationRequest
         */
        NotificationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.NotificationRequest)
                return object;
            var message = new $root.proto.NotificationRequest();
            if (object.category != null)
                message.category = String(object.category);
            if (object.title != null)
                message.title = String(object.title);
            if (object.subtitle != null)
                message.subtitle = String(object.subtitle);
            if (object.body != null)
                message.body = String(object.body);
            if (object.sound != null)
                message.sound = String(object.sound);
            if (object.badgeNumber != null)
                message.badgeNumber = object.badgeNumber | 0;
            if (object.mutable != null)
                message.mutable = Boolean(object.mutable);
            if (object.properties) {
                if (!Array.isArray(object.properties))
                    throw TypeError(".proto.NotificationRequest.properties: array expected");
                message.properties = [];
                for (var i = 0; i < object.properties.length; ++i) {
                    if (typeof object.properties[i] !== "object")
                        throw TypeError(".proto.NotificationRequest.properties: object expected");
                    message.properties[i] = $root.proto.NotificationProperty.fromObject(object.properties[i]);
                }
            }
            switch (object.interruptLevel) {
            default:
                if (typeof object.interruptLevel === "number") {
                    message.interruptLevel = object.interruptLevel;
                    break;
                }
                break;
            case "PASSIVE":
            case 1:
                message.interruptLevel = 1;
                break;
            case "ACTIVE":
            case 2:
                message.interruptLevel = 2;
                break;
            case "TIME_SENSITIVE":
            case 3:
                message.interruptLevel = 3;
                break;
            case "CRITICAL":
            case 4:
                message.interruptLevel = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a NotificationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.NotificationRequest
         * @static
         * @param {proto.NotificationRequest} message NotificationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.properties = [];
            if (options.defaults) {
                object.category = "";
                object.title = "";
                object.subtitle = "";
                object.body = "";
                object.sound = "";
                object.badgeNumber = 0;
                object.mutable = false;
                object.interruptLevel = options.enums === String ? "PASSIVE" : 1;
            }
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.subtitle != null && message.hasOwnProperty("subtitle"))
                object.subtitle = message.subtitle;
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = message.body;
            if (message.sound != null && message.hasOwnProperty("sound"))
                object.sound = message.sound;
            if (message.badgeNumber != null && message.hasOwnProperty("badgeNumber"))
                object.badgeNumber = message.badgeNumber;
            if (message.mutable != null && message.hasOwnProperty("mutable"))
                object.mutable = message.mutable;
            if (message.properties && message.properties.length) {
                object.properties = [];
                for (var j = 0; j < message.properties.length; ++j)
                    object.properties[j] = $root.proto.NotificationProperty.toObject(message.properties[j], options);
            }
            if (message.interruptLevel != null && message.hasOwnProperty("interruptLevel"))
                object.interruptLevel = options.enums === String ? $root.proto.NotificationRequest.InterruptLevel[message.interruptLevel] === undefined ? message.interruptLevel : $root.proto.NotificationRequest.InterruptLevel[message.interruptLevel] : message.interruptLevel;
            return object;
        };

        /**
         * Converts this NotificationRequest to JSON.
         * @function toJSON
         * @memberof proto.NotificationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationRequest
         * @function getTypeUrl
         * @memberof proto.NotificationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.NotificationRequest";
        };

        /**
         * InterruptLevel enum.
         * @name proto.NotificationRequest.InterruptLevel
         * @enum {number}
         * @property {number} PASSIVE=1 PASSIVE value
         * @property {number} ACTIVE=2 ACTIVE value
         * @property {number} TIME_SENSITIVE=3 TIME_SENSITIVE value
         * @property {number} CRITICAL=4 CRITICAL value
         */
        NotificationRequest.InterruptLevel = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[1] = "PASSIVE"] = 1;
            values[valuesById[2] = "ACTIVE"] = 2;
            values[valuesById[3] = "TIME_SENSITIVE"] = 3;
            values[valuesById[4] = "CRITICAL"] = 4;
            return values;
        })();

        return NotificationRequest;
    })();

    proto.NotificationProperty = (function() {

        /**
         * Properties of a NotificationProperty.
         * @memberof proto
         * @interface INotificationProperty
         * @property {string} key NotificationProperty key
         * @property {string} value NotificationProperty value
         */

        /**
         * Constructs a new NotificationProperty.
         * @memberof proto
         * @classdesc Represents a NotificationProperty.
         * @implements INotificationProperty
         * @constructor
         * @param {proto.INotificationProperty=} [properties] Properties to set
         */
        function NotificationProperty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationProperty key.
         * @member {string} key
         * @memberof proto.NotificationProperty
         * @instance
         */
        NotificationProperty.prototype.key = "";

        /**
         * NotificationProperty value.
         * @member {string} value
         * @memberof proto.NotificationProperty
         * @instance
         */
        NotificationProperty.prototype.value = "";

        /**
         * Creates a new NotificationProperty instance using the specified properties.
         * @function create
         * @memberof proto.NotificationProperty
         * @static
         * @param {proto.INotificationProperty=} [properties] Properties to set
         * @returns {proto.NotificationProperty} NotificationProperty instance
         */
        NotificationProperty.create = function create(properties) {
            return new NotificationProperty(properties);
        };

        /**
         * Encodes the specified NotificationProperty message. Does not implicitly {@link proto.NotificationProperty.verify|verify} messages.
         * @function encode
         * @memberof proto.NotificationProperty
         * @static
         * @param {proto.INotificationProperty} message NotificationProperty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationProperty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified NotificationProperty message, length delimited. Does not implicitly {@link proto.NotificationProperty.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.NotificationProperty
         * @static
         * @param {proto.INotificationProperty} message NotificationProperty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationProperty.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotificationProperty message from the specified reader or buffer.
         * @function decode
         * @memberof proto.NotificationProperty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.NotificationProperty} NotificationProperty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationProperty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.NotificationProperty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("key"))
                throw $util.ProtocolError("missing required 'key'", { instance: message });
            if (!message.hasOwnProperty("value"))
                throw $util.ProtocolError("missing required 'value'", { instance: message });
            return message;
        };

        /**
         * Decodes a NotificationProperty message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.NotificationProperty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.NotificationProperty} NotificationProperty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationProperty.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotificationProperty message.
         * @function verify
         * @memberof proto.NotificationProperty
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotificationProperty.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.key))
                return "key: string expected";
            if (!$util.isString(message.value))
                return "value: string expected";
            return null;
        };

        /**
         * Creates a NotificationProperty message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.NotificationProperty
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.NotificationProperty} NotificationProperty
         */
        NotificationProperty.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.NotificationProperty)
                return object;
            var message = new $root.proto.NotificationProperty();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a NotificationProperty message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.NotificationProperty
         * @static
         * @param {proto.NotificationProperty} message NotificationProperty
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationProperty.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.key = "";
                object.value = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this NotificationProperty to JSON.
         * @function toJSON
         * @memberof proto.NotificationProperty
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationProperty.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationProperty
         * @function getTypeUrl
         * @memberof proto.NotificationProperty
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationProperty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.NotificationProperty";
        };

        return NotificationProperty;
    })();

    proto.StatusAPIv1Client = (function() {

        /**
         * Properties of a StatusAPIv1Client.
         * @memberof proto
         * @interface IStatusAPIv1Client
         * @property {proto.IStatusModuleSetupRequest|null} [setupRequest] StatusAPIv1Client setupRequest
         * @property {proto.IStatusModuleSetCurrentRequest|null} [setCurrentRequest] StatusAPIv1Client setCurrentRequest
         * @property {proto.IStatusModuleListAvailableRequest|null} [listAvailableRequest] StatusAPIv1Client listAvailableRequest
         */

        /**
         * Constructs a new StatusAPIv1Client.
         * @memberof proto
         * @classdesc Represents a StatusAPIv1Client.
         * @implements IStatusAPIv1Client
         * @constructor
         * @param {proto.IStatusAPIv1Client=} [properties] Properties to set
         */
        function StatusAPIv1Client(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusAPIv1Client setupRequest.
         * @member {proto.IStatusModuleSetupRequest|null|undefined} setupRequest
         * @memberof proto.StatusAPIv1Client
         * @instance
         */
        StatusAPIv1Client.prototype.setupRequest = null;

        /**
         * StatusAPIv1Client setCurrentRequest.
         * @member {proto.IStatusModuleSetCurrentRequest|null|undefined} setCurrentRequest
         * @memberof proto.StatusAPIv1Client
         * @instance
         */
        StatusAPIv1Client.prototype.setCurrentRequest = null;

        /**
         * StatusAPIv1Client listAvailableRequest.
         * @member {proto.IStatusModuleListAvailableRequest|null|undefined} listAvailableRequest
         * @memberof proto.StatusAPIv1Client
         * @instance
         */
        StatusAPIv1Client.prototype.listAvailableRequest = null;

        /**
         * Creates a new StatusAPIv1Client instance using the specified properties.
         * @function create
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {proto.IStatusAPIv1Client=} [properties] Properties to set
         * @returns {proto.StatusAPIv1Client} StatusAPIv1Client instance
         */
        StatusAPIv1Client.create = function create(properties) {
            return new StatusAPIv1Client(properties);
        };

        /**
         * Encodes the specified StatusAPIv1Client message. Does not implicitly {@link proto.StatusAPIv1Client.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {proto.IStatusAPIv1Client} message StatusAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusAPIv1Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setupRequest != null && Object.hasOwnProperty.call(message, "setupRequest"))
                $root.proto.StatusModuleSetupRequest.encode(message.setupRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.setCurrentRequest != null && Object.hasOwnProperty.call(message, "setCurrentRequest"))
                $root.proto.StatusModuleSetCurrentRequest.encode(message.setCurrentRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.listAvailableRequest != null && Object.hasOwnProperty.call(message, "listAvailableRequest"))
                $root.proto.StatusModuleListAvailableRequest.encode(message.listAvailableRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StatusAPIv1Client message, length delimited. Does not implicitly {@link proto.StatusAPIv1Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {proto.IStatusAPIv1Client} message StatusAPIv1Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusAPIv1Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusAPIv1Client message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusAPIv1Client} StatusAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusAPIv1Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusAPIv1Client();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setupRequest = $root.proto.StatusModuleSetupRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.setCurrentRequest = $root.proto.StatusModuleSetCurrentRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.listAvailableRequest = $root.proto.StatusModuleListAvailableRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusAPIv1Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusAPIv1Client} StatusAPIv1Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusAPIv1Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusAPIv1Client message.
         * @function verify
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusAPIv1Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest")) {
                var error = $root.proto.StatusModuleSetupRequest.verify(message.setupRequest);
                if (error)
                    return "setupRequest." + error;
            }
            if (message.setCurrentRequest != null && message.hasOwnProperty("setCurrentRequest")) {
                var error = $root.proto.StatusModuleSetCurrentRequest.verify(message.setCurrentRequest);
                if (error)
                    return "setCurrentRequest." + error;
            }
            if (message.listAvailableRequest != null && message.hasOwnProperty("listAvailableRequest")) {
                var error = $root.proto.StatusModuleListAvailableRequest.verify(message.listAvailableRequest);
                if (error)
                    return "listAvailableRequest." + error;
            }
            return null;
        };

        /**
         * Creates a StatusAPIv1Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusAPIv1Client} StatusAPIv1Client
         */
        StatusAPIv1Client.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusAPIv1Client)
                return object;
            var message = new $root.proto.StatusAPIv1Client();
            if (object.setupRequest != null) {
                if (typeof object.setupRequest !== "object")
                    throw TypeError(".proto.StatusAPIv1Client.setupRequest: object expected");
                message.setupRequest = $root.proto.StatusModuleSetupRequest.fromObject(object.setupRequest);
            }
            if (object.setCurrentRequest != null) {
                if (typeof object.setCurrentRequest !== "object")
                    throw TypeError(".proto.StatusAPIv1Client.setCurrentRequest: object expected");
                message.setCurrentRequest = $root.proto.StatusModuleSetCurrentRequest.fromObject(object.setCurrentRequest);
            }
            if (object.listAvailableRequest != null) {
                if (typeof object.listAvailableRequest !== "object")
                    throw TypeError(".proto.StatusAPIv1Client.listAvailableRequest: object expected");
                message.listAvailableRequest = $root.proto.StatusModuleListAvailableRequest.fromObject(object.listAvailableRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a StatusAPIv1Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {proto.StatusAPIv1Client} message StatusAPIv1Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusAPIv1Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.setupRequest = null;
                object.setCurrentRequest = null;
                object.listAvailableRequest = null;
            }
            if (message.setupRequest != null && message.hasOwnProperty("setupRequest"))
                object.setupRequest = $root.proto.StatusModuleSetupRequest.toObject(message.setupRequest, options);
            if (message.setCurrentRequest != null && message.hasOwnProperty("setCurrentRequest"))
                object.setCurrentRequest = $root.proto.StatusModuleSetCurrentRequest.toObject(message.setCurrentRequest, options);
            if (message.listAvailableRequest != null && message.hasOwnProperty("listAvailableRequest"))
                object.listAvailableRequest = $root.proto.StatusModuleListAvailableRequest.toObject(message.listAvailableRequest, options);
            return object;
        };

        /**
         * Converts this StatusAPIv1Client to JSON.
         * @function toJSON
         * @memberof proto.StatusAPIv1Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusAPIv1Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusAPIv1Client
         * @function getTypeUrl
         * @memberof proto.StatusAPIv1Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusAPIv1Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusAPIv1Client";
        };

        return StatusAPIv1Client;
    })();

    proto.Status = (function() {

        /**
         * Properties of a Status.
         * @memberof proto
         * @interface IStatus
         * @property {string} name Status name
         * @property {string} uuid Status uuid
         * @property {string|null} [bgColor] Status bgColor
         * @property {string|null} [fgColor] Status fgColor
         */

        /**
         * Constructs a new Status.
         * @memberof proto
         * @classdesc Represents a Status.
         * @implements IStatus
         * @constructor
         * @param {proto.IStatus=} [properties] Properties to set
         */
        function Status(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Status name.
         * @member {string} name
         * @memberof proto.Status
         * @instance
         */
        Status.prototype.name = "";

        /**
         * Status uuid.
         * @member {string} uuid
         * @memberof proto.Status
         * @instance
         */
        Status.prototype.uuid = "";

        /**
         * Status bgColor.
         * @member {string} bgColor
         * @memberof proto.Status
         * @instance
         */
        Status.prototype.bgColor = "";

        /**
         * Status fgColor.
         * @member {string} fgColor
         * @memberof proto.Status
         * @instance
         */
        Status.prototype.fgColor = "";

        /**
         * Creates a new Status instance using the specified properties.
         * @function create
         * @memberof proto.Status
         * @static
         * @param {proto.IStatus=} [properties] Properties to set
         * @returns {proto.Status} Status instance
         */
        Status.create = function create(properties) {
            return new Status(properties);
        };

        /**
         * Encodes the specified Status message. Does not implicitly {@link proto.Status.verify|verify} messages.
         * @function encode
         * @memberof proto.Status
         * @static
         * @param {proto.IStatus} message Status message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Status.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.uuid);
            if (message.bgColor != null && Object.hasOwnProperty.call(message, "bgColor"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.bgColor);
            if (message.fgColor != null && Object.hasOwnProperty.call(message, "fgColor"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.fgColor);
            return writer;
        };

        /**
         * Encodes the specified Status message, length delimited. Does not implicitly {@link proto.Status.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Status
         * @static
         * @param {proto.IStatus} message Status message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Status.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Status message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Status
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Status} Status
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Status.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Status();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.uuid = reader.string();
                        break;
                    }
                case 3: {
                        message.bgColor = reader.string();
                        break;
                    }
                case 4: {
                        message.fgColor = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("uuid"))
                throw $util.ProtocolError("missing required 'uuid'", { instance: message });
            return message;
        };

        /**
         * Decodes a Status message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Status
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Status} Status
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Status.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Status message.
         * @function verify
         * @memberof proto.Status
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Status.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isString(message.uuid))
                return "uuid: string expected";
            if (message.bgColor != null && message.hasOwnProperty("bgColor"))
                if (!$util.isString(message.bgColor))
                    return "bgColor: string expected";
            if (message.fgColor != null && message.hasOwnProperty("fgColor"))
                if (!$util.isString(message.fgColor))
                    return "fgColor: string expected";
            return null;
        };

        /**
         * Creates a Status message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Status
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Status} Status
         */
        Status.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Status)
                return object;
            var message = new $root.proto.Status();
            if (object.name != null)
                message.name = String(object.name);
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.bgColor != null)
                message.bgColor = String(object.bgColor);
            if (object.fgColor != null)
                message.fgColor = String(object.fgColor);
            return message;
        };

        /**
         * Creates a plain object from a Status message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Status
         * @static
         * @param {proto.Status} message Status
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Status.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.uuid = "";
                object.bgColor = "";
                object.fgColor = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.bgColor != null && message.hasOwnProperty("bgColor"))
                object.bgColor = message.bgColor;
            if (message.fgColor != null && message.hasOwnProperty("fgColor"))
                object.fgColor = message.fgColor;
            return object;
        };

        /**
         * Converts this Status to JSON.
         * @function toJSON
         * @memberof proto.Status
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Status.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Status
         * @function getTypeUrl
         * @memberof proto.Status
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Status.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.Status";
        };

        return Status;
    })();

    proto.StatusModuleSetupRequest = (function() {

        /**
         * Properties of a StatusModuleSetupRequest.
         * @memberof proto
         * @interface IStatusModuleSetupRequest
         */

        /**
         * Constructs a new StatusModuleSetupRequest.
         * @memberof proto
         * @classdesc Represents a StatusModuleSetupRequest.
         * @implements IStatusModuleSetupRequest
         * @constructor
         * @param {proto.IStatusModuleSetupRequest=} [properties] Properties to set
         */
        function StatusModuleSetupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StatusModuleSetupRequest instance using the specified properties.
         * @function create
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {proto.IStatusModuleSetupRequest=} [properties] Properties to set
         * @returns {proto.StatusModuleSetupRequest} StatusModuleSetupRequest instance
         */
        StatusModuleSetupRequest.create = function create(properties) {
            return new StatusModuleSetupRequest(properties);
        };

        /**
         * Encodes the specified StatusModuleSetupRequest message. Does not implicitly {@link proto.StatusModuleSetupRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {proto.IStatusModuleSetupRequest} message StatusModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleSetupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StatusModuleSetupRequest message, length delimited. Does not implicitly {@link proto.StatusModuleSetupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {proto.IStatusModuleSetupRequest} message StatusModuleSetupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusModuleSetupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusModuleSetupRequest} StatusModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleSetupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusModuleSetupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusModuleSetupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusModuleSetupRequest} StatusModuleSetupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleSetupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusModuleSetupRequest message.
         * @function verify
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusModuleSetupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StatusModuleSetupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusModuleSetupRequest} StatusModuleSetupRequest
         */
        StatusModuleSetupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusModuleSetupRequest)
                return object;
            return new $root.proto.StatusModuleSetupRequest();
        };

        /**
         * Creates a plain object from a StatusModuleSetupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {proto.StatusModuleSetupRequest} message StatusModuleSetupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusModuleSetupRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StatusModuleSetupRequest to JSON.
         * @function toJSON
         * @memberof proto.StatusModuleSetupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusModuleSetupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusModuleSetupRequest
         * @function getTypeUrl
         * @memberof proto.StatusModuleSetupRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusModuleSetupRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusModuleSetupRequest";
        };

        return StatusModuleSetupRequest;
    })();

    proto.StatusModuleSetupResponse = (function() {

        /**
         * Properties of a StatusModuleSetupResponse.
         * @memberof proto
         * @interface IStatusModuleSetupResponse
         * @property {proto.IStatus|null} [currentStatus] StatusModuleSetupResponse currentStatus
         * @property {boolean|null} [statusAvailable] StatusModuleSetupResponse statusAvailable
         */

        /**
         * Constructs a new StatusModuleSetupResponse.
         * @memberof proto
         * @classdesc Represents a StatusModuleSetupResponse.
         * @implements IStatusModuleSetupResponse
         * @constructor
         * @param {proto.IStatusModuleSetupResponse=} [properties] Properties to set
         */
        function StatusModuleSetupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusModuleSetupResponse currentStatus.
         * @member {proto.IStatus|null|undefined} currentStatus
         * @memberof proto.StatusModuleSetupResponse
         * @instance
         */
        StatusModuleSetupResponse.prototype.currentStatus = null;

        /**
         * StatusModuleSetupResponse statusAvailable.
         * @member {boolean} statusAvailable
         * @memberof proto.StatusModuleSetupResponse
         * @instance
         */
        StatusModuleSetupResponse.prototype.statusAvailable = false;

        /**
         * Creates a new StatusModuleSetupResponse instance using the specified properties.
         * @function create
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {proto.IStatusModuleSetupResponse=} [properties] Properties to set
         * @returns {proto.StatusModuleSetupResponse} StatusModuleSetupResponse instance
         */
        StatusModuleSetupResponse.create = function create(properties) {
            return new StatusModuleSetupResponse(properties);
        };

        /**
         * Encodes the specified StatusModuleSetupResponse message. Does not implicitly {@link proto.StatusModuleSetupResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {proto.IStatusModuleSetupResponse} message StatusModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleSetupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentStatus != null && Object.hasOwnProperty.call(message, "currentStatus"))
                $root.proto.Status.encode(message.currentStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.statusAvailable != null && Object.hasOwnProperty.call(message, "statusAvailable"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.statusAvailable);
            return writer;
        };

        /**
         * Encodes the specified StatusModuleSetupResponse message, length delimited. Does not implicitly {@link proto.StatusModuleSetupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {proto.IStatusModuleSetupResponse} message StatusModuleSetupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusModuleSetupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusModuleSetupResponse} StatusModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleSetupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusModuleSetupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentStatus = $root.proto.Status.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.statusAvailable = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusModuleSetupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusModuleSetupResponse} StatusModuleSetupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleSetupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusModuleSetupResponse message.
         * @function verify
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusModuleSetupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentStatus != null && message.hasOwnProperty("currentStatus")) {
                var error = $root.proto.Status.verify(message.currentStatus);
                if (error)
                    return "currentStatus." + error;
            }
            if (message.statusAvailable != null && message.hasOwnProperty("statusAvailable"))
                if (typeof message.statusAvailable !== "boolean")
                    return "statusAvailable: boolean expected";
            return null;
        };

        /**
         * Creates a StatusModuleSetupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusModuleSetupResponse} StatusModuleSetupResponse
         */
        StatusModuleSetupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusModuleSetupResponse)
                return object;
            var message = new $root.proto.StatusModuleSetupResponse();
            if (object.currentStatus != null) {
                if (typeof object.currentStatus !== "object")
                    throw TypeError(".proto.StatusModuleSetupResponse.currentStatus: object expected");
                message.currentStatus = $root.proto.Status.fromObject(object.currentStatus);
            }
            if (object.statusAvailable != null)
                message.statusAvailable = Boolean(object.statusAvailable);
            return message;
        };

        /**
         * Creates a plain object from a StatusModuleSetupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {proto.StatusModuleSetupResponse} message StatusModuleSetupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusModuleSetupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currentStatus = null;
                object.statusAvailable = false;
            }
            if (message.currentStatus != null && message.hasOwnProperty("currentStatus"))
                object.currentStatus = $root.proto.Status.toObject(message.currentStatus, options);
            if (message.statusAvailable != null && message.hasOwnProperty("statusAvailable"))
                object.statusAvailable = message.statusAvailable;
            return object;
        };

        /**
         * Converts this StatusModuleSetupResponse to JSON.
         * @function toJSON
         * @memberof proto.StatusModuleSetupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusModuleSetupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusModuleSetupResponse
         * @function getTypeUrl
         * @memberof proto.StatusModuleSetupResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusModuleSetupResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusModuleSetupResponse";
        };

        return StatusModuleSetupResponse;
    })();

    proto.StatusModuleSetCurrentRequest = (function() {

        /**
         * Properties of a StatusModuleSetCurrentRequest.
         * @memberof proto
         * @interface IStatusModuleSetCurrentRequest
         * @property {string|null} [statusUuid] StatusModuleSetCurrentRequest statusUuid
         * @property {string|null} [userUuid] StatusModuleSetCurrentRequest userUuid
         * @property {string|null} [entityId] StatusModuleSetCurrentRequest entityId
         */

        /**
         * Constructs a new StatusModuleSetCurrentRequest.
         * @memberof proto
         * @classdesc Represents a StatusModuleSetCurrentRequest.
         * @implements IStatusModuleSetCurrentRequest
         * @constructor
         * @param {proto.IStatusModuleSetCurrentRequest=} [properties] Properties to set
         */
        function StatusModuleSetCurrentRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusModuleSetCurrentRequest statusUuid.
         * @member {string} statusUuid
         * @memberof proto.StatusModuleSetCurrentRequest
         * @instance
         */
        StatusModuleSetCurrentRequest.prototype.statusUuid = "";

        /**
         * StatusModuleSetCurrentRequest userUuid.
         * @member {string} userUuid
         * @memberof proto.StatusModuleSetCurrentRequest
         * @instance
         */
        StatusModuleSetCurrentRequest.prototype.userUuid = "";

        /**
         * StatusModuleSetCurrentRequest entityId.
         * @member {string} entityId
         * @memberof proto.StatusModuleSetCurrentRequest
         * @instance
         */
        StatusModuleSetCurrentRequest.prototype.entityId = "";

        /**
         * Creates a new StatusModuleSetCurrentRequest instance using the specified properties.
         * @function create
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {proto.IStatusModuleSetCurrentRequest=} [properties] Properties to set
         * @returns {proto.StatusModuleSetCurrentRequest} StatusModuleSetCurrentRequest instance
         */
        StatusModuleSetCurrentRequest.create = function create(properties) {
            return new StatusModuleSetCurrentRequest(properties);
        };

        /**
         * Encodes the specified StatusModuleSetCurrentRequest message. Does not implicitly {@link proto.StatusModuleSetCurrentRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {proto.IStatusModuleSetCurrentRequest} message StatusModuleSetCurrentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleSetCurrentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statusUuid != null && Object.hasOwnProperty.call(message, "statusUuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.statusUuid);
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userUuid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.entityId);
            return writer;
        };

        /**
         * Encodes the specified StatusModuleSetCurrentRequest message, length delimited. Does not implicitly {@link proto.StatusModuleSetCurrentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {proto.IStatusModuleSetCurrentRequest} message StatusModuleSetCurrentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleSetCurrentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusModuleSetCurrentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusModuleSetCurrentRequest} StatusModuleSetCurrentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleSetCurrentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusModuleSetCurrentRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.statusUuid = reader.string();
                        break;
                    }
                case 2: {
                        message.userUuid = reader.string();
                        break;
                    }
                case 3: {
                        message.entityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusModuleSetCurrentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusModuleSetCurrentRequest} StatusModuleSetCurrentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleSetCurrentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusModuleSetCurrentRequest message.
         * @function verify
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusModuleSetCurrentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statusUuid != null && message.hasOwnProperty("statusUuid"))
                if (!$util.isString(message.statusUuid))
                    return "statusUuid: string expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isString(message.entityId))
                    return "entityId: string expected";
            return null;
        };

        /**
         * Creates a StatusModuleSetCurrentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusModuleSetCurrentRequest} StatusModuleSetCurrentRequest
         */
        StatusModuleSetCurrentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusModuleSetCurrentRequest)
                return object;
            var message = new $root.proto.StatusModuleSetCurrentRequest();
            if (object.statusUuid != null)
                message.statusUuid = String(object.statusUuid);
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            return message;
        };

        /**
         * Creates a plain object from a StatusModuleSetCurrentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {proto.StatusModuleSetCurrentRequest} message StatusModuleSetCurrentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusModuleSetCurrentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.statusUuid = "";
                object.userUuid = "";
                object.entityId = "";
            }
            if (message.statusUuid != null && message.hasOwnProperty("statusUuid"))
                object.statusUuid = message.statusUuid;
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            return object;
        };

        /**
         * Converts this StatusModuleSetCurrentRequest to JSON.
         * @function toJSON
         * @memberof proto.StatusModuleSetCurrentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusModuleSetCurrentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusModuleSetCurrentRequest
         * @function getTypeUrl
         * @memberof proto.StatusModuleSetCurrentRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusModuleSetCurrentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusModuleSetCurrentRequest";
        };

        return StatusModuleSetCurrentRequest;
    })();

    proto.StatusModuleListAvailableRequest = (function() {

        /**
         * Properties of a StatusModuleListAvailableRequest.
         * @memberof proto
         * @interface IStatusModuleListAvailableRequest
         * @property {string|null} [userUuid] StatusModuleListAvailableRequest userUuid
         * @property {string|null} [entityId] StatusModuleListAvailableRequest entityId
         */

        /**
         * Constructs a new StatusModuleListAvailableRequest.
         * @memberof proto
         * @classdesc Represents a StatusModuleListAvailableRequest.
         * @implements IStatusModuleListAvailableRequest
         * @constructor
         * @param {proto.IStatusModuleListAvailableRequest=} [properties] Properties to set
         */
        function StatusModuleListAvailableRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusModuleListAvailableRequest userUuid.
         * @member {string} userUuid
         * @memberof proto.StatusModuleListAvailableRequest
         * @instance
         */
        StatusModuleListAvailableRequest.prototype.userUuid = "";

        /**
         * StatusModuleListAvailableRequest entityId.
         * @member {string} entityId
         * @memberof proto.StatusModuleListAvailableRequest
         * @instance
         */
        StatusModuleListAvailableRequest.prototype.entityId = "";

        /**
         * Creates a new StatusModuleListAvailableRequest instance using the specified properties.
         * @function create
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {proto.IStatusModuleListAvailableRequest=} [properties] Properties to set
         * @returns {proto.StatusModuleListAvailableRequest} StatusModuleListAvailableRequest instance
         */
        StatusModuleListAvailableRequest.create = function create(properties) {
            return new StatusModuleListAvailableRequest(properties);
        };

        /**
         * Encodes the specified StatusModuleListAvailableRequest message. Does not implicitly {@link proto.StatusModuleListAvailableRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {proto.IStatusModuleListAvailableRequest} message StatusModuleListAvailableRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleListAvailableRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userUuid != null && Object.hasOwnProperty.call(message, "userUuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userUuid);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.entityId);
            return writer;
        };

        /**
         * Encodes the specified StatusModuleListAvailableRequest message, length delimited. Does not implicitly {@link proto.StatusModuleListAvailableRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {proto.IStatusModuleListAvailableRequest} message StatusModuleListAvailableRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleListAvailableRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusModuleListAvailableRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusModuleListAvailableRequest} StatusModuleListAvailableRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleListAvailableRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusModuleListAvailableRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userUuid = reader.string();
                        break;
                    }
                case 2: {
                        message.entityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusModuleListAvailableRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusModuleListAvailableRequest} StatusModuleListAvailableRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleListAvailableRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusModuleListAvailableRequest message.
         * @function verify
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusModuleListAvailableRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                if (!$util.isString(message.userUuid))
                    return "userUuid: string expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isString(message.entityId))
                    return "entityId: string expected";
            return null;
        };

        /**
         * Creates a StatusModuleListAvailableRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusModuleListAvailableRequest} StatusModuleListAvailableRequest
         */
        StatusModuleListAvailableRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusModuleListAvailableRequest)
                return object;
            var message = new $root.proto.StatusModuleListAvailableRequest();
            if (object.userUuid != null)
                message.userUuid = String(object.userUuid);
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            return message;
        };

        /**
         * Creates a plain object from a StatusModuleListAvailableRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {proto.StatusModuleListAvailableRequest} message StatusModuleListAvailableRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusModuleListAvailableRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.userUuid = "";
                object.entityId = "";
            }
            if (message.userUuid != null && message.hasOwnProperty("userUuid"))
                object.userUuid = message.userUuid;
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            return object;
        };

        /**
         * Converts this StatusModuleListAvailableRequest to JSON.
         * @function toJSON
         * @memberof proto.StatusModuleListAvailableRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusModuleListAvailableRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusModuleListAvailableRequest
         * @function getTypeUrl
         * @memberof proto.StatusModuleListAvailableRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusModuleListAvailableRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusModuleListAvailableRequest";
        };

        return StatusModuleListAvailableRequest;
    })();

    proto.StatusModuleListAvailableResponse = (function() {

        /**
         * Properties of a StatusModuleListAvailableResponse.
         * @memberof proto
         * @interface IStatusModuleListAvailableResponse
         * @property {Array.<proto.IStatus>|null} [statuses] StatusModuleListAvailableResponse statuses
         */

        /**
         * Constructs a new StatusModuleListAvailableResponse.
         * @memberof proto
         * @classdesc Represents a StatusModuleListAvailableResponse.
         * @implements IStatusModuleListAvailableResponse
         * @constructor
         * @param {proto.IStatusModuleListAvailableResponse=} [properties] Properties to set
         */
        function StatusModuleListAvailableResponse(properties) {
            this.statuses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusModuleListAvailableResponse statuses.
         * @member {Array.<proto.IStatus>} statuses
         * @memberof proto.StatusModuleListAvailableResponse
         * @instance
         */
        StatusModuleListAvailableResponse.prototype.statuses = $util.emptyArray;

        /**
         * Creates a new StatusModuleListAvailableResponse instance using the specified properties.
         * @function create
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {proto.IStatusModuleListAvailableResponse=} [properties] Properties to set
         * @returns {proto.StatusModuleListAvailableResponse} StatusModuleListAvailableResponse instance
         */
        StatusModuleListAvailableResponse.create = function create(properties) {
            return new StatusModuleListAvailableResponse(properties);
        };

        /**
         * Encodes the specified StatusModuleListAvailableResponse message. Does not implicitly {@link proto.StatusModuleListAvailableResponse.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {proto.IStatusModuleListAvailableResponse} message StatusModuleListAvailableResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleListAvailableResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statuses != null && message.statuses.length)
                for (var i = 0; i < message.statuses.length; ++i)
                    $root.proto.Status.encode(message.statuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StatusModuleListAvailableResponse message, length delimited. Does not implicitly {@link proto.StatusModuleListAvailableResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {proto.IStatusModuleListAvailableResponse} message StatusModuleListAvailableResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusModuleListAvailableResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusModuleListAvailableResponse message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusModuleListAvailableResponse} StatusModuleListAvailableResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleListAvailableResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusModuleListAvailableResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.statuses && message.statuses.length))
                            message.statuses = [];
                        message.statuses.push($root.proto.Status.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusModuleListAvailableResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusModuleListAvailableResponse} StatusModuleListAvailableResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusModuleListAvailableResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusModuleListAvailableResponse message.
         * @function verify
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusModuleListAvailableResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statuses != null && message.hasOwnProperty("statuses")) {
                if (!Array.isArray(message.statuses))
                    return "statuses: array expected";
                for (var i = 0; i < message.statuses.length; ++i) {
                    var error = $root.proto.Status.verify(message.statuses[i]);
                    if (error)
                        return "statuses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a StatusModuleListAvailableResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusModuleListAvailableResponse} StatusModuleListAvailableResponse
         */
        StatusModuleListAvailableResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusModuleListAvailableResponse)
                return object;
            var message = new $root.proto.StatusModuleListAvailableResponse();
            if (object.statuses) {
                if (!Array.isArray(object.statuses))
                    throw TypeError(".proto.StatusModuleListAvailableResponse.statuses: array expected");
                message.statuses = [];
                for (var i = 0; i < object.statuses.length; ++i) {
                    if (typeof object.statuses[i] !== "object")
                        throw TypeError(".proto.StatusModuleListAvailableResponse.statuses: object expected");
                    message.statuses[i] = $root.proto.Status.fromObject(object.statuses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StatusModuleListAvailableResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {proto.StatusModuleListAvailableResponse} message StatusModuleListAvailableResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusModuleListAvailableResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.statuses = [];
            if (message.statuses && message.statuses.length) {
                object.statuses = [];
                for (var j = 0; j < message.statuses.length; ++j)
                    object.statuses[j] = $root.proto.Status.toObject(message.statuses[j], options);
            }
            return object;
        };

        /**
         * Converts this StatusModuleListAvailableResponse to JSON.
         * @function toJSON
         * @memberof proto.StatusModuleListAvailableResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusModuleListAvailableResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusModuleListAvailableResponse
         * @function getTypeUrl
         * @memberof proto.StatusModuleListAvailableResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusModuleListAvailableResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusModuleListAvailableResponse";
        };

        return StatusModuleListAvailableResponse;
    })();

    proto.StatusAPIv1Server = (function() {

        /**
         * Properties of a StatusAPIv1Server.
         * @memberof proto
         * @interface IStatusAPIv1Server
         * @property {proto.IStatusChangedRequest|null} [statusChangedRequest] StatusAPIv1Server statusChangedRequest
         */

        /**
         * Constructs a new StatusAPIv1Server.
         * @memberof proto
         * @classdesc Represents a StatusAPIv1Server.
         * @implements IStatusAPIv1Server
         * @constructor
         * @param {proto.IStatusAPIv1Server=} [properties] Properties to set
         */
        function StatusAPIv1Server(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusAPIv1Server statusChangedRequest.
         * @member {proto.IStatusChangedRequest|null|undefined} statusChangedRequest
         * @memberof proto.StatusAPIv1Server
         * @instance
         */
        StatusAPIv1Server.prototype.statusChangedRequest = null;

        /**
         * Creates a new StatusAPIv1Server instance using the specified properties.
         * @function create
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {proto.IStatusAPIv1Server=} [properties] Properties to set
         * @returns {proto.StatusAPIv1Server} StatusAPIv1Server instance
         */
        StatusAPIv1Server.create = function create(properties) {
            return new StatusAPIv1Server(properties);
        };

        /**
         * Encodes the specified StatusAPIv1Server message. Does not implicitly {@link proto.StatusAPIv1Server.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {proto.IStatusAPIv1Server} message StatusAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusAPIv1Server.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statusChangedRequest != null && Object.hasOwnProperty.call(message, "statusChangedRequest"))
                $root.proto.StatusChangedRequest.encode(message.statusChangedRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StatusAPIv1Server message, length delimited. Does not implicitly {@link proto.StatusAPIv1Server.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {proto.IStatusAPIv1Server} message StatusAPIv1Server message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusAPIv1Server.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusAPIv1Server message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusAPIv1Server} StatusAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusAPIv1Server.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusAPIv1Server();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.statusChangedRequest = $root.proto.StatusChangedRequest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusAPIv1Server message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusAPIv1Server} StatusAPIv1Server
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusAPIv1Server.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusAPIv1Server message.
         * @function verify
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusAPIv1Server.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statusChangedRequest != null && message.hasOwnProperty("statusChangedRequest")) {
                var error = $root.proto.StatusChangedRequest.verify(message.statusChangedRequest);
                if (error)
                    return "statusChangedRequest." + error;
            }
            return null;
        };

        /**
         * Creates a StatusAPIv1Server message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusAPIv1Server} StatusAPIv1Server
         */
        StatusAPIv1Server.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusAPIv1Server)
                return object;
            var message = new $root.proto.StatusAPIv1Server();
            if (object.statusChangedRequest != null) {
                if (typeof object.statusChangedRequest !== "object")
                    throw TypeError(".proto.StatusAPIv1Server.statusChangedRequest: object expected");
                message.statusChangedRequest = $root.proto.StatusChangedRequest.fromObject(object.statusChangedRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a StatusAPIv1Server message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {proto.StatusAPIv1Server} message StatusAPIv1Server
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusAPIv1Server.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.statusChangedRequest = null;
            if (message.statusChangedRequest != null && message.hasOwnProperty("statusChangedRequest"))
                object.statusChangedRequest = $root.proto.StatusChangedRequest.toObject(message.statusChangedRequest, options);
            return object;
        };

        /**
         * Converts this StatusAPIv1Server to JSON.
         * @function toJSON
         * @memberof proto.StatusAPIv1Server
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusAPIv1Server.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusAPIv1Server
         * @function getTypeUrl
         * @memberof proto.StatusAPIv1Server
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusAPIv1Server.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusAPIv1Server";
        };

        return StatusAPIv1Server;
    })();

    proto.StatusChangedRequest = (function() {

        /**
         * Properties of a StatusChangedRequest.
         * @memberof proto
         * @interface IStatusChangedRequest
         * @property {proto.IStatus|null} [newStatus] StatusChangedRequest newStatus
         */

        /**
         * Constructs a new StatusChangedRequest.
         * @memberof proto
         * @classdesc Represents a StatusChangedRequest.
         * @implements IStatusChangedRequest
         * @constructor
         * @param {proto.IStatusChangedRequest=} [properties] Properties to set
         */
        function StatusChangedRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusChangedRequest newStatus.
         * @member {proto.IStatus|null|undefined} newStatus
         * @memberof proto.StatusChangedRequest
         * @instance
         */
        StatusChangedRequest.prototype.newStatus = null;

        /**
         * Creates a new StatusChangedRequest instance using the specified properties.
         * @function create
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {proto.IStatusChangedRequest=} [properties] Properties to set
         * @returns {proto.StatusChangedRequest} StatusChangedRequest instance
         */
        StatusChangedRequest.create = function create(properties) {
            return new StatusChangedRequest(properties);
        };

        /**
         * Encodes the specified StatusChangedRequest message. Does not implicitly {@link proto.StatusChangedRequest.verify|verify} messages.
         * @function encode
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {proto.IStatusChangedRequest} message StatusChangedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusChangedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newStatus != null && Object.hasOwnProperty.call(message, "newStatus"))
                $root.proto.Status.encode(message.newStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StatusChangedRequest message, length delimited. Does not implicitly {@link proto.StatusChangedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {proto.IStatusChangedRequest} message StatusChangedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusChangedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusChangedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.StatusChangedRequest} StatusChangedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusChangedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.StatusChangedRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.newStatus = $root.proto.Status.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusChangedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.StatusChangedRequest} StatusChangedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusChangedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusChangedRequest message.
         * @function verify
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusChangedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.newStatus != null && message.hasOwnProperty("newStatus")) {
                var error = $root.proto.Status.verify(message.newStatus);
                if (error)
                    return "newStatus." + error;
            }
            return null;
        };

        /**
         * Creates a StatusChangedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.StatusChangedRequest} StatusChangedRequest
         */
        StatusChangedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.StatusChangedRequest)
                return object;
            var message = new $root.proto.StatusChangedRequest();
            if (object.newStatus != null) {
                if (typeof object.newStatus !== "object")
                    throw TypeError(".proto.StatusChangedRequest.newStatus: object expected");
                message.newStatus = $root.proto.Status.fromObject(object.newStatus);
            }
            return message;
        };

        /**
         * Creates a plain object from a StatusChangedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {proto.StatusChangedRequest} message StatusChangedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusChangedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.newStatus = null;
            if (message.newStatus != null && message.hasOwnProperty("newStatus"))
                object.newStatus = $root.proto.Status.toObject(message.newStatus, options);
            return object;
        };

        /**
         * Converts this StatusChangedRequest to JSON.
         * @function toJSON
         * @memberof proto.StatusChangedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusChangedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusChangedRequest
         * @function getTypeUrl
         * @memberof proto.StatusChangedRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusChangedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/proto.StatusChangedRequest";
        };

        return StatusChangedRequest;
    })();

    return proto;
})();

module.exports = $root;
