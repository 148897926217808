import { Layout } from "src/app/gui/components/Layout";
import { EndCallIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { Button } from "@mui/material";
import type { Session } from "src/app/model/sessions/Session";

export const EndCallButton = connect<{
  session: Session;
}>(({ session }) => (
  <Layout column marginLeft={10}>
    <Button
      onClick={() => {
        void session.endCall();
      }}
      style={{ background: "#aa3333" }}
      variant="contained"
    >
      <EndCallIcon height={32} style={{ fill: "#dfdfdf" }} width={32} />
    </Button>
  </Layout>
));
