import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Usage license expired. Reconnect to purchase a new license.
 */
export class UsageLicenseExpired extends GroupTalkAPIError {
  public constructor() {
    super("Usage license expired. Reconnect to purchase a new license.", false);
  }
}
