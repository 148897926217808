import { Token } from "src/lib/modules/Token";

/**
 * Encapsulation of a partial authentication token used in two-factor authentication.
 * Complete with a verification code to make this a valid token.
 */
export class PartialToken {
  /**
   * Token id
   */
  public id: string;
  /**
   * Token realm
   */
  public realm: string;
  /**
   * Token secret
   */
  public secret: string;
  public constructor(id: string, secret: string, realm: string) {
    this.id = id;
    this.secret = secret;
    this.realm = realm;
  }
  /**
   * Supply verification code to make this a valid token.
   * @param {string} code
   * @returns {Token} The valid token
   */
  public supplyVerificationCode(code: string): Token {
    return new Token(this.id, this.secret + code, this.realm);
  }
}
