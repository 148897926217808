import { GtError } from "src/app/gui/components/GtError";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Head } from "src/app/gui/offline/Head";
import { connect } from "src/app/state/connect";
import { Divider, CircularProgress } from "@mui/material";

export const Connecting = connect<{
  children: React.ReactNode;
  header: string;
}>(({ children, header }, state) => {
  const { isConnecting } = state.connecting;
  const { error } = state.login;
  return (
    <Layout column>
      <Head />
      <Layout
        alignCenter
        background="#eee"
        boxShadow
        column
        stretch
        width="400"
      >
        <Layout column padding={20}>
          <Text center color="#6f8294" size={20}>
            {isConnecting ? "Connecting..." : header}
          </Text>
        </Layout>
        <Divider />
        <Layout relative>
          <Layout
            style={{
              opacity: isConnecting ? 1.0 : 0.0,
              transition: "opacity 0.2s ease-in",
            }}
            absolute
            alignCenter
            alignMiddle
            column
            height="100%"
            width="100%"
          >
            <Layout alignCenter alignMiddle column>
              {error && (
                <Layout column marginBottom={20}>
                  <GtError message={error} />
                </Layout>
              )}
              <CircularProgress />
            </Layout>
          </Layout>
          <Layout
            style={{
              opacity: isConnecting ? 0.0 : 1.0,
              pointerEvents: isConnecting ? "none" : undefined,
              transition: "all 0.2s ease-in",
              transitionProperty: "opacity",
            }}
            alignCenter
            column
            paddingTop={20}
          >
            <Layout
              style={{
                height: "auto",
                overflow: "hidden",
                textAlign: "center",
                transition: "max-height 0.4s ease-out",
              }}
              width={320}
            >
              {error && <GtError message={error} />}
            </Layout>
            {children}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
});
