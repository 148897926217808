export enum ChannelMediaType {
  /** Channel is a PTT group. */
  GroupTalkPtt = 0,
  /** Reserved. */
  Reserved1 = 2,
  /** Reserved. */
  Reserved2 = 3,
  /** Reserved. */
  Reserved3 = 4,
  /** Reserved. */
  Reserved4 = 5,
  /** Channel is a Twilio Full Duplex group. */
  TwilioFullDuplex = 1,
}
