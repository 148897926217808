import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Closed by operator.
 */
export class TerminationByRequest extends GroupTalkAPIError {
  public constructor() {
    super("Closed by operator.", false);
  }
}
