/* eslint-disable no-console */

const DEBUG_STYLE = "color:#afa;";
const TRACE_STYLE = "color:#eee; font-style:italic";
const WARN_STYLE = "color:#fe7; font-weight:bold";
const ERROR_STYLE = "color:#f88; font-weight:bold";
const INFO_STYLE = "color:#efa; font-weight:bold";

const LEVEL_TRACE = 0;
const LEVEL_DEBUG = 1;
const LEVEL_INFO = 2;
const LEVEL_WARN = 3;
const LEVEL_ERROR = 4;
const LEVEL_NONE = 5;

function padding(string: string, width: number, padchar = "0"): string {
  if (string.length >= width) {
    return string;
  }
  return new Array(width - string.length + 1).join(padchar) + string;
}

function getPrefix(name: string, message: string, object?: any): string {
  const s1 = typeof message === "string" ? "%s" : "%O";
  const s2 = typeof object === "string" ? "   %s" : "   %O";
  return `${padding(`%c${name}`, 34, " ")} | ${s1}${object ? s2 : ""}`;
}

export class Logger {
  private static readonly loggers: Record<string, Logger> = {};
  private readonly debugLevel: number;
  private readonly name: string;
  public constructor(name: string, debugLevel: number) {
    this.name = name;
    this.debugLevel = debugLevel;
  }
  public static getLogger(loggerName: string): Logger {
    const debugLevel =
      window.gtConfig && window.gtConfig.logging ? LEVEL_TRACE : LEVEL_NONE;
    if (!this.loggers[loggerName]) {
      this.loggers[loggerName] = new Logger(loggerName, debugLevel);
    }
    return this.loggers[loggerName];
  }
  public debug(message: string, object?: any): void {
    if (this.debugLevel <= LEVEL_DEBUG) {
      if (object) {
        console.log(
          getPrefix(this.name, message, object),
          DEBUG_STYLE,
          message,
          object
        );
      } else {
        console.log(getPrefix(this.name, message), DEBUG_STYLE, message);
      }
    }
  }
  public error(message: string, object?: any): void {
    if (this.debugLevel <= LEVEL_ERROR) {
      if (object) {
        console.error(
          getPrefix(this.name, message, object),
          ERROR_STYLE,
          message,
          object
        );
      } else {
        console.error(getPrefix(this.name, message), ERROR_STYLE, message);
      }
    }
  }
  public info(message: string, object?: any): void {
    if (this.debugLevel <= LEVEL_INFO) {
      if (object) {
        console.info(
          getPrefix(this.name, message, object),
          INFO_STYLE,
          message,
          object
        );
      } else {
        console.info(getPrefix(this.name, message), INFO_STYLE, message);
      }
    }
  }
  public trace(message: string, object?: any): void {
    if (this.debugLevel <= LEVEL_TRACE) {
      if (object) {
        console.debug(
          getPrefix(this.name, message, object),
          TRACE_STYLE,
          message,
          object
        );
      } else {
        console.debug(getPrefix(this.name, message), TRACE_STYLE, message);
      }
    }
  }
  public warn(message: string, object?: any): void {
    if (this.debugLevel <= LEVEL_WARN) {
      if (object) {
        console.warn(
          getPrefix(this.name, message, object),
          WARN_STYLE,
          message,
          object
        );
      } else {
        console.warn(getPrefix(this.name, message), WARN_STYLE, message);
      }
    }
  }
}
