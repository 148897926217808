import { GtActionButton } from "src/app/gui/components/GtActionButton";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import {
  OnlineIcon,
  AlarmIcon,
  TicketIcon,
  PriorityTicketIcon,
  OfflineIcon,
} from "src/app/images";
import type { GtTableColumn } from "src/app/gui/components/GtTableColumn";
import type { Ticket } from "src/app/model/queues/Ticket";

export const TicketColumns: GtTableColumn<Ticket>[] = [
  {
    header: "Priority",
    id: "priority",
    render: ({ isEmergency, isHighPriority }) => (
      <Layout alignMiddle row>
        {isEmergency && <AlarmIcon style={{ fill: "#7d1616" }} />}
        {!isEmergency && isHighPriority && (
          <PriorityTicketIcon style={{ fill: "#4e4e4e" }} />
        )}
        {!isEmergency && !isHighPriority && (
          <TicketIcon style={{ fill: "#1d1d1d" }} />
        )}
      </Layout>
    ),
  },
  { header: "Name", id: "name", render: ({ name }) => <Text>{name}</Text> },
  {
    header: "Queue",
    id: "queue",
    render: ({ queue }) => <Text>{queue.name}</Text>,
  },
  {
    header: "PTT Group",
    id: "channel",
    render: ({ channelName }) => <Text>{channelName || ""}</Text>,
  },
  {
    header: "Online Status",
    id: "online",
    render: ({ online }) => (
      <Layout gap={2} row>
        {online && <OnlineIcon style={{ fill: "#095f09" }} />}
        {!online && <OfflineIcon style={{ fill: "#4e4e4e" }} />}
        <Text color={online ? "#095f09" : "#4e4e4e"}>
          {online ? "online" : "offline"}
        </Text>
      </Layout>
    ),
  },
  {
    header: "Status",
    id: "status",
    render: ({ status }) => (
      <Layout
        background={status ? status.backgroundColor || "#ffffff24" : "unset"}
        column
        gap={2}
        marginRight={20}
        padding={3}
        style={{ borderRadius: 5 }}
      >
        {!status && (
          <Text color="#4e4e4e" italic>
            No status
          </Text>
        )}
        {status && (
          <Text center color={status.foregroundColor || "unset"}>
            {status.name}
          </Text>
        )}
      </Layout>
    ),
  },
  {
    header: "Time in queue",
    id: "time",
    render: ({ opened }) => <Text>{opened}</Text>,
  },
  {
    header: "Presses",
    id: "presses",
    render: ({ numRequests }) => <Text>{numRequests}</Text>,
  },
  {
    header: "Actions",
    id: "actions",
    render: (ticket) => (
      <Layout gap={5} row>
        {(ticket.maySteal || ticket.mayPick) && (
          <GtActionButton
            action={{
              id: "pick",
              name:
                ticket && ticket.maySteal
                  ? `Steal from ${ticket.pickedBy}`
                  : "Pick",
              onClick: () => {
                void ticket.pick();
              },
              render: () => <Text>{ticket.maySteal ? "Steal" : "Pick"}</Text>,
              show: ticket.maySteal || ticket.mayPick,
            }}
          />
        )}
      </Layout>
    ),
  },
];
