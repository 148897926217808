import { Room } from "src/app/model/video/Room";
import { observableClass } from "src/app/state/observableClass";
import type { State } from "src/app/model/State";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { FullDuplexUuid } from "src/nextgen/types/FullDuplexUuid";
import type { RemoteAudioTrack } from "twilio-video";

export class Rooms {
  private list: Record<ChannelUuid, Room> = {};
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public get audioTracksFromAllRooms(): RemoteAudioTrack[] {
    const audioTracks: RemoteAudioTrack[] = [];
    for (const room of Object.values(this.list)) {
      for (const participant of Object.values(room.presence)) {
        const audioTrack = participant.twilioPeerAudioTrack;
        if (audioTrack) {
          audioTracks.push(audioTrack);
        }
      }
    }
    return audioTracks;
  }
  public get roomWithChannelUuid() {
    return (channelUuid: ChannelUuid) =>
      Object.values(this.list).find((room) => room.channelUuid === channelUuid);
  }
  public get roomWithPeerUserUuid() {
    return (peerUserUuid: ClientUserUuid) =>
      Object.values(this.list).find(
        (room) => room.peerUserUuid === peerUserUuid
      );
  }
  public closeDown(): void {
    Object.keys(this.list).forEach((key) => {
      this.roomStopped(key);
    });
  }
  /**
   * This is called when a full duplex session is started.
   */
  public roomStarted(options: {
    channelUuid?: ChannelUuid;
    fullDuplexUuid: FullDuplexUuid;
    peerUserUuid?: ClientUserUuid;
  }): void {
    if (!this.list[options.fullDuplexUuid]) {
      const room = new Room(this.state, options);
      this.list[options.fullDuplexUuid] = room;
      void room.setup();
    }
  }
  /**
   * This is called when full duplex session should be stopped.
   * (Typically by leaving the channel)
   */
  public roomStopped(fullDuplexUuid: ChannelUuid): void {
    if (this.list[fullDuplexUuid]) {
      this.list[fullDuplexUuid].stop();
      delete this.list[fullDuplexUuid];
    }
  }
}
