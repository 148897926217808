import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { AlertIcon } from "src/app/images";
import { connect } from "src/app/state/connect";

export const NO_MICROPHONE_HEIGHT = 30;

export const NoMicrophone = connect(() => (
  <Layout
    absolute
    alignCenter
    alignMiddle
    background="linear-gradient(to bottom, rgb(238 105 118), rgb(205 93 100))"
    height={NO_MICROPHONE_HEIGHT}
    left={0}
    right={0}
    row
    top={0}
  >
    <Layout alignMiddle gap={10} row>
      <AlertIcon style={{ height: 20, width: 20 }} />
      <Text color="#000">No Microphone Input</Text>
    </Layout>
  </Layout>
));
