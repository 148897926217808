import { Sessions } from "src/app/model/sessions/Sessions";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import { getReadableTimeFormat } from "src/util/getReadableTimeFormat";
import { now } from "mobx-utils";
import type { Panel } from "src/app/model/panels/Panel";
import type { Queue } from "src/app/model/queues/Queue";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";
import type { QueueEntry } from "src/lib/modules/QueueEntry";
import type { ChannelNodeId } from "src/lib/types/ChannelNodeId";
import type { Location } from "src/lib/types/Location";
import type { Status } from "src/lib/types/Status";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";

export class Ticket {
  public readonly channelId?: ChannelNodeId;
  public readonly channelName?: string;
  public readonly channelUuid?: ChannelUuid;
  public readonly closed?: Date;
  public readonly deviceEntityId?: string;
  public readonly lastLocation?: Location;
  public readonly numRequests: number;
  public readonly openedTime: number;
  public readonly pickedBy?: null | string;
  public readonly pickedByMe?: boolean;
  public readonly queueEntryEntityId: string;
  public readonly userEntityId?: string;
  public readonly userUuid?: string;
  public leaveGroupWhenClosing = false;
  public name: string;
  public online: boolean;
  public status?: Status;
  public title?: string;
  // True if this ticket should count when notifying of new tickets
  public isNew: boolean;
  /**
   * Unread count is currently not updated - but here because of general treatment.
   */
  public unreadCount = 0;
  private readonly callableReference?: string;
  private readonly monitorEntityId?: string;
  private isPickingTicket = false;
  public constructor(
    private readonly state: State,
    public readonly queueEntry: QueueEntry,
    public readonly queue: Queue,
    oldTicket?: Ticket
  ) {
    this.queueEntryEntityId = queueEntry.queueEntryEntityId;
    this.closed = queueEntry.closed;
    this.openedTime = queueEntry.opened.getTime();
    this.pickedBy = queueEntry.pickedBy === "" ? null : queueEntry.pickedBy;
    this.pickedByMe = queueEntry.pickedByMe;
    // Count this as new entry if number of requests has been increasing since last update,
    // or if it is a new, unpicked ticket
    // this.isNew =
    //   (oldTicket?.numRequests ?? 0) < queueEntry.numRequests && !this.pickedBy;
    this.isNew = !oldTicket && !this.pickedBy;
    this.numRequests = queueEntry.numRequests;
    this.userEntityId = queueEntry.userEntityId;
    this.deviceEntityId = queueEntry.deviceEntityId;
    this.callableReference = queueEntry.callableReference;
    this.lastLocation = queueEntry.lastLocation;
    this.monitorEntityId = queueEntry.monitorEntityId;
    this.channelId = queueEntry.channelId;
    this.channelUuid = queueEntry.channelUuid;
    this.channelName = queueEntry.channelName;
    this.userUuid = queueEntry.userUuid;
    this.name = queueEntry.name;
    this.title = queueEntry.title;
    this.status = oldTicket?.status ?? undefined;
    this.online = oldTicket?.online ?? false;
    this.leaveGroupWhenClosing = oldTicket?.leaveGroupWhenClosing ?? false;
    observableClass(this);
  }

  public get callSession(): Session | undefined {
    return this.userUuid
      ? this.state.online?.sessions.sessionWithPeerUserUuid(this.userUuid)
      : undefined;
  }
  public get callable(): boolean {
    return !!(
      !this.monitorable &&
      this.pickedByMe &&
      this.callableReference != null
    );
  }
  public get closeTicketsAndCallSimultaneously(): boolean {
    return (
      (this.state.settings.queues.normal.closeTicketsAndCallSimultaneously
        .value &&
        this.isNormal) ||
      (this.state.settings.queues.prio.closeTicketsAndCallSimultaneously
        .value &&
        this.isHighPriority) ||
      (this.state.settings.queues.emergency.closeTicketsAndCallSimultaneously
        .value &&
        this.isEmergency)
    );
  }
  public get hasPassedDelay() {
    return (delay: number): boolean => {
      // This will make time an observable and update calling function every second.
      // (Don't do anything stupid every second.)
      // We specifically check for 0 to turn off this feature unless opted in.
      return (
        delay === 0 ||
        new Date(now(1000)).getTime() - this.openedTime >= delay * 1000
      );
    };
  }
  public get isEmergency(): boolean {
    return this.queue.emergency;
  }
  public get isHighPriority(): boolean {
    return this.queue.isHighPriority && !this.isEmergency;
  }
  public get isNormal(): boolean {
    return !this.isEmergency && !this.isHighPriority;
  }
  public get joinGroupWhenPicking(): boolean {
    return (
      (this.state.settings.queues.normal.joinGroupWhenPicking.value &&
        this.isNormal) ||
      (this.state.settings.queues.prio.joinGroupWhenPicking.value &&
        this.isHighPriority) ||
      (this.state.settings.queues.emergency.joinGroupWhenPicking.value &&
        this.isEmergency)
    );
  }
  public get locatable(): boolean {
    return !!(
      this.pickedByMe &&
      (this.isEmergency || this.state.online?.location.maySubscribe)
    );
  }
  public get mayJoinGroup(): boolean {
    return !!(this.pickedByMe && this.channelId);
  }
  public get mayPick(): boolean {
    return this.pickedBy == null;
  }
  public get maySteal(): boolean {
    return this.pickedBy != null && !this.pickedByMe;
  }
  public get monitorable(): boolean {
    return !!(this.pickedByMe && !!this.monitorEntityId);
  }
  public get monitoringSession(): Session | undefined {
    return this.monitorable && this.state.online
      ? this.state.online.sessions.list.find(
          (session) => session.callRef === this.deviceEntityId
        )
      : undefined;
  }
  public get opened(): string {
    // Update every minute.
    return getReadableTimeFormat({
      ago: false,
      fromTime: this.queueEntry.opened,
      now: new Date(now(60000)),
    });
  }
  public get priority(): number {
    return this.queue.priority;
  }
  public get session(): Session | undefined {
    return this.queueEntry.channelUuid
      ? this.state.online?.sessions.sessionWithChannelUuid(
          this.queueEntry.channelUuid
        )
      : undefined;
  }
  public get startMonitoringWhenPicking(): boolean {
    return (
      this.isEmergency &&
      this.state.settings.queues.emergency.startMonitoringWhenPicking!.value
    );
  }
  public get startPrivateCallWhenPicking(): boolean {
    return (
      (this.state.settings.queues.normal.startPrivateCallWhenPicking.value &&
        this.isNormal) ||
      (this.state.settings.queues.prio.startPrivateCallWhenPicking.value &&
        this.isHighPriority) ||
      (this.state.settings.queues.emergency.startPrivateCallWhenPicking.value &&
        this.isEmergency)
    );
  }
  public autoAnswerCall(): void {
    if (this.callable) {
      this.state.online?.setupCall.call({
        autoAnswer: true,
        callableEntityId: this.callableReference!,
        fullDuplex: false,
        name: this.name,
      });
    }
  }
  public close({ alsoCloseCall } = { alsoCloseCall: true }): void {
    void this.queueEntry.close();
    this.terminateOngoing(alsoCloseCall);
  }
  public joinGroup(): void {
    if (this.state.online) {
      if (this.channelId) {
        void this.state.online.channels.updateJoined(this.channelId, true);
      }
      if (this.session) {
        this.state.online.sessions.setSessionFocus(this.session);
      } else {
        this.state.online.sessions.sessionFocus = null;
        Sessions.setChannelFocus(this.queueEntry.channelUuid);
      }
    }
  }
  public monitor(): void {
    if (this.monitorable) {
      this.state.online?.setupCall.monitor(this.monitorEntityId!);
    }
  }
  public openOrFocusPanel(panel: Panel): void {
    this.state.panels.add({
      customData: {
        entityId: this.queueEntryEntityId,
      },
      name: this.name,
      panel,
      templateId: TemplateId.location,
    });
  }
  public async pick(): Promise<void> {
    if (this.isPickingTicket) {
      return;
    }
    this.isPickingTicket = true;
    try {
      await this.queueEntry.pick(this.pickedBy != null);
      this.isPickingTicket = false;
    } catch (error: any) {
      this.state.dialogs.show({
        text: error.message,
        title: "Picking ticket failed",
      });
      this.isPickingTicket = false;
    }
  }
  public setIsNew(newValue: boolean): void {
    this.isNew = newValue;
  }
  public unpick(): void {
    void this.queueEntry.unpick();
    this.terminateOngoing(true);
  }
  private terminateOngoing(closeCall: boolean): void {
    if (
      closeCall &&
      this.callSession &&
      this.closeTicketsAndCallSimultaneously
    ) {
      void this.callSession.endCall({ dontCloseTicket: true });
    }
    if (this.leaveGroupWhenClosing && this.channelId) {
      this.leaveGroupWhenClosing = false;
      void this.state.online?.channels.updateJoined(this.channelId, false);
    }
  }
}
