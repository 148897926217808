import { GtCheckbox } from "src/app/gui/components/GtCheckbox";
import { GtError } from "src/app/gui/components/GtError";
import { GtPanelTab } from "src/app/gui/components/GtPanelTab";
import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Presence } from "src/app/gui/panels/channel/Presence";
import { BreakPatchMenu } from "src/app/gui/panels/channels/BreakPatchMenu";
import { PTTButton } from "src/app/gui/panels/channels/detailedGroup/PTTButton";
import { RecordMeter } from "src/app/gui/panels/channels/detailedGroup/RecordMeter";
import { VolumeControls } from "src/app/gui/panels/channels/detailedGroup/VolumeControls";
import { LatestSpeaker } from "src/app/gui/panels/channels/LatestSpeaker";
import { LockButton } from "src/app/gui/panels/channels/LockButton";
import { LockGroupMenu } from "src/app/gui/panels/channels/LockGroupMenu";
import { PatchButton } from "src/app/gui/panels/channels/PatchButton";
import { PowerButton } from "src/app/gui/panels/channels/PowerButton";
import { ReplayButton } from "src/app/gui/panels/channels/ReplayButton";
import { Map } from "src/app/gui/panels/location/Map";
import { Messages } from "src/app/gui/panels/messages/Messages";
import { FullDuplexPTTButton } from "src/app/gui/panels/video/FullDuplexPTTButton";
import { Presence as RoomPresence } from "src/app/gui/panels/video/Presence";
import {
  MessageIcon,
  UsersIcon,
  LocationIcon,
  BroadcastIcon,
} from "src/app/images";
import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const Channel = connect<{
  panel: Panel;
}>(({ panel }) => {
  const {
    channel,
    groupTab,
    groupTabIsEnabled,
    id,
    locationData,
    messageChannel,
    setGroupTab,
    showLatestSpeaker,
    showPttButton,
    showReplayButton,
    showVolumeControls,
  } = panel.channelPanelData!;

  if (!channel) {
    return (
      <Layout column>
        <GtError message="No group selected" severity="info" />
      </Layout>
    );
  }
  const { isFullDuplex, session } = channel;
  const maySelectForBroadcasting = session && session.maySelectForBroadcasting;
  let presenceNumber = 0;
  if (session != null) {
    if (!isFullDuplex) {
      presenceNumber = session.presence.length;
    } else if (channel.room) {
      presenceNumber = channel.room.presenceCount;
    }
  }
  return (
    <Layout column grow>
      <Layout alignMiddle height={55} marginBottom={4} row>
        <Layout marginRight={10} row>
          {channel.hasLock && (
            <GtTooltip
              id={`${panel.id}_${channel.channelUuid}_locked`}
              title={`${channel.locked ? "Unlock" : "Lock"} group`}
            >
              <LockGroupMenu channel={channel} panel={panel}>
                <LockButton channel={channel} noClick />
              </LockGroupMenu>
            </GtTooltip>
          )}
          {!channel.locked && (
            <GtTooltip
              id={`${panel.id}_${channel.channelUuid}_leave`}
              title={`${channel.joined ? "Leave" : "Join"} group`}
            >
              <PowerButton channel={channel} />
            </GtTooltip>
          )}
          {channel.hasPatch && (
            <BreakPatchMenu channel={channel} panel={panel}>
              <PatchButton />
            </BreakPatchMenu>
          )}
        </Layout>
        {!isFullDuplex && session && showPttButton && (
          <PTTButton
            channel={channel}
            session={session}
            showLatestSpeaker={false}
          />
        )}
        {isFullDuplex && channel.room && session && (
          <FullDuplexPTTButton
            detailed={false}
            name={channel.name}
            room={channel.room}
          />
        )}
        {session && session.outgoingTalkburst && session.isFocused && (
          <Layout height={40} marginLeft={10} relative width={234}>
            <RecordMeter session={session} width={234} />
          </Layout>
        )}
        {(!session || !showPttButton) && (
          <Layout row>
            <Layout column>
              <Text
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                bold
                color="#000"
                nowrap
                size={14}
              >
                {channel.name}
              </Text>
              {channel.description && (
                <Text
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  color="#474747"
                  nowrap
                  size={10}
                >
                  {channel.description}
                </Text>
              )}
            </Layout>
          </Layout>
        )}
        {!isFullDuplex && maySelectForBroadcasting && (
          <Layout marginLeft={10} row>
            <BroadcastIcon style={{ fill: "#1d568c", height: 32, width: 32 }} />
            <GtCheckbox
              checked={session.selectedForBroadcasting}
              onChange={() => session.toggleSelectForBroadcast()}
            />
          </Layout>
        )}
        <Layout grow row />
        <GtPanelTab
          onClick={() => {
            if (groupTab !== ChannelTabSetting.Messages) {
              setGroupTab(ChannelTabSetting.Messages);
            } else {
              messageChannel?.setScroll(true);
            }
          }}
          Icon={MessageIcon}
          disabled={!groupTabIsEnabled(ChannelTabSetting.Messages)}
          highlightNumber
          id={`show_messages_${id}`}
          number={messageChannel == null ? 0 : messageChannel.unread}
          positiveOnly
          selected={groupTab === ChannelTabSetting.Messages}
          tooltip="Show messages"
        />
        <GtPanelTab
          onClick={() => {
            setGroupTab(ChannelTabSetting.Presence);
          }}
          Icon={UsersIcon}
          disabled={!groupTabIsEnabled(ChannelTabSetting.Presence)}
          id={`show_presence_${id}`}
          number={presenceNumber}
          positiveOnly={false}
          selected={groupTab === ChannelTabSetting.Presence}
          tooltip="Show presence"
        />
        <GtPanelTab
          onClick={() => {
            setGroupTab(ChannelTabSetting.Locations);
          }}
          Icon={LocationIcon}
          disabled={!groupTabIsEnabled(ChannelTabSetting.Locations)}
          id={`show_locations_${id}`}
          selected={groupTab === ChannelTabSetting.Locations}
          tooltip="Show locations"
        />
      </Layout>
      {session &&
        !isFullDuplex &&
        (showVolumeControls || showReplayButton || showLatestSpeaker) && (
          <Layout alignMiddle background="#00000008" marginBottom={10} row>
            {showVolumeControls && (
              <Layout marginLeft={10} width={200}>
                <VolumeControls session={session} />
              </Layout>
            )}
            {showReplayButton && <ReplayButton session={session} />}
            {showLatestSpeaker &&
              session.percentActive > 0 &&
              session.latestSpeakerPresence && (
                <Layout column marginLeft={10}>
                  <LatestSpeaker panel={panel} session={session} />
                </Layout>
              )}
          </Layout>
        )}
      <Layout alignTop column grow>
        {groupTab === ChannelTabSetting.Presence &&
          !isFullDuplex &&
          groupTabIsEnabled(ChannelTabSetting.Presence) && (
            <Presence panel={panel} session={session!} />
          )}
        {groupTab === ChannelTabSetting.Presence &&
          isFullDuplex &&
          groupTabIsEnabled(ChannelTabSetting.Presence) && (
            <RoomPresence channel={channel} panel={panel} />
          )}
        {groupTab === ChannelTabSetting.Messages &&
          groupTabIsEnabled(ChannelTabSetting.Messages) &&
          messageChannel && (
            <Messages
              channelLocked={channel.locked}
              messageChannel={messageChannel}
              panel={panel}
            />
          )}
        {groupTab === ChannelTabSetting.Locations &&
          groupTabIsEnabled(ChannelTabSetting.Locations) && (
            <Map locationData={locationData!} />
          )}
      </Layout>
    </Layout>
  );
});
