import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { ContactMenu } from "src/app/gui/panels/contacts/ContactMenu";
import { PresenceIcon } from "src/app/gui/shared/PresenceIcon";
import { AlarmIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { ButtonBase } from "@mui/material";
import type { Panel } from "src/app/model/panels/Panel";
import type { Presence as PresenceModel } from "src/app/model/presence/Presence";
import type { Status } from "src/lib/types/Status";

const transistionStyle = { transition: "all .4s" };
const badgeBackground = (
  isSpeaking: boolean,
  alarmActive: boolean,
  status?: Status
): string => {
  if (alarmActive) {
    return "#ff0000";
  }
  if (status && status.backgroundColor) {
    return status.backgroundColor;
  }
  if (isSpeaking) {
    return "#33aa33";
  }
  return "#d0d4d8";
};
const boxShadow = (isLatestSpeaker: boolean): string => {
  if (isLatestSpeaker) {
    return "0px 0px 0px 5px #579868";
  }
  return "1px 2px 2px 1px rgba(0,0,0,.1)";
};
export const Presence = connect<{
  description?: null | string;
  mini?: boolean;
  panel: Panel;
  presence: PresenceModel;
}>(({ description = null, mini = false, panel, presence }) => {
  const {
    alarmActive,
    contactDetails,
    fetchContactDetails,
    isLatestSpeaker,
    isSpeaking,
    name,
    status,
    title,
    type,
    unavailable,
  } = presence;

  let nameColor = isSpeaking ? "#fff" : "#222";
  let titleColor = isSpeaking ? "#fff" : "#6d707b";
  const titleColorWithAlarm = "#222";

  const iconStyle = {
    ...transistionStyle,
    fill: isSpeaking ? "#fff" : "#333",
    height: isSpeaking && !mini ? 24 : 16,
    width: isSpeaking && !mini ? 24 : 16,
  };
  if (status && status.foregroundColor) {
    nameColor = status.foregroundColor;
    titleColor = status.foregroundColor;
    iconStyle.fill = status.foregroundColor;
  }
  let height = 28;
  if (mini) {
    height = 20;
  } else if (isSpeaking) {
    height = 42;
  }
  return (
    <ContactMenu
      contactDetails={contactDetails}
      fetchContactDetails={fetchContactDetails}
      menuId={`${presence.presenceId}`}
      panel={panel}
    >
      <GtTooltip
        id={`${panel.id}_${presence.presenceId}`}
        title={status ? status.name : ""}
      >
        <ButtonBase>
          <Layout
            style={{
              ...transistionStyle,
              border: "1px solid #333",
              borderRadius: isSpeaking && !mini ? "6px" : "3px",
              boxShadow: boxShadow(isLatestSpeaker && !mini),
              opacity: unavailable ? 0.5 : 1.0,
              textAlign: "left",
            }}
            alignMiddle
            background={badgeBackground(isSpeaking, alarmActive, status)}
            column
            height={height}
            margin={2}
            padding={mini ? "3px 3px" : "5px 8px"}
          >
            <Layout alignMiddle gap={5} row>
              {alarmActive && <AlarmIcon style={iconStyle} />}
              {!alarmActive && (
                <PresenceIcon presenceType={type} style={iconStyle} />
              )}
              <Text
                style={{
                  maxWidth: mini ? 100 : undefined,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  ...transistionStyle,
                }}
                color={nameColor}
                nowrap
                size={isSpeaking && !mini ? 18 : 12}
                weight={400}
              >
                {name}
              </Text>
            </Layout>
            {!mini && (
              <Text
                color={alarmActive ? titleColorWithAlarm : titleColor}
                size={isSpeaking && !mini ? 15 : 10}
                style={{ ...transistionStyle }}
              >
                {description || title}
              </Text>
            )}
          </Layout>
        </ButtonBase>
      </GtTooltip>
    </ContactMenu>
  );
});
