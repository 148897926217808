import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { HEADER_HEIGHT } from "src/app/gui/header/Header";
import { NO_MICROPHONE_HEIGHT } from "src/app/gui/header/NoMicrophone";
import { Panel } from "src/app/gui/panels/Panel";
import { panelTypeInfo } from "src/app/gui/shared/panelTypeInfo";
import {
  SettingsIcon,
  CheckIcon,
  ZoomOutIcon,
  ZoomInIcon,
  MoreHorizIcon,
} from "src/app/images";
import { connect } from "src/app/state/connect";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { IconButton } from "@mui/material";
import * as FlexLayout from "flexlayout-react";
import { cloneElement, useEffect } from "react";
import type { ComponentId } from "src/app/types/ComponentId";

const CustomizeButton = connect<{
  onClick: () => void;
  panelId: ComponentId;
}>(({ onClick, panelId }, state) => {
  const { isCustomizing } = state.panels;
  return (
    <IconButton
      aria-label="Customize"
      disableRipple
      onClick={onClick}
      style={{ backgroundColor: "transparent", padding: 0, width: 18 }}
    >
      {isCustomizing(panelId) ? (
        <CheckIcon style={{ fill: "#000", height: 18, width: 18 }} />
      ) : (
        <SettingsIcon style={{ fill: "#000", height: 18, width: 18 }} />
      )}
    </IconButton>
  );
});

const PanelTitle = connect<{
  name: string;
  panelId: ComponentId;
}>(({ name, panelId }, state) => {
  const panel = state.panels.list[panelId];
  if (!panel) {
    return <div />;
  }

  const { badgeNumber } = panel;
  let background;
  let textColor;
  if (panel.type === "emergencyTicket") {
    background = "#d13e3e";
    textColor = "#000";
  } else if (panel.type === "priorityTicket") {
    background = "#c9aa70";
    textColor = "#000";
  }
  return (
    <Layout alignMiddle background={background} row style={{ borderRadius: 2 }}>
      <Layout alignLeft column>
        <Text color={textColor}>{name}</Text>
      </Layout>
      {badgeNumber != null && badgeNumber > 0 && (
        <Layout
          alignCenter
          alignMiddle
          background="rgb(170, 51, 51)"
          column
          height={18}
          marginLeft={10}
          style={{ borderRadius: "20%" }}
          width={24}
        >
          <Text color="rgb(170, 170, 170)" size={14}>
            {badgeNumber}
          </Text>
        </Layout>
      )}
    </Layout>
  );
});

const PanelIcon = connect<{
  panelId: ComponentId;
}>(({ panelId }, state) => {
  const panel = state.panels.list[panelId];
  const panelType = panel ? panel.type : "unknown";
  const { icon } = panelTypeInfo[panelType];
  const isSelected = state.layout.visible(panelId);
  return (
    <Layout
      alignCenter
      alignMiddle
      column
      height={18}
      marginRight={5}
      width={18}
    >
      {cloneElement(icon, {
        style: { fill: isSelected ? "#fff" : "#000", height: 18, width: 18 },
      })}
    </Layout>
  );
});

const titleFactory = (node: FlexLayout.TabNode): JSX.Element => {
  const component = node.getComponent() as ComponentId;
  const name = node.getName();
  return <PanelTitle name={name} panelId={component} />;
};

const iconFactory = (node: FlexLayout.TabNode): JSX.Element => {
  const component = node.getComponent() as ComponentId;
  return <PanelIcon panelId={component} />;
};

export const Main = connect((_, state) => {
  const { flexLayoutModel } = state.layout;
  const { layout } = state;
  const { customizable, customize, onClosing, onCreate, onDelete } =
    state.panels;
  const showNoMicrophoneInput =
    state.online?.audio.showNoMicrophoneInput ?? false;

  const factory = (node: FlexLayout.TabNode): JSX.Element => {
    const component = node.getComponent() as ComponentId;
    // This is to ensure the tab color is updated if the panel is moved around.
    state.panels.updateTabColor(component);
    return <Panel panelId={component} />;
  };

  // When we are closing down, notify panels that we are destroyed
  useEffect(() => () => state.panels.onGone(), [state.panels]);

  const reportModelChanged = (): void => {
    setTimeout(() => {
      layout.reportFlexLayoutModelChanged();
    }, 1);
  };
  useEffect(() => {
    /**
     * Notify things that depend on panel sizes
     */
    window.addEventListener("resize", reportModelChanged);
    return () => {
      window.removeEventListener("resize", reportModelChanged);
    };
  });

  return (
    <Layout
      top={
        showNoMicrophoneInput
          ? NO_MICROPHONE_HEIGHT + HEADER_HEIGHT
          : HEADER_HEIGHT
      }
      absolute
      bottom={0}
      left={0}
      right={0}
    >
      {flexLayoutModel && (
        <FlexLayout.Layout
          icons={{
            maximize: () => (
              <Layout alignMiddle column style={{ cursor: "pointer" }}>
                <ZoomOutIcon style={{ height: 18, width: 18 }} />
              </Layout>
            ),
            more: () => (
              <Layout alignMiddle column style={{ cursor: "pointer" }}>
                <MoreHorizIcon
                  style={{ fill: "#000", height: 18, width: 18 }}
                />
              </Layout>
            ),
            restore: () => (
              <Layout alignMiddle column style={{ cursor: "pointer" }}>
                <ZoomInIcon style={{ height: 18, width: 18 }} />
              </Layout>
            ),
          }}
          onAction={(action) => {
            /**
             * This make it so that things that depend on state of the flexLayout such as
             * size of panels get an mobx update
             */
            reportModelChanged();
            if (action.type === "FlexLayout_DeleteTab") {
              const node = flexLayoutModel.getNodeById(
                action.data.node
              ) as FlexLayout.TabNode;
              const component = node.getComponent();
              if (component) {
                onClosing(component, () => {
                  flexLayoutModel.doAction(
                    FlexLayout.Actions.deleteTab(action.data.node)
                  );
                  onDelete(component);
                });
                return undefined;
              }
            }
            return action;
          }}
          onModelChange={(newModel) => {
            layout.updateLayout(newModel);
          }}
          onRenderTab={(node: FlexLayout.TabNode) => {
            // Will be called many times, so it should be idempotent
            const component = node.getComponent() as ComponentId;
            BackgroundTimer.setTimeout(() => onCreate(component), 1);
          }}
          onRenderTabSet={(tabSetNode, renderValues) => {
            const selected = tabSetNode.getSelectedNode() as FlexLayout.TabNode;
            if (selected) {
              const component = selected.getComponent() as ComponentId;
              const id = tabSetNode.getId();
              if (customizable(component)) {
                renderValues.buttons.push(
                  <CustomizeButton
                    onClick={() => {
                      customize(component, id);
                    }}
                    key="customize"
                    panelId={component}
                  />
                );
              }
            }
          }}
          factory={factory}
          iconFactory={iconFactory}
          model={flexLayoutModel}
          titleFactory={titleFactory}
        />
      )}
    </Layout>
  );
});
