import { observableClass } from "src/app/state/observableClass";

export class NumberParameterSetting {
  private readonly fetchIsConfigurable: () => boolean;
  private readonly getValue: () => number;
  public readonly maxValue?: number;
  public readonly minValue?: number;
  private readonly setParameter: (newValue: number) => void;
  private subscriptions: {
    id: number;
    subscription: () => void;
  }[] = [];
  private temporary: number | string;
  public constructor({
    configurable,
    getValue,
    maxValue,
    minValue,
    setValue,
  }: {
    configurable: () => boolean;
    getValue: () => number;
    maxValue?: number;
    minValue?: number;
    setValue: (newValue: number) => void;
  }) {
    this.getValue = getValue;
    this.fetchIsConfigurable = configurable;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.temporary = getValue();
    this.setParameter = setValue;
    observableClass(this);
  }
  public get configurable(): boolean {
    return this.fetchIsConfigurable();
  }
  public get unvalidated(): string {
    return `${this.temporary !== null ? this.temporary : this.value}`;
  }
  public get value(): number {
    return this.getValue();
  }
  public reset(): void {
    this.temporary = this.getValue();
    this.setParameter(this.temporary);
  }
  public setValue(newValue: string): void {
    this.temporary = newValue;
  }
  public subscribeOnChanges(subscription: () => void): () => void {
    const id = Math.random();
    this.subscriptions.push({
      id,
      subscription,
    });
    return () => {
      this.subscriptions = this.subscriptions.filter((ele) => ele.id !== id);
    };
  }
  public validate(): void {
    if (Number.isNaN(Number(this.temporary))) {
      this.temporary = this.value;
    } else if (
      this.minValue !== undefined &&
      (this.temporary as number) < this.minValue
    ) {
      this.temporary = this.minValue;
    } else if (
      this.maxValue !== undefined &&
      (this.temporary as number) > this.maxValue
    ) {
      this.temporary = this.maxValue;
    }
    this.temporary = Number(this.temporary);
    this.setParameter(this.temporary);
    this.subscriptions.forEach((subscription) => {
      subscription.subscription();
    });
  }
}
