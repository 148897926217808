import OpusCodecDecoder from "src/audio/codec/es5/opusCodecDecoder";
import { Logger } from "src/util/Logger";
import type { Decoder } from "src/audio/codec/Decoder";

const log = Logger.getLogger("OpusDecoder");

export class OpusDecoder implements Decoder {
  private readonly buf: Uint8Array;
  private readonly bufPtr: number;
  private readonly channels = 1;
  private readonly frameSize: number;
  private readonly fromOpusBuffer = new Float32Array(320);
  private readonly handle: any;
  private readonly pcm: Float32Array;
  private readonly pcmPtr: number;
  public constructor() {
    log.debug("Creating opus decoder");
    const samplingRate = 16000;
    this.frameSize = (samplingRate * 60) / 1e3;
    const err = OpusCodecDecoder._malloc(4);
    this.handle = OpusCodecDecoder._opus_decoder_create(
      samplingRate,
      this.channels,
      err
    );
    const errNum = OpusCodecDecoder.getValue(err, "i32");
    OpusCodecDecoder._free(err);
    if (errNum !== 0) {
      throw new Error(`Unable to create decoder (${errNum})`);
    }
    const bufSize = 1275 * 3 + 7;
    const pcmSamples = this.frameSize * this.channels;
    this.bufPtr = OpusCodecDecoder._malloc(bufSize);
    this.pcmPtr = OpusCodecDecoder._malloc(4 * pcmSamples);
    this.buf = OpusCodecDecoder.HEAPU8.subarray(
      this.bufPtr,
      this.bufPtr! + bufSize
    );
    this.pcm = OpusCodecDecoder.HEAPF32.subarray(
      this.pcmPtr! / 4,
      this.pcmPtr! / 4 + pcmSamples
    );
  }
  public decode(audio: Uint8Array): Float32Array {
    this.decodeInternal(audio, false, false);
    return this.fromOpusBuffer.slice();
  }
  public decodeWithFEC(audio: Uint8Array): Float32Array {
    log.debug("Generating PLC");
    this.decodeInternal(audio, true, false);
    return this.fromOpusBuffer.slice();
  }
  public generatePLC(): Float32Array {
    log.debug("Generating PLC");
    this.decodeInternal(null, false, true);
    return this.fromOpusBuffer.slice();
  }
  public release(): void {
    log.debug("Releasing opus decoder");
    OpusCodecDecoder._opus_decoder_destroy(this.handle);
    OpusCodecDecoder._free(this.bufPtr);
    OpusCodecDecoder._free(this.pcmPtr);
  }
  private decodeInternal(
    audio: Uint8Array | null,
    fec: boolean,
    plc: boolean
  ): void {
    let ret;
    if (plc) {
      ret = OpusCodecDecoder._opus_decode_float(
        this.handle,
        null,
        0,
        this.pcmPtr,
        this.frameSize,
        0
      );
    } else {
      this.buf.set(new Uint8Array(audio!.buffer));
      ret = OpusCodecDecoder._opus_decode_float(
        this.handle,
        this.bufPtr,
        audio!.buffer.byteLength,
        this.pcmPtr,
        this.frameSize,
        fec ? 1 : 0
      );
    }
    if (ret >= 0) {
      const samples = new Float32Array(
        this.pcm.subarray(0, ret * this.channels)
      );
      for (let cop = 0, len = samples.length; cop < len; cop += 1) {
        const s = samples[cop];
        this.fromOpusBuffer[cop] = s * (s < 0 ? 32768 : 32767);
      }
    }
  }
}
