import { connect } from "src/app/state/connect";
import { Button, Menu } from "@mui/material";

type GtMenuProps = {
  base?: React.ComponentType<{}>;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string;
  items: React.ReactNode[];
  onOpen?: () => Promise<boolean | void>;
  style?: React.CSSProperties;
};

export const GtMenu = connect<GtMenuProps>(
  ({ base, children, disabled, id, items, onOpen, ...other }, state) => {
    const { close, left, open, top } = state.menu;
    const menuId = state.menu.id;
    const Base = base || Button;
    return (
      <>
        <Base
          onClick={(event) => {
            void (async () => {
              const { clientX, clientY } = event;
              if (onOpen) {
                event.persist();
                if (!(await onOpen())) {
                  return;
                }
              }
              event.stopPropagation();
              open({
                id,
                left: clientX - 2,
                top: clientY - 4,
              });
            })();
          }}
          aria-haspopup="true"
          disabled={disabled}
          {...other}
        >
          {children}
        </Base>
        <Menu
          anchorPosition={
            menuId === id && top != null && left != null
              ? { left, top }
              : undefined
          }
          onClose={() => {
            close();
          }}
          anchorReference="anchorPosition"
          open={menuId === id}
        >
          <div>{items.filter((item) => item)}</div>
        </Menu>
      </>
    );
  }
);
