import { gql } from "src/app/graphql";
import { SoundService } from "src/app/model/audio/SoundService";
import { MenuData } from "src/app/model/notifications/MenuData";
import { Notification } from "src/app/model/notifications/Notification";
import { Logger } from "src/util/Logger";
import type { State } from "src/app/model/State";
import type { NotificationModule } from "src/nextgen/NotificationModule";
import type { SubscriptionModule } from "src/nextgen/SubscriptionModule";

const log = Logger.getLogger("NotificationManager");

export class NotificationManager {
  public menuData: MenuData;
  public constructor(private readonly state: State) {
    this.menuData = new MenuData(state);
  }
  public setup(
    notificationModule: NotificationModule,
    subscriptionModule: SubscriptionModule
  ): void {
    this.menuData.setup(subscriptionModule);
    this.subcribeToNotifications(notificationModule);
  }
  private async fetchNotification(
    notificationId: string
  ): Promise<Notification | null> {
    const notification = (
      await this.state.graphqlModule.queryDataOrThrow({
        fetchPolicy: "network-only",
        query: gql(`
      query notification(
        $notificationId: ID!
      ) {
        notification(id: $notificationId) {
          ...NotificationFields
        }
      }
    `),
        variables: { notificationId },
      })
    ).notification;
    return notification ? new Notification(this.state, notification) : null;
  }
  /**
   * Subscribe to push notifications and display notifications as flash messages
   */
  private subcribeToNotifications(
    notificationModule: NotificationModule
  ): void {
    notificationModule.subscribe({
      onActive: () => log.debug("Notification websocket ready"),
      onEvent: (evt) => {
        void (async () => {
          try {
            // ignore unreadCount updates
            if (
              evt.type &&
              evt.type !== "notification.unreadCount" &&
              evt.notification_id
            ) {
              const notification = await this.fetchNotification(
                evt.notification_id
              );
              if (notification) {
                // TODO: determine if notification flash should be shown or not
                if (
                  this.state.settings.notificationSounds.notificationSounds
                    .value
                ) {
                  SoundService.playSound("notification");
                }
                this.state.flashMessage.info({ notification });
              }
            }
          } catch (e) {
            log.warn(`Unable to fetch notification ${evt.notification_id}`, e);
          }
        })();
      },
      onInactive: () => log.debug("Notification websocket inactive"),
    });
  }
}
