import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("SmsModule");
/**
 * Returned from <code>setupSmsModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class SmsModule {
  private constructor(private readonly requestManager: RequestManager) {}
  public static async setup(
    requestManager: RequestManager
  ): Promise<SmsModule> {
    const response = await requestManager.send({ sms: { setupRequest: {} } });
    log.debug("sms module setup.", response);
    return new SmsModule(requestManager);
  }
  /**
   * Send SMS from server to receiver.
   * @param {string} receiver Receiver of SMS, in international format (e.g. '+123456789').
   * @param {string} message The SMS content.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async sendSms(receiver: string, message: string): Promise<void> {
    await this.requestManager.send({
      sms: { sendRequest: { message, receiver } },
    });
  }
}
