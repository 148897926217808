import type { RequestManager } from "src/lib/RequestManager";

export class Alarm {
  /**
   * <code>true</code> if this alarm has been cleared; <code>false</code> if this alarm
   * is still active.
   * @member {boolean}
   */
  public cleared = false;
  public constructor(
    private readonly requestManager: RequestManager,
    public readonly alarmId: number,
    /**
     * <code>true</code> if client may clear this alarm.
     * @member {boolean}
     */
    public readonly clientMayClear: boolean
  ) {}
  /**
   * Clear this alarm. Will result in a <code>{@link Forbidden}</code> if client
   * may not clear this alarm.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async clear(): Promise<void> {
    await this.requestManager.send({
      emergency: { clearAlarmRequest: { alarmId: this.alarmId } },
    });
  }
}
