import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * This account needs to be reauthenticated.
 */
export class TokenExpired extends GroupTalkAPIError {
  public constructor() {
    super("This account needs to be reauthenticated.", false);
  }
}
