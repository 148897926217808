import { gql } from "src/app/graphql";
import { Constants } from "src/app/model/Constants";
import { ContactDetails } from "src/app/model/contacts/ContactDetails";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { LocationData } from "src/app/model/location/LocationData";
import { MessageChannel } from "src/app/model/messages/MessageChannel";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";
import type { ComponentId } from "src/app/types/ComponentId";
import type { MapBounds } from "src/app/types/MapBounds";
import type { ClientUserDetailed } from "src/nextgen/types/ClientUserDetailed";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { MessageChannelUuid } from "src/nextgen/types/MessageChannelUuid";

const log = Logger.getLogger("ContactPanelData");

type Parameters = {
  bounds: MapBounds;
  channelId: MessageChannelUuid | null;
  contactId: ClientUserUuid | null;
  contactTab: ContactTabSetting;
  storedLayer: string;
};

export class ContactPanelData {
  public contact?: ContactDetails;
  public error?: string;
  public locationData?: LocationData;
  public messageChannel?: MessageChannel;
  private messageUnsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    public readonly id: ComponentId
  ) {
    observableClass(this);
  }
  public get badgeNumber(): number {
    return this.messageChannel ? this.messageChannel.unread : 0;
  }
  public get contactId(): null | string {
    return this.parameters.contactId;
  }
  public get contactTab(): ContactTabSetting {
    return this.parameters.contactTab;
  }
  public get contactTabIsEnabled() {
    return (id: ContactTabSetting): boolean => {
      switch (id) {
        case ContactTabSetting.Call:
          return this.hasFullDuplexRoom;
        case ContactTabSetting.Actions:
          return this.contact != null;
        case ContactTabSetting.Messages:
          return this.messageChannel != null;
        case ContactTabSetting.Locations:
          return !!(
            this.contact != null &&
            this.contact.locatable &&
            this.state.online?.location.maySubscribe &&
            this.locationData
          );
        default:
          return false;
      }
    };
  }
  public get defaultParameters(): Parameters {
    return {
      bounds: {},
      channelId: null,
      contactId: null,
      contactTab: ContactTabSetting.Messages,
      storedLayer: this.state.settings.map.storedLayer.selected.id as string,
    };
  }
  public get hasFullDuplexRoom(): boolean {
    return this.contact?.hasFullDuplexRoom ?? false;
  }
  public get hasMessaging(): boolean {
    return !!(
      (this.messageChannel && this.messageChannel.hasMessaging) ||
      (this.contact && this.contact.messagePermission)
    );
  }
  public get mayWriteMessage(): boolean {
    return !!(
      (this.messageChannel && this.messageChannel.mayWrite) ||
      (this.contact && this.contact.messagePermission)
    );
  }
  public get messagingVisible(): boolean {
    return this.panel.visible && this.contactTab === ContactTabSetting.Messages;
  }
  public get panel(): Panel {
    return this.state.panels.list[this.id];
  }
  public get parameters(): Parameters {
    return this.panel.parameters as Parameters;
  }
  public get session(): Session | undefined {
    return this.contact?.session;
  }
  public onClosing(performClose: () => void): void {
    if (
      !this.contact ||
      !this.contact.session ||
      !this.contact.session.stoppable
    ) {
      performClose();
    } else {
      this.state.dialogs.show({
        actions: [
          { label: "Cancel", onSelect: () => {} },
          {
            label: "End call",
            onSelect: () => {
              if (this.contact!.session) {
                void this.contact!.session.endCall();
              }
              performClose();
            },
          },
        ],
        forceRespond: true,
        text: `End call from ${this.contact.session.name}?`,
        title: "End call?",
      });
    }
  }
  public async onCreate(): Promise<void> {
    log.debug(`Creating contact panel data with id: ${this.contactId}`);

    if (this.contactId != null) {
      try {
        const data = await this.queryContactDetails(this.contactId);

        if (data != null) {
          const contactDetails = new ContactDetails(this.state, {
            callPermission: data.permissions.call,
            email: data.email,
            entityId: data.entityId,
            fullDuplexPermission: data.permissions.fullDuplexCall,
            id: data.id,
            locatable: data.permissions.locate,
            messagePermission: data.permissions.message,
            name: data.displayName,
            online: data.onlineStatus.state === "ONLINE",
            organization: data.organization,
            phoneNumbers: data.phoneNumbers,
            status: data.currentStatus,
            statusList: data.availableStatuses,
          });
          this.contact = contactDetails;
          this.contact.subscribeToUpdates({
            onNameUpdated: (newName) => {
              this.panel.setName(newName);
            },
          });

          // attach possible existing private message channel
          if (data.privateMessageChannel && data.privateMessageChannel.id) {
            log.debug(
              `Attach private message channel with uuid: ${data.privateMessageChannel.id}`
            );
            contactDetails.setUnreadCount(
              data.privateMessageChannel.unreadCount
            );
            this.messageChannel = new MessageChannel(
              this.state,
              this.panel,
              data.privateMessageChannel.id
            );
          }

          log.debug(`Creating location data with contact id: ${data.entityId}`);
          this.locationData = new LocationData(
            this.state,
            this.panel,
            data.entityId ?? null,
            this.contact.name
          );

          // select desired tab when data has been setup and loaded
          this.setContactTab(this.parameters.contactTab);

          // fetch callsign from Callsign Module and the Node API
          void this.contact?.fetchCallsign();

          if (!this.messageChannel) {
            // no private message channel exists. Subscribe to all new private messages
            // to catch if a new private message channel is created by the contact
            // sending a message to this user
            this.subscribeToNewPrivateMessages();
          }
        } else if (this.parameters.channelId != null) {
          // no access to contact details, but might have access
          // to a message change if it was provided
          log.debug(
            `Attach private message channel with uuid: ${this.parameters.channelId}`
          );
          this.messageChannel = new MessageChannel(
            this.state,
            this.panel,
            this.parameters.channelId!
          );

          // select desired tab when data has been setup and loaded
          this.setContactTab(this.parameters.contactTab);
        }
      } catch (err: any) {
        log.error(err);
      }
    }
  }
  public onDelete(): void {
    log.debug("Deleting contact panel data");
    this.messageChannel?.onDelete();
    this.messageChannel = undefined;
    void this.locationData?.onDelete();
    this.locationData = undefined;
    this.messageUnsubscriber?.();
    this.messageUnsubscriber = undefined;
    this.contact?.unsubscribeToUpdates();
  }
  public setContactTab(id: ContactTabSetting): void {
    if (this.contactTabIsEnabled(id)) {
      this.panel.setData(["contactTab"], id);
    } else if (
      id === ContactTabSetting.Messages &&
      !this.messageChannel &&
      this.contact &&
      this.contact.messagePermission
    ) {
      // no private message channel exists, but the user is allowed to create one, so create it
      void (async () => {
        const data = await this.createPrivateMessageChannel(this.contact!.id);

        if (data.error) {
          // operation failed due to AuthorizationError or due to no such channel
          log.error(data.error.message);
          this.error = "Failed to create private message channel";
        } else if (data.messageChannel && data.messageChannel.id) {
          // We no longer need to be subscribed for events to check if this channel is created,
          // since we created it ourselves.
          this.messageUnsubscriber?.();
          this.messageUnsubscriber = undefined;

          log.debug(
            `Attach created private message channel with uuid: ${data.messageChannel.id}`
          );
          this.messageChannel = new MessageChannel(
            this.state,
            this.panel,
            data.messageChannel.id
          );
          this.panel.setData(["contactTab"], id);
        }
      })();
    }
  }
  private async createPrivateMessageChannel(
    userId: ClientUserUuid
  ): Promise<any> {
    const { createPrivateMessageChannel } =
      await this.state.graphqlModule.mutationDataOrThrow({
        mutation: gql(`
          mutation createPrivateMessageChannel(
            $userId: ID!
            $locked: Boolean!
          ) {
            createPrivateMessageChannel(
              input: { userId: $userId, locked: $locked }
            ) {
              error {
                __typename
                ... on AuthorizationError {
                  message
                }
                ... on BadInputError {
                  message
                }
              }
              messageChannel {
                id
              }
            }
          }
        `),
        variables: {
          locked: false,
          userId,
        },
      });
    return createPrivateMessageChannel;
  }
  private async queryContactDetails(
    id: ClientUserUuid
  ): Promise<ClientUserDetailed | undefined> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserForContact($id: ID!) {
          clientUser(id: $id) {
            id
            entityId
            displayName
            email
            phoneNumbers
            onlineStatus {
              state
            }
            organization {
              id
              name
            }
            currentStatus {
              id
              name
              backgroundColor
              foregroundColor
            }
            availableStatuses {
              ... on ClientUserStatus {
                id
                name
                backgroundColor
                foregroundColor
              }
            }
            permissions {
              locate
              call
              message
              fullDuplexCall
            }
            privateMessageChannel {
              id
              locked
              unreadCount
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser ?? undefined;
  }
  private async queryPrivateMessageChannel(id: ClientUserUuid): Promise<{
    id: string;
    locked: boolean;
    unreadCount: number;
  } | null> {
    const { clientUser } = await this.state.graphqlModule.queryDataOrThrow({
      fetchPolicy: "no-cache",
      query: gql(`
        query clientUserMessageChannel($id: ID!) {
          clientUser(id: $id) {
            privateMessageChannel {
              id
              locked
              unreadCount
            }
          }
        }
      `),
      variables: {
        id,
      },
    });
    return clientUser?.privateMessageChannel ?? null;
  }
  private async refreshPrivateMessageChannel(
    id: ClientUserUuid
  ): Promise<void> {
    // do a refresh of this contact because event might have been a
    // message in a previously not existing private message channel so
    // need to do a refresh to notice the new channel
    const privateMessageChannel = await this.queryPrivateMessageChannel(id);

    if (privateMessageChannel?.id) {
      // attach the private message channel
      log.debug(
        `Attach private message channel with uuid: ${privateMessageChannel.id}`
      );
      this.contact?.setUnreadCount(privateMessageChannel.unreadCount);
      if (!this.messageChannel) {
        this.messageChannel = new MessageChannel(
          this.state,
          this.panel,
          privateMessageChannel.id
        );
      }
      this.messageUnsubscriber?.();
      this.messageUnsubscriber = undefined;
    }
  }
  // Subscription to all new private messages
  private subscribeToNewPrivateMessages(): void {
    this.messageUnsubscriber = this.state.online?.subscriptionModule?.subscribe(
      {
        onEvent: (msg) => {
          if (msg.type === "PrivateMessageChannelCreated" && this.contactId) {
            // PrivateMessageChannelCreated => Re-fetch data if this contact is one of
            // the participants
            const participants = msg.payload.participants as ClientUserUuid[];
            log.debug("Private message channel created with", participants);

            if (participants.some((v) => this.contactId === v)) {
              log.debug("Refreshing private message channel");
              void this.refreshPrivateMessageChannel(this.contactId);
            }
          }
        },
        onSetupSubscription: async (webSocketId) => {
          const data = await this.state.graphqlModule.mutationDataOrThrow({
            mutation: gql(`
          mutation subscribeNewMessages(
            $webSocketId: String!,
            $profile: String!,
            $channelFilter: ChannelFilter!
          ) {
            subscribeNewMessages(input: {
              webSocketId: $webSocketId,
              profile: $profile,
              channelFilter: $channelFilter
            }) {
              subscriptionId
            }
          }
        `),
            variables: {
              channelFilter: {
                allChannelMessages: false,
                allPrivateMessages: true,
                channelIds: null,
              },
              profile: Constants.PROFILE,
              webSocketId,
            },
          });
          return data.subscribeNewMessages.subscriptionId;
        },
        onTearDownSubscription: (subscriptionId) => {
          try {
            void this.state.graphqlModule.mutationDataOrThrow({
              mutation: gql(`
            mutation unsubscribeMessages(
              $subscriptionId: ID!
            ) {
              unsubscribeMessages(input: {
                subscriptionId: $subscriptionId
              }) {
                error {
                  __typename
                  ... on AuthorizationError {
                      message
                  }
                }
              }
            }
          `),
              variables: { subscriptionId },
            });
          } catch (e) {
            log.warn(`Unable to unsubscribe from messages: ${e}`);
          }
        },
      }
    );
  }
}
