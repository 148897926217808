import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import type { ContactDetails } from "src/app/model/contacts/ContactDetails";

export const WaitingForCall = connect<{
  contact: ContactDetails;
}>(({ contact }) => (
  <Layout
    alignMiddle
    background={"#0002"}
    marginRight={10}
    padding={10}
    row
    style={{ borderRadius: 10 }}
  >
    <Text
      bold
      nowrap
      size={12}
    >{`Waiting for ${contact.name} to join call...`}</Text>
  </Layout>
));
