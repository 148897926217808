import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * General failure.
 */
export class GeneralFailure extends GroupTalkAPIError {
  public constructor() {
    super("General failure.", true);
  }
}
