import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";
import { Button, Slider } from "@mui/material";
import type { Session } from "src/app/model/sessions/Session";

const buttonStyle = (
  muted: boolean,
  color: string,
  mini?: boolean
): React.CSSProperties => ({
  background: muted ? color : "#9191a1",
  border: "1px solid #666",
  borderRadius: 3,
  color: "#fff",
  fontSize: 10,
  height: mini ? 20 : 32,
  marginRight: 5,
  minHeight: mini ? 20 : 32,
  minWidth: mini ? 20 : 32,
  transition: "all .5s",
  width: mini ? 20 : 32,
});

export const VolumeControls = connect<{
  mini?: boolean;
  session: Session;
}>(({ mini, session }) => {
  const {
    actualMute,
    actualSolo,
    actualVolume,
    configuredMute,
    configuredSolo,
    maySelectForBroadcasting,
    muteDisabled,
    setMuted,
    setSolo,
    setVolume,
    soloDisabled,
  } = session;
  return (
    <Layout alignMiddle gap={5} row>
      <Button
        disabled={muteDisabled}
        onClick={() => setMuted(!configuredMute)}
        style={buttonStyle(actualMute, "#e33", mini)}
        variant="contained"
      >
        <Layout alignCenter alignMiddle column>
          <Text>Mute</Text>
        </Layout>
      </Button>
      <Button
        disabled={soloDisabled}
        onClick={() => setSolo(!configuredSolo)}
        style={buttonStyle(actualSolo, "#86b96982", mini)}
        variant="contained"
      >
        <Layout alignCenter alignMiddle column>
          <Text>Solo</Text>
        </Layout>
      </Button>
      {!mini && (
        <Layout alignCenter alignMiddle column grow minWidth={110} padding={10}>
          <Slider
            onChange={(_, value) => {
              setVolume(value as number);
            }}
            disabled={maySelectForBroadcasting}
            max={125}
            value={actualVolume}
          />
        </Layout>
      )}
    </Layout>
  );
});
