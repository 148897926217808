import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Connection terminated. Your user connected on another device.
 */
export class AnotherDeviceConnected extends GroupTalkAPIError {
  public constructor() {
    super(
      "Connection terminated. Your user connected on another device.",
      false
    );
  }
}
