import { BooleanSetting } from "src/app/model/settings/BooleanSetting";
import { observableClass } from "src/app/state/observableClass";

export class MuteGroupsSettings {
  public readonly showInMenu: BooleanSetting;
  public constructor() {
    this.showInMenu = new BooleanSetting({
      defaultValue: false,
      key: "gt2.settings.muteGroupsInMenu",
    });
    observableClass(this);
  }
}
