import { SoundService } from "src/app/model/audio/SoundService";
import { IdleState } from "src/app/model/queues/queueAlert/IdleState";
import { PickedEmergencyState } from "src/app/model/queues/queueAlert/PickedEmergencyState";
import { PickedPrioState } from "src/app/model/queues/queueAlert/PickedPrioState";
import { PickedQueueState } from "src/app/model/queues/queueAlert/PickedQueueState";
import { StartupState } from "src/app/model/queues/queueAlert/StartupState";
import { UnpickedEmergencyState } from "src/app/model/queues/queueAlert/UnpickedEmergencyState";
import { UnpickedPrioState } from "src/app/model/queues/queueAlert/UnpickedPrioState";
import { UnpickedQueueState } from "src/app/model/queues/queueAlert/UnpickedQueueState";
import { observableClass } from "src/app/state/observableClass";
import type { QueueState } from "src/app/model/queues/queueAlert/QueueState";
import type { State } from "src/app/model/State";
import type { Flags } from "src/app/types/Flags";

export class QueueAlertService {
  private currentState: QueueState;
  private enabled = false;
  public constructor(public readonly state: State) {
    this.currentState = new StartupState(this);
    observableClass(this);
  }
  public addSubscription(): void {
    if (this.enabled) {
      this.currentState.subscription(true);
    }
  }
  public calculateNextState(flags: Flags): QueueState {
    let newState: QueueState;
    let {
      newUnpickedEmergencyEntry,
      newUnpickedPrioEntry,
      newUnpickedQueueEntry,
    } = flags;
    if (
      this.currentState instanceof StartupState &&
      this.currentState.pendingSubscriptionsCount === 0
    ) {
      newUnpickedQueueEntry = false;
      newUnpickedPrioEntry = flags.unpickedPrioEntry;
      newUnpickedEmergencyEntry = flags.unpickedEmergencyEntry;
    }
    if (flags.pickedEmergencyEntry) {
      newState = new PickedEmergencyState(this);
    } else if (flags.unpickedEmergencyEntry) {
      newState = new UnpickedEmergencyState(this, newUnpickedEmergencyEntry);
    } else if (flags.pickedPrioEntry) {
      newState = new PickedPrioState(this);
    } else if (flags.unpickedPrioEntry) {
      newState = new UnpickedPrioState(this, newUnpickedPrioEntry);
    } else if (flags.pickedQueueEntry) {
      newState = new PickedQueueState(this);
    } else if (flags.unpickedQueueEntry) {
      newState = new UnpickedQueueState(this, newUnpickedQueueEntry);
    } else {
      newState = new IdleState(this);
    }
    if (this.currentState.name !== newState.name) {
      // TODO: How to do this more clean?
      this.currentState.onExit?.();
      if (newState.onEnter !== undefined) {
        newState.onEnter();
      }
    } else {
      newState = this.currentState;
    }

    if (
      newUnpickedQueueEntry &&
      newState.newUnpickedQueueEntryEvent !== undefined
    ) {
      newState.newUnpickedQueueEntryEvent();
    }
    if (
      newUnpickedPrioEntry &&
      newState.newUnpickedPrioEntryEvent !== undefined
    ) {
      newState.newUnpickedPrioEntryEvent();
    }
    if (
      newUnpickedEmergencyEntry &&
      newState.newUnpickedEmergencyEntryEvent !== undefined
    ) {
      newState.newUnpickedEmergencyEntryEvent();
    }
    return newState;
  }
  public playEmergencyAlert(): void {
    if (
      this.state.settings.queues.emergency.playSound.selected.id !== "never"
    ) {
      void SoundService.playSound(
        this.state.settings.queues.emergency.alertSound.selected.id as string,
        this.state.settings.queues.emergency.alertDevice.selected.id as string,
        this.state.settings.queues.emergency.alertVolume.value as number
      );
    }
  }
  public playPrioAlert(): void {
    if (this.state.settings.queues.prio.playSound.selected.id !== "never") {
      void SoundService.playSound(
        this.state.settings.queues.prio.alertSound.selected.id as string,
        this.state.settings.queues.prio.alertDevice.selected.id as string,
        this.state.settings.queues.prio.alertVolume.value as number
      );
    }
  }
  public playQueueAlert(): void {
    if (this.state.settings.queues.normal.playSound.selected.id !== "never") {
      void SoundService.playSound(
        this.state.settings.queues.normal.alertSound.selected.id as string,
        this.state.settings.queues.normal.alertDevice.selected.id as string,
        this.state.settings.queues.normal.alertVolume.value as number
      );
    }
  }
  public start(): void {
    this.currentState = new StartupState(this);
    this.enabled = true;
  }
  public stop(): void {
    if (this.currentState.alertTimer !== undefined) {
      this.currentState.alertTimer();
    }
    this.enabled = false;
  }
  public subscriptionFinish(): void {
    if (this.enabled) {
      this.currentState.subscription(false);
    }
  }
  public update(flags: Flags): void {
    if (this.enabled) {
      this.currentState = this.calculateNextState(flags);
    }
  }
}
