import { GtTooltip } from "src/app/gui/components/GtTooltip";
import { Layout } from "src/app/gui/components/Layout";
import { BreakPatchMenu } from "src/app/gui/panels/channels/BreakPatchMenu";
import { PTTButton } from "src/app/gui/panels/channels/detailedGroup/PTTButton";
import { RecordMeter } from "src/app/gui/panels/channels/detailedGroup/RecordMeter";
import { VolumeControls } from "src/app/gui/panels/channels/detailedGroup/VolumeControls";
import { LeaveGroupMenu } from "src/app/gui/panels/channels/LeaveGroupMenu";
import { LockButton } from "src/app/gui/panels/channels/LockButton";
import { LockGroupMenu } from "src/app/gui/panels/channels/LockGroupMenu";
import { PatchButton } from "src/app/gui/panels/channels/PatchButton";
import { PowerButton } from "src/app/gui/panels/channels/PowerButton";
import { PresenceIcon } from "src/app/gui/panels/channels/PresenceIcon";
import { ReplayButton } from "src/app/gui/panels/channels/ReplayButton";
import { RoomsPresenceIcon } from "src/app/gui/panels/channels/RoomsPresenceIcon";
import { FullDuplexPTTButton } from "src/app/gui/panels/video/FullDuplexPTTButton";
import { FavoriteButton } from "src/app/gui/shared/FavoriteButton";
import { MessageButton } from "src/app/gui/shared/MessageButton";
import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { connect } from "src/app/state/connect";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const GroupInfo = connect<{
  channel: Channel;
  isDummy?: boolean;
  mini: boolean;
  panel: Panel;
  showDispatcherPresence?: boolean;
  showFavoriteButton: boolean;
  showFullDuplexButtons?: boolean;
  showLockbutton?: boolean;
  showMessages: boolean;
  showPatchIcon?: boolean;
  showPowerbutton: boolean;
  showPowerbuttonWithMenu: boolean;
  showPresence: boolean;
  showPttButton: boolean;
  showReplayButton?: boolean;
  showRoomsPresence?: boolean;
  showSpaces: boolean;
  showVolumeControls: boolean;
}>(
  ({
    channel,
    isDummy,
    mini,
    panel,
    showDispatcherPresence,
    showFavoriteButton,
    showFullDuplexButtons,
    showLockbutton,
    showMessages,
    showPatchIcon,
    showPowerbutton,
    showPowerbuttonWithMenu,
    showPresence,
    showPttButton,
    showReplayButton,
    showRoomsPresence,
    showSpaces,
    showVolumeControls,
  }) => {
    const { hasPatch, room, session } = channel;
    return (
      <Layout alignMiddle row>
        {showPttButton &&
          mini &&
          session &&
          session.outgoingTalkburst &&
          session.isFocused && (
            <Layout height={20} marginRight={10} relative width={140}>
              <RecordMeter session={session} width={140} />
            </Layout>
          )}
        {showFullDuplexButtons && room && mini && (
          <Layout column marginLeft={4} marginRight={4}>
            <FullDuplexPTTButton
              detailed={false}
              mini
              name={channel.name}
              room={room}
            />
          </Layout>
        )}
        {showPttButton && mini && session && (
          <Layout column marginLeft={8} marginRight={8}>
            <PTTButton channel={channel} mini session={session} />
          </Layout>
        )}
        {showReplayButton && mini && session && (
          <Layout column marginLeft={8} marginRight={8}>
            <ReplayButton session={session} />
          </Layout>
        )}
        {showVolumeControls && mini && session && (
          <Layout column marginLeft={4} marginRight={4}>
            <VolumeControls mini session={session} />
          </Layout>
        )}
        {showPresence && mini && session && (
          <GtTooltip
            id={`${panel.id}_${channel.channelUuid}_presence`}
            title="Presence"
          >
            <Layout column marginLeft={4} marginRight={4}>
              <PresenceIcon
                panel={panel}
                session={session}
                showDispatcherPresence={showDispatcherPresence}
              />
            </Layout>
          </GtTooltip>
        )}
        {showRoomsPresence && room && mini && (
          <GtTooltip
            id={`${panel.id}_${channel.channelUuid}_presence`}
            title="Presence"
          >
            <Layout column marginLeft={4} marginRight={4}>
              <RoomsPresenceIcon channel={channel} panel={panel} />
            </Layout>
          </GtTooltip>
        )}
        {showPatchIcon && hasPatch && (
          <BreakPatchMenu channel={channel} panel={panel}>
            <PatchButton />
          </BreakPatchMenu>
        )}
        {showPatchIcon && showSpaces && !hasPatch && <Layout margin={16} />}
        <GtTooltip
          id={`${panel.id}_${channel.channelUuid}_open_in_new_panel`}
          title="Open in new panel"
        >
          <Layout column marginLeft={4} marginRight={4}>
            <MessageButton
              panelTab={
                channel.hasMessaging
                  ? ChannelTabSetting.Messages
                  : ChannelTabSetting.Presence
              }
              entity={channel}
              isDummy={isDummy}
              panel={panel}
              showMessages={!!(showMessages && session)}
            />
          </Layout>
        </GtTooltip>
        {showFavoriteButton && (
          <GtTooltip
            title={`${
              channel.isFavorite(panel) ? "Remove" : "Mark"
            } channel as favorite`}
            id={`${panel.id}_${channel.channelUuid}_favorite`}
          >
            <FavoriteButton entity={channel} panel={panel} />
          </GtTooltip>
        )}
        {showPowerbuttonWithMenu && (
          <GtTooltip
            id={`${panel.id}_${channel.channelUuid}_leave`}
            title="Leave Group"
          >
            <LeaveGroupMenu channel={channel} panel={panel}>
              <PowerButton channel={channel} noClick />
            </LeaveGroupMenu>
          </GtTooltip>
        )}
        {showPowerbutton && !channel.locked && (
          <GtTooltip
            id={`${panel.id}_${channel.channelUuid}_joined`}
            title={`${channel.joined ? "Leave" : "Join"} group`}
          >
            <PowerButton channel={channel} />
          </GtTooltip>
        )}
        {showSpaces && showPowerbutton && channel.locked && (
          <Layout margin={16} />
        )}
        {showLockbutton && channel.hasLock && (
          <GtTooltip
            id={`${panel.id}_${channel.channelUuid}_locked`}
            title={`${channel.locked ? "Unlock" : "Lock"} group`}
          >
            <LockGroupMenu channel={channel} panel={panel}>
              <LockButton channel={channel} noClick />
            </LockGroupMenu>
          </GtTooltip>
        )}
        {showSpaces && showLockbutton && !channel.hasLock && (
          <Layout margin={16} />
        )}
      </Layout>
    );
  }
);
