import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";

const getValue = (key: string, defaultValue: string): string => {
  const value = localStorage.getItem(key);
  if (value == null) {
    return defaultValue;
  }
  return value;
};

export class StringSetting {
  private readonly defaultValue: string;
  private readonly key: string;
  private temporary: string;
  public constructor({
    defaultValue,
    key,
  }: {
    defaultValue: string;
    key: string;
  }) {
    this.defaultValue =
      window.gtConfig.defaultSettings &&
      window.gtConfig.defaultSettings[key] !== undefined
        ? window.gtConfig.defaultSettings[key]
        : defaultValue;
    this.key = key;
    this.temporary = getValue(key, defaultValue);
    observableClass(this);
  }
  public get configurable(): boolean {
    return window.gtConfig.configurableSettings &&
      window.gtConfig.configurableSettings[this.key] !== undefined
      ? window.gtConfig.configurableSettings[this.key]
      : true;
  }
  public get unvalidated(): string {
    return this.temporary !== null ? this.temporary : this.value;
  }
  public get value(): string {
    return getValue(this.key, this.defaultValue);
  }
  public setValue(newValue: string): void {
    this.temporary = newValue;
  }
  public validate(): void {
    localStorage.setItem(this.key, this.temporary);
  }
}
