import type { CustomAction } from "src/lib/modules/CustomAction";
import type { RequestManager } from "src/lib/RequestManager";
import type { ActionType } from "src/lib/types/ActionType";

/**
 * A collection of <code>{@link CustomAction}</code>. Used to create an hierarchy of actions.
 * @namespace
 */
export class CustomFolderAction implements CustomAction {
  public constructor(
    public readonly requestManager: RequestManager,
    public readonly caption: string,
    public readonly type: ActionType,
    public readonly actions: CustomAction[]
  ) {}
  public queues(): CustomAction[] {
    return this.actions.reduce(
      (ack: CustomAction[], action: CustomAction) => [
        ...ack,
        ...(action.queues?.() ?? []),
      ],
      []
    );
  }
}
