import type { Resampler } from "src/audio/resampler/Resampler";

/**
 * Multi-tap down-sampling
 * @private
 */
export class Downsampler implements Resampler {
  private lastOutput = 0;
  private lastWeight = 0;
  private tailExists = false;
  public constructor(private readonly ratioWeight: number) {}
  public resample(buffer: Float32Array): Float32Array {
    const bufferLength = buffer.length;
    const outputBuffer = [];
    if (bufferLength > 0) {
      let weight = 0;
      let output = 0;

      let actualPosition = 0;
      let amountToNext = 0;
      let alreadyProcessedTail = !this.tailExists;
      this.tailExists = false;
      let outputOffset = 0;
      let currentPosition = 0;
      do {
        if (alreadyProcessedTail) {
          weight = this.ratioWeight;
          output = 0;
        } else {
          weight = this.lastWeight;
          output = this.lastOutput;
          alreadyProcessedTail = true;
        }
        while (weight > 0 && actualPosition < bufferLength) {
          amountToNext = 1 + actualPosition - currentPosition;
          if (weight >= amountToNext) {
            output += buffer[actualPosition] * amountToNext;
            actualPosition += 1;
            currentPosition = actualPosition;
            weight -= amountToNext;
          } else {
            output += buffer[actualPosition] * weight;
            currentPosition += weight;
            weight = 0;
            break;
          }
        }
        if (weight === 0) {
          outputBuffer[outputOffset] = output / this.ratioWeight;
          outputOffset += 1;
        } else {
          this.lastWeight = weight;
          this.lastOutput = output;
          this.tailExists = true;
          break;
        }
      } while (actualPosition < bufferLength);
      return new Float32Array(outputBuffer.slice(0, outputOffset));
    }
    return new Float32Array(0);
  }
}
