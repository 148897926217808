import { ButtonFunction } from "src/app/model/input/ButtonFunction";
import type { ButtonFunctionData } from "src/app/model/input/ButtonFunctionData";

export const ButtonFunctions: Record<ButtonFunction, ButtonFunctionData> = {
  [ButtonFunction.CLOSE_SELECTED_SESSION]: {
    id: ButtonFunction.CLOSE_SELECTED_SESSION,
    name: "Close selected session",
  },
  [ButtonFunction.DISABLE]: { id: ButtonFunction.DISABLE, name: "Disable" },
  [ButtonFunction.PICK_NEXT_TICKET]: {
    id: ButtonFunction.PICK_NEXT_TICKET,
    name: "Pick next ticket",
  },
  [ButtonFunction.PICK_TICKET_IN_PANEL]: {
    id: ButtonFunction.PICK_TICKET_IN_PANEL,
    name: "Pick next ticket in panel...",
  }, // Secondary: Which panel?
  [ButtonFunction.PTT_FIXED_GROUP]: {
    id: ButtonFunction.PTT_FIXED_GROUP,
    name: "PTT in group...",
  }, // Secondary: Which group?
  [ButtonFunction.PTT_SELECTED_GROUP]: {
    id: ButtonFunction.PTT_SELECTED_GROUP,
    name: "PTT in selected group",
  }, // PTT in selected group
  [ButtonFunction.TOGGLE_MUTE_OF_SELECTED_GROUP]: {
    id: ButtonFunction.TOGGLE_MUTE_OF_SELECTED_GROUP,
    name: "Toggle mute of selected group",
  }, // Toggle must of selected group
};
