import { ChannelTabSetting } from "src/app/model/channels/ChannelTabSetting";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import { datesAreOnSameDay } from "src/util/datesAreOnSameDay";
import type { NotificationFieldsFragment } from "src/app/graphql/graphql";
import type { State } from "src/app/model/State";

export class Notification {
  public readonly id: string;
  public readonly message?: {
    channel: {
      id: string;
      name?: string;
      type: "Group" | "PrivateMessageChannel";
      unreadCount: number;
    };
    from: {
      id: string;
      name: string;
    };
    text?: string;
  };
  /**
   * Time of initialization
   */
  private readonly now = new Date();
  private readonly time: Date;
  private readonly yesterday: Date;
  public constructor(
    private readonly state: State,
    notification: NotificationFieldsFragment
  ) {
    this.yesterday = new Date(this.now.getTime() - 86400000);
    this.id = notification.id;
    this.time = new Date(notification.sentTime);

    if (notification.type === "messaging.message") {
      // (We previously removed notifications without data)
      const data = notification.data!;
      this.message = {
        channel: {
          id: data.channel.id,
          name:
            data.channel.source.__typename === "Group"
              ? data.channel.source.name
              : undefined,
          type: data.channel.source.__typename,
          unreadCount: data.channel.unreadCount,
        },
        from: {
          id: data.from.id,
          name: data.from.name,
        },
        text: data.content?.data ?? undefined,
      };
    }
    observableClass(this);
  }
  public get postedThisYear(): boolean {
    return this.time.getFullYear() === this.now.getFullYear();
  }
  public get postedToday(): boolean {
    return datesAreOnSameDay(this.time, this.now);
  }
  public get postedYesterday(): boolean {
    return datesAreOnSameDay(this.time, this.yesterday);
  }
  public get timeSince(): string {
    const now = new Date();
    const secondsPast = (now.getTime() - this.time.getTime()) / 1000;
    if (secondsPast < 10) {
      return "Just now";
    }
    if (secondsPast < 60) {
      const s = Math.floor(secondsPast);
      return `${s} seconds ago`;
    }
    if (secondsPast < 3600) {
      const m = Math.floor(secondsPast / 60);
      return `${m} minute${m > 1 ? "s" : ""} ago`;
    }
    if (secondsPast <= 86400) {
      const h = Math.floor(secondsPast / 3600);
      return `${h} hour${h > 1 ? "s" : ""} ago`;
    }
    if (this.postedToday || this.postedYesterday) {
      return `${this.readableDay} at ${this.readableTime}`;
    }
    return this.readableDay;
  }
  public onSelect(): void {
    if (this.message) {
      if (this.message.channel.type === "PrivateMessageChannel") {
        this.openMessagingPanel();
      } else {
        this.state.online?.channels.openOrFocusChannelInPanel({
          id: this.message.channel.id,
          name: this.message.channel.name!,
          tab: ChannelTabSetting.Messages,
        });
      }
    }
  }
  private get readableDay(): string {
    if (this.postedToday) {
      return "Today";
    }
    if (this.postedYesterday) {
      return "Yesterday";
    }
    return this.time.toLocaleDateString([], {
      day: "numeric",
      month: "long",
      weekday: "long",
      year: this.postedThisYear ? undefined : "numeric",
    });
  }
  private get readableTime(): string {
    return this.time.toLocaleTimeString([], {
      hour: "2-digit",
      hour12: false,
      minute: "2-digit",
    });
  }
  private openMessagingPanel(): void {
    const existingPanel = this.state.panels.find(
      (p) => this.message!.from.id === p.contactPanelData?.contact?.contactUuid
    );
    if (existingPanel) {
      existingPanel.select();
      existingPanel.contactPanelData?.setContactTab(ContactTabSetting.Messages);
      if (existingPanel.contactPanelData?.messageChannel) {
        existingPanel.contactPanelData.messageChannel.setScroll(true);
      }
      existingPanel.flash();
    } else {
      this.state.panels.add({
        customData: {
          channelId: this.message!.channel.id,
          contactId: this.message!.from.id,
          contactTab: ContactTabSetting.Messages,
        },
        name: this.message!.from.name,
        templateId: TemplateId.contact,
      });
    }
  }
}
