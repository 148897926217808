import { ChannelsTabSetting } from "src/app/model/channels/ChannelsTabSetting";
import { PanelType } from "src/app/types/PanelType";
import { TemplateId } from "src/app/types/TemplateId";
import { objectDeepMerge } from "src/util/objectDeepMerge";
import type { Template } from "src/app/types/Template";

const defaultTemplate: Record<TemplateId, Template> = {
  [TemplateId.channel]: {
    customizable: true,
    name: "Selected Group",
    showInMenu: true,
    templateId: TemplateId.channel,
    type: PanelType.channel,
  },
  [TemplateId.channels]: {
    customData: {
      detailedView: false,
      groupFilterSetting: ChannelsTabSetting.All,
      showDescription: true,
      showGroupSymbol: true,
      showPresence: true,
      showPttButton: true,
      showReplayButton: true,
      showVolumeControls: true,
    },
    customizable: true,
    name: "Groups",
    showInMenu: true,
    templateId: TemplateId.channels,
    type: PanelType.channels,
  },
  [TemplateId.contact]: {
    name: "Contact",
    templateId: TemplateId.contact,
    type: PanelType.contact,
  },
  [TemplateId.contacts]: {
    customizable: true,
    name: "Contacts",
    showInMenu: true,
    templateId: TemplateId.contacts,
    type: PanelType.contacts,
  },
  [TemplateId.emergencyTicket]: {
    templateId: TemplateId.emergencyTicket,
    type: PanelType.emergencyTicket,
  },
  [TemplateId.location]: {
    name: "Locations",
    showInMenu: true,
    templateId: TemplateId.location,
    type: PanelType.location,
  },
  [TemplateId.opentickets]: {
    customData: {
      defaultUnpicked: true,
      hiddenActions: {
        autoanswer: true,
        close: true,
        email: true,
        joingroup: true,
        location: true,
        message: true,
        monitor: true,
        phonecall: true,
        pttcall: true,
        putback: true,
      },
    },
    customizable: true,
    name: "Tickets",
    showInMenu: true,
    templateId: TemplateId.opentickets,
    type: PanelType.tickets,
  },
  [TemplateId.patch]: {
    name: "Patch",
    showInMenu: false,
    templateId: TemplateId.patch,
    type: PanelType.patch,
  },
  /**
   * Deprecated
   */
  [TemplateId.pickedtickets]: {
    customizable: false,
    name: "Picked Tickets",
    showInMenu: false,
    templateId: TemplateId.pickedtickets,
    type: PanelType.ticket,
  },
  [TemplateId.priorityTicket]: {
    templateId: TemplateId.priorityTicket,
    type: PanelType.priorityTicket,
  },
  [TemplateId.sessions]: {
    customizable: true,
    name: "Sessions",
    showInMenu: true,
    templateId: TemplateId.sessions,
    type: PanelType.channels,
  },
  [TemplateId.ticket]: {
    templateId: TemplateId.ticket,
    type: PanelType.ticket,
  },
};

export const templates: Record<TemplateId, Template> = objectDeepMerge(
  defaultTemplate,
  window.gtConfig.templates || {}
);
