import { Presence } from "src/app/model/presence/Presence";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { State } from "src/app/model/State";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { Presence as NodePresence } from "src/lib/modules/Presence";
import type { PresenceDelta } from "src/lib/types/PresenceDelta";
import type { PresenceNodeId } from "src/lib/types/PresenceNodeId";

const log = Logger.getLogger("presence");

export class PresenceList {
  public list: Record<PresenceNodeId, Presence> = {};
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public closeDown(): void {
    this.list = {};
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    const presenceModule = await authenticatedModule.setupPresenceModule();
    const initialDelta = await presenceModule.subscribe((presenceDelta) => {
      log.debug("Presence delta", presenceDelta);
      this.handleDelta(presenceDelta);
    });
    log.debug("Initial delta", initialDelta);
    // this.handleDelta({ updatedOrNew: initialDelta });
  }
  private handleDelta(delta: PresenceDelta): void {
    const sessionIds: number[] = [];
    if (delta.updatedOrNew) {
      delta.updatedOrNew.forEach((presence: NodePresence) => {
        const newPresence = new Presence(this.state, presence);
        this.list[presence.presenceId] = newPresence;
        if (!sessionIds.includes(presence.sessionId)) {
          sessionIds.push(presence.sessionId);
        }
      });
    }
    if (delta.deletedPresenceIds) {
      delta.deletedPresenceIds.forEach((presenceId) => {
        delete this.list[presenceId];
      });
    }
    if (this.state.online && this.state.online.sessions) {
      for (const sessionId of sessionIds) {
        this.state.online.sessions.openOrFocusCallSessions(sessionId);
      }
    }
  }
}
