import { templates } from "src/app/model/panels/templates";
import { TemplateId } from "src/app/types/TemplateId";
import type { PanelConfig } from "src/app/types/PanelConfig";
import type { PanelLayoutGrid } from "src/app/types/PanelLayoutGrid";
import type { PanelLayoutGt } from "src/app/types/PanelLayoutGt";

const parseLayout = (
  layout: PanelLayoutGt,
  nextId: number,
  panels: Record<number, PanelConfig>
): {
  layout: PanelLayoutGrid;
  nextId: number;
  panels: Record<number, PanelConfig>;
} => {
  let returnNextId = nextId;
  const returnLayout: Partial<PanelLayoutGrid> = {};
  let returnPanels: Record<number, PanelConfig> = { ...panels };
  if (layout.weight !== undefined) {
    returnLayout.weight = layout.weight;
  }
  if (layout.template !== undefined) {
    returnPanels[returnNextId] = {
      component: `${returnNextId}`,
      templateId: layout.template,
    };
    if (layout.customData) {
      returnPanels[returnNextId].customData = layout.customData;
    }
    returnLayout.component = returnNextId;
    returnLayout.type = "tab";
    returnLayout.name = layout.name || templates[layout.template].name;
    returnNextId += 1;
  }
  if (layout.layout !== undefined || layout.panels !== undefined) {
    const children = (
      layout.layout !== undefined ? layout.layout : layout.panels
    )!;
    returnLayout.type = layout.layout !== undefined ? "row" : "tabset";
    returnLayout.children = [];
    children.forEach((child) => {
      const childrenData = parseLayout(child, returnNextId, returnPanels);
      returnLayout.children!.push(childrenData.layout);
      returnPanels = childrenData.panels;
      returnNextId = childrenData.nextId;
    });
  }
  return {
    layout: returnLayout as PanelLayoutGrid,
    nextId: returnNextId,
    panels: returnPanels,
  };
};

// This is the factory default layout. Can be used as template for customized layout.
// customData (object) and name is available in panels for additional customization.
const defaultLayout: PanelLayoutGt = {
  layout: [
    {
      layout: [
        {
          panels: [{ template: TemplateId.channels }],
          weight: 80,
        },
        {
          panels: [{ template: TemplateId.opentickets }],
          weight: 20,
        },
      ],
      weight: 60,
    },
    {
      layout: [
        {
          panels: [{ template: TemplateId.location }],
          weight: 50,
        },
        {
          panels: [{ template: TemplateId.contacts }],
          weight: 50,
        },
      ],
      weight: 40,
    },
  ],
};

export const defaultPanelLayout = parseLayout(
  window.gtConfig.defaultLayout || defaultLayout,
  1,
  {}
);
