import { Logger } from "src/util/Logger";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";

const log = Logger.getLogger("WebappModule");
/**
 * Returned from <code>setupWebappModule</code> of <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class WebappModule {
  /**
   * @member {Array<Webapp>}
   */
  public readonly webapps: proto.IWebappInfo[];
  private constructor(options: { webapps: proto.IWebappInfo[] }) {
    this.webapps = options.webapps;
  }
  public static async setup(
    requestManager: RequestManager
  ): Promise<WebappModule> {
    const response = (await requestManager.send({
      webapp: { setupRequest: {} },
    })) as proto.WebappModuleSetupResponse;
    log.debug("Webapp module setup.", response);
    return new WebappModule({ webapps: response.webapps });
  }
}
