import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Microphone not readable.
 */
export class MicrophoneNotReadable extends GroupTalkAPIError {
  public constructor() {
    super(
      "Microphone not readable. Make sure the microphone is not already in use.",
      false
    );
  }
}
