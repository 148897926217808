import { LocationEnquiryModule } from "src/lib/modules/LocationEnquiryModule";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import type { Queue } from "src/lib/modules/Queue";
import type { proto } from "src/lib/protobuf/proto";
import type { RequestManager } from "src/lib/RequestManager";
import type { Location } from "src/lib/types/Location";

/**
 * Obtained from callback after <code>{@link Queue}.subscribe</code>.
 * @namespace
 */
export class QueueEntry {
  /**
   * Callable reference that can be used to call the device that entered queue.
   * @member {string}
   */
  public callableReference?: string;
  /**
   * The channel entity id the user was in when entering the queue (if any).
   * @member {string}
   */
  public channelId?: string;
  /**
   * The name of the channel the user was in when entering the queue (if any).
   * @member {string}
   */
  public channelName?: string;
  /**
   * The channel uuid the user was in when entering the queue (if any).
   * @member {string}
   */
  public channelUuid?: string;
  /**
   * Time of closing, may be <code>null</code>.
   * @member {Date}
   */
  public closed?: Date;
  /**
   * Entity id for the device entering the queue, may be <code>null</code>.
   * @member {string}
   */
  public deviceEntityId?: string;
  /**
   * Last location of device.
   * @member {Location}
   */
  public lastLocation?: Location;
  /**
   * Entity id that can be used to monitor the device (with <code>{@link SetupCallModule}</code>).
   * @member {string}
   */
  public monitorEntityId?: string;
  /**
   * @member {string}
   */
  public name: string;
  /**
   * Number of times user has requested queue entry.
   * @member {Number}
   */
  public numRequests: number;
  /**
   * Time of ticket creation (UTC).
   * @member {Date}
   */
  public opened: Date;
  /**
   * Name of current owner of the entry, may be <code>null</code>.
   * @member {string}
   */
  public pickedBy?: string;
  /**
   * <code>true</code> if entry is picked by me.
   * @member {boolean}
   */
  public pickedByMe?: boolean;
  /**
   * The queue this entry is associated with
   * @member {Queue}
   */
  public queue: Queue;
  /**
   * Unique entity id for this queue entry.
   * @member {string}
   */
  public queueEntryEntityId: string;
  /**
   * @member {string}
   */
  public title?: string;
  /**
   * Entity id for the user entering the queue, may be <code>null</code>.
   * @member {string}
   */
  public userEntityId?: string;
  /**
   * The uuid for the user entering the queue, may be <code>null</code>.
   * @member {string}
   */
  public userUuid?: string;
  public constructor(
    private readonly requestManager: RequestManager,
    queue: Queue,
    queueEntry: proto.IQueueEntry
  ) {
    this.requestManager = requestManager;
    this.queueEntryEntityId = queueEntry.queueEntryEntityId;
    this.queue = queue;
    this.opened = new Date(longToNumber(queueEntry.opened) * 1000);
    this.closed = queueEntry.closed
      ? new Date(longToNumber(queueEntry.closed) * 1000)
      : undefined;
    this.pickedBy = queueEntry.pickedBy ?? undefined;
    this.pickedByMe = queueEntry.pickedByMe ?? undefined;
    this.name = queueEntry.name;
    this.title = queueEntry.title ?? undefined;
    this.numRequests = queueEntry.numRequests;
    this.userEntityId = queueEntry.userEntityId ?? undefined;
    this.userUuid = queueEntry.userUuid ?? undefined;
    this.deviceEntityId = queueEntry.deviceEntityId ?? undefined;
    this.callableReference = queueEntry.callableReference ?? undefined;
    this.lastLocation =
      queueEntry.lastLocation != null
        ? LocationEnquiryModule.fromProtoLocation(queueEntry.lastLocation)
        : undefined;
    this.monitorEntityId = queueEntry.monitorEntityId ?? undefined;
    this.channelId = queueEntry.channelId ?? undefined;
    this.channelUuid = queueEntry.channelUuid ?? undefined;
    this.channelName = queueEntry.channelName ?? undefined;
  }
  /**
   * Close the ticket, marking it handled. If this ticket is an emergency
   * ticket the alarm will become closed.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async close(): Promise<void> {
    await this.requestManager.send({
      queueManagement: {
        closeRequest: {
          queueEntryEntityId: this.queueEntryEntityId,
        },
      },
    });
  }
  /**
   * Picks the ticket (queueEntry) to mark it being handled.
   * @param {boolean} steal If <code>true</code> will pick ticket even if the ticket already has
   * been picked.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async pick(steal: boolean): Promise<void> {
    await this.requestManager.send({
      queueManagement: {
        pickRequest: {
          queueEntryEntityId: this.queueEntryEntityId,
          stealIfPicked: steal,
        },
      },
    });
  }
  /**
   * Unpicks the ticket, putting it back in the queue.
   * @returns {Promise} Resolves when task is successfully completed.
   */
  public async unpick(): Promise<void> {
    await this.requestManager.send({
      queueManagement: {
        unpickRequest: {
          queueEntryEntityId: this.queueEntryEntityId,
        },
      },
    });
  }
}
