import { GtError } from "src/app/gui/components/GtError";
import { Layout } from "src/app/gui/components/Layout";
import { Map } from "src/app/gui/panels/location/Map";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const Location = connect<{
  panel: Panel;
}>(({ panel }, state) => {
  const maySubscribe = state.online?.location.maySubscribe;
  const locationData = panel.locationPanelData!.locationData;
  return (
    <Layout column grow style={{ zIndex: 0 }}>
      {maySubscribe && locationData && (
        <Map fullView locationData={locationData} />
      )}
      {(!maySubscribe || !locationData) && (
        <GtError message="Locations not enabled." severity="info" />
      )}
    </Layout>
  );
});
