import { DetailedGroup } from "src/app/gui/panels/channels/DetailedGroup";
import { GroupRow } from "src/app/gui/panels/channels/GroupRow";
import { ChannelsTabSetting } from "src/app/model/channels/ChannelsTabSetting";
import { connect } from "src/app/state/connect";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const Group = connect<{
  channel: Channel;
  detailedView: boolean;
  evenRow?: boolean;
  groupFilter: ChannelsTabSetting;
  isDummy?: boolean;
  panel: Panel;
  showDescription: boolean;
  showDispatcherPresence?: boolean;
  showGroupSymbol: boolean;
  showLatestSpeaker: boolean;
  showLockbutton?: boolean;
  showMessages: boolean;
  showOrganization: boolean;
  showPatchIcon?: boolean;
  showPresence: boolean;
  showPttButton: boolean;
  showReplayButton: boolean;
  showVolumeControls: boolean;
}>(
  ({
    channel,
    detailedView,
    evenRow,
    groupFilter,
    isDummy,
    panel,
    showDescription,
    showDispatcherPresence,
    showGroupSymbol,
    showLatestSpeaker,
    showLockbutton,
    showMessages,
    showOrganization,
    showPatchIcon,
    showPresence,
    showPttButton,
    showReplayButton,
    showVolumeControls,
  }) => {
    if (detailedView) {
      return (
        <DetailedGroup channel={channel} isDummy={isDummy} panel={panel} />
      );
    }
    return (
      <GroupRow
        showPowerbutton={
          groupFilter !== ChannelsTabSetting.Joined &&
          groupFilter !== ChannelsTabSetting.Mobile
        }
        showPowerbuttonWithMenu={
          groupFilter === ChannelsTabSetting.Joined ||
          groupFilter === ChannelsTabSetting.Mobile
        }
        channel={channel}
        evenRow={evenRow}
        isDummy={isDummy}
        panel={panel}
        showDescription={showDescription}
        showDispatcherPresence={showDispatcherPresence}
        showFavoriteButton={groupFilter === ChannelsTabSetting.All}
        showGroupSymbol={showGroupSymbol}
        showLatestSpeaker={showLatestSpeaker}
        showLockbutton={showLockbutton}
        showMessages={showMessages}
        showOrganization={showOrganization}
        showPatchIcon={showPatchIcon}
        showPresence={showPresence}
        showPttButton={showPttButton}
        showReplayButton={showReplayButton}
        showSpaces
        showVolumeControls={showVolumeControls}
      />
    );
  }
);
