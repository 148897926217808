import { StatusModule } from "src/lib/modules/StatusModule";
import { longToNumber } from "src/lib/modules/util/longToNumber";
import type { proto } from "src/lib/protobuf/proto";
import type { PhoneBookEntryNodeId } from "src/lib/types/PhoneBookEntryNodeId";
import type { PresenceNodeId } from "src/lib/types/PresenceNodeId";
import type { PresenceType } from "src/lib/types/PresenceType";
import type { SessionNodeId } from "src/lib/types/SessionNodeId";
import type { Status } from "src/lib/types/Status";

/**
 * Returned from <code>{@link PresenceModule}.subscribe</code> and
 * <code>{@link PresenceDelta}</code> after subscribing.
 * @namespace
 */
export class Presence {
  /**
   True of the participant has an active panic alarm.
   @member {boolean}
 */
  public alarmActive: boolean;
  /**
   * Reference used when trying to make a call to this entity. <code>(optional)</code>
   * @member {string}
   */
  public callableReference?: string;
  // Use type if exists
  /**
   * Name of the entity that is present.
   * @member {string}
   */
  public name: string;
  /**
   * Reference used to resolve via phone book api. <code>(optional)</code>
   * @member {string}
   */
  public phoneBookEntryId?: PhoneBookEntryNodeId;
  /**
   * Id of this presence.
   * @member {Number}
   */
  public presenceId: PresenceNodeId;
  /**
   * Id of which session where this presence takes place.
   * @member {Number}
   */
  public sessionId: SessionNodeId;
  /**
   * String that uniquely identifies the device/source of the participant within a talkburst.
   * @member {string}
   */
  public sourceId?: string;
  /**
   * Status of the participant.
   * @member {Status}
   */
  public status?: Status;
  /**
   * Title of the entity that is present. Typically to be presented in a second row or column
   * together with the name.
   * @member {string}
   */
  public title: string;
  /**
   * @member {PresenceType}
   */
  public type: PresenceType;
  /**
   * <code>true</code> if entity is unavailable (meaning talkbursts in this session will not be
   * heard by him); otherwise, <code>false</code>.
   * @member {boolean}
   */
  public unavailable: boolean;
  public userEntityId?: string;
  public constructor(
    presenceInfo: proto.IPresenceInfo,
    status?: proto.IStatus[]
  ) {
    this.sessionId = longToNumber(presenceInfo.sessionId);
    this.presenceId = longToNumber(presenceInfo.presenceId);
    this.type = (presenceInfo.type ??
      presenceInfo.limitedType) as number as PresenceType;
    this.name = presenceInfo.name;
    this.title = presenceInfo.title;
    this.unavailable = presenceInfo.unavailable;
    this.callableReference = presenceInfo.callableReference ?? undefined;
    this.phoneBookEntryId = presenceInfo.phoneBookEntryId ?? undefined;
    this.sourceId = presenceInfo.sourceId ?? undefined;
    this.userEntityId = presenceInfo.userEntityId ?? undefined;
    this.alarmActive = presenceInfo.alarmActive ?? false;
    this.status =
      status && presenceInfo.statusIndex != null
        ? StatusModule.fromProtoStatus(status[presenceInfo.statusIndex])
        : undefined;
  }
}
