import { longToNumber } from "src/lib/modules/util/longToNumber";
import { proto } from "src/lib/protobuf/proto";
import { Logger } from "src/util/Logger";
import type { RequestManager } from "src/lib/RequestManager";
import type { PresenceType } from "src/lib/types/PresenceType";
import type { ReceptionStatistics } from "src/lib/types/ReceptionStatistics";
import type { TalkburstReceptionStart } from "src/lib/types/TalkburstReceptionStart";
import type { TalkburstReceptionStop } from "src/lib/types/TalkburstReceptionStop";

const log = Logger.getLogger("TalkburstReceptionModule");

/**
 * Returned from <code>setupTalkburstReceptionModule</code> of
 * @private
 * <code>{@link AuthenticatedModule}</code>.
 * @namespace
 */
export class TalkburstReceptionModule {
  /**
   * Callback when receiving incoming talkburst.
   * @member {function(TalkburstReceptionStart)}
   */
  private readonly onTalkburstStart?: (
    receptionStart: TalkburstReceptionStart
  ) => void;
  /**
   * Callback when talkburst is stopped.
   * @member {function(TalkburstReceptionStop):Promise<ReceptionStatistics>}
   */
  private readonly onTalkburstStop?: (
    receptionStop: TalkburstReceptionStop
  ) => Promise<ReceptionStatistics> | null;
  /**
   * if <code>true</code> client should collect and
   * return ReceptionStatistics on <code>onTalkburstStop</code>.
   * @member {boolean}
   */
  private readonly returnReceptionStatistics: boolean;
  private constructor({
    onTalkburstStart,
    onTalkburstStop,
    returnReceptionStatistics,
  }: {
    onTalkburstStart?: (receptionStart: TalkburstReceptionStart) => void;
    onTalkburstStop?: (
      receptionStop: TalkburstReceptionStop
    ) => Promise<ReceptionStatistics> | null;
    returnReceptionStatistics: boolean;
  }) {
    this.returnReceptionStatistics = returnReceptionStatistics;
    this.onTalkburstStart = onTalkburstStart;
    this.onTalkburstStop = onTalkburstStop;
  }
  public static fromProtoTalkburstReceptionStarted(
    talkburstReceptionStarted: proto.ITalkburstReceptionStarted
  ): TalkburstReceptionStart {
    return {
      audioSuppressed: talkburstReceptionStarted.audioSuppressed ?? undefined,
      globalTalkburstId:
        talkburstReceptionStarted.globalTalkburstId ?? undefined,
      muteGroupId: talkburstReceptionStarted.muteGroupId ?? undefined,
      sender: talkburstReceptionStarted.sender,
      sessionId: longToNumber(talkburstReceptionStarted.sessionId),
      sourceId: talkburstReceptionStarted.sourceId ?? undefined,
      talkburstId: longToNumber(talkburstReceptionStarted.talkburstId),
      type: talkburstReceptionStarted.type as number as PresenceType,
    };
  }
  public static fromProtoTalkburstReceptionStopped(
    talkburstReceptionStopped: proto.ITalkburstReceptionStopped
  ): TalkburstReceptionStop {
    return {
      lastSentSeqNo: talkburstReceptionStopped.lastSentSeqNo,
      talkburstId: longToNumber(talkburstReceptionStopped.talkburstId),
    };
  }
  public static async setup(
    requestManager: RequestManager,
    options: {
      onTalkburstStart?: (receptionStart: TalkburstReceptionStart) => void;
      onTalkburstStop?: (
        receptionStop: TalkburstReceptionStop
      ) => Promise<ReceptionStatistics> | null;
    }
  ): Promise<TalkburstReceptionModule> {
    const response = (await requestManager.send({
      talkburstReception: { setupRequest: {} },
    })) as proto.TalkburstReceptionModuleSetupResponse;
    log.debug("talkburstReception module setup.", response);
    return new TalkburstReceptionModule({
      ...options,
      returnReceptionStatistics: response.returnReceptionStatistics,
    });
  }
  public onRequest(
    message: proto.ITalkburstReceptionAPIv1Server,
    respond: (code: proto.ResponseCode) => void
  ): void {
    if (message.start) {
      this.onStart(message.start, respond);
    } else if (message.stop) {
      void this.onStop(message.stop, respond);
    } else {
      log.warn("Unhandled request", message);
      respond(proto.ResponseCode.REQUEST_UNKNOWN);
    }
  }
  private onStart(
    message: proto.ITalkburstReceptionStarted,
    respond: (code: proto.ResponseCode) => void
  ): void {
    respond(proto.ResponseCode.OK);
    if (this.onTalkburstStart) {
      this.onTalkburstStart(
        TalkburstReceptionModule.fromProtoTalkburstReceptionStarted(message)
      );
    }
  }
  private async onStop(
    message: proto.ITalkburstReceptionStopped,
    respond: (
      code: proto.ResponseCode,
      response?: { statistics: ReceptionStatistics }
    ) => void
  ): Promise<void> {
    if (this.onTalkburstStop) {
      if (this.returnReceptionStatistics) {
        respond(proto.ResponseCode.TRYING);
        const statistics = await this.onTalkburstStop(
          TalkburstReceptionModule.fromProtoTalkburstReceptionStopped(message)
        );
        if (statistics) {
          respond(proto.ResponseCode.OK, { statistics });
        } else {
          respond(proto.ResponseCode.OK);
        }
      } else {
        respond(proto.ResponseCode.OK);
        void this.onTalkburstStop(
          TalkburstReceptionModule.fromProtoTalkburstReceptionStopped(message)
        );
      }
    } else {
      respond(proto.ResponseCode.OK);
    }
  }
}
