import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { ListSetting } from "src/app/gui/settings/ListSetting";
import { connect } from "src/app/state/connect";

export const MapSettings = connect((_, { settings }) => {
  const { resetView, storedLayer } = settings.map;
  return (
    <Layout column width={400}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          Map
        </Text>
      </Layout>

      <ListSetting
        id="default_layer"
        label="Default layer:"
        none={false}
        setting={storedLayer}
      />
      <Layout marginBottom={16} marginTop={10}>
        <Text color="#080808" weight={300}>
          New maps will open with this layer.
        </Text>
      </Layout>
      <BooleanSetting label="Reset view on load" setting={resetView} />
    </Layout>
  );
});
