import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { EventType } from "src/app/model/events/types/EventType";
import { observableClass } from "src/app/state/observableClass";
import { TemplateId } from "src/app/types/TemplateId";
import { action } from "mobx";
import type { DetailedEvent } from "src/app/model/events/types/DetailedEvent";
import type { PartialEvent } from "src/app/model/events/types/PartialEvent";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";
import type { State } from "src/app/model/State";
import type { Room } from "src/app/model/video/Room";
import type { CallReferenceNodeId } from "src/lib/types/CallReferenceNodeId";
import type { Status } from "src/lib/types/Status";
import type { ClientUserUuid } from "src/nextgen/types/ClientUserUuid";
import type { MessageChannelUuid } from "src/nextgen/types/MessageChannelUuid";
import type { Organization } from "src/nextgen/types/Organization";

export class ContactDetails {
  public readonly callPermission: boolean;
  public readonly email?: string;
  public readonly fullDuplexPermission: boolean;
  public readonly id: string;
  public readonly locatable: boolean;
  public readonly messagePermission: boolean;
  public readonly phoneNumbers?: string[];
  public readonly statusList?: Status[];
  /**
   * Fetched from the Callsign Module and the Node API
   */
  public callsign?: string;
  /**
   * Fetched from the Callsign Module and the Node API
   */
  public callsignLabel?: string;
  public description?: string;
  public name: string;
  public online: boolean;
  public owner?: null | string;
  public privateMessageChannelId?: MessageChannelUuid;
  public status?: Status | null;
  public unreadCount?: number;
  private readonly entityId?: string;
  private unsubscriber?: () => void;
  public constructor(
    private readonly state: State,
    entry: {
      callPermission: boolean;
      description?: null | string;
      email?: null | string;
      entityId: null | string;
      fullDuplexPermission: boolean;
      id: ClientUserUuid;
      locatable: boolean;
      messagePermission: boolean;
      name: string;
      online: boolean;
      organization?: Organization;
      phoneNumbers?: string[];
      privateMessageChannelId?: MessageChannelUuid;
      status: Status | null;
      statusList?: Status[];
      unreadCount?: number;
    }
  ) {
    this.id = entry.id;
    this.entityId = entry.entityId ?? undefined;
    this.name = entry.name;
    this.description = entry.description ?? undefined;
    this.online = entry.online;
    const ourUuid = this.state.userData.organizationUuid;
    this.owner =
      entry.organization &&
      entry.organization.id &&
      entry.organization.id.toLowerCase() !== ourUuid?.toLowerCase()
        ? entry.organization.name
        : null;
    this.callPermission = entry.callPermission;
    this.fullDuplexPermission = entry.fullDuplexPermission;
    this.locatable = entry.locatable;
    this.messagePermission = entry.messagePermission;
    this.phoneNumbers = entry.phoneNumbers;
    this.email = entry.email ?? undefined;
    this.status = entry.status;
    this.statusList = entry.statusList;
    this.unreadCount = entry.unreadCount;
    this.privateMessageChannelId = entry.privateMessageChannelId || undefined;
    observableClass(this);
  }
  public get callable(): boolean {
    return this.callPermission && this.online;
  }
  public get contactUuid(): ClientUserUuid {
    return this.id;
  }
  public get fullDuplexCallable(): boolean {
    return this.fullDuplexPermission && this.online;
  }
  public get fullDuplexRoom(): Room | null {
    return this.session?.isFullDuplex
      ? this.state.online?.rooms.roomWithPeerUserUuid(this.id) ?? null
      : null;
  }
  public get hasEmail(): boolean {
    return !!this.email;
  }
  public get hasFullDuplexRoom(): boolean {
    return this.fullDuplexRoom !== null ?? false;
  }
  public get hasPhoneNumber(): boolean {
    return !!(this.phoneNumbers && this.phoneNumbers.length > 0);
  }
  public get isFavorite() {
    return (panel: Panel) =>
      panel.contactsPanelData &&
      panel.contactsPanelData.includeContact(this.contactUuid);
  }
  public get messagable(): boolean {
    return this.messagePermission;
  }
  public get session(): Session | undefined {
    return this.contactUuid
      ? this.state.online?.sessions.sessionWithPeerUserUuid(this.contactUuid)
      : undefined;
  }
  public async autoAnswerCall(
    options: {
      onCallRef?: (callRef: CallReferenceNodeId) => void;
    } = {}
  ): Promise<void> {
    return this.call({
      ...options,
      autoAnswer: true,
    });
  }
  public async call(
    options: {
      autoAnswer?: boolean;
      fullDuplex?: boolean;
      onCallRef?: (callRef: CallReferenceNodeId) => void;
    } = {}
  ): Promise<void> {
    const { autoAnswer = false, fullDuplex = false, onCallRef } = options;
    // look up matching phonebook entry to get the callable reference
    if (!this.entityId) {
      return;
    }
    this.state.online?.setupCall.call({
      autoAnswer,
      callableEntityId: `e:${this.entityId}`,
      fullDuplex,
      name: this.name,
      onCallRef,
    });
  }
  public async fetchCallsign(): Promise<void> {
    const callsign = await this.state.online?.callsign.getCallsign({
      entityId: this.entityId,
    });
    if (callsign) {
      this.callsignLabel = callsign.callsignLabel;
      this.callsign = callsign.callsign;
    }
  }
  public async fullDuplexCall(
    options: {
      onCallRef?: (callRef: CallReferenceNodeId) => void;
    } = {}
  ): Promise<void> {
    return this.call({
      ...options,
      fullDuplex: true,
    });
  }
  /**
   * Checks if the contact has a private message channel with the given groupId
   */
  public hasPrivateMessageChannel(groupId: MessageChannelUuid): boolean {
    return this.privateMessageChannelId === groupId;
  }
  public openOrFocusPanel(
    panel: Panel | undefined,
    tab: ContactTabSetting
  ): void {
    const existingPanel = this.state.panels.find(
      (p) =>
        p.isContactPanel && p.contactPanelData!.contact?.contactUuid === this.id
    );
    if (existingPanel) {
      existingPanel.select();
      if (tab) {
        existingPanel.contactPanelData!.setContactTab(tab);
        if (tab === ContactTabSetting.Messages) {
          if (existingPanel.contactPanelData!.messageChannel) {
            existingPanel.contactPanelData!.messageChannel.setScroll(true);
          }
        }
      }
      existingPanel.flash();
    } else {
      const newTab = tab || ContactTabSetting.Actions;
      this.state.panels.add({
        customData: {
          contactId: this.id,
          contactTab: newTab,
        },
        name: this.name,
        panel,
        templateId: TemplateId.contact,
      });
    }
  }
  public setPrivateMessageChannelId(id: MessageChannelUuid): void {
    this.privateMessageChannelId = id;
  }
  public setUnreadCount(count: number): void {
    this.unreadCount = count;
  }
  public subscribeToUpdates(
    options: { onNameUpdated?: (newName: string) => void } = {}
  ): void {
    if (this.unsubscriber === undefined) {
      this.unsubscriber = this.state.online?.eventManager.subscribe({
        onDetailedEvent: action((msg: DetailedEvent) => {
          if (this.id.toUpperCase() === msg.payload.id.toUpperCase()) {
            if (msg.type === EventType.OnlineUpdated) {
              this.online = msg.payload.online;
            } else if (msg.type === EventType.UserDetailsUpdated) {
              this.name = msg.payload.displayName;
              this.description = msg.payload.title ?? undefined;
              void this.fetchCallsign();
              options.onNameUpdated?.(this.name);
            } else if (msg.type === EventType.StatusUpdated) {
              this.status = msg.payload.currentStatus;
            }
          }
        }),
        onEvent: (msg: PartialEvent) => {
          return this.id.toUpperCase() === msg.payload.id?.toUpperCase();
        },
        types: [
          EventType.UserDetailsUpdated,
          EventType.OnlineUpdated,
          EventType.StatusUpdated,
        ],
      });
    }
  }
  public toggleFavorite(panel: Panel): void {
    if (panel.contactsPanelData) {
      panel.contactsPanelData.toggleIncludeContact(this.contactUuid);
    }
  }
  public unsubscribeToUpdates(): void {
    this.unsubscriber?.();
    this.unsubscriber = undefined;
  }
  public updateCallsign(): void {
    this.state.online?.callsign.updateCallsign({
      callsign: this.callsign,
      callsignLabel: this.callsignLabel,
      entityId: this.entityId,
      onUpdated: action((newCallsign) => {
        this.callsign = newCallsign;
      }),
    });
  }
  public updateStatus(uuid?: string): void {
    return this.state.online?.status.updateStatus({
      statusUuid: uuid,
      userUuid: this.id,
    });
  }
}
