import { IlbcDecoder } from "src/audio/codec/IlbcDecoder";
import { OpusDecoder } from "src/audio/codec/OpusDecoder";
import { SequenceTracker } from "src/audio/SequenceTracker";
import { Logger } from "src/util/Logger";
import type { Decoder } from "src/audio/codec/Decoder";
import type { Packet } from "src/audio/Packet";
import type { ReceptionStatistics } from "src/lib/types/ReceptionStatistics";

const log = Logger.getLogger("JitterBufer");
const PACKET_TIME_IN_MS = 20;
const SAMPLES_PER_MS = 16;
const PLC_DURATION_BEFORE_FADING_IN_MS = 250;
const PLC_FADE_OUT_TIME_IN_MS = 250;
const UNENCODED_FRAMES = PACKET_TIME_IN_MS * SAMPLES_PER_MS;

/**
 * Jitter buffer is used to buffer audio and deliver it in a steady stream of fixed size audio
 * frames. If a packet is missing in the input stream the jitter buffer conceals this using PLC.
 * A packet arriving late will be discarded, while the whole stream being paused will simply cause
 * PLC-silence and possibly new buffering.
 * <p/>
 * The audio will be buffered until specified number of millis has passed since first packet
 * arrived in buffer,
 * or specified number of millis of audio is buffered (need not be in sequence order).
 * <p/>
 * Codec:
 * Input: ILBC 20 ms
 * Output: PCM 20 ms 8 kHz 16 bit mono
 * @private
 * @author markus
 */
export class JitterBuffer {
  public static readonly EMPTY_PACKET = new Float32Array(UNENCODED_FRAMES);
  private readonly latePackets = new SequenceTracker();
  private readonly maxBufferingDuration: number;
  private readonly minBufferedAudioTime: number;
  private readonly packetList: Packet[] = [];
  private readonly playedPackets = new SequenceTracker();
  private readonly receptionStats: ReceptionStatistics;
  private readonly savedPackets: Packet[] = [];
  /**
   * How much data has been buffered?
   */
  private bufferedAudioTime = 0;
  private buffering = true;
  /**
   * When will buffering stop.
   */
  private bufferingDone = 0;
  private consumeFinished = false;
  private decoder?: Decoder;
  /**
   * True if lastSeqNo has been set.
   */
  private hasReceivedStop = false;
  private isAborted = false;
  /**
   * Sequence number of last packet delivered.
   */
  private lastDeliveredSeqNo = -1;
  /**
   * Last sequence number or -1 if not yet set.
   */
  private lastSeqNo = -1;
  /**
   * Number of PLC packets in between real packets.
   */
  private numPlcPackets = 0;
  /**
   * Number of PLC packets that has been delivered since last non-PLC.
   */
  private numSequentialPLCDelivered = 0;
  private onConsumedCallback?: (receptionStats: ReceptionStatistics) => void;
  private shortBuffer: Float32Array;
  public constructor({
    maxBufferingDuration = 1000,
    minBufferedAudioTime = 80,
  } = {}) {
    if (minBufferedAudioTime < 0) {
      throw new Error("Negative buffer time not allowed");
    }
    if (maxBufferingDuration < 0) {
      throw new Error("Negative buffer time not allowed");
    }
    this.minBufferedAudioTime = minBufferedAudioTime;
    this.maxBufferingDuration = maxBufferingDuration;
    this.shortBuffer = new Float32Array(UNENCODED_FRAMES);
    const startTime = new Date().getTime();
    this.receptionStats = {
      networkInfo: [
        {
          time: startTime,
          type: "unknown",
        },
      ],
      numLate: 0,
      numLost: 0,
      numPlc: 0,
      numRebuffering: 0,
      packetReceiveTimes: [],
      packetSeqNos: [],
      startedReceiveTime: startTime,
    };
  }
  public abort(): void {
    this.isAborted = true;
    this.packetList.length = 0;
  }
  public getReplayCopy({
    onFinished,
  }: {
    onFinished: () => void;
  }): JitterBuffer {
    const jitterBuffer = new JitterBuffer();
    const decoder =
      this.decoder instanceof OpusDecoder
        ? new OpusDecoder()
        : new IlbcDecoder();
    this.savedPackets.forEach((packet) => {
      jitterBuffer.put(packet, decoder);
    });
    jitterBuffer.setLastSeqNumber(this.lastSeqNo, () => {
      decoder.release();
      onFinished();
    });
    return jitterBuffer;
  }
  public poll(): Float32Array | null {
    let i;
    const isDone = this.isDone();
    if (isDone) {
      this.consumedFinished();
      return null;
    }

    let audioPacket: Packet | null = null;
    try {
      if (this.buffering) {
        if (
          this.bufferingDone !== 0 &&
          this.bufferingDone <= new Date().getTime()
        ) {
          // Buffering is now over!
          log.debug("Buffering ended due to buffered long enough");
          this.bufferingDone = 0;
          this.buffering = false;
        } else if (this.lastDeliveredSeqNo === -1) {
          // Playback not yet started
          return JitterBuffer.EMPTY_PACKET;
        } else {
          // We need PLC, fall-through
        }
      }

      if (this.packetList.length === 0 || this.buffering) {
        // No packets, but already delivered data is used up!
        audioPacket = null;
      } else {
        [audioPacket] = this.packetList;
        const { seqNo } = audioPacket;
        if (seqNo === this.lastDeliveredSeqNo + 1) {
          // Note: also matches when lastDeliveredSeqNo = -1 and seqNo = 0!
          // We may always deliver packets sequentially
        } else if (
          seqNo <=
          this.lastDeliveredSeqNo + 1 + this.numSequentialPLCDelivered
        ) {
          // It's time to deliver existing packets, some was lost or late
          this.receptionStats.numLost += seqNo - this.lastDeliveredSeqNo - 1;
        } else {
          // We have to do another plc packet
          audioPacket = null;
        }
      }

      if (audioPacket !== null && this.decoder !== undefined) {
        const { audio, seqNo } = audioPacket;
        this.shortBuffer = this.decoder.decode(audio);
        this.updateDeliverStatistics(seqNo);
        this.packetList.splice(0, 1);
      } else {
        // Do PLC
        if (
          this.packetList.length === 0 &&
          !this.buffering &&
          !this.hasReceivedStop
        ) {
          // If PLC is required and buffer is empty, do some rebuffering!
          log.debug("Rebuffering due to PLC and empty buffer");
          this.buffering = true;
          this.bufferingDone = 0; // TODO: is this correct?
          this.bufferedAudioTime = this.minBufferedAudioTime / 2;
          this.receptionStats.numRebuffering += 1;
        }

        // Fade out PLC eventually
        const time = this.numSequentialPLCDelivered * PACKET_TIME_IN_MS;
        if (time > PLC_DURATION_BEFORE_FADING_IN_MS) {
          const k =
            1 -
            (time - PLC_DURATION_BEFORE_FADING_IN_MS) / PLC_FADE_OUT_TIME_IN_MS;
          if (k > 0) {
            this.shortBuffer = this.providePLC();
            for (i = 0; i < UNENCODED_FRAMES; i += 1) {
              this.shortBuffer[i] *= k;
            }
          } else {
            this.shortBuffer = JitterBuffer.EMPTY_PACKET;
          }
        } else {
          this.shortBuffer = this.providePLC();
        }
        // Increment number of sequentially delivered PLC packets
        this.numSequentialPLCDelivered += 1;
        this.receptionStats.numPlc += 1;
      }
    } finally {
      // ONLY DEBUGGING HERE

      if (audioPacket) {
        let s = "";
        s += "poll:";
        if (this.buffering) {
          s += " buffering ";
          if (this.bufferingDone !== 0) {
            s += ` walltime:${this.bufferingDone - new Date().getTime()}ms`;
          }
          s += ` audiotime:${
            this.minBufferedAudioTime - this.bufferedAudioTime
          }ms`;
        }
        s += ` #bufferedPackets=${this.packetList.length}`;
        if (audioPacket === null) {
          s += " -> delivered PLC/silence";
          s += ` lastDeliveredSeqNo=${this.lastDeliveredSeqNo}`;
          if (this.hasReceivedStop) {
            s += `/${this.lastSeqNo}`;
          }
          s += ` #seqPLC=${this.numSequentialPLCDelivered}`;
        } else {
          s += ` -> delivered packet ${audioPacket.seqNo}`;
          if (this.hasReceivedStop) {
            s += `/${this.lastSeqNo}`;
          }
        }
        log.trace(s);
      }
    }
    return this.shortBuffer;
  }
  public put(packet: Packet, decoder: Decoder): void {
    const { seqNo } = packet;
    this.decoder = decoder;
    if (seqNo === null) {
      throw new Error("seqNo may not be null");
    }
    this.receptionStats.packetSeqNos.push(seqNo);
    this.receptionStats.packetReceiveTimes.push(new Date().getTime());
    this.savedPackets.push(packet);

    if (this.isFinished()) {
      // Ignore any packet that is added when stopped.
      log.warn(`Discarding received audio packet ${seqNo} due to isFinished`);
      return;
    }

    if (seqNo <= this.lastDeliveredSeqNo) {
      // Discard late packet
      if (this.latePackets.add(seqNo)) {
        this.receptionStats.numLate += 1;
        this.receptionStats.numLost -= 1;
      }
      log.debug(`Discarding late audio packet ${seqNo}`);
      return;
    }
    this.playedPackets.add(seqNo);
    // Insert packet into packetList
    let added = false;
    for (let i = this.packetList.length - 1; i >= 0; i -= 1) {
      const x = this.packetList[i];
      if (x.seqNo < seqNo) {
        this.packetList.splice(i + 1, 0, packet);
        added = true;
        break;
      } else if (x.seqNo === seqNo) {
        // Same sequence number, discard
        return;
      }
    }
    if (!added) {
      // Sequence number less than all packets in list, insert first
      this.packetList.splice(0, 0, packet);
    }

    log.trace(
      `Buffered audio packet ${seqNo}, in buffer now ${this.packetList.length} ` +
        "unplayed packets"
    );

    if (this.buffering) {
      this.bufferedAudioTime += PACKET_TIME_IN_MS;
      if (this.bufferedAudioTime >= this.minBufferedAudioTime) {
        // Buffering done!
        this.buffering = false;
        log.debug("Buffering ended due to enough audio was buffered");
      }
      if (this.bufferingDone === 0) {
        // Set a fixed buffering time
        this.bufferingDone = new Date().getTime() + this.maxBufferingDuration;
      }
    }
  }
  public setLastSeqNumber(
    lastSeqNo: number,
    callback?: (receptionStats: ReceptionStatistics) => void
  ): void {
    if (!this.hasReceivedStop) {
      this.hasReceivedStop = true;
      this.lastSeqNo = lastSeqNo;
      if (this.buffering) {
        if (this.lastDeliveredSeqNo === -1) {
          // No packets yet delivered, give client a chance to start playback
          const newBufferingDone = new Date().getTime() + 3000;
          if (
            this.bufferingDone === 0 ||
            this.bufferingDone > newBufferingDone
          ) {
            this.bufferingDone = newBufferingDone;
          }
        } else {
          // Buffering, but playback started.
          this.buffering = false;
          this.bufferingDone = 0;
        }
      }
      this.receptionStats.stoppedReceiveTime = new Date().getTime();
      this.onConsumedCallback = callback;
    }
  }
  public toString(): string {
    return (
      `delivered=${this.playedPackets}, late=${this.latePackets}, ` +
      `numPlc=${this.numPlcPackets}, last=${
        this.hasReceivedStop ? `${this.lastSeqNo}` : "-"
      }`
    );
  }
  private consumedFinished(): void {
    if (!this.isFinished()) {
      throw new Error(
        "JitterBuffer consumeFinished called before reaching lastSeqNo"
      );
    }
    if (!this.consumeFinished) {
      this.consumeFinished = true;
      if (this.lastSeqNo !== -1) {
        this.receptionStats.numLost += this.lastSeqNo - this.lastDeliveredSeqNo;
      }
      this.updateDeliverStatistics(this.lastSeqNo);

      if (this.onConsumedCallback) {
        // Re-write times to offset
        const now = new Date().getTime();
        this.receptionStats.startedReceiveTime =
          now - this.receptionStats.startedReceiveTime;
        this.receptionStats.stoppedReceiveTime =
          now - this.receptionStats.stoppedReceiveTime!;
        let i;
        for (i = 0; i < this.receptionStats.packetReceiveTimes.length; i += 1) {
          this.receptionStats.packetReceiveTimes[i] =
            now - this.receptionStats.packetReceiveTimes[i];
        }
        for (i = 0; i < this.receptionStats.networkInfo.length; i += 1) {
          this.receptionStats.networkInfo[i].time =
            now - this.receptionStats.networkInfo[i].time;
        }
        this.onConsumedCallback(this.receptionStats);
      }
    } else {
      log.warn("Consume finished called multiple times.");
    }
  }
  private isDone(): boolean {
    return this.isFinished() && this.packetList.length === 0;
  }
  private isFinished(): boolean {
    return (
      this.isAborted ||
      (this.hasReceivedStop &&
        (this.lastSeqNo === -1 ||
          this.lastDeliveredSeqNo + this.numSequentialPLCDelivered >=
            this.lastSeqNo))
    );
  }
  private providePLC(): Float32Array {
    if (this.decoder) {
      if (this.packetList.length > 0 && this.decoder.decodeWithFEC) {
        const audioPacket = this.packetList[0];
        const { seqNo } = audioPacket;
        if (seqNo === this.lastDeliveredSeqNo + 2) {
          log.debug(`Providing FEC frame for seqNo ${audioPacket.seqNo - 1}`);
          return this.decoder.decodeWithFEC(audioPacket.audio);
        }
      }
      return this.decoder.generatePLC();
    }
    return JitterBuffer.EMPTY_PACKET;
  }
  private updateDeliverStatistics(deliveredSeqNo: number): void {
    if (this.lastDeliveredSeqNo !== -1 && this.numSequentialPLCDelivered > 0) {
      // Only count PLC packets between real audio packets
      log.debug(
        `Delivered ${this.numSequentialPLCDelivered} PLC packets between ` +
          `seqNo ${this.lastDeliveredSeqNo} and ${deliveredSeqNo}`
      );

      this.numPlcPackets += this.numSequentialPLCDelivered;
    }
    if (this.lastDeliveredSeqNo === -1 && deliveredSeqNo > 0) {
      log.debug(`Lost initial packets 0-${deliveredSeqNo - 1}`);
    }
    // Reset number of sequential PLCs
    this.numSequentialPLCDelivered = 0;
    this.lastDeliveredSeqNo = deliveredSeqNo;
  }
}
