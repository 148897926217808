import { UsbDevices } from "src/app/model/input/UsbDevices";
import { GroupSelectionSetting } from "src/app/model/settings/GroupSelectionSetting";
import { observableClass } from "src/app/state/observableClass";
import { Logger } from "src/util/Logger";
import type { DeviceHandler } from "src/app/model/input/handlers/DeviceHandler";
import type { RecognizedUsbDevice } from "src/app/model/input/RecognizedUsbDevice";
import type { ListSetting } from "src/app/model/settings/ListSetting";
import type { State } from "src/app/model/State";

const log = Logger.getLogger("UsbDevice");

export class UsbDevice {
  public readonly deviceName: string;
  public readonly groupSelectionSetting: GroupSelectionSetting;
  private readonly device: HIDDevice;
  private readonly deviceHandler: DeviceHandler;
  private readonly id: string;
  public constructor(
    private readonly state: State,
    device: HIDDevice,
    id: string
  ) {
    this.device = device;
    this.id = id;
    this.deviceName = device.productName;
    this.deviceHandler = new (UsbDevice.recognizedDevice(device)!.Handler)(
      this.state.online!.input.buttonMapper,
      this.id
    );
    this.groupSelectionSetting = new GroupSelectionSetting(this.state, id);
    device.addEventListener("inputreport", (e) => {
      log.debug(
        `${device.productName} ReportId: ${e.reportId}, data=${Array.from(
          new Uint8Array(e.data.buffer)
        )
          .map((x) => x.toString(16).padStart(2, "0"))
          .join(" ")}`
      );
      this.deviceHandler.onData(e.data.buffer);
    });
    this.state.flashMessage.success({
      icon: "usb",
      message: `Connected to USB device ${this.deviceName}`,
    });
    log.debug(
      `Connected to USB HID device ${device.productName} with vendorId ${device.vendorId} and productId ${device.productId}`
    );
    log.debug(
      `${device.productName} Input reports: ${device.collections
        .flatMap((c) => c.inputReports)
        .map(
          (d) =>
            `${d?.reportId}: ${d?.items?.map((i) =>
              JSON.stringify(i, undefined, 2)
            )}`
        )}`
    );
    observableClass(this);
  }
  public static get recognizedDevice() {
    return (d: HIDDevice): RecognizedUsbDevice | undefined =>
      UsbDevices.find(
        ({ productId, vendorId }) =>
          d.vendorId === vendorId && d.productId === productId
      );
  }
  public get buttons(): {
    fixedGroupId: string;
    id: string;
    label: string;
    pressed: number;
    setting: ListSetting;
    showGroup: boolean;
    showTicketPanel: boolean;
    ticketPanel: ListSetting;
    ticketPanelId: string;
  }[] {
    return this.state.online!.input.buttonMapper.buttons(this.id);
  }
  public close(): Promise<void> {
    return this.device.close();
  }
}
