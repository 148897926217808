import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MediaTrackComponent } from "src/app/gui/panels/video/MediaTrackComponent";
import { MicMutedIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { VideocamOff } from "@mui/icons-material";
import type { VideoTrack } from "twilio-video";

const TRANSITION = "all 0.8s";
export const VideoSpace = connect<{
  mirrored: boolean;
  muted: boolean;
  name: string;
  preview: boolean;
  videoTrack?: VideoTrack;
  visible: boolean;
}>(({ mirrored, muted, name, preview, videoTrack, visible }) => {
  let boxShadow: string | undefined;
  if (visible) {
    if (preview) {
      boxShadow = "0px 0px 9px 9px #0008";
    } else if (videoTrack) {
      boxShadow = "0px 0px 5px 5px #0002";
    }
  }
  return (
    <Layout
      style={{
        borderRadius: 5,
        boxShadow,
        opacity: visible ? 1.0 : 0.0,
        transform: preview ? "scale(0.3)" : "scale(1.0)",
        transformOrigin: "98% 98%",
        transition: TRANSITION,
      }}
      absolute
      alignCenter
      alignMiddle
      background="#626e7d"
      bottom={0}
      column
      height="100%"
      right={preview ? "0%" : "0%"}
      width="100%"
    >
      {videoTrack && visible && (
        <MediaTrackComponent
          invisible={false}
          mirrored={mirrored}
          track={videoTrack}
        />
      )}
      <Layout
        style={{
          opacity: !preview && visible && videoTrack ? 1.0 : 0,
          transition: TRANSITION,
        }}
        absolute
        alignBottom
        alignLeft
        bottom={0}
        column
        left={0}
        padding={10}
        right={0}
        top={0}
      >
        <Text
          style={{
            textShadow: `-1px -1px 0 #0004, 1px -1px 0 #0004, -1px 1px 0 #0004, 1px 1px 0 #0004`,
          }}
          color="#fff"
          size={20}
        >
          {name}
        </Text>
      </Layout>
      <Layout
        style={{
          opacity: visible && !videoTrack ? 1.0 : 0,
          transition: TRANSITION,
        }}
        absolute
        alignCenter
        alignMiddle
        bottom={0}
        column
        left={0}
        right={0}
        top={0}
      >
        <Text size={preview ? 40 : 30} weight={500}>
          {name}
        </Text>
        <VideocamOff
          style={{
            color: "#234",
            height: 60,
            opacity: visible && !videoTrack ? 1.0 : 0,
            transform: "scale(2.5)",
          }}
        />
      </Layout>
      <Layout
        style={{
          borderRadius: 50,
          opacity: muted ? 1.0 : 0,
          transform: preview ? "scale(1)" : "scale(0.4)",
          transformOrigin: "top right",
          transition: TRANSITION,
        }}
        absolute
        alignCenter
        alignMiddle
        background="#44444466"
        column
        height={100}
        right={20}
        top={20}
        width={100}
      >
        <MicMutedIcon
          style={{
            color: "#fff",
            transform: "scale(2.5)",
          }}
        />
      </Layout>
    </Layout>
  );
});
