import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { BooleanSetting } from "src/app/gui/settings/BooleanSetting";
import { NumberSetting } from "src/app/gui/settings/NumberSetting";
import { connect } from "src/app/state/connect";

export const SoundInput = connect((_, state) => {
  const { inputWarning, latencyCompensation } = state.settings.soundInput;
  return (
    <Layout column width={600}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          Sound Input
        </Text>
      </Layout>
      <BooleanSetting
        label="Warn of no microphone input"
        setting={inputWarning}
      />
      <Layout marginTop={20}>
        <Text bold size={15}>
          Latency compensation
        </Text>
      </Layout>
      <NumberSetting
        label="Recording latency compensation (in milliseconds)"
        setting={latencyCompensation}
      />
      <Layout marginBottom={16} marginTop={10}>
        <Text color="#080808" weight={300}>
          {"Increase latency compensation if users report not hearing the beginnings" +
            " of your transmissions. Decrease it if users do not hear the endings."}
        </Text>
      </Layout>
    </Layout>
  );
});
