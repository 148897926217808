import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Callee(s) not online.
 */
export class NotOnline extends GroupTalkAPIError {
  public constructor() {
    super("Callee(s) not online.", true);
  }
}
