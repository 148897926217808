import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * The account of your organization has expired.
 */
export class CompanyInvalid extends GroupTalkAPIError {
  public constructor() {
    super("The account of your organization has expired.", false);
  }
}
