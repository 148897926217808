import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { USBDevice } from "src/app/gui/settings/USBDevice";
import { connect } from "src/app/state/connect";
import { Alert, Button } from "@mui/material";

export const USBSettings = connect((_, state) => {
  if (!state.online) {
    return <></>;
  }
  const { connectToNewButton, connected, devices } = state.online.input.usb;
  return (
    <Layout column width={600}>
      <Layout marginBottom={10}>
        <Text bold size={15}>
          USB Buttons
        </Text>
      </Layout>
      <Layout column>
        <Layout alignLeft column gap={20}>
          <Button onClick={() => connectToNewButton()} variant="contained">
            Connect to new button
          </Button>
          {connected && (
            <Layout column gap={20} width="100%">
              <Text bold size={15}>
                Connected Devices:
              </Text>
              {Object.entries(devices).map(([key, device]) => (
                <USBDevice device={device} key={key} />
              ))}
            </Layout>
          )}
          {!connected && (
            <Alert
              style={{
                background: "none",
                border: "1px solid #111",
                color: "#000",
              }}
              severity="info"
            >
              No USB device detected
            </Alert>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
});
