import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { PresenceList } from "src/app/gui/panels/channels/detailedGroup/PresenceList";
import { PTTButton } from "src/app/gui/panels/channels/detailedGroup/PTTButton";
import { RecordMeter } from "src/app/gui/panels/channels/detailedGroup/RecordMeter";
import { SessionTypeArea } from "src/app/gui/panels/channels/detailedGroup/SessionTypeArea";
import { VolumeControls } from "src/app/gui/panels/channels/detailedGroup/VolumeControls";
import { GroupInfo } from "src/app/gui/panels/channels/GroupInfo";
import { ReplayButton } from "src/app/gui/panels/channels/ReplayButton";
import { FullDuplexPTTButton } from "src/app/gui/panels/video/FullDuplexPTTButton";
import { RoomPresenceTags } from "src/app/gui/panels/video/RoomPresenceTags";
import { ChannelsTabSetting } from "src/app/model/channels/ChannelsTabSetting";
import { connect } from "src/app/state/connect";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const DetailedGroup = connect<{
  channel: Channel;
  isDummy?: boolean;
  panel: Panel;
}>(({ channel, isDummy, panel }) => {
  const {
    groupFilter,
    showDescription,
    showDispatcherPresence,
    showGroupSymbol,
    showLockbutton,
    showMessages,
    showOrganization,
    showPatchIcon,
    showPresence,
    showPttButton,
    showReplayButton,
    showVolumeControls,
  } = panel.channelsPanelData!;
  const { session } = channel;
  const description = showDescription && channel.description;
  const { name } = channel;
  const { isFullDuplex, room } = channel;
  return (
    <Layout alignMiddle row style={{ flexShrink: 0 }}>
      <Layout
        style={{
          border: "1px solid black",
          filter: "drop-shadow(0px 0px 4px #2225)",
        }}
        background={session && session.isFocused ? "#5479b1" : "#7d8297"}
        grow
        margin={2}
        row
      >
        {showGroupSymbol && (
          <Layout column minWidth={60} width={60}>
            <SessionTypeArea channel={channel} />
          </Layout>
        )}
        <Layout
          alignMiddle
          background={session && session.isFocused ? "#6386ba" : "#7d8297"}
          column
          gap={4}
          grow={showPresence && session ? undefined : true}
          minWidth={showPresence ? 250 : undefined}
          padding={8}
          width={showPresence ? 250 : undefined}
        >
          {!isFullDuplex && (
            <Layout alignMiddle row>
              {showPttButton && session && (
                <Layout column grow>
                  <PTTButton
                    channel={channel}
                    session={session}
                    showOrganization={showOrganization}
                  />
                </Layout>
              )}
              {showReplayButton && session && (
                <Layout column>
                  <ReplayButton session={session} />
                </Layout>
              )}
            </Layout>
          )}
          {isFullDuplex && room && (
            <FullDuplexPTTButton detailed name={channel.name} room={room} />
          )}
          {(!showPttButton || !session) && (
            <Layout
              alignMiddle
              column
              onClick={() => session && session.focus()}
              style={{ cursor: "pointer" }}
            >
              <Text
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="#000"
                nowrap
                size={16}
              >
                {name}
              </Text>
              {description && (
                <Layout column marginTop={5}>
                  <Text
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color="#222"
                    lineHeight="1em"
                    size={12}
                    weight={400}
                  >
                    {description}
                  </Text>
                </Layout>
              )}
            </Layout>
          )}
          {!isFullDuplex && showVolumeControls && session && (
            <Layout alignMiddle column height={40} relative>
              <VolumeControls session={session} />
              {session.outgoingTalkburst && session.isFocused && (
                <RecordMeter session={session} width={234} />
              )}
            </Layout>
          )}
        </Layout>
        {showPresence && session && (
          <Layout
            background={session && session.isFocused ? "#496999" : "#696e82"}
            column
            grow
            onClick={() => session.focus()}
            style={{ cursor: "pointer" }}
          >
            {isFullDuplex && room && (
              <RoomPresenceTags channel={channel} panel={panel} />
            )}
            {!isFullDuplex && (
              <PresenceList
                panel={panel}
                session={session}
                showDispatcherPresence={showDispatcherPresence}
              />
            )}
          </Layout>
        )}
        <GroupInfo
          showPowerbutton={
            groupFilter !== ChannelsTabSetting.Joined &&
            groupFilter !== ChannelsTabSetting.Mobile
          }
          channel={channel}
          isDummy={isDummy}
          mini={false}
          panel={panel}
          showDispatcherPresence={showDispatcherPresence}
          showFavoriteButton={groupFilter === ChannelsTabSetting.All}
          showLockbutton={showLockbutton}
          showMessages={showMessages}
          showPatchIcon={showPatchIcon}
          showPowerbuttonWithMenu={groupFilter === ChannelsTabSetting.Joined}
          showPresence={showPresence}
          showPttButton={showPttButton}
          showSpaces
          showVolumeControls={showVolumeControls}
        />
      </Layout>
    </Layout>
  );
});
