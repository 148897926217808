import { Layout } from "src/app/gui/components/Layout";
import { connect } from "src/app/state/connect";
import type { Session } from "src/app/model/sessions/Session";

const color = (nr: number): string => {
  if (nr < 16) {
    return "#10b939";
  }
  if (nr < 18) {
    return "#e0b839";
  }
  return "#ef580a";
};

const Bar = connect<{
  nr: number;
  session?: Session;
}>(({ nr, session = { inputLevel: 0 } }) => (
  <Layout
    style={{
      borderRadius: 1,
      opacity: nr < (session.inputLevel ?? 0) ? 1.0 : 0.2,
    }}
    background={color(nr)}
    grow
    margin={1}
  />
));

export const RecordMeter = connect<{
  session: Session;
  width: number | string;
}>(({ session, width }) => (
  <Layout
    style={{
      border: "1px solid black",
      borderRadius: 5,
      boxShadow:
        "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
      zIndex: 2,
    }}
    absolute
    background="#575968"
    bottom={0}
    left={0}
    padding={1}
    right={0}
    row
    top={0}
    width={width}
  >
    {[...Array(20)].map((_, i) => (
      <Bar key={String(i)} nr={i} session={session} />
    ))}
  </Layout>
));
