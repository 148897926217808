/**
 * Encapsulation of authentication token.
 * Note that a token is associated with a specific device (via its device id).
 */
export class Token {
  /**
   * Token id
   */
  public id: string;
  /**
   * Token realm
   */
  public realm: string;
  /**
   * Token secret
   */
  public secret: string;
  public constructor(id: string, secret: string, realm: string) {
    this.id = id;
    this.secret = secret;
    this.realm = realm;
  }
  /**
   * @param {string} string The serialized string.
   * @returns {Token} Deserialized token.
   */
  public static deserialize(string: string): Token {
    const array = string.split(",");
    return new Token(array[1], array[2], array[0]); // Funny order because of backward compability.
  }
  /**
   * @returns {string} Serialized version of token.
   */
  public serialize(): string {
    return [this.realm, this.id, this.secret].join(",");
  }
}
