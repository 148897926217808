import { observableClass } from "src/app/state/observableClass";
import localStorage from "mobx-localstorage";

const getValue = (key: string, defaultValue: number): number => {
  const value = localStorage.getItem(key);
  if (value == null) {
    return defaultValue;
  }
  return Number(value);
};

export class NumberSetting {
  private readonly defaultValue: number;
  private readonly key: string;
  public readonly maxValue?: number;
  public readonly minValue?: number;
  private subscriptions: {
    id: number;
    subscription: () => void;
  }[] = [];
  private temporary: number | string;
  public constructor({
    defaultValue,
    key,
    maxValue,
    minValue,
  }: {
    defaultValue: number;
    key: string;
    maxValue?: number;
    minValue?: number;
  }) {
    this.defaultValue =
      window.gtConfig.defaultSettings &&
      window.gtConfig.defaultSettings[key] !== undefined
        ? window.gtConfig.defaultSettings[key]
        : defaultValue;
    this.key = key;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.temporary = getValue(key, defaultValue);
    observableClass(this);
  }
  public get configurable(): boolean {
    return window.gtConfig.configurableSettings &&
      window.gtConfig.configurableSettings[this.key] !== undefined
      ? window.gtConfig.configurableSettings[this.key]
      : true;
  }
  public get unvalidated(): string {
    return `${this.temporary !== null ? this.temporary : this.value}`;
  }
  public get value(): number {
    return getValue(this.key, this.defaultValue);
  }
  public reset(): void {
    this.temporary = getValue(this.key, this.defaultValue);
    localStorage.setItem(this.key, this.temporary);
  }
  public setValue(newValue: string): void {
    this.temporary = newValue;
  }
  public subscribeOnChanges(subscription: () => void): () => void {
    const id = Math.random();
    this.subscriptions.push({
      id,
      subscription,
    });
    return () => {
      this.subscriptions = this.subscriptions.filter((ele) => ele.id !== id);
    };
  }
  public validate(): void {
    if (Number.isNaN(Number(this.temporary))) {
      this.temporary = this.value;
    } else if (
      this.minValue !== undefined &&
      (this.temporary as number) < this.minValue
    ) {
      this.temporary = this.minValue;
    } else if (
      this.maxValue !== undefined &&
      (this.temporary as number) > this.maxValue
    ) {
      this.temporary = this.maxValue;
    }
    this.temporary = Number(this.temporary);
    if (this.temporary !== this.defaultValue) {
      localStorage.setItem(this.key, this.temporary);
    } else {
      localStorage.removeItem(this.key);
    }
    this.subscriptions.forEach((subscription) => {
      subscription.subscription();
    });
  }
}
