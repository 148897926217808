import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { connect } from "src/app/state/connect";

export const Preview = connect<{
  children: React.ReactElement;
}>(({ children }) => (
  <Layout background="rgba(0,0,0,0.1)" marginTop={10} padding={20}>
    <Text>Example Output:</Text>
    {children}
  </Layout>
));
