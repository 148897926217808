import { MissingRefreshToken } from "src/lib/errors/MissingRefreshToken";
import { Logger } from "src/util/Logger";
import jwtDecode from "jwt-decode";
import queryString from "query-string";
import { TextEncoder } from "text-encoding-polyfill";

const log = Logger.getLogger("AuthenticationModule");
const CLIENT_ID = "dispatcher";
const EXPIRE_THRESHOLD = 1000 * 120; // 2 minutes

/**
 * OAuth authentication module.
 * The config object can contain the following fields:
 *   authorizeUrl (mandatory) - The URL to the authorize endpoint
 *   tokenUrl (mandatory) - The URL to the token endpoint
 *   logoutUrl (mandatory) - The URL to the logout endpoint
 *   scope (mandatory) - Scope
 *   redirectUrl (optional) - The URL to redirect to after
 *     finishing an authentication step. If not given, it will default to
 *     the URL the browser is currently on, minus query parameters.
 */
export class AuthenticationModule {
  public idToken?: {
    name: string;
    nonce: string;
    orgName: string;
    orgUuid: string;
  };
  private static readonly validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  private static readonly validPKCEVerifierChars = `${AuthenticationModule.validChars}-._~`;
  private readonly audience: string;
  private readonly authorizeUrl: string;
  private readonly extraParams?: Record<string, string>;
  private readonly logoutUrl: string;
  private readonly redirectUrl: string;
  private readonly scope: string;
  private readonly tokenUrl: string;
  private accessToken: null | string = null;
  private idTokenRaw?: string;
  private pendingTokenRequest?: Promise<string>;
  private refreshToken: null | string = null;
  public constructor(
    config: {
      audience: string;
      authorizeUrl: string;
      logoutUrl: string;
      redirectUrl: string;
      scope: string;
      tokenUrl: string;
    },
    extraParams?: Record<string, string>
  ) {
    this.authorizeUrl = config.authorizeUrl;
    this.tokenUrl = config.tokenUrl;
    this.logoutUrl = config.logoutUrl;
    this.audience = config.audience;
    this.scope = config.scope;
    this.redirectUrl =
      config.redirectUrl ||
      `${document.location.origin}${document.location.pathname}`;
    this.accessToken = null;
    this.extraParams = extraParams;
  }
  /**
   * Is the user authenticated?
   *
   * This method returns true if the user is authenticated. This might not
   * mean we have an access token yet, but we have enough information to
   * obtain one from the authentication server.
   */
  public get isAuthenticated(): boolean {
    return !!this.refreshToken;
  }
  /**
   * Returns true if a code from query parameter was detected indicating that
   * the client should probably try to automatically login.
   */
  public fetchAccessTokenByCode(): boolean {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { code, error, error_description } = queryString.parse(
      document.location.search
    );
    if (error) {
      throw new Error(`Login failed: ${error_description || error}`);
    }
    if (window.history) {
      window.history.replaceState(null, "", document.location.pathname);
    }
    const verifier = localStorage.getItem("grouptalk.codeVerifier");
    if (code && verifier) {
      localStorage.removeItem("grouptalk.codeVerifier");
      this.pendingTokenRequest = this.tokenRequest({
        client_id: CLIENT_ID,
        code: code as string,
        code_verifier: verifier,
        grant_type: "authorization_code",
        redirect_uri: this.redirectUrl,
      });
      return true;
    }
    return false;
  }
  /**
   * Returns true if this is a logout request from OIDC server
   */
  public isLogoutRequest(): boolean {
    const { iss, logout, sid } = queryString.parse(
      document.location.search
    ) as Record<string, string>;
    if (logout !== undefined) {
      log.debug(
        `Logout request received from ${iss} while logged in to ${this.authorizeUrl}`
      );
    }
    if (
      logout !== undefined &&
      AuthenticationModule.sameServer(iss, this.authorizeUrl)
    ) {
      log.debug("Logging out");
      localStorage.setItem("logout", sid);
      return true;
    }
    return false;
  }
  /**
   * Returns a promise of an access token.
   *
   * Note that this might cause the browser to be redirected to a login
   * page, and later restart this app with an authorization code, etc, so
   * the function might not return. It is the responsibility of the
   * application to recover enough state to continue whatever operation
   * caused this to resume, if desired.
   */
  public login(): Promise<string> {
    // If we have an access token already, that hasn't expired, return it
    if (this.accessToken) {
      // Decode token to find out expiry time
      const claims = JSON.parse(atob(this.accessToken.split(".")[1]));
      const expires = new Date(claims.exp * 1000);
      const now = new Date();
      const expiresSafe = new Date(expires.getTime() - EXPIRE_THRESHOLD);
      if (expiresSafe > now) {
        return Promise.resolve(this.accessToken);
      }
    }
    // There is an outstanding token request, waiting for it to resolve
    if (this.pendingTokenRequest) {
      return this.pendingTokenRequest;
    }

    // We don't have a valid access token. Get a new one. If we have a refresh token,
    // we just make a new request to obtain an access token.
    if (this.refreshToken) {
      log.debug("Requesting a new access token, since we have a request token");
      this.pendingTokenRequest = this.tokenRequest({
        client_id: CLIENT_ID,
        grant_type: "refresh_token",
        refresh_token: this.refreshToken,
      });
      return this.pendingTokenRequest;
    }
    // No refresh token either. We need to authorize the user first.
    // Note that this method will redirect the browser, so we can't
    // do anything meaningful after we call it.
    log.debug("User not authorized, redirecting to authorization app");
    void this.authorize(this.extraParams);
    throw new MissingRefreshToken();
  }
  /**
   * Logout user. This will cause the browser to be redirected to a login page.
   */
  public async logout(): Promise<void> {
    const redirectUrl = encodeURIComponent(this.redirectUrl);
    if (this.refreshToken && this.logoutUrl) {
      await this.login();
      this.refreshToken = null;
      document.location.href = `${this.logoutUrl}?id_token_hint=${this.idTokenRaw}&post_logout_redirect_uri=${redirectUrl}`;
    } else {
      void this.authorize({ prompt: "login" });
    }
  }
  public logoutFromExternal(): void {
    log.info("Logging out from other window");
    this.accessToken = null;
    this.refreshToken = null;
    void this.authorize({ prompt: "login" });
  }
  public async tokenRequest(body: Record<string, string>): Promise<string> {
    log.debug(`POSTing request to ${this.tokenUrl} to obtain an access token`);
    try {
      const response = await fetch(this.tokenUrl, {
        body: AuthenticationModule.toForm(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        mode: "cors",
      });
      if (!response.ok) {
        if (response.status === 400 || response.status === 401) {
          this.refreshToken = null;
          // Non-valid refresh token. We need to re-authorize the user.
          // Note that this method will redirect the browser, so we can't
          // do anything meaningful after we call it.
          log.debug("User not authorized, redirecting to authorization app");
          void this.authorize(this.extraParams);
          throw new MissingRefreshToken();
        }
        let error = `Login server returned ${response.status} ${response.statusText}`;
        if (response.headers.get("Content-Type") === "application/json") {
          const json = await response.json();
          if (json.error && json.error_description) {
            error = `${error} (${json.error} - ${json.error_description})`;
          } else if (json.error) {
            error = `${error} (${json.error})`;
          }
        }
        throw new Error(error);
      }
      const json = await response.json();
      log.debug("Access token received from auth server");
      if (json.refresh_token) {
        this.refreshToken = json.refresh_token as string;
      }

      if (json.id_token) {
        const idToken = jwtDecode<{
          name: string;
          nonce: string;
          orgName: string;
          orgUuid: string;
        }>(json.id_token);
        const nonce = localStorage.getItem("grouptalk.nonce");
        if (nonce) {
          localStorage.removeItem("grouptalk.nonce");
          if (nonce !== idToken.nonce) {
            throw new Error(
              "Nonce in ID token doesn't match initially sent nonce!"
            );
          }
        }
        this.idToken = idToken;
        this.idTokenRaw = json.id_token as string;
      }

      // Save the access token, so it's used in subsequent requests until it expires.
      this.accessToken = json.access_token;
      log.debug("ID token is:", this.idToken);
      return json.access_token;
    } finally {
      this.pendingTokenRequest = undefined;
    }
  }
  private static base64URLEncode(str: string): string {
    return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  }
  private static randomBytes(size: number, valid?: string): Uint8Array {
    const validChs = valid || AuthenticationModule.validChars;
    const array = new Uint8Array(size || 10);
    window.crypto.getRandomValues(array);
    return array.map((x) => validChs.charCodeAt(x % validChs.length));
  }
  private static randomString(size: number, valid?: string): string {
    return String.fromCharCode(
      ...Array.from(AuthenticationModule.randomBytes(size, valid))
    );
  }
  private static removeUndefined(
    object: Record<string, string | undefined>
  ): Record<string, string> {
    return Object.entries(object).reduce(
      (a, [k, v]) => (v === undefined ? a : { ...a, [k]: v }),
      {}
    );
  }
  private static sameServer(s: string, t: string): boolean {
    const sUrl = new URL(s);
    const tUrl = new URL(t);
    return (
      sUrl.protocol === tUrl.protocol &&
      sUrl.hostname === tUrl.hostname &&
      sUrl.port === tUrl.port
    );
  }
  private static sha256(text: string): Promise<ArrayBuffer> {
    return crypto.subtle.digest("SHA-256", new TextEncoder().encode(text));
  }
  private static toForm(object: Record<string, string>): string {
    return Object.keys(object)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
      )
      .join("&");
  }
  // Private methods on AuthenticationModule
  private async authorize(extraParams?: Record<string, string>): Promise<void> {
    // Redirect the browser to the authorization URL, with appropriate parameters
    const { redirectUrl } = this;
    const state = AuthenticationModule.randomString(40);
    const nonce = AuthenticationModule.randomString(40);
    localStorage.setItem("grouptalk.nonce", nonce);
    const verifier = AuthenticationModule.base64URLEncode(
      AuthenticationModule.randomString(
        80,
        AuthenticationModule.validPKCEVerifierChars
      )
    );
    localStorage.setItem("grouptalk.codeVerifier", verifier);

    const sha = await AuthenticationModule.sha256(verifier);
    const query = queryString.stringify(
      AuthenticationModule.removeUndefined({
        ...extraParams,
        audience: this.audience,
        client_id: CLIENT_ID,
        code_challenge: AuthenticationModule.base64URLEncode(
          String.fromCharCode(...Array.from(new Uint8Array(sha)))
        ),
        code_challenge_method: "S256",
        nonce,
        redirect_uri: redirectUrl,
        response_type: "code",
        scope: this.scope,
        state,
      })
    );
    document.location.href = `${this.authorizeUrl}?${query}`;
  }
}
