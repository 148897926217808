/* eslint-disable import/order */
import { Logger } from "src/util/Logger";
import { createResampler } from "src/audio/resampler/createResampler";
import type { Decoder } from "src/audio/codec/Decoder";
import type { Resampler } from "src/audio/resampler/Resampler";

const log = Logger.getLogger("IlbcDecoder");
const IlbcCodec = require("./es5/ilbcCodec");

export class IlbcDecoder implements Decoder {
  private readonly fromILBCBuffer: Float32Array;
  private readonly ilbcDecoderInst: typeof IlbcCodec;
  private readonly inputHeap: Uint8Array;
  private readonly outputHeap: Float32Array;
  private readonly upSampler: Resampler;
  public constructor() {
    log.debug("Creating iLBC decoder");
    const size = IlbcCodec.ccall("getDecStructSize", "number", [], []);
    this.ilbcDecoderInst = IlbcCodec._malloc(size);
    const mode = 20;
    const useEnhancer = 1;
    const inputBytes = 38;
    const inputPtr = IlbcCodec._malloc(inputBytes);
    this.inputHeap = new Uint8Array(
      IlbcCodec.HEAPU8.buffer,
      inputPtr,
      inputBytes
    );
    const outputLength = 160;
    const outputPtr = IlbcCodec._malloc(outputLength * 4);
    this.outputHeap = new Float32Array(
      IlbcCodec.HEAPF32.buffer,
      outputPtr,
      outputLength
    );
    IlbcCodec.ccall(
      "initDecode",
      "number",
      ["number", "number", "number"],
      [this.ilbcDecoderInst, mode, useEnhancer]
    );
    this.upSampler = createResampler({ fromHz: 8000, toHz: 16000 });
    this.fromILBCBuffer = new Float32Array(160);
  }
  public decode(audio: Uint8Array): Float32Array {
    this.inputHeap.set(audio);
    IlbcCodec.ccall(
      "iLBC_decode",
      null,
      ["number", "number", "number", "mode"],
      [
        this.outputHeap.byteOffset,
        this.inputHeap.byteOffset,
        this.ilbcDecoderInst,
        1,
      ]
    );
    this.fromILBCBuffer.set(this.outputHeap);
    return this.upSampler.resample(this.fromILBCBuffer);
  }
  public generatePLC(): Float32Array {
    log.debug("Generating PLC");
    IlbcCodec.ccall(
      "iLBC_decode",
      null,
      ["number", "number", "number", "mode"],
      [
        this.outputHeap.byteOffset,
        this.inputHeap.byteOffset,
        this.ilbcDecoderInst,
        0,
      ]
    );
    this.fromILBCBuffer.set(this.outputHeap);
    return this.upSampler.resample(this.fromILBCBuffer);
  }
  public release(): void {
    log.debug("Releasing iLBC decoder");
    IlbcCodec._free(this.inputHeap.byteOffset);
    IlbcCodec._free(this.outputHeap.byteOffset);
    IlbcCodec._free(this.ilbcDecoderInst);
  }
}
