import { SoundService } from "src/app/model/audio/SoundService";
import { ContactTabSetting } from "src/app/model/contacts/ContactTabSetting";
import { Session } from "src/app/model/sessions/Session";
import { ListSetting } from "src/app/model/settings/ListSetting";
import { observableClass } from "src/app/state/observableClass";
import { SessionMediaType } from "src/lib/types/SessionMediaType";
import { SessionType } from "src/lib/types/SessionType";
import { Logger } from "src/util/Logger";
import { action } from "mobx";
import localStorage from "mobx-localstorage";
import type { State } from "src/app/model/State";
import type { AuthenticatedModule } from "src/lib/modules/AuthenticatedModule";
import type { Session as NodeSession } from "src/lib/modules/Session";
import type { GlobalTalkburstId } from "src/lib/types/GlobalTalkburstId";
import type { SessionNodeId } from "src/lib/types/SessionNodeId";
import type { ChannelUuid } from "src/nextgen/types/ChannelUuid";

const log = Logger.getLogger("sessions");

export class Sessions {
  public lastActiveSession: Session | null = null;
  public list: Session[] = [];
  public muteGroups?: ListSetting | null = null;
  public sessionFocus: SessionNodeId | null = null;
  public constructor(private readonly state: State) {
    observableClass(this);
  }
  public static get channelFocus(): string | undefined {
    return localStorage.getItem("gt2.channelfocus");
  }
  public get callSessions(): Session[] {
    return this.list.filter((session) => session.type === SessionType.Call);
  }
  public get focusedSession(): Session | undefined {
    return this.list.find((session) => session.sessionId === this.sessionFocus);
  }
  public get groupSessions(): Session[] {
    return this.list.filter((session) => session.type === SessionType.Group);
  }
  public get hasCallsOrMonitoringSessions(): boolean {
    return this.callSessions.length > 0;
  }
  public get hasSessionWithChannelUuid() {
    return (channelUuid: ChannelUuid): boolean =>
      !!this.sessionWithChannelUuid(channelUuid);
  }
  public get hasSolodSession(): boolean {
    return !!this.list.find((session) => session.configuredSolo);
  }
  public get sessionWithChannelUuid() {
    return (channelUuid: ChannelUuid): Session | undefined =>
      this.list.find((session) => session.channelUuid === channelUuid);
  }
  public get sessionWithLatestTalkburst() {
    return (globalTalkburstId: GlobalTalkburstId, suppressed: boolean) =>
      this.list.find(
        (session) =>
          session.lastIncomingTalkburst !== null &&
          session.lastIncomingTalkburst.globalTalkburstId ===
            globalTalkburstId &&
          session.lastIncomingTalkburst.audioSuppressed === suppressed
      );
  }
  public get sessionWithPeerUserUuid() {
    return (userEntityId: string): Session | undefined =>
      this.list.find((session) => {
        if (
          session.type !== SessionType.Call ||
          session.peerUserUuid === undefined
        ) {
          return false;
        }
        return session.peerUserUuid === userEntityId;
      });
  }
  public static setChannelFocus(channelUuid: ChannelUuid | undefined): void {
    localStorage.setItem("gt2.channelfocus", channelUuid);
  }
  public broadcastPttDown(originalSessionId: SessionNodeId): void {
    this.broadcastSessions(originalSessionId).forEach((session) => {
      void session.pttDown(session.sessionId !== originalSessionId);
    });
  }
  public broadcastPttUp(originalSessionId: SessionNodeId): void {
    this.broadcastSessions(originalSessionId).forEach((session) => {
      session.pttUp(session.sessionId !== originalSessionId);
    });
  }
  public clearMonitoringCallSessionsExceptList(list: SessionNodeId[]): void {
    this.monitoringCallSessions
      .filter((s) => list.indexOf(s.sessionId) === -1)
      .forEach((session) => {
        void session.endCall();
      });
  }
  public closeDown(): void {
    this.list.forEach((session) => {
      session.pttUp();
    });
    this.list.length = 0;
  }
  /**
   * @param sessionId If set, only handle sessions with this sessionId.
   */
  public openOrFocusCallSessions(sessionId?: number): void {
    this.list
      .filter(
        (session) =>
          (sessionId === undefined || session.sessionId === sessionId) &&
          session.type === SessionType.Call
      )
      .forEach((session) => {
        if (session.peerUserUuid) {
          this.state.contactManagement.openOrFocusPanel({
            name: session.name,
            tab: session.isFullDuplex
              ? ContactTabSetting.Call
              : ContactTabSetting.Actions,
            userUuid: session.peerUserUuid,
          });
        }
      });
  }
  public openRooms(): void {
    this.list
      .filter(
        (session) => session.mediaType === SessionMediaType.TwilioFullDuplex
      )
      .forEach((session) => {
        if (session.fullDuplexUuid) {
          this.state.online?.rooms.roomStarted({
            channelUuid: session.channelUuid,
            fullDuplexUuid: session.fullDuplexUuid ?? session.channelUuid,
            peerUserUuid: session.peerUserUuid,
          });
        }
      });
  }
  public setSessionFocus(session: Session): void {
    this.sessionFocus = session.sessionId;
    if (session.channelId) {
      Sessions.setChannelFocus(session.channelUuid);
    }
  }
  public async setup(authenticatedModule: AuthenticatedModule): Promise<void> {
    const sessionsModule = await authenticatedModule.setupSessionModule({
      maxCalls:
        window.gtConfig.maxCalls !== undefined ? window.gtConfig.maxCalls : 16,
      maxSessions:
        window.gtConfig.maxSessions !== undefined
          ? window.gtConfig.maxSessions
          : 128,
      onMonitored: (sessionMonitoringStatus) => {
        log.debug("Session monitored", sessionMonitoringStatus);
        setTimeout(() => {
          this.state.online?.queueManagement.cleanupMonitoringSessions();
        }, 5000);
      },
      onStarted: action((session: NodeSession) => {
        log.debug("Session start", session);
        this.list.push(new Session(this.state, session));
        // Possibly focus session
        if (session.type === SessionType.Call) {
          this.sessionFocus = session.sessionId;
        }
        if (
          (session.type === SessionType.Call ||
            session.type === SessionType.MonitoringListener) &&
          this.state.settings.notificationSounds.callStartSounds.value
        ) {
          SoundService.playSound("call-start");
        }

        if (
          session.channelUuid !== null &&
          this.sessionFocus == null &&
          Sessions.channelFocus === session.channelUuid
        ) {
          this.sessionFocus = session.sessionId;
        }
        // Possibly open contact panel for call
        this.openOrFocusCallSessions();
        // Possibly autopick some ticket due to a session started
        if (this.state.online) {
          this.state.online.queueManagement.pickNextTicket();
          if (session.mediaType === SessionMediaType.TwilioFullDuplex) {
            this.state.online.rooms.roomStarted({
              channelUuid: session.channelUuid,
              fullDuplexUuid: session.fullDuplexUuid ?? session.channelUuid!,
              peerUserUuid: session.peerUserUuid,
            });
          }
        }
      }),
      onStopped: action((sessionStopped) => {
        log.debug("Session stopped", sessionStopped);
        const stoppedSession = this.list.find(
          (session) => session.sessionId === sessionStopped.sessionId
        );
        stoppedSession?.possiblyMigrateLastTalkburst();
        this.list = this.list.filter(
          (session) => session.sessionId !== sessionStopped.sessionId
        );

        // Possibly restore focus
        if (this.sessionFocus === sessionStopped.sessionId) {
          this.restoreFocus();
        }
        // Restore contact tabs to action
        this.state.contactManagement.deselectCallTabInContactPanelsWithoutCall();
        if (
          stoppedSession &&
          stoppedSession.mediaType === SessionMediaType.TwilioFullDuplex
        ) {
          this.state.online?.rooms.roomStopped(stoppedSession.fullDuplexUuid!);
        }
        if (
          stoppedSession &&
          (stoppedSession.type === SessionType.Call ||
            stoppedSession.type === SessionType.MonitoringListener) &&
          this.state.settings.notificationSounds.callStopSounds.value
        ) {
          SoundService.playSound("call-end");
        }
      }),
    });
    this.muteGroups = new ListSetting({
      key: "gt2.mutegroup",
      list: sessionsModule.muteGroups,
    });
  }
  private get broadcastSessions() {
    return (originalSessionId: SessionNodeId): Session[] =>
      this.groupSessions.filter(
        (session) =>
          session.channelId &&
          this.state.online?.broadcast.broadcastGroups.contains(
            session.channelId
          ) &&
          (session.presence.length > 0 ||
            originalSessionId === session.sessionId)
      );
  }
  private get monitoringCallSessions(): Session[] {
    return this.list.filter(
      (session) => session.type === SessionType.MonitoringListener
    );
  }
  private restoreFocus(): void {
    if (Sessions.channelFocus !== null) {
      const restoreSession = this.list.find(
        (session) => session.channelUuid === Sessions.channelFocus
      );
      this.sessionFocus = restoreSession ? restoreSession.sessionId : null;
    } else {
      this.sessionFocus = null;
    }
  }
}
