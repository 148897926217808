import { GtError } from "src/app/gui/components/GtError";
import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { GtPanelTab } from "src/app/gui/components/GtPanelTab";
import { Layout } from "src/app/gui/components/Layout";
import { ContactRow } from "src/app/gui/panels/contacts/ContactRow";
import { SearchSetting } from "src/app/gui/settings/SearchSetting";
import { OrganizationFilter } from "src/app/gui/shared/OrganizationFilter";
import { UsersIcon, OnlineIcon, FavoriteFilledIcon } from "src/app/images";
import { ContactsTabSetting } from "src/app/model/contacts/ContactsTabSetting";
import { connect } from "src/app/state/connect";
import type { Panel } from "src/app/model/panels/Panel";

export const Contacts = connect<{
  panel: Panel;
}>(({ panel }) => {
  const {
    contactDetails,
    contactFilter,
    fetchContactDetails,
    filterSetting,
    id,
    setContactFilter,
    showDescription,
    showMessages,
    showOrganization,
  } = panel.contactsPanelData!;
  const { contacts, error, loadMore, loading } =
    panel.contactsPanelData!.contacts;
  let noElementsMessage = "No contacts available.";
  if (filterSetting.value.length > 0) {
    noElementsMessage = "No contacts matching filter";
  } else if (contactFilter === ContactsTabSetting.Favorites) {
    noElementsMessage = "No favorites available.";
  } else if (contactFilter === ContactsTabSetting.Online) {
    noElementsMessage = "No contacts online.";
  }
  return (
    <Layout column grow>
      <Layout alignMiddle minHeight={52} row>
        <Layout marginRight={20} row>
          <SearchSetting label="Search" setting={filterSetting} />
        </Layout>
        <OrganizationFilter panelData={panel.contactsPanelData!} />
        <Layout grow row />
        <GtPanelTab
          onClick={() => {
            setContactFilter(ContactsTabSetting.All);
          }}
          Icon={UsersIcon}
          id={`show_all_contacts_${id}`}
          selected={contactFilter === ContactsTabSetting.All}
          tooltip="Show all contacts"
        />
        <GtPanelTab
          onClick={() => {
            setContactFilter(ContactsTabSetting.Favorites);
          }}
          Icon={FavoriteFilledIcon}
          id={`show_facorite_contacts_${id}`}
          selected={contactFilter === ContactsTabSetting.Favorites}
          tooltip="Show favorite contacts"
        />
        <GtPanelTab
          onClick={() => {
            setContactFilter(ContactsTabSetting.Online);
          }}
          Icon={OnlineIcon}
          id={`show_online_contacts_${id}`}
          selected={contactFilter === ContactsTabSetting.Online}
          tooltip="Show online contacts"
        />
      </Layout>
      {error && <GtError message={error} />}
      {!error && (
        <Layout alignTop column grow marginTop={10}>
          <GtInfiniteRenderer
            elements={contacts.map((contact, index) => (
              <ContactRow
                contact={contact}
                contactDetails={contactDetails[contact.id]}
                evenRow={index % 2 === 1}
                fetchContactDetails={fetchContactDetails}
                key={contact.id}
                panel={panel}
                showDescription={showDescription}
                showFavorites={contactFilter === ContactsTabSetting.All}
                showMessages={showMessages}
                showOrganization={showOrganization}
              />
            ))}
            loadMore={loadMore}
            loading={loading}
            noElements={noElementsMessage}
          />
        </Layout>
      )}
    </Layout>
  );
});
