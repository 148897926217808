import { Layout } from "src/app/gui/components/Layout";
import { MediaTrackComponent } from "src/app/gui/panels/video/MediaTrackComponent";
import { connect } from "src/app/state/connect";

export const TwilioAudioTracks = connect((_, state) => {
  const audioTracks = state.online?.rooms.audioTracksFromAllRooms ?? [];
  return (
    <Layout>
      {audioTracks.map((audioTrack) => (
        <MediaTrackComponent
          invisible={true}
          key={audioTrack.sid}
          mirrored={false}
          track={audioTrack}
        />
      ))}
    </Layout>
  );
});
