import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { Presence } from "src/app/gui/panels/channels/detailedGroup/Presence";
import { TimeIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { useState } from "react";
import type { Panel } from "src/app/model/panels/Panel";
import type { Session } from "src/app/model/sessions/Session";

export const LatestSpeaker = connect<{
  panel: Panel;
  session: Session;
}>(({ panel, session }) => {
  const [over, setOver] = useState(false);

  return (
    <Layout
      onMouseEnter={() => {
        setOver(true);
      }}
      onMouseLeave={() => {
        setOver(false);
      }}
      style={{
        opacity: over ? 1.0 : session.percentActive,
        transform: "scale(1.0)",
      }}
      alignMiddle
      row
    >
      <Layout column marginLeft={5} marginRight={5}>
        <Presence
          mini
          panel={panel}
          presence={session.latestSpeakerPresence!}
        />
      </Layout>
      <TimeIcon style={{ fill: "#2d6666" }} />
      <Layout column marginLeft={3}>
        <Text color="#1d4646" size={12}>
          {session.timeSinceLastActive}
        </Text>
      </Layout>
    </Layout>
  );
});
