import { Message } from "src/app/model/flashMessage/Message";
import { observableClass } from "src/app/state/observableClass";
import { BackgroundTimer } from "src/util/BackgroundTimer";
import { action } from "mobx";
import type { Notification } from "src/app/model/notifications/Notification";

export class FlashMessage {
  private messageObj: Record<number, Message> = {};
  private nextId = 0;
  public constructor() {
    observableClass(this);
  }
  public get messages(): Message[] {
    return Object.values(this.messageObj);
  }
  public info(options: {
    duration?: number;
    icon?: "reset" | "usb";
    message?: string;
    notification?: Notification;
  }): void {
    this.show({ severity: "info", ...options });
  }
  public success(options: {
    duration?: number;
    icon?: "reset" | "usb";
    message: string;
    notification?: Notification;
  }): void {
    this.show({ duration: 3000, ...options });
  }
  public warn(options: {
    duration?: number;
    icon?: "reset" | "usb";
    message: string;
    notification?: Notification;
  }): void {
    this.show({ severity: "warning", ...options });
  }
  private show(options: {
    duration?: number;
    icon?: "reset" | "usb";
    message?: string;
    notification?: Notification;
    severity?: "info" | "success" | "warning";
  }): void {
    const useIndex = this.nextId;
    this.nextId += 1;
    this.messages.forEach((message) => {
      message.close();
    });
    this.messageObj[useIndex] = new Message({
      key: useIndex,
      removeMe: () => {
        BackgroundTimer.setTimeout(
          action(() => {
            // TODO: Found this commented out. Why?
            // delete this.messageObj[useIndex];
          }),
          500
        );
      },
      ...options,
    });
  }
}
