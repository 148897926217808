import { NotificationRenderer } from "src/app/gui/shared/NotificationRenderer";
import { UsbIcon, ResetIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import type { Message } from "src/app/model/flashMessage/Message";

const FlashMessage = connect<{
  flashMessage: Message;
}>(({ flashMessage }) => {
  const { close, duration, icon, message, notification, opened, severity } =
    flashMessage;
  let iconElement;
  if (icon) {
    switch (icon) {
      case "usb":
        iconElement = <UsbIcon />;
        break;
      case "reset":
        iconElement = <ResetIcon />;
        break;
      default:
        break;
    }
  } else if (notification) {
    iconElement = NotificationRenderer.createNotificationIcon(notification);
  }

  return (
    <Slide direction="down" in>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        autoHideDuration={duration}
        onClose={close}
        open={opened}
      >
        <Alert
          onClick={() => {
            if (notification) {
              notification.onSelect();
            }
            if (close) {
              close();
            }
          }}
          elevation={6}
          icon={iconElement}
          severity={severity}
          variant="filled"
        >
          {message ||
            (notification &&
              NotificationRenderer.createNotificationBody(notification))}
        </Alert>
      </Snackbar>
    </Slide>
  );
});

export const FlashMessages = connect((_, state) => {
  const { messages } = state.flashMessage;
  return (
    <>
      {messages.map((message) => (
        <FlashMessage flashMessage={message} key={message.key} />
      ))}
    </>
  );
});
