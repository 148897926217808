import { GroupTalkAPIError } from "src/lib/GroupTalkAPIError";

/**
 * Call declined.
 */
export class Declined extends GroupTalkAPIError {
  public constructor() {
    super("Call declined.", true);
  }
}
