import { implicitPx } from "src/util/implicitPx";
import { createElement, forwardRef } from "react";

type TextProps = {
  baseLine?: boolean;
  bold?: boolean;
  bolder?: boolean;
  bottom?: boolean;
  capitalize?: boolean;
  center?: boolean;
  color?: string;
  italic?: boolean;
  lineHeight?: number | string;
  nowrap?: boolean;
  size?: number | string;
  style?: React.CSSProperties;
  uppercase?: boolean;
  weight?: number;
};

const mapPropsToStyles: Record<
  string,
  (props: TextProps) => React.CSSProperties
> = {
  baseline: () => ({ verticalAlign: "baseline" }),
  bold: () => ({ fontWeight: "bold" }),
  bolder: () => ({ fontWeight: "bolder" }),
  bottom: () => ({ verticalAlign: "bottom" }),
  capitalize: () => ({ textTransform: "capitalize" }),
  center: () => ({ textAlign: "center" }),
  color: (props: TextProps) => ({ color: props.color }),
  italic: () => ({ fontStyle: "italic" }),
  lineHeight: (props: TextProps) => ({
    lineHeight: implicitPx(props.lineHeight),
  }),
  nowrap: () => ({ whiteSpace: "nowrap" }),
  size: (props: TextProps) => ({ fontSize: implicitPx(props.size) }),
  uppercase: () => ({ textTransform: "uppercase" }),
  weight: (props: TextProps) => ({ fontWeight: props.weight }),
};

export const Text = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TextProps & React.HTMLAttributes<HTMLSpanElement>>
>((props, ref) => {
  const { style, ...otherProps } = props;
  const computedStyle = Object.keys(props).reduce((obj, prop) => {
    const fn = mapPropsToStyles[prop];
    return fn != null && props[prop as keyof TextProps] !== undefined
      ? { ...obj, ...fn(props) }
      : obj;
  }, style);

  const passProps = Object.keys(otherProps)
    .filter((key) => mapPropsToStyles[key] === undefined)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: otherProps[key as keyof typeof otherProps],
      }),
      {}
    );

  const { children } = props;
  return createElement(
    "span",
    { ref, ...passProps, style: computedStyle },
    children
  );
});
