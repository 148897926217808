import { TextField } from "@mui/material";
import type { TextFieldProps } from "@mui/material";

type GtTextFieldProps = {
  fullWidth?: boolean;
  onValidate?: () => void;
  onValueChanged: (value: string) => void;
  style?: React.CSSProperties;
} & TextFieldProps;

export const GtTextField = ({
  fullWidth = true,
  onValidate,
  onValueChanged,
  style,
  ...props
}: GtTextFieldProps): JSX.Element => (
  <TextField
    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
      onValueChanged(event.target.value)
    }
    fullWidth={fullWidth}
    margin="dense"
    onBlur={onValidate}
    size="small"
    variant="outlined"
    {...props}
  />
);
