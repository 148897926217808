import { GtMenu } from "src/app/gui/components/GtMenu";
import { GtMenuItem } from "src/app/gui/components/GtMenuItem";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { StatusIcon, CheckIcon } from "src/app/images";
import { connect } from "src/app/state/connect";

export const StatusMenu = connect((_, state) => {
  const { availableStatuses, currentStatus, updateStatus } =
    state.online!.status;
  return (
    <Layout grow row>
      <GtMenu
        items={[
          <GtMenuItem
            icon={
              currentStatus == null ? (
                <CheckIcon style={{ fill: "#666" }} />
              ) : null
            }
            label={
              <Text color="#666" italic>
                No status
              </Text>
            }
            onSelect={() => {
              updateStatus();
            }}
            key="none"
          />,
          ...availableStatuses.map((status) => (
            <GtMenuItem
              icon={
                currentStatus && currentStatus.id === status.id ? (
                  <CheckIcon />
                ) : null
              }
              label={
                <Layout
                  style={{
                    borderRadius: 5,
                    width: "100%",
                  }}
                  background={status.backgroundColor || "#88888824"}
                  column
                  grow
                  padding={5}
                >
                  <Text center color={status.foregroundColor || "#000"}>
                    {status.name}
                  </Text>
                </Layout>
              }
              onSelect={() => {
                updateStatus({ statusUuid: status.id });
              }}
              key={status.id}
            />
          )),
        ]}
        id="statuses"
      >
        <Layout alignCenter alignMiddle row>
          <StatusIcon />
          {currentStatus && (
            <Layout
              style={{
                borderRadius: 5,
                width: "100%",
              }}
              background={currentStatus.backgroundColor || "#88888824"}
              column
              grow
              marginLeft={8}
              padding="5px 10px 5px 10px"
            >
              <Text center color={currentStatus.foregroundColor || "#aaaaaa"}>
                {currentStatus.name}
              </Text>
            </Layout>
          )}
        </Layout>
      </GtMenu>
    </Layout>
  );
});
