import { GtInfiniteRenderer } from "src/app/gui/components/GtInfiniteRenderer";
import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { PresenceRow } from "src/app/gui/panels/video/PresenceRow";
import { connect } from "src/app/state/connect";
import { CircularProgress } from "@mui/material/index";
import type { Channel } from "src/app/model/channels/Channel";
import type { Panel } from "src/app/model/panels/Panel";

export const Presence = connect<{
  channel: Channel;
  panel: Panel;
}>(({ channel, panel }) => {
  const { loading, presence } = channel.room!;
  if (loading) {
    return (
      <Layout alignCenter alignTop column grow>
        <Layout alignMiddle row>
          <CircularProgress size={24} />
          <Layout column marginLeft={10}>
            <Text bold size={14}>
              Joining room...
            </Text>
          </Layout>
        </Layout>
      </Layout>
    );
  }
  return (
    <Layout alignTop column grow>
      <GtInfiniteRenderer
        elements={[
          ...Object.values(presence).map((presenceRow, index) => (
            <PresenceRow
              evenRow={index % 2 === 1}
              key={presenceRow.id}
              panel={panel}
              presence={presenceRow}
            />
          )),
        ]}
        noElements="No users in group."
      />
    </Layout>
  );
});
