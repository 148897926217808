import { Layout } from "src/app/gui/components/Layout";
import { Text } from "src/app/gui/components/Text";
import { MicIcon, ConferenceGroupIcon } from "src/app/images";
import { connect } from "src/app/state/connect";
import { Button, Switch } from "@mui/material";
import type { Room } from "src/app/model/video/Room";

export const FullDuplexPTTButton = connect<{
  detailed: boolean;
  mini?: boolean;
  name: string;
  room: Room;
}>(({ detailed, mini, name, room }) => {
  const PttIcon = MicIcon;
  const { localAudioMuted: muted, roomMuted } = room;
  const buttonColor = roomMuted ? "#585868" : "#ff4444";
  const fillColor = roomMuted ? "#e6e6e6" : "#fff";

  let down = false;
  const onPttUp = (event: Event): void => {
    if (down) {
      event.preventDefault();
      down = false;
      document.removeEventListener("touchend", onPttUp);
      document.removeEventListener("mouseup", onPttUp);
      room.muteRoom();
    }
  };
  const onPttDown = (event: Event): void => {
    if (!down) {
      event.preventDefault();
      down = true;
      document.addEventListener("touchend", onPttUp);
      document.addEventListener("mouseup", onPttUp);
      room.unmuteRoom();
    }
  };
  return (
    <Layout
      alignMiddle
      column={detailed ? true : undefined}
      row={detailed ? undefined : true}
    >
      <Layout
        alignMiddle
        background={mini ? "#0000" : "#0002"}
        marginRight={10}
        padding={5}
        row
        style={{ borderRadius: 10 }}
      >
        {!mini && !detailed && (
          <Layout alignCenter column width={25}>
            <ConferenceGroupIcon />
          </Layout>
        )}
        {!mini && (
          <Layout column grow marginLeft={10} marginRight={15}>
            <Text bold nowrap size={12}>
              {name}
            </Text>
          </Layout>
        )}
        <Layout column>
          <Button
            onMouseDown={
              muted ? (event) => onPttDown(event.nativeEvent) : undefined
            }
            onTouchStart={
              muted ? (event) => onPttDown(event.nativeEvent) : undefined
            }
            style={{
              background: buttonColor,
              border: "1px solid black",
              cursor: muted ? "pointer" : "default",
              flexGrow: 1,
              justifyContent: "center",
              opacity: 1.0,
              overflow: "hidden",
              padding: 0,
              textOverflow: "ellipsis",
              textTransform: "none",
              transition: "all .4s",
              whiteSpace: "nowrap",
            }}
            disableRipple
            variant="contained"
          >
            <Layout
              alignCenter
              alignMiddle
              paddingLeft={5}
              paddingRight={5}
              row
            >
              <PttIcon height={32} style={{ fill: fillColor }} width={32} />
            </Layout>
          </Button>
        </Layout>
      </Layout>
      <Layout
        alignMiddle
        background={detailed ? undefined : "#0001"}
        padding={4}
        row
        style={{ borderRadius: 5 }}
      >
        <Text size={12}>PTT</Text>
        <Switch
          onChange={() => {
            room!.setMute(!muted);
          }}
          checked={!muted}
          size="small"
        />
        <Text size={12}>Open Mic</Text>
      </Layout>
    </Layout>
  );
});
